import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { auth } from "../../../utils/auth";
import { formatCurrency, getMoneda } from "../../../utils/helpers";
import { apiRequest } from "../../../hooks/apiRequest";
import dayjs from "dayjs";

const PrintEntradas = () => {
    const [entradas, setEntradas] = useState([]);

    const getEntradas = async () => {

        const response = await apiRequest({}, "entradas-diario" + window.location.search, 'get');

        if (response.data) {
            setEntradas(response.data);
        }
    };

    useEffect(() => {
        getEntradas();
    }, []);

    const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
        (suc) => suc.id === user.sucursalActual
    )[0];

    const totalBalancePesos = entradas.filter(c => c.moneda === 'DOP').reduce((acc, entrada) => {
        return acc + parseFloat(entrada.balance);
    }, 0);

    const totalBalanceDolares = entradas.filter(c => c.moneda === 'USD').reduce((acc, entrada) => {
        return acc + parseFloat(entrada.balance);
    }, 0);

    return (
        <div>
            <div className='row'>
                <h2 className='col-md-12 text-center m-b-5'>
                    Entradas de Diario
                </h2>
                <div className='col-md-12 text-center'>
                    {sucursal.nombre} - {sucursal.direccion} •{" "}
                    {queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
                </div>
                <div className='col-md-12 text-right m-b-10'>
                    <div className='float-left'>
                        CANT. ENTRADAS: {entradas.length}
                    </div>
                    <div className="float-right">
                        TOTAL: {entradas.length && formatCurrency(entradas.reduce((a,e) => a + e.credito, 0))}
                    </div>
                </div>
            </div>
            <table className='table'>
                <thead>
                    <tr>
                        <th style={{ width: 120 }}>Fecha</th>
                        <th>Referencia</th>
                        <th>Observaciones</th>
                        <th>Estado</th>
                        <th>Total</th>
                        <th>Movimientos</th>
                    </tr>
                </thead>
                <tbody>
                    {entradas.map((entrada) => {
                        return (
                            <tr key={entrada.id}>
                                <td>{dayjs(entrada.fecha).format('DD/MM/YYYY')}</td>
                                <td>
                                    {entrada.referencia}
                                </td>
                                <td>{entrada.observaciones}</td>
                                <td>{entrada.estado}</td>
                                <td>{formatCurrency(entrada.debito)}</td>
                                <td>
                                    <table className="table">
                                        <tbody>
                                            <tr className="uppercase">
                                                <td><small>Cuenta Contable</small></td>
                                                <td><small>Crédito</small></td>
                                                <td><small>Débito</small></td>
                                            </tr>
                                            {entrada.movimientos.map(movimiento => (
                                                <tr>
                                                    <td>{movimiento.cuentaContable?.nombre}</td>
                                                    <td>{movimiento.credito && formatCurrency(movimiento.credito)}</td>
                                                    <td>{movimiento.debito && formatCurrency(movimiento.debito)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className='row m-t-45'>
                <div className='col-md-12 text-right m-b-10'>
                    <div className='float-left'>
                        CANT. ENTRADAS: {entradas.length}
                    </div>

                    <div className='float-right'>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrintEntradas;
