import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import { toast } from "../../utils/helpers";
import { withRequest } from "../../utils/request";
import Modal from "../general/Modal";

class Edit extends Component {
    state = {
        errors: {},
        showDelete: false,
    };
    editReclamacion = async (event) => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(
            apiUrl(`reclamaciones/${this.props.reclamacion.id}`),
            {
                method: "post",
                body: data,
                headers: {
                    Accept: "application/json",
                    "X-Request-With": "XMLHttpRequest",
                    Authorization: "Bearer " + token,
                },
            }
        );

        const response = await res.json();

        if (response.success) {
            this.props.getReclamaciones();
            this.props.toggle();
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    deleteReclamacion = async (reclamacion) => {
        const token = auth.getToken();

        const url = `reclamaciones/${reclamacion}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.getReclamaciones();
            this.props.toggle();
            toast("Reclamacion borrada.");
            return;
        } else {
            toast(response.data, "error");
        }
    };
    toggleDeleteModal = () => {
        this.setState({
            showDelete: !this.state.showDelete,
        });
    };
    render() {
        const { errors } = this.state;
        const { toggle, reclamacion, empleados, vehiculos, rutas } = this.props;
        return (
            <div>
                <form method='post' onSubmit={this.editReclamacion}>
                    <input type='hidden' name='_method' value='patch' />
                    <div className='popup__header'>Editar Reclamación</div>
                    <div className='popup__body'>
                        <Form
                            reclamacion={reclamacion}
                            empleados={empleados}
                            vehiculos={vehiculos}
                            rutas={rutas}
                            errors={errors}
                        />
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            className='btn float-left'
                            onClick={this.toggleDeleteModal}>
                            <i className='fas fa-trash-alt'></i>
                        </button>
                        <button
                            type='button'
                            onClick={toggle}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        <button type='submit' className='btn btn-primary'>
                            Guardar
                        </button>
                    </div>
                </form>
                <Modal
                    title='Borrar Reclamación'
                    show={this.state.showDelete}
                    callback={() => this.deleteReclamacion(reclamacion.id)}
                    acceptText='Borrar'
                    toggle={this.toggleDeleteModal}>
                    ¿Estás seguro de que deseas borrar esta reclamación?
                </Modal>
            </div>
        );
    }
}

export default withRequest(Edit);
