import React, { Component } from "react";

import Select from "react-select";

class Form extends Component {
    render() {
        const {
            vehiculos,
            rutas,
            onSelectFicha,
            tarjeta,
            categoria,
            errors,
        } = this.props;
        return (
            <div>
                <input
                    type='hidden'
                    name='categoria_id'
                    defaultValue={categoria ? categoria : tarjeta.categoriaId}
                />
                <div className='form-group'>
                    <label className='label form-control-label'>Ficha</label>
                    <Select
                        name='vehiculo_id'
                        isClearable={true}
                        onChange={onSelectFicha}
                        defaultValue={
                            tarjeta.vehiculoId && {
                                isFixed: true,
                                value: tarjeta.vehiculoId,
                                label: tarjeta.ficha,
                            }
                        }
                        options={vehiculos.map((vehiculo) => ({
                            value: vehiculo.id,
                            label: vehiculo.ficha,
                        }))}
                    />
                    {errors.vehiculo_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.vehiculo_id[0]}
                        </small>
                    )}
                </div>
                <div className='form-group'>
                    <label className='label form-control-label'>Zona</label>
                    <Select
                        name='ruta_id'
                        isClearable={true}
                        defaultValue={
                            tarjeta.rutaId && {
                                isFixed: true,
                                value: tarjeta.rutaId,
                                label: tarjeta.ruta.nombre,
                            }
                        }
                        options={rutas.map((ruta) => ({
                            value: ruta.id,
                            label: `${ruta.nombre} ${ruta.descripcion}`,
                        }))}
                    />
                    {errors.ruta_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.ruta_id[0]}
                        </small>
                    )}
                </div>
                <div className='form-group'>
                    <label className='label form-control-label'>Meta</label>
                    <input
                        className='form-control'
                        defaultValue={tarjeta.meta}
                        type='number'
                        name='meta'
                        step='0.01'
                    />
                    {errors.meta && (
                        <small className='help-blockParams form-Text'>
                            {errors.meta[0]}
                        </small>
                    )}
                </div>
                <div className='form-group'>
                    <label className='label form-control-label'>Nota</label>
                    <textarea
                        className='form-control'
                        defaultValue={tarjeta.nota}
                        rows='6'
                        name='nota'
                    />
                    {errors.nota && (
                        <small className='help-blockParams form-Text'>
                            {errors.nota[0]}
                        </small>
                    )}
                </div>
            </div>
        );
    }
}

export default Form;
