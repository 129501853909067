import React, { Component } from "react";
import { withRequest } from "../../../../utils/request";
import { auth } from "../../../../utils/auth";
import { apiUrl } from "../../../../utils/apiUrl";
import Loader from "../../../general/Loader";
import Modal from "../../../general/Modal";
import { toast } from "../../../../utils/helpers";
import Create from "./Create";
import Edit from "./Edit";

class Miembros extends Component {
    state = {
        miembros: [],
        miembro: {},
        showCreate: false,
        showEdit: false,
        loading: true
    };
    componentDidMount() {
        this.setState({
            miembros: this.props.grupo.miembros,
        })
    }
    getMiembros = async (url = null) => {
        const id = this.props.grupo.id;
        url = url ? url : `gi-grupos/${id}/miembros`;
        const miembros = await this.props.request(url);

        if (miembros.data.length > 0) {
            this.setState({
                miembros: miembros.data,
                loading: false
            });
        }
    };
    removeMiembro = async (event, miembro) => {
        event.preventDefault();

        const { grupo } = this.props;
        const token = auth.getToken();

        const url = `gi-grupos/${grupo.id}/miembros/${miembro}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.getMiembros();
            toast('Miembro Borrado.', 'error');
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate
        });
    };
    toggleEditModal = (id) => {
        this.setState({
            showEdit: !this.state.showEdit,
            miembro: this.state.miembros.filter(m=>m.id===id)[0] ?? {}
        });
    };
    render() {
        const { grupo } = this.props;
        const { miembros, miembro, loading } = this.state;

        if (loading === 0) {
            return <Loader />;
        }
        return (
            <div>
                <div className='row m-b-20'>
                    <div className='col'>
                        <h3>Miembros</h3>
                    </div>
                    <div className='col text-right'>
                        <button
                            onClick={this.toggleCreateModal}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'>
                            Agregar
                        </button>
                    </div>
                </div>
                <table className='table tickets-table'>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Servidor</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {miembros.map(miembro => (
                            <tr key={miembro.id}>
                                <td>{miembro.nombre}</td>
                                <td>{miembro.servidor}</td>
                                <td className="uppercase">
                                    {miembro.estado === 'activo' ? (<small className="badge badge-success">{miembro.estado}</small>) : (<small className="badge badge-danger">{miembro.estado}</small>)}
                                </td>
                                <td className="text-right">
                                    <button
                                        onClick={event =>
                                            this.toggleEditModal(
                                                miembro.id
                                            )
                                        }>
                                        <i className='fa fa-pen' />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        grupo={grupo}
                        miembro={miembro}
                        getMiembros={this.getMiembros}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={this.toggleEditModal}>
                    <Edit
                        toggle={this.toggleEditModal}
                        grupo={grupo}
                        miembro={miembro}
                        getMiembros={this.getMiembros}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Miembros);
