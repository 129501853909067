import React, { Component } from "react";
import Loader from "../../general/Loader";
import { formatCurrency, getLink } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import { withRequest } from "../../../utils/request";
import Pagination from "../../general/Pagination";

class Gastos extends Component {
    state = {
        gastos: [],
        loading: true,
    };
    componentDidMount() {
        this.getGastos();
    }
    getGastos = async (url = null) => {
        const id = this.props.match.params.id;
        url = url ? url : `gastos-internos?limit=50&vehiculo=${id}`;
        const gastos = await this.props.request(url);

        if (gastos.data) {
            this.setState({
                gastos: gastos.data,
                loading: false,
                meta: gastos.meta,
                links: gastos.links,
            });
        }
    };

    render() {
        const { vehiculo } = this.props;
        const { loading, gastos } = this.state;

        if (loading === true) {
            return <Loader />;
        }
        return (
            <div>
                <div className='row m-b-20'>
                    <div className='col'>
                        <h3>Historial de Gastos Realizados </h3>
                    </div>
                    <div className='col text-right'>
                        <Link
                            to={getLink(
                                `/gastos-internos/crear?vehiculo=${vehiculo.id}`
                            )}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'>
                            Registrar gasto
                        </Link>
                    </div>
                </div>
                {gastos.length > 0 ? (
                    <div>
                        <table className='table tickets-table'>
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Descripción</th>
                                    <th className='text-right'>Total</th>
                                    <th className='text-right'>Cantidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gastos.map((gasto) => (
                                    <tr key={gasto.id}>
                                        <td>{gasto.fecha}</td>
                                        <td>
                                            {gasto.suplidor?.nombre}
                                            {gasto.items.map(item => (<div>
                                                <small>{item.descripcion} - {formatCurrency(item.total)}</small>
                                            </div>))}
                                        </td>
                                        <td className='text-right'>
                                            {formatCurrency(gasto.total)}
                                        </td>
                                        <td className='text-right'>
                                            {gasto.items?.length}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            links={this.state.links}
                            meta={this.state.meta}
                            getData={this.getGastos}
                        />
                    </div>
                ) : (
                    <div>No se han registrado gastos para este vehículo.</div>
                )}
            </div>
        );
    }
}

export default withRequest(Gastos);
