import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';
import Select from "react-select";
import { contador, diferenciaEnSegundos, getLink } from '../../utils/helpers';

const SolicitudItem = ({solicitud, estados, updateSolicitud, toggleEditModal}) => {
    const [pendiente, setPendiente] = useState(0);
    const [progreso, setProgreso] = useState(0);
    const [completada, setCompletada] = useState(0);

    const estadoClass = {
        'cancelada': 'danger',
        'en-progreso': 'primary',
        'completada': 'success',
        'pendiente': 'warning',
    }

    useEffect(() => {
        if (solicitud.estado === 'pendiente') {
            let pendInt = setInterval(() => {
                setPendiente(
                    diferenciaEnSegundos(solicitud.fechaCreacion)
                );
            }, 1000);
            setProgreso(solicitud.t_progreso);
            setProgreso(solicitud.t_completada);

            return () => clearInterval(pendInt);
        }
        if (solicitud.estado === 'en-progreso') {
            setPendiente(solicitud.t_pendiente);

            let progInt = setInterval(() => {
                setProgreso(
                    diferenciaEnSegundos(solicitud.fechaCreacion) - solicitud.t_pendiente
                );
            }, 1000);
            setCompletada(solicitud.t_completada);

            return () => clearInterval(progInt);
        }
        if (solicitud.estado === 'completada') {
            setPendiente(solicitud.t_pendiente);
            setProgreso(solicitud.t_progreso);
            setCompletada(solicitud.t_pendiente + solicitud.t_progreso);
        }
        if (solicitud.estado === 'cancelada') {
            setPendiente(solicitud.t_pendiente);
            setProgreso(solicitud.t_progreso);
        }
    }, [solicitud.estado]);

    return (
        <div className='card'>
            <div className='row'>
                <div
                    key={solicitud.id}
                    className={
                        "col-md-12 au-task__item au-task__item--" +
                        estadoClass[solicitud.estado]
                    }>
                    <div className='au-task__item-inner'>
                        <div className='row'>
                            <div className='col'>
                                <div><strong>{solicitud.numero} - {solicitud.establecimiento}</strong></div>
                                
                                <div style={{ whiteSpace: "pre-line" }}>{solicitud.descripcion}</div>
                                
                                <div style={{ lineHeight: 1.2 }}>
                                    {solicitud.creador && (<small>Creador: {solicitud.creador}</small>)}
                                    
                                    <br />
                                    
                                    {solicitud.ultimaActividad && (<small>{solicitud.ultimaActividad}</small>)}
                                </div>
                            </div>

                            <div className="col-md-3">
                                {solicitud.averia && (<div>
                                    <div style={{marginBottom: '-5px'}}><small className='uppercase'>Tipo de Solicitud:</small></div>
                                    
                                    <strong>{solicitud.averia}</strong>
                                </div>)}
                                
                                {solicitud.ruta && (<div>
                                    <div style={{marginBottom: '-5px'}}><small className='uppercase'>Zona:</small></div>
                                        
                                    <span>{solicitud.ruta.nombre}</span>
                                </div>)}
                                
                                {solicitud.contacto && (<div>
                                    <div style={{marginBottom: '-5px'}}><small className='uppercase'>Contacto:</small></div>
                                    <span>{solicitud.contacto.nombre}</span>
                                </div>)}
                            </div>

                            <div className='col'>
                                <strong>{solicitud.vehiculo}</strong>
                                
                                <div>{solicitud.empleado}</div>

                                <div>{solicitud.telefono && <small>Teléfono: {solicitud.telefono}</small>}</div>

                                <div>{solicitud.flota && <small>Flota: {solicitud.flota}</small>}</div>
                                
                                <small>Fecha: {dayjs(solicitud.fecha ?? solicitud.fechaCreacion).format("DD/MM/YYYY")}</small>
                            </div>

                            <div className='col-md-2'>
                                <div className="p-b-15">
                                    <strong>Tiempo Transcurrido</strong>
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                            <tr>
                                                <td>Pendiente:</td>
                                                <td className='text-right'>{contador(pendiente)}</td>
                                            </tr>
                                            <tr>
                                                <td>En Progreso:</td>
                                                <td className='text-right'>{contador(progreso)}</td>
                                            </tr>
                                            <tr>
                                                <td>Completada:</td>
                                                <td className='text-right'>{contador(completada)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                            <div className='col-md-2'>
                                <Select
                                    name="estado"
                                    defaultValue={
                                        estados.filter(e => e.value == solicitud.estado)
                                    }
                                    onChange={(e) =>
                                        updateSolicitud(
                                            e.value,
                                            solicitud.id,
                                            {
                                                pendiente,
                                                progreso,
                                                completada,
                                            }
                                        )
                                    }
                                    options={estados}
                                />
                                <div className='text-center d-flex m-t-5'>
                                    <Link
                                        to={'https://web.whatsapp.com/send?text=Ver solicictud ' + window.location.origin + getLink('/imprimir/solicitudes/'+solicitud.id)}
                                        target="_blank"
                                        className='btn btn-secondary'
                                        style={{flex: 1}}>
                                            <i className="zmdi zmdi-whatsapp"></i>
                                    </Link>
                                    <Link
                                        to={getLink('/imprimir/solicitudes/'+solicitud.id)}
                                        target="_blank"
                                        className='btn btn-primary'
                                        style={{margin: '0 5px', flex: 1}}>
                                            <i className="fa fa-print"></i>
                                    </Link>
                                    <button
                                        onClick={() =>
                                            toggleEditModal(
                                                solicitud
                                            )
                                        }
                                        style={{flex: 1}}
                                        className='btn btn-secondary'>
                                        <i className='zmdi zmdi-edit' />{" "}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SolicitudItem;