import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";

import { withRequest } from "../../../utils/request";
import { formatCurrency } from "../../../utils/helpers";
import { auth } from "../../../utils/auth";

class DesgloseEfectivo extends Component {
    state = {
        nominas: [{ pagos: [] }],
        orden: 'nombre',
        redondear: false,
    };
    componentDidMount() {
        this.getNomina();
    }
    getNomina = async (url = null) => {
        const id = this.props.match.params.id;
        const search = window.location.search ? window.location.search : "";
        url = url ? url : "nominas/" + id + search;
        const nominas = await this.props.request(url);

        if (nominas.success) {
            this.setState({
                nominas: nominas.data,
            });
            //window.print();
        }
    };
    onChangeOrden = (e) => {
        this.setState({
            orden: e.target.value
        });
    }
    onChangeRedondear = () => {
        this.setState({
            redondear: !this.state.redondear
        });
    }
    redondear = (cantidad) => {
        return Math.round(cantidad/5) * 5;
    }
    denominaciones = [2000,1000,500,200,100,50,25,10,5,1];
    desgloseEfectivo = (pago) => {
        const total = this.state.redondear ? this.redondear(pago.total) : pago.total;

        return this.denominaciones.reduce((result, denominacion) => {
            const residuo = result['carry'] % denominacion;

            if (residuo === result['carry']) {
                result[denominacion] = 0;
            } else {
                result[denominacion] = (result['carry'] - residuo) / denominacion;
            }

            result['carry'] = residuo;

            return result;

        }, {'carry': total});
    }
    render() {
        const { nominas, orden, redondear } = this.state;

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const nomina = nominas[0];

        const pagos = nominas.reduce((acc, nom) => {
            acc = [...acc, ...nom.pagos];
            return acc;
        }, []);

        const empleados = pagos.sort((a, b) => {
                return a.nombre < b.nombre ? -1 : 1;
            });

        const totalEmpleados = nominas.reduce((acc, nomina) => {
            return parseInt(nomina.empleados) + acc;
        }, 0);

        const totalPagos = pagos.reduce((acc, nomina) => {
            const total = redondear ? this.redondear(parseFloat(nomina.total)) : parseFloat(nomina.total);
            return total + acc;
        }, 0);

        const resumenDesglose = pagos.reduce((carry, pago) => {
            const desgloseEfectivo = this.desgloseEfectivo(pago);
            Object.keys(desgloseEfectivo).map(key => {
                if ( carry[key] ) {
                    carry[key] += desgloseEfectivo[key];
                } else {
                    carry[key] = desgloseEfectivo[key];
                }
                return key;
            });

            return carry;
        }, {});

        return (
            <div>
                <style>{`@page {size: 11in  8.5in;} *{font-size: 14px}`}</style>
                <div className="ordernar-resultados no-print">
                    <div className="form-group">
                        <div className="radio-btn">
                            <label>
                                <input type="radio" onChange={this.onChangeOrden} name="orden" value="nombre" defaultChecked={true} /> Ordenar por Nombre
                            </label>
                            <label>
                                <input type="radio" onChange={this.onChangeOrden} name="orden" value="codigo" /> Ordenar por Código
                            </label>
                            <label>
                                <input type="checkbox" onChange={this.onChangeRedondear} name="redondear" value="redondear" /> Redondear
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {nomina.cerradaEn && (
                        <div className='fecha_cierre col-md-12'>
                            Nomina Cerrada:{" "}
                            {dayjs(nomina.cerradaEn).format("DD/MM/YYYY")}
                        </div>
                    )}
                    <h2 className='col-md-12 text-center m-b-5'>
                        Desglose Efectivo
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(nomina.fechaInicio).format("DD/MM/YYYY")} -{" "}
                        {dayjs(nomina.fechaCierre).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            EMPLEADOS: {totalEmpleados}
                        </div>

                        <div className='float-right'>
                            <h3>TOTAL: {formatCurrency(totalPagos)}</h3>
                        </div>
                    </div>
                </div>
                <table className="table m-b-50">
                    <thead>
                        <tr>
                            <th className="text-center">RD$2,000</th>
                            <th className="text-center">RD$1,000</th>
                            <th className="text-center">RD$500</th>
                            <th className="text-center">RD$200</th>
                            <th className="text-center">RD$100</th>
                            <th className="text-center">RD$50</th>
                            <th className="text-center">RD$25</th>
                            <th className="text-center">RD$10</th>
                            <th className="text-center">RD$5</th>
                            <th className="text-center">RD$1</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {this.denominaciones.map(denominacion => (
                                <td className="text-center" key={denominacion}>{Math.floor(resumenDesglose[denominacion])}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{width: 100}}>Código</th>
                            <th>Nombre</th>
                            <th>Cargo</th>
                            <th className='text-center'>
                                2,000
                            </th>
                            <th className='text-center' style={{minWidth: 50}}>
                                1,000
                            </th>
                            <th className='text-center' style={{minWidth: 50}}>
                                500
                            </th>
                            <th className='text-center' style={{minWidth: 50}}>
                                200
                            </th>
                            <th className='text-center' style={{minWidth: 50}}>
                                100
                            </th>
                            <th className='text-center' style={{minWidth: 50}}>
                                50
                            </th>
                            <th className='text-center' style={{minWidth: 50}}>
                                25
                            </th>
                            <th className='text-center' style={{minWidth: 50}}>
                                10
                            </th>
                            <th className='text-center' style={{minWidth: 50}}>
                                5
                            </th>
                            <th className='text-center' style={{minWidth: 50}}>
                                1
                            </th>
                            <th className='text-right'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {empleados.sort((a, b) => {
                            if ( orden === 'codigo') {
                                if (!a.codigo) {
                                    return 1;
                                }
                                return a.codigo.localeCompare(b.codigo);
                            }

                            return a.nombre.localeCompare(b.nombre);
                        }).map((empleado, index) => {
                            const desgloseEfectivo = this.desgloseEfectivo(empleado);
                            return (
                                <tr key={index}>
                                    <td>{empleado.codigo}</td>
                                    <td>{empleado.nombre}</td>
                                    <td>{empleado.cargo}</td>
                                    {this.denominaciones.map(desglose => (
                                        <td className="text-center" key={desglose}>{Math.floor(desgloseEfectivo[desglose])}</td>
                                    ))}
                                    <td className="text-right">
                                        {redondear ? (
                                            <span>
                                                {formatCurrency(
                                                    this.redondear(empleado.total)
                                                )}
                                            </span>
                                        ) : (
                                            <span>
                                                {formatCurrency(
                                                    empleado.total
                                                )}
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='row'>
                    <div className='col-md-12 text-right p-t-10 p-b-10'>
                        <div className='float-left'>
                            EMPLEADOS: {totalEmpleados}
                        </div>

                        <div className='float-right'>
                            <h3>TOTAL: {formatCurrency(totalPagos)}</h3>
                        </div>
                    </div>
                </div>
                <div className='firma-reporte'>
                    <div className='firma'>Supervisor</div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(DesgloseEfectivo));
