import React, { Component } from "react";

class Form extends Component {
    state = {
        contenedor: false,
    };

    componentDidMount() {
        this.setState({
            contenedor: this.props.almacen.contenedor,
        });
    }

    setContenedor = (e) => {
        this.setState({
            contenedor: e.target.checked,
        });
    };

    render() {
        const { almacen, errors } = this.props;
        const { contenedor } = this.state;
        return (
            <div>
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Nombre:
                        </label>
                    </div>
                    <div className='col col-md-7'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre'
                            name='nombre'
                            defaultValue={almacen.nombre}
                        />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Inventario:
                        </label>
                    </div>
                    <div className='col col-md-7'>
                        <select
                            className='text-input form-control'
                            id='tipo_producto'
                            name='tipo_producto'
                            defaultValue={almacen.tipo_producto}
                        >
                            <option value="">- Seleccionar -</option>
                            <option value="productos">Productos</option>
                            <option value="items">Interno</option>
                        </select>
                        {errors.tipo_producto && (
                            <small className='help-blockParams form-Text'>
                                {errors.tipo_producto[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Principal
                        </label>
                    </div>
                    <div className='col-md-7'>
                        <label className='switch switch-3d switch-success mr-3'>
                            <input
                                type='checkbox'
                                className='switch-input'
                                name='principal'
                                value='yes'
                                defaultChecked={almacen.principal}
                            />
                            <span className='switch-label' />
                            <span className='switch-handle' />
                        </label>
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            ¿Es un Contenedor?
                        </label>
                    </div>
                    <div className='col-md-7'>
                        <label className='switch switch-3d switch-success mr-3'>
                            <input
                                type='checkbox'
                                className='switch-input'
                                name='contenedor'
                                value='yes'
                                checked={contenedor}
                                onChange={this.setContenedor}
                                defaultChecked={almacen.contenedor}
                            />
                            <span className='switch-label' />
                            <span className='switch-handle' />
                        </label>
                    </div>
                </div>
                {contenedor && (
                    <div>
                        <div className='row form-group'>
                            <div className='col col-md-5'>
                                <label className='label form-control-label'>
                                    Contenido:
                                </label>
                            </div>
                            <div className='col col-md-7'>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    id='contenido'
                                    name='contenido'
                                    defaultValue={almacen.contenido}
                                />
                                {errors.contenido && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.contenido[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col col-md-5'>
                                <label className='label form-control-label'>
                                    Capacidad:
                                </label>
                            </div>
                            <div className='col col-md-7'>
                                <input
                                    type='number'
                                    className='text-input form-control'
                                    id='capacidad'
                                    name='capacidad'
                                    step='0.01'
                                    defaultValue={almacen.capacidad}
                                />
                                {errors.capacidad && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.capacidad[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col col-md-5'>
                                <label className='label form-control-label'>
                                    Unidad:
                                </label>
                            </div>
                            <div className='col col-md-7'>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    id='unidad'
                                    name='unidad'
                                    defaultValue={almacen.unidad}
                                />
                                {errors.unidad && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.unidad[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Form;
