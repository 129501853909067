import dayjs from "dayjs";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { auth } from "../../../../utils/auth";
import { withRequest } from "../../../../utils/request";


class PrintEstudiantes extends Component {
    state = {
        estudiantes: [],
    };
    componentDidMount() {
        this.getItems();
    }
    getItems = async () => {
        const response = await this.props.request(
            "academico/estudiantes" + window.location.search
        );

        if (response.data) {
            this.setState({
                estudiantes: response.data,
            });
            window.print();
        }
    };
    render() {
        const { estudiantes } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Estudiantes
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL ESTUDIANTES: {estudiantes.length}
                        </div>

                        <div className='float-right'>
                            <h3>ESTUDIANTES</h3>
                        </div>
                    </div>
                    <style>{`
                        td {
                            vertical-align: middle !important;
                        }
                    `}</style>
                </div>
                <table className='table m-b-40'>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Nombre</th>
                            <th>Grado</th>
                            <th>Sección</th>
                            <th>Estado</th>
                            <th>Nacionalidad</th>
                            <th>Beca</th>
                        </tr>
                    </thead>
                    <tbody>
                        {estudiantes.map((estudiante, index) => {
                            return (
                                <tr key={estudiante.id}>
                                    <td>{index+1}</td>
                                    <td><img src={estudiante.foto} style={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '20px',
                                    }}/></td>
                                    <td>{estudiante.nombreCompleto}</td>
                                    <td>{estudiante.curso?.grado?.nombre}</td>
                                    <td>{estudiante.curso?.seccion?.nombre}</td>
                                    <td>{estudiante.estado}</td>
                                    <td>{estudiante.nacionalidad}</td>
                                    <td>{estudiante.beca?.nombre}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='row'>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL ESTUDIANTES: {estudiantes.length}
                        </div>

                        <div className='float-right'>
                            <h3>ESTUDIANTES</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintEstudiantes));
