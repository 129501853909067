import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { auth } from "../../utils/auth";
import { withRequest } from "../../utils/request";
import { toast, getLink, catchEvent, isPastDate } from "../../utils/helpers";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import Form from "./Form";
import Modal from "../general/Modal";
import Edit from "../Contactos/Edit";
import CreateContacto from "../Contactos/Create";
import FileLoader from "../general/FileLoader";
import dayjs from "dayjs";
class Create extends Component {
	state = {
		errors: {},
		compra: { porPagar: 0 },
		contactos: [false],
		items: [false],
		productos: [],
		grupos: [],
		impuestos: [],
		terminos: [],
		almacenes: [],
		compraItems: [{ id: Date.now(), subtotal: 0, itbis: 0 }],
		useItbis: "aplicado",
		itbis: 1.18,
		total: 0,
		tipo: "producto",
		saving: false,
		contacto_id: false,
		showCreateContactoModal: false,
		showEditContactoModal: false,
		submitBtnClicked: "normal",
		showPagoModal: false,
		pagoInfo: { pago: '', tipo_pago: "efectivo" },
		shouldCleanScreen: false,
		isLoading: true,
		showBotonPago: true,
	};
	componentDidMount() {
		this.getData();
		this.formRef = React.createRef();

        catchEvent('actualizar-fecha-compra', this.handleCambioFecha);
	}
    componentWillUnmount() {
        document.removeEventListener('actualizar-fecha-compra', this.handleCambioFecha)
    }
    handleCambioFecha = (data) => {
        if (isPastDate(data.fecha)) {
            this.setState({
                showBotonPago: false
            });
        } else {
            this.setState({
                showBotonPago: true
            });
        }
    }
	getData = () => {
		this.getTable("items");
		this.getTable("productos");
		this.getTable("terminos-pago", "terminos");
		this.getTable("grupos");
		this.getTable("contactos");
		this.getTable("impuestos");
		this.setState({isLoading: false});
	};

	getTable = async (url = null, nombre = null) => {
		const table = await this.props.request(url);
		const tableName = nombre ? nombre : url;

		if (table.data) {
			this.setState({
				[tableName]: table.data,
			});
			this.setState({
				loading: false,
			});
		}
	};
	createCompra = async (event) => {
		event.preventDefault();

		this.setState({
			saving: true,
		});

		const token = auth.getToken();
		const data = new FormData(event.target);

		const res = await fetch(apiUrl("compras"), {
			method: "post",
			body: data,
			headers: {
				Accept: "application/json",
				"X-Request-With": "XMLHttpRequest",
				Authorization: "Bearer " + token,
			},
		});

		const response = await res.json();

		if (response.success) {
			if (this.state.submitBtnClicked === "normal") {
				this.props.history.push(getLink("/compras"));
			} else if (this.state.submitBtnClicked === "nueva") {
				this.setState({
					errors: {},
					compra: {},
					tipo: "producto",
					contacto_id: false,
					showCreateContactoModal: false,
					showEditContactoModal: false,
					submitBtnClicked: "normal",
					showPagoModal: false,
					isLoading: true,
				});
				this.getData();
			} else if (this.state.submitBtnClicked === "imprimir") {
				window.open(getLink("/imprimir/compras/" + response.data?.id));
				this.props.history.push(getLink("/compras"));
			}
			this.setState({
				saving: false,
			});
			toast("Compra registrada con exito");
		}

		if (response.code === 422) {
			this.setState({ errors: response.data.message, saving: false });
            var errors = Object.keys(response.data.message);
            for (let index = 0; index < errors.length; index++) {
                if (errors[index].indexOf('item_id') > -1) {
                    toast("Favor seleccionar un producto o borrar el item vacío.", 'error');
                    break;
                }
                if (errors[index].indexOf('costo') > -1) {
                    toast("Hay un producto con el campo costo vacío.", 'error');
                    break;
                }
                if (errors[index].indexOf('precios') > -1) {
                    toast("Favor verifica que todos los productos tenga al menos un precio.", 'error');
                    break;
                }

                toast("Por favor revisa todos los campos", "error");
                break;
            }
		} else if (response.code === 500) {
			this.setState({ errors: response.data.message, saving: false });
			toast("Error creando compra", "error");
		}
	};
	addItem = () => {
		this.setState({
			compraItems: [...this.state.compraItems, { id: Date.now() }],
		});
	};
	removeItem = (item) => {
		this.setState({
			compraItems: this.state.compraItems.filter((i) => i.id !== item),
		});
	};
	calcularItbis = (items) => {
		return items.reduce((accum, item) => {
			return accum + parseFloat(item.itbis);
		}, 0);
	};
	calcularSubtotal = (items) => {
		return items.reduce((accum, item) => {
			return accum + parseFloat(item.subtotal);
		}, 0);
	};
	calcularTotal = (total, itbis) => {
		return total + itbis;
	};
	updateTotal = async (id = null, value = null, itbis = 0) => {
		await this.setState({
			compraItems: this.state.compraItems.map((item) => {
				if (item.id === id) {
					item.subtotal = value;
					item.itbis = itbis;
				}
				return item;
			}),
		});
	};
	triggerUpdateItbis = () => {
		const event = new Event("update-itbis");
		window.dispatchEvent(event);
	};
	toggleModal = async (modal, data = false) => {
		const name = "show" + modal + "Modal";
		this.setState({
			[name]: !this.state[name],
            pagoInfo: { pago: '', tipo_pago: "efectivo" },
		});

		if (data) {
			await this.getTable("contactos");
			this.setState({
				contacto_id: data.id,
			});
		}
	};

	updateContacto = (value) => {
		this.setState({
			contacto_id: value,
		});
	};

	guardarYSalir = async (btnClick) => {
		await this.setState({ submitBtnClicked: "normal" });
		this.formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};
	guardarYPagar = () => {
		this.setState({
            showPagoModal: true,
            pagoInfo: { pago: '', tipo_pago: "efectivo" }
        });
	};
	guardarEImprimir = async () => {
		await this.setState({ submitBtnClicked: "imprimir" });

		this.formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};
	guardarYNuevaFactura = async () => {
		await this.setState({ submitBtnClicked: "nueva" });

		this.formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	setPagoInfo = (callback) => {
		this.setState((state) => ({ pagoInfo: callback(state.pagoInfo) }));
	};

	setPorPagar = () => {
		this.setPagoInfo((state) => ({ ...state, pago: state.total }));
	};

	togglePagoModal = () => {
		this.setState((state) => ({
			...state,
			showPagoModal: false,
			pagoInfo: { pago: '', tipo_pago: "efectivo" },
		}));
	};

	render() {
		const {
			errors,
			compra,
			compraItems,
			items,
			productos,
			terminos,
			impuestos,
			grupos,
			saving,
			contactos,
			contacto_id,
			showPagoModal,
			shouldCleanScreen,
			isLoading,
			showBotonPago
		} = this.state;

		/* const subtotal = this.calcularSubtotal(compraItems);
        const totalitbis = this.calcularItbis(compraItems); */
		const total = this.calcularSubtotal(compraItems);

		const contacto =
			contactos && contactos.filter((c) => c.id === contacto_id)[0];

		if (isLoading) {
			return (<Loader />);
		}

		return (
			<div className="crear-compra">
				<div className="page-header">
					<h1 className="page-title row">
						<div className="col">
							<span className="m-r-15">Nueva Compra</span>
						</div>
					</h1>
				</div>
				<form
					method="post"
					onSubmit={this.createCompra}
					onKeyUp={this.preventEnter}
					className="row"
					ref={this.formRef}
                    encType='multipart/form-data'
				>
					<Form
						compra={compra}
						errors={errors}
						items={items}
						productos={productos}
						terminos={terminos}
						grupos={grupos}
						impuestos={impuestos}
						total={total}
						toggleModal={this.toggleModal}
						updateContacto={this.updateContacto}
						citems={[]}
						contactos={contactos}
						contacto={contacto}
						showPagoModal={showPagoModal}
						togglePagoModal={this.togglePagoModal}
						guardarYSalir={this.guardarYSalir}
						shouldCleanScreen={shouldCleanScreen}
					>
						<div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    {compra.facturaCompra?.length > 0 ? (
                                        <label className="label form-control-label">
                                            Factura Original en PDF:
                                        </label>
                                    ) : (
                                        <label className="label form-control-label">
                                            Adjuntar Factura Original:
                                        </label>
                                    )}
                                    <FileLoader
                                        name="factura_compra[]"
                                        archivos={compra.facturaCompra ?? []}
                                    />
                                    {errors.factura_compra && (
                                        <small className="help-blockParams form-Text">
                                            {errors.factura_compra[0]}
                                        </small>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <label className="label form-control-label">
                                        Nota:
                                    </label>
                                    {compra.borrada ? (
                                        <div>{compra.descripcion}</div>
                                    ) : (
                                        <textarea
                                            className="text-input form-control"
                                            id="descripcion"
                                            name="descripcion"
                                            rows="4"
                                            defaultValue={compra.descripcion}
                                        />
                                    )}
                                    {errors.descripcion && (
                                        <small className="help-blockParams form-Text">
                                            {errors.descripcion[0]}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
					</Form>
					<div className="page-footer text-right col-md-12">
						<button
							type="button"
							onClick={() =>
								(window.location = getLink("/compras"))
							}
							className="btn btn-plain"
						>
							Volver
						</button>
						{(!saving && showBotonPago) && (
							<button
								type="button"
								className="btn btn-outline-primary"
								onClick={() => this.guardarYPagar()}
							>
								Guardar y agregar pago
							</button>
						)}

						{!saving && (
							<button
								type="button"
								className="btn btn-outline-primary"
								onClick={() => this.guardarYNuevaFactura()}
							>
								Guardar y crear nueva
							</button>
						)}

						{!saving && (
							<button
								type="button"
								className="btn btn-outline-primary"
								onClick={() => this.guardarEImprimir()}
							>
								Guardar e imprimir
							</button>
						)}

						{saving ? (
							<Loader type="small" />
						) : (
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => this.guardarYSalir()}
							>
								Guardar
							</button>
						)}
					</div>

					{/* pago fields */}
					{/* <input type="hidden" name="pago" value={pagoInfo.pago} /> */}
				</form>

				<Modal
					show={this.state.showCreateContactoModal}
					blank={true}
					toggle={() => this.toggleModal("CreateContacto")}
					size="lg"
				>
					<CreateContacto
						toggle={(e, contacto) =>
							this.toggleModal("CreateContacto", contacto)
						}
						fromFacturacion
					/>
				</Modal>

				<Modal
					show={this.state.showEditContactoModal}
					blank={true}
					toggle={() => this.toggleModal("EditContacto")}
					size="lg"
				>
					<Edit
						toggle={(e, contacto) =>
							this.toggleModal("EditContacto", contacto)
						}
						contacto={contacto}
						fromFacturacion
					/>
				</Modal>
			</div>
		);
	}
}

export default withRouter(withRequest(Create));
