import React, { Component } from "react";

import Loader from "../../general/Loader";
import { formatCurrency } from "../../../utils/helpers";

class Prestamo extends Component {
    state = {
        saving: false,
        ayudante: false,
        activo: {},
        showDropdown: false,
        showModal: false,
        showPrestamo: false,
    };

    constructor(props) {
        super(props);

        this.form = React.createRef();
    }

    componentDidMount() {
        this.setState({
            prestamo: this.props.prestamo,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const prestamo = nextProps.prestamo;
        this.setState({
            prestamo: prestamo,
        });
    }

    togglePrestamos = (show) => {
        if (!show) {
            this.sidePopupWrap.current.classList.remove("slideInRight");
            this.sidePopupWrap.current.classList.add("slideOutRight");
            this.sidePopupOverlay.current.classList.remove("fadeIn");
            this.sidePopupOverlay.current.classList.add("fadeOut");
            setTimeout(() => {
                this.setState({
                    showPrestamo: show,
                });
            }, 1000);
            return;
        }

        this.setState({
            showPrestamo: true,
        });
    };

    submitForm = (e) => {
        setTimeout(() => {
            var evObj = document.createEvent("Events");
            evObj.initEvent("submit", true, false);
            this.form.current.dispatchEvent(evObj);
        }, 0);
    };

    showDropdown = () => {
        this.setState({
            showDropdown: !this.state.showDropdown,
        });
    };

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    movimientosPrestamo = (activo) => {
        this.setState({
            activo,
        });
        this.togglePrestamo(true);
    };

    hideDropdown = (e) => {
        if (this.dropdown && this.dropdown.contains(e.target)) {
            return;
        }
        this.setState({
            showDropdown: false,
        });
    };

    UNSAFE_componentWillMount() {
        document.addEventListener("mousedown", this.hideDropdown, false);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.hideDropdown, false);
    }

    render() {
        const { index, prestamo, removePrestamo } = this.props;
        const { showDropdown } = this.state;

        if (!prestamo) return <Loader />;

        return (
            <div
                style={{ animationDelay: index * 50 + "ms" }}
                id={prestamo.miembroId}
                className='asignacion prestamo'>
                <form
                    ref={this.form}
                    onSubmit={(e) =>
                        this.updateTarjeta(e,  prestamo.id)
                    }>
                    <input type='hidden' name='_method' value='PATCH' />
                    <input
                        type='hidden'
                        name='categoria_id'
                        value={prestamo.categoriaId}
                    />
                    <input
                        type='hidden'
                        name='vehiculo_id'
                        value={prestamo.vehiculoId}
                    />
                    <input
                        type='hidden'
                        name='ruta_id'
                        value={prestamo.rutaId}
                    />
                    {prestamo.grupo && (
                        <div className='asignacion-header row'>
                            <div className='ficha'>{prestamo.miembro?.nombre} - {prestamo.miembro?.servidor}</div>
                                <div className='ruta'>
                                    {prestamo.grupo.alias} ({prestamo.grupo.nombre}) {" » "}
                                    <small
                                        className='ruta-descripcion'
                                        title={prestamo.grupo.direccion}>
                                            {prestamo.grupo.direccion}
                                    </small>
                                </div>
                                <div
                                className={
                                    "asignacion-menu " +
                                    (showDropdown ? "active" : "")
                                }>
                                    <button
                                        onClick={this.showDropdown}
                                        type='button'>
                                        <i className='zmdi zmdi-menu'></i>
                                    </button>
                                    <div
                                        ref={(dropdown) =>
                                            (this.dropdown = dropdown)
                                        }
                                        className='dropdown'>
                                        <ul>
                                            <li>
                                                <button
                                                    type='button'
                                                    onClick={() => {
                                                        this.showDropdown();
                                                        this.props.editar(
                                                            prestamo
                                                        );
                                                    }}>
                                                    <i className='zmdi zmdi-edit'></i>{" "}
                                                    Editar
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    type='button'
                                                    onClick={(e) =>
                                                        removePrestamo(
                                                            prestamo.id
                                                        )
                                                    }>
                                                    <i className='zmdi zmdi-delete'></i>{" "}
                                                    Borrar
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        </div>
                    )}
                    <div className='asignacion-body row'>
                        <div className='operadores row'>
                            {prestamo.grupo && (
                                <table className="table table-prestamos">
                                    <thead>
                                        <tr>
                                            <th><small>Deuda Inicial:</small></th>
                                            <th><small>Balance Deuda Inicial:</small></th>
                                            <th><small>Total de Abonos:</small></th>
                                            <th><small>Total de Préstamos:</small></th>
                                            <th><small>Total Adeudado:</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>{formatCurrency(prestamo.deudaInicial)}</strong>
                                            </td>
                                            <td>
                                                <strong>{formatCurrency(prestamo.deudaInicialP)}</strong>
                                            </td>
                                            <td>
                                                <strong>{formatCurrency(prestamo.totalAbono)}</strong>
                                            </td>
                                            <td>
                                                <strong>{formatCurrency(prestamo.totalPrestamo)}</strong>
                                            </td>
                                            <td>
                                                <strong>{formatCurrency(prestamo.totalAdeudado)}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <button
                            type='button'
                            onClick={(e) =>
                                this.props.clickPrestamo(prestamo)
                            }
                            className='tickets-btn btn-despachar au-btn--green m-r-10'>
                            <span className='count'>Editar</span>
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default Prestamo;
