import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { withRequest } from "../../../utils/request";
import { formatCurrency } from "../../../utils/helpers";

class ComprobantesDetalle extends Component {
    state = {
        nominas: [{ pagos: [] }],
        orden: 'nombre',
        redondear: false,
    };
    componentDidMount() {
        this.getNomina();
    }
    getNomina = async (url = null) => {
        const id = this.props.match.params.id;
        const search = window.location.search ? window.location.search : "";
        url = url ? url : "nominas/" + id + search;
        const nominas = await this.props.request(url);

        if (nominas.success) {
            this.setState({
                nominas: nominas.data,
            });
            window.print();
        }
    };
    onChangeOrden = (e) => {
        this.setState({
            orden: e.target.value
        });
    }
    onChangeRedondear = () => {
        this.setState({
            redondear: !this.state.redondear
        });
    }
    redondear = (cantidad) => {
        return Math.round(cantidad/5) * 5;
    }
    render() {
        const { nominas, orden, redondear } = this.state;
        const nomina = nominas[0];
        const params = queryString.parse(window.location.search);

        const combinarPagos = nominas.reduce((acc, nom) => {
            acc = [...acc, ...nom.pagos];
            return acc;
        }, []);

        const empleados = combinarPagos
            .sort((a, b) => {
                return a.nombre < b.nombre ? -1 : 1;
            })
            .reduce((acc, empleado) => {
                if (acc[empleado.cedula] === undefined) {
                    acc[empleado.cedula] = empleado;
                    if (parseFloat(acc[empleado.cedula].toneladas) > 0) {
                        acc[empleado.cedula].totalDescuentos = empleado.descuentos.reduce((carry, desc) => {
                            return carry + parseFloat(desc.monto);
                        }, 0);
                    } else {
                        acc[empleado.cedula].totalDescuentos = 0;
                    }
                } else {
                    acc[empleado.cedula].toneladas =
                        parseFloat(acc[empleado.cedula].toneladas) +
                        parseFloat(empleado.toneladas);
                    acc[empleado.cedula].total =
                        parseFloat(acc[empleado.cedula].total) +
                        parseFloat(empleado.total);
                    acc[empleado.cedula].sueldoBruto =
                        parseFloat(acc[empleado.cedula].sueldoBruto) +
                        parseFloat(empleado.sueldoBruto);
                }
                return acc;
            }, {});

            console.log(empleados);

        const pagos = Object.keys(empleados).reduce((acc, empleado) => {
            acc.push(empleados[empleado]);
            return acc;
        }, []);
        return (
            <div className={'comprobantes-row ' + params.tipo}>
                <style>{`@page {size: 8.5in 11in;}`}</style>
                <div className="ordernar-resultados no-print">
                    <div className="form-group">
                        <div className="radio-btn">
                            <label>
                                <input type="radio" onChange={this.onChangeOrden} name="orden" value="nombre" defaultChecked={true} /> Ordenar por Nombre
                            </label>
                            <label>
                                <input type="radio" onChange={this.onChangeOrden} name="orden" value="codigo" /> Ordenar por Código
                            </label>
                            <label>
                                <input type="checkbox" onChange={this.onChangeRedondear} name="redondear" value="redondear" /> Redondear
                            </label>
                        </div>
                    </div>
                </div>
                {pagos.sort((a, b) => {
                    if ( orden === 'codigo') {
                        if (!a.codigo) {
                            return 1;
                        }
                        return a.codigo?.localeCompare(b.codigo);
                    }
                    return a.nombre.localeCompare(b.nombre);
                })
                .map((pago) => {
                    return (
                        <p className='comprobante-pago'>
                            {nomina.tipo === "PROD" ? (
                                <div className='text-center'>
                                    <div>
                                        <strong>COMPROBANTE DE PAGO</strong>
                                    </div>
                                    <div>
                                        <small>
                                            Fecha:{" "}
                                            {new Date().toLocaleString()}
                                        </small>
                                    </div>
                                </div>
                            ) : (
                                <div className='text-center'>
                                    <div>
                                        <strong
                                            style={{
                                                textTransform: "uppercase",
                                            }}>
                                            COMPROBANTE DE PAGO{" "}
                                            {nomina.sucursal.nombre}
                                        </strong>
                                    </div>
                                    <div>
                                        <small>
                                            {nomina.sucursal.direccion} •{" "}
                                            {nomina.sucursal.notas}
                                        </small>
                                    </div>
                                    <div>
                                        <small>
                                            Fecha:{" "}
                                            {new Date().toLocaleString()}
                                        </small>
                                    </div>
                                </div>
                            )}
                            <br />
                            <div>
                                <strong>Nombre:</strong> {pago.nombre}
                            </div>
                            <div>
                                <strong>Código:</strong> {pago.codigo}
                            </div>
                            <div>
                                <strong>Cédula:</strong> {pago.cedula}
                            </div>
                            <div>
                                <strong>Periodo:</strong>{" "}
                                {nomina.fechaInicio} al {nomina.fechaCierre}
                            </div>
                            <br />
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <th>Sueldo</th>
                                        <td className='text-right'>
                                            {formatCurrency(
                                                pago.sueldoBruto
                                            )}
                                        </td>
                                    </tr>
                                    {pago.descuentos.length > 0 &&
                                        pago.descuentos.filter(d => d.monto > 0).map((desc) => (
                                            <tr>
                                                <th>{desc.descripcion}</th>
                                                <td className='text-right'>
                                                    -{formatCurrency(
                                                        desc.monto
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    {pago.listadoIncentivos.length > 0 &&
                                        pago.listadoIncentivos
                                            .filter(inc => inc.frecuencia === 'unico')
                                            .map(
                                            (incentivo, index) => (
                                                <tr key={incentivo.id}>
                                                    <th>{incentivo.descripcion}</th>
                                                    <td className='text-right'>
                                                        +{formatCurrency(
                                                            incentivo.monto
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    <tr>
                                        <th>Total</th>
                                        <td className='text-right'>
                                            {redondear ? (
                                                <span>
                                                    {formatCurrency(
                                                        this.redondear(pago.total)
                                                    )}
                                                </span>
                                            ) : (
                                                <span>
                                                    {formatCurrency(
                                                        pago.total
                                                    )}
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <hr />
                            <div className='text-center'>
                                Firma Empleado
                            </div>
                        </p>
                    );
                })}

            </div>
        );
    }
}

export default withRouter(withRequest(ComprobantesDetalle));
