import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
// import { FILTERS_INPUT_TYPES } from "./types";

// COMPONENTS
import PageHeader from "../PageHeader";
import FiltersContainer from "../FiltersContainer";
import { request } from "../../../utils/request";
import Pagination from "../Pagination";
import Loader from "../Loader";
import DesktopTable from "./components/DesktopTable";
// import Select from "react-select";

const IndexPageLayout = (props) => {
	const { pageHeaderProps = {}, filters = {}, dataUrl, tableColumns = [], triggerRequest = 0 } = props;
    const {isLoading, data, getData} = useData(dataUrl);

	useEffect(() => {
		if (triggerRequest > 0) {
			getData(dataUrl);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [triggerRequest]);
	// TODO: Load filters dynamically given an object.
	// const renderFilter = (filter) => {
	// 	switch (filter.type) {
	// 		case FILTERS_INPUT_TYPES.SELECT:
	// 			return (
	// 				<div className={`${filter.classes ? filter.classes : "col-12 col-md-6 col-lg-2"}`}>
	// 					<label>{filter.label}</label>
	// 					<Select
	// 						id={filter.id}
	// 						name={filter.name}
	// 						onChange={filter.onChange}
	// 						options={filter.options}
	// 						placeholder={filter.placeholder}
	// 						defaultValue={filter.defaultValue}
	// 						styles={{
	// 							control: (styles) => ({
	// 								...styles,
	// 								height: "38px",
	// 							}),
	// 							...filter.styles,
	// 						}}
	//                         {...filters.extraProps}
	// 					/>
	// 				</div>
	// 			);

	// 		default:
	// 			break;
	// 	}
	// };

	return (
		<>
			<PageHeader {...pageHeaderProps}>
				{pageHeaderProps.children}
			</PageHeader>
			<FiltersContainer extraFilters={filters.extraFilters}>
				{filters.children}
			</FiltersContainer>
            <div className="row px-3" style={{ overflowX: "auto", paddingBottom: "5rem" }}>
				{isLoading && <Loader />}
				{!isLoading && data.data?.length ? (
					<div
                    className="table-data table-responsive table-row__desktop"
                    style={{ overflowX: "visible" }}
                >
                    <DesktopTable data={data.data} columns={tableColumns} />
                </div>
				) : (
					<div>
						{!isLoading && (
							<div>No hay ningún Record creado.</div>
						)}
					</div>
				)}
				{data.links && (
					<Pagination
						links={data.links}
						meta={data.meta}
						getData={getData}
					/>
				)}
			</div>
		</>
	);
};

IndexPageLayout.propTypes = {
	pageHeaderProps: PropTypes.object,
	filters: PropTypes.object,
};

export default IndexPageLayout;

const useData = (dataUrl) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const getData = async (url) => {
		setIsLoading(true);
        await request(url).then((data) => {
            setData(data);
            setIsLoading(false);
        });
    };


    useEffect(() => {
		if (!dataUrl) {
			setIsLoading(false);
			return;
		}
        getData(dataUrl);
    }, [dataUrl]);

    return {
        isLoading,
        data,
        getData
    };
};
