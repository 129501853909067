import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { debounce } from "lodash";
import queryString from "query-string";
import Select from "react-select";

import Dropdown from "../general/Dropdown";
import { downloadFile, withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../general/Loader";
import { apiUrl } from "../../utils/apiUrl";
import { auth } from "../../utils/auth";
import { getLink, toast, formatCurrency } from "../../utils/helpers";
import dayjs from "dayjs";

class Contactos extends Component {
	state = {
		activo: {},
		contratos: [],
		contrato: {},
		links: null,
		loading: true,
		showCreate: false,
		showEdit: false,
		showSidePopup: false,
		showDelete: false,
		rutasForDropdown: [],
		categoriasForDropdown: [],

		};
	constructor(props) {
		super(props);
		this.sidePopupWrap = React.createRef();
		this.sidePopupOverlay = React.createRef();
	}
	componentDidMount() {

		this.getContactos();
		this.getRutas("rutas?limit=100");
		this.getCategoriasData("terminos-pago?limit=100");
		const params = queryString.parse(window.location.search);
		if (params.id !== undefined) {
			this.onFiltroChange(params.id, "buscar");
			this.setState({
				activo: params.id,
			});
		}
	}
	getContactos = async (url = null) => {

		url = url ? url : "contratos?limit=20";
		const contratos = await this.props.request(url);

		if (contratos.data) {
			this.setState({
				contratos: contratos.data,
				links: contratos.links,
				meta: contratos.meta,
				loading: false,
			});
		}
	};
	onFiltroChange = debounce(async (value, filtro) => {

		await this.setState({
			filtros: {
				...this.state.filtros,
				[filtro]: value,
			},
		});

		let query = Object.keys(this.state.filtros).map((fil) => {
			return `${fil}=${this.state.filtros[fil]}`;
		});

		this.getContactos("contratos?limit=20&" + query.join("&"));
	}, 500);
	toggleCreateModal = () => {
		this.setState({
			showCreate: !this.state.showCreate,
			contrato: {},
		});
	};
	toggleEditModal = (contrato) => {

		this.setState({
			showEdit: !this.state.showEdit,
			contrato: contrato,
		});
	};
	changeItem = (activo) => {
		this.setState({
			activo,
		});
	};

	toggleDeleteModal = () => {
		this.setState((state) => ({ ...state, showDelete: !state.showDelete }));
	};
	getRutas = async (url = null) => {
		url = url ? url + "&limit=20" : "rutas?limit=20";
		const rutas = await this.props.request(url);
		if (rutas.links) {
			this.setState({
				rutasForDropdown: rutas.data.map((ruta) => ({
					value: ruta.id,
					label: ruta.nombre,
				})),
			});
		}
	};
	getCategoriasData = async (url = null) => {
		//url = url ? url + "&limit=15" : "terminos-pago?limit=15";
		url = "categorias-contrato";

		const terminos = await this.props.request(url);

		if (terminos.data) {
			this.setState({
				categoriasForDropdown: terminos.data.map((termino) => ({
					value: termino.id,
					label: termino.nombre,
				})),
			});
		}
	};

		/* const itemActivo = contactos.filter(
			(contacto) => contacto.id === activo
		)[0]; */

	render() {
		const {
			contratos,
			activo,
			loading,
			rutasForDropdown,
			categoriasForDropdown,
		} = this.state;


		const itemActivo = 0;

		const query = Object.keys(this.state.filtros ?? {}).map((fil) => {
			return `${fil}=${this.state.filtros[fil]}`;
		});

		return (
			<div>
				<div className="page-header">
					<h1 className="page-title row">
						<span className="col">Contratos</span>
						<div className='col-md-2'>
                            <label htmlFor='desde' style={{fontSize: '12px', textTransform: 'uppercase'}}>Desde</label>
                            <input
                                type='date'
                                name='desde'
                                id='desde'
                                className='form-control'
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.target.value,
                                        "desde"
                                    )
                                }
                            />
                        </div>
                        <div className='col-md-2'>
                            <label htmlFor='hasta' style={{fontSize: '12px', textTransform: 'uppercase'}}>Hasta</label>
                            <input
                                type='date'
                                name='hasta'
                                id='hasta'
                                className='form-control'
								defaultValue={dayjs().format("YYYY-MM-DD")}
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.target.value,
                                        "hasta"
                                    )
                                }

                            />
                        </div>
						<Link
                            to={getLink(
                                `/imprimir/contratos?limit=1000&${query.join("&")}`
                            )}
                            target='_blank'
                            className='au-btn-filter m-r-15'>
                            <i className='zmdi zmdi-print' /> Imprimir
                        </Link>
						<button
							className="au-btn au-btn-icon au-btn--green au-btn--small"
							onClick={this.toggleCreateModal}
						>
							<i className="zmdi zmdi-plus" />
							Añadir Contrato
						</button>
					</h1>
				</div>
				<div className="page-controls">
					<div className="table-data__tool">
						<div className="table-data__tool-left row">
							<div className="col-md-2">
								<label>Buscar</label>
								<input
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"buscar"
										)
									}
									type="search"
									placeholder="Buscar..."
									className="form-control"
									name="buscar"
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="estado">Frecuencia de Pago</label>
								<Select
									name="frecuencia_pago"
									id="frecuencia_pago"
									onChange={(e) =>
										this.onFiltroChange(
											e.value,
											"frecuencia"
										)
									}
									options={[{value:"todos",label:"Todos"},{value:"mensual",label:"Mensual"},{value:"trimestral",label:"Trimestral"},{value:"semestral",label:"Semestral"},{value:"anual",label:"Anual"}]}
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="estado">
									Estado
								</label>
								<Select
									name="estado"
									id="estado"
									onChange={(e) =>
										this.onFiltroChange(
											e.value,
											"estado"
										)
									}
									options={[{value:"todos",label:"Todos"},{value:"activo", label:"Activos"},{value:"inactivo",label:"Inactivos"}]}
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="estado">Zona</label>
								<Select
									name="ruta"
									id="ruta"
									onChange={(e) =>
										this.onFiltroChange(
											e.value,
											"ruta"
										)
									}
									options={[
										{value:"todos",label:"Todos"},
										...rutasForDropdown.map((ruta) => (
											{value: ruta.value, label: ruta.label}
										))
									]}
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="estado">Tipo</label>
								<Select
									name="tipo"
									id="tipo"
									onChange={(e) =>
										this.onFiltroChange(
											e.value,
											"tipo"
										)
									}
									options={[{value:'todos',label:'Todos'},{value:'interno',label:'Interno'},{value:'externo',label:'Externo'}]}
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="estado">Categoría</label>
								<Select
									name="categoria"
									id="categoria"
									onChange={(e) =>
										this.onFiltroChange(
											e.value,
											"categoria"
										)
									}
									options={[{value:'todos',label:'Todos'},...categoriasForDropdown.map((termino) => (
										{value:termino.label, label:termino.label}
									))]}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div>
							{loading && <Loader />}
							{!contratos.length ? (
								<div>
									{!loading && (
										<div>
											Todavía no hay contratos creados.
										</div>
									)}
								</div>
							) : (
								<div className="table-data">
									<table className="table table-data2">
										<thead>
											<tr>
												<th>Nombre</th>
												<th>Detalle</th>
												<th>Monto</th>
												<th>Inicio</th>
												<th>Fin</th>
												<th>Perfil</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{contratos.map((contrato) => (
												<tr
													onClick={() => this.changeItem(contrato.id)}
													style={{
														cursor: "pointer",
													}}
													className="tr-shadow"
													key={contrato.id}
												>
													<td>
														{(contrato.perfil_contrato == 1) && (contrato.contratoable?.nombre + " " + contrato.contratoable?.apellido )}
														{(contrato.perfil_contrato != 1) && contrato.contratoable?.nombre}
													</td>
													<td>
														<b>Estado:</b> {contrato.activo} <br/>
														{/* Renderizado condicional de frecuencia de pago */}
														{contrato.frecuencia_pago && (<b>Plazo de pago: </b>) }
														{contrato.frecuencia_pago && (contrato.frecuencia_pago)}
														{contrato.frecuencia_pago && (<br/>)}

														{/* Renderizado condicional de tipo de contrato */}
														{contrato.tipo && (<b>Tipo: </b>)}
														{contrato.tipo && (contrato.tipo)}
													</td>
													<td>{formatCurrency(contrato?.monto)}</td>
													<td>{contrato.inicio ? dayjs(contrato.inicio).format("DD-MM-YYYY") : "-"}</td>
													<td>
														{contrato.fin ? dayjs(contrato.fin).format("DD-MM-YYYY") : "-"}
													</td>
													<td>
														{(contrato.perfil_contrato == 1) && "Empleado"}
														{(contrato.perfil_contrato == 2) && "Cliente"}
														{(contrato.perfil_contrato == 3) && "Proveedor"}
													</td>
													<td>
													<Dropdown>
														<button
															onClick={(e) => this.toggleEditModal(contrato)}
															target="_blank"
															className="dropdown-item"
															title="Editar contrato"
														>
															Editar
														</button>
														{contrato.documentos?.length >= 1 && <li className="dropdown-divider"></li>}
														{contrato.documentos?.map(documento => (
															<a onClick={() => {downloadFile(documento.url, documento.nombre ?? `documento_${documento.id}`)}} className="dropdown-item" rel="noreferrer">
																{documento.nombre ?? `documento_${documento.id}`}
															</a>
														))}

													</Dropdown>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									{this.state.links && (
										<Pagination
											links={this.state.links}
											meta={this.state.meta}
											getData={this.getContactos}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</div>

				<Modal
					show={this.state.showCreate}
					blank={true}
					toggle={this.toggleCreateModal}
					size="600px"
				>
					<Create
						toggle={this.toggleCreateModal}
						contacto={this.state.contrato}
						getContactos={this.getContactos}
					/>
				</Modal>
				<Modal
					show={this.state.showEdit}
					blank={true}
					toggle={() => this.toggleEditModal({})}
					size="600px"
				>
					<Edit
						toggle={() => this.toggleEditModal({})}
						contrato={this.state.contrato}
						getContactos={this.getContactos}
					/>
				</Modal>
			</div>
		);
	}
}

export default withRouter(withRequest(Contactos));
