import React, { Component } from "react";
import Select from "react-select";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";

import Loader from "../../general/Loader";
import { toast } from "../../../utils/helpers";
import dayjs from "dayjs";

class Edit extends Component {
    state = {
        miembros: [],
        saving: false,
        errors: {},
    };
    componentDidMount() {
        this.setState({
            miembros: this.props.prestamo.grupo.miembros
        })
    }
    updatePrestamo = async (event, id) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const token = auth.getToken();
        const data = new FormData(event.target);

        const url = `gi-prestamos/${id}`;

        const res = await fetch(apiUrl(url), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.getPrestamos();
            this.props.toggle({});
            toast("Préstamo actualizado");
        } else {
            this.setState({
                errors: response.data.message,
            });
            toast("Por favor revisa todos los campos", "error");
        }

        this.setState({
            saving: false,
        });
    };
    onChangeGrupo = (e) => {
        this.setState({
            miembros: this.props.grupos.filter(g => g.id === e.value)[0].miembros,
        });
    };
    render = () => {
        const { toggle, grupos, miembrosIngresados, prestamo } = this.props;
        const { miembros, saving, errors } = this.state;
        return (
            <div>
                <form onSubmit={(e) => this.updatePrestamo(e, prestamo.id)}>
                    <input type="hidden" name="_method" value="patch" />
                    <input type="hidden" name="semana" value={dayjs().week()} />
                    <input type="hidden" name="desde" value={dayjs().startOf('week').format('YYYY-MM-DD')} />
                    <input type="hidden" name="hasta" value={dayjs().endOf('week').format('YYYY-MM-DD')} />
                    <div className='popup__header'>Editar Préstamo</div>
                    <div className='popup__body'>
                        <div className='form-group'>
                            <label className='label form-control-label'>Grupo</label>
                            <Select
                                name='grupo_id'
                                isClearable={true}
                                defaultValue={
                                    prestamo.grupo?.id && {
                                        isFixed: true,
                                        value: prestamo.grupo?.id,
                                        label: `${prestamo.grupo?.alias} (${prestamo.grupo?.nombreCompleto})`,
                                    }
                                }
                                options={grupos
                                    .map((grupo) => ({
                                        value: grupo.id,
                                        label: `${grupo.alias} (${grupo.nombreCompleto})`,
                                    }))}
                            />
                            {errors.grupo_id && (
                                <small className='help-blockParams form-Text'>
                                    {errors.grupo_id[0]}
                                </small>
                            )}
                        </div>
                        {miembros.length > 0 && (
                            <div className='form-group'>
                                <label className='label form-control-label'>Miembro</label>
                                <Select
                                    name='miembro_id'
                                    isClearable={true}
                                    options={miembros
                                        .filter(m => !miembrosIngresados.includes(m.id))
                                        .map((miembro) => ({
                                            value: miembro.id,
                                            label: `${miembro.nombre} - ${miembro.servidor}`,
                                        }))}
                                />
                                {errors.miembro_id && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.miembro_id[0]}
                                    </small>
                                )}
                            </div>
                        )}
                        <div className='form-group'>
                            <label className='label form-control-label'>Deuda Inicial</label>
                            <input
                                className='form-control'
                                defaultValue={prestamo.deudaInicial}
                                type='number'
                                name='deuda_inicial'
                                step='any'
                            />
                            {errors.deuda_inicial && (
                                <small className='help-blockParams form-Text'>
                                    {errors.deuda_inicial[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            onClick={() => toggle({})}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {saving ? (
                            <Loader type='small' />
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Guardar
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    };
}

export default withRequest(Edit);
