import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import Select from "react-select";

import {
	getLink,
	formatCurrency,
	getZonasForDropdown,
	getVendedoresForDropdown,
	isVendedor,
    getOptionsForDropdown,
} from "../../../utils/helpers";
import CrearOrden from "./Create";
import PageHeader from "../../general/PageHeader";
import Loader from "../../general/Loader";
import Pagination from "../../general/Pagination";
import { request } from "../../../utils/request";
import EditOrden from "./Edit";
import { auth } from "../../../utils/auth";
import FiltersContainer from "../../general/FiltersContainer";
import InvalidScreen from "../../general/InvalidScreen";
import Dropdown from "../../general/Dropdown";

const estados = [
	{ value: "", label: "Todos" },
	{ value: "pendiente", label: "Pendiente" },
	{ value: "facturada", label: "Facturada" },
	// { value: "cancelada", label: "Cancelada" },
];

const renderEstadoBadge = (estado, factura) => {
	if (estado === "pendiente") {
		return (
			<span
				className="badge badge-secondary p-2"
				// style={{ cursor: "pointer" }}
				// onDoubleClick={() => toggleEstado(factura)}
			>
				Pendiente
			</span>
		);
	} else if (estado === "facturada") {
		return (
			<span
				className="badge badge-success p-2"
				// style={{ cursor: "pointer" }}
				// onDoubleClick={() => toggleEstado(factura)}
			>
				Facturada
			</span>
		);
	}

	return <span className="badge badge-danger p-2">Cancelada</span>;
};

const OrdenesCompra = () => {
	// Route  matcher
	let match = useRouteMatch();

	const [filtrosQuery, setFiltrosQuery] = useState("");
	const [filtros, setFiltros] = useState({
		buscar: "",
		vendedor:
			auth.is("vendedor") || auth.is("vendedor-factura-orden_compra") || auth.is("cobrador") || auth.is('vendedor-cliente')
				? auth.getUser().empleado?.id
				: "",
		estado: "",
		ruta: "",
		desde: dayjs().format("YYYY-MM-DD"),
		hasta: dayjs().format("YYYY-MM-DD"),
	});

	const [ordenes, setOrdenes] = useState({});
	const [numeraciones, setNumeraciones] = useState({});
	const [vendedores, setVendedores] = useState([]);
	const [rutas, setRutas] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const getOrdenes = (url) => {
		url = url ? url + "&limit=15" : "ordenes-compra?limit=15";
		setIsLoading(true);

		request(url).then((res) => {
			setOrdenes(res);
			setIsLoading(false);
		});
	};

	const onFilterChange = debounce(async (value, filtro) => {
		await setFiltros((filtros) => ({
			...filtros,
			[filtro]: value instanceof Array ? value.map((v) => v.value).join(",") : value,
		}));
	}, 500);

    const displayFilters = () => {
        document.getElementById('mas-filtros').classList.toggle('d-none');
    }

	useEffect(() => {
		if (filtrosQuery === "") return;
		getOrdenes(`ordenes-compra${filtrosQuery}`);
	}, [filtrosQuery]);

	useEffect(() => {
		let query = Object.keys(filtros).map((fil) => {
			return `${fil}=${filtros[fil]}`;
		});

		setFiltrosQuery("?" + query.join("&"));
	}, [filtros]);

	useEffect(() => {
		if (isVendedor()) {
			const rutas = JSON.parse(localStorage.getItem("rutas"));
			setRutas(rutas);
			return;
		} else {
			getZonasForDropdown().then((data) => {
				setRutas(data);
			});
		}
		if (isVendedor()) return;
		getVendedoresForDropdown().then((data) => {
			setVendedores(data);
		});
        getOptionsForDropdown('numeracion-documentos?tipo_documento=orden_compra').then((data) => {
			setNumeraciones(data);
		});
	}, []);

	if (isVendedor() && !localStorage.getItem("rutas")) {
		return <InvalidScreen message="No hay zonas seleccionadas" />;
	}

	if (auth.is('vendedor-cliente') && !localStorage.getItem("almacenes")) {
		return <InvalidScreen message="No hay almacenes seleccionados" />;
	}
	
	return (
		<>
			<PageHeader title="Ordenes de Compra">
				<Dropdown btnText="Reportes" classes={{dropdown: 'mr-2'}}>
					<Link
						to={getLink(`/imprimir/ordenes-compra${filtrosQuery}`)}
						target="_blank"
						className="dropdown-item"
					>
						Detalles
					</Link>
					<Link
						to={getLink(`/imprimir/resumen-ordenes-compra${filtrosQuery}`)}
						target="_blank"
						className="dropdown-item"
					>
						Resumen
					</Link>
				</Dropdown>
				<Link
					className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15"
					to={`${match.url}/crear`}
				>
					<i className="zmdi zmdi-plus" />
					Añadir Orden
				</Link>
			</PageHeader>
			<FiltersContainer>
				<div className="col">
					<label className="orden-filter-label">Buscar</label>
					<input
						onChange={(e) =>
							onFilterChange(e.target.value, "buscar")
						}
						type="search"
						placeholder="Buscar..."
						className="form-control"
						name="buscar"
					/>
				</div>

				<div className="col-12 col-md-6 col-lg-2">
					<label className="orden-filter-label">Zona</label>
					<Select
						id="ruta"
						options={rutas}
						name="ruta"
						onChange={(e) => onFilterChange(e.value, "ruta")}
						defaultValue={{
							value: "",
							label: "Todos",
						}}
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>

				<div className="col-12 col-md-6 col-lg-2">
					<label className="orden-filter-label">Estado</label>
					<Select
						id="estado"
						options={estados}
						name="estado"
						onChange={(e) => onFilterChange(e.value, "estado")}
						defaultValue={{ value: "", label: "Todos" }}
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>

				{!isVendedor() && (
					<div className="col-12 col-md-6 col-lg-2">
						<label className="orden-filter-label">Vendedor</label>
						<Select
							id="vendedor"
							options={vendedores}
							name="vendedor"
							onChange={(e) =>
								onFilterChange(e.value, "vendedor")
							}
							defaultValue={{
								value: "",
								label: "Todos",
							}}
							styles={{
								control: (styles) => ({
									...styles,
									height: "38px",
								}),
							}}
						/>
					</div>
				)}

				<div className="col-12 col-md-6 col-lg-2">
					<label className="orden-filter-label" htmlFor="desde">
						Desde
					</label>
					<input
						type="date"
						name="desde"
						id="desde"
						defaultValue={dayjs()
							.startOf("day")
							.format("YYYY-MM-DD")}
						className="form-control"
						onChange={(e) =>
							onFilterChange(e.target.value, "desde")
						}
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2">
					<label className="orden-filter-label" htmlFor="hasta">
						Hasta
					</label>
					<input
						type="date"
						name="hasta"
						id="hasta"
						defaultValue={dayjs().endOf("day").format("YYYY-MM-DD")}
						className="form-control"
						onChange={(e) =>
							onFilterChange(e.target.value, "hasta")
						}
					/>
				</div>

                <div style={{width: '50px'}} className="filtros-avanzados" onClick={displayFilters}>
                    <label>&nbsp;</label>
                    <button className="btn" title="Filtros Avanzados"><i className="zmdi zmdi-filter-list"></i></button>
                </div>
                <div id="mas-filtros" className="row col-md-12 mas-filtros d-none m-b-25 m-t-5">
                    <div className="col-12 col-md-6 col-lg-3">
                        <label className="orden-filter-label">Numeración</label>
                        <Select
                            id="numeracion"
                            options={numeraciones}
                            name="numeracion"
                            isMulti
                            isClearable
                            placeholder="Todas"
                            onChange={(values) =>
                                onFilterChange(values, "numeracion")
                            }
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    height: "38px",
                                }),
                            }}
                        />
                    </div>
                </div>
			</FiltersContainer>
			<div
				className="row px-3"
				style={{ overflowX: "auto", paddingBottom: "5rem" }}
			>
				{isLoading && <Loader />}
				{!isLoading && ordenes.data?.length ? (
					<>
						{/* ---TABLE MOBILE--- */}
						<OrdenesListMobile ordenes={ordenes} />

						{/* ---TABLE DESKTOP/--- */}
						<OrdenesListDesktop ordenes={ordenes} />
					</>
				) : (
					<div>
						{!isLoading && <div>No hay ninguna Orden creada.</div>}
					</div>
				)}
				{ordenes.links && (
					<Pagination
						links={ordenes.links}
						meta={ordenes.meta}
						getData={getOrdenes}
					/>
				)}
			</div>
			{/* CREAR FACTURA */}
			<Switch>
				<Route path={`${match.url}/crear`}>
					<CrearOrden />
				</Route>
				<Route path={`${match.url}/:id/edit`}>
					<EditOrden />
				</Route>
			</Switch>
		</>
	);
};

export const OrdenesListMobile = ({ ordenes }) => {
	return (
		<div
			className="table-row__mobile w-100"
			style={{ overflowX: "visible" }}
		>
			{ordenes.data.map((orden, i) => (
				<div className="col-12" key={i}>
					<fieldset
						className="fieldset px-2"
						style={{ border: "2px solid #008aab" }}
					>
						<legend>{orden.numero_comprobante}</legend>
						<div className="row">
							<div className="form-group col-12 mb-1">
								<label className="orden-filter-label d-flex justify-content-between">
									<span>Cliente</span>
									<span
										className={`text-success pr-2 font-weight-normal ${
											orden.estado === "facturada"
												? "d-block"
												: "d-none"
										}`}
										style={{ fontSize: "0.9rem" }}
									>
										Facturada
									</span>
								</label>
								<input
									type="text"
									placeholder="Cliente"
									value={orden.cliente?.nombre}
									className="form-control"
									style={{ backgroundColor: "#fff" }}
									readOnly
								/>
							</div>
						</div>
						<div className="row">
							<div className="form-group col-7 pr-1 mb-1">
								<label className="orden-filter-label">
									Creación
								</label>
								<input
									type="text"
									placeholder="Cliente"
									value={dayjs(orden.created_at).format(
										"DD/MM/YYYY hh:mm a"
									)}
									style={{ backgroundColor: "#fff" }}
									className="form-control"
									readOnly
								/>
							</div>
							<div className="form-group col-5 pl-1 mb-1">
								<label className="orden-filter-label">
									Vencimiento
								</label>
								<input
									type="text"
									className="form-control"
									style={{ backgroundColor: "#fff" }}
									value={dayjs(
										orden.fecha_vencimiento
									).format("DD/MM/YYYY")}
									readOnly
								/>
							</div>
						</div>
						<div className="row">
							<div className="form-group col-7 pr-1 mb-1">
								<label className="orden-filter-label">
									Total
								</label>
								<input
									type="text"
									placeholder="Cliente"
									value={formatCurrency(orden.total, "$")}
									style={{ backgroundColor: "#fff" }}
									className="form-control"
									readOnly
								/>
							</div>
							<div className="form-group col-5 pl-1 mb-1">
								<label>
									<br />
								</label>
								<div className="dropdown">
									<button
										className="au-btn au-btn-icon au-btn-filter w-100"
										style={{
											height: "38px",
											lineHeight: "0px",
											border: "1px solid #FE9D1F",
										}}
									>
										Acciones
									</button>
									<div
										style={{
											zIndex: "99",
										}}
										className="dropdown-menu"
									>
										<Link
											to={getLink(
												`/imprimir/ordenes-compra/${orden.id}`
											)}
											target="_blank"
											className="dropdown-item"
											title="Imprimir"
										>
											Impresión
										</Link>
										{orden.estado === "pendiente" && (
											<Link
												to={getLink(
													`/ingresos/ordenes-compra/${orden.id}/edit`
												)}
												className="dropdown-item"
												title="Editar"
											>
												Editar
											</Link>
										)}
										{!isVendedor() &&
											orden.estado === "pendiente" &&
											orden.tipo === "cliente" && (
												<Link
													className="dropdown-item"
													to={getLink(
														`/ingresos/facturas/crear/${orden.id}`
													)}
													title="Facturar"
													data-original-title="Facturar"
													aria-label="Facturar"
													onClick={() =>
														localStorage.setItem(
															"fromOrden",
															JSON.stringify("si")
														)
													}
												>
													Facturar
												</Link>
											)}
									</div>
								</div>
							</div>
						</div>
					</fieldset>
				</div>
			))}
		</div>
	);
};
export const OrdenesListDesktop = ({ ordenes }) => {
	return (
		<div
			className="table-data table-responsive table-row__desktop"
			style={{ overflowX: "visible" }}
		>
			<table className="table table-data2">
				<thead>
					<tr>
						<th>Numero</th>
						<th>Cliente</th>
						<th>Creación</th>
						<th>Vencimiento</th>
						<th>Total</th>
						{!isVendedor() && <th>Vendedor</th>}
						<th>Zona</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{ordenes.data.map((orden) => {
						return (
							<tr className="tr-shadow" key={orden.id}>
								<td>{orden.numero_comprobante}</td>
								<td>
									{orden.cliente?.nombre}{" "}
									<div>{renderEstadoBadge(orden.estado)}</div>
								</td>
								<td>
									{dayjs(orden.created_at).format(
										"DD/MM/YYYY hh:mm a"
									)}
								</td>
								<td>
									{dayjs(orden.fecha_vencimiento).format(
										"DD/MM/YYYY"
									)}
								</td>
								<td>{formatCurrency(orden.total, "$")}</td>
								{!auth.is("vendedor") && !auth.is("vendedor-factura-orden_compra") && !auth.is("cobrador")
									&& (
										<td>
											{orden.vendedor
												? `${orden.vendedor?.nombre} ${orden.vendedor?.apellido}`
												: ""}
										</td>
									)}
								<td>{orden.ruta?.nombre}</td>
								<td>
									<div className="dropdown">
										<button className="au-btn au-btn-icon au-btn-filter">
											Acciones
										</button>
										<div
											style={{
												left: "-30%",
											}}
											className="dropdown-menu"
										>
											<Link
												to={getLink(
													`/imprimir/ordenes-compra/${orden.id}`
												)}
												target="_blank"
												className="dropdown-item"
												title="Imprimir"
											>
												Impresión
											</Link>
											{orden.estado === "pendiente" && (
												<Link
													to={getLink(
														`/ingresos/ordenes-compra/${orden.id}/edit`
													)}
													className="dropdown-item"
													title="Editar"
												>
													Editar
												</Link>
											)}
											{!isVendedor() &&
												orden.estado === "pendiente" &&
												orden.tipo === "cliente" && (
													<Link
														className="dropdown-item"
														to={getLink(
															`/ingresos/facturas/crear/${orden.id}`
														)}
														title="Facturar"
														data-original-title="Facturar"
														aria-label="Facturar"
														onClick={() =>
															localStorage.setItem(
																"fromOrden",
																JSON.stringify(
																	"si"
																)
															)
														}
													>
														Facturar
													</Link>
												)}
										</div>
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default OrdenesCompra;
