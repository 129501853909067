import React, { Component, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { formatNumber } from "../../utils/helpers";

const GraficoBarras = (props) => {
	const containerStyle = {
		display: "flex",
		flexDirection: "column",
		justifyItem: "center",
	};
	const [state, setState] = useState({
		options: {
			chart: {
				id: "basic-bar",
				type: "bar",
				stacked: true,
				width: "100%",
			},
			plotOptions: {
				bar: {
					horizontal: true,
					barWidth: "85%",
					barHeight: "35",
					dataLabels: {
						position: "center",
						maxItems: 100,
						hideOverflowingLabels: true,
						orientation: "horizontal",
						total: {
							enabled: false,
							formatter: undefined,
							offsetX: 0,
							offsetY: 0,
							style: {
								color: "#373d3f",
								fontSize: "13px",
								fontFamily: undefined,
								fontWeight: 600,
							},
						},
					},
				},
			},
			// title: {
			// 	text: "Compras y Gastos",
			// 	offsetY: 10,
			// 	align: "center",
			// 	style: {
			// 		color: "rgb(55, 61, 63)",
			// 		fontSize: "15px",
			// 		fontWeight: 600,
			// 		fontFamily: "Helvetica, Arial, sans-serif",
			// 	},
			// },
			grid: {
				borderColor: "red",
				show: false,
			},
			legend: {
				showForSingleSeries: true,
				position: "top",
				horizontalAlign: "center",
				fontSize: "15px",
				fontFamily: "Helvetica, Arial, sans-serif",
			},
			tooltip: {
				enabled: true,
			},
			yaxis: {
				show: false,
			},
			tooltip: {
				y: {
					formatter: function (value) {
						return "$" + formatNumber(value.toFixed(2));
					},
				},
			},
			xaxis: {
				labels: {
					formatter: function (value) {
						return "$" + formatNumber(value.toFixed(2));
					},
					padding: {
						top: 0,
						bottom: -2,
					},
				},
			},
			dataLabels: {
				formatter: function (val, opts) {
					return "$" + formatNumber(val.toFixed(2));
				},
			},
			responsive: [
				{
					breakpoint: 575,
					options: {
						chart: {
							width: "100%",
						},
						legend: {
							fontSize: "12px",
						},
						xaxis: {
							labels: {
								formatter: function (value) {
									return "$" + formatNumber(value.toFixed(2));
								},
								padding: 0,
								
								style: {
									fontSize: "7px", // Adjust font size for responsive mode
								},
							},
						},
						yaxis: {
							labels: {
								style: {
									fontSize: "12px", // Adjust font size for responsive mode
								},
							},
						},
					},
				},
			],
		},

		series: [],
	});

	useEffect(() => {
		const series = [];
		const categories = [];

		const dataWithPositiveValues = Object.values(props.data).filter(
			(val) => val.value > 0
		);

		// este es un index para saber cuales elementos del prop data son mayores a 0
		let indexOfDataBiggerThan0 = dataWithPositiveValues.length;

		dataWithPositiveValues.forEach((data) => {
			const newArray = Array(indexOfDataBiggerThan0).fill(0);
			newArray[series.length] = data.value;
			series.push({ name: data.label, data: newArray });
			categories.push(data.label);
		});
		let newChartHeight = Math.min(260,indexOfDataBiggerThan0 * 100, 260); // Ajusta según sea necesario
		if (indexOfDataBiggerThan0 === 1) {
			newChartHeight=160;
		}
		if (indexOfDataBiggerThan0 === 0) {
			newChartHeight=260;
		}

		setState((prevState) => ({
			...prevState,
			series,
			options: {
				...prevState.options,
				xaxis: {
					...prevState.options.xaxis,
					categories,
				},
			},
			chartHeight: newChartHeight,
		}));
	}, [props.data]);

	return (
		<div
			className="col-xl-5 graficoBarras dashboard-tabs-widget"
			style={containerStyle}
		>
			<style>
				{`
    
         	.title{
				position:relative;
				display:flex;
				justify-content:center;
				color: rgb(55, 61, 63);
    			font-size: 15px;
    			font-weight: 600;
    			font-family: Helvetica, Arial, sans-serif;
				padding:10px;
				
			}
           
          `}
			</style>
			<div className="title">Compras y Gastos</div>
				<Chart
					options={{
						...state.options,
						chart: {
							...state.options.chart,
							height: state.chartHeight,
						},
					}}
					series={state.series}
					type="bar"
					height={state.chartHeight}
					width="100%"
				/>
			</div>
	);
};

export default GraficoBarras;
