import React, { Component } from "react";
import Select from "react-select";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { toast } from "../../utils/helpers";
import { withRequest } from "../../utils/request";
import Modal from "../general/Modal";

class Abonar extends Component {
    state = {
        fecha: '',
        monto: '',
        descuentosPendientes: '',
        errors: {},
        descuento: {},
        cuentas: [],
        showDelete: false,
    };
    componentDidMount() {
        this.getTable('cuentas')
    }
    abonarDescuento = async (event) => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(
            apiUrl(`descuentos/${this.props.descuentoId}/abonar`),
            {
                method: "post",
                body: data,
                headers: {
                    Accept: "application/json",
                    "X-Request-With": "XMLHttpRequest",
                    Authorization: "Bearer " + token,
                },
            }
        );

        const response = await res.json();

        if (response.success) {
            this.props.getDescuentos();
            this.props.toggle();
            toast(response.data);
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    getTable = async (url = null, nombre = null) => {
        const table = await this.props.request(url);
        const tableName = nombre ? nombre : url;

        if (table.data) {
            this.setState({
                [tableName]: table.data,
            });
        }
    };
    render() {
        const { errors, cuentas } = this.state;
        const { toggle, descuento, descuentoId } = this.props;

        return (
            <div>
                <form method='post' onSubmit={this.abonarDescuento}>
                    <input type='hidden' name='_method' value='patch' />
                    <div className='popup__header'>Pagar Múltiples Cuotas</div>
                    <div className="popup__body">
                        <div className='row form-group'>
                            <div className='col col-md-6'>
                                <label className='label form-control-label'>
                                    Cantidad de cuotas a pagar:
                                </label>
                            </div>
                            <div className='col col-md-6'>
                                <input type="hidden" name="pendientes" value={descuento.cuotasPendientes?.length} />
                                <input
                                    type='number'
                                    name='cuotas'
                                    step="1"
                                    max={descuento.pendientes}
                                    className='form-control'
                                />
                                {errors.cuotas && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.cuotas[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col col-md-6'>
                                <label className='label form-control-label'>
                                    Cuenta:
                                </label>
                            </div>
                            <div className='col col-md-6'>
                                <Select
                                    name="cuenta_id"
                                    options={cuentas.map( c => ({value: c.id, label: c.nombre}))}
                                />
                                {errors.cuenta_id && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.cuenta_id[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col">
                                <label htmlFor="comentario">Comentario:</label>
                                <textarea name="comentario" rows="4" width="100%" className="form-control"></textarea>
                            </div>
                        </div>
                        <p className="m-b-30 aviso"><strong>Nota:</strong> Las cuotas pagadas pasarán a ser las últimas por lo que las restantes se seguirán descontando de manera regular hasta completar el balance total.</p>
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            onClick={toggle}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        <button type='submit' className='btn btn-primary'>
                            Aplicar Pago
                        </button>
                    </div>
                </form>
                <Modal
                    title='Borrar Descuento'
                    show={this.state.showDelete}
                    callback={() => this.deleteDescuento(descuentoId)}
                    acceptText='Borrar'
                    toggle={this.toggleDeleteModal}>
                    ¿Estás seguro de que deseas borrar este descuento?
                </Modal>
            </div>
        );
    }
}

export default withRequest(Abonar);
