import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatCurrency } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";

export default class ReporteProduccionEmpleados extends Component {
    state = {
        empleados: false,
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const token = auth.getToken();

        const res = await fetch(apiUrl(`reportes` + window.location.search), {
            method: "get",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.setState({
                empleados: response.data,
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { empleados } = this.state;

        if (!empleados) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Detalle de Producción Empleados
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            Empleados: {Object.keys(empleados).length}
                        </div>

                        <div className='float-right'></div>
                    </div>
                </div>
                {Object.keys(empleados).map((empleado, index) => {
                    let totalVentas = 0;
                    let totalDepositos = 0;
                    let totalDolares = 0;
                    let totalViajes = 0;
                    const otrosDesc = empleados[empleado].descuentos.reduce(
                        (acc, desc) => acc + parseFloat(desc.monto),
                        0
                    );
                    return (
                        <div>
                            <h3>
                                {empleados[empleado].codigo} -{" "}
                                {empleados[empleado].nombre}{" "}
                                {empleados[empleado].apellido} (
                                {empleados[empleado].cargo})
                            </h3>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Ficha</th>
                                        <th>Categoría</th>
                                        <th>Zona</th>
                                        <th className='text-center'>Cuadres</th>
                                        <th className='text-right'>Ventas</th>
                                        <th className='text-right'>Depósitos</th>
                                        <th className='text-right'>Dólares</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(empleados[empleado].tarjetas)
                                        .sort((a, b) => {
                                            return (
                                                dayjs(
                                                    empleados[empleado]
                                                        .tarjetas[a].fecha
                                                ).unix() -
                                                dayjs(
                                                    empleados[empleado]
                                                        .tarjetas[b].fecha
                                                ).unix()
                                            );
                                        })
                                        .map((id, index) => {
                                            const tarjeta =
                                                empleados[empleado].tarjetas[
                                                    id
                                                ];
                                            const viajes = 1;
                                            totalVentas += parseInt(tarjeta.ventas);
                                            totalDepositos += parseInt(tarjeta.depositos);
                                            totalDolares += parseInt(tarjeta.dolares);
                                            totalViajes += viajes;
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {dayjs(
                                                            tarjeta.fecha
                                                        ).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>{tarjeta.ficha}</td>
                                                    <td>{tarjeta.categoria}</td>
                                                    <td>{tarjeta.ruta}</td>
                                                    <td className='text-center'>
                                                        {viajes}
                                                    </td>
                                                    <td className='text-right'>
                                                        {" "}
                                                        {formatCurrency(
                                                            tarjeta.ventas
                                                        )}
                                                    </td>
                                                    <td className='text-right'>
                                                        {formatCurrency(
                                                            tarjeta.depositos
                                                        )}
                                                    </td>
                                                    <td className='text-right'>
                                                        {formatCurrency(
                                                            tarjeta.dolares, 'USD$'
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className='text-center'>
                                            {totalViajes}
                                        </td>
                                        <td className='text-right'>
                                            {formatCurrency(totalVentas)}
                                        </td>
                                        <td className='text-right'>
                                            {formatCurrency(totalDepositos)}
                                        </td>
                                        <td className='text-right'>
                                            <div>
                                                {formatCurrency(totalDolares, 'USD$')}
                                            </div>
                                            {otrosDesc > 0 && (
                                                <div>
                                                    Otros Descuentos:{" "}
                                                    {formatCurrency(otrosDesc)}
                                                </div>
                                            )}
                                            {empleados[empleado].informal ? (
                                                <div>
                                                    <strong>
                                                        TOTAL:{" "}
                                                        {formatCurrency(
                                                            totalVentas + totalDepositos
                                                        )}
                                                    </strong>
                                                </div>
                                            ) : (
                                                <div>
                                                    <strong>
                                                        TOTAL:{" "}
                                                        {formatCurrency(
                                                            totalVentas + totalDepositos
                                                        )}
                                                    </strong>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
        );
    }
}
