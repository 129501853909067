import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatNumber, formatCurrency } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";

export default class ReporteMovimientoItems extends Component {
    state = {
        items: false
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const token = auth.getToken();

        const res = await fetch(apiUrl(`reportes` + window.location.search), {
            method: "get",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.setState({
                items: response.data
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { items } = this.state;

        if (!items) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            suc => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Movimiento Items
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'></div>

                        <div className='float-right'>
                            <h3>TOTAL DE ITEMS: {items.length}</h3>
                        </div>
                    </div>
                </div>
                {items.map((item, index) => {
                    let registros = [];

                    item.entradas.map(entrada => {
                        const registro = {
                            id: "e" + entrada.id,
                            fecha: entrada.compra.fecha,
                            nombre: entrada.compra.contacto.nombre,
                            tipo: "entrada",
                            precio: formatCurrency(entrada.costo),
                            cantidad: entrada.cantidad
                        };
                        registros.push(registro);
                        return entrada;
                    });

                    item.salidas.map(salida => {
                        const registro = {
                            id: "s" + salida.id,
                            fecha: salida.fecha,
                            nombre: salida.vehiculo.ficha,
                            tipo: "salida",
                            precio: formatCurrency(salida.precio_unidad),
                            cantidad: formatNumber(salida.cantidad)
                        };
                        registros.push(registro);
                        return salida;
                    });

                    item.ajustes.map(ajuste => {
                        const registro = {
                            id: "a" + ajuste.id,
                            fecha: ajuste.created_at,
                            nombre: `AJUSTE (${ajuste.descripcion})`,
                            tipo:
                                ajuste.incrementar === 1 ? "entrada" : "salida",
                            precio: "N/A",
                            cantidad: formatNumber(ajuste.cantidad)
                        };
                        registros.push(registro);
                        return ajuste;
                    });

                    registros.sort((a, b) => {
                        return dayjs(a.fecha).unix() - dayjs(b.fecha).unix();
                    });

                    return (
                        <div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h3 className='m-b-5'>
                                        {index + 1}. {item.nombre}
                                    </h3>
                                </div>
                                <div className='col-md-2'>
                                    REF: {item.referencia}
                                </div>
                                <div className='col-md-3'>
                                    DISPONIBLE: {formatNumber(item.cantidad)}{" "}
                                    {item.unidad}
                                </div>
                                <div className='col-md-3'>
                                    PRECIO: {formatCurrency(item.costo)}
                                </div>
                                {item.grupo &&
                                    <div className='col-md-2'>
                                        GRUPO: {item.grupo.nombre}
                                    </div>
                                }
                                {item.almacen &&
                                <div className='col-md-2'>
                                    EN: {item.almacen.nombre}
                                </div>
                                }
                            </div>
                            <table className='table m-t-10 m-b-20'>
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Suplidor/Vehículo</th>
                                        <th>Precio</th>
                                        <th className='text-right'>Entrada</th>
                                        <th className='text-right'>Salida</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {registros.map(registro => {
                                        return (
                                            <tr key={registro.id}>
                                                <td>
                                                    {dayjs(
                                                        registro.fecha
                                                    ).format("DD/MM/YYYY")}
                                                </td>
                                                <td>{registro.nombre}</td>
                                                <td>{registro.precio}</td>
                                                {registro.tipo === "entrada" ? (
                                                    <td
                                                        className='text-right'
                                                        style={{
                                                            color: "green"
                                                        }}>
                                                        <strong>
                                                            {formatNumber(
                                                                registro.cantidad
                                                            )}{" "}
                                                            {item.unidad}
                                                        </strong>
                                                    </td>
                                                ) : (
                                                    <td className='text-right'>
                                                        0
                                                    </td>
                                                )}
                                                {registro.tipo === "salida" ? (
                                                    <td
                                                        className='text-right'
                                                        style={{
                                                            color: "red"
                                                        }}>
                                                        <strong>
                                                            {formatNumber(
                                                                registro.cantidad
                                                            )}{" "}
                                                            {item.unidad}
                                                        </strong>
                                                    </td>
                                                ) : (
                                                    <td className='text-right'>
                                                        0
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className='text-right'>
                                            <strong>TOTALES =></strong>
                                        </td>
                                        <td className='text-right'>
                                            <strong style={{ color: "green" }}>
                                                {formatNumber(
                                                    registros.reduce(
                                                        (acc, i) => {
                                                            if (
                                                                i.tipo ===
                                                                "entrada"
                                                            ) {
                                                                return (
                                                                    acc +
                                                                    parseFloat(
                                                                        i.cantidad
                                                                    )
                                                                );
                                                            }

                                                            return acc;
                                                        },
                                                        0
                                                    )
                                                )}{" "}
                                                {item.unidad}
                                            </strong>
                                        </td>
                                        <td className='text-right'>
                                            <strong style={{ color: "red" }}>
                                                {formatNumber(
                                                    registros.reduce(
                                                        (acc, i) => {
                                                            if (
                                                                i.tipo ===
                                                                "salida"
                                                            ) {
                                                                return (
                                                                    acc +
                                                                    parseFloat(
                                                                        i.cantidad
                                                                    )
                                                                );
                                                            }

                                                            return acc;
                                                        },
                                                        0
                                                    )
                                                )}{" "}
                                                {item.unidad}
                                            </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
        );
    }
}
