import React, { Component } from "react";
import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import AmonestacionesForm from "./Form";
import Modal from "../../general/Modal";
import DescuentoItem from "./DescuentoItem";

class Amonestaciones extends Component {
    state = {
        amonestaciones: [],
        showCreate: false,
        loading: true
    };
    componentDidMount() {
        this.getAmonestaciones();
    }
    getAmonestaciones = async (url = null) => {
        const id = this.props.empleado.id;
        url = url ? url : `empleados/${id}/sanciones`;
        const amonestaciones = await this.props.request(url);

        if (amonestaciones.success) {
            this.setState({
                amonestaciones: amonestaciones.data,
                loading: false
            });
        }
    };
    removeAmonestacion = async (event, amonestacion) => {
        event.preventDefault();

        const { empleado } = this.props;
        const token = auth.getToken();

        const url = `empleados/${empleado.id}/sanciones/${amonestacion}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.getAmonestaciones();
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate
        });
    };
    render() {
        const { empleado } = this.props;
        const { amonestaciones, loading } = this.state;

        if (loading === 0) {
            return <Loader />;
        }
        return (
            <div>
                <div className='row m-b-20 descripcion-subpage'>
                    <div className='col'>
                        <h3>Descuentos Programados y Amonestaciones</h3>
                        <p><small>Los descuentos programados solo se aplican en las nóminas que coinciden con la fecha del descuento. Útil para descontar préstamos, amonestaciones y otros descuentos futuros de manera automática.</small></p>
                    </div>
                    <div className='col-md-3 text-right'>
                        <button
                            onClick={this.toggleCreateModal}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'>
                            Nuevo
                        </button>
                    </div>
                </div>
                <div className='table descuentos-table tickets-table'>
                    <div className="descuentos-table-head">
                        <div>Monto</div>
                        <div>Descripción</div>
                        <div className="text-right">Fecha</div>
                        <div></div>
                    </div>
                    <div>
                        {amonestaciones.map(descuento => (
                            <DescuentoItem descuento={descuento} key={descuento.id} />
                        ))}
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <AmonestacionesForm
                        toggle={this.toggleCreateModal}
                        empleadoId={empleado.id}
                        getAmonestaciones={this.getAmonestaciones}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Amonestaciones);
