import React, { useState } from "react";

const Form = ({ item, ajuste, errors }) => {
    if (!ajuste) {
        ajuste = {};
    }
    const [incrementar, setIncrementar] = useState("yes");
    return (
        <div className='popup__body'>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Tipo de Ajuste:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <select
                        type='date'
                        name='incrementar'
                        className='form-control'
                        onChange={e => setIncrementar(e.target.value)}
                        defaultValue={incrementar}>
                        <option value='yes'>Incrementar</option>
                        <option value='no'>Reducir</option>
                    </select>
                </div>
                <input type='hidden' name='item_id' value={item.id} />
            </div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Cantidad:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <input
                        type='number'
                        step='0.01'
                        className='text-input form-control'
                        id='cantidad'
                        name='cantidad'
                        max={incrementar === "no" ? item.cantidad : ""}
                        defaultValue={ajuste.cantidad}
                    />
                    {errors.cantidad && (
                        <small className='help-blockParams form-Text'>
                            {errors.cantidad[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>Motivo:</label>
                </div>
                <div className='col col-md-8'>
                    <textarea
                        type='text'
                        name='descripcion'
                        rows='5'
                        className='form-control'
                        defaultValue={ajuste.descripcion}
                    />
                    {errors.descripcion && (
                        <small className='help-blockParams form-Text'>
                            {errors.descripcion[0]}
                        </small>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Form;
