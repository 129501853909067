import React, { Component } from "react";
import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import Create from "./Create";
import Modal from "../../general/Modal";
import Pagination from "../../general/Pagination";
import dayjs from "dayjs";
import { debounce } from "lodash";

class Ajustes extends Component {
    state = {
        ajustes: [],
        showCreate: false,
        loading: true,
        filtros: [],
        filtrosQuery: '',
    };
    componentDidMount() {
        this.getAjustes();
    }
    getAjustes = async (url = null) => {
        const id = this.props.item.id;
        url = url ? url : `ajustes?item=${id}`;
        const ajustes = await this.props.request(url);

        if (ajustes.data && ajustes.data.length > 0) {
            this.setState({
                ajustes: ajustes.data,
                meta: ajustes.meta,
                links: ajustes.links,
                loading: false,
            });
        }

        this.setState({ loading: false });
    };
    removeAjuste = async (event, ajuste) => {
        event.preventDefault();

        const token = auth.getToken();

        const url = `ajustes/${ajuste}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.getAjustes();
            this.props.getItem();
            return;
        } else {
            this.setState({ errors: response.data.message });
        }

        this.setState({ loading: false });
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        this.getAjustesFiltrados();
    }, 500);

    getAjustesFiltrados = async () => {
        const id = this.props.item.id;
        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getAjustes(`ajustes?item=${id}&` + query.join("&"));
    };
    render() {
        const { item } = this.props;
        const { ajustes, loading, meta, links } = this.state;

        if (loading === true) {
            return <Loader />;
        }
        return (
            <div>
                <div className='table-data__tool'>
                    <div className='table-data__tool-left row'>
                        <div className='col-md-4'>
                            <label htmlFor='desde'>Desde</label>
                            <input
                                type='date'
                                name='desde'
                                id='desde'
                                defaultValue={dayjs()
                                    .startOf("day")
                                    .format("YYYY-MM-DD")}
                                className='form-control'
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.target.value,
                                        "desde"
                                    )
                                }
                            />
                        </div>
                        <div className='col-md-4'>
                            <label htmlFor='hasta'>Hasta</label>
                            <input
                                type='date'
                                name='hasta'
                                id='hasta'
                                defaultValue={dayjs()
                                    .endOf("day")
                                    .format("YYYY-MM-DD")}
                                className='form-control'
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.target.value,
                                        "hasta"
                                    )
                                }
                            />
                        </div>
                        <div className='col-md-2'>
                            <label htmlFor='existencia'>En Inventario</label>
                            <input
                                type='text'
                                id='existencia'
                                value={item.cantidad}
                                className="form-control"
                                readOnly
                            />
                        </div>
                        <div className="col text-right">
                            <button
                                onClick={this.toggleCreateModal}
                                className='au-btn au-btn-icon au-btn--green au-btn--small'>
                                Nuevo Ajuste
                            </button>
                        </div>
                    </div>
                </div>
                {ajustes.length > 0 ? (
                    <table className='table tickets-table'>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Tipo de Ajuste</th>
                                <th>Descripción</th>
                                <th>Cantidad</th>
                                <th>En Inventario</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {ajustes.map((ajuste) => (
                                <tr key={ajuste.id}>
                                    <td>{ajuste.fecha}</td>
                                    <td>
                                        {ajuste.incrementar
                                            ? "Incrementar"
                                            : "Reducir"}
                                    </td>
                                    <td>{ajuste.descripcion}</td>
                                    <td>{ajuste.cantidad}</td>
                                    <td>{ajuste.existencia}</td>
                                    <td>
                                        <button
                                            onClick={(event) =>
                                                this.removeAjuste(
                                                    event,
                                                    ajuste.id
                                                )
                                            }>
                                            <i className='fa fa-trash-alt' />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>No hay ningún ajuste realizado.</div>
                )}

                {meta && (
                    <Pagination
                        links={links}
                        meta={meta}
                        getData={this.getAjustes}
                    />
                )}

                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        item={item}
                        getAjustes={this.getAjustes}
                        getItem={this.props.getItem}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Ajustes);
