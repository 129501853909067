import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import { formatNumber } from "../../../utils/helpers";

export default class TicketList extends Component {
    removeTicket = async (event, ticket) => {
        event.preventDefault();

        const { programacion, tarjeta } = this.props;
        const token = auth.getToken();

        const url = `programaciones/${programacion}/tarjetas/${tarjeta}/tickets/${ticket}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.props.getProgramacion();
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { tickets } = this.props;
        return (
            <div>
                {tickets.length > 0 && (
                    <table className='table tickets-table'>
                        <thead>
                            <tr>
                                <th>Número</th>
                                <th>Hora</th>
                                <th>Peso Bruto</th>
                                <th>Peso Tara</th>
                                <th>Peso neto</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.map(ticket => (
                                <tr key={ticket.numero}>
                                    <td>
                                        {ticket.numero}
                                        {ticket.nota && <small> - {ticket.nota}</small>}
                                    </td>
                                    <td>{ticket.hora}</td>
                                    <td>{formatNumber(ticket.pesoBruto)}</td>
                                    <td>{formatNumber(ticket.pesoTara)}</td>
                                    <td>{formatNumber(ticket.pesoNeto)}</td>
                                    <td>
                                        <button
                                            onClick={event =>
                                                this.removeTicket(
                                                    event,
                                                    ticket.id
                                                )
                                            }>
                                            <i className='fa fa-trash-alt' />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        );
    }
}
