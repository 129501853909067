import React, { Component, useEffect, useRef, useState } from "react";

const FotoField = ({foto: fotoOriginal, name, fotoCed}) => {
    const [foto, setFoto] = useState(fotoOriginal || false);
    const fotoRef = useRef();

    const onChangeFoto = (e) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setFoto(e.target.result);
        };

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        } else {
            setFoto(false);
        }
    };

    const removeFoto = () => {
        fotoRef.current.value = null;
        setFoto(false);
    };

    useEffect(() => {
        setFoto(fotoOriginal);
    }, [fotoOriginal])

    return (
        <div className='foto-container'>
            {!fotoCed && (
                <div>
                    <input
                        name={name}
                        type='file'
                        style={{
                            display: foto ? "none" : "block",
                        }}
                        ref={fotoRef}
                        accept='image/png, image/jpeg'
                        onChange={onChangeFoto}
                    />
                    {foto && <img src={foto} alt='Foto' />}
                </div>
            )}
            {fotoCed && (
                <div>
                    <input type='hidden' name='fotoCed' value={fotoCed} />
                    <img
                        src={`data:image/jpeg;base64,${fotoCed}`}
                        alt={name}
                    />
                </div>
            )}
            {foto && (
                <div>
                    <input
                        type='hidden'
                        name={`${name}`}
                        value={foto ? foto : ""}
                    />
                </div>
            )}
            {foto && (
                <button type='button' onClick={removeFoto}>
                    X
                </button>
            )}
        </div>
    );
}

export default FotoField;
