import React, { useEffect, useState } from "react";

import Loader from "../../general/Loader";
import { toast } from "../../../utils/helpers";
import { request } from "../../../utils/request";
import { apiRequest } from "../../../hooks/apiRequest";
import Form from "./Form";

const Edit = ({empleadoId, getPersonasContacto, toggle, persona}) => {
    const [state, setState] = useState({
        saving: false,
        errors: {}
    });

    const updatePersonaContacto = async event => {
        event.preventDefault();

        setState({
            ...state,
            saving: true
        });

        const data = new FormData(event.target);
        const response = await apiRequest(data, `personas-contacto/${persona.id}?empleado_id=${empleadoId}`);

        if (response.success) {
            getPersonasContacto();
            toggle();
            toast("Persona de contacto actualizada.");
            document.getElementById("personaForm").reset();
            setState({
                ...state,
                saving: false,
            });
        }
        if (response.code === 422) {
            setState({
                ...state,
                errors: response.data.message,
                saving: false,
            });
        }

    };

    return (
        <form
            id='personaForm'
            method='post'
            onSubmit={updatePersonaContacto}>
            <input type='hidden' name='_method' value='patch' />
            <div className='popup__header'>Editar Persona de Contacto</div>
            <div className='popup__body'>
                <Form persona={persona} errors={state.errors}/>
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                {state?.saving ? (
                    <Loader type='small' />
                ) : (
                    <button type='submit' className='btn btn-primary'>
                        Actualizar
                    </button>
                )}
            </div>
        </form>
    );
}

export default Edit;
