import React, { useState } from "react";
import Select from "react-select";

const EntradaItem = ({index, item, contactos, cuentasContables, removeMovimiento, updateCredito, updateDebito, errors}) => {
    const [state, setState] = useState({
        credito: item.credito,
        debito: item.debito,
    });

    const onChangeCredito = async (value) => {
        await setState({
            credito: value,
        });

        updateCredito(item.id, value);
    };

    const onChangeDebito = async (value) => {
        await setState({
            debito: value,
        });

        updateDebito(item.id, value);
    };

    const {debito, credito} = state;

    return (
        <tr>
            <td>
                <Select
                    key={`${index}-contactos`}
                    loadingMessage={() => "Cargando items..."}
                    defaultValue={item.contacto && {
                        label: item.contacto?.nombre,
                        value: item.contacto?.id
                    }}
                    onChange={(e) => {
                        if (!e) e = {};
                        //onChangeInput(e.value);
                    }}
                    name={`movimientos[${index}][contacto_id]`}
                    isClearable={true}
                    options={contactos.map(c => ({label: c.nombre, value: c.id}))}
                />
            </td>
            <td>
                <Select
                    key={`${index}-cuentas-contables`}
                    loadingMessage={() => "Cargando items..."}
                    defaultValue={item.cuentaContable && {
                        label: item.cuentaContable?.nombre,
                        value: item.cuentaContable?.id
                    }}
                    required
                    onChange={(e) => {
                        if (!e) e = {};
                        //onChangeInput(e.value);
                    }}
                    name={`movimientos[${index}][cuenta_contable_id]`}
                    isClearable={true}
                    options={cuentasContables.map(c => ({label: c.nombre, value: c.id}))}
                />
                {errors[`movimientos.${index}.cuenta_contable_id`] && (
                    <small className='help-blockParams form-Text'>
                        {errors[`movimientos.${index}.cuenta_contable_id`][0]}
                    </small>
                )}
            </td>
            <td>
                <textarea name={`movimientos[${index}][descripcion]`} className="form-control" defaultValue={item.descripcion || ''}></textarea>
            </td>
            <td>
                <input
                    type="number"
                    disabled={credito > 0}
                    required={!credito}
                    placeholder="Débito"
                    className="form-control"
                    step='any'
                    name={`movimientos[${index}][debito]`}
                    onChange={e => onChangeDebito(e.target.value)}
                    defaultValue={item.debito || 0}
                />
            </td>
            <td>
                <input
                    type="number"
                    disabled={debito > 0}
                    required={!debito}
                    placeholder="Crédito"
                    className="form-control"
                    step='any'
                    name={`movimientos[${index}][credito]`}
                    onChange={e => onChangeCredito(e.target.value)} defaultValue={item.credito || 0} />
            </td>
            <td>
                {index > 1 && (
                    <button
                        type='button'
                        className='btn'
                        onClick={() => removeMovimiento(item.id)}>
                        <i className='fas fa-trash'></i>
                    </button>
                )}
            </td>
        </tr>
    );
}

export default EntradaItem;
