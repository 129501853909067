import React, { Component } from "react";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import Select from "react-select";
import SelectAsync from "react-select/async";

import { request, withRequest } from "../../utils/request";
import Modal from "../general/Modal";
import Create from "../Empleados/Solicitudes/Create";
import Edit from "../Empleados/Solicitudes/Edit";
import Loader from "../general/Loader";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import Pagination from "../general/Pagination";
import { getLink, provinciasMunicipiosForDropdown, toast } from "../../utils/helpers";
import { apiRequest } from "../../hooks/apiRequest";
import SolicitudItem from "./SolicitudItem";

class Solicitudes extends Component {
    state = {
        solicitudes: [],
        showCreate: false,
        showEdit: false,
        showFilters: false,
        links: [],
        loading: true,
        solicitud: {},
        tPendiente: '',
        tProgreso: '',
        tCompletado: '',
        filtros: {
            limit: 15,
            estado: 'pendiente',
        },
        filtrosQuery: "?estado=pendiente",
    };
    componentDidMount() {
        this.getSolicitudes();
    }
    getSolicitudes = async (url = null) => {

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        url = url ? url + "&limit=15" : "solicitudes?" + query.join('&');
        const solicitudes = await this.props.request(url);

        if (solicitudes.data) {
            this.setState({
                solicitudes: solicitudes.data,
                loading: false,
                links: solicitudes.links,
                meta: solicitudes.meta,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    };
    toggleEditModal = (solicitud) => {
        this.setState({
            showEdit: !this.state.showEdit,
            solicitud,
        });
    };
    updateSolicitud = async (value, solicitud, tiempos = {}) => {
        const token = auth.getToken();

        let data = new FormData();
        data.append("_method", "PATCH");
        data.append("estado", value);

        if (tiempos.pendiente) {
            data.append("t_pendiente", tiempos.pendiente);
        }
        if (tiempos.progreso) {
            data.append("t_progreso", tiempos.progreso);
        }
        if (tiempos.completada) {
            data.append("t_completada", tiempos.completada);
        }

        const res = await fetch(apiUrl(`solicitudes/${solicitud}`), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.getSolicitudes();
            toast("¡Solicitud actualizada!");
        }
    };
    toggleFilters = () => {
        this.setState({
            showFilters: !this.state.showFilters
        })
    }
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getSolicitudes("solicitudes?" + query.join("&"));
    }, 500);
    getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };
    displayFilters = () => {
        document.getElementById('mas-filtros').classList.toggle('d-none');
    }
    
    render() {
        const { loading, solicitudes, solicitud, filtrosQuery } = this.state;
        if (loading) {
            return <Loader />;
        }
        const estados = [
            {label: 'Pendiente', value: 'pendiente'},
            {label: 'En Progreso', value: 'en-progreso'},
            {label: 'Completada', value: 'completada'},
            {label: 'Cancelada', value: 'cancelada'},
        ];

        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Solicitudes</span>

                        <div className='col-md-2'>
                            <label style={{fontSize: '12px', textTransform: 'uppercase'}}>Desde</label>
                            <input
                                type='date'
                                name='desde'
                                id='desde'
                                className='form-control'
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.target.value,
                                        "desde"
                                    )
                                }
                            />
                        </div>
                        <div className='col-md-2'>
                            <label style={{fontSize: '12px', textTransform: 'uppercase'}}>Hasta</label>
                            <input
                                type='date'
                                name='hasta'
                                id='hasta'
                                className='form-control'
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.target.value,
                                        "hasta"
                                    )
                                }
                            />
                        </div>
                        <div className="page-actions">
                            <Link
                                to={getLink(`/imprimir/solicitudes${filtrosQuery}`)}
                                target='_blank'
                                className='au-btn-filter m-r-15'>
                                <i className='zmdi zmdi-print' /> Imprimir
                            </Link>
                            <button
                                className='au-btn au-btn-icon au-btn--green au-btn--small'
                                onClick={this.toggleCreateModal}>
                                <i className='zmdi zmdi-plus' />
                                Nueva Solicitud
                            </button>
                            <button
                                type="button"
                                className='au-btn au-btn-icon m-l-10 m-r-15 btn-filtros au-btn--small'
                                onClick={this.toggleFilters}>
                                <i className='zmdi zmdi-filter-list' />
                            </button>
                        </div>
                    </h1>
                    <div className={`page-controls `+ (this.state.showFilters ? 'active' : '')}>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='buscar'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='provincia'>Provincia</label>
                                    <Select
                                        name='provincia'
                                        isClearable={true}
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e?.value ?? '',
                                                "provincia"
                                            )
                                        }
                                        options={provinciasMunicipiosForDropdown}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Empleado</label>
                                    <SelectAsync
                                        name="empleado_id"
                                        onChange={e => {
                                            this.onFiltroChange(
                                                e?.value ?? '',
                                                "empleado_id"
                                            )
                                        }}
                                        cacheOptions
                                        defaultOptions
                                        isClearable
                                        loadOptions={e => this.getOptions("empleados?estado=activos&buscar="+e, 'nombreCompleto')}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Tipo de Solicitud</label>
                                    <SelectAsync
                                        name="averia"
                                        onChange={e => {
                                            this.onFiltroChange(
                                                e?.value ?? '',
                                                "averia"
                                            )
                                        }}
                                        cacheOptions
                                        defaultOptions
                                        isClearable
                                        loadOptions={e => this.getOptions("taxonomias?relacion=Solicitudes&tipo=Averías&buscar="+e, 'nombre')}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Estado</label>
                                    <Select
                                        name="estado"
                                        onChange={e => {
                                            this.onFiltroChange(
                                                e?.value || '',
                                                "estado"
                                            )
                                        }}
                                        isClearable
                                        defaultValue={
                                            {label: 'Pendiente', value: 'pendiente'}
                                        }
                                        options={[
                                            {label: 'Todas', value: 'todo'},
                                            ...estados
                                        ]}
                                    />
                                </div>
                                <div style={{width: '50px'}} className="filtros-avanzados" onClick={this.displayFilters}>
                                    <label>&nbsp;</label>
                                    <button className="btn" title="Filtros Avanzados"><i className="zmdi zmdi-filter-list"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="mas-filtros" className="row mas-filtros d-none m-b-25">
                        <div className='col-md-3'>
                            <label htmlFor='vehiculo'>Vehículo</label>
                            <SelectAsync
                                name="vehiculo"
                                onChange={e => {
                                    this.onFiltroChange(
                                        e?.value ?? '',
                                        "vehiculo"
                                    )
                                }}
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={e => this.getOptions("vehiculos?buscar="+e, 'ficha')}
                            />
                        </div>
                        <div className='col-md-3'>
                            <label htmlFor='establecimiento'>Establecimiento</label>
                            <SelectAsync
                                name="establecimiento"
                                onChange={e => {
                                    this.onFiltroChange(
                                        e?.value ?? '',
                                        "establecimiento"
                                    )
                                }}
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={e => this.getOptions("taxonomias?relacion=Solicitudes&tipo=Establecimientos&buscar="+e, 'nombre')}
                            />
                        </div>
                        <div className='col-md-3'>
                            <label htmlFor='ruta'>Zona</label>
                            <SelectAsync
                                name="ruta"
                                onChange={e => {
                                    this.onFiltroChange(
                                        e?.value ?? '',
                                        "ruta"
                                    )
                                }}
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={e => this.getOptions("rutas?limit=150&buscar="+e)}
                            />
                        </div>
                        <div className='col-md-3'>
                            <label htmlFor='contacto'>Contacto</label>
                            <SelectAsync
                                name="contacto"
                                onChange={e => {
                                    this.onFiltroChange(
                                        e?.value ?? '',
                                        "contacto"
                                    )
                                }}
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={e => this.getOptions("contactos?limit=150&buscar="+e)}
                            />
                        </div>
                    </div>
                {!solicitudes.length ? (
                    <div>
                        {!loading && <div>No hay solicitudes creadas.</div>}
                    </div>
                ) : (
                    <div>
                        {solicitudes.map((solicitud) => {
                            return (
                                <SolicitudItem
                                    key={solicitud.id}
                                    solicitud={solicitud}
                                    estados={estados}
                                    updateSolicitud={this.updateSolicitud}
                                    toggleEditModal={this.toggleEditModal}
                                />
                            );
                        })}
                        {this.state.links && (
                            <Pagination
                                links={this.state.links}
                                meta={this.state.meta}
                                getData={this.getSolicitudes}
                            />
                        )}
                    </div>
                )}
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        empleado={{}}
                        getSolicitudes={this.getSolicitudes}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={this.toggleEditModal}>
                    <Edit
                        toggle={this.toggleEditModal}
                        empleado={{}}
                        solicitud={solicitud}
                        getSolicitudes={this.getSolicitudes}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Solicitudes);
