import React, { useState } from "react";
import { apiRequest } from "../../../hooks/apiRequest";
import { toast } from "../../../utils/helpers";
import Loader from "../../general/Loader";

const Form = ({tipo, cancel, success}) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const onsubmit = (event) => {
        event.preventDefault();

        setErrors({});
        setLoading(true);

        const data = new FormData(event.target);
        let url = "tipo-ausencia";
        if (tipo.id) {
            data.append("_method", "put");

            url += `/${tipo.id}`;
        }

        apiRequest(data, url, "POST").then((response) => {
            if (response.success) {
                toast(`Tipo Ausencia ${tipo.id ? "actualizada" : "creada"}.` , "success");
                success(response.data);
            } else if (response.code === 422) {
                setErrors(response.data.message);
            } else {
                toast(response.data.message, "error");
            }
    
            setLoading(false);
        });
    };

    return (
        <form onSubmit={onsubmit}>
            <div className='popup__header'>{tipo.id ? "Editar" : "Nuevo"} Tipo de Ausencia</div>

            <div className='popup__body'>
                <div>
                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>Nombre:</label>
                        </div>
                        <div className='col col-md-7'>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='nombre'
                                name='nombre'
                                defaultValue={tipo?.nombre}
                            />
                            {errors.nombre && (
                                <small className='help-blockParams form-Text'>
                                    {errors.nombre[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            { loading
                ? <Loader />  
                : (<div className='popup__footer'>
                    <button
                        type='button'
                        onClick={cancel}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        {tipo.id ? "Actualizar" : "Crear"}
                    </button>
                </div>)
            }
        </form>
    );
};

export default Form;