import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { request } from "../../utils/request";
import { auth } from "../../utils/auth";
import { formatCurrency, getModalidadAusencia } from "../../utils/helpers";
import dayjs from "dayjs";

const useData = () => {
    const [ausencias, setAusencias] = useState([]);

    const location = useLocation();

    const getAusencias = useCallback(() => {
		const searchString = location.search === "" ? "?paginate=100000" : location.search + "&paginate=1000000";

		request(`ausencias${searchString}`).then((res) => {
			setAusencias(res.data);
		});
	}, [location]);

    useEffect(() => {
        getAusencias();
    }, [getAusencias]);

    useEffect(() => {
		if (ausencias.length === 0) return;

		const timeout = setTimeout(() => {
			window.print();
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [ausencias]);

    return useMemo(
		() => ({
			ausencias,
		}),
		[ausencias]
	);
};

const Print = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
    const { ausencias } = useData();

    const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];

    return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Ausencias
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
			</div>
			<div className="row mt-5 ml-2">
				<table className="table m-b-15">
					<thead>
						<tr>
							<th>Empleado</th>
							<th>Tipo</th>
							<th>Modalidad</th>
							<th>Desde</th>
							<th>Hasta</th>
							<th>Días</th>
							<th>Sábado</th>
							<th>Pagar</th>
							<th>Descripción</th>
							<th>Desc.</th>
							<th>Estado</th>
						</tr>
					</thead>

					<tbody>
						{ausencias.map((ausencia) => {
							return (
								<tr key={ausencia.id}>
									<td>{ausencia.empleado?.nombreCompleto}</td>
									<td>{ausencia.tipo?.nombre}</td>
									<td>{getModalidadAusencia(ausencia.modalidad)?.label}</td>
									<td>{dayjs(ausencia.desde).format("DD/MM/YYYY")}</td>
									<td>{dayjs(ausencia.hasta).format("DD/MM/YYYY")}</td>
									<td className="text-center">T-{ausencia.cantidad_dia}<br/>P-{ausencia.restantes?.toString()}</td>
									<td>{ausencia.contar_sabado ? "Contar" : "No Contar"}</td>
									<td>{formatCurrency(ausencia.monto_pagar)}</td>
									<td style={{maxWidth: '256px', padding: '5px', overflowWrap: 'break-word'}}>{ausencia.descripcion}</td>
									<td className="text-center">{ausencia.descontar_vacaciones ? "Si" : "No"}</td>
									<td style={{textTransform: 'capitalize'}}>{ausencia.estado}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			<style type="text/css" media="print">
				@page {"{ size: landscape; }"}
			</style>
        </>
    );
};

export default Print;
