import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Select from "react-select";
import SelectAsync from "react-select/async";

import { request } from "../../../../utils/request";
import Pagination from "../../../general/Pagination";
import Modal from "../../../general/Modal";
import Create from "./Create";
import EditInscripcion from "./Edit";
import Loader from "../../../general/Loader";
import { formatCurrency, getLink, getOptionsForDropdown } from "../../../../utils/helpers";
import { debounce } from "lodash";
import Dropdown from "../../../general/Dropdown";

const InscripcionCocurriculares = () => {
    const [inscripciones, setInscripciones] = useState([]);
    const [inscripcion, setInscripcion] = useState({});
    const [estudiantes, setEstudiantes] = useState([]);
    const [becas, setBecas] = useState([]);
    const [clases, setClases] = useState([]);
    const [links, setLinks] = useState([]);
    const [meta, setMeta] = useState([]);
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filtros, setFiltros] = useState({});
    const [filtrosQuery, setFiltrosQuery] = useState('');
    const [cuentasSeleccionadas, setCuentasSeleccionadas] = useState([]);
	const [showCuentasModal, setShowCuentasModal] = useState(false);
    const [seleccion, setSeleccion] = useState({});

    useEffect(() => {
        getInscripciones();
        getTable('academico/becas', 'becas');
        getTable('academico/cocurriculares', 'clases');
    }, []);

    const getTable = async (url, name = null) => {
        request(url).then(({data}) => {
            if (data) {
                if (name === 'becas') {
                    setBecas(data);
                }
                if (name === 'clases') {
                    setClases(data);
                }
                setLoading(false);
            }
        });
    };

    const getInscripciones = async (url = null) => {
        url = url ? url + "&limit=15" : "academico/inscripciones-cocurriculares?limit=20";
        request(url).then(inscripciones => {
            if (inscripciones.success) {
                setInscripciones(inscripciones.data.data);
                setLinks(inscripciones.data.links);
                setMeta(inscripciones.data.meta);
                setLoading(false);
            }
        });
    };
    const toggleCreateModal = () => {
        setShowCreate(prev => !prev);
        setInscripcion({});
    };
    const toggleEditModal = (inscripcion) => {
        setInscripcion(inscripcion);
        setShowEdit(prev => !prev);
    };
    const onFiltroChange = debounce((value, filtro) => {
        const filters = {
            ...filtros,
            [filtro]: encodeURIComponent(value),
        };

        setFiltros(filters);
        let query = new URLSearchParams(filters);
        setFiltrosQuery(query);
        getInscripciones("academico/inscripciones-cocurriculares?" + query.toString());
    }, 500);

    return (
        <div>
            <div className='page-header'>
                <h1 className='page-title row'>
                    <span className='col'>Inscripciones Cocurriculares</span>
                    <Link
                        to={getLink(
                            `/imprimir/academico/inscripciones-cocurriculares?${filtrosQuery}`
                        )}
                        target="_blank"
                        className='au-btn au-btn-icon au-btn-filter m-r-20'>
                        <i className='zmdi zmdi-print'></i> Imprimir
                    </Link>
                    <button
                        className='au-btn au-btn-icon au-btn--green au-btn--small'
                        onClick={toggleCreateModal}>
                        <i className='zmdi zmdi-plus' />
                        Inscribir
                    </button>
                </h1>
                <div className='page-controls'>
                    <div className='table-data__tool'>
                        <div className='table-data__tool-left row'>
                            <div className='col'>
                                <label htmlFor='search'>Buscar</label>
                                <input
                                    onChange={(e) =>
                                        onFiltroChange(
                                            e.target.value,
                                            "buscar"
                                        )
                                    }
                                    type='search'
                                    placeholder='Buscar...'
                                    className='form-control'
                                    name='buscar'
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='estado'>Año</label>
                                <SelectAsync
                                    key="ano"
                                    name='ano'
                                    isClearable
                                    cacheOptions
                                    defaultOptions
                                    placeholder='Seleccionar...'
                                    onChange={(e) =>{
                                        onFiltroChange(
                                            e?.value || "",
                                            "ano"
                                        )
                                    }}
                                    loadOptions={(e) => getOptionsForDropdown("academico/periodos-academicos?buscar="+e, 'nombre', 'nombre', true)}
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='estado'>Clase</label>
                                <Select
                                    key="cocurricular_id"
                                    name='cocurricular_id'
                                    isClearable
                                    placeholder='Seleccionar...'
                                    menuPosition='fixed'
                                    menuPlacement='auto'
                                    onChange={(e) =>
                                        onFiltroChange(
                                            e?.value || "",
                                            "cocurricular"
                                        )
                                    }
                                    options={clases && [
                                        {label: "Todas", value: ""},
                                        ...clases.map(c => ({
                                        label: c.nombre,
                                        value: c.id,
                                    }))]}
                                />
                            </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Tipo de Contacto</label>
                                    <Select
                                        key="tipo_contacto"
                                        name='tipo_contacto'
                                        isClearable
                                        placeholder='Seleccionar...'
                                        menuPosition='fixed'
                                        menuPlacement='auto'
                                        onChange={(e) =>
                                            onFiltroChange(
                                                e?.value || "",
                                                "tipo_contacto"
                                            )
                                        }
                                        options={clases && [
                                            {label: "Todos", value: ""},
                                            {label: "Estudiante", value: "estudiante"},
                                            {label: "Cliente", value: "cliente"},
                                        ]}
                                    />
                                </div>
                            <div className='col-md-2'>
                                <label htmlFor='estado'>Beca</label>
                                <Select
                                    name='beca'
                                    id='beca'
                                    placeholder="Seleccionar..."
                                    onChange={(e) =>
                                        onFiltroChange(
                                            e?.value || "",
                                            "beca"
                                        )
                                    }
                                    options={[
                                        {label: "Todas", value: ""},
                                        ...becas.map(b => {
                                        return {label: `${b.nombre}`, value: b.id}
                                    })]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div>
                        {loading && <Loader />}
                        {!loading && inscripciones.length === 0 && (
                            <div>
                                {!loading && (
                                    <div>
                                        No hay ninguna inscripición
                                        creada.
                                    </div>
                                )}
                            </div>
                        )}
                        {!loading && inscripciones.length > 0 && (
                            <div className='table-data'>
                                <table className='table table-data2'>
                                    <thead>
                                        <tr>
                                            <th>Contacto</th>
                                            <th>Fecha</th>
                                            <th>Clase</th>
                                            <th>Año</th>
                                            <th>Pendiente</th>
                                            <th>Balance</th>
                                            <th>Beca</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inscripciones.map((inscrip) => (
                                            <tr
                                                className='tr-shadow'
                                                key={inscrip.id}>
                                                <td>
                                                    <strong>{inscrip.contacto?.nombre}</strong>
                                                    <div>
                                                        <small className="uppercase">{inscrip.tipo}</small>
                                                    </div>
                                                </td>
                                                <td>{dayjs(inscrip.fecha).format('DD-MM-YYYY')}</td>
                                                <td>{inscrip.clase?.nombre}</td>
                                                <td>{inscrip.ano}</td>
                                                <td>{formatCurrency(inscrip.pendiente)}</td>
                                                <td>{formatCurrency(inscrip.balance)}</td>
                                                <td>{inscrip.beca?.nombre}</td>
                                                <td>
                                                    <div className='table-data-feature'>
                                                        <button
                                                            className='item'
                                                            data-toggle='tooltip'
                                                            data-placement='top'
                                                            title=''
                                                            data-original-title='Edit'
                                                            onClick={() => {
                                                                toggleEditModal(
                                                                    inscrip
                                                                );
                                                            }}>
                                                            <i className='zmdi zmdi-edit' />
                                                        </button>
                                                        <Dropdown>
                                                            <button
                                                                className="dropdown-item"
                                                                title="Historial de Pagos"
                                                                onClick={() => {
                                                                    setSeleccion(inscrip);
                                                                    setCuentasSeleccionadas(
                                                                        inscrip.servicios
                                                                    );
                                                                    setShowCuentasModal(
                                                                        (state) => !state
                                                                    );
                                                                }}
                                                            >
                                                                Historial de Pagos
                                                            </button>
                                                            <Link className="dropdown-item" to={getLink(`/academico/cocurriculares?contacto=${inscrip.contacto?.id}&cocurricular=${inscrip.clase?.id}`)} target="_blank">
                                                                Cobros
                                                            </Link>
                                                        </Dropdown>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {links && (
                                    <Pagination
                                        links={links}
                                        meta={meta}
                                        getData={getInscripciones}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                show={showCreate}
                blank={true}
                toggle={toggleCreateModal}>
                <Create
                    toggle={toggleCreateModal}
                    inscripcion={inscripcion}
                    estudiantes={estudiantes}
                    becas={becas}
                    clases={clases}
                    getInscripciones={getInscripciones}
                />
            </Modal>
            <Modal
                show={showEdit}
                blank={true}
                toggle={() => toggleEditModal({})}>
                <EditInscripcion
                    toggle={() => toggleEditModal({})}
                    inscripcion={inscripcion}
                    estudiantes={estudiantes}
                    becas={becas}
                    clases={clases}
                    getInscripciones={getInscripciones}
                />
            </Modal>
            <Modal
				title="Historial de Pagos"
				show={showCuentasModal}
				toggle={() => {
					setShowCuentasModal((state) => !state);
				}}
                showCancelBtn={false}
                acceptText={"Cerrar"}
				size="1024px"
			>
				<h5 className="row text-uppercase text-secondary fw-600">
					<div className="col-md-6">
                        <small>Contacto:</small>
                        <h3>{seleccion?.contacto?.nombre}</h3>
                    </div>
					<div className="col-md-6 text-right">
                        <small>Clase:</small>
                        <h3>{seleccion?.clase?.nombre}</h3>
                    </div>
				</h5>
				<table className="table table-fixed">
					<tbody>
						<tr>
							<td style={{ fontWeight: "600" }}>
								Fecha de pago:
							</td>
							<td style={{ fontWeight: "600" }}>Pago:</td>
							<td style={{ fontWeight: "600" }}>Cuota:</td>
							{/* <td style={{ fontWeight: "600" }}>Pendiente:</td> */}
							<td style={{ fontWeight: "600" }}>Abono:</td>
							<td style={{ fontWeight: "600" }}>Balance:</td>
						</tr>
						{seleccion.pagos?.map((pago, i) => {
                            return(<>
                                {pago.recibos?.map( recibo => (
                                    <tr key={recibo.id}>
                                        <td>
                                            {dayjs(recibo.fecha).format("DD-MM-YYYY")}
                                        </td>
                                        <td>
                                            {pago.nombre}
                                            {recibo.estado === 'cancelado' && (
                                                <small className="badge badge-danger text-uppercase m-l-10">Cancelado</small>
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(pago.monto)}
                                        </td>
                                        {/* <td>
                                            {formatCurrency(recibo.servicios[0]?.pivot?.pendiente)}
                                        </td> */}
                                        <td>
                                            {recibo.estado === 'cancelado' ? (
                                                <del>{formatCurrency(recibo.pivot?.abonado)}</del>
                                            ) : (
                                                formatCurrency(recibo.pivot?.abonado)
                                            )}
                                        </td>
                                        <td>
                                            {recibo.estado === 'cancelado' ? (
                                                formatCurrency(parseFloat(recibo.pivot?.pendiente))
                                            ) : (
                                                formatCurrency(parseFloat(recibo.pivot?.pendiente) - parseFloat(recibo.pivot?.abonado))
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </>)
						})}
					</tbody>
				</table>
			</Modal>
        </div>
    );
}

export default InscripcionCocurriculares;
