import React, { Component } from "react";
import { debounce } from "lodash";
import { Link, Route, withRouter } from "react-router-dom";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../general/Loader";
import { getLink } from "../../utils/helpers";

class Rutas extends Component {
    state = {
        rutas: [],
        ruta: {},
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        loading: true,
        filtros: {
            buscar: "",
        },
    };
    componentDidMount() {
        this.getRutas();
    }
    getRutas = async (url = null) => {
        url = url ? url + "&limit=20" : "rutas?limit=20";
        const rutas = await this.props.request(url);
        if (rutas.links) {
            this.setState({
                rutas: rutas.data,
                links: rutas.links,
                meta: rutas.meta,
                loading: false,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            ruta: { operadores: [] },
        });
    };
    toggleEditModal = (ruta) => {
        this.setState({
            showEdit: !this.state.showEdit,
            ruta: ruta,
        });
        this.props.history.push(getLink("/rutas"));
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        this.getRutas("rutas?limit=20&" + query.join("&"));
    }, 500);
    render() {
        const { rutas, loading } = this.state;
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Todas las Zonas</span>
                        <Link
                            to={getLink(`/imprimir/rutas?limit=200`)}
                            target='_blank'
                            className='au-btn-filter m-r-15'>
                            <i className='zmdi zmdi-print' /> Imprimir
                        </Link>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Zona
                        </button>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col-md-12'>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!rutas.length ? (
                                <div>
                                    {!loading && (
                                        <div>No hay zonas creadas.</div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Descripción</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rutas.map((ruta) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={ruta.id}>
                                                    <td>{ruta.nombre}</td>
                                                    <td>{ruta.descripcion}</td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <Link
                                                                to={getLink(
                                                                    `/rutas/${ruta.id}`
                                                                )}
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Edit'
                                                                onClick={() => {
                                                                    this.toggleEditModal(
                                                                        ruta
                                                                    );
                                                                }}>
                                                                <i className='zmdi zmdi-edit' />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getRutas}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Route
                    path='/:sucursal/rutas/:id'
                    render={(props) => (
                        <Modal show blank toggle={this.toggleEditModal}>
                            <Edit
                                toggle={this.toggleEditModal}
                                ruta={
                                    rutas.filter(
                                        (r) =>
                                            r.id ===
                                            parseInt(props.match.params.id)
                                    )[0] || {}
                                }
                                getRutas={this.getRutas}
                            />
                        </Modal>
                    )}
                />
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        ruta={this.state.ruta}
                        getRutas={this.getRutas}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(Rutas));
