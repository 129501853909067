import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";

const Form = ({ termino, errors }) => {
	const [form, setForm] = useState({
		id: "",
		nombre: "",
		dias: 0,
        status: "activo",
	});

	const handleFormInputChange = (input, inputChanged) => {
		setForm((form) => ({
			...form,
			[inputChanged]: input,
		}));
	};

	useLayoutEffect(() => {
		if(Object.keys(termino).length === 0) return;

		setForm({
			id: termino.id || "",
			nombre: termino.nombre || "",
			dias: termino.dias || 0,
			status: termino.status || "activo",
		});
	}, [termino]);
	return (
		<div className="popup__body">
			<input type="hidden" name="id" value={form.id} />
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">Nombre:</label>
				</div>
				<div className="col col-md-8">
					<input
						type="text"
						name="nombre"
						onChange={(e) =>
							handleFormInputChange(e.target.value, "nombre")
						}
						value={form.nombre}
						className="form-control"
						readOnly={form.is_default}
					/>
					{errors.nombre && (
						<small className="help-blockParams form-Text">
							{errors.nombre[0]}
						</small>
					)}
				</div>
			</div>
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">
						Dias:
					</label>
				</div>
				<div className="col col-md-8">
					<input
						type="number"
						name="dias"
						onChange={(e) =>
							handleFormInputChange(
								e.target.value,
								"dias"
							)
						}
						value={form.dias}
						className="form-control"
					/>
					{errors.dias && (
						<small className="help-blockParams form-Text">
							{errors.dias[0]}
						</small>
					)}
				</div>
			</div>
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">Estado:</label>
				</div>
				<div className="col col-md-8">
					<div className="form-inline">
						<div className="radio">
							<label className="mr-3">
								<input
									className="mr-1"
									type="radio"
									name="status"
									id="optionsRadiosActivo"
									value="activo"
									checked={form.status == "activo"}
									onChange={(e) =>
										handleFormInputChange(
											e.target.value,
											"status"
										)
									}
								/>
								Activo
							</label>
						</div>
						<div className="radio">
							<label>
								<input
									className="mr-1"
									type="radio"
									name="status"
									id="optionsRadiosActivo"
									value="inactivo"
									checked={form.status == "inactivo"}
									onChange={(e) =>
										handleFormInputChange(
											e.target.value,
											"status"
										)
									}
								/>
								Inactivo
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Form.propTypes = {
	errors: PropTypes.object,
	termino: PropTypes.object,
};

export default Form;
