import React from "react";
import Select from "react-select";

export default function Form({miembro, servidores, errors}){
    return <div>
        <div className='row form-group'>
            <div className='col col-md-4'>
                <label className='label form-control-label'>
                    Nombre:
                </label>
            </div>
            <div className='col col-md-8'>
                <input
                    type='text'
                    name='nombre'
                    rows='5'
                    defaultValue={miembro.nombre}
                    className='form-control'
                />
                {errors.nombre && (
                    <small className='help-blockParams form-Text'>
                        {errors.nombre[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-4'>
                <label className='label form-control-label'>
                    Estado:
                </label>
            </div>
            <div className='col col-md-8'>
                <select
                    className='text-input form-control'
                    id='estado'
                    name='estado'
                    defaultValue={miembro.estado}>
                    <option value='activo'>Activo</option>
                    <option value='inactivo'>Inactivo</option>
                </select>
                {errors.estado && (
                    <small className='help-blockParams form-Text'>
                        {errors.estado_civil[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-4'>
                <label className='label form-control-label'>
                    Servidor:
                </label>
            </div>
            <div className='col col-md-8'>
                <Select
                    name='servidor_id'
                    isClearable={true}
                    defaultValue={
                        miembro.servidor && {
                            isFixed: true,
                            value: miembro.servidorId,
                            label: miembro.servidor
                        }
                    }
                    options={servidores.map(servidor => ({
                        value: servidor.id,
                        label: servidor.nombre
                    }))}
                />
                {errors.servidor && (
                    <small className='help-blockParams form-Text'>
                        {errors.servidor[0]}
                    </small>
                )}
            </div>
        </div>
    </div>
}
