import React, { Component } from "react";
import Select from 'react-select';

class Form extends Component {
    onChangeInput = (e, name) => {
        this.setState({
            [name]: e.target.value
        });
    }
    render() {
        const { cuenta, errors } = this.props;

        return (
            <div>
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Nombre:
                        </label>
                    </div>
                    <div className='col col-md-7'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre'
                            name='nombre'
                            readOnly={cuenta.defecto}
                            defaultValue={cuenta.nombre}
                        />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Número:
                        </label>
                    </div>
                    <div className='col col-md-7'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='numero'
                            name='numero'
                            defaultValue={cuenta.numero}
                        />
                        {errors.numero && (
                            <small className='help-blockParams form-Text'>
                                {errors.numero[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Naturaleza:
                        </label>
                    </div>
                    <div className='col col-md-7'>
                        {cuenta.defecto ? (
                            <input
                                type='text'
                                readOnly
                                className='text-input form-control'
                                value={cuenta.naturaleza}
                            />
                        ) : (
                            <Select
                                name="naturaleza"
                                key="naturaleza"
                                defaultValue={cuenta.naturaleza && {label: cuenta.naturaleza, value: cuenta.naturaleza}}
                                options={[
                                    {label: "Acreedora", value: "Acreedora"},
                                    {label: "Deudora", value: "Deudora"}
                                ]}
                            />
                        )}
                        {errors.naturaleza && (
                            <small className='help-blockParams form-Text'>
                                {errors.naturaleza[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Descripción:
                        </label>
                    </div>
                    <div className='col col-md-7'>
                        <textarea name="descripcion" defaultValue={cuenta.descripcion} className="form-control"></textarea>
                        {errors.descripcion && (
                            <small className='help-blockParams form-Text'>
                                {errors.descripcion[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
