import React, { useEffect } from 'react';
import Loader from '../../general/Loader';
import Modal from '../../general/Modal';
import FormModalidades from './Forms/FormModalidades';
import { useConfiguracionAcademica } from './ConfiguracionAcademicaContext';

const Modalidades = () => {
    const {items, item, onFiltroChange, toggleCreateModal, toggleEditModal, toggleDeleteModal, filtrosQuery, loading, errors, getData, create, edit, deleteItem, clasesEstadoMapping, saving, showEdit, showDelete, showCreate} = useConfiguracionAcademica();

    const url = "academico/modalidades";
    useEffect(() => {
        getData(`${url}${filtrosQuery}`);
    }, [filtrosQuery]);

    return (<>
        <div className="row m-b-20 descripcion-subpage">
            <div className="col">
                <input
                    type="text"
                    className='form-control'
                    name="buscar"
                    onChange={(e) =>
                        onFiltroChange(e.target.value, "buscar")
                    }
                    placeholder='Buscar...'
                />
            </div>
            <div className="col-md-3 text-right">
                <button className="au-btn au-btn-icon au-btn--green au-btn--small" onClick={() => toggleCreateModal()}>+ Nueva Modalidad</button>
            </div>
        </div>
        <hr />
        {loading && <Loader />}
        {!loading && items.length > 0 && (<div className="table-data">
            <table className='table table-data2'>
                <thead>
                    <tr>
                        <th>Modalidad</th>
                        <th className='text-center'>Estado</th>
                        <th className='text-right'></th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item => (
                        <tr key={item.id} className="tr-shadow">
                            <td>{item.nombre}</td>
                            <td className='text-center'>
                                <span className={`badge badge-${clasesEstadoMapping[item.estado]} text-uppercase`}>{item.estado}</span>
                            </td>
                            <td className='text-right'>
                                <div className="table-data-feature">
                                    <button
                                        className="item"
                                        onClick={() => toggleEditModal(item)}
                                    >
                                        <i className="zmdi zmdi-edit" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )))}
                </tbody>
            </table>
        </div>)}

        {!loading && items.length === 0 && (
            <div className='text-center p-t-20 p-b-40'>{ filtrosQuery !== '?' ? "No hay elementos que coincidan con la búsqueda." : "No hay elementos creados." }</div>
        )}

        <Modal
            blank
            show={showCreate}
            toggle={() => toggleCreateModal()}>
            <form method='post' onSubmit={e => create(e, url, "Modalidad Creada.") }>
                <input type="hidden" name="_method" value="POST" />
                <div className='popup__header'>Crear Nueva Modalidad</div>
                <div className='popup__body'>
                    <FormModalidades
                        item={{}}
                        errors={errors}
                    />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={() => toggleCreateModal()}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving ? (<Loader type="small" />) : (<button type='submit' className='btn btn-primary'>
                        Crear
                    </button>)}
                </div>
            </form>
        </Modal>
        <Modal
            blank
            show={showEdit}
            toggle={toggleEditModal}>
            <form method='post' onSubmit={e => edit(e, url, "Modalidad actualizada.") }>
                <input type="hidden" name="_method" value="PATCH" />
                <div className='popup__header'>Editar Modalidad</div>
                <div className='popup__body'>
                    <FormModalidades
                        item={item}
                        errors={errors}
                    />
                </div>
                <div className='popup__footer'>
                    {!saving && (
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={toggleDeleteModal}>
                        <i className='fas fa-trash-alt'></i>
                    </button>)}
                    <button
                        type='button'
                        onClick={() => toggleEditModal()}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving ? (<Loader type="small" />) : (<button type='submit' className='btn btn-primary'>
                        Actualizar
                    </button>)}
                </div>
            </form>
        </Modal>
        <Modal
            title='Borrar Modalidad'
            show={showDelete}
            callback={() => deleteItem(url, "Modalidad borrada.")}
            acceptText="Borrar"
            toggle={toggleDeleteModal}>
            ¿Seguro que deseas borrar {item.nombre}?
        </Modal>
    </>)
}

export default Modalidades;
