/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import queryString from "query-string";

import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";
import { request } from "../../../utils/request";

const params = queryString.parse(window.location.search);

const PrintResumenCuentasPorPagar = () => {
	const [cuentas, setCuentas] = useState({});
	const [sumatorias, setSumatorias] = useState({});
    const [sample, setSample] = useState({});
	const [totalCxC, setTotalCxC] = useState(0);
	const [cantidadCxC, setCantidadCxC] = useState(0);

	const getCuentas = () => {
		request(
			"cuentas-por-pagar" +
				window.location.search +
				"&paginate=100000&sortBy=cliente"
		).then((res) => {
			processCuentasData(res.data);
            setSample(res.data[0]);
			setTimeout(() => {
				window.print();
			}, 1500);
		});
	};

	const processCuentasData = (data) => {
        console.log({data});
		const cuentasSegmentadas = {};
		const sumatoriasSegmentadas = {};
		let totalCxCCurrent = 0;

		data.forEach((cuenta) => {
			totalCxCCurrent += parseFloat(cuenta?.porPagar);
			if (
				cuenta.contacto &&
				!cuentasSegmentadas.hasOwnProperty(cuenta.contacto)
			) {
				cuentasSegmentadas[cuenta.contacto] = [cuenta];
				sumatoriasSegmentadas[cuenta.contacto] = parseFloat(
					cuenta?.porPagar
				);
			} else if (cuentasSegmentadas.hasOwnProperty(cuenta.contacto)) {
				cuentasSegmentadas[cuenta.contacto].push(cuenta);
				sumatoriasSegmentadas[cuenta.contacto] += parseFloat(
					cuenta?.porPagar
				);
			}
		});
		setCantidadCxC(data.length);
		setTotalCxC(totalCxCCurrent);
		setSumatorias(sumatoriasSegmentadas);
		setCuentas(cuentasSegmentadas);
	};

	useEffect(() => {
		getCuentas();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	// const valorTotal = cuentas.reduce((acc, factura) => {
	// 	return acc + parseFloat(factura.por_cobrar);
	// }, 0);

	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
                {sucursal.nombre}
				</h2>
                <div className="col-md-12 text-center uppercase"><strong>Resumen Cuentas Por Pagar</strong></div>
				<div className="col-md-12 text-center">
                    {sucursal.direccion} •{" "}
					{new Date().toLocaleString()}
				</div>
                <div className="col-md-12 p-t-5 p-b-10 text-center">
                    {Object.keys(params).map(k => {
                        switch (k) {
                            case 'categoria':
                                return `${k.toUpperCase()}: "${sample.categorias?.length && sample.categorias[0].nombre}"`;
                            case 'zona':
                                return `${k.toUpperCase()}: "${sample.ruta?.nombre}"`;
                            case 'vendedor':
                                return `${k.toUpperCase()}: "${sample.vendedor?.nombre} ${sample.vendedor?.apellido}"`;
                            default:
                                return `${k.toUpperCase()}: "${params[k]}"`;
                        }
                    }).join(' • ')}
                </div>
				<div className="col-md-12 text-right p-b-10" style={{fontSize: 'larger'}}>
					<div className="float-left">
						TOTAL CUENTAS POR COBRAR: {cantidadCxC}
					</div>

					<div className="float-right">
						<h3>BALANCE TOTAL: {formatCurrency(totalCxC)}</h3>
					</div>
				</div>
			</div>
			{Object.values(cuentas).map((cuentaMap) => (
				<>
					<table className="table mb-3" style={{fontSize: 'larger'}}>
						<thead>
							<tr>
								<th>NCF/Numero</th>
								<th>Tipo</th>
								<th>Suplidor</th>
								<th width="165px">Fecha</th>
								<th className="text-right" width="165px">
									Vencimiento
								</th>
                                <th className="text-right">Monto</th>
								<th className="text-right">Balance</th>
							</tr>
						</thead>
						<tbody>
							{cuentaMap.map((cuenta) => {
								return (
									<tr key={cuenta.id}>
										<td>{cuenta.ncf}{cuenta.numero && '/' + cuenta.numero}</td>
										<td className="text-capitalize">
											{cuenta.tipo}
										</td>
										<td>{cuenta.contacto}</td>
										<td>
											{dayjs(cuenta.fecha).format(
												"DD/MM/YYYY"
											)}{" "}
											<span>
												<div>
													{dayjs()
														.startOf("day")
														.diff(
															dayjs(
																cuenta.fecha
															).startOf("day"),
															"day"
														)}{" "}
													días transcurridos
												</div>
											</span>
										</td>
										<td className="text-right">
											{dayjs(
												cuenta.fechaVencimiento
											).format("DD/MM/YYYY")}{" "}
											<div>
												{cuenta.plazo?.dias || 0} Días
											</div>
										</td>
										<td className="text-right">
											{formatCurrency(cuenta.total)}
										</td>
										<td className="text-right">
											{formatCurrency(cuenta.porPagar)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="row m-b-40">
						<div className="col-md-12 text-right" style={{fontSize: 'larger'}}>
							<div className="float-left">
								TOTAL CUENTAS POR PAGAR: {cuentaMap.length}
							</div>

							<div className="float-right">
								<h3>
									BALANCE TOTAL:{" "}
									{formatCurrency(
										sumatorias[
											cuentaMap[0]?.contacto
										]
									)}
								</h3>
							</div>
						</div>
					</div>
				</>
			))}
		</>
	);
};

export default PrintResumenCuentasPorPagar;
