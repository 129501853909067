/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Select, {createFilter} from "react-select";
import {
	formatCurrency,
	isVendedor,
	money,
} from "../../../../utils/helpers";
import PropTypes from "prop-types";

import ItemFotos from "../../../Items/ItemFotos";
import Modal from "../../../general/Modal";
import { auth } from "../../../../utils/auth";
import useProducto from "../../Hooks/useProducto";

const ProductosOrdenMobile = ({
	producto,
	updateTotal,
	productos,
	index,
	removeProducto,
	precioLimite,
	banderaContacto,
	impuestosForDropdown,
	facturaProductos,
	isEdit,
	tipoOrden,
	contacto
}) => {
    const {
        productoInfo,
        productLabel,
        total,
        totalReal,
        preciosVenta,
        precioManual,
        pinSeguridad,
        productoITBIS,
        checkPINSeguridad,
        onChangeInput,
        onChangeImpuesto,
        onChangeProducto,
        onChangeCostoManual,
        handlePrecioVentaChange,
        setProductoInfo,
        setPrecioManual,
        setPinSeguridad,
        setShowFotos,
        setShowPrecioCompra,
        toggleFotosModal,
        toggleDeleteModal,
        togglePINModal,
        showPrecioManual,
        showFotos,
        showDelete,
        showPINModal,
        showPrecioCompra,
        volverAlSelector,
    } = useProducto(producto, productos, facturaProductos, updateTotal, index, impuestosForDropdown, banderaContacto, precioLimite, contacto, isEdit, true);

	if (!productos) {
		return <></>;
	}

	return (
		<fieldset
			className="fieldset my-2"
			style={{ border: "2px solid #008aab" }}
		>
			<legend>Producto {index + 1}</legend>
			<div className="col-12 px-0">
				<input
					type="hidden"
					name={`productos[${index}][id]`}
					value={producto.id}
				/>
				<div className="form-group mb-1">
					<label className="orden-filter-label">Descripción</label>
					{tipoOrden === "interna" ? (
						<input
							type="text"
							name={`productos[${index}][nombre_producto]`}
							className="form-control"
							value={productoInfo.nombre_producto}
							onChange={(e) =>
								onChangeInput("nombre_producto", e.target.value)
							}
						/>
					) : (
						<Select
							type="text"
							name={`productos[${index}][producto_id]`}
							placeholder={"Seleccione..."}
                            filterOption={createFilter({ignoreAccents: false})}
							value={
								productoInfo.id && {
									value: productoInfo.id,
									label: productoInfo.nombre,
								}
							}
							onChange={(e) => onChangeProducto(e.value)}
							styles={{
								singleValue: (provided, state) => ({
									...provided,
									width: "120%",
									display: "flex",
								}),
								option: (provided, state) => ({
									...provided,
									backgroundColor: state.isSelected
										? "rgba(152, 204, 245,.3)"
										: "white",
									color: state.isSelected
										? "#008aab"
										: "#666666",
									fontWeight: state.isSelected
										? "600"
										: "normal",
								}),
							}}
							maxMenuHeight={200}
							options={
								productos && contacto &&
								productos.map((producto) => ({
									label: `${producto.referencia} ${producto.nombre}`,
									value: producto.id,
									producto: producto,
								}))
							}
							formatOptionLabel={(option) =>
								option.producto
									? productLabel(option.producto)
									: option.label
							}
						/>
					)}
				</div>
			</div>
			<div className="row">
				<div className="form-group col-6 pr-1 mb-1">
					<label className="orden-filter-label">Cantidad</label>
					<input
						type="number"
						name={`productos[${index}][cantidad]`}
						placeholder="Cantidad"
						value={productoInfo.cantidad || undefined}
						step="1"
						min="0"
						onChange={(e) => {
							producto.cantidad = parseFloat(e.target.value || 0);
							onChangeInput("cantidad", e.target.value);
						}}
						className="form-control"
					/>
				</div>
				<div className="form-group col-6 pl-1 mb-1">
					<label className="orden-filter-label">Unidad</label>
					<input
						type="text"
						placeholder="Und."
						value={productoInfo.unidad}
						className="form-control"
						style={{ backgroundColor: "#fff" }}
						readOnly
					/>
				</div>
			</div>
			<div className="row">
				<div className="form-group col-6 pr-1 mb-1">
					<label className="orden-filter-label">Importe</label>
					<input
						type="text"
						className="form-control"
						style={{ backgroundColor: "#fff" }}
						value={formatCurrency(money(productoInfo.costo), "")}
						readOnly
					/>
					<input
						type="hidden"
						name={`productos[${index}][importe]`}
						className="form-control"
						value={productoInfo.costo}
						readOnly
					/>
				</div>
				<div className="form-group col-6 pl-1 mb-1">
					<label
						className="orden-filter-label"
						htmlFor="inputVendedor"
					>
						Impuesto
					</label>
					{/* !isVendedor() */ false  ? (
						<Select
							type="text"
							name={`productos[${index}][impuesto_id]`}
							placeholder="Impuesto"
							onChange={(e) => onChangeImpuesto(e.value)}
							value={productoInfo.impuesto}
							options={impuestosForDropdown}
						/>
					) : (
						<>
							<input
								className="form-control"
								value={productoInfo.impuesto?.label}
							/>
							<input
								type="hidden"
								name={`productos[${index}][impuesto_id]`}
								value={productoInfo.impuesto?.value}
							/>
						</>
					)}
				</div>
			</div>
			<div className="row">
				<div className="form-group col-8 pr-1 mb-1">
					<label className="orden-filter-label">Precio</label>
					{tipoOrden === "interna" ? (
						<input
							type="number"
							className="form-control"
							name={`productos[${index}][costo]`}
							value={productoInfo.costo}
							onChange={(e) =>
								onChangeInput("costo", e.target.value)
							}
						/>
					) : !showPrecioManual ? (
						<Select
							type="text"
							name={`productos[${index}][costo]`}
							placeholder={"Seleccione..."}
							isLoading={productos[0] === false}
							loadingMessage={() => "Cargando"}
							onChange={(e) => handlePrecioVentaChange(e)}
							maxMenuHeight={200}
							options={
								[
									...preciosVenta(),
									{ value: "manual", label: "Manual" },
								] || []
							}
							value={productoInfo?.precio_venta}
						/>
					) : (
						<>
							<input
                                type="number"
                                className="form-control"
                                id={`costo-${index}`}
                                name={`productos[${index}][costo]`}
                                value={precioManual}
                                onChange={(e) => {
                                    const svalue = e.target.value;
                                    setPrecioManual(svalue)
                                    setProductoInfo(prev => {
                                        return {
                                            ...prev,
                                            precio_manual: svalue
                                        }
                                    })
                                    onChangeCostoManual(svalue)
                                }}
                                onBlur={(e) => {
                                    onChangeCostoManual(e.target.value, true)
                                }}
                            />
							<small
								className="text-primary form-Text"
								style={{ cursor: "pointer" }}
								onClick={() => {
                                    volverAlSelector();
                                }}
							>
								Volver a selector
							</small>
						</>
					)}
				</div>
				<div className="form-group col-4 pl-1 mb-1">
					<label
						className="orden-filter-label"
						htmlFor="inputVendedor"
					>
						Descuento
					</label>
                    <input type="hidden" name={`productos[${index}][descuento]`} value="0" />
					<input
						type="number"
						min="0"
						name={`productos[${index}][descuento]`}
						placeholder="Descuento"
						value={productoInfo.descuento}
						step="0.1"
						onChange={(e) =>
							onChangeInput("descuento", e.target.value)
						}
						className="form-control"
					/>
				</div>
			</div>
			{productoInfo.fotos?.length > 0 && (
				<button
					type="button"
					className="btn btn-primary btn-block mb-2"
					style={{ backgroundColor: "#008aab" }}
					title="Ver Fotos"
					onClick={() => toggleFotosModal()}
				>
					Ver fotos
				</button>
			)}

			<div className="row px-3 justify-content-between align-items-center">
				<div className="form-group mb-0">
					{!showPrecioManual ? (
						<input
							type="hidden"
							name={`productos[${index}][precio_facturado]`}
							value={productoInfo.costo + productoITBIS}
						/>
					) : (
						<input
							type="hidden"
							name={`productos[${index}][precio_facturado]`}
							value={precioManual}
						/>
					)}
					<input
						type="hidden"
						name={`productos[${index}][total]`}
						value={total}
					/>
					<label className="orden-filter-label">Total</label>
					{formatCurrency(productoInfo.descuento > 0 ? totalReal : total)}
				</div>
				{auth.is("vendedor-admin") && productoInfo.id && (
						<div className="d-inline-block text-center">
							{!showPrecioCompra ? (
								<button
									type="button"
									title="Ver Precio compra"
									onClick={() => setShowPrecioCompra(true)}
									className="producto"
								>
									<i className="fas fa-eye"></i>
								</button>
							) : (
								<p
									onClick={() => setShowPrecioCompra(false)}
									style={{ cursor: "pointer" }}
								>
									{formatCurrency(
										productoInfo.precioCompra
									)}
								</p>
							)}
						</div>
					)}
				{/* <div className="d-inline-block text-center">
					{productoInfo.fotos?.length > 0 && (
						<button
							type="button"
							title="Ver Fotos"
							onClick={() => toggleFotosModal()}
							className="producto"
						>
							<i className="fas fa-eye"></i>
						</button>
					)}
				</div> */}
				<div className="text-right">
					<button
						type="button"
						className="btn btn-danger"
						onClick={() =>
							isEdit
								? toggleDeleteModal()
								: removeProducto(producto.id)
						}
					>
						<i className="fas fa-trash"></i>
					</button>
				</div>
			</div>
			{productoInfo?.fotos && (
				<Modal
					show={showFotos}
					blank={true}
					toggle={() => setShowFotos((state) => !state)}
				>
					<ItemFotos
						toggle={() => setShowFotos((state) => !state)}
						fotos={productoInfo.fotos}
					/>
				</Modal>
			)}
			<Modal
				title="Borrar Producto"
				show={showDelete}
				callback={() => removeProducto(producto.id)}
				acceptText="Borrar"
				toggle={toggleDeleteModal}
			>
				¿Estás seguro de que deseas borrar este producto?
			</Modal>
			{/* <---PIN DE SEGURIDAD MODAL--- */}
			<Modal
				title="PIN de seguridad"
				show={showPINModal}
				callback={() => checkPINSeguridad()}
				acceptText="Aceptar"
				toggle={() => togglePINModal()}
				size="419px"
			>
				<div className="form-group">
					<label>PIN de seguridad</label>
					<input
						type="password"
						className="form-control"
						id="PIN"
						name="pin_seguridad"
						value={pinSeguridad}
						onChange={(e) => setPinSeguridad(e.target.value)}
					/>
					<p
						className="font-weight-bold"
						style={{
							color: "gray",
							fontStyle: "italic",
							lineHeight: "13px",
							fontSize: "0.7rem",
						}}
					>
						Para continuar ingrese PIN de seguridad
					</p>
				</div>
			</Modal>
		</fieldset>
	);
};

ProductosOrdenMobile.propTypes = {
	producto: PropTypes.object,
	updateTotal: PropTypes.func,
	productos: PropTypes.array,
	index: PropTypes.number,
	removeProducto: PropTypes.func,
	precioLimite: PropTypes.number,
	impuestosForDropdown: PropTypes.array,
	ordenProductos: PropTypes.array,
	isEdit: PropTypes.bool,
	tipoOrden: PropTypes.string,
};

export default ProductosOrdenMobile;
