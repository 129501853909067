import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { Link } from 'react-router-dom';
import { getLink } from '../../../../utils/helpers';
import { useEstudianteContext } from '../EstudianteContext';
import { apiRequest } from '../../../../hooks/apiRequest';

export default function RelacionadoItem({estudiante, relacionado: relacionadoOriginal, setRelacionados, relacionados, removeRelacionado}) {

    const [relacionado, setRelacionado] = useState(relacionadoOriginal || {});
    const [seleccionados, setSeleccionados] = useState(relacionados.map(t => t.idOriginal) || []);

    useEffect(() => {
        setSeleccionados(relacionados.map(t => t.idOriginal || t.id));
    }, [relacionados]);

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data
                .filter(r => !seleccionados.includes(r.id) && r.id !== estudiante.id)
                .map(item => ({value: item[value], label: item[label], item}));
        }
    };

    return (
        <tr>
            <td>
                <div className="row">
                    <SelectAsync
                        key={`${relacionado.id}-estudiante`}
                        className="col"
                        cacheOptions
                        defaultOptions
                        onChange={(e) => {
                            if (e) {
                                setRelacionado(e.item)
                                setRelacionados(prev => prev.map(r => {
                                    if (r.id === relacionadoOriginal.id) {
                                        r.idOriginal = e.value;
                                    }
                                    return r;
                                }));
                            } else {
                                setRelacionado({})
                            }
                        }}
                        name={`relacionados[${relacionado.id}][estudiante_id]`}
                        defaultValue={relacionado.nombreCompleto && {
                            label: relacionado.nombreCompleto, value: relacionado.id
                        }}
                        loadOptions={(e) => {
                            return getOptions(`academico/estudiantes?buscar=${e}&limit=30
                            `, "nombreCompleto");
                        }}
                    />
                </div>
            </td>
            <td>
                <Select
                    key={`${relacionado.id}-parentesco`}
                    name={`relacionados[${relacionado.id}][parentesco]`}
                    defaultValue={relacionado.parentesco && {
                        label: relacionado.parentesco, value: relacionado.parentesco
                    }}
                    options={[
                        {label: 'Hermano(a)', value: 'Hermano(a)'},
                        {label: 'Primo(a)', value: 'Primo(a)'},
                        {label: 'Hermanastro(a)', value: 'Hermanastro(a)'},
                    ]}
                />
            </td>
                <td>
                    {Boolean(relacionado.idOriginal) && (
                        <Link to={getLink('/academico/estudiantes/' + relacionado.idOriginal + '/informacion')} target="_blank">Ver Perfil</Link>
                    )}
                </td>
            <td className="text-right">
                <button
                    type="button"
                    className="btn"
                    onClick={() => removeRelacionado(relacionadoOriginal.id)}>
                    <i className='fa fa-trash-alt' />
                </button>
            </td>
        </tr>
    );
}
