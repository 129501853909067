import React, { Component } from "react";
import { debounce } from "lodash";
import dayjs from "dayjs";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Loader from "../general/Loader";
import { formatCurrency, getLink, toast } from "../../utils/helpers";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { Link } from "react-router-dom";

class GastosGenerales extends Component {
    state = {
        gastos: [],
        gasto: {},
        categorias: [],
        links: null,
        loading: true,
        showCreate: false,
        showEdit: false,
        showSidePopup: false,
        filtrosQuery: "",
    };
    constructor(props) {
        super(props);
        this.sidePopupWrap = React.createRef();
        this.sidePopupOverlay = React.createRef();
    }
    componentDidMount() {
        this.getGastos();
        this.getTable("categoria-gastos", "categorias");
    }
    getGastos = async (url = null) => {
        url = url ? url : "gastos-generales?limit=20";
        const gastos = await this.props.request(url);

        if (gastos.data) {
            this.setState({
                gastos: gastos.data,
                meta: gastos.meta,
                links: gastos.links,
                loading: false,
            });
        }
    };
    getTable = async (url = null, nombre = null) => {
        const table = await this.props.request(url);
        const tableName = nombre ? nombre : url;

        if (table.data) {
            this.setState({
                [tableName]: table.data,
                loading: false,
            });
        }
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getGastos("gastos-generales?limit=20&" + query.join("&"));
    }, 500);
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            gasto: {},
        });
    };
    toggleEditModal = (gasto) => {
        this.setState({
            showEdit: !this.state.showEdit,
            gasto: gasto,
        });
    };
    changeItem = (activo) => {
        this.setState({
            activo,
        });
        this.toggleSidePopup(true);
    };
    deleteGasto = async (event, gasto) => {
        event.preventDefault();

        const token = auth.getToken();

        const url = `gastos-generales/${gasto}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.getGastos();
            toast("Gasto Borrado.");
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const {
            gastos,
            categorias,
            showSidePopup,
            activo,
            loading,
            filtrosQuery,
        } = this.state;

        const itemActivo = gastos.filter((gasto) => gasto.id === activo)[0];

        return (
            <div>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Gastos Generales</span>
                        <Link
                            to={getLink(
                                `/imprimir/gastos-generales${filtrosQuery}`
                            )}
                            target='_blank'
                            className='au-btn-filter m-r-15'>
                            <i className='zmdi zmdi-print' /> Imprimir
                        </Link>
                        <Link
                            className='au-btn au-btn-icon au-btn--green au-btn--small m-r-15'
                            to={getLink("/gastos-generales/crear")}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Gastos
                        </Link>
                    </h1>
                </div>
                <div className='page-controls'>
                    <div className='table-data__tool'>
                        <div className='table-data__tool-left row'>
                            <div className='col'>
                                <label>Buscar</label>
                                <input
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "buscar"
                                        )
                                    }
                                    type='search'
                                    placeholder='Buscar...'
                                    className='form-control'
                                    name='buscar'
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='desde'>Categoría</label>
                                <select
                                    name='categoria'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "categoria"
                                        )
                                    }
                                    className='form-control'>
                                    <option value='todas'>Todas</option>
                                    {categorias.map((cat) => (
                                        <option value={cat.id}>
                                            {cat.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='desde'>Desde</label>
                                <input
                                    type='date'
                                    name='desde'
                                    id='desde'
                                    defaultValue={dayjs()
                                        .startOf("day")
                                        .format("YYYY-MM-DD")}
                                    className='form-control'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "desde"
                                        )
                                    }
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='hasta'>Hasta</label>
                                <input
                                    type='date'
                                    name='hasta'
                                    id='hasta'
                                    defaultValue={dayjs()
                                        .endOf("day")
                                        .format("YYYY-MM-DD")}
                                    className='form-control'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "hasta"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!gastos.length ? (
                                <div>
                                    {!loading && (
                                        <div className='no-results'>
                                            Todavía no hay gastos creados en el
                                            rango de fechas seleccionado.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Descripción</th>
                                                <th>Cantidad</th>
                                                <th>Categoría</th>
                                                <th>Cuenta</th>
                                                <th>Monto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gastos.map((gasto) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={gasto.id}>
                                                    <td>
                                                        {dayjs(
                                                            gasto.fecha
                                                        ).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>
                                                        {gasto.item ? (
                                                            <div
                                                                style={{
                                                                    lineHeight: 1.2,
                                                                }}>
                                                                <div>
                                                                    {
                                                                        gasto
                                                                            .item
                                                                            .nombre
                                                                    }
                                                                </div>
                                                                <small>
                                                                    {
                                                                        gasto.descripcion
                                                                    }
                                                                </small>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {
                                                                    gasto.descripcion
                                                                }
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>{gasto.cantidad}</td>
                                                    <td>{gasto.categoria}</td>
                                                    <td>{gasto.cuenta}</td>
                                                    <td>
                                                        {formatCurrency(
                                                            gasto.cantidad *
                                                                gasto.precio
                                                        )}
                                                    </td>
                                                    <td className='text-right'>
                                                        <form
                                                            onSubmit={(e) =>
                                                                this.deleteGasto(
                                                                    e,
                                                                    gasto.id
                                                                )
                                                            }>
                                                            <button
                                                                type='submit'
                                                                className='btn btn-danger btn-sm'>
                                                                <i className='fa fa-trash-alt'></i>
                                                            </button>
                                                        </form>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getGastos}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {showSidePopup && (
                    <div className='side-popup'>
                        <div
                            onClick={() => this.toggleSidePopup(false)}
                            ref={this.sidePopupOverlay}
                            className='side-popup-background animated fadeIn'></div>
                        <div
                            ref={this.sidePopupWrap}
                            className='side-popup-container animated slideInRight fast'>
                            <h2>
                                {itemActivo.nombre}{" "}
                                <button
                                    className='btn btn-primary float-right gasto'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title=''
                                    data-original-title='Edit'
                                    onClick={() => {
                                        this.toggleEditModal(itemActivo);
                                    }}>
                                    <i className='zmdi zmdi-edit' />
                                </button>
                            </h2>
                            <br />
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td>Encargado</td>
                                        <td>{itemActivo.encargado}</td>
                                    </tr>
                                    <tr>
                                        <td>Tipo</td>
                                        <td>{itemActivo.tipo}</td>
                                    </tr>
                                    <tr>
                                        <td>Teléfono</td>
                                        <td>{itemActivo.telefono}</td>
                                    </tr>
                                    <tr>
                                        <td>Celular</td>
                                        <td>{itemActivo.celular}</td>
                                    </tr>
                                    <tr>
                                        <td>Cédula/RNC</td>
                                        <td>{itemActivo.rnc}</td>
                                    </tr>
                                    <tr>
                                        <td>Dirección</td>
                                        <td>{itemActivo.direccion}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withRequest(GastosGenerales);
