import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { getLink, toast } from "../../../utils/helpers";
import Form from "./Form";
import Loader from "../../general/Loader";
import Modal from "../../general/Modal";
import CreateModal from "../Tutores/CreateModal";
import EditModal from "../Tutores/EditModal";
import { EstudianteContextProvider, useEstudianteContext } from "./EstudianteContext";
import { apiRequest } from "../../../hooks/apiRequest";
import HistorialPagos from "./HistorialPagos";

const Edit = () => {
    return (
        <EstudianteContextProvider>
            <EditEstudiante />
        </EstudianteContextProvider>
    );
}
const EditEstudiante = () => {

    const { estudiante, setErrors, showCreateTutorModal, toggleModal, showEditTutorModal, editando, setSaving, loading, isReservaCupo, isInscripcion } = useEstudianteContext();

    const history = useHistory();
    const [showDelete, setShowDelete] = useState(false);
    const [showHistorialPagos, setShowHistorialPagos] = useState(false);
    const {id} = useParams();

    const updateEstudiante = async (event) => {
        event.preventDefault();

        setSaving(true);

        const data = new FormData(event.target);

        apiRequest(data, `academico/estudiantes/${id}`, 'post').then(res => {
            if (res.code === 422) {
                if (res.code === 422) {
                    if (typeof res.data === 'object') {
                        setErrors(res.data?.message || {});
                        toast('Favor revisar todos los campos.', 'error');
                    } else {
                        toast(res.data, 'error');
                    }
                }
                setSaving(false);
                return;
            }
            if (res.success) {
                if (res.data?.recibo) {
                    window.open(getLink(`/imprimir/academico/recibos/${res.data?.recibo.id}/${isInscripcion ? 'inscripcion' : 'mensualidad'}`));
                }
                history.push(getLink("/academico/estudiantes"));
                toast("Estudiante actualizado correctamente.");
            } else {
                toast("Estudiante no pudo ser actualizado.", "error");
            }

            setSaving(false);
        });

    };

    const deleteEstudiante = async () => {
        apiRequest({}, `academico/estudiantes/${id}`, 'delete').then(res => {
            if (res.success) {
                history.push(getLink("/academico/estudiantes"));
                toast("Estudiante borrado.");
            } else {
                toast("Estudiante no pudo ser borrado.", "error");
            }
        });
    };

    const toggleDeleteModal = () => {
        setShowDelete(prev => !prev);
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <div className='page-header'>
                {isInscripcion ? (
                    <h1 className='page-title'>
                        Nueva Inscripción
                    </h1>
                ) : (
                    <h1 className='page-title row'>
                        <div className="col">
                            <small className="text-uppercase">{isReservaCupo ? 'Reservar Cupo' : 'Editar'}</small>
                            <div><strong>{estudiante.nombreCompleto}</strong></div>
                        </div>
                        {estudiante.tieneCocurriculares && (
                            <Link
                                to={getLink(
                                    `/academico/cocurriculares?contacto=${estudiante.contacto_id}`
                                )}
                                target="_blank"
                                className='au-btn au-btn-icon au-btn-filter m-r-20 text-uppercase'>
                                Cobros Cocurriculares
                            </Link>
                        )}
                        {estudiante.curso && (
                            <Link
                                to={getLink(
                                    `/academico/facturar?estudiante_id=${estudiante.id}`
                                )}
                                target="_blank"
                                className='au-btn au-btn-icon au-btn--green au-btn--small'>
                                Cobros Académicos
                            </Link>
                        )}
                    </h1>
                )}
            </div>
            <form
                method='post'
                onSubmit={updateEstudiante}
                encType='multipart/form-data'>
                <input type="hidden" name="_method" value="patch" />
                <Form
                    estudiante={estudiante}
                    isEdit={true}
                    toggleModal={toggleModal}
                    setShowHistorialPagos={setShowHistorialPagos}
                />
                <div className='page-footer text-right'>
                    <div className='float-left'>
                        <button
                            type='button'
                            onClick={toggleDeleteModal}
                            className='btn'>
                            <i className='fas fa-trash'></i>
                        </button>
                    </div>
                </div>
            </form>

            <Modal
                title='Borrar Estudiante'
                show={showDelete}
                callback={deleteEstudiante}
                acceptText="Borrar"
                toggle={toggleDeleteModal}>
                ¿Seguro que deseas borrar a {estudiante.nombreCompleto}?
            </Modal>

            <Modal
                blank
                show={showCreateTutorModal}
                toggle={() => toggleModal('CreateTutor')}
                size="lg"
            >
                <CreateModal toggleModal={toggleModal} />
            </Modal>

            {showEditTutorModal && (
                <Modal
                    blank
                    show={showEditTutorModal}
                    toggle={() => toggleModal('EditTutor')}
                    size="lg"
                >
                    <EditModal toggleModal={toggleModal} id={editando} />
                </Modal>
            )}

            <Modal
				title="Historial de Pagos"
				show={showHistorialPagos}
				toggle={() => {
					setShowHistorialPagos((state) => !state);
				}}
                showCancelBtn={false}
                acceptText={"Cerrar"}
				size="1024px"
			>
                <HistorialPagos estudiante={estudiante} />
            </Modal>
        </div>
    );
}

export default Edit;
