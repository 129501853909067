import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const ImageLoader = ({
	multiple = false,
	name,
	imagenes = [],
	loaderClass = "",
	previewClass = "",
	text="Click para subir imagen (Max: 2MB)",
	className="",
	ref = false,
	deleteImageRequest = () => {},
	id = "fileInput"
}) => {
	const [images, setImages] = useState(imagenes);
	const [imageURLs, setImageURLs] = useState([]);
	const inputField = useRef(null);

	const onImageChange = (e) => {
		if(ref) {
			setImages((images) => [...images, ...ref.current.files]);
			return;
		}
		setImages((images) => [...images, ...inputField.current.files]);
	};

	const removeImage = (image) => {
		if (typeof image == "string") {
			setImages((images) => images.filter((img) => img !== image));
			return;
		}
		setImages((images) =>
			images.filter((img) => img.lastModified !== image.lastModified)
		);
	};

	useEffect(() => {
		if (!images) return;
		const newImageUrls = [];
		images.forEach((image) => {
			if (typeof image == "string") {
				newImageUrls.push({ url: image, image });
				return;
			}

			newImageUrls.push({ url: URL.createObjectURL(image), image });
		});
		setImageURLs(newImageUrls);
	}, [images]);
	return (
		<div className={className}>
			<div className="row">
				{imageURLs.map((imageSrc, i) => (
					<div className={`col-md-${multiple ? '6' : '12'}`} key={i}>
						<div
							className={`custom-file-loader__preview ${previewClass}`}
							key={i}
						>
							{typeof imageSrc.image == "string" && (
								<input
									type="hidden"
									name={name}
									value={imageSrc.image}
								/>
							)}
							<img src={imageSrc.url} alt="imagen" />
							<button
								type="button"
								onClick={() => {removeImage(imageSrc.image); deleteImageRequest(imageSrc)}}
							>
								X
							</button>
						</div>
					</div>
				))}
			</div>
			{/* {!multiple && images.length > 0 ? (
				<><input type="hidden" name={name} value={inputField?.current?.files[0]} /> </>
			) : ( */}
				<label
					htmlFor={id}
					className={`m-t-20 custom-file-loader ${loaderClass} ${images.length > 0 && !multiple ? 'd-none' : 'd-flex'}`}
				>
					<i
						className="fas fa-upload"
						style={{ fontSize: "2rem" }}
					></i>
					<span>{text}</span>
					<input
						id={id}
						type="file"
						ref={ref ? ref : inputField}
						name={name}
						multiple={multiple}
						accept="image/png, image/jpg, image/jpeg"
						onChange={() => onImageChange()}
						hidden
					/>
				</label>
			{/* )} */}
		</div>
	);
};

ImageLoader.propTypes = {
	multiple: PropTypes.bool,
	name: PropTypes.string,
	imagenes: PropTypes.array,
	loaderClass: PropTypes.string,
	previewClass: PropTypes.string,
	deleteImage: PropTypes.func,
	text: PropTypes.string,
	className: PropTypes.string,
	deleteImageRequest: PropTypes.func
};

export default ImageLoader;
