import { useEffect, useState } from "react";
import { request } from "../../../../utils/request";
import { auth } from "../../../../utils/auth";
import dayjs from "dayjs";

const useSalarioNavidad = () => {
    const [empleados, setEmpleados] = useState([]);
    const [orden, setOrden] = useState('nombre');
    const [redondeo, setRedondeo] = useState(false);

    const onChangeOrden = (e) => {
        setOrden(e.target.value);
    }

    const redondear = (cantidad) => {
        return Math.round(cantidad/5) * 5;
    }

    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
        (suc) => suc.id === user.sucursalActual
    )[0];

    let totalRegalia = 0;
    let totalRedondeado = 0;
    const totalEmpleados = empleados.length;

    const pagos = empleados.map(emp => {
        const multiplicador = {'mensual': 1, 'quincenal': 2, 'semanal': 4}[emp.tipo.intervalo_nomina];
        emp.pagos.push({
            fecha: dayjs().format('YYYY') + '-12-01',
            sueldo_bruto: emp.tipo.sueldo_fijo / multiplicador,
        });

        emp.fechai = dayjs(emp.fecha_ingreso);
        emp.fechaf = dayjs().endOf('year');
        emp.duracion = dayjs.duration(emp.fechaf.diff(emp.fechai));
        emp.anos = emp.fechaf.year() - emp.fechai.year();
        const diferencia = emp.fechai.daysInMonth() - 30;
        emp.meses = 11 - emp.fechai.month();
        emp.dias = 31 - emp.fechai.date() + diferencia;

        let index = 0;
        let mesAnterior = emp.fechai.format("MM");
        emp.salarios = emp.pagos.reduce((acc, pago) => {
            const mes = dayjs(pago.fecha).format('MM');
            const sb = parseFloat(pago.sueldo_bruto);
            if (emp.anos === 0) {
                if (index === 0 && mes === mesAnterior) {
                    acc[mes] = {sueldo_bruto: (acc[mes]?.sueldo_bruto ?? 0) + sb}
                } else if (!acc[mes] && parseInt(mes) >= parseInt(emp.fechai.format("MM"))) {
                    acc[mes] = {sueldo_bruto: sb * multiplicador}
                    if (mes !== mesAnterior) {
                        index += 1;
                    }
                    mesAnterior = mes;
                }
            } else {
                if (!acc[mes]) {
                    acc[mes] = {sueldo_bruto: parseFloat(pago.sueldo_bruto) * multiplicador}
                }
            }
            return acc;
        }, {});

        const salarioBruto = (Object.keys(emp.salarios).reduce((acc,s) => acc + emp.salarios[s].sueldo_bruto, 0) / 12);
        emp.salario_bruto = salarioBruto || emp.tipo?.sueldo_fijo;
        emp.salario_actual = emp.tipo?.sueldo_fijo;
        emp.salariom = emp.salario_bruto / 12;
        emp.salariod = emp.salariom / 31;

        emp.prestaciones = emp.salario_bruto;

        if (emp.fecha_ingreso) {
            totalRegalia += emp.prestaciones;
            totalRedondeado += redondear(emp.prestaciones);
        }

        return emp;
    });

    const onChangeRedondear = () => {
        setRedondeo(!redondeo);
    }

    const denominaciones = [2000,1000,500,200,100,50,25,10,5,1];
    const salarioNavidadDesglose = (pago) => {
        const total = redondeo ? redondear(pago.prestaciones) : pago.prestaciones;

        return denominaciones.reduce((result, denominacion) => {
            const residuo = result['carry'] % denominacion;

            if (residuo === result['carry']) {
                result[denominacion] = 0;
            } else {
                result[denominacion] = (result['carry'] - residuo) / denominacion;
            }

            result['carry'] = residuo;

            return result;

        }, {'carry': total});
    }

    const totalPagos = pagos.reduce((acc, emp) => {
        const total = redondear ? redondear(parseFloat(emp.prestaciones)) : parseFloat(emp.prestaciones);
        return total + acc;
    }, 0);

    const resumenDesglose = pagos.reduce((carry, pago) => {
        const desglose = salarioNavidadDesglose(pago);
        Object.keys(desglose).map(key => {
            if ( carry[key] ) {
                carry[key] += desglose[key];
            } else {
                carry[key] = desglose[key];
            }
            return key;
        });

        return carry;
    }, {});

    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        const getEmpleados = async () => {
            const params = new URLSearchParams(window.location.search);
            params.set('con-pagos', 1);
            const response = await request(
                "empleados?" + params.toString()
            );

            if (response.data) {
                setEmpleados(response.data);
            }
        };
        getEmpleados();
    }, []);

    return {
        pagos,
        empleados,
        orden,
        onChangeOrden,
        onChangeRedondear,
        sucursal,
        redondear,
        totalRegalia,
        totalRedondeado,
        totalPagos,
        resumenDesglose,
        denominaciones,
        salarioNavidadDesglose,
        totalEmpleados,
        redondeo,
        params
    }
}

export default useSalarioNavidad;
