import React from "react";

export default function ServiciosPorVencer({ servicios, toggleModal }) {
    if (servicios.length === 0) {
        return <div>¡Muy bien! No hay servicios por vencer.</div>;
    }

    return (
        <table className='table table-top-campaign'>
            <tbody>
                <tr>
                    <td></td>
                    <td>Ficha</td>
                    <td>Nombre</td>
                    <td>Contador</td>
                    <td>Intervalo</td>
                    <td></td>
                </tr>
                {servicios.map((servicio, index) => (
                    <tr
                        className={
                            parseFloat(servicio.contador) >
                            parseFloat(servicio.intervalo)
                                ? "expirado"
                                : ""
                        }
                        key={servicio.id}>
                        <td style={{fontWeight: "bold"}}>{index + 1}.</td>
                        <td>{servicio.vehiculo}</td>
                        <td>{servicio.nombre}</td>
                        <td>
                            {servicio.contador} {servicio.unidad}
                        </td>
                        <td>
                            {servicio.intervalo} {servicio.unidad}
                        </td>
                        <td>
                            <button
                                onClick={() =>
                                    toggleModal(
                                        {
                                            id: servicio.vehiculoId,
                                            ficha: servicio.vehiculo,
                                        },
                                        {
                                            vehiculoId: servicio.vehiculoId,
                                            vehiculo: servicio.vehiculo,
                                            servicioId: servicio.id,
                                            servicio: servicio.nombre,
                                        }
                                    )
                                }
                                className='btn btn-primary'>
                                Registrar
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
