import React, { useCallback, useEffect, useState } from "react";
import { useChecks } from "../../Hooks/useChecks";
import { formatCurrency, formatNumber, money, toast, validarPINSeguridad } from "../../../../utils/helpers";

const calcularDescuento = (costo, descuento) =>
	money(costo * descuento / 100);

const useProducto = (producto, productos, facturaProductos, updateTotal, index, impuestosForDropdown, banderaContacto, precioLimite, contacto, isEdit, isRecovery, fromPlantilla) => {

    const [productoInfo, setProductoInfo] = useState({
		id: false,
		nombre: false,
		cantidad: producto.cantidad,
		costo: producto.costo,
		descuento: producto.descuento,
        importe: producto.importe,
        precio_facturado: producto.precio_facturado,
		precios: [],
		impuesto: producto.impuesto,
		precio_venta: null,
		existencia: null,
		cantidadAnterior: null,
		precioCompra: null,
	});

	const { checkProductoRepetidoFactura, checkExistenciaProducto } = useChecks(
        facturaProductos,
		productos,
		productoInfo
	);

	const [showFotos, setShowFotos] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [showPrecioManual, setShowPrecioManual] = useState(false);
	const [showPINModal, setShowPINModal] = useState(false);
    const [showPrecioCompra, setShowPrecioCompra] = useState(false);

	const [pinSeguridad, setPinSeguridad] = useState("");
	const [precioManual, setPrecioManual] = useState(0);
	const [pinSeguridadValidated, setPinSeguridadValidated] = useState(false);
    const [chequeoPrecio, setChequeoPrecio] = useState(true);

    const [isPrecioPorDebajo, setIsPrecioPorDebajo] = useState(false);

	const onChangeInput = (field, value) => {
		setProductoInfo((producto) => ({
			...producto,
			[field]: value,
		}));
	};

	const handlePrecioVentaChange = (precio) => {
		if (precio.value === "manual") {
			setShowPrecioManual(true);
			return;
		}

        const imp = impuestosForDropdown.find(i => i.value === precio.impuesto_id);

		onChangeInput("impuesto", imp);
		onChangeInput("costo", calcularImporte(precio.value, imp));
		onChangeInput("precio_venta", precio);
		onChangeInput("descuento", 0);

        setPrecioManual(precio.value);
	};

    const onChangeImpuesto = (impuesto_id) => {
        const imp = impuestosForDropdown.find(i => i.value === impuesto_id);

        const precio = (productoInfo.costo * parseFloat(imp?.porcentaje + 1)).toFixed(2);

        onChangeInput("precio_venta", {
            value: precio,
            label: `1 - ${formatNumber(precio)}`,
            impuesto_id: imp.id
        });

        setPrecioManual(precio);

        onChangeInput("impuesto", imp);
    }

	const onChangeProducto = (productoId) => {
		if (!checkExistenciaProducto(productoId)) return;
		checkProductoRepetidoFactura(productoId, index, facturaProductos);

		let newProducto = productos.filter(
			(producto) => producto.id === productoId
		)[0];
		newProducto = newProducto ? newProducto : {};

        setShowPrecioManual(false);
        setPrecioManual('');

        let plength = precioLimite && newProducto?.precios.length >= precioLimite ? precioLimite : (precioLimite && precioLimite > newProducto?.precios.length ? newProducto?.precios.length : 1);
        plength = banderaContacto ? plength : 1;

        updateProducto(newProducto, plength - 1);
	};

	const productLabel = useCallback((producto) => {
		return (
			<span className="row px-0 align-items-center">
				<span
					className="col-2 pr-1"
					style={{ minWidth: "100px", wordBreak: "break-all" }}
				>
					{producto.referencia}
				</span>
				<span className="col-5 px-1">{producto.nombre}</span>
				<span
					className="col-1 px-1"
					style={{
						fontWeight: "bold",
						color: "#008aab",
					}}
				>
					{isNaN(parseFloat(producto.cantidad))
						? "∞"
						: parseFloat(producto.cantidad)}
				</span>
				<span
					className="col-2 pr-0"
					style={{
						fontWeight: "bold",
						color: "#008aab",
					}}
				>
					{banderaContacto &&
						precioLimite >= 1 &&
						producto.precios?.length >= precioLimite
							? formatCurrency(
									money   (
										producto.precios[precioLimite - 1]
											?.total
									) || 0
							  )
							: formatCurrency(
									money(producto.precios[0]?.total) || 0
							  )}
				</span>
			</span>
		);
	}, [contacto]);

	const calcularItbis = (total) => {
		return total * (productoInfo.impuesto?.porcentaje || 0);
	};

    const volverAlSelector = async () => {
        const plength = banderaContacto && precioLimite ? precioLimite : 1;
        const precioOriginal = productoInfo?.precios[plength-1];
        setShowPrecioManual(false);

        handlePrecioVentaChange({
            label: `${plength} -> ${formatNumber(precioOriginal?.total)}`,
            value: precioOriginal?.total,
            impuesto_id: precioOriginal.impuesto?.id,
            tipo: precioOriginal.impuesto?.tipo
        });

        setProductoInfo(prev => {
            const newProductoInfo = {
                ...prev,
                impuesto: {
                    label: precioOriginal?.impuesto?.nombre,
                    value: precioOriginal?.impuesto?.id,
                    porcentaje: precioOriginal?.impuesto?.porcentaje,
                    tipo: precioOriginal?.impuesto?.tipo,
                },
                precio_manual: precioOriginal?.total
            }

            onChangeCostoManual(precioOriginal?.total, false, newProductoInfo);

            return newProductoInfo;
        });
    }

	const preciosVenta = useCallback(() => {
		if (!precioLimite && !banderaContacto) {
			return productoInfo.precios?.map((precio, index) => ({
				value: parseFloat(precio.total),
				label: `${index + 1} -> ${formatNumber(precio.total)}`,
				impuesto_id: precio.impuesto?.id,
			}));
		}

		let precios = [];

		if (productoInfo.precios?.length > 0 && !banderaContacto) {
            productoInfo.precios.forEach((precio, index) => {

				if (index + 1 <= precioLimite) {
					precios.push({
						value: parseFloat(precio.total),
						label: `${index + 1} -> ${formatNumber(precio.total)}`,
						impuesto_id: precio.impuesto?.id,
					});
				}
			});
		} else if (productoInfo.precios?.length > 0 && banderaContacto) {
            if (precioLimite > productoInfo.precios?.length) {
                productoInfo.precios.forEach((precio, index) => {
                    if (index + 1 === productoInfo.precios.length) {
                        precios.push({
                            value: parseFloat(precio.total),
                            label: `${index + 1} -> ${formatNumber(precio.total)}`,
                            impuesto_id: precio.impuesto?.id,
                        });
                    }
                });
            } else {
                productoInfo.precios.forEach((precio, index) => {
                    if (index + 1 >= precioLimite) {
                        precios.push({
                            value: parseFloat(precio.total),
                            label: `${index + 1} -> ${formatNumber(precio.total)}`,
                            impuesto_id: precio.impuesto?.id,
                        });
                    }
                });
            }
		}
		return precios;
	}, [onChangeProducto]);

	const toggleFotosModal = () => {
		setShowFotos((state) => !state);
	};

	const toggleDeleteModal = () => {
		setShowDelete((state) => !state);
	};

	const onChangeCostoManual = async (value, revisarCalculo = false, productoOriginal = null) => {
        const precios = preciosVenta().length > 0 ? preciosVenta() : productoOriginal?.precios;

        let plength = (precioLimite && precios.length >= precioLimite) ? precioLimite : precios.length;
        plength = banderaContacto ? precios.length : plength;
        const precioVenta = precios[plength-1];

        let imp = null;

        if ( productoOriginal ) {
            imp = productoOriginal.impuesto;
        } else if (showPrecioManual ) {
            imp = productoInfo.impuesto;
        } else {
            imp = impuestosForDropdown.find(
                i => i.value === precioVenta?.impuesto_id
            );
        }

		let menorPrecioVenta = calcularImporte(precioVenta?.value || precioVenta?.total, imp);

		const importeCalculado = calcularImporte(value || precioManual, imp);

        if (revisarCalculo && importeCalculado < productoInfo.precioCompra) {
            toast("ATENCIÓN: Este precio es menor al costo de compra.", "error");
        }

		if (revisarCalculo && importeCalculado < menorPrecioVenta && !pinSeguridadValidated) {
            setIsPrecioPorDebajo(true);

			setShowPINModal(true);
			return;
		}

        onChangeInput("costo", importeCalculado);
	};

	const productoITBIS = calcularItbis(productoInfo.costo);

    const st = !showPrecioManual
		? ((productoInfo?.precio_venta?.value?.toFixed(2)) * productoInfo.cantidad) ||
		  0
		: precioManual * productoInfo.cantidad;

	// const totalItbis = calcularItbis(costoTotal);
	const totalItbis =
    calcularItbis(productoInfo.costo).toFixed(2) * productoInfo.cantidad;

	const costoTotal = st - totalItbis;

	const desc =
		calcularDescuento(costoTotal + totalItbis, productoInfo.descuento) || 0;

	const subtotal = costoTotal || 0;

    const costoReal = (productoInfo.costo - (productoInfo.costo * productoInfo.descuento / 100)) * productoInfo.cantidad;
    const descuentoReal = calcularDescuento(productoInfo.costo * productoInfo.cantidad, productoInfo.descuento);
    const itbisReal = calcularItbis(costoReal);
    const totalReal = costoReal + itbisReal;

	const total = st;

    //Cargar la informacion del producto desde factura existente o autoguardado
	const changeProductoToEdit = (productoOriginal) => {
        const productoId = productoOriginal.producto.id;
        const precio_facturado = productoOriginal.precio_facturado;

		const newProducto = productos.find(
			(producto) => producto.id === productoId
		) || {};

        let precioIndex = 0;
        precioIndex = newProducto.precios?.findIndex(
            (precio) => parseFloat(precio.precio) === precio_facturado
        );

        if (precioIndex < 0) {
            setShowPrecioManual(true);
            updateProducto(newProducto, precioIndex, productoOriginal);
            setPrecioManual(precio_facturado);

            //Checkeo de precios al cargar
            if(
                isRecovery
                && chequeoPrecio
                && precio_facturado > 0
                && !isEdit
                && !fromPlantilla
            ) {
                onChangeCostoManual(precio_facturado, true, productoOriginal);
                setChequeoPrecio(false);
            }
            return;
        }

        updateProducto(newProducto, precioIndex, productoOriginal);
	};

    const updateProducto = (newProducto, precioIndex, productoOriginal = null) => {
        let newPrecio = null;

        if (precioIndex >= 0) {
            newPrecio = newProducto?.precios[precioIndex];
        }

        //Usar el impuesto del primer precio de venta
        let newimpuesto = {};

        if (productoOriginal) {
            newimpuesto = productoOriginal.impuesto;
        } else {
            newimpuesto = newProducto.precios ? newProducto.precios[0]?.impuesto : newProducto.impuesto;
        }

        if (!newPrecio) {
            newPrecio = {
                precio: productoInfo.precio_facturado,
                precio_venta: calcularImporte(productoInfo.precio_facturado, newimpuesto),
                impuesto: newimpuesto
            };
        }

        setProductoInfo((state) => ({
            ...state,
            id: newProducto.id,
            nombre: (
                <>
                    <span
                        className="col-3 pl-1"
                        style={{
                            minWidth: "100px",
                            wordBreak: "break-all !important",
                        }}
                    >
                        {newProducto.referencia}
                    </span>{" "}
                    <span className="col-8">{newProducto.nombre}</span>
                </>
            ) || '',
            importe: newProducto.importe,
            costo: newPrecio?.precio_venta,
            precio_facturado: newProducto.precio_facturado || newPrecio.precio,
            referencia: newProducto.referencia,
            unidad: newProducto.unidad,
            precios: newProducto.precios || [],
            fotos: newProducto.fotos
                ? newProducto.fotos.map((foto) => foto.url)
                : [],
            precio_venta: {
                value: parseFloat(
                    newPrecio?.precio || 0
                ),
                label: `${precioIndex + 1} -> ${formatNumber(
                    newPrecio?.precio
                )}`,
                impuesto_id: newPrecio.impuesto?.id,
            },
            impuesto: {
                label: newimpuesto?.nombre,
                porcentaje: newimpuesto?.porcentaje,
                value: newimpuesto?.id,
                tipo: newimpuesto?.tipo,
            },
            existencia: newProducto.cantidad,
            cantidadAnterior: newProducto.cantidad,
            precioCompra: newProducto.costo,
        }));
    }

	const checkQuantityConstrains = () => {
        if (isEdit) {
            const existencia = productoInfo.existencia + productoInfo.cantidadAnterior
			if (
				productoInfo.existencia != null &&
				productoInfo.cantidadAnterior != null &&
				productoInfo.cantidad > existencia
			) {
				toast(
					`Existencia Actual ${
						existencia
					}`,
					"error"
				);
				onChangeInput(
					"cantidad",
					existencia
				);
                return;
			}
            if (productoInfo.cantidadAnterior) {
                return;
            }
		}

        if (
			productoInfo.existencia != null &&
			producto.cantidad > productoInfo.existencia
		) {
			toast(`Existencia Actual ${productoInfo.existencia}`, "error");
			producto.cantidad = parseFloat(productoInfo.existencia);
			onChangeInput("cantidad", productoInfo.existencia);
			return;
		}

		if (producto.cantidad <= 0) {
			toast(`Cantidad Debe ser mayor a 0`, "error");
			producto.cantidad = 1;
			onChangeInput("cantidad", 1);
			return;
		}
	};

	const checkPINSeguridad = async () => {
		if (await validarPINSeguridad(pinSeguridad)) {
            proceedWithPin();
			return;
		}

		toast("PIN incorrecto, intente de nuevo", "error");
	};

    const proceedWithPin = (proceed = false) => {
        if (!proceed.detail) return;

        setPinSeguridad("");
        setShowPINModal(false);
        setPinSeguridadValidated(true);
    }

	const togglePINModal = () => {
		setShowPINModal(false);

        if (isPrecioPorDebajo) {
            const plength = banderaContacto && precioLimite ? precioLimite : 1;

            const nuevoPrecio = money(productoInfo.precios[plength-1]?.precio);
            const importeCalculado =  calcularImporte(nuevoPrecio, productoInfo.impuesto);

            onChangeInput("costo", importeCalculado);
            setPrecioManual(nuevoPrecio);
        }
	};

    const calcularImporte = (precio, impuesto) => {
        return parseFloat(precio) / (impuesto?.porcentaje + 1);
    }

    const onChangeDescuento = (descuento) => {
        if (descuento === 0) return;
		if (
			total <
				(preciosVenta()[preciosVenta().length - 1]?.value *
					productoInfo.cantidad || 0) ||
			descuento < 0
		) {
			toast("Este descuento no puede ser aplicado", "error");
			onChangeInput("descuento", 0);
		}
    }


	useEffect(() => {
		if (!producto.producto?.id ||!producto.precio_facturado || productos?.length <= 0) {
            return;
        }
        changeProductoToEdit(
            producto
        );
	}, [producto.producto?.id, productos?.length]);

    //Esto Actualiza la cantidad luego de chekear productos duplicados
	useEffect(() => {
		onChangeInput("cantidad", producto.cantidad);
	}, [producto.cantidad]);

	useEffect(() => {
		updateTotal(
			producto.id,
			desc > 0 ? costoReal : subtotal,
			desc > 0 ? totalReal : total,
			desc > 0 ? itbisReal : totalItbis,
			desc > 0 ? descuentoReal : 0,
			productoInfo.impuesto,
            productoInfo
		);
	}, [subtotal, totalItbis, total, desc, showPrecioManual]);

	useEffect(() => {
		onChangeDescuento(productoInfo.descuento);
	}, [productoInfo.descuento]);

    useEffect(() => {
        window.addEventListener('pinValidado', proceedWithPin);
        window.addEventListener('pinCancelado', togglePINModal);
        return () => {
            window.removeEventListener('pinValidado', proceedWithPin);
            window.removeEventListener('pinValidado', togglePINModal);
        }
    }, [togglePINModal, proceedWithPin]);

    return {
        productoInfo,
        productLabel,
        total,
        totalReal,
        preciosVenta,
        precioManual,
        pinSeguridad,
        productoITBIS,
        checkQuantityConstrains,
        checkPINSeguridad,
        onChangeInput,
        onChangeImpuesto,
        onChangeProducto,
        onChangeCostoManual,
        onChangeDescuento,
        handlePrecioVentaChange,
        setProductoInfo,
        setPrecioManual,
        setPinSeguridad,
        setShowPrecioManual,
        setShowFotos,
        toggleFotosModal,
        toggleDeleteModal,
        togglePINModal,
        showPrecioManual,
        showPrecioCompra,
        setShowPrecioCompra,
        showFotos,
        showDelete,
        showPINModal,
        volverAlSelector
    }
}

export default useProducto;
