import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import { toast } from "../../utils/helpers";
import Loader from "../general/Loader";

class Create extends Component {
	state = {
		errors: {},
		contacto: {},
		isLoading: false,
	};
	createContrato = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		const token = auth.getToken();
		const data = new FormData(event.target);

		// Validate documentos
		const documentos = { validos: true };
		data.forEach((value, key) => {
			if(key.startsWith("documentos")){
				const parts = key.split('[');

				if(parts.length >= 3) {
					if (!Object.hasOwnProperty.call(documentos, parts[1])) {
						documentos[parts[1]] = {};
					}

					if (parts[2] === "archivo]")
						documentos[parts[1]].archivo = value.size;
					else
						documentos[parts[1]].name = value;
				}
			}
		});

		for (const index in documentos) {
			if (Object.hasOwnProperty.call(documentos, index)) {
				const documento = documentos[index];
				
				if(documento.archivo && !documento.name) {
					toast("Debe ingresar un nombre valido a cada archivo", "error");
					documentos.validos = false;
					break;
				}
			}
		}
		
		if (documentos.validos) {
			const res = await fetch(apiUrl("contratos"), {
				method: "post",
				body: data,
				headers: {
					Accept: "application/json",
					"X-Request-With": "XMLHttpRequest",
					Authorization: "Bearer " + token,
				},
			});
			
			const response = await res.json();
			
			if (response.success) {
				if (this.props.fromFacturacion) {
					this.props.toggle(null, response.data);
					return;
				}
				this.props.getContactos();
				this.props.toggle();
				toast("Nuevo contrato creado.");
			}
			if (response.code === 422) {
				this.setState({ errors: response.data.message });
				toast("Favor verificar todos los campos.", "error");
			}
		}

		this.setState({ isLoading: false });
	};
	render() {
		const { errors, contacto, isLoading } = this.state;
		const { toggle } = this.props;
		return (
			<form method="post" onSubmit={this.createContrato}>
				<div className="popup__header">
					<div className="row">
						<div className="col col-md-8">
							Nuevo Contrato
						</div>
					</div>
				</div>
				<div className="popup__body">
					<Form contacto={contacto} errors={errors} />
				</div>
				<div className="popup__footer">
					<button
						type="button"
						onClick={toggle}
						className="btn btn-plain"
					>
						Cancelar
					</button>
					{isLoading ? (
						<Loader type="small" />
					) : (
						<button type="submit" className="btn btn-primary">
							Crear
						</button>
					)}
				</div>
			</form>
		);
	}
}

export default Create;
