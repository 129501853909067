import React, { useState, useEffect } from "react";
import { getTable } from "../../../utils/request";

export default function ReporteRutas() {
    const [rutas, setRutas] = useState([]);
    const [sRuta, setRuta] = useState("");

    useEffect(() => {
        let ignore = false;

        async function fetchRutas() {
            const data = await getTable("rutas");
            if (!ignore) {
                setRutas(data);
            }
        }

        fetchRutas();
        return () => {
            ignore = true;
        };
    }, []);

    function updateList(id) {
        setRutas(
            rutas.map(ruta => {
                if (ruta.id === id) {
                    ruta.checked = !ruta.checked;
                }
                return ruta;
            })
        );
    }

    function checkAll(categoria) {
        if (categoria === "todos") {
            setRutas(
                rutas.map(ruta => {
                    return {
                        ...ruta,
                        checked: true
                    };
                })
            );
        }
        if (categoria === "quitar") {
            setRutas(
                rutas.map(ruta => {
                    return {
                        ...ruta,
                        checked: false
                    };
                })
            );
        }
    }

    function buscarRuta(ruta) {
        setRuta(ruta);
    }

    return (
        <div>
            <div className='row m-t-20 m-b-20'>
                <div className='col-md-7 p-t-5'>
                    <button type='button' onClick={() => checkAll("todos")}>
                        Seleccionar Todo
                    </button>{" "}
                    |{" "}
                    <button type='button' onClick={() => checkAll("quitar")}>
                        Deseleccionar Todo
                    </button>
                </div>
                <div className='col-md-5'>
                    <input
                        type='text'
                        className='form-control'
                        value={sRuta}
                        placeholder='Buscar...'
                        onChange={e => buscarRuta(e.target.value)}
                    />
                </div>
            </div>
            <div className='row m-t-15'>
                {rutas &&
                    rutas
                        .filter(ruta => {
                            if (sRuta === "") {
                                return true;
                            }

                            if (ruta.nombre.indexOf(sRuta) !== -1) {
                                return true;
                            }

                            return false;
                        })
                        .map(ruta => (
                            <div className='col-md-4' key={ruta.id}>
                                <label>
                                    <input
                                        type='checkbox'
                                        value={ruta.id}
                                        onChange={() => updateList(ruta.id)}
                                        checked={ruta.checked}
                                    />
                                    {ruta.nombre}
                                </label>
                            </div>
                        ))}
            </div>
            <input
                type='hidden'
                name='rutas'
                required
                value={rutas
                    .filter(ruta => ruta.checked)
                    .map(ruta => ruta.id)
                    .join(",")}
            />
        </div>
    );
}
