import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";

class PrintRutas extends Component {
    state = {
        rutas: []
    };
    componentDidMount() {
        this.getSolicitudes();
    }
    getSolicitudes = async () => {
        const response = await this.props.request(
            "rutas" + window.location.search
        );

        if (response.data) {
            this.setState({
                rutas: response.data
            });
            window.print();
        }
    };
    render() {
        const { rutas } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            suc => suc.id === user.sucursalActual
        )[0];
        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>Zonas</h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-center p-b-10'></div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ width: "100px" }}>Nombre</th>
                            <th>Descripción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rutas.map(ruta => {
                            return (
                                <tr key={ruta.id}>
                                    <td>{ruta.nombre}</td>
                                    <td>{ruta.descripcion}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintRutas));
