import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatCurrency } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";

export default class ReporteDetalleProduccionRutas extends Component {
    state = {
        rutas: false,
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const token = auth.getToken();

        const res = await fetch(apiUrl(`reportes` + window.location.search), {
            method: "get",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.setState({
                rutas: response.data,
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { rutas } = this.state;

        if (!rutas) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        const totalViajes = rutas.reduce((acc, ruta) => {
            return acc + parseFloat(ruta.viajes);
        }, 0);

        const totalVentas = rutas.reduce((acc, ruta) => {
            return acc + parseFloat(ruta.ventas);
        }, 0);

        const totalDolares = rutas.reduce((acc, ruta) => {
            return acc + parseFloat(ruta.dolares);
        }, 0);

        const totalDepositos = rutas.reduce((acc, ruta) => {
            return acc + parseFloat(ruta.depositos);
        }, 0);

        rutas.sort((a, b) => {
            return b.ventas - a.ventas;
        });

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Detalle Producción por Zonas
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>ZONAS: {rutas.length}</div>

                        <div className='m-t-5 float-right'>
                            <h3>
                                <span className='m-r-25'>
                                    TOTAL CUADRES: {totalViajes}
                                </span>{" "}
                                <span className='m-r-25'>
                                    TOTAL VENTAS: {formatCurrency(totalVentas)}
                                </span>{" "}
                                <span className='m-r-25'>
                                    TOTAL DEPÓSITOS: {formatCurrency(totalDepositos)}
                                </span>{" "}
                                <span>
                                    TOTAL DÓLARES: {formatCurrency(totalDolares, 'USD$')}
                                </span>{" "}
                            </h3>
                        </div>
                    </div>
                </div>

                {rutas.map((ruta) => {
                    return (
                        <div key={ruta.id} className='m-b-25'>
                            <h3>
                                {ruta.nombre} - {ruta.descripcion}
                            </h3>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th style={{ width: "100px" }}>
                                            Fecha
                                        </th>
                                        <th style={{ width: "90px" }}>Ficha</th>
                                        <th>Chofer</th>
                                        <th
                                            style={{ width: "100px" }}
                                            className='text-center'>
                                            CUADRES
                                        </th>
                                        <th
                                            style={{ width: "100px" }}
                                            className='text-right'>
                                            VENTAS
                                        </th>
                                        <th
                                            className='text-right'>
                                            DEPÓSITOS
                                        </th>
                                        <th
                                            className='text-right'>
                                            DÓLARES
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ruta.tarjetas.map((tarjeta) => {
                                        return (
                                            <tr key={tarjeta.id}>
                                                <td>
                                                    {dayjs(
                                                        tarjeta.fecha
                                                    ).format("DD/MM/YYYY")}
                                                </td>
                                                <td>{tarjeta.ficha}</td>
                                                <td>{tarjeta.chofer}</td>
                                                <td className='text-center'>
                                                    1
                                                </td>
                                                <td className='text-right'>
                                                    {formatCurrency(
                                                        tarjeta.ventas
                                                    )}
                                                </td>
                                                <td className='text-right'>
                                                    {formatCurrency(
                                                        tarjeta.depositos
                                                    )}
                                                </td>
                                                <td className='text-right'>
                                                    {formatCurrency(
                                                        tarjeta.dolares, 'USD$'
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className='text-right'>TOTALES:</td>
                                        <td className='text-center'>
                                            {ruta.viajes}
                                        </td>
                                        <td className='text-right'>
                                            {formatCurrency(ruta.ventas)}
                                        </td>
                                        <td className='text-right'>
                                            {formatCurrency(ruta.depositos)}
                                        </td>
                                        <td className='text-right'>
                                            {formatCurrency(ruta.dolares, 'USD$')}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                })}
                <div className='m-t-25'>
                    <div className='float-left'>ZONAS: {rutas.length}</div>
                    <div className='text-right float-right'>
                        <div className='m-t-5 float-right'>
                            <h3>
                                <span className='m-r-25'>
                                    TOTAL CUADRES: {totalViajes}
                                </span>{" "}
                                <span className='m-r-25'>
                                    TOTAL VENTAS: {formatCurrency(totalVentas)}
                                </span>{" "}
                                <span className='m-r-25'>
                                    TOTAL DEPÓSITOS: {formatCurrency(totalDepositos)}
                                </span>{" "}
                                <span>
                                    TOTAL DÓLARES: {formatCurrency(totalDolares, 'USD$')}
                                </span>{" "}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
