import React, { Component } from "react";
import { Route, NavLink, Link, withRouter } from "react-router-dom";
import { withRequest } from "../../../utils/request";
import { getLink } from "../../../utils/helpers";
import Loader from "../../general/Loader";
import PeriodosAcademicos from "./PeriodosAcademicos";
import Ciclos from "./Ciclos";
import Grados from "./Grados";
import Modalidades from "./Modalidades";
import Periodos from "./Periodos";
import Niveles from "./Niveles";
import Secciones from "./Secciones";
import Becas from "./Becas";
import SalidasOptativas from "./SalidasOptativas";
import PagosEducativos from "./PagosEducativos";
import Cocurriculares from "./Cocurriculares";

class Academica extends Component {
    state = {
        sucursal: {},
        loading: true,
        sucursales: [],
    };

    componentDidMount() {
        this.getSucursales();
    }

    getSucursales = async (url = null) => {
        const id = parseInt(this.props.match.params.id);
        url = url ? url : "sucursales";
        const sucursales = await this.props.request(url);

        if (sucursales.data) {
            this.setState({
                sucursal: sucursales.data.filter(s => s.id === id)[0],
                sucursales: sucursales.data,
                loading: false,
            });
        }
    };

    render() {
        const { loading } = this.state;

        if (loading) {
            return <Loader />;
        }

        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/configuracion')}>&laquo; Volver a configuraciones</Link>
                    <h1 className='page-title'>
                        Configuración Académica
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-2 page-sidebar'>
                        <ul className='list-unstyled page-sidebar__menu'>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/configuracion/academica`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Años Escolares
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/configuracion/academica/pagos-educativos`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Servicios Educativos
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/configuracion/academica/cocurriculares`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Cocurriculares
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/configuracion/academica/periodos`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Periodos
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/configuracion/academica/ciclos`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Ciclos
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/configuracion/academica/niveles`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Niveles
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/configuracion/academica/grados`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Grados
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/configuracion/academica/secciones`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Secciones
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/configuracion/academica/modalidades`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Modalidades
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/configuracion/academica/salidas-optativas`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Salidas Optativas
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/configuracion/academica/becas`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Becas
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-10 p-l-40'>
                        <Route
                            exact
                            path='/:sucursal/configuracion/academica'
                            render={(props) => (
                                <PeriodosAcademicos {...props} />
                            )}
                        />
                        <Route
                            exact
                            path='/:sucursal/configuracion/academica/periodos'
                            render={(props) => (
                                <Periodos {...props} />
                            )}
                        />
                        <Route
                            path='/:sucursal/configuracion/academica/ciclos'
                            render={(props) => (
                                <Ciclos {...props} />
                            )}
                        />
                        <Route
                            path='/:sucursal/configuracion/academica/niveles'
                            render={(props) => (
                                <Niveles {...props} />
                            )}
                        />
                        <Route
                            path='/:sucursal/configuracion/academica/grados'
                            render={(props) => (
                                <Grados {...props} />
                            )}
                        />
                        <Route
                            path='/:sucursal/configuracion/academica/secciones'
                            render={(props) => (
                                <Secciones {...props} />
                            )}
                        />
                        <Route
                            path='/:sucursal/configuracion/academica/modalidades'
                            render={(props) => (
                                <Modalidades {...props} />
                            )}
                        />
                        <Route
                            path='/:sucursal/configuracion/academica/salidas-optativas'
                            render={(props) => (
                                <SalidasOptativas {...props} />
                            )}
                        />
                        <Route
                            path='/:sucursal/configuracion/academica/becas'
                            render={(props) => (
                                <Becas {...props} />
                            )}
                        />
                        <Route
                            path='/:sucursal/configuracion/academica/pagos-educativos'
                            render={(props) => (
                                <PagosEducativos {...props} />
                            )}
                        />
                        <Route
                            path='/:sucursal/configuracion/academica/cocurriculares'
                            render={(props) => (
                                <Cocurriculares {...props} />
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(Academica));
