import React, { useEffect, useState } from "react";

import Form from "./Form";
import Loader from "../../general/Loader";
import { apiRequest } from "../../../hooks/apiRequest";
import { request } from "../../../utils/request";
import { toast } from "../../../utils/helpers";
import Modal from "../../general/Modal";

const Edit = ({ toggleModal, getTerminosData, terminoID }) => {
	const [saving, setSaving] = useState(false);
	const [errors, setErrors] = useState({});
	const [termino, setTermino] = useState({});
	const [showDelete, setShowDelete] = useState(false);

	const getTerminoData = () => {
		if (!terminoID) return;

		request(`terminos-pago/${terminoID}`).then((res) => {
			setTermino(res.data);
		});
	};
	const updateTermino = (event) => {
		event.preventDefault();
		setSaving(true);
		const data = new FormData(event.target);

		apiRequest(data, `terminos-pago/${termino.id}`, "post").then(
			(response) => {
				if (response.success) {
					getTerminosData();
					toggleModal();
					setSaving(false);
					toast("Término actualizado.");
				}
				if (response.code === 422) {
					setErrors({
						...response.data.message,
					});
					setSaving(false);
				}
			}
		);
	};

	const deleteTermino = () => {
		apiRequest(null, `terminos-pago/${termino.id}`, "delete").then(
			(response) => {
				if (response.success) {
					getTerminosData();
					toggleModal();
					setSaving(false);
					toast("Término Eliminado.");
				}
				if (response.code === 422) {
					setErrors({
						...response.data.message,
					});
					setSaving(false);
				}
			}
		);
	};

	const toggleDeleteModal = () => {
        setShowDelete(state => !state);
    };

	useEffect(() => {
		getTerminoData();
	}, [terminoID]);
	return (
		<>
		<form id="terminoForm" method="post" onSubmit={updateTermino}>
			<input type="hidden" name="_method" value="put" />
			<div className="popup__header">Editar Término</div>

			{!terminoID ? (
				<Loader />
			) : (
				<Form termino={termino} errors={errors} />
			)}

			<div className="popup__footer">
				{!termino.is_default && (
					<button
						type="button"
						className="btn float-left"
						onClick={(e) => toggleDeleteModal()}
					>
						<i className="fas fa-trash-alt"></i>
					</button>
				)}
				<button
					type="button"
					className="btn btn-plain"
					onClick={() => toggleModal()}
				>
					Cancelar
				</button>
				{saving ? (
					<Loader type="small" />
				) : (
					<button type="submit" className="btn btn-primary">
						Editar
					</button>
				)}
			</div>
		</form>
		<Modal
				title="Borrar Término"
				show={showDelete}
				callback={() => deleteTermino()}
				acceptText="Borrar"
				toggle={toggleDeleteModal}
			>
				¿Estás seguro de que deseas borrar esta Término?
			</Modal>
		</>
	);
};

export default Edit;
