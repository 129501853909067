import React, { Component } from "react";
import { debounce } from "lodash";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Loader from "../general/Loader";
import { formatCurrency, getLink } from "../../utils/helpers";
import { Link } from "react-router-dom";
import { auth } from "../../utils/auth";
import Enviar from "./Enviar";
import ItemFotos from "./ItemFotos";

class Items extends Component {
	state = {
		items: {},
		item: {},
		almacenes: [],
		grupos: [],
		links: null,
		showCreate: false,
		showEnviar: false,
		showFotos: false,
		loading: true,
		filtrosQuery: "",
	};
	componentDidMount() {
		this.getItems();
		this.getTable("almacenes");
		this.getTable("grupos");
	}
	getItems = async (url = null) => {
		url = url ? url + "&limit=20" : "items?limit=20";
		const items = await this.props.request(url);

		if (items.data) {
			this.setState({
				items: items.data,
				meta: items.meta,
				links: items.links,
				loading: false,
			});
		}
	};
	getTable = async (table) => {
		const response = await this.props.request(table);

		if (response.data && response.data.length) {
			this.setState({
				[table]: response.data,
			});
		}
	};
	toggleCreateModal = () => {
		this.setState({
			showCreate: !this.state.showCreate,
			item: {},
		});
	};
	toggleEnviarModal = (item) => {
		this.setState({
			showEnviar: !this.state.showEnviar,
			item: item,
		});
	};
	toggleFotosModal = (item) => {
		this.setState({
			showFotos: !this.state.showFotos,
			item: item,
		});
	};
	onFiltroChange = debounce(async (value, filtro) => {
		await this.setState({
			filtros: {
				...this.state.filtros,
				[filtro]: value,
			},
		});

		let query = Object.keys(this.state.filtros).map((fil) => {
			return `${fil}=${this.state.filtros[fil]}`;
		});

		await this.setState({
			filtrosQuery: "?" + query.join("&"),
		});

		this.getItems("items?" + query.join("&"));
	}, 500);
	render() {
		const { items, almacenes, grupos, loading, filtrosQuery } = this.state;
		return (
			<div>
				<div className="page-header">
					<h1 className="page-title row">
						<span>Inventario Interno</span>
                        <small className="col page-title__description">
                            Registre productos y servicios relacionados a gastos internos.
                        </small>
						<Link
							to={getLink(`/imprimir/items${filtrosQuery}`)}
							target="_blank"
							className="au-btn-filter m-r-15"
						>
							<i className="zmdi zmdi-print" /> Imprimir
						</Link>
						{auth.can("editar-inventario") && (
							<Link
								to={getLink(`/inventario/crear`)}
								className="au-btn au-btn-icon au-btn--green au-btn--small"
							>
								<i className="zmdi zmdi-plus" />
								Añadir Item
							</Link>
						)}
					</h1>
				</div>
				<div className="page-controls">
					<div className="table-data__tool">
						<div className="table-data__tool-left row">
							<div className="col-md-6">
								<label htmlFor="search">Buscar</label>
								<input
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"buscar"
										)
									}
									type="search"
									placeholder="Buscar..."
									className="form-control"
									name="buscar"
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="almacen">Almacén</label>
								<select
									className="form-control"
									name="almacen"
									id="almacen"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"almacen"
										)
									}
								>
									<option value="todos">Todos</option>
									{almacenes.map((almacen) => (
										<option
											key={almacen.id}
											value={almacen.id}
										>
											{almacen.nombre}
										</option>
									))}
								</select>
							</div>
							<div className="col-md-2">
								<label htmlFor="grupo">Grupo</label>
								<select
									className="form-control"
									name="grupo"
									id="grupo"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"grupo"
										)
									}
								>
									<option value="todos">Todos</option>
									{grupos.map((grupo) => (
										<option key={grupo.id} value={grupo.id}>
											{grupo.nombre}
										</option>
									))}
								</select>
							</div>
							<div className="col-md-2">
								<label htmlFor="tipo">Tipo</label>
								<select
									className="form-control"
									name="tipo"
									id="tipo"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"tipo"
										)
									}
								>
									<option value="todos">Todos</option>
									<option value="item">Items</option>
									<option value="servicio">Servicios</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div>
							{loading && <Loader />}
							{!items.length ? (
								<div>
									{!loading && (
										<div>Todavía no hay items creados.</div>
									)}
								</div>
							) : (
								<div className="table-data">
									<table className="table table-data2">
										<thead>
											<tr>
												<th>Nombre</th>
												<th>Ref.</th>
												<th>Ubicación</th>
												<th>Disponible</th>
												<th>Costo</th>
												<th>Impuesto</th>
												<th>Costo Total</th>
												<th>Total</th>
												<th>Descripción</th>
												<th />
											</tr>
										</thead>
										<tbody>
											{items.map((item) => (
												<tr
													className="tr-shadow"
													key={item.id}
												>
													<td>{item.nombre}</td>
													<td>{item.referencia}</td>
													<td style={{ width: 130 }}>
														{item.almacen &&
															item.almacen.nombre}
													</td>
													{item.inventariable ? (
														<td>
															{item.cantidad}{" "}
															{item.unidad}
														</td>
													) : (
														<td>&infin;</td>
													)}
													<td>{formatCurrency(item.costo)}</td>
													<td>{item.impuesto?.nombre}</td>
													<td>{formatCurrency(item.costo * (1 + (item.impuesto?.porcentaje ?? 0)))}</td>
													<td>
														{formatCurrency((item.costo * (1 + (item.impuesto?.porcentaje ?? 0))) * item.cantidad)}
													</td>
													<td>{ item.descripcion }</td>
													<td>
														<div className="table-data-feature">
															{item.fotos.length > 0 && (
																<button
																	title="Ver Fotos"
																	onClick={() =>
																		this.toggleFotosModal(
																			item
																		)
																	}
																	className="item"
																>
																	<i className="fas fa-eye"></i>
																</button>
															)}

															{item.cantidad >
																0 && (
																<button
																	title="Enviar"
																	onClick={() =>
																		this.toggleEnviarModal(
																			item
																		)
																	}
																	className="item"
																>
																	<i className="zmdi zmdi-truck" />
																</button>
															)}
															<Link
																data-toggle="tooltip"
																data-placement="top"
																title="Editar"
																data-original-title="Edit"
																className="item"
																to={getLink(
																	`/inventario/${item.id}/informacion`
																)}
															>
																<i className="zmdi zmdi-edit" />
															</Link>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									{this.state.links && (
										<Pagination
											links={this.state.links}
											meta={this.state.meta}
											getData={this.getItems}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				<Modal
					show={this.state.showEnviar}
					blank={true}
					toggle={() => {
						this.setState({
							showEnviar: !this.state.showEnviar,
						});
					}}
				>
					<Enviar
						toggle={() => {
							this.setState({
								showEnviar: !this.state.showEnviar,
							});
						}}
						item={this.state.item}
						getItems={this.getItems}
					/>
				</Modal>
				{this.state.item?.fotos && (
					<Modal
						show={this.state.showFotos}
						blank={true}
						toggle={() => {
							this.setState({
								showEnviar: !this.state.showFotos,
							});
						}}
					>
						<ItemFotos
							toggle={() => {
								this.setState({
									showFotos: !this.state.showFotos,
								});
							}}
							fotos={this.state.item.fotos}
						/>
					</Modal>
				)}
			</div>
		);
	}
}

export default withRequest(Items);
