import React, { Component, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { formatNumber } from "../../utils/helpers";


const AppLine = (props) => {
	const [state, setState] = useState({
		options: {
			chart: {
				id: "basic-bar",
					offsetX:0,				
			},
			xaxis: {
				categories: props.diasDelMes,
			},
			toolbar: {
				show: false,
			},
			legend: {
				position: "top",
				offsetY: 10,
				fontSize:'15px',
				fontFamily: 'Helvetica, Arial, sans-serif',
			
			},
			
			title: {
				text: "Ventas por mes:",
				align: "center",
				margin: 3,
				offsetX: 0,
				offsetY: 10,
				padding:10,
				floating: false,
				style: {
					color: "rgb(55, 61, 63)",
						fontSize: "15px",
						fontWeight: 600,
						fontFamily: "Helvetica, Arial, sans-serif",
				},
			},
			
			yaxis: {
				labels: {
				  formatter: function (value) {
					return formatNumber(value);
				  }
				},
			},
			  responsive: [
				{
				  breakpoint: 575,
				  options: {
					chart: {
					 width: "100%",
					
					},
					legend: {
						fontSize: "12px",
					},
					xaxis: {
						labels: {
						  style: {
							fontSize: "7px", // Adjust font size for responsive mode
						  },
						},
					  },
					  yaxis: {
				
						labels: {
							formatter: function (value) {
								return formatNumber(value);
							  },
						  style: {
							fontSize: "12px", // Adjust font size for responsive mode
						  },
						},
					  },
					
				  },
				},
			  ],
		},
		series: [],
	});
	
	useEffect(() => {
		if (!props.LastThreeMonthsChartDashboard || !props.diasDelMes) return;

		// Formatear los datos de LastThreeMonthsChartDashboard para el gráfico
		const formattedData = Object.keys(
			props.LastThreeMonthsChartDashboard
		).map((month) => ({
			name: getMonthNameInSpanish(month),
			data: props.LastThreeMonthsChartDashboard[month].map(
				(dayData) => dayData.total
			),
		}));
		setState((prevState) => ({
			...prevState,
			series: formattedData,
		}));
		
	}, [props]);

	const getMonthNameInSpanish = (month) => {
		// Define un mapeo de nombres de meses en inglés a español
		const monthMappings = {
			January: "Enero",
			February: "Febrero",
			March: "Marzo",
			April: "Abril",
			May: "Mayo",
			June: "Junio",
			July: "Julio",
			August: "Agosto",
			September: "Septiembre",
			October: "Octubre",
			November: "Noviembre",
			December: "Diciembre",
		};

		// Retorna el nombre del mes en español
		return monthMappings[month];
	};

	return (
		<div className="col app-dashboard">
			<style>
				{`
            .apexcharts-toolbar {
              display: none !important; 
			}
         
           
          `}
		  
			</style>
				<Chart
					options={state.options}
					series={state.series}
					type="line"
					height="297"
				/>
		</div>
	);
};

export default AppLine;
