import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import { toast } from "../../../utils/helpers";

import dayjs from "dayjs";

export default class AmonestacionesForm extends Component {
    state = {
        saving: false,
        fechaInicio: dayjs().format("YYYY-MM-DD"),
        intervalo: 1,
        cuotas: 1,
        monto: '',
    };
    updateField(field, value) {
        this.setState({
            [field]: value
        })
    }
    createAmonestacion = async event => {
        event.preventDefault();

        this.setState({
            saving: true
        });

        const { empleadoId } = this.props;
        const token = auth.getToken();
        const data = new FormData(event.target);

        const url = `empleados/${empleadoId}/sanciones`;

        const res = await fetch(apiUrl(url), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.props.getAmonestaciones();
            document.getElementById("amonestacionesform").reset();
            this.props.toggle();
            toast("Nuevo descuento creado.");
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false
        });
    };
    render() {
        const {cuotas, monto, fechaInicio, intervalo} = this.state;
        let proximasCuotas = [];

        if (cuotas > 1 && monto > 0) {
            const time = intervalo === 15 ? 'day' : 'month';
            for (let index = 0; index < cuotas; index++) {
                proximasCuotas.push({
                    fecha: dayjs(fechaInicio).add(index * intervalo, time).format("YYYY-MM-DD"),
                    monto: (monto / cuotas).toFixed(2),
                })
            }
        }

        return (
            <form
                id='amonestacionesform'
                method='post'
                onSubmit={this.createAmonestacion}>
                <input type='hidden' name='_method' value='post' />
                <div className='popup__header'>Crear Descuento Programado</div>
                <div className='popup__body'>
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Fecha Inicio:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <input
                                type='date'
                                name='fecha_inicio'
                                onChange={e => this.updateField('fechaInicio', e.target.value)}
                                value={fechaInicio}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Monto:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <input
                                type='number'
                                name='monto'
                                step='0.01'
                                onChange={e => this.updateField('monto', e.target.value)}
                                value={monto}
                                required
                                className='text-input form-control'
                            />
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Descripción:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <input
                                type='text'
                                name='descripcion'
                                required
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Cuotas:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <input
                                type='number'
                                step='1'
                                onChange={e => this.updateField('cuotas', e.target.value)}
                                value={cuotas}
                                className='text-input form-control'
                            />
                        </div>
                    </div>
                    {cuotas > 1 &&
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Intervalo:
                                </label>
                            </div>
                                <div className='col col-md-8'>
                                    <label htmlFor="intervalo_mes" className="inline">
                                        <input
                                            type='radio'
                                            name='frecuencia'
                                            id="intervalo_mes"
                                            checked={intervalo === 1}
                                            onChange={e => this.updateField('intervalo', 1)}
                                            value='1'
                                        />
                                        Mensual
                                    </label>
                                    {'    '}
                                    <label htmlFor="intervalo_quincenal" className="inline">
                                        <input
                                            type='radio'
                                            name='frecuencia'
                                            id="intervalo_quincenal"
                                            checked={intervalo === 15}
                                            onChange={e => this.updateField('intervalo', 15)}
                                            value='15'
                                        />
                                        Quincenal
                                    </label>
                                    {'    '}
                                    <label htmlFor="intervalo_semanal" className="inline">
                                        <input
                                            type='radio'
                                            name='frecuencia'
                                            id="intervalo_semanal"
                                            checked={intervalo === 7}
                                            onChange={e => this.updateField('intervalo', 7)}
                                            value='7'
                                        />
                                        Semanal
                                    </label>
                                </div>
                        </div>
                    }
                    <div className="row form-group">
                        <div className="col-md-12">
                            <table className="table">
                                {proximasCuotas.map((cuota, index) => {
                                    return <tr key="index">
                                        <td>
                                            <label>Monto</label>
                                            <input type="text" name={`cuotas[${index}][monto]`} className="text-input form-control" readOnly value={cuota.monto} />
                                        </td>
                                        <td>
                                            <label>Fecha</label>
                                            <input type="date" name={`cuotas[${index}][fecha]`} className="text-input form-control" readOnly value={cuota.fecha}/>
                                        </td>
                                    </tr>
                                })}
                            </table>
                        </div>
                    </div>
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {this.state.saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Crear
                        </button>
                    )}
                </div>
            </form>
        );
    }
}
