import React, { Component } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import Select from "react-select";
import SelectAsync from "react-select/async";
import { getLink } from "../../../utils/helpers";
import Loader from "../../general/Loader";
import Pagination from "../../general/Pagination";
import { withRequest } from "../../../utils/request";
import Modal from "../../general/Modal";
import HistorialPagos from "./HistorialPagos";
import { estadosEstudiantes } from "./EstudianteContext";


const classesEstudiantes = {
    'Inscrito': 'badge badge-success',
    'Inactivo': 'badge badge-secondary',
    'evaluacion': 'badge badge-warning',
    'Evaluado': 'badge badge-primary',
}

class Estudiantes extends Component {
    state = {
        estudiantes: [],
        links: [],
        meta: [],
        estudiante: {},
        filtros: {
            buscar: "",
            tipo: "",
            estado: "todos",
        },
        loading: true,
        showCompararTss: false,
        filtrosQuery: "",
        showHistorialPagos: false,
    };

    componentDidMount() {
        this.getEstudiantes();
    }

    getEstudiantes = async (url = null) => {
        url = url ? url + "&limit=12" : "academico/estudiantes?limit=12";
        const estudiantes = await this.props.request(url);

        if (estudiantes.links) {
            this.setState({
                estudiantes: estudiantes.data,
                links: estudiantes.links,
                meta: estudiantes.meta,
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
            });
        }
    };

    getTable = async (url, name = null) => {
        const request = await this.props.request(url);
        const table = name ? name : url;

        if (request.data) {
            this.setState({
                [table]: request.data,
            });
            this.setState({
                loading: false,
            });
        }
    };

    getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await this.props.request(url);
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };

    toggleShowHistorialPagos = () => {
        this.setState(prev => ({
            showHistorialPagos: !prev.showHistorialPagos
        }));
    }

    setEstudiante = (estudiante) => {
        this.setState({
            estudiante: estudiante
        });
    }

    onFiltroChange = debounce(async (value, filtro) => {
        if (!value) {
            value = '';
        }
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: encodeURIComponent(value),
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getEstudiantes("academico/estudiantes?" + query.join("&"));
    }, 500);

    displayFilters = () => {
        document.getElementById('mas-filtros').classList.toggle('d-none');
    }

    render() {
        const { estudiantes, estudiante, showHistorialPagos, loading, filtrosQuery } = this.state;
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Listado de Estudiantes</span>

                        <div className='dropdown m-r-10'>
                            <button className='au-btn au-btn-icon au-btn-filter'>
                                <i className='zmdi zmdi-print'></i> Imprimir
                            </button>
                            <ul
                                style={{ right: "0", left: "auto" }}
                                className='dropdown-menu'>
                                <li>
                                    <Link
                                        to={getLink(
                                            `/imprimir/academico/estudiantes${filtrosQuery}`
                                            )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        {" "}
                                        Listado Estudiantes
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <Link
                            to={getLink("/academico/estudiantes/crear")}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Estudiante
                        </Link>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='search'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='grado'>Grado</label>
                                    <SelectAsync
                                        name='grado'
                                        key='grado'
                                        cacheOptions
                                        placeholder="Seleccionar..."
                                        defaultOptions
                                        isClearable
                                        onChange={(e) => this.onFiltroChange(e?.value, "grado")}
                                        loadOptions={(e) => this.getOptions("academico/grados?buscar="+e, 'nombreCompleto')}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='seccion'>Sección</label>
                                    <SelectAsync
                                        name='seccion'
                                        key='seccion'
                                        cacheOptions
                                        placeholder="Seleccionar..."
                                        defaultOptions
                                        isClearable
                                        onChange={(e) => this.onFiltroChange(e?.value, "seccion")}
                                        loadOptions={(e) => this.getOptions("academico/secciones?buscar="+e)}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Estado</label>
                                    <Select
                                        name='estado'
                                        key='estado'
                                        defaultOptions
                                        placeholder="Seleccionar..."
                                        options={[
                                            {
                                                label: "Todos",
                                                value: "",
                                            },
                                            ...Object.keys(estadosEstudiantes).filter(e => e !== 'evaluacion').map(e => estadosEstudiantes[e])]}
                                        onChange={(e) => this.onFiltroChange(e?.value, "estado")}
                                    />
                                </div>
                                <div style={{width: '50px'}} className="filtros-avanzados" onClick={this.displayFilters}>
                                    <label>&nbsp;</label>
                                    <button className="btn" title="Filtros Avanzados"><i className="zmdi zmdi-filter-list"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mas-filtros" className="row mas-filtros d-none m-b-25">
                        <div className='col-md-2'>
                            <label htmlFor='beca'>Beca</label>
                            <SelectAsync
                                name='beca'
                                key='beca'
                                cacheOptions
                                defaultOptions
                                placeholder="Buscar..."
                                isClearable
                                onChange={(e) => this.onFiltroChange(e?.value, "beca")}
                                loadOptions={(e) => this.getOptions("academico/becas?buscar="+e)}
                            />
                        </div>
                        <div className='col-md-2'>
                            <label>Nacionalidad</label>
                            <SelectAsync
                                name='nacionalidad'
                                key='nacionalidad'
                                isClearable
                                cacheOptions
                                defaultOptions
                                placeholder="Seleccionar..."
                                loadOptions={e => this.getOptions("taxonomias?relacion=Estudiantes&tipo=Nacionalidad&buscar="+e, "nombre", "nombre")}
                                onChange={(e) => this.onFiltroChange(e?.value, "nacionalidad")}
                            />
                        </div>
                        <div className='col-md-2'>
                            <label>Sexo</label>
                            <Select
                                name='sexo'
                                key='sexo'
                                isClearable
                                placeholder="Seleccionar..."
                                options={[
                                    {label: 'Todos', value: 'todos'},
                                    {label: 'Masculino', value: 'M'},
                                    {label: 'Femenino', value: 'F'},
                                ]}
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.value,
                                        "sexo"
                                    )
                                }
                                />
                        </div>
                        <div className='col-md-2'>
                            <label htmlFor='desde'>Tipo de Sangre</label>
                            <Select
                                key='grupo_sanguineo'
                                name='grupo_sanguineo'
                                isClearable
                                placeholder="Seleccionar..."
                                options={[
                                    {label: 'Todos', value: 'todos'},
                                    {label: 'A+', value: 'A+'},
                                    {label: 'A-', value: 'A-'},
                                    {label: 'B+', value: 'B+'},
                                    {label: 'B-', value: 'B-'},
                                    {label: 'AB+', value: 'AB+'},
                                    {label: 'AB-', value: 'AB-'},
                                    {label: 'O+', value: 'O+'},
                                    {label: 'O-', value: 'O-'},
                                ]}
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.value,
                                        "grupo_sanguineo"
                                    )
                                }
                                />
                        </div>
                    </div>
                </div>
                <div className='estudiantes-list'>
                    {loading && <Loader />}
                    {!estudiantes.length ? (
                        <div>
                            {!loading && <div>No hay Estudiantes creados.</div>}
                        </div>
                    ) : (
                        <div className='table-data'>
                            <table className="table table-data2">
                                <thead>
                                    <tr>
                                        <th width="50px"></th>
                                        <th>Estudiante</th>
                                        <th>Grado</th>
                                        <th className="text-center">Sección</th>
                                        <th>Nacionalidad</th>
                                        <th>Beca</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {estudiantes.map((estudiante) => (
                                        <EstudianteCard
                                            key={estudiante.id}
                                            estudiante={estudiante}
                                            setEstudiante={this.setEstudiante}
                                            toggleShowHistorialPagos={this.toggleShowHistorialPagos}
                                        />
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                links={this.state.links}
                                meta={this.state.meta}
                                getData={this.getEstudiantes}
                            />
                        </div>
                    )}
                </div>
                <Modal
                    title="Historial de Pagos"
                    show={showHistorialPagos}
                    toggle={this.toggleShowHistorialPagos}
                    showCancelBtn={false}
                    acceptText={"Cerrar"}
                    size="1024px"
                >
                    <HistorialPagos estudiante={estudiante} />
                </Modal>
            </div>
        );
    }
}

const EstudianteCard = ({estudiante, setEstudiante, toggleShowHistorialPagos}) => {
    const inscripcionDisponible = estudiante.inscripcion_disponible.reduce((acc, ins) => {
        return ins.disponible ? true : acc;
    }, false);
    return (
        <tr className="tr-shadow">
            <td>{estudiante.foto && (
                <div className='col-md-3'>
                    <span className='page-title-foto'>
                        <img
                            src={estudiante.foto}
                            alt={estudiante.codigo}
                        />
                    </span>
                </div>
            )}</td>
            <td>
                <Link to={getLink(`/academico/estudiantes/${estudiante.id}/informacion`)}>
                    <strong>{estudiante.nombreCompleto}</strong>
                    <div>{estudiante.matricula} - <small className={`uppercase ${classesEstudiantes[estudiante.estado]}`}>{estudiante.estado}</small></div>
                </Link>
            </td>
            <td>
                {estudiante.curso?.grado?.nombre}
            </td>
            <td className="text-center">
                {estudiante.curso?.seccion?.nombre}
            </td>
            <td>{estudiante.nacionalidad}</td>
            <td>
                {estudiante.curso?.beca?.nombre}
            </td>
            <td>
                <div className="table-data-feature">
                    <div className="dropdown">
                        <button className="btn btn-secondary">
                            Acciones <i className="zmdi zmdi-chevron-down"></i>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right">
                            <li>
                                <Link to={getLink(`/academico/estudiantes/${estudiante.id}/informacion`)}
                                     className="dropdown-item">Editar</Link>
                            </li>
                            {inscripcionDisponible && estudiante.curso ? (
                                <li>
                                    <Link to={getLink(`/academico/estudiantes/${estudiante.id}/informacion?reservar-cupo=1`)}
                                        className="dropdown-item">Reservar Cupo</Link>
                                </li>
                            ) : (
                                <li>
                                    <Link to={getLink(`/academico/estudiantes/${estudiante.id}/informacion?inscripcion=1`)}
                                        className="dropdown-item">Inscribir</Link>
                                </li>
                            )}
                            <li>
                                <Link to={getLink('/imprimir/academico/estudiantes/' + estudiante.id + '/ficha')} target="_blank" className="dropdown-item">Ficha Estudiante</Link>
                            </li>
                            {estudiante.curso && (
                                <li>
                                    <button type="button" onClick={() => {
                                        setEstudiante(estudiante);
                                        toggleShowHistorialPagos();
                                    }} className="dropdown-item">Historial de Pagos</button>
                                </li>
                            )}
                            {estudiante.documentos.length > 0 && <li className="dropdown-divider"></li>}
                            {estudiante.documentos.map(documento => (
                                <li>
                                    <a href={documento.url} className="dropdown-item" target="_blank">
                                        {documento.nombre}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {estudiante.curso && (
                        <Link to={getLink(`/academico/facturar?estudiante_id=${estudiante.id}`)} target="_blank" className="btn btn-primary m-l-10">
                            Cobros
                        </Link>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default withRequest(Estudiantes);
