import React from "react";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";

import { formatCurrency } from "../../../utils/helpers";
import useSalarioNavidad from "./Hooks/useSalarioNavidad";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const ComprobanteSalarioNavidad = () => {
    const {
        orden,
        onChangeOrden,
        sucursal,
        redondear,
        redondeo,
        empleados,
        onChangeRedondear,
        params,
    } = useSalarioNavidad();

    return (
        <div className={'comprobantes-row ' + params.get('tipo')}>
            <style>{`@page {size: 8.5in 11in;}`}</style>
            <div className="ordernar-resultados no-print">
                <div className="form-group">
                    <div className="radio-btn">
                        <label>
                            <input type="radio" onChange={onChangeOrden} name="orden" value="nombre" defaultChecked={true} /> Ordenar por Nombre
                        </label>
                        <label>
                            <input type="radio" onChange={onChangeOrden} name="orden" value="codigo" /> Ordenar por Código
                        </label>
                        <label>
                            <input type="checkbox" onChange={onChangeRedondear} name="redondear" value="redondear" /> Redondear
                        </label>
                    </div>
                </div>
            </div>
            {empleados.sort((a, b) => {
                if ( orden === 'codigo') {
                    if (!a.codigo) {
                        return 1;
                    }
                    return a.codigo.localeCompare(b.codigo);
                }

                return a.nombre.localeCompare(b.nombre);
            }).map((empleado) => {
                return (
                    <p key={empleado.id} className='comprobante-pago'>
                        <div className='text-center'>
                            <div>
                                <strong
                                    style={{
                                        textTransform: "uppercase",
                                    }}>
                                    {sucursal.nombre}
                                </strong>
                            </div>
                            <div>
                                <small>
                                    {sucursal.direccion} •{" "}
                                    {sucursal.notas}
                                </small>
                            </div>
                            <div>
                                <small>
                                    Fecha:{" "}
                                    {new Date().toLocaleString()}
                                </small>
                            </div>
                        </div>
                        <br />
                        <div>
                            <strong>Nombre:</strong> {empleado.nombre} {empleado.apellido}
                        </div>
                        <div>
                            <strong>Código:</strong> {empleado.codigo}
                        </div>
                        <div>
                            <strong>Cédula:</strong> {empleado.cedula}
                        </div>
                        <div>
                            <strong>Tiempo Trabajando:</strong>{" "}
                            {empleado.fecha_ingreso && (<>
                                {empleado.anos > 0 && (<span>{empleado.anos} año(s), </span>)}
                                {empleado.meses < 12 && empleado.meses > 0 && (<span>{empleado.meses} mes(es)</span>)}
                                {empleado.dias > 0 && (<span> y {empleado.dias} día(s)</span>)}
                            </>)}
                        </div>
                        <br />
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <th>Sueldo Mensual</th>
                                    <td className='text-right'>
                                        {formatCurrency(empleado.salario_bruto)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Mensualidad Navidad</th>
                                    <td className='text-right'>
                                        {formatCurrency(empleado.salariom)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Diario Navidad</th>
                                    <td className='text-right'>
                                        {formatCurrency(empleado.salariod)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Prestaciones</th>
                                    <td className='text-right'>
                                        {empleado.fecha_ingreso && formatCurrency( redondeo ? redondear(empleado.prestaciones) : empleado.prestaciones)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <hr />
                        <div className='text-center'>
                            Firma Empleado
                        </div>
                    </p>
                );
            })}
        </div>
    );
}

export default ComprobanteSalarioNavidad;
