/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { request } from "../../../../utils/request";
import { formatCurrency, getSucursal, money } from "../../../../utils/helpers";
import dayjs from "dayjs";
import { apiRequest } from "../../../../hooks/apiRequest";

const tipos_impuesto = {
	itbis: "Itbis",
	otro: "Otro",
	isc: "ISC",
	propina_legal: "Propina",
	no_facturable: "No facturable",
	exento: "Exento",
};

const tiposComprobante = [
	"B01",
	"B02",
	"B04",
	"B14",
	"B15",
	"B16",
	"B11",
	"B03",
	"B11",
];

const getDiasPlazo = (fecha, fecha_vencimiento) => {
	fecha = new Date(fecha);
	fecha_vencimiento = new Date(fecha_vencimiento);

	let difference = fecha.getTime() - fecha_vencimiento.getTime();
	let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
	return Math.abs(TotalDays);
};

const tipoITBIS = (impuesto) => {
	if (!impuesto) return "";
	if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0.16) {
		return "I1";
	} else if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0.18) {
		return "I2";
	} else if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0) {
		return "IE";
	}
};

const calcularImpuestos = (productos) => {
	let impuestos = {};

	if (productos) {
		productos.forEach((producto) => {
			if (
				!tipos_impuesto[producto.impuesto?.tipo] ||
				producto.impuesto?.porcentaje === 0
			)
				return;
			if (
				isNaN(
					impuestos[
						`${tipos_impuesto[producto.impuesto.tipo]} ${
							producto.impuesto.porcentaje * 100
						}%`
					]
				)
			) {
				impuestos[
					`${tipos_impuesto[producto.impuesto.tipo]} ${
						producto.impuesto.porcentaje * 100
					}%`
				] = 0;
			}
			impuestos[
				`${tipos_impuesto[producto.impuesto.tipo]} ${
					producto.impuesto.porcentaje * 100
				}%`
			] +=
				producto.cantidad *
				(producto.importe * producto.impuesto?.porcentaje);
		});
	}

	return impuestos;
};

const PrintSingleNotaCredito = () => {
	const { id } = useParams();
	const location = useLocation();

	const [notaCredito, setNotaCredito] = useState({
		productos: [],
	});
	const [sucursal, setSucursal] = useState({});
	const [infoBlock2Margin, setInfoBlock2Margin] = useState(10);
	const [isMobileDevice, setIsMobileDevice] = useState(false);

	const [sumatoriaFinal, setSumatoriaFinal] = useState({
		impuestos: {},
	});
	const [status, setStatus] = useState("Original");

	const getNotaCredito = () => {
		request("notas-credito/" + id).then((res) => {
			setNotaCredito(res.data);
			setTimeout(() => {
				window.print();
			}, 1000);
		});
	};

	const isFactura = (notaCredito) => {
		return (
			tiposComprobante.findIndex(
				(tipo) => tipo === notaCredito.numeracion?.substring(0, 3)
			) >= 0
		);
	};

	const onChangeStatusFactura = (estado) => {
		setStatus(estado);
	};

	useEffect(() => {
		getNotaCredito();
		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);
		// window.addEventListener("afterprint", () => {
		// 	apiRequest(null, `facturas/${id}/update-printed`, "put");
		// });
		// identify if mobile
		let isMobile = navigator.userAgent.toLowerCase().match(/mobile/i),
			isTablet = navigator.userAgent.toLowerCase().match(/tablet/i),
			isAndroid = navigator.userAgent.toLowerCase().match(/android/i),
			isiPhone = navigator.userAgent.toLowerCase().match(/iphone/i),
			isiPad = navigator.userAgent.toLowerCase().match(/ipad/i);

		if (isMobile || isTablet || isAndroid || isiPhone || isiPad) {
			setIsMobileDevice(true);
		}
	}, []);
	useEffect(() => {
		setSumatoriaFinal((state) => ({
			...state,
			impuestos: calcularImpuestos(notaCredito.productos),
		}));
		// This is for calculate the margin of the second column of client info. that way the last row of each column will be alined\
		if (isMobileDevice) return;

		const infoBlock1 = document.getElementById("info-block-1");
		const infoBlock2 = document.getElementById("info-block-2");
		const count =
			infoBlock2.childElementCount - infoBlock1.childElementCount;

		if (count >= 5 && !notaCredito.logo && !sucursal.logo) {
			setInfoBlock2Margin((count * 25 - 40) * -1);
		} else {
			setInfoBlock2Margin((count * 25 + 10) * -1);
		}
	}, [notaCredito]);

    const sinDevolucion = notaCredito.razon?.tiene_devolucion === 'No';

	return (
		<>
			<NotaCreditoTemplate
				notaCredito={notaCredito}
				sucursal={sucursal}
				infoBlock2Margin={infoBlock2Margin}
				sumatoriaFinal={sumatoriaFinal}
				isFactura={isFactura}
				status={status}
				isMobileDevice={isMobileDevice}
                sinDevolucion={sinDevolucion}
			/>
			{/* Segunda factura (Se usa en las facturas dobles) */}
			{location.pathname.includes("doble") && (
				<>
					<div
						style={{ borderTop: "2px dashed #000" }}
						className="my-4"
					></div>
					<NotaCreditoTemplate
						notaCredito={notaCredito}
						sucursal={sucursal}
						infoBlock2Margin={infoBlock2Margin}
						sumatoriaFinal={sumatoriaFinal}
						isFactura={isFactura}
						status={status}
						isMobileDevice={isMobileDevice}
					/>
				</>
			)}
			<div className="ordernar-resultados no-print">
				<div className="form-group">
					<div className="radio-btn">
						<label>
							<input
								type="radio"
								onChange={() =>
									onChangeStatusFactura("Original")
								}
								name="orden"
								value="Original"
								defaultChecked={true}
							/>{" "}
							Original
						</label>
						<label>
							<input
								type="radio"
								onChange={() => onChangeStatusFactura("Copia")}
								name="orden"
								value="Copia"
							/>{" "}
							Copia
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export const NotaCreditoTemplate = ({
	notaCredito,
	sucursal,
	infoBlock2Margin,
	sumatoriaFinal,
	isFactura = () => false,
	status = "",
	isMobileDevice = false,
    sinDevolucion
}) => {
	return (
		<>
			{isMobileDevice ? (
				<MobileLayout
					notaCredito={notaCredito}
					sucursal={sucursal}
					sumatoriaFinal={sumatoriaFinal}
					status={status}
                    sinDevolucion={sinDevolucion}
				/>
			) : (
				<>
					<DesktopLayout
						notaCredito={notaCredito}
						sucursal={sucursal}
						sumatoriaFinal={sumatoriaFinal}
						status={status}
						infoBlock2Margin={infoBlock2Margin}
                        sinDevolucion={sinDevolucion}
					/>
				</>
			)}
		</>
	);
};

const MobileLayout = ({
	notaCredito,
	sucursal,
	sumatoriaFinal,
	isFactura = () => false,
	status,
}) => {
	return (
		<>
			<style>
				{`
					@page {
						size: 90mm 300mm !important;
						margin-top: 30px !important;
						margin-bottom: 0px !important;
						margin-left: 0px !important;
						margin-right: 0px !important;
					}
					.invoice {
						width: 125mm;
						font-size: large !important;
					 }
					 body {
						width: 100% !important;
					}
					.invoice-table {
						display: none;
					}
					.sm-invoice-table {
						display: block;
					}

					.invoice h3 {
						margin-bottom: 0.1rem;
					}
					.invoice h3.signature-title {
						font-size: 0.9rem;
						font-weight: 600 !important;
					}
					.client-info-title {
						font-size: 19px;
						font-weight: 600 !important;
					}
					.info-cliente-responsive .info-client__response {
						font-size: 18px !important;
						line-height: 20px !important;
					}
					.sucursal-info h3 {
						font-size: 23px !important;
						margin-bottom: 8px !important;
						font-weight: 600 !important;
					}
					.invoice-name {
						font-weight: 600 !important;
					}
					.sucursal-info div, .invoice-fecha {
						font-size: 18px !important;
					}
					.sm-invoice-table > div.row {
						font-size: 17.5px !important;
					}
					.invoice-itbis-info p, .invoice-itbis-info div {
						font-size: 19px !important;
						line-height: 22px !important;
					}
					.summary-text {
						font-size: 20px !important;
						line-height: 20px !important;
					}
					.info-cliente-responsive {
						line-height: 20px !important;
						margin-bottom: 1rem !important;
						font-size: 20px !important;
						margin-left: 5px !important;
					}
					.info-cliente-responsive .row{
						margin-bottom: 6px;
					}
				`}
			</style>
			<div className="p-2 invoice recibo-print">
				<div className="clear-both p-t-30 p-b-10">
					<div className="sucursal-info sucursal-info__mobile">
						{sucursal.logo ? (
							<img
								alt="logo"
								className="mb-2 factura-logo"
								src={sucursal.logo[0].url}
								width="270px"
							/>
						) : notaCredito.logo ? (
							<img
								alt="logo"
								className="mb-2 factura-logo"
								src={notaCredito.logo[0].url}
								width="270px"
							/>
						) : (
							<h3
								style={{
									lineHeight: "16px",
									marginBottom: "3px",
								}}
							>
								{sucursal.nombre}
							</h3>
						)}
						<div
							className="mb-0"
							style={{ lineHeight: "16px", fontSize: "23px" }}
						>
							RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
						</div>
						<div
							className="mb-2"
							style={{ lineHeight: "16px", fontSize: "13px" }}
						>
							{sucursal.direccion}
						</div>
					</div>

					<div className="">
						<div
							className="mb-2 invoice-fecha d-block"
							style={{ fontSize: "medium", lineHeight: "16px" }}
						>
							Fecha:{" "}
							{dayjs(notaCredito.fecha).format("DD/MM/YYYY")}
						</div>
						<h3
							style={{
								fontSize: "18px",
								lineHeight: "14px",
								marginBottom: "3px",
							}}
							className="d-block invoice-name"
						>
							{tiposComprobante.findIndex(
								(tipo) =>
									tipo ===
									notaCredito.numeracion?.substring(0, 3)
							) >= 0
								? notaCredito.numeracion_documento?.nombre
								: "Nota de crédito"}
						</h3>
						<div
							className="d-block"
							style={{ fontSize: "medium", lineHeight: "16px" }}
						>
							<span>
								{tiposComprobante.findIndex(
									(tipo) =>
										tipo ===
										notaCredito.numeracion?.substring(0, 3)
								) >= 0
									? "NCF: "
									: "NO. "}
							</span>
							{notaCredito.numeracion}
							<br />
							{notaCredito.numeracion_documento
								?.fecha_vencimiento && (
								<>
									<span>FV:</span>{" "}
									{dayjs(
										notaCredito.numeracion_documento
											?.fecha_vencimiento
									).format("DD/MM/YYYY")}
								</>
							)}
						</div>
						<span className="d-block client-info-title pt-2">
							{status}
						</span>
					</div>
				</div>

				<div className="info-cliente-responsive d-block">
					{notaCredito.contacto && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Cliente:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{notaCredito.contacto?.nombre}
							</div>
						</div>
					)}
					{notaCredito.contacto?.identificacion && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									RNC/Cédula:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{notaCredito.contacto?.identificacion}
							</div>
						</div>
					)}
					{notaCredito.contacto?.encargado && (
						<div className="row">
							<div className="col-4 text-right px-1 client-info-title">
								Responsable:
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{notaCredito.contacto?.encargado}
							</div>
						</div>
					)}
					{notaCredito.contacto?.telefono && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Teléfono:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{notaCredito.contacto?.telefono}
							</div>
						</div>
					)}
					{notaCredito.contacto?.direccion && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Dirección:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "23px" }}
							>
								{notaCredito.contacto?.direccion}
							</div>
						</div>
					)}
				</div>

				<h3 className="table-title"></h3>
				{/* PRODUCTOS RESPONSIVE */}
				<div className="sm-invoice-table">
					<div className="row" style={{ fontWeight: "600" }}>
						<div className="col-5">DESCRIPCIÓN</div>
						<div className="col-3 px-0">IMPUESTOS</div>
						<div className="col-4" style={{ whiteSpace: "nowrap" }}>
							TOTAL A PAGAR
						</div>
					</div>

					{notaCredito.productos?.map((producto) => {
						return (
							<div
								className="row"
								style={{
									fontSize: "small",
									marginBottom: "5px",
								}}
								key={producto.id}
							>
								<div
									className="col-5"
									style={{ lineHeight: "16px" }}
								>
									{`${producto.cantidad} x ${formatCurrency(
										producto.precio_facturado,
										""
									)}`}
									<br />
									{producto.producto?.nombre}
									<br />
									REF: {producto.producto?.referencia}
								</div>
								<div className="col-3 px-0">
									<span className="fw-600">
										{tipoITBIS(producto.impuesto)}
									</span>{" "}
									{formatCurrency(
										producto.precio_facturado *
											producto.impuesto?.porcentaje,
										"$"
									)}
								</div>
								<div className="col-3 pr-0">
									{formatCurrency(producto.total, "$")}{" "}
								</div>
							</div>
						);
					})}
				</div>

				<div className="summary mt-3">
                    {notaCredito.descuentos && (
                        <div className="row justify-content-between">
                            <div
                                className="col-2 summary-text"
                                style={{
                                    maxWidth: "8rem",
                                    lineHeight: "16px",
                                    textAlign: "end",
                                    fontWeight: "600",
                                }}
                            >
                                Descuento
                            </div>
                            <div
                                className="col-2 text-right summary-text"
                                style={{ maxWidth: "9rem", lineHeight: "16px" }}
                            >
                                -{formatCurrency(notaCredito.descuentos)}
                            </div>
                        </div>
                    )}
					<div className="row justify-content-between justify-content-md-end">
						<div
							className="col-2 summary-text"
							style={{
								maxWidth: "8rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							Subtotal
						</div>
						<div
							className="col-2 text-right summary-text"
							style={{ maxWidth: "9rem", lineHeight: "16px" }}
						>
							{formatCurrency(notaCredito.subtotal, "$")}
						</div>
					</div>
					<div className="d-block">
						{Object.keys(sumatoriaFinal.impuestos).map(
							(impuesto, index) => {
								return (
									<div
										className="row justify-content-between"
										key={index}
									>
										<div
											className="col summary-text"
											style={{
												maxWidth: "8rem",
												lineHeight: "16px",
												fontWeight: "600",
											}}
										>
											{impuesto}
										</div>
										<div
											className="col-2 text-right summary-text"
											style={{
												maxWidth: "9rem",
												lineHeight: "16px",
											}}
										>
											{formatCurrency(
												sumatoriaFinal.impuestos[
													impuesto
												],
												"$"
											)}
										</div>
									</div>
								);
							}
						)}
					</div>

					<div className="row justify-content-between">
						<div
							className="col summary-text"
							style={{
								maxWidth: "11rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							TOTAL A PAGAR
						</div>
						<div
							className="col-2 text-right summary-text"
							style={{
								maxWidth: "9rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							{formatCurrency(notaCredito.total)}
						</div>
					</div>
					{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
						<hr className="mb-0" />
					)}

					<div className="invoice-itbis-info">
						{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
							<p
								style={{
									lineHeight: "16px",
									fontSize: "small",
								}}
							>
								{Object.keys(sumatoriaFinal.impuestos).includes(
									"Itbis 16%"
								) && (
									<>
										<span>
											Items con I1 son gravados con 16%
											ITBIS
										</span>
										<br />
									</>
								)}
								{Object.keys(sumatoriaFinal.impuestos).includes(
									"Itbis 18%"
								) && (
									<span>
										Items con I2 son gravados con 18% ITBIS
									</span>
								)}
							</p>
						)}

						{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
							<div className="row justify-content-between">
								<div
									className="col-6 pr-1"
									style={{
										maxWidth: "11rem",
										fontSize: "small",
										lineHeight: "16px",
										fontWeight: "600",
										whiteSpace: "nowrap",
									}}
								>
									TOTAL IMPUESTOS
								</div>
								<div
									className="col-2"
									style={{
										maxWidth: "9rem",
										fontSize: "small",
										lineHeight: "16px",
										fontWeight: "600",
									}}
								>
									{formatCurrency(notaCredito.impuestos, "$")}
								</div>
							</div>
						)}
					</div>
				</div>

				<hr className="d-block mt-0" />
				{notaCredito.notas && (
					<>
						<p style={{ fontWeight: "600", fontSize: "1rem" }}>
							Notas:
						</p>
						<p
							className="col-12 pl-0 mb-3"
							style={{
								fontSize: "1rem",
								whiteSpace: "pre-line",
								lineHeight: "normal",
							}}
						>
							{notaCredito.notas}
						</p>
					</>
				)}

				<div className="row justify-content-center mt-5">
					<h3
						className="col-10 text-center mr-0 fw-600 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Elaborado por
					</h3>
					<h3
						className="col-10 text-center ml-0 fw-600 mt-5 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Recibido por
					</h3>
				</div>

				{notaCredito.plantilla?.pie_pagina && (
					<div
						className="col-12 pt-5 text-center d-flex justify-content-center"
						style={{
							fontWeight: "600",
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						<p className="text-center pie-pagina">
							{notaCredito.plantilla?.pie_pagina}
						</p>
					</div>
				)}
			</div>
		</>
	);
};

const DesktopLayout = ({
	notaCredito,
	sucursal,
	sumatoriaFinal,
	infoBlock2Margin,
	status,
	isFactura = () => false,
    sinDevolucion,
}) => {
	const renderTableRow = (producto) => {
		if (producto.producto) {
			return (
				<tr key={producto.id}>
					<td>
						<div style={{ lineHeight: 1.2 }}>
							<div>{producto.producto?.referencia}</div>
						</div>
					</td>
					<td>{producto.producto?.nombre || producto.nombre_producto}</td>
					<td>{producto.cantidad}</td>
					<td>{producto.producto?.unidad}</td>
					<td>{formatCurrency(producto.importe, "$")}</td>
					<td style={{ whiteSpace: "nowrap" }}>
						<span className="fw-600">
							{tipoITBIS(producto.impuesto)}
						</span>{" "}
						{formatCurrency(money(producto.impuestos), "$")}
					</td>
					<td>{formatCurrency(producto.precio_facturado, "$")}</td>
					{notaCredito.descuentos > 0 && (
						<td>
							{formatCurrency(
								producto.cantidad *
									(producto.importe + producto.impuestos) *
									(producto.descuento / 100)
							)}
						</td>
					)}
					<td>{formatCurrency(producto.total)}</td>
				</tr>
			);
		}
		return (
			<tr key={producto.id}>
				<td>
					<div style={{ lineHeight: 1.2 }}>
						<div>{producto.item?.referencia || "--"}</div>
					</div>
				</td>
				<td>{producto.item?.nombre || producto.nombre_producto}</td>
				<td>{producto.cantidad}</td>
				<td>{producto.item?.unidad}</td>
				<td>{formatCurrency(producto.importe, "$")}</td>
				<td style={{ whiteSpace: "nowrap" }}>
					<span className="fw-600">
						{tipoITBIS(producto.impuesto)}
					</span>{" "}
					{formatCurrency(money(producto.impuestos), "$")}
				</td>
				<td>{formatCurrency(producto.precio_facturado, "$")}</td>
				{notaCredito.descuentos > 0 && (
					<td>
						{formatCurrency(
							producto.cantidad *
								(producto.importe + producto.impuestos) *
								(producto.descuento / 100)
						)}
					</td>
				)}
				<td>{formatCurrency(producto.total)}</td>
			</tr>
		);
	};

	return (
		<div className="p-2 invoice recibo-print">
			<div className="clear-both p-t-30 p-b-10">
				<div className="float-md-left sucursal-info">
					{sucursal.logo ? (
						<img
							alt="logo"
							className="mb-2 factura-logo"
							src={sucursal.logo[0].url}
							width="270px"
						/>
					) : notaCredito.logo ? (
						<img
							alt="logo"
							className="mb-2 factura-logo"
							src={notaCredito.logo[0].url}
							width="270px"
						/>
					) : (
						<h3 style={{ lineHeight: "12px", marginBottom: "3px" }}>
							{sucursal.nombre}
						</h3>
					)}
					<div
						className="mb-0 mb-md-0"
						style={{ lineHeight: "15px", fontSize: "13px" }}
					>
						RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
					</div>
					<div
						className="mb-2 mb-md-0"
						style={{ lineHeight: "15px", fontSize: "13px" }}
					>
						{sucursal.direccion}
					</div>
				</div>

				<div className="float-md-right text-md-right">
					<div
						className="mb-2 invoice-fecha d-block d-md-none"
						style={{ fontSize: "small", lineHeight: "15px" }}
					>
						Fecha: {dayjs(notaCredito.fecha).format("DD/MM/YYYY")}
					</div>
					<h3
						style={{
							fontSize: "15px",
							lineHeight: "12px",
							marginBottom: "3px",
						}}
						className="d-block d-md-none"
					>
						Nota de Crédito
					</h3>
					<div
						className="d-block d-md-none"
						style={{ fontSize: "small", lineHeight: "12px" }}
					>
						<span>
							{tiposComprobante.findIndex(
								(tipo) =>
									tipo ===
									notaCredito.numeracion?.substring(0, 3)
							) >= 0
								? "NCF: "
								: "NO. "}
						</span>
						{notaCredito.numeracion}
					</div>
					<span className="d-block d-md-none client-info-title pt-2">
						{status}
					</span>
				</div>
			</div>

			<div className="info-cliente-responsive d-block d-md-none">
				{notaCredito.contacto && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Contacto:</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{notaCredito.contacto?.nombre}
						</div>
					</div>
				)}
				{notaCredito.contacto?.identificacion && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								RNC/Cédula:
							</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{notaCredito.contacto?.identificacion}
						</div>
					</div>
				)}
				{notaCredito.contacto?.encargado && (
					<div className="row">
						<div className="col-4 text-right px-1 client-info-title">
							Responsable:
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{notaCredito.contacto?.encargado}
						</div>
					</div>
				)}
				{notaCredito.contacto?.telefono && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Teléfono:</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{notaCredito.contacto?.telefono}
						</div>
					</div>
				)}
				{notaCredito.contacto?.direccion && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								Dirección:
							</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{notaCredito.contacto?.direccion}
						</div>
					</div>
				)}
			</div>

			<div className="clear-both info-row p-t-10 p-b-10 d-none d-md-flex">
				<div className="info-block" style={{ width: "65%" }}>
					<table>
						<tbody id="info-block-1">
							{notaCredito.contacto?.nombre && (
								<tr>
									<td
										width="120px"
										className="client-info-title text-uppercase text-right"
									>
										Cliente:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{notaCredito.contacto?.nombre}
									</td>
								</tr>
							)}
							{notaCredito.contacto?.identificacion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										RNC/Cédula:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{notaCredito.contacto?.identificacion}
									</td>
								</tr>
							)}
							{notaCredito.contacto?.encargado && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Responsable:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{notaCredito.contacto?.encargado}
									</td>
								</tr>
							)}
							{notaCredito.contacto?.telefono && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Teléfono:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{notaCredito.contacto?.telefono}
									</td>
								</tr>
							)}
							{notaCredito.contacto?.direccion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Dirección:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{notaCredito.contacto?.direccion}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<div
					className="info-block"
					style={{ marginTop: infoBlock2Margin }}
				>
					<table>
						<tbody id="info-block-2">
							<tr>
								<td style={{ paddingLeft: "10px" }}>
									<h3
										style={{
											fontSize: "0.9rem",
											textAlign: "end",
										}}
									>
										{status}
									</h3>
								</td>
							</tr>
							<tr>
								<td colSpan={isFactura(notaCredito) ? 2 : 1}>
									<h3
										style={{
											fontSize: "0.9rem",
											marginBottom: "3px",
											paddingBottom: "10px",
											whiteSpace: "nowrap",
											paddingLeft: "25%",
											textAlign: isFactura(notaCredito)
												? "left"
												: "right",
										}}
									>
										{isFactura(notaCredito)
											? notaCredito.numeracion_documento
													?.nombre
											: "Nota de Crédito"}
									</h3>
								</td>
							</tr>
							{notaCredito.fecha && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Fecha:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{dayjs(notaCredito.fecha).format(
											"DD/MM/YYYY"
										)}
									</td>
								</tr>
							)}
							{notaCredito.numeracion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										{tiposComprobante.findIndex(
											(tipo) =>
												tipo ===
												notaCredito.numeracion?.substring(
													0,
													3
												)
										) >= 0
											? "NCF:"
											: "NO.:"}
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{notaCredito.numeracion}
									</td>
								</tr>
							)}
							<tr>
								<td className="client-info-title text-uppercase text-right">
									Tipo:
								</td>
								<td
									style={{
										paddingLeft: "10px",
										textTransform: "capitalize",
									}}
								>
									{notaCredito.tipo}
								</td>
							</tr>
							<tr>
								<td className="client-info-title text-uppercase text-right">
									Trans. Afectada:
								</td>
								<td style={{ paddingLeft: "10px" }}>
									{notaCredito.tipo === "venta"
										? notaCredito.factura
												?.numero_comprobante
										: notaCredito.compra?.ncf ||
										  notaCredito.compra?.numero}
								</td>
							</tr>
							{notaCredito.tipo === "contado" &&
							notaCredito.estado === "pendiente" ? (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Estado:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										Pendiente
									</td>
								</tr>
							) : (
								notaCredito.tipo === "contado" &&
								notaCredito.estado === "cancelada" && (
									<tr>
										<td className="client-info-title text-uppercase text-right">
											Estado:
										</td>
										<td style={{ paddingLeft: "10px" }}>
											Cancelada
										</td>
									</tr>
								)
							)}

							{notaCredito.estado === "pagada" && (
								<tr>
									<td
										className="client-info-title text-uppercase text-right"
										style={{ whiteSpace: "nowrap" }}
									>
										Método de pago:
									</td>
									<td
										style={{
											paddingLeft: "10px",
											textTransform: "capitalize",
										}}
									>
										{notaCredito.tipo_pago !== ""
											? notaCredito.tipo_pago
											: "Efectivo"}
									</td>
								</tr>
							)}

							{notaCredito.numeracion_documento
								?.fecha_vencimiento && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										FV:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{dayjs(
											notaCredito.numeracion_documento
												?.fecha_vencimiento
										).format("DD/MM/YYYY")}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			<div className="table-wrapper">
				{/* eslint-disable-next-line jsx-a11y/heading-has-content */}
				<h3 className="table-title"></h3>
                {sinDevolucion ? (<>
                    <table className="table d-none d-md-table">
                        <thead>
                            <tr className="text-uppercase">
                                <th width="70%">Descripción</th>
                                <th>Importe</th>
                                <th>Impuesto</th>
                                <th className="text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{notaCredito.razon?.detalle}</td>
                                <td>{formatCurrency(notaCredito.total - notaCredito.total_impuestos)}</td>
                                <td>{formatCurrency(notaCredito.total_impuestos)}</td>
                                <td className="text-right">{formatCurrency(notaCredito.total)}</td>
                            </tr>
                        </tbody>
                    </table>
                </>) : (
                    <table className="table d-none d-md-table">
                        <thead>
                            <tr className="text-uppercase">
                                <th width="12%">Código/Ref.</th>
                                <th width="25%">Descripción</th>
                                <th width="8%">Cantidad</th>
                                <th width="8%">Unidad</th>
                                <th>Importe</th>
                                <th>Impuesto</th>
                                <th>Precio</th>
                                {notaCredito.descuentos > 0 && <th>Descuento</th>}
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notaCredito?.productos?.map((producto) =>
                                renderTableRow(producto)
                            )}
                        </tbody>
                    </table>
                )}
			</div>

            {sinDevolucion ? (
            <div className="summary">
                <div className="row mt-3 justify-content-between justify-content-md-end">
                    <div
                        className="col-2 text-md-right"
                        style={{
                            maxWidth: "10rem",
                            fontWeight: "600",
                        }}
                    >
                        Subtotal
                    </div>
                    <div
                        className="col-2 text-right"
                        style={{ maxWidth: "9rem" }}
                    >
                        {formatCurrency(notaCredito.total - notaCredito.total_impuestos)}
                    </div>
                </div>
                <div className="row justify-content-between justify-content-md-end">
                    <div
                        className="col-2 text-md-right"
                        style={{
                            maxWidth: "10rem",
                            fontWeight: "600",
                        }}
                    >
                        Impuestos
                    </div>
                    <div
                        className="col-2 text-right"
                        style={{ maxWidth: "9rem" }}
                    >
                        {formatCurrency(notaCredito.total_impuestos)}
                    </div>
                </div>
                <div className="row justify-content-between justify-content-md-end">
                    <div
                        className="col col-md-2 text-md-right"
                        style={{
                            maxWidth: "11rem",
                            fontWeight: "600",
                        }}
                    >
                        TOTAL A PAGAR
                    </div>
                    <div
                        className="col-2 text-right"
                        style={{
                            maxWidth: "9rem",
                            fontWeight: "600",
                        }}
                    >
                        {formatCurrency(notaCredito.total)}
                    </div>
                </div>
            </div>
            ) : (
                <div className="summary mt-3">
                    {notaCredito.descuentos > 0 && (
                        <div className="row justify-content-between justify-content-md-end">
                            <div
                                className="col-2 text-md-right"
                                style={{
                                    maxWidth: "9rem",
                                    textAlign: "end",
                                    fontWeight: "600",
                                }}
                            >
                                Descuento
                            </div>
                            <div
                                className="col-2 text-right"
                                style={{ maxWidth: "9rem" }}
                            >
                                -{formatCurrency(notaCredito.descuentos)}
                            </div>
                        </div>
                    )}
                    <div className="row justify-content-between justify-content-md-end">
                        <div
                            className="col-2 text-md-right"
                            style={{
                                maxWidth: "10rem",
                                fontWeight: "600",
                            }}
                        >
                            Subtotal
                        </div>
                        <div
                            className="col-2 text-right"
                            style={{ maxWidth: "9rem" }}
                        >
                            {formatCurrency(notaCredito.subtotal - (notaCredito.descuentos > 0 ? notaCredito.descuentos : 0))}
                        </div>
                    </div>
                    <div className="d-block">
                        {Object.keys(sumatoriaFinal.impuestos).map(
                            (impuesto, index) => {
                                return (
                                    <div
                                        className="row justify-content-between justify-content-md-end"
                                        key={index}
                                    >
                                        <div
                                            className="col col-md-2 text-md-right"
                                            style={{
                                                maxWidth: "9rem",
                                                fontWeight: "600",
                                            }}
                                        >
                                            {impuesto}
                                        </div>
                                        <div
                                            className="col-2 text-right"
                                            style={{
                                                maxWidth: "9rem",
                                            }}
                                        >
                                            {formatCurrency(
                                                sumatoriaFinal.impuestos[impuesto],
                                                "$"
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        )}
                    </div>

                    <div className="row justify-content-between justify-content-md-end">
                        <div
                            className="col col-md-2 text-md-right"
                            style={{
                                maxWidth: "11rem",
                                fontWeight: "600",
                            }}
                        >
                            TOTAL A PAGAR
                        </div>
                        <div
                            className="col-2 text-right"
                            style={{
                                maxWidth: "9rem",
                                fontWeight: "600",
                            }}
                        >
                            {formatCurrency(notaCredito.total)}
                        </div>
                    </div>
                    {Object.keys(sumatoriaFinal.impuestos).length > 1 && (
                        <hr className="mb-0" />
                    )}

                    <div className="text-md-right invoice-itbis-info">
                        {Object.keys(sumatoriaFinal.impuestos).length > 1 && (
                            <p
                                style={{
                                    fontSize: "small",
                                }}
                            >
                                {Object.keys(sumatoriaFinal.impuestos).includes(
                                    "Itbis 16%"
                                ) && (
                                    <>
                                        <span>
                                            Items con I1 son gravados con 16% ITBIS
                                        </span>
                                        <br />
                                    </>
                                )}
                                {Object.keys(sumatoriaFinal.impuestos).includes(
                                    "Itbis 18%"
                                ) && (
                                    <span>
                                        Items con I2 son gravados con 18% ITBIS
                                    </span>
                                )}
                            </p>
                        )}

                        {Object.keys(sumatoriaFinal.impuestos).length > 1 && (
                            <div className="row justify-content-between justify-content-md-end">
                                <div
                                    className="col col-md-6 pr-1"
                                    style={{
                                        maxWidth: "11rem",
                                        fontSize: "small",
                                        fontWeight: "600",
                                    }}
                                >
                                    TOTAL IMPUESTOS
                                </div>
                                <div
                                    className="col-2"
                                    style={{
                                        maxWidth: "9rem",
                                        fontSize: "small",
                                        fontWeight: "600",
                                    }}
                                >
                                    {formatCurrency(notaCredito.impuestos, "$")}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

			<hr className="d-block d-md-none mt-0" />
			{notaCredito.notas && (
				<>
					<p style={{ fontWeight: "600", fontSize: "1rem" }}>
						Notas:
					</p>
					<p
						className="col-12 col-md-8 pl-0 mb-3"
						style={{
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						{notaCredito.notas}
					</p>
				</>
			)}

			<div className="row justify-content-center mt-5">
				<h3
					className="col-10 col-md-3 text-center mr-md-2 mr-0 mt-md-4 fw-600 signature-title"
					style={{ borderTop: "1px solid #000" }}
				>
					Elaborado por
				</h3>
				<div className="col-md-2 d-none d-md-block"></div>
				<h3
					className="col-10 col-md-3 text-center ml-md-2 ml-0 fw-600 mt-5 mt-md-4 signature-title"
					style={{ borderTop: "1px solid #000" }}
				>
					Recibido por
				</h3>
			</div>

			{notaCredito.plantilla?.pie_pagina && (
				<div
					className="col-12 pt-5 text-center d-flex justify-content-center"
					style={{
						fontWeight: "600",
						fontSize: "1rem",
						whiteSpace: "pre-line",
						lineHeight: "normal",
					}}
				>
					<p className="text-center pie-pagina">
						{notaCredito.plantilla?.pie_pagina}
					</p>
				</div>
			)}
		</div>
	);
};

export default PrintSingleNotaCredito;
