import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../general/PageHeader";
import { getLink, getSucursal, toast } from "../../../utils/helpers";
import ImageLoader from "../../general/ImageLoader";
import { FacturaTemplate } from "../../Ingresos/Facturacion/Components/PrintSingleFactura";
import { apiRequest } from "../../../hooks/apiRequest";
import Loader from "../../general/Loader";
import { OrdenTemplate } from "../../Ingresos/OrdenCompra/Components/PrintSingleOrden";
import { CotizacionTemplate } from "../../Ingresos/Cotizacion/Components/PrintSingleCotizacion";
import { ReciboIngresoTemplate } from "../../Consultas/RecibosIngresos/PrintSingleRecibo";
import { CompraTemplate } from "../../Compras/PrintSingleCompra";
import { NotaCreditoTemplate } from "../../Consultas/NotasCredito/print/PrintSingleNotaCredito";

const tipos_impuesto = {
	itbis: "Itbis",
	otro: "Otro",
	isc: "ISC",
	propina_legal: "Propina",
	no_facturable: "No facturable",
	exento: "Exento",
};

const factura = {
	tipo_pago: "",
	id: 140,
	numero_comprobante: "B0100000051",
	tipo: "contado",
	subtotal: 28094.92,
	impuestos: 305.08,
	total: 28390,
	descuento: "10.00",
	fecha: "2022-11-24",
	fecha_vencimiento: "2022-11-24",
	notas: "consectetur adipiscing elit, sed do	eiusmod tempor incididunt ut labore	et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi utaliquip ex ea commodo consequat.",
	estado: "pagada",
	contacto_id: 8,
	numeracion_id: 7,
	empleado_id: 1,
	plazo_id: 1,
	ruta_id: null,
	sucursal_id: 1,
	entidad: "factura",
	detalle_metodo_pago: null,
	se_ha_impreso: true,
	created_at: "2022-11-24T13:24:18.000000Z",
	updated_at: "2022-11-25T05:52:03.000000Z",
	productos: [
		{
			id: 242,
			precio_facturado: 2000,
			importe: 1694.92,
			descuento: 0.5,
			cantidad: "1.00",
			total: 1990,
			producto_id: 6,
			nombre_producto: null,
			factura_id: 140,
			impuesto_id: 2,
			impuesto: {
				id: 2,
				tipo: "itbis",
				porcentaje: 0.18,
				nombre: "ITBIS",
			},
			producto: {
				id: 6,
				nombre: "Tablet",
				referencia: "TB-05",
			},
		},
		{
			id: 243,
			precio_facturado: 2400,
			importe: 2400,
			descuento: 0,
			cantidad: "1.00",
			total: 2400,
			producto_id: 4,
			nombre_producto: null,
			factura_id: 140,
			impuesto_id: 1,
			impuesto: {
				id: 1,
				tipo: null,
				porcentaje: 0,
				nombre: "Ninguno",
			},
			producto: {
				id: 4,
				nombre: "Bocina",
				referencia: "BN-025",
			},
		},
		{
			id: 244,
			precio_facturado: 24000,
			importe: 24000,
			descuento: 0,
			cantidad: "1.00",
			total: 24000,
			producto_id: 5,
			nombre_producto: null,
			factura_id: 140,
			impuesto_id: 1,
			impuesto: {
				id: 1,
				tipo: null,
				porcentaje: 0,
				nombre: "Ninguno",
			},
			producto: {
				id: 5,
				nombre: "Lenovo All in one",
				referencia: "PC-006",
			},
		},
	],
	cliente: {
		id: 8,
		nombre: "José Reyes",
		tipo_identificacion: "otro",
		identificacion: "A45678",
		telefono: "8094789898",
		encargado: "Pedro Mir",
		direccion: "Santiago, Republica Dominicana",
		limite_credito: 1500,
		credito_disponible: 1499,
		precio_venta: 1,
	},
	vendedor: {
		id: 1,
		nombre: "Salome",
		apellido: "Ureña",
		tipo: null,
	},
	plazo: {
		id: 1,
		nombre: "Contado",
		dias: 0,
	},
	ruta: null,
	categorias: [],
	numeracion: {
		id: 7,
		nombre: "Crédito fiscal (01)",
		fecha_vencimiento: "2022-11-30",
	},
};

const reciboIngreso = {
	id: 21,
	numeracion: "RI20",
	abono_total: 294531,
	balance_total: 0,
	notas: null,
	detalle_metodo_pago: {
		num_cheque: "656757",
		banco: "Banreservas",
	},
	numeracion_id: 33,
	contacto_id: 2,
	empleado_id: null,
	sucursal_id: 1,
	created_at: "2022-12-20T14:12:47.000000Z",
	updated_at: "2022-12-20T14:12:47.000000Z",
	tipo_pago: "cheque",
	cuentas: [
		{
			id: 42,
			deuda: 60008,
			abono: 60008,
			balance: 0,
			recibo_ingreso_id: 21,
			factura_id: 84,
			factura: {
				id: 84,
				numero_comprobante: "B0100000038",
				fecha_vencimiento: "2022-11-11",
			},
		},
		{
			id: 43,
			deuda: 80498,
			abono: 80498,
			balance: 0,
			recibo_ingreso_id: 21,
			factura_id: 79,
			factura: {
				id: 79,
				numero_comprobante: "P6",
				fecha_vencimiento: "2022-11-10",
			},
		},
	],
	cliente: {
		id: 2,
		nombre: "José Reyes",
		telefono: null,
		direccion: "C. 6A 11, Santiago De Los Caballeros 51000",
		identificacion: "12345",
	},
};

const compra = {
	id: 39,
	numero: null,
	fechaVencimiento: "2023-03-02",
	fecha: "2023-03-02",
	total: 20600,
	porPagar: 20600,
	cuenta: {
		id: 2,
		nombre: "Cuenta 2",
		balance: "230975.92",
		numero_cuenta: null,
		moneda: "DOP",
		tipo: null,
		ubicacion: null,
		estado: "ACTIVA",
		banco: null,
		descripcion: null,
		sucursal_id: 1,
		deleted_at: null,
		created_at: "2022-12-05T08:30:56.000000Z",
		updated_at: "2023-02-28T17:26:20.000000Z",
	},
	pagado: null,
	ncf: "B0200005",
	tipo: "producto",
	descripcion: null,
	itbis: 0,
	tipoGasto: "01 - GASTOS DE PERSONAL",
	plazo: {
		id: 1,
		nombre: "Contado",
		dias: 0,
		status: "activo",
		is_default: 1,
		sucursal_id: 1,
		created_at: "2022-10-27T07:10:34.000000Z",
		updated_at: "2022-10-27T07:10:34.000000Z",
	},
	estado: "pendiente",
	formaPago: "01 - EFECTIVO",
	totalItbis: 0,
	contacto: "CC CAROL",
	contactoId: 8,
	borrada: false,
	categorias: [
		{
			id: 39,
			nombre: "Producto",
			tipo: "Categor\u00edas",
			relacion: "Compras",
		},
	],
	items: [
		{
			id: 88,
			item_id: 6,
			referencia: "TB-05",
			fecha: "2023-03-02",
			compraId: 39,
			suplidor: "CC CAROL",
			nombre: "Amazon Tablet",
			unidad: "UND",
			costo: 1853.45,
			cantidad: 6,
			descuento: "0.00",
			total: 12900,
			impuesto: {
				id: 3,
				nombre: "ITBIS 16%",
				porcentaje: 0.16,
				tipo: "itbis",
				acreditable: "Si",
				descripcion: null,
				status: "activo",
				is_default: 0,
				sucursal_id: 1,
				created_at: "2022-10-27T07:10:34.000000Z",
				updated_at: "2022-10-27T07:10:34.000000Z",
			},
			totalImpuesto: 1779.3000000000002,
			descripcion: null,
			precios: [
				{
					id: 218,
					precio_venta: "3768.96",
					total: "4065.51",
					porciento_beneficio: "103.35",
					impuesto_id: 3,
					item_id: null,
					producto_id: 6,
					created_at: "2023-03-02T15:15:28.000000Z",
					updated_at: "2023-03-02T15:15:28.000000Z",
					precio: 4371.9936,
					impuesto: {
						id: 3,
						nombre: "ITBIS 16%",
						porcentaje: 0.16,
						tipo: "itbis",
						acreditable: "Si",
						descripcion: null,
						status: "activo",
						is_default: 0,
						sucursal_id: 1,
						created_at: "2022-10-27T07:10:34.000000Z",
						updated_at: "2022-10-27T07:10:34.000000Z",
					},
				},
			],
			grupo: "Tech",
		},
		{
			id: 89,
			item_id: 47,
			referencia: "AQ-02",
			fecha: "2023-03-02",
			compraId: 39,
			suplidor: "CC CAROL",
			nombre: "Avena",
			unidad: "CAJA",
			costo: 932.21,
			cantidad: 7,
			descuento: "0.00",
			total: 7700,
			impuesto: {
				id: 2,
				nombre: "ITBIS",
				porcentaje: 0.18,
				tipo: "itbis",
				acreditable: "Si",
				descripcion: null,
				status: "activo",
				is_default: 0,
				sucursal_id: 1,
				created_at: "2022-10-27T07:10:34.000000Z",
				updated_at: "2022-10-27T07:10:34.000000Z",
			},
			totalImpuesto: 1174.6000000000001,
			descripcion: null,
			precios: [
				{
					id: 219,
					precio_venta: "2703.41",
					total: "2871.21",
					porciento_beneficio: "190.00",
					impuesto_id: 2,
					item_id: null,
					producto_id: 47,
					created_at: "2023-03-02T15:15:28.000000Z",
					updated_at: "2023-03-02T15:15:28.000000Z",
					precio: 3190.0238,
					impuesto: {
						id: 2,
						nombre: "ITBIS",
						porcentaje: 0.18,
						tipo: "itbis",
						acreditable: "Si",
						descripcion: null,
						status: "activo",
						is_default: 0,
						sucursal_id: 1,
						created_at: "2022-10-27T07:10:34.000000Z",
						updated_at: "2022-10-27T07:10:34.000000Z",
					},
				},
				{
					id: 220,
					precio_venta: "2610.19",
					total: "2777.99",
					porciento_beneficio: "180.00",
					impuesto_id: 2,
					item_id: null,
					producto_id: 47,
					created_at: "2023-03-02T15:15:28.000000Z",
					updated_at: "2023-03-02T15:15:28.000000Z",
					precio: 3080.0242,
					impuesto: {
						id: 2,
						nombre: "ITBIS",
						porcentaje: 0.18,
						tipo: "itbis",
						acreditable: "Si",
						descripcion: null,
						status: "activo",
						is_default: 0,
						sucursal_id: 1,
						created_at: "2022-10-27T07:10:34.000000Z",
						updated_at: "2022-10-27T07:10:34.000000Z",
					},
				},
			],
			grupo: "Cornflakes",
		},
	],
	suplidor: {
		id: 8,
		codigo: "C08",
		nombre: "Jose Reyes",
		tipo_identificacion: "otro",
		identificacion: "A45678",
		telefono: "8574789898",
		email: null,
		provincia: "San Rafael del Yuma",
		direccion: "Santiago oeste, Republica Dominicana",
		celular: null,
		encargado: "Pedro mir",
		identificacion_encargado: null,
		tipo: "Proveedor",
		limite_credito: 1500,
		credito_disponible: 100,
		precio_venta: 1,
		notas: null,
		sucursal_id: 1,
		plazo_id: 3,
		ruta_id: 2,
		vendedor_id: null,
		deleted_at: null,
		created_at: "2022-11-04T09:53:30.000000Z",
		updated_at: "2023-02-20T17:16:20.000000Z",
	},
	tipo_pago: null,
};

const notaCredito = {
	id: 38,
	tipo: "venta",
	razon: "Nueva editada desde sae",
	fecha: "2023-04-12",
	numeracion: "B14000001",
	total: "10800.00",
	subtotal: "10800.00",
	total_impuestos: null,
	descuentos: null,
	notas: null,
	factura_id: 602,
	contacto_id: 8,
	numeracion_documento_id: 77,
	razon_id: 2,
	created_at: "2023-04-12T14:41:27.000000Z",
	updated_at: "2023-04-12T14:41:27.000000Z",
	compra_id: null,
	productos: [
		{
			id: 44,
			precio_facturado: 10800,
			importe: 10800,
			descuento: 0,
			impuestos: 0,
			cantidad: 1,
			total: 10800,
			nombre_producto: null,
			nota_credito_id: 38,
			producto_id: 40,
			impuesto_id: 1,
			created_at: "2023-04-12T14:41:27.000000Z",
			updated_at: "2023-04-12T14:41:27.000000Z",
			producto: {
				id: 40,
				nombre: "Monitor Samsung 27'",
				unidad: "UND",
				referencia: "MT-014",
				estado: "nuevo",
				inventariable: 1,
				costo: 7000,
				costo_total: 7000,
				cantidad: 46,
				descripcion: null,
				tipo: "producto",
				impuesto_id: 1,
				almacen_id: 4,
				grupo_id: 1,
				sucursal_id: 1,
				contacto_id: null,
				reorden: null,
				deleted_at: null,
			},
			impuesto: {
				id: 1,
				nombre: "Ninguno",
				porcentaje: 0,
				tipo: null,
				acreditable: "No",
				descripcion: null,
				status: "activo",
				is_default: 0,
				sucursal_id: 1,
				created_at: "2022-10-27T07:10:34.000000Z",
				updated_at: "2022-10-27T07:10:34.000000Z",
			},
		},
	],
	contacto: {
		id: 8,
		codigo: "C08",
		nombre: "Jose Reyes",
		tipo_identificacion: "rnc",
		identificacion: "A45678",
		telefono: "8574789898",
		email: null,
		provincia: "San Rafael del Yuma",
		direccion: "Santiago oeste, Republica Dominicana",
		celular: null,
		encargado: "Pedro mir",
		identificacion_encargado: null,
		tipo: "Proveedor-Cliente",
		limite_credito: 1500,
		credito_disponible: 1500,
		precio_venta: 3,
		notas: null,
		sucursal_id: 1,
		plazo_id: 3,
		ruta_id: 2,
		vendedor_id: null,
		deleted_at: null,
		created_at: "2022-11-04T09:53:30.000000Z",
		updated_at: "2023-04-06T15:00:15.000000Z",
	},
	numeracion_documento: {
		id: 77,
		tipo_documento: "nota_credito",
		tipo: "NC",
		nombre: "Nota de credito informal",
		numero_inicial: 1,
		numero_final: 100000,
		numero_siguiente: 3,
		fecha_vencimiento: null,
		preferida: 0,
		status: "activo",
		is_default: 0,
		sucursal_id: 1,
		created_at: "2023-04-11T15:00:38.000000Z",
		updated_at: "2023-04-12T14:41:27.000000Z",
	},
	factura: {
		id: 602,
		numero_comprobante: "P291",
		tipo: "contado",
		subtotal: 13316.95,
		impuestos: 453.05,
		total: 13770,
		descuento: "0.00",
		fecha: "2023-04-04",
		fecha_emision: "2023-04-04",
		fecha_vencimiento: "2023-04-04",
		notas: "Factura afectada por nota de cr\u00e9dito NC2",
		estado: "pagada",
		contacto_id: 8,
		numeracion_id: 9,
		empleado_id: 1,
		plazo_id: 1,
		ruta_id: 2,
		sucursal_id: 1,
		entidad: "factura",
		detalle_metodo_pago: { tipo: "visa" },
		se_ha_impreso: false,
		validada: "si",
		created_at: "2023-04-04T19:21:28.000000Z",
		updated_at: "2023-04-12T14:41:27.000000Z",
		deleted_at: null,
		recurrencia_id: null,
		productos: [
			{
				id: 1962,
				precio_facturado: 2970,
				importe: 2516.95,
				descuento: 0,
				cantidad: 1,
				total: 2970,
				producto_id: 47,
				nombre_producto: null,
				factura_id: 602,
				impuesto_id: 2,
				producto: {
					id: 47,
					nombre: "Avena quaquer",
					unidad: "CAJA",
					referencia: "AQ-02",
					estado: "nuevo",
					inventariable: 1,
					costo: 932.21,
					costo_total: 1100.01,
					cantidad: 63,
					descripcion: null,
					tipo: "producto",
					impuesto_id: 2,
					almacen_id: 5,
					grupo_id: 2,
					sucursal_id: 1,
					contacto_id: 6,
					reorden: 10,
					deleted_at: null,
				},
			},
			{
				id: 1963,
				precio_facturado: 10800,
				importe: 10800,
				descuento: 0,
				cantidad: 1,
				total: 10800,
				producto_id: 40,
				nombre_producto: null,
				factura_id: 602,
				impuesto_id: 1,
				producto: {
					id: 40,
					nombre: "Monitor Samsung 27'",
					unidad: "UND",
					referencia: "MT-014",
					estado: "nuevo",
					inventariable: 1,
					costo: 7000,
					costo_total: 7000,
					cantidad: 46,
					descripcion: null,
					tipo: "producto",
					impuesto_id: 1,
					almacen_id: 4,
					grupo_id: 1,
					sucursal_id: 1,
					contacto_id: null,
					reorden: null,
					deleted_at: null,
				},
			},
		],
	},
	compra: null,
};

const calcularImpuestos = (productos) => {
	let impuestos = {};

	if (productos) {
		productos.forEach((producto) => {
			if (
				!tipos_impuesto[producto.impuesto?.tipo] ||
				producto.impuesto?.porcentaje === 0
			)
				return;
			if (
				isNaN(
					impuestos[
						`${tipos_impuesto[producto.impuesto.tipo]} ${
							producto.impuesto.porcentaje * 100
						}%`
					]
				)
			) {
				impuestos[
					`${tipos_impuesto[producto.impuesto.tipo]} ${
						producto.impuesto.porcentaje * 100
					}%`
				] = 0;
			}
			impuestos[
				`${tipos_impuesto[producto.impuesto.tipo]} ${
					producto.impuesto.porcentaje * 100
				}%`
			] +=
				producto.cantidad *
				producto.importe *
				producto.impuesto.porcentaje;
		});
	}

	return impuestos;
};

const PlantillaImpresion = () => {
	const facturaFormRef = useRef();
	const ordenFormRef = useRef();
	const cotizacionFormRef = useRef();
	const reciboIngresoFormRef = useRef();
	const compraFormRef = useRef();
	const notaCreditoFormRef = useRef();

	const [sucursal, setSucursal] = useState({});
	const [loading, setLoading] = useState(true);
	const [plantillas, setPlantillas] = useState({});
	const [infoBlock2Margin, setInfoBlock2Margin] = useState(10);
	const [sumatoriaFinal, setSumatoriaFinal] = useState({
		impuestos: {},
	});

	const getPlantillasInfo = async () => {
		const response = await apiRequest(null, "plantillas-impresion", "GET");

		if (response.success) {
			setPlantillas(response.data);
			setLoading(false);
			return;
		}

		toast("Error obteniendo info de plantillas", "error");
		setLoading(false);
	};

	const onSubmitBtnClicked = (tipo) => {
		switch (tipo) {
			case "factura":
				facturaFormRef.current.dispatchEvent(
					new Event("submit", { bubbles: true, cancelable: true })
				);
				break;
			case "orden-compra":
				ordenFormRef.current.dispatchEvent(
					new Event("submit", { bubbles: true, cancelable: true })
				);
				break;
			case "cotizacion":
				cotizacionFormRef.current.dispatchEvent(
					new Event("submit", { bubbles: true, cancelable: true })
				);
				break;
			case "recibo-ingreso":
				reciboIngresoFormRef.current.dispatchEvent(
					new Event("submit", { bubbles: true, cancelable: true })
				);
				break;
			case "compra":
				compraFormRef.current.dispatchEvent(
					new Event("submit", { bubbles: true, cancelable: true })
				);
				break;
			case "nota-credito":
				notaCreditoFormRef.current.dispatchEvent(
					new Event("submit", { bubbles: true, cancelable: true })
				);
				break;

			default:
				break;
		}
	};

	const onSubmitForm = async (e, id) => {
		e.preventDefault();
		setLoading(true);

		const data = new FormData(e.target);
		data.append("_method", "PUT");

		const response = await apiRequest(
			data,
			`plantillas-impresion/${id}`,
			"POST"
		);

		if (response.success) {
			toast("Plantilla Actualizada");
			await getPlantillasInfo();
			setLoading(false);
			return;
		}

		toast("Error Actualizando plantilla", "error");
		setLoading(false);
	};

	const toggleAccordion = (e) => {
		e.target.classList.toggle("opened");
	};

	useEffect(() => {
		getPlantillasInfo();

		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);

		setSumatoriaFinal((state) => ({
			...state,
			impuestos: calcularImpuestos(factura.productos),
		}));
	}, []);

	useEffect(() => {
		if (loading) return;

		const infoBlock1 = document.getElementById("info-block-1");
		const infoBlock2 = document.getElementById("info-block-2");
		const count =
			infoBlock2.childElementCount - infoBlock1.childElementCount;
		setInfoBlock2Margin((count * 25 + 10) * -1);
	}, [loading]);

	if (loading) {
		return <Loader />;
	}
	return (
		<>
			<PageHeader
				hasButton={false}
				title="Plantillas de impresión"
				goBackText="Volver a configuración"
				goBackLink={getLink("/configuracion/")}
			></PageHeader>

			<table className="table-clean">
				<tbody>
					{/* ---FACTURAS--- */}
					<tr>
						<td className="table-accordion">
							<div
								className="table-accordion__toggle m-b-5 d-flex justify-content-between align-items-center"
								onClick={toggleAccordion}
							>
								<span className="font-weight-bolder font-size-lg">
									Factura
								</span>
								<div>
									<button
										className="btn btn-primary"
										onClick={() =>
											onSubmitBtnClicked("factura")
										}
									>
										Guardar
									</button>
								</div>
							</div>
							<div className="table-accordion__content">
								<div
									className="container bg-white"
									style={{
										height: "65rem",
										color: "black",
									}}
								>
									<form
										method="post"
										ref={facturaFormRef}
										onSubmit={(e) =>
											onSubmitForm(
												e,
												plantillas.find(
													(plantilla) =>
														plantilla.modulo ===
														"factura"
												)?.id
											)
										}
									>
										<input
											type="hidden"
											name="modulo"
											value="factura"
										/>
										<div style={{ width: "20rem" }}>
											<ImageLoader
												name="logo"
												text="Click para subir Logo (Max: 2MB)"
												id="facturaFoto"
												// ref={facturaFotoRef}
												imagenes={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"factura"
													)?.logo
														? plantillas
																.find(
																	(
																		plantilla
																	) =>
																		plantilla.modulo ===
																		"factura"
																)
																?.logo.map(
																	(logo) =>
																		logo.url
																)
														: []
												}
											/>
										</div>
										<FacturaTemplate
											factura={factura}
											sucursal={sucursal}
											infoBlock2Margin={infoBlock2Margin}
											sumatoriaFinal={sumatoriaFinal}
										/>
										<div
											className="form-group col-md-12 mt-4"
											style={{ marginBottom: "0.3rem" }}
										>
											<label className="label form-control-label">
												Pie de página:
											</label>
											<textarea
												className="text-input form-control"
												id="pie_pagina"
												name="pie_pagina"
												rows={4}
												defaultValue={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"factura"
													)?.pie_pagina
												}
											/>
										</div>
									</form>
								</div>
							</div>
						</td>
					</tr>
					{/* ---ORDEN DE COMPRA--- */}
					<tr className="mt-3">
						<td className="table-accordion">
							<div
								className="table-accordion__toggle m-b-5 d-flex justify-content-between align-items-center"
								onClick={toggleAccordion}
							>
								<span className="font-weight-bolder font-size-lg">
									Orden de compra
								</span>
								<div>
									<button
										className="btn btn-primary"
										onClick={() =>
											onSubmitBtnClicked("orden-compra")
										}
									>
										Guardar
									</button>
								</div>
							</div>
							<div className="table-accordion__content">
								<div
									className="container bg-white"
									style={{
										height: "65rem",
										color: "black",
									}}
								>
									<form
										method="post"
										ref={ordenFormRef}
										onSubmit={(e) =>
											onSubmitForm(
												e,
												plantillas.find(
													(plantilla) =>
														plantilla.modulo ===
														"orden-compra"
												)?.id
											)
										}
									>
										<input
											type="hidden"
											name="modulo"
											value="orden-compra"
										/>
										<div style={{ width: "20rem" }}>
											<ImageLoader
												name="logo"
												id="ordenFoto"
												// ref={fotoRef}
												text="Click para subir Logo (Max: 2MB)"
												imagenes={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"orden-compra"
													)?.logo
														? plantillas
																.find(
																	(
																		plantilla
																	) =>
																		plantilla.modulo ===
																		"orden-compra"
																)
																?.logo.map(
																	(logo) =>
																		logo.url
																)
														: []
												}
											/>
										</div>
										<OrdenTemplate
											factura={factura}
											sucursal={sucursal}
											infoBlock2Margin={infoBlock2Margin}
											sumatoriaFinal={sumatoriaFinal}
										/>
										<div
											className="form-group col-md-12 mt-4"
											style={{ marginBottom: "0.3rem" }}
										>
											<label className="label form-control-label">
												Pie de página:
											</label>
											<textarea
												className="text-input form-control"
												id="pie_pagina"
												name="pie_pagina"
												rows={4}
												defaultValue={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"orden-compra"
													)?.pie_pagina
												}
											/>
										</div>
									</form>
								</div>
							</div>
						</td>
					</tr>
					{/* ---COTIZACION--- */}
					<tr className="mt-3">
						<td className="table-accordion">
							<div
								className="table-accordion__toggle m-b-5 d-flex justify-content-between align-items-center"
								onClick={toggleAccordion}
							>
								<span className="font-weight-bolder font-size-lg">
									Cotización
								</span>
								<div>
									<button
										className="btn btn-primary"
										onClick={() =>
											onSubmitBtnClicked("cotizacion")
										}
									>
										Guardar
									</button>
								</div>
							</div>
							<div className="table-accordion__content">
								<div
									className="container bg-white"
									style={{
										height: "65rem",
										color: "black",
									}}
								>
									<form
										method="post"
										ref={cotizacionFormRef}
										onSubmit={(e) =>
											onSubmitForm(
												e,
												plantillas.find(
													(plantilla) =>
														plantilla.modulo ===
														"cotizacion"
												)?.id
											)
										}
									>
										<input
											type="hidden"
											name="modulo"
											value="cotizacion"
										/>
										<div style={{ width: "20rem" }}>
											<ImageLoader
												name="logo"
												id="cotizacionFoto"
												// ref={fotoRef}
												text="Click para subir Logo (Max: 2MB)"
												imagenes={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"cotizacion"
													)?.logo
														? plantillas
																.find(
																	(
																		plantilla
																	) =>
																		plantilla.modulo ===
																		"cotizacion"
																)
																?.logo.map(
																	(logo) =>
																		logo.url
																)
														: []
												}
											/>
										</div>
										<CotizacionTemplate
											factura={factura}
											sucursal={sucursal}
											infoBlock2Margin={infoBlock2Margin}
											sumatoriaFinal={sumatoriaFinal}
										/>
										<div
											className="form-group col-md-12 mt-4"
											style={{ marginBottom: "0.3rem" }}
										>
											<label className="label form-control-label">
												Pie de página:
											</label>
											<textarea
												className="text-input form-control"
												id="pie_pagina"
												name="pie_pagina"
												rows={4}
												defaultValue={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"cotizacion"
													)?.pie_pagina
												}
											/>
										</div>
									</form>
								</div>
							</div>
						</td>
					</tr>
					{/* ---RECIBOS DE INGRESO--- */}
					<tr className="mt-3">
						<td className="table-accordion">
							<div
								className="table-accordion__toggle m-b-5 d-flex justify-content-between align-items-center"
								onClick={toggleAccordion}
							>
								<span className="font-weight-bolder font-size-lg">
									Recibo de ingreso
								</span>
								<div>
									<button
										className="btn btn-primary"
										onClick={() =>
											onSubmitBtnClicked("recibo-ingreso")
										}
									>
										Guardar
									</button>
								</div>
							</div>
							<div className="table-accordion__content">
								<div
									className="container bg-white"
									style={{
										height: "65rem",
										color: "black",
									}}
								>
									<form
										method="post"
										ref={reciboIngresoFormRef}
										onSubmit={(e) =>
											onSubmitForm(
												e,
												plantillas.find(
													(plantilla) =>
														plantilla.modulo ===
														"recibo-ingreso"
												)?.id
											)
										}
									>
										<input
											type="hidden"
											name="modulo"
											value="recibo-ingreso"
										/>
										<div style={{ width: "20rem" }}>
											<ImageLoader
												name="logo"
												id="reciboIngresoFoto"
												// ref={fotoRef}
												text="Click para subir Logo (Max: 2MB)"
												imagenes={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"recibo-ingreso"
													)?.logo
														? plantillas
																.find(
																	(
																		plantilla
																	) =>
																		plantilla.modulo ===
																		"recibo-ingreso"
																)
																?.logo.map(
																	(logo) =>
																		logo.url
																)
														: []
												}
											/>
										</div>
										<ReciboIngresoTemplate
											recibo={reciboIngreso}
											sucursal={sucursal}
											infoBlock2Margin={infoBlock2Margin}
											sumatoriaFinal={
												reciboIngreso.abono_total
											}
										/>
										<div
											className="form-group col-md-12 mt-4"
											style={{ marginBottom: "0.3rem" }}
										>
											<label className="label form-control-label">
												Pie de página:
											</label>
											<textarea
												className="text-input form-control"
												id="pie_pagina"
												name="pie_pagina"
												rows={4}
												defaultValue={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"recibo-ingreso"
													)?.pie_pagina
												}
											/>
										</div>
									</form>
								</div>
							</div>
						</td>
					</tr>
					{/* ---COMPRA--- */}
					<tr className="mt-3">
						<td className="table-accordion">
							<div
								className="table-accordion__toggle m-b-5 d-flex justify-content-between align-items-center"
								onClick={toggleAccordion}
							>
								<span className="font-weight-bolder font-size-lg">
									Compras
								</span>
								<div>
									<button
										className="btn btn-primary"
										onClick={() =>
											onSubmitBtnClicked("compra")
										}
									>
										Guardar
									</button>
								</div>
							</div>
							<div className="table-accordion__content">
								<div
									className="container bg-white"
									style={{
										height: "65rem",
										color: "black",
									}}
								>
									<form
										method="post"
										ref={compraFormRef}
										onSubmit={(e) =>
											onSubmitForm(
												e,
												plantillas.find(
													(plantilla) =>
														plantilla.modulo ===
														"compra"
												)?.id
											)
										}
									>
										<input
											type="hidden"
											name="modulo"
											value="compra"
										/>
										<div style={{ width: "20rem" }}>
											<ImageLoader
												name="logo"
												id="compraFoto"
												// ref={fotoRef}
												text="Click para subir Logo (Max: 2MB)"
												imagenes={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"compra"
													)?.logo
														? plantillas
																.find(
																	(
																		plantilla
																	) =>
																		plantilla.modulo ===
																		"compra"
																)
																?.logo.map(
																	(logo) =>
																		logo.url
																)
														: []
												}
											/>
										</div>
										<CompraTemplate
											compra={compra}
											sucursal={sucursal}
											infoBlock2Margin={infoBlock2Margin}
											sumatoriaFinal={{
												impuestos: {},
												totalITBIS: 0,
												subTotalCompra: 0,
											}}
										/>
										<div
											className="form-group col-md-12 mt-4"
											style={{ marginBottom: "0.3rem" }}
										>
											<label className="label form-control-label">
												Pie de página:
											</label>
											<textarea
												className="text-input form-control"
												id="pie_pagina"
												name="pie_pagina"
												rows={4}
												defaultValue={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"compra"
													)?.pie_pagina
												}
											/>
										</div>
									</form>
								</div>
							</div>
						</td>
					</tr>
					{/* ---NOTA DE CREDITO--- */}
					<tr className="mt-3">
						<td className="table-accordion">
							<div
								className="table-accordion__toggle m-b-5 d-flex justify-content-between align-items-center"
								onClick={toggleAccordion}
							>
								<span className="font-weight-bolder font-size-lg">
									Notas de crédito
								</span>
								<div>
									<button
										className="btn btn-primary"
										onClick={() =>
											onSubmitBtnClicked("nota-credito")
										}
									>
										Guardar
									</button>
								</div>
							</div>
							<div className="table-accordion__content">
								<div
									className="container bg-white"
									style={{
										height: "65rem",
										color: "black",
									}}
								>
									<form
										method="post"
										ref={notaCreditoFormRef}
										onSubmit={(e) =>
											onSubmitForm(
												e,
												plantillas.find(
													(plantilla) =>
														plantilla.modulo ===
														"nota-credito"
												)?.id
											)
										}
									>
										<input
											type="hidden"
											name="modulo"
											value="nota-credito"
										/>
										<div style={{ width: "20rem" }}>
											<ImageLoader
												name="logo"
												id="notaCreditoFoto"
												// ref={fotoRef}
												text="Click para subir Logo (Max: 2MB)"
												imagenes={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"nota-credito"
													)?.logo
														? plantillas
																.find(
																	(
																		plantilla
																	) =>
																		plantilla.modulo ===
																		"nota-credito"
																)
																?.logo.map(
																	(logo) =>
																		logo.url
																)
														: []
												}
											/>
										</div>
										<NotaCreditoTemplate
											notaCredito={notaCredito}
											sucursal={sucursal}
											infoBlock2Margin={infoBlock2Margin}
											sumatoriaFinal={{
												impuestos: {},
												totalITBIS: 0,
												subTotalCompra: 0,
											}}
										/>
										<div
											className="form-group col-md-12 mt-4"
											style={{ marginBottom: "0.3rem" }}
										>
											<label className="label form-control-label">
												Pie de página:
											</label>
											<textarea
												className="text-input form-control"
												id="pie_pagina"
												name="pie_pagina"
												rows={4}
												defaultValue={
													plantillas.find(
														(plantilla) =>
															plantilla.modulo ===
															"nota-credito"
													)?.pie_pagina
												}
											/>
										</div>
									</form>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};

export default PlantillaImpresion;
