import React, { Component } from "react";
import { debounce } from "lodash";
import { Link, Route, withRouter } from "react-router-dom";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import FormCompletada from "./FormCompletada";
import Loader from "../general/Loader";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { getLink, toast } from "../../utils/helpers";
import View from "./View";
import dayjs from "dayjs";

class Reclamaciones extends Component {
    state = {
        reclamaciones: [],
        reclamacion: {},
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        showCompletar: true,
        showProgramacion: true,
        estado: "completada",
        loading: true,
        filtros: {
            buscar: "",
        },
        filtrosQuery: "?limit=20&estado=pendiente",
        empleados: [],
        vehiculos: [],
        rutas: [],
    };
    componentDidMount() {
        this.getReclamaciones();
        this.getTable("vehiculos");
        this.getTable("rutas");
        this.getTable("empleados");
    }
    getTable = async (table) => {
        const response = await this.props.request(table);

        if (response.data && response.data.length) {
            this.setState({
                [table]: response.data,
            });
        }
    };
    getReclamaciones = async (url = null) => {
        url = url
            ? url + "&limit=20"
            : "reclamaciones" + this.state.filtrosQuery;
        const reclamaciones = await this.props.request(url);
        if (reclamaciones.data) {
            this.setState({
                reclamaciones: reclamaciones.data,
                links: reclamaciones.links,
                meta: reclamaciones.meta,
                loading: false,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            reclamacion: { operadores: [] },
        });
    };
    toggleEditModal = (reclamacion) => {
        this.setState({
            showEdit: !this.state.showEdit,
            reclamacion: reclamacion,
        });
        this.props.history.push(getLink("/reclamaciones"));
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getReclamaciones("reclamaciones?limit=20&" + query.join("&"));
    }, 500);

    cambiarEstado = (event, reclamacion) => {
        const estado = event.target.value;

        this.setState({ estado });

        switch (estado) {
            case "pendiente":
                this.updateReclamacion(reclamacion.id, {
                    estado: "pendiente",
                });
                break;
            case "en-proceso":
                this.updateReclamacion(reclamacion.id, {
                    estado: "en-proceso",
                });
                break;
            case "cancelada":
            case "atendida":
                this.props.history.push(
                    getLink(`/reclamaciones/${reclamacion.id}/completar`)
                );
                break;
            default:
                break;
        }
    };

    updateReclamacion = async (reclamacion, fields) => {
        const token = auth.getToken();

        let data = new FormData();
        data.append("_method", "PATCH");

        Object.keys(fields).map((field) => data.append(field, fields[field]));

        const res = await fetch(apiUrl(`reclamaciones/${reclamacion}`), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.getReclamaciones();
            toast("¡Reclamación actualizada!");
        }
    };

    render() {
        const {
            reclamaciones,
            vehiculos,
            empleados,
            rutas,
            loading,
            estado,
            filtrosQuery,
        } = this.state;
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Todas las Reclamaciones</span>
                        <Link
                            to={getLink(
                                `/imprimir/reclamaciones${filtrosQuery}`
                            )}
                            target='_blank'
                            className='au-btn-filter m-r-15'>
                            <i className='zmdi zmdi-print' /> Imprimir
                        </Link>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Reclamación
                        </button>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='buscar'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='desde'>Desde</label>
                                    <input
                                        type='date'
                                        name='desde'
                                        id='desde'
                                        className='form-control'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "desde"
                                            )
                                        }
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='hasta'>Hasta</label>
                                    <input
                                        type='date'
                                        name='hasta'
                                        id='hasta'
                                        className='form-control'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "hasta"
                                            )
                                        }
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Estado</label>
                                    <select
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "estado"
                                            )
                                        }
                                        name='estado'
                                        defaultValue='pendiente'
                                        className='form-control'>
                                        <option value='todo'>Todas</option>
                                        <option value='pendiente'>
                                            Pendientes
                                        </option>
                                        <option value='en-proceso'>
                                            En Proceso
                                        </option>
                                        <option value='atendida'>
                                            Atendida
                                        </option>
                                        <option value='cancelada'>
                                            Canceladas
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!reclamaciones.length ? (
                                <div>
                                    {!loading && (
                                        <div>No hay reclamaciones creadas.</div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Detalle</th>
                                                <th style={{ width: "200px" }}>
                                                    Dirección
                                                </th>
                                                <th>Reclamado por</th>
                                                <th style={{ width: "150px" }}>
                                                    Estado
                                                </th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reclamaciones.map(
                                                (reclamacion) => {
                                                    let estado = "";
                                                    switch (
                                                        reclamacion.estado
                                                    ) {
                                                        case "cancelada":
                                                            estado = "danger";
                                                            break;
                                                        case "en-proceso":
                                                            estado = "primary";
                                                            break;
                                                        case "atendida":
                                                            estado = "success";
                                                            break;
                                                        default:
                                                            estado = "warning";
                                                            break;
                                                    }
                                                    return (
                                                        <tr
                                                            className={
                                                                "tr-shadow " +
                                                                estado
                                                            }
                                                            key={
                                                                reclamacion.id
                                                            }>
                                                            <td>
                                                                {dayjs(
                                                                    reclamacion.fecha
                                                                ).format(
                                                                    "YYYY-MM-DD"
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    reclamacion.comentario
                                                                }
                                                                <div>
                                                                    {reclamacion.creador && (
                                                                        <small>
                                                                            Creador:{" "}
                                                                            {
                                                                                reclamacion.creador
                                                                            }
                                                                        </small>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    {reclamacion.ruta &&
                                                                        reclamacion
                                                                            .ruta
                                                                            .nombre}
                                                                </strong>
                                                                <div>
                                                                    <small>
                                                                        {
                                                                            reclamacion.direccion
                                                                        }
                                                                    </small>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    {
                                                                        reclamacion.nombreReclamador
                                                                    }
                                                                </strong>
                                                                <div>
                                                                    Tel:{" "}
                                                                    {
                                                                        reclamacion.telefonoReclamador
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <select
                                                                    name='estado'
                                                                    className='form-control'
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        this.cambiarEstado(
                                                                            e,
                                                                            reclamacion
                                                                        );
                                                                    }}
                                                                    value={
                                                                        reclamacion.estado
                                                                    }>
                                                                    <option value='pendiente'>
                                                                        Pendiente
                                                                    </option>
                                                                    <option value='en-proceso'>
                                                                        En
                                                                        Proceso
                                                                    </option>
                                                                    <option value='atendida'>
                                                                        Atendida
                                                                    </option>
                                                                    <option value='cancelada'>
                                                                        Cancelada
                                                                    </option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <div className='table-data-feature'>
                                                                    {reclamacion.estado !==
                                                                        "atendida" && (
                                                                        <Link
                                                                            to={getLink(
                                                                                `/reclamaciones/${reclamacion.id}`
                                                                            )}
                                                                            className='item'
                                                                            data-toggle='tooltip'
                                                                            data-placement='top'
                                                                            title=''
                                                                            data-original-title='Edit'>
                                                                            <i className='zmdi zmdi-edit' />
                                                                        </Link>
                                                                    )}
                                                                    <Link
                                                                        to={getLink(
                                                                            `/reclamaciones/${reclamacion.id}/ver`
                                                                        )}
                                                                        className='item'
                                                                        data-toggle='tooltip'
                                                                        data-placement='top'
                                                                        title=''
                                                                        data-original-title='Edit'>
                                                                        <i className='zmdi zmdi-eye' />
                                                                    </Link>
                                                                    <Link
                                                                        to={getLink(
                                                                            `/imprimir/reclamaciones/${reclamacion.id}`
                                                                        )}
                                                                        className='item'
                                                                        target='_blank'
                                                                        data-original-title='Imprimir'>
                                                                        <i className='zmdi zmdi-print' />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getReclamaciones}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Route
                    exact
                    path='/:sucursal/reclamaciones/:id'
                    render={(props) => (
                        <Modal show blank toggle={this.toggleEditModal}>
                            <Edit
                                toggle={this.toggleEditModal}
                                empleados={empleados}
                                rutas={rutas}
                                vehiculos={vehiculos}
                                reclamacion={
                                    reclamaciones.filter(
                                        (r) =>
                                            r.id ===
                                            parseInt(props.match.params.id)
                                    )[0] || {}
                                }
                                getReclamaciones={this.getReclamaciones}
                            />
                        </Modal>
                    )}
                />
                <Route
                    exact
                    path='/:sucursal/reclamaciones/:id/completar'
                    render={(props) => (
                        <Modal show blank toggle={this.toggleEditModal}>
                            <FormCompletada
                                toggle={this.toggleEditModal}
                                empleados={empleados}
                                rutas={rutas}
                                vehiculos={vehiculos}
                                estado={estado}
                                reclamacion={
                                    reclamaciones.filter(
                                        (r) =>
                                            r.id ===
                                            parseInt(props.match.params.id)
                                    )[0] || {}
                                }
                                getReclamaciones={this.getReclamaciones}
                            />
                        </Modal>
                    )}
                />
                <Route
                    exact
                    path='/:sucursal/reclamaciones/:id/ver'
                    render={(props) => {
                        return (
                            <Modal
                                show
                                title='Ver Reclamación'
                                toggle={this.toggleEditModal}>
                                <View
                                    reclamacion={
                                        reclamaciones.filter(
                                            (r) =>
                                                r.id ===
                                                parseInt(props.match.params.id)
                                        )[0] || {}
                                    }
                                />
                            </Modal>
                        );
                    }}
                />
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        reclamacion={this.state.reclamacion}
                        empleados={empleados}
                        rutas={rutas}
                        vehiculos={vehiculos}
                        getReclamaciones={this.getReclamaciones}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(Reclamaciones));
