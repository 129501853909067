import React, { useState, useEffect } from "react";
import { getTable } from "../../../utils/request";
import Select from "react-select";

export default function ReporteEmpleados() {
    const [empleados, setEmpleados] = useState([]);
    const [selectedEmpleados, setSelectedEmpleados] = useState([]);

    useEffect(() => {
        let ignore = false;

        async function fetchEmpleados() {
            const data = await getTable("empleados?informal=todos");
            if (!ignore) {
                setEmpleados(data);
            }
        }

        fetchEmpleados();
        return () => {
            ignore = true;
        };
    }, []);

    function onChangeEmpleado(values) {
        if (values) {
            setSelectedEmpleados(values.map((i) => i.value));
        } else {
            setSelectedEmpleados([]);
        }
    }

    return (
        <div>
            <div className='form-group'>
                <label>Empleados:</label>
                <input
                    type='hidden'
                    name='empleados'
                    value={selectedEmpleados.join(",")}
                />
                <Select
                    isLoading={empleados.length === 0}
                    isMulti
                    loadingMessage={() => "Cargando Empleados.."}
                    onChange={onChangeEmpleado}
                    options={empleados.map((empleado) => ({
                        value: empleado.id,
                        label: `${empleado.codigo} - ${empleado.nombre} ${empleado.apellido} (${empleado.cargo})`,
                    }))}></Select>
            </div>
        </div>
    );
}
