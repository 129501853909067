import React from "react";
import Loader from "../general/Loader";

export default ({ categorias, cargarTarjetas, saving }) => {
    return saving ? (
        <Loader />
    ) : (
        <form onSubmit={cargarTarjetas} className='row col-md-6 offset-3'>
            <select name='categoria' className='form-control col' required>
                <option>Seleccionar Asignaciones</option>
                {categorias.map(cat => (
                    <option key={cat.id} value={cat.id}>
                        {cat.nombre}
                    </option>
                ))}
            </select>
            <button type='sumbit' className='btn btn-primary m-l-15'>
                Cargar Tarjetas
            </button>
        </form>
    );
};
