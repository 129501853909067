/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../../utils/auth";
import { formatCurrency } from "../../../../utils/helpers";
import { request } from "../../../../utils/request";

const PrintFacturas = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [facturas, setFacturas] = useState([]);
	const vendedoresMap = new Map([]);
	const [vendedoresMap1, setVendedoresMap1] = useState({});

	const [summaries, setSummaries] = useState({
		efectivo: 0,
		tarjeta: 0,
		transferencia: 0,
		cheque: 0,
		otro: 0,
		canceladas: 0,
		pendientesDePago: 0,
		pagadas: 0,
		// totalVendido: 0,
	});

	const getFacturas = () => {
		request("facturas" + window.location.search + "&paginate=1000000").then(
			(res) => {
				setFacturas(res.data);
			}
		);
	};

	const formatDateTime = (date) => {
		return dayjs(date).format("DD/MM/YYYY, hh:mm a");
	};

	const calculateSummaries = useCallback(() => {
		facturas.forEach((factura) => {
            setSummaries((prevState) => ({
                ...prevState,
                [factura.tipo_pago]: prevState[factura.tipo_pago] +
                    (factura.total - factura.por_cobrar),
            }));

			if (factura.estado === "pendiente") {
				setSummaries((prevState) => ({
					...prevState,
					pendientesDePago:
						prevState.pendientesDePago + factura.por_cobrar,
				}));
			} else if (factura.estado === "cancelada") {
				setSummaries((prevState) => ({
					...prevState,
					canceladas: prevState.canceladas + factura.total,
				}));
			}
			if (factura.estado === "pagada") {
				setSummaries((prevState) => ({
					...prevState,
					pagadas:
						prevState.pagadas + factura.total,
				}));
			}
		});
	}, [facturas]);

	const fillVendedores = () => {
		facturas.forEach((factura) => {
			if (factura.vendedor) {
				setVendedoresMap1((prevState) => ({
					...prevState,
					[factura.vendedor?.id]: {
						cantidad:
							(prevState[factura.vendedor?.id]?.cantidad || 0) +
							1,
						nombre: `${factura.vendedor?.nombre} ${factura.vendedor?.apellido}`,
						total:
							(prevState[factura.vendedor?.id]?.total || 0) +
							parseFloat(factura.total),
						codigo: factura.vendedor.codigo,
					},
				}));
			}
		});
	};

	useEffect(() => {
		getFacturas();
	}, []);
	useEffect(() => {
		if (facturas.length <= 0) return;
		calculateSummaries();
		fillVendedores();
		const timeout = setTimeout(() => {
			window.print();
		}, 700);

		return () => {
			clearTimeout(timeout);
		};
	}, [facturas]);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotal = facturas.reduce((acc, factura) => {
        if (factura.estado !== 'cancelada') {
            return acc + parseFloat(factura.total);
        }

        return acc;
	}, 0);

	return (
		<>
			<style>{`@page {size: A4 landscape}`}</style>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Facturas
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {facturas.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-6">
					<h5 className="fw-600 w-100 text-center mb-2">
						FORMAS DE PAGO
					</h5>
					<table className="table m-b-40">
						<thead>
							<tr>
								<th>Efectivo</th>
								<th>Tarjeta</th>
								<th>Transferencia</th>
								<th>Cheque</th>
								<th width="90px">Otro</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{formatCurrency(summaries.efectivo)}
								</td>
								<td>
									{formatCurrency(summaries.tarjeta)}
								</td>
								<td>
									{formatCurrency(summaries.transferencia)}
								</td>
								<td>{formatCurrency(summaries.cheque)}</td>
								<td>{formatCurrency(summaries.otro)}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="col-6">
					<h5 className="fw-600 w-100 text-center mb-2">
						DETALLES DE FACTURAS
					</h5>
					<table className="table m-b-40">
						<thead>
							<tr>
								<th>Canceladas</th>
								<th>Pendiente de pago</th>
								<th>Pagadas</th>
								<th>Ventas del Periodo</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{formatCurrency(summaries.canceladas)}
								</td>
								<td>
									{formatCurrency(
										summaries.pendientesDePago
									)}
								</td>
								<td>
									{formatCurrency(summaries.pagadas)}
								</td>
								<td>
									{formatCurrency(valorTotal)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<table className="table m-b-40">
				<thead>
					<tr>
						<th>NCF/Numero</th>
						<th>Tipo</th>
						<th>Cliente</th>
						<th>Vendedor</th>
						<th>Creación</th>
						<th className="text-right">Vencimiento</th>
						<th className="text-right">Estado</th>
						<th className="text-right">Balance</th>
						<th className="text-right">Total</th>
					</tr>
				</thead>
				<tbody>
					{facturas.map((factura) => {
						return (
							<tr key={factura.id}>
								<td>{factura.numero_comprobante}</td>
								<td className="text-capitalize">
									{factura.tipo}
								</td>
								<td>
									{factura.cliente
										? factura.cliente.nombre
										: ""}
								</td>
								<td>
									{factura.vendedor
										? `${factura.vendedor.nombre} ${factura.vendedor.apellido}`
										: ""}
								</td>
								<td>{formatDateTime(factura.created_at)}</td>
								<td className="text-right">
									{new Intl.DateTimeFormat("es-ES").format(
										new Date(factura.fecha_vencimiento)
									)}
								</td>
								<td className="text-capitalize text-right">
									{factura.estado}
								</td>
								<td className="text-capitalize text-right">
									{formatCurrency(factura.por_cobrar)}
								</td>
								<td className="text-right">
									{formatCurrency(factura.total)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {facturas.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>

			{facturas.length > 0 && (
				<div className="row">
					<div className="col-8">
						<h5>RESUMEN DE VENTAS POR VENDEDOR</h5>
						<table className="table m-b-40">
							<thead>
								<tr>
									<th>Código</th>
									<th>Nombre</th>
									<th className="text-right">Cant. de facturas</th>
									<th className="text-right">Total</th>
								</tr>
							</thead>
							<tbody>
								{Object.values(vendedoresMap1).map(
									(vendedor, i) => {
										return (
											<tr key={i}>
												<td>{vendedor.codigo}</td>
												<td>{vendedor.nombre}</td>
												<td className="text-right" width="130px">{vendedor.cantidad}</td>
												<td className="text-right">{formatCurrency(vendedor.total)}</td>
											</tr>
										);
									}
								)}
							</tbody>
						</table>
					</div>
				</div>
			)}
		</>
	);
};

export default PrintFacturas;
