import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency, formatNumber } from "../../../utils/helpers";
import TableOfProducts from "./TableOfProducts";

class PrintProductos extends Component {
    state = {
        productos: [],
    };
    componentDidMount() {
        this.getProductos();
    }
    getProductos = async () => {
        const response = await this.props.request(
            "productos" + window.location.search
        );

        if (response.data) {
            this.setState({
                productos: response.data,
            });
            window.print();
        }
    };
    render() {
        const { productos } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const categorias = productos.reduce((acc, current) => {
            if (!acc[current.grupo?.nombre]) {
                acc[current.grupo?.nombre] = [];
            }
            acc[current.grupo?.nombre].push(current);
            return acc;
        }, {});

        const valorTotalCosto = productos.reduce((acc, producto) => {
            if (!producto.costo_total || !producto.cantidad || !producto.inventariable) {
                return acc;
            }
            return acc + (parseFloat(producto.cantidad) * parseFloat(producto.costo_total));
        }, 0);

        const valorTotalPrecio = productos.reduce((acc, producto) => {
            if (!producto.precios || !producto.cantidad || !producto.inventariable) {
                return acc;
            }
            return acc + (parseFloat(producto.cantidad) * parseFloat(producto.precios[0].total));
        }, 0);

        return (
            <div>
                <style>{`@page {size: 11in 8.5in;}`}</style>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Reporte Productos
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL PRODUCTOS: {productos.length}
                        </div>

                        <div className='float-right'>
                            <h3>VALOR TOTAL AL COSTO: {formatCurrency(valorTotalCosto)} | VALOR TOTAL PRECIO 1: {formatCurrency(valorTotalPrecio)}</h3>
                        </div>
                    </div>
                </div>

                <TableOfProducts categorias={categorias} />

                <div className='row'>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL PRODUCTOS: {productos.length}
                        </div>

                        <div className='float-right'>
                            <h3>VALOR TOTAL AL COSTO: {formatCurrency(valorTotalCosto)} | VALOR TOTAL PRECIO 1: {formatCurrency(valorTotalPrecio)}</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintProductos));
