import React, { useState } from "react";
import Form from "./Form";
import Loader from "../general/Loader";
import { apiRequest } from "../../hooks/apiRequest";
import { toast } from "../../utils/helpers";

const Create = ({toggle, getCheques}) => {

    const [state, setState] = useState({
        cheque: {},
        saving: false,
    });

    const [errors, setErrors] = useState({});

    const [afectarCuentas, setAfectarCuentas] = useState(false);

    const createCheque = async (event) => {
        event.preventDefault();

        setState({ ...state, saving: true });

        const data = new FormData(event.target);
        const response = await apiRequest(data, 'cheques');

        if (response.success) {
            getCheques();
        } else {
            setErrors(response.data.message);
            toast("Error creando cheque.", 'error');
        }

        setState({ ...state, saving: false });
    };

    const { cheque, saving } = state;

    return (
        <form method='post' onSubmit={createCheque}>
            <div className='popup__header'>
                Nuevo Cheque
                <div className="float-right">
                    <small className="m-r-15">Afectar cuentas contables</small>
                    <label className='switch switch-3d switch-success mr-3'>
                        <input type='hidden' name='afectar_cuentas' value='no' />
                        <input
                            key={cheque.id}
                            type='checkbox'
                            className='switch-input'
                            id='afectar_cuentas'
                            name='afectar_cuentas'
                            value='yes'
                            defaultChecked={afectarCuentas}
                            onChange={() => setAfectarCuentas(!afectarCuentas)}
                        />
                        <span className='switch-label' />
                        <span className='switch-handle' />
                    </label>
                </div>
            </div>
            <div className='popup__body'>
                <Form
                    cheque={cheque}
                    errors={errors}
                    afectarCuentas={afectarCuentas}
                />
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                {saving ? (
                    <Loader type='small' />
                ) : (
                    <button type='submit' className='btn btn-primary'>
                        Crear
                    </button>
                )}
            </div>
        </form>
    );
}

export default Create;
