import React, { useState } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { auth } from "../../utils/auth";
import { toast, getLink } from "../../utils/helpers";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import Form from "./Form";

const CreatetEntrada = ({}) => {
    const [state, setState] = useState({
        errors: {},
        entradaItems: [{ id: Date.now(), subtotal: 0 }],
        debito: 0,
        credito: 0,
        fecha: dayjs().format("YYYY-MM-DD"),
        loading: false,
        saving: false,
    });

    const history = useHistory();

    const createEntradaDiario = async (event, salir = false, imprimir = false) => {
        event.preventDefault();

        const {debito, credito} = state;

        if (debito !== credito) {
            toast("Los totales no coinciden", "error");
            return;
        }

        setState({
            ...state,
            saving: true,
        });


        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl("entradas-diario"), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            setState({
                ...state,
                saving: false,
            });
            if (imprimir) {
                window.open(getLink('/imprimir/entradas-de-diario/' + response.data.id))
            }
            if (salir) {
                history.push(getLink("/entradas-de-diario"));
            } else {
                const current = window.location.pathname;
                history.replace(getLink('/reload'));
                    setTimeout(() => {
                    history.replace(current);
                });
            }
        }
        if (response.code === 422) {
            setState({ ...state, errors: response.data.message, saving: false });
            toast("Por favor revisa todos los campos", "error");
        }
    };
    const guardarSalir = (e) => {
        e.target = e.target.form;
        createEntradaDiario(e, true)
    }
    const guardarImprimir = (e) => {
        e.target = e.target.form;
        createEntradaDiario(e, true, true)
    }

    const {
        errors,
        loading,
        saving,
    } = state;

    return (
        <div className='crear-compra'>
            <div className='page-header'>
                <h1 className='page-title row'>
                    <span className='col'>Nueva Entrada de Diario</span>
                </h1>
            </div>
            { loading ? <Loader /> : (
                <form
                    method='post'
                    onSubmit={createEntradaDiario}
                    className='row'>
                    <Form
                        isCreate={true}
                        entrada={{}}
                        errors={errors}
                        items={[
                            {id: Date.now(), credito: 0, debito: 0},
                            {id: Date.now()+1, credito: 0, debito: 0},
                        ]}
                        date={dayjs().format("YYYY-MM-DD")}
                    />
                    <div className='page-footer text-right col-md-12'>
                        <button
                            type='button'
                            onClick={() =>
                                history.push(getLink("/entradas-de-diario"))
                            }
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {!saving && (
                            <button type='button' className='btn btn-secondary' onClick={guardarSalir}>
                                Guardar
                            </button>
                        )}
                        {!saving && (
                            <button type='button' className='btn btn-secondary' onClick={guardarImprimir}>
                                Guardar e Imprimir
                            </button>
                        )}
                        {saving ? (
                            <Loader type='small' />
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Guardar y Crear Nuevo
                            </button>
                        )}
                    </div>
                </form>
            )}
        </div>
    );
}

export default CreatetEntrada;
