import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

import { withRequest } from "../../utils/request";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { toast, provinciasMunicipiosForDropdown } from "../../utils/helpers";
import { apiRequest } from "../../hooks/apiRequest";
import { apiUrl } from "../../utils/apiUrl";

class Form extends Component {
	state = {
		grupos: [],
		almacenes: [],
		dropdownOptions: {
			codigos: [],
			provincias: [],
			vendedores: [],
			terminos: [],
			tipos_identificacion: [
				{ label: "Cédula", value: "cedula" },
				{ label: "RNC", value: "rnc" },
				{ label: "Pasaporte", value: "pasaporte" },
				{ label: "Otro", value: "otro" },
			],
		},
		searchContacto: "",
		searchEncargado: "",
		isLoading: false,
		provincia: { value: null, label: "Seleccione..." },
		form: {
			codigo: "",
			tipo_identificacion: "",
			identificacion: "",
			nombre: "",
			provincia: "",
			telefono: "",
			celular: "",
			email: "",
			encargado: "",
			direccion: "",
			ruta: "",
			plazo: null,
			notas: "",
			cliente: false,
			proveedor: false,
			limite_credito: 0,
			bandera: false,
			latitud_longitud: "",
		},
	};
	componentDidMount() {
		this.getTerminosForDropdown();
		this.getRutasForDropdown();
		this.getCodigosContacto();
		this.getCantPreciosContacto();
		this.getProvincias();
		this.getVendedoresForDropdown();

		if (!this.props.contacto) return;

		this.setState((state) => ({
			...state,
			form: {
				...state.form,
				...this.props.contacto,
				ruta: this.props.contacto?.ruta?.value
					? this.props.contacto?.ruta?.value
					: this.props.contacto?.ruta?.id,
				plazo: {
					value: this.props.contacto?.plazo?.id,
					label: this.props.contacto?.plazo?.nombre,
					dias: this.props.contacto?.plazo?.dias,
				},
				vendedor: this.props.contacto?.vendedor?.value
					? this.props.contacto?.vendedor?.value
					: this.props.contacto?.vendedor?.id,
				cliente: this.props.contacto?.tipo?.includes("Cliente"),
				proveedor: this.props.contacto?.tipo?.includes("Proveedor"),
			},
			dropdownOptions: {
				...state.dropdownOptions,
				provincias: [
					...state.dropdownOptions.provincias,
					...provinciasMunicipiosForDropdown,
				],
			},
			searchEncargado:
				this.props.contacto?.identificacion_encargado || "",
		}));

        const params = queryString.parse(window.location.search);
        if (params.crear_contacto) {
            document.querySelector('#nombre').value = params.crear_contacto;
        }
	}
	getTable = async (url = null) => {
		const table = await this.props.request(url);

		if (table.data) {
			this.setState({
				[url]: table.data,
				loading: false,
			});
		}
	};
	changeInput = (e, input) => {
		e.preventDefault();
		const { value } = e.target;
		this.setState((state) => ({
			...state,
			form: {
				...state.form,
				[input]: value,
			},
		}));
	};
	changeSelect = (value, input) => {
		this.setState((state) => ({
			...state,
			form: {
				...state.form,
				[input]: value,
			},
		}));
	};
	selectedIdentification = () => {
		if (this.state.form.tipo_identificacion === "cedula") return "Cédula";
		if (this.state.form.tipo_identificacion === "rnc") return "RNC";
		if (this.state.form.tipo_identificacion === "pasaporte")
			return "Pasaporte";

		return null;
	};
	getTerminosForDropdown = () => {
		this.props.request("terminos-pago?limit=100").then((res) => {
			this.setState((state) => ({
				...state,
				dropdownOptions: {
					...state.dropdownOptions,
					terminos: res.data.map((termino) => ({
						value: termino.id,
						label: termino.nombre,
						dias: termino.dias,
					})),
				},
			}));
		});
	};
	getRutasForDropdown = () => {
		this.props.request("rutas").then((res) => {
			this.setState((state) => ({
				...state,
				dropdownOptions: {
					...state.dropdownOptions,
					rutas: res.data.map((ruta) => ({
						value: ruta.id,
						label: `${ruta.nombre} ${ruta.descripcion}`,
					})),
				},
			}));
		});
	};
	getVendedoresForDropdown = () => {
		this.props.request("empleados?limit=100").then((res) => {
			this.setState((state) => ({
				...state,
				dropdownOptions: {
					...state.dropdownOptions,
					vendedores: res.data.map((vendedor) => ({
						value: vendedor.id,
						label: `${vendedor.nombre} ${vendedor.apellido}`,
					})),
				},
			}));
		});
	};
	getCodigosContacto = () => {
		this.props.request("codigos-contacto").then((res) => {
			this.setState((state) => ({
				...state,
				dropdownOptions: {
					...state.dropdownOptions,
					codigos: res.data,
				},
			}));
		});
	};
	getCantPreciosContacto = () => {
		this.props.request("cantidad-precio-maximo-registrado").then((res) => {
			let precios = [];
			for (let i = 1; i <= res.data; i++) {
				precios.push({ value: i, label: `Precio venta ${i}` });
			}
			this.setState((state) => ({
				...state,
				dropdownOptions: {
					...state.dropdownOptions,
					cantidadPrecios: precios,
				},
			}));
		});
	};
	handleProvinciaChange = (newValue) => {
		this.setState({ provincia: newValue });
	};
	createProvincia = async (inputValue) => {
		this.setState({ isLoading: true });
		const formData = new FormData();
		formData.append("seccion", "contacto");
		formData.append("nombre", "provincia");
		formData.append("valor", inputValue);

		const { success, data } = await apiRequest(
			formData,
			"store-config",
			"post"
		);

		if (success) {
			this.setState((state) => ({
				...state,
				provincia: { value: data.valor, label: data.valor },
				isLoading: false,
				dropdownOptions: {
					...state.dropdownOptions,
					provincias: [
						...state.dropdownOptions.provincias,
						{ value: data.valor, label: data.valor },
					],
				},
			}));
		}
	};
	getProvincias = async () => {
		const provincias = await apiRequest(
			null,
			"get-config-for-dropdown?name=provincia"
		);

		const { success, data } = provincias;

		if (success) {
			this.setState((state) => ({
				...state,
				isLoading: false,
				dropdownOptions: {
					...state.dropdownOptions,
					provincias: [...provinciasMunicipiosForDropdown, ...data],
				},
			}));
			return;
		}
	};
	buscarInfoContacto = async (e, entityType) => {
		e.preventDefault();

		this.setState({
			searching: true,
		});

		const {
			searchEncargado,
			form: { tipo_identificacion, identificacion },
		} = this.state;

		const searchString =
			entityType === "contacto" ? identificacion : searchEncargado;

		let url = `https://app.migente.com.do/api/getpadronexterno/?cedula=${searchString}&user=felipe_api&pswd=F3l1pe`;

		if (tipo_identificacion === "rnc") {
			url = apiUrl(`get-rnc-info?rnc=${searchString}`);

			const response = apiRequest(
				null,
				`get-rnc-info?rnc=${searchString}`
			);

			response.then((res) => {
				if (!res.existe) {
					toast("Persona/Empresa No existe", "error");
					return;
				}
				this.setState((state) => ({
					...state,
					form: {
						...state.form,
						nombre: res.data[0].nombre,
					},
				}));
			});
			return;
		}

		const res = await fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
			},
		});
		res.json().then((res) => {
			if (!res.Existe) {
				toast("Persona/Empresa No existe", "error");
				return;
			}

			if (entityType === "contacto") {
				this.setState((state) => ({
					...state,
					form: {
						...state.form,
						nombre: res.Persona.NombreCompleto,
						provincia: res.Persona.Provincia,
						direccion: res.Persona.Municipio,
					},
				}));

				return;
			} else if (
				entityType === "contacto" &&
				tipo_identificacion === "rnc"
			) {
				this.setState((state) => ({
					...state,
					form: {
						...state.form,
						nombre: res.nombre,
					},
				}));
				return;
			}

			this.setState((state) => ({
				...state,
				form: {
					...state.form,
					encargado: res.Persona.NombreCompleto,
				},
			}));
		});
	};
	renderClientExtraFields = () => {
		const { form, dropdownOptions } = this.state;
		const { errors } = this.props;

		if (form.cliente) {
			return (
				<>
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="ruta_id"
								className="label form-control-label"
							>
								Zona:
							</label>
						</div>
						{dropdownOptions.rutas?.length > 0 && (
							<div className="col col-md-7">
								<Select
									name="ruta_id"
									id="ruta_id"
									defaultValue={
										dropdownOptions.rutas.filter(
											(r) => r.value === form.ruta
										)[0]
									}
									options={dropdownOptions.rutas}
									placeholder="Seleccionar..."
									aria-label="rutaInput"
								/>
								{errors.ruta_id && (
									<small className="help-blockParams form-Text">
										{errors.ruta_id[0]}
									</small>
								)}
							</div>
						)}
					</div>
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="vendedor_id"
								className="label form-control-label"
							>
								Vendedor:
							</label>
						</div>
						{dropdownOptions.vendedores?.length > 0 && (
							<div className="col col-md-7">
								<Select
									name="vendedor_id"
									id="vendedor"
									defaultValue={
										dropdownOptions.vendedores.filter(
											(v) => v.value === form.vendedor
										)[0]
									}
									options={dropdownOptions.vendedores}
									placeholder="Seleccionar..."
									aria-label="vendedorInput"
								/>
								{errors.vendedor_id && (
									<small className="help-blockParams form-Text">
										{errors.vendedor_id[0]}
									</small>
								)}
							</div>
						)}
					</div>
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="precio_venta"
								className="label form-control-label"
							>
								Bandera de Precio:
							</label>
						</div>
						<div className="col col-md-7">
							<div className="form-check form-check-inline col-4">
								<input
									className="form-check-input"
									type="checkbox"
									id="check-bandera"
									name="bandera"
									aria-label="checkBandera"
									checked={form.bandera}
									onChange={(e) => {
										this.setState((state) => ({
											...state,
											form: {
												...state.form,
												bandera: !state.form.bandera,
											},
										}));
									}}
								/>
								<label
									className="form-check-label"
									htmlFor="check-cliente"
								></label>
							</div>
						</div>
					</div>

					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="precio_venta"
								className="label form-control-label"
							>
								Precio de venta:
							</label>
						</div>
						{dropdownOptions.cantidadPrecios?.length > 0 && (
							<div className="col col-md-7">
								<Select
									name="precio_venta"
									id="precio_venta"
									defaultValue={
										dropdownOptions.cantidadPrecios.filter(
											(p) => p.value === form.precio_venta
										)[0]
									}
									options={dropdownOptions.cantidadPrecios}
									placeholder="Seleccionar..."
									aria-label="precioVentaInput"
								/>
								{errors.precio_venta && (
									<small className="help-blockParams form-Text">
										{errors.precio_venta[0]}
									</small>
								)}
							</div>
						)}
					</div>
				</>
			);
		}

		return null;
	};
	render() {
		const { contacto, errors } = this.props;
		const { dropdownOptions, form, searchEncargado, isLoading } =
			this.state;
		return (
			<div className="row">
				<div className="col-6">
					{Object.keys(contacto).length > 0 ? (
						<div className="row form-group">
							<div className="col col-md-5">
								<label
									htmlFor="codigo"
									className="label form-control-label"
								>
									Código:
								</label>
							</div>
							<div className="col col-md-7">
								<div className="font-weight-bold font-size-lg">
									{form.codigo}
									{/* <input
										hidden
										type="text"
										name="codigo"
										value={form.codigo}
									/> */}
								</div>
							</div>
						</div>
					) : (
						<div className="row form-group">
							<div className="col col-md-5">
								<label
									htmlFor="codigo"
									className="label form-control-label"
								>
									Código:
								</label>
							</div>
							<div className="col col-md-7">
								{dropdownOptions.codigos?.length > 0 && (
									<CreatableSelect
										id="codigo"
										name="codigo"
										options={dropdownOptions.codigos}
										menuPortalTarget={document.body}
										defaultValue={
											dropdownOptions.codigos?.length <= 1
												? {
														value: dropdownOptions
															.codigos[0]?.value,
														label: dropdownOptions
															.codigos[0]?.label,
												  }
												: {
														value: null,
														label: "Seleccione...",
												  }
										}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 9999,
											}),
											control: (styles) => ({
												...styles,
												height: "38px",
											}),
										}}
									/>
								)}
								{errors.codigo && (
									<small className="help-blockParams form-Text">
										{errors.codigo[0]}
									</small>
								)}
							</div>
						</div>
					)}
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="tipo_identificacion"
								className="label form-control-label"
							>
								Tipo de identificación:
							</label>
						</div>
						<div className="col col-md-7">
							<Select
								name="tipo_identificacion"
								value={
									dropdownOptions.tipos_identificacion.filter(
										(t) =>
											t.value === form.tipo_identificacion
									)[0]
								}
								placeholder="Seleccionar..."
								onChange={(e) =>
									this.changeSelect(
										e.value,
										"tipo_identificacion"
									)
								}
								options={dropdownOptions.tipos_identificacion}
							/>
							{errors.tipo_identificacion && (
								<small className="help-blockParams form-Text">
									{errors.tipo_identificacion[0]}
								</small>
							)}
						</div>
					</div>
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="identificacion"
								className="label form-control-label"
							>
								{this.selectedIdentification()} Número:
							</label>
						</div>
						<div className="col col-md-7">
							<div className="row w-100">
								<div className="col-11">
									<input
										type="text"
										className="text-input form-control"
										id="identificacion"
										name="identificacion"
										value={form.identificacion}
										aria-label="identificacionInput"
										onChange={(e) =>
											this.changeInput(
												e,
												"identificacion"
											)
										}
									/>
								</div>
								<div className="col-1 px-0">
									<button
										type="button"
										className="btn btn-primary"
										onClick={(e) =>
											this.buscarInfoContacto(
												e,
												"contacto"
											)
										}
									>
										<i className="fas fa-search"></i>
									</button>
								</div>
							</div>

							{errors.identificacion && (
								<small className="help-blockParams form-Text">
									{errors.identificacion[0]}
								</small>
							)}
						</div>
					</div>
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="nombre"
								className="label form-control-label"
							>
								Nombre/Empresa:
							</label>
						</div>
						<div className="col col-md-7">
							<input
								type="text"
								className="text-input form-control"
								id="nombre"
								name="nombre"
								aria-label="nombreInput"
								defaultValue={form.nombre}
							/>
							{errors.nombre && (
								<small className="help-blockParams form-Text">
									{errors.nombre[0]}
								</small>
							)}
						</div>
					</div>
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="inputProvincia"
								className="label form-control-label"
							>
								Municipio/Provincia:
							</label>
						</div>
						{dropdownOptions.provincias?.length > 0 && (
							<div className="col col-md-7">
								<CreatableSelect
									id="inputProvincia"
									options={dropdownOptions.provincias}
									name="provincia"
									onCreateOption={this.createProvincia}
									isLoading={isLoading}
									defaultValue={
										dropdownOptions.provincias.filter(
											(p) => p.value === form.provincia
										)[0]
									}
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 9999,
										}),
										control: (styles) => ({
											...styles,
											height: "38px",
										}),
									}}
								/>
								{errors.provincia && (
									<small className="help-blockParams form-Text">
										{errors.provincia[0]}
									</small>
								)}
							</div>
						)}
					</div>
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="encargado"
								className="label form-control-label"
							>
								Encargado:
							</label>
						</div>
						<div className="col col-md-7">
							<input
								type="text"
								className="text-input form-control"
								id="encargado"
								name="encargado"
								aria-label="encargadoInput"
								defaultValue={form.encargado}
							/>
							{errors.encargado && (
								<small className="help-blockParams form-Text">
									{errors.encargado[0]}
								</small>
							)}
						</div>
					</div>
					{form.tipo_identificacion === "rnc" && (
						<div className="row form-group">
							<div className="col col-md-5">
								<label
									htmlFor="identificacion_encargado"
									className="label form-control-label"
								>
									Cédula Encargado:
								</label>
							</div>
							<div className="col col-md-7">
								<div className="row w-100">
									<div className="col-11">
										<input
											type="text"
											className="text-input form-control"
											id="identificacion_encargado"
											name="identificacion_encargado"
											aria-label="identificacionEncargadoInput"
											value={searchEncargado}
											onChange={(e) =>
												this.setState({
													searchEncargado:
														e.target.value,
												})
											}
										/>
									</div>
									<div className="col-1 px-0">
										<button
											type="button"
											className="btn btn-primary"
											onClick={(e) =>
												this.buscarInfoContacto(
													e,
													"encargado"
												)
											}
										>
											<i className="fas fa-search"></i>
										</button>
									</div>
								</div>

								{errors.rnc && (
									<small className="help-blockParams form-Text">
										{errors.rnc[0]}
									</small>
								)}
							</div>
						</div>
					)}
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="telefono"
								className="label form-control-label"
							>
								Teléfono:
							</label>
						</div>
						<div className="col col-md-7">
							<input
								type="text"
								className="text-input form-control"
								id="telefono"
								name="telefono"
								aria-label="telefonoInput"
								defaultValue={form.telefono}
							/>
							{errors.telefono && (
								<small className="help-blockParams form-Text">
									{errors.telefono[0]}
								</small>
							)}
						</div>
					</div>
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="celular"
								className="label form-control-label"
							>
								Celular:
							</label>
						</div>
						<div className="col col-md-7">
							<input
								type="text"
								className="text-input form-control"
								id="celular"
								name="celular"
								aria-label="celularInput"
								defaultValue={form.celular}
							/>
							{errors.celular && (
								<small className="help-blockParams form-Text">
									{errors.celular[0]}
								</small>
							)}
						</div>
					</div>
				</div>
				<div className="col-6">
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="email"
								className="label form-control-label"
							>
								Correo electrónico:
							</label>
						</div>
						<div className="col col-md-7">
							<input
								type="text"
								className="text-input form-control"
								id="email"
								name="email"
								aria-label="emailInput"
								defaultValue={form.email}
							/>
							{errors.email && (
								<small className="help-blockParams form-Text">
									{errors.email[0]}
								</small>
							)}
						</div>
					</div>
					<div className="row form-group">
						<div className="col col-md-5">
							<label className="label form-control-label">
								Tipo:
							</label>
						</div>
						<div className="col col-md-7">
							<div className="form-check form-check-inline col-4">
								<input
									className="form-check-input"
									type="checkbox"
									id="check-cliente"
									name="cliente"
									aria-label="checkCliente"
									checked={form.cliente}
									onChange={() =>
										this.setState((state) => ({
											...state,
											form: {
												...state.form,
												cliente: !state.form.cliente,
											},
										}))
									}
								/>
								<label
									className="form-check-label"
									htmlFor="check-cliente"
								>
									Cliente
								</label>
								{errors.cliente && (
								<small className="help-blockParams form-Text">
									{errors.cliente[0]}
								</small>
							)}
							</div>
							<div className="form-check form-check-inline col-4">
								<input
									className="form-check-input"
									type="checkbox"
									id="check-proveedor"
									name="proveedor"
									aria-label="checkProveedor"
									checked={form.proveedor}
									onChange={() =>
										this.setState((state) => ({
											...state,
											form: {
												...state.form,
												proveedor:
													!state.form.proveedor,
											},
										}))
									}
								/>
								<label
									className="form-check-label"
									htmlFor="check-proveedor"
								>
									Proveedor
								</label>
							</div>
							{errors.proveedor && (
								<small className="help-blockParams form-Text">
									{errors.proveedor[0]}
								</small>
							)}
						</div>
					</div>
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="direccion"
								className="label form-control-label"
							>
								Dirección:
							</label>
						</div>
						<div className="col col-md-7">
							<input
								type="text"
								className="text-input form-control"
								id="direccion"
								name="direccion"
								aria-label="direccionInput"
								defaultValue={form.direccion}
							/>
							{errors.direccion && (
								<small className="help-blockParams form-Text">
									{errors.direccion[0]}
								</small>
							)}
						</div>
					</div>
					<div className="row form-group">
						<div className="col col-sm-5">
							<label
								htmlFor="plazo_id"
								className="label form-control-label"
							>
								Plazo de pago
							</label>
						</div>
						<div className="col col-sm-7">
							<Select
								options={dropdownOptions.terminos.filter(
									(termino) => termino.dias !== null
								)}
								id="plazo_id"
								name="plazo_id"
								placeholder="Seleccionar"
								onChange={(e) =>
									this.setState((state) => ({
										...state,
										form: {
											...state.form,
											plazo: e,
										},
									}))
								}
								value={form.plazo}
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
								}}
							/>
							{errors.plazo_id && (
								<small className="help-blockParams form-Text">
									{errors.plazo_id[0]}
								</small>
							)}
						</div>
					</div>
					{this.renderClientExtraFields()}
					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="limite_credito"
								className="label form-control-label"
							>
								Limite de crédito:
							</label>
						</div>
						<div className="col col-md-7">
							<input
								type="number"
								className="text-input form-control"
								id="limite_credito"
								name="limite_credito"
								aria-label="limiteCreditoInput"
								value={form.limite_credito}
								onChange={(e) =>
									this.changeInput(e, "limite_credito")
								}
							/>
							{errors.limite_credito && (
								<small className="help-blockParams form-Text">
									{errors.limite_credito[0]}
								</small>
							)}
						</div>
					</div>

					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="latitud_longitud"
								className="label form-control-label"
							>
								Latitud, Longitud:
							</label>
						</div>
						<div className="col col-md-7">
							<input
								type="text"
								className="text-input form-control"
								id="latitud_longitud"
								name="latitud_longitud"
								aria-label="latitud_longitud"
								value={form.latitud_longitud}
								onChange={(e) =>
									this.changeInput(e, "latitud_longitud")
								}
							/>
							{errors.latitud_longitud && (
								<small className="help-blockParams form-Text">
									{errors.latitud_longitud[0]}
								</small>
							)}
						</div>
					</div>

					<div className="row form-group">
						<div className="col col-md-5">
							<label
								htmlFor="notas"
								className="label form-control-label"
							>
								Notas:
							</label>
						</div>
						<div className="col col-md-7">
							<textarea
								type="text"
								className="text-input form-control"
								id="notas"
								name="notas"
								aria-label="notasInput"
								defaultValue={form.notas}
							></textarea>
							{errors.notas && (
								<small className="help-blockParams form-Text">
									{errors.notas[0]}
								</small>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Form.propTypes = {
	contacto: PropTypes.object.isRequired,
	errors: PropTypes.object,
};

export default withRequest(Form);
