import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { auth } from "../../../utils/auth";
import { withRequest } from "../../../utils/request";
import { apiUrl } from "../../../utils/apiUrl";
import Form from "./Form";
import Loader from "../../general/Loader";
import { getLink, toast } from "../../../utils/helpers";

class Edit extends Component {
    state = {
        errors: {},
        saving: false,
    };
    updateGrupo = async (event) => {
        event.preventDefault();

        this.setState({ saving: true });

        const token = auth.getToken();
        const data = new FormData(event.target);

        const id = this.props.grupo.id;
        const url = "gi-grupos/" + id;

        const res = await fetch(apiUrl(url), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.code === 422) {
            this.setState({ errors: response.data.message, saving: false });
            toast("Grupo no pudo ser actualizado.", "error");
            return;
        }

        if (response.success) {
            this.props.history.push(getLink("/gi-grupos"));
            toast("Grupo actualizado correctamente.");
        } else {
            toast("Grupo no pudo ser actualizado.", "error");
        }

        this.setState({ saving: false });
    };
    deleteGrupo = async (event, grupo) => {
        event.preventDefault();

        const token = auth.getToken();

        const url = `gi-grupos/${grupo}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.history.push(getLink("/gi-grupos"));
            toast("Grupo borrado.");
        } else {
            toast("Grupo no pudo ser borrado.", "error");
        }
    };
    render() {
        const { grupo } = this.props;
        const { errors, saving } = this.state;

        return (
            <form
                method='post'
                onSubmit={this.updateGrupo}
                encType='multipart/form-data'>
                <input type='hidden' name='_method' value='patch' />
                <Form grupo={grupo} errors={errors} codigo={0} />
                <div className='page-footer text-right'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={(e) => this.deleteGrupo(e, grupo.id)}>
                        <i className='fas fa-trash-alt'></i>
                    </button>
                    <Link to={getLink("/gi-grupos")} className='btn btn-plain'>
                        Cancelar
                    </Link>
                    {saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Guardar
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default withRouter(withRequest(Edit));
