import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import dayjs from "dayjs";

import { withRequest } from "../../../utils/request";
import { formatCurrency, formatNumber } from "../../../utils/helpers";
import { auth } from "../../../utils/auth";

class ResumenCategorizado extends Component {
    state = {
        nominas: [{ pagos: [] }],
        columnas: [],
        orden: 'nombre',
        redondear: false,
    };
    componentDidMount() {
        this.getNomina();
        this.getColumnasNomina();
    }
    getNomina = async (url = null) => {
        const id = this.props.match.params.id;
        const search = window.location.search ? window.location.search : "";
        url = url ? url : "nominas/" + id + search;
        const nominas = await this.props.request(url);

        if (nominas.success) {
            this.setState({
                nominas: nominas.data,
            });
            //window.print();
        }
    };
    getColumnasNomina = async (url = null) => {
        url = `taxonomias?relacion=nomina&tipo=columnas`;
        const columnas = await this.props.request(url);

        if (columnas) {
            this.setState({
                columnas: columnas.data,
            });
        }
    };
    onChangeOrden = (e) => {
        this.setState({
            orden: e.target.value
        });
    }
    onChangeRedondear = () => {
        this.setState({
            redondear: !this.state.redondear
        });
    }
    redondear = (cantidad) => {
        return Math.round(cantidad/5) * 5;
    }
    render() {
        const { nominas, columnas, orden, redondear } = this.state;

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);
        const nomina = nominas[0];

        const pagos = nominas.reduce((acc, nom) => {
            acc = [...acc, ...nom.pagos];
            return acc;
        }, []);

        const empleadosCombinados = pagos.reduce((acc, empleado) => {
            if (acc[empleado.cedula] === undefined) {
                acc[empleado.cedula] = empleado;
            } else {
                acc[empleado.cedula].toneladas =
                    parseFloat(acc[empleado.cedula].toneladas) +
                    parseFloat(empleado.toneladas);
                acc[empleado.cedula].total =
                    parseFloat(acc[empleado.cedula].sueldoBruto) +
                    parseFloat(empleado.sueldoBruto);
                acc[empleado.cedula].sueldoBruto =
                    parseFloat(acc[empleado.cedula].sueldoBruto) +
                    parseFloat(empleado.sueldoBruto);
            }
            return acc;
        }, {});

        const empleados = Object.keys(empleadosCombinados)
            .sort((a, b) => {
                return empleadosCombinados[a].nombre <
                    empleadosCombinados[b].nombre
                    ? -1
                    : 1;
            })
            .reduce((acc, empleado) => {
                const emp = empleadosCombinados[empleado];
                if (acc[emp.cargo] === undefined) {
                    acc[emp.cargo] = [emp];
                } else {
                    acc[emp.cargo].push(emp);
                }
                return acc;
            }, {});

        const totalEmpleados = nominas.reduce((acc, nomina) => {
            return parseInt(nomina.empleados) + acc;
        }, 0);

        const totalPagos = nominas.reduce((acc, nomina) => {
            var totalPagos = 0;
            if (redondear) {
                totalPagos = nomina.pagos.reduce((c, pago) => {
                    const total = this.redondear(parseInt(pago.total));
                    return total + c;
                }, 0);
            } else {
                totalPagos = nomina.total
            }
            return totalPagos + acc;
        }, 0);

        const abreviaciones = columnas.reduce((acc, col) => {
            acc[col.nombre] = col.nombre.split(' ').map(c => c[0]).join('');
            return acc;
        }, {})

        return (
            <div>
                <style>{`@page {size: 14in  8.5in;} *{font-size: 14px}`}</style>
                <div className="ordernar-resultados no-print">
                    <div className="form-group">
                        <div className="radio-btn">
                            <label>
                                <input type="radio" onChange={this.onChangeOrden} name="orden" value="nombre" defaultChecked={true} /> Ordenar por Nombre
                            </label>
                            <label>
                                <input type="radio" onChange={this.onChangeOrden} name="orden" value="codigo" /> Ordenar por Código
                            </label>
                            <label>
                                <input type="checkbox" onChange={this.onChangeRedondear} name="redondear" value="redondear" /> Redondear
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {nomina.cerradaEn && (
                        <div className='fecha_cierre col-md-12'>
                            Nomina Cerrada:{" "}
                            {dayjs(nomina.cerradaEn).format("DD/MM/YYYY")}
                        </div>
                    )}
                    <h3 className='col-md-12 text-center m-b-5'>
                        Nómina {nomina.nombre}
                        {query.contrato === "informal" ? "Informales" : ""}
                    </h3>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(nomina.fechaInicio).format("DD/MM/YYYY")} -{" "}
                        {dayjs(nomina.fechaCierre).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                </div>
                <div className='row text-right'>
                    <div className='col-sm-3 text-left'>
                        EMPLEADOS: {totalEmpleados}
                    </div>
                    <div className="col"></div>
                    <div className='col-sm-3 text-right'>
                        <h3>TOTAL: {formatCurrency(totalPagos)}</h3>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th className="text-center">Sueldo Fijo</th>
                            <th className="text-center">Incentivos Fijos</th>
                            <th className="text-center">Incentivos Únicos</th>
                            <th className="text-center">Total Incentivos</th>
                            <th className="text-center">Descuentos Fijos</th>
                            <th className="text-center">Otros Descuentos</th>
                            <th className="text-center">TSS+AFP</th>
                            <th className="text-center">Total Descuentos</th>
                            <th className="text-center">Total Nómina</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">
                                {formatCurrency(Object.keys(empleados).reduce((acc, key) => {
                                    return acc + empleados[key].reduce((a, e) => a + parseFloat(e.sueldoFijo), 0);
                                }, 0))}
                            </td>
                            <td className="text-center">
                                {formatCurrency(Object.keys(empleados).reduce((acc, key) => {
                                    return acc + empleados[key].reduce((a, e) => a + parseFloat(e.incentivosFijos), 0);
                                }, 0))}
                            </td>
                            <td className="text-center">
                                {formatCurrency(Object.keys(empleados).reduce((acc, key) => {
                                    return acc + empleados[key].reduce((a, e) => a + parseFloat(e.incentivosUnicos), 0);
                                }, 0))}
                            </td>
                            <td className="text-center">
                                {formatCurrency(Object.keys(empleados).reduce((acc, key) => {
                                    return acc + empleados[key].reduce((a, e) => a + parseFloat(e.incentivos), 0);
                                }, 0))}
                            </td>
                            <td className="text-center">
                                {formatCurrency(Object.keys(empleados).reduce((acc, key) => {
                                    return acc + empleados[key].reduce((a, e) => a + parseFloat(e.descuentosFijos), 0);
                                }, 0))}
                            </td>
                            <td className="text-center">
                                {formatCurrency(Object.keys(empleados).reduce((acc, key) => {
                                    return acc + empleados[key].reduce((a, e) => a + parseFloat(e.otrosDescuentos), 0);
                                }, 0))}
                            </td>
                            <td className="text-center">
                                {formatCurrency(Object.keys(empleados).reduce((acc, key) => {
                                    return acc + empleados[key].reduce((a, e) => a + parseFloat(e.tss), 0);
                                }, 0))}
                            </td>
                            <td className="text-center">
                                {formatCurrency(Object.keys(empleados).reduce((acc, key) => {
                                    return acc + empleados[key].reduce((a, e) => a + parseFloat(e.descuentosTotal), 0);
                                }, 0))}
                            </td>
                            <td className="text-center">
                                {formatCurrency(totalPagos)}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <td>
                                <div className="m-t-20 abreviaciones text-center">
                                    {Object.keys(abreviaciones).map(a => (<span key={a} className="m-r-15"><strong>{abreviaciones[a]}</strong>={a}</span>))}
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {Object.keys(empleados).map((key) => {
                                    const subtotalDescuentos = empleados[key].reduce(
                                        (acc, empleado) => {
                                            return acc + parseFloat(empleado.descuentosTotal);
                                        },
                                        0
                                    );

                                    const subtotalTss = empleados[key].reduce(
                                        (acc, empleado) => {
                                            return acc + parseFloat(empleado.tss);
                                        },
                                        0
                                    );

                                    const subtotalIncentivos = empleados[key].reduce(
                                        (acc, empleado) => {
                                            return acc + parseFloat(empleado.incentivos);
                                        },
                                        0
                                    );

                                    const subtotal = empleados[key].reduce((acc, empleado) => {
                                        return acc + parseFloat(empleado.sueldoBruto - empleado.descuentosTotal);
                                    }, 0);

                                    const totalCategoria = empleados[key].reduce((acc, empleado) => {
                                        if (redondear) {
                                            return acc + this.redondear(parseFloat(empleado.total));
                                        }
                                        return acc + parseFloat(empleado.total);
                                    }, 0);

                                    const totalesColumnas = columnas.reduce((a, c) => {a[c.nombre] = 0; return a;}, {});
                                    return (
                                        <div key={empleados[key][0].cargo}>
                                            <h3 className="pt-5 pb-3">
                                                <div className='float-left'>
                                                    TIPO EMPLEADO: {empleados[key][0].cargo} -
                                                    EMPLEADOS: {empleados[key].length}
                                                </div>
                                                <div className='float-right'>
                                                    TOTAL:
                                                    {formatCurrency(subtotal + subtotalIncentivos)}
                                                </div>
                                            </h3>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>Código</th>
                                                        <th><div style={{minWidth: '270px'}}>Nombre</div></th>
                                                        {nomina.tipo !== 'FIJOS' && (
                                                            <th className='text-center'>
                                                                Ventas
                                                            </th>
                                                        )}
                                                        <th className='text-right'>
                                                            Sueldo Fijo
                                                        </th>
                                                        {columnas.map(col => (
                                                            <th key={col.id} className="text-right" width="100px">{abreviaciones[col.nombre]}</th>
                                                        ))}
                                                        <th className='text-right'>
                                                            AFP + TSS
                                                        </th>
                                                        <th className='text-right'>
                                                            Descuentos
                                                        </th>
                                                        <th className='text-right'>
                                                            Incentivos
                                                        </th>
                                                        <th className='text-right'>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {empleados[key]
                                                        .sort((a, b) => {
                                                            if ( orden === 'codigo') {
                                                                if (!a.codigo) {
                                                                    return 1;
                                                                }
                                                                return a.codigo.localeCompare(b.codigo);
                                                            }

                                                            return a.nombre.localeCompare(b.nombre);
                                                        })
                                                        .map((empleado, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td><div style={{width: '70px', paddingRight: '5px'}}>{empleado.codigo}</div></td>
                                                                <td>{empleado.nombre}</td>
                                                                {nomina.tipo !== 'FIJOS' && (
                                                                    <td className='text-center'>
                                                                        {formatNumber(
                                                                            empleado.toneladas
                                                                        )}
                                                                    </td>
                                                                )}
                                                                <td className='text-right'>
                                                                    {formatCurrency(
                                                                        parseFloat(
                                                                            empleado.sueldoFijo
                                                                        )
                                                                    )}
                                                                </td>
                                                                {columnas.map(col => {
                                                                    const desc = empleado.descuentos
                                                                        .filter(d => d.columna === col.nombre)
                                                                        .reduce((a, d) => a + parseFloat(d.monto), 0);

                                                                    const descP = empleado.descuentosProgramados
                                                                        .filter(d => d.columna === col.nombre)
                                                                        .reduce((a, d) => a + parseFloat(d.monto), 0);

                                                                    totalesColumnas[col.nombre] += desc + descP;
                                                                    return (
                                                                    <td key={col.id} className="text-right">{formatCurrency(desc + descP)}</td>
                                                                )})}
                                                                <td className='text-right'>
                                                                    {formatCurrency(
                                                                        empleado.tss
                                                                    )}
                                                                </td>
                                                                <td className='text-right'>
                                                                    {formatCurrency(
                                                                        empleado.descuentosTotal
                                                                    )}
                                                                </td>
                                                                <td className='text-right'>
                                                                    {formatCurrency(
                                                                        empleado.incentivos
                                                                    )}
                                                                </td>
                                                                <td className='text-right'>
                                                                    {redondear ? (
                                                                        <span>
                                                                            {formatCurrency(
                                                                                this.redondear(parseFloat(empleado.total))
                                                                                )}
                                                                        </span>
                                                                    ) : (
                                                                        <span>
                                                                            {formatCurrency(
                                                                                parseFloat(empleado.total)
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <td></td>
                                                        <td></td>
                                                        {nomina.tipo !== 'FIJOS' && (
                                                            <td></td>
                                                        )}
                                                        <td>TOTALES =></td>
                                                        {columnas.map(col => (
                                                            <td key={col.id} className='text-right'>
                                                                {formatCurrency(totalesColumnas[col.nombre])}
                                                            </td>
                                                        ))}
                                                        <td className='text-right'>
                                                            {formatCurrency(subtotalTss)}
                                                        </td>
                                                        <td className='text-right'>
                                                            {formatCurrency(subtotalDescuentos)}
                                                        </td>
                                                        <td className='text-right'>
                                                            {formatCurrency(subtotalIncentivos)}
                                                        </td>
                                                        <td className='text-right'>
                                                            {formatCurrency(totalCategoria)}
                                                        </td>
                                                        <td />
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    );
                                })}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className='row'>
                    <div className='col-md-12 text-right p-t-10 p-b-10'>
                        <div className='float-left'>
                            EMPLEADOS: {totalEmpleados}
                        </div>

                        <div className='float-right'>
                            <h3>TOTAL: {formatCurrency(totalPagos)}</h3>
                        </div>
                    </div>
                </div>
                <div className='firma-reporte'>
                    <div className='firma'>Supervisor</div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(ResumenCategorizado));
