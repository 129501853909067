/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../utils/auth";
import { formatCurrency } from "../../utils/helpers";
import { request } from "../../utils/request";

const PrintCompras = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [compras, setCompras] = useState([]);

	const [summaries, setSummaries] = useState({
		efectivo: 0,
		transferencia: 0,
		tarjeta: 0,
		credito: 0,
		permuta: 0,
		nota: 0,
		mixto: 0,
	});

	const getFacturas = () => {
		const searchString = window.location.search === '' ? "?paginate=100000" : window.location.search + "&paginate=1000000";
		request("compras" + searchString).then(
			(res) => {
				setCompras(res.data);
			}
		);
	};

	const formatDateTime = (date) => {
		return dayjs(date).format("DD/MM/YYYY, hh:mm a");
	};

	const calculateSummaries = useCallback(() => {
		const formaPago = {
			efectivo: 0,
			transferencia: 0,
			tarjeta: 0,
			credito: 0,
			permuta: 0,
			nota: 0,
			mixto: 0,
		};
		
		for (const compra of compras) {
			if (compra.estado === "cancelada") continue;

			switch (compra.tipoPago) {
				case "01 - EFECTIVO":
					formaPago.efectivo += compra.total - compra.porPagar;
					break;
				case "02 - CHEQUES/TRANSFERENCIAS":
					formaPago.transferencia += compra.total - compra.porPagar;
					break;
				case "03 - TARJETA DE CREDITO/DEBITO":
					formaPago.tarjeta += compra.total - compra.porPagar;
					break;
				case "04 - COMPRA A CREDITO":
					formaPago.credito += compra.total - compra.porPagar;
					break;
				case "05 - PERMUTA":
					formaPago.permuta += compra.total - compra.porPagar;
					break;
				case "06 - NOTA DE CREDITO":
					formaPago.nota += compra.total - compra.porPagar;
					break;
				case "07 - MIXTO":
					formaPago.mixto += compra.total - compra.porPagar;
					break;
				default:
					break;
			}
		}

		setSummaries(formaPago);
		// compras.forEach((compra) => {
		// 	switch (compra.formaPago) {
		// 		case "efectivo":
		// 			setSummaries((prevState) => ({
		// 				...prevState,
		// 				efectivo:
		// 					prevState.efectivo +
		// 					(compra.total - compra.porPagar),
		// 			}));
		// 			break;
		// 		case "tarjeta":
		// 			setSummaries((prevState) => ({
		// 				...prevState,
		// 				tarjeta:
		// 					prevState.tarjeta +
		// 					(compra.total - compra.porPagar),
		// 			}));
		// 			break;
		// 		case "transferencia":
		// 			setSummaries((prevState) => ({
		// 				...prevState,
		// 				transferencia:
		// 					prevState.transferencia +
		// 					(compra.total - compra.porPagar),
		// 			}));
		// 			break;
		// 		case "cheque":
		// 			setSummaries((prevState) => ({
		// 				...prevState,
		// 				cheque:
		// 					prevState.cheque +
		// 					(compra.total - compra.porPagar),
		// 			}));
		// 			break;
		// 		case "otro":
		// 			setSummaries((prevState) => ({
		// 				...prevState,
		// 				otro:
		// 					prevState.otro +
		// 					(compra.total - compra.porPagar),
		// 			}));
		// 			break;

		// 		default:
		// 			break;
		// 	}

		// 	if (compra.estado === "pendiente") {
		// 		setSummaries((prevState) => ({
		// 			...prevState,
		// 			pendientesDePago:
		// 				prevState.pendientesDePago + compra.porPagar,
		// 		}));
		// 	} else if (compra.estado === "cancelada") {
		// 		setSummaries((prevState) => ({
		// 			...prevState,
		// 			canceladas: prevState.canceladas + compra.total,
		// 		}));
		// 	}
		// 	if (compra.estado !== "cancelada") {
		// 		setSummaries((prevState) => ({
		// 			...prevState,
		// 			pagadas:
		// 				prevState.pagadas +
		// 				(compra.total - compra.porPagar),
		// 		}));
		// 	}
		// });
	}, [compras]);

	useEffect(() => {
		getFacturas();
	}, []);
	useEffect(() => {
		if (compras.length <= 0) return;
		calculateSummaries();
		const timeout = setTimeout(() => {
			window.print();
		}, 700);

		return () => {
			clearTimeout(timeout);
		};
	}, [compras]);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotal = compras.reduce((acc, compra) => {
		return acc + parseFloat(compra.total);
	}, 0);
	const valorTotalCanceladaCompras = compras.reduce((acc, compra) => {
		return (
			acc +
			(compra.estado === "cancelada" ? parseFloat(compra.total) : 0)
		);
	}, 0);
	const valorTotalPendienteCompras = compras.reduce((acc, compra) => {
		return (
			acc +
			(compra.estado === "cancelada" ? 0 : parseFloat(compra.porPagar))
		);
	}, 0);
	const valorTotalPagadaCompras = compras.reduce((acc, compra) => {
		return (
			acc +
			(compra.estado === "cancelada" ? 0 : parseFloat(compra.total) - parseFloat(compra.porPagar))
		);
	}, 0);

	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Compras
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde") && queryParams.get("hasta") ? (
                        <span>
                            {queryParams.get("desde")
                                ? `${dayjs(queryParams.get("desde")).format(
                                        "DD/MM/YYYY"
                                  )} - `
                                : ""}{" "}
                            {dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
                        </span>
                    ) : (
                        <span>{dayjs().format("DD/MM/YYYY")}</span>
                    )}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL COMPRAS: {compras.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-8">
					<h5 className="fw-600 w-100 text-center mb-2">
						FORMAS DE PAGO
					</h5>
					<table className="table m-b-40">
						{/* <thead>
							<tr>
								<th>Efectivo</th>
								<th>Tarjeta</th>
								<th>Transferencia</th>
								<th>Cheque</th>
								<th width="90px">Otro</th>
							</tr>
						</thead> */}
						<tbody>
							<tr>
								<th>01 - EFECTIVO</th>
								<td>{formatCurrency(summaries.efectivo, "$")}</td>
								<th>05 - PERMUTA</th>
								<td>{formatCurrency(summaries.permuta, "$")}</td>
							</tr>
							<tr>
								<th>02 - CHEQUES/TRANSFERENCIAS</th>
								<td>{formatCurrency(summaries.transferencia, "$")}</td>
								<th>06 - NOTA DE CREDITO</th>
								<td>{formatCurrency(summaries.nota, "$")}</td>
							</tr>
							<tr>
								<th>03 - TARJETA DE CREDITO/DEBITO</th>
								<td>{formatCurrency(summaries.tarjeta, "$")}</td>
								<th>07 - MIXTO</th>
								<td>{formatCurrency(summaries.mixto, "$")}</td>
							</tr>
							<tr>
								<th>04 - COMPRA A CREDITO</th>
								<td>{formatCurrency(summaries.credito, "$")}</td>
								<td></td>
							</tr>
							{/* <tr>
								<td>
									{formatCurrency(summaries.efectivo, "$")}
								</td>
								<td>
									{formatCurrency(summaries.tarjeta, "$")}
								</td>
								<td>
									{formatCurrency(
										summaries.transferencia,
										"$"
									)}
								</td>
								<td>{formatCurrency(summaries.cheque, "$")}</td>
								<td>{formatCurrency(summaries.otro, "$")}</td>
							</tr> */}
						</tbody>
					</table>
				</div>
				<div className="col-4">
					<h5 className="fw-600 w-100 text-center mb-2">
						DETALLES DE COMPRAS
					</h5>
					<table className="table m-b-40">
						{/* <thead>
							<tr>
								<th>Canceladas</th>
								<th>Pendiente de pago</th>
								<th>Pagadas</th>
								<th>Total comprado</th>
							</tr>
						</thead> */}
						<tbody>
							<tr>
								<th>Canceladas</th>
								<td>
									{formatCurrency(valorTotalCanceladaCompras, "$")}
								</td>
							</tr>
							<tr>
								<th>Pendiente de pago</th>
								<td>
									{formatCurrency(valorTotalPendienteCompras, "$")}
								</td>
							</tr>
							<tr>
								<th>Pagadas</th>
								<td>
									{formatCurrency(valorTotalPagadaCompras, "$")}
								</td>
							</tr>
							<tr>
								<th>Total comprado</th>
								<td>
									{formatCurrency(valorTotalPendienteCompras + valorTotalPagadaCompras, "$")}
								</td>
							</tr>
							{/* <tr>
								<td>
									{formatCurrency(summaries.canceladas, "$")}
								</td>
								<td>
									{formatCurrency(
										summaries.pendientesDePago,
										"$"
									)}
								</td>
								<td>
									{formatCurrency(summaries.pagadas, "$")}
								</td>
								<td>
									{formatCurrency(
										summaries.pendientesDePago +
											summaries.pagadas,
										"$"
									)}
								</td>
							</tr> */}
						</tbody>
					</table>
				</div>
			</div>
			<table className="table m-b-40">
				<thead>
					<tr>
						<th>NCF/Numero</th>
						<th>Tipo</th>
						<th>Suplidor</th>
						<th>Creación</th>
						<th className="text-right">Vencimiento</th>
						<th className="text-right">Estado</th>
						<th className="text-right">Balance</th>
						<th className="text-right">Total</th>
					</tr>
				</thead>
				<tbody>
					{compras.map((compra) => {
						return (
							<tr key={compra.id}>
								<td>{compra.ncf}</td>
								<td className="text-capitalize">
									{compra.tipo}
								</td>
								<td>
									{compra.contacto}
								</td>
								<td>{formatDateTime(compra.fecha)}</td>
								<td className="text-right">
									{dayjs(compra.fechaVencimiento).format("DD/MM/YYYY")}
								</td>
								<td className="text-capitalize text-right">
									{compra.estado}
								</td>
								<td className="text-capitalize text-right">
									{formatCurrency(compra.porPagar, "$")}
								</td>
								<td className="text-right">
									{formatCurrency(compra.total)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {compras.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrintCompras;
