import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";

class NoDespachados extends Component {
    state = {
        programacion: {},
        empleados: [],
        errors: {},
    };
    componentDidMount() {
        this.getProgramacion();
        this.getEmpleados();
    }
    getProgramacion = async () => {
        const id = this.props.match.params.id;
        const response = await this.props.request("programaciones/" + id);

        if (response.success) {
            this.setState({
                programacion: {
                    id: response.data.id,
                    nombre: response.data.nombre,
                    fecha: response.data.fecha,
                },
            });
        }
    };
    getEmpleados = async () => {
        const response = await this.props.request(
            "empleados" + window.location.search
            );

        if (response.data) {
            this.setState({
                empleados: response.data,
            });
            window.print();
        }
    };
    render() {
        const { programacion, empleados } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Reporte de No Despachados {programacion.nombre}
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-center p-b-10'></div>
                </div>
                <h3>Mensajeros</h3>
                <table className='table'>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "550px",
                                }}>
                                Nombre
                            </th>
                            <th>Cédula</th>
                            <th>Cargo</th>
                            <th>Tipo</th>
                            <th>Teléfono</th>
                            <th>Flota</th>
                        </tr>
                    </thead>
                    <tbody>
                        {empleados &&
                            empleados
                                .filter(
                                    (emp) =>
                                        ['chofer', 'mensajero'].includes(emp.tipo
                                            .toLowerCase())
                                )
                                .map((empleado) => {
                                    return (
                                        <tr key={empleado.codigo}>
                                            <td>{empleado.nombreCompleto}</td>
                                            <td>{empleado.cedula}</td>
                                            <td>{empleado.tipo}</td>
                                            <td>
                                                {empleado.informal
                                                    ? "Informal"
                                                    : "Formal"}
                                            </td>
                                            <td>{empleado.telefono}</td>
                                            <td>{empleado.flota}</td>
                                        </tr>
                                    );
                                })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(NoDespachados));
