import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import Form from "./Form";
import Loader from "../general/Loader";
import { getLink, toast } from "../../utils/helpers";

class Edit extends Component {
    state = {
        saving: false,
        errors: {},
    };
    updateVehiculo = async (event) => {
        event.preventDefault();
        this.setState({ saving: true });

        const token = auth.getToken();
        const data = new FormData(event.target);

        const id = this.props.match.params.id;
        const url = "vehiculos/" + id;

        const res = await fetch(apiUrl(url), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.history.push(getLink("/vehiculos"));
            toast("Vehiculo actualizado correctamente.");
        } else {
            toast("No se pudo actualizar el vehiculo.");
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({ saving: false });
    };
    deleteVehiculo = async (event, vehiculo) => {
        event.preventDefault();

        const token = auth.getToken();

        const url = `vehiculos/${vehiculo}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.history.push(getLink("/vehiculos"));
            toast("Vehiculo borrado.");
            return;
        } else {
            toast(
                "No se pudo borrar el vehiculo. Revisa que no tenga gastos o tarjetas asignadas.",
                "error"
            );
        }
    };
    render() {
        const { errors, saving } = this.state;
        const { vehiculo, categorias } = this.props;
        return (
            <div>
                {!vehiculo.ficha ? (
                    <Loader />
                ) : (
                    <form
                        method='post'
                        onSubmit={this.updateVehiculo}
                        encType='multipart/form-data'>
                        <input type='hidden' name='_method' value='patch' />
                        <Form
                            vehiculo={vehiculo}
                            errors={errors}
                            categorias={categorias}
                        />
                        <div className='page-footer text-right'>
                            <button
                                type='button'
                                className='btn float-left'
                                onClick={(e) =>
                                    this.deleteVehiculo(e, vehiculo.id)
                                }>
                                <i className='fas fa-trash-alt'></i>
                            </button>
                            <Link
                                to={getLink("/vehiculos")}
                                className='btn btn-plain'>
                                Cancelar
                            </Link>
                            {saving ? (
                                <Loader type='small' />
                            ) : (
                                <button
                                    type='submit'
                                    className='btn btn-primary'>
                                    Guardar
                                </button>
                            )}
                        </div>
                    </form>
                )}
            </div>
        );
    }
}

export default withRouter(Edit);
