import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { getLink } from "../../utils/helpers";

const catalogueTypes = [
	{ value: "listado", label: "Listado" },
	{ value: "imagenes", label: "Imagenes" },
];

const ProductCatalogueExportForm = ({ toggle, grupos, suplidores, request }) => {
	const [state, setState] = useState({
		dropdownOptions: {
			cantidadPrecios: []
		}
	});
	const [selectedGroup, setSelectedGroup] = useState([]);
	const [selectedSuppliers, setSelectedSuppliers] = useState([]);
	const [rncName, setRncName] = useState(false);

	// declare the data fetching function
	const fetchData = useCallback(() => {
		request("cantidad-precio-maximo-registrado")
		.then((res) => {
			let precios = [];
			for (let i = 1; i <= res.data; i++) {
				precios.push({ value: i, label: `Precio venta ${i}` });
			}
			setState((state) => ({
				...state,
				dropdownOptions: {
					...state.dropdownOptions,
					cantidadPrecios: precios,
				},
			}));
		})
		.catch((err) => console.error(err));
	}, []); 

	useEffect(() => {
		// call the function
		fetchData();
	}, [fetchData]);

	const onSelect = (e) => {
		setRncName(e.value === 'listado')
	}; 

    return (
		<form 
		method='get'
		target='_blank'
		action={getLink('/imprimir/catalogo')}
		>
            <div className='popup__header'>CAT&Aacute;LOGOS DE PRODUCTOS</div>
            <div className='popup__body' style={{overflow: "visible"}}>
				<div className="d-flex flex-row">
					<div>
						<div className='form-group m-t-0'>
							<label>Tipo de Catalogo:</label>
							<Select
								name="catalogueType"
								isClearable
								options={catalogueTypes}
								onChange={onSelect}
							/>
						</div>
						<div className='form-group m-t-0'>
							<label>Seleccione los Suplidores:</label>
							<input
								type='hidden'
								name='suplidores'
								value={selectedSuppliers.join(",")}
							/>
							<Select
								isMulti
								isClearable
								onChange={(values) =>{
									if (values) {
										setSelectedSuppliers(values.map((i) => i.value));
										setSelectedGroup([]);
									} else {
										setSelectedSuppliers([]);
									} 
								}}
								options={suplidores.map((suplidor) =>  ({ label: suplidor.nombre, value: suplidor.id }))}
								isDisabled={selectedGroup.length ? true : false}
							/>
						</div>
						<div className='form-group m-t-0'>
							<label>Seleccione las Categorias:</label>
							<input
								type='hidden'
								name='grupo'
								value={selectedGroup.join(",")}
							/>
							<Select
								isMulti
								isClearable
								onChange={(values) =>{
									if (values) {
										setSelectedGroup(values.map((i) => i.value));
										setSelectedSuppliers([]);
									} else {
										setSelectedGroup([]);
									} 
								}}
								options={grupos.map((grupo) =>  ({ label: grupo.nombre, value: grupo.id }))}
								isDisabled={selectedSuppliers.length ? true : false}
							/>
						</div>
						<div className='form-group m-t-0'>
							<label>Precio de venta:</label>
							{state.dropdownOptions.cantidadPrecios?.length > 0 && (
								<>
									<Select
										name="precio_venta"
										id="precio_venta"
										options={[
											{
												label: 'Sin precio',
												value: null
											},
											...state.dropdownOptions.cantidadPrecios,
										]}
										placeholder="Sin precio"
										aria-label="precioVentaInput"
									/>
								</>
							)}
						</div>
					</div>
					<div className='m-l-50 m-t-20'>
						<div className='form-group m-t-0'>
							<input 
								className="form-check-input" 
								type="checkbox" 
								checked={rncName} 
								onClick={() => setRncName(prev => !prev)}
								name="nameOrCode" 
								value="nameOrCOde"
								id="nameOrCOde" 
							/>
							<label className="form-check-label" >
								Codigo y Nombre:
							</label>
						</div>
						<div className='form-group m-t-0'>
							<input className="form-check-input" type="checkbox" name="productsExist" value="productsExist" id="productsExist" />
							<label className="form-check-label" >
								Productos con Existencia:
							</label>
						</div>
						<div className='form-group m-t-0'>
							<input className="form-check-input" type="checkbox" name="showCantOfProducts" value="showCantOfProducts" id="showCantOfProducts" />
							<label className="form-check-label">
								Existencia en Inventario:
							</label>
						</div>
					</div>
				</div>
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-light'>
                    Cancelar
                </button>
				<button
                    type='button'
                    onClick={(event) => {
						const formData = new FormData(event.target.parentElement.parentElement);
						const queryString = new URLSearchParams(formData).toString()
						window.open(
							`https://web.whatsapp.com/send?text=Ver catalogo ${window.location.origin + getLink('/imprimir/catalogo') + '?' + queryString}`
						)
					} }
                    className='btn btn-outline-success ml-2 mr-2'>
                    Enviar <i className="zmdi zmdi-whatsapp"></i>
                </button>
                <button type='submit' className='btn btn-primary'>
                    Imprimir
                </button>
            </div>
        </form>
	);
};

ProductCatalogueExportForm.propTypes = {
	toggle: PropTypes.func,
};

export default ProductCatalogueExportForm;
