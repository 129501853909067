import React from 'react';
import { formatCurrency } from '../../../../utils/helpers';

const Cocurriculares = ({servicios, seleccionCocurricular, setSeleccionCocurricular}) => {
    return (
        <>
            <style>{`
                ul {
                    list-style: none;
                    margin-bottom: 25px;
                }
                li button {
                    width: 100%;
                    text-align: left;
                    border-bottom: 1px solid #f2f2f2;
                    padding: 10px 0;
                }
                li table {
                    display: none;
                    width: 100%;
                }
                li.active table {
                    display: table;
                }
            `}</style>
            <fieldset className='p-l-20 p-r-20 p-b-20'>
                <legend className="uppercase">Cocurriculares</legend>
                <table className='w-100'>
                    <tbody>
                        {servicios.map(servicio => (
                            <tr key={servicio.id}>
                                <td><input
                                    type="checkbox"
                                    className='m-r-10'
                                    value={servicio.clase?.id}
                                    checked={seleccionCocurricular.includes(servicio.clase?.id)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSeleccionCocurricular(prev => [
                                                servicio.clase?.id
                                            ]);
                                        } else {
                                            setSeleccionCocurricular(prev => prev.filter(c => c !== servicio.clase?.id));
                                        }
                                    }} /></td>
                                <td>{servicio.clase?.nombre}</td>
                                <td>{servicio.pendiente > 0 ? 'Pendiente' : 'Al Día'}</td>
                                <td className='text-right'>{formatCurrency(servicio.pendiente)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </fieldset>
        </>
    );
}

export default Cocurriculares;
