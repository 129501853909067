import React, { useState } from "react";

import Form from "./Form";
import Loader from "../../general/Loader";
import { apiRequest } from "../../../hooks/apiRequest";

const Create = ({ toggleModal, getImpuestosData }) => {
	const [saving, setSaving] = useState(false);
	const [errors, setErrors] = useState({});

	const createImpuesto = (event) => {
		event.preventDefault();
		setSaving(true);
		const data = new FormData(event.target);

		apiRequest(data, "impuestos", "post").then((response) => {
			if (response.success) {
				getImpuestosData();
				toggleModal();
				setSaving(false);
			}
			if (response.code === 422) {
				setErrors({
					...response.data.message,
				});
				setSaving(false);
			}
		});
	};

	return (
		<form id="terminosForm" method="post" onSubmit={createImpuesto}>
			<input type="hidden" name="_method" value="post" />
			<div className="popup__header">Crear Término</div>

			<Form impuesto={{}} errors={errors} />

			<div className="popup__footer">
				<button
					type="button"
					className="btn btn-plain"
					onClick={toggleModal}
				>
					Cancelar
				</button>
				{saving ? (
					<Loader type="small" />
				) : (
					<button type="submit" className="btn btn-primary">
						Crear
					</button>
				)}
			</div>
		</form>
	);
};

export default Create;
