import React from "react";

const Form = ({ servidor, errors }) => {
    return (
        <div>
            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>Nombre:</label>
                </div>
                <div className='col col-md-7'>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='nombre'
                        name='nombre'
                        defaultValue={servidor.nombre}
                    />
                    {errors.nombre && (
                        <small className='help-blockParams form-Text'>
                            {errors.nombre[0]}
                        </small>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Form;
