import React, { Component } from "react";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { withRequest } from "../../utils/request";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../general/Loader";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import Pagination from "../general/Pagination";
import { getLink, toast } from "../../utils/helpers";
import CreateRegistro from "../Vehiculos/Servicios/Create";

class Servicios extends Component {
    state = {
        servicios: [],
        showCreate: false,
        showEdit: false,
        showRegistro: false,
        showGastoConfirmation: false,
        links: [],
        loading: true,
        servicio: {},
        filtros: "",
        filtrosQuery: "",
        vehiculoActivo: {},
        registroActivo: {},
    };
    componentDidMount() {
        this.getServicios();
    }
    getServicios = async (url = null) => {
        url = url ? url + "&limit=15" : "servicios?limit=15&estado=pendiente";
        const servicios = await this.props.request(url);

        if (servicios.data) {
            this.setState({
                servicios: servicios.data,
                loading: false,
                links: servicios.links,
                meta: servicios.meta,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    };
    toggleEditModal = (servicio) => {
        this.setState({
            showEdit: !this.state.showEdit,
            servicio,
        });
    };
    updateServicio = async (event, servicio) => {
        event.preventDefault();
        const token = auth.getToken();

        let data = new FormData();
        data.append("_method", "PATCH");
        data.append("estado", event.target.value);

        const res = await fetch(apiUrl(`servicios/${servicio}`), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.getServicios();
            toast("¡Servicio actualizado!");
        }
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getServicios("servicios?" + query.join("&"));
    }, 500);

    toggleRegistroModal = (vehiculo, registro = {}, showGasto = false) => {
        if (vehiculo) {
            this.setState({
                showRegistro: true,
                vehiculoActivo: vehiculo,
                registroActivo: registro,
            });
            return;
        }
        this.setState({
            showRegistro: false,
            registroActivo: registro,
            showGastoConfirmation: showGasto,
        });
    };

    toggleGastoConfirmation = () => {
        this.setState({
            showGastoConfirmation: !this.state.showGastoConfirmation,
        });
    };

    render() {
        const {
            loading,
            servicios,
            servicio,
            filtrosQuery,
            registroActivo,
        } = this.state;
        if (loading) {
            return <Loader />;
        }
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Todos los Servicios</span>
                        <Link
                            to={getLink(`/imprimir/servicios${filtrosQuery}`)}
                            target='_blank'
                            className='au-btn-filter m-r-15'>
                            <i className='zmdi zmdi-print' /> Imprimir
                        </Link>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Nuevo Servicio
                        </button>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label>Unidad</label>
                                    <select
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "unidad"
                                            )
                                        }
                                        className='form-control'
                                        name='estado'>
                                        <option value='todas'>Todas</option>
                                        <option value='TON'>Toneladas</option>
                                        <option value='DIAS'>Días</option>
                                    </select>
                                </div>
                                <div className='col-md-2'>
                                    <label>Estado</label>
                                    <select
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "estado"
                                            )
                                        }
                                        className='form-control'
                                        name='estado'>
                                        <option value='todos'>Todos</option>
                                        <option value='a-tiempo'>
                                            A tiempo
                                        </option>
                                        <option value='por-vencer'>
                                            Por vencer
                                        </option>
                                        <option value='vencidos'>
                                            Vencidos
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!servicios.length ? (
                    <div className='no-results'>
                        {!loading && <div>No hay servicios creados.</div>}
                    </div>
                ) : (
                    <div className='table-data'>
                        <table className='table table-data2'>
                            <thead>
                                <tr>
                                    <th>Ultimo Mant.</th>
                                    <th>Vehículo</th>
                                    <th>Nombre</th>
                                    <th>Intervalo</th>
                                    <th>Contador</th>
                                    <th>Creador</th>
                                </tr>
                            </thead>
                            <tbody>
                                {servicios.map((servicio) => {
                                    let color = "green";
                                    if (
                                        servicio.contador >=
                                        parseFloat(servicio.alerta)
                                    ) {
                                        color = "orange";
                                    }
                                    if (
                                        servicio.contador >
                                        parseFloat(servicio.intervalo)
                                    ) {
                                        color = "red";
                                    }
                                    return (
                                        <tr
                                            className='tr-shadow'
                                            key={servicio.id}>
                                            <td>
                                                {dayjs(servicio.fecha).format(
                                                    "DD/MM/YYYY"
                                                )}
                                            </td>
                                            <td>
                                                <Link
                                                    to={getLink(
                                                        `/vehiculos/${servicio.vehiculoId}/servicios`
                                                    )}>
                                                    <strong>
                                                        {servicio.vehiculo}
                                                    </strong>
                                                </Link>
                                            </td>
                                            <td>{servicio.nombre}</td>
                                            <td>
                                                {servicio.intervalo}{" "}
                                                {servicio.unidad}
                                            </td>
                                            <td>
                                                <strong
                                                    style={{ color: color }}>
                                                    {servicio.contador}{" "}
                                                    {servicio.unidad}
                                                </strong>
                                            </td>
                                            <td>{servicio.creador}</td>
                                            <td className='text-right'>
                                                <div className='table-data-feature'>
                                                    <button
                                                        onClick={() =>
                                                            this.toggleEditModal(
                                                                servicio
                                                            )
                                                        }
                                                        className='item'>
                                                        <i className='zmdi zmdi-edit' />{" "}
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            this.toggleRegistroModal(
                                                                {
                                                                    id:
                                                                        servicio.vehiculoId,
                                                                    ficha:
                                                                        servicio.vehiculo,
                                                                },
                                                                {
                                                                    vehiculoId:
                                                                        servicio.vehiculoId,
                                                                    vehiculo:
                                                                        servicio.vehiculo,
                                                                    servicioId:
                                                                        servicio.id,
                                                                    servicio:
                                                                        servicio.nombre,
                                                                }
                                                            )
                                                        }
                                                        className='au-btn au-btn-filter m-l-15'>
                                                        Registrar
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {this.state.links && (
                            <Pagination
                                links={this.state.links}
                                meta={this.state.meta}
                                getData={this.getServicios}
                            />
                        )}
                    </div>
                )}
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        empleado={{}}
                        getServicios={this.getServicios}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={this.toggleEditModal}>
                    <Edit
                        toggle={this.toggleEditModal}
                        empleado={{}}
                        servicio={servicio}
                        getServicios={this.getServicios}
                    />
                </Modal>
                <Modal
                    show={this.state.showRegistro}
                    blank={true}
                    toggle={this.toggleRegistroModal}>
                    <CreateRegistro
                        toggle={this.toggleRegistroModal}
                        vehiculo={this.state.vehiculoActivo}
                        getRegistros={this.getServicios}
                        registro={registroActivo}
                    />
                </Modal>
                <Modal
                    title='Registrar gastos...'
                    show={this.state.showGastoConfirmation}
                    toggle={this.toggleGastoConfirmation}
                    acceptText='Ir a gastos'
                    callback={() => {
                        window.location = getLink(
                            `/gastos/crear?registro=${registroActivo.id}&vehiculo=${registroActivo.vehiculoId}&mecanico=${registroActivo.mecanicoId}`
                        );
                    }}>
                    ¿Deseas registrar los gastos relacionados a este servicio
                    ahora?
                </Modal>
            </div>
        );
    }
}

export default withRequest(Servicios);
