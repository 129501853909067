/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../../../utils/request";
import Form from "./Components/FacturaForm";

const EditFacturaRecurrente = () => {
	const { id } = useParams();
	const [factura, setFactura] = useState({});

	const getFactura = () => {
		request("facturas-recurrentes/" + id).then((res) => {
			setFactura(res.data);
		});
	};

	useEffect(() => {
		getFactura();
	}, []);

	return (
        <Form
            facturaActual={factura}
            isEdit={true}
            fromCotizacion={false}
        />
	);
};

export default EditFacturaRecurrente;
