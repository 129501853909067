import React, { Component } from "react";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../general/Loader";
import { Link } from "react-router-dom";
import { getLink } from "../../utils/helpers";

class GrupoTipoEmpleados extends Component {
    state = {
        grupos: {},
        grupo: {},
        tipos: [],
        links: null,
        showCreate: false,
        showEdit: false,
        loading: true,
    };
    componentDidMount() {
        this.getGrupos();
        this.getTipos();
    }
    getGrupos = async (url = null) => {
        url = url ? url : "grupos-tipos-empleados";
        const grupos = await this.props.request(url);

        if (grupos.data) {
            this.setState({
                grupos: grupos.data,
                loading: false,
            });
        }
    };
    getTipos = async (url = null) => {
        url = url ? url : "tipos";
        const tipos = await this.props.request(url);

        if (tipos.data) {
            this.setState({
                tipos: tipos.data,
                loading: false,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            grupo: {},
        });
    };
    toggleEditModal = (grupo) => {
        this.setState({
            showEdit: !this.state.showEdit,
            grupo: grupo,
        });
    };
    render() {
        const { grupos, loading } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Grupos de Tipos de Empleado</span>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Grupo
                        </button>
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!grupos.length ? (
                                <div>
                                    {!loading && (
                                        <div>
                                            Todavía no hay grupos creados.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Tipos</th>
                                                <th>Estado</th>
                                                <th>Periodo</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {grupos.map((grupo) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={grupo.id}>
                                                    <td>{grupo.nombre}</td>
                                                    <td>{grupo.tipos.map(tipo => (<div>{tipo.nombre}</div>))}</td>
                                                    <td>
                                                        {grupo.estado === 'activo' ?
                                                            (<span className='badge badge-success'>
                                                                Activo
                                                            </span>)
                                                            :
                                                            (<span className='badge badge-danger'>
                                                                Inactivo
                                                            </span>)
                                                        }
                                                    </td>
                                                    <td>{grupo.periodo}</td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <button
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Edit'
                                                                onClick={() => {
                                                                    this.toggleEditModal(
                                                                        grupo
                                                                    );
                                                                }}>
                                                                <i className='zmdi zmdi-edit' />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getGrupos}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        grupo={this.state.grupo}
                        tipos={this.state.tipos}
                        getGrupos={this.getGrupos}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        grupo={this.state.grupo}
                        tipos={this.state.tipos}
                        getGrupos={this.getGrupos}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(GrupoTipoEmpleados);
