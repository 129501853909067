import React, { Component } from "react";
import { Route, NavLink, withRouter } from "react-router-dom";
import { withRequest } from "../../utils/request";
import Loader from "../general/Loader";
import Edit from "./Edit";
import Ajustes from "./Ajustes";
import Compras from "./Compras";
import Gastos from "./Gastos";
import GastosGenerales from "./GastosGenerales";
import { auth } from "../../utils/auth";
import { getLink } from "../../utils/helpers";
import EditFotos from "./EditFotos";
import { itemContext } from "./itemContext";

class Layout extends Component {
    state = {
        item: {},
        loading: true,
        almacenes: [],
        grupos: [],
    };

    componentDidMount() {
        this.getItem();
        this.getTable("grupos");
        this.getTable("almacenes");
    }

    getItem = async (url = null) => {
        const id = this.props.match.params.id;
        url = url ? url : "items/" + id;
        const item = await this.props.request(url);

        if (item.success) {
            this.setState({
                item: item.data,
                loading: false,
            });
        }
    };
    getTable = async (url = null) => {
        const table = await this.props.request(url);

        if (table.data) {
            this.setState({
                [url]: table.data,
                loading: false,
            });
        }
    };
    render() {
        const { loading, item, almacenes, grupos } = this.state;

        if (loading) {
            return <Loader />;
        }

        return (
            <div>
                <div className='page-header'>
                    <h1 className='page-title'>{item.nombre}</h1>
                </div>
                <div className='row'>
                    <div className='col-md-3 page-sidebar' style={{maxWidth: '250px'}}>
                        <ul className='list-unstyled page-sidebar__menu'>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/inventario/${item.id}/informacion`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Información General
                                </NavLink>
                            </li>
                            <li>
                                {item.fotos && (
                                            <NavLink
                                                to={getLink(
                                                    `/inventario/${item.id}/fotos`
                                                )}
                                                exact
                                                className='nav-link'>
                                                Fotos
                                            </NavLink>
                                        )
                                    }
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/inventario/${item.id}/compras`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Compras
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/inventario/${item.id}/gastos`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Gastos
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/inventario/${item.id}/ajustes`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Ajustes
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className='col p-l-45'>
                        <Route
                            path='/:sucursal/inventario/:id/informacion'
                            render={(props) => (
                                <itemContext.Provider value={item}>
                                    <Edit
                                        {...props}
                                        almacenes={almacenes}
                                        grupos={grupos}
                                    />
                                </itemContext.Provider>
                            )}
                        />
                        <Route
                            path='/:sucursal/inventario/:id/fotos'
                            render={(props) => (
                                <EditFotos
                                    {...props}
                                    item={item}
                                />
                            )}
                        />
                        <Route
                            path='/:sucursal/inventario/:id/ajustes'
                            render={(props) => (
                                <Ajustes
                                    {...props}
                                    item={item}
                                    getItem={this.getItem}
                                />
                            )}
                        />
                        <Route
                            path='/:sucursal/inventario/:id/compras'
                            render={(props) => (
                                <Compras {...props} item={item} />
                            )}
                        />
                        <Route
                            path='/:sucursal/inventario/:id/gastos'
                            render={(props) => (
                                <Gastos {...props} item={item} />
                            )}
                        />
                        <Route
                            path='/:sucursal/inventario/:id/gastos-generales'
                            render={(props) => (
                                <GastosGenerales {...props} item={item} />
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(Layout));
