import React from "react";
import { Link } from "react-router-dom";

const PageHeader = ({
	title,
	titleDescription,
	hasButton = true,
	classes = {},
	children,
	buttonType = "button",
	linkUrl,
	handleButtonClick,
	buttonText,
	goBackText,
	goBackLink,
}) => {
	return (
		<div className="page-header">
			{goBackText && <Link to={goBackLink}>&laquo; {goBackText}</Link>}
			<h1 className={`page-title row ${classes.h1}`}>
				<span className={`${titleDescription ? '' : 'col'} ${classes.title}`}>{title}</span>
				{titleDescription && (
					<small className="col page-title__description">
						{titleDescription}
					</small>
				)}
				{!children ? (
					<>
						{hasButton &&
							(buttonType === "link" ? (
								<Link
									className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15"
									to={linkUrl}
								>
									<i className="zmdi zmdi-plus" />
									{buttonText}
								</Link>
							) : (
								<button
									className="au-btn au-btn-icon au-btn--green au-btn--small"
									onClick={handleButtonClick}
								>
									<i className="zmdi zmdi-plus" />
									{buttonText}
								</button>
							))}
					</>
				) : (
					children
				)}
			</h1>
		</div>
	);
};

export default PageHeader;
