import React, { Component, useEffect, useState } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { getLink, toast } from "../../../utils/helpers";
import Loader from "../../general/Loader";
import { useHistory } from "react-router-dom";
import Modal from "../../general/Modal";

const Edit = ({evaluacion, getEvaluaciones, toggle, grados, periodosAcademicos}) => {
    const [estado, setEstado] = useState(evaluacion.estado || '');
    const [pago, setPago] = useState(evaluacion.pago || '');
    const [inscribir, setInscribir] = useState(false);
    const [crearEstudiante, setCrearEstudiante] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showPendientePagoAlert, setShowPendientePagoAlert] = useState(false);
    const [errors, setErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const history = useHistory();

    const editEvaluacion = async (event, inscribir = false) => {
        event.preventDefault();

        setSaving(true);

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl(`academico/evaluaciones/${evaluacion.id}`), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success && response.data.estudiante_id) {
            window.location.href = getLink('/academico/estudiantes/'+response.data.estudiante_id+'/editar');
        }

        if (response.success) {
            if (inscribir) {
                return response.data;
            } else {
                getEvaluaciones();
                toggle();
                toast("Evaluacion actualizada.");
            }
        }

        if (response.code === 422) {
            setErrors(response.data.message);
        }

        setSaving(false);
    };
    const deleteEvaluacion = async (evaluacionId) => {
        setSaving(true);

        const token = auth.getToken();

        const url = `academico/evaluaciones/${evaluacionId}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            getEvaluaciones();
            toggle();
            toast("Evaluacion borrada.");
            return;
        } else {
            setErrors(response.data.message);
            toast(response.data, "error");
        }
        setSaving(false);
    };
    const checkPago = (e) => {

        if (([estado].includes('Aprobado') && ([pago].includes('Devuelto')) || [pago].includes('Pendiente')) && !showPendientePagoAlert) {
            setShowPendientePagoAlert(true);
            return false;
        }

        editEvaluacion({target: e.target.form, preventDefault: () => {}}, true).then(evaluacion => {
            if (evaluacion.estudiante?.id) {
                history.push(getLink(`/academico/estudiantes/${evaluacion.estudiante.id}/informacion?inscripcion=1&evaluacion_id=${evaluacion.id}`));
            } else {
                history.push(getLink(`/academico/estudiantes/crear?evaluacion_id=${evaluacion.id}&inscripcion=1`));
            }
        });

    }

    useEffect(() => {
        setPago(evaluacion.pago);
        setEstado(evaluacion.estado);
    }, [evaluacion]);

    useEffect(() => {
        setShowPendientePagoAlert(false);
    }, [estado, pago]);

    const toggleDeleteModal = () => {
        setShowDelete(prev => !prev);
    }

    return (
        <>
            <form method='post' onSubmit={editEvaluacion}>
                <input type='hidden' name='_method' value='patch' />
                {inscribir && (
                    <input type='hidden' name='inscribir' value='si' />
                )}
                {crearEstudiante && (
                    <input type='hidden' name='crear_estudiante' value='si' />
                )}
                <div className='popup__header'>Editar Evaluación</div>
                <div className='popup__body'>
                    <Form
                        grados={grados}
                        periodosAcademicos={periodosAcademicos}
                        evaluacion={evaluacion}
                        setEstado={setEstado}
                        setPago={setPago}
                        estado={estado}
                        showPendientePagoAlert={showPendientePagoAlert}
                        errors={errors}
                    />
                </div>
                <div className='popup__footer'>
                    {!saving && (
                        <button
                            type='button'
                            className='btn float-left'
                            onClick={() => setShowDelete(true)}>
                            <i className='fas fa-trash-alt'></i>
                        </button>
                    )}
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving && <Loader type="small"/>}
                    {!saving && (
                        <button type='submit' className='btn btn-primary'>
                            Guardar
                        </button>
                    )}
                    {estado === 'Aprobado' && (
                        <button type="button" className='btn btn-secondary m-l-15' onClick={checkPago}>
                            Inscribir
                        </button>
                    )}
                </div>
            </form>
            <Modal
                title='Borrar Evaluación'
                show={showDelete}
                callback={() => deleteEvaluacion(evaluacion.id)}
                acceptText="Borrar"
                toggle={toggleDeleteModal}>
                ¿Seguro que deseas borrar la evaluación de {evaluacion.nombre} {evaluacion.primerApellido} {evaluacion.segundoApellido}?
            </Modal>
        </>
    );
}

export default Edit;
