import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";

class PrintGastos extends Component {
    state = {
        gastos: [],
    };
    componentDidMount() {
        this.getGastos();
    }
    getGastos = async () => {
        const response = await this.props.request(
            "gastos-internos" + window.location.search
        );

        if (response.data) {
            this.setState({
                gastos: response.data,
            });
            window.print();
        }
    };
    render() {
        const { gastos } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const gastoTotal = gastos.reduce((acc, gasto) => {
            return acc + parseFloat(gasto.total);
        }, 0);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>Gastos</h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL GASTOS: {gastos.length}
                        </div>

                        <div className='float-right'>
                            <h3>TOTAL GASTOS: {formatCurrency(gastoTotal)}</h3>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Número</th>
                            <th>Detalle</th>
                            <th>Relacionados</th>
                            <th>Categoría</th>
                            <th className='text-right'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gastos.map((gasto) => {
                            return (
                                <tr key={gasto.id}>
                                    <td>
                                        {dayjs(gasto.fecha).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </td>
                                    <td>
                                        {gasto.numeracion}
                                    </td>
                                    <td>
                                        {gasto.items.length && (
                                            <div>{gasto.items[0].descripcion}</div>
                                        )}
                                        {gasto.suplidor && (
                                            <div>
                                                ({gasto.suplidor?.nombre})
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        <div>{gasto.vehiculo && `Vehículo: ${gasto.vehiculo.ficha}`}</div>
                                        <div>{gasto.empleado && `Empleado: ${gasto.empleado.nombreCompleto}`}</div>
                                        <div>{gasto.ruta && `Ruta: ${gasto.ruta.nombre}`}</div>
                                    </td>
                                    <td>{gasto.categorias.map(c => c.nombre).join(', ')}</td>
                                    <td className='text-right'>
                                        {formatCurrency(
                                            parseFloat(gasto.total)
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='text-right'>
                                <strong>TOTAL:</strong>
                            </td>
                            <td className='text-right'>
                                <strong>{formatCurrency(gastoTotal)}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintGastos));
