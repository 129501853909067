import React, { Component } from "react";

import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";

import { toast } from "../../../utils/helpers";

class Categorias extends Component {
    state = {
        categorias: []
    };
    componentDidMount() {
        this.setState({
            categorias: this.props.categorias
        });
    }
    addCategoria = () => {
        this.setState({
            categorias: [...this.state.categorias, { id: new Date() }]
        });
    };
    removeCategoria = index => {
        this.setState({
            categorias: this.state.categorias.filter((op, i) => i !== index)
        });
    };
    updateCategorias = async event => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const url = "categorias-asignaciones";

        const res = await fetch(apiUrl(url), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.props.toggle();
            this.props.getCategorias();
            toast("Categorias actualizadas.");
        }
    };
    render = () => {
        const { toggle } = this.props;
        const { categorias } = this.state;
        return (
            <div>
                <form onSubmit={e => this.updateCategorias(e)}>
                    <div className='popup__header'>Categorías Asignaciones</div>
                    <div className='popup__body'>
                        <FormCategorias
                            categorias={categorias}
                            addCategoria={this.addCategoria}
                            removeCategoria={this.removeCategoria}
                        />
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            onClick={toggle}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        <button type='submit' className='btn btn-primary'>
                            Guardar
                        </button>
                    </div>
                </form>
            </div>
        );
    };
}

export default Categorias;

const FormCategorias = ({ categorias, addCategoria, removeCategoria }) => {
    return (
        <div>
            <div className='row form-group'>
                <table className='table'>
                    <tbody>
                        {categorias.map((categoria, index) => {
                            return (
                                <tr key={categoria.id}>
                                    <td>
                                        <input
                                            type='hidden'
                                            name={`categorias[${categoria.id}][id]`}
                                            value={categoria.id}
                                        />
                                        <input
                                            type='text'
                                            className='text-input form-control'
                                            id='nombre'
                                            name={`categorias[${categoria.id}][nombre]`}
                                            defaultValue={categoria.nombre}
                                            placeholder='Nombre'
                                        />
                                    </td>
                                    <td>
                                        {!categoria.vehiculos && (
                                            <div className='table-data-feature'>
                                                <button
                                                    className='item'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title=''
                                                    type='button'
                                                    data-original-title='Borrar'
                                                    onClick={() =>
                                                        removeCategoria(index)
                                                    }>
                                                    <i className='zmdi zmdi-delete' />
                                                </button>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td colSpan='2' className='text-right'>
                                <button
                                    type='button'
                                    className='btn'
                                    onClick={addCategoria}>
                                    Añadir Categoría{" "}
                                    <i className='fas fa-plus'></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
