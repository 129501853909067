import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";

class Edit extends Component {
    state = {
        errors: {},
    };
    editUser = async (event) => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(
            apiUrl(`categorias/${this.props.categoria.id}`),
            {
                method: "post",
                body: data,
                headers: {
                    Accept: "application/json",
                    "X-Request-With": "XMLHttpRequest",
                    Authorization: "Bearer " + token,
                },
            }
        );

        const response = await res.json();

        if (response.success) {
            this.props.getCategorias();
            this.props.toggle();
            toast("Categoría actualizada.");
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    delete = async (event, categoria) => {
        event.preventDefault();

        const token = auth.getToken();

        const url = `categorias/${categoria}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.getCategorias();
            this.props.toggle({});
            toast("Categoría borrada.");
            return;
        } else {
            toast(response.data, "error");
        }
    };
    render() {
        const { errors } = this.state;
        const { toggle, categoria } = this.props;
        return (
            <form method='post' onSubmit={this.editUser}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>Editar Categoría</div>
                <div className='popup__body'>
                    <Form categoria={categoria} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={(e) => this.delete(e, categoria.id)}>
                        <i className='fas fa-trash-alt'></i>
                    </button>
                    <button
                        type='button'
                        onClick={() => toggle({})}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default Edit;
