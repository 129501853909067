import React from "react";
import NotaCreditoForm from "./components/NotaCreditoForm";

const CreateNotaCredito = () => {

    return (
        <NotaCreditoForm />
    );
};

export default CreateNotaCredito;