import React from 'react';
import Select from 'react-select';

export default function FormSecciones({item, grados, errors}) {
    return (<>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Nombre:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='text'
                    className='text-input form-control'
                    id='nombre'
                    name='nombre'
                    defaultValue={item.nombre}
                />
                {errors.nombre && (
                    <small className='help-blockParams form-Text'>
                        {errors.nombre[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Grado:
                </label>
            </div>
            <div className='col col-md-7'>
                <Select
                    key="grado"
                    name='grado_id'
                    isClearable
                    required
                    options={grados && grados.map(g => ({
                        label: g.nombre,
                        value: g.id,
                    }))}
                    defaultValue={item.grado && {
                        label: item.grado?.nombre,
                        value: item.grado?.id
                    }}
                />
                {errors.grado_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.grado_id[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Número Estudiantes:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='number'
                    step='1'
                    className='text-input form-control'
                    id='numero_estudiantes'
                    name='numero_estudiantes'
                    defaultValue={item.numeroEstudiantes}
                />
                {errors.numero_estudiantes && (
                    <small className='help-blockParams form-Text'>
                        {errors.numero_estudiantes[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Estado:
                </label>
            </div>
            <div className='col col-md-7'>
                <select className='form-control' name="estado" defaultValue={item.estado}>
                    <option value="activo">Activo</option>
                    <option value="pendiente">Pendiente</option>
                    <option value="inactivo">Inactivo</option>
                    <option value="cerrado">Cerrado</option>
                </select>
                {errors.estado && (
                    <small className='help-blockParams form-Text'>
                        {errors.estado[0]}
                    </small>
                )}
            </div>
        </div>
    </>)
}
