import React, { useRef } from "react";
import { useHistory } from "react-router-dom";

import { getLink, toast } from "../../../utils/helpers";
import Form from "./Form";
import Modal from "../../general/Modal";
import CreateModal from "../Tutores/CreateModal";
import EditModal from "../Tutores/EditModal";
import { EstudianteContextProvider, useEstudianteContext } from "./EstudianteContext";
import { apiRequest } from "../../../hooks/apiRequest";

const Create = () => {
    return (
        <EstudianteContextProvider>
            <CreateEstudiante />
        </EstudianteContextProvider>
    )
}

const CreateEstudiante = () => {
    const formRef = useRef();

    const {setErrors, showCreateTutorModal, toggleModal, showEditTutorModal, editando, setSaving, evaluacionId  } = useEstudianteContext();

    const history = useHistory();
    const params = new URLSearchParams(window.location.search);

    const createEstudiante = async (event) => {
        event.preventDefault();

        setSaving(true);

        const data = new FormData(formRef.current);

        apiRequest(data, "academico/estudiantes", 'post').then(res => {
            if (res.success) {
                if (res.data?.recibo) {
                    window.open(getLink(`/imprimir/academico/recibos/${res.data?.recibo.id}/inscripcion`));
                    history.push(getLink("/academico/evaluaciones"));
                } else {
                    history.push(getLink("/academico/estudiantes"));
                }
            }
            if (res.code === 422) {
                setErrors(res.data.message);
                toast('Favor revisar todos los campos.', 'error');
            }
            setSaving(false);
        });

    };

    return (
        <>
            <div className='page-header'>
                <h1 className='page-title'>
                    {evaluacionId ? 'Nueva Inscripción' : 'Crear Estudiante'}
                </h1>
            </div>
            <form
                method='post'
                onSubmit={createEstudiante}
                ref={formRef}
                encType='multipart/form-data'>
                {params.get('evaluacion_id') && (
                    <input type="hidden" name="evaluacion_id" value={params.get('evaluacion_id')} />
                )}
                <Form />
            </form>

            <Modal
                blank
                show={showCreateTutorModal}
                toggle={() => toggleModal('CreateTutor')}
                size="lg"
            >
                <CreateModal toggleModal={toggleModal} />
            </Modal>

            {showEditTutorModal && (
                <Modal
                    blank
                    show={showEditTutorModal}
                    toggle={() => toggleModal('EditTutor')}
                    size="lg"
                >
                    <EditModal toggleModal={toggleModal} id={editando} />
                </Modal>
            )}
        </>
    );
}

export default Create;
