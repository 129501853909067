import React from 'react';
import { formatCurrency, getLink } from '../../../../utils/helpers';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Cocurriculares = ({estudiante, cocurriculares}) => {
    return (
        <>
            <style>{`
                ul {
                    list-style: none;
                    margin-bottom: 25px;
                }
                li button {
                    width: 100%;
                    text-align: left;
                    border-bottom: 1px solid #f2f2f2;
                    padding: 10px 0;
                }
                li table {
                    display: none;
                    width: 100%;
                }
                li.active table {
                    display: table;
                }
            `}</style>
            <fieldset>
                <legend className="uppercase">Cocurriculares</legend>
                <ul className='p-r-30 p-l-30'>
                    <li className='active'>
                         <table>
                            <tbody>
                                {cocurriculares.map(cocurricular => (
                                    <tr key={cocurricular.id}>
                                        <td>
                                            <Link to={getLink(`/academico/cocurriculares?contacto=${estudiante.contacto_id}&cocurricular=${cocurricular.clase?.id}`)} target="_blank">
                                                {cocurricular.clase?.nombre}
                                            </Link>
                                        </td>
                                        <td className='text-center'>{cocurricular.pendiente > 0 ? 'Pendiente' : 'Al día'}</td>
                                        <td className='text-right'>{formatCurrency(cocurricular.pendiente)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </li>
                    {/* {Object.keys(pagos).map(servicio => (
                        <li key={servicio}>
                            <button type='button' onClick={(e) => {
                                e.target.closest('li').classList.toggle('active');
                            }}>{servicio} <i className="fa fa-chevron-down float-right"></i></button>
                            <table>
                                <tbody>
                                    {pagos[servicio].map(pago => (
                                        <tr key={pago.id}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    className='m-r-10'
                                                    value={pago.id}
                                                    onChange={(e) => {
                                                        updateSeleccion(parseInt(e.target.value));
                                                    }} /> {pago.nombre}
                                            </td>
                                            <td className='text-center'>{pago.estado}</td>
                                            <td className='text-right'>{formatCurrency(pago.monto)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </li>
                    ))} */}
                </ul>
            </fieldset>
        </>
    );
}

export default Cocurriculares;
