import dayjs from "dayjs";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { auth } from "../../../utils/auth";
import { withRequest } from "../../../utils/request";
import { formatCurrency } from "../../../utils/helpers";


class PrintCheques extends Component {
    state = {
        cheques: [],
    };
    componentDidMount() {
        this.getCheques();
    }
    getCheques = async () => {
        const response = await this.props.request(
            "cheques" + window.location.search
        );

        if (response.data) {
            this.setState({
                cheques: response.data,
            });
            window.print();
        }
    };
    render() {
        const { cheques } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const total = cheques.reduce((a,c) => c.monto + a, 0);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Cheques
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL CHEQUES: {cheques.length}
                        </div>

                        <div className='float-right'>
                            <h3>TOTAL: {formatCurrency(total)}</h3>
                        </div>
                    </div>
                </div>
                <table className='table m-b-40'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Fecha</th>
                            <th>Número</th>
                            <th>Concepto</th>
                            <th>Monto</th>
                            <th>Cuenta</th>
                            <th>Contacto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cheques.map((cheque, index) => {
                            return (
                                <tr key={cheque.id}>
                                    <td>{index+1}</td>
                                    <td>{dayjs(cheque.fecha).format('DD-MM-YYYY')}</td>
                                    <td>{cheque.numero}</td>
                                    <td>{cheque.concepto}</td>
                                    <td>{formatCurrency(cheque.monto)}</td>
                                    <td>{cheque.cuenta?.nombre}</td>
                                    <td>{cheque.contacto?.nombre}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='row'>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL CHEQUES: {cheques.length}
                        </div>

                        <div className='float-right'>
                            <h3>TOTAL: {formatCurrency(total)}</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintCheques));
