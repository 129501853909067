import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { auth } from "../../../../utils/auth";
import {
	formatCurrency,
} from "../../../../utils/helpers";
import { request } from "../../../../utils/request";

const PrintOrdenes = () => {
	const [ordenes, setOrdenes] = useState([]);

	const getOrdenes = () => {
		request("ordenes-compra" + window.location.search + "&paginate=100000").then((res) => {
			setOrdenes(res.data);
            window.print();
		});
	};

	const formatDateTime = (date) => {
        return dayjs(date).format('DD/MM/YYYY, hh:mm a');
	};

	useEffect(() => {
		getOrdenes();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotal = ordenes.reduce((acc, orden) => {
		return acc + parseFloat(orden.total);
	}, 0);

	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Ordenes de compra
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{new Date().toLocaleString()}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL ORDENES DE COMPRA: {ordenes.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
			<table className="table m-b-40">
				<thead>
					<tr>
						<th>NCF/Numero</th>
						<th>Tipo</th>
						<th>Cliente</th>
						<th>Vendedor</th>
						<th>Creación</th>
						<th className="text-right">Vencimiento</th>
						<th className="text-right">Estado</th>
						<th className="text-right">Total</th>
					</tr>
				</thead>
				<tbody>
					{ordenes.map((orden) => {
						return (
							<tr key={orden.id}>
								<td>{orden.numero_comprobante}</td>
								<td className="text-capitalize">
									{orden.tipo}
								</td>
								<td>{orden.cliente ? orden.cliente.nombre : ""}</td>
								<td>{orden.vendedor ? `${orden.vendedor.nombre} ${orden.vendedor.apellido}` : ""}</td>
								<td>{formatDateTime(orden.created_at)}</td>
								<td className="text-right">
									{new Intl.DateTimeFormat("es-ES").format(
										new Date(orden.fecha_vencimiento)
									)}
								</td>
								<td className="text-capitalize text-right">
									{orden.estado}
								</td>
								<td className="text-right">
									{formatCurrency(orden.total)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL ORDENES DE COMPRA: {ordenes.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrintOrdenes;
