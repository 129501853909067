import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import { getLink, toast } from "../../../utils/helpers";
import { withRouter } from "react-router-dom";

class Create extends Component {
    state = {
        saving: false,
        errors: {},
        estado: 'Pendiente',
        pago: 'Pendiente',
        duplicado: null,
        showPendientePagoAlert: false,
    };
    createEvaluacion = async (event, inscribir = false) => {
        event.preventDefault();

        this.setState({saving: true, duplicado: null});

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl("academico/evaluaciones"), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.setState({saving: false});
            if (inscribir) {
                return response.data;
            } else {
                this.props.getEvaluaciones();
                this.props.toggle();
            }
        }
        if (response.code === 422 && response.data.evaluacion) {
            toast(response.data.mensaje, 'error');
            this.setState({
                saving: false,
                duplicado: response.data.evaluacion
            });
            return;
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message, saving: false });
        }
    };
    checkPago = (e) => {
        const {estado, pago} = this.state;

        if (([estado].includes('Aprobado') && ([pago].includes('Devuelto')) || [pago].includes('Pendiente')) && !this.state.showPendientePagoAlert) {
            this.setState({
                showPendientePagoAlert: true
            });
            return false;
        }

        this.createEvaluacion({target: e.target.form, preventDefault: () => {}}, true).then(evaluacion => {
            this.props.history.push(getLink(`/academico/estudiantes/crear?evaluacion_id=${evaluacion.id}`));
        });
    }
    setEstado = (e) => {
        this.setState({
            estado: e,
            showPendientePagoAlert: false,
        });
    }
    setPago = (e) => {
        this.setState({
            pago: e,
            showPendientePagoAlert: false,
        });
    }
    render() {
        const { errors, saving, estado, pago, duplicado, showPendientePagoAlert } = this.state;
        const { toggle, evaluacion } = this.props;
        return (
            <form method='post' onSubmit={this.createEvaluacion}>
                <div className='popup__header'>Nueva Evaluación</div>
                <div className='popup__body'>
                    <Form
                        grados={this.props.grados}
                        periodosAcademicos={this.props.periodosAcademicos}
                        evaluacion={evaluacion}
                        estado={estado}
                        pago={pago}
                        setEstado={this.setEstado}
                        setPago={this.setPago}
                        errors={errors}
                        showPendientePagoAlert={showPendientePagoAlert}
                        duplicado={duplicado}
                    />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving && <Loader type="small"/>}
                    {!saving && (
                        <button type='submit' className='btn btn-primary'>
                            Guardar
                        </button>
                    )}
                    {([estado].includes('Aprobado')) && (
                        <button type="button" className='btn btn-secondary m-l-15' onClick={this.checkPago}>
                            Guardar e Inscribir
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default withRouter(Create);
