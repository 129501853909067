import React, { Component } from "react";
import Select from "react-select";

import { formatCurrency, toast } from "../../utils/helpers";

export default class GastoItem extends Component {
    state = {
        cantidad: 1,
        costo: "",
        costoMin: 0.01,
        subtotal: 0,
        disponible: 0,
        valid: true,
        isItem: false,
    };
    onChangeInput = async (name, value) => {
        await this.setState({
            [name]: value,
        });

        const { cantidad, costo, disponible } = this.state;
        const { gasto } = this.props;

        if (this.state.isItem) {
            if (parseFloat(cantidad) > disponible) {
                this.setState({ valid: false });
                toast("Cantidad disponible: " + disponible, "error");
                return;
            } else if (parseFloat(cantidad) < 0.01 || cantidad === "") {
                toast("Cantidad no puede ser 0", "error");
                this.setState({ valid: false });
            } else {
                this.setState({ valid: true });
            }
        }

        this.props.updateTotal(gasto.id, cantidad * costo);
    };
    onChangeItem = async (itemId) => {
        let item = this.props.items.filter((item) => item.id === itemId)[0];
        item = item ? item : {};

        await this.setState({
            id: item.id ? item.id : null,
            nombre: item.nombre ? item.nombre : null,
            costo: item.costo ? item.costo : null,
            costoMin: item.min ? item.min : item.costo,
            disponible: item.cantidad ? item.cantidad : 0,
            valid: item.cantidad > 0,
        });

        const { gasto } = this.props;
        this.props.updateTotal(gasto.id, parseFloat(item.costo));
    };
    toggleItem = () => {
        this.setState({
            isItem: !this.state.isItem,
        });
    };
    render() {
        const { index, items, fecha, gasto, removeGasto } = this.props;
        const {
            cantidad,
            costo,
            costoMin,
            disponible,
            valid,
            isItem,
        } = this.state;

        return (
            <tr
                style={{
                    backgroundColor: !valid
                        ? "rgba(255,0,0,0.07)"
                        : "transparent",
                }}>
                <td className='text-center'>
                    <input
                        type='checkbox'
                        onChange={this.toggleItem}
                        checked={isItem}
                    />
                </td>
                <td>
                    <input
                        type='hidden'
                        name={`gastos[${index}][fecha]`}
                        value={fecha}
                    />
                    <input
                        type='hidden'
                        name={`gastos[${index}][forma_pago]`}
                        value='contado'
                    />
                    <input
                        type='hidden'
                        name={`gastos[${index}][cuento_id]`}
                        value='1'
                    />
                    {isItem ? (
                        <div>
                            <Select
                                options={items.map((item) => ({
                                    label: `${item.referencia} - ${item.nombre}`,
                                    value: item.id,
                                }))}
                                isLoading={items[0] === false}
                                loadingMessage={() => "Cargando items..."}
                                required
                                onChange={(e) => {
                                    if (!e) e = {};
                                    this.onChangeItem(e.value);
                                }}
                                name={`gastos[${index}][item_id]`}
                                isClearable={true}
                            />
                            <div>
                                <small>NOTA:</small>
                            </div>
                            <input
                                type='text'
                                className='form-control'
                                name={`gastos[${index}][descripcion]`}
                                id='descripcion'
                            />
                        </div>
                    ) : (
                        <input
                            type='text'
                            className='form-control'
                            required
                            name={`gastos[${index}][descripcion]`}
                            id='descripcion'
                        />
                    )}
                </td>
                <td className='text-center'>{isItem ? disponible : "N/A"}</td>
                <td>
                    {isItem ? (
                        <input
                            type='number'
                            name={`gastos[${index}][cantidad]`}
                            value={cantidad}
                            min='0.01'
                            disabled={disponible < 1}
                            max={disponible}
                            onChange={(e) =>
                                this.onChangeInput("cantidad", e.target.value)
                            }
                            id=''
                            className='form-control text-right'
                            step='0.01'
                        />
                    ) : (
                        <input
                            type='number'
                            name={`gastos[${index}][cantidad]`}
                            value={cantidad}
                            min='0.01'
                            onChange={(e) =>
                                this.onChangeInput("cantidad", e.target.value)
                            }
                            className='form-control text-right'
                            step='0.01'
                        />
                    )}
                </td>
                <td className='text-center'>
                    <input
                        type='number'
                        name={`gastos[${index}][precio_unidad]`}
                        value={costo}
                        min={costoMin}
                        onChange={(e) =>
                            this.onChangeInput("costo", e.target.value)
                        }
                        className='form-control text-right'
                        step='0.01'
                    />
                </td>
                <td className='text-right'>
                    {formatCurrency(costo * cantidad)}
                </td>
                <td>
                    <button
                        type='button'
                        className='btn'
                        onClick={() => removeGasto(gasto.id)}>
                        <i className='fas fa-trash'></i>
                    </button>
                </td>
            </tr>
        );
    }
}
