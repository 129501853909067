import React, { Component } from "react";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../general/Loader";
import { formatCurrency, getLink, toast } from "../../utils/helpers";
import { debounce } from "lodash";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import Transaccion from "./Transaccion";
import { Link } from "react-router-dom";

class Cuentas extends Component {
    state = {
        cuentas: {},
        cuenta: {},
        links: null,
        showCreate: false,
        showEdit: false,
        showTransaccion: false,
        loading: true,
        showBorrarCuenta: false,
        filtros: [],
        filtrosQuery: '',
    };
    componentDidMount() {
        this.getCuentas();
    }
    getCuentas = async (url = null) => {
        url = url ? url : "cuentas";
        const cuentas = await this.props.request(url);

        if (cuentas.data) {
            this.setState({
                cuentas: cuentas.data,
                loading: false,
            });
        }
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getCuentas("cuentas?limit=20&" + query.join("&"));
    }, 500);
    borrarCuenta = async () => {
        const token = auth.getToken();
        const {cuenta} = this.state;

        const url = `cuentas/${cuenta.id}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.getCuentas();
            this.setState({
                showEdit: false,
                cuenta: {}
            });
            toast("Cuenta borrada.");
            return;
        } else {
            toast(response.data, "error");
        }
    }
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            cuenta: {},
        });
    };
    toggleEditModal = (cuenta) => {
        this.setState({
            showEdit: !this.state.showEdit,
            cuenta: cuenta,
        });
    };
    toggleBorrarCuenta = () => {
        this.setState({
            showBorrarCuenta: !this.state.showBorrarCuenta,
        });
    };
    toggleTransaccionModal = (cuenta) => {
        this.setState({
            showTransaccion: !this.state.showTransaccion,
            cuenta: cuenta ? cuenta : {},
        });
    };
    render() {
        const { cuentas, loading, filtrosQuery } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Bancos</span>
                        <Link
                            to={getLink(
                                `/imprimir/cuentas${filtrosQuery}`
                            )}
                            target='_blank'
                            className='au-btn-filter m-r-15'>
                            <i className='zmdi zmdi-print' /> Imprimir
                        </Link>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Cuenta
                        </button>
                    </h1>
                </div>
                <div className='page-controls'>
                    <div className='table-data__tool'>
                        <div className='table-data__tool-left row'>
                            <div className='col'>
                                <label>Buscar</label>
                                <input
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "buscar"
                                        )
                                    }
                                    type='search'
                                    placeholder='Buscar...'
                                    className='form-control'
                                    name='buscar'
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='estado'>Tipo</label>
                                <select
                                    className='form-control'
                                    name='tipo'
                                    id='tipo'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "tipo"
                                        )
                                    }>
                                    <option value='todos'>Todos</option>
                                    <option value='LOCAL'>Local</option>
                                    <option value='BANCARIA'>Bancaria</option>
                                </select>
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='moneda'>Moneda</label>
                                <select
                                    className='form-control'
                                    name='moneda'
                                    id='moneda'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "moneda"
                                        )
                                    }>
                                    <option value='todos'>Todos</option>
                                    <option value='DOP'>Pesos</option>
                                    <option value='USD'>Dólares</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!cuentas.length ? (
                                <div>
                                    {!loading && (
                                        <div>
                                            Todavía no hay cuentas creados.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Moneda</th>
                                                <th>Nombre</th>
                                                <th>Tipo de Cuenta</th>
                                                <th># Cuenta</th>
                                                <th>Banco</th>
                                                <th>Balance</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cuentas.map((cuenta) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={cuenta.id}>
                                                    <td>{cuenta.moneda === 'DOP' ? 'Pesos' : 'Dólares'}</td>
                                                    <td>
                                                        <Link to={getLink(`/cuentas/${cuenta.id}/ver`)}>
                                                            {cuenta.nombre}
                                                        </Link>
                                                    </td>
                                                    <td>{cuenta.tipo}</td>
                                                    <td>{cuenta.numeroCuenta}</td>
                                                    <td>{cuenta.banco}</td>
                                                    <td>{formatCurrency(cuenta.balance, cuenta.moneda)}</td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <Link to={getLink(`/cuentas/${cuenta.id}/ver`)}
                                                                className='item'
                                                                >
                                                                <i className='zmdi zmdi-eye' />
                                                            </Link>
                                                            <button
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Editar'
                                                                onClick={() => {
                                                                    this.toggleEditModal(
                                                                        cuenta
                                                                    );
                                                                }}>
                                                                <i className='zmdi zmdi-edit' />
                                                            </button>
                                                            <button
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Transacción'
                                                                onClick={() => {
                                                                    this.toggleTransaccionModal(
                                                                        cuenta
                                                                    );
                                                                }}>
                                                                <i className='zmdi zmdi-swap' />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getCuentas}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        cuenta={this.state.cuenta}
                        getCuentas={this.getCuentas}
                    />
                </Modal>
                <Modal
                    show={this.state.showTransaccion}
                    blank={true}
                    toggle={this.toggleTransaccionModal}>
                    <Transaccion
                        toggle={this.toggleTransaccionModal}
                        cuenta={this.state.cuenta}
                        cuentas={this.state.cuentas}
                        getCuentas={this.getCuentas}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        cuenta={this.state.cuenta}
                        getCuentas={this.getCuentas}
                        borrarCuenta={this.toggleBorrarCuenta}
                    />
                </Modal>
                <Modal
                    toggle={this.toggleBorrarCuenta}
                    title='Borrar Cuenta'
                    acceptText='Borrar'
                    callback={this.borrarCuenta}
                    show={this.state.showBorrarCuenta}>
                    ¿Seguro que desea borrar esta cuenta?
                </Modal>
            </div>
        );
    }
}

export default withRequest(Cuentas);
