import dayjs from "dayjs";
import React from "react";

const Form = ({persona, errors}) => {
    return (
        <div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Nombre:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <input
                        type='text'
                        name='nombre'
                        defaultValue={persona.nombre}
                        className='text-input form-control'
                    />
                    {errors.nombre && (
                        <small className='help-blockParams form-Text'>
                            {errors.nombre[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Relación:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <input
                        type='text'
                        name='relacion'
                        defaultValue={persona.relacion}
                        className='text-input form-control'
                    />
                    {errors.relacion && (
                        <small className='help-blockParams form-Text'>
                            {errors.relacion[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Teléfono:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <input
                        type='text'
                        name='telefono'
                        defaultValue={persona.telefono}
                        className='text-input form-control'
                    />
                    {errors.telefono && (
                        <small className='help-blockParams form-Text'>
                            {errors.telefono[0]}
                        </small>
                    )}
                </div>
            </div>

            <div className='form-separator' />

            {persona.notas?.length > 0 && (
                <div className="row notas">
                    {persona.notas.map(nota => (
                        <div className="col-md-12 nota" key={nota.id}>
                            <i class="zmdi zmdi-comment-text"></i>
                            {nota.descripcion}
                            <div className="nota-meta">Por {nota.user} - {dayjs(nota.created_at).format('MM/DD/YYYY')}</div>
                        </div>
                    ))}
                </div>
            )}

            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Añadir Nota:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <textarea
                        type='text'
                        name='notas'
                        rows='6'
                        className='text-input form-control'
                    />
                    {errors.notas && (
                        <small className='help-blockParams form-Text'>
                            {errors.notas[0]}
                        </small>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Form;
