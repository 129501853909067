import React, { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";

let slideIndex = 1;
const ItemFotos = ({ toggle, fotos }) => {
	// const [slideIndex, setSlideIndex] = useState(1);

	const showSlides = (n) => {
		let i;
		let slides = document.getElementsByClassName("mySlides");
		let dots = document.getElementsByClassName("dot");
		if (n > slides.length) {
			slideIndex = 1;
		}
		if (n < 1) {
			slideIndex = slides.length;
		}
		for (i = 0; i < fotos.length; i++) {
			slides[i].style.display = "none";
		}
		for (i = 0; i < fotos.length; i++) {
			dots[i].className = dots[i].className.replace(" slide-active", "");
		}
		slides[slideIndex - 1].style.display = "block";
		dots[slideIndex - 1].className += " slide-active";
	};
	function plusSlides(n) {
		showSlides((slideIndex += n));
	}
	function currentSlide(n) {
		showSlides((slideIndex = n));
	}
	// const plusSlides = async (n) => {
	// 	await setSlideIndex((slide) => (slide += n));
	// 	console.log(n, slideIndex);

	// 	showSlides(slideIndex);
	// };
	// const currentSlide = async (n) => {
	// 	await setSlideIndex(n);
	// 	showSlides(slideIndex);
	// };

	useLayoutEffect(() => {
		showSlides(slideIndex);
	}, []);
	return (
		<>
			<div className="popup__header">Fotos</div>
			<div className="popup__body">
				<div className="slideshow-container">
					{fotos.map((foto, index) => (
						<div className="mySlides" key={index}>
							<div className="numbertext">
								{index + 1} / {fotos.length}
							</div>
							<img src={foto} style={{ width: "100%" }} alt="imagen" />
						</div>
					))}

					<a className="prev" onClick={() => plusSlides(-1)}>
						&#10094;
					</a>
					<a className="next" onClick={() => plusSlides(1)}>
						&#10095;
					</a>
				</div>
				<br />
				<div style={{ textAlign: "center" }}>
					{fotos.map((_, index) => (
						<span
							className="dot"
							onClick={() => currentSlide(index + 1)}
							key={index}
						></span>
					))}
				</div>
			</div>
			<div className="popup__footer">
				<button
					type="button"
					onClick={toggle}
					className="btn btn-plain"
				>
					Cerrar
				</button>
			</div>
		</>
	);
};

ItemFotos.propTypes = {
	toggle: PropTypes.func,
	fotos: PropTypes.array.isRequired,
};

export default ItemFotos;
