import React, { createContext, useContext, useLayoutEffect, useState } from "react";

import { request } from "../../../utils/request";
import { apiRequest } from "../../../hooks/apiRequest";
import { toast } from "../../../utils/helpers";

export const ConfiguracionAcademicaContext = createContext(null);

export const ConfiguracionAcademicaContextProvider = ({children}) => {
    const context = useConfiguracionAcademica();
    return (
        <ConfiguracionAcademicaContext.Provider value={{...context}}>
            {children}
        </ConfiguracionAcademicaContext.Provider>
    )
}

export const useConfiguracionAcademicaContext = () => {
    const context = useContext(ConfiguracionAcademicaContext);

    if (!context) {
        throw new Error("useConfiguracionAcademicaContext debe ser utilizado dentro de ConfiguracionAcademicaContextProvider")
    }

    return context;
}

export const useConfiguracionAcademica = () => {
    const [errors, setErrors] = useState({});
    const [item, setItem] = useState({});
    const [items, setItems] = useState([]);
    const [filtrosQuery, setFiltrosQuery] = useState("");
	const [filtros, setFiltros] = useState({});
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showCreate, setShowCreate] = useState(false);

    const toggleCreateModal = () => {
        setErrors({});
        setSaving(false);
        setShowCreate(prev => !prev);
    };

    const toggleEditModal = (item = null) => {
        setErrors({});
        setShowEdit(prev => !prev);
        setSaving(false);
        if (item) {
            setItem(item);
        } else {
            setItem({});
        }
    };

    const toggleDeleteModal = () => {
        setShowDelete(prev => !prev);
        setSaving(false);
    }

    const getData = (url) => {
        setLoading(true);
        request(url).then((res) => {
            setItems(res.data);
            setLoading(false);
		});
    };

    const getTable = async (url) => {
        return request(url).then((res) => {
            return res.data;
		});
    };

    const onFiltroChange = async (value, filtro) => {
		setFiltros((filtros) => ({
			...filtros,
			[filtro]: value,
		}));
	};

    const create = async (event, url, successMessage = "Item creado.") => {
        event.preventDefault();
        setSaving(true);

        const data = new FormData(event.target);

        apiRequest(data, url, 'POST').then(response => {
            if (response.success) {
                getData(url, 'items');
                toggleCreateModal();
                toast(successMessage);
            }
            if (response.code === 422) {
                setErrors(response.data.message);
                toast('Favor revisar todos los campos.', 'error');
            }
        });

        setSaving(false);
    };

    const edit = async (event, url, successMessage = "Item actualizado") => {
        event.preventDefault();
        setSaving(true);

        const data = new FormData(event.target);
        apiRequest(data, `${url}/${item.id}`, 'POST').then(response => {
            if (response.success) {
                getData(url, 'items');
                toggleEditModal();
                setSaving(false);
                toast(successMessage);
            }
            if (response.code === 422) {
                setErrors(response.data.message);
                setSaving(false);
                toast("No se pudo procesar el pedido.", "error");
            }
        });

    };

    const deleteItem = async (url, successMessage = "Item borrado.") => {
        setSaving(true);

        apiRequest(null, `${url}/${item.id}`, 'DELETE').then(response => {
            if (response.success) {
                getData(url, 'items');
                setSaving(false);
                toggleEditModal();
                toggleDeleteModal();
                toast(successMessage);
            } else {
                setSaving(false);
                toast(response.data, "error");
            }
        });
    };

    useLayoutEffect(() => {
		let query = Object.keys(filtros).map((fil) => {
			return `${fil}=${filtros[fil]}`;
		});

		setFiltrosQuery("?" + query.join("&"));
	}, [filtros]);

    const clasesEstadoMapping = {
        activo: 'success',
        pendiente: 'warning',
        cerrado: 'danger',
        inactivo: 'gray',
    }

    return {
        errors,
        items,
        filtrosQuery,
        showDelete,
        toggleCreateModal,
        toggleEditModal,
        toggleDeleteModal,
        getData,
        getTable,
        onFiltroChange,
        showCreate,
        showEdit,
        item,
        loading,
        saving,
        setErrors,
        create,
        clasesEstadoMapping,
        create,
        edit,
        deleteItem,
        showEdit,
        showCreate,
        showDelete
    }
}
