import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { contador } from "../../../utils/helpers";

class PrintSolicitudes extends Component {
    state = {
        solicitudes: [],
    };
    componentDidMount() {
        this.getSolicitudes();
    }
    getSolicitudes = async () => {
        const response = await this.props.request(
            "solicitudes" + window.location.search
        );

        if (response.data) {
            this.setState({
                solicitudes: response.data,
            });
            //window.print();
        }
    };
    render() {
        const { solicitudes } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>Solicitudes</h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-center p-b-10'></div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ width: "100px" }}>Fecha</th>
                            <th style={{ width: "30%" }}>Descripción</th>
                            <th></th>
                            <th>Tiempos</th>
                            <th className="text-center" style={{ width: "150px" }}>Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {solicitudes.map((solicitud) => {
                            return (
                                <tr key={solicitud.id}>
                                    <td>
                                        {dayjs(solicitud.fecha).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </td>
                                    <td>
                                        <div>
                                            <strong>{solicitud.numero} - {solicitud.establecimiento}</strong>
                                        </div>
                                        <div style={{ whiteSpace: "pre-line" }}>
                                            {solicitud.descripcion}
                                        </div>
                                        <small>Creador: {solicitud.creador}</small>
                                        <br />
                                        <small>
                                            {solicitud.ultimaActividad}
                                        </small>
                                    </td>
                                    <td>
                                        <strong>
                                            {solicitud.vehiculo}
                                        </strong>
                                        <div>
                                            {solicitud.empleado}
                                        </div>

                                        <div>{solicitud.telefono && <small>Telefono: {solicitud.telefono}</small>}</div>

                                        <div>{solicitud.flota && <small>Flota: {solicitud.flota}</small>}</div>
                                        
                                        <div className="d-flex">
                                            {solicitud.averia && (
                                                <div className="m-r-15">
                                                    <div style={{marginBottom: '-5px'}}><small className='uppercase'>Avería:</small></div>
                                                    <div>{solicitud.averia}</div>
                                                </div>
                                            )}
                                            {solicitud.provincia && (
                                                <div>
                                                    <div style={{marginBottom: '-5px'}}><small className='uppercase'>Provincia:</small></div>
                                                    <div>{solicitud.provincia}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex">
                                            {solicitud.ruta && (
                                                <div className="m-r-15">
                                                    <div style={{marginBottom: '-5px'}}><small className='uppercase'>Zona:</small></div>
                                                    <span>{solicitud.ruta.nombre}</span>
                                                </div>
                                            )}
                                            {solicitud.contacto && (
                                                <div>
                                                    <div style={{marginBottom: '-5px'}}><small className='uppercase'>Contacto:</small></div>
                                                    <span>{solicitud.contacto.nombre}</span>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="p-b-15">
                                            <strong>Tiempo Transcurrido</strong>
                                            <table style={{width: '100%'}}>
                                                <tbody>
                                                    <tr>
                                                        <td>Pendiente:</td>
                                                        <td className='text-right'>{contador(solicitud.t_pendiente)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>En Progreso:</td>
                                                        <td className='text-right'>{contador(solicitud.t_progreso)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Completada:</td>
                                                        <td className='text-right'>{contador(solicitud.t_total)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                    <td className="text-center" style={{ textTransform: "uppercase" }}>
                                        {solicitud.estado}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintSolicitudes));
