import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import dayjs from "dayjs";

import { withRequest } from "../../../utils/request";
import { formatCurrency } from "../../../utils/helpers";
import { auth } from "../../../utils/auth";

class Comisiones extends Component {
    state = {
        orden: 'nombre',
        pago: {},
        redondear: false,
    };
    componentDidMount() {
        this.getNomina();
    }
    getNomina = async (url = null) => {
        const { id } = this.props.match.params;
        url = url ? url : `nominas/${id}?detalles=true`;
        const nomina = await this.props.request(url);

        if (nomina.success) {
            this.setState({
                nomina: nomina.data[0],
            });
            //window.print();
        }
    };
    onChangeOrden = (e) => {
        this.setState({
            orden: e.target.value
        });
    }
    onChangeRedondear = () => {
        this.setState({
            redondear: !this.state.redondear
        });
    }
    redondear = (cantidad) => {
        return Math.round(cantidad/5) * 5;
    }
    render() {
        const { nomina, orden, redondear } = this.state;

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        return (
            <div>
                <style>{`@page {size: 11in  8.5in;} *{font-size: 14px}`}</style>
                {nomina?.pagos?.map(pago => (
                    <div key={pago.id} style={{pageBreakAfter: 'always'}}>
                        <div className='row'>
                            {pago.cerradaEn && (
                                <div className='fecha_cierre col-md-12'>
                                    Nomina Cerrada:{" "}
                                    {dayjs(nomina.cerradaEn).format("DD/MM/YYYY")}
                                </div>
                            )}
                            <h3 className='col-md-12 text-center m-b-5'>
                                Detalle de Comisiones por {pago.tipo} {" "}
                                {query.contrato === "informal" ? "Informales" : ""}
                            </h3>
                            <div className='col-md-12 text-center'>
                                {sucursal.nombre} - {sucursal.direccion} •{" "}
                                {dayjs(nomina.desde || nomina.fechaInicio).format("DD/MM/YYYY")} -{" "}
                                {dayjs(nomina.hasta || nomina.fechaCierre).format("DD/MM/YYYY")}
                            </div>
                        </div>
                        <div className='row text-right'>
                            <div className='col text-left'>
                                TRANSACCIONES: {pago.transacciones?.length} - PORCENTAJE COMISIÓN: {pago.porcentaje}%
                            </div>

                            <div className='col-sm-6 text-center'>
                                <h3>{pago.nombre}</h3>
                            </div>

                            <div className='col-sm-3 text-right'>
                                <h3>TOTAL: {formatCurrency(pago.total)}</h3>
                            </div>
                        </div>
                        <div>
                            <h3 className="pb-3">
                                <div className='float-left'>
                                    EMPLEADO: {pago.codigo} - {pago.empleado}
                                </div>
                            </h3>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Cliente</th>
                                        <th>NFC/Número</th>
                                        <th>Fecha</th>
                                        <th className="text-right">Subtotal</th>
                                        <th className="text-right">Impuesto</th>
                                        <th className="text-right">Total</th>
                                        <th className='text-right'>Comisión</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pago.transacciones?.map((transaccion, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{transaccion.cliente}</td>
                                                <td>{transaccion.numero}</td>
                                                <td>{transaccion.fecha}</td>
                                                <td className="text-right">{formatCurrency(transaccion.subtotal)}</td>
                                                <td className="text-right">{formatCurrency(transaccion.monto - transaccion.subtotal)}</td>
                                                <td className="text-right">{formatCurrency(transaccion.monto)}</td>
                                                <td className="text-right">{formatCurrency(transaccion.comision)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 text-right p-t-10 p-b-10'>
                                <div className='float-left'>
                                    TRANSACCIONES: {pago.transacciones?.length} - PORCENTAJE COMISIÓN: {pago.porcentaje}%
                                </div>

                                <div className='float-right'>
                                    <h3>TOTAL: {formatCurrency(pago.total)}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default withRouter(withRequest(Comisiones));
