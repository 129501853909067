import dayjs from 'dayjs';
import React, { useState } from 'react';
import { formatCurrency, getLink } from '../../../../utils/helpers';
import { Link } from 'react-router-dom';

const mappingClases = {
    pendiente: "text-uppercase badge badge-warning",
    cancelado: "text-uppercase badge badge-danger",
    pagado: "text-uppercase badge badge-success",
    anulado: "text-uppercase badge badge-dark",
    exonerado: "text-uppercase badge badge-info",
    devuelto: "text-uppercase badge badge-info",
}

const Seleccion = ({seleccion, serviciosFiltrados, updateSeleccion, total}) => {

    const [mostrarTodos, setMostrarTodos] = useState(false);

    return (
        <div className="crear-compra">
            <table className="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Fecha</th>
                        <th>Servicio</th>
                        <th>Estado</th>
                        <th className='text-right'>Monto</th>
                    </tr>
                </thead>
                <tbody>
                    {serviciosFiltrados.length > 0 ? (
                        <tr>
                            <td colSpan={5} className='text-center'><button type="button" className="btn" onClick={() => setMostrarTodos(!mostrarTodos)} ><small>{mostrarTodos ? 'Ocultar' : 'Mostrar'} historial de cobros</small></button></td>
                        </tr>
                    ) : (
                        <tr>
                            <td colSpan={5} className='text-center'>
                                <small className='m-t-25'>Debes seleccionar una clase cocurricular para pagar. &rarr;</small>
                            </td>
                        </tr>
                    )}
                    {serviciosFiltrados?.filter(s => mostrarTodos ? true : s.estado.includes('pendiente')).map(servicio => (
                        <PagoRow
                            servicio={servicio}
                            seleccion={seleccion}
                            updateSeleccion={updateSeleccion}
                        />
                    ))}
                    {/* <tr>
                        <td colSpan={5} className="text-right">
                            <div className='d-flex align-center justify-content-end'>
                                <span>Descuento:</span> <input type="text" name="descuento" style={{width: '150px'}} className='form-control m-l-15' />
                            </div>
                        </td>
                    </tr> */}
                    <tr>
                        <td colSpan={5} className="text-right">Total a Pagar: <strong>{formatCurrency(total)}</strong></td>
                    </tr>
                </tbody>
            </table>
            <div className="form-group m-t-25">
                <label>Notas:</label>
                <textarea name="notas" className='form-control' cols="30" rows="4"></textarea>
            </div>
        </div>
    );
}

export default Seleccion;

const PagoRow = ({servicio, seleccion, updateSeleccion}) => {
    const [open, setOpen] = useState(false);

    return <tr key={servicio.id}>
        <td className='text-center'>
            {servicio.estado === 'pendiente' && (
                <input
                    type="checkbox"
                    className='m-r-10'
                    value={servicio.id}
                    checked={seleccion.includes(servicio.id)}
                    onChange={(e) => {
                        updateSeleccion(parseInt(servicio.id));
                    } } />
            )}
        </td>
        <td>{dayjs(servicio.fecha).format('DD/MM/YYYY')}</td>
        <td style={{ lineHeight: 1.1 }}>
            {servicio.recibos?.length > 0 ? (
                <>
                    <button type="button" className="text-left btn-block" style={{marginTop: '4px'}} onClick={()=> setOpen(prev => !prev)}>
                        {servicio.nombre}
                        <div className="float-right m-r-25">
                            <small className={open ? "zmdi zmdi-chevron-down" : "zmdi zmdi-chevron-right"}></small>
                        </div>
                    </button>
                    {open && (
                        <ul className="list-unstyled m-t-10">
                            {servicio.recibos.map(recibo => {
                                return (<li>
                                    {dayjs(recibo.fecha).format('DD/MM/YYYY')} - {" "}
                                    <Link to={getLink(`/imprimir/academico/recibos/${recibo.id}/cocurricular`)} target="_blank">
                                        {recibo.numeracion}
                                        <small><i className="fa fa-print m-l-5"></i></small>
                                    </Link> - {formatCurrency(parseFloat(recibo.abonado))}
                                    <small> - {servicio.modificado_por?.username} {recibo.estado === 'cancelado' ? (<small className={mappingClases[recibo.estado]}>{recibo.estado}</small>) : ''}</small>
                                </li>);
                            })}
                        </ul>
                    )}
                </>
            ) : (
                <>
                    <div>{servicio.nombre}</div>
                    {servicio.modificado_por && (
                        <small>Modificado por: {servicio.modificado_por?.username} {servicio.estado === 'cancelado' ? (<small className={mappingClases[servicio.estado]}>cancelado</small>) : ''}</small>
                    )}
                </>
            )}
        </td>
        <td className='text-uppercase'>
            <small className={mappingClases[servicio.estado]}>{servicio.estado}</small> {servicio.fechaPagado && <small>{dayjs(servicio.fechaPagado).format("DD/MM/YYYY")}</small>}
        </td>
        <td className='text-right'>{formatCurrency(servicio.estado === 'pagado' ? servicio.monto : servicio.restante)}</td>
    </tr>;
}
