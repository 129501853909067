import React, { Component, useState, useEffect } from "react";
import { withRequest, request } from "../../utils/request";
import dayjs from "dayjs";
import Select from "react-select";
import { getLink, toast } from "../../utils/helpers";

class Reports extends Component {
    state = {
        errors: {},
        vehiculos: [],
        ficha: "",
        tipo: "operaciones-inventario",
    };

    validate = (event) => {
        const form = new FormData(event.target);

        if (!form.get("productos")) {
            event.preventDefault();
            toast("Debes seleccionar al menos un producto.", "error");
        }
        console.log(event.target);
    };

    render() {

        return (
            <form
                method='get'
                target='_blank'
                action={getLink("/imprimir/reportes")}
                onSubmit={this.validate}>
                <div className='popup__header'>Generar Reporte de Operaciones de Inventario</div>
                <div className='popup__body'>
                    <div>
                        <input
                            type='hidden'
                            name='reporte'
                            value='operaciones-inventario'
                        />
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Desde:</label>
                                    <input
                                        type='date'
                                        name='desde'
                                        id='desde'
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Hasta:</label>
                                    <input
                                        type='date'
                                        name='hasta'
                                        defaultValue={dayjs()
                                            .format("YYYY-MM-DD")}
                                        id='hasta'
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <Reporte/>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain'>
                        Atrás
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Generar
                    </button>
                </div>
            </form>
        );
    }
}

export default withRequest(Reports);

function Reporte() {
    const [productos, setProductos] = useState([]);
    const [selectedProductos, setSelectedProductos] = useState([]);

    useEffect(() => {
        let ignore = false;

        async function fetchProducto() {
            const response = await request("productos");
            console.log(response.data);
            if (!ignore) {
                setProductos(response.data);
            }
        }

        fetchProducto();
        return () => {
            ignore = true;
        };
    }, []);

    function onChangeProducto(values) {
        if (values) {
            setSelectedProductos(values.map((i) => i.value));
        } else {
            setSelectedProductos([]);
        }

    }

    return (
        <div>
            <div className='form-group' style={{zIndex: 9999}}>
                <label>Productos | <small>Puede seleccionar más de uno</small></label>
                <input
                    type='hidden'
                    name='productos'
                    value={selectedProductos.join(",")}
                />
                    <Select
                        menuPosition={'fixed'}
                        maxMenuHeight={250}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        isLoading={productos.length === 0}
                        isMulti
                        loadingMessage={() => "Cargando Productos..."}
                        onChange={onChangeProducto}
                        options={[{value: "todos", label:"Todos"},...productos.map((producto) => ({
                            value: producto.id,
                            label: `${producto.referencia} - ${producto.nombre}`,
                        }))]}></Select>
            </div>
        </div>
    );
}