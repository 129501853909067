import React, { Component } from "react";

class Form extends Component {
    state = {
        operadores: []
    };
    componentDidMount() {
        this.setState({
            operadores: this.props.categoria.operadores
        });
    }
    addOperador = () => {
        this.setState({
            operadores: [...this.state.operadores, {}]
        });
    };
    removeOperador = index => {
        this.setState({
            operadores: this.state.operadores.filter((op, i) => i !== index)
        });
    };
    render() {
        const { operadores } = this.state;
        const { categoria, errors } = this.props;
        return (
            <div>
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Nombre de la Categoría:
                        </label>
                    </div>
                    <div className='col col-md-7'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre'
                            name='nombre'
                            defaultValue={categoria.nombre}
                        />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                {operadores.length > 0 && (
                    <FormOperadores
                        operadores={operadores}
                        categoria={categoria}
                        removeOperador={this.removeOperador}
                    />
                )}
                <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => this.addOperador()}>
                    Agregar Operador
                </button>
            </div>
        );
    }
}

const FormOperadores = ({ operadores, removeOperador, categoria }) => {
    return (
        <div>
            <h2 className='form-section-title p-b-5' style={{paddingBottom: 0}}>Tipos de empleado</h2>
            <p className="m-b-15" style={{lineHeight: 1.2}}><small>Agregue el tipo de empleados que comisionará adicional a su sueldo.</small></p>
            <div className='row form-group'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Comisión por producción</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {operadores.map((operador, index) => {
                            return (
                                <tr key={operador.nombre + operador.sueldo}>
                                    <td>
                                        <input
                                            type='hidden'
                                            name={`operadores[${index}][id]`}
                                            value={operador.id}
                                        />
                                        <input
                                            type='text'
                                            className='text-input form-control'
                                            name={`operadores[${index}][nombre]`}
                                            defaultValue={operador.nombre}
                                            placeholder='Tipo de empleado'
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            className='text-input form-control'
                                            name={`operadores[${index}][sueldo]`}
                                            placeholder='Comisión'
                                            defaultValue={operador.sueldo}
                                        />
                                    </td>
                                    <td>
                                        {!categoria.vehiculos && (
                                            <div className='table-data-feature'>
                                                <button
                                                    className='item'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title=''
                                                    type='button'
                                                    data-original-title='Borrar'
                                                    onClick={() =>
                                                        removeOperador(index)
                                                    }>
                                                    <i className='zmdi zmdi-delete' />
                                                </button>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Form;
