import React from 'react';
import Select from 'react-select/async-creatable';
import { apiRequest } from '../../../../hooks/apiRequest';

export default function FormNiveles({item, errors}) {

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };

    return (<>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Nombre:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='text'
                    className='text-input form-control'
                    id='nombre'
                    name='nombre'
                    defaultValue={item.nombre}
                />
                {errors.nombre && (
                    <small className='help-blockParams form-Text'>
                        {errors.nombre[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Estado:
                </label>
            </div>
            <div className='col col-md-7'>
                <select className='form-control' name="estado" defaultValue={item.estado}>
                    <option value="activo">Activo</option>
                    <option value="pendiente">Pendiente</option>
                    <option value="inactivo">Inactivo</option>
                    <option value="cerrado">Cerrado</option>
                </select>
                {errors.estado && (
                    <small className='help-blockParams form-Text'>
                        {errors.estado[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Documentos:
                </label>
            </div>
            <div className='col col-md-7'>
                <Select
                    key="documentos"
                    name='documentos[]'
                    isClearable
                    isMulti
                    required
                    cacheOptions
                    defaultOptions
                    menuPosition='fixed'
                    menuPlacement='auto'
                    defaultValue={item.documentos && item.documentos.map(c => ({label: c.nombre, value: c.id}))}
                    loadOptions={(e) => {
                        return getOptions("taxonomias?relacion=Niveles&buscar="+e);
                    }}
                />
                {errors.documentos && (
                    <small className='help-blockParams form-Text'>
                        {errors.documentos[0]}
                    </small>
                )}
            </div>
        </div>
    </>)
}
