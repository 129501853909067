import React, { Component } from "react";
import { withRequest } from "../../utils/request";
import ImageLoader from "../general/ImageLoader";
import CreatableSelect from "react-select/creatable";
import { apiRequest } from "../../hooks/apiRequest";

class Form extends Component {
	state = {
		grupos: [],
		almacenes: [],
		inventariable: true,
		cantidad: 0,
		precios_venta: [
			{
				id: Date.now(),
				precio_venta: 0,
				impuesto: 0,
				precio_total: 0,
				costo: 0,
			},
		],
		itemType: "item",
		impuesto_id: "",
		impuestos: [],
		costo_total: 0,
		unidades: [
            {value: 'UND', label: 'UND'},
            {value: 'PAQUETE', label: 'PAQUETE'},
            {value: 'JUEGO', label: 'JUEGO'},
            {value: 'PIES', label: 'PIES'},
            {value: 'PULG', label: 'PULG'},
            {value: 'MTS', label: 'MTS'},
            {value: 'CM', label: 'CM'},
            {value: 'GLS', label: 'GLS'},
            {value: 'LATA', label: 'LATA'},
            {value: 'CUBETA', label: 'CUBETA'},
            {value: 'CUARTOS', label: 'CUARTOS'},
            {value: 'LITROS', label: 'LITROS'},
            {value: 'LBS', label: 'LBS'},
            {value: 'KGS', label: 'KGS'},
        ],
		unidad: {value: 'UND', label:'UND'},
		isLoading: false,
	};

	componentDidMount() {
		this.getTable("grupos");
		this.getTable("almacenes");
		this.getImpuestos();
		this.getUnidades();

		this.setState({
			inventariable: this.props.item.nombre
				? this.props.item.inventariable
				: true,
		});
		this.costoField = React.createRef();
	}
	getTable = async (url = null) => {
		const table = await this.props.request(url);

		if (table.data) {
			this.setState({
				[url]: table.data,
				loading: false,
			});
		}
	};
	getImpuestos = async () => {
		const impuestos = await this.props.request("impuestos-dropdown");

		if (impuestos.success) {
			this.setState({
				impuestos: impuestos.data,
				impuesto_id: impuestos.data.find(
					(impuesto) =>
						impuesto.porcentaje === 0.18 &&
						impuesto.label.toLowerCase().includes("itbis")
				).value,
			});
			return;
		}
	};
	getUnidades = async () => {
		const unidades = {}; //await this.props.request("getUnidades");

		if (unidades.success) {
			this.setState({ unidades: unidades.data });
			return;
		}
	};
	createUnidad = async (inputValue) => {
		this.setState({ isLoading: true });
		const formData = new FormData();
		formData.append("seccion", "inventario");
		formData.append("nombre", "unidad");
		formData.append("valor", inputValue.toUpperCase());

		const {success, data} = await apiRequest(formData, "store-config", "post");

		if (success) {
			this.setState(state => ({ ...state, unidad: {value: data.valor, label: data.valor}, isLoading: false, unidades: [...state.unidades, {value: data.valor, label: data.valor}] }));
		}
	};
	handleUnidadChange = (newValue) => {
		this.setState({ unidad: newValue });
	};
	onChangeInput = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});

		if (e.target.name !== "costo" && e.target.name !== "impuesto_id")
			return;

		this.onChangeCostoTotal(e.target.name, parseFloat(e.target.value));
	};
	onChangeCheckbox = (e) => {
		this.setState({
			[e.target.name]: e.target.checked,
		});
	};
	onChangeCostoTotal = (field, currentValue) => {
		if (field === "costo") {
			this.setState((state) => ({
				...state,
				costo_total:
					currentValue +
					currentValue *
						state.impuestos.find(
							(impuesto) =>
								impuesto.value === parseInt(state.impuesto_id)
						).porcentaje,
			}));
			return;
		}
		this.setState((state) => ({
			...state,
			costo_total:
				parseFloat(state.costo) +
				parseFloat(state.costo) *
					state.impuestos.find(
						(impuesto) => impuesto.value === currentValue
					).porcentaje,
		}));
	};
	addPrecio = () => {
		this.setState((state) => ({
			precios_venta: [
				...state.precios_venta,
				{
					id: Date.now(),
					precio_venta: 0,
					impuesto: "",
					precio_total: 0,
					costo: 0,
				},
			],
		}));
	};

	removePrecio = (item) => {
		this.setState((state) => ({
			...state,
			precios_venta: state.precios_venta.filter(
				(impuesto) => impuesto.id != item
			),
		}));
	};
	render() {
		const { item, errors } = this.props;
		const {
			grupos,
			almacenes,
			inventariable,
			cantidad,
			itemType,
			impuesto_id,
			impuestos,
			costo_total,
			unidades,
			unidad,
			isLoading,
		} = this.state;
		return (
			<>
				{/* TIPOS */}
				<div className="items">
					<div className="row">
						{/* PRIMERA COLUMNA */}
						<div className="col-12 col-sm-4">
                            <div className="form-group" >
                                <input
                                    name="tipo"
                                    hidden
                                    value={itemType}
                                    onChange={() => {}}
                                />
                                <button
                                    type="button"
                                    className={`btn btn-lg d-inline-flex col-3 justify-content-between px-2 align-items-center mr-2 ${
                                        itemType === "item"
                                            ? "btn-outline-success"
                                            : "btn-outline-secondary"
                                    }`}
                                    style={{
                                        maxWidth: "125px",
                                    }}
                                    onClick={() => this.setState({ itemType: "item" })}
                                >
                                    ITEM
                                    <i
                                        className="fas fa-check-circle"
                                        style={{
                                            fontSize: "large",
                                            display:
                                                itemType !== "item" ? "none" : "inline",
                                        }}
                                    ></i>
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-lg d-inline-flex col-3 justify-content-between px-2 align-items-center mr-2 ${
                                        itemType === "servicio"
                                            ? "btn-outline-success"
                                            : "btn-outline-secondary"
                                    }`}
                                    style={{
                                        maxWidth: "125px",
                                    }}
                                    onClick={() =>
                                        this.setState({ itemType: "servicio" })
                                    }
                                >
                                    SERVICIO
                                    <i
                                        className="fas fa-check-circle"
                                        style={{
                                            fontSize: "large",
                                            display:
                                                itemType !== "servicio"
                                                    ? "none"
                                                    : "inline",
                                        }}
                                    ></i>
                                </button>
                            </div>
							<div className="form-group">
								<label className="label form-control-label">
									Nombre:
								</label>
								<input
									type="text"
									className="text-input form-control"
									id="nombre"
									name="nombre"
									defaultValue={item.nombre}
								/>
								{errors.nombre && (
									<small className="help-blockParams form-Text">
										{errors.nombre[0]}
									</small>
								)}
							</div>
							<div className="form-group">
								<label className="label form-control-label">
									Referencia:
								</label>
								<input
									type="text"
									className="text-input form-control"
									id="referencia"
									name="referencia"
									defaultValue={item.referencia}
								/>
								{errors.referencia && (
									<small className="help-blockParams form-Text">
										{errors.referencia[0]}
									</small>
								)}
							</div>
							{itemType === "item" && (
								<div className="form-group">
									<label className="label form-control-label">
										Estado:
									</label>
									<select
										type="text"
										className="text-input form-control"
										id="estado"
										name="estado"
										defaultValue={item.estado}
									>
										<option value="nuevo">Nuevo</option>
										<option value="usado">Usado</option>
									</select>
									{errors.estado && (
										<small className="help-blockParams form-Text">
											{errors.estado[0]}
										</small>
									)}
								</div>
							)}
							<div className="form-group">
                                    <table className="table-precios">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label className="label form-control-label">
                                                        Costo:
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="text-input form-control"
                                                        id="costo"
                                                        name="costo"
                                                        step="0.01"
                                                        defaultValue={item.costo}
                                                        ref={this.costoField}
                                                        onChange={(e) => this.onChangeInput(e)}
                                                    />
                                                    {errors.costo && (
                                                        <small className="help-blockParams form-Text">
                                                            {errors.costo[0]}
                                                        </small>
                                                    )}
                                                </td>
                                                <td className="p-t-15">
                                                    <span
                                                        className="h2 color-primary"
                                                    >
                                                        +
                                                    </span>
                                                </td>
                                                <td>
                                                    <label className="label form-control-label">
                                                        Impuesto:
                                                    </label>
                                                    <select
                                                        type="text"
                                                        className="form-control"
                                                        id="impuesto_id"
                                                        name="impuesto_id"
                                                        value={impuesto_id}
                                                        onChange={(e) => this.onChangeInput(e)}
                                                    >
                                                        {impuestos.map((impuesto) => (
                                                            <option
                                                                value={impuesto.value}
                                                                key={impuesto.value}
                                                            >
                                                                {impuesto.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td className="p-t-15">
                                                    <span
                                                        className="h2 color-primary"
                                                    >
                                                        =
                                                    </span>
                                                </td>
                                                <td>
                                                    <label className="label form-control-label">
                                                        Costo Total:
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="text-input form-control"
                                                        id="costo_total"
                                                        name="costo_total"
                                                        step="0.01"
                                                        value={costo_total}
                                                        readOnly
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
						</div>

						{/* SEGUNDA COLUMNA */}
						<div className="col-12 col-sm-4">
                            <div className="form-group row">
                                    <div
                                        className="col-md-4"
                                        style={{ height: "63px" }}
                                    >
                                        <label className="label form-control-label">
                                            Inventariable
                                        </label>
                                        <label className="switch switch-3d switch-success mr-3">
                                            <input
                                                type="hidden"
                                                name="inventariable"
                                                value="no"
                                            />
                                            <input
                                                type="checkbox"
                                                className="switch-input"
                                                name="inventariable"
                                                value="yes"
                                                onChange={this.onChangeCheckbox}
                                                defaultChecked={
                                                    item.id
                                                        ? item.inventariable
                                                        : inventariable
                                                }
                                            />
                                            <span className="switch-label" />
                                            <span className="switch-handle" />
                                        </label>
                                    </div>
                                    {inventariable ? (
                                        <div className="col-md-4">
                                            <label className="label form-control-label">
                                                Cantidad:
                                            </label>
                                            <input
                                                type="number"
                                                className="text-input form-control"
                                                id="cantidad"
                                                name="cantidad"
                                                step="0.01"
                                                onChange={this.onChangeInput}
                                                value={
                                                    cantidad ? cantidad : item.cantidad
                                                }
                                            />
                                            {errors.cantidad && (
                                                <small className="help-blockParams form-Text">
                                                    {errors.cantidad[0]}
                                                </small>
                                            )}
                                        </div>
                                    ) : (
                                        <input
                                            type="hidden"
                                            name="cantidad"
                                            value="0"
                                        />
                                    )}
                                    <div className="col-md-4">
                                        <label className="label form-control-label">
                                            Unidad:
                                        </label>
                                        <CreatableSelect
                                            options={unidades}
                                            name="unidad"
                                            onCreateOption={this.createUnidad}
                                            isClearable
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            onChange={this.handleUnidadChange}
                                            value={unidad}
                                        />
                                        {errors.unidad && (
                                            <small className="help-blockParams form-Text">
                                                {errors.unidad[0]}
                                            </small>
                                        )}
                                    </div>
                                </div>
							{grupos.length > 0 && itemType === "item" && (
								<div className="form-group">
									<label className="label form-control-label">
										Grupo:
									</label>
									<select
										className="text-input form-control"
										id="grupo_id"
										name="grupo_id"
										rows="4"
										defaultValue={
											item.grupo ? item.grupo.id : false
										}
									>
										<option value="">
											- Seleccionar -
										</option>
										{grupos.map((grupo) => (
											<option
												key={grupo.id}
												value={grupo.id}
											>
												{grupo.nombre}
											</option>
										))}
									</select>
									{errors.grupo_id && (
										<small className="help-blockParams form-Text">
											{errors.grupo_id[0]}
										</small>
									)}
								</div>
							)}
							{almacenes.length > 0 && itemType === "item" && (
								<div className="form-group">
									<label className="label form-control-label">
										Almacen:
									</label>
									<select
										className="text-input form-control"
										id="almacen_id"
										name="almacen_id"
										rows="4"
										defaultValue={
											item.almacen
												? item.almacen.id
												: false
										}
									>
										<option value="">
											- Seleccionar -
										</option>
										{almacenes.filter(a => a.tipo_producto === 'items').map((almacen) => (
											<option
												key={almacen.id}
												value={almacen.id}
											>
												{almacen.nombre}
											</option>
										))}
									</select>
									{errors.almacen_id && (
										<small className="help-blockParams form-Text">
											{errors.almacen_id[0]}
										</small>
									)}
								</div>
							)}
						</div>

						{/* TERCERA COLUMNA */}
						<div className="col-12 col-sm-4">
							<div className="form-group">
								<label className="label form-control-label">
									Descripción:
								</label>
								<textarea
									className="text-input form-control"
									id="descripcion"
									name="descripcion"
									rows="4"
									defaultValue={item.descripcion}
								/>
								{errors.descripcion && (
									<small className="help-blockParams form-Text">
										{errors.descripcion[0]}
									</small>
								)}
							</div>
                            <div className="col-md-12">
                                {itemType === "item" && (
                                    <div className="form-group">
                                        <label className="label form-control-label">
                                            Fotos:
                                        </label>
                                        <ImageLoader name="fotos[]" multiple />
                                        {errors.fotos && (
                                            <small className="help-blockParams form-Text">
                                                {errors.fotos[0]}
                                            </small>
                                        )}
                                    </div>
                                )}
                            </div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRequest(Form);
