/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";
import { request } from "../../../utils/request";

const ReporteCuadreCompra = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const { recibos, compras, summaries } = useData();

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotalCompras = compras.reduce((acc, factura) => {
		return (
			acc +
			(factura.estado === "cancelada" ? 0 : parseFloat(factura.total))
		);
	}, 0);
	const valorTotalPendienteCompras = compras.reduce((acc, factura) => {
		return (
			acc +
			(factura.estado === "cancelada" ? 0 : parseFloat(factura.porPagar))
		);
	}, 0);
	const valorTotalRecibos = recibos.reduce((acc, recibo) => {
		return (
			acc +
			(recibo.status === "cancelado" ? 0 : parseFloat(recibo.abono_total))
		);
	}, 0);
	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte de Cuadre de Compras
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde") && queryParams.get("hasta") ? (
                        <span>
                            {queryParams.get("desde")
                                ? `${dayjs(queryParams.get("desde")).format(
                                        "DD/MM/YYYY"
                                  )} - `
                                : ""}{" "}
                            {dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
                        </span>
                    ) : (
                        <span>{dayjs().format("DD/MM/YYYY")}</span>
                    )}
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-8">
					<h5 className="fw-600 w-100 text-center mb-2">
						FORMAS DE PAGO
					</h5>
					<table className="table m-b-40">
						{/* <thead>
							<tr>
								<th>01 - EFECTIVO</th>
								<th>02 - CHEQUES/TRANSFERENCIAS</th>
								<th>03 - TARJETA DE CREDITO/DEBITO</th>
								<th>04 - COMPRA A CREDITO</th>
								<th>05 - PERMUTA</th>
								<th>06 - NOTA DE CREDITO</th>
								<th>07 - MIXTO</th>
							</tr>
						</thead> */}
						<tbody>
							<tr>
								<th>01 - EFECTIVO</th>
								<td>{formatCurrency(summaries.efectivo, "$")}</td>
								<th>04 - COMPRA A CREDITO</th>
								<td>{formatCurrency(summaries.credito, "$")}</td>
								<th>07 - MIXTO</th>
								<td>{formatCurrency(summaries.mixto, "$")}</td>
							</tr>
							<tr>
								<th>02 - CHEQUES/TRANSFERENCIAS</th>
								<td>{formatCurrency(summaries.transferencia, "$")}</td>
								<th>05 - PERMUTA</th>
								<td>{formatCurrency(summaries.permuta, "$")}</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>03 - TARJETA DE CREDITO/DEBITO</th>
								<td>{formatCurrency(summaries.tarjeta, "$")}</td>
								<th>06 - NOTA DE CREDITO</th>
								<td>{formatCurrency(summaries.nota, "$")}</td>
								<td></td>
								<td></td>
							</tr>
							{/* <tr>
								<td>{formatCurrency(summaries.efectivo, "$")}</td>
								<td>{formatCurrency(summaries.transferencia, "$")}</td>
								<td>{formatCurrency(summaries.tarjeta, "$")}</td>
								<td>{formatCurrency(summaries.credito, "$")}</td>
								<td>{formatCurrency(summaries.permuta, "$")}</td>
								<td>{formatCurrency(summaries.nota, "$")}</td>
								<td>{formatCurrency(summaries.mixto, "$")}</td>
							</tr> */}
						</tbody>
					</table>
				</div>
				<div className="col-4">
					<h5 className="fw-600 w-100 text-center mb-2">
						DETALLES DE CUADRE
					</h5>
					<table className="table m-b-40">
						{/* <thead>
							<tr>
								<th>Recibos</th>
								<th>Compras</th>
								<th>Total Egreso</th>
							</tr>
						</thead> */}
						<tbody>
							<tr>
								<th>Recibos</th>
								<td>{formatCurrency(valorTotalRecibos, "$")}</td>
							</tr>
							<tr>
								<th>Compras</th>
								<td>{formatCurrency(valorTotalCompras, "$")}</td>
							</tr>
							<tr>
								<th>Total Egreso</th>
								<td>{formatCurrency(valorTotalRecibos + valorTotalCompras, "$")}</td>
							</tr>
							{/* <tr>
								<td>
									{formatCurrency(valorTotalRecibos, "$")}
								</td>
								<td>
									{formatCurrency(valorTotalCompras, "$")}
								</td>
								<td>
									{formatCurrency(valorTotalRecibos + valorTotalCompras, "$")}
								</td>
							</tr> */}
						</tbody>
					</table>
				</div>
			</div>

			<h3>Compras</h3>
			<table className="table m-b-15">
				<thead>
					<tr>
						<th>NCF/Numero</th>
						<th>Tipo</th>
						<th>Suplidor</th>
						<th>Creación</th>
						<th className="text-right">Vencimiento</th>
						<th className="text-right">Estado</th>
						<th className="text-right">Balance</th>
						<th className="text-right">Total</th>
					</tr>
				</thead>
				<tbody>
					{compras.map((compra) => {
						return (
							<tr key={compra.id}>
								<td>{compra.ncf}</td>
								<td className="text-capitalize">
									{compra.tipo}
								</td>
								<td>
									{compra.contacto
										? compra.contacto
										: ""}
								</td>
								<td>{dayjs(compra.fecha).format('DD/MM/YYYY')}</td>
								<td className="text-right">
									{dayjs(compra.fechaVencimiento).format("DD/MM/YYYY")}
								</td>
								<td className="text-capitalize text-right">
									{compra.estado}
								</td>
								<td className="text-capitalize text-right">
									{formatCurrency(compra.porPagar, "$")}
								</td>
								<td className="text-right">
									{formatCurrency(compra.total)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row m-b-40">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL COMPRAS: {compras.length}
					</div>

					<div className="float-right">
						<h5 className="fw-600">
							TOTAL PAGADAS: {formatCurrency(valorTotalCompras)}
						</h5>
					</div>

					<div className="float-right">
						<h5 className="fw-600">
							&nbsp;|&nbsp;
						</h5>
					</div>

					<div className="float-right">
						<h5 className="fw-600">
							TOTAL PENDIENTES: {formatCurrency(valorTotalPendienteCompras)}
						</h5>
					</div>
				</div>
			</div>

			<h3>Recibos de Egreso</h3>
			<table className="table m-b-15">
				<thead>
					<tr>
						<th>Numero</th>
						<th width="300px">Facturas afectadas</th>
						<th>Cliente</th>
						<th>Creación</th>
						<th>Deuda</th>
						<th>Abono</th>
						<th>Restan</th>
						<th>Tipo de pago</th>
						<th>Estado</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{recibos.map((recibo) => {
						return (
							<tr key={recibo.id}>
								<td>{recibo.numeracion}</td>
								<td>
									{recibo.cuentas?.map((cuenta) => (
										<span key={cuenta.id}>
											{cuenta.compra?.ncf }
											{cuenta.compra?.ncf && cuenta.compra?.id && "-"}
											{cuenta.compra?.id}
											,{" "}
										</span>
									))}
								</td>
								<td>{recibo.cliente?.nombre}</td>
								<td>
									{dayjs(recibo.created_at).format(
										"DD/MM/YYYY hh:mm"
									)}
								</td>
								<td>{formatCurrency(recibo.deuda, "$")}</td>
								<td>
									{formatCurrency(recibo.abono_total, "$")}
								</td>
								<td>
									{formatCurrency(recibo.balance_total, "$")}
								</td>
								<td className="text-capitalize">
									{recibo.tipo_pago}
								</td>
								<td className="text-capitalize">
									{recibo.status}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row m-b-40">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL RECIBOS DE EGRESO: {recibos.length}
					</div>

					<div className="float-right">
						<h5 className="fw-600">
							VALOR TOTAL: {formatCurrency(valorTotalRecibos, "$")}
						</h5>
					</div>
				</div>
			</div>
		</>
	);
};

const useData = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const [recibos, setRecibos] = useState([]);
	const [compras, setCompras] = useState([]);
	const [summaries, setSummaries] = useState({
		efectivo: 0,
		transferencia: 0,
		tarjeta: 0,
		credito: 0,
		permuta: 0,
		nota: 0,
		mixto: 0,
	});

	const getCompras = useCallback(() => {
		const searchString =
			location.search === ""
				? "?paginate=100000"
				: location.search + "&paginate=1000000";
		request(`compras${searchString}`).then((res) => {
			setCompras(res.data);
		});
	}, []);
	const getRecibos = useCallback(() => {
		const params = {
			tipo_pago: queryParams.get("metodo_pago")
				? queryParams.get("metodo_pago")
				: "",
			vendedor: queryParams.get("vendedor")
				? queryParams.get("vendedor")
				: "",
			zona: queryParams.get("zona") ? queryParams.get("zona") : "",
			desde: queryParams.get("desde") ? queryParams.get("desde") : dayjs().format("YYYY-MM-DD"),
			hasta: queryParams.get("hasta") ? queryParams.get("hasta") : dayjs().format("YYYY-MM-DD"),
		};
		const searchString = `?tipo_pago=${params.tipo_pago}&tipo_recibo=egreso&vendedor=${params.vendedor}&zona=${params.zona}&desde=${params.desde}&hasta=${params.hasta}&paginate=1000000`;
		request(`recibos-ingreso${searchString}`).then((res) => {
			setRecibos(res.data);
		});
	}, []);

	const calculateSummaries = useCallback(() => {
		// inicializar todos los valores a zero
		const formaPago = {
			efectivo: 0,
			transferencia: 0,
			tarjeta: 0,
			credito: 0,
			permuta: 0,
			nota: 0,
			mixto: 0,
		};
		
		for (const compra of compras) {
			if (compra.estado === "cancelada") continue;

			switch (compra.tipoPago) {
				case "01 - EFECTIVO":
					formaPago.efectivo += compra.total - compra.porPagar;
					break;
				case "02 - CHEQUES/TRANSFERENCIAS":
					formaPago.transferencia += compra.total - compra.porPagar;
					break;
				case "03 - TARJETA DE CREDITO/DEBITO":
					formaPago.tarjeta += compra.total - compra.porPagar;
					break;
				case "04 - COMPRA A CREDITO":
					formaPago.credito += compra.total - compra.porPagar;
					break;
				case "05 - PERMUTA":
					formaPago.permuta += compra.total - compra.porPagar;
					break;
				case "06 - NOTA DE CREDITO":
					formaPago.nota += compra.total - compra.porPagar;
					break;
				case "07 - MIXTO":
					formaPago.mixto += compra.total - compra.porPagar;
					break;
				default:
					break;
			}
		}

		setSummaries(formaPago);
	}, [compras]);

	useEffect(() => {
		getRecibos();
		getCompras();
	}, []);

	useEffect(() => {
		if (recibos.length === 0 && compras.length === 0) return;
		calculateSummaries();
		const timeout = setTimeout(() => {
			window.print();
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [compras, recibos]);

	return useMemo(
		() => ({
			recibos,
			compras,
			summaries,
		}),
		[recibos, compras, summaries]
	);
};

export default ReporteCuadreCompra;
