import React, { useEffect, useState } from 'react';
import RelacionadoItem from './RelacionadoItem';
import { useEstudianteContext } from '../EstudianteContext';

const Relacionados = () => {

    const {estudiante, setEstudiante } = useEstudianteContext();
    const [relacionados, setRelacionados] = useState([{id: 0}]);

    const addRelacionado = () => {
        setRelacionados(prev => ([
            ...prev,
            {id: relacionados.length}
        ]))
    }

    const removeRelacionado = (id) => {
        const relacionadosNuevos = [
            ...relacionados.filter(r => r.id !== id),
        ];
        setRelacionados(relacionadosNuevos);
        setEstudiante(prev => ({
            ...prev,
            rerlacionados: relacionadosNuevos,
        }));
    }

    useEffect(() => {
        if (estudiante?.relacionados?.length > 0) {
            setRelacionados(estudiante.relacionados);
        }
    }, [estudiante.relacionados]);

    return (
        <section>
            <h3>Estudiantes Relacionados</h3>
            <table className="table tickets-table">
                <thead>
                    <tr className="text-uppercase">
                        <td><small><strong>Estudiante</strong></small></td>
                        <td><small><strong>Parentesco</strong></small></td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {relacionados.map(relacionado => (
                        <RelacionadoItem
                            key={relacionado.id}
                            estudiante={estudiante}
                            relacionado={relacionado}
                            relacionados={relacionados}
                            setRelacionados={setRelacionados}
                            removeRelacionado={removeRelacionado}
                        />
                    ))}
                </tbody>
            </table>
            <div className="text-right m-t-15">
                <button type="button" className="btn" onClick={addRelacionado}>
                    + Agregar Estudiante
                </button>
            </div>
        </section>
    );
}

export default Relacionados;
