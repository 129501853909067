import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "../../utils/helpers";

const EditableInput = ({name, type, value, editable = false, onChange, currency = false, prefix = null}) => {

    const [editar, setEditar] = useState(editable);
    const [val, setVal] = useState(value);

    useEffect(()=>{
        setVal(value);
    }, [value]);

    return (
        <div onDoubleClick={() => setEditar(!editar)}>
            {editar ? (
                <div className='input-box'>
                    {prefix && (
                        <span className="prefix">{prefix}</span>
                    )}
                    <input
                        type={type}
                        onChange={(e) => {
                            setVal(e.target.value);
                            if (typeof onChange === 'function') {
                                onChange(e);
                            }
                        }}
                        name={name}
                        value={parseFloat(val)}
                    />
                </div>
            ) : (
                <>
                    {currency ? formatCurrency(val) : val }<i className="fa fa-pen table-edit-field color-primary"></i>
                    <input type="hidden" name={name} value={val} />
                </>
            )}
        </div>
    );
}

EditableInput.propTypes = {
	name: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.number,
	defaultValue: PropTypes.number,
	editable: PropTypes.bool,
	currency: PropTypes.bool,
	onChange: PropTypes.func,
	prefix: PropTypes.string,
};

export default EditableInput;
