import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";

class PrintDetalleEmpleados extends Component {
    state = {
        empleados: [],
    };
    componentDidMount() {
        this.getEmpleados();
    }
    getEmpleados = async () => {
        const response = await this.props.request(
            "empleados" + window.location.search
        );

        if (response.data) {
            this.setState({
                empleados: response.data,
            });
        }
    };
    render() {
        const { empleados } = this.state;

        return (
            <div>
                {empleados.map((empleado) => {
                    return (
                        <div className='detalle-empleado row p-t-15'>
                            <div className='col-md-3 text-center'>
                                {empleado.foto && (
                                    <img
                                        src={empleado.foto}
                                        alt={empleado.codigo}
                                        style={{
                                            width: "115px",
                                            height: "auto",
                                        }}
                                    />
                                )}
                            </div>
                            <div className='col-md-9'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan='2'>
                                                <strong>
                                                    {empleado.nombre}{" "}
                                                    {empleado.apellido}
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='200px'>Cédula:</td>
                                            <td>{empleado.cedula}</td>
                                        </tr>
                                        <tr>
                                            <td>Cargo:</td>
                                            <td>{empleado.cargo}</td>
                                        </tr>
                                        <tr>
                                            <td>Fecha de Nacimiento:</td>
                                            <td>{empleado.fechaNacimiento}</td>
                                        </tr>
                                        <tr>
                                            <td>Dirección:</td>
                                            <td>{empleado.direccion}</td>
                                        </tr>
                                        {empleado.celular && (
                                            <tr>
                                                <td>Celular:</td>
                                                <td>{empleado.celular}</td>
                                            </tr>
                                        )}
                                        {empleado.cargo &&
                                            empleado.cargo
                                                .toLowerCase()
                                                .indexOf("chofer") > -1 && (
                                                <tr>
                                                    <td>Licencia:</td>
                                                    <td>{empleado.licencia}</td>
                                                </tr>
                                            )}
                                        <tr>
                                            <td>Fecha de Ingreso:</td>
                                            <td>{empleado.fechaIngreso}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default withRouter(withRequest(PrintDetalleEmpleados));
