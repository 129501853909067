import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import dayjs from "dayjs";
import { formatCurrency } from "../../../utils/helpers";

class PrintDescuento extends Component {
    state = {
        descuento: {},
    };
    componentDidMount() {
        this.getTicket();
    }
    getTicket = async () => {
        const id = this.props.match.params.id;
        const response = await this.props.request("descuentos/" + id);

        if (response.success) {
            this.setState({
                descuento: response.data,
            });
            window.print();
        }
    };
    render() {
        const { descuento } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Descuento #{descuento.id}
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-center p-b-10'></div>
                </div>

                <h3>Detalles</h3>
                <table className='table m-b-20'>
                    <tbody>
                        <tr>
                            <td>Fecha Solicitud:</td>
                            <td>{dayjs(descuento.fecha).format("YYYY-MM-DD")}</td>
                        </tr>
                        <tr>
                            <td>Empleado:</td>
                            <td>{descuento.empleado}</td>
                        </tr>
                        <tr>
                            <td>Cargo:</td>
                            <td>{descuento.cargo}</td>
                        </tr>
                        <tr>
                            <td>Teléfono:</td>
                            <td>{descuento.telefono}</td>
                        </tr>
                        <tr>
                            <td>Monto:</td>
                            <td>{formatCurrency(descuento.montoTotal)}</td>
                        </tr>
                        {descuento.cuotasPendientes && <tr>
                            <td>Monto Pendiente:</td>
                            {descuento.cuotasPendientes.length ? (<td>{formatCurrency(descuento.cuotasPendientes.reduce((p,c) => p + parseFloat(c.monto), 0))}</td>) : (<td>{ descuento.pendientes <= 0 ? formatCurrency(0) :  formatCurrency(descuento.monto)}</td>)}

                        </tr>}
                        {descuento.cuotasCompletadas && <tr>
                            <td>Monto Abonado:</td>
                            {descuento.cuotasCompletadas.length ? (<td>{formatCurrency(descuento.cuotasCompletadas.reduce((p,c) => p + parseFloat(c.monto), 0))}</td>) : (<td>{ descuento.pendientes <= 0 ?  formatCurrency(descuento.monto) : formatCurrency(0)}</td>)}
                        </tr>}
                        {descuento.cuotas && <tr>
                            <td>Total Cuotas:</td>
                            <td>{descuento.cuotas.length === 0 ? 1 : descuento.cuotas.length}</td>
                        </tr>}
                        {descuento.cuotasPendientes && <tr>
                            <td>Cuotas Pendientes:</td>
                            <td>{descuento.cuotasPendientes.length > 0 ? descuento.cuotasPendientes.length : (descuento.pendientes > 0 ? descuento.pendientes : 0)}</td>
                        </tr>}
                        {descuento.cuotasCompletadas && <tr>
                            <td>Cuotas Completadas:</td>
                            <td>{descuento.cuotas.length === 0 && descuento.pendientes === 0 ? 1 : descuento.cuotasCompletadas.length}</td>
                        </tr>}
                        <tr>
                            <td>Descripción:</td>
                            <td>{descuento.descripcion}</td>
                        </tr>
                    </tbody>
                </table>

                {descuento.cuotas?.length > 0 && <div>
                    <h3>Cuotas</h3>
                    <table className='table m-b-20'>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Fecha</th>
                                <th>Monto</th>
                                <th className="text-right">Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {descuento.cuotas.map((cuota, i) => (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{dayjs(cuota.fecha).format("DD MMM, YYYY")}</td>
                                    <td>{formatCurrency(cuota.monto)}</td>
                                    <td className="text-right">{cuota.estado}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
            </div>
        );
    }
}

export default withRouter(withRequest(PrintDescuento));
