import React, { Component } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";

import { withRequest } from "../../../utils/request";
import Pagination from "../../general/Pagination";
import Loader from "../../general/Loader";
import { getLink } from "../../../utils/helpers";

class Grupos extends Component {
    state = {
        grupos: [],
        links: [],
        meta: [],
        filtros: {
            buscar: "",
            tipo: "",
            estado: "todos",
        },
        loading: true,
        filtrosQuery: "",
    };

    componentDidMount() {
        this.getGrupos();
    }

    getGrupos = async (url = null) => {
        url = url ? url + "&limit=12" : "gi-grupos?limit=12";
        const grupos = await this.props.request(url);

        if (grupos.links) {
            this.setState({
                grupos: grupos.data,
                links: grupos.links,
                meta: grupos.meta,
                loading: false,
            });
        }
    };

    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getGrupos("gi-grupos?" + query.join("&"));
    }, 500);

    render() {
        const { grupos, loading, filtrosQuery } = this.state;
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Listado de Grupos</span>

                        <div className='dropdown m-r-10'>
                            <button className='au-btn au-btn-icon au-btn-filter'>
                                <i className='zmdi zmdi-print'></i> Imprimir
                            </button>
                            <div
                                style={{ right: "0", left: "auto" }}
                                className='dropdown-menu'>
                                <Link
                                    to={getLink(
                                        `/imprimir/gi-grupos${filtrosQuery}`
                                    )}
                                    target='_blank'
                                    className='dropdown-item'>
                                    {" "}
                                    Listado Grupos
                                </Link>
                            </div>
                        </div>
                        <Link
                            to={getLink("/gi-grupos/crear")}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Grupo
                        </Link>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='search'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Estado</label>
                                    <select
                                        className='form-control'
                                        name='estado'
                                        id='estado'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "estado"
                                            )
                                        }>
                                        <option value='todos'>Todos</option>
                                        <option value='activos'>Activos</option>
                                        <option value='inactivos'>
                                            Inactivos
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grupos-list'>
                    {loading && <Loader />}
                    {!grupos.length ? (
                        <div>
                            {!loading && <div>No hay Grupos creados.</div>}
                        </div>
                    ) : (
                        <div className='table-data'>
                            <table className='table table-data2'>
                                <thead>
                                    <tr>
                                        <th width="50px"></th>
                                        <th>Nombre</th>
                                        <th>Miembros</th>
                                        <th>Estado</th>
                                        <th>Cédula</th>
                                        <th>Teléfono</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {grupos.map((grupo) => (
                                        <GrupoCard
                                            key={grupo.id}
                                            grupo={grupo}
                                        />
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                links={this.state.links}
                                meta={this.state.meta}
                                getData={this.getGrupos}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const GrupoCard = (props) => {
    const { grupo } = props;
    return (
        <tr className="tr-shadow">
            <td>{grupo.foto && (
                <div className='col-md-3'>
                    <span className='page-title-foto'>
                        <img
                            src={grupo.foto}
                            alt={grupo.codigo}
                        />
                    </span>
                </div>
            )}</td>
            <td>
                <Link to={getLink(`/gi-grupos/${grupo.id}/informacion`)}>
                    <strong>{grupo.nombreCompleto}</strong>
                    <div>({grupo.alias})</div>
                </Link>
            </td>
            <td>
                {grupo.miembros
                    .filter(m => m.estado === 'activo')
                    .map(miembro => (
                    <div key={miembro.id}>{miembro.nombre} - {miembro.servidor}</div>
                ))}
            </td>
            <td>
                {grupo.active ?
                    (<span className='badge badge-success'>
                        Activo
                    </span>)
                    :
                    (<span className='badge badge-danger'>
                        Inactivo
                    </span>)
                }
            </td>
            <td>{grupo.cedula}</td>
            <td>
                {grupo.telefono}
                <div>{grupo.flota}</div>
            </td>
            <td>
                <div className="table-data-feature">
                    <Link to={getLink(`/gi-grupos/${grupo.id}/informacion`)}
                        className='item'
                        >
                        <i className='zmdi zmdi-edit' />
                    </Link>
                </div>
            </td>
        </tr>
    );
};

export default withRequest(Grupos);
