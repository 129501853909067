import React, { Component } from "react";
import FotoField from "../../general/FotoField";

class Form extends Component {
    render() {
        const { grupo, errors, codigo } = this.props;
        return (
            <div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Nombre:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='nombre'
                                name='nombre'
                                defaultValue={grupo.nombre}
                            />
                            {errors.nombre && (
                                <small className='help-blockParams form-Text'>
                                    {errors.nombre[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Apellido:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='apellido'
                                name='apellido'
                                defaultValue={grupo.apellido}
                            />
                            {errors.apellido && (
                                <small className='help-blockParams form-Text'>
                                    {errors.apellido[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Cédula:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='cedula'
                                name='cedula'
                                defaultValue={grupo.cedula}
                            />
                            {errors.cedula && (
                                <small className='help-blockParams form-Text'>
                                    {errors.cedula[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Fecha de Nacimiento:
                            </label>
                            <div className='input-group'>
                                <div className='input-group-addon'>
                                    <i className='far fa-calendar' />
                                </div>
                                <input
                                    type='date'
                                    className='text-input form-control'
                                    defaultValue={grupo.fechaNacimiento}
                                    name='fecha_nacimiento'
                                />
                            </div>
                            {errors.fecha_nacimiento && (
                                <small className='help-blockParams form-Text'>
                                    {errors.fecha_nacimiento[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Dirección:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='direccion'
                                name='direccion'
                                rows='7'
                                defaultValue={grupo.direccion}
                            />
                            {errors.direccion && (
                                <small className='help-blockParams form-Text'>
                                    {errors.direccion[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Estado Civil:
                            </label>
                            <select
                                key={grupo.id}
                                className='text-input form-control'
                                id='estado_civil'
                                name='estado_civil'
                                defaultValue={grupo.estadoCivil}>
                                <option value='soltero'>Soltero</option>
                                <option value='casado'>Casado</option>
                                <option value='union-libre'>Unión Libre</option>
                            </select>
                            {errors.estado_civil && (
                                <small className='help-blockParams form-Text'>
                                    {errors.estado_civil[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Foto:
                            </label>
                            <FotoField
                                foto={grupo.foto}
                                fotoCed={grupo.fotoCed}
                                name='foto'
                            />
                        </div>
                    </div>
                </div>
                <div className='form-separator' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Alias:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='alias_grupo'
                                name='alias_grupo'
                                defaultValue={grupo.alias}
                            />
                            {errors.alias_grupo && (
                                <small className='help-blockParams form-Text'>
                                    {errors.alias_grupo[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Código: {codigo && <small>Último Utilizado: {codigo.ultimo}</small>}
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='codigo'
                                name='codigo'
                                defaultValue={grupo.nombre ? grupo.codigo : codigo.mayor}
                            />
                            {errors.codigo && (
                                <small className='help-blockParams form-Text'>
                                    {errors.codigo[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Fecha Ingreso:
                            </label>
                            <div className='input-group'>
                                <div className='input-group-addon'>
                                    <i className='far fa-calendar' />
                                </div>
                                <input
                                    type='date'
                                    className='text-input form-control'
                                    defaultValue={grupo.fechaIngreso}
                                    required
                                    name='fecha_ingreso'
                                />
                            </div>
                            {errors.fecha_ingreso && (
                                <small className='help-blockParams form-Text'>
                                    {errors.fecha_ingreso[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Fecha de Salida:
                            </label>
                            <div className='input-group'>
                                <div className='input-group-addon'>
                                    <i className='far fa-calendar' />
                                </div>
                                <input
                                    type='date'
                                    className='text-input form-control'
                                    defaultValue={grupo.fechaSalida}
                                    name='fecha_salida'
                                />
                            </div>
                            {errors.fecha_salida && (
                                <small className='help-blockParams form-Text'>
                                    {errors.fecha_salida[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Teléfono:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='telefono'
                                name='telefono'
                                defaultValue={grupo.telefono}
                            />
                            {errors.telefono && (
                                <small className='help-blockParams form-Text'>
                                    {errors.telefono[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Flota:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='flota'
                                name='flota'
                                defaultValue={grupo.flota}
                            />
                            {errors.flota && (
                                <small className='help-blockParams form-Text'>
                                    {errors.flota[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div
                            className='form-group'
                            style={{ marginTop: "40px" }}>
                            <span style={{ marginRight: "15px" }}>Activo:</span>
                            <label className='switch switch-3d switch-success mr-3'>
                                <input type='hidden' name='active' value='no' />
                                <input
                                    key={grupo.id}
                                    type='checkbox'
                                    className='switch-input'
                                    id='active'
                                    name='active'
                                    value='si'
                                    defaultChecked={
                                        grupo.id ? grupo.active : true
                                    }
                                />
                                <span className='switch-label' />
                                <span className='switch-handle' />
                            </label>
                            {errors.active && (
                                <small className='help-blockParams form-Text'>
                                    {errors.active[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='form-separator' />
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Notas:
                            </label>
                            <textarea
                                key={grupo.id}
                                className='text-input form-control'
                                id='notas'
                                name='notas'
                                rows='7'
                                defaultValue={grupo.notas}
                            />
                            {errors.notas && (
                                <small className='help-blockParams form-Text'>
                                    {errors.notas[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
