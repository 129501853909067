import React from 'react';
import Loader from '../../general/Loader';

const SearchCedula =  function({searching, searchResults, searchSuccess, onInputChange, buscarTutor, insertarTutor, toggleSearchModal}) {
    return (<>
        <div className='popup__header'>Buscar Tutor</div>
        <div className='popup__body'>
            <form onSubmit={buscarTutor}>
                <div className='search-cedula'>
                    <div className='row'>
                        <div className='col'>
                            <input
                                name='search'
                                onChange={onInputChange}
                                type='text'
                                className='form-control search'
                            />
                        </div>
                        <div className='col-md-2'>
                            <button className='btn btn-primary'>
                                <i className='fas fa-search'></i>
                            </button>
                        </div>
                    </div>
                    {searching && <Loader />}
                    {searchResults && searchSuccess && (
                        <div>
                            <table className='table'>
                                <tr>
                                    <td></td>
                                    <td>
                                        <img
                                            alt={
                                                searchResults.Nombres
                                            }
                                            src={`data:image/jpeg;base64,${searchResults.Imagen}`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre:</td>
                                    <td>{searchResults.Nombres}</td>
                                </tr>
                                <tr>
                                    <td>Apellido:</td>
                                    <td>
                                        {searchResults.Apellido1}{" "}
                                        {searchResults.Apellido2}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Fecha Nacimiento:</td>
                                    <td>
                                        {
                                            searchResults.FechaNacimiento
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Cédula:</td>
                                    <td>{searchResults.Cedula}</td>
                                </tr>
                                <tr>
                                    <td>Dirección:</td>
                                    <td>
                                        {searchResults.Municipio},{" "}
                                        {searchResults.Provincia}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    )}
                    {searchSuccess === false && (
                        <div>
                            No hay resultados para esta cédula.
                        </div>
                    )}
                </div>
            </form>
        </div>
        <div className='popup__footer'>
            <button
                type='button'
                onClick={toggleSearchModal}
                className='btn btn-plain'>
                Cancelar
            </button>
            <button
                type='submit'
                onClick={insertarTutor}
                className='btn btn-primary'>
                Insertar
            </button>
        </div>
    </>);
}

export default SearchCedula;
