import React from "react";
import dayjs from "dayjs";
import Select from "react-select";
import SelectAsync from "react-select/async";
import { getCategoriasForDropdown, getLink, toast } from "../../utils/helpers";

const EstadosCuentaForm = ({toggle, numeraciones, clientes}) => {
    const validate = (event) => {
        const form = new FormData(event.target);

        if (!form.get("contactos")) {
            event.preventDefault();
            toast("Debes seleccionar al menos un cliente.", "error");
        } else {
            toggle();
        }
    };

    return (
        <form
            method='get'
            target='_blank'
            action={getLink(`/imprimir/estados-cuenta`)}
            onSubmit={validate}>
            <div className='popup__header'>Generar Estados de Cuentas</div>
            <div className='popup__body'>
                <div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Desde:</label>
                                <input
                                    type='date'
                                    name='desde'
                                    id='desde'
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Hasta:</label>
                                <input
                                    type='date'
                                    name='hasta'
                                    defaultValue={dayjs().endOf('month')
                                        .format("YYYY-MM-DD")}
                                    id='hasta'
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label
                                    style={{ fontSize: "12px", textTransform: "uppercase" }}
                                >
                                    Numeración
                                </label>
                                <Select
                                    name="numeracion[]"
                                    isClearable
                                    menuPosition="fixed"
                                    isMulti
                                    options={[{label: 'Todos', value: ''}].concat(numeraciones?.map(numeracion => {
                                        return {
                                            label: numeracion.nombre,
                                            value: numeracion.id,
                                        }
                                    }))}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label
                                    style={{ fontSize: "12px", textTransform: "uppercase" }}
                                >
                                    Categoría
                                </label>
                                <SelectAsync
                                    name="categoria[]"
                                    isMulti
                                    menuPosition="fixed"
                                    isClearable
                                    placeholder="Todas"
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={() => getCategoriasForDropdown()}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            height: "38px",
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <input
                            type='hidden'
                            name='contactos'
                            value={clientes.id}
                        />
                    </div>
                </div>
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Atrás
                </button>
                <button type='submit' className='btn btn-primary'>
                    Generar
                </button>
            </div>
        </form>
    );
}

export default EstadosCuentaForm;
