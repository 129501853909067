import React from "react";
import PageHeader from "../../general/PageHeader";
import Form from "./Components/CotizacionForm";

const CreateCotizacion = () => {
	return (
		<>
			<PageHeader title="Nueva Cotizacion" hasButton={false}/>
			<Form cotizacionActual={{}} isEdit={false} />
		</>
	);
};

export default CreateCotizacion;
