import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency, getMoneda } from "../../../utils/helpers";

class PrintCuentas extends Component {
    state = {
        cuentas: [],
    };
    componentDidMount() {
        this.getCuentas();
    }
    getCuentas = async () => {
        const response = await this.props.request(
            "cuentas" + window.location.search
        );

        if (response.data) {
            this.setState({
                cuentas: response.data,
            });
            window.print();
        }
    };
    render() {
        const { cuentas } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const totalBalancePesos = cuentas.filter(c => c.moneda === 'DOP').reduce((acc, cuenta) => {
            return acc + parseFloat(cuenta.balance);
        }, 0);

        const totalBalanceDolares = cuentas.filter(c => c.moneda === 'USD').reduce((acc, cuenta) => {
            return acc + parseFloat(cuenta.balance);
        }, 0);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Cuentas
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right m-b-10'>
                        <div className='float-left'>
                            CANT. CUENTAS: {cuentas.length}
                        </div>

                        <div className='float-right'>
                            TOTAL PESOS: <strong>{formatCurrency(totalBalancePesos)}</strong>
                            <br />
                            TOTAL DÓLARES: <strong>{formatCurrency(totalBalanceDolares, 'USD')}</strong>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ width: 120 }}>Moneda</th>
                            <th>Nombre</th>
                            <th>Tipo de Cuenta</th>
                            <th>Banco</th>
                            <th># Cuenta</th>
                            <th className='text-right'>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cuentas.map((cuenta) => {
                            return (
                                <tr key={cuenta.id}>
                                    <td>{getMoneda(cuenta.moneda)}</td>
                                    <td>
                                        {cuenta.nombre}
                                    </td>
                                    <td>{cuenta.tipo}</td>
                                    <td>{cuenta.banco}</td>
                                    <td>{cuenta.numeroCuenta}</td>
                                    <td className='text-right'>
                                        {formatCurrency(
                                            cuenta.balance, cuenta.moneda
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='row m-t-45'>
                    <div className='col-md-12 text-right m-b-10'>
                        <div className='float-left'>
                            CANT. CUENTAS: {cuentas.length}
                        </div>

                        <div className='float-right'>
                            TOTAL PESOS: <strong>{formatCurrency(totalBalancePesos)}</strong>
                            <br />
                            TOTAL DÓLARES: <strong>{formatCurrency(totalBalanceDolares, 'USD')}</strong>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintCuentas));
