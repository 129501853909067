import React from "react";

import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { getLink } from "../../utils/helpers";

export default function SegurosPorVencer({ seguros }) {
    if (seguros.length === 0) {
        return <div>¡Muy bien! No hay seguros por vencer.</div>;
    }

    return (
        <table className='table table-top-campaign'>
            <tbody>
                <tr>
                    <td style={{ width: "25px" }}></td>
                    <td>Ficha</td>
                    <td>Categoría</td>
                    <td>Marca/Modelo</td>
                    <td>Chasis</td>
                    <td>Año</td>
                    <td>Placa</td>
                    <td  style={{ minWidth: "85px" }}>Expira</td>
                </tr>
                {seguros.map((vehiculo, index) => (
                    <tr
                        className={vehiculo.seguroExpirado ? "expirado" : ""}
                        key={vehiculo.ficha}>
                        <td style={{fontWeight: "bold"}}>{index + 1}.</td>
                        <td>
                            <Link
                                to={getLink(
                                    `/vehiculos/${vehiculo.id}/informacion`
                                )}>
                                {vehiculo.ficha}
                            </Link>
                        </td>
                        <td>{vehiculo.categoria}</td>
                        <td>{vehiculo.modelo}</td>
                        <td>{vehiculo.chasis}</td>
                        <td>{vehiculo.ano}</td>
                        <td>{vehiculo.placa}</td>
                        <td>
                            {dayjs(vehiculo.seguroExpira).format("DD-MM-YYYY")}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
