import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import SelectCreate from "react-select/creatable";
import PageHeader from "../../../general/PageHeader";
import { auth } from "../../../../utils/auth";
import dayjs from "dayjs";
import { isVendedor } from "../../../../utils/helpers";
import { clientLabel } from "../../Components/utils";

const Detalles = ({
	form,
	contactoInfo,
	dropdownOptions,
	toggleCreateContactoModal,
	toggleEditContactoModal,
	handleInputChange,
	getComprobanteData,
	aplicarLimiteFecha,
	handleDetalleChange,
	isComprobanteFiscal,
	isEdit,
    changeContactInfo,
    contactoId,
    comprobante,
    plazo,
    changePlazo,
    errors,
    setContactoId,
}) => {
	return (
		<>
			{plazo && (
                <input
                    type="hidden"
                    name="tipo"
                    value={
                        plazo?.dias > 0
                            ? "credito"
                            : "contado"
                    }
                />
            )}
			{!isEdit && (
				<PageHeader title="Nueva Factura" hasButton={false}>
					<div className="col-7 col-sm-4 offsett-8">
						<div
							className="form-group"
							style={{ marginBottom: "0.3rem" }}
						>
							<div className="col-lg-9 pl-0">
								<select
									name="numeracion_id"
									id="comprobante"
									onChange={(e) => {
										getComprobanteData(e.target.value);
									}}
                                    value={comprobante?.id}
								>
									<option value="">
										Seleccione Comprobante
									</option>
									{dropdownOptions.comprobantes?.map(
										(comprobante) => (
											<option
												value={comprobante.value}
												key={comprobante.value}
											>
												{comprobante.label}
											</option>
										)
									)}
								</select>
								{errors.numeracion_id && (
									<small className="help-blockParams form-Text">
										{errors.numeracion_id[0]}
									</small>
								)}
							</div>
						</div>
					</div>
				</PageHeader>
			)}
			{isEdit && <PageHeader title="Editar Factura" hasButton={false} />}

			<div className="row">
				{/* PRIMERA COLUMNA EN MOVIL */}
				<div className="col-12 col-lg-4 forMobileShow">
					{!isEdit ? (
						<div
							className="form-group"
							style={{ marginBottom: "0.3rem" }}
						>
							<label>No.</label>
							<div className="row">
								<div className="col-3 pr-2">
									<input
										type="text"
										className="form-control"
										value={comprobante.prefijo || ""}
										readOnly
									/>
								</div>
								<div className="col-9 pl-0">
									<input
										type="text"
										className="form-control"
										value={
											comprobante.numero_siguiente || ""
										}
										readOnly
									/>
									{comprobante.prefijo && (
										<input
											type="hidden"
											name="numero_comprobante"
											value={`${comprobante.prefijo}${comprobante.numero_siguiente}`}
										/>
									)}
								</div>
							</div>
							{errors.numero_comprobante && (
								<small className="help-blockParams form-Text">
									{errors.numero_comprobante[0]}
								</small>
							)}
						</div>
					) : (
						<div className="row">
							<div
								className="form-group col-12"
								style={{ marginBottom: "0.3rem" }}
							>
								<label>NO.</label>
								<input
									type="text"
									className="form-control"
									value={`${comprobante.prefijo}${comprobante.numero_siguiente}`}
									onChange={() => {}}
									readOnly={true}
								/>
								{errors.fecha_vencimiento && (
									<small className="help-blockParams form-Text">
										{errors.fecha_vencimiento[0]}
									</small>
								)}
							</div>
						</div>
					)}

					<div className="row">
						<div
							className="form-group col-12"
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="fecha">Fecha</label>
							<input
								type="date"
								className="form-control"
								id="fecha"
								name="fecha"
								onChange={(e) =>
									handleInputChange(e.target.value, "fecha")
								}
								value={form.fecha}
							/>
							{errors.fecha && (
								<small className="help-blockParams form-Text">
									{errors.fecha[0]}
								</small>
							)}
						</div>

					</div>

				</div>
				{/* PRIMERA COLUMNA */}
				<div className="col-12 col-lg-4 d-inline-block">
					<div
						className="form-group"
						style={{ marginBottom: "0.3rem" }}
					>
						<label htmlFor="inputContacto" className="">
							Cliente
						</label>
						<div className="row">
							<Select
								id="inputContacto"
								options={dropdownOptions.contactos}
								name="contacto_id"
								onChange={(e) =>{
									setContactoId(e.value);
                                    changeContactInfo(e.value);
								}}
								value={
									contactoId &&
									dropdownOptions.contactos.find(
										(contacto) =>
											contacto.value === contactoId
									)
								}
								className="col-10 pr-0"
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
									singleValue: (provided, state) => ({
										...provided,
										display: "flex",
										width: "100%",
									}),
								}}
								formatOptionLabel={(option) =>
									clientLabel(option)
								}
								// isDisabled={isEdit}
							/>
							{!isEdit && !isVendedor() && (
								<div
									className="tooltip-self color-primary d-inline-block ml-2"
									style={{ alignSelf: "center" }}
								>
									<i
										className="fas fa-plus-circle color-primary"
										style={{
											fontSize: "1.25rem",
											cursor: "pointer",
										}}
										onClick={toggleCreateContactoModal}
									></i>
									<span className="tootip-text tooltip-right">
										Crear Contacto
									</span>
								</div>
							)}
							{contactoId && !isVendedor() && (
								<div
									className="tooltip-self color-primary d-inline-block ml-2"
									style={{ alignSelf: "center" }}
								>
									<i
										className="fas fa-edit color-primary"
										style={{
											fontSize: "1.25rem",
											cursor: "pointer",
										}}
										onClick={toggleEditContactoModal}
									></i>
									<span className="tootip-text tooltip-right">
										Editar Contacto
									</span>
								</div>
							)}
						</div>
						{errors.contacto_id && (
							<small className="help-blockParams form-Text">
								{errors.contacto_id[0]}
							</small>
						)}
					</div>
					<div
						className={`row ${isVendedor() && "d-none d-lg-flex"}`}
					>
						<div
							className="form-group col-6 pr-0"
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="inputRnc">RNC o cédula</label>
							<input
								type="text"
								className="form-control"
								id="inputRnc"
								value={contactoInfo.identificacion}
								readOnly
							/>
						</div>
						<div
							className="form-group col-6"
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="inputTelefono">Teléfono</label>
							<input
								type="text"
								className="form-control"
								id="inputTelefono"
								value={contactoInfo?.telefono}
								readOnly
							/>
						</div>
					</div>
					<div
						className={`form-group ${
							isVendedor() && "d-none d-lg-block"
						}`}
						style={{ marginBottom: "0.3rem" }}
					>
						<label htmlFor="inputDireccion">Dirección</label>
						<input
							type="text"
							className="form-control"
							id="inputDireccion"
							value={contactoInfo?.direccion}
							readOnly
						/>
					</div>
				</div>

				{/* SEGUNDA COLUMNA */}
				<div className="col-12 col-lg-4 d-inline-block">
					<div className="row">
						<div
							className="form-group col-6 pr-0"
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="inputEncargado">Encargado</label>
							<input
								type="text"
								className="text-input form-control"
								id="inputEncargado"
								value={contactoInfo.encargado}
								readOnly
							/>
						</div>
						<div
							className="form-group col-6"
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="inputCelular">Celular</label>
							<input
								type="text"
								className="form-control"
								id="inputCelular"
								value={contactoInfo.celular}
								readOnly
							/>
						</div>
					</div>
					<div
						className={`form-group forMobileHidden ${
							isVendedor() && "d-none d-lg-block"
						}`}
						style={{ marginBottom: "0.3rem" }}
					>
						<label htmlFor="inputVendedor">Vendedor</label>
						<Select
							id="inputVendedor"
							options={dropdownOptions.vendedores}
							name="empleado_id"
							value={contactoInfo.vendedor || {value: "", label: ""}}
							styles={{
								control: (styles) => ({
									...styles,
									height: "38px",
								}),
							}}
							onChange={(e) => handleDetalleChange(e, "vendedor")}
							isDisabled={isVendedor()}
						/>
						{isVendedor() && (
							<input
								type="hidden"
								name="empleado_id"
								value={auth.getUser().empleado?.id}
							/>
						)}
						{errors.empleado_id && (
							<small className="help-blockParams form-Text">
								{errors.empleado_id[0]}
							</small>
						)}
					</div>
					<div className="row forMobileHidden" style={{ marginBottom: "0.3rem" }}>
						<div
							className={`form-group col-12 col-sm-6 pr-sm-0 ${
								isVendedor() && "d-none d-lg-block"
							}`}
						>
							<label htmlFor="inputRuta">Zona</label>
							<Select
								id="inputRuta"
								options={dropdownOptions.rutas}
								name="ruta_id"
								value={contactoInfo.ruta}
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
								}}
								onChange={(e) => handleDetalleChange(e, "ruta")}
								isDisabled={isVendedor()}
							/>
							{errors.ruta_id && (
								<small className="help-blockParams form-Text">
									{errors.ruta_id[0]}
								</small>
							)}
						</div>
						<div
							className={`form-group ${
								isVendedor()
									? "col-12 col-lg-6"
									: "col-12 col-md-6"
							}`}
						>
							<label htmlFor="inputRuta">Categorías</label>
							<SelectCreate
								isMulti
								isClearable
								options={dropdownOptions.categorias}
								value={form.categorias}
								onChange={(values) =>
									handleInputChange(values, "categorias")
								}
							/>
							<input
								type="hidden"
								name="categorias"
								value={form.categorias
									?.map((c) => c.label)
									.join(",")}
							/>
							{isVendedor() && (
								<>
									<input
										type="hidden"
										name="empleado_id"
										value={auth.getUser().empleado?.id}
									/>{" "}
									<input
										type="hidden"
										name="ruta_id"
										value={contactoInfo.ruta?.value}
									/>
								</>
							)}
						</div>
					</div>
					<div className="row forMobileShowPlazo">
						<div
							className={`form-group ${
								plazo.dias === 0 ? "col-12" : "col-4 pr-0"
							}`}
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="inputPlazo" className="">
								Plazo de pago
							</label>
							<Select
								id="inputPlazo"
								options={
									contactoInfo.plazo
										? dropdownOptions.terminos.filter(
												(termino) => {
													if (
														termino.dias <=
														contactoInfo.plazo
															?.dias
													) {
														return termino;
													}
												}
										  )
										: []
								}
								name="plazo_id"
								value={plazo}
								onChange={(e) => {changePlazo(e)}}
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
								}}
							/>
							{errors.plazo_id && (
								<small className="help-blockParams form-Text">
									{errors.plazo_id[0]}
								</small>
							)}
						</div>
						{plazo.dias !== 0 && (
							<>
								<div
									className="form-group col-4 pr-0"
									style={{ marginBottom: "0.3rem" }}
								>
									<label
										htmlFor="credito_aprobado"
										className=""
									>
										Credito aprobado
									</label>
									<input
										type="text"
										className="form-control"
										id="credito_aprobado"
										value={contactoInfo.limite_credito}
										readOnly
									/>
								</div>
								<div
									className="form-group col-4 pr-0"
									style={{ marginBottom: "0.3rem" }}
								>
									<label
										htmlFor="credito_disponible"
										className=""
									>
										Credito disponible
									</label>
									<input
										className="form-control"
										id="credito_disponible"
										name="credito_disponible"
										value={
											contactoInfo.credito_disponible ||
											0
										}
										readOnly
									/>
								</div>
							</>
						)}
					</div>
				</div>

				{/* TERCERA COLUMNA */}
				<div className="col-12 col-lg-4 forMobileHidden">
					{!isEdit ? (
						<div
							className="form-group"
							style={{ marginBottom: "0.3rem" }}
						>
							<label>No.</label>
							<div className="row">
								<div className="col-2 pr-2">
									<input
										type="text"
										className="form-control"
										value={comprobante.prefijo || ""}
										readOnly
									/>
								</div>
								<div className="col-10 pl-0">
									<input
										type="text"
										className="form-control"
										value={
											comprobante.numero_siguiente || ""
										}
										readOnly
									/>
									{comprobante.prefijo && (
										<input
											type="hidden"
											name="numero_comprobante"
											value={`${comprobante.prefijo}${comprobante.numero_siguiente}`}
										/>
									)}
								</div>
							</div>
							{errors.numero_comprobante && (
								<small className="help-blockParams form-Text">
									{errors.numero_comprobante[0]}
								</small>
							)}
						</div>
					) : (
						<div className="row">
							<div
								className="form-group col-12"
								style={{ marginBottom: "0.3rem" }}
							>
								<label>NO.</label>
								<input
									type="text"
									className="form-control"
									value={`${comprobante.prefijo}${comprobante.numero_siguiente}`}
									onChange={() => {}}
									readOnly={true}
								/>
								{errors.fecha_vencimiento && (
									<small className="help-blockParams form-Text">
										{errors.fecha_vencimiento[0]}
									</small>
								)}
							</div>
						</div>
					)}

					<div className="row">
						<div
							className="form-group col-6 pr-0"
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="fecha">Fecha</label>
							<input
								type="date"
								className="form-control"
								id="fecha"
								name="fecha"
								onChange={(e) =>
									handleInputChange(e.target.value, "fecha")
								}
								value={form.fecha}
							/>
							{errors.fecha && (
								<small className="help-blockParams form-Text">
									{errors.fecha[0]}
								</small>
							)}
						</div>
						{isComprobanteFiscal() && (
							<div
								className={`form-group col-6`}
								style={{ marginBottom: "0.3rem" }}
							>
								<label>Vencimiento comprobante</label>
								<input
									type="date"
									className="form-control"
									value={comprobante?.fecha_vencimiento}
									onChange={(e) =>
										handleInputChange(
											e.target,
											"fecha_vencimiento"
										)
									}
									readOnly
								/>
								{errors.fecha_vencimiento && (
									<small className="help-blockParams form-Text">
										{errors.fecha_vencimiento[0]}
									</small>
								)}
							</div>
						)}
						<div
							className={`form-group col-6 ${
								!isComprobanteFiscal() ? "" : "d-none"
							}`}
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="fecha_vencimiento">
								Vencimiento
							</label>
							<input
								type="date"
								max={`${aplicarLimiteFecha()}`}
								min={`${dayjs().format("YYYY-MM-DD")}`}
								className="form-control"
								id="fecha_vencimiento"
								name="fecha_vencimiento"
								value={form.fecha_vencimiento}
								onChange={(e) =>
									handleInputChange(
										e.target.value,
										"fecha_vencimiento"
									)
								}
								readOnly={plazo.dias !== null}
							/>
							{errors.fecha_vencimiento && (
								<small className="help-blockParams form-Text">
									{errors.fecha_vencimiento[0]}
								</small>
							)}
						</div>
					</div>
					<div className="row">
						<div
							className={`form-group ${
								plazo.dias === 0 ? "col-12" : "col-4 pr-0"
							}`}
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="inputPlazo" className="">
								Plazo de pago
							</label>
							<Select
								id="inputPlazo"
								options={
									contactoInfo.plazo
										? dropdownOptions.terminos.filter(
												(termino) => {
													if (
														termino.dias <=
														contactoInfo.plazo
															?.dias
													) {
														return termino;
													}
												}
										  )
										: []
								}
								name="plazo_id"
								value={plazo}
								onChange={(e) => {changePlazo(e)}}
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
								}}
							/>
							{errors.plazo_id && (
								<small className="help-blockParams form-Text">
									{errors.plazo_id[0]}
								</small>
							)}
						</div>
						{plazo.dias !== 0 && (
							<>
								<div
									className="form-group col-4 pr-0"
									style={{ marginBottom: "0.3rem" }}
								>
									<label
										htmlFor="credito_aprobado"
										className=""
									>
										Credito aprobado
									</label>
									<input
										type="text"
										className="form-control"
										id="credito_aprobado"
										value={contactoInfo.limite_credito}
										readOnly
									/>
								</div>
								<div
									className="form-group col-4 pr-0"
									style={{ marginBottom: "0.3rem" }}
								>
									<label
										htmlFor="credito_disponible"
										className=""
									>
										Credito disponible
									</label>
									<input
										className="form-control"
										id="credito_disponible"
										name="credito_disponible"
										value={
											contactoInfo.credito_disponible ||
											0
										}
										readOnly
									/>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

Detalles.propTypes = {
	dropdownOptions: PropTypes.object,
	contactoInfo: PropTypes.object,
	form: PropTypes.object,
	handleInputChange: PropTypes.func,
	getComprobanteData: PropTypes.func,
	aplicarLimiteFecha: PropTypes.func,
	handleDetalleChange: PropTypes.func,
	isEdit: PropTypes.bool,
	isRecovery: PropTypes.bool,
};

export default React.memo(Detalles);
