import React, { Component } from "react";

import { debounce } from "lodash";
import Select from "react-select";

import { withRequest } from "../../../utils/request";
import { formatCurrency, getLink } from "../../../utils/helpers";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../../general/Loader";
import { Link } from "react-router-dom";

class Tipos extends Component {
    state = {
        tipos: {},
        grupos: [],
        tipo: {},
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        loading: true,
    };
    componentDidMount() {
        this.getTipos();
        this.getGruposTipos();
    }
    getTipos = async (url = null) => {
        url = url ? url + "&limit=15" : "tipos?limit=15";
        const tipos = await this.props.request(url);

        if (tipos.links) {
            this.setState({
                tipos: tipos.data,
                links: tipos.links,
                meta: tipos.meta,
                loading: false,
            });
        }
    };
    getGruposTipos = async (url = null) => {
        url = url ? url + "&limit=15" : "grupos-tipos-empleados";
        const grupos = await this.props.request(url);

        if (grupos.data.length > 0) {
            this.setState({
                grupos: grupos.data,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            tipo: {},
        });
    };
    toggleEditModal = (tipo) => {
        this.setState({
            showEdit: !this.state.showEdit,
            tipo: tipo,
        });
    };

    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getTipos("tipos?limit=15&" + query.join("&"));
    }, 500);

    render() {
        const { tipos, grupos, loading } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/configuracion/')}>&laquo; Volver a configuración</Link>
                    <h1 className='page-title row'>
                        <span className='col'>Tipos de Empleados</span>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Tipo de Empleado
                        </button>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='search'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='periodo'>Periodo</label>
                                    <Select
                                        name='periodo'
                                        id='periodo'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "periodo"
                                            )
                                        }
                                        options={[
                                            {label: 'Todos', value: 'todos'},
                                            {label: 'Semanal', value: 'semanal'},
                                            {label: 'Quincenal', value: 'quincenal'},
                                            {label: 'Mensual', value: 'mensual'},
                                        ]}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='grupo'>Grupos</label>
                                    <Select
                                        name='grupo'
                                        id='grupo'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "grupo"
                                            )
                                        }
                                        options={[
                                            {value: 'todos', label: 'Todos'},
                                            ...grupos.map(grupo => ({
                                                label: grupo.nombre,
                                                value: grupo.id,
                                            }))
                                        ]}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!tipos.length ? (
                                <div>
                                    {!loading && (
                                        <div>
                                            No hay ningún tipo de empleado
                                            creado.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Sueldo Fijo</th>
                                                <th>Descuento</th>
                                                <th className="text-center">Maneja</th>
                                                <th>Tipo</th>
                                                <th>Período</th>
                                                <th className='text-center'>
                                                    Empleados
                                                </th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tipos.map((tipo) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={tipo.id}>
                                                    <td>{tipo.nombre}</td>
                                                    <td>
                                                        {formatCurrency(
                                                            tipo.sueldoFijo
                                                        )}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(
                                                            tipo.descuento
                                                        )}
                                                    </td>
                                                    <td className="text-center">{tipo.operaVehiculo ? 'Sí' : 'No'}</td>
                                                    <td>{tipo.tipoNomina}</td>
                                                    <td>{tipo.intervaloNomina}</td>
                                                    <td className='text-center'>
                                                        {tipo.empleados}
                                                    </td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <button
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Edit'
                                                                onClick={() => {
                                                                    this.toggleEditModal(
                                                                        tipo
                                                                    );
                                                                }}>
                                                                <i className='zmdi zmdi-edit' />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getTipos}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        tipo={this.state.tipo}
                        getTipos={this.getTipos}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        tipo={this.state.tipo}
                        getTipos={this.getTipos}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Tipos);
