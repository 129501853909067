import React, { Component } from "react";
import Select from "react-select";
import SelectCreate from "react-select/creatable";

import { formatCurrency, formatNumber, money, toast } from "../../utils/helpers";

export default class ProductoCompra extends Component {
    state = {
        cantidad: 1,
        costo: "",
        viejoCosto: 0,
        precio: 0,
        descuento: 0,
        costoMin: 0.01,
        subtotal: 0,
        disponible: 0,
        impuesto: 0,
        referencia: null,
        impuesto_id: null,
        precios: [],
        grupo: null,
        valid: true,
        formaDeCalculo: 'total',
		total: 0,
        selected: {},
    };
    componentDidMount() {
        const {item} = this.props;
        if (item) {
            this.setState({
                formaDeCalculo: 'total',
                costo: item.costo || 0,
                total: item.total || 0,
                cantidad: item.cantidad,
                precio: item.total / item.cantidad,
                descuento: item.descuento || 0,
                isItem: item.isItem,
                referencia: item.referencia,
                disponible: item.item?.cantidad || 1,
                impuesto: item.impuesto ? item.impuesto?.porcentaje * 100 : 0,
                impuesto_id: item.impuesto?.id || 0,
                precios: item.precios || [{}],
                grupo: item.grupo,
                selected: item.nombre ? {
                    label: `${item?.referencia} - ${item?.nombre}`,
                    value: item.item_id
                } : null
            });
            setTimeout(() => {
                this.actualizarTotal(true);
            }, 1000);
        }
    }
    onChangeInput = async (name, value) => {
        if (name === 'descuento') {
            if (this.props.tipoDescuento === 'Porcentaje' && parseFloat(value) > 100) {
                toast('El descuento no puede ser major al 100%', 'error');
                return;
            }
            if (this.props.tipoDescuento === 'Monto Fijo' && parseFloat(value) > this.state.costo) {
                toast('El descuento no puede ser mayor al importe', 'error');
                return;
            }
        }

        await this.setState({
            [name]: value,
        });

        this.actualizarTotal();
    };
    onChangeImpuesto = async (value) => {
        const impuesto = this.props.impuestos.filter(i => i.id === value)[0];

        await this.setState({
            impuesto_id: impuesto.id,
            impuesto: impuesto.porcentaje ?? 0,
        });

        this.actualizarTotal();
    }
    onChangePreciosVenta = (id, value) => {
        this.setState(prevState => ({
            precios: prevState.precios.map(precio => {
                if (precio.id === id) {
                    precio.total = value;
                }
                return precio;
            }),
        }));
    };
    actualizarTotal = async (ignoreTotals = false) => {
        switch (this.state.formaDeCalculo) {
            case 'total':
                await this.calcularPorTotal();
                break;
            case 'precio':
                await this.calcularPorPrecio();
                break;
            case 'costo':
            default:
                await this.calcularPorCosto();
                break;
        }

        const { item } = this.props;
        const { cantidad, costo, impuesto, descuento, impuesto_id, formaDeCalculo } = this.state;
        let imp = this.props.impuestos.filter(i => i.id === impuesto_id)[0];

        if (!imp && item.impuesto) {
            imp = item.impuesto;
        }

        const subTotal = costo * cantidad;
        const totalDesc = this.calcularDescuento(subTotal, descuento, cantidad);
        const totalImp = this.calcularImpuesto(subTotal - totalDesc, impuesto);
        const total = subTotal - totalDesc + totalImp;

        if (formaDeCalculo !== 'total') {
            this.setState({total});
        }

        this.props.updateTotal(
            item.id,
            total,
            {nombre: imp?.nombre ?? 'Ninguno', totalImpuesto: totalImp},
            totalDesc,
            ignoreTotals
        );

        this.updatePrecios();
    }

    updatePrecios = () => {
        this.setState(prevState => ({
            precios: prevState.precios.map(precio => {
                const imp = prevState.impuesto > 1 ? prevState.impuesto / 100 : prevState.impuesto;
                if (precio.porciento_beneficio && prevState.viejoCosto < prevState.costo) {
                    precio.total = (parseFloat(prevState.costo) + parseFloat(prevState.costo) * parseFloat(precio.porciento_beneficio / 100)) * ((imp || 0) + 1);
                } else {
                    precio.total = (precio.precio_venta * ((imp || 0) + 1)).toFixed(2);
                }
                return precio;
            })
        }));
    }

    onChangeItem = async (itemId) => {
        let itemCompra = this.props.productos.filter((item) => item.id === itemId)[0];
        itemCompra = itemCompra ? itemCompra : {};

        await this.setState({
            id: itemCompra.id ? itemCompra.id : null,
            formaDeCalculo: 'total',
            nombre: itemCompra.nombre ? itemCompra.nombre : null,
            costo: itemCompra.costo || 0,
            precio: itemCompra.precio || 0,
            descuento: itemCompra.descuento || 0,
            viejoCosto: itemCompra.costo ? itemCompra.costo : null,
            cantidad: 1,
            referencia: itemCompra.referencia || '',
            impuesto: itemCompra.impuesto?.porcentaje || 0,
            impuesto_id: itemCompra.impuesto ? itemCompra.impuesto.id : 0,
            costoMin: itemCompra.min ? itemCompra.min : itemCompra.costo,
            disponible: itemCompra.cantidad ? itemCompra.cantidad : 0,
            precios: itemCompra.precios || [],
            grupo: itemCompra.grupo?.nombre,
            valid: itemCompra.cantidad > 0,
            total: itemCompra.total,
        });

        this.actualizarTotal();
    };
    addPrecio = () => {
        this.setState((prevState)=>({
            precios: [...prevState.precios, {id: Date.now()}]
        }));
    }
    removePrecio = (id) => {
        this.setState((prevState)=>({
            precios: prevState.precios.filter(p => p.id !== id)
        }));
    }
    toggleItem = () => {
        this.setState({
            isItem: !this.state.isItem,
        });
    };
    calcularTotal = (costo, cantidad, impuesto, descuento) => {
        const subTotal = costo * cantidad;
        const totalDescuento = this.calcularDescuento(subTotal, descuento, cantidad);
        const totalImpuesto = this.calcularImpuesto(subTotal, impuesto);

        return subTotal - totalDescuento + totalImpuesto;
    }
    calcularDescuento = (total, descuento, cantidad = 1) => {
        if (this.props.tipoDescuento === 'Porcentaje') {
            const porcentajeDesc = descuento ? descuento / 100 : 0;
            return total * porcentajeDesc;
        } else {
            return descuento * cantidad;
        }
    }
    calcularImpuesto = (total, impuesto) => {
        const p = impuesto < 1 ? impuesto : impuesto / 100;
        return total * p;
    }
    setFormaDeCalculo = (forma = 'costo') => {
        this.setState({
            formaDeCalculo: forma
        });
    }
    calcularPorCosto = async () => {
        const { costo, cantidad, impuesto, descuento } = this.state;

        const porcentajeImpuesto = parseFloat(impuesto > 1 ? (impuesto || 0) / 100 : impuesto);
        const desc = this.calcularDescuento(costo, descuento);
        const precio = (parseFloat(costo) - parseFloat(desc || 0)) * (porcentajeImpuesto + 1);
        const total = precio * cantidad;

        await this.setState({
            precio: precio,
            total: total
        });
    }
    calcularPorPrecio = async () => {
        const { precio, cantidad, impuesto, descuento } = this.state;

        const porcentajeImpuesto = parseFloat(impuesto > 1 ? (impuesto || 0) / 100 : impuesto);
        const total = precio * cantidad;
        const subcosto = precio / (porcentajeImpuesto + 1);
        const desc = this.calcularDescuento(subcosto, descuento);
        const costo = subcosto + parseFloat(desc || 0);

        await this.setState({
            costo: costo,
            total: total
        });
    }
    calcularPorTotal = async () => {
        const { total, cantidad, impuesto, descuento } = this.state;

        const porcentajeImpuesto = parseFloat(impuesto > 1 ? (impuesto || 0) / 100 : (impuesto || 0));
        const costo = (((total / cantidad)) / (porcentajeImpuesto + 1)) + parseFloat(descuento || 0);

        await this.setState({
            costo: costo,
            precio: total/cantidad
        });
    }
    render() {
        const { index, items, productos, item, impuestos, removeItem, almacen, tipoDescuento, display } = this.props;
        const {
            cantidad,
            costo,
            impuesto_id,
            precios,
            precio,
            descuento,
            total,
            formaDeCalculo,
            selected,
            viejoCosto,
            disponible
        } = this.state;

        // let total = this.calcularTotal(costo, cantidad, impuesto);
        let imp = impuesto_id ? impuestos.filter(i => i.id === impuesto_id)[0] : impuestos[0];

        if (!imp && item.impuesto) {
            imp = item.impuesto;
        }

        return (
            <tr>
                <td className='text-center'>
                    {index + 1}
                </td>
                <td>
                    <input type="hidden" name={`items_compra[p${index}][almacen_id]`} value={almacen || ''} />
                    <input type="hidden" name={`items_compra[p${index}][tipo]`} value={item.tipo ?? 'producto'} />
                    {item.item_id && (
                        <input type="hidden" name={`items_compra[p${index}][item_id]`} value={item.item_id} />
                        )}
                    {item.productoNuevo && (
                        <>
                            <input type="hidden" name={`items_compra[p${index}][referencia]`} value={item.productoNuevo?.referencia} />
                            <input type="hidden" name={`items_compra[p${index}][unidad]`} value={item.productoNuevo?.unidad?.value} />
                            <input type="hidden" name={`items_compra[p${index}][grupo_id]`} value={item.productoNuevo?.categoria?.value} />
                        </>
                    )}
                    <div className="d-flex">
                        <div style={{flex: 1}}>
                            <SelectCreate
                                options={productos && [...productos?.map(i => ({value: i.id, label: i.referencia ? `${i.referencia.padEnd(8, ' ')} - ${i.nombre}` : i.nombre})), selected || {}]}
                                isLoading={items && items[0] === false}
                                loadingMessage={() => "Cargando items..."}
                                value={selected}
                                onCreateOption={async label => {
                                    await this.props.editarProducto({nombre: label, impuesto: {}}, item.id);
                                    await this.setState({
                                        selected: {label, value: label},
                                        impuesto: { nombre: "Ninguno", porcentaje: 0, totalImpuesto: 0 }
                                    });
                                    this.onChangeItem(label);
                                }}
                                required
                                onChange={(e) => {
                                    if (!e) e = {};
                                    this.setState({selected: {label: e?.label, value: e?.value}})
                                    this.onChangeItem(e?.value);
                                }}
                                name={`items_compra[p${index}][item_id]`}
                                isClearable={true}
                            />
                            {viejoCosto > 0 && (
                                <div style={{with: '100%'}}>
                                    <small>
                                        Últ. Precio: {formatCurrency(parseFloat(viejoCosto) + this.calcularImpuesto(viejoCosto, imp.porcentaje))} | Displonible: {disponible}
                                    </small>
                                </div>
                            )}
                        </div>
                        {item?.productoNuevo?.nombre && (
                            <button type="button" className="btn" onClick={() => this.props.editarProducto(item.productoNuevo, item.id, true)}>
                                <i className="fa fa-pen"></i>
                            </button>
                        )}
                    </div>
                </td>
                <td>
                    <input
                        type='number'
                        name={`items_compra[p${index}][cantidad]`}
                        value={cantidad}
                        min='0.01'
                        onChange={(e) =>
                            this.onChangeInput("cantidad", e.target.value)
                        }
                        className='form-control text-right'
                        step='any'
                    />
                </td>
                <td onDoubleClick={() => this.setFormaDeCalculo('costo')} title="Doble Click para editar">
                    {formaDeCalculo === 'costo' ? (
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">RD$</span>
                            </div>
                            <input
                                type='number'
                                name={`items_compra[p${index}][costo]`}
                                value={costo || ''}
                                onChange={(e) =>
                                    this.onChangeInput("costo", e.target.value)
                                }
                                className='form-control'
                                step='any'
                            />
                        </div>
                    ) : (
                        <div className="m-t-5">
                            <input type="hidden" name={`items_compra[p${index}][costo]`} value={costo} />
                            {formatCurrency(costo)}<i className="fa fa-pen table-edit-field color-primary"></i>
                        </div>
                    )}
                </td>
                <td className='text-center'>
                    <Select
                        options={impuestos.map(i => ({
                            label: i.nombre,
                            value: i.id
                        }))}
                        value={imp && {
                            label: imp?.nombre,
                            value: imp?.id,
                        }}
                        onChange={(e) =>
                            this.onChangeImpuesto(e.value)
                        }
                    />
                    <input type="hidden" name={`items_compra[p${index}][impuesto_id]`} value={impuesto_id || ''} />
                </td>
                <td className='text-center'>
                    <div className="input-group">
                        <div className="input-group">
                            {tipoDescuento === 'Monto Fijo' && (
                                <div className="input-group-append">
                                    <span className="input-group-text" id="basic-addon1">RD$</span>
                                </div>
                            )}
                            <input
                                type='number'
                                name={`items_compra[p${index}][descuento]`}
                                value={descuento}
                                min="0"
                                onChange={(e) =>
                                    this.onChangeInput("descuento", e.target.value)
                                }
                                className='form-control'
                                step='any'
                            />
                            {tipoDescuento === 'Porcentaje' && (
                                <div className="input-group-append">
                                    <span className="input-group-text" id="basic-addon1">%</span>
                                </div>
                            )}
                        </div>
                    </div>
                </td>
                <td onDoubleClick={() => this.setFormaDeCalculo('precio')} title="Doble Click para editar">
                    {formaDeCalculo === 'precio' ? (
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">RD$</span>
                            </div>
                            <input
                                type='number'
                                name={`items_compra[p${index}][precio]`}
                                value={precio || ''}
                                onChange={(e) =>
                                    this.onChangeInput("precio", e.target.value)
                                }
                                className='form-control'
                                step='any'
                            />
                        </div>
                    ) : (
                        <div className="m-t-5">
                            <input type="hidden" name={`items_compra[p${index}][precio]`} value={precio} />
                            {formatCurrency(precio)}<i className="fa fa-pen table-edit-field color-primary"></i>
                        </div>
                    )}
                </td>
                <td
					className="text-right"
					onDoubleClick={() => this.setFormaDeCalculo('total')}
                    title="Doble Click para editar"
				>
					{formaDeCalculo === 'total' ? (
						<div className="input-group">
							<div className="input-group-prepend">
								<span
									className="input-group-text"
									id="basic-addon1"
								>
									RD$
								</span>
							</div>
							<input
								type="number"
								name={`items_compra[p${index}][total]`}
								value={total || 0}
								onChange={(e) =>
									this.onChangeInput("total", e.target.value)
								}
								className="form-control text-right"
								step="any"
							/>
						</div>
					) : (
						<div className="m-t-5">
                            {formatCurrency(total)}<i className="fa fa-pen table-edit-field color-primary"></i>
                        </div>
					)}
					<input
						type="hidden"
						name={`items_compra[p${index}][total]`}
						value={total || 0}
					/>
				</td>
                <td className='text-right'>
                    <div className="dropdown m-t-5">
                        <button className="dropdown-toggle" type="button"><small>PRECIOS ({precios.length})</small></button>
                        <div className="dropdown-menu dropdown-menu-right">
                            {precios.map((precio, i) => {
                                return (
                                <li key={precio.id || i} className="dropdown-item d-flex align-center">
                                    <div className="text-left p-r-15">#{i + 1}</div> <input type="number" name={`items_compra[p${index}][precios][${i}][total]`} value={precio.total} onChange={e => this.onChangePreciosVenta(precio.id, e.target.value)} className="form-control text-right" style={{minWidth: 150}} />
                                    <button type="button" className="p-l-10"><i className="fas fa-trash" onClick={() => this.removePrecio(precio.id)}></i></button>
                                    <input type="hidden" name={`items_compra[p${index}][precios][${i}][id]`} value={precio.id} />
                                    <input type="hidden" name={`items_compra[p${index}][precios][${i}][precio_venta]`} value={precio.precio_venta} />
                                    <input type="hidden" name={`items_compra[p${index}][precios][${i}][porciento_beneficio]`} value={precio.porciento_beneficio} />
                                </li>
                            )})}
                            <li className="dropdown-divider"></li>
                            <li className="dropdown-item text-right"><button type="button" onClick={this.addPrecio}>Agregar +</button></li>
                        </div>
                    </div>
                </td>
                <td>
                    <button
                        type='button'
                        className='btn'
                        onClick={() => removeItem(item.id)}>
                        <i className='fas fa-trash'></i>
                    </button>
                </td>
            </tr>
        );
    }
}
