/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
	formatCurrency,
	getSucursal,
	money,
	toast,
} from "../../../utils/helpers";
import { request } from "../../../utils/request";

const BalanceReport = () => {
	const { data, sucursal, queryParams } = useData();

    const utilidadBruta = data.totalVentasPeriodo - data.totalMenosCostoDeVenta;
    let utilidadBrutaProforma = data.totalProformas - data.totalMenosCostoDeVentaProforma;
    utilidadBrutaProforma = utilidadBrutaProforma + ((utilidadBrutaProforma * 0.18) / 2)
    const utilidadBrutaComprobante = data.totalFacturas - data.totalMenosCostoDeVentaComprobante;

	// TODO en el lado izquierdo hacer un resumen de las ventas de los vendedores.
	return (
		<>
<style>
    {`
        .balance-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 30px;
            align-items: start;
            max-width: 950px;
            margin: 0 auto;
        }
        table {
            margin-bottom: 15px;
            width: 100%;
            /* table-layout: fixed; */
        }
        .print-page table td {
            padding: 7px 15px;
        }
        .print-page table td:nth-child(3) {
            text-align: right;
        }
        .print-page table td:nth-child(2) {
            text-align: center;
        }
        fieldset {
            margin-bottom: 25px;
        }
        .print-page table .separate td {
            padding-top: 25px;
        }
        .print-page table .border-top {
            border-top: 2px solid #333;
        }
    `}
</style>
            {/* SUCURSAL INFO */}
            <div className="row mb-3">
                <h2 className="col-md-12 text-center m-b-5">
                    Reporte de balance de la compañía
                </h2>
                <div className="col-md-12 text-center">
                    {sucursal.nombre} - {sucursal.direccion} •{" "}
                    {queryParams.get("desde")
                        ? `${dayjs(queryParams.get("desde")).format(
                                "DD/MM/YYYY"
                            )} - `
                        : ""}{" "}
                    {dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
                </div>
            </div>
            <div className="balance-grid">
                {/* CUENTAS TOTALS */}
                <fieldset>
                    <legend>Balance Cuentas</legend>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    TOTAL CUENTAS X COBRAR:
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(data.totalCxC)}
                                </td>
                            </tr>
                            {/* INVENTARIO AL COSTO */}
                            <tr>
                                <td>
                                    INVENTARIO AL COSTO :
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(data.totalProductos)}
                                </td>
                            </tr>
                            {/* TOTAL CXC + VALOR INVENTARIO */}
                            <tr>
                                <td></td>
                                <td></td>
                                <td className="border-top">
                                    {formatCurrency(
                                        parseFloat(data.totalCxC) + parseFloat(data.totalProductos)
                                    )}
                                </td>
                            </tr>
                            <tr className="separate">
                                <td>
                                    TOTAL CUENTAS X PAGAR:
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(money(data.totalCxP))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>

                <fieldset>
                    <legend>Resumen Ventas</legend>
                    <table>
                        <tbody>
                            {/* VENTAS DEL PERIODO */}
                            <tr>
                                <td>
                                    VENTAS DEL PERIODO
                                </td>
                                <td>
                                    =
                                </td>
                                <td>
                                    {formatCurrency(
                                        data.totalVentasPeriodo
                                    )}
                                </td>
                            </tr>
                            {/* TOTAL ITBIS 16% */}
                            {data.totalITBIS16 > 0 && (
                                <tr>
                                    <td>
                                        ITBIS 16%
                                    </td>
                                    <td>=</td>
                                    <td>
                                        {formatCurrency(
                                            money(data.totalITBIS16)
                                        )}
                                    </td>
                                </tr>
                            )}
                            {/* TOTAL ITBIS 18% */}
                            {data.totalITBIS18 > 0 && (
                                <tr>
                                    <td>
                                        ITBIS 18%
                                    </td>
                                    <td>=</td>
                                    <td>
                                        {formatCurrency(
                                            data.totalITBIS18
                                        )}
                                    </td>
                                </tr>
                            )}
                            {/* TOTAL ITBIS */}
                            {data.totalITBIS > 0 && (
                                <tr>
                                    <td>
                                        TOTAL ITBIS
                                    </td>
                                    <td>=</td>
                                    <td>
                                        {formatCurrency(money(data.totalITBIS))}
                                    </td>
                                </tr>
                            )}
                            {/* MENOS COSTO DE VENTAS */}
                            <tr>
                                <td>
                                    MENOS COSTO DE VENTAS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        money(data.totalMenosCostoDeVenta)
                                    )}
                                </td>
                            </tr>
                            {/* UTILIDAD BRUTA */}
                            <tr>
                                <td>
                                    UTILIDAD BRUTA DEL PERIODO
                                </td>
                                <td>
                                    =
                                </td>
                                <td className="border-top">
                                    {formatCurrency(
                                        money(
                                            utilidadBruta
                                        )
                                    )}
                                </td>
                            </tr>
                                        {/* % de UTILIDAD/COSTO */}
                            <tr>
                                <td>
                                    % de UTILIDAD SOBRE LA VENTA
                                </td>
                                <td>
                                    =
                                </td>
                                <td>
                                    {money((utilidadBruta / data.totalVentasPeriodo) * 100
                                    )}
                                    %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>

                <fieldset>
                    <legend>Facturas</legend>
                    {/* FACTURAS */}
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    TOTAL FACTURADO
                                </td>
                                <td>
                                    =
                                </td>
                                <td>
                                    {formatCurrency(data.totalFacturas)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CANTIDAD
                                </td>
                                <td>=</td>
                                <td>
                                    {data.cantidadFacturas}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ITBIS 18%
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(data.facturaTotalITBIS18)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ITBIS 16%
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(data.facturaTotalITBIS16)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    TOTAL ITBIS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.facturaTotalITBIS18 +
                                            data.facturaTotalITBIS16
                                    )}
                                </td>
                            </tr>

                            {/* MENOS COSTO DE VENTAS */}
                            <tr className="separate">
                                <td>
                                    MENOS COSTO DE VENTAS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        money(data.totalMenosCostoDeVentaComprobante)
                                    )}
                                </td>
                            </tr>
                            {/* UTILIDAD BRUTA */}
                            <tr>
                                <td>
                                    UTILIDAD BRUTA DEL PERIODO
                                </td>
                                <td>
                                    =
                                </td>
                                <td className="border-top">
                                    {formatCurrency(
                                        money(
                                            utilidadBrutaComprobante
                                        )
                                    )}
                                </td>
                            </tr>
                                        <tr>
                                <td>
                                    % de UTILIDAD SOBRE LA VENTA
                                </td>
                                <td>
                                    =
                                </td>
                                <td>
                                    {money((utilidadBrutaComprobante / data.totalFacturas) * 100
                                    )}
                                    %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>

                <fieldset>
                    <legend>Proformas</legend>
                    {/* PROFORMAS */}
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    TOTAL FACTURADO
                                </td>
                                <td>
                                    =
                                </td>
                                <td>
                                    {formatCurrency(data.totalProformas)}
                                </td>
                            </tr>
                                        <tr>
                                <td>
                                    CANTIDAD
                                </td>
                                <td>=</td>
                                <td>
                                    {data.cantidadProformas}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ITBIS 18%
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(data.proformaTotalITBIS18)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ITBIS 16%
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(data.proformaTotalITBIS16)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    TOTAL ITBIS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.proformaTotalITBIS18 +
                                            data.proformaTotalITBIS16
                                    )}
                                </td>
                            </tr>
                                        {/* MENOS COSTO DE VENTAS */}
                            <tr className="separate">
                                <td>
                                    MENOS COSTO DE VENTAS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                                    data.totalMenosCostoDeVentaProforma
                                    )}
                                </td>
                            </tr>
                            {/* UTILIDAD BRUTA */}
                            <tr>
                                <td>
                                    UTILIDAD BRUTA DEL PERIODO
                                </td>
                                <td>
                                    =
                                </td>
                                <td className="border-top">
                                    {formatCurrency(
                                        money(
                                            utilidadBrutaProforma
                                        )
                                    )}
                                </td>
                            </tr>
                                        <tr>
                                <td>
                                    % de UTILIDAD SOBRE LA VENTA
                                </td>
                                <td>
                                    =
                                </td>
                                <td>
                                    {money((utilidadBrutaProforma / data.totalProformas) * 100
                                    )}
                                    %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>

                <fieldset>
                    <legend>Facturas Canceladas</legend>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    TOTAL
                                </td>
                                <td>
                                    =
                                </td>
                                <td>
                                    {formatCurrency(data.totalVentasCanceladas)}
                                </td>
                            </tr>
                                        <tr>
                                <td>
                                    CANTIDAD
                                </td>
                                <td>=</td>
                                <td>
                                    {data.cantidadCanceladas}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>

                {/* SEGUNDA SECCIÓN */}
                <fieldset>
                    <legend>Notas de Crédito</legend>
                    <table>
                        <tbody>
                            {/* DEVOLUCIONES/VENTAS */}
                            <tr>
                                <td>
                                    NOTAS DE CRÉDITOS VENTAS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        money(data.totalNotasCredito)
                                    )}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    NOTAS DE CRÉDITO COMPRAS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.totalComprasPeriodoCanceladas
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>

                {/* TERCERA SECCIÓN */}
                <fieldset>
                    <legend>Ingresos</legend>
                    <table>
                        <tbody>
                            {/* INGRESOS DEL PERIODO */}
                            <tr>
                                <td>
                                    TOTAL INGRESOS DEL PERIODO
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.totalIngresosPeriodo
                                    )}
                                </td>
                            </tr>

                            {/* DESCUENTOS EN FACTURACIÓN */}
                            <tr>
                                <td>
                                    DESCUENTOS EN FACTURACIÓN
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.totalDescuentosFacturacion
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>

                <fieldset>
                    <legend>Egresos</legend>
                    <table>
                        <tbody>
                            {/* PAGOS A SUPLIDORES */}
                            <tr>
                                <td>
                                    PAGOS A SUPLIDORES
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(data.totalPagosCompras)}
                                </td>
                            </tr>
                            {/* NOMINA */}
                            <tr>
                                <td>
                                    NOMINA
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(data.totalNomina)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>

                {/* CUARTA SECCIÓN */}
                <fieldset>
                    <legend>Compras</legend>
                    <table>
                        <tbody>
                            {/* COMPRAS DEL PERIODO */}
                            <tr>
                                <td>
                                    COMPRAS DEL PERIODO
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.totalComprasPeriodo
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ITBIS 18%
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.compraTotalITBIS18
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ITBIS 16%
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.compraTotalITBIS16
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    TOTAL ITBIS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.compraTotalITBIS16 + data.compraTotalITBIS18
                                    )}
                                </td>
                            </tr>
                            <br />
                            {/* COMPRAS PRODUCTOS */}
                            <tr>
                                <td>
                                    COMPRAS PRODUCTOS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.totalCompraProductos
                                    )}
                                </td>
                            </tr>
                            {/* COMPRAS ITEMS */}
                            <tr>
                                <td>
                                    COMPRAS INTERNAS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.totalCompraItems
                                    )}
                                </td>
                            </tr>
                            {/* COMPRAS SUMINISTROS */}
                            <tr>
                                <td>
                                    COMPRAS SUMINISTROS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.totalCompraSuministros
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>

                <fieldset>
                    <legend>Inventario Interno</legend>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    VALOR DE INVENTARIO INTERNO
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(
                                        data.totalInventarioInterno
                                    )}
                                </td>
                            </tr>
                            {/* GASTOS INTERNOS */}
                            <tr>
                                <td>
                                    GASTOS INTERNOS
                                </td>
                                <td>=</td>
                                <td>
                                    {formatCurrency(data.totalGastoInterno)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
            </div>
		</>
	);
};

const useData = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [sucursal, setSucursal] = useState({});
	const [data, setData] = useState({
		totalCxC: 0,
		totalProductos: 0,
		totalCxP: 0,
		totalPagosCompras: 0,
		totalComprasPeriodo: 0,
		totalComprasPeriodoCanceladas: 0,
		totalVentasCanceladas: 0,
		totalVentasPeriodo: 0,
		totalIngresosPeriodo: 0,
		totalDescuentosFacturacion: 0,
		totalMenosCostoDeVenta: 0,
		totalMenosCostoDeVentaProforma: 0,
		totalMenosCostoDeVentaComprobante: 0,
		totalITBIS18: 0,
		totalITBIS16: 0,
		totalFacturas: 0,
		totalProformas: 0,
	});

	useEffect(() => {
		let filterParams = "";
		if (queryParams.get("desde")) {
			filterParams += `?desde=${queryParams.get("desde")}`;
		}
		if (queryParams.get("hasta")) {
			filterParams += `&hasta=${queryParams.get("hasta")}`;
		}

		(async () => {
			const res = await request(`reportes/balance${location.search}`);

			if (!res.success) {
				toast("error obteniendo data", "error");
				return;
			}

			setData(res.data);
			/* setTimeout(() => {
				window.print();
			}, 1000); */
		})();

		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);
	}, []);

	return React.useMemo(
		() => ({ data, sucursal, queryParams }),
		[data, sucursal]
	);
};

export default BalanceReport;
