import React, { Component } from "react";

import Select from "react-select";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";

import Loader from "../../general/Loader";

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

class Create extends Component {
    state = {
        grupos: [],
        saving: false,
        miembros: [],
        errors: {},
    };
    createProgramacion = async (event) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl('gi-prestamos'), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.getPrestamos();
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false,
        });
    };
    onChangeGrupo = (e) => {
        this.setState({
            miembros: this.props.grupos.filter(g => g.id === e.value)[0].miembros,
        });
    };

    render = () => {
        const { toggle, grupos, miembrosIngresados, semana } = this.props;
        const { errors, miembros } = this.state;
        return (
            <div>
                <form onSubmit={this.createProgramacion}>
                    <input type="hidden" name="semana" value={semana.numero} />
                    <input type="hidden" name="desde" value={semana.desde} />
                    <input type="hidden" name="hasta" value={semana.hasta} />
                    <div className='popup__header'>Nuevo</div>
                    <div className='popup__body'>
                        <div className='form-group'>
                            <label className='label form-control-label'>Grupo</label>
                            <Select
                                name='grupo_id'
                                isClearable={true}
                                onChange={this.onChangeGrupo}
                                options={grupos
                                    .filter(g => g.active)
                                    .map((grupo) => ({
                                        value: grupo.id,
                                        label: `${grupo.alias} (${grupo.nombreCompleto})`,
                                    }))}
                            />
                            {errors.grupo_id && (
                                <small className='help-blockParams form-Text'>
                                    {errors.grupo_id[0]}
                                </small>
                            )}
                        </div>
                        {miembros.length > 0 && (
                            <div className='form-group'>
                                <label className='label form-control-label'>Miembro</label>
                                <Select
                                    name='miembro_id'
                                    isClearable={true}
                                    options={miembros
                                        .filter(m => !miembrosIngresados.includes(m.id) && m.estado === 'activo')
                                        .map((miembro) => ({
                                            value: miembro.id,
                                            label: `${miembro.nombre} - ${miembro.servidor}`,
                                        }))}
                                />
                                {errors.miembro_id && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.miembro_id[0]}
                                    </small>
                                )}
                            </div>
                        )}
                        <div className='form-group'>
                            <label className='label form-control-label'>Deuda Inicial</label>
                            <input
                                className='form-control'
                                type='number'
                                name='deuda_inicial'
                                step='any'
                            />
                            {errors.deuda_inicial && (
                                <small className='help-blockParams form-Text'>
                                    {errors.deuda_inicial[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            onClick={toggle}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {this.state.saving ? (
                            <Loader type='small' />
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Crear
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    };
}

export default withRequest(Create);
