import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { request } from "../../../utils/request";
import { apiRequest } from "../../../hooks/apiRequest";
import { getLink, toast } from "../../../utils/helpers";

export const EstudianteContext = createContext(null);

export const EstudianteContextProvider = ({children}) => {
    const context = useEstudiante();
    return (
        <EstudianteContext.Provider value={{...context}}>
            {children}
        </EstudianteContext.Provider>
    )
}

export const useEstudianteContext = () => {
    const context = useContext(EstudianteContext);

    if (!context) {
        throw new Error("useEstudianteContext debe ser utilizado dentro de EstudianteContextProvider")
    }

    return context;
}

export const useEstudiante = () => {
    const [errors, setErrors] = useState({});
    const [estudiante, setEstudiante] = useState({});
    const [evaluacion, setEvaluacion] = useState({});
    const [tipos, setTipos] = useState([]);
    const [codigo, setCodigo] = useState({});
    const [editando, setEditando] = useState('');
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showCreateTutorModal, setShowCreateTutorModal] = useState(false);
    const [showEditTutorModal, setShowEditTutorModal] = useState(false);
    const [showProcesarModal, setShowProcesarModal] = useState(false);
    const [processBtnSelected, setProcessBtnSelected] = useState("normal");
    const [montoPagar, setMontoPagar] = useState(0);
    const [grados, setGrados] = useState([]);
    const [secciones, setSecciones] = useState([])
    const [inscripcion, setInscripcion] = useState(estudiante.curso || {})
    const [gruposPagos, setGruposPagos] = useState([]);
    const [servicios, setServicios] = useState([]);
    const [periodosAcademicos, setPeriodosAcademicos] = useState([]);
    const [pagoEvaluacion, setPagoEvaluacion] = useState(0);

    const [pagoInfo, setPagoInfo] = useState({
		pago: 0,
		tipo_pago: "efectivo",
		cuenta: "",
		fecha: dayjs().format("YYYY-MM-DD"),
	});

    const [page, setPage] = useState(1);
    const { id } = useParams();

    const params = new URLSearchParams(window.location.search);

    const evaluacionId = params.get('evaluacion_id');
    const isInscripcion = params.get('inscripcion') === '1';
    const isReservaCupo = params.get('reservar-cupo') === '1';

    const procesarTransaccion = async (event) => {
        event.preventDefault();

        setSaving(true);

        const data = new FormData(event.target.form);

        apiRequest(data, "academico/facturacion/inscripcion", 'post').then(res => {
            if (res.success) {
                window.open(getLink(`/imprimir/recibos-ingreso/inscripcion/${res.data?.recibo.id}`));
            }
            if (res.code === 422) {
                if (typeof res.data === 'object') {
                    setErrors(res.data?.message || {});
                    toast('Favor revisar todos los campos.', 'error');
                } else {
                    toast(res.data, 'error');
                }
            }
            setSaving(false);
        });
    }

    const toggleModal = async (modal, data = false, id = false) => {
        switch (modal) {
            case "EditTutor":
                setShowEditTutorModal(prev => !prev);
                setEditando(id ? id : '');
                break;
            case "CreateTutor":
                setShowCreateTutorModal(prev => !prev);
            default:
                break;
        }


        if (data) {
            setEstudiante(prevState => {
                const tuts = prevState.tutores?.map((t, index) => {
                    console.log({t})
                    if (t.id === data.id || !t.id) {
                        data.key = t.key;
                        data.residencia = t.residencia;
                        return data;
                    }
                    return t;
                });
                return {...prevState, tutores: tuts ? tuts : [
                    {
                        ...data,
                        key: 0,
                    }
                ]}
            });
        }
    };

    const getEstudiante = () => {
        request(`academico/estudiantes/${id}${isReservaCupo ? '?reserva_cupo=1' : ''}`).then(res => {
            if (res.success) {
                setEstudiante(res.data);
                setLoading(false);
            }
        });
    };

    const onInputChange = (value, nombre) => {
        setEstudiante(prevState => ({
            ...prevState,
            [nombre]: value,
        }));
    };

    const getGruposPagos = async () => {
        const url = "configuraciones?seccion=academica_grupos-pagos";
        request(url).then((res) => {
            const gpagos = JSON.parse(res.data["grupos-pagos"]);
            setGruposPagos(
                Object.keys(gpagos).map(g => gpagos[g])
            );
		});
    }

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label], item: item}));
        }
    };

    const gradosRanking = grados.reduce((a,c) => {
        a[c.nombre] = c.orden;
        return a;
    }, {});

    const [tiposDocumentos, setTiposDocumentos] = useState(estudiante?.curso?.grado?.nivel?.documentos || []);

    const getServicios = async () => {
        const url = "academico/pagos-educativos";
        const response = await apiRequest({}, url, 'get');
        setServicios(response.data);
    }

    const servicio = isReservaCupo ? servicios?.find(s => s.nombre.includes('eserva')) : servicios?.find(s => s.nombre.includes('nscripci'));

    useEffect(()=> {
        const rank = gradosRanking[estudiante?.curso?.grado?.nombre];
        const gp = estudiante?.curso?.grupoPago?.includes('regular') ? 'regulares' : estudiante?.curso?.grupoPago || 'regulares';
        const grupo = servicio?.configuracion[gp];
        const precio = grupo?.find(c => {
            return gradosRanking[c.grado_desde] <= rank && gradosRanking[c.grado_hasta] >= rank;
        });
        setMontoPagar(precio?.monto);
    }, [gradosRanking, estudiante.curso?.grupoPago, servicios]);


    useEffect(() => {
      if (evaluacionId) {
        apiRequest({}, `academico/evaluaciones/${evaluacionId}`, 'get').then(({data}) => {
            setEstudiante(prev => ({
                ...prev,
                nombre: data.nombre,
                primerApellido: data.primerApellido,
                segundoApellido: data.segundoApellido,
                telefono: data.telefono,
                curso: {
                    grupoPago: 'regulares',
                    periodoAcademico: data.periodoAcademico,
                    grado: data.grado,
                    fechaInscripcion: dayjs().format("YYYY-MM-DD")
                }
            }));
            setEvaluacion(data);
            setPagoEvaluacion(evaluacion?.estudiante?.pagos?.find(p => p.nombre.includes('valuaci')).abonado || 0);
            setLoading(false);
        });
      }
    }, [grados, gruposPagos]);

    useEffect(() => {
        const grad = grados?.find(g => g.id === estudiante?.curso?.grado?.id);
        setTiposDocumentos(grad?.nivel?.documentos || [])
    }, [estudiante, grados]);

    useEffect(() => {
        if (id) {
            getEstudiante();
        }
        request('academico/grados').then((res) => {
            setGrados(res.data);
		});
        request('academico/secciones').then((res) => {
            setSecciones(res.data);
		});
        getGruposPagos();
        getServicios();
    }, []);

    return {
        estudiante,
        evaluacion,
        errors,
        tipos,
        toggleModal,
        codigo,
        tiposDocumentos,
        setTiposDocumentos,
        setEstudiante,
        getOptions,
        gruposPagos,
        inscripcion,
        setInscripcion,
        secciones,
        grados,
        servicios,
        page,
        montoPagar,
        pagoInfo,
        setPagoInfo,
        gradosRanking,
        periodosAcademicos,
        servicio,
        evaluacionId,
        showCreateTutorModal,
        showEditTutorModal,
        setShowEditTutorModal,
        showProcesarModal,
        setShowProcesarModal,
        processBtnSelected,
        setProcessBtnSelected,
        procesarTransaccion,
        saving,
        setSaving,
        loading,
        setLoading,
        editando,
        setEditando,
        setTipos,
        setErrors,
        setCodigo,
        setPage,
        setShowCreateTutorModal,
        setPeriodosAcademicos,
        onInputChange,
        isReservaCupo,
        isInscripcion,
        pagoEvaluacion,
    }
}

export const estadosEstudiantes = {
    "evaluacion": {
        label: "Pendiente (Ocultar)",
        value: "evaluacion"
    },
    "Evaluado": {
        label: "Evaluado",
        value: "Evaluado"
    },
    "Inactivo": {
        label: "Inactivo",
        value: "Inactivo"
    },
    "Retirado": {
        label: "Retirado",
        value: "Retirado"
    },
}
