import React from "react";
import dayjs from "dayjs";
import { formatCurrency } from "../../utils/helpers";
import Loader from "../general/Loader";

export default function View({ descuento }) {

    if (descuento === {}) {
        return <Loader />
    }

    return (
        <div>
            <h3>Detalles</h3>
            <table className='table m-b-20'>
                <tbody>
                    <tr>
                        <td>Fecha Solicitud:</td>
                        <td>{dayjs(descuento.fecha).format("YYYY-MM-DD")}</td>
                    </tr>
                    <tr>
                        <td>Empleado:</td>
                        <td>{descuento.empleado}</td>
                    </tr>
                    <tr>
                        <td>Cargo:</td>
                        <td>{descuento.cargo}</td>
                    </tr>
                    <tr>
                        <td>Telefono:</td>
                        <td>{descuento.telefono}</td>
                    </tr>
                    <tr>
                        <td>Monto:</td>
                        <td>{formatCurrency(descuento.montoTotal)}</td>
                    </tr>
                    {descuento.cuotas && <tr>
                        <td>Total Cuotas:</td>
                        <td>{descuento.cuotas.length}</td>
                    </tr>}
                    {descuento.cuotas && <tr>
                        <td>Monto Cuotas:</td>
                        <td>{formatCurrency(descuento.monto)}</td>
                    </tr>}
                    {descuento.cuotasPendientes && <tr>
                        <td>Cuotas Pendientes:</td>
                        <td>{descuento.cuotasPendientes.length}</td>
                    </tr>}
                    {descuento.cuotasCompletadas && <tr>
                        <td>Cuotas Completadas:</td>
                        <td>{descuento.cuotasCompletadas.length}</td>
                    </tr>}
                    <tr>
                        <td>Descripción:</td>
                        <td>{descuento.descripcion}</td>
                    </tr>
                </tbody>
            </table>

            {descuento.esPrestamo && <div>
                <h3>Cuotas Pendientes</h3>
                <table className='table m-b-20'>
                    <thead>
                        <tr>
                            <th>Monto</th>
                            <th className="text-right">Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                        {descuento.cuotasPendientes && descuento.cuotasPendientes.map(cuota => (
                            <tr>
                                <td>{formatCurrency(descuento.monto)}</td>
                                <td className="text-right">{dayjs(cuota.fecha).format("YYYY-MM-DD")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}

            {descuento.esPrestamo && <div>
                <h3>Cuotas Completadas</h3>
                <table className='table m-b-20'>
                    <thead>
                        <tr>
                            <th>Monto</th>
                            <th className="text-right">Fecha Aplicada</th>
                        </tr>
                    </thead>
                    <tbody>
                        {descuento.cuotasCompletadas.map(cuota => (
                            <tr>
                                <td>{formatCurrency(descuento.monto)}</td>
                                <td className="text-right">{dayjs(cuota.fecha).format("YYYY-MM-DD")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        </div>
    );
}
