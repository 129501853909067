import React, { useState } from 'react';
import { useEstudianteContext } from '../EstudianteContext';

export default function DocumentoItem({documento}) {
    const {estudiante} = useEstudianteContext();
    const [eliminar, setEliminar] = useState(false);
    const archivo = estudiante.documentos?.filter(e => e.nombre === documento.nombre).shift() || {};
    return (
        <tr>
            <td>
                {documento.nombre}
                <input type="hidden" name={`documentos[${documento.id}][nombre]`} value={documento.nombre} />
            </td>
            <td>
                {archivo?.url && !eliminar ? (
                    <>
                        <a href={archivo?.url} target="_blank">Ver</a>
                        <input type="hidden" name={`documentos[${documento.id}][archivo]`} value={archivo?.url} />
                    </>
                ) : (
                    <input type="file" name={`documentos[${documento.id}][archivo]`} />
                )}
            </td>
            <td className="text-right">
                {archivo && !eliminar && (
                    <button type="button" onClick={() => setEliminar(true) }>Eliminar</button>
                )}
            </td>
        </tr>
    );
}
