import React from "react";

const Pagination = ({ links, meta, getData }) => {
    return (
        <div className='pagination'>
            <div className='total'>
                Página {meta.current_page} de {meta.last_page} - Mostrando del{" "}
                {meta.from} al {meta.to} de <strong>{meta.total}</strong>
            </div>
            {meta.last_page > 1 && (
                <ul className='links'>
                    <li className='pagination__link prev'>
                        {links.prev ? (
                            <button onClick={() => getData(links.prev)}>
                                Anterior
                            </button>
                        ) : (
                            "Anterior"
                        )}
                    </li>
                    <li className='pagination__link next'>
                        {links.next ? (
                            <button onClick={() => getData(links.next)}>
                                Siguiente
                            </button>
                        ) : (
                            "Siguiente"
                        )}
                    </li>
                </ul>
            )}
        </div>
    );
};

export default Pagination;
