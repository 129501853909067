import React, { useEffect, useState } from 'react';
import TutorItem from './TutorItem';
import { useEstudianteContext } from '../EstudianteContext';

const Tutores = ({setTitular}) => {
    const {estudiante, setEstudiante, toggleModal, errors, setErrors } = useEstudianteContext();
    const [tutores, setTutores] = useState(estudiante?.tutores?.map((t, index) => {
        t.key = index;
        return t;
    }) || [{key: 0}]);

    const addTutor = () => {
        const tutoresNuevos = [
            ...tutores,
            {key: tutores.length}
        ];
        setTutores(tutoresNuevos);
        setEstudiante(prev => ({
            ...prev,
            tutores: tutoresNuevos,
        }));
        setErrors(prev => ({...prev, tutores: false}))
    }

    const removeTutor = (key) => {
        const tutoresNuevos = [
            ...tutores.filter(t => t.key !== key),
        ];
        setTutores(tutoresNuevos);
        setEstudiante(prev => ({
            ...prev,
            tutores: tutoresNuevos,
        }));
    }

    const setResidencia = (key, value, tutor) => {
        let newTutor = {};

        setTutores(prevState => {
            return prevState.map(t => {
                if (t.key === key) {
                    t.residencia = value;
                    newTutor = value ? tutor : {};
                } else {
                    t.residencia = false;
                }
                return t;
            });
        });

        setTitular(newTutor);
    }

    useEffect(() => {
        if (estudiante?.tutores?.length > 0) {
            setTutores(estudiante?.tutores?.map((t, index) => {
                t.key = index;
                return t;
            }));
            setTitular(tutores?.find((p,c) => p.residencia));
        }
    }, [estudiante.tutores]);

    useEffect(() => {
        if (tutores?.length > 0) {
            setTitular(tutores?.find((p,c) => p.residencia));
        } else {
            setTitular({});
        }
    }, [tutores, estudiante.tutores]);

    return (
        <section>
            <h3>Padres/Tutores</h3>
            <table className="table tickets-table">
                <thead>
                    <tr className="text-uppercase">
                        <td></td>
                        <td width="40%"><small><strong>Padre/Tutor</strong></small></td>
                        <td width="20%"><small><strong>Parentesco</strong></small></td>
                        <td className="text-center"><small><strong>Residencia</strong></small></td>
                        <td className="text-center"><small><strong>Notificar</strong></small></td>
                        <td className="text-center"><small><strong>NCF</strong></small></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {tutores.map((tutor) => (
                        <TutorItem
                            key={tutor.key}
                            index={tutor.key}
                            toggleModal={() => toggleModal('EditTutor')}
                            tutor={tutor}
                            tutores={tutores}
                            setTutores={setTutores}
                            removeTutor={removeTutor}
                            setResidencia={setResidencia}
                        />
                    ))}
                </tbody>
            </table>
            {(errors.tutores || errors["tutores.undefined.parentesco"]) && (
                <div class="alert alert-danger m-t-25" role="alert">
                    <small>Debes añadir al menos un tutor.</small>
                </div>
            )}
            <div className="text-right m-t-15">
                <button type="button" className="btn" onClick={addTutor}>
                    + Agregar Padre/Tutor
                </button>
            </div>
        </section>
    );
}

export default Tutores;
