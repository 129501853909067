import React, { useState } from 'react';
import Select from 'react-select/creatable';
import { toast, unidades } from '../../utils/helpers';

const CrearProductoModal = ({toggle, grupos, productoNuevo, crearProductoNuevo}) => {
    console.log({productoNuevo})
    const [state, setState] = useState({
        nombre: productoNuevo.nombre,
        referencia: productoNuevo.referencia,
        unidad: productoNuevo.unidad,
        categoria: productoNuevo.categoria,
    });

    const onChangeInput = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }

    const onClickGuardar = () => {
        if (!state.categoria || state.categoria === {}) {
            toast('Debes seleccionar una categoria', 'error');
            return;
        } else {
            crearProductoNuevo(state);
            toggle();
        }
    }

    return (
        <>
            <div className='popup__header'>Nuevo Producto</div>
            <div className="popup__body">
                <div className="row">
                    <div className="col-md-6">
                        <label>Nombre:</label>
                        <div>{state.nombre}</div>
                    </div>
                    <div className="col-md-6">
                        <label>Referencia/Código</label>
                        <input
                            type="text"
                            value={state.referencia}
                            onChange={(e) => onChangeInput('referencia', e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6">
                        <label>Unidad</label>
                        <Select
                            menuPosition='fixed'
                            menuPlacement='auto'
                            value={state.unidad}
                            onChange={(e) => onChangeInput('unidad', {
                                label: e.label,
                                value: e.value,
                            })}
                            options={unidades}
                        />
                    </div>
                    <div className="col-md-6">
                        <label>Categoría</label>
                        <Select
                            menuPosition='fixed'
                            menuPlacement='auto'
                            value={state.categoria}
                            onChange={(e) => onChangeInput('categoria', {
                                label: e.label,
                                value: e.value,
                            })}
                            options={grupos?.map(g => ({
                                label: g.nombre,
                                value: g.nombre
                            }))}
                        />
                    </div>
                </div>
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                <button type='button' onClick={onClickGuardar} className='btn btn-primary'>
                    Guardar
                </button>
            </div>
        </>
    )
}

export default CrearProductoModal;
