import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { withRequest } from "../../../../utils/request";
import { auth } from "../../../../utils/auth";
import { formatCurrency } from "../../../../utils/helpers";
import dayjs from "dayjs";

class Ausentes extends Component {
    state = {
        prestamos: [],
    };
    componentDidMount() {
        this.getPrestamos();
    }
    getPrestamos = async () => {
        const params = queryString.parse(window.location.search);

        console.log({params});

        const response = await this.props.request("gi-prestamos?ids=" + params.ids + '&semana=' + params.semana);

        if (response.data.length > 0) {
            this.setState({
                prestamos: response.data,
            });
        }
    };
    render() {
        const { prestamos } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Préstamos Grupos
                    </h2>
                    <div className='col-md-12 text-center m-b-20'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        Semana {dayjs(prestamos[0]?.desde).format('DD MMM YYYY')} - {dayjs(prestamos[0]?.hasta).format('DD MMM YYYY')}
                    </div>
                    <div className='col-md-12 p-b-10 row'>
                        <div className='col-md-4'>
                            <strong># Préstamos:</strong> {prestamos.length}
                        </div>
                        <div className='col-md-4'>
                            <strong>Deuda Inicial</strong>: {formatCurrency(prestamos.reduce((acc,p) => acc + p.deudaInicial, 0))}
                        </div>
                        <div className='col-md-4 text-right'>
                            <strong>Balance Deuda Inicial:</strong> {formatCurrency(prestamos.reduce((acc,p) => acc + p.deudaInicialP, 0))}
                        </div>
                    </div>
                    <div className='col-md-12 p-b-10 row m-b-20'>
                        <div className='col-md-4'>
                            <strong>Total Abonos:</strong> {formatCurrency(prestamos.reduce((acc,p) => acc + p.totalAbono, 0))}
                        </div>
                        <div className='col-md-4'>
                            <strong>Total Préstamos:</strong> {formatCurrency(prestamos.reduce((acc,p) => acc + p.totalPrestamo, 0))}
                        </div>
                        <div className='col-md-4 text-right'>
                            <strong>Total Adeudado:</strong> {formatCurrency(prestamos.reduce((acc,p) => acc + p.totalAdeudado, 0))}
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Miembro</th>
                            <th>Grupo</th>
                            <th className="text-right">Deuda Inicial</th>
                            <th className="text-right">Balance Deuda Inicial</th>
                            <th className="text-right">Total Abonos</th>
                            <th className="text-right">Total Prestamos</th>
                            <th className="text-right">Total Adeudado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prestamos &&
                            prestamos
                                .map((prestamo) => {
                                    return (
                                        <tr key={prestamo.codigo}>
                                            <td>
                                                {prestamo.miembro?.nombre}
                                                <div><small>{prestamo.miembro?.servidor}</small></div>
                                            </td>
                                            <td>
                                                {prestamo.grupo.nombre} {prestamo.grupo.alias}
                                                <div><small>{prestamo.grupo.direccion}</small></div>
                                            </td>
                                            <td className="text-right">{formatCurrency(prestamo.deudaInicial)}</td>
                                            <td className="text-right">{formatCurrency(prestamo.deudaInicialP)}</td>
                                            <td className="text-right">{formatCurrency(prestamo.totalAbono)}</td>
                                            <td className="text-right">{formatCurrency(prestamo.totalPrestamo)}</td>
                                            <td className="text-right">{formatCurrency(prestamo.totalAdeudado)}</td>
                                        </tr>
                                    );
                                })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(Ausentes));
