import React from "react";

import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { getLink } from "../../utils/helpers";

export default function LicenciasPorVencer({ licencias }) {
    if (licencias.length === 0) {
        return <div>¡Muy bien! No hay licencias por vencer.</div>;
    }

    return (
        <table className='table table-top-campaign'>
            <tbody>
                <tr>
                    <td></td>
                    <td>Nombre</td>
                    <td>Categoría</td>
                    <td>Teléfono</td>
                    <td>Flota</td>
                    <td>Fecha Exp.</td>
                </tr>
                {licencias.map((chofer, index) => (
                    <tr
                        className={chofer.licenciaExpirada ? "expirado" : ""}
                        key={chofer.codigo}>
                        <td style={{fontWeight: "bold"}}>{index + 1}.</td>
                        <td>
                            <Link
                                to={getLink(
                                    `/empleados/${chofer.id}/informacion`
                                )}>
                                {chofer.nombreCompleto}
                            </Link>
                        </td>
                        <td>{chofer.licencia}</td>
                        <td>{chofer.telefono}</td>
                        <td>{chofer.flota}</td>
                        <td>
                            {dayjs(chofer.licenciaExpira).format("DD-MM-YYYY")}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
