import React from "react";

const FiltersContainer = ({ children, extraFilters = false }) => {
	const displayFilters = () => {
		document.getElementById("mas-filtros").classList.toggle("d-none");
	};
	return (
		<>
			<div className="page-controls">
				<div className="table-data__tool">
					<div className="table-data__tool-left row">
						{children}
						{extraFilters && (
							<div
								style={{ width: "50px" }}
								className="filtros-avanzados"
								onClick={displayFilters}
							>
								<label>&nbsp;</label>
								<button
									className="btn"
									title="Filtros Avanzados"
								>
									<i className="zmdi zmdi-filter-list"></i>
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
            <div id="mas-filtros" className="row mas-filtros d-none m-b-25">
                {extraFilters instanceof Function ? extraFilters() : extraFilters}
            </div>
		</>
	);
};

export default FiltersContainer;
