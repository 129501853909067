import React, { Component } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Dashboard from "./components/Dashboard";
import Imprimir from "./components/Imprimir";

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path='/:sucursal/imprimir' component={Imprimir} />
                    <Route path='/' component={Dashboard} />
                </Switch>
            </Router>
        );
    }
}
export default App;
