import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const tipos_impuesto = [
	{
		value: "itbis",
		label: "ITBIS",
	},
	{
		value: "otro",
		label: "Otro",
	},
	{
		value: "isc",
		label: "ISC",
	},
	{
		value: "propina_legal",
		label: "Propina legal",
	},
	{
		value: "no_facturable",
		label: "No facturable",
	},
	{
		value: "exento",
		label: "Exento",
	},
	{
		value: "especial",
		label: "Especial",
	},
];

const Form = ({ impuesto, errors }) => {
	const [form, setForm] = useState({
		id: "",
		nombre: "",
		porcentaje: 0,
		tipo: "",
		acreditable: "Si",
		descripcion: "",
		status: "activo",
	});

	const handleFormInputChange = (input, inputChanged) => {
		setForm((form) => ({
			...form,
			[inputChanged]: input,
		}));
	};

	useLayoutEffect(() => {
		if (Object.keys(impuesto).length === 0) return;

		setForm({
			id: impuesto.id || "",
			nombre: impuesto.nombre || "",
			porcentaje: impuesto.porcentaje || 0,
			tipo: impuesto.tipo || "",
			acreditable: impuesto.acreditable || "Si",
			descripcion: impuesto.descripcion || "",
			status: impuesto.status || "activo",
		});
	}, [impuesto]);
	return (
		<div className="popup__body">
			<input type="hidden" name="id" value={form.id} />
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">Nombre:</label>
				</div>
				<div className="col col-md-8">
					<input
						type="text"
						name="nombre"
						onChange={(e) =>
							handleFormInputChange(e.target.value, "nombre")
						}
						value={form.nombre}
						className="form-control"
						readOnly={form.is_default}
					/>
					{errors.nombre && (
						<small className="help-blockParams form-Text">
							{errors.nombre[0]}
						</small>
					)}
				</div>
			</div>
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">
						Porcentaje:
					</label>
				</div>
				<div className="col col-md-8">
					<input
						type="number"
						max={99}
						min={0}
						name="porcentaje"
						onChange={(e) =>
							handleFormInputChange(e.target.value, "porcentaje")
						}
						value={form.porcentaje}
						className="form-control"
					/>
					{errors.porcentaje && (
						<small className="help-blockParams form-Text">
							{errors.porcentaje[0]}
						</small>
					)}
				</div>
			</div>
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">Tipo:</label>
				</div>
				<div className="col col-md-8">
					<Select
						name="tipo"
						options={tipos_impuesto}
						onChange={(e) => handleFormInputChange(e.value, "tipo")}
						menuPortalTarget={document.body}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
						}}
						value={
							impuesto.id &&
							tipos_impuesto.find(
								(tipo) => tipo.value === form.tipo
							)
						}
					/>
					{errors.tipo && (
						<small className="help-blockParams form-Text">
							{errors.tipo[0]}
						</small>
					)}
				</div>
			</div>
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">
						Descripción:
					</label>
				</div>
				<div className="col col-md-8">
					<textarea
						type="text"
						name="descripcion"
						onChange={(e) =>
							handleFormInputChange(e.target.value, "descripcion")
						}
						value={form.descripcion}
						className="form-control"
						readOnly={form.is_default}
					/>
					{errors.descripcion && (
						<small className="help-blockParams form-Text">
							{errors.descripcion[0]}
						</small>
					)}
				</div>
			</div>
			<div className="row form-group">
				<div className="col col-md-4 pr-0">
					<label className="label form-control-label">
						Impuesto Acréditable:
					</label>
				</div>
				<div className="col col-md-8">
					<div className="form-inline">
						<div className="radio">
							<label className="mr-3">
								<input
									className="mr-1"
									type="radio"
									name="acreditable"
									id="optionsRadiosActivo"
									value="Si"
									checked={form.acreditable == "Si"}
									onChange={(e) =>
										handleFormInputChange(
											e.target.value,
											"acreditable"
										)
									}
								/>
								Si
							</label>
						</div>
						<div className="radio">
							<label>
								<input
									className="mr-1"
									type="radio"
									name="acreditable"
									id="optionsRadiosActivo"
									value="No"
									checked={form.acreditable == "No"}
									onChange={(e) =>
										handleFormInputChange(
											e.target.value,
											"acreditable"
										)
									}
								/>
								No
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">Estado:</label>
				</div>
				<div className="col col-md-8">
					<div className="form-inline">
						<div className="radio">
							<label className="mr-3">
								<input
									className="mr-1"
									type="radio"
									name="status"
									id="optionsRadiosActivo"
									value="activo"
									checked={form.status == "activo"}
									onChange={(e) =>
										handleFormInputChange(
											e.target.value,
											"status"
										)
									}
								/>
								Activo
							</label>
						</div>
						<div className="radio">
							<label>
								<input
									className="mr-1"
									type="radio"
									name="status"
									id="optionsRadiosActivo"
									value="inactivo"
									checked={form.status == "inactivo"}
									onChange={(e) =>
										handleFormInputChange(
											e.target.value,
											"status"
										)
									}
								/>
								Inactivo
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Form.prototypes = {
	errors: PropTypes.object,
	impuesto: PropTypes.object,
};

export default Form;
