import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";

class DetalleTicket extends Component {
    state = {
        ticket: {}
    };
    componentDidMount() {
        this.getTicket();
    }
    getTicket = async () => {
        const numero = this.props.match.params.numero;
        const response = await this.props.request("tickets/" + numero);

        if (response.success) {
            this.setState({
                ticket: response.data
            });
            window.print();
        }
    };
    render() {
        const { ticket } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            suc => suc.id === user.sucursalActual
        )[0];
        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Ticket {ticket.numero}
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-center p-b-10'></div>
                </div>
                <table className='table'>
                    <tbody>
                        <tr>
                            <td width='150px'>Fecha:</td>
                            <td>{ticket.programacion}</td>
                        </tr>
                        <tr>
                            <td>Ficha:</td>
                            <td>{ticket.ficha}</td>
                        </tr>
                        <tr>
                            <td>Categoría:</td>
                            <td>{ticket.categoria}</td>
                        </tr>
                        <tr>
                            <td>Zona:</td>
                            <td>{ticket.ruta}</td>
                        </tr>
                        <tr>
                            <td>Número:</td>
                            <td>{ticket.numero}</td>
                        </tr>
                        <tr>
                            <td>Hora:</td>
                            <td>{ticket.hora}</td>
                        </tr>
                        <tr>
                            <td>Peso Bruto:</td>
                            <td>{ticket.pesoBruto}</td>
                        </tr>
                        <tr>
                            <td>Peso Tara:</td>
                            <td>{ticket.pesoTara}</td>
                        </tr>
                        <tr>
                            <td>Peso Neto:</td>
                            <td>{ticket.pesoNeto}</td>
                        </tr>
                        {ticket.operadores &&
                            ticket.operadores.map(op => (
                                <tr key={op.asignadoId}>
                                    <td>{op.nombre}</td>
                                    <td>{op.asignadoNombre}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(DetalleTicket));
