import React, { useState } from "react";
import Select from "react-select";
import SelectCreateAsync from "react-select/async-creatable";
import SelectAsync from "react-select/async";
import { apiRequest } from "../../../hooks/apiRequest";
import { provinciasMunicipiosForDropdown } from "../../../utils/helpers";
import dayjs from "dayjs";

const Form = ({ vehiculos, empleados, empleado, solicitud }) => {
    if (!solicitud) {
        solicitud = {};
    }

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };

    const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState(solicitud.empleadoId ? {
        value: solicitud.empleadoId,
        label: solicitud.empleado,
        telefono: solicitud.telefono,
        flota: solicitud.flota,
    } : null);

    return (
        <div className='popup__body'>
            <div className='row form-group'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>
                        Fecha:
                    </label>
                </div>
                <div className='col-md-8'>
                    <input
                        type="date"
                        name="fecha"
                        className="form-control"
                        defaultValue={dayjs(solicitud.fecha).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD')}
                    />
                </div>
            </div>
            <div className='row form-group'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>
                        Municipio/Provincia:
                    </label>
                </div>
                <div className='col-md-8'>
                    <Select
                        name='provincia'
                        isClearable={true}
                        defaultValue={
                            solicitud.provincia && {
                                value: solicitud.provincia,
                                label: solicitud.provincia
                            }
                        }
                        options={provinciasMunicipiosForDropdown}
                    />
                </div>
            </div>
            <div className='row form-group'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>
                        Establecimiento:
                    </label>
                </div>
                <div className='col-md-8'>
                    <SelectCreateAsync
                        name='establecimiento[]'
                        isClearable={true}
                        cacheOptions
                        defaultOptions
                        defaultValue={
                            solicitud.establecimiento && {
                                value: solicitud.establecimiento,
                                label: solicitud.establecimiento
                            }
                        }
                        loadOptions={e => getOptions("taxonomias?relacion=Solicitudes&tipo=Establecimientos&buscar="+e, 'nombre', 'nombre')}
                    />
                </div>
            </div>
            <div className='row form-group'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>
                        Tipo Solicitud:
                    </label>
                </div>
                <div className='col-md-8'>
                    <SelectCreateAsync
                        name='averia[]'
                        isClearable={true}
                        cacheOptions
                        defaultOptions
                        defaultValue={
                            solicitud.averia && {
                                value: solicitud.averia,
                                label: solicitud.averia
                            }
                        }
                        loadOptions={e => getOptions("taxonomias?relacion=Solicitudes&tipo=Averías&buscar="+e, 'nombre', 'nombre')}
                    />
                </div>
            </div>
            <div className='row form-group'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>
                        Descripción:
                    </label>
                </div>
                <div className='col-md-8'>
                    <textarea
                        type='text'
                        name='descripcion'
                        rows='5'
                        className='form-control'
                        defaultValue={solicitud.descripcion}
                    />
                </div>
            </div>
            <div className='row form-group'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>
                        Vehículo:
                    </label>
                </div>
                <div className='col-md-8'>
                    <Select
                        name='vehiculo_id'
                        isClearable={true}
                        defaultValue={
                            solicitud.vehiculoId && {
                                value: solicitud.vehiculoId,
                                label: solicitud.vehiculo
                            }
                        }
                        options={vehiculos.map(vehiculo => ({
                            value: vehiculo.id,
                            label: vehiculo.ficha
                        }))}
                    />
                </div>
            </div>
            <div className='row form-group'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>
                        Empleado:
                    </label>
                </div>
                <div className='col-md-8'>
                    <Select
                        name='empleado_id'
                        isClearable={true}
                        defaultValue={
                            solicitud.empleadoId && {
                                isFixed: true,
                                value: solicitud.empleadoId,
                                label: solicitud.empleado,
                                telefono: solicitud.telefono,
                                flota: solicitud.flota,
                            }
                        }
                        options={empleados.map(empleado => ({
                            value: empleado.id,
                            label: empleado.nombreCompleto,
                            telefono: empleado.telefono,
                            flota: empleado.flota,
                        }))}
                        onChange={setEmpleadoSeleccionado}
                    />
                </div>
            </div>

            {empleadoSeleccionado?.value && <div className='row form-group'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>
                        Teléfono:
                    </label>
                </div>

                <div className='col-md-8'>
                    <div className="form-control">{empleadoSeleccionado.telefono}&nbsp;</div>
                </div>
            </div>}

            {empleadoSeleccionado?.value && <div className='row form-group'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>
                        Flota:
                    </label>
                </div>

                <div className='col-md-8'>
                    <div className="form-control">{empleadoSeleccionado.flota}&nbsp;</div>
                </div>
            </div>}

            <div className='row form-group'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>
                        Zona:
                    </label>
                </div>
                <div className='col-md-8'>
                    <SelectAsync
                        name='ruta_id'
                        isClearable={true}
                        cacheOptions
                        defaultOptions
                        defaultValue={
                            solicitud.ruta && {
                                value: solicitud.ruta.id,
                                label: solicitud.ruta.nombre
                            }
                        }
                        loadOptions={e => getOptions("rutas?limit=15"+ (e ? `&buscar=${e}` : ''))}
                    />
                </div>
            </div>
            <div className='row form-group'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>
                        Contacto:
                    </label>
                </div>
                <div className='col-md-8'>
                    <SelectAsync
                        name='contacto_id'
                        isClearable={true}
                        cacheOptions
                        defaultOptions
                        defaultValue={
                            solicitud.contacto && {
                                value: solicitud.contacto.id,
                                label: solicitud.contacto.nombre
                            }
                        }
                        loadOptions={e => getOptions("contactos?limit=15"+ (e ? `&buscar=${e}` : ''))}
                    />
                </div>
            </div>
            <div className='row form-group sm-hidden'>
                <div className='col-md-4'>
                    <label className='label form-control-label'>Estado:</label>
                </div>
                <div className='col-md-8'>
                    <select
                        type='date'
                        name='estado'
                        className='form-control'
                        defaultValue={solicitud.estado}>
                        <option value='pendiente'>Pendiente</option>
                        <option value='en-progreso'>En Progreso</option>
                        <option value='completada'>Completada</option>
                        <option value='cancelada'>Cancelada</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default Form;