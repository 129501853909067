import React, { Component } from "react";
import { Route } from "react-router-dom";

import { auth } from "../utils/auth";

import Login from "./Login";
import Comprobantes from "./Nominas/Print/Comprobantes";
import Despachados from "./Programaciones/Print/Despachados";
import Reportes from "./Reportes";
import DetalleTicket from "./Programaciones/Print/DetalleTicket";
import Solicitudes from "./Solicitudes/Print";
import Solicitud from "./Solicitudes/Print/Solicitud";
import Reclamaciones from "./Reclamaciones/Print";
import Reclamacion from "./Reclamaciones/Print/Reclamacion";
import Servicios from "./Servicios/Print";
import Vehiculos from "./Vehiculos/Print";
import DetalleVehiculos from "./Vehiculos/Print/ListadoDetalleVehiculos";
import Items from "./Items/Print";
import Productos from "./Productos/Print";
import PrintReporteSuplidor from "./Productos/Print/ReporteSuplidor";
import HistorialVentas from "./Productos/Print/HistorialVentas";
import CatalogueOfProducts from "./Productos/Print/CatalogueOfProducts";
import HistorialComprasItems from "./Items/Print/HistorialCompras";
import HistorialComprasProductos from "./Productos/Print/HistorialCompras";
import Empleados from "./Empleados/Print";
import DetalleEmpleados from "./Empleados/Print/ListadoDetalleEmpleados";
import GastosVehiculos from "./Gastos/Print";
import Rutas from "./Rutas/Print";
import Tarjetas from "./Programaciones/Print/Tarjetas";
import Resumen from "./Nominas/Print/Resumen";
import ComprobantesEstandard from "./Nominas/Print/ComprobantesEstandard";
import ComprobantesNormales from "./Nominas/Print/ComprobantesNormales";
import DesgloseEfectivo from "./Nominas/Print/DesgloseEfectivo";
import GastosGenerales from "./GastosGenerales/Print";
import Ausentes from "./Programaciones/Print/Ausentes";
import NoDespachados from "./Programaciones/Print/NoDespachados";
import DescuentosProgramados from "./DescuentosProgramados/Print";
import DescuentoProgramado from "./DescuentosProgramados/Print/Descuento";
import ReciboDescuentoProgramado from "./DescuentosProgramados/Print/Recibo";
import Incentivos from "./Incentivos/Print/";
import Incentivo from "./Incentivos/Print/Incentivo";
import Cuentas from "./Cuentas/Print/";
import GIPrestamos from "./GI/Prestamos/Print/Semana";
import GIGrupos from "./GI/Grupos/Print/";
import Sobres from "./Nominas/Print/Sobres";
import SobresDetalles from "./Nominas/Print/SobresDetalles";
import Transacciones from "./Cuentas/Print/Transacciones";
import ComprobantesDetalles from "./Nominas/Print/ComprobantesDetalles";
import PrintFacturas from "./Ingresos/Facturacion/Components/PrintFacturas";
import PrintFacturasResumen from "./Ingresos/Facturacion/Components/PrintFacturasResumen";
import PrintSingleFactura from "./Ingresos/Facturacion/Components/PrintSingleFactura";
import PrintOrdenes from "./Ingresos/OrdenCompra/Components/PrintOrdenes";
import PrintSingleOrden from "./Ingresos/OrdenCompra/Components/PrintSingleOrden";
import PrintSingleCotizacion from "./Ingresos/Cotizacion/Components/PrintSingleCotizacion";
import PrintCotizaciones from "./Ingresos/Cotizacion/Components/PrintCotizaciones";
import PrintComprobanteGasto from "./GastosInternos/Print/Recibo";
import ResumenColumnas from "./Nominas/Print/ResumenColumnas";
import GastosInternos from "./GastosInternos/Print/";
import ResumenColumnasCategorizado from "./Nominas/Print/ResumenColumnasCategorizado";
import Cumpleanios from "./Empleados/Print/Cumpleanios";
import PrintTransaccionesHistory from "./Contactos/Transacciones/Print/History";
import PrintTransaccionesCompras from "./Contactos/Transacciones/Print/Compras";
import SalarioNavidad from "./Empleados/Print/SalarioNavidad";
import SobreSalarioNavidad from "./Empleados/Print/SobreSalarioNavidad";
import SalarioNavidadDesglose from "./Empleados/Print/SalarioNavidadDesglose";
import SingleTransaccion from "./Cuentas/Print/SingleTransaccion";
import PrintSingleRecibo from "./Consultas/RecibosIngresos/PrintSingleRecibo";
import PrintCuentasPorCobrar from "./Consultas/CuentasPorCobrar/PrintCuentasPorCobrar";
import PrintEstadosCuenta from "./Consultas/CuentasPorCobrar/PrintEstadosCuenta";
import ComprobanteSalarioNavidad from "./Empleados/Print/ComprobanteSalarioNavidad";
import PrintRecibosIngresos from "./Consultas/RecibosIngresos/PrintRecibosIngresos";
import PrintFacturasRecurrentes from "./Ingresos/FacturacionRecurrente/Components/PrintFacturasRecurrentes";
import PrintProductosReorden from "./Productos/Print/PrintProductosReorden";
import ReporteCuadre from "./Ingresos/print/ReporteCuadre";
import ReporteCuadreResumido from "./Ingresos/print/ReporteCuadreResumido";
import PrintEvaluaciones from "./Academico/Evaluaciones/Print";
import PrintEstudiantes from "./Academico/Estudiantes/Print";
import PrintInscripciones from "./Academico/Inscripciones/Print";
import PrintInscripcionesCocurriculares from "./Academico/Cocurriculares/Inscripcion/PrintInscripciones";
import PrintCuentasPorPagar from "./Consultas/CuentasPorPagar/PrintCuentasPorPagar";
import PrintCompras from "./Compras/PrintCompras";
import PrintSingleCompra from "./Compras/PrintSingleCompra";
import ReporteCuadreCompra from "./Compras/Print/ReporteCuadreCompra";
import ReporteDetalleCompra from "./Compras/Print/ReporteComprasDetalles";
import PrintClave from "./Academico/Estudiantes/Print/Clave";
import PrintFicha from "./Academico/Estudiantes/Print/Ficha";
import PrintListadoRecibosAcademicos from "./Academico/Recibos/Print/ListadoRecibos";
import PrintReciboInscripcion from "./Academico/Recibos/Print/ReciboInscripcion";
import PrintReciboMensualidad from "./Academico/Recibos/Print/ReciboMensualidad";
import PrintReciboCocurricular from "./Academico/Cocurriculares/Recibos/Print/ReciboCocurricular";
import PrintListadoRecibosCocurriculares from "./Academico/Cocurriculares/Recibos/Print/ListadoRecibosCocurricular";
import BalanceReport from "./Ingresos/print/BalanceReport";
import ReporteBalanceDetalle from "./Ingresos/print/ReporteBalanceDetalle";
import ReporteFacturasDetalles from "./Ingresos/print/ReporteFacturasDetalles";
import ReporteFacturasDetalles2 from "./Ingresos/print/ReporteFacturasDetalles2";
import ReporteListaCarga from "./Ingresos/print/ReporteListaCarga";
import DetalleComisiones from "./Nominas/Print/DetalleComisiones";
import PrintComisiones from "./Nominas/Print/Comisiones";
import PrintSingleNotaCredito from "./Consultas/NotasCredito/print/PrintSingleNotaCredito";
import PrintReporteNotasCredito from "./Consultas/NotasCredito/print/PrintReporteNotasCredito";
import ReporteNotasCreditoDetalles from "./Consultas/NotasCredito/print/ReporteNotasCreditoDetalles";
import PrintResumenCuentasPorCobrar from "./Consultas/CuentasPorCobrar/PrintResumenCuentasPorCobrar";
import PrintResumenCuentasPorPagar from "./Consultas/CuentasPorPagar/PrintResumenCuentasPorPagar";
import PrintResumenOrdenCompra from "./Ingresos/print/PrintResumenOrdenCompra";
import EntradasDiario from "./EntradasDiario/Print";
import Cheques from "./Cheques/Print";
import Contratos from "./Contratos/Print";
import PrintNominasEmpleado from "./Empleados/Nominas/Print/NominasEmpleado";
import SalarioNavidadDetalle from "./Empleados/Print/SalarioNavidadDetalle";
import ReportInventoryAdjustment from "./Productos/Print/ReportInventoryAdjustment";
import PrintAusencias from "./Ausencias/Print";
import PrintSingleAusencia from "./Ausencias/PrintSingleAusencia";
import PrintAusenciasEmpleados from "./Empleados/Ausencias/Print";
import ReportInventoryAdjustmentSingle from "./Productos/Print/ReportInventoryAdjustmentSingle";
class Imprimir extends Component {
    state = {
        isLoggedIn: auth.isLoggedIn(),
    };
    componentDidMount() {
        window.addEventListener("storage", this.syncLogout);
    }
    componentWillUnmount() {
        window.removeEventListener("storage", this.syncLogout);
        window.localStorage.removeItem("logout");
    }
    syncLogout = (event) => {
        if (event.key === "logout") {
            this.setState({ isLoggedIn: auth.isLoggedIn() });
        }
    };
    render() {
        if (!this.state.isLoggedIn) return <Login />;
        return (
            <div className='print-page'>
                <Route
                    path='/:sucursal/imprimir/nomina/:id/comprobantes'
                    component={Comprobantes}
                />
                <Route
                    path='/:sucursal/imprimir/nomina/:id/comprobantes-estandard'
                    component={ComprobantesEstandard}
                />
                <Route
                    path='/:sucursal/imprimir/nomina/:id/comprobantes-normales'
                    component={ComprobantesNormales}
                />
                <Route
                    path='/:sucursal/imprimir/nomina/:id/comprobantes-detalles'
                    component={ComprobantesDetalles}
                />
                <Route
                    path='/:sucursal/imprimir/nomina/:id/sobres'
                    component={Sobres}
                />
                <Route
                    path='/:sucursal/imprimir/nomina/:id/sobres-detalles'
                    component={SobresDetalles}
                />
                <Route
                    path='/:sucursal/imprimir/nomina/:id/resumen'
                    component={Resumen}
                />
                <Route
                    path='/:sucursal/imprimir/nomina/:id/resumen-columnas'
                    component={ResumenColumnas}
                />
                <Route
                    path='/:sucursal/imprimir/nomina/:id/resumen-columnas-categorizado'
                    component={ResumenColumnasCategorizado}
                />
                <Route
                    path='/:sucursal/imprimir/nomina/:id/desglose-efectivo'
                    component={DesgloseEfectivo}
                />
                <Route
                    path='/:sucursal/imprimir/nomina/:id/comisiones/:comision'
                    exact
                    component={DetalleComisiones}
                />
                <Route
                    path='/:sucursal/imprimir/nomina/:id/comisiones'
                    exact
                    component={PrintComisiones}
                />
                <Route
                    path='/:sucursal/imprimir/programacion/:id/despachados'
                    component={Despachados}
                />
                <Route
                    path='/:sucursal/imprimir/programacion/:id/tarjetas'
                    component={Tarjetas}
                />
                <Route
                    path='/:sucursal/imprimir/programacion/:id/ausentes'
                    component={Ausentes}
                />
                <Route
                    path='/:sucursal/imprimir/programacion/:id/no-despachados'
                    component={NoDespachados}
                />
                <Route
                    path='/:sucursal/imprimir/ticket/:numero'
                    component={DetalleTicket}
                />
                <Route
                    path='/:sucursal/imprimir/reportes'
                    component={Reportes}
                />
                <Route
                    exact
                    path='/:sucursal/imprimir/solicitudes'
                    component={Solicitudes}
                />
                <Route
                    path='/:sucursal/imprimir/solicitudes/:id'
                    component={Solicitud}
                />
                <Route
                    exact
                    path='/:sucursal/imprimir/reclamaciones/:id'
                    component={Reclamacion}
                />
                <Route
                    exact
                    path='/:sucursal/imprimir/reclamaciones'
                    component={Reclamaciones}
                />
                <Route
                    path='/:sucursal/imprimir/servicios'
                    component={Servicios}
                />
                <Route
                    path='/:sucursal/imprimir/vehiculos'
                    exact
                    component={Vehiculos}
                />
                <Route
                    path='/:sucursal/imprimir/vehiculos/detalle'
                    component={DetalleVehiculos}
                />
                <Route
                    path='/:sucursal/imprimir/gastos-vehiculos'
                    component={GastosVehiculos}
                />
                <Route
                    path='/:sucursal/imprimir/gastos-generales'
                    component={GastosGenerales}
                />
                <Route
                    exact
                    path='/:sucursal/imprimir/gastos-internos'
                    component={GastosInternos}
                />
                <Route path='/:sucursal/imprimir/rutas' component={Rutas} />
                <Route path='/:sucursal/imprimir/gastos-internos/:id' component={PrintComprobanteGasto} />
                <Route path='/:sucursal/imprimir/items' component={Items} />
                <Route path='/:sucursal/imprimir/productos' component={Productos} />
                <Route path='/:sucursal/imprimir/productos-suplidor' component={PrintReporteSuplidor} />
                <Route path='/:sucursal/imprimir/ventas' component={HistorialVentas} />
                <Route path='/:sucursal/imprimir/catalogo' component={CatalogueOfProducts} />
                <Route path='/:sucursal/imprimir/ajustes-inventario' exact component={ReportInventoryAdjustment} />
                <Route path='/:sucursal/imprimir/ajustes-inventario/:ajusteId' exact component={ReportInventoryAdjustmentSingle} />
                <Route path='/:sucursal/imprimir/reorden' component={PrintProductosReorden} />
                <Route path='/:sucursal/imprimir/facturas' exact component={PrintFacturas} />
                <Route path='/:sucursal/imprimir/facturas-resumen' exact component={PrintFacturasResumen} />
                <Route path='/:sucursal/imprimir/facturas-recurrentes' exact component={PrintFacturasRecurrentes} />
                <Route path='/:sucursal/imprimir/facturas/:id' exact component={PrintSingleFactura} />
                <Route path='/:sucursal/imprimir/facturas/:id/doble' exact component={PrintSingleFactura} />
                <Route path='/:sucursal/imprimir/balance' exact component={BalanceReport} />
                <Route path='/:sucursal/imprimir/balance-detalle' exact component={ReporteBalanceDetalle} />
                <Route path='/:sucursal/imprimir/facturas-detalles' exact component={ReporteFacturasDetalles} />
                <Route path='/:sucursal/imprimir/facturas-detalles-2' exact component={ReporteFacturasDetalles2} />
                <Route path='/:sucursal/imprimir/lista-carga' exact component={ReporteListaCarga} />
                <Route path='/:sucursal/imprimir/cuentas-por-cobrar' exact component={PrintCuentasPorCobrar} />
                <Route path='/:sucursal/imprimir/estados-cuenta' exact component={PrintEstadosCuenta} />
                <Route path='/:sucursal/imprimir/cuentas-por-cobrar-resumen' exact component={PrintResumenCuentasPorCobrar} />
                <Route path='/:sucursal/imprimir/cuentas-por-pagar' exact component={PrintCuentasPorPagar} />
                <Route path='/:sucursal/imprimir/cuentas-por-pagar-resumen' exact component={PrintResumenCuentasPorPagar} />
                <Route path='/:sucursal/imprimir/recibos-ingreso' exact component={PrintRecibosIngresos} />
                <Route path='/:sucursal/imprimir/ordenes-compra' exact component={PrintOrdenes} />
                <Route path='/:sucursal/imprimir/resumen-ordenes-compra' exact component={PrintResumenOrdenCompra} />
                <Route path='/:sucursal/imprimir/ordenes-compra/:id' exact component={PrintSingleOrden} />
                <Route path='/:sucursal/imprimir/cotizaciones' exact component={PrintCotizaciones} />
                <Route path='/:sucursal/imprimir/cotizaciones/:id' exact component={PrintSingleCotizacion} />
                <Route path='/:sucursal/imprimir/recibos-ingreso/:id' exact component={PrintSingleRecibo} />
                <Route path='/:sucursal/imprimir/compras' exact component={PrintCompras} />
                <Route path='/:sucursal/imprimir/compras/:id' exact component={PrintSingleCompra} />
                <Route path='/:sucursal/imprimir/notas-credito' exact component={PrintReporteNotasCredito} />
                <Route path='/:sucursal/imprimir/notas-credito/:id' exact component={PrintSingleNotaCredito} />
                <Route path='/:sucursal/imprimir/notas-credito-detalles' exact component={ReporteNotasCreditoDetalles} />
                <Route
                    path='/:sucursal/imprimir/compras-item'
                    component={HistorialComprasItems}
                />
                <Route
                    path='/:sucursal/imprimir/compras-producto'
                    component={HistorialComprasProductos}
                />
                <Route
                    path='/:sucursal/imprimir/empleados'
                    exact
                    component={Empleados}
                />
                <Route
                    path='/:sucursal/imprimir/empleados/detalle'
                    exact
                    component={DetalleEmpleados}
                />
                <Route
                    path='/:sucursal/imprimir/empleados/cumpleanios'
                    exact
                    component={Cumpleanios}
                />
                <Route
                    path='/:sucursal/imprimir/empleados/salario-navidad'
                    exact
                    component={SalarioNavidad}
                />
                <Route
                    path='/:sucursal/imprimir/empleados/salario-navidad-detalle'
                    exact
                    component={SalarioNavidadDetalle}
                />
                <Route
                    path='/:sucursal/imprimir/empleados/:id/nominas'
                    exact
                    component={PrintNominasEmpleado}
                />
                <Route
                    path='/:sucursal/imprimir/empleados/salario-navidad-desglose'
                    exact
                    component={SalarioNavidadDesglose}
                />
                <Route
                    path='/:sucursal/imprimir/empleados/sobre-salario-navidad'
                    exact
                    component={SobreSalarioNavidad}
                />
                <Route
                    path='/:sucursal/imprimir/empleados/comprobante-salario-navidad'
                    exact
                    component={ComprobanteSalarioNavidad}
                />
                <Route
                    path='/:sucursal/imprimir/descuentos'
                    exact
                    component={DescuentosProgramados}
                />
                <Route
                    path='/:sucursal/imprimir/descuentos/:id'
                    exact
                    component={DescuentoProgramado}
                />
                <Route
                    path='/:sucursal/imprimir/descuentos/:id/recibo/:cuota'
                    exact
                    component={ReciboDescuentoProgramado}
                />
                <Route
                    path='/:sucursal/imprimir/incentivos'
                    exact
                    component={Incentivos}
                />
                <Route
                    path='/:sucursal/imprimir/incentivos/:id'
                    exact
                    component={Incentivo}
                />
                <Route
                    path='/:sucursal/imprimir/cuentas'
                    exact
                    component={Cuentas}
                />
                <Route
                    path='/:sucursal/imprimir/entradas-diario'
                    exact
                    component={EntradasDiario}
                />
                <Route
                    path='/:sucursal/imprimir/cheques'
                    exact
                    component={Cheques}
                />
                <Route
                    path='/:sucursal/imprimir/cuentas/:id/transacciones'
                    exact
                    component={Transacciones}
                />
                <Route
                    path='/:sucursal/imprimir/cuentas/:id/transacciones/:transaccion'
                    exact
                    component={SingleTransaccion}
                />
                <Route
                    path='/:sucursal/imprimir/gi-prestamos'
                    exact
                    component={GIPrestamos}
                />
                <Route
                    path='/:sucursal/imprimir/gi-grupos'
                    exact
                    component={GIGrupos}
                />
                <Route
                    path='/:sucursal/imprimir/contactos/:id/transacciones'
                    exact
                    component={PrintTransaccionesHistory}
                />
                <Route
                    path='/:sucursal/imprimir/contactos/:id/compras'
                    exact
                    component={PrintTransaccionesCompras}
                />
                <Route
                    path='/:sucursal/imprimir/cuadre-diario'
                    exact
                    component={ReporteCuadre}
                />
                <Route
                    path='/:sucursal/imprimir/cuadre-diario-resumido'
                    exact
                    component={ReporteCuadreResumido}
                />
                <Route
                    path='/:sucursal/imprimir/cuadre-compras'
                    exact
                    component={ReporteCuadreCompra}
                />
                <Route
                    path='/:sucursal/imprimir/detalle-compras'
                    exact
                    component={ReporteDetalleCompra}
                />
                <Route
                    path='/:sucursal/imprimir/academico/evaluaciones'
                    exact
                    component={PrintEvaluaciones}
                />
                <Route
                    path='/:sucursal/imprimir/academico/estudiantes'
                    exact
                    component={PrintEstudiantes}
                />
                <Route
                    path='/:sucursal/imprimir/academico/inscripciones'
                    exact
                    component={PrintInscripciones}
                />
                <Route
                    path='/:sucursal/imprimir/academico/inscripciones-cocurriculares'
                    exact
                    component={PrintInscripcionesCocurriculares}
                />
                <Route
                    path='/:sucursal/imprimir/academico/estudiantes/:id/clave'
                    exact
                    component={PrintClave}
                />
                <Route
                    path='/:sucursal/imprimir/academico/estudiantes/:id/ficha'
                    exact
                    component={PrintFicha}
                />
                <Route
                    path='/:sucursal/imprimir/academico/recibos/:id/cocurricular'
                    exact
                    component={PrintReciboCocurricular}
                />
                <Route
                    path='/:sucursal/imprimir/academico/recibos/cocurriculares'
                    exact
                    component={PrintListadoRecibosCocurriculares}
                />
                <Route
                    path='/:sucursal/imprimir/academico/recibos'
                    exact
                    component={PrintListadoRecibosAcademicos}
                />
                <Route
                    path='/:sucursal/imprimir/academico/recibos/:id/inscripcion'
                    exact
                    component={PrintReciboInscripcion}
                />
                <Route
                    path='/:sucursal/imprimir/academico/recibos/:id/mensualidad'
                    exact
                    component={PrintReciboMensualidad}
                />
                <Route
                    path='/:sucursal/imprimir/contratos'
                    exact
                    component={Contratos}
                />
                <Route
                    path='/:sucursal/imprimir/ausencias'
                    exact
                    component={PrintAusencias}
                />
                <Route
                    path='/:sucursal/imprimir/ausencias/:id'
                    exact
                    component={PrintSingleAusencia}
                />
                <Route
                    path='/:sucursal/imprimir/empleados/:id/ausencias'
                    exact
                    component={PrintAusenciasEmpleados}
                />
            </div>
        );
    }
}

export default Imprimir;
