import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import dayjs from "dayjs";
import { formatCurrency } from "../../../utils/helpers";

class PrintIncentivo extends Component {
    state = {
        incentivo: {},
    };
    componentDidMount() {
        this.getTicket();
    }
    getTicket = async () => {
        const id = this.props.match.params.id;
        const response = await this.props.request("incentivos/" + id);

        if (response.success) {
            this.setState({
                incentivo: response.data,
            });
            window.print();
        }
    };
    render() {
        const { incentivo } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Incentivo #{incentivo.id}
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-center p-b-10'></div>
                </div>

                <h3>Detalles</h3>
                <table className='table m-b-20'>
                    <tbody>
                        <tr>
                            <td>Fecha Solicitud:</td>
                            <td>{dayjs(incentivo.fecha).format("YYYY-MM-DD")}</td>
                        </tr>
                        <tr>
                            <td>Empleado:</td>
                            <td>{incentivo.empleado}</td>
                        </tr>
                        <tr>
                            <td>Cargo:</td>
                            <td>{incentivo.cargo}</td>
                        </tr>
                        <tr>
                            <td>Telefono:</td>
                            <td>{incentivo.telefono}</td>
                        </tr>
                        <tr>
                            <td>Monto:</td>
                            <td>{formatCurrency(incentivo.monto)}</td>
                        </tr>
                        <tr>
                            <td>Descripción:</td>
                            <td>{incentivo.descripcion}</td>
                        </tr>
                    </tbody>
                </table>

                {incentivo.cuotas?.length > 0 && <div>
                    <h3>Cuotas</h3>
                    <table className='table m-b-20'>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Monto</th>
                                <th className="text-right">Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {incentivo.cuotas.map(cuota => (
                                <tr>
                                    <td>{dayjs(cuota.fecha).format("DD MMM, YYYY")}</td>
                                    <td>{formatCurrency(cuota.monto)}</td>
                                    <td className="text-right">{cuota.estado}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
            </div>
        );
    }
}

export default withRouter(withRequest(PrintIncentivo));
