import React, {useState} from 'react';
import dayjs from 'dayjs';

import { formatCurrency } from '../../../utils/helpers';

export default function DescuentoItem({descuento}) {
    const [state, setState] = useState({
        open: false
    });

    function toggleRow() {
        console.log('clicked')
        setState({
            ...state,
            open: !state.open,
        })
    }

    return (
        <div>
            <div className="descuentos-table-row" onClick={() => toggleRow()}>
                <div>{formatCurrency(descuento.montoTotal || descuento.monto)}</div>
                <div>{descuento.descripcion}</div>
                <div className="text-right">{dayjs(descuento.fecha).format('DD MMM, YYYY')}</div>
                <div><i className="zmdi zmdi-chevron-right"></i></div>
            </div>
            {descuento.cuotas?.length > 0 && (<div className={`descuento-cuotas ${state.open ? 'show' : ''}`}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Monto</th>
                            <th className="text-center">Fecha</th>
                            <th className="text-right">Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {descuento.cuotas.map(cuota =>
                            <tr key={cuota.id} className={`cuota ${cuota.estado}`}>
                                <td>{formatCurrency(cuota.monto)}</td>
                                <td className="text-center">
                                    {dayjs(
                                        cuota.fecha
                                    ).format(
                                        "DD MMM, YYYY"
                                    )}
                                </td>
                                <td className="cuota__estado text-right">
                                    {cuota.estado}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>)}
        </div>
    );
}
