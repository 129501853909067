import React from "react";
import PropTypes from "prop-types";

// COMPONENTS

/* DOCUMENTATION
// PROPS:
    - data: it have the data to show in the table || type: Array.
    - columns: it have the columns the table will have || type: Array of objects
        - object in columns MOST have: 
        * title: this is the name of the column || type: String.
        * tdContent: this is for show the info in the TD. is a function that takes the row as param and MOST return a value || type: Function or any primitive type
        * 
*/

const DesktopTable = ({ data = [], columns = [] }) => {
	return (
		<table className="table table-data2">
			<thead>
				<tr>
					{columns.map((column) => (
						<th {...column.thProps}>{column.title}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{data.map((row, i) => (
					<tr className="tr-shadow" key={row.id || i}>
						{columns.map((column) => (
							<td {...column.tdProps}>
								{column.tdContent instanceof Function
									? column.tdContent(row)
									: column.tdContent}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

DesktopTable.propTypes = {
	data: PropTypes.array,
	columns: PropTypes.array,
};

export default DesktopTable;
