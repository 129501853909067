/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { formatCurrency, money, toast } from "../../../../utils/helpers";

// COMPONENTS
import Select from "react-select";
import Modal from "../../../general/Modal";
import ItemFotos from "../../../Items/ItemFotos";
import { createFilter } from "react-select/dist/react-select.cjs.prod";

// DETALLES
/*
    [DONE] Los productos vendrán desde el parent.
    [DONE] La cantidad a reducir no puede ser mayor a la existencia de productos en la factura.
    [DONE] Debe contar con los constraints de productos en factura.
    [DONE] No hay modal de pago.
    [TODO] Resolver en este componente el problema de los decimales (si un producto viene con su impuesto por
        defecto, que pase el valor total como es, no que calcule).
    [TODO] <---Meter mano--->.
*/

const ProductoFacturaNotaCredito = React.memo(
	({
		productos = [],
		productoSelected = {},
		index,
		checkProductoRepetido,
		removeProductoSelected,
		isEdit,
		handleInputChange,
		isFactura,
		enableProducto,
	}) => {
		const [precioManual, setPrecioManual] = useState(0);
		const [showPrecioManual, setShowPrecioManual] = useState(true);
		const [showDelete, setShowDelete] = useState(false);
		const [showPINModal, setShowPINModal] = useState(false);
		const [showFotos, setShowFotos] = useState(false);
		const [pinSeguridad, setPinSeguridad] = useState(false);

		// CALCULOS FINALES
		const [total, setTotal] = useState(0);
		const [subtotal, setSubtotal] = useState(0);
		const [impuestoCalculado, setImpuestoCalculado] = useState(0);

		const productLabel = useCallback(
			(producto) => {
				if (isFactura) {
					return (
						<span className="row px-0 align-items-center">
							<span
								className="col-2 pr-1"
								style={{
									minWidth: "100px",
									wordBreak: "break-all",
								}}
							>
								{producto.producto?.referencia}
							</span>
							<span className="col-5 px-1">
								{producto.producto?.nombre}
							</span>
							<span
								className="col-1 px-1"
								style={{
									fontWeight: "bold",
									color: "#008aab",
								}}
							>
								{isNaN(parseFloat(producto.cantidad))
									? "∞"
									: parseFloat(producto.cantidad)}
							</span>
							<span
								className="col-2 pr-0"
								style={{
									fontWeight: "bold",
									color: "#008aab",
								}}
							>
								{formatCurrency(
									money(producto.precio_facturado) || 0,
									"$"
								)}
							</span>
						</span>
					);
				}

				return (
					<span className="row px-0 align-items-center">
						<span
							className="col-2 pr-1"
							style={{
								minWidth: "100px",
								wordBreak: "break-all",
							}}
						>
							{producto?.referencia}
						</span>
						<span className="col-5 px-1">{producto?.nombre}</span>
						<span
							className="col-1 px-1"
							style={{
								fontWeight: "bold",
								color: "#008aab",
							}}
						>
							{isNaN(parseFloat(producto.cantidad))
								? "∞"
								: parseFloat(producto.cantidad)}
						</span>
						<span
							className="col-2 pr-0"
							style={{
								fontWeight: "bold",
								color: "#008aab",
							}}
						>
							{formatCurrency(
								money(producto.precio || producto.costo) || 0,
								"$"
							)}
						</span>
					</span>
				);
			},
			[isFactura]
		);

		const onChangeProductoFactura = async (prod) => {
            const productoID = prod.value;
			productoSelected.productoID = productoID.value;

			if (checkProductoRepetido(productoID, index, productoSelected.id)) {
				removeProductoSelected(productoSelected.id);
				return;
			}

			const currProducto = productos.find(
				(producto) => producto.producto?.id === productoID
			);

            if (currProducto.cantidad <= 0) {
                toast('Esta factura no tiene este producto disponible para devolución', 'error');
                return;
            }

			const impuestoCalculado =
            currProducto.precio_facturado - (currProducto.precio_facturado / (1+currProducto.impuesto?.porcentaje));
			const descuentoCalculado =
				currProducto.precio_facturado *
				productoSelected.cantidad *
				(currProducto.descuento / 100);

			handleInputChange(
				null,
				{
					id: productoSelected.id,
					productoID: currProducto.producto?.id,
					nombre: (
						<>
							<span
								className="col-3 pl-1"
								style={{
									minWidth: "100px",
									wordBreak: "break-all !important",
								}}
							>
								{currProducto.producto?.referencia}
							</span>{" "}
							<span className="col-8">
								{currProducto.producto?.nombre}
							</span>
						</>
					),
					cantidad: 1,
					importe:
						currProducto.importe ||
						currProducto.precio_facturado /
							(currProducto.impuesto.porcentaje + 1),
					descuento: currProducto.descuento,
					existencia: currProducto.cantidad,
					unidad: currProducto.producto?.unidad,
					impuesto: {
						value: currProducto.impuesto?.id,
						label: currProducto.impuesto?.nombre,
						porcentaje: currProducto.impuesto?.porcentaje,
						tipo: currProducto?.impuesto?.tipo,
					},
					impuestoCalculado,
					precioVenta: currProducto.precio_facturado,
					total:
						currProducto.precio_facturado *
						productoSelected.cantidad,
					descuentoCalculado,
					tipo: 'producto'
				},
				productoSelected.id,
				false
			);

			setPrecioManual(currProducto.precio_facturado);
			setTotal(
				currProducto.precio_facturado * productoSelected.cantidad -
					descuentoCalculado
			);
			setImpuestoCalculado(impuestoCalculado);
		};

		const onChangeInput = (field, value) =>
			handleInputChange(field, value, productoSelected.id);

		const checkQuantityConstrains = () => {};
		const handlePrecioVentaChange = () => {};
		const preciosVenta = () => [];
		const calcularDescuento = () => {};

		const toggleDeleteModal = () => {
			setShowDelete((state) => !state);
		};

		const toggleFotosModal = () => {};
		const checkPINSeguridad = () => {};
		const togglePINModal = () => {};
		const impuestosForDropdown = [];
		const productoITBIS = 0;
		const errors = {};

		useEffect(() => {
			if (!productoSelected.cantidad || !productoSelected.existencia)
				return;

			if (productoSelected.cantidad <= 0) {
				onChangeInput("cantidad", 1);
				toast("No incluir cantidades menores a 1", "error");
				return;
			} else if (
				(productoSelected.cantidad > productoSelected.existencia)
			) {
				onChangeInput("cantidad", productoSelected.existencia);
				toast(
					"Cantidad maxima permitida es: " +
						productoSelected.existencia,
					"error"
				);
				return;
			}

			setTotal(
				precioManual * productoSelected.cantidad -
					productoSelected.descuentoCalculado
			);
			handleInputChange(
				"total",
				precioManual * productoSelected.cantidad -
					productoSelected.descuentoCalculado,
				productoSelected.id
			);
			handleInputChange(
				"descuentoCalculado",
				precioManual *
					productoSelected.cantidad *
					(productoSelected.descuento / 100),
				productoSelected.id
			);
			handleInputChange(
				"impuestoCalculado",
				impuestoCalculado * productoSelected.cantidad,
				productoSelected.id
			);
		}, [productoSelected.cantidad]);

		useEffect(() => {
			if (!isEdit) return;
            const imp = productoSelected.precioVenta - (productoSelected.precioVenta / (1+productoSelected.impuesto?.porcentaje));
			setPrecioManual(productoSelected.precioVenta);
			setTotal(
				productoSelected.precioVenta * productoSelected.cantidad -
					productoSelected.descuentoCalculado
			);
			handleInputChange(
				"total",
				productoSelected.precioVenta * productoSelected.cantidad -
					productoSelected.descuentoCalculado,
				productoSelected.id
			);
			handleInputChange(
				"impuestoCalculado",
				imp * productoSelected.cantidad,
				productoSelected.id
			);
			setImpuestoCalculado(imp);
		}, []);

		return (
			<>
				<tr>
					<td colSpan={2}>
						<input
							type="hidden"
							name={`productos[${index}][id]`}
							value={isEdit ? productoSelected.id : "random1"}
						/>

						<Select
							type="text"
							filterOption={createFilter({
								ignoreAccents: false,
							})}
							// filterOption={filterOption}
							name={
								productoSelected.tipo === 'producto'
									? `productos[${index}][producto_id]`
									: `productos[${index}][item_id]`
							}
							placeholder={"Seleccione..."}
							value={
								productoSelected.id && {
									value: productoSelected.productoID,
									label: productoSelected.nombre,
								}
							}
							onChange={(e) => onChangeProductoFactura(e)}
							styles={{
								singleValue: (provided, state) => ({
									...provided,
									width: "100%",
									display: "flex",
								}),
								option: (provided, state) => ({
									...provided,
									backgroundColor: state.isSelected
										? "rgba(152, 204, 245,.3)"
										: "white",
									color: state.isSelected
										? "#008aab"
										: "#666666",
									fontWeight: state.isSelected
										? "600"
										: "normal",
								}),
							}}
							maxMenuHeight={200}
							options={
								productos &&
								productos.map((producto) => ({
									label: isFactura
										? `${producto.producto?.referencia} ${producto.producto?.nombre}`
										: `${producto?.referencia} ${producto?.nombre}`,
									value: isFactura
										? producto.producto?.id
										: producto.item_id,
									producto: producto,
								}))
							}
							formatOptionLabel={(option) =>
								option.producto
									? productLabel(option.producto)
									: option.label
							}
							isDisabled={!enableProducto}
						/>
					</td>
					<td>
						<input
							type="number"
							name={`productos[${index}][cantidad]`}
							placeholder="Cantidad"
							value={productoSelected.cantidad || undefined}
							step="1"
							min="0"
                            style={{maxWidth: "95px"}}
							onChange={(e) => {
								onChangeInput(
									"cantidad",
									parseFloat(e.target.value)
								);
							}}
							onBlur={() => checkQuantityConstrains()}
							className="form-control"
							readonly={!enableProducto}
						/>
					</td>
					<td>
                        {productoSelected.unidad}
					</td>
					<td>
                        {formatCurrency(
                            money((productoSelected.precio_venta || precioManual) - impuestoCalculado)
                        )}
						<input
							type="hidden"
							name={`productos[${index}][importe]`}
							className="form-control"
							value={(productoSelected.precio_venta || precioManual) - impuestoCalculado}
							readOnly
						/>
					</td>
					<td>
                        <>
                            {productoSelected.impuesto?.label}
                            <input
                                type="hidden"
                                name={`productos[${index}][impuesto_id]`}
                                value={productoSelected.impuesto?.value}
                            />
                            <input
                                type="hidden"
                                name={`productos[${index}][impuestos]`}
                                value={
                                    impuestoCalculado * productoSelected.cantidad
                                }
                            />
                        </>
					</td>
					<td>
                        <>
                            {formatCurrency(
                                money(precioManual),
                                ""
                            )}
                            <input
                                type="hidden"
                                name={`productos[${index}][precio_facturado]`}
                                value={precioManual}
                            />
                        </>
					</td>
					<td>
                        {productoSelected.descuento}
                        <input
							type="hidden"
							name={`productos[${index}][descuento]`}
							className="form-control"
							value={productoSelected.descuento}
							readOnly
						/>
					</td>
					<td className="text-right">
						{!showPrecioManual ? (
							<input
								type="hidden"
								name={`productos[${index}][precio_facturado]`}
								value={
									productoSelected.costo +
										productoITBIS -
										calcularDescuento(
											productoSelected.costo,
											productoSelected.descuento,
											productoSelected.cantidad
										) || 0
								}
							/>
						) : (
							<input
								type="hidden"
								name={`productos[${index}][precio_facturado]`}
								value={precioManual}
							/>
						)}

						<input
							type="hidden"
							name={`productos[${index}][total]`}
							value={total}
						/>
						{formatCurrency(money(total))}
					</td>
					<td className="text-center">
						{productoSelected.fotos?.length > 0 && (
							<button
								type="button"
								title="Ver Fotos"
								onClick={() => toggleFotosModal()}
								className="producto"
							>
								<i className="fas fa-eye"></i>
							</button>
						)}
					</td>
					<td className="text-right">
						<button
							type="button"
							className="btn btn-danger"
							onClick={() =>
								isEdit
									? toggleDeleteModal()
									: removeProductoSelected(
											productoSelected.id
									  )
							}
						>
							<i className="fas fa-trash"></i>
						</button>
					</td>
				</tr>
				{errors[`productos.${index}.total`] && (
					<small
						className="help-blockParams form-Text d-block"
						style={{ whiteSpace: "nowrap" }}
					>
						{errors[`productos.${index}.total`][0]}
					</small>
				)}
				{productoSelected?.fotos && (
					<Modal
						show={showFotos}
						blank={true}
						toggle={() => setShowFotos((state) => !state)}
					>
						<ItemFotos
							toggle={() => setShowFotos((state) => !state)}
							fotos={productoSelected.fotos}
						/>
					</Modal>
				)}
				<Modal
					title="Borrar Producto"
					show={showDelete}
					callback={() => removeProductoSelected(productoSelected.id)}
					acceptText="Borrar"
					toggle={toggleDeleteModal}
				>
					¿Estás seguro de que deseas borrar este producto?
				</Modal>
				{/* <---PIN DE SEGURIDAD MODAL--- */}
				<Modal
					title="PIN de seguridad"
					show={showPINModal}
					callback={() => checkPINSeguridad()}
					acceptText="Aceptar"
					toggle={() => togglePINModal()}
					size="419px"
				>
					<div className="form-group">
						<label>PIN de seguridad</label>
						<input
							type="password"
							className="form-control"
							id="PIN"
							name="pin_seguridad"
							value={pinSeguridad}
							onChange={(e) => setPinSeguridad(e.target.value)}
						/>
						<p
							className="font-weight-bold"
							style={{
								color: "gray",
								fontStyle: "italic",
								lineHeight: "13px",
								fontSize: "0.7rem",
							}}
						>
							Para continuar ingrese PIN de seguridad
						</p>
					</div>
				</Modal>
			</>
		);
	}
);

export default ProductoFacturaNotaCredito;
