/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { request } from "../../../../utils/request";
import { formatCurrency, getSucursal } from "../../../../utils/helpers";
import { auth } from "../../../../utils/auth";

const PrintSingleRecibo = () => {
	const { id } = useParams();
	// const location = useLocation();
    const usuario = auth.getUser();
	let isReservaCupo = false;

	const [recibo, setRecibo] = useState({});
	const [servicios, setServicios] = useState([]);
	const [grados, setGrados] = useState([]);
	const [sucursal, setSucursal] = useState({});
	const [status, setStatus] = useState("Original");

	const getRecibo = () => {
		request("academico/recibos/" + id).then((res) => {
			setRecibo(res.data);
			setTimeout(() => {
				window.print();
			}, 500);
		});
	};

	const onChangeStatusRecibo = (estado) => {
		setStatus(estado);
	};

	useEffect(() => {
		getRecibo();
        request("academico/pagos-educativos").then((res) => {
            setServicios(res.data);
		});
        request('academico/grados').then((res) => {
            setGrados(res.data);
		});
		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);
	}, []);

    const estudiante = recibo.estudiante;
    const curso = recibo.estudiante?.curso;
    const reciboIngreso = recibo.reciboIngreso;
    const tutor = recibo.estudiante?.tutores[0];
    const gradosRanking = grados.reduce((a,c) => {
        a[c.nombre] = c.orden;
        return a;
    }, {});

    const rank = gradosRanking[curso?.grado?.nombre];

    const inscripcion = servicios?.find(s => s.nombre.includes("nscripci"));
    const mensualidad = servicios?.find(s => s.nombre.includes("ensualidad"));
    const reservaCupo = servicios?.find(s => s.nombre.includes("eserva"));

    const precioInscripcion = curso?.grupoPago && inscripcion?.configuracion[curso?.grupoPago].find(c => {
        return gradosRanking[c.grado_desde] <= rank && gradosRanking[c.grado_hasta] >= rank;
    });
    const precioMensualidad = curso?.grupoPago && mensualidad?.configuracion[curso?.grupoPago].find(c => {
        return gradosRanking[c.grado_desde] <= rank && gradosRanking[c.grado_hasta] >= rank;
    });
    const precioReservaCupo = curso?.grupoPago && reservaCupo?.configuracion[curso?.grupoPago].find(c => {
        return gradosRanking[c.grado_desde] <= rank && gradosRanking[c.grado_hasta] >= rank;
    });

    recibo?.servicios?.forEach(servicio => {
        if (servicio.nombre.includes('eserva')) {
            isReservaCupo = true;
        }
    })

	return (
		<>
			<div className="p-2 invoice recibo-print">
                <div className="clear-both info-row p-t-10 d-sm-flex" style={{alignItems: 'flex-end'}}>
                    <div className="info-block" style={{ width: "65%" }}>
                        <div className="clear-both p-t-30 p-b-10">
                            <div className="float-sm-left sucursal-info">
                                {sucursal.logo ? (
                                    <img
                                        alt="logo"
                                        className="mb-2 recibo-logo"
                                        src={sucursal.logo[0].url}
                                        width="270px"
                                    />
                                ) : recibo.logo ? (
                                    <img
                                        alt="logo"
                                        className="mb-2 recibo-logo"
                                        src={recibo.logo[0].url}
                                        width="270px"
                                    />
                                ) : (
                                    <h3 style={{ lineHeight: "12px", marginBottom: "3px" }}>
                                        {sucursal.nombre}
                                    </h3>
                                )}
                                <div
                                    className="mb-0 mb-sm-0"
                                    style={{ lineHeight: "15px", fontSize: "13px" }}
                                >
                                    RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
                                </div>
                                <div
                                    className="mb-2 mb-sm-0"
                                    style={{ lineHeight: "15px", fontSize: "13px" }}
                                >
                                    {sucursal.direccion}
                                </div>
                            </div>
                        </div>
                        {tutor && (
                            <table>
                                <tbody id="info-block-1">
                                    <tr>
                                        <td
                                            width="120px"
                                            className="client-info-title text-uppercase text-right"
                                        >
                                            Tutor:
                                        </td>
                                        <td
                                            style={{
                                                lineHeight: "13px",
                                                paddingLeft: "10px",
                                            }}
                                        >
                                            {tutor.nombre} {tutor.apellido}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="client-info-title text-uppercase text-right">
                                            Cédula/RNC:
                                        </td>
                                        <td style={{ paddingLeft: "10px" }}>
                                            {tutor.cedula} / {tutor.pivot.nfc}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="client-info-title text-uppercase text-right">
                                            Teléfono:
                                        </td>
                                        <td style={{ paddingLeft: "10px" }}>
                                            {tutor.telefono}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="client-info-title text-uppercase text-right">
                                            Dirección:
                                        </td>
                                        <td
                                            style={{
                                                lineHeight: "13px",
                                                paddingLeft: "10px",
                                            }}
                                        >
                                            {tutor.direccion}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                    <div
                        className="info-block"
                    >
                        <table>
                            <tbody id="info-block-2">
                                <tr>
                                    <td colSpan={2} style={{ paddingLeft: "10px" }}>
                                        <h3
                                            style={{
                                                fontSize: "0.9rem",
                                                textAlign: "right",
                                            }}
                                        >
                                            {status}
                                        </h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <h3
                                            style={{
                                                fontSize: "0.9rem",
                                                marginBottom: "3px",
                                                paddingBottom: "10px",
                                                whiteSpace: "nowrap",
                                                paddingLeft: "50%",
                                                textAlign: "right",
                                            }}
                                        >
                                            Recibo de Ingreso
                                        </h3>
                                    </td>
                                </tr>
                                {recibo.fecha && (
                                    <tr>
                                        <td className="client-info-title text-uppercase text-right">
                                            Fecha:
                                        </td>
                                        <td style={{ paddingLeft: "10px" }}>
                                            {dayjs(recibo.fecha).format(
                                                "DD/MM/YYYY"
                                            )}
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="client-info-title text-uppercase text-right">
                                        No.:
                                    </td>
                                    <td style={{ paddingLeft: "10px" }}>
                                        {recibo.numeracion}
                                    </td>
                                </tr>
                                {reciboIngreso?.tipo_pago && (
                                    <tr>
                                        <td className="client-info-title text-uppercase text-right">
                                            MÉTODO DE PAGO:
                                        </td>
                                        <td style={{ paddingLeft: "10px", textTransform: "capitalize" }}>
                                            {reciboIngreso.tipo_pago}
                                        </td>
                                    </tr>
                                )}
                                {reciboIngreso?.tipo_pago === "cheque" && reciboIngreso?.detalle_metodo_pago?.num_cheque && (
                                    <tr>
                                        <td className="client-info-title text-uppercase text-right">
                                            NÚMERO CHEQUE:
                                        </td>
                                        <td style={{ paddingLeft: "10px", textTransform: "uppercase" }}>
                                            {reciboIngreso.detalle_metodo_pago.num_cheque}
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="client-info-title text-uppercase text-right">
                                        Período Académico:
                                    </td>
                                    <td style={{ paddingLeft: "10px", textTransform: "uppercase" }}>
                                        {curso?.periodoAcademico?.nombre}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="client-info-title text-uppercase text-right">
                                        Grupo:
                                    </td>
                                    <td style={{ paddingLeft: "10px", textTransform: "uppercase" }}>
                                        {curso?.grupoPago}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <hr />
                <div className="clear-both info-row p-b-10">
                    <div className="info-block" style={{ width: "65%" }}>
                        <table>
                            <tbody id="info-block-1">
                                <tr>
                                    <td
                                        width="120px"
                                        className="client-info-title text-uppercase text-right"
                                    >
                                        Estudiante:
                                    </td>
                                    <td
                                        style={{
                                            lineHeight: "13px",
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        {estudiante?.nombreCompleto}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="client-info-title text-uppercase text-right">
                                        Matrícula:
                                    </td>
                                    <td style={{ paddingLeft: "10px" }}>
                                        {estudiante?.matricula}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="client-info-title text-uppercase text-right">
                                        CORREO:
                                    </td>
                                    <td style={{ paddingLeft: "10px" }}>
                                        {estudiante?.correo}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="info-block" style={{ width: "65%" }}>
                        <table>
                            <tbody id="info-block-1">
                                <tr>
                                    <td
                                        width="120px"
                                        className="client-info-title text-uppercase text-right"
                                    >
                                        Curso:
                                    </td>
                                    <td
                                        style={{
                                            lineHeight: "13px",
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        {curso?.grado.nombre}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="client-info-title text-uppercase text-right">
                                        Sección:
                                    </td>
                                    <td style={{ paddingLeft: "10px" }}>
                                        {curso?.seccion?.nombre}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="client-info-title text-uppercase text-right">
                                        Aula:
                                    </td>
                                    <td style={{ paddingLeft: "10px" }}>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="table-wrapper">
                    <h3 className="table-title"></h3>
                    <table className="table d-none d-sm-table">
                        <thead>
                            <tr className="text-uppercase">
                                <th>Descripción</th>
                                <th className="text-right">Cuota</th>
                                {recibo?.servicios?.find(s => s.descuento > 0) && (
                                    <th className="text-right">Desc. Evaluación</th>
                                )}
                                <th className="text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recibo?.servicios?.map((servicio) => {
                                return (
                                    <tr key={servicio.id}>
                                        <td>
                                            <div className="text-uppercase">{servicio.nombre}</div>
                                            <hr />
                                            <div>
                                                Inicio Clases: {dayjs(curso?.periodoAcademico?.fechaInicio).format('DD/MM/YYYY')}<br/>
                                                Cuota Mensual: {formatCurrency(parseFloat(precioMensualidad?.monto))}
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            {formatCurrency(servicio.monto)}
                                        </td>
                                        {servicio.descuento > 0 && (
                                            <td className="text-right">{formatCurrency(servicio.descuento)}</td>
                                        )}
                                        <td className="text-right">
                                            {formatCurrency(servicio.abonado)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="summary mt-sm-3">
                    <div className="row justify-content-between justify-content-md-end">
                        <div
                            className="col col-md-3 text-sm-right"
                            style={{
                                maxWidth: "10rem",
                                lineHeight: "14px",
                                fontWeight: "600",
                            }}
                        >
                            TOTAL PAGADO
                        </div>
                        <div
                            className="col-2 text-right"
                            style={{
                                maxWidth: "8rem",
                                lineHeight: "14px",
                                fontWeight: "600",
                            }}
                        >
                            {formatCurrency(recibo.total)}
                        </div>
                    </div>
                </div>

                <hr className="d-block d-sm-none mt-0 mb-4" />

                {recibo.notas && (
                    <>
                        <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                            Notas:
                        </p>
                        <p
                            className="col-12 col-md-8 pl-0 mb-3"
                            style={{
                                fontSize: "1rem",
                                whiteSpace: "pre-line",
                                lineHeight: "normal",
                            }}
                        >
                            {recibo.notas}
                        </p>
                    </>
                )}
                <hr />
                <div
                    className="col-md-12 pl-0 mb-3 m-t-20"
                    style={{
                        fontSize: "1rem",
                        whiteSpace: "pre-line",
                        lineHeight: "normal",
                    }}
                >
                    {sucursal.id == 8 ? (
                        <>
                            <strong>NOTA(S)</strong><br />
                            <ol className="p-l-30 p-t-10">
                                <li>CORREO INSTITUCIONAL DEL ESTUDIANTE: el correo se forma con el primer nombre completo y las iniciales del segundo nombre al igual que los apellidos, finalizando con los últimos dígitos de nacimiento con el dominio institucional @colegiopadreemilianotardif.edu.do. EJEMPLO: Laura Patricia Mercado Torres, Año de nacimiento 2011 (laurapmt11@colegiopadreemilianotardif.edu.do).<br />
                            CLAVE TEMPORAL: Recibirá una clave antes del inicio de clases para que pueda cambiarla por una de su preferencia que sea fácil de recordar y un video tutorial de cómo ingresar. Todo esto vía el grupo de WhatsApp del curso asignado.</li>
                                <li>
                                    SE PAGAN DIEZ CUOTAS independientemente de las fechas de inicio o finalización de clases.
                                </li>
                                <li>
                                    Recordar que su compromiso de pago siempre será del 25 al 30 de cada mes.
                                </li>
                            </ol>
                        </>
                    ) : (
                        <>
                            <strong>NOTA(S)</strong><br />
                            <ol className="p-l-30 p-t-10">
                                <li>
                                    SE PAGAN ONCE CUOTAS independientemente de las fechas de inicio o finalización de clases.
                                </li>
                                <li>
                                    Recordar que su compromiso de pago siempre será del 25 al 30 de cada mes.
                                </li>
                            </ol>
                        </>

                    )}
                </div>
                <style>{`
                    .table-bordered {
                        border: 1px solid #777;
                    }
                    .table-bordered td {
                        border: 1px solid #777;
                        padding: 5px 15px !important;
                    }
                `}</style>
                <div className="row m-t-25">
                    <div className="col-md-8">
                        <table className="table table-bordered" style={{border: '1px solid #777'}}>
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="text-center"><strong>DETALLES ANUALIDAD</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Anualidad</strong></td>
                                    <td>{formatCurrency(parseFloat(precioMensualidad?.monto) * 10)}</td>
                                    <td><strong>Cuota Mensual</strong></td>
                                    <td>{formatCurrency(parseFloat(precioMensualidad?.monto))}</td>
                                </tr>
                                <tr>
                                    <td><strong>{isReservaCupo ? "Reserva Cupo" : "Inscripción"}</strong></td>
                                    <td>{formatCurrency(isReservaCupo ? precioReservaCupo?.monto : precioInscripcion?.monto)}</td>
                                    <td><strong>Descuento</strong></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Monto Neto Anual</strong></td>
                                    <td>{formatCurrency((parseFloat(precioMensualidad?.monto) * 10) + parseFloat(isReservaCupo ? precioReservaCupo?.monto : precioInscripcion?.monto))}</td>
                                    <td><strong>Monto Auspiciado</strong></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-1"></div>
                    <h3
                        className="col-md-3 text-center ml-0 fw-600 m-t-100 signature-title"
                        style={{ borderTop: "1px solid #000" }}
                    >
                        <div>Firma y Sello</div>
                        <small>({usuario.nombre} {usuario.apellido})</small>
                    </h3>
                </div>

                {recibo.plantilla?.pie_pagina && recibo.tipo_recibo === "ingreso" && (
                    <div
                        className="col-12 pt-5 text-center d-flex justify-content-center"
                        style={{
                            fontWeight: "600",
                            fontSize: "1rem",
                            whiteSpace: "pre-line",
                            lineHeight: "normal",
                        }}
                    >
                        <p className="text-center pie-pagina">
                            {recibo.plantilla?.pie_pagina}
                        </p>
                    </div>
                )}
            </div>
			<div className="ordernar-resultados no-print">
				<div className="form-group">
					<div className="radio-btn">
						<label>
							<input
								type="radio"
								onChange={() =>
									onChangeStatusRecibo("Original")
								}
								name="orden"
								value="Original"
								defaultChecked={true}
							/>{" "}
							Original
						</label>
						<label>
							<input
								type="radio"
								onChange={() => onChangeStatusRecibo("Copia")}
								name="orden"
								value="Copia"
							/>{" "}
							Copia
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrintSingleRecibo;
