import React, { Component } from "react";

import { withRequest } from "../../../utils/request";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../../general/Loader";
import { Link } from "react-router-dom";
import { getLink } from "../../../utils/helpers";
import ProductoFotos from "../../Productos/ProductoFotos";

class Sucursales extends Component {
	state = {
		sucursales: {},
		sucursal: {},
		links: null,
		showCreate: false,
		showEdit: false,
		showFotos: false,
		loading: true,
	};
	componentDidMount() {
		this.getSucursales();
	}
	getSucursales = async (url = null) => {
		url = url ? url : "sucursales";
		const sucursales = await this.props.request(url);

		if (sucursales.data) {
			this.setState({
				sucursales: sucursales.data,
				loading: false,
			});
		}
	};
	toggleCreateModal = () => {
		this.setState({
			showCreate: !this.state.showCreate,
			sucursal: { operadores: [] },
		});
	};
	toggleEditModal = (sucursal) => {
		this.setState({
			showEdit: !this.state.showEdit,
			sucursal: sucursal,
		});
	};
	toggleFotosModal = (sucursal) => {
		this.setState({
			showFotos: !this.state.showFotos,
			sucursal: sucursal,
		});
	};
	render() {
		const { sucursales, loading } = this.state;
		return (
			<div>
				<div className="page-header">
					<Link to={getLink("/configuracion/")}>
						&laquo; Volver a configuración
					</Link>
					<h1 className="page-title row">
						<span className="col">Sucursales</span>
						<button
							className="au-btn au-btn-icon au-btn--green au-btn--small"
							onClick={this.toggleCreateModal}
						>
							<i className="zmdi zmdi-plus" />
							Añadir Sucursal
						</button>
					</h1>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div>
							{loading && <Loader />}
							{!sucursales.length ? (
								<div>
									{!loading && (
										<div>
											Todavía no hay categorías creadas.
										</div>
									)}
								</div>
							) : (
								<div className="table-data">
									<table className="table table-data2">
										<thead>
											<tr>
												<th>Nombre</th>
												<th>Dirección</th>
												<th>Tipo</th>
												<th>Logo</th>
												<th />
											</tr>
										</thead>
										<tbody>
											{sucursales.map((sucursal) => (
												<tr
													className="tr-shadow"
													key={sucursal.id}
												>
													<td>{sucursal.nombre}</td>
													<td>
														{sucursal.direccion}
													</td>
													<td>{sucursal.tipo}</td>
													<td>
														{sucursal?.logo && (
															<button
																title="Ver Fotos"
																onClick={() =>
																	this.toggleFotosModal(
																		sucursal
																	)
																}
																className="item"
															>
																<i className="fas fa-eye"></i>
															</button>
														)}
													</td>
													<td>
														<div className="table-data-feature">
															<Link
																to={getLink(
																	`/sucursales/${sucursal.id}/editar`
																)}
																className="item"
															>
																<i className="zmdi zmdi-edit" />
															</Link>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									{this.state.links && (
										<Pagination
											links={this.state.links}
											meta={this.state.meta}
											getData={this.getSucursales}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				<Modal
					show={this.state.showCreate}
					blank={true}
					toggle={this.toggleCreateModal}
				>
					<Create
						toggle={this.toggleCreateModal}
						sucursal={this.state.sucursal}
						sucursales={sucursales}
						getSucursales={this.getSucursales}
					/>
				</Modal>
				<Modal
					show={this.state.showEdit}
					blank={true}
					toggle={() => this.toggleEditModal({})}
				>
					<Edit
						toggle={() => this.toggleEditModal({})}
						sucursal={this.state.sucursal}
						sucursales={sucursales}
						getSucursales={this.getSucursales}
					/>
				</Modal>
				{this.state.sucursal?.logo && (
					<Modal
						show={this.state.showFotos}
						blank={true}
						toggle={() => {
							this.setState({
								showEnviar: !this.state.showFotos,
							});
						}}
					>
						<ProductoFotos
							toggle={() => {
								this.setState({
									showFotos: !this.state.showFotos,
								});
							}}
							fotos={this.state.sucursal.logo.map(
								(foto) => foto.url
							)}
						/>
					</Modal>
				)}
			</div>
		);
	}
}

export default withRequest(Sucursales);
