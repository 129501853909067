import React, { Component } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import Select from "react-select";
import SelectAsync from "react-select/async";

import { downloadFile, withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Loader from "../general/Loader";
import { getLink } from "../../utils/helpers";
import Modal from "../general/Modal";
import ReporteNominaTss from "../Reportes/Form/ReporteNominaTss";

class Empleados extends Component {
    state = {
        empleados: [],
        links: [],
        meta: [],
        tipos: [],
        gruposEmpleados: [],
        filtros: {
            buscar: "",
            tipo: "",
            estado: "todos",
        },
        loading: true,
        showCompararTss: false,
        filtrosQuery: "",
    };

    componentDidMount() {
        this.getEmpleados();
        this.getTipos();
        this.getTable("grupos-tipos-empleados?estado=activo", "gruposEmpleados");
    }

    getOptions = async (url = null, label = "nombre", value = "id") => {
		const table = await this.props.request(url);
		if (table.data) {
			return table.data.map((item) => ({
				value: item[value],
				label: item[label],
			}));
		}
	};

    getCategorias = async (e) => {
        const categorias = await this.getOptions("taxonomias?relacion=empleados&buscar=" + e);
        const label = "Sin Categoría";
        const value = "sin categoria";

        if (label.includes(e.toLowerCase()) || value.includes(e.toLowerCase())) {
            categorias.push({ value, label });
        }

        return categorias;
    }

    getEmpleados = async (url = null) => {
        url = url ? url + "&limit=12" : "empleados?limit=12";
        const empleados = await this.props.request(url);

        if (empleados.links) {
            this.setState({
                empleados: empleados.data,
                links: empleados.links,
                meta: empleados.meta,
                loading: false,
            });
        }
    };

    getTipos = async () => {
        const tipos = await this.props.request("tipos", this.signal);

        if (tipos.data && tipos.data.length) {
            this.setState({
                tipos: tipos.data,
            });
        }
    };

    getTable = async (url, name = null) => {
        const request = await this.props.request(url);
        const table = name ? name : url;

        if (request.data) {
            this.setState({
                [table]: request.data,
            });
            this.setState({
                loading: false,
            });
        }
    };

    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: encodeURIComponent(value),
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getEmpleados("empleados?" + query.join("&"));
    }, 500);

    toggleCompararTssModal = () => {
        this.setState({
            showCompararTss: !this.state.showCompararTss
        });
    }

    displayFilters = () => {
        document.getElementById('mas-filtros').classList.toggle('d-none');
    }

    DownloadAperturaBR = () => {
        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        downloadFile(`plantillas/empleados/BR?${query.join("&")}`, 'DBE-033.xlsx');
    };

    render() {
        const { empleados, loading, tipos, filtrosQuery, gruposEmpleados } = this.state;
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Listado de Empleados</span>

                        <div className='dropdown m-r-10'>
                            <button className='au-btn au-btn-icon au-btn-filter'>
                                <i className='zmdi zmdi-widgets'></i> Acciones
                            </button>
                            <ul
                                style={{ right: "0", left: "auto" }}
                                className='dropdown-menu'>
                                <li>
                                    <Link
                                        to={getLink(
                                            `/imprimir/empleados${filtrosQuery}`
                                            )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        {" "}
                                        Listado Empleados
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={getLink(
                                            `/imprimir/empleados/detalle${filtrosQuery}`
                                            )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        {" "}
                                        Detalle Empleados
                                    </Link>
                                </li>
                                <li>
                                    <button onClick={this.toggleCompararTssModal} className="dropdown-item">Comparar Nómina y TSS</button>
                                    <Link
                                        to={getLink(
                                            `/imprimir/empleados/cumpleanios${filtrosQuery}`
                                            )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        {" "}
                                        Cumpleaños
                                    </Link>
                                </li>
                                <li className='subdropdown' style={{position: 'relative'}}>
                                    <button className='dropdown-item'>
                                        Salario Navidad <i className='fas fa-angle-right'></i>
                                    </button>
                                    <div
                                    /* style={{ right: "auto", left: "100%", top: 0 }} */
                                    className='dropdown-menu dropdown-submenu'>
                                        <Link
                                            to={getLink(
                                                `/imprimir/empleados/salario-navidad${filtrosQuery}`
                                                )}
                                            target='_blank'
                                            className='dropdown-item'>
                                            {" "}
                                            Salario Navidad
                                        </Link>
                                        <Link
                                            to={getLink(
                                                `/imprimir/empleados/salario-navidad-detalle${filtrosQuery}`
                                                )}
                                            target='_blank'
                                            className='dropdown-item'>
                                            {" "}
                                            Salario Navidad Detalle
                                        </Link>
                                        <Link
                                            to={getLink(
                                                `/imprimir/empleados/sobre-salario-navidad${filtrosQuery}`
                                                )}
                                            target='_blank'
                                            className='dropdown-item'>
                                            {" "}
                                            Sobres Salario Navidad
                                        </Link>
                                        <Link
                                            to={getLink(
                                                `/imprimir/empleados/salario-navidad-desglose${filtrosQuery}`
                                                )}
                                            target='_blank'
                                            className='dropdown-item'>
                                            {" "}
                                            Desglose Salario Navidad
                                        </Link>
                                        <Link
                                            to={getLink(
                                                `/imprimir/empleados/comprobante-salario-navidad${filtrosQuery}`
                                                )}
                                            target='_blank'
                                            className='dropdown-item'>
                                            {" "}
                                            Volantes Salario Navidad
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <button onClick={this.DownloadAperturaBR} className="dropdown-item">Apertura de cuenta BR</button>
                                </li>
                            </ul>
                        </div>
                        <Link
                            to={getLink("/empleados/crear")}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Empleado
                        </Link>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='search'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='tipo'>Cargo</label>
                                    <Select
                                        name='tipo'
                                        id='tipo'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "tipo"
                                            )
                                        }
                                        options={[
                                            {value: '-1', label: 'Todos'},
                                            ...tipos.map(tipo => (
                                                {value: tipo.id, label: tipo.nombre}
                                            ))
                                        ]}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Estado</label>
                                    <Select
                                        name='estado'
                                        id='estado'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "estado"
                                            )
                                        }
                                        options={[
                                            {value: 'todos', label: 'Todos'},
                                            {value: 'activos', label: 'Activos'},
                                            {value: 'inactivos', label: 'Inactivos'},
                                        ]}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='informal'>Tipo</label>
                                    <Select
                                        name='informal'
                                        id='informal'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "informal"
                                            )
                                        }
                                        options={[
                                            {value: 'todos', label: 'Todos'},
                                            {value: 'formal', label: 'Formal'},
                                            {value: 'informal', label: 'Informal'},
                                        ]}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='tipo'>Grupo</label>
                                    <Select
                                        name='grupos_tipos'
                                        id='grupos_tipos'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "grupos_tipos"
                                            )
                                        }
                                        options={[
                                            {value: '-1', label: 'Todos'},
                                            ...gruposEmpleados.map(tipo => (
                                                {value: tipo.id, label: tipo.nombre}
                                            ))
                                        ]}
                                    />
                                </div>
                                <div style={{width: '50px'}} className="filtros-avanzados" onClick={this.displayFilters}>
                                    <label>&nbsp;</label>
                                    <button className="btn" title="Filtros Avanzados"><i className="zmdi zmdi-filter-list"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mas-filtros" className="row mas-filtros d-none m-b-25">
                        <div className='col-md-2'>
                            <label htmlFor='periodo'>Periodo</label>
                            <Select
                                name='periodo'
                                id='periodo'
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.value,
                                        "periodo"
                                    )
                                }
                                options={[
                                    {value: 'todos', label: 'Todos'},
                                    {value: 'semanal', label: 'Semanal'},
                                    {value: 'quincenal', label: 'Quincenal'},
                                    {value: 'mensual', label: 'Mensual'},
                                ]}
                            />
                        </div>
                        <div className='col-md-2'>
                            <label htmlFor='desde'>Tipo de Sangre</label>
                            <Select
                                name='grupo_sanguineo'
                                isClearable
                                options={[
                                    {label: 'Todos', value: 'todos'},
                                    {label: 'A+', value: 'A+'},
                                    {label: 'A-', value: 'A-'},
                                    {label: 'B+', value: 'B+'},
                                    {label: 'B-', value: 'B-'},
                                    {label: 'AB+', value: 'AB+'},
                                    {label: 'AB-', value: 'AB-'},
                                    {label: 'O+', value: 'O+'},
                                    {label: 'O-', value: 'O-'},
                                ]}
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.value,
                                        "grupo_sanguineo"
                                    )
                                }
                                />
                        </div>
                        <div className='col-md-2'>
                            <label>Sexo</label>
                            <Select
                                name='empleado'
                                isClearable
                                options={[
                                    {label: 'Todos', value: 'todos'},
                                    {label: 'Masculino', value: 'M'},
                                    {label: 'Femenino', value: 'F'},
                                ]}
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.value,
                                        "sexo"
                                    )
                                }
                                />
                        </div>
                        <div className='col-md-2'>
                            <label>Fecha de Ingreso Desde</label>
                            <input
                                type="date"
                                name='fecha_ingreso_desde'
                                className="form-control"
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.target.value,
                                        "fecha_ingreso_desde"
                                    )
                                }
                                />
                        </div>
                        <div className='col-md-2'>
                            <label>Fecha de Ingreso Hasta</label>
                            <input
                                type="date"
                                name='fecha_ingreso_hasta'
                                className="form-control"
                                onChange={(e) =>
                                    this.onFiltroChange(
                                        e.target.value,
                                        "fecha_ingreso_hasta"
                                    )
                                }
                                />
                        </div>
                        <div className='col-md-2'>
                            <label>Categoría</label>
                            <SelectAsync
								name="categorias"
								isMulti
								cacheOptions
								defaultOptions
								onChange={(e) => {
									this.onFiltroChange(e.map(e => e.value).join(','), "categorias");
                                }}
								loadOptions={(e) => {
									return this.getCategorias(e);
								}}
							/>
                        </div>
                    </div>
                </div>
                <div className='empleados-list'>
                    {loading && <Loader />}
                    {!empleados.length ? (
                        <div>
                            {!loading && <div>No hay Empleados creados.</div>}
                        </div>
                    ) : (
                        <div className='row'>
                            {empleados.map((empleado) => (
                                <EmpleadoCard
                                    key={empleado.id}
                                    empleado={empleado}
                                />
                            ))}
                            <Pagination
                                links={this.state.links}
                                meta={this.state.meta}
                                getData={this.getEmpleados}
                            />
                        </div>
                    )}
                </div>

                <Modal
                    show={this.state.showCompararTss}
                    blank
                    title="Comparar Listado de Empleados con TSS"
                    toggle={this.toggleCompararTssModal}>
                        <ReporteNominaTss toggle={this.toggleCompararTssModal} />
                </Modal>

            </div>
        );
    }
}

const EmpleadoCard = (props) => {
    const { empleado } = props;
    return (
        <div className='col-md-4'>
            <div
                className={
                    "card empleado-card " +
                    (empleado.active ? "activo" : "inactivo")
                }>
                <div className='card-body'>
                    <div className='row empleado-header'>
                        {empleado.foto && <div className='col-md-3'></div>}
                        <div className='col'>
                            <h3 className='nombre'>
                                {empleado.nombreCompleto}
                                <Link
                                    to={getLink(
                                        `/empleados/${empleado.id}/informacion`
                                    )}
                                    className='item'>
                                    <i className='zmdi zmdi-edit' />
                                </Link>
                            </h3>
                            <div className='cargo'>
                                {empleado.cargo}{" "}
                                {empleado.licencia
                                    ? `- ${empleado.licencia}`
                                    : ""}{" "}
                                {empleado.informal !== 0 && (
                                    <span className='badge badge-warning'>
                                        Informal
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {empleado.foto && (
                            <div className='col-md-3'>
                                <span className='page-title-foto'>
                                    <img
                                        src={empleado.foto}
                                        alt={empleado.codigo}
                                    />
                                </span>
                            </div>
                        )}
                        <div className='col'>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>
                                            <i className='zmdi zmdi-assignment-account'></i>
                                        </th>
                                        <td>{empleado.cedula}</td>
                                    </tr>
                                    {empleado.telefono && (
                                        <tr>
                                            <th>
                                                <i className='zmdi zmdi-smartphone'></i>
                                            </th>
                                            <td>{empleado.telefono}</td>
                                        </tr>
                                    )}
                                    {empleado.direccion && (
                                        <tr>
                                            <th>
                                                <i className='zmdi zmdi-pin'></i>
                                            </th>
                                            <td>{empleado.direccion}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRequest(Empleados);
