/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth } from "../../../utils/auth";
import { request } from "../../../utils/request";
import InvalidScreen from "../../general/InvalidScreen";
import PageHeader from "../../general/PageHeader";
import Form from "./Components/OrdenForm";

const EditOrden = () => {
	const { id } = useParams();
	const [orden, setOrden] = useState({});

	const getOrden = () => {
		request("ordenes-compra/" + id).then((res) => {
			setOrden(res.data);
		});
	};

	useEffect(() => {
		getOrden();
	}, []);

	if ((auth.is("vendedor") || auth.is("vendedor-factura-orden_compra") || auth.is("cobrador") || auth.is('vendedor-cliente')) && !localStorage.getItem("rutas")) {
		return <InvalidScreen message="No hay zonas seleccionadas" />;
	}

	return (
		<>
			<PageHeader title="Editar Orden de Compra" hasButton={false} />
			<Form ordenActual={orden} isEdit={true} />
		</>
	);
};

export default EditOrden;
