import React, { Component } from "react";
import dayjs from "dayjs";
import Select from "react-select";
import SelectCreateAsync from "react-select/async-creatable";

import FotoField from "../general/FotoField";
import { bancos } from "../../utils/helpers";
import { apiRequest } from "../../hooks/apiRequest";

class Form extends Component {
    state = {
        cargo: "",
        operaVehiculo: false,
    };
    componentDidMount() {
        this.setState({
            cargo: this.props.empleado.tipo,
            operaVehiculo: this.props.empleado.operaVehiculo,
        });
    }
    onInputChange = (e) => {
        const tipo = this.getTipo(e.target.value);

        this.setState({
            cargo: tipo.nombre,
            operaVehiculo: tipo.operaVehiculo,
        });
    };
    getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };
    getTipo = (id) => {
        return this.props.tipos.filter((tipo) => tipo.id === parseInt(id))[0];
    };
    render() {
        const { empleado, errors, tipos, codigo } = this.props;
        const { cargo, operaVehiculo } = this.state;
        return (
            <div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Nombre:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='nombre'
                                name='nombre'
                                defaultValue={empleado.nombre}
                            />
                            {errors.nombre && (
                                <small className='help-blockParams form-Text'>
                                    {errors.nombre[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Apellido:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='apellido'
                                name='apellido'
                                defaultValue={empleado.apellido}
                            />
                            {errors.apellido && (
                                <small className='help-blockParams form-Text'>
                                    {errors.apellido[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Cédula:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='cedula'
                                name='cedula'
                                defaultValue={empleado.cedula}
                            />
                            {errors.cedula && (
                                <small className='help-blockParams form-Text'>
                                    {errors.cedula[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Fecha de Nacimiento:
                            </label>
                            <div className='input-group'>
                                <div className='input-group-addon'>
                                    <i className='far fa-calendar' />
                                </div>
                                <input
                                    type='date'
                                    className='text-input form-control'
                                    defaultValue={empleado.fechaNacimiento}
                                    name='fecha_nacimiento'
                                />
                            </div>
                            {errors.fecha_nacimiento && (
                                <small className='help-blockParams form-Text'>
                                    {errors.fecha_nacimiento[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Sexo:
                            </label>
                            <select
                                className='text-input form-control'
                                id='sexo'
                                name='sexo'
                                defaultValue={empleado.sexo}
                            >
                                <option value="">Seleccionar...</option>
                                <option value="M">Masculino</option>
                                <option value="F">Femenino</option>
                            </select>
                            {errors.sexo && (
                                <small className='help-blockParams form-Text'>
                                    {errors.sexo[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Tipo Sanguineo:
                            </label>
                            <select
                                className='text-input form-control'
                                id='grupo_sanguineo'
                                name='grupo_sanguineo'
                                defaultValue={empleado.grupo_sanguineo}
                            >
                                <option value="">Seleccionar...</option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                            </select>
                            {errors.grupo_sanguineo && (
                                <small className='help-blockParams form-Text'>
                                    {errors.grupo_sanguineo[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Dirección:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='direccion'
                                name='direccion'
                                rows='7'
                                defaultValue={empleado.direccion}
                            />
                            {errors.direccion && (
                                <small className='help-blockParams form-Text'>
                                    {errors.direccion[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Estado Civil:
                            </label>
                            <select
                                key={empleado.id}
                                className='text-input form-control'
                                id='estado_civil'
                                name='estado_civil'
                                defaultValue={empleado.estadoCivil}>
                                <option value='soltero'>Soltero</option>
                                <option value='casado'>Casado</option>
                                <option value='union-libre'>Unión Libre</option>
                            </select>
                            {errors.estado_civil && (
                                <small className='help-blockParams form-Text'>
                                    {errors.estado_civil[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Foto:
                            </label>
                            <FotoField
                                foto={empleado.foto}
                                fotoCed={empleado.fotoCed}
                                name='foto'
                            />
                        </div>
                    </div>
                </div>
                <div className='form-separator' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Cargo:
                            </label>
                            {tipos.length > 0 && (
                                <select
                                    key={empleado.id}
                                    className='text-input form-control'
                                    id='tipo_id'
                                    onChange={this.onInputChange}
                                    defaultValue={empleado.tipoId}
                                    name='tipo_id'>
                                    <option value=''>- Seleccionar -</option>
                                    {tipos.map((tipo) => (
                                        <option key={tipo.id} value={tipo.id}>
                                            {tipo.nombre}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {errors.tipo_id && (
                                <small className='help-blockParams form-Text'>
                                    {errors.tipo_id[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Código: {codigo && <small>Último Utilizado: {codigo.ultimo}</small>}
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='codigo'
                                name='codigo'
                                defaultValue={empleado.nombre ? empleado.codigo : codigo.mayor}
                            />
                            {errors.codigo && (
                                <small className='help-blockParams form-Text'>
                                    {errors.codigo[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                {(cargo !== '' && operaVehiculo === 1) && (
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label className='label form-control-label'>
                                    Licencia:
                                </label>
                                <select
                                    type='text'
                                    className='text-input form-control'
                                    id='licencia'
                                    name='licencia'
                                    defaultValue={empleado.licencia}>
                                    <option value=''>- Seleccionar -</option>
                                    <option value='categoria-1'>
                                        Categoría 1
                                    </option>
                                    <option value='categoria-2'>
                                        Categoría 2
                                    </option>
                                    <option value='categoria-3'>
                                        Categoría 3
                                    </option>
                                    <option value='categoria-4'>
                                        Categoría 4
                                    </option>
                                    <option value='categoria-5'>
                                        Categoría 5
                                    </option>
                                </select>
                                {errors.licencia && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.licencia[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label className='label form-control-label'>
                                    Expiración Licencia:
                                </label>
                                <div className='input-group'>
                                    <div className='input-group-addon'>
                                        <i className='far fa-calendar' />
                                    </div>
                                    <input
                                        type='date'
                                        className='text-input form-control'
                                        defaultValue={empleado.licenciaExpira}
                                        name='licencia_expira'
                                    />
                                </div>
                                {errors.licencia_expira && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.licencia_expira[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Fecha Ingreso:
                            </label>
                            <div className='input-group'>
                                <div className='input-group-addon'>
                                    <i className='far fa-calendar' />
                                </div>
                                <input
                                    type='date'
                                    className='text-input form-control'
                                    defaultValue={empleado.fechaIngreso}
                                    name='fecha_ingreso'
                                />
                            </div>
                            {errors.fecha_ingreso && (
                                <small className='help-blockParams form-Text'>
                                    {errors.fecha_ingreso[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Fecha de Salida:
                            </label>
                            <div className='input-group'>
                                <div className='input-group-addon'>
                                    <i className='far fa-calendar' />
                                </div>
                                <input
                                    type='date'
                                    className='text-input form-control'
                                    defaultValue={empleado.fechaSalida}
                                    name='fecha_salida'
                                />
                            </div>
                            {errors.fecha_salida && (
                                <small className='help-blockParams form-Text'>
                                    {errors.fecha_salida[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='control-label'>Tipo:</label>
                            <select
                                className='form-control'
                                id='informal'
                                name='informal'
                                defaultValue={empleado.informal ? "si" : "no"}>
                                <option value='no'>Formal</option>
                                <option value='si'>Informal</option>
                            </select>
                            {errors.informal && (
                                <small className='help-blockParams form-Text'>
                                    {errors.informal[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label className='control-label'>Categoría:</label>
                            <SelectCreateAsync
                                placeholder="Seleccionar..."Create
                                key="categorias"
                                id='categorias'
                                name='categorias_empleados[]'
                                isMulti
                                required
                                cacheOptions
                                defaultOptions
                                defaultValue={empleado.categorias && empleado.categorias.map(c => ({label: c.nombre, value: c.id}))}
                                loadOptions={(e) => {
                                    return this.getOptions("taxonomias?relacion=empleados&buscar="+e);
                                }}
                            />
                            {errors.categorias_empleados && (
                                <small className='help-blockParams form-Text'>
                                    {errors.categorias_empleados[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div
                            className='form-group'>
                            <span style={{ marginRight: "15px" }}>Activo:</span>
                            <label className='switch switch-3d switch-success mr-3'>
                                <input type='hidden' name='active' value='no' />
                                <input
                                    key={empleado.id}
                                    type='checkbox'
                                    className='switch-input'
                                    id='active'
                                    name='active'
                                    value='si'
                                    defaultChecked={
                                        empleado.id ? empleado.active : true
                                    }
                                />
                                <span className='switch-label' />
                                <span className='switch-handle' />
                            </label>
                            {errors.active && (
                                <small className='help-blockParams form-Text'>
                                    {errors.active[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>

                <div className='form-separator' />

                <div className='row'>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Teléfono:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='telefono'
                                name='telefono'
                                defaultValue={empleado.telefono}
                            />
                            {errors.telefono && (
                                <small className='help-blockParams form-Text'>
                                    {errors.telefono[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Flota:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='flota'
                                name='flota'
                                defaultValue={empleado.flota}
                            />
                            {errors.flota && (
                                <small className='help-blockParams form-Text'>
                                    {errors.flota[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Correo Electrónico:
                            </label>
                            <input
                                type='email'
                                className='text-input form-control'
                                id='email'
                                name='email'
                                defaultValue={empleado.email}
                            />
                            {errors.email && (
                                <small className='help-blockParams form-Text'>
                                    {errors.email[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label className='label form-control-label'>
                                Institución Financiera:
                            </label>
                            <Select
                                isClearable
                                options={bancos}
                                name="institucion_financiera"
                                defaultValue={empleado.institucion_financiera ? {label: empleado.institucion_financiera, value: empleado.institucion_financiera} : null}
                            />
                            {errors.institucion_financiera && (
                                <small className='help-blockParams form-Text'>
                                    {errors.institucion_financiera[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label className='control-label'>Tipo de Cuenta:</label>
                            <Select
                                isClearable
                                options={[{
                                    label: 'Ahorros', value: 'Ahorros'
                                },{
                                    label: 'Corriente', value: 'Corriente'
                                },{
                                    label: 'Ahorros-Corriente', value: 'Ahorros-Corriente'
                                },{
                                    label: 'Otro', value: 'Otro'
                                }]}
                                name="tipo_cuenta"
                                defaultValue={empleado.tipo_cuenta ? {label: empleado.tipo_cuenta, value: empleado.tipo_cuenta} : null}
                            />
                            {errors.tipo_cuenta && (
                                <small className='help-blockParams form-Text'>
                                    {errors.tipo_cuenta[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label className='control-label'>Cuenta Bancaria:</label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='cuenta_bancaria'
                                name='cuenta_bancaria'
                                defaultValue={empleado.cuenta_bancaria}
                            />
                            {errors.cuenta_bancaria && (
                                <small className='help-blockParams form-Text'>
                                    {errors.cuenta_bancaria[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>

                <div className='form-separator' />

                <div className="row notas">
                    {empleado.comentarios && empleado.comentarios.map(comentario => (
                        <div className="col-md-12 nota" key={comentario.id}>
                            <i className="zmdi zmdi-comment-text"></i>
                            {comentario.descripcion}
                            <div className="nota-meta">Por {comentario.user} - {dayjs(comentario.created_at).format('MM/DD/YYYY')}</div>
                        </div>
                    ))}
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <h3>Agregar Nota</h3>
                            <textarea
                                key={empleado.id}
                                className='text-input form-control'
                                id='comentarios'
                                name='comentarios'
                                rows='7'
                                defaultValue={empleado.notas}
                            />
                            {errors.comentarios && (
                                <small className='help-blockParams form-Text'>
                                    {errors.comentarios[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
