import React from "react";
import SelectCreate from "react-select/creatable";

const Form = ({descuento, columnas}) => {
    return (
        <div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Nombre:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <input
                        type='text'
                        name='nombre'
                        defaultValue={descuento.nombre}
                        className='text-input form-control'
                    />
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Monto:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <input
                        type='number'
                        name='monto'
                        step='0.01'
                        defaultValue={descuento.monto}
                        className='text-input form-control'
                    />
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Columna Nómina:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <SelectCreate
                        defaultValue={descuento.columna ? {label: descuento.columna?.nombre, value: descuento.columna?.id} : null}
                        options={columnas.map(c => ({value: c.id, label: c.nombre}))}
                        name="columna_nomina"
                    />
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Notas:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <textarea
                        type='text'
                        name='descripcion'
                        rows='6'
                        defaultValue={descuento.descripcion}
                        className='text-input form-control'
                    />
                </div>
            </div>
        </div>
    );
}

export default Form;
