import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";

class PrintVehiculos extends Component {
    state = {
        vehiculos: [],
    };
    componentDidMount() {
        this.getVehiculos();
    }
    getVehiculos = async () => {
        const response = await this.props.request(
            "vehiculos" + window.location.search
        );

        if (response.data) {
            this.setState({
                vehiculos: response.data,
            });
            window.print();
        }
    };
    render() {
        const { vehiculos } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>Vehiculos</h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL VEHICULOS: {vehiculos.length}
                        </div>

                        <div className='float-right'>
                            <h3>VEHICULOS</h3>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Ficha</th>
                            <th>Marca/Modelo</th>
                            <th>Placa</th>
                            <th>Estado</th>
                            <th>Categoría</th>
                            <th>Propietario</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vehiculos.map((vehiculo) => {
                            return (
                                <tr key={vehiculo.id}>
                                    <td>{vehiculo.ficha}</td>
                                    <td>{vehiculo.modelo}</td>
                                    <td>{vehiculo.placa}</td>
                                    <td>{vehiculo.estado}</td>
                                    <td>{vehiculo.categoria}</td>
                                    <td>{vehiculo.propietario}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintVehiculos));
