import React, { useState } from "react";
import Select from 'react-select';
import SelectAsync from 'react-select/async';

import {bancos} from '../../utils/helpers';
import { apiRequest } from "../../hooks/apiRequest";

const Form  = ({cuenta, errors}) => {
    const [tipo, setTipo] = useState(cuenta.tipo ?? 'LOCAL');

    const onChangeInput = (e) => {
        setTipo(e.target.value);
    }

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };

    return (
        <div>
            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>
                        Nombre:
                    </label>
                </div>
                <div className='col col-md-7'>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='nombre'
                        name='nombre'
                        defaultValue={cuenta.nombre}
                    />
                    {errors.nombre && (
                        <small className='help-blockParams form-Text'>
                            {errors.nombre[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>
                        Moneda:
                    </label>
                </div>
                <div className='col col-md-7'>
                    <select name="moneda" defaultValue={cuenta.moneda} className="form-control">
                        <option value="DOP">Pesos (RD$)</option>
                        <option value="USD">Dólares (USD$)</option>
                    </select>
                        {errors.moneda && (
                            <small className='help-blockParams form-Text'>
                                {errors.moneda[0]}
                            </small>
                        )}
                </div>
            </div>
            {cuenta.balance <= 0 && <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>
                        Estado:
                    </label>
                </div>
                <div className='col col-md-7'>
                    <select name="estado" defaultValue={cuenta.estado} className="form-control">
                        <option value="ACTIVA">Activa</option>
                        <option value="INACTIVA">Inactiva</option>
                    </select>
                        {errors.estado && (
                            <small className='help-blockParams form-Text'>
                                {errors.estado[0]}
                            </small>
                        )}
                </div>
            </div>
            }
            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>
                        Tipo de Caja:
                    </label>
                </div>
                <div className='col col-md-7'>
                    <select className="form-control" name="tipo" value={tipo} onChange={e => onChangeInput(e)}>
                        <option value="LOCAL">Local</option>
                        <option value="BANCARIA">Bancaria</option>
                    </select>
                    {errors.tipo && (
                        <small className='help-blockParams form-Text'>
                            {errors.tipo[0]}
                        </small>
                    )}
                </div>
            </div>
            {tipo === 'LOCAL' && (
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Ubicación:
                        </label>
                    </div>
                    <div className='col col-md-7'>
                        <input type="text" className='text-input form-control' defaultValue={cuenta.ubicacion} name="ubicacion" />
                        {errors.ubicacion && (
                            <small className='help-blockParams form-Text'>
                                {errors.ubicacion[0]}
                            </small>
                        )}
                    </div>
                </div>
            )}
            {tipo === 'BANCARIA' && (
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Banco:
                        </label>
                    </div>
                    <div className='col col-md-7'>
                        <Select
                            options={bancos} name="banco" defaultValue={{label: cuenta.banco, value: cuenta.banco}}/>
                        {errors.banco && (
                            <small className='help-blockParams form-Text'>
                                {errors.banco[0]}
                            </small>
                        )}
                    </div>
                </div>
            )}
            {tipo === 'BANCARIA' && (
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Número de Cuenta:
                        </label>
                    </div>
                    <div className='col col-md-7'>
                        <input type="text" className='text-input form-control' defaultValue={cuenta.numeroCuenta} name="numero_cuenta"></input>
                        {errors.numero_cuenta && (
                            <small className='help-blockParams form-Text'>
                                {errors.numero_cuenta[0]}
                            </small>
                        )}
                    </div>
                </div>
            )}

            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>
                        Cuenta Contable:
                    </label>
                </div>
                <div className='col col-md-7'>
                    <SelectAsync
                        key="cuenta_contable"
                        name='cuenta_contable_id'
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        cacheOptions
                        defaultOptions
                        defaultValue={cuenta.cuentaContable && ({
                            label: cuenta.cuentaContable.nombre,
                            value: cuenta.cuentaContable.id,
                        })}
                        loadOptions={(e) => getOptions("cuentas-contables?buscar="+e)}
                    />
                    {errors.cuenta_contable_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.cuenta_contable_id[0]}
                        </small>
                    )}
                </div>
            </div>

            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>
                        Comentario:
                    </label>
                </div>
                <div className='col col-md-7'>
                    <textarea name="descripcion" defaultValue={cuenta.descripcion} className="form-control"></textarea>
                    {errors.descripcion && (
                        <small className='help-blockParams form-Text'>
                            {errors.descripcion[0]}
                        </small>
                    )}
                </div>
            </div>

        </div>
    );
}

export default Form;
