import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency, formatNumber } from "../../../utils/helpers";

class PrintHistorialVentas extends Component {
    state = {
        productos: [],
    };
    componentDidMount() {
        this.getProductos();
    }
    getProductos = async () => {
        const response = await this.props.request(
            "productos" + (window.location.search ? window.location.search + '&productsExist=1' : '?productsExist=1')
        );

        if (response.data) {
            this.setState({
                productos: response.data,
            });
            window.print();
        }
    };
    render() {
        const { productos } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const categorias = productos.reduce((acc, current) => {
            if (!acc[current.grupo?.nombre]) {
                acc[current.grupo?.nombre] = [];
            }
            acc[current.grupo?.nombre].push(current);
            return acc;
        }, {});

        return (
			<div style={{fontSize: '1.2em'}}>
				<div className="row">
					<h2 className="col-md-12 text-center m-b-5">
						Reporte Inventario Ventas
					</h2>
					<div className="col-md-12 text-center">
						{sucursal.nombre} - {sucursal.direccion} •{" "}
						{new Date().toLocaleString()}
					</div>
					<div className="col-md-12 text-right p-b-10">
						<div className="float-left">
							TOTAL PRODUCTOS: {productos.length}
						</div>
					</div>
				</div>

				{Object.keys(categorias).sort(function(a,b) {
                    let exceptions = [ "undefined" ], indexA, indexB; // Ordenando alfabeticamente, y buscando los productos sin suplidor para colocarlos de ultimo
                    indexA = exceptions.indexOf(a);
                    indexB = exceptions.indexOf(b);
                    if (indexA === -1 && indexB === -1) {
                        return a.toLowerCase().localeCompare(b.toLowerCase()); // regular case
                    }
                    return indexA - indexB; // index will be -1 (doesn't occur), 0 or 1
                }).map((categoria) => (
					<div className="seccion-producto">
						<table className="table print-productos m-b-30">
							<thead className="head-table-producto">
								<tr>
									<th colSpan="4">
										{categoria === "undefined"
											? "SIN CATEGORIZAR"
											: categoria}
									</th>
									<th colSpan="4" className="text-right">
										Productos:{" "}
										{categorias[categoria].length}
									</th>
								</tr>
								<tr>
									<th style={{ width: "120px" }}>Ref/Cód</th>
									<th>Nombre</th>
									<th className="text-right"
                                        style={{ width: "150px" }}
                                    >Disponible</th>
									<th
										className="text-right"
                                        style={{ width: "150px" }}
									>
										Precio 1
									</th>
									<th className="text-right" style={{ width: "150px" }}>Precio 2</th>
								</tr>
							</thead>
							<tbody>
								{categorias[categoria].map((producto) => {
									return (
										<tr key={producto.id}>
											<td>{producto.referencia}</td>
											<td>{producto.nombre}</td>
											<td className="text-right">
                                            {producto.cantidad != null ? formatNumber(producto.cantidad) : "0.0"}{" "}
                                            <small>{producto.unidad}</small>
											</td>
											<td className="text-right">
												{formatCurrency(
													producto.precios[0]?.total
												)}
											</td>
											<td className="text-right">
												{formatCurrency(
													producto.precios[1]?.total
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				))}

				<div className="row">
					<div className="col-md-12 text-right p-b-10">
						<div className="float-left">
							TOTAL PRODUCTOS: {productos.length}
						</div>
					</div>
				</div>
			</div>
		);
    }
}

export default withRouter(withRequest(PrintHistorialVentas));
