import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { auth } from "../../../utils/auth";
import { formatCurrency, formatNumber } from "../../../utils/helpers";
import { request } from "../../../utils/request";

const PrintProductosReorden = () => {
	const [productos, setProductos] = useState([]);

	const getProductos = async () => {
		const searchString = window.location.search.includes("?")
			? window.location.search + "&tipo=producto&orderBySuplidor=true"
			: "?tipo=producto&orderBySuplidor=true";
		const response = await request(`productos${searchString}`);

		if (response.data) {
			setProductos(response.data);
			setTimeout(() => {
				window.print();
			}, 500);
		}
	};

	useEffect(() => {
		getProductos();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];

	const valorTotal = productos.reduce((acc, producto) => {
		if (!producto.costo) {
			return acc;
		}
		return (
			acc + (producto.costo * (producto.impuesto?.porcentaje + 1))
		);
	}, 0);
	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">Reporte Reorden</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{dayjs().format("DD/MM/YYYY")}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL PRODUCTOS: {productos.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
			<table className="table m-b-40">
				<thead>
					<tr>
						<th>Producto</th>
						<th>Referencia</th>
						<th>Cantidad</th>
						<th>Reorden</th>
						<th>Costo</th>
						<th className="text-right">Suplidor</th>
						<th className="text-right">Teléfono</th>
					</tr>
				</thead>
				<tbody>
					{productos.map((producto) => {
						return (
							<tr key={producto.id}>
								<td>{producto.nombre}</td>
								<td>{producto.referencia}</td>
								<td>
									{isNaN(parseFloat(producto.cantidad))
										? "-"
										: formatNumber(producto.cantidad)}
								</td>
								<td>
									{isNaN(parseFloat(producto.reorden))
										? "-"
										: formatNumber(producto.reorden)}
								</td>
								<td>{formatCurrency(producto.costo * (producto.impuesto?.porcentaje + 1), "$")}</td>
								<td className="text-right">
									{producto.suplidor?.nombre}
								</td>
								<td className="text-right">
									{producto.suplidor?.telefono}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL PRODUCTOS: {productos.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrintProductosReorden;
