/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiRequest } from "../../../../hooks/apiRequest";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { apiUrl } from "../../../../utils/apiUrl";
import { auth } from "../../../../utils/auth";
import { formatCurrency, isPastDate, toast } from "../../../../utils/helpers";

export default () => {
	const { id } = useParams();
	const [transactions, setTransactions] = useState([]);
	const [contacto] = useLocalStorage("contacto", "");

	const getTransactions = async (url = null) => {
		if (url && url.includes(apiUrl(""))) {
			url = url.split(apiUrl(""))[1];
		}
		const transactions = await apiRequest(null, url, "get");

		if (transactions.success) {
			setTransactions(transactions.data.data);
			setTimeout(() => {
				window.print();
			}, 500);
			return;
		}

		toast("Error obteniendo transacciones", "error");
	};

	const formatDateTime = (date) => {
		return dayjs(date).format("DD/MM/YYYY, hh:mm a");
	};

	useEffect(() => {
		getTransactions(
			`contactos/${id}/transacciones${window.location.search}&pagination=1000`
		);
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotal = transactions
		? transactions.reduce((acc, factura) => {
				return acc + parseFloat(factura.total);
		  }, 0)
		: 0;

	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Historial de transacciones de {contacto.nombre}
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{new Date().toLocaleString()}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {transactions.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
			<table className="table m-b-40">
				<thead>
					<tr>
						<th>NCF/Numero</th>
						<th>Vendedor</th>
						<th>Creación</th>
						<th>Vencimiento</th>
						<th>Estado</th>
						<th>Fecha pagada</th>
						<th>Tipo</th>
						<th className="text-right">Total</th>
					</tr>
				</thead>
				<tbody>
					{transactions.map((transaction) => {
						return (
							<tr key={transaction.id}>
								<td>{transaction.numero_comprobante}</td>
								<td>{transaction.nombre_vendedor}</td>
								<td>
									{transaction.recurrencia_id && !isPastDate(transaction.fecha_emision) ? (
										<>
											{dayjs(
												transaction.fecha_emision
											).format("DD/MM/YYYY")}
										</>
									) : (
										<>
											{formatDateTime(
												transaction.created_at
											)}
										</>
									)}
								</td>
								<td>
									{new Intl.DateTimeFormat("es-ES").format(
										new Date(transaction.fecha_vencimiento)
									)}
								</td>
								<td className="text-capitalize">
									{transaction.estado}
								</td>
								<td>{transaction.estado === "pagada" ? dayjs(transaction.fecha_pago).format('DD/MM/YYYY, hh:mm a') : ""}</td>
								<td className="text-capitalize">
									{transaction.entidad &&
										transaction.entidad
											.split("-")
											.join(" de ")}
								</td>
								<td className="text-right">
									{formatCurrency(transaction.total)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {transactions.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
		</>
	);
};
