import React, { useState } from "react";
import Form from "./Form";
import { apiRequest } from "../../../../hooks/apiRequest";
import Loader from "../../../general/Loader";

const Create = ({inscripcion, estudiantes, becas, getInscripciones, clases, toggle}) => {
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});

    const createInscripcion = async (event) => {
        event.preventDefault();

        setSaving(true);

        const data = new FormData(event.target);
        const response = await apiRequest(data, 'academico/inscripciones-cocurriculares');

        if (response?.success) {
            getInscripciones();
            toggle();
        }
        if (response?.code === 422) {
            setErrors(response.data.message);
        }

        setSaving(false);
    };

    return (
        <form method='post' onSubmit={createInscripcion}>
            <div className='popup__header'>Inscribir</div>
            <div className='popup__body'>
                <Form
                    estudiantes={estudiantes}
                    becas={becas}
                    inscripcion={inscripcion}
                    errors={errors}
                    clases={clases}
                />
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                {saving && <Loader type="small"/>}
                {!saving && (
                    <button type='submit' className='btn btn-primary'>
                        Inscribir
                    </button>
                )}
            </div>
        </form>
    );
}

export default Create;
