import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";

class PrintDetalleVehiculos extends Component {
    state = {
        vehiculos: [],
    };
    componentDidMount() {
        this.getVehiculos();
    }
    getVehiculos = async () => {
        const response = await this.props.request(
            "vehiculos" + window.location.search
        );

        if (response.data) {
            this.setState({
                vehiculos: response.data,
            });
        }
    };
    render() {
        const { vehiculos } = this.state;

        return (
            <div>
                {vehiculos.map((vehiculo) => {
                    return (
                        <div className='detalle-vehiculo row p-t-15'>
                            <div className='col-md-3 text-center'>
                                {vehiculo.foto ? (
                                    <img
                                        style={{ maxWidth: "100%" }}
                                        alt={vehiculo.ficha}
                                        src={vehiculo.foto}
                                    />
                                ) : (
                                    <img
                                        style={{ width: "100px" }}
                                        alt={vehiculo.ficha}
                                        src='/images/trash.png'
                                    />
                                )}
                            </div>
                            <div className='col-md-9'>
                                <table className='detalle-vehiculo-table'>
                                    <tbody>
                                        <tr>
                                            <td colSpan='2'>
                                                <strong>
                                                    {vehiculo.ficha}
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='200px'>Marca/Modelo:</td>
                                            <td width='25%'>
                                                {vehiculo.marca}
                                            </td>
                                            <td width='200px'>Chasis:</td>
                                            <td width='25%'>
                                                {vehiculo.chasis}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Año:</td>
                                            <td>{vehiculo.ano}</td>
                                            <td>Placa:</td>
                                            <td>{vehiculo.placa}</td>
                                        </tr>
                                        <tr>
                                            <td>Capacidad Máxima (TONs):</td>
                                            <td>{vehiculo.capacidad}</td>
                                            <td>Tamaño Tanque (GLs):</td>
                                            <td>{vehiculo.tanque}</td>
                                        </tr>
                                        <tr>
                                            <td>Categoría:</td>
                                            <td>{vehiculo.categoria}</td>
                                            <td>Peso (TONs):</td>
                                            <td>{vehiculo.peso}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default withRouter(withRequest(PrintDetalleVehiculos));
