import React, { Component } from "react";
import Loader from "../../general/Loader";
import { formatCurrency } from "../../../utils/helpers";
import Pagination from "../../general/Pagination";
import { withRequest } from "../../../utils/request";
import dayjs from "dayjs";
import { debounce } from "lodash";

class Gastos extends Component {
    state = {
        gastos: [],
        meta: false,
        links: false,
        loading: true,
        filtrosQuery: "",
        filtros: [],
    };
    componentDidMount() {
        this.getGastos();
    }
    getGastos = async (url = null) => {
        const id = this.props.item.id;
        url = url ? url : `gastos-internos?item=${id}&limit=20`;
        const gastos = await this.props.request(url);

        if (gastos.data && gastos.data.length > 0) {
            this.setState({
                gastos: gastos.data,
                meta: gastos.meta,
                links: gastos.links,
                loading: false,
            });
        }

        this.setState({ loading: false });
    };

    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        this.getGastosFiltrados();
    }, 500);

    getGastosFiltrados = async () => {
        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getGastos("gastos-internos?limit=20&" + query.join("&"));
    };

    render() {
        const { gastos, meta, links } = this.state;
        const { item } = this.props;
        const { loading } = this.state;

        if (loading === true) {
            return <Loader />;
        }

        const gastosItems = gastos.reduce((acc, curr) => {
            const items = curr.items.filter(i => i.item?.id === item.id).map(i => {
                return {
                    ...i,
                    empleado: curr.empleado,
                    vehiculo: curr.vehiculo,
                    cuenta: curr.cuenta,
                    categorias: curr.categorias,
                    suplidor: curr.suplidor,
                    ruta: curr.ruta,
                }
            });
            return [...acc, ...items];
        }, []);

        console.log(gastosItems);

        return (
            <div>
                <div className='page-controls'>
                    <div className='table-data__tool'>
                        <div className='table-data__tool-left row'>
                            <div className='col-md-6'>
                                <label htmlFor='desde'>Desde</label>
                                <input
                                    type='date'
                                    name='desde'
                                    id='desde'
                                    defaultValue={dayjs()
                                        .startOf("day")
                                        .format("YYYY-MM-DD")}
                                    className='form-control'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "desde"
                                        )
                                    }
                                />
                            </div>
                            <div className='col-md-6'>
                                <label htmlFor='hasta'>Hasta</label>
                                <input
                                    type='date'
                                    name='hasta'
                                    id='hasta'
                                    defaultValue={dayjs()
                                        .endOf("day")
                                        .format("YYYY-MM-DD")}
                                    className='form-control'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "hasta"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {gastos.length > 0 ? (
                    <table className='table tickets-table'>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Descripción</th>
                                <th>Vehículo</th>
                                <th>Empleado</th>
                                <th>Cantidad</th>
                                <th className="text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gastosItems.map(gasto => (
                                <tr key={gasto.id}>
                                    <td>{gasto.fecha}</td>
                                    <td>{gasto.suplidor?.nombre}</td>
                                    <td>{gasto.vehiculo?.ficha}</td>
                                    <td>{gasto.empleado?.ficha}</td>
                                    <td>{gasto.cantidad} {gasto.item?.unidad}</td>
                                    <td className="text-right">{formatCurrency(gasto.total)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>No se han registrado gastos de este item.</div>
                )}
                {meta && (
                    <Pagination
                        links={links}
                        meta={meta}
                        getData={this.getGastos}
                    />
                )}
            </div>
        );
    }
}

export default withRequest(Gastos);
