import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import dayjs from "dayjs";

class PrintReclamacion extends Component {
    state = {
        reclamacion: {},
    };
    componentDidMount() {
        this.getTicket();
    }
    getTicket = async () => {
        const id = this.props.match.params.id;
        const response = await this.props.request("reclamaciones/" + id);

        if (response.success) {
            this.setState({
                reclamacion: response.data,
            });
            window.print();
        }
    };
    render() {
        const { reclamacion } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Reclamacion #{reclamacion.id}
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-center p-b-10'></div>
                </div>
                {reclamacion.estado === "atendida" && (
                    <table className='table m-b-20'>
                        <tbody>
                            <tr>
                                <td style={{ width: "150px" }}>
                                    Fecha Atendida
                                </td>
                                <td>
                                    {dayjs(reclamacion.atendidaEn).format(
                                        "YYYY-MM-DD"
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Atendida Por</td>
                                <td>
                                    {reclamacion.fichaAtendio &&
                                        reclamacion.fichaAtendio.ficha}
                                </td>
                            </tr>
                            <tr>
                                <td>Supervisado Por</td>
                                <td>
                                    {reclamacion.supervisorAtendio &&
                                        reclamacion.supervisorAtendio
                                            .nombreCompleto}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
                {reclamacion.estado === "cancelada" && (
                    <table className='table m-b-20'>
                        <tbody>
                            <tr>
                                <td style={{ width: "150px" }}>
                                    Fecha Cancelada
                                </td>
                                <td>
                                    {dayjs(reclamacion.atendidaEn).format(
                                        "YYYY-MM-DD"
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Comentario</td>
                                <td>{reclamacion.atendidaComentario}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                <table className='table'>
                    <tbody>
                        <tr>
                            <td>ID:</td>
                            <td>{reclamacion.id}</td>
                        </tr>
                        <tr>
                            <td style={{ width: "150px" }}>Estado</td>
                            <td>
                                {reclamacion.estado &&
                                    reclamacion.estado.toUpperCase()}
                            </td>
                        </tr>
                        <tr>
                            <td>Fecha:</td>
                            <td>
                                {dayjs(reclamacion.fecha).format("YYYY-MM-DD")}
                            </td>
                        </tr>
                        <tr>
                            <td>Dirección:</td>
                            <td>{reclamacion.direccion}</td>
                        </tr>
                        <tr>
                            <td>Reclamado por:</td>
                            <td>{reclamacion.nombreReclamador}</td>
                        </tr>
                        <tr>
                            <td>Teléfono:</td>
                            <td>{reclamacion.telefonoReclamador}</td>
                        </tr>
                        <tr>
                            <td>Detalles</td>
                            <td>{reclamacion.comentario}</td>
                        </tr>
                        <tr>
                            <td>Supervisor</td>
                            <td>
                                {reclamacion.supervisor &&
                                    reclamacion.supervisor.nombreCompleto}
                            </td>
                        </tr>
                        <tr>
                            <td>Zona:</td>
                            <td>
                                {reclamacion.ruta && (
                                    <div>
                                        {reclamacion.ruta.nombre}
                                        <br />{" "}
                                        <small>
                                            {reclamacion.ruta.descripcion}
                                        </small>
                                    </div>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Ficha:</td>
                            <td>
                                {reclamacion.ficha && (
                                    <div>{reclamacion.ficha.ficha}</div>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintReclamacion));
