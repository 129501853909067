import React, { useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { formatCurrency, getLink } from "../../utils/helpers";

export default function DescuentoItem({descuento}) {

    const [state, setState] = useState({
        showCuotas: false,
        monto: 0,
        seleccionCuotas: [],
    });

    function toggleCuotas(){
        setState({
            ...state,
            monto: 0,
            showCuotas: !state.showCuotas,
        });
    }

    function updateSeleccionCuotas(e){
        if (!e.target.checked) {
            setState({
                ...state,
                monto: e.target.dataset.monto,
                seleccionCuotas: state.seleccionCuotas.filter(c => {
                    return c !== e.target.value
                })
            });
            return;
        }

        setState({
            ...state,
            monto: e.target.dataset.monto,
            seleccionCuotas: [
                ...state.seleccionCuotas,
                e.target.value
            ]
        });
    }

    const { showCuotas, seleccionCuotas, monto } = state;
    const cuotasPendientes = descuento.cuotas.filter(c => c.estado === 'Pendiente');
    const cuotasCompletadas = descuento.cuotas.filter(c => c.estado === 'Pagada');
    const proxCuota = cuotasPendientes.length && dayjs(
        cuotasPendientes[0].fecha
    ).format("DD MMM, YYYY");

    let completadas = [];
    if (proxCuota) {
        completadas = cuotasCompletadas.filter(c => {
            return cuotasPendientes.length && dayjs(c.fecha).isBefore(proxCuota);
        });
    } else {
        completadas = cuotasCompletadas;
    }

    const ultimaCuota = completadas.length && dayjs(
        completadas[0].fecha
    ).format("DD MMM, YYYY");

    function quitarSeleccionCuotas() {
        setState({...state, seleccionCuotas: []});
        document.querySelectorAll('[name="cuotas_pendientes"]').forEach(e => e.checked = false);
    }

    window.addEventListener('update-cuotas', quitarSeleccionCuotas);

    return (
        <div className="descuento-item" id={`di-${descuento.id}`} key={descuento.id}>
            <div className="descuento-row descuento-info" onClick={() => toggleCuotas()}>
                <div>
                    <strong>
                        <Link to={getLink(`/empleados/${descuento.empleadoId}/descuentos-programados`)}>{ descuento.empleado }</Link>
                    </strong>
                    {descuento.telefono && <div>
                        Tel: {descuento.telefono}
                    </div>}
                </div>
                <div>
                    <small>
                        Frecuencia: {
                            descuento.frecuencia
                        }
                    </small>
                    <div className="descuento__descripcion">
                        {
                            descuento.descripcion
                        }
                    </div>
                </div>
                <div>
                    <div><strong>{formatCurrency(descuento.montoTotal)}</strong></div>
                    # Cuotas: {descuento.cuotas?.length || 1}
                </div>
                <div>
                    <div><strong>{formatCurrency(descuento.monto)}</strong></div>
                    Pendientes: {descuento.pendientes}
                </div>
                <div>
                    <div className="d-flex justify-content-end">
                        {completadas.length > 0 && <div style={{minWidth: "120px"}}>
                            <strong>Último:{' '}</strong>
                            <div>
                                {ultimaCuota}
                            </div>
                        </div>}
                        {cuotasPendientes?.length > 0 && <div style={{minWidth: "120px"}}>
                            <strong>Próximo:{' '}</strong>
                            <div>
                                {proxCuota}
                            </div>
                        </div>}
                        {descuento.frecuencia === 'Unico' && <div style={{minWidth: "120px"}}>
                            <strong>Fecha:{' '}</strong>
                            <div>
                                {dayjs(descuento.fecha).format("DD MMM, YYYY")}
                            </div>
                        </div>}
                    </div>
                </div>
                <div>
                    <div className='table-data-feature'>
                        <Link
                            to={getLink(
                                `/descuentos/${descuento.id}`
                            )}
                            className='item'
                            data-toggle='tooltip'
                            data-placement='top'
                            title=''
                            data-original-title='Edit'>
                            <i className='zmdi zmdi-edit' />
                        </Link>
                        {(descuento.cuotas.length > 0 && cuotasPendientes.length > 0) && (
                            <Link
                                to={getLink(
                                    `/descuentos/${descuento.id}/abonar`
                                )}
                                className='item'
                                data-toggle='tooltip'
                                data-placement='top'
                                title=''
                                data-original-title='Abonar'>
                                <i className='zmdi zmdi-collection-plus' />
                            </Link>
                        )}
                        <Link
                            to={getLink(
                                `/imprimir/descuentos/${descuento.id}`
                            )}
                            className='item'
                            target='_blank'
                            data-original-title='Imprimir'>
                            <i className='zmdi zmdi-print' />
                        </Link>
                    </div>
                </div>
            </div>

            {descuento.cuotas?.length > 0 && showCuotas && (<div className="descuento-cuotas show">
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{width: 40}}></th>
                            <th>Monto</th>
                            <th>Fecha</th>
                            <th>Estado</th>
                            <th>Comentarios</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {descuento.cuotas.map(cuota =>
                            <tr key={cuota.id} className={`cuota ${cuota.estado}`}>
                                <td>{cuota.estado === 'Pendiente' && <input type="checkbox" onClick={ updateSeleccionCuotas} name="cuotas_pendientes" value={cuota.id} data-monto={cuota.monto}/>}</td>
                                <td>{formatCurrency(cuota.monto)}</td>
                                <td>
                                    {dayjs(
                                        cuota.fecha
                                    ).format(
                                        "DD MMM, YYYY"
                                    )}
                                </td>
                                <td className="cuota__estado" style={{lineHeight: 1.2}}>
                                    <div>{cuota.estado}</div>
                                    {cuota.actividad && <small title={cuota.actividad.descripcion}>Por: {cuota.actividad.user.username}</small>}
                                    {!cuota.actividad && cuota.comentarios.length > 0 && <small>Por: {cuota.comentarios[cuota.comentarios.length - 1]?.user}</small>}
                                </td>
                                <td>
                                    {cuota.comentarios.length > 0 && <div style={{lineHeight: 1.2}}>
                                        <div>{cuota.comentarios[cuota.comentarios.length - 1]?.descripcion}</div>
                                        <div><small>Por: {cuota.comentarios[cuota.comentarios.length - 1]?.user}</small></div>
                                    </div>}
                                </td>
                                <td>
                                    <div className="table-data-feature">
                                        {cuota.estado === 'Pagada' && (
                                            <Link
                                                to={getLink(
                                                    `/imprimir/descuentos/${descuento.id}/recibo/${cuota.id}`
                                                )}
                                                className='item'
                                                target='_blank'
                                                title="Imprimir Recibo"
                                                data-original-title='Imprimir'>
                                                <i className='zmdi zmdi-print' />
                                            </Link>
                                        )}
                                        <Link
                                            to={getLink(
                                                `/descuentos/cuota/${cuota.id}`
                                            )}
                                            className='item'
                                            data-toggle='tooltip'
                                            data-placement='top'
                                            title=''
                                            data-original-title='Edit'>
                                            <i className='zmdi zmdi-edit' />
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {seleccionCuotas.length > 0 && (<div id="editar-cuotas-actions" className="text-right">
                    <button onClick={() => quitarSeleccionCuotas()}>Deseleccionar</button> <span className="m-l-15 m-r-15">|</span>
                    <Link
                        to={getLink(
                            `/descuentos/cuotas/editar?cuotas=${seleccionCuotas.join(',')}&monto=${monto}`
                        )}
                        className='item'
                        data-toggle='tooltip'
                        data-placement='top'
                        title=''
                        data-original-title='Editar'>
                            Editar <strong>{seleccionCuotas.length}</strong> cuotas seleccionadas
                    </Link>
                </div>)}
            </div>)}
        </div>
    );
}
