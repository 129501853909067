import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import Select from 'react-select';
import { formatCurrency, getLink } from '../../../../utils/helpers';
import { Link } from 'react-router-dom';

const Detalles = ({estudiantes, tutores, estudiante, setEstudiante, tutor, setTutor, getServicios, getCocurriculares, balance, formData, limpiar}) => {

    const estudiantesFiltrados = estudiantes?.filter(e => {
        if (!tutor.id) { return true; }
        var resultado = e.tutores.filter(t => t.id === tutor.id);
        return resultado.length > 0;
    });

    useEffect(() => {
        if (estudiante.tutores?.length > 0) {
            setTutor(estudiante.tutores[0]);
        }
    }, [estudiante]);

    return (
        <div className="row">
            <div className="col-md-2">
                {estudiante.foto ? (
                    <div>
                        <img src={estudiante.foto} style={{maxHeight: '175px'}}/>
                    </div>
                ) : (
                    <div className="text-center" style={{border: "1px solid #ccc", fontSize: "10em", color: "rgba(0,0,0,0.2)", lineHeight: 1.35}}>
                        <i className="zmdi zmdi-assignment-account"></i>
                    </div>
                )}
                {estudiante.id && (
                    <div className='text-center'>
                        <Link to={getLink(`/academico/estudiantes/${estudiante.id}/informacion`)} target="_blank"><small>Ver Perfil</small></Link>
                    </div>
                )}
            </div>
            <div className="col-md-4">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Padre/Tutor</label>
                            <Select
                                key={'tutores'}
                                name="tutor_id"
                                onChange={e => {
                                    limpiar();
                                    if (e?.value) {
                                        setTutor(tutores.find(t => t.id === e.value));
                                    } else {
                                        setTutor({});
                                    }
                                }}
                                isClearable
                                options={tutores.map(tutor => ({
                                    label: tutor.nombreCompleto,
                                    value: tutor.id,
                                }))}
                                value={tutor.id ? {
                                    value: tutor.id,
                                    label: tutor.nombreCompleto
                                } : null}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>RNC o Cédula</label>
                            <input type="text"  className="form-control" name="tutor_identificacion" value={tutor.cedula || ''} disabled />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Teléfono</label>
                            <input type="text" className="form-control" name="tutor_telefono" value={tutor.telefono || ''} disabled />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Balance</label>
                            <input type="text" className="form-control" name="balance" value={formatCurrency(balance)} disabled />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Grupo de Pago</label>
                            <input type="text" id="grupo_pago" className="form-control uppercase" value={estudiante.curso?.grupoPago} name="grupo_pago" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Estudiante</label>
                            <Select
                                key={'estudiantes'}
                                name="estudiante_id"
                                isClearable
                                onChange={e => {
                                    if (e?.value) {
                                        setEstudiante(e.estudiante);
                                        getServicios(e.value);
                                        getCocurriculares(e.estudiante.contacto_id);
                                    } else {
                                        setEstudiante({});
                                        limpiar();
                                    }
                                }}
                                options={estudiantesFiltrados.map(e => ({
                                    label: `${e.matricula || ''} - ${e.nombreCompleto}`,
                                    value: e.id,
                                    estudiante: e
                                }))}
                                value={estudiante.id ? {
                                    label: estudiante.nombreCompleto,
                                    value: estudiante.id,
                                } : null}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Periodo</label>
                            <input type="text"  className="form-control" name="periodo_academico" value={estudiante.curso?.periodoAcademico?.nombre || ''} disabled />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Matrícula</label>
                            <input type="text" className="form-control" name="matricula" disabled value={estudiante.matricula || ''} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Grado</label>
                            <input type="text" className="form-control" name="grado" disabled value={estudiante.curso?.grado?.nombre || ''}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Sección</label>
                            <input type="text" id="seccion" className="form-control" name="seccion" disabled value={estudiante.curso?.seccion?.nombre} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-2">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>No.</label>
                            <div className="d-flex">
                                <input type="text"  className="form-control m-r-5" value={formData?.comprobante?.prefijo || ''} readOnly name="prefijo_numero" style={{width: '65px'}} />
                                <input type="text"  className="form-control" readOnly value={formData?.comprobante?.numero_siguiente || ''} name="numero_factura" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Fecha</label>
                            <input type="date"  defaultValue={dayjs().format("YYYY-MM-DD")} className="form-control" name="fecha" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Detalles;
