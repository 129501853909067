import React, { Component } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Loader from "../general/Loader";
import { formatCurrency, getLink } from "../../utils/helpers";
import ComisionesForm from "./ComisionesForm";

class Nominas extends Component {
    state = {
        nominas: {},
        nomina: {},
        links: null,
        meta: null,
        showCreate: false,
        showComisiones: false,
        loading: true,
        filtros: {
            buscar: "",
        },
    };
    componentDidMount() {
        this.getNominas();
    }
    getNominas = async (url = null) => {
        url = url ? url + "&limit=20" : "nominas?limit=20";
        const nominas = await this.props.request(url);

        if (nominas.links) {
            this.setState({
                nominas: nominas.data,
                links: nominas.links,
                meta: nominas.meta,
                loading: false,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    };
    toggleComisionesModal = () => {
        this.setState({
            showComisiones: !this.state.showComisiones,
        });
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        this.getNominas("nominas?" + query.join("&"));
    }, 500);
    render() {
        const { nominas, nomina, loading } = this.state;
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Todas las Nóminas</span>
                        <button
                            className='au-btn au-btn-icon au-btn-filter au-btn--small m-r-10'
                            onClick={this.toggleComisionesModal}>
                            <i className='zmdi zmdi-plus' />
                            Cálculo de Comisión
                        </button>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Generar Nómina
                        </button>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col-md-12'>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!nominas.length ? (
                                <div>
                                    {!loading && (
                                        <div>No hay nóminas creadas.</div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Inicio</th>
                                                <th>Cierre</th>
                                                <th className='text-center'>
                                                    # Empleados
                                                </th>
                                                <th className='text-right'>
                                                    Total
                                                </th>
                                                <th className='text-right' />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {nominas.map((nomina) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={nomina.id}>
                                                    <td>
                                                        <Link
                                                            to={getLink(
                                                                `/nomina/${nomina.id}`
                                                            )}>
                                                            {nomina.nombre}{" "}
                                                            {nomina.cerrada && (
                                                                <small className='badge badge-success'>
                                                                    CERRADA
                                                                </small>
                                                            )}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {nomina.fechaInicio}
                                                    </td>
                                                    <td>
                                                        {nomina.fechaCierre}
                                                    </td>
                                                    <td className='text-center'>
                                                        {nomina.empleados}
                                                    </td>
                                                    <td className='text-right'>
                                                        {formatCurrency(
                                                            nomina.total
                                                        )}
                                                    </td>
                                                    <td className='text-right'>
                                                        <div className='table-data-feature'>
                                                            <Link
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title='Ver Nómina'
                                                                to={getLink(
                                                                    `/nomina/${nomina.id}`
                                                                )}>
                                                                <i className='zmdi zmdi-mail-send' />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getNominas}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        nomina={nomina}
                        getNominas={this.getNominas}
                    />
                </Modal>

                <Modal
                    show={this.state.showComisiones}
                    blank={true}
                    toggle={this.toggleComisionesModal}>
                    <ComisionesForm
                        toggle={this.toggleComisionesModal}
                        getNominas={this.getNominas}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Nominas);
