import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import Loader from "../../general/Loader";
import { getLink, toast } from "../../../utils/helpers";
import Modal from "../../general/Modal";
import Create from "./Create";
import { apiUrl } from "../../../utils/apiUrl";
import { auth } from "../../../utils/auth";
import Edit from "./Edit";
import dayjs from "dayjs";

class Servicios extends Component {
    state = {
        registros: [],
        loading: true,
        showCreate: false,
        showEdit: false,
        registroActivo: {},
    };
    componentDidMount() {
        this.getRegistros();
    }
    getRegistros = async (url = null) => {
        this.setState({
            loading: true,
        });

        const id = this.props.match.params.id;
        url = url ? url : `vehiculos/${id}/servicios`;
        const registros = await this.props.request(url);

        if (registros.success) {
            this.setState({
                registros: registros.data,
                loading: false,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    };
    toggleEditModal = (e, registro) => {
        if (registro) {
            this.setState({
                showEdit: !this.state.showEdit,
                registroActivo: registro,
            });
        } else {
            this.setState({
                showEdit: !this.state.showEdit,
            });
        }
    };
    removeRegistro = async (event, servicio) => {
        event.preventDefault();

        const { vehiculo } = this.props;
        const token = auth.getToken();

        const url = `vehiculos/${vehiculo.id}/servicios/${servicio}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.getRegistros();
            this.toggleEditModal();
            toast("Servicio Borrado");
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { vehiculo } = this.props;
        const { registros, loading, registroActivo } = this.state;

        if (loading === true) {
            return <Loader />;
        }
        return (
            <div>
                <div className='row m-b-20'>
                    <div className='col'>
                        <h3>Historial de Servicios Realizados </h3>
                    </div>
                    <div className='col text-right'>
                        <button
                            onClick={this.toggleCreateModal}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'>
                            Registrar Servicio
                        </button>
                    </div>
                </div>
                <table className='table tickets-table'>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Servicio</th>
                            <th>Toneladas</th>
                            <th>Mecanico</th>
                            <th>Creador</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {registros.map((registro) => (
                            <tr key={registro.id}>
                                <td>
                                    {dayjs(registro.fecha).format("DD/MM/YYYY")}
                                </td>
                                <td>{registro.servicio}</td>
                                <td>{registro.toneladas}</td>
                                <td>{registro.mecanico}</td>
                                <td>{registro.creador}</td>
                                <td className='text-right'>
                                    {/* <button
                                        onClick={(event) =>
                                            this.removeRegistro(
                                                event,
                                                registro.id
                                            )
                                        }>
                                        <i className='fa fa-trash-alt' />
                                    </button> */}
                                    <button
                                        className='m-r-15'
                                        onClick={(event) =>
                                            this.toggleEditModal(
                                                event,
                                                registro
                                            )
                                        }>
                                        <i className='fa fa-pen' />
                                    </button>
                                    <Link
                                        className='au-btn au-btn-filter'
                                        to={getLink(
                                            `/gastos/crear?registro=${registro.id}&vehiculo=${registro.vehiculoId}&mecanico=${registro.mecanicoId}`
                                        )}>
                                        Añadir Gastos
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        vehiculo={vehiculo}
                        getRegistros={this.getRegistros}
                        registro={{}}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={this.toggleEditModal}>
                    <Edit
                        toggle={this.toggleEditModal}
                        vehiculo={vehiculo}
                        getRegistros={this.getRegistros}
                        registro={registroActivo}
                        deleteRegistro={this.removeRegistro}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(Servicios));
