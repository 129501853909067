/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import queryString from "query-string";

import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";
import { request } from "../../../utils/request";

const params = queryString.parse(window.location.search);

const PrintResumenCuentasPorCobrar = () => {
	const [cuentas, setCuentas] = useState([]);
	const [sample, setSample] = useState({});
	const [sumatorias, setSumatorias] = useState({});
	const [totalCxC, setTotalCxC] = useState(0);
	const [cantidadCxC, setCantidadCxC] = useState(0);

	const getCuentas = () => {
		request(
			"cuentas-por-cobrar" +
				window.location.search +
				"&paginate=100000&sortBy=cliente"
		).then((res) => {
			processCuentasData(res.data);
			setSample(res.data[0]);
			setTimeout(() => {
				window.print();
			}, 1500);
		});
	};

	const formatDateTime = (date) => {
		return dayjs(date).format("DD/MM/YYYY, hh:mm a");
	};

	const processCuentasData = (data) => {
		const cuentasSegmentadas = {};
		const sumatoriasSegmentadas = {};
		let totalCxCCurrent = 0;
		data.forEach((cuenta) => {
			totalCxCCurrent += parseFloat(cuenta?.por_cobrar);
			if (
				cuenta.cliente?.id &&
				!cuentasSegmentadas.hasOwnProperty(cuenta.cliente?.nombre)
			) {
				cuentasSegmentadas[cuenta.cliente?.nombre] = [cuenta];
				sumatoriasSegmentadas[cuenta.cliente?.nombre] = parseFloat(
					cuenta?.por_cobrar
				);
			} else if (
				cuentasSegmentadas.hasOwnProperty(cuenta.cliente?.nombre)
			) {
				cuentasSegmentadas[cuenta.cliente?.nombre].push(cuenta);
				sumatoriasSegmentadas[cuenta.cliente?.nombre] += parseFloat(
					cuenta?.por_cobrar
				);
			}

		});

		setCantidadCxC(data.length);
		setTotalCxC(totalCxCCurrent);
		setSumatorias(sumatoriasSegmentadas);
		setCuentas(cuentasSegmentadas);
	};

	useEffect(() => {
		getCuentas();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	// const valorTotal = cuentas.reduce((acc, factura) => {
	// 	return acc + parseFloat(factura.por_cobrar);
	// }, 0);

	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
                    {sucursal.nombre}
				</h2>
                <div className="col-md-12 text-center uppercase"><strong>Resumen Cuentas Por Cobrar</strong></div>
				<div className="col-md-12 text-center">
                    {sucursal.direccion} •{" "}
					{new Date().toLocaleString()}
				</div>
                <div className="col-md-12 p-t-5 p-b-10 text-center">
                    {Object.keys(params).map(k => {
                        switch (k) {
                            case 'categoria':
                                return `${k.toUpperCase()}: "${sample.categorias && sample.categorias[0].nombre}"`;
                            case 'zona':
                                return `${k.toUpperCase()}: "${sample.ruta?.nombre}"`;
                            case 'vendedor':
                                return `${k.toUpperCase()}: "${sample.vendedor?.nombre} ${sample.vendedor?.apellido}"`;
                            default:
                                return `${k.toUpperCase()}: "${params[k]}"`;
                        }
                    }).join(' • ')}
                </div>
				<div className="col-md-12 text-right p-b-10" style={{fontSize: 'larger'}}>
					<div className="float-left">
						TOTAL CUENTAS POR COBRAR: {cantidadCxC}
					</div>

					<div className="float-right">
						<h3>BALANCE TOTAL: {formatCurrency(totalCxC)}</h3>
					</div>
				</div>
			</div>
			{Object.values(cuentas).map((cuentaMap) => (
				<>
					<table className="table mb-4" style={{fontSize: 'larger'}}>
						<thead>
							<tr>
								<th>NCF/Numero</th>
								<th>Tipo</th>
								<th>Cliente</th>
								<th>Vendedor</th>
								<th>Creación</th>
								<th className="text-right">Vencimiento</th>
								{/* <th className="text-right">Estado</th> */}
								<th className="text-right">Monto</th>
								<th className="text-right">Balance</th>
							</tr>
						</thead>
						<tbody>
							{cuentaMap.map((cuenta) => {
								return (
									<tr key={cuenta.id}>
										<td>{cuenta.numero_comprobante}</td>
										<td className="text-capitalize">
											{cuenta.tipo}
										</td>
										<td>
											{cuenta.cliente
												? cuenta.cliente.nombre
												: ""}
										</td>
										<td>
											{cuenta.vendedor
												? `${cuenta.vendedor.nombre} ${cuenta.vendedor.apellido}`
												: ""}
										</td>
										<td>
											{!!cuenta.recurrencia_id
												? dayjs(cuenta.fecha).format(
														"DD/MM/YYYY"
												  )
												: formatDateTime(
														cuenta.created_at
												  )}{" "}
											<span>
												<div>
													{!!cuenta.recurrencia_id
														? dayjs()
																.startOf("day")
																.diff(
																	dayjs(
																		cuenta.fecha
																	).startOf(
																		"day"
																	),
																	"day"
																)
														: dayjs()
																.startOf("day")
																.diff(
																	dayjs(
																		cuenta.created_at
																	).startOf(
																		"day"
																	),
																	"day"
																)}{" "}
													días transcurridos
												</div>
											</span>
										</td>
										<td className="text-right">
											{dayjs(
												cuenta.fecha_vencimiento
											).format("DD/MM/YYYY")}{" "}
											<div>{cuenta.plazo?.dias} Días</div>
										</td>
										{/* <td className="text-capitalize text-right">
											{cuenta.estado}
										</td> */}
										<td className="text-right">
											{formatCurrency(cuenta.total)}
										</td>
										<td className="text-right">
											{formatCurrency(cuenta.por_cobrar)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="row m-b-40">
						<div className="col-md-12 text-right" style={{fontSize: 'larger'}}>
							<div className="float-left">
								TOTAL CUENTAS POR COBRAR: {cuentaMap.length}
							</div>

							<div className="float-right">
								<h3>
									BALANCE TOTAL:{" "}
									{formatCurrency(
										sumatorias[
											cuentaMap[0]?.cliente?.nombre
										]
									)}
								</h3>
							</div>
						</div>
					</div>
				</>
			))}
		</>
	);
};

export default PrintResumenCuentasPorCobrar;
