import React, { Component } from "react";
import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import Create from "./Create";
import Modal from "../../general/Modal";

class Solicitudes extends Component {
    state = {
        solicitudes: [],
        showCreate: false,
        loading: true
    };
    componentDidMount() {
        this.getSolicitudes();
    }
    getSolicitudes = async (url = null) => {
        const id = this.props.empleado.id;
        url = url ? url : `solicitudes?empleado=${id}`;
        const solicitudes = await this.props.request(url);

        if (solicitudes.data && solicitudes.data.length > 0) {
            this.setState({
                solicitudes: solicitudes.data,
                loading: false
            });
        }
    };
    removeSolicitud = async (event, solicitud) => {
        event.preventDefault();

        const token = auth.getToken();

        const url = `solicitudes/${solicitud}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.getSolicitudes();
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate
        });
    };
    render() {
        const { empleado } = this.props;
        const { solicitudes, loading } = this.state;

        if (loading === 0) {
            return <Loader />;
        }
        return (
            <div>
                <div className='row m-b-20'>
                    <div className='col'>
                        <h3>Solicitudes</h3>
                    </div>
                    <div className='col text-right'>
                        <button
                            onClick={this.toggleCreateModal}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'>
                            Nueva
                        </button>
                    </div>
                </div>
                <table className='table tickets-table'>
                    <thead>
                        <tr>
                            <th>Descripción</th>
                            <th>Vehiculo</th>
                            <th>Fecha</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {solicitudes.map(solicitud => (
                            <tr key={solicitud.id}>
                                <td>{solicitud.descripcion}</td>
                                <td>{solicitud.vehiculo}</td>
                                <td>{solicitud.fecha}</td>
                                <td>{solicitud.estado}</td>
                                <td>
                                    <button
                                        onClick={event =>
                                            this.removeSolicitud(
                                                event,
                                                solicitud.id
                                            )
                                        }>
                                        <i className='fa fa-trash-alt' />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        empleado={empleado}
                        getSolicitudes={this.getSolicitudes}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Solicitudes);
