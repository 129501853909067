import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useHistory, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

import { toast, getLink } from "../../utils/helpers";
import Loader from "../general/Loader";
import Form from "./Form";
import { apiRequest } from "../../hooks/apiRequest";

const EditEntrada = ({}) => {
    const [state, setState] = useState({
        errors: {},
        entrada: {},
        entradaItems: [],
        fecha: dayjs().format("YYYY-MM-DD"),
        loading: true,
        saving: false,
    });

    const history = useHistory();
    const match = useRouteMatch();

    const getEntrada = async () => {
        const id = match.params.id;
        const request = await apiRequest(null, `entradas-diario/${id}`, 'get');

        setState({
            ...state,
            entrada: request.data,
            entradaItems: request.data.movimientos,
            fecha: dayjs(request.fecha).format("YYYY-MM-DD"),
            loading: false,
            saving: false,
        });
    }

    const updateEntradaDiario = async (event, salir = false, imprimir = false) => {
        event.preventDefault();

        const {debito, credito} = state;

        if (debito !== credito) {
            toast("Los totales no coinciden", "error");
            return;
        }

        setState({
            ...state,
            saving: true,
        });

        const data = new FormData(event.target);
        const response = await apiRequest(data, `entradas-diario/${match.params.id}`);

        if (response.success) {
            setState({
                ...state,
                saving: false,
            });
            if (imprimir) {
                window.open(getLink('/imprimir/entradas-de-diario/' + response.data.id))
            }
            if (salir) {
                history.push(getLink("/entradas-de-diario"));
            } else {
                const current = window.location.pathname;
                history.replace(getLink('/reload'));
                    setTimeout(() => {
                    history.replace(current);
                });
            }
        }
        if (response.code === 422) {
            setState({ ...state, errors: response.data.message, saving: false });
            toast("Por favor revisa todos los campos", "error");
        }
    };
    const guardarSalir = (e) => {
        e.target = e.target.form;
        updateEntradaDiario(e, true)
    }
    const guardarImprimir = (e) => {
        e.target = e.target.form;
        updateEntradaDiario(e, true, true)
    }

    useEffect(() => {
        getEntrada();
    }, []);

    const {
        errors,
        loading,
        entrada,
        saving,
        entradaItems,
    } = state;

    return (
        <div className='crear-compra'>
            <div className='page-header'>
                <h1 className='page-title row'>
                    <span className='col'>Editar Entrada de Diario</span>
                </h1>
            </div>
            { loading ? <Loader /> : (
                <form
                    method='post'
                    onSubmit={updateEntradaDiario}
                    className='row'>
                    <input type="hidden" name="_method" value="patch" />
                    <Form
                        isCreate={true}
                        entrada={entrada}
                        errors={errors}
                        items={entradaItems}
                        date={dayjs().format("YYYY-MM-DD")}
                    />
                    <div className='page-footer text-right col-md-12'>
                        <button
                            type='button'
                            onClick={() =>
                                history.push(getLink("/entradas-de-diario"))
                            }
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {!saving && (
                            <button type='button' className='btn btn-secondary' onClick={guardarSalir}>
                                Guardar
                            </button>
                        )}
                        {!saving && (
                            <button type='button' className='btn btn-secondary' onClick={guardarImprimir}>
                                Guardar e Imprimir
                            </button>
                        )}
                        {saving ? (
                            <Loader type='small' />
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Guardar y Crear Nuevo
                            </button>
                        )}
                    </div>
                </form>
            )}
        </div>
    );
}

export default EditEntrada;
