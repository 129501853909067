import React from 'react';
import Select from 'react-select';
import PrefixedInput from '../../../general/PrefixedInput';

export default function Cocurriculares({item, errors}) {
    return (<>
        <div className="row">
            <div className="col">
                <div className='row form-group'>
                    <div className='col col-md-8'>
                        <label className='label form-control-label'>
                            Nombre:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre'
                            name='nombre'
                            defaultValue={item.nombre}
                        />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Referencia/Código:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='referencia'
                            name='referencia'
                            defaultValue={item.referencia}
                        />
                        {errors.referencia && (
                            <small className='help-blockParams form-Text'>
                                {errors.referencia[0]}
                            </small>
                        )}
                    </div>

                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            # Cuotas:
                        </label>
                        <input
                            type='number'
                            className='text-input form-control'
                            id='cuotas'
                            name='cuotas'
                            defaultValue={item.cuotas}
                        />
                        {errors.cuotas && (
                            <small className='help-blockParams form-Text'>
                                {errors.cuotas[0]}
                            </small>
                        )}
                    </div>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Costo:
                        </label>
                        <PrefixedInput
                            type='number'
                            className='text-input form-control'
                            id='costo'
                            prefix="RD$"
                            name='costo'
                            defaultValue={item.costo}
                        />
                        {errors.costo && (
                            <small className='help-blockParams form-Text'>
                                {errors.costo[0]}
                            </small>
                        )}
                    </div>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Inscripción:
                        </label>
                        <PrefixedInput
                            type='number'
                            className='text-input form-control'
                            id='costo_inscripcion'
                            prefix="RD$"
                            name='costo_inscripcion'
                            defaultValue={item.costoInscripcion}
                        />
                        {errors.costo_inscripcion && (
                            <small className='help-blockParams form-Text'>
                                {errors.costo_inscripcion[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-6'>
                        <label className='label form-control-label'>
                            Fecha Inicio:
                        </label>
                        <input
                            type='date'
                            className='text-input form-control'
                            id='fecha_inicio'
                            name='fecha_inicio'
                            defaultValue={item.fechaInicio}
                        />
                        {errors.fecha_inicio && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha_inicio[0]}
                            </small>
                        )}
                    </div>
                    <div className='col col-md-6'>
                        <label className='label form-control-label'>
                            Frecuencia:
                        </label>
                        <Select
                            key="frecuencia"
                            name="frecuencia"
                            menuPosition='fixed'
                            menuPlacement='auto'
                            defaultValue={item.frecuencia && {
                                label: item.frecuencia,
                                value: item.frecuencia,
                            }}
                            options={[
                                {label: 'Quincenal', value: 'Quincenal'},
                                {label: 'Mensual', value: 'Mensual'},
                                {label: 'Bimestral', value: 'Bimestral'},
                                {label: 'Trimestral', value: 'Trimestral'},
                                {label: 'Semestral', value: 'Semestral'},
                                {label: 'Anual', value: 'Anual'},
                            ]}
                        />
                        {errors.frecuencia && (
                            <small className='help-blockParams form-Text'>
                                {errors.frecuencia[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
        </div>

    </>)
}
