import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import Select from "react-select";

import {
	getLink,
	formatCurrency,
	isVendedor,
} from "../../../utils/helpers";
import CreateCotizacion from "./Create";
import PageHeader from "../../general/PageHeader";
import Loader from "../../general/Loader";
import Pagination from "../../general/Pagination";
import { request } from "../../../utils/request";
import EditCotizacion from "./Edit";
import FiltersContainer from "../../general/FiltersContainer";
import { ESTADOS_COTIZACIONES } from "../../../utils/types";
import InvalidScreen from "../../general/InvalidScreen";
import { auth } from "../../../utils/auth";


const Cotizaciones = () => {
	// Route  matcher
	let match = useRouteMatch();

	const [calls, setCalls] = useState(0);
	const [filtrosQuery, setFiltrosQuery] = useState("?limit=15");
	const [filtros, setFiltros] = useState({
		buscar: "",
		vendedor: auth.is('vendedor-cliente') ? auth.getUser().empleado.id : "",
		estado: "",
        limit: 15,
		desde: dayjs().format("YYYY-MM-DD"),
		hasta: dayjs().format("YYYY-MM-DD"),
	});

	const [cotizaciones, setCotizaciones] = useState({});
	const [vendedores, setVendedores] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const getCotizaciones = (url) => {
		url = url ? url : "cotizaciones?limit=15";
		setIsLoading(true);

		request(url).then((res) => {
			setCotizaciones(res);
			setIsLoading(false);
		});
	};

	const onFilterChange = debounce(async (value, filtro) => {
		await setFiltros((filtros) => ({
			...filtros,
			[filtro]: value,
		}));
	}, 500);

	const renderEstadoBadge = (estado) => {
		if (estado === "pendiente") {
			return <span className="badge badge-secondary p-2">Pendiente</span>;
		} else if (estado === "pagada") {
			return <span className="badge badge-success p-2">Pagada</span>;
		}else if (estado === "facturada") {
			return <span className="badge badge-success p-2">Facturada</span>;
		}

		return <span className="badge badge-danger p-2">Cancelada</span>;
	};

	const getVendedoresForDropdown = () => {
		request("vendedores?limit=100").then((res) => {
			const vendedores = res.data.filter((vendedor) => vendedor != null);
			setVendedores([
				{ value: "", label: "Todos" },
				...vendedores.map((vendedor) => ({
					value: vendedor.id,
					label: `${vendedor.nombre} ${vendedor.apellido}`,
				})),
			]);
			// setIsLoading(false);
		});
	};

	const formatDateTime = (date) => {
		return dayjs(date).format("DD/MM/YYYY hh:mm a");
	};

	useEffect(() => {
		if(calls) getCotizaciones(`cotizaciones${filtrosQuery}`);
		setCalls(calls + 1);
	}, [filtrosQuery]);

	useEffect(() => {
		let query = Object.keys(filtros).map((fil) => {
			return `${fil}=${filtros[fil]}`;
		});
		
		setFiltrosQuery("?" + query.join("&"));
	}, [filtros]);

	useEffect(() => {
		getVendedoresForDropdown();
	}, []);

	if (isVendedor() && !localStorage.getItem("rutas")) {
		return <InvalidScreen message="No hay zonas seleccionadas" />;
	}

	if (auth.is('vendedor-cliente') && !localStorage.getItem("almacenes")) {
		return <InvalidScreen message="No hay almacenes seleccionados" />;
	}

	return (
		<>
			<PageHeader title="Cotizaciones">
				<Link
					to={getLink(`/imprimir/cotizaciones${filtrosQuery}`)}
					target="_blank"
					className="au-btn-filter m-r-15"
				>
					<i className="zmdi zmdi-print" /> Imprimir
				</Link>
				<Link
					className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15"
					to={`${match.url}/crear`}
				>
					<i className="zmdi zmdi-plus" />
					Añadir Cotización
				</Link>
			</PageHeader>
			<FiltersContainer>
				<div className="col-md-4">
					<label>Buscar</label>
					<input
						onChange={(e) =>
							onFilterChange(e.target.value, "buscar")
						}
						type="search"
						placeholder="Buscar..."
						className="form-control"
						name="buscar"
					/>
				</div>
				<div className="col-md-2">
					<label>Estado</label>
					<Select
						id="estado"
						options={[{value: "", label: "Todos"}, ...ESTADOS_COTIZACIONES]}
						name="estado"
						onChange={(e) => onFilterChange(e.value, "estado")}
						defaultValue={{ value: null, label: "Todos" }}
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
				<div className="col-md-2">
					<label>Vendedor</label>
					<Select
						id="vendedor"
						options={vendedores}
						name="vendedor"
						onChange={(e) => onFilterChange(e.value, "vendedor")}
						isDisabled={auth.is('vendedor-cliente')}
						defaultValue={auth.is('vendedor-cliente') ? { value: auth.getUser().empleado?.id, label: `${auth.getUser().empleado?.nombre} ${auth.getUser().empleado?.apellido}` } : { value: null, label: "Todos" }}
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
				<div className="col-md-2">
					<label htmlFor="desde">Desde</label>
					<input
						type="date"
						name="desde"
						id="desde"
						defaultValue={dayjs()
							.startOf("day")
							.format("YYYY-MM-DD")}
						className="form-control"
						onChange={(e) =>
							onFilterChange(e.target.value, "desde")
						}
					/>
				</div>
				<div className="col-md-2">
					<label htmlFor="hasta">Hasta</label>
					<input
						type="date"
						name="hasta"
						id="hasta"
						defaultValue={dayjs().endOf("day").format("YYYY-MM-DD")}
						className="form-control"
						onChange={(e) =>
							onFilterChange(e.target.value, "hasta")
						}
					/>
				</div>
			</FiltersContainer>
			<div
				className="row px-3"
				style={{ overflowX: "auto", paddingBottom: "5rem" }}
			>
				{isLoading && <Loader />}
				{!isLoading && cotizaciones.data?.length ? (
					<div
						className="table-data table-responsive"
						style={{ overflowX: "visible" }}
					>
						<table className="table table-data2">
							<thead>
								<tr>
									<th>NCF/Numero</th>
									<th>Cliente</th>
									<th>Creación</th>
									<th>Vencimiento</th>
									<th>Total</th>
									<th>Vendedor</th>
									<th>Zona</th>
									<th className="text-center">Estado</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{cotizaciones.data.map((cotizacion) => {
									return (
										<tr
											className="tr-shadow"
											key={cotizacion.id}
										>
											<td>
												{cotizacion.numero_comprobante}
											</td>
											<td>{cotizacion.cliente?.nombre}</td>
											<td>
												{formatDateTime(
													cotizacion.created_at
												)}
											</td>
											<td>
												{dayjs(
													cotizacion.fecha_vencimiento
												).format("DD/MM/YYYY")}
											</td>
											<td>
												{formatCurrency(
													cotizacion.total,
													"$"
												)}
											</td>
											{cotizacion.vendedor ? (
												<td>
													{`${cotizacion.vendedor?.nombre} ${cotizacion.vendedor?.apellido}`}
												</td>
											) : (
												<td></td>
											)}
											<td>{cotizacion.ruta?.nombre}</td>
											<td className="text-center">
												{renderEstadoBadge(
													cotizacion.estado
												)}
											</td>
											<td>
												<div className="dropdown">
													<button className="au-btn au-btn-icon au-btn-filter">
														Acciones
													</button>
													<div
														style={{
															left: "-30%",
														}}
														className="dropdown-menu"
													>
														<Link
															to={getLink(
																`/imprimir/cotizaciones/${cotizacion.id}`
															)}
															target="_blank"
															className="dropdown-item"
															title="Imprimir"
														>
															Impresión
														</Link>
														{cotizacion.estado === "pendiente" && (
															<Link
																className="dropdown-item"
																to={getLink(
																	`/ingresos/facturas/crear/${cotizacion.id}`
																)}
																title="Facturar"
																data-original-title="Facturar"
																onClick={() => localStorage.setItem('fromOrden', JSON.stringify('no'))}
															>
																Facturar
															</Link>
														)}
														{cotizacion.estado ===
															"pendiente" && (
															<Link
																to={getLink(
																	`/ingresos/cotizaciones/${cotizacion.id}/edit`
																)}
																className="dropdown-item"
																title="Editar"
															>
																Editar
															</Link>
														)}
													</div>
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				) : (
					<div>
						{!isLoading && (
							<div>No hay ninguna Cotización creada.</div>
						)}
					</div>
				)}
				{cotizaciones.links && (
					<Pagination
						links={cotizaciones.links}
						meta={cotizaciones.meta}
						getData={getCotizaciones}
					/>
				)}
			</div>
			{/* CREAR COTIZACION */}
			<Switch>
				<Route path={`${match.url}/crear`}>
					<CreateCotizacion />
				</Route>
				<Route path={`${match.url}/:id/edit`}>
					<EditCotizacion />
				</Route>
			</Switch>
		</>
	);
};

export default Cotizaciones;
