import React, { useEffect, useState } from "react";
import { request } from "../../utils/request";
import Loader from "../general/Loader";

const Summary = ({empleadoId}) => {
    const [loadingSummary, setLoadingSummary] = useState(false);
    const [summary, setSummary] = useState({ adquiridos: 0, usados: 0});
    const [disponibles, setDisponibles] = useState(0);

    const getSummary = (id) => {
        setLoadingSummary(true);

        request(`empleados/${id}/summary-ausencias`).then((response) => {
            setSummary(response.data)
            setLoadingSummary(false);
        });
    }

    useEffect(() => {
        getSummary(empleadoId);
    }, [empleadoId]);

    useEffect(() => {
        const disponible = summary.adquiridos - summary.usados;
        setDisponibles(disponible < 0 ? 0 : disponible);
    }, [summary]);

    return loadingSummary 
        ? <Loader /> 
        : (<div className='row form-group'>
            <div className="col-10 offset-1">
                <div className="row px-1 py-3" style={{backgroundColor: '#eaefe9'}}>
                    <div className="col" style={{borderRight: '1px solid var(--gray)'}}>
                        <h2>{summary.adquiridos}</h2>
                        <label>DÍAS TOTALES</label>
                    </div>
                    <div className="col" style={{borderRight: '1px solid var(--gray)'}}>
                        <h2>{disponibles}</h2>
                        <label>DISPONIBLES</label>
                    </div>
                    <div className="col">
                        <h2>{summary.usados}</h2>
                        <label>DÍAS USADOS</label>
                    </div>
                </div>
            </div>
        </div>);
}

export default Summary;