import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../../../utils/request";
import PageHeader from "../../general/PageHeader";
import Form from "./Components/CotizacionForm";

const EditCotizacion = () => {
	const { id } = useParams();
	const [cotizacion, setCotizacion] = useState({});

	const getCotizacion = () => {
		request("cotizaciones/" + id).then((res) => {
			setCotizacion(res.data);
		});
	};
	useEffect(() => {
		getCotizacion();
	}, []);
	return (
		<>
			<PageHeader title="Editar Cotizacion" hasButton={false} />
			<Form cotizacionActual={cotizacion} isEdit={true} />
		</>
	);
};

export default EditCotizacion;
