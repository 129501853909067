import React, { useEffect, useState } from "react";

import Form from "./Form";
import Loader from "../../general/Loader";
import { apiRequest } from "../../../hooks/apiRequest";
import { request } from "../../../utils/request";
import { toast } from "../../../utils/helpers";
import Modal from "../../general/Modal";

const Edit = ({
	toggleModal,
	getNumeracionesData,
	numeracionID,
	filtrosQuery,
}) => {
	const [saving, setSaving] = useState(false);
	const [errors, setErrors] = useState({});
	const [numeracion, setNumeracion] = useState({});
	const [showDelete, setShowDelete] = useState(false);
	
	const getNumeracionData = () => {
		if (!numeracionID) return;

		request(`numeracion-documentos/${numeracionID}`).then((res) => {
			setNumeracion(res.data);
		});
	};
	const updateNumeracion = (event) => {
		event.preventDefault();
		setSaving(true);
		const data = new FormData(event.target);

		apiRequest(data, `numeracion-documentos/${numeracion.id}`, "post").then(
			(response) => {
				if (response.success) {
					getNumeracionesData(`numeracion-documentos?${filtrosQuery}`);
					toggleModal();
					setSaving(false);
					toast("Numeración actualizada.");
				}
				if (response.code === 422) {
					setErrors({
						...response.data.message,
					});
					setSaving(false);
				}
			}
		);
	};

	const deleteNumeracion = () => {
		apiRequest(
			null,
			`numeracion-documentos/${numeracion.id}`,
			"delete"
		).then((response) => {
			if (response.success) {
				getNumeracionesData();
				toggleModal();
				setSaving(false);
				toast("Numeración Eliminada.");
			}
			if (response.code === 422) {
				setErrors({
					...response.data.message,
				});
				setSaving(false);
			}
		});
	};

	const toggleDeleteModal = () => {
        setShowDelete(state => !state);
    };

	useEffect(() => {
		getNumeracionData();
	}, [numeracionID]);
	return (
		<>
			<form id="numeracionForm" method="post" onSubmit={updateNumeracion}>
				<input type="hidden" name="_method" value="put" />
				<div className="popup__header">Editar Numeración</div>

				{!numeracionID ? (
					<Loader />
				) : (
					<Form numeracion={numeracion} errors={errors} />
				)}

				<div className="popup__footer">
					{!numeracion.is_default && (
						<button
							type="button"
							className="btn float-left"
							onClick={(e) => toggleDeleteModal()}
						>
							<i className="fas fa-trash-alt"></i>
						</button>
					)}
					<button
						type="button"
						className="btn btn-plain"
						onClick={() => toggleModal()}
					>
						Cancelar
					</button>
					{saving ? (
						<Loader type="small" />
					) : (
						<button type="submit" className="btn btn-primary">
							Editar
						</button>
					)}
				</div>
			</form>
			<Modal
				title="Borrar Numeración"
				show={showDelete}
				callback={() => deleteNumeracion()}
				acceptText="Borrar"
				toggle={toggleDeleteModal}
			>
				¿Estás seguro de que deseas borrar esta Numeración?
			</Modal>
		</>
	);
};

export default Edit;
