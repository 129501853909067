import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import { formatCurrency, formatNumber, toast } from "../../../utils/helpers";
import Loader from "../../general/Loader";

export default class TicketsForm extends Component {
    state = {
        id: null,
        saving: false,
        d2000: 0,
        d1000: 0,
        d500: 0,
        d200: 0,
        d100: 0,
        d50: 0,
        d25: 0,
        d10: 0,
        d5: 0,
        d1: 0,
        dolares: 0,
        nota: '',
        total: 0,
        cuenta_id: '',
        depositos: [],
        temp_cuenta: '',
        temp_deposito: 0,
        usd_cuenta_id: '',
        errors: {}
    };
    componentDidMount() {
        const {tarjeta} = this.props;
        if (tarjeta.giTicket) {
            const ticket = tarjeta.giTicket;
            this.setState({
                id: ticket.id,
                d2000: ticket.d2000,
                d1000: ticket.d1000,
                d500: ticket.d500,
                d200: ticket.d200,
                d100: ticket.d100,
                d50: ticket.d50,
                d25: ticket.d25,
                d10: ticket.d10,
                d5: ticket.d5,
                d1: ticket.d1,
                depositos: ticket.depositos,
                dolares: ticket.dolares,
                cuenta_id: ticket.cuenta_id,
                usd_cuenta_id: ticket.usd_cuenta_id,
                nota: ticket.nota,
                total: ticket.total,
            });
        }
    };
    createTicket = async event => {
        event.preventDefault();

        this.setState({ saving: true });

        if (!this.props.tarjeta.activa) {
            return;
        }

        const { programacion, tarjeta } = this.props;
        const token = auth.getToken();
        const data = new FormData(event.target);

        const url = `programaciones/${programacion}/tarjetas/${tarjeta.id}/gi-tickets`;

        const res = await fetch(apiUrl(url), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.props.getProgramacion();
            toast(response.data);
            this.props.toggle(false);
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });

            if (response.data.message.numero) {
                toast("Este ticket ya ha sido registrado.", "error");
            }
        }

        this.setState({ saving: false });
    };
    addDeposito = () => {
        if (this.state.temp_deposito > 0) {
            this.setState({
                depositos: [
                    ...this.state.depositos,
                    {
                        monto: this.state.temp_deposito,
                        cuenta_id: this.state.temp_cuenta,
                    }
                ],
                temp_cuenta: '',
                temp_deposito: 0,
            });
        }
    };
    removeDeposito = (index) => {
        this.setState({
            depositos: this.state.depositos.filter((d, i) => i !== index)
        });
    };
    onInputChange = async (e, nombre) => {
        await this.setState({
            [nombre]: e.target.value
        });
        this.calcularTotal();
    };
    calcularTotal = () => {
        const {
            d2000,
            d1000,
            d500,
            d200,
            d100,
            d50,
            d25,
            d10,
            d5,
            d1,
        } = this.state;

        this.setState({
            total: (d2000 * 2000) + (d1000 * 1000) + (d500 * 500) + (d200 * 200) + (d100 * 100) + (d50 * 50) + (d25 * 25) + (d10 * 10) + (d5 * 5) + (d1 * 1)
        });
    }
    render() {
        const {
            id,
            d2000,
            d1000,
            d500,
            d200,
            d100,
            d50,
            d25,
            d10,
            d5,
            d1,
            dolares,
            depositos,
            total,
            cuenta_id,
            usd_cuenta_id,
            temp_cuenta,
            temp_deposito,
            saving
        } = this.state;
        const { tarjeta } = this.props;
        const cuentas = [
            {
                id: '',
                nombre: 'Seleccionar',
            },
            ...this.props.cuentas
        ];

        const disabled = !tarjeta.activa;

        return (
            <div className='tickets-form'>
                <form
                    id='ticketForm'
                    method='post'
                    onSubmit={this.createTicket}>
                    <input type='hidden' name='_method' value='post' />
                    <input type='hidden' name='total' value={total} />
                    <input type='hidden' name='tarjeta_id' value={tarjeta.id} />
                    {id && <input type='hidden' name='ticket_id' value={id} />}
                    <div className="row">
                        <div className="col-md-6">
                            <h3>
                                TOTAL RD$: {formatNumber(total)}
                            </h3>
                            <table className='table table-produccion'>
                                <thead>
                                    <tr>
                                        <th style={{width: 120}}>Denominación</th>
                                        <th style={{width: 100}}>Cantidad</th>
                                        <th className="text-right">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><strong>RD$2,000</strong></td>
                                        <td>
                                            <input
                                                type='number'
                                                name='d2000'
                                                step='1'
                                                min='0'
                                                required
                                                disabled={disabled}
                                                onChange={e => this.onInputChange(e, 'd2000')}
                                                className='form-control'
                                                id='d2000'
                                                value={d2000}
                                            />
                                        </td>
                                        <td className="text-right">{formatCurrency(d2000 * 2000)}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RD$1,000</strong></td>
                                        <td>
                                            <input
                                                type='number'
                                                name='d1000'
                                                step='1'
                                                min='0'
                                                required
                                                disabled={disabled}
                                                onChange={e => this.onInputChange(e, 'd1000')}
                                                className='form-control'
                                                id='d1000'
                                                value={d1000}
                                            />
                                        </td>
                                        <td className="text-right">{formatCurrency(d1000 * 1000)}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RD$500</strong></td>
                                        <td>
                                            <input
                                                type='number'
                                                name='d500'
                                                step='1'
                                                min='0'
                                                required
                                                disabled={disabled}
                                                onChange={e => this.onInputChange(e, 'd500')}
                                                className='form-control'
                                                id='d500'
                                                value={d500}
                                            />
                                        </td>
                                        <td className="text-right">{formatCurrency(d500 * 500)}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RD$200</strong></td>
                                        <td>
                                            <input
                                                type='number'
                                                name='d200'
                                                step='1'
                                                min='0'
                                                required
                                                disabled={disabled}
                                                onChange={e => this.onInputChange(e, 'd200')}
                                                className='form-control'
                                                id='d200'
                                                value={d200}
                                            />
                                        </td>
                                        <td className="text-right">{formatCurrency(d200 * 200)}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RD$100</strong></td>
                                        <td>
                                            <input
                                                type='number'
                                                name='d100'
                                                step='1'
                                                min='0'
                                                required
                                                disabled={disabled}
                                                onChange={e => this.onInputChange(e, 'd100')}
                                                className='form-control'
                                                id='d100'
                                                value={d100}
                                            />
                                        </td>
                                        <td className="text-right">{formatCurrency(d100 * 100)}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RD$50</strong></td>
                                        <td>
                                            <input
                                                type='number'
                                                name='d50'
                                                step='1'
                                                min='0'
                                                required
                                                disabled={disabled}
                                                onChange={e => this.onInputChange(e, 'd50')}
                                                className='form-control'
                                                id='d50'
                                                value={d50}
                                            />
                                        </td>
                                        <td className="text-right">{formatCurrency(d50 * 50)}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RD$25</strong></td>
                                        <td>
                                            <input
                                                type='number'
                                                name='d25'
                                                step='1'
                                                min='0'
                                                required
                                                disabled={disabled}
                                                onChange={e => this.onInputChange(e, 'd25')}
                                                className='form-control'
                                                id='d25'
                                                value={d25}
                                            />
                                        </td>
                                        <td className="text-right">{formatCurrency(d25 * 25)}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RD$10</strong></td>
                                        <td>
                                            <input
                                                type='number'
                                                name='d10'
                                                step='1'
                                                min='0'
                                                required
                                                disabled={disabled}
                                                onChange={e => this.onInputChange(e, 'd10')}
                                                className='form-control'
                                                id='d10'
                                                value={d10}
                                            />
                                        </td>
                                        <td className="text-right">{formatCurrency(d10 * 10)}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RD$5</strong></td>
                                        <td>
                                            <input
                                                type='number'
                                                name='d5'
                                                step='1'
                                                min='0'
                                                required
                                                disabled={disabled}
                                                onChange={e => this.onInputChange(e, 'd5')}
                                                className='form-control'
                                                id='d5'
                                                value={d5}
                                            />
                                        </td>
                                        <td className="text-right">{formatCurrency(d5 * 5)}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RD$1</strong></td>
                                        <td>
                                            <input
                                                type='number'
                                                name='d1'
                                                step='1'
                                                min='0'
                                                required
                                                disabled={disabled}
                                                onChange={e => this.onInputChange(e, 'd1')}
                                                className='form-control'
                                                id='d1'
                                                value={d1}
                                            />
                                        </td>
                                        <td className="text-right">{formatCurrency(d1)}</td>
                                    </tr>
                                    <tr>
                                        <td>Cuenta:</td>
                                        <td colSpan="2">
                                            <select name="cuenta_id" required={total > 0}
                                            disabled={disabled} onChange={e => this.onInputChange(e, 'cuenta_id')} className="form-control" value={cuenta_id}>
                                                {cuentas.map(cuenta => <option key={cuenta.id} value={cuenta.id}>{cuenta.nombre}</option>)}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <h3>
                                    TOTAL RD$: {formatNumber(depositos.reduce((a, d) => a += parseInt(d.monto), 0))}
                                </h3>
                                <table className="table table-produccion">
                                    <tbody>
                                        <tr>
                                            <td><strong>Depósitos</strong></td>
                                            <td></td>
                                        </tr>
                                        {depositos.map((deposito, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <select name={`depositos[${index}][cuenta_id]`} required
                                                    disabled={disabled} className="form-control">
                                                        {cuentas.map(cuenta => <option key={cuenta.id} value={cuenta.id} selected={cuenta.id === deposito.cuenta_id}>{cuenta.nombre}</option>)}
                                                    </select>
                                                </td>
                                                <td>
                                                    <div className="flex align-center">
                                                        {deposito.id && <input type="hidden" value={deposito.id} name={`depositos[${index}][id]`} />}
                                                        <input
                                                            type='number'
                                                            name={`depositos[${index}][monto]`}
                                                            step='0.01'
                                                            min='0'
                                                            required
                                                            disabled={disabled}
                                                            className='form-control m-r-10'
                                                            id='dolares'
                                                            defaultValue={deposito.monto}
                                                        />
                                                        {tarjeta.activa && (
                                                            <button type="button" className="btn btn-primary" onClick={() => this.removeDeposito(index)}>
                                                                <i className="fas fa-times"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {tarjeta.activa && (
                                    <table className="table table-produccion m-t-10">
                                        <tbody>
                                            <tr>
                                                <th>Cuenta</th>
                                                <th>Monto</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <select onChange={e => this.onInputChange(e, 'temp_cuenta')} className="form-control" value={temp_cuenta}>
                                                        {cuentas.map(cuenta => <option key={cuenta.id} value={cuenta.id}>{cuenta.nombre}</option>)}
                                                    </select>
                                                </td>
                                                <td>
                                                    <div className="flex align-center">
                                                        <input
                                                            type='number'
                                                            step='0.01'
                                                            min='0'
                                                            required
                                                            disabled={disabled}
                                                            onChange={e => this.onInputChange(e, 'temp_deposito')}
                                                            className='form-control m-r-10'
                                                            id='dolares'
                                                            style={{width: 150}}
                                                            value={temp_deposito}
                                                        />
                                                        <button type="button" className="btn btn-primary" onClick={this.addDeposito}>
                                                            <i className="zmdi zmdi-plus"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                )}
                            </div>
                            <div className="m-t-20">
                                <h3>
                                    TOTAL USD$: {formatNumber(dolares)}
                                </h3>
                                <table className="table table-produccion">
                                    <tbody>
                                        <tr>
                                            <td style={{width: 170}}><strong>Dólares</strong></td>
                                            <td colSpan="2">
                                                <input
                                                    type='number'
                                                    name='dolares'
                                                    step='1'
                                                    min='0'
                                                    required
                                                    disabled={disabled}
                                                    onChange={e => this.onInputChange(e, 'dolares')}
                                                    className='form-control'
                                                    id='dolares'
                                                    value={dolares}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cuenta:</td>
                                            <td colSpan="2">
                                                <select name="usd_cuenta_id" required={dolares > 0}
                                                disabled={disabled} onChange={e => this.onInputChange(e, 'usd_cuenta_id')} className="form-control" value={usd_cuenta_id}>
                                                    {cuentas.map(cuenta => <option key={cuenta.id} value={cuenta.id}>{cuenta.nombre}</option>)}
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    {tarjeta.activa && (<div className="text-right m-t-20">
                        {saving ? (
                            <Loader type='small' />
                        ) : (
                            <button className="btn btn-primary" type="submit">Guardar</button>
                        )}
                    </div>)}
                </form>
            </div>
        );
    }
}
