import React, { Component } from "react";
import Loader from "../../general/Loader";
import { formatCurrency } from "../../../utils/helpers";

class GastosGenerales extends Component {
    state = {
        loading: true,
    };
    render() {
        const { item } = this.props;
        const { loading } = this.state;

        if (loading === 0) {
            return <Loader />;
        }
        return (
            <div>
                {item.gastosGenerales.length > 0 ? (
                    <table className='table tickets-table'>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Descripción</th>
                                <th>Categoría</th>
                                <th>Precio/UND</th>
                                <th>Cantidad</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {item.gastosGenerales.map((gasto) => (
                                <tr key={gasto.id}>
                                    <td>{gasto.fecha}</td>
                                    <td>{gasto.descripcion}</td>
                                    <td>{gasto.categoria}</td>
                                    <td>{formatCurrency(gasto.precio)}</td>
                                    <td>{gasto.cantidad}</td>
                                    <td>
                                        {formatCurrency(
                                            gasto.precio * gasto.cantidad
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>
                        No se han registrado gastos generales de este item.
                    </div>
                )}
            </div>
        );
    }
}

export default GastosGenerales;
