import React, { Component } from "react";
import Select from "react-select";
import queryString from "query-string";

import { formatCurrency, toast } from "../../utils/helpers";

export default class GastoItem extends Component {
    state = {
        grupo: "",
        cantidad: 1,
        costo: 0,
        costoMin: 0,
        subtotal: 0,
        disponible: 0,
        valid: true,
        errors: {},
    };
    onChangeInput = async (name, value) => {
        await this.setState({
            [name]: value,
        });

        const { cantidad, costo, disponible } = this.state;
        const { gasto } = this.props;

        if (parseFloat(cantidad) > disponible) {
            this.setState({ valid: false });
            toast("Cantidad disponible: " + disponible, "error");
            return;
        } else if (parseFloat(cantidad) < 0.01 || cantidad === "") {
            toast("Cantidad no puede ser 0", "error");
            this.setState({ valid: false });
        } else {
            this.setState({ valid: true });
        }
        this.props.updateTotal(gasto.id, cantidad * costo);
    };
    onChangeGrupo = (e) => {
        this.setState({
            grupo: e.label,
        });
    };
    onChangeItem = async (itemId) => {
        let item = this.props.items.filter((item) => item.id === itemId)[0];
        item = item ? item : {};

        await this.setState({
            id: item.id ? item.id : null,
            nombre: item.nombre ? item.nombre : null,
            costo: item.costo ? item.costo : null,
            costoMin: item.min ? item.min : item.costo,
            grupo: item.grupo ? item.grupo.nombre : null,
            disponible: item.cantidad ? item.cantidad : 0,
            valid: item.cantidad > 0,
        });

        const { gasto } = this.props;
        this.props.updateTotal(gasto.id, parseFloat(item.costo));
    };
    render() {
        const {
            index,
            items,
            fecha,
            gasto,
            vehiculo,
            mecanico,
            removeGasto,
        } = this.props;
        const {
            cantidad,
            costo,
            costoMin,
            disponible,
            grupo,
            errors,
            valid,
        } = this.state;

        const query = queryString.parse(window.location.search);

        return (
            <tr
                style={{
                    backgroundColor: !valid
                        ? "rgba(255,0,0,0.07)"
                        : "transparent",
                }}>
                <td>
                    <input
                        type='hidden'
                        name={`gastos[${index}][fecha]`}
                        value={fecha}
                    />
                    <input
                        type='hidden'
                        name={`gastos[${index}][vehiculo_id]`}
                        value={query.vehiculo ? query.vehiculo : vehiculo}
                    />
                    <input
                        type='hidden'
                        name={`gastos[${index}][mecanico_id]`}
                        value={query.mecanico ? query.mecanico : mecanico}
                    />
                    <input
                        type='hidden'
                        name={`gastos[${index}][registro_servicio_id]`}
                        value={query.registro}
                    />
                    <Select
                        options={items.map((item) => ({
                            label: `${item.referencia} - ${item.nombre}`,
                            value: item.id,
                        }))}
                        isLoading={items.length === 0}
                        loadingMessage={() => "Cargando items..."}
                        required
                        onChange={(e) => {
                            if (!e) e = {};
                            this.onChangeItem(e.value);
                        }}
                        name={`gastos[${index}][item_id]`}
                        isClearable={true}
                    />
                    {errors.nombre && (
                        <small className='help-blockParams form-Text'>
                            {errors.nombre[0]}
                        </small>
                    )}
                </td>
                <td className='text-center'>{disponible}</td>
                <td>
                    <input
                        type='number'
                        name={`gastos[${index}][cantidad]`}
                        value={cantidad}
                        min='0.01'
                        disabled={disponible < 0.01}
                        max={disponible}
                        onChange={(e) =>
                            this.onChangeInput("cantidad", e.target.value)
                        }
                        id=''
                        className='form-control'
                        step='0.01'
                    />
                </td>
                <td className='text-center'>
                    <input
                        type='number'
                        name={`gastos[${index}][precio_unidad]`}
                        value={costo}
                        min={costoMin}
                        onChange={(e) =>
                            this.onChangeInput("costo", e.target.value)
                        }
                        id=''
                        className='form-control'
                        step='0.01'
                    />
                </td>
                <td className='text-center'>{grupo}</td>
                <td className='text-right'>
                    {formatCurrency(costo * cantidad)}
                </td>
                <td>
                    <button
                        type='button'
                        className='btn'
                        onClick={() => removeGasto(gasto.id)}>
                        <i className='fas fa-trash'></i>
                    </button>
                </td>
            </tr>
        );
    }
}
