import React from "react";

const InvalidScreen = ({message}) => {
		return (
			<div style={{height: "80vh"}} className="w-100 d-flex flex-column justify-content-center align-items-center">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					width="100"
					height="100"
					version="1.1"
					id="Layer_1"
					x="0px"
					y="0px"
					viewBox="0 0 489.068 489.068"
					style={{enableBackground:"new 0 0 489.068 489.068"}}
					xmlSpace="preserve"
					fill="#008aab"
				>
					<g>
						<g>
							<path d="M486.011,456.66l-222.6-445.2c-7.8-14.5-29.9-15.9-37.5,0l-223.6,448.4c-6.4,9.8,0.7,29.1,18.7,29.1h447.3    C484.511,490.16,494.411,470.86,486.011,456.66z M53.211,448.36l191.5-382.8l190.4,382.8H53.211z"></path>
							<path d="M223.911,177.86v125.9c0,11.4,9.4,20.8,20.8,20.8c11.4,0,19.8-9.4,20.8-20.8v-125.9c0-11.4-9.4-20.8-20.8-20.8    S223.911,166.46,223.911,177.86z"></path>
							<path d="M244.711,347.46c-11.4,0-20.8,9.4-20.8,20.8v13.5c0,11.4,9.4,20.8,20.8,20.8c11.4,0,19.8-9.4,20.8-20.8v-13.5    C265.511,356.86,256.111,347.46,244.711,347.46z"></path>
						</g>
					</g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
				</svg>
				<h3 className="mt-3" style={{color: "#008aab"}}>{message}</h3>
			</div>
		);
}

export default InvalidScreen;