import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import Select from "react-select";

import {
	getLink,
	formatCurrency,
	toast,
	getZonasForDropdown,
	getVendedoresForDropdown,
	getCategoriasForDropdown,
} from "../../../utils/helpers";
import CrearFacturaRecurrente from "./Create";
import PageHeader from "../../general/PageHeader";
import Loader from "../../general/Loader";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import { request } from "../../../utils/request";
import { apiRequest } from "../../../hooks/apiRequest";
import EditFacturaRecurrente from "./Edit";
import PrefixedInput from "../../general/PrefixedInput";
import FiltersContainer from "../../general/FiltersContainer";
import FacturasGroup from "./Components/FacturasGroup";
import useFilters from "../../../hooks/useFilters";
import { ChequeInfo, DepositoInfo, OtroInfo, TarjetaInfo, TransferenciaInfo } from "../Facturacion";
import { METODOS_PAGO } from "../../../utils/types";

const estados = [
	{ value: "", label: "Todos" },
	{ value: "activo", label: "Activas" },
	{ value: "inactivo", label: "Inactivas" },
];

const frecuenciaPago = [
	{ value: "", label: "Todos" },
	{ value: "mensual", label: "Mensual" },
	{ value: "anual", label: "Anual" },
];

const FacturasRecurrentes = () => {
	// Route  matcher
	let match = useRouteMatch();
	const formRef = useRef();
	const formInfoPagoRef = useRef();

	const { handleFilterChange, filtersQuery } = useFilters({
		buscar: "",
		vendedor: "",
		estado: "activo",
		frecuencia_pago: "",
		desde: "",
		hasta: "",
	});

	const [facturas, setFacturas] = useState({ data: [] });
	const [vendedores, setVendedores] = useState([]);
	const [zonas, setZonas] = useState([]);
	const [categorias, setCategorias] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showValidateModal, setShowValidateModal] = useState(false);

	// Modals show/hide properties
	const [showAgregarPagoModal, setShowAgregarPagoModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showAgregarInfoPagoModal, setShowAgregarInfoPagoModal] =
		useState(false);

	const [facturaSeleccionada, setFacturaSeleccionada] = useState({});
	const [pagoError, setPagoError] = useState({});
	const [pagoInfo, setPagoInfo] = useState({
		pago: 0,
		tipo_pago: "efectivo",
	});

	const getFacturas = (url) => {
		url = url ? url + "&limit=15" : "facturas-recurrentes?limit=15";
		setIsLoading(true);
		// setTempUrl(url);

		request(url).then((res) => {
			setFacturas(res);
			setIsLoading(false);
		});
	};

	const openPagarFacturaModal = (factura) => {
		setFacturaSeleccionada(factura);
		setPagoInfo((state) => ({ tipo_pago: factura.tipo_pago }));
		setShowAgregarPagoModal(true);
	};

	const guardarPago = async (event) => {
		event.preventDefault();

		const data = new FormData(event.target);

		const response = await apiRequest(
			data,
			`agregar-pago-factura/${facturaSeleccionada.id}`,
			"POST"
		);

		if (response.success) {
			toast("Pago Agregado", "success");
			setShowAgregarPagoModal(false);
			setFacturaSeleccionada({});
			setPagoInfo({ pago: null, tipo_pago: "efectivo" });
			getFacturas(`facturas-recurrentes${filtersQuery}`);
			window.open(
				getLink(`/imprimir/facturas/${facturaSeleccionada.id}`)
			);
			return;
		} else if (response.code === 422) {
			setPagoError(response.data.message);
			return;
		}

		toast(response.data.message, "error");
	};

	const guardarInfoMetodoPago = async (event) => {
		event.preventDefault();

		const data = new FormData(event.target);

		const response = await apiRequest(
			data,
			`agregar-info-metodo-pago-factura/${facturaSeleccionada.id}`,
			"POST"
		);

		if (response.success) {
			setShowAgregarInfoPagoModal(false);
			setFacturaSeleccionada({});
			window.open(
				getLink(
					`/imprimir/facturas-recurrentes/${facturaSeleccionada.id}`
				)
			);
			// setInfoMetodoPago({});
			return;
		} else if (response.code === 422) {
			setPagoError(response.data.message);
			return;
		}

		toast(response.data.message, "error");
	};

	const setPorPagar = () => {
		setPagoInfo((state) => ({
			...state,
			pago: facturaSeleccionada.por_cobrar,
		}));
	};

	const toggleDeleteModal = (factura = {}) => {
		setFacturaSeleccionada(factura);
		setShowDeleteModal((state) => !state);
	};

	const removeFactura = async (facturaId) => {
		const response = await apiRequest(
			null,
			`facturas-recurrentes/${facturaId}`,
			"DELETE"
		);

		if (response.success) {
			toast("Factura recurrente eliminada");
			getFacturas(`facturas-recurrentes${filtersQuery}`);
			toggleDeleteModal();
			return;
		}

		toast("error eliminando Factura recurrente", "error");
	};

	const openValidarFacturaModal = (factura) => {
		setFacturaSeleccionada(factura);
		setShowValidateModal(true);
	};

	const validateFactura = async (facturaId) => {
		const response = await apiRequest(
			null,
			`facturas/${facturaId}/validate`,
			"PUT"
		);

		if (response.success) {
			getFacturas(`facturas-recurrentes${filtersQuery}`);
			setShowValidateModal(false);
			toast("Factura validada");
			return;
		}

		toast("Error Validando Factura", "error");
	};

	useEffect(() => {
		if (filtersQuery === "") return;
		getFacturas(`facturas-recurrentes${filtersQuery}`);
	}, [filtersQuery]);

	useEffect(() => {
		getVendedoresForDropdown().then((data) => {
			setVendedores(data);
		});
		getZonasForDropdown().then((data) => {
			setZonas(data);
		});
		getCategoriasForDropdown().then((data) => {
			setCategorias(data);
		});
	}, []);
	return (
		<>
			<PageHeader title="Facturas Recurrentes">
				<div className="col-12 col-md-2 pr-0">
					<label
						htmlFor="desde"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Desde
					</label>
					<input
						type="date"
						name="desde"
						id="desde"
						className="form-control"
						onChange={(e) =>
							handleFilterChange("desde", e.target.value)
						}
					/>
				</div>
				<div className="col-12 col-md-2 pr-0 m-r-15">
					<label
						htmlFor="hasta"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Hasta
					</label>
					<input
						type="date"
						name="hasta"
						id="hasta"
						className="form-control"
						onChange={(e) =>
							handleFilterChange("hasta", e.target.value)
						}
					/>
				</div>
				<Link
					to={getLink(
						`/imprimir/facturas-recurrentes${filtersQuery}`
					)}
					target="_blank"
					className="au-btn-filter m-r-15"
				>
					<i className="zmdi zmdi-print" /> Imprimir
				</Link>
				<Link
					className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15"
					to={`${match.url}/crear`}
				>
					<i className="zmdi zmdi-plus" />
					Añadir Factura
				</Link>
			</PageHeader>
			<FiltersContainer>
				<div className="col">
					<label>Buscar</label>
					<input
						onChange={(e) =>
							handleFilterChange("buscar", e.target.value)
						}
						type="search"
						placeholder="Buscar..."
						className="form-control"
						name="buscar"
					/>
				</div>
				<div className="col-md-2">
					<label>Frecuencia de pago</label>
					<Select
						id="frecuencia_pago"
						options={frecuenciaPago}
						name="frecuencia_pago"
						onChange={(e) =>
							handleFilterChange("frecuencia_pago", e.value)
						}
						placeholder="Todos"
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
				<div className="col-md-2">
					<label>Estado</label>
					<Select
						id="estado"
						options={estados}
						name="estado"
						onChange={(e) => handleFilterChange("estado", e.value)}
						placeholder="Activas"
						defaultValue={estados[1]}
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
				<div className="col-md-2">
					<label>Zona</label>
					<Select
						id="zona"
						options={zonas}
						name="zona"
						onChange={(e) => handleFilterChange("zona", e.value)}
						placeholder="Todos"
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
				<div className="col-md-2">
					<label>Vendedor</label>
					<Select
						id="vendedor"
						options={vendedores}
						name="vendedor"
						placeholder="Todos"
						onChange={(e) => handleFilterChange("vendedor", e.value)}
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
				<div className="col-md-2">
					<label>Categoría</label>
					<Select
						id="categoria"
						options={categorias}
						name="categoria"
						isMulti
						isClearable
						placeholder="Todas"
						onChange={(values) =>
							handleFilterChange("categoria", values.map((v) => v.value).join(","))
						}
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
			</FiltersContainer>
			<div className="row px-3">
				<div className="col-md-12">
					<div>
						{isLoading && <Loader />}
						{facturas.data?.length <= 0 ? (
							<div>
								{!isLoading && (
									<div>
										No hay Facturas recurrentes creadas.
									</div>
								)}
							</div>
						) : (
							<div className="tabla-descuentos">
								<div className="tabla-descuentos__header descuento-row">
									<div>Cliente</div>
									<div>Detalle</div>
									<div>Monto</div>
									<div>Inicio</div>
									<div>Fechas</div>
									<div></div>
								</div>
								<div className="tabla-descuentos__body">
									{facturas.data.map((factura) => (
										<FacturasGroup
											factura={factura}
											openPagarFacturaModal={
												openPagarFacturaModal
											}
											toggleDeleteModal={
												toggleDeleteModal
											}
											openValidarFacturaModal={openValidarFacturaModal}
											key={factura.id}
										/>
									))}
								</div>
								{facturas.links && (
									<Pagination
										links={facturas.links}
										meta={facturas.meta}
										getData={getFacturas}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			{/* AGREGAR PAGO MODAL */}
			<Modal
				title="Agregar Pago"
				show={showAgregarPagoModal}
				callback={() => {
					if (!pagoInfo.pago || pagoInfo.pago <= 0) {
						toast("Agregue el monto a pagar", "error");
						return;
					}
					formRef.current.dispatchEvent(
						new Event("submit", { bubbles: true, cancelable: true })
					);
				}}
				acceptText="Aceptar"
				toggle={() => {
					setPagoInfo({ pago: null, tipo_pago: "efectivo" });
					setShowAgregarPagoModal((state) => !state);
				}}
				size="419px"
			>
				<form method="post" onSubmit={guardarPago} ref={formRef}>
					<div className="form-group">
						<label>Tipo de pago</label>
						<select
							className="form-control"
							name="tipo_pago"
							value={pagoInfo.tipo_pago}
							onChange={(e) => {
								e.persist();
								setPagoInfo((state) => ({
									...state,
									terminal_tarjeta: "",
									tipo: "visa",
									banco: "",
									num_aprobacion: "",
									num_cheque: "",
									num_transferencia: "",
									num_transaccion: "",
									num_deposito: "",
									tipo_pago: e.target.value,
								}));
							}}
						>
							{METODOS_PAGO.map((metodo) => (
								<option value={metodo.value}>
									{metodo.label}
								</option>
							))}
						</select>
					</div>

					{pagoInfo.tipo_pago === "cheque" && (
						<ChequeInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}
					{pagoInfo.tipo_pago === "tarjeta" && (
						<TarjetaInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}
					{pagoInfo.tipo_pago === "transferencia" && (
						<TransferenciaInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}
					{pagoInfo.tipo_pago === "deposito" && (
						<DepositoInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}
					{pagoInfo.tipo_pago === "otro" && (
						<OtroInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}

					<div className="form-group">
						<label>Monto Recibido</label>
						<PrefixedInput
							type="number"
							prefix="RD$"
							name="monto"
							value={pagoInfo.pago}
							setValue={(e) =>
								setPagoInfo((state) => ({ ...state, pago: e }))
							}
						/>
						<input
							type="hidden"
							min="0"
							className="form-control"
							defaultValue={formatCurrency(pagoInfo.pago)}
							onChange={(e) => {
								e.persist();
								setPagoInfo((state) => ({
									...state,
									pago: e.target.value,
								}));
							}}
						/>
						<input
							type="hidden"
							name="monto"
							value={pagoInfo.pago}
						/>
						{pagoError.monto && (
							<small className="help-blockParams form-Text">
								{pagoError.monto[0]}
							</small>
						)}
					</div>
					<div
						className="row"
						style={{ justifyContent: "space-around" }}
					>
						<div
							className="col-5 text-center"
							style={{
								border: "1px solid #cdd2d7",
								borderRadius: "7px",
								paddingTop: "8px",
								paddingBottom: "8px",
								cursor: "pointer",
							}}
							onClick={() => setPorPagar()}
						>
							<h5 className="mb-2">Balance:</h5>
							<p>
								{formatCurrency(facturaSeleccionada.por_cobrar)}
							</p>
						</div>
						<div
							className="col-5 text-center"
							style={{
								border: "1px solid #cdd2d7",
								borderRadius: "7px",
								paddingTop: "8px",
								paddingBottom: "8px",
							}}
						>
							<h5 className="mb-2">Devuelta:</h5>
							<p>
								{pagoInfo.pago >
								parseFloat(facturaSeleccionada.por_cobrar)
									? formatCurrency(
											pagoInfo.pago -
												facturaSeleccionada.por_cobrar
									  )
									: 0}
							</p>
						</div>
					</div>
                    <div className="form-group">
						<label>Retención</label>
						<PrefixedInput
							type="number"
							prefix="RD$"
							name="retencion"
							value={pagoInfo.retencion}
							setValue={(e) =>
								setPagoInfo((state) => ({ ...state, pago: e }))
							}
						/>
					</div>
				</form>
			</Modal>

			{/* AGREGAR INFORMACIÓN DE PAGO MODAL */}
			<Modal
				title="Agregar Información de pago"
				show={showAgregarInfoPagoModal}
				callback={() => {
					formInfoPagoRef.current.dispatchEvent(
						new Event("submit", { bubbles: true, cancelable: true })
					);
				}}
				acceptText="Aceptar"
				toggle={() => {
					// setInfoMetodoPago({});
					setShowAgregarInfoPagoModal((state) => !state);
				}}
				size="419px"
			>
				<form
					method="post"
					onSubmit={guardarInfoMetodoPago}
					ref={formInfoPagoRef}
				>
					{facturaSeleccionada.tipo_pago === "cheque" && (
						<ChequeInfo factura={facturaSeleccionada} />
					)}
					{facturaSeleccionada.tipo_pago === "tarjeta" && (
						<TarjetaInfo factura={facturaSeleccionada} />
					)}
					{facturaSeleccionada.tipo_pago === "transferencia" && (
						<TransferenciaInfo factura={facturaSeleccionada} />
					)}
					{facturaSeleccionada.tipo_pago === "deposito" && (
						<DepositoInfo factura={facturaSeleccionada} />
					)}
					{facturaSeleccionada.tipo_pago === "otro" && (
						<OtroInfo factura={facturaSeleccionada} />
					)}
				</form>
			</Modal>

			{/* CONFIRMACIÓN DE ELIMINACIÓN DE RECURRENCIA */}
			<Modal
				title="Eliminar Factura Recurrente"
				show={showDeleteModal}
				callback={() => removeFactura(facturaSeleccionada.id)}
				acceptText="Borrar"
				toggle={toggleDeleteModal}
			>
				¿Estás seguro de que deseas eliminar esta Factura recurrente?
				(esta acción no se puede deshacer)
			</Modal>

			{/* VALIDAR FACTURA RECURRENTE MODAL */}
			<Modal
				title="Validar Factura Recurrente"
				show={showValidateModal}
				callback={() => validateFactura(facturaSeleccionada.id)}
				acceptText="Validar"
				toggle={() => {
					setShowValidateModal((state) => !state);
				}}
			>
				¿Estás seguro de que deseas validar esta Factura?
			</Modal>
			{/* CREAR FACTURA */}
			<Switch>
				<Route path={`${match.url}/crear`}>
					<CrearFacturaRecurrente />
				</Route>
				<Route path={`${match.url}/:id/edit`}>
					<EditFacturaRecurrente />
				</Route>
			</Switch>
		</>
	);
};

export default FacturasRecurrentes;
