import React, { useEffect, useState } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import Select from "react-select";

import { request } from "../../../utils/request";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../../general/Loader";
import { getLink } from "../../../utils/helpers";
import { debounce } from "lodash";

const Evaluaciones = () => {
    const [evaluaciones, setEvaluaciones] = useState([]);
    const [evaluacion, setEvaluacion] = useState([]);
    const [grados, setGrados] = useState([]);
    const [periodosAcademicos, setPeriodosAcademicos] = useState([]);
    const [links, setLinks] = useState({});
    const [meta, setMeta] = useState({});
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filtros, setFiltros] = useState({
        buscar: "",
        tipo: "",
        estado: "todos",
    });
    const [filtrosQuery, setFiltrosQuery] = useState('');
    const [periodoActivo, setPeriodoActivo] = useState({
        nombre: 'Todos',
        id: ''
    });

    const getTable = async (url, name = null) => {
        const table = name ? name : url;
        request(url).then(res => {
            if (res.data) {
                switch (table) {
                    case "grados":
                        setGrados(res.data)
                        break;
                    case "periodosAcademicos":
                        setPeriodosAcademicos(res.data)
                        break;
                }
            }
        });
    };

    const history = useHistory();

    const getEvaluaciones = async (url = null) => {
        url = url ? url + "&limit=20" : "academico/evaluaciones?limit=20" + `&${filtrosQuery}`;
        request(url).then(evs => {
            if (evs.links) {
                setEvaluaciones(evs.data);
                setLinks(evs.links);
                setMeta(evs.meta);
            }
        });
        setLoading(false);
    };
    const toggleCreateModal = () => {
        setShowCreate(prev => !prev);
        setEvaluacion({});
    };
    const toggleEditModal = (evaluacion) => {
        setShowEdit(prev => !prev);
        setEvaluacion(evaluacion)
        history.push(getLink("/academico/evaluaciones"));
    };
    const onFiltroChange = debounce(async (value, filtro) => {
        setLoading(true);
        const filtrosRaw = {
            ...filtros,
            [filtro]: encodeURIComponent(value),
        }
        setFiltros(filtrosRaw);
        const query = new URLSearchParams(filtrosRaw);
        setFiltrosQuery(query);

        await getEvaluaciones("academico/evaluaciones?" + query.toString());
        setLoading(false);
    }, 500);

    useEffect(() => {
        getTable('academico/grados', 'grados');
        getTable('academico/periodos-academicos', 'periodosAcademicos');
    }, []);

    useEffect(() => {
        let pactivo = periodosAcademicos?.find(p => filtros.periodo_academico ? p.id == filtros.periodo_academico : p.estado === 'pendiente');

        if (!pactivo && periodosAcademicos.length > 0) {
            pactivo = periodosAcademicos[0]
        }

        if (pactivo) {
            setPeriodoActivo(pactivo);
            onFiltroChange(
                pactivo.id,
                "periodo_academico"
            );
        } else {
            getEvaluaciones();
        }
    }, [periodosAcademicos])

    return (
        <div>
            <div className='page-header'>
                <h1 className='page-title row'>
                    <span className='col'>Evaluaciones {periodoActivo?.nombre}</span>

                    <div className="col-12 col-md-6 col-lg-2 pr-md-0">
                        <label
                            htmlFor="desde"
                            style={{ fontSize: "12px", textTransform: "uppercase" }}
                        >
                            Desde
                        </label>
                        <input
                            type="date"
                            name="desde"
                            id="desde"
                            className="form-control"
                            onChange={(e) =>
                                onFiltroChange(
                                    e.target.value,
                                    "desde"
                                )
                            }
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-2">
                        <label
                            htmlFor="hasta"
                            style={{ fontSize: "12px", textTransform: "uppercase" }}
                        >
                            Hasta
                        </label>
                        <input
                            type="date"
                            name="hasta"
                            id="hasta"
                            className="form-control"
                            onChange={(e) =>
                                onFiltroChange(
                                    e.target.value,
                                    "hasta"
                                )
                            }
                        />
                    </div>

                    <Link
                        to={getLink(
                            `/imprimir/academico/evaluaciones?${filtrosQuery}`
                        )}
                        target="_blank"
                        className='au-btn au-btn-icon au-btn-filter m-r-20'>
                        <i className='zmdi zmdi-print'></i> Imprimir
                    </Link>
                    <button
                        className='au-btn au-btn-icon au-btn--green au-btn--small'
                        onClick={toggleCreateModal}>
                        <i className='zmdi zmdi-plus' />
                        Nueva Evaluación
                    </button>
                </h1>
                <div className='page-controls'>
                    <div className='table-data__tool'>
                        <div className='table-data__tool-left row'>
                            <div className='col'>
                                <label htmlFor='search'>Buscar</label>
                                <input
                                    onChange={(e) =>
                                        onFiltroChange(
                                            e.target.value,
                                            "buscar"
                                        )
                                    }
                                    type='search'
                                    placeholder='Buscar...'
                                    className='form-control'
                                    name='buscar'
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='tipo'>Grado</label>
                                <Select
                                    name='grados'
                                    id='grados'
                                    defaultValue={{label: 'Todos', value: ''}}
                                    onChange={(e) =>
                                        onFiltroChange(
                                            e.value,
                                            "grados"
                                        )
                                    }
                                    options={grados && [
                                        {label: 'Todos', value: 'todos'},
                                        ...grados.map(g => {
                                            return {label: g.nombre, value: g.id}
                                        })
                                    ]}
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='estado'>Año Escolar</label>
                                <Select
                                    name='periodo_academico'
                                    id='periodo_academico'
                                    value={periodoActivo && {
                                        label: periodoActivo.nombre,
                                        value: periodoActivo.id
                                    }}
                                    onChange={(e) => {
                                        onFiltroChange(
                                            e.value,
                                            "periodo_academico"
                                        );
                                        setPeriodoActivo(e.item);
                                    }}
                                    options={periodosAcademicos && [
                                        {label: 'Todos', value: 'todos'},
                                        ...periodosAcademicos.map(p => ({
                                            label: p.nombre,
                                            value: p.id,
                                            item: p
                                        }))
                                    ]}
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='informal'>Pago</label>
                                <Select
                                    name='pago'
                                    id='pago'
                                    defaultValue={{label: 'Todos', value: ''}}
                                    onChange={(e) =>
                                        onFiltroChange(
                                            e.value,
                                            "pago"
                                        )
                                    }
                                    options={[
                                        {value: '', label: 'Todos'},
                                        {label: 'Pendiente', value: 'Pendiente'},
                                        {label: 'Exonerado', value: 'Exonerado'},
                                        {label: 'Procesado', value: 'Procesado'},
                                        {label: 'Devuelto', value: 'Devuelto'},
                                    ]}
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='informal'>Estado</label>
                                <Select
                                    name='estado'
                                    id='estado'
                                    onChange={(e) =>
                                        onFiltroChange(
                                            e.value,
                                            "estado"
                                        )
                                    }
                                    defaultValue={{label: 'Todos', value: ''}}
                                    options={[
                                        {label: 'Todos', value: ''},
                                        {label: 'Pendiente', value: 'Pendiente'},
                                        {label: 'Aprobado', value: 'Aprobado'},
                                        {label: 'Reevaluar', value: 'Reevaluar'},
                                        {label: 'Reprovado', value: 'Reprovado'},
                                        {label: 'Inscrito', value: 'Inscrito'},
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div>
                        {loading && <Loader />}
                        {!loading && evaluaciones.length === 0 && (
                            <div>
                                {!loading && (
                                    <div>
                                        No hay ninguna evaluación
                                        creada.
                                    </div>
                                )}
                            </div>
                        )}
                        {!loading && evaluaciones?.length > 0 && (
                            <div className='table-data'>
                                <table className='table table-data2'>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Fecha</th>
                                            <th>Curso/Año</th>
                                            <th>Procedencia</th>
                                            <th>Pago</th>
                                            <th>Estado</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {evaluaciones?.map((evaluacion) => {
                                            const pagoEvaluacion = evaluacion.estudiante?.pagos?.find(p => p.nombre.includes('valuaci'));
                                            return (
                                                <tr
                                                    className='tr-shadow'
                                                    style={{lineHeight: 1.3}}
                                                    key={evaluacion.id}>
                                                    <td>
                                                        <strong>{evaluacion.nombreCompleto}</strong>
                                                        <div>Tel: {evaluacion.telefono}</div>
                                                    </td>
                                                    <td>{dayjs(evaluacion.fecha).format('DD-MM-YYYY')}</td>
                                                    <td>
                                                        {evaluacion.grado?.nombre}
                                                        <div><small>{evaluacion.periodoAcademico?.nombre}</small></div>
                                                    </td>
                                                    <td>{evaluacion.procedencia}</td>
                                                    <td>
                                                        {pagoEvaluacion?.estado && (
                                                            <span className={{
                                                                "pagado": "uppercase badge badge-success",
                                                                "Exonerado": "uppercase badge badge-primary",
                                                                "Devuelto": "uppercase badge badge-danger",
                                                                "pendiente": "uppercase badge badge-warning",
                                                            }[pagoEvaluacion.estado]}>
                                                                {pagoEvaluacion.estado}
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <span className={{
                                                            "Aprobado": "uppercase badge badge-success",
                                                            "Inscrito": "uppercase badge badge-primary",
                                                            "Reprovado": "uppercase badge badge-danger",
                                                            "Pendiente": "uppercase badge badge-warning",
                                                        }[evaluacion.estado]}>{evaluacion.estado}</span>
                                                    </td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <Link
                                                                to={getLink('/academico/evaluaciones/'+evaluacion.id)}
                                                                className="item"
                                                            >
                                                                <i className='zmdi zmdi-edit' />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                        )})}
                                    </tbody>
                                </table>
                                {links && (
                                    <Pagination
                                        links={links}
                                        meta={meta}
                                        getData={getEvaluaciones}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                show={showCreate}
                blank={true}
                size="1048px"
                toggle={toggleCreateModal}>
                <Create
                    toggle={toggleCreateModal}
                    evaluacion={evaluacion}
                    periodosAcademicos={periodosAcademicos}
                    grados={grados}
                    getEvaluaciones={getEvaluaciones}
                />
            </Modal>
            <Route
                path='/:sucursal/academico/evaluaciones/:id'
                render={(props) => (
                    <Modal
                        show
                        blank
                        size="1048px"
                        toggle={() => toggleEditModal({})}>
                        <Edit
                            toggle={() => toggleEditModal({})}
                            evaluacion={
                                evaluaciones.filter(
                                    (r) =>
                                        r.id ===
                                        parseInt(props.match.params.id)
                                )[0] || {}
                            }
                            periodosAcademicos={periodosAcademicos}
                            grados={grados}
                            getEvaluaciones={getEvaluaciones}
                        />
                    </Modal>
                )}
            />
        </div>
    );

}

export default Evaluaciones;
