import React, { Component } from "react";

import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";

import Loader from "../../general/Loader";

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

class CreateMany extends Component {
    state = {
        grupos: [],
        saving: false,
        errors: {},
    };
    createProgramacion = async (event) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl('gi-prestamos'), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.getPrestamos();
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false,
        });
    };
    render = () => {
        const { toggle, grupos, miembrosIngresados, semana } = this.props;
        return (
            <div>
                <form onSubmit={this.createProgramacion}>
                    <input type="hidden" name="semana" value={semana.numero} />
                    <input type="hidden" name="desde" value={semana.desde} />
                    <input type="hidden" name="hasta" value={semana.hasta} />
                    <div className='popup__header'>Crear todos</div>
                    <div className='popup__body'>
                        {grupos.filter(g => g.miembros.length > 0).map((grupo, index) => (
                            <fieldset className="form-group" key={grupo.id}>
                                <legend>{grupo.nombreCompleto} <small>({grupo.alias})</small></legend>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Grupo</th>
                                            <th>Deuda Inicial</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {grupo.miembros
                                            .filter(m => !miembrosIngresados.includes(m.id) && m.estado === 'activo')
                                            .map((miembro,i) => (
                                            <tr key={miembro.id}>
                                                <td>
                                                    <input type="hidden" name={`grupos[${miembro.id}][id]`} value={grupo.id} />
                                                    <input type="hidden" name={`grupos[${miembro.id}][miembro_id]`} value={miembro.id} />
                                                    {miembro.nombre} - {miembro.servidor}
                                                </td>
                                                <td>
                                                    <input
                                                        className='form-control'
                                                        type='number'
                                                        name={`grupos[${miembro.id}][deuda_inicial]`}
                                                        step='any'
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </fieldset>
                        ))}
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            onClick={toggle}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {this.state.saving ? (
                            <Loader type='small' />
                        ) : (
                            <button type='submit' className='btn btn-primary'>
                                Crear
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    };
}

export default withRequest(CreateMany);
