import React, { Component } from "react";
import { withRequest } from "../../../utils/request";
import Loader from "../../general/Loader";
import { formatCurrency, getLink } from "../../../utils/helpers";
import { Link } from "react-router-dom";

class Nominas extends Component {
    state = {
        pagos: [],
        loading: true
    };
    componentDidMount() {
        this.getPagos();
    }
    getPagos = async (url = null) => {
        const id = this.props.empleado.id;
        url = url ? url : `empleados/${id}/pagos`;
        const pagos = await this.props.request(url);

        if (pagos.success) {
            this.setState({
                pagos: pagos.data,
                loading: false
            });
        }
    };
    render() {
        const { pagos, loading } = this.state;

        if (loading === 0) {
            return <Loader />;
        }
        return (
            <div>
                <div className='row m-b-20'>
                    <div className='col'>
                        <h3>Historial de Pagos</h3>
                    </div>
                    <div className='col text-right'>
                        <Link
                            to={getLink(
                                `/imprimir/empleados/${this.props.empleado.id}/nominas`
                            )}
                            target="_blank"
                            title="Impresión Doble"
                            className='au-btn au-btn-icon au-btn-filter'>
                            <i class="zmdi zmdi-print"></i>
                            Imprimir
                        </Link>
                    </div>
                </div>
                <table className='table tickets-table'>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Sueldo Fijo</th>
                            <th>Descuentos</th>
                            <th>Incentivos Fijos</th>
                            <th>Incentivos Únicos</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pagos.map((pago, index) => (
                            <tr key={index}>
                                <td>{pago.fecha}</td>
                                <td>{formatCurrency(pago.sueldoBruto)}</td>
                                <td>{formatCurrency(pago.descuentosTotal)}</td>
                                <td>{formatCurrency(pago.incentivosFijos)}</td>
                                <td>{formatCurrency(pago.incentivosUnicos)}</td>
                                <td>{formatCurrency(pago.total)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRequest(Nominas);
