import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { request } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency, getModalidadAusencia } from "../../../utils/helpers";
import dayjs from "dayjs";

const useData = () => {
    const [ausencias, setAusencias] = useState([]);
	const [empleado, setEmpleado] = useState();

	const { id } = useParams();
    const location = useLocation();

    const getAusencias = useCallback(() => {
		const searchString = location.search === "" ? "?paginate=100000" : location.search + "&paginate=1000000";

		request(`empleados/${id}/ausencias${searchString}`).then((res) => {
			setAusencias(res.data);
		});
	}, [location, id]);

	const getEmpleado = useCallback(() => {
		request(`empleados/${id}`).then((res) => {
			setEmpleado(res.data);
		});
	}, [id]);

    useEffect(() => {
        getAusencias();
		getEmpleado();
    }, [getAusencias, getEmpleado]);

    useEffect(() => {
		if (!empleado || ausencias.length === 0) return;

		const timeout = setTimeout(() => {
			window.print();
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [ausencias, empleado]);

    return useMemo(
		() => ({
			ausencias,
			empleado,
		}),
		[ausencias, empleado]
	);
};

const Print = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
    const { ausencias, empleado } = useData();

    const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];

    return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Ausencias
				</h2>
				
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
			</div>

			<div className="row mt-5">
				<h3 className="col-md-12 text-center m-b-5">
					{empleado?.nombreCompleto}
				</h3>
			</div>

			<div className="row mt-1">
				<table className="table m-b-15">
					<thead>
						<tr>
							<th>Tipo</th>
							<th>Modalidad</th>
							<th>Desde</th>
							<th>Hasta</th>
							<th>Cantidad Días</th>
							<th>Contar Sábado</th>
							<th>Pagar</th>
							<th>Descripción</th>
							<th>Descontar Vacaciones</th>
							<th>Fecha Creación</th>
							<th>Estado</th>
						</tr>
					</thead>

					<tbody>
						{ausencias.map((ausencia) => {
							return (
								<tr key={ausencia.id}>
									<td>{ausencia.tipo.nombre}</td>
									<td>{getModalidadAusencia(ausencia.modalidad)?.label}</td>
									<td>{dayjs(ausencia.desde).format("DD/MM/YYYY")}</td>
									<td>{dayjs(ausencia.hasta).format("DD/MM/YYYY")}</td>
									<td className="text-center">{ausencia.cantidad_dia}</td>
									<td>{ausencia.contar_sabado ? "Contar" : "No Contar"}</td>
									<td>{formatCurrency(ausencia.monto_pagar)}</td>
									<td>{ausencia.descripcion}</td>
									<td className="text-center">{ausencia.descontar_vacaciones ? "Si" : "No"}</td>
									<td>{dayjs(ausencia.fechaCreacion).format("DD/MM/YYYY hh:mm a")}</td>
									<td style={{textTransform: 'capitalize'}}>{ausencia.estado}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
        </>
    );
};

export default Print;