import React, { useState } from "react";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatCurrency, toast } from "../../utils/helpers";

export default function Enviar({ item, toggle, getItems }) {
    const [state, setState] = useState({
        sucursal: null,
        almacen: "",
    });

    const user = auth.getUser();

    async function enviarItem(event) {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl(`items/${item.id}/enviar`), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            getItems();
            toggle(item);
            toast("Item enviado exitosamente.");
        } else {
            toast("El item no ha podido enviarse.", "error");
        }
    }

    function onChangeSucursal(e) {
        const { sucursales } = user;
        setState({
            sucursal: sucursales.filter(
                (s) => s.id === parseInt(e.target.value)
            )[0],
        });
    }

    const { sucursal } = state;

    return (
        <form method='post' onSubmit={enviarItem}>
            <div className='popup__header'>Enviar item a otra sucursal</div>
            <div className='popup__body'>
                <h3>{item.nombre}</h3>
                <table className='table m-b-15'>
                    <tbody>
                        <tr>
                            <td>
                                <strong>REF: </strong>
                                {item.referencia}
                            </td>
                            <td>
                                <strong>Costo</strong>:{" "}
                                {formatCurrency(item.costo)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Almacén: </strong>
                                {item.almacen.nombre}
                            </td>
                            <td>
                                <strong>Disponible</strong>: {item.cantidad}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='form-group m-t-0'>
                    <label>Sucursal</label>
                    <select
                        onChange={onChangeSucursal}
                        required
                        className='form-control'
                        name='sucursal'>
                        <option>- Seleccionar -</option>
                        {user.sucursales.map((sucursal) => {
                            return (
                                <option key={sucursal.id} value={sucursal.id}>
                                    {sucursal.nombre}
                                </option>
                            );
                        })}
                    </select>
                </div>
                {sucursal && (
                    <div className='form-group m-t-0'>
                        <label>Almacen</label>
                        <select
                            required
                            className='form-control'
                            name='almacen'>
                            <option>- Seleccionar -</option>
                            {sucursal.almacenes.map((sucursal) => {
                                return (
                                    <option value={sucursal.id}>
                                        {sucursal.nombre}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )}
                <div className='form-group m-t-0'>
                    <label>Cantidad</label>
                    <input
                        className='form-control'
                        type='number'
                        name='cantidad'
                        min='0.01'
                        max={item.cantidad}
                        required
                        step='0.01'
                    />
                </div>
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                <button type='submit' className='btn btn-primary'>
                    Enviar
                </button>
            </div>
        </form>
    );
}
