export const METODOS_PAGO = [
    { value: "efectivo", label: "Efectivo" },
	{ value: "tarjeta", label: "Tarjeta" },
	{ value: "cheque", label: "Cheque" },
	{ value: "transferencia", label: "Transferencia" },
	{ value: "deposito", label: "Deposito" },
	{ value: "otro", label: "Otro" },
];

export const ESTADOS_TRANSACCION = [
    { value: "pendiente", label: "Pendiente" },
	{ value: "pagada", label: "Pagada" },
	{ value: "cancelada", label: "Cancelada" },
];

export const ESTADOS_COTIZACIONES = [
    { value: "pendiente", label: "Pendiente" },
	{ value: "facturada", label: "Facturada" },
	{ value: "cancelada", label: "Cancelada" },
];