import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { debounce } from "lodash";
import queryString from "query-string";

import { downloadFile, request, withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../general/Loader";
import { apiUrl } from "../../utils/apiUrl";
import { auth } from "../../utils/auth";
import { getLink, toast } from "../../utils/helpers";
import EstadosCuentaForm from "./EstadoCuentasForm";

class Contactos extends Component {
	state = {
		activo: {},
		contactos: [],
		contacto: {},
		links: null,
		loading: true,
		showCreate: false,
		showEdit: false,
		showSidePopup: false,
		showDelete: false,
		showEstadoDeCuentaModal: false,
		rutasForDropdown: [],
		vendedoresForDropdown: [],
		terminosForDropdown: [],
		numeraciones: [],
	};
	constructor(props) {
		super(props);
		this.sidePopupWrap = React.createRef();
		this.sidePopupOverlay = React.createRef();
	}
	componentDidMount() {
		this.getContactos();
		this.getRutas();
		this.getVendedores();
		this.getTerminosData("terminos-pago?limit=100");
		this.getNumeraciones();
		const params = queryString.parse(window.location.search);
		if (params.id !== undefined) {
			this.onFiltroChange(params.id, "buscar");
			this.setState({
				activo: params.id,
			});
		}
        if (typeof params.crear_contacto !== 'undefined') {
            this.toggleCreateModal();
        }
	}
	getNumeraciones = async () => {
        request('numeracion-documentos?tipo_documento=factura_venta').then((response) => {
            this.setState({ numeraciones: response.data });
        });
    }
	getContactos = async (url = null) => {
		url = url ? url + "&limit=20" : "contactos?limit=20";
		const contactos = await this.props.request(url);

		if (contactos.data) {
			this.setState({
				contactos: contactos.data,
				links: contactos.links,
				meta: contactos.meta,
				loading: false,
			});
		}
	};
	onFiltroChange = debounce(async (value, filtro) => {
		const filtros= {
			...this.state.filtros,
			[filtro]: value,
		};

		if (filtros.tipo !== 'cliente') {
			delete filtros.vendedor;
			delete filtros.ruta;
		}

		await this.setState({
			filtros,
		});

		let query = Object.keys(this.state.filtros).map((fil) => {
			return `${fil}=${this.state.filtros[fil]}`;
		});

		this.getContactos("contactos?limit=20&" + query.join("&"));
	}, 500);
	toggleCreateModal = () => {
		this.setState({
			showCreate: !this.state.showCreate,
			contacto: {},
		});
	};
	toggleEditModal = (contacto) => {
		this.setState({
			showEdit: !this.state.showEdit,
			contacto: contacto,
		});
	};
	changeItem = (activo) => {
		this.setState({
			activo,
		});
		this.toggleSidePopup(true);
	};
	toggleSidePopup = (show) => {
		if (!show) {
			this.sidePopupWrap.current.classList.remove("slideInRight");
			this.sidePopupWrap.current.classList.add("slideOutRight");
			this.sidePopupOverlay.current.classList.remove("fadeIn");
			this.sidePopupOverlay.current.classList.add("fadeOut");
			setTimeout(() => {
				this.setState({
					showSidePopup: show,
				});
			}, 1000);
			return;
		}

		this.setState({
			showSidePopup: true,
		});
	};
	deleteContacto = async (contacto) => {
		this.toggleSidePopup(false);

		const token = auth.getToken();

		const url = `contactos/${contacto}`;

		const res = await fetch(apiUrl(url), {
			method: "delete",
			headers: {
				Accept: "application/json",
				"X-Request-With": "XMLHttpRequest",
				Authorization: "Bearer " + token,
			},
		});

		const response = await res.json();

		if (response.success) {
			this.toggleDeleteModal();
			this.getContactos();
			toast("Contacto borrado.");
			return;
		} else {
			toast(response.data, "error");
		}
	};
	toggleDeleteModal = () => {
		this.setState((state) => ({ ...state, showDelete: !state.showDelete }));
	};
	toggleEstadoDeCuentaModal = () => {
		this.setState((state) => ({ ...state, showEstadoDeCuentaModal: !state.showEstadoDeCuentaModal }));
	};
	getRutas = async (url = null) => {
		url = url ? url + "&limit=1000" : "rutas?limit=1000";
		const rutas = await this.props.request(url);
		if (rutas.links) {
			this.setState({
				rutasForDropdown: rutas.data.map((ruta) => ({
					value: ruta.id,
					label: ruta.nombre,
				})),
			});
		}
	};
	getVendedores = async (url = null) => {
		url = url ? url + "&limit=1000" : "vendedores?limit=1000";
		const vendedores = await this.props.request(url);
		if (vendedores.success) {
			this.setState({
				vendedoresForDropdown: vendedores.data.map((vendedor) => ({
					value: vendedor.id,
					label: `${vendedor.nombre} ${vendedor.apellido}`,
				})),
			});
		}
	};
	getTerminosData = async (url = null) => {
		url = url ? url + "&limit=15" : "terminos-pago?limit=15";

		const terminos = await this.props.request(url);
		if (terminos.links) {
			this.setState({
				terminosForDropdown: terminos.data.map((termino) => ({
					value: termino.id,
					label: termino.nombre,
				})),
			});
		}
	};
	exportPdf = async () => {
		const query = Object.keys(this.state.filtros ?? {}).map((fil) => {
			return `${fil}=${this.state.filtros[fil]}`;
		});

		const url = `contactos/export/pdf?${query.join("&")}`;

		downloadFile(url, 'reporte.pdf');
	};
	render() {
		const {
			contactos,
			showSidePopup,
			activo,
			loading,
			rutasForDropdown,
			vendedoresForDropdown,
			terminosForDropdown,
		} = this.state;

		const itemActivo = contactos.filter(
			(contacto) => contacto.id === activo
		)[0];

		return (
			<div>
				<div className="page-header">
					<h1 className="page-title row">
						<span className="col">Contactos</span>
						<button
							className="au-btn au-btn-icon au-btn--green au-btn--small"
							onClick={this.toggleCreateModal}
						>
							<i className="zmdi zmdi-plus" />
							Añadir Contacto
						</button>

						{auth.is("super-admin") && <button
							className="au-btn au-btn-icon au-btn--green au-btn--small ml-2"
							onClick={this.exportPdf}
						>
							<i className="zmdi zmdi-file" />
							PDF
						</button>}
					</h1>
				</div>
				<div className="page-controls">
					<div className="table-data__tool">
						<div className="table-data__tool-left row">
							<div className={this.state.filtros?.tipo === 'cliente' ? 'col-md-2' : 'col-md-6'}>
								<label>Buscar</label>
								<input
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"buscar"
										)
									}
									type="search"
									placeholder="Buscar..."
									className="form-control"
									name="buscar"
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="estado">Tipo</label>
								<select
									className="form-control"
									name="tipo"
									id="tipo"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"tipo"
										)
									}
								>
									<option value="todos">Todos</option>
									<option value="proveedor">Proveedor</option>
									<option value="cliente">Cliente</option>
								</select>
							</div>
							<div className="col-md-2">
								<label htmlFor="estado">
									Tipo de identificación
								</label>
								<select
									className="form-control"
									name="tipo_identificacion"
									id="tipo_identificacion"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"tipo_identificacion"
										)
									}
								>
									<option value="todos">Todos</option>
									<option value="cedula">Cedula</option>
									<option value="rnc">RNC</option>
									<option value="pasaporte">Pasaporte</option>
									<option value="otro">Otro</option>
								</select>
							</div>
							{this.state.filtros?.tipo === 'cliente' && <div className="col-md-2">
								<label htmlFor="estado">Zona</label>
								<select
									className="form-control"
									name="ruta"
									id="ruta"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"ruta"
										)
									}
								>
									<option value="todos">Todos</option>
									{rutasForDropdown.map((ruta) => (
										<option
											value={ruta.value}
											key={ruta.value}
										>
											{ruta.label}
										</option>
									))}
								</select>
							</div>}
							{this.state.filtros?.tipo === 'cliente' && <div className="col-md-2">
								<label htmlFor="estado">Vendedor</label>
								<select
									className="form-control"
									name="vendedor"
									id="rutvendedora"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"vendedor"
										)
									}
								>
									<option value="todos">Todos</option>
									{vendedoresForDropdown.map((ruta) => (
										<option
											value={ruta.value}
											key={ruta.value}
										>
											{ruta.label}
										</option>
									))}
									<option value="sin vendedor">Sin Vendedor</option>
								</select>
							</div>}
							<div className="col-md-2">
								<label htmlFor="estado">Plazo</label>
								<select
									className="form-control"
									name="plazo"
									id="plazo"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"plazo"
										)
									}
								>
									<option value="todos">Todos</option>
									{terminosForDropdown.map((termino) => (
										<option
											value={termino.value}
											key={termino.value}
										>
											{termino.label}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div>
							{loading && <Loader />}
							{!contactos.length ? (
								<div>
									{!loading && (
										<div>
											Todavía no hay contactos creados.
										</div>
									)}
								</div>
							) : (
								<div className="table-data">
									<table className="table table-data2">
										<thead>
											<tr>
												<th>Código</th>
												<th>Nombre</th>
												<th>Tipo</th>
												<th>Telefono</th>
												<th>Celular</th>
												<th>Zona</th>
												<th>Plazo</th>
											</tr>
										</thead>
										<tbody>
											{contactos.map((contacto) => (
												<tr
													onClick={(e) => {
														e.preventDefault();
														this.changeItem(
															contacto.id
														);
													}}
													style={{
														cursor: "pointer",
													}}
													className="tr-shadow"
													key={contacto.id}
												>
													<td>{contacto.codigo}</td>
													<td>{contacto.nombre}</td>
													<td>{contacto.tipo}</td>
													<td>{contacto.telefono}</td>
													<td>{contacto.celular}</td>
													<td>
														{contacto.ruta?.nombre}
													</td>
													<td>
														{contacto.plazo?.nombre}
													</td>
												</tr>
											))}
										</tbody>
									</table>
									{this.state.links && (
										<Pagination
											links={this.state.links}
											meta={this.state.meta}
											getData={this.getContactos}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				{showSidePopup && itemActivo && (
					<div className="side-popup">
						<div
							onClick={() => this.toggleSidePopup(false)}
							ref={this.sidePopupOverlay}
							className="side-popup-background animated fadeIn"
						></div>
						<div
							ref={this.sidePopupWrap}
							className="side-popup-container animated slideInRight fast"
						>
							<h2 className="d-flex">
								<div style={{width: "60%"}}>
									{itemActivo?.nombre}{" "}
								</div>
                                <div className="text-right d-flex" style={{flex: 1, justifyContent: 'flex-end', alignItems: 'center'}}>
                                    {itemActivo.tipo?.includes('Proveedor') && (
                                        <Link
                                            to={getLink(
                                                `/contactos/${itemActivo.id}/compras`
                                            )}
                                            className="btn btn-outline-primary contacto"
                                            onClick={() => {
                                                localStorage.setItem(
                                                    "contacto",
                                                    JSON.stringify(itemActivo)
                                                );
                                            }}
                                        >
                                            Historial de Compras
                                        </Link>
                                    )}
                                    {itemActivo.tipo?.includes('Cliente') && (
                                        <button
											onClick={this.toggleEstadoDeCuentaModal}
											className="btn btn-outline-primary ml-2 contacto"
										>
											Estados de Cuenta
										</button>
                                    )}
                                    {itemActivo.tipo?.includes('Cliente') && (
                                        <Link
                                            to={getLink(
                                                `/contactos/${itemActivo.id}/transacciones`
                                            )}
                                            className="btn btn-outline-primary ml-2 contacto"
                                            onClick={() => {
                                                localStorage.setItem(
                                                    "contacto",
                                                    JSON.stringify(itemActivo)
                                                );
                                            }}
                                        >
                                            Historial de Transacciones
                                        </Link>
                                    )}
                                    <button
                                        className="btn btn-primary float-right contacto ml-2"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Edit"
                                        onClick={() => {
                                            this.toggleEditModal(itemActivo);
                                        }}
                                    >
                                        <i className="zmdi zmdi-edit" />
                                    </button>
                                </div>
							</h2>
							<br />
							<table className="table">
								<tbody>
									<tr>
										<td>Encargado</td>
										<td>{itemActivo?.encargado}</td>
									</tr>
									<tr>
										<td>Identificación Encargado</td>
										<td>
											{
												itemActivo?.identificacion_encargado
											}
										</td>
									</tr>
									<tr>
										<td>Tipo</td>
										<td>{itemActivo?.tipo}</td>
									</tr>
									<tr>
										<td>Teléfono</td>
										<td>{itemActivo?.telefono}</td>
									</tr>
									<tr>
										<td>Celular</td>
										<td>{itemActivo?.celular}</td>
									</tr>
									<tr>
										<td>Correo electrónico</td>
										<td>{itemActivo?.email}</td>
									</tr>
									<tr>
										<td>Cédula/RNC</td>
										<td>{itemActivo?.identificacion}</td>
									</tr>
									<tr>
										<td>Provincia/Municipio</td>
										<td>{itemActivo?.provincia}</td>
									</tr>
									<tr>
										<td>Dirección</td>
										<td>{itemActivo?.direccion}</td>
									</tr>
									<tr>
										<td>Limite de crédito</td>
										<td>{itemActivo?.limite_credito}</td>
									</tr>
									<tr>
										<td>Latitud, Longitud</td>
										<td>{itemActivo?.latitud_longitud}</td>
									</tr>
									<tr>
										<td>Plazo</td>
										<td>{itemActivo?.plazo?.nombre}</td>
									</tr>
									{itemActivo?.tipo?.includes("Cliente") && (
										<>
											<tr>
												<td>Zona</td>
												<td>
													{itemActivo?.ruta?.nombre}
												</td>
											</tr>
											<tr>
												<td>Vendedor</td>
												<td>
													{
														itemActivo?.vendedor
															?.nombre
													}
												</td>
											</tr>
											<tr>
												<td>Precio de venta</td>
												<td>
													Precio venta{" "}
													{itemActivo?.precio_venta}
												</td>
											</tr>
										</>
									)}
								</tbody>
							</table>
							<p className="text-right">
								<button
									type="button"
									onClick={(e) => this.toggleDeleteModal()}
									className="btn btn-danger btn-sm"
								>
									<i className="fa fa-trash-alt"></i>
								</button>
							</p>
						</div>
					</div>
				)}
				<Modal
					show={this.state.showCreate}
					blank={true}
					toggle={this.toggleCreateModal}
					size="1048px"
				>
					<Create
						toggle={this.toggleCreateModal}
						contacto={this.state.contacto}
						getContactos={this.getContactos}
					/>
				</Modal>
				<Modal
					show={this.state.showEdit}
					blank={true}
					toggle={() => this.toggleEditModal({})}
					size="1048px"
				>
					<Edit
						toggle={() => this.toggleEditModal({})}
						contacto={this.state.contacto}
						getContactos={() => {
							const query = Object.keys(this.state.filtros ?? {}).map((fil) => {
								return `${fil}=${this.state.filtros[fil]}`;
							});

							this.getContactos("contactos?limit=20&" + query.join("&"));
						}}
					/>
				</Modal>
				<Modal
					title="Borrar Contacto"
					show={this.state.showDelete}
					callback={() => this.deleteContacto(itemActivo.id)}
					acceptText="Borrar"
					toggle={this.toggleDeleteModal}
				>
					¿Estás seguro de que deseas borrar este contacto?
				</Modal>
				<Modal
                show={this.state.showEstadoDeCuentaModal}
                blank={true}
                size={"600px"}
                toggle={this.toggleEstadoDeCuentaModal}
            >
                <EstadosCuentaForm
                    toggle={() => {
                        this.toggleEstadoDeCuentaModal();
                    }}
                    numeraciones={this.state.numeraciones}
					clientes={itemActivo}
                />
            </Modal>
			</div>
		);
	}
}

export default withRouter(withRequest(Contactos));
