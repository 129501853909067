import React, { useState } from 'react';

export default function FormGruposPagos({item, errors, gpagos}) {

    const [gruposPagos, setGruposPagos] = useState(gpagos ? gpagos : [
        {label: 'Predeterminado', id: 'predeterminado'},
        {label: 'Extranjeros', id: 'extranjeros'},
        {label: 'Segundo Hermano', id: 'segundo-hermano'},
    ]);

    const [nuevoGrupo, setNuevoGrupo] = useState('');

    const addNuevoGrupo = () => {
        if (nuevoGrupo !== '') {
            setGruposPagos([
                ...gruposPagos,
                {
                    label: nuevoGrupo,
                    id: nuevoGrupo.toLowerCase().replace(' ', '-')
                }
            ]);
            setNuevoGrupo('');
        }
    }

    const removeGrupo = (e) => {
        setGruposPagos([
            ...gruposPagos.filter(g => g.id !== e)
        ])
    }

    return (<>
        <input type='hidden' name='seccion' value='academica_grupos-pagos' />
        <table className='table'>
            <tbody>
                {gruposPagos.map(g => (
                    <tr key={g.id}>
                        <td>
                            {g.label}
                            <input type="hidden" name={`configuraciones[grupos-pagos][${g.id}][label]`} value={g.label} />
                            <input type="hidden" name={`configuraciones[grupos-pagos][${g.id}][id]`} value={g.id} />
                        </td>
                        <td className='text-right'>
                            <button className="btn" onClick={() => removeGrupo(g.id)}>
                                <i className="zmdi zmdi-delete"></i>
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <hr />
        <div className='row form-group'>
            <div className='col col-md-7'>
                <input
                    type='text'
                    className='text-input form-control'
                    value={nuevoGrupo}
                    onChange={e => setNuevoGrupo(e.target.value)}
                />
                {errors.nombre && (
                    <small className='help-blockParams form-Text'>
                        {errors.nombre[0]}
                    </small>
                )}
            </div>
            <div className="col">
                <button type="button" className='btn' onClick={addNuevoGrupo}>+ Añadir Grupo de Pago</button>
            </div>
        </div>
    </>)
}
