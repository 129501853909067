import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { formatCurrency } from "../../../utils/helpers";

class SobresDetalles extends Component {
    state = {
        nominas: [{ pagos: [] }],
        orden: 'nombre',
        redondear: false,
    };
    componentDidMount() {
        this.getNomina();
    }
    getNomina = async (url = null) => {
        const id = this.props.match.params.id;
        const search = window.location.search ? window.location.search : "";
        url = url ? url : "nominas/" + id + search;
        const nominas = await this.props.request(url);

        if (nominas.success) {
            this.setState({
                nominas: nominas.data,
            });
            window.print();
        }
    };
    onChangeOrden = (e) => {
        this.setState({
            orden: e.target.value
        });
    }
    onChangeRedondear = () => {
        this.setState({
            redondear: !this.state.redondear
        });
    }
    redondear = (cantidad) => {
        return Math.round(cantidad/5) * 5;
    }
    render() {
        const { nominas, orden, redondear } = this.state;
        const nomina = nominas[0];

        const combinarPagos = nominas.reduce((acc, nom) => {
            acc = [...acc, ...nom.pagos];
            return acc;
        }, []);

        const empleados = combinarPagos
            .sort((a, b) => {
                return a.nombre < b.nombre ? -1 : 1;
            })
            .reduce((acc, empleado) => {
                if (acc[empleado.cedula] === undefined) {
                    acc[empleado.cedula] = empleado;
                } else {
                    acc[empleado.cedula].toneladas =
                        parseFloat(acc[empleado.cedula].toneladas) +
                        parseFloat(empleado.toneladas);
                    acc[empleado.cedula].total =
                        parseFloat(acc[empleado.cedula].total) +
                        parseFloat(empleado.total);
                    acc[empleado.cedula].sueldoBruto =
                        parseFloat(acc[empleado.cedula].sueldoBruto) +
                        parseFloat(empleado.sueldoBruto);
                }
                return acc;
            }, {});

        const pagos = Object.keys(empleados).reduce((acc, empleado) => {
            acc.push(empleados[empleado]);
            return acc;
        }, []);

        return (
            <div>
                <style>{`@page {size: 27cm 11.7cm;}`}</style>
                <div className="ordernar-resultados no-print">
                    <div className="form-group">
                        <div className="radio-btn">
                            <label>
                                <input type="radio" onChange={this.onChangeOrden} name="orden" value="nombre" defaultChecked={true} /> Ordenar por Nombre
                            </label>
                            <label>
                                <input type="radio" onChange={this.onChangeOrden} name="orden" value="codigo" /> Ordenar por Código
                            </label>
                            <label>
                                <input type="checkbox" onChange={this.onChangeRedondear} name="redondear" value="redondear" /> Redondear
                            </label>
                        </div>
                    </div>
                </div>
                <div style={{breakAfter: 'avoid-page'}} className='nomina-sobres'>
                    {pagos.sort((a, b) => {
                        if ( orden === 'codigo') {
                            if (!a.codigo) {
                                return 1;
                            }
                            return a.codigo.localeCompare(b.codigo);
                        }

                        return a.nombre.localeCompare(b.nombre);
                    }).map((pago) => {
                        return (
                            <div
                                key={pago.cedula}
                                className='comprobante-pago row'>
                                    <div className="sobre-column">

                                        <div className='text-left'>
                                            <div>
                                                <strong>
                                                    {nomina.sucursal.nombre}
                                                </strong>
                                            </div>
                                            <div>
                                                <small>
                                                    Fecha:{" "}
                                                    {new Date().toLocaleString()}
                                                </small>
                                            </div>
                                        </div>
                                        <br />
                                        <div>
                                            <strong>Nombre:</strong> {pago.nombre}
                                        </div>
                                        <div>
                                            <strong>Código:</strong> {pago.codigo}
                                        </div>
                                        <div>
                                            <strong>Cédula:</strong> {pago.cedula}
                                        </div>
                                        <div>
                                            <strong>Periodo:</strong>{" "}
                                            {nomina.fechaInicio} al {nomina.fechaCierre}
                                        </div>
                                    </div>

                                    <div className="sobre-column">
                                        <table className='table'>
                                            <tbody>
                                                <tr>
                                                    <th>Sueldo</th>
                                                    <td className='text-right'>
                                                        {formatCurrency(
                                                            pago.sueldoFijo
                                                        )}
                                                    </td>
                                                </tr>
                                                {pago.descuentos.length > 0 &&
                                                    pago.descuentos.filter(d => d.monto > 0).map(
                                                        (desc, index) => (
                                                            <tr key={index}>
                                                                <th>{desc.descripcion}</th>
                                                                <td className='text-right'>
                                                                    -{formatCurrency(
                                                                        desc.monto
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                {pago.listadoIncentivos.length > 0 &&
                                                    pago.listadoIncentivos.map(
                                                        (inc, index) => (
                                                            <tr key={index}>
                                                                <th>{inc.descripcion}</th>
                                                                <td className='text-right'>
                                                                    +{formatCurrency(
                                                                        inc.monto
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                <tr>
                                                    <th>Total</th>
                                                    <td className='text-right'>
                                                        {redondear ? (
                                                            <span>
                                                                {formatCurrency(
                                                                    this.redondear(pago.total)
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {formatCurrency(
                                                                    pago.total
                                                                )}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(SobresDetalles));
