import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import SelectAsync from "react-select/async-creatable";

import FotoField from "../../general/FotoField";
import { apiRequest } from "../../../hooks/apiRequest";

const Form = ({tutor, errors, toggleSearchModal}) => {

    const [nacionalidad, setNacionalidad] = useState(tutor.nacionalidad);

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };

    useEffect(() => {
        setNacionalidad(tutor.nacionalidad);
    }, [tutor.nacionalidad])

    return (
        <div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Nombre:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre'
                            name='nombre'
                            defaultValue={tutor.nombre}
                        />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Apellido:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='apellido'
                            name='apellido'
                            defaultValue={tutor.apellido}
                        />
                        {errors.apellido && (
                            <small className='help-blockParams form-Text'>
                                {errors.apellido[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Cédula:
                        </label>
                        <div className="row col">
                            <input
                                type='text'
                                className='text-input form-control col'
                                id='cedula'
                                name='cedula'
                                defaultValue={tutor.cedula}
                            />
                            <button
                                type='button'
                                onClick={toggleSearchModal}
                                className='btn btn-primary float-right m-l-10'>
                                <i className='fas fa-search'></i>
                            </button>
                        </div>
                        {errors.cedula && (
                            <small className='help-blockParams form-Text'>
                                {errors.cedula[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Fecha de Nacimiento:
                        </label>
                        <div className='input-group'>
                            <div className='input-group-addon'>
                                <i className='far fa-calendar' />
                            </div>
                            <input
                                type='date'
                                className='text-input form-control'
                                defaultValue={tutor.fechaNacimiento && dayjs(tutor.fechaNacimiento).format('YYYY-MM-DD')}
                                name='fecha_nacimiento'
                            />
                        </div>
                        {errors.fecha_nacimiento && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha_nacimiento[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Nacionalidad:
                        </label>
                        <SelectAsync
                            isClearable
                            key="nacionalidad"
                            name="nacionalidad"
                            cacheOptions
                            defaultOptions
                            onChange={e => setNacionalidad(e?.value)}
                            value={nacionalidad && {label: nacionalidad, value: nacionalidad}}
                            loadOptions={e => getOptions("taxonomias?relacion=Tutores&tipo=Nacionalidad&buscar="+e, 'nombre', 'nombre')}
                        />
                        {errors.nacionalidad && (
                            <small className='help-blockParams form-Text'>
                                {errors.nacionalidad[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='col-md-8'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Dirección:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='direccion'
                            name='direccion'
                            rows='7'
                            defaultValue={tutor.direccion}
                        />
                        {errors.direccion && (
                            <small className='help-blockParams form-Text'>
                                {errors.direccion[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Estado Civil:
                        </label>
                        <select
                            key={tutor.id}
                            className='text-input form-control'
                            id='estado_civil'
                            name='estado_civil'
                            defaultValue={tutor.estadoCivil}>
                            <option value='soltero'>Soltero</option>
                            <option value='casado'>Casado</option>
                            <option value='union-libre'>Unión Libre</option>
                        </select>
                        {errors.estado_civil && (
                            <small className='help-blockParams form-Text'>
                                {errors.estado_civil[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Foto:
                        </label>
                        <FotoField
                            foto={tutor.foto}
                            fotoCed={tutor.fotoCed}
                            name='foto'
                        />
                    </div>
                </div>
            </div>

            <div className='form-separator' />

            <h3 className="m-t-20">Información de Contacto</h3>

            <div className='row'>
                <div className='col-md-6 form-group'>
                    <label className='label form-control-label'>
                        Teléfono Casa:
                    </label>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='telefono_casa'
                        name='telefono_casa'
                        defaultValue={tutor.telefonoCasa}
                    />
                    {errors.telefono_casa && (
                        <small className='help-blockParams form-Text'>
                            {errors.telefono_casa[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-6 form-group'>
                    <label className='label form-control-label'>
                        Celular:
                    </label>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='telefono'
                        name='telefono'
                        defaultValue={tutor.telefono}
                    />
                    {errors.telefono && (
                        <small className='help-blockParams form-Text'>
                            {errors.telefono[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-6 form-group'>
                    <label className='label form-control-label'>
                        Correo Electrónico:
                    </label>
                    <input
                        type='email'
                        className='text-input form-control'
                        id='correo'
                        name='correo'
                        defaultValue={tutor.correo}
                    />
                    {errors.correo && (
                        <small className='help-blockParams form-Text'>
                            {errors.correo[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-6 form-group'>
                    <label className='label form-control-label'>
                        Teléfono Trabajo:
                    </label>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='telefono_trabajo'
                        name='telefono_trabajo'
                        defaultValue={tutor.telefonoTrabajo}
                    />
                    {errors.telefono_trabajo && (
                        <small className='help-blockParams form-Text'>
                            {errors.telefono_trabajo[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-6 form-group'>
                    <label className='label form-control-label'>
                        Lugar de Trabajo:
                    </label>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='lugar_trabajo'
                        name='lugar_trabajo'
                        defaultValue={tutor.lugarTrabajo}
                    />
                    {errors.lugar_trabajo && (
                        <small className='help-blockParams form-Text'>
                            {errors.lugar_trabajo[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-6 form-group'>
                    <label className='label form-control-label'>
                        Cargo:
                    </label>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='cargo_trabajo'
                        name='cargo_trabajo'
                        defaultValue={tutor.cargo}
                    />
                    {errors.cargo_trabajo && (
                        <small className='help-blockParams form-Text'>
                            {errors.cargo_trabajo[0]}
                        </small>
                    )}
                </div>
            </div>

            <div className='form-separator' />

            <h3 className="m-t-20">Datos Centro Educativo</h3>

            <div className='row'>
                <div className='col form-group'>
                    <label className='label form-control-label'>
                        Cargo Comité de Padres:
                    </label>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='comite'
                        name='comite'
                        defaultValue={tutor.comite}
                    />
                    {errors.comite && (
                        <small className='help-blockParams form-Text'>
                            {errors.comite[0]}
                        </small>
                    )}
                </div>
                <div
                    className='form-group'
                    style={{ marginTop: "30px" }}>
                    <span style={{ marginRight: "15px" }}>Activo:</span>
                    <label className='switch switch-3d switch-success mr-3'>
                        <input type='hidden' name='activo' value='no' />
                        <input
                            key={tutor.id}
                            type='checkbox'
                            className='switch-input'
                            id='activo'
                            name='activo'
                            value='si'
                            defaultChecked={
                                tutor.id ? tutor.activo : true
                            }
                        />
                        <span className='switch-label' />
                        <span className='switch-handle' />
                    </label>
                    {errors.activo && (
                        <small className='help-blockParams form-Text'>
                            {errors.activo[0]}
                        </small>
                    )}
                </div>
                <div
                    className='form-group'
                    style={{ marginTop: "30px" }}>
                    <span style={{ marginRight: "15px" }}>Egresado:</span>
                    <label className='switch switch-3d switch-success mr-3'>
                        <input type='hidden' name='active' value='no' />
                        <input
                            key={tutor.id}
                            type='checkbox'
                            className='switch-input'
                            id='egresado'
                            name='egresado'
                            value='si'
                            defaultChecked={
                                tutor.id ? tutor.egresado : false
                            }
                        />
                        <span className='switch-label' />
                        <span className='switch-handle' />
                    </label>
                    {errors.egresado && (
                        <small className='help-blockParams form-Text'>
                            {errors.egresado[0]}
                        </small>
                    )}
                </div>
            </div>

            <div className='form-separator' />

            <div className="row notas">
                {tutor.notas && tutor.notas.map(nota => (
                    <div className="col-md-12 nota" key={nota.id}>
                        <i className="zmdi zmdi-comment-text"></i>
                        {nota.descripcion}
                        <div className="nota-meta">Por {nota.user} - {dayjs(nota.created_at).format('MM/DD/YYYY')}</div>
                    </div>
                ))}
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className='form-group'>
                        <h3>Agregar Nota</h3>
                        <textarea
                            key={tutor.id}
                            className='text-input form-control'
                            id='notas'
                            name='notas'
                            rows='7'
                        />
                        {errors.notas && (
                            <small className='help-blockParams form-Text'>
                                {errors.notas[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;
