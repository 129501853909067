import React, { Component } from "react";
import { Route, NavLink, Link, withRouter } from "react-router-dom";
import { withRequest } from "../../../utils/request";
import Edit from "./Edit";
import Nomina from "./Nomina";
import Terminos from "./Terminos";
import { getLink } from "../../../utils/helpers";
import Loader from "../../general/Loader";
import DatosBancarios from "./DatosBancarios";

class Layout extends Component {
    state = {
        sucursal: {},
        loading: true,
        sucursales: [],
    };

    componentDidMount() {
        this.getSucursales();
    }

    getSucursales = async (url = null) => {
        const id = parseInt(this.props.match.params.id);
        url = url ? url : "sucursales";
        const sucursales = await this.props.request(url);

        if (sucursales.data) {
            this.setState({
                sucursal: sucursales.data.filter(s => s.id === id)[0],
                sucursales: sucursales.data,
                loading: false,
            });
        }
    };

    render() {
        const { loading, sucursal, sucursales } = this.state;

        if (loading) {
            return <Loader />;
        }

        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/sucursales/')}>&laquo; Volver a sucursales</Link>
                    <h1 className='page-title'>
                        Editando {sucursal.nombre}
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-3 page-sidebar'>
                        <ul className='list-unstyled page-sidebar__menu'>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/sucursales/${sucursal.id}/editar`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Información General
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/sucursales/${sucursal.id}/nomina`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Nómina
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/sucursales/${sucursal.id}/terminos`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Términos
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/sucursales/${sucursal.id}/DatosBancarios`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Datos Bancarios
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-8 offset-1'>
                        <Route
                            path='/:sucursal/sucursales/:id/editar'
                            render={(props) => (
                                <Edit {...props}
                                    sucursal={sucursal}
                                    sucursales={sucursales}
                                    />
                            )}
                        />
                        <Route
                            path='/:sucursal/sucursales/:id/nomina'
                            render={(props) => (
                                <Nomina {...props} sucursal={sucursal} />
                            )}
                        />
                        <Route
                            path='/:sucursal/sucursales/:id/terminos'
                            render={(props) => (
                                <Terminos {...props} sucursal={sucursal} />
                            )}
                        />
                        <Route
                            path='/:sucursal/sucursales/:id/DatosBancarios'
                            render={(props) => (
                                <DatosBancarios {...props} sucursal={sucursal} />
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(Layout));
