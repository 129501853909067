import React from "react";
import dayjs from "dayjs";

export default function View({ reclamacion }) {
    let estado = "";
    switch (reclamacion.estado) {
        case "cancelada":
            estado = "danger";
            break;
        case "en-proceso":
            estado = "primary";
            break;
        case "atendida":
            estado = "success";
            break;
        default:
            estado = "warning";
            break;
    }
    return (
        <div>
            {reclamacion.estado === "atendida" && (
                <table className='table m-b-20'>
                    <tbody>
                        <tr>
                            <td style={{ width: "150px" }}>Fecha Atendida</td>
                            <td>
                                {dayjs(reclamacion.atendidaEn).format(
                                    "YYYY-MM-DD"
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Atendida Por</td>
                            <td>
                                {reclamacion.fichaAtendio &&
                                    reclamacion.fichaAtendio.ficha}
                            </td>
                        </tr>
                        <tr>
                            <td>Supervisado Por</td>
                            <td>
                                {reclamacion.supervisorAtendio &&
                                    reclamacion.supervisorAtendio
                                        .nombreCompleto}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
            {reclamacion.estado === "cancelada" && (
                <table className='table m-b-20'>
                    <tbody>
                        <tr>
                            <td style={{ width: "150px" }}>Fecha Cancelada</td>
                            <td>
                                {dayjs(reclamacion.atendidaEn).format(
                                    "YYYY-MM-DD"
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Comentario</td>
                            <td>{reclamacion.atendidaComentario}</td>
                        </tr>
                    </tbody>
                </table>
            )}
            <table className='table'>
                <tbody>
                    <tr>
                        <td style={{ width: "150px" }}>Estado</td>
                        <td>
                            <span className={"uppercase badge badge-" + estado}>
                                {reclamacion.estado}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>ID:</td>
                        <td>{reclamacion.id}</td>
                    </tr>
                    <tr>
                        <td>Fecha:</td>
                        <td>{dayjs(reclamacion.fecha).format("YYYY-MM-DD")}</td>
                    </tr>
                    <tr>
                        <td>Dirección:</td>
                        <td>{reclamacion.direccion}</td>
                    </tr>
                    <tr>
                        <td>Reclamado por:</td>
                        <td>{reclamacion.nombreReclamador}</td>
                    </tr>
                    <tr>
                        <td>Teléfono:</td>
                        <td>{reclamacion.telefonoReclamador}</td>
                    </tr>
                    <tr>
                        <td>Detalles</td>
                        <td>{reclamacion.comentario}</td>
                    </tr>
                    <tr>
                        <td>Supervisor</td>
                        <td>
                            {reclamacion.supervisor &&
                                reclamacion.supervisor.nombreCompleto}
                        </td>
                    </tr>
                    <tr>
                        <td>Zona:</td>
                        <td>
                            {reclamacion.ruta && (
                                <div>
                                    {reclamacion.ruta.nombre}
                                    <br />{" "}
                                    <small>
                                        {reclamacion.ruta.descripcion}
                                    </small>
                                </div>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>Ficha:</td>
                        <td>
                            {reclamacion.ficha && (
                                <div>{reclamacion.ficha.ficha}</div>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
