import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { getLink, toast } from "../../../utils/helpers";
import useFilters from "../../../hooks/useFilters";
import { request } from "../../../utils/request";
import Loader from "../../general/Loader";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import Form from "./Form";
import dayjs from "dayjs";
import { apiRequest } from "../../../hooks/apiRequest";

const TipoAusencias = () => {
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [tipos, setTipos] = useState();
    const [tipo, setTipo] = useState({});
    const [tipoToDelete, setTipoToDelete] = useState({});

    const { handleFilterChange, filtersQuery } = useFilters({
		buscar: "",
	});

    const openCreate = () => {
        setTipo({ nombre: null });

        setShowModal(true);
    };

    const openEdit = (tipo) => {
        setTipo({ ...tipo });

        setShowModal(true);
    };

    const closeModal = () => {
        setTipo({});

        setShowModal(false);
    };

    const openDelete = (tipo) => {
        setTipoToDelete({ ...tipo });
        
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setTipoToDelete({});

        setShowDeleteModal(false);
    };

    const eliminar =  () => {
        setDeleting(true);

        apiRequest(undefined, `tipo-ausencia/${tipoToDelete.id}`, "DELETE").then((response) => {
            if (response.success) {
                toast(`Tipo Ausencia Eliminada.` , "success");

                closeDeleteModal();

                getTipos(`tipo-ausencia${filtersQuery}`);
            } else {
                toast(response.data.message, "error");
            }
    
            setDeleting(false);
        });
    };

    const getTipos = async (url) => {
        url = url ? url + "&limit=15" : "tipo-ausencia?limit=15";

        setLoading(true);

		const response = await request(url);
        
		setTipos(response);

		setLoading(false);
    };

    const success = () => {
        closeModal();

        getTipos(`tipo-ausencia${filtersQuery}`);
    };

    useEffect(() => {
        getTipos(`tipo-ausencia${filtersQuery}`);
    }, [filtersQuery]);

    return (
        <div>
            <div className='page-header'>
                <Link to={getLink('/configuracion/')}>&laquo; Volver a configuración</Link>
                <h1 className='page-title row'>
                    <span className='col'>Tipos de Ausencias</span>
                    <button
                        className='au-btn au-btn-icon au-btn--green au-btn--small'
                        onClick={openCreate}>
                        <i className='zmdi zmdi-plus' />
                        Añadir Tipo de Ausencia
                    </button>
                </h1>

                <div className='page-controls'>
                    <div className='table-data__tool'>
                        <div className='table-data__tool-left row'>
                            <div className='col'>
                                <label htmlFor='search'>Buscar</label>
                                <input onChange={(e) => handleFilterChange("buscar", e.target.value)}
                                    type='search'
                                    placeholder='Buscar...'
                                    className='form-control'
                                    name='buscar'
                                />
                            </div>                                
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div>
                        {loading 
                            ? <Loader /> 
                            : (tipos?.data?.length 
                                ? (
                                    <div className='table-data'>
                                        <table className='table table-data2'>
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Fecha Creación</th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tipos.data.map((tipo) => {
                                                    return (
                                                        <tr className="tr-shadow" key={tipo.id}>
                                                            <td>{tipo.nombre}</td>
                                                            <td>{dayjs(tipo.fechaCreacion).format("DD/MM/YYYY hh:mm a")}</td>
                                                            <td>
                                                                {tipo.isEditable && <div className='table-data-feature'>
                                                                    <button
                                                                        className='item'
                                                                        data-toggle='tooltip'
                                                                        data-placement='top'
                                                                        title=''
                                                                        data-original-title='Editar'
                                                                        onClick={() => openEdit(tipo) }>
                                                                        <i className='zmdi zmdi-edit' />
                                                                    </button>
                                                                    <button
                                                                        className='item'
                                                                        data-toggle='tooltip'
                                                                        data-placement='top'
                                                                        title=''
                                                                        data-original-title='Eliminar'
                                                                        onClick={() => openDelete(tipo) }>
                                                                        <i className='zmdi zmdi-delete' />
                                                                    </button>
                                                                </div>}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        {tipos.links && (<Pagination links={tipos.links} meta={tipos.meta} getData={getTipos} />)}
                                    </div>
                                ) 
                                : (
                                    <div>
                                        <div> No Data. </div>
                                    </div>
                                )
                            )}
                    </div>
                </div>
            </div>

            <Modal
                show={showModal}
                blank={true}
                toggle={() => setShowModal((state) => !state)}>
                <Form
                    tipo={tipo}
                    cancel={closeModal}
                    success={success}
                />
            </Modal>

            <Modal
                show={showDeleteModal}
                blank={true}
                toggle={() => setShowDeleteModal((state) => !state)}>
                <div className='page-header'>
                    <h3 className="page-title px-2">¿Estás seguro? </h3>
                </div>
                <p className="px-3 pb-3">Seguro que desea eliminar el tipo de ausencia: <strong>{tipoToDelete.nombre}</strong></p>

                { deleting
                ? <Loader />  
                : (<div className='popup__footer'>
                    <button
                        type='button'
                        onClick={closeDeleteModal}
                        className='btn btn-plain'>
                        No
                    </button>
                    <button type='button' onClick={eliminar} className='btn btn-primary'>
                        Si
                    </button>
                </div>)
            }
            </Modal>
        </div>
    );
};

export default TipoAusencias;
