import React, { Component } from "react";
import { Route, NavLink, withRouter } from "react-router-dom";
import { withRequest } from "../../utils/request";
import Edit from "./Edit";
import Produccion from "./Produccion";
import Servicios from "./Servicios/";
import Gastos from "./Gastos";
import { getLink } from "../../utils/helpers";

class LayoutVehiculo extends Component {
    state = {
        vehiculo: {},
        categorias: [],
    };
    componentDidMount() {
        this.getCategorias();
        this.getVehiculo();
    }
    getCategorias = async (url = null) => {
        url = url ? url : "categorias";
        const categorias = await this.props.request(url);

        if (categorias.links) {
            this.setState({
                categorias: categorias.data,
            });
        }
    };
    getVehiculo = async (url = null) => {
        const id = this.props.match.params.id;
        url = url ? url : "vehiculos/" + id;
        const vehiculo = await this.props.request(url);

        if (vehiculo.success) {
            this.setState({
                vehiculo: vehiculo.data,
            });
        }
    };
    render() {
        const { vehiculo, categorias } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <h1 className='page-title'>Editar {vehiculo.ficha}</h1>
                </div>
                <div className='row'>
                    <div className='col page-sidebar'>
                        <ul className='list-unstyled page-sidebar__menu'>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/vehiculos/${vehiculo.id}/informacion`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Información General
                                </NavLink>
                                <NavLink
                                    to={getLink(
                                        `/vehiculos/${vehiculo.id}/gastos`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Historial de Gastos
                                </NavLink>
                                <NavLink
                                    to={getLink(
                                        `/vehiculos/${vehiculo.id}/produccion`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Producción
                                </NavLink>
                                <NavLink
                                    to={getLink(
                                        `/vehiculos/${vehiculo.id}/servicios`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Servicios
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-9 p-l-50'>
                        <Route
                            path='/:sucursal/vehiculos/:id/informacion'
                            render={(props) => (
                                <Edit
                                    {...props}
                                    vehiculo={vehiculo}
                                    categorias={categorias}
                                    getVehiculo={this.getVehiculo}
                                />
                            )}
                        />
                        <Route
                            path='/:sucursal/vehiculos/:id/gastos'
                            render={(props) => (
                                <Gastos {...props} vehiculo={vehiculo} />
                            )}
                        />
                        <Route
                            path='/:sucursal/vehiculos/:id/produccion'
                            render={(props) => (
                                <Produccion {...props} vehiculo={vehiculo} />
                            )}
                        />
                        <Route
                            path='/:sucursal/vehiculos/:id/servicios'
                            render={(props) => (
                                <Servicios {...props} vehiculo={vehiculo} />
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(LayoutVehiculo));
