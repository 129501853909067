import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import dayjs from "dayjs";
import { downloadFile, withRequest } from "../../../utils/request";
import { arrayToCsv, downloadBlob, formatCurrency, formatNumber } from "../../../utils/helpers";
import { auth } from "../../../utils/auth";

class Resumen extends Component {
    state = {
        nominas: [{ pagos: [] }],
        orden: 'nombre',
        redondear: false,
    };
    componentDidMount() {
        this.getNomina();
    }
    getNomina = async (url = null) => {
        const id = this.props.match.params.id;
        const search = window.location.search ? window.location.search : "";
        url = url ? url : "nominas/" + id + search;
        const nominas = await this.props.request(url);

        if (nominas.success) {
            this.setState({
                nominas: nominas.data,
            });
            //window.print();
        }
    };
    onChangeOrden = (e) => {
        this.setState({
            orden: e.target.value
        });
    }
    onChangeRedondear = () => {
        this.setState({
            redondear: !this.state.redondear
        });
    }
    redondear = (cantidad) => {
        return Math.round(cantidad/5) * 5;
    }
    DownloadExcel = () => {
        const id = this.props.match.params.id;

        downloadFile(`nominas/${id}/excel-resume`, `resumen_nomina_${id}.xlsx`);
    };
    render() {
        const { nominas, orden, redondear } = this.state;

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);
        const nomina = nominas[0];

        const pagos = nominas.reduce((acc, nom) => {
            acc = [...acc, ...nom.pagos];
            return acc;
        }, []);

        const empleadosCombinados = pagos.reduce((acc, empleado) => {
            if (acc[empleado.cedula] === undefined) {
                acc[empleado.cedula] = empleado;
            } else {
                acc[empleado.cedula].toneladas =
                    parseFloat(acc[empleado.cedula].toneladas) +
                    parseFloat(empleado.toneladas);
                acc[empleado.cedula].total =
                    parseFloat(acc[empleado.cedula].sueldoBruto) +
                    parseFloat(empleado.sueldoBruto);
                acc[empleado.cedula].sueldoBruto =
                    parseFloat(acc[empleado.cedula].sueldoBruto) +
                    parseFloat(empleado.sueldoBruto);
            }
            return acc;
        }, {});

        const empleados = Object.keys(empleadosCombinados)
            .sort((a, b) => {
                return empleadosCombinados[a].nombre <
                    empleadosCombinados[b].nombre
                    ? -1
                    : 1;
            })
            .reduce((acc, empleado) => {
                const emp = empleadosCombinados[empleado];
                if (acc[emp.cargo] === undefined) {
                    acc[emp.cargo] = [emp];
                } else {
                    acc[emp.cargo].push(emp);
                }
                return acc;
            }, {});

        const totalEmpleados = nominas.reduce((acc, nomina) => {
            return parseInt(nomina.empleados) + acc;
        }, 0);

        const totalPagos = nominas.reduce((acc, nomina) => {
            var totalPagos = 0;
            if (redondear) {
                totalPagos = nomina.pagos.reduce((c, pago) => {
                    const total = this.redondear(parseInt(pago.total));
                    return total + c;
                }, 0);
            } else {
                totalPagos = nomina.total
            }
            return totalPagos + acc;
        }, 0);

        const totalSueldoFijo = Object.keys(empleados).reduce((acc, key) => {
            return acc + empleados[key].reduce((a, e) => a + parseFloat(e.sueldoFijo), 0);
        }, 0);

        const totalSueldoBruto = Object.keys(empleados).reduce((acc, key) => {
            return acc + empleados[key].reduce((a, e) => a + parseFloat(e.sueldoBruto), 0);
        }, 0);

        const totalIncentivosFijos = Object.keys(empleados).reduce((acc, key) => {
            return acc + empleados[key].reduce((a, e) => a + parseFloat(e.incentivosFijos), 0);
        }, 0);

        const totalIncentivosUnicos = Object.keys(empleados).reduce((acc, key) => {
            return acc + empleados[key].reduce((a, e) => a + parseFloat(e.incentivosUnicos), 0);
        }, 0);

        const totalIncentivos = Object.keys(empleados).reduce((acc, key) => {
            return acc + empleados[key].reduce((a, e) => a + parseFloat(e.incentivos), 0);
        }, 0);

        const totalDescuentosFijos = Object.keys(empleados).reduce((acc, key) => {
            return acc + empleados[key].reduce((a, e) => a + parseFloat(e.descuentosFijos), 0);
        }, 0)

        const totalOtrosDescuentos = Object.keys(empleados).reduce((acc, key) => {
            return acc + empleados[key].reduce((a, e) => a + parseFloat(e.otrosDescuentos), 0);
        }, 0);

        const totalTss = Object.keys(empleados).reduce((acc, key) => {
            return acc + empleados[key].reduce((a, e) => a + parseFloat(e.tss), 0);
        }, 0);

        const totalDescuentos = Object.keys(empleados).reduce((acc, key) => {
            return acc + empleados[key].reduce((a, e) => a + parseFloat(e.descuentosTotal), 0);
        }, 0);

        const reporte = [
            [
                "SUELDO FIJO",
                "SUELDO BRUTO",
                "INCENTIVOS FIJOS",
                "INCENTIVOS UNICOS",
                "TOTAL INCENTIVOS",
                "DESCUENTOS FIJOS",
                "OTROS DESCUENTOS",
                "TSS+AFP",
                "TOTAL DESCUENTOS",
                "TOTAL NOMINA",
            ],
            [
                totalSueldoFijo,
                totalSueldoBruto,
                totalIncentivosFijos,
                totalIncentivosUnicos,
                totalIncentivos,
                totalDescuentosFijos,
                totalOtrosDescuentos,
                totalTss,
                totalDescuentos,
                totalPagos
            ],
            []
        ];

        Object.keys(empleados).forEach((key) => {
            const totalCategoria = empleados[key].reduce((acc, empleado) => {
                if (redondear) {
                    return acc + this.redondear(parseFloat(empleado.total));
                }
                return acc + parseFloat(empleado.total);
            }, 0);
            const subtotalSueldoBruto = empleados[key].reduce(
                (acc, empleado) => {
                    return acc + parseFloat(empleado.sueldoBruto);
                },
                0
            );

            const subtotalDescuentos = empleados[key].reduce(
                (acc, empleado) => {
                    return acc + parseFloat(empleado.descuentosTotal);
                },
                0
            );

            const subtotalIncentivos = empleados[key].reduce(
                (acc, empleado) => {
                    return acc + parseFloat(empleado.incentivos);
                },
                0
            );

            reporte.push([
                `TIPO EMPLEADO: ${empleados[key][0].cargo} -
                EMPLEADOS: ${empleados[key].length}`,
                '',
                '',
                '',
                '',
                'TOTAL:',
                totalCategoria,
                ''
            ]);
            reporte.push([
                'CODIGO',
                'NOMBRE',
                'SUELDO FIJO',
                'SUELDO BRUTO',
                'DESCUENTOS',
                'INCENTIVOS',
                'TOTAL',
                'FIRMA',
            ]);

            empleados[key]
                .sort((a, b) => {
                    if ( orden === 'codigo') {
                        if (!a.codigo) {
                            return 1;
                        }
                        return a.codigo.localeCompare(b.codigo);
                    }

                    return a.nombre.localeCompare(b.nombre);
                })
                .forEach((empleado) => {
                    reporte.push([
                        empleado.codigo,
                        empleado.nombre,
                        empleado.sueldoFijo,
                        empleado.sueldoBruto,
                        empleado.descuentosTotal,
                        empleado.incentivos,
                        redondear ? this.redondear(parseFloat(empleado.total)) : parseFloat(empleado.total),
                        ''
                    ]);
                });

            reporte.push([
                '',
                '',
                'TOTALES =>',
                subtotalSueldoBruto,
                subtotalDescuentos,
                subtotalIncentivos,
                totalCategoria,
                ''
            ]);

            reporte.push(['']);
        });

        return (
            <div>
                <style>{`@page {size: 11in  8.5in;} *{font-size: 14px}`}</style>
                <div className="ordernar-resultados no-print">
                    <div className="form-group d-flex align-center">
                        <div className="radio-btn">
                            <label>
                                <input type="radio" onChange={this.onChangeOrden} name="orden" value="nombre" defaultChecked={true} /> Ordenar por Nombre
                            </label>
                            <label>
                                <input type="radio" onChange={this.onChangeOrden} name="orden" value="codigo" /> Ordenar por Código
                            </label>
                            <label>
                                <input type="checkbox" onChange={this.onChangeRedondear} name="redondear" value="redondear" /> Redondear
                            </label>
                        </div>
                        {/* <button className="btn btn-secondary" onClick={() => {
                            const content = arrayToCsv(reporte);
                            downloadBlob(content, `Nomina - ${nomina.nombre}.csv`)
                        }}>Exportar</button> */}

                        <button className="btn btn-secondary ml-2" onClick={this.DownloadExcel}>Exportar a Excel</button>
                    </div>
                </div>
                <div className='row'>
                    {nomina.cerradaEn && (
                        <div className='fecha_cierre col-md-12'>
                            Nomina Cerrada:{" "}
                            {dayjs(nomina.cerradaEn).format("DD/MM/YYYY")}
                        </div>
                    )}
                    <h2 className='col-md-12 text-center m-b-5'>
                        Resumen de Nómina{" "}
                        {query.contrato === "informal" ? "Informales" : ""}
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(nomina.fechaInicio).format("DD/MM/YYYY")} -{" "}
                        {dayjs(nomina.fechaCierre).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                </div>
                <div className='row text-right'>
                    <div className='col-sm-3 text-left'>
                        EMPLEADOS: {totalEmpleados}
                    </div>

                    <div className='col-sm-6 text-center'>
                        <h3>{nomina.nombre}</h3>
                    </div>

                    <div className='col-sm-3 text-right'>
                        <h3>TOTAL: {formatCurrency(totalPagos)}</h3>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th className="text-center">Sueldo Fijo</th>
                            <th className="text-center">Sueldo Bruto</th>
                            <th className="text-center">Incentivos Fijos</th>
                            <th className="text-center">Incentivos Únicos</th>
                            <th className="text-center">Total Incentivos</th>
                            <th className="text-center">Descuentos Fijos</th>
                            <th className="text-center">Otros Descuentos</th>
                            <th className="text-center">TSS+AFP</th>
                            <th className="text-center">Total Descuentos</th>
                            <th className="text-center">Total Nómina</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">
                                {formatCurrency(totalSueldoFijo)}
                            </td>
                            <td className="text-center">
                                {formatCurrency(totalSueldoBruto)}
                            </td>
                            <td className="text-center">
                                {formatCurrency(totalIncentivosFijos)}
                            </td>
                            <td className="text-center">
                                {formatCurrency(totalIncentivosUnicos)}
                            </td>
                            <td className="text-center">
                                {formatCurrency(totalIncentivos)}
                            </td>
                            <td className="text-center">
                                {formatCurrency(totalDescuentosFijos)}
                            </td>
                            <td className="text-center">
                                {formatCurrency(totalOtrosDescuentos)}
                            </td>
                            <td className="text-center">
                                {formatCurrency(totalTss)}
                            </td>
                            <td className="text-center">
                                {formatCurrency(totalDescuentos)}
                            </td>
                            <td className="text-center">
                                {formatCurrency(totalPagos)}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {Object.keys(empleados).map((key) => {
                    const subtotalSueldoBruto = empleados[key].reduce(
                        (acc, empleado) => {
                            return acc + parseFloat(empleado.sueldoBruto);
                        },
                        0
                    );

                    const subtotalDescuentos = empleados[key].reduce(
                        (acc, empleado) => {
                            return acc + parseFloat(empleado.descuentosTotal);
                        },
                        0
                    );

                    const subtotalIncentivos = empleados[key].reduce(
                        (acc, empleado) => {
                            return acc + parseFloat(empleado.incentivos);
                        },
                        0
                    );

                    const totalCategoria = empleados[key].reduce((acc, empleado) => {
                        if (redondear) {
                            return acc + this.redondear(parseFloat(empleado.total));
                        }
                        return acc + parseFloat(empleado.total);
                    }, 0);

                    return (
                        <div key={empleados[key][0].cargo}>
                            <h3 className="pt-5 pb-3">
                                <div className='float-left'>
                                    TIPO EMPLEADO: {empleados[key][0].cargo} -
                                    EMPLEADOS: {empleados[key].length}
                                </div>
                                <div className='float-right'>
                                    TOTAL:
                                    {formatCurrency(totalCategoria)}
                                </div>
                            </h3>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Nombre</th>
                                        {nomina.tipo !== 'FIJOS' && (
                                            <th className='text-center'>
                                                Ventas
                                            </th>
                                        )}
                                        <th className='text-right'>
                                            Sueldo Fijo
                                        </th>
                                        <th className='text-right'>
                                            Sueldo Bruto
                                        </th>
                                        <th className='text-right'>
                                            Descuentos
                                        </th>
                                        <th className='text-right'>
                                            Incentivos
                                        </th>
                                        <th className='text-right'>Total</th>
                                        <th className='text-right' style={{width: 200}}>Firma</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {empleados[key]
                                        .sort((a, b) => {
                                            if ( orden === 'codigo') {
                                                if (!a.codigo) {
                                                    return 1;
                                                }
                                                return a.codigo.localeCompare(b.codigo);
                                            }

                                            return a.nombre.localeCompare(b.nombre);
                                        })
                                        .map((empleado, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{empleado.codigo}</td>
                                                <td>{empleado.nombre}</td>
                                                {nomina.tipo !== 'FIJOS' && (
                                                    <td className='text-center'>
                                                        {formatNumber(
                                                            empleado.toneladas
                                                        )}
                                                    </td>
                                                )}
                                                <td className='text-right'>
                                                    {formatCurrency(
                                                        parseFloat(
                                                            empleado.sueldoFijo
                                                        )
                                                    )}
                                                </td>
                                                <td className='text-right'>
                                                    {formatCurrency(
                                                        empleado.sueldoBruto
                                                    )}
                                                </td>
                                                <td className='text-right'>
                                                    {formatCurrency(
                                                        empleado.descuentosTotal
                                                    )}
                                                </td>
                                                <td className='text-right'>
                                                    {formatCurrency(
                                                        empleado.incentivos
                                                    )}
                                                </td>
                                                <td className='text-right'>
                                                    {redondear ? (
                                                        <span>
                                                            {formatCurrency(
                                                                this.redondear(parseFloat(empleado.total))
                                                                )}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            {formatCurrency(
                                                                parseFloat(empleado.total)
                                                            )}
                                                        </span>
                                                    )}
                                                </td>
                                                <td></td>
                                            </tr>
                                        );
                                    })}
                                    <tr style={{ fontWeight: "bold" }}>
                                        <td></td>
                                        <td></td>
                                        {nomina.tipo !== 'FIJOS' && (
                                            <td></td>
                                        )}
                                        <td>TOTALES =&gt;</td>
                                        <td className='text-right'>
                                            {formatCurrency(
                                                subtotalSueldoBruto
                                            )}
                                        </td>
                                        <td className='text-right'>
                                            {formatCurrency(subtotalDescuentos)}
                                        </td>
                                        <td className='text-right'>
                                            {formatCurrency(subtotalIncentivos)}
                                        </td>
                                        <td className='text-right'>
                                            {formatCurrency(totalCategoria)}
                                        </td>
                                        <td />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                })}
                <div className='row'>
                    <div className='col-md-12 text-right p-t-10 p-b-10'>
                        <div className='float-left'>
                            EMPLEADOS: {totalEmpleados}
                        </div>

                        <div className='float-right'>
                            <h3>TOTAL: {formatCurrency(totalPagos)}</h3>
                        </div>
                    </div>
                </div>
                <div className='firma-reporte'>
                    <div className='firma'>Supervisor</div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(Resumen));
