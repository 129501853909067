import React, { Component } from "react";
import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import Form from "./Form";
import { toast } from "../../../utils/helpers";

class CreateAjuste extends Component {
    state = {
        saving: false,
        errors: []
    };
    createAjuste = async event => {
        event.preventDefault();

        this.setState({
            saving: true
        });

        const token = auth.getToken();
        const data = new FormData(event.target);

        const url = `ajustes`;

        const res = await fetch(apiUrl(url), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            if (this.props.getAjustes) {
                this.props.getAjustes();
            }
            document.getElementById("ajustesform").reset();
            this.props.toggle();

            this.props.getItem();

            toast("Ajuste creada exitosamente.");
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false
        });
    };
    getTable = async (url = null, params = "") => {
        const table = await this.props.request(url + params);

        if (table.data && table.data.length > 0) {
            this.setState({
                [url]: table.data
            });
        }
    };
    render() {
        const { item } = this.props;
        const { errors } = this.state;
        return (
            <form id='ajustesform' method='post' onSubmit={this.createAjuste}>
                <input type='hidden' name='_method' value='post' />
                <div className='popup__header'>Ajustar Item</div>
                <Form item={item} errors={errors} />
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {this.state.saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Ajustar
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default withRequest(CreateAjuste);
