/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { auth } from "../../../utils/auth";
import { arrayToCsv, composeCSV, downloadBlob, formasPagoCompras, formatCurrency, money } from "../../../utils/helpers";
import { downloadFile, request } from "../../../utils/request";
import { procesarNotasCreditoData } from "../../Consultas/NotasCredito/print/ReporteNotasCreditoDetalles";

const ReporteComprasDetalles = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [compras, setCompras] = useState([]);
	const [notas, setNotas] = useState([]);
	const [resumen, setResumen] = useState({
		totalGravado: 0,
		totalExento: 0,
		detalleGravado: {
			totalITBIS16: 0,
			totalITBIS18: 0,
			gravadoITBIS16: 0,
			gravadoITBIS18: 0
		},
		detallePagos: {
			efectivo: 0,
			cheque: 0,
			tarjeta: 0,
			ventaCredito: 0,
			bonusRegalo: 0,
			permuta: 0,
			otro: 0,
		},
	});

	const getCompras = () => {
		request(
			"reportes/compras-detalles" +
				window.location.search + (window.location.search
				    ? "&paginate=1000000"
				    : "?paginate=1000000")
		).then((res) => {
            const data = procesarData(res.compras);
			setCompras(data.compras);
			setResumen({
                detallePagos: res.detallePagos,
                ...data.resumenes
            });
			setTimeout(() => {
                window.print();
            }, 1000);
		});
	};

    const getNotasCredito = () => {
		request(
			"notas-credito" +
				window.location.search +
				"&tipo=compra&paginate=1000000&order=ncf"
		).then((res) => {
            var data = procesarNotasCreditoData(res.data).notasCredito.map(n => {
                n.rnc       = n.compra?.contacto?.identificacion?.replace(/-/g, '');
                n.tipoId    = n.compra.suplidor?.tipo_identificacion === 'rnc' ? 1 : 2;
                n.contacto  = n.cliente;
                n.tipoGasto = n.compra?.tipo_gasto;
                n.formaPago = n.compra?.forma_de_pago;
                n.fecha1    = dayjs(n.fecha).format("YYYYMM");
                n.fecha2    = dayjs(n.fecha).format("DD");
                n.fecha3    = dayjs(n.fecha).format("YYYY/MM/DD");
                n.fechaPago = '';
                n.fechaPago3 = '';
                n.productos = n.productos || [];
                return n;
            });
			setNotas(data);
		});
	};

    const procesarData = (data) => {
        const resumenes = {
            totalExento: 0,
            totalGravado: 0,
            detalleGravado: {
                totalITBIS16: 0,
                gravadoITBIS16:0,
                totalITBIS18: 0,
                gravadoITBIS18:0,
            }
        };
        const compras = [];
        const notas = [];
        data.map(compra => {
            const imp = compra.items?.reduce((a,i) => {
                return a + compra.totalImpuesto
            }, 0);

            const detalleImpuestos = {
                isc: 0,
                propina: 0,
                cdt: 0,
            }

            Object.keys(compra.detalleTotal).forEach(detalle => {
                if (detalle.toLowerCase().includes('isc')) {
                    detalleImpuestos.isc = parseFloat(compra.detalleTotal[detalle] || 0);
                }
                if (detalle.toLowerCase().includes('propina')) {
                    detalleImpuestos.propina = parseFloat(compra.detalleTotal[detalle] || 0);
                }
                if (detalle.toLowerCase().includes('cdt')) {
                    detalleImpuestos.cdt = parseFloat(compra.detalleTotal[detalle] || 0);
                }
                if (detalle.toLowerCase().includes('16')) {
                    detalleImpuestos.i16 = parseFloat(compra.detalleTotal[detalle] || 0);
                }
                if (detalle.toLowerCase().includes('18')) {
                    detalleImpuestos.i18 = parseFloat(compra.detalleTotal[detalle] || 0);
                }
            });

            const row = {
                suplidor: compra.suplidor?.nombre || '',
                rnc: compra.suplidor?.identificacion?.replace(/-/g, '') || '',
                tipoId: compra.suplidor?.tipo_identificacion === 'rnc' ? 1 : 2,
                tipoGasto: compra.tipoGasto || '',
                afectada: compra.afectada || '',
                ncf: compra.ncf || '',
                fecha: dayjs(compra.fecha).format("YYYYMMDD"),
                fecha1: dayjs(compra.fecha).format("YYYYMM"),
                fecha2: dayjs(compra.fecha).format("DD"),
                fecha3: dayjs(compra.fecha).format("YYYY/MM/DD"),
                fechaPago: compra.pagos.length > 0 ? dayjs(compra.pagos[0].fecha).format("YYYYMMDD") : '',
                fechaPago3: compra.pagos.length > 0 ? dayjs(compra.pagos[0].fecha).format("YYYY/MM/DD") : '',
                importe: compra.detalleTotal['subtotal'],
                importeC: compra.estado === "cancelada" ? 0 : compra.total - imp,
                impuesto: 0,
                impuestoC: compra.estado === "cancelada" ? 0 : imp,
                subtotal: compra.detalleTotal['subtotal'],
                descuento: parseFloat(compra.descuento),
                total: compra.total,
                totalC: compra.estado === "cancelada" ? 0 : compra.total,
                formaPago: compra.formaPago || '',
                i16: detalleImpuestos.i16 || 0,
                i18: detalleImpuestos.i18 || 0,
                ie: 0,
                ig16: 0,
                ig18: 0,
                it: 0,
                tipo: compra.formaPago,
                isc: detalleImpuestos.isc,
                cdt: detalleImpuestos.cdt,
                propina: detalleImpuestos.propina,
            }

            const impuestoTotal = (row.i16 + row.i18) || compra.detalleTotal['ITBIS'];

            console.log({impuestoTotal})
            const otrosImpuestos = row.isc + row.cdt + row.propina;
            const importe = row.total - impuestoTotal - otrosImpuestos + row.descuento;

            row.impuesto = impuestoTotal + otrosImpuestos;
            row.otrosImpuestos = otrosImpuestos || 0;
            row.ig16 = row.i16 / 0.16;
            row.ig18 = row.i18 / 0.18;
            row.ie = importe - row.ig16 - row.ig18;

            resumenes.detalleGravado.totalITBIS16 += row.i16;
            resumenes.detalleGravado.gravadoITBIS16 += row.ig16;
            resumenes.totalGravado += row.ig16;

            resumenes.detalleGravado.totalITBIS18 += row.i18;
            resumenes.detalleGravado.gravadoITBIS18 += row.ig18;
            resumenes.totalGravado += row.ig18;

            resumenes.totalExento += row.ie;

            compras.push(row);

            /* if (compra.notas_credito.length > 0) {
                const nproductos = compra.notas_credito.reduce((a,n) => {
                    n.productos.forEach(p => {
                        return a.push(p);
                    })
                    return a;
                }, []);
                const notasData = procesarNotasCreditoData(
                    compra.notas_credito.filter(n => {
                        const params = new URLSearchParams(window.location.search);
                        return (dayjs(n.fecha) >= dayjs(params.get('desde'))) && (dayjs(n.fecha) <= dayjs(params.get('hasta')));
                    }).map(n => {
                        n.compra = compra;
                        n.contacto = compra.suplidor;
                        n.tipoGasto = compra.tipoGasto;
                        n.formaPago = compra.formaPago;
                        n.fecha1 = dayjs(n.fecha).format("YYYYMM");
                        n.fecha2 = dayjs(n.fecha).format("DD");
                        n.fechaPago = '';
                        n.productos = nproductos.map(p => {
                            return {
                                ...compra.items.find(p => p.producto_id == p.producto_id),
                                ...p,
                            };
                        });
                        return n;
                    })
                );
                notasData.notasCredito.forEach(n => {
                    notas.push(n);
                })
            } */
        });

        return {
            resumenes,
            compras
        };
    }

	useEffect(() => {
		getCompras();
        getNotasCredito();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];

	const valorTotal = compras?.reduce((acc, compra) => acc + parseFloat(compra.total || 0), 0);
	const valorImpuestos = compras?.reduce((acc, compra) => acc + parseFloat(compra.impuesto || 0), 0);
	const valorImporte = compras?.reduce((acc, compra) => acc + parseFloat(compra.importe || 0), 0);
	const valorDescuento = compras?.reduce((acc, compra) => acc + parseFloat(compra.descuento || 0), 0);
	const valorOtrosImpuestos = compras?.reduce((acc, compra) => acc + parseFloat(compra.otrosImpuestos || 0), 0);

    const reporte = composeCSV(
        compras?.map(compra => ({
            "Suplidor"  : compra.suplidor,
            "R.N.C"     : compra.rnc,
            "Tipo Gasto": compra.tipoGasto,
            "NCF"       : compra.ncf,
            "Fecha"     : compra.fecha,
            "Importe"   : money(compra.importe),
            "Impuesto"  : money(compra.impuesto),
            "Descuento" : money(compra.descuento),
            "Total"     : money(compra.total),
            "Tipo pago" : compra.formaPago
        }))
    );

    const reporteDetalle = composeCSV(
        compras?.map(compra => ({
            "Suplidor"   : compra.suplidor,
            "R.N.C"      : compra.rnc,
            "Tipo Gasto" : compra.tipoGasto,
            "NCF"        : compra.ncf,
            "Fecha"      : compra.fecha,
            "Tipo pago"  : compra.formaPago,
            "Importe"    : money(compra.importe),
            "ITBIS 16%"  : money(compra.i16),
            "ITBIS 18%"  : money(compra.i18),
            "Impuesto"   : money(compra.impuesto),
            "Descuento"  : money(compra.descuento),
            "Total"      : money(compra.total),
            "Exento"     : money(compra.ie),
            "Grabado 16%": money(compra.ig16),
            "Grabado 18%": money(compra.ig18),
        }))
    );

    const reporte606 = composeCSV(
        compras?.concat(notas.filter(nota => nota.estado !== 'cancelada'))?.map(compra => ({
            "RNC o Cédula" : compra.rnc,
            "Tipo Id" : compra.tipoId,
            "Tipo Bienes y Servicios Comprados" : compra.tipoGasto,
            "NCF" : compra.ncf,
            "NCF ó Documento Modificado" : compra.afectada || '',
            "Fecha Comprobante" : compra.fecha,
            "Fecha de Retención" : '',
            "Fecha Pago" : compra.fechaPago,
            "Monto Facturado en Servicios" : '',
            "Monto Facturado en Bienes" : '',
            "Total Monto Facturado" : money(compra.importe),
            "ITBIS Facturado" : money(compra.i18 + compra.i16),
            "ITBIS Retenido" : '',
            "ITBIS sujeto a Proporcionalidad (Art. 349)" : '',
            "ITBIS llevado al Costo" : '',
            "ITBIS por Adelantar" : money(compra.i18 + compra.i16),
            "ITBIS percibido en compras" : '',
            "Tipo de Retención en ISR" : '',
            "Monto Retención Renta" : '',
            "ISR Percibido en compras" : '',
            "Impuesto Selectivo al Consumo" : money(compra.isc) || '',
            "Otros Impuesto/Tasas" : money(compra.cdt) || '',
            "Monto Propina Legal" : money(compra.propina) || '',
            "Forma de Pago" : compra.formaPago,
        }))
    );

    const downloadReport = async (reporte, name, type) => {
        await downloadFile(`reportes/compras/2${window.location.search}&reporte=${reporte}&type=${type}`, `${name}.${type}`);
    };

	return (
		<>
			<style>{`@page {size: A4 landscape}`}</style>
            <div className="ordernar-resultados no-print">
                <div className="form-group">
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        const content = arrayToCsv(reporte);
                        downloadBlob(content, `detalle-de-impuestos-compras-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}).csv`)
                    }}>Exportar.CSV</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        const content = arrayToCsv(reporteDetalle);
                        downloadBlob(content, `detalle-de-impuestos-compras-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}).csv`)
                    }}>Exportar Detalle.CSV</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        const content = arrayToCsv(reporte606);
                        downloadBlob(content, `impuestos-compras-606 (${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}).csv`)
                    }}>Exportar 606.CSV</button>

                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('exportar', `detalle-de-impuestos-compras-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}`, 'xlsx');
                    }}>Exportar.XLSX</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('detalle', `detalle-de-impuestos-compras-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}`, 'xlsx');
                    }}>Exportar Detalle.XLSX</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('606', `impuestos-compras-606-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}`, 'xlsx');
                    }}>Exportar 606.XLSX</button>

                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('exportar', `detalle-de-impuestos-compras-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}`, 'pdf');
                    }}>Exportar.PDF</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('detalle', `detalle-de-impuestos-compras-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}`, 'pdf');
                    }}>Exportar Detalle.PDF</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('606', `impuestos-compras-606-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}`, 'pdf');
                    }}>Exportar 606.PDF</button>
                </div>
            </div>

			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Detalle de Impuestos Compras
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde") && queryParams.get("hasta") ? (
                        <span>
                            {queryParams.get("desde")
                                ? `${dayjs(queryParams.get("desde")).format(
                                        "DD/MM/YYYY"
                                  )} - `
                                : ""}{" "}
                            {dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
                        </span>
                    ) : (
                        <span>{dayjs().format("DD/MM/YYYY")}</span>
                    )}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL COMPRAS: {compras.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>

			<table className="table m-b-10">
				<thead>
					<tr>
						<th width="290px">Suplidor</th>
						<th width="100px">R.N.C</th>
						<th width="120px">Tipo Gasto</th>
						<th width="120px">NCF</th>
						<th width="100px">Fecha</th>
						<th width="135px">Importe</th>
						<th width="125px">Impuesto</th>
						<th>Desc.</th>
						<th width="135px">Total</th>
						<th width="110px">Forma de pago</th>
					</tr>
				</thead>
				<tbody>
					{compras.filter(c => c.suplidor).map((compra) => {
						return (
							<tr key={compra.id}>
								<td>
									{compra.suplidor}
								</td>
								<td className="text-capitalize">
									{compra.rnc}
								</td>
								<td>{compra.tipoGasto}</td>
								<td>{compra.ncf}</td>
								<td>
									{compra.fecha3}
								</td>
								<td>
									{formatCurrency(compra.importe)}
								</td>
								<td>
									{formatCurrency(compra.impuesto)}
								</td>
								<td>
									{formatCurrency(compra.descuento)}
								</td>
								<td>
									{formatCurrency(compra.total)}
								</td>
								<td className="text-capitalize">
									{compra.formaPago}
								</td>
							</tr>
						);
					})}
					<tr style={{fontWeight: "bold"}} className="p-t-2">
						<td style={{fontWeight: "normal"}}>Total de compras: {compras.length}</td>
						<td></td>
						<td ></td>
						<td ></td>
						<td></td>
						<td>
							{formatCurrency(valorImporte)}
						</td>
						<td>
							{formatCurrency(valorImpuestos)}
						</td>
						<td>
							{formatCurrency(valorDescuento)}
						</td>
						<td>
							{formatCurrency(valorTotal)}
						</td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</table>

			{/* RESUMEN DE compraS (IMPUESTOS) */}
			<div className="row">
                <div className="fw-600 mb-2 mt-4     col-md-12 pl-3">
                    RESUMEN DE COMPRAS
                </div>
                <div className="col-md-6 row">
                    {/* DETALLES */}
                    <div
                        className="p-b-10 pl-3 d-inline-block mr-4"
                        style={{ width: "22rem" }}
                    >
                        <div className="fw-600">TOTALES NETOS</div>
                        <hr style={{borderBottom: "3px double #999"}}/>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                GRAVADOS CON ITBIS 16%
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(
                                    resumen.detalleGravado?.gravadoITBIS16
                                )}
                            </span>
                        </div>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                GRAVADOS CON ITBIS 18%
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(
                                    resumen.detalleGravado?.gravadoITBIS18
                                )}
                            </span>
                        </div>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                OTROS IMPUESTOS
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(
                                    valorOtrosImpuestos
                                )}
                            </span>
                        </div>
                        <hr style={{borderBottom: "3px double #999"}}/>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL GRAVADO
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(resumen.totalGravado)}
                            </span>
                        </div>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL EXENTO{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(resumen.totalExento)}
                            </span>
                        </div>
                        <hr style={{borderBottom: "3px double #999"}}/>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL OPERACIONES
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(resumen.totalGravado + resumen.totalExento)}
                            </span>
                        </div>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL DE ITBIS{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(valorImpuestos)}
                            </span>
                        </div>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL DE DESCUENTOS{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(valorDescuento)}
                            </span>
                        </div>
                        <hr style={{borderBottom: "3px double #999"}}/>
                        <div className="bold m-t-5">
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL GENERAL{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(valorTotal)}
                            </span>
                        </div>
                    </div>

                </div>
                {/* RESUMEN DE compraS (TIPOS DE COBRO) */}
                <div className="col-md-6">
                    {/* IMPUESTOS */}
                    <div className="p-b-10" style={{ width: "26rem" }}>
                        <div className="fw-600">TOTALES POR TASAS DE ITBIS:</div>
                        <hr style={{borderBottom: "3px double #999"}}/>
                        <table className="table-clean">
                            <thead style={{borderBottom: "1px dashed #999"}}>
                                <tr>
                                    <td>TASA</td>
                                    <td className="text-right">VENTA</td>
                                    <td className="text-right">ITBIS</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>16% ITBIS</td>
                                    <td className="text-right">
                                        {formatCurrency(
                                            resumen.detalleGravado?.gravadoITBIS16
                                        )}
                                    </td>
                                    <td className="text-right">
                                        {formatCurrency(
                                            resumen.detalleGravado?.totalITBIS16
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>18% ITBIS</td>
                                    <td className="text-right">
                                        {formatCurrency(
                                            resumen.detalleGravado?.gravadoITBIS18
                                        )}
                                    </td>
                                    <td className="text-right">
                                        {formatCurrency(
                                            resumen.detalleGravado?.totalITBIS18
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>OTROS IMPUESTOS</td>
                                    <td className="text-right">
                                        {/* {formatCurrency(
                                            valorOtrosImpuestos
                                        )} */}
                                    </td>
                                    <td className="text-right">
                                        {formatCurrency(
                                            valorOtrosImpuestos
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot style={{borderTop: "1px dashed #999"}}>
                                <tr>
                                    <td></td>
                                    <td className="text-right">
                                        {formatCurrency(
                                            resumen.detalleGravado?.gravadoITBIS16 +
                                            resumen.detalleGravado?.gravadoITBIS18
                                        )}
                                    </td>
                                    <td className="text-right">
                                        {formatCurrency(
                                            resumen.detalleGravado?.totalITBIS16 +
                                            resumen.detalleGravado?.totalITBIS18 +
                                            (valorOtrosImpuestos || 0)
                                        )}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    {/* TIPOS DE COBRO */}
                    <div
                        className="p-b-10 d-inline-block mr-4"
                        style={{ width: "26rem" }}
                    >
                        <div className="fw-600">FORMAS DE PAGOS</div>
                        {formasPagoCompras.map(formaPago => (
                            <div>
                                <span
                                    className="uppercase"
                                    style={{ width: "250px", display: "inline-block" }}
                                >
                                    {formaPago}
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(
                                        compras.filter(c => c.formaPago === formaPago).reduce((a,c) => a + c.total, 0)
                                    )}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
			</div>

		</>
	);
};

export default ReporteComprasDetalles;
