import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";

class PrintGastosVehiculos extends Component {
    state = {
        gastos: [],
    };
    componentDidMount() {
        this.getGastosVehiculos();
    }
    getGastosVehiculos = async () => {
        const response = await this.props.request(
            "gastos" + window.location.search
        );

        if (response.data) {
            this.setState({
                gastos: response.data,
            });
            window.print();
        }
    };
    render() {
        const { gastos } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const totalGastos = gastos.reduce((acc, gasto) => {
            return acc + gasto.cantidad * gasto.precio;
        }, 0);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Gastos Vehículos
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            CANT. GASTOS: {gastos.length}
                        </div>

                        <div className='float-right'>
                            <h3>GASTO TOTAL: {formatCurrency(totalGastos)}</h3>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ width: 120 }}>Fecha</th>
                            <th>Vehículo</th>
                            <th>Item</th>
                            <th>Grupo</th>
                            <th>Mecanico</th>
                            <th className='text-right'>Cantidad</th>
                            <th className='text-right'>Monto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gastos.map((gasto) => {
                            return (
                                <tr key={gasto.id}>
                                    <td>
                                        {dayjs(gasto.fecha).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </td>
                                    <td>{gasto.vehiculo}</td>
                                    <td>{gasto.item.nombre}</td>
                                    <td>{gasto.grupo}</td>
                                    <td>{gasto.mecanico}</td>
                                    <td className='text-right p-r-10'>
                                        {gasto.cantidad}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(
                                            gasto.cantidad * gasto.precio
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='row m-t-45'>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            CANT. GASTOS: {gastos.length}
                        </div>

                        <div className='float-right'>
                            <h3>GASTO TOTAL: {formatCurrency(totalGastos)}</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintGastosVehiculos));
