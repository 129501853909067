import React, {useEffect, useState} from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { auth } from "../../utils/auth";
import { getLink, isCobrador, isVendedor } from "../../utils/helpers";
import { request } from "../../utils/request";
import Modal from "./Modal";

const Sidebar = ({ toggleModal }) => {
    const location = useLocation();
    const history = useHistory();
    // const isGastos = location.pathname.indexOf("gastos") > -1;
    const isOperaciones = location.pathname.match(/(gi-)/)?.length > 0;
    const isEmpleados = location.pathname.match(/(empleados|tipos)/)?.length > 0;
    const isOrdenes = location.pathname.indexOf("ingresos") > -1;
    const isConsulta = location.pathname.indexOf("consultas") > -1;
    const isInventario = location.pathname.match(/(productos|inventario)/) > -1;
    const isAcademico = location.pathname.match(/(academico)/) > -1;
    const isContabilidad = location.pathname.match(/(cuentas-contables|entradas-de-diario|reportes-contables)/) > -1;

	const [rutas, setRutas] = useState([]);
	const [rutasSeleccionadas, setRutasSeleccionadas] = useState([]);
	const [showRutasModal, setShowRutasModal] = useState(false);

    const [almacenes, setAlmacenes] = useState([]);
    const [almacenesSeleccionados, setAlmacenesSeleccionados] = useState([]);
	const [showAlmacenesModal, setShowAlmacenesModal] = useState(false);

    const registerZonesToWork = () => {
        if(rutasSeleccionadas.length <= 0) return;

		localStorage.setItem("rutas", JSON.stringify(rutasSeleccionadas));

        setShowRutasModal(false);

        if (auth.getUser().rol !== 'vendedor-cliente' && !isOrdenes) {
            history.push(getLink(isCobrador() ? "/consultas/cuentas-por-cobrar" : "/ingresos/ordenes-compra"));
        }
	};

    const registerAlmacenesToWork = () => {
        if(almacenesSeleccionados.length <= 0) return;

		localStorage.setItem("almacenes", JSON.stringify(almacenesSeleccionados));

        setShowAlmacenesModal(false);

        if (!isOrdenes) {
            history.push(getLink(isCobrador() ? "/consultas/cuentas-por-cobrar" : "/ingresos/ordenes-compra"));
        }
	};

    const getRutasForDropdown = () => {
        const url = auth.getUser().rol === 'vendedor-cliente' ? 'rutas-clientes' : 'rutas?limit=100';
		request(url).then((res) => {
			setRutas([
				...res.data.map((ruta) => ({
					value: ruta.id,
					label: `${ruta.nombre} ${ruta.descripcion}`,
				})),
			]);
		});
	};

    const getAlmacenesForDropdown = () => {
        const url = 'almacenes';
		request(url).then((res) => {
			setAlmacenes([
				...res.data.map((alamacen) => ({
					value: alamacen.id,
					label: alamacen.nombre,
				})),
			]);
		});
	};

    useEffect(()  => {
        if (isVendedor() && !localStorage.getItem("rutas")) {
            getRutasForDropdown();
            setShowRutasModal(true);
		}

        if (auth.getUser().rol === 'vendedor-cliente' && !localStorage.getItem("almacenes")) {
            getAlmacenesForDropdown();
            setShowAlmacenesModal(true);
        }
    }, [location.pathname]);

    const user = auth.getUser();
    const sucursalActual = user.sucursales.filter(s => s.id === user.sucursalActual)[0];

    return (
        <>
            <aside
                id="sidebar"
                className='menu-sidebar d-none d-lg-block'
                onClick={() => toggleModal("showReportes", false)}>
                <div className='menu-sidebar__content js-scrollbar1'>
                    <nav className='navbar-sidebar'>
                        <ul className='list-unstyled navbar__list'>
                            {auth.can("ver-dashboard") && (
                                <li>
                                    <NavLink
                                        className='js-arrow'
                                        to={getLink("/")}
                                        exact>
                                        <i className='fas fa-tachometer-alt' />
                                        <span className="menu-name">Inicio</span>
                                    </NavLink>
                                </li>
                            )}
                            {auth.can("ver-dashboard") && (
                                <li className='separator' />
                            )}

                            {sucursalActual.tipo === 'educativa' && auth.can("ver-academico") && (
                                <li
                                    className={
                                        "has-sub " + (isAcademico ? "active" : "")
                                    }>
                                    <a
                                        href='#sub'
                                        className='parent-label'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.target.parentElement.classList.toggle(
                                                "active"
                                            );
                                        }}>
                                        <i className="fas fa-graduation-cap"></i>
                                        <span className="menu-name">Académico</span>
                                    </a>
                                    <ul className='sub-menu'>
                                        {auth.can("ver-evaluaciones") && (
                                        <li>
                                            <NavLink
                                                to={getLink("/academico/evaluaciones")}>
                                                <span className="menu-name">Evaluaciones</span>
                                            </NavLink>
                                        </li>
                                        )}
                                        {auth.can("ver-estudiantes") && (
                                        <li>
                                            <NavLink
                                                to={getLink("/academico/estudiantes")}>
                                                <span className="menu-name">Estudiantes</span>
                                            </NavLink>
                                        </li>
                                        )}
                                        {auth.can("ver-inscripcion") && (
                                        <li>
                                            <NavLink
                                                to={getLink("/academico/inscripcion")}>
                                                <span className="menu-name">Inscripción</span>
                                            </NavLink>
                                        </li>
                                        )}
                                        {auth.can("ver-padres-tutores") && (
                                        <li>
                                            <NavLink
                                                to={getLink("/academico/padres-tutores")}>
                                                <span className="menu-name">Padres/Tutores</span>
                                            </NavLink>
                                        </li>
                                        )}
                                        {auth.can("ver-facturacion-academica") && (
                                            <>
                                                <li>
                                                    <NavLink
                                                        to={getLink("/academico/recibos")}>
                                                        <span className="menu-name">Cobros Académicos</span>
                                                    </NavLink>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </li>
                            )}

                            {sucursalActual.tipo === 'educativa' && auth.can("ver-academico") && (
                                <li className='separator' />
                            )}

                            {sucursalActual.tipo === 'educativa' && auth.can("ver-academico") && (
                                <li
                                    className={
                                        "has-sub " + (isAcademico ? "active" : "")
                                    }>
                                    <a
                                        href='#sub'
                                        className='parent-label'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.target.parentElement.classList.toggle(
                                                "active"
                                            );
                                        }}>
                                        <i className="zmdi zmdi-palette" style={{fontSize: '1.4rem'}}></i>
                                        <span className="menu-name">Cocurriculares</span>
                                    </a>
                                    <ul className='sub-menu'>
                                        {auth.can("ver-facturacion-academica") && (
                                            <>
                                                <li>
                                                    <NavLink
                                                        to={getLink("/academico/recibos-cocurriculares")}>
                                                        <span className="menu-name">Cobros</span>
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        to={getLink("/academico/inscripcion-cocurriculares")}>
                                                        <span className="menu-name">Inscripción</span>
                                                    </NavLink>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </li>
                            )}

                            {sucursalActual.tipo === 'educativa' && auth.can("ver-academico") && (
                                <li className='separator' />
                            )}

                            {auth.can("ver-contabilidad") && (
                                <li
                                    className={
                                        "has-sub " + (isContabilidad ? "active" : "")
                                    }>
                                    <a
                                        href='#sub'
                                        className='parent-label'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.target.parentElement.classList.toggle(
                                                "active"
                                            );
                                        }}>
                                        <i className="fas fa-calculator"></i>
                                        <span className="menu-name">Contabilidad</span>
                                    </a>
                                    <ul className='sub-menu'>
                                        {auth.can("ver-cuentas") && (
                                            <li>
                                                <NavLink
                                                    to={getLink("/cuentas")}>
                                                    <span className="menu-name">Bancos</span>
                                                </NavLink>
                                            </li>
                                        )}
                                        <li>
                                            <NavLink
                                                to={getLink("/cheques")}>
                                                <span className="menu-name">Cheques</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={getLink("/cuentas-contables")}>
                                                <span className="menu-name">Cuentas Contables</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={getLink("/entradas-de-diario")}>
                                                <span className="menu-name">Entradas de Diario</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={getLink("/reportes-contables")}>
                                                <span className="menu-name">Reportes Contables</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            )}

                            {auth.can("ver-contabilidad") && (
                                <li className='separator' />
                            )}

                            {auth.can("ver-contratos") && (
                                <li>
                                    <NavLink
                                        href='#sub'
                                        className='js-arrow'
                                        to={getLink("/contratos")}

                                    >
                                        <i className="fas fa-file-signature"/>
                                        <span className="menu-name">Contratos</span>
                                    </NavLink>
                                </li>
                            )}

                            {auth.can("ver-programacion") && (
                                <li>
                                    <NavLink
                                        className='js-arrow'
                                        to={getLink("/programaciones")}>
                                        <i className='fas fa-calendar-alt' />
                                        <span className="menu-name">Programación</span>
                                    </NavLink>
                                </li>
                            )}
                            {auth.can("ver-ingresos") && (
                                <li
                                    className={
                                        "has-sub " + (isOrdenes ? "active" : "")
                                    }>
                                    <a
                                        href='#sub'
                                        className='parent-label'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.target.parentElement.classList.toggle(
                                                "active"
                                            );
                                        }}>
                                        <i className="fas fa-dollar-sign"></i>
                                        <span className="menu-name">Ingresos</span>
                                    </a>
                                    <ul className='sub-menu'>
                                        {auth.can("ver-facturas") && (
                                            <li>
                                                <NavLink
                                                    to={getLink("/ingresos/facturas")}>
                                                    <span className="menu-name">Facturas</span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {auth.can("ver-facturas-recurrentes") && (
                                            <li>
                                                <NavLink
                                                    to={getLink("/ingresos/facturas-recurrentes")}>
                                                    <span className="menu-name">Facturas Recurrentes</span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {auth.can("ver-cotizaciones") && (
                                            <li>
                                                <NavLink
                                                    to={getLink("/ingresos/cotizaciones")}>
                                                    <span className="menu-name">Cotizaciones</span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {auth.can("ver-ordenes-compra") && (
                                            <li>
                                                <NavLink
                                                    to={getLink("/ingresos/ordenes-compra")}>
                                                    <span className="menu-name">Ordenes de compra</span>
                                                </NavLink>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}
                            {auth.can("ver-consultas") && (
                                <li
                                    className={
                                        "has-sub " + (isConsulta ? "active" : "")
                                    }>
                                    <a
                                        href='#sub'
                                        className='parent-label'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.target.parentElement.classList.toggle(
                                                "active"
                                            );
                                        }}>
                                        <i className="fas fa-search"></i>
                                        <span className="menu-name">Consultas</span>
                                    </a>
                                    <ul className='sub-menu'>
                                        {auth.can("ver-cuentas-por-cobrar") && (
                                            <li>
                                                <NavLink
                                                    to={getLink("/consultas/cuentas-por-cobrar")}>
                                                    <span className="menu-name">Cuentas por cobrar</span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {auth.can("ver-cuentas-por-pagar") && (
                                            <li>
                                                <NavLink
                                                    to={getLink("/consultas/cuentas-por-pagar")}>
                                                    <span className="menu-name">Cuentas por pagar</span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {auth.can("ver-recibos-ingreso") && (
                                            <li>
                                                <NavLink
                                                    to={getLink("/consultas/recibos")}>
                                                    <span className="menu-name">Recibos</span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {auth.can("ver-notas-credito") && (
                                            <li>
                                                <NavLink
                                                    to={getLink("/consultas/notas-credito")}>
                                                    <span className="menu-name">Notas de crédito</span>
                                                </NavLink>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}
                            {auth.can("ver-gi-grupos") && (
                                <li
                                    className={
                                        "has-sub " + (isOperaciones ? "active" : "")
                                    }>
                                    <a
                                        href='#sub'
                                        className='parent-label'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.target.parentElement.classList.toggle(
                                                "active"
                                            );
                                        }}>
                                        <i className='fas fa-cogs' />
                                        <span className="menu-name">Operaciones</span>
                                    </a>
                                    <ul className='sub-menu'>
                                        <li>
                                            <NavLink
                                                to={getLink("/gi-grupos")}>
                                                <span className="menu-name">Grupos</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={getLink("/gi-servidores")}>
                                                <span className="menu-name">Servidores</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={getLink("/gi-prestamos")}>
                                                <span className="menu-name">Préstamos</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {auth.can("ver-empleados") && (
                                <li className='separator' />
                            )}
                            {auth.can("ver-empleados") && (
                                <li
                                    className={
                                        "has-sub " + (isEmpleados ? "active" : "")
                                    }>
                                    <a
                                        href='#sub'
                                        className='parent-label'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.target.parentElement.classList.toggle(
                                                "active"
                                            );
                                        }}>
                                        <i className='fas fa-users' />
                                        <span className="menu-name">Empleados</span>
                                    </a>
                                    <ul className='sub-menu'>
                                        <li>
                                            <NavLink to={getLink("/empleados")}>
                                                <span className="menu-name">Listado</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={getLink("/configuracion/tipos")}>
                                                <span className="menu-name">Tipos Empleados</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={getLink("/grupo-tipos-empleados")}>
                                                <span className="menu-name">Grupos Tipos</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={getLink("/ausencias")}>
                                                <span className="menu-name">Ausencias</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {auth.can("ver-nomina") && (
                                <li>
                                    <NavLink to={getLink("/nomina")}>
                                        <i className='fa fa-paste' />
                                        <span className="menu-name">Nómina</span>
                                    </NavLink>
                                </li>
                            )}
                            {auth.can("ver-descuentos") && (
                                <li>
                                    <NavLink to={getLink("/descuentos")}>
                                        <i className='fa fa-calculator' />
                                        <span className="menu-name">Descuentos</span>
                                    </NavLink>
                                </li>
                            )}
                            {auth.can("ver-incentivos") && (
                                <li>
                                    <NavLink to={getLink("/incentivos")}>
                                        <i className='fa fa-file-invoice-dollar' />
                                        <span className="menu-name">Incentivos</span>
                                    </NavLink>
                                </li>
                            )}
                            {auth.can("ver-solicitudes") && (
                                <li>
                                    <NavLink to={getLink("/solicitudes")}>
                                        <i className='fa fa-clipboard' />
                                        <span className="menu-name">Solicitudes</span>
                                    </NavLink>
                                </li>
                            )}
                            {auth.can("ver-reclamaciones") && (
                                <li>
                                    <NavLink to={getLink("/reclamaciones")}>
                                        <i className='fa fa-exclamation-circle' />
                                        <span className="menu-name">Reclamaciones</span>
                                    </NavLink>
                                </li>
                            )}
                            {auth.can("ver-vehiculos") && (
                                <li className='separator' />
                            )}
                            {auth.can("ver-vehiculos") && (
                                <li>
                                    <NavLink to={getLink("/vehiculos")}>
                                        <i className='fas fa-truck' />
                                        <span className="menu-name">Vehículos</span>
                                    </NavLink>
                                </li>
                            )}
                            {auth.can("ver-servicios") && (
                                <li>
                                    <NavLink to={getLink("/servicios")}>
                                        <i className='fas fa-wrench' />
                                        <span className="menu-name">Servicios</span>
                                    </NavLink>
                                </li>
                            )}
                            {auth.can("ver-rutas") && (
                                <li>
                                    <NavLink to={getLink("/rutas")}>
                                        <i className='fas fa-map-marker-alt' />
                                        <span className="menu-name">Zonas</span>
                                    </NavLink>
                                </li>
                            )}

                            {auth.can("ver-inventario") && (
                                <li
                                    className={
                                        "has-sub " + (isInventario ? "active" : "")
                                    }>
                                    <a
                                        href='#sub'
                                        className='parent-label'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.target.parentElement.classList.toggle(
                                                "active"
                                            );
                                        }}>
                                        <i className='fas fa-store' />
                                        <span className="menu-name">Inventario</span>
                                    </a>
                                    <ul className='sub-menu'>
                                        <li>
                                            <NavLink to={getLink("/productos")}>
                                                <span className="menu-name">Productos</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={getLink("/inventario")}>
                                                <span className="menu-name">Interno</span>
                                            </NavLink>
                                        </li>
                                        {auth.can("ver-compras") && (
                                            <li>
                                                <NavLink to={getLink("/compras")}>
                                                    <span className="menu-name">Compras</span>
                                                </NavLink>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}

                            {auth.can("ver-productos") && (
                                <li className='separator' />
                            )}

                            {auth.can("ver-gastos") && (
                                <li>
                                    <NavLink to={getLink("/gastos-internos")}>
                                        <i className='fas fa-money-check-alt' />
                                        <span className="menu-name">Gastos</span>
                                    </NavLink>
                                </li>
                            )}
                            {auth.can("ver-dashboard") && (
                                <li className='separator' />
                            )}
                            {auth.can("ver-contactos") && (
                                <li>
                                    <NavLink to={getLink("/contactos")}>
                                        <i className='fas fa-address-book' />
                                        <span className="menu-name">Contactos</span>
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
                <div></div>
            </aside>

            <Modal
                show={showAlmacenesModal}
                size="sm"
                callback={registerAlmacenesToWork}
                toggle={() => setShowAlmacenesModal(state => !state)}
                showCancelBtn={false}
                title="Seleccione Almacen(es)"
                classes={{content: "zone-selector"}}
            >
                <div className="form-group">
                    <label>Seleccione almacenes a Trabajar</label>
                    <Select
                        id="inputVendedor"
                        options={almacenes}
                        isMulti
                        onChange={(e) => setAlmacenesSeleccionados(e)}
                        menuPortalTarget={document.body}
                        closeMenuOnSelect={false}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 200005 }),
                        }}
                    />
                </div>
            </Modal>
            <Modal
                show={showRutasModal}
                size="sm"
                callback={registerZonesToWork}
                toggle={() => setShowRutasModal(state => !state)}
                showCancelBtn={false}
                title="Seleccione Zona(s)"
                classes={{content: "zone-selector"}}
            >
                <div className="form-group">
                    <label>Seleccione zonas a Trabajar</label>
                    <Select
                        id="inputVendedor"
                        options={rutas}
                        isMulti
                        menuPosition="fixed"
                        onChange={(e) => setRutasSeleccionadas(e)}
                        menuPortalTarget={document.body}
                        closeMenuOnSelect={false}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 200005 }),
                        }}
                    />
                </div>
            </Modal>
        </>

    );
};

export default Sidebar;
