import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { withRequest } from "../../../utils/request";
import { formatCurrency } from "../../../utils/helpers";

class Comprobantes extends Component {
    state = {
        nominas: [{ pagos: [] }],
    };
    componentDidMount() {
        this.getNomina();
    }
    getNomina = async (url = null) => {
        const id = this.props.match.params.id;
        const search = window.location.search ? window.location.search : "";
        url = url ? url : "nominas/" + id + search;
        const nominas = await this.props.request(url);

        if (nominas.success) {
            this.setState({
                nominas: nominas.data,
            });
            //window.print();
        }
    };
    render() {
        const { nominas } = this.state;
        const nomina = nominas[0];
        const params = queryString.parse(window.location.search);

        const combinarPagos = nominas.reduce((acc, nom) => {
            acc = [...acc, ...nom.pagos];
            return acc;
        }, []);

        const empleados = combinarPagos
            .sort((a, b) => {
                return a.nombre < b.nombre ? -1 : 1;
            })
            .reduce((acc, empleado) => {
                if (acc[empleado.cedula] === undefined) {
                    acc[empleado.cedula] = empleado;
                } else {
                    acc[empleado.cedula].toneladas =
                        parseFloat(acc[empleado.cedula].toneladas) +
                        parseFloat(empleado.toneladas);
                    acc[empleado.cedula].total =
                        parseFloat(acc[empleado.cedula].total) +
                        parseFloat(empleado.total);
                    acc[empleado.cedula].sueldoBruto =
                        parseFloat(acc[empleado.cedula].sueldoBruto) +
                        parseFloat(empleado.sueldoBruto);
                }
                return acc;
            }, {});

        const pagos = Object.keys(empleados).reduce((acc, empleado) => {
            acc.push(empleados[empleado]);
            return acc;
        }, []);

        return (
            <div>
                <style>{`@page {size: 8.5in 11in;}`}</style>
                <div className={'comprobantes-row ' + params.tipo}>
                    {pagos.map((pago) => {
                        return (
                            <p
                                key={pago.cedula}
                                className='comprobante-pago'>
                                {nomina.tipo === "PROD" ? (
                                    <div className='text-center'>
                                        <div>
                                            <strong>COMPROBANTE DE PAGO</strong>
                                        </div>
                                        <div>
                                            <small>
                                                Fecha:{" "}
                                                {new Date().toLocaleString()}
                                            </small>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='text-center'>
                                        <div>
                                            <strong
                                                style={{
                                                    textTransform: "uppercase",
                                                }}>
                                                COMPROBANTE DE PAGO{" "}
                                                {nomina.sucursal.nombre}
                                            </strong>
                                        </div>
                                        <div>
                                            <small>
                                                {nomina.sucursal.direccion} •{" "}
                                                {nomina.sucursal.notas}
                                            </small>
                                        </div>
                                        <div>
                                            <small>
                                                Fecha:{" "}
                                                {new Date().toLocaleString()}
                                            </small>
                                        </div>
                                    </div>
                                )}
                                <br />
                                <div>
                                    <strong>Nombre:</strong> {pago.nombre}
                                </div>
                                {pago.codigo && (
                                    <div>
                                        <strong>Código:</strong> {pago.codigo}
                                    </div>
                                )}
                                <div>
                                    <strong>Cédula:</strong> {pago.cedula}
                                </div>
                                <div>
                                    <strong>Periodo:</strong>{" "}
                                    {nomina.fechaInicio} al {nomina.fechaCierre}
                                </div>
                                <br />
                                <table className='table'>
                                    <tbody>
                                        <tr>
                                            <th>Sueldo</th>
                                            <td className='text-right'>
                                                {formatCurrency(
                                                    pago.sueldoFijo
                                                )}
                                            </td>
                                        </tr>
                                        {nomina.tipo !== "INFORMALES" && pago.tss > 0 && (
                                            <tr>
                                                <th>TSS</th>
                                                <td className='text-right'>
                                                    -{formatCurrency(pago.tss)}
                                                </td>
                                            </tr>
                                        )}
                                        {pago.descuentos.length > 0 &&
                                            pago.descuentos.map(
                                                (desc, index) => (
                                                    <tr key={index}>
                                                        <th>{desc.nombre}</th>
                                                        <td className='text-right'>
                                                            -{formatCurrency(
                                                                desc.monto
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        {/* {pago.incentivos > 0 && (
                                            <tr>
                                                <th>Incentivos</th>
                                                <td className='text-right'>
                                                    {formatCurrency(
                                                        pago.incentivos
                                                    )}
                                                </td>
                                            </tr>
                                        )} */}
                                        {pago.amonestaciones > 0 && (
                                            <tr>
                                                <th>Descuentos</th>
                                                <td className='text-right'>
                                                    -{formatCurrency(
                                                        pago.amonestaciones
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        {pago.listadoIncentivos.length > 0 &&
                                            pago.listadoIncentivos.map(
                                                (incentivo, index) => (
                                                    <tr key={index}>
                                                        <th>{incentivo.descripcion}</th>
                                                        <td className='text-right'>
                                                            +{formatCurrency(
                                                                incentivo.monto
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        <tr>
                                            <th>Total</th>
                                            <td className='text-right'>
                                                {formatCurrency(
                                                    pago.sueldoFijo -
                                                        parseFloat(
                                                            pago.descuentosTotal
                                                        ) +
                                                        parseFloat(
                                                            pago.incentivos
                                                        )
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <hr />
                                <div className='text-center'>
                                    Firma Empleado
                                </div>
                            </p>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(Comprobantes));
