import React, { Component } from "react";
import { debounce } from "lodash";
import dayjs from "dayjs";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Loader from "../general/Loader";
import { formatCurrency, getLink, toast } from "../../utils/helpers";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { Link } from "react-router-dom";

class Gastos extends Component {
    state = {
        gastos: [],
        gasto: {},
        grupos: [],
        links: null,
        loading: true,
        showCreate: false,
        showDelete: false,
        showSidePopup: false,
        filtrosQuery: "",
        pin: '',
    };
    constructor(props) {
        super(props);
        this.sidePopupWrap = React.createRef();
        this.sidePopupOverlay = React.createRef();
    }
    componentDidMount() {
        this.getGastos();
        this.getTable("grupos");
    }
    getGastos = async (url = null) => {
        url = url ? url : "gastos?limit=20";
        const gastos = await this.props.request(url);

        if (gastos.data) {
            this.setState({
                gastos: gastos.data,
                meta: gastos.meta,
                links: gastos.links,
                loading: false,
            });
        }
    };
    getTable = async (table) => {
        const response = await this.props.request(table);

        if (response.data && response.data.length) {
            this.setState({
                [table]: response.data,
            });
        }
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        this.getGastosFiltrados();
    }, 500);

    getGastosFiltrados = async () => {
        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getGastos("gastos?limit=20&" + query.join("&"));
    };
    
    toggleDeleteModal = (gasto) => {
        this.setState({
            showDelete: !this.state.showDelete,
            gasto: gasto,
        });
    };
    changeItem = (activo) => {
        this.setState({
            activo,
        });
        this.toggleSidePopup(true);
    };
    deleteGasto = async (gasto) => {
        const token = auth.getToken();

        const url = `gastos/${gasto}?pin=${this.state.pin}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            }
        });

        const response = await res.json();

        this.setState({ pin: '' });

        if (response.success) {
            this.getGastosFiltrados();
            toast("Gasto Borrado.");
            return;
        } else {
            this.setState({ errors: response.data });
            toast(response.data, 'error');
        }
    };
    onChangePin = (e) => {
        this.setState({pin: e.target.value});
    }
    render() {
        const {
            gastos,
            showSidePopup,
            activo,
            loading,
            filtrosQuery,
            grupos,
        } = this.state;

        const itemActivo = gastos.filter((gasto) => gasto.id === activo)[0];

        return (
            <div>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Gastos Vehículos</span>
                        <Link
                            to={getLink(
                                `/imprimir/gastos-vehiculos${filtrosQuery}`
                            )}
                            target='_blank'
                            className='au-btn-filter m-r-15'>
                            <i className='zmdi zmdi-print' /> Imprimir
                        </Link>
                        <Link
                            className='au-btn au-btn-icon au-btn--green au-btn--small m-r-15'
                            to={getLink("/gastos/crear")}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Gastos
                        </Link>
                        {/* <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Gasto
                        </button> */}
                    </h1>
                </div>
                <div className='page-controls'>
                    <div className='table-data__tool'>
                        <div className='table-data__tool-left row'>
                            <div className='col-md-6'>
                                <label>Buscar</label>
                                <input
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "buscar"
                                        )
                                    }
                                    type='search'
                                    placeholder='Buscar...'
                                    className='form-control'
                                    name='buscar'
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='desde'>Desde</label>
                                <input
                                    type='date'
                                    name='desde'
                                    id='desde'
                                    defaultValue={dayjs()
                                        .startOf("day")
                                        .format("YYYY-MM-DD")}
                                    className='form-control'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "desde"
                                        )
                                    }
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='hasta'>Hasta</label>
                                <input
                                    type='date'
                                    name='hasta'
                                    id='hasta'
                                    defaultValue={dayjs()
                                        .endOf("day")
                                        .format("YYYY-MM-DD")}
                                    className='form-control'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "hasta"
                                        )
                                    }
                                />
                            </div>
                            <div className='col-md-2'>
                                <label htmlFor='grupo'>Grupo</label>
                                <select
                                    className='form-control'
                                    name='grupo'
                                    id='grupo'
                                    onChange={(e) =>
                                        this.onFiltroChange(
                                            e.target.value,
                                            "grupo"
                                        )
                                    }>
                                    <option value='todos'>Todos</option>
                                    {grupos.map((grupo) => (
                                        <option key={grupo.id} value={grupo.id}>
                                            {grupo.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!gastos.length ? (
                                <div>
                                    {!loading && (
                                        <div className='no-results'>
                                            Todavía no hay gastos creados en el
                                            rango de fechas seleccionado.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Vehículo</th>
                                                <th>Item</th>
                                                <th>Grupo</th>
                                                <th>Cantidad</th>
                                                <th>Monto</th>
                                                <th>Mecánico</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gastos.map((gasto) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={gasto.id}>
                                                    <td>
                                                        {dayjs(
                                                            gasto.fecha
                                                        ).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={getLink(
                                                                `/vehiculos/${gasto.vehiculoId}/informacion`
                                                            )}>
                                                            {gasto.vehiculo}
                                                        </Link>
                                                    </td>
                                                    <td>{gasto.item.nombre}</td>
                                                    <td>{gasto.grupo}</td>
                                                    <td>{gasto.cantidad}</td>
                                                    <td>
                                                        {formatCurrency(
                                                            gasto.cantidad *
                                                                gasto.precio
                                                        )}
                                                    </td>
                                                    <td>{gasto.mecanico}</td>
                                                    <td className='text-right'>
                                                        <button
                                                            type='submit'
                                                            onClick={(e) =>
                                                                this.toggleDeleteModal(gasto.id)
                                                            }
                                                            className='btn btn-danger btn-sm'>
                                                            <i className='fa fa-trash-alt'></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getGastos}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {showSidePopup && (
                    <div className='side-popup'>
                        <div
                            onClick={() => this.toggleSidePopup(false)}
                            ref={this.sidePopupOverlay}
                            className='side-popup-background animated fadeIn'></div>
                        <div
                            ref={this.sidePopupWrap}
                            className='side-popup-container animated slideInRight fast'>
                            <h2>
                                {itemActivo.nombre}{" "}
                                <button
                                    className='btn btn-primary float-right gasto'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title=''
                                    data-original-title='Edit'
                                    onClick={() => {
                                        this.toggleEditModal(itemActivo);
                                    }}>
                                    <i className='zmdi zmdi-edit' />
                                </button>
                            </h2>
                            <br />
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td>Encargado</td>
                                        <td>{itemActivo.encargado}</td>
                                    </tr>
                                    <tr>
                                        <td>Tipo</td>
                                        <td>{itemActivo.tipo}</td>
                                    </tr>
                                    <tr>
                                        <td>Teléfono</td>
                                        <td>{itemActivo.telefono}</td>
                                    </tr>
                                    <tr>
                                        <td>Celular</td>
                                        <td>{itemActivo.celular}</td>
                                    </tr>
                                    <tr>
                                        <td>Cédula/RNC</td>
                                        <td>{itemActivo.rnc}</td>
                                    </tr>
                                    <tr>
                                        <td>Dirección</td>
                                        <td>{itemActivo.direccion}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                <Modal
                    title='Borrar Gasto'
                    show={this.state.showDelete}
                    callback={() => this.deleteGasto(this.state.gasto)}
                    acceptText='Borrar'
                    toggle={this.toggleDeleteModal}>
                    Ingrese Código de seguridad:
                    <input type="text" name="pin" autoComplete="new-password" className="form-control pass-input" value={this.state.pin} onChange={this.onChangePin} />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Gastos);
