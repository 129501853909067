import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import ImageLoader from "../../general/ImageLoader";

class Form extends Component {
    render() {
        const { sucursal, sucursales, errors } = this.props;

        return (
            <div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Nombre:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre'
                            name='nombre'
                            defaultValue={sucursal.nombre}
                        />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Tipo:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <select
                            type='text'
                            className='text-input form-control'
                            id='tipo'
                            name='tipo'
                            defaultValue={sucursal.tipo}>
                            <option value='general'>General</option>
                            <option value='banca'>Banca</option>
                            <option value='almacen'>Almacén</option>
                            <option value='educativa'>Institución Educativa</option>
                        </select>
                        {errors.tipo && (
                            <small className='help-blockParams form-Text'>
                                {errors.tipo[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>RNC:</label>
                    </div>
                    <div className='col col-md-9'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='rnc'
                            name='rnc'
                            defaultValue={sucursal.rnc}
                        />
                        {errors.rnc && (
                            <small className='help-blockParams form-Text'>
                                {errors.rnc[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Teléfono:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='telefono'
                            name='telefono'
                            defaultValue={sucursal.telefono}
                        />
                        {errors.telefono && (
                            <small className='help-blockParams form-Text'>
                                {errors.telefono[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Direccion:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <textarea
                            type='text'
                            className='text-input form-control'
                            id='direccion'
                            name='direccion'
                            rows='4'
                            defaultValue={sucursal.direccion}
                        />
                        {errors.direccion && (
                            <small className='help-blockParams form-Text'>
                                {errors.direccion[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Notas:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <textarea
                            type='text'
                            className='text-input form-control'
                            id='notas'
                            name='notas'
                            rows='4'
                            defaultValue={sucursal.notas}
                        />
                        {errors.notas && (
                            <small className='help-blockParams form-Text'>
                                {errors.notas[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Sucursal Padre:
                        </label>
                    </div>
                    {sucursales.length > 0 && (
                        <div className='col col-md-9'>
                            <select
                                className='text-input form-control'
                                id='sucursal_padre'
                                name='sucursal_padre'
                                defaultValue={sucursal.sucursalPadre}>
                                <option value='0'>Sin padre</option>
                                {sucursales.map((sucursal) => (
                                    <option
                                        key={sucursal.id}
                                        value={sucursal.id}>
                                        {sucursal.nombre}
                                    </option>
                                ))}
                            </select>
                            {errors.sucursal_padre && (
                                <small className='help-blockParams form-Text'>
                                    {errors.sucursal_padre[0]}
                                </small>
                            )}
                        </div>
                    )}
                </div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>PIN Seguridad:</label>
                    </div>
                    <div className='col col-md-9'>
                        <input
                            type='password'
                            className='text-input form-control'
                            id='pin_seguridad'
                            name='pin_seguridad'
                            defaultValue={sucursal.pin_seguridad?.valor}
                        />
                    </div>
                </div>
                {auth.is("super-admin") && (
                    <div className='row form-group'>
                        <div className='col col-md-3'>
                            <label className='label form-control-label'>
                                Visible
                            </label>
                        </div>
                        <div className='col-md-9'>
                            <label className='switch switch-3d switch-success mr-3'>
                                <input
                                    type='checkbox'
                                    className='switch-input'
                                    name='mostrar'
                                    value='yes'
                                    defaultChecked={sucursal.mostrar}
                                />
                                <span className='switch-label' />
                                <span className='switch-handle' />
                            </label>
                        </div>
                    </div>
                )}
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Logo
                        </label>
                    </div>
                    <div className='col-md-9'>
                        <ImageLoader name="logo" imagenes={sucursal.logo ? sucursal.logo.map(logo => logo.url) : []} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
