import React, { Component } from "react";
import { debounce } from "lodash";
import Select from "react-select";
import { Link, Route, withRouter } from "react-router-dom";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../general/Loader";
import { formatCurrency, getLink } from "../../utils/helpers";
import dayjs from "dayjs";

class EntradasDiario extends Component {
    state = {
        entradasDiario: [],
        entradaDiario: {},
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        loading: true,
        filtros: {
            buscar: "",
        },
        filtrosQuery: '',
    };
    componentDidMount() {
        this.getEntradasDiario();
    }
    getEntradasDiario = async (url = null) => {
        url = url ? url + "&limit=20" : "entradas-diario?limit=20";
        const entradasDiario = await this.props.request(url);
        if (entradasDiario.data) {
            this.setState({
                entradasDiario: entradasDiario.data,
                links: entradasDiario.links,
                meta: entradasDiario.meta,
            });
        }
        this.setState({
            loading: false,
        });
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            entradaDiario: { operadores: [] },
        });
    };
    toggleEditModal = (entradaDiario) => {
        this.setState({
            showEdit: !this.state.showEdit,
            entradaDiario: entradaDiario,
        });
        this.props.history.push(getLink("/entradas-diario"));
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getEntradasDiario("entradas-diario?limit=20&" + query.join("&"));
    }, 500);
    render() {
        const { entradasDiario, loading, filtrosQuery } = this.state;
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Entradas de Diario</span>
                        <Link
                            to={getLink(`/imprimir/entradas-diario${filtrosQuery}`)}
                            target='_blank'
                            className='au-btn-filter m-r-15'>
                            <i className='zmdi zmdi-print' /> Imprimir
                        </Link>
                        <Link to={getLink(`/entradas-de-diario/crear`)}
                         className='au-btn au-btn-icon au-btn--green au-btn--small'>
                            <i className='zmdi zmdi-plus' />
                            Añadir Entrada de Diario
                        </Link>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='desde'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor='estado'>Estado</label>
                                    <Select
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "estado"
                                            )
                                        }
                                        options={[
                                            {label: 'Activa', value: 'Activa'},
                                            {label: 'Verificada', value: 'Verificada'},
                                            {label: 'Cancelada', value: 'Cancelada'},
                                        ]}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='desde'>Desde</label>
                                    <input
                                        type='date'
                                        name='desde'
                                        id='desde'
                                        defaultValue={dayjs()
                                            .startOf("day")
                                            .format("YYYY-MM-DD")}
                                        className='form-control'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "desde"
                                            )
                                        }
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='hasta'>Hasta</label>
                                    <input
                                        type='date'
                                        name='hasta'
                                        id='hasta'
                                        defaultValue={dayjs()
                                            .endOf("day")
                                            .format("YYYY-MM-DD")}
                                        className='form-control'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "hasta"
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {entradasDiario.length === 0 ? (
                                <div>
                                    {!loading && (
                                        <div>No hay entradas de diario creadas.</div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Referencia</th>
                                                <th>Observaciones</th>
                                                <th>Estado</th>
                                                <th>Total</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {entradasDiario.map((entradaDiario) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={entradaDiario.id}>
                                                    <td>{dayjs(entradaDiario.fecha).format('DD/MM/YYYY')}</td>
                                                    <td>{entradaDiario.referencia}</td>
                                                    <td>{entradaDiario.observaciones}</td>
                                                    <td>{entradaDiario.estado}</td>
                                                    <td>{formatCurrency(entradaDiario.debito)}</td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <Link
                                                                to={getLink(
                                                                    `/entradas-de-diario/${entradaDiario.id}`
                                                                )}
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Edit'
                                                                onClick={() => {
                                                                    this.toggleEditModal(
                                                                        entradaDiario
                                                                    );
                                                                }}>
                                                                <i className='zmdi zmdi-edit' />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getEntradasDiario}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Route
                    path='/:sucursal/entradasDiario/:id'
                    render={(props) => (
                        <Modal show blank toggle={this.toggleEditModal}>
                            <Edit
                                toggle={this.toggleEditModal}
                                entradaDiario={
                                    entradasDiario.filter(
                                        (r) =>
                                            r.id ===
                                            parseInt(props.match.params.id)
                                    )[0] || {}
                                }
                                getEntradasDiario={this.getEntradasDiario}
                            />
                        </Modal>
                    )}
                />
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        entradaDiario={this.state.entradaDiario}
                        getEntradasDiario={this.getEntradasDiario}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(EntradasDiario));
