import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";

class Create extends Component {
    state = {
        errors: {},
        ruta: {},
        saving: false,
    };
    createUser = async (event) => {
        event.preventDefault();

        this.setState({ saving: true });

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl("rutas"), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.getRutas();
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({ saving: false });
    };
    render() {
        const { errors, ruta, saving } = this.state;
        const { toggle } = this.props;
        return (
            <form method='post' onSubmit={this.createUser}>
                <div className='popup__header'>Nueva Zona</div>
                <div className='popup__body'>
                    <Form ruta={ruta} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Crear
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default Create;
