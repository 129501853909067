import React, { useState, useEffect } from "react";
import { getTable } from "../../../utils/request";
import Select from "react-select";

export default function ReporteItems() {
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        let ignore = false;

        async function fetchItems() {
            const data = await getTable("items", "tipo=PROD");
            if (!ignore) {
                setItems(data);
            }
        }

        fetchItems();
        return () => {
            ignore = true;
        };
    }, []);

    function onChangeItem(values) {
        if (values) {
            setSelectedItems(values.map(i => i.value));
        } else {
            setSelectedItems([]);
        }
    }

    return (
        <div>
            <div className='form-group m-b-80'>
                <label>Items:</label>
                <Select
                    isLoading={items.length === 0}
                    isMulti
                    loadingMessage={() => "Cargando Items.."}
                    onChange={onChangeItem}
                    options={items.map(item => ({
                        value: item.id,
                        label: `${item.referencia} - ${item.nombre} (${item.cantidad})`
                    }))}></Select>
                <input
                    type='hidden'
                    name='items'
                    required
                    value={selectedItems.join(",")}
                />
            </div>
        </div>
    );
}
