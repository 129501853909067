import React, { useEffect, useState } from "react";
import Loader from "../../general/Loader";
import { apiRequest } from "../../../hooks/apiRequest";
import { toast } from "../../../utils/helpers";
const AjusteManual = ({empleado, adquiridos, usados, disponibles, cancel}) => {
    const [valorAdquiridos, setValorAdquiridos] = useState(adquiridos);
    const [valorUsados, setValorUsados] = useState(usados);
    const [valorDisponibles, setValorDisponibles] = useState(disponibles);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const disponible = valorAdquiridos - valorUsados;
        setValorDisponibles(disponible < 0 ? 0 : disponible);
    }, [valorAdquiridos, valorUsados]);

    const onFormSubmit = (e) => {
        e.preventDefault();
    
        setLoading(true);

        const data = new FormData(e.target);
        data.append("_method", "put");

        const url = `empleados/${empleado.id}/ausencias/ajustar-manual`;
        apiRequest(data, url, "POST").then((response) => {
            if (response.success) {
                toast("Cambios realizados." , "success");
                cancel({ adquiridos: valorAdquiridos, usados: valorUsados});
            }

            setLoading(false);
        });
    };

    return <>
        <form onSubmit={onFormSubmit}>
            <div className='popup__header'>Ajuste manual de vacaciones</div>

            <div className='popup__body'>
                <div>
                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>Días Totales:</label>
                        </div>

                        <div className='col col-md-7'>
                            <input
                                type="number"
                                name="adquiridos"
                                className="form-control"
                                value={valorAdquiridos}
                                onChange={(e) => setValorAdquiridos(e.target.value)}
                                min={0}
                                step={1}
                            />
                        </div>
                    </div>

                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>Días Usados:</label>
                        </div>

                        <div className='col col-md-7'>
                            <input
                                type="number"
                                name="usados"
                                className="form-control"
                                value={valorUsados}
                                onChange={(e) => setValorUsados(e.target.value)}
                                min={0}
                                step={1}
                            />
                        </div>
                    </div>

                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>Días Disponible:</label>
                        </div>

                        <div className='col col-md-7'>
                            <input
                                type="number"
                                name="disponibles"
                                className="form-control"
                                value={valorDisponibles}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            </div>

            { loading
                ? <Loader />  
                : (<div className='popup__footer'>
                    <div className="row">
                        <button
                            type='button'
                            onClick={() => cancel({ adquiridos, usados })}
                            className='btn btn-plain mr-auto ml-2'>
                            Cancelar
                        </button>
                        <button type='submit' className='btn btn-primary mr-2'>
                            Salvar
                        </button>
                    </div>
                </div>)
            }
        </form>
    </>;
}
export default AjusteManual;