import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";

class Create extends Component {
    state = {
        errors: {},
        grupo: {}
    };
    createGrupo = async event => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl("grupos-tipos-empleados"), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.props.getGrupos();
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { errors, grupo } = this.state;
        const { toggle, tipos } = this.props;
        return (
            <form method='post' onSubmit={this.createGrupo}>
                <div className='popup__header'>Nuevo Grupo de Tipo de Empleados</div>
                <div className='popup__body'>
                    <p className="m-b-20">Con esta opción puedes agrupar diferentes tipos de empleados para generar nómina y reportes.</p>
                    <Form grupo={grupo} errors={errors} tipos={tipos} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Crear
                    </button>
                </div>
            </form>
        );
    }
}

export default Create;
