import dayjs from 'dayjs';
import React from 'react';

export default function FormModalidades({item, errors}) {
    return (<>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Nombre:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='text'
                    className='text-input form-control'
                    id='nombre'
                    name='nombre'
                    defaultValue={item.nombre}
                />
                {errors.nombre && (
                    <small className='help-blockParams form-Text'>
                        {errors.nombre[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Estado:
                </label>
            </div>
            <div className='col col-md-7'>
                <select className='form-control' name="estado" defaultValue={item.estado}>
                    <option value="activo">Activo</option>
                    <option value="pendiente">Pendiente</option>
                    <option value="inactivo">Inactivo</option>
                    <option value="cerrado">Cerrado</option>
                </select>
                {errors.estado && (
                    <small className='help-blockParams form-Text'>
                        {errors.estado[0]}
                    </small>
                )}
            </div>
        </div>
    </>)
}
