import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { auth } from "../../../../utils/auth";
import { formatCurrency } from "../../../../utils/helpers";
import { request } from "../../../../utils/request";

export const ReciboClaveEstudiante = () => {

    const { id } = useParams();
    const [state, setState] = useState({
        estudiante: {}
    });

    const getEstudiante = async () => {
        const response = await request(
            `academico/estudiantes/${id}`
        );

        if (response.data) {
            setState({
                ...state,
                estudiante: response.data,
            });
            //window.print();
        }
    };

    useEffect(() => {
        getEstudiante();
    }, [id])

    const { estudiante } = state;
    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
        (suc) => suc.id === user.sucursalActual
    )[0];

    return (<div className="p-2 invoice recibo-print">
        <div className='clear-both p-t-30 p-b-10'>
            <div className="float-sm-left sucursal-info">
                {sucursal.logo ? (<img alt="logo" className="mb-2 factura-logo" src={sucursal.logo[0].url} width="270px" />) : (
                    <h3 style={{ lineHeight: "12px", marginBottom: "3px" }}>
                        {sucursal.nombre}
                    </h3>
                )}
                <div
                    className="mb-0 mb-sm-0"
                    style={{ lineHeight: "15px", fontSize: "13px" }}
                >
                    RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
                </div>
                <div
                    className="mb-2 mb-sm-0"
                    style={{ lineHeight: "15px", fontSize: "13px" }}
                >
                    {sucursal.direccion}
                </div>
            </div>

            <div className="float-sm-right text-sm-right">
                <div
                    className="mb-2 invoice-fecha d-block"
                    style={{ fontSize: "small", lineHeight: "15px" }}
                >
                    Fecha: {dayjs(estudiante.fecha).format("DD/MM/YYYY")}
                </div>
                <h3
                    className="d-block"
                    style={{
                        fontSize: "15px",
                        lineHeight: "12px",
                        marginBottom: "3px",
                    }}
                >
                    Estudiante
                </h3>
                {estudiante.ncf && (
                    <div
                        className="d-block"
                        style={{ fontSize: "15px", lineHeight: "1.2" }}
                    >
                        <span>NCF: </span>
                        {estudiante.ncf}
                    </div>
                )}
                {estudiante.numeroFactura && (
                    <div
                        className="d-block"
                        style={{ fontSize: "15px", lineHeight: "1.2" }}
                    >
                        <span>No. Factura: </span>
                        {estudiante.numeroFactura}
                    </div>
                )}
            </div>
        </div>
        <div className="table-wrapper m-t-100">
            <table className='table'>
                <thead>
                    <tr>
                        <th>Matrícula</th>
                        <th>Estudiante</th>
                        <th>Usuario</th>
                        <th>Clave</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{estudiante.matricula}</td>
                        <td>{estudiante.nombreCompleto}</td>
                        <td>{estudiante.correo}</td>
                        <td>{estudiante.clave}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>);
}

export default ReciboClaveEstudiante;
