/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Loader from "../../general/Loader";
import PageHeader from "../../general/PageHeader";
import Pagination from "../../general/Pagination";
import { apiRequest } from "../../../hooks/apiRequest";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Link, useParams } from "react-router-dom";
import { formatCurrency, getLink, toast } from "../../../utils/helpers";
import { apiUrl } from "../../../utils/apiUrl";
import Select from "react-select";
import PrefixedInput from "../../general/PrefixedInput";
import Modal from "../../general/Modal";
import {
	ChequeInfo,
	DepositoInfo,
	OtroInfo,
	TarjetaInfo,
	TransferenciaInfo,
} from "../../Ingresos/Facturacion";
import FiltersContainer from "../../general/FiltersContainer";
import useFilters from "../../../hooks/useFilters";
import { METODOS_PAGO } from "../../../utils/types";
import { auth } from "../../../utils/auth";

const History = () => {
	const { id } = useParams();
	const formRef = useRef();
	const formInfoPagoRef = useRef();
	const [contacto] = useLocalStorage("contacto", "");

	const [transacciones, setTransacciones] = useState({});
	const [vendedores, setVendedores] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showAgregarPagoModal, setShowAgregarPagoModal] = useState(false);
	const [facturaSeleccionada, setFacturaSeleccionada] = useState({});
	const [pagoError, setPagoError] = useState({});
	const [printFacturaTipo] = useState("normal");
	const [showAgregarInfoPagoModal, setShowAgregarInfoPagoModal] =
		useState(false);
	const [pagoInfo, setPagoInfo] = useState({
		pago: 0,
		tipo_pago: "efectivo",
	});
	const { handleFilterChange, filtersQuery } = useFilters({
		buscar: "",
		tipo: "",
		estado: "",
		vendedor: "",
		desde: "",
		consultar: "",
		hasta: dayjs().format("YYYY-MM-DD"),
	});

	const getTransactions = (url = null) => {
		setLoading(true);
		fetch(url, {
			headers: {
				Accept: "application/json",
				"X-Request-With": "XMLHttpRequest",
				Authorization: "Bearer " + auth.getToken(),
			},
		})
			.then((res) => res.json())
			.then((response) => {
				setTransacciones(response.data);
			})
			.catch(() => {
				toast("Error obteniendo transacciones", "error");
			});

		setLoading(false);
	};

	const renderEstadoBadge = (estado) => {
		if (estado === "pendiente") {
			return <span className="badge badge-secondary p-2">Pendiente</span>;
		} else if (estado === "pagada") {
			return <span className="badge badge-success p-2">Pagada</span>;
		}

		return <span className="badge badge-danger p-2">Cancelada</span>;
	};

	const getVendedoresForDropdown = useCallback(() => {
		apiRequest(null, "vendedores?limit=100", "get").then((res) => {
			const vendedores = res.data.filter((vendedor) => vendedor != null);
			setVendedores([
				{ value: "", label: "Todos" },
				...vendedores.map((vendedor) => ({
					value: vendedor.id,
					label: `${vendedor.nombre} ${vendedor.apellido}`,
				})),
			]);
		});
	}, []);

	// const openPagarFacturaModal = (transaccion) => {
	// 	setFacturaSeleccionada(transaccion);
	// 	setPagoInfo((state) => ({ tipo_pago: transaccion.tipo_pago }));
	// 	setShowAgregarPagoModal(true);
	// };

	// const openInfoPagoModal = (transaccion, tipo) => {
	// 	setFacturaSeleccionada(transaccion);
	// 	setPrintFacturaTipo(tipo);
	// 	setShowAgregarInfoPagoModal(true);
	// };

	const setPorPagar = () => {
		setPagoInfo((state) => ({
			...state,
			pago: facturaSeleccionada.por_cobrar,
		}));
	};

	const guardarPago = async (event) => {
		event.preventDefault();

		const data = new FormData(event.target);

		const response = await apiRequest(
			data,
			`agregar-pago-factura/${facturaSeleccionada.id}`,
			"POST"
		);

		if (response.success) {
			toast("Pago Agregado", "success");
			setShowAgregarPagoModal(false);
			setFacturaSeleccionada({});
			setPagoInfo({ pago: null, tipo_pago: "efectivo" });
			getTransactions(
				apiUrl(`contactos/${id}/transacciones${filtersQuery}`)
			);
			return;
		} else if (response.code === 422) {
			setPagoError(response.data.message);
			return;
		}

		toast(response.data.message, "error");
	};

	const guardarInfoMetodoPago = async (event) => {
		event.preventDefault();

		const data = new FormData(event.target);

		const response = await apiRequest(
			data,
			`agregar-info-metodo-pago-factura/${facturaSeleccionada.id}`,
			"POST"
		);

		if (response.success) {
			if (printFacturaTipo === "normal") {
				window.open(
					getLink(`/imprimir/facturas/${facturaSeleccionada.id}`)
				);
			} else {
				window.open(
					getLink(
						`/imprimir/facturas/${facturaSeleccionada.id}/doble`
					)
				);
			}
			setShowAgregarInfoPagoModal(false);
			setFacturaSeleccionada({});
			return;
		} else if (response.code === 422) {
			setPagoError(response.data.message);
			return;
		}

		toast(response.data.message, "error");
	};

	useEffect(() => {
		getVendedoresForDropdown();
	}, []);

	useEffect(() => {
		if (filtersQuery === "") return;
		getTransactions(apiUrl(`contactos/${id}/transacciones${filtersQuery}`));
	}, [filtersQuery]);
	return (
		<>
			<PageHeader
				title={<Titulo contacto={contacto.nombre} />}
				hasButton={false}
			>
				<div className="col-3">
					<label
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Consultar Productos
					</label>
					<input
						onChange={(e) =>
							handleFilterChange("consultar", e.target.value)
						}
						type="search"
						placeholder="Consultar..."
						className="form-control"
						name="consultar"
					/>
				</div>
				<Link
					to={getLink(
						`/imprimir/contactos/${id}/transacciones${filtersQuery}`
					)}
					target="_blank"
					className="au-btn-filter m-r-15 ml-4"
				>
					<i className="zmdi zmdi-print" /> Imprimir
				</Link>
				{contacto.tipo?.includes("Proveedor") && (
					<Link
						to={getLink(`/contactos/${id}/compras`)}
						target="_blank"
						className="au-btn-filter m-r-15"
					>
						Historial Compras
					</Link>
				)}
				<Link
					className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15"
					to={`${getLink("/ingresos/facturas/crear")}`}
				>
					<i className="zmdi zmdi-plus" />
					Añadir Factura
				</Link>
			</PageHeader>
			<FiltersContainer>
				<div className="col">
					<label>Buscar</label>
					<input
						onChange={(e) =>
							handleFilterChange("buscar", e.target.value)
						}
						type="search"
						placeholder="Buscar..."
						className="form-control"
						name="buscar"
					/>
				</div>
				<div className="col-md-2">
					<label>Tipo</label>
					<select
						className="form-control"
						name="tipo"
						id="tipo"
						onChange={(e) =>
							handleFilterChange("tipo", e.target.value)
						}
					>
						<option value="">Todos</option>
						<option value="factura">Factura</option>
						<option value="orden-compra">Orden de Compra</option>
						<option value="cotizacion">Cotización</option>
					</select>
				</div>
				<div className="col-md-2">
					<label>Estado</label>
					<select
						className="form-control"
						name="estado"
						id="estado"
						onChange={(e) =>
							handleFilterChange("estado", e.target.value)
						}
					>
						<option value="">Todos</option>
						<option value="pendiente">Pendiente</option>
						<option value="pagada">Pagada</option>
						<option value="cancelada">Cancelada</option>
					</select>
				</div>
				<div className="col-md-2">
					<label>Vendedor</label>
					<Select
						id="vendedor"
						options={vendedores}
						name="vendedor"
						placeholder="Todos"
						onChange={(e) =>
							handleFilterChange("vendedor", e.value)
						}
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
				<div className="col-md-2">
					<label>Desde</label>
					<input
						className="form-control"
						type="date"
						name="desde"
						onChange={(e) =>
							handleFilterChange("desde", e.target.value)
						}
					/>
				</div>
				<div className="col-md-2">
					<label htmlFor="estado">Hasta</label>
					<input
						className="form-control"
						type="date"
						name="hasta"
						onChange={(e) =>
							handleFilterChange("hasta", e.target.value)
						}
						defaultValue={dayjs().format("YYYY-MM-DD")}
					/>
				</div>
			</FiltersContainer>
			<div className="row">
				<div className="col-md-12">
					<div>
						{loading && <Loader />}
						{!transacciones.data?.length ? (
							<div>
								{!loading && (
									<div>
										Todavía no hay transacciones creadas.
									</div>
								)}
							</div>
						) : (
							!loading && (
								<div className="table-data">
									<table className="table table-data2">
										<thead>
											<tr>
												<th>NCF/Número</th>
												<th>Creación</th>
												<th>Vencimiento</th>
												<th>Fecha Pagada</th>
												<th>Total</th>
												<th>Por Cobrar</th>
												<th>Vendedor</th>
												<th>Tipo</th>
												<th className="text-center">
													Estado
												</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{transacciones.data.map(
												(transaccion) => (
													<tr
														className="tr-shadow"
														key={transaccion.id}
													>
														<td>
															{
																transaccion.numero_comprobante
															}
														</td>
														<td>
															{dayjs(
																transaccion.created_at
															).format(
																"DD/MM/YYYY hh:mm a"
															)}
														</td>
														<td>
															{dayjs(
																transaccion.fecha_vencimiento
															).format(
																"DD/MM/YYYY"
															)}
														</td>
														<td>
															{transaccion.estado ===
															"pagada"
																? dayjs(
																		transaccion.fecha_pago ||
																			transaccion.updated_at
																  ).format(
																		"DD/MM/YYYY, hh:mm a"
																  )
																: ""}
														</td>
														<td>
															{formatCurrency(
																transaccion.total,
																"$"
															)}
														</td>
														<td>
															{formatCurrency(
																transaccion.por_cobrar,
																"$"
															)}
														</td>
														<td>
															{
																transaccion.nombre_vendedor
															}
														</td>
														<td className="text-capitalize">
															{transaccion.entidad &&
																transaccion.entidad
																	.split("-")
																	.join(
																		" de "
																	)}
														</td>
														<td className="text-center">
															{renderEstadoBadge(
																transaccion.estado
															)}
														</td>
														<td></td>
														<td>
															<div
																className="table-data-feature"
																style={{
																	justifyContent:
																		"flex-start",
																}}
															>
																{/* {renderActionsBtns(
																	transaccion
																)} */}
																<Link
																	to={getViewLink(
																		transaccion
																	)}
																	target="_blank"
																	className="item"
																	title="Impresión normal"
																>
																	<i className="fas fa-eye"></i>
																</Link>
															</div>
														</td>
													</tr>
												)
											)}
										</tbody>
									</table>
									{transacciones.links && (
										<Pagination
											links={{
												next: transacciones.next_page_url,
												prev: transacciones.prev_page_url,
											}}
											meta={transacciones}
											getData={getTransactions}
										/>
									)}
								</div>
							)
						)}
					</div>
				</div>
			</div>

			{/* AGREGAR PAGO MODAL */}
			<Modal
				title="Agregar Pago"
				show={showAgregarPagoModal}
				callback={() => {
					if (!pagoInfo.pago || pagoInfo.pago <= 0) {
						toast("Agregue el monto a pagar", "error");
						return;
					}
					formRef.current.dispatchEvent(
						new Event("submit", { bubbles: true, cancelable: true })
					);
				}}
				acceptText="Aceptar"
				toggle={() => {
					if (pagoInfo.pago <= 0) {
						toast("Agregue el monto a pagar", "error");
						return;
					}
					setPagoInfo({ pago: null, tipo_pago: "efectivo" });
					setShowAgregarPagoModal((state) => !state);
				}}
				size="419px"
			>
				<form method="post" onSubmit={guardarPago} ref={formRef}>
					<div className="form-group">
						<label>Tipo de pago</label>
						<select
							className="form-control"
							name="tipo_pago"
							value={pagoInfo.tipo_pago}
							onChange={(e) => {
								e.persist();
								setPagoInfo((state) => ({
									...state,
									terminal_tarjeta: "",
									tipo: "visa",
									banco: "",
									num_aprobacion: "",
									num_cheque: "",
									num_transferencia: "",
									num_transaccion: "",
									num_deposito: "",
									tipo_pago: e.target.value,
								}));
							}}
						>
							{METODOS_PAGO.map((metodo) => (
								<option value={metodo.value}>
									{metodo.label}
								</option>
							))}
						</select>
					</div>

					{pagoInfo.tipo_pago === "cheque" && (
						<ChequeInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}
					{pagoInfo.tipo_pago === "tarjeta" && (
						<TarjetaInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}
					{pagoInfo.tipo_pago === "transferencia" && (
						<TransferenciaInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}
					{pagoInfo.tipo_pago === "transferencia" && (
						<DepositoInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}
					{pagoInfo.tipo_pago === "otro" && (
						<OtroInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}

					<div className="form-group">
						<label>Monto Recibido</label>
						<PrefixedInput
							type="number"
							prefix="RD$"
							name="monto"
							value={pagoInfo.pago}
							setValue={(e) =>
								setPagoInfo((state) => ({ ...state, pago: e }))
							}
						/>
						<input
							type="hidden"
							min="0"
							className="form-control"
							defaultValue={formatCurrency(pagoInfo.pago)}
							onChange={(e) => {
								e.persist();
								setPagoInfo((state) => ({
									...state,
									pago: e.target.value,
								}));
							}}
						/>
						<input
							type="hidden"
							name="monto"
							value={pagoInfo.pago}
						/>
						{pagoError.monto && (
							<small className="help-blockParams form-Text">
								{pagoError.monto[0]}
							</small>
						)}
					</div>
					<div
						className="row"
						style={{ justifyContent: "space-around" }}
					>
						<div
							className="col-5 text-center"
							style={{
								border: "1px solid #cdd2d7",
								borderRadius: "7px",
								paddingTop: "8px",
								paddingBottom: "8px",
								cursor: "pointer",
							}}
							onClick={() => setPorPagar()}
						>
							<h5 className="mb-2">Balance:</h5>
							<p>
								{formatCurrency(facturaSeleccionada.por_cobrar)}
							</p>
						</div>
						<div
							className="col-5 text-center"
							style={{
								border: "1px solid #cdd2d7",
								borderRadius: "7px",
								paddingTop: "8px",
								paddingBottom: "8px",
							}}
						>
							<h5 className="mb-2">Devuelta:</h5>
							<p>
								{pagoInfo.pago >
								parseFloat(facturaSeleccionada.por_cobrar)
									? formatCurrency(
											pagoInfo.pago -
												facturaSeleccionada.por_cobrar
									  )
									: 0}
							</p>
						</div>
					</div>
				</form>
			</Modal>
			{/* AGREGAR INFORMACIÓN DE PAGO MODAL */}
			<Modal
				title="Agregar Información de pago"
				show={showAgregarInfoPagoModal}
				callback={() => {
					formInfoPagoRef.current.dispatchEvent(
						new Event("submit", { bubbles: true, cancelable: true })
					);
				}}
				acceptText="Aceptar"
				toggle={() => {
					setShowAgregarInfoPagoModal((state) => !state);
				}}
				size="419px"
			>
				<form
					method="post"
					onSubmit={guardarInfoMetodoPago}
					ref={formInfoPagoRef}
				>
					{facturaSeleccionada.tipo_pago === "cheque" && (
						<ChequeInfo factura={facturaSeleccionada} />
					)}
					{facturaSeleccionada.tipo_pago === "tarjeta" && (
						<TarjetaInfo factura={facturaSeleccionada} />
					)}
					{facturaSeleccionada.tipo_pago === "transferencia" && (
						<TransferenciaInfo factura={facturaSeleccionada} />
					)}
					{facturaSeleccionada.tipo_pago === "otro" && (
						<OtroInfo factura={facturaSeleccionada} />
					)}
				</form>
			</Modal>
		</>
	);
};

const getViewLink = (transaccion) => {
	if (transaccion.entidad === "orden-compra")
		return getLink(`/imprimir/ordenes-compra/${transaccion.id}`);

	if (transaccion.entidad === "cotizacion")
		return getLink(`/imprimir/cotizaciones/${transaccion.id}`);

	return getLink(`/imprimir/facturas/${transaccion.id}`);
};

const Titulo = ({ contacto }) => {
	return (
		<span style={{ fontSize: "1.6rem" }}>
			<div style={{ fontSize: "1.2rem" }}>
				Historial de transacciones de{" "}
			</div>
			<span style={{ fontWeight: "600", fontSize: "1.4rem" }}>
				{contacto}
			</span>
		</span>
	);
};

export default History;
