/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	useEffect,
	useState,
	useRef,
} from "react";
import Loader from "../../../general/Loader";
import { request } from "../../../../utils/request";
import {
	formatCurrency,
	getLink,
	getVendedoresForDropdown,
	money,
	toast,
} from "../../../../utils/helpers";
import { useHistory } from "react-router-dom";
import { apiRequest } from "../../../../hooks/apiRequest";

import Create from "../../../Contactos/Create";
import Edit from "../../../Contactos/Edit";
import Modal from "../../../general/Modal";
import ProductosOrden from "./ProductosOrden";
import ProductosOrdenMobile from "./ProductosOrdenMobile";
import Detalles from "./Detalles";
import { auth } from "../../../../utils/auth";
import dayjs from "dayjs";
import { calcularDescuento, calcularExentoGrabado, calcularImpuestos, calcularSubtotal, calcularTotal, calcularTotalImpuestos, templateProducto } from "../../Components/utils";

const Form = ({ ordenActual, isEdit }) => {
	const history = useHistory();

	const formRef = useRef();
    const [loading, setLoading] = useState(true);

	//Cosas generales como formulario
	const [form, setForm] = useState({
		orden: {},
		numero_orden: {},
		fecha: dayjs().format("YYYY-MM-DD"),
		fecha_vencimiento: dayjs().format("YYYY-MM-DD"),
		notas: "",
		tipo: "cliente",
	});

    const [contactoId, setContactoId] = useState();
    const [facturaProductos, setFacturaProductos] = useState([
        templateProducto(Date.now())
    ]);
    const [plazo, setPlazo] = useState({});
    const [errors, setErrors] = useState({});

	const [showCreateContactoModal, setShowCreateContactoModal] =
		useState(false);
	const [showEditContactoModal, setShowEditContactoModal] = useState(false);
	const [showCancel, setShowCancel] = useState(false);

	const [totalitbis, setTotalItbis] = useState(0);

	// Opciones de los Dropdown
	const [dropdownOptions, setDropdownOptions] = useState({
		contactos: [],
		terminos: [],
		productos: [],
		vendedores: [],
		rutas: [],
		grupos: [],
		impuestos: [],
	});

	const [saving, setSaving] = useState(false);
	const [createButtonClicked, setCreateButtonClicked] = useState("normal");
    const [pinSeguridad, setPinSeguridad] = useState("");

	//Calculos Finales
	const [sumatoriaFinal, setSumatoriaFinal] = useState({
		subtotal: 0,
		descuento: 0,
		impuestos: {},
		exentoGrabado: {},
		total: 0,
	});

	//Para llenar inputs de contacto (Cliente)
	const [contactoInfo, setContactoInfo] = useState({});

    const resetFacturaProductos = () => {
        setFacturaProductos([
            templateProducto(Date.now())
        ]);
    }

	const toggleCreateContactoModal = async (e, contacto = null) => {
		if (e) e.preventDefault();
		setShowCreateContactoModal((state) => !state);

		if (!contacto || !Object.keys(contacto).includes("id")) return;

		await getContactosForDropdown();

		setContactoId(contacto.id);
	};

	const toggleEditContactoModal = (e, contacto = null) => {
		if (e) e.preventDefault();
		setShowEditContactoModal((state) => !state);

		if (!contacto || !Object.keys(contacto).includes("id")) return;

		getContactosForDropdown();

		setContactoId(contacto.id);

		changeContactInfo(contacto.id);
	};

	const changeContactInfo = (contacto, clear = true) => {
		if (!contacto) return;

		request(`info-contacto/${contacto}`).then(({ data }) => {
			const vendedor =
				auth.is("vendedor") || auth.is("vendedor-factura-orden_compra") || auth.is("cobrador") || auth.is('vendedor-cliente')
					? {
							value: auth.getUser().empleado?.id,
							label: `${auth.getUser().empleado?.codigo} ${
								auth.getUser().empleado?.nombre
							} ${auth.getUser().empleado?.apellido}`,
					  }
					: {
							value: data.vendedor?.id,
							label: `${data.vendedor?.codigo} ${data.vendedor?.nombre} ${data.vendedor?.apellido}`,
					  };

            const contact = {
                ...data,
                vendedor: vendedor.value ? vendedor : null,
                ruta: data.ruta
                    ? { value: data.ruta?.id, label: data.ruta?.nombre }
                    : null,
                credito_disponible_actual: parseFloat(data.credito_disponible),
            };

			setContactoInfo(contact);


            if (clear) {
                changePlazo(dropdownOptions?.terminos[0]);
                resetFacturaProductos();
            }

            aplicarLimiteFecha();
		});
	};

	const handleInputChange = (value, field) => {
		setForm((form) => ({
			...form,
			[field]: value,
		}));
	};

	const handleDetalleChange = (value, field) => {
		setContactoInfo((state) => ({ ...state, [field]: value }));
	};

	const aplicarLimiteFecha = () => {
		if (!contactoInfo.plazo) return;

		return dayjs(form.fecha)
			.add(contactoInfo.plazo?.dias, "day")
			.format("YYYY-MM-DD");
	};

	// Funciones para traer data de los dropdowns
	const getDropdownData = () => {
		request("ordenes-compra/create").then((res) => {
			setDropdownOptions((options) => ({
				...options,
				// productos: res.data?.productos,
				rutas: res.data?.rutas,
				terminos: res.data?.terminos,
				impuestos: res.data?.impuestos,
			}));

			if (isEdit) return;

            changePlazo(res.data?.terminos[0]);

			setForm((state) => ({
				...state,
				numero_orden: {
					value: res.data.numero_orden?.id,
					label: `${res.data?.numero_orden?.tipo}${res.data?.numero_orden?.numero_siguiente}`,
					fecha_vencimiento:
						res.data?.numero_orden?.fecha_vencimiento,
				},
			}));
		});

		getVendedoresForDropdown().then((data) => {
			setDropdownOptions((options) => ({
				...options,
				vendedores: data.filter((vendedor) => vendedor.value !== ""),
			}));
		});
	};

	const getContactosForDropdown = () => {
		const url = isEdit
			? "contactos?limit=1000"
			: `contactos?limit=1000&tipo=${
					form.tipo === "interna" ? "proveedor" : "cliente"
			  }`;

		request(url).then((res) => {
			const contactos = [];
			if (
				auth.is("vendedor") ||
				auth.is("vendedor-factura-orden_compra") ||
				auth.is("cobrador") ||
				auth.is('vendedor-cliente')
			) {
				const rutasGuardadas = JSON.parse(
					localStorage.getItem("rutas")
				);

				for (let { value } of rutasGuardadas) {
					contactos.push(
						...res.data.filter(
							(contacto) => contacto.ruta?.id === value && (auth.is('vendedor-cliente') ? contacto.vendedor?.id === auth.getUser().empleado?.id : true)
						)
					);
				}
			}
			setDropdownOptions((options) => ({
				...options,
				contactos:
					auth.is("vendedor") ||
					auth.is("vendedor-factura-orden_compra") ||
					auth.is("cobrador") ||
					auth.is('vendedor-cliente')
						? contactos.map((contacto) => ({
								value: contacto.id,
								label: contacto.nombre,
						  }))
						: res.data.map((contacto) => ({
								value: contacto.id,
								label: contacto.nombre,
						  })),
			}));

            setLoading(false);
		});
	};

	const getProductosForDropdown = () => {
		request("productos").then((res) => {
			const almacenesGuardados = JSON.parse(localStorage.getItem("almacenes"));

			setDropdownOptions((options) => ({
				...options,
				productos: res.data.filter((producto) => {
					if (!auth.is('vendedor-cliente')) return true;

					for (let { value } of almacenesGuardados) {
						if (producto.almacen?.id === value) return true;
					}

					return false;
				}),
			}));

            setLoading(false);
		});
	};

	const updateTotal = (
		id = null,
		subtotal = null,
		total = 0,
		impuestos = 0,
		descuento = 0,
		impuesto,
        productoInfo = {},
	) => {
		const nproductos = facturaProductos?.map((producto) => {
            if (producto.id === id) {
                producto.producto_id = productoInfo.id;
                producto.importe = subtotal / producto.cantidad
                producto.descuento = descuento;
                producto.subtotal = subtotal;
                producto.impuesto = impuesto;
                producto.impuestos = impuestos;
                producto.total = total;
                if (productoInfo.precio_manual || productoInfo.precio_venta) {
                    producto.precio_facturado = parseFloat(productoInfo.precio_manual ?? productoInfo.precio_venta?.value);
                }
                producto.cantidadAnterior = productoInfo.cantidadAnterior;
            }
            return producto;
        });

        setFacturaProductos(nproductos);

        updateSumatoriaFinal(nproductos, contactoInfo);
	};

	const addProducto = () => {
        setFacturaProductos(prev => [
            ...prev,
            templateProducto(Date.now())
        ])
	};

	const removeProducto = async (index) => {
        const newProds = facturaProductos.filter(
            (i) => i.id !== index
        );
        setFacturaProductos(newProds);
        updateSumatoriaFinal(newProds, contactoInfo);
	};

	const cancel = () => {
		history.push(getLink("/ingresos/ordenes-compra"));
	};

	const guardarYNuevaOrden = async () => {
		await setCreateButtonClicked("nueva");

		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const guardarYFacturar = async () => {
		await setCreateButtonClicked("facturar");

		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const guardarYSalir = async () => {
		await setCreateButtonClicked("normal");

		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};
	const guardarEImprimir = async () => {
		await setCreateButtonClicked("imprimir");

		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const createOrden = async (event) => {
		event.preventDefault();

		setForm((state) => ({ ...state, errors: {} }));

		setSaving(true);
		const data = new FormData(event.target);

		const response = await apiRequest(data, "ordenes-compra", "POST");

		if (response.success) {
			toast("Orden creada", "success");
			if (createButtonClicked === "normal") {
				history.push(getLink("/ingresos/ordenes-compra"));
			} else if (createButtonClicked === "imprimir") {
				window.open(
					getLink(`/imprimir/ordenes-compra/${response.data.orden}`)
				);
				history.push(getLink("/ingresos/ordenes-compra"));
			} else if (createButtonClicked === "facturar") {
				localStorage.setItem("fromOrden", JSON.stringify("si"));
				history.push(
					getLink("/ingresos/facturas/crear/") + response.data?.orden
				);
			}
			resetFields();
            getDropdownData();
		}
		if (response.code === 422) {
			setForm((state) => ({ ...state, errors: response.data.message }));
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}

		setSaving(false);
	};

	const editOrden = async (event) => {
		event.preventDefault();

		setErrors({});

		setSaving(true);
		const data = new FormData(event.target);
		data.append("_method", "put");

		const response = await apiRequest(
			data,
			`ordenes-compra/${ordenActual.id}`,
			"POST"
		);

		if (response.success) {
			toast("Orden actualizada", "success");
			if (createButtonClicked === "normal") {
				history.push(getLink("/ingresos/ordenes-compra"));
			} else if (createButtonClicked === "imprimir") {
				window.open(
					getLink(`/imprimir/ordenes-compra/${response.data.orden}`)
				);
				history.push(getLink("/ingresos/ordenes-compra"));
			} else if (createButtonClicked === "facturar") {
				localStorage.setItem("fromOrden", JSON.stringify("si"));
				history.push(
					getLink("/ingresos/facturas/crear/") + response.data?.orden
				);
			}
			resetFields();
		}
		if (response.code === 422) {
			setErrors(response.data.message);
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}

		setSaving(false);
	};

	const resetFields = () => {
		setCreateButtonClicked("normal");
		setContactoInfo({});
		setForm({
            numero_orden: {},
			fecha: dayjs().format("YYYY-MM-DD"),
			fecha_vencimiento: dayjs().format("YYYY-MM-DD"),
			notas: "",
		});

        setErrors({});
        setContactoId(null);
        resetFacturaProductos();
        changePlazo({ value: 0, label: "Seleccione...", dias: 0 });
        setPinSeguridad("");

		window.scrollTo(0, 0);
	};

	const toggleCancelModal = () => {
		setShowCancel((state) => !state);
	};

	const cancelOrden = async (ordenID) => {
		setShowCancel((state) => !state);
		setErrors({});

		setSaving(true);

		const response = await apiRequest(
			null,
			`ordenes-compra/${ordenID}`,
			"DELETE"
		);

		if (response.success) {
			toast("Orden Cancelada", "success");
			history.push(getLink("/ingresos/ordenes-compra"));
			return;
		}
		toast("Error cancelando orden", "error");

		setSaving(false);
	};

	const setEditInfo = async () => {
		await setContactoInfo({
			...ordenActual.cliente,
			vendedor: ordenActual.vendedor && {
				value: ordenActual.vendedor.id,
				label: `${ordenActual.vendedor?.nombre} ${ordenActual.vendedor?.apellido}`,
			},
			ruta: ordenActual.ruta
				? {
						value: ordenActual.ruta?.id,
						label: ordenActual.ruta?.nombre,
				  }
				: null,
			credito_disponible_actual: ordenActual.cliente?.credito_disponible,
		});

        setFacturaProductos(ordenActual.productos);

        setContactoId(ordenActual.cliente?.id);
        changeContactInfo(ordenActual.cliente?.id, false);

        changePlazo({
            value: ordenActual.plazo?.id || ordenActual.plazo?.value,
            label: ordenActual.plazo?.nombre || ordenActual.plazo?.label,
            dias: ordenActual.plazo?.dias,
        });

		setForm((form) => ({
			...form,
			numero_orden: {
				value: ordenActual.numeracion_id,
				label: ordenActual.numero_comprobante,
			},
			fecha: dayjs(ordenActual.fecha).format("YYYY-MM-DD"),
			fecha_vencimiento: dayjs(ordenActual.fecha_vencimiento).format(
				"YYYY-MM-DD"
			),
			notas: ordenActual.notas,
			tipo: ordenActual.tipo,
		}));
	};

    const changePlazo = (plazo) => {
        let vencimiento = dayjs(form.fecha);

        setPlazo(plazo);

        if (form.fecha) {
            if (plazo.dias === null) return;

            vencimiento = vencimiento.add(plazo.dias, "day");

            handleInputChange(
                vencimiento.format("YYYY-MM-DD"),
                "fecha_vencimiento"
            );
        }
    }

    const updateSumatoriaFinal = (productos, contacto) => {
        const total = !isNaN(calcularTotal(productos))
			? calcularTotal(productos)
			: 0;

		setSumatoriaFinal((state) => ({
			...state,
			subtotal: calcularSubtotal(productos),
			descuento: calcularDescuento(productos),
			impuestos: calcularImpuestos(productos),
			exentoGrabado: calcularExentoGrabado(productos),
            total: total,
		}));

		setTotalItbis(calcularTotalImpuestos(productos));

        if (Object.keys(contacto).length >= 0) {
			setContactoInfo((state) => ({
				...state,
				credito_disponible: money(
					state.credito_disponible_actual - (total - form.prevTotal)
				),
			}));
		}
    }

	useEffect(() => {
        getDropdownData();
		getContactosForDropdown();
		getProductosForDropdown();
	}, []);

	useEffect(() => {
		if (!isEdit) return;
		setEditInfo();
	}, [ordenActual, ordenActual.productos]);

    if (loading) {
		return <Loader />;
	}

	return (
		<>
			<form
				method="post"
				onSubmit={(e) => {
					isEdit ? editOrden(e) : createOrden(e);
				}}
				ref={formRef}
			>
				<input
					type="hidden"
					name="id"
					value={ordenActual.id}
					onChange={() => {}}
				/>
				<div className="crear-compra">
					<div className="page-controls">
						<Detalles
							form={form}
							contactoInfo={contactoInfo}
                            contactoId={contactoId}
                            setContactoId={setContactoId}
                            plazo={plazo}
                            changePlazo={changePlazo}
							dropdownOptions={dropdownOptions}
							toggleCreateContactoModal={
								toggleCreateContactoModal
							}
                            changeContactInfo={changeContactInfo}
							toggleEditContactoModal={toggleEditContactoModal}
							handleInputChange={handleInputChange}
							aplicarLimiteFecha={aplicarLimiteFecha}
							handleDetalleChange={handleDetalleChange}
							isEdit={isEdit}
							tipoOrden={form.tipo}
                            errors={errors}
						/>
					</div>
					<hr />
					<div className="col-md-12 p-0">
						<table className="table d-none d-lg-table">
							<thead>
								<tr>
									<th width="170px">REF./CÓDIGO</th>
									<th width="23%">Descripción</th>
									<th width="85px">Cant.</th>
									<th width="85px">Unidad</th>
									<th width="9%">Importe</th>
									<th width="160px">Impuesto</th>
									<th width="180px">Precio</th>
									<th width="78px">% Desc.</th>
									<th className="text-right" width="9%">
										Total
									</th>
									<th className="text-center" width="5%">
										Fotos
									</th>
									<th width="85px"></th>
								</tr>
							</thead>
							<tbody>
								{facturaProductos &&
									facturaProductos.map(
										(producto, index) => (
											<ProductosOrden
												key={producto.id}
												producto={producto}
												index={index}
												removeProducto={removeProducto}
												productos={
													dropdownOptions.productos
												}
												updateTotal={updateTotal}
												precioLimite={
													contactoInfo.precio_venta
												}
												banderaContacto={
													contactoInfo.bandera
												}
												impuestosForDropdown={
													dropdownOptions.impuestos
												}
												facturaProductos={
													facturaProductos
												}
												isEdit={isEdit}
												tipoOrden={form.tipo}
												errors={errors}
												contacto={contactoInfo.id}
											/>
										)
									)}
								<tr>
									<td
										colSpan="11"
										style={{ border: 0, paddingTop: 0 }}
										className="text-right"
									>
										<button
											type="button"
											onClick={addProducto}
											className="btn"
										>
											<i className="fas fa-plus" />{" "}
											Agregar Producto
										</button>
									</td>
								</tr>
                                {sumatoriaFinal.descuento > 0 ? (
                                    <tr>
                                        <td colSpan="9" className="text-right">
                                            <strong className="label form-control-label">
                                                Descuento:
                                            </strong>
                                        </td>
                                        <td colSpan="2" className="text-right">
                                            <input
                                                type="hidden"
                                                name="descuento"
                                                value={
                                                    sumatoriaFinal.descuento || 0
                                                }
                                                readOnly
                                            />
                                            -
                                            {formatCurrency(
                                                money(sumatoriaFinal.descuento)
                                            )}
                                            <input
                                                type="hidden"
                                                name="impuestos"
                                                value={totalitbis}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                ) : (
                                    <tr className="d-none">
                                        <td></td>
                                        <td><input type="hidden" name="descuento" value="0" /></td>
                                    </tr>
                                )}
								<tr>
									<td colSpan="9" className="text-right">
										<strong className="label form-control-label">
											Subtotal:
										</strong>
									</td>
									<td colSpan="2" className="text-right">
										<input
											type="hidden"
											name="subtotal"
											value={sumatoriaFinal.subtotal || 0}
											readOnly
										/>
										{formatCurrency(
											money(sumatoriaFinal.subtotal)
										)}
									</td>
								</tr>
								{Object.keys(sumatoriaFinal.impuestos).map(
									(impuesto, index) => (
										<tr key={index}>
											<td
												colSpan="9"
												className="text-right"
											>
												<strong className="label form-control-label">
													{impuesto}
												</strong>
											</td>
											<td
												colSpan="2"
												className="text-right"
											>
												<input
													type="hidden"
													name={`impuesto-${index}`}
													value={
														sumatoriaFinal
															.impuestos[impuesto]
													}
													readOnly
												/>
												{formatCurrency(
													money(
														sumatoriaFinal
															.impuestos[impuesto]
													)
												)}
											</td>
										</tr>
									)
								)}
								<tr>
									<td colSpan="9" className="text-right">
										<strong className="label form-control-label">
											Total:
										</strong>
									</td>
									<td colSpan="2" className="text-right">
										<input
											type="hidden"
											name="pagado"
											value="0"
											readOnly
										/>
										<input
											type="hidden"
											name="total"
											value={money(sumatoriaFinal.total)}
											readOnly
										/>
										{formatCurrency(
											money(sumatoriaFinal.total)
										)}
									</td>
								</tr>
                                {errors.total && (
                                    <tr>
                                        <td colSpan="11" className="text-right">
                                            <small className="help-blockParams form-Text">
                                                {errors.total[0]}
                                            </small>
                                        </td>
                                    </tr>
                                )}
							</tbody>
						</table>
						{window.screen.width <= 992 && (
							<div className="d-block d-lg-none">
								{facturaProductos &&
									facturaProductos.map(
										(producto, index) => (
											<ProductosOrdenMobile
												key={producto.id}
												producto={producto}
												index={index}
												removeProducto={removeProducto}
												productos={
													dropdownOptions.productos
												}
												updateTotal={updateTotal}
												precioLimite={
													contactoInfo.precio_venta
												}
												banderaContacto={
													contactoInfo.bandera
												}
												impuestosForDropdown={
													dropdownOptions.impuestos
												}
												facturaProductos={
													facturaProductos
												}
												isEdit={isEdit}
												tipoOrden={form.tipo}
												contacto={contactoInfo.id}
                                                errors={errors}
											/>
										)
									)}
								{errors.productos && (
									<small className="help-blockParams form-Text">
										{errors.productos[0]}
									</small>
								)}
								<div className="d-flex justify-content-end mb-3">
									<button
										type="button"
										onClick={addProducto}
										className="btn"
										style={{
											border: "1px solid #FE9D1F",
											color: "#6c757d",
										}}
									>
										<i className="fas fa-plus" /> Agregar
										Producto
									</button>
								</div>
								<div className="row px-4 justify-content-between">
									<strong className="label form-control-label">
										Subtotal:
									</strong>
									<span>
										{formatCurrency(
											money(sumatoriaFinal.subtotal)
										)}
									</span>
								</div>
								{Object.keys(sumatoriaFinal.impuestos).map(
									(impuesto, index) => (
										<div
											className="row px-4 justify-content-between"
											key={index}
										>
											<strong className="label form-control-label">
												{impuesto}
											</strong>

											{formatCurrency(
												money(
													sumatoriaFinal.impuestos[
														impuesto
													]
												)
											)}
										</div>
									)
								)}
                                {sumatoriaFinal.descuento > 0 && (
                                    <div className="row px-4 justify-content-between">
                                        <strong className="label form-control-label">
                                            Descuentos:
                                        </strong>
                                        <span>
                                            -
                                            {formatCurrency(
                                                money(sumatoriaFinal.descuento)
                                            )}
                                        </span>
                                    </div>
                                )}
								<div className="row px-4 justify-content-between">
									<strong className="label form-control-label">
										Total:
									</strong>
									<span>
										{formatCurrency(
											money(sumatoriaFinal.total)
										)}
									</span>
								</div>
							</div>
						)}
						<hr />
					</div>
					<div
						className="form-group col-md-12"
						style={{ marginBottom: "0.3rem" }}
					>
						<label className="label form-control-label">
							notas:
						</label>
						<textarea
                            className="text-input form-control"
                            id="notas"
                            name="notas"
                            rows={4}
                            value={form.notas}
                            onChange={e => handleInputChange(e.target.value, 'notas')}
                        />
						{errors.notas && (
							<small className="help-blockParams form-Text">
								{errors.notas[0]}
							</small>
						)}
					</div>
					{/* DESKTOP FOOTER */}
					<div className="page-footer d-none d-lg-block text-right">
						<button
							type="button"
							onClick={cancel}
							className="btn btn-plain"
						>
							Volver
						</button>
						{saving ? (
							<Loader type="small" />
						) : (
							<>
								{isEdit && (
									<button
										type="button"
										onClick={() => toggleCancelModal()}
										className="btn btn-outline-danger mr-2"
									>
										Cancelar Orden
									</button>
								)}
								{form.tipo !== "interna" && (
									<button
										type="button"
										onClick={() => guardarYFacturar()}
										className="btn btn-outline-primary mr-2"
									>
										Guardar y facturar
									</button>
								)}
								{!isEdit && (
									<button
										type="button"
										onClick={() => guardarYNuevaOrden()}
										className="btn btn-outline-primary mr-2"
									>
										Guardar y crear nueva
									</button>
								)}
								<button
									type="button"
									onClick={() => guardarYSalir()}
									className="btn btn-outline-primary"
								>
									Guardar
								</button>
								<button
									type="button"
									onClick={() => guardarEImprimir()}
									className="btn btn-primary"
								>
									Guardar e imprimir
								</button>
							</>
						)}
					</div>
					{/* MOBILE FOOTER */}
					<div className="page-footer d-block d-lg-none">
						{!saving && (
							<div className="row justify-content-center">
								<button
									type="button"
									onClick={() => guardarEImprimir()}
									className="btn btn-primary col-11 col-sm-5 mb-2 mb-sm-0"
								>
									Guardar e imprimir
								</button>
								<button
									type="button"
									onClick={() => guardarYSalir()}
									className="btn btn-outline-primary col-11 col-sm-5"
								>
									Guardar
								</button>
							</div>
						)}
						<div className="row justify-content-center">
							{saving ? (
								<Loader type="small" />
							) : (
								<>
									{!isEdit && (
										<button
											type="button"
											onClick={() => guardarYNuevaOrden()}
											className="btn btn-outline-primary col-11 col-sm-5 mt-2"
										>
											Guardar y crear nueva
										</button>
									)}
									{isEdit && (
										<button
											type="button"
											onClick={() => toggleCancelModal()}
											className="btn btn-outline-danger col-11 col-sm-5 mt-2"
										>
											Cancelar Orden
										</button>
									)}
								</>
							)}
							<button
								type="button"
								onClick={cancel}
								className="btn btn-plain col-11 col-sm-5 mt-2"
							>
								Volver
							</button>
						</div>
					</div>
				</div>
			</form>
			<Modal
				show={showCreateContactoModal}
				blank={true}
				toggle={toggleCreateContactoModal}
				size="lg"
			>
				<Create toggle={toggleCreateContactoModal} fromFacturacion />
			</Modal>
			<Modal
				show={showEditContactoModal}
				blank={true}
				toggle={toggleEditContactoModal}
				size="lg"
			>
				<Edit
					toggle={toggleEditContactoModal}
					contacto={contactoInfo}
					fromFacturacion
				/>
			</Modal>
			<Modal
				title="Cancelar Orden"
				show={showCancel}
				callback={() => cancelOrden(ordenActual.id)}
				acceptText="Aceptar"
				toggle={toggleCancelModal}
			>
				¿Estás seguro de que deseas cancelar esta orden? (Esta acción no
				se puede deshacer).
			</Modal>
		</>
	);
};

export default Form;
