import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";

class Edit extends Component {
    state = {
        errors: {}
    };
    editUser = async event => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl(`usuarios/${this.props.usuario.id}`), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.props.getUsers();
            this.props.toggle();
            toast("Usuario Actualizado.");
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    deleteUsuario = async (event, usuario) => {
        event.preventDefault();

        const token = auth.getToken();

        const url = `usuarios/${usuario}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.props.getUsers();
            this.props.toggle();
            toast("Usuario borrado.");
            return;
        } else {
            toast(response.data, "error");
        }
    };
    render() {
        const { errors } = this.state;
        const { toggle, usuario, sucursales } = this.props;
        return (
            <form method='post' onSubmit={this.editUser}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>Editar Usuario</div>
                <div className='popup__body'>
                    <Form usuario={usuario} errors={errors} sucursales={sucursales} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={e => this.deleteUsuario(e, usuario.id)}>
                        <i className='fas fa-trash-alt'></i>
                    </button>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default Edit;
