import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";

const months = {
	'01': "Enero",
	'02': "Febrero",
	'03': "Marzo",
	'04': "Abril",
	'05': "Mayo",
	'06': "Junio",
	'07': "Julio",
	'08': "Agosto",
	'09': "Septiembre",
	'10': "Octubre",
	'11': "Noviembre",
	'12': "Diciembre",
};

class Cumpleanios extends Component {
	state = {
		empleados: [],
		empleadosByMonth: {},
	};
	componentDidMount() {
		this.getEmpleados();
	}
	getEmpleados = async () => {
		const searchString =
			window.location.search.length > 0
				? window.location.search + "&is_cumpleanio=true"
				: "?is_cumpleanio=true";
		const response = await this.props.request("empleados" + searchString);

		if (response.data) {
			this.setState({ empleados: response.data });
			this.groupByMonth(response.data);
			setTimeout(() => {
				window.print();
			}, 600);
		}
	};

	groupByMonth = (data) => {
		let empleadosByMonth = {
			Enero: [],
			Febrero: [],
			Marzo: [],
			Abril: [],
			Mayo: [],
			Junio: [],
			Julio: [],
			Agosto: [],
			Septiembre: [],
			Octubre: [],
			Noviembre: [],
			Diciembre: [],
		};

		if (data) {
			data.forEach((empleado) => {
				const employeeMonth = dayjs(empleado.fechaNacimiento).format("MM");
				if (empleadosByMonth[months[employeeMonth]]) {
					empleadosByMonth[months[employeeMonth]].push(empleado);
				}
			});
		}

		this.setState({ empleadosByMonth });
	};

	// padTo2Digits = (num) => {
	// 	return num.toString().padStart(2, "0");
	// };

	formatDate = (date) => {
		return dayjs(date).format("DD/MM/YYYY");
	};

	getAge = (birthDate) => Math.abs(dayjs(birthDate).diff(dayjs(), "y"));

	render() {
		const { empleados, empleadosByMonth } = this.state;
		const user = auth.getUser();
		const sucursal = user.sucursales.filter(
			(suc) => suc.id === user.sucursalActual
		)[0];

		return (
			<div>
				<div className="row">
					<h2 className="col-md-12 text-center m-b-5">
						Reporte Cumpleaños de Empleados
					</h2>
					<div className="col-md-12 text-center">
						{sucursal.nombre} - {sucursal.direccion} •{" "}
						{new Date().toLocaleString()}
					</div>
				</div>
				{Object.values(empleadosByMonth).map((mes, index) => (
					<>
						<div className="row">
							<div className="col-md-12 text-right p-b-10">
								<div
									className="float-left font-weight-bold text-uppercase"
									style={{ fontSize: "medium" }}
								>
									{index < 9 ? months['0' + (index+1)] : months[index+1]}
								</div>
							</div>
						</div>
						{mes.length <= 0 ? (
							<h3
								style={{
									textTransform: "inherit",
									marginBottom: "10px",
									marginTop: "-10px",
									fontSize: "0.9rem",
									fontWeight: 400,
								}}
							>
								No hay cumpleaños
							</h3>
						) : (
							<table className="table m-b-20">
								<thead style={{fontSize: '1.28rem'}}>
									<tr>
										<th width="20%">Código</th>
										<th width="30%">Nombre</th>
										<th width="30%">Teléfono</th>
										<th width="25%">Cumpleaños</th>
										<th width="20%" className="text-right">Años</th>
									</tr>
								</thead>
								<tbody style={{fontSize: 'medium'}}>
									{mes.map((empleado) => {
										return (
											<tr key={empleado.id}>
												<td>{empleado.codigo}</td>
												<td>
													{empleado.nombre}{" "}
													{empleado.apellido}
												</td>
												<td>{empleado.telefono}</td>
												<td>
													{this.formatDate(
														empleado.fechaNacimiento
													)}
												</td>
												<td className="text-right">
													{this.getAge(
														empleado.fechaNacimiento
													)}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						)}
					</>
				))}
				<div className="row">
					<div className="col-md-12 text-right p-b-10">
						<div className="float-left">
							TOTAL EMPLEADOS: {empleados.length}
						</div>

						<div className="float-right">
							<h3>EMPLEADOS</h3>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withRequest(Cumpleanios));
