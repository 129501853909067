import dayjs from "dayjs";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { auth } from "../../../../utils/auth";
import { withRequest } from "../../../../utils/request";


class PrintInscripciones extends Component {
    state = {
        inscripciones: [],
    };
    componentDidMount() {
        this.getEmpleados();
    }
    getEmpleados = async () => {
        const response = await this.props.request(
            "academico/inscripciones" + window.location.search
        );

        if (response.data) {
            this.setState({
                inscripciones: response.data,
            });
            window.print();
        }
    };
    render() {
        const { inscripciones } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Inscripciones
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL INSCRIPCICONES: {inscripciones.length}
                        </div>

                        <div className='float-right'>
                            <h3>INSCRIPCICONES</h3>
                        </div>
                    </div>
                </div>
                <table className='table m-b-40'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nombre</th>
                            <th>Fecha</th>
                            <th>Curso</th>
                            <th>Año</th>
                            <th>Teléfono</th>
                            <th>Pago</th>
                            <th>Procedencia</th>
                            <th>Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inscripciones.map((inscripcion, index) => {
                            return (
                                <tr key={inscripcion.id}>
                                    <td>{index+1}</td>
                                    <td>{inscripcion.nombreCompleto}</td>
                                    <td>{dayjs(inscripcion.fecha).format('DD-MM-YYYY')}</td>
                                    <td>{inscripcion.grado?.nombre}</td>
                                    <td>{inscripcion.periodoAcademico?.nombre}</td>
                                    <td>{inscripcion.telefono}</td>
                                    <td>{inscripcion.pago}</td>
                                    <td>{inscripcion.procedencia}</td>
                                    <td>{inscripcion.estado}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='row'>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL INSCRIPCICONES: {inscripciones.length}
                        </div>

                        <div className='float-right'>
                            <h3>INSCRIPCICONES</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintInscripciones));
