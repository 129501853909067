import React, { useState } from 'react';
import Select from 'react-select';

export default function FormPagosEducativos({item, gruposPagos, grados, errors}) {
    const [activeTab, setActiveTab] = useState('regulares');
    return (<>
        <div className="row">
            <div className="col">
                <div className='row form-group'>
                    <div className='col col-md-8'>
                        <label className='label form-control-label'>
                            Nombre:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre'
                            name='nombre'
                            defaultValue={item.nombre}
                        />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Cuotas:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='cuotas'
                            name='cuotas'
                            defaultValue={item.cuotas}
                        />
                        {errors.cuotas && (
                            <small className='help-blockParams form-Text'>
                                {errors.cuotas[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-6'>
                        <label className='label form-control-label'>
                            Fecha Inicio:
                        </label>
                        <input
                            type='date'
                            className='text-input form-control'
                            id='fecha_inicio'
                            name='fecha_inicio'
                            defaultValue={item.fechaInicio}
                        />
                        {errors.fecha_inicio && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha_inicio[0]}
                            </small>
                        )}
                    </div>
                    <div className='col col-md-6'>
                        <label className='label form-control-label'>
                            Frecuencia:
                        </label>
                        <Select
                            key="frecuencia"
                            name="frecuencia"
                            menuPosition='fixed'
                            menuPlacement='auto'
                            defaultValue={item.frecuencia && {
                                label: item.frecuencia,
                                value: item.frecuencia,
                            }}
                            options={[
                                {label: 'Quincenal', value: 'Quincenal'},
                                {label: 'Mensual', value: 'Mensual'},
                                {label: 'Bimestral', value: 'Bimestral'},
                                {label: 'Trimestral', value: 'Trimestral'},
                                {label: 'Semestral', value: 'Semestral'},
                                {label: 'Anual', value: 'Anual'},
                            ]}
                        />
                        {errors.fecha_inicio && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha_inicio[0]}
                            </small>
                        )}
                    </div>
                </div>

                <ul className="tabs-vertical m-t-15">
                    {gruposPagos.map(grupo => (
                        <li>
                            <button className={grupo.id === activeTab ? 'activa' : ''} type="button" onClick={() => setActiveTab(grupo.id)}>
                                {grupo.label} <i className="zmdi zmdi-chevron-right"></i>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="col-md-6 tabs-vertical-container">
                {gruposPagos.map(grupo => (
                    <PagoItems
                        key={grupo.id}
                        grupo={grupo}
                        item={item}
                        grados={grados}
                        activeTab={activeTab}
                    />
                ))}
            </div>
        </div>

    </>)
}


const PagoItems = ({grupo, item, grados, activeTab}) => {
    const [reglas, setReglas] = useState(item.configuracion?.[grupo.id] || [
        {id: 0},
    ]);

    const addRegla = (i) => {
        setReglas(prevState => [...prevState, {id: Date.now() }]);
    }

    const removeRegla = (id) => {
        setReglas(prevState => [...prevState.filter(r => r.id !== id) ]);
    }

    return (
        <div className={'m-t-15 ' + (grupo.id === activeTab ? '' : 'd-none')}>
            <h3>{grupo.label}</h3>
            {reglas.map((regla, index) => (
                <fieldset key={regla.id} className="m-t-15" style={{padding: "15px"}}>
                    <input type="hidden" className="form-control" defaultValue={item.configuracion ? item.configuracion[grupo.id]?.monto : ''} name={`configuracion[${grupo.id}][${index}][id]`} value={regla.id} />
                    <div className="row">
                        <div className="col-md-6">
                            Desde Grado
                            <Select
                                defaultValue={item.configuracion && grados.filter(g => g.nombre === item.configuracion?.[grupo.id]?.[index]?.grado_desde).map(g => ({label: g.nombreCompleto, value: g.nombre})).shift()}
                                name={`configuracion[${grupo.id}][${index}][grado_desde]`}
                                menuPosition='fixed'
                                menuPlacement='auto'
                                options={grados.map(g => ({
                                    label: g.nombreCompleto,
                                    value: g.nombre
                                }))}
                            />
                        </div>
                        <div className="col-md-6">
                            Hasta Grado
                            <Select
                                defaultValue={item.configuracion && grados.filter(g => g.nombre === item.configuracion[grupo.id]?.[index]?.grado_hasta).map(g => ({label: g.nombreCompleto, value: g.nombre})).shift()}
                                name={`configuracion[${grupo.id}][${index}][grado_hasta]`}
                                menuPosition='fixed'
                                menuPlacement='auto'
                                options={grados.map(g => ({
                                    label: g.nombreCompleto,
                                    value: g.nombre
                                }))}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            Monto
                            <input type="number" className="form-control" defaultValue={item.configuracion ? item.configuracion[grupo.id]?.[index]?.monto : ''} name={`configuracion[${grupo.id}][${index}][monto]`} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-right">
                            <button type="button" className="btn" onClick={() => removeRegla(regla.id)} >Eliminar</button>
                        </div>
                    </div>
                </fieldset>
            ))}
            <div className="text-right m-t-15">
                <button className="btn" type="button" onClick={() => addRegla()}>+ Añadir</button>
            </div>
        </div>
    );
}
