/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { request } from "../../../../utils/request";

export const useData = (tipoNotaCredito, contacto) => {
	const [comprobantes, setComprobantes] = useState([]);
	const [facturas, setFacturas] = useState([]);
	const [contactos, setContactos] = useState([]);

	const getContactosForDropdown = useCallback(async () => {
		await request(
			`contactos?limit=200000&tipo=${
				tipoNotaCredito === "compra" ? "proveedor" : "cliente"
			}`
		).then((res) => {
			setContactos([
				...res.data.map((contacto) => ({
					value: contacto.id,
					label: contacto.nombre,
				})),
			]);
		});
	}, [tipoNotaCredito]);

	const getFacturasForDropdown = useCallback(async () => {
		if (tipoNotaCredito === "compra") {
			await request(`compras-for-dropdown?contacto=${contacto}`).then((res) => {
				setFacturas(res.data);
			});
			return;
		}
		await request(`facturas-for-dropdown?contacto=${contacto}`).then((res) => {
			setFacturas(res.data);
		});
	}, [contacto]);

	const getRazonesForDropdown = useCallback(async () => {
		let razones = [];
		await request("razones-for-dropdown").then((res) => {
			razones = res.data;
		});

		return razones;
	}, [tipoNotaCredito]);

	const getComprobantesForDropdown = useCallback(async () => {
		request("notas-credito-comprobantes").then((res) => {
			setComprobantes(res.data);
		});
	}, [tipoNotaCredito]);

	useEffect(() => {
		if (!tipoNotaCredito) return;
		getContactosForDropdown();
		getComprobantesForDropdown();
	}, [tipoNotaCredito]);

    useEffect(() => {
        if (!contacto) return;
        getFacturasForDropdown();
    }, [contacto]);

	return useMemo(
		() => ({ comprobantes, facturas, contactos, getRazonesForDropdown }),
		[comprobantes, facturas, contactos, getRazonesForDropdown]
	);
};
