import React from "react";

export default function ReporteVehiculos({
    vehiculos,
    ficha,
    buscarFicha,
    checkAll,
    updateList,
}) {
    const porCategoria = vehiculos.reduce((acc, vehiculo) => {
        if (!acc[vehiculo.categoria]) {
            acc[vehiculo.categoria] = [];
        }

        acc[vehiculo.categoria].push(vehiculo);

        return acc;
    }, {});

    return (
        <div>
            <div className='row m-t-20 m-b-20'>
                <div className='col-md-7 p-t-5'>
                    <button type='button' onClick={() => checkAll("todos")}>
                        Seleccionar Todo
                    </button>{" "}
                    |{" "}
                    <button type='button' onClick={() => checkAll("quitar")}>
                        Deseleccionar Todo
                    </button>
                </div>
                <div className='col-md-5'>
                    <input
                        type='text'
                        className='form-control'
                        value={ficha}
                        placeholder='Buscar...'
                        onChange={(e) => buscarFicha(e.target.value)}
                    />
                </div>
            </div>
            {Object.keys(porCategoria).map((categoria) => {
                return (
                    <div className='row m-t-15'>
                        <h3 className='col-md-12'>
                            {categoria}{" "}
                            <button
                                type='button'
                                onClick={() => checkAll(categoria)}>
                                <small>SELECCIONAR TODOS</small>
                            </button>
                        </h3>
                        {porCategoria &&
                            porCategoria[categoria].map((vehiculo) => (
                                <div className='col-md-4' key={vehiculo.id}>
                                    <label>
                                        <input
                                            type='checkbox'
                                            value={vehiculo.id}
                                            onChange={() =>
                                                updateList(vehiculo.id)
                                            }
                                            checked={vehiculo.checked}
                                        />
                                        {vehiculo.ficha}
                                    </label>
                                </div>
                            ))}
                    </div>
                );
            })}
            <input
                type='hidden'
                name='vehiculos'
                required
                value={vehiculos
                    .filter((vehiculo) => vehiculo.checked)
                    .map((vehiculo) => vehiculo.id)
                    .join(",")}
            />
        </div>
    );
}
