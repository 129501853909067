import React from "react";

const Dropdown = ({children, btnText = "Acciones", btnIcon, classes = {}, styles = {}}) => {
	return (
		<div className={`dropdown ${classes.dropdown}`} style={styles.dropdown}>
			<button className={`au-btn au-btn-icon au-btn-filter ${classes.button}`} style={styles.button}>
				{btnIcon} {btnText}
			</button>
			<div
				style={styles.dropdownMenu}
				className={`dropdown-menu ${classes.dropdownMenu}`}
			>
				{children}
			</div>
		</div>
	);
};

export default Dropdown;
