import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import { getLink } from "../../../utils/helpers";
import Pagination from "../../general/Pagination";
import Loader from "../../general/Loader";
import { request } from "../../../utils/request";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";

const tipos_impuesto = [
	{
		value: "itbis",
		label: "ITBIS",
	},
	{
		value: "otro",
		label: "Otro",
	},
	{
		value: "isc",
		label: "ISC",
	},
	{
		value: "propina_legal",
		label: "Propina legal",
	},
	{
		value: "no_facturable",
		label: "No facturable",
	},
	{
		value: "exento",
		label: "Exento",
	},
];

const Impuestos = () => {
	const [impuestos, setImpuestos] = useState({
		data: [],
		links: null,
		meta: null,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [showCreateModal, setCreateModal] = useState(false);
	const [showEditModal, setEditModal] = useState(false);
	const [impuestoToEdit, setImpuestoToEdit] = useState();
	const [filtrosQuery, setFiltrosQuery] = useState("");
	const [filtros, setFiltros] = useState({
		buscar: "",
		status: "todos",
	});

	const toggleCreateModal = () => {
		setCreateModal((state) => !state);
	};

	const toggleEditModal = (impuesto) => {
		setEditModal((state) => !state);
		if (!impuesto) return;

		setImpuestoToEdit(impuesto.id);
	};

	const getImpuestosData = (url = null) => {
		url = url ? url + "&limit=15" : "impuestos?limit=15";

		request(url).then((res) => {
			setImpuestos(res);
			setIsLoading(false);
		});
	};

	const onFiltroChange = async (value, filtro) => {
		setFiltros((filtros) => ({
			...filtros,
			[filtro]: value,
		}));
	};

	useEffect(() => {
		getImpuestosData(`impuestos?${filtrosQuery}`);
	}, [filtrosQuery]);

	useLayoutEffect(() => {
		let query = Object.keys(filtros).map((fil) => {
			return `${fil}=${filtros[fil]}`;
		});

		setFiltrosQuery("?" + query.join("&"));
	}, [filtros]);
	return (
		<>
			<div className="page-header">
				<Link to={getLink("/configuracion/")}>
					&laquo; Volver a configuración
				</Link>
				<h1 className="page-title row">
					<span className="col">Impuestos de pago</span>
					<button
						className="au-btn au-btn-icon au-btn--green au-btn--small"
						onClick={toggleCreateModal}
					>
						<i className="zmdi zmdi-plus" />
						Añadir Impuesto
					</button>
				</h1>
				<div className="page-controls">
					<div className="table-data__tool">
						<div className="table-data__tool-left row">
							<div className="col">
								<label htmlFor="search">Buscar</label>
								<input
									onChange={(e) =>
										onFiltroChange(e.target.value, "buscar")
									}
									type="search"
									placeholder="Buscar..."
									className="form-control"
									name="buscar"
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="status">Estado</label>
								<select
									className="form-control"
									name="status"
									id="status"
									onChange={(e) =>
										onFiltroChange(e.target.value, "status")
									}
								>
									<option value="todos">Todos</option>
									<option value="activo">Activos</option>
									<option value="inactivo">Inactivos</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<div>
						{isLoading && <Loader />}
						{!impuestos.data.length ? (
							<div>
								{!isLoading && (
									<div>No hay ningún Impuesto creado.</div>
								)}
							</div>
						) : (
							<div className="table-data">
								<table className="table table-data2">
									<thead>
										<tr>
											<th>Nombre</th>
											<th>Porcentaje (%)</th>
											<th>Tipo</th>
											<th width="200px">
												Impuesto Acréditable
											</th>
											<th>Descripción</th>
											<th className="text-center">
												Status
											</th>
										</tr>
									</thead>
									<tbody>
										{impuestos.data.map((impuesto) => {
											return (
												<tr
													className="tr-shadow"
													key={impuesto.id}
												>
													<td>{impuesto.nombre}</td>
													<td>{impuesto.porcentaje}</td>
													<td>{tipos_impuesto.find(tipo => tipo.value === impuesto.tipo)?.label}</td>
													<td>{impuesto.acreditable}</td>
													<td>{impuesto.descripcion}</td>
													<td className="d-flex justify-content-center">
														{impuesto.status ==
														"activo" ? (
															<span className="badge badge-success p-2">
																Activo
															</span>
														) : (
															<span className="badge badge-danger p-2">
																Inactivo
															</span>
														)}
													</td>
													<td>
														{!impuesto.is_default && (
															<div
																className="table-data-feature"
																style={{
																	justifyContent:
																		"center",
																}}
															>
																<button
																	className="item"
																	data-toggle="tooltip"
																	data-placement="top"
																	title=""
																	data-original-title="Edit"
																	onClick={() => {
																		toggleEditModal(
																			impuesto
																		);
																	}}
																>
																	<i className="zmdi zmdi-edit" />
																</button>
															</div>
														)}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
						{impuestos.links && (
							<Pagination
								links={impuestos.links}
								meta={impuestos.meta}
								getData={getImpuestosData}
							/>
						)}
					</div>
				</div>
			</div>
			<Modal
				show={showCreateModal}
				blank={true}
				toggle={toggleCreateModal}
			>
				<Create
					toggleModal={toggleCreateModal}
					getImpuestosData={getImpuestosData}
				/>
			</Modal>
			<Modal
				show={showEditModal}
				blank={true}
				toggle={() => toggleEditModal()}
			>
				<Edit
					toggleModal={() => toggleEditModal()}
					getImpuestosData={getImpuestosData}
					impuestoID={impuestoToEdit}
				/>
			</Modal>
		</>
	);
};

export default Impuestos;
