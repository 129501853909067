/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { downloadFile, request } from "../../../../utils/request";
import { auth } from "../../../../utils/auth";
import { arrayToCsv, composeCSV, downloadBlob, formatCurrency, money } from "../../../../utils/helpers";


export const procesarNotasCreditoData = (data) => {
    const resumenes = {
        totalExento: 0,
        totalGravado: 0,
        detalleGravado: {
            totalITBIS16: 0,
            gravadoITBIS16:0,
            totalITBIS18: 0,
            gravadoITBIS18:0,
        }
    };

    const notasCredito = [];

    data.forEach(notaCredito => {
        const tipoPago = (notaCredito.tipo === 'venta' ? notaCredito.factura.tipo_pago?.toLowerCase() : notaCredito.compra.formaPago?.toLowerCase()) || '';
        const row = {
            compra: notaCredito.compra,
            cliente: notaCredito.contacto?.nombre || '',
            tipoIdentificacion: notaCredito.contacto?.tipo_identificacion == 'rnc' ? 1 : 2,
            rnc: notaCredito.contacto?.identificacion?.replace(/-/g, '') || '',
            numero: notaCredito.compra?.numero || '',
            ncf : notaCredito.numeracion,
            afectada: notaCredito.tipo === 'venta' ? notaCredito.factura?.numero_comprobante : (notaCredito.compra?.ncf || notaCredito.compra?.numero),
            fecha: dayjs(notaCredito.fecha).format("YYYYMMDD"),
            fecha3: dayjs(notaCredito.fecha).format("YYYY/MM/DD"),
            fechaRetencion: '',
            fechaRetencion3: '',
            importe: 0,
            importeC: notaCredito.estado === "cancelada" ? 0 : notaCredito.subtotal || money(notaCredito.total - (notaCredito.totalImpuestos || 0)),
            impuesto: notaCredito.estado === "cancelada" ? 0 : money(notaCredito.total_impuestos),
            subtotal: notaCredito.subtotal,
            total: 0,
            totalC: notaCredito.estado === "cancelada" ? 0 : money(notaCredito.total),
            estado: notaCredito.estado,
            tipo: notaCredito.tipo,
            tipoGasto: notaCredito.tipoGasto,
            formaPago: notaCredito.formaPago,
            fechaPago: notaCredito.fechaPago,
            fechaPago3: dayjs(notaCredito.fechaPago3).format("YYYY/MM/DD"),
            fecha1: notaCredito.fecha1,
            fecha2: notaCredito.fecha2,
            tipoIngreso: '01 - Ingresos por Operaciones (No Financieros)',
            i16: 0,
            i18: 0,
            ie: 0,
            ig16: 0,
            ig18: 0,
            it: 0,
            efectivo: tipoPago.match(/efectivo/) ? notaCredito.total : '',
            cheque: tipoPago.match(/cheque|deposito|transferencia/) ? notaCredito.total : '',
            tarjeta: tipoPago.match(/tarjeta/) ? notaCredito.total : '',
            credito: tipoPago.match(/cheque|deposito|transferencia|efectivo|tarjeta/) ? '' : notaCredito.total,
        }

        if (notaCredito.estado !== 'cancelada') {
            if (notaCredito.productos?.length > 0){
                notaCredito.productos.forEach(producto => {
                    const total = producto.total
                    const porcentaje = producto.impuesto.porcentaje;
                    const impuestoTotal = (total / (porcentaje + 1)) * porcentaje;
                    const importeTotal = total - impuestoTotal;

                    if (porcentaje === 0.16) {
                        row.i16 += impuestoTotal;
                        row.ig16 += importeTotal;

                        resumenes.detalleGravado.totalITBIS16 += impuestoTotal;
                        resumenes.detalleGravado.gravadoITBIS16 += importeTotal;
                        resumenes.totalGravado += importeTotal;
                    }
                    else if (porcentaje === 0.18) {
                        row.i18 += impuestoTotal;
                        row.ig18 += importeTotal;

                        resumenes.detalleGravado.totalITBIS18 += impuestoTotal;
                        resumenes.detalleGravado.gravadoITBIS18 += importeTotal;
                        resumenes.totalGravado += importeTotal;
                    } else {
                        row.ie += importeTotal;
                        resumenes.totalExento += importeTotal;
                    }

                    row.it += impuestoTotal;
                    row.importe += importeTotal;
                    row.total += total;
                });
            } else {
                let porcentaje = 0;
                if (notaCredito?.totalImpuestos) {
                    porcentaje = parseFloat((notaCredito.totalImpuestos / (notaCredito.total - notaCredito.totalImpuestos)).toFixed(2));
                }
                if (notaCredito?.total_impuestos) {
                    porcentaje = parseFloat((notaCredito.total_impuestos / (notaCredito.total - notaCredito.total_impuestos)).toFixed(2));
                }
                const total = notaCredito.total;
                const impuestoTotal = (total / (porcentaje + 1)) * porcentaje;
                const importeTotal = total - impuestoTotal;

                if (porcentaje === 0.18) {
                    row.i18 += impuestoTotal;
                    row.ig18 += importeTotal;

                    resumenes.detalleGravado.totalITBIS18 += impuestoTotal;
                    resumenes.detalleGravado.gravadoITBIS18 += importeTotal;
                }
                else if (porcentaje === 0.16) {
                    row.i16 += impuestoTotal;
                    row.ig16 += importeTotal;

                    resumenes.detalleGravado.totalITBIS16 += impuestoTotal;
                    resumenes.detalleGravado.gravadoITBIS16 += importeTotal;
                } else {
                    row.ie += importeTotal;
                    resumenes.totalGravado += importeTotal;
                }

                row.it += impuestoTotal;
                row.importe += importeTotal;
                row.total += total;
            }
        }

        notasCredito.push(row)
    });

    return {
        resumenes,
        notasCredito
    };
}

const ReporteNotasCreditoDetalles = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [notasCredito, setNotasCredito] = useState([]);
	const [resumen, setResumen] = useState({
		totalGravado: 0,
		totalExento: 0,
		detalleGravado: {
			totalITBIS16: 0,
			totalITBIS18: 0,
			gravadoITBIS16: 0,
			gravadoITBIS18: 0
		},
	});

	const getNotasCredito = () => {
		request(
			"notas-credito" +
				window.location.search +
				"&paginate=1000000&order=ncf"
		).then((res) => {
            var data = procesarNotasCreditoData(res.data)
			setNotasCredito(data.notasCredito);
            setResumen(data.resumenes);
		});
	};

	useEffect(() => {
		getNotasCredito();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotal = notasCredito.reduce((acc, notaCredito) => acc + notaCredito.total, 0);
	const valorImpuestos16 = notasCredito.reduce((acc, notaCredito) => acc + notaCredito.i16, 0);
	const valorImpuestos18 = notasCredito.reduce((acc, notaCredito) => acc + notaCredito.i18, 0);
	const valorImpuestos = notasCredito.reduce((acc, notaCredito) => acc + notaCredito.i16 + notaCredito.i18, 0);
	const valorImporte = notasCredito.reduce((acc, notaCredito) => acc + notaCredito.importe, 0);

    const reporte = composeCSV(
        notasCredito.map(nota => ({
            "Contacto"            : nota.cliente,
            "R.N.C"               : nota.rnc,
            "NO."                 : nota.numero,
            "NCF"                 : nota.ncf,
            "Transaccion Afectada": nota.afectada,
            "Fecha"               : nota.fecha,
            "Importe"             : money(nota.importe),
            "Impuesto"            : money(nota.it),
            "Total"               : money(nota.total),
            "Estado"              : nota.estado,
            "Tipo"                : nota.tipo,
        }))
    );

    const reporteDetalle = composeCSV(
        notasCredito.map(nota => ({
            "Contacto"            : nota.cliente,
            "R.N.C"               : nota.rnc,
            "NO."                 : nota.numero,
            "NCF"                 : nota.ncf,
            "Transaccion Afectada": nota.afectada,
            "Fecha"               : nota.fecha,
            "Estado"              : nota.estado,
            "Tipo"                : nota.tipo,
            "ITBIS 16%"           : money(nota.i16),
            "ITBIS 18%"           : money(nota.i18),
            "Impuesto"            : money(nota.it),
            "Importe"             : money(nota.importe),
            "Total"               : money(nota.total),
            "Exento"              : money(nota.ie),
            "Grabado 16%"         : money(nota.ig16),
            "Grabado 18%"         : money(nota.ig18),
        }))
    );

	const downloadReport = async (reporte, name, type) => {
        await downloadFile(`reportes/notas-credito/2${window.location.search}&reporte=${reporte}&type=${type}`, `${name}.${type}`);
    };

	return (
		<>
			<style>{`@page {size: A4 landscape}`}</style>
            <div className="ordernar-resultados no-print">
                <div className="form-group">
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        const content = arrayToCsv(reporte);
                        downloadBlob(content, `detalle-de-impuestos-notas-credito-${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}.csv`)
                    }}>Exportar.CSV</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        const content = arrayToCsv(reporteDetalle);
                        downloadBlob(content, `detalle-de-impuestos-notas-credito-${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}.csv`)
                    }}>Exportar Detalles.CSV</button>

					<button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('exportar', `impuestos-notas-credito-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")})`, 'xlsx');
                    }}>Exportar.XLSX</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('detalle', `impuestos-notas-credito-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")})`, 'xlsx');
                    }}>Exportar Detalle.XLSX</button>

					<button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('exportar', `impuestos-notas-credito-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")})`, 'pdf');
                    }}>Exportar.PDF</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('detalle', `impuestos-notas-credito-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")})`, 'pdf');
                    }}>Exportar Detalle.PDF</button>
                </div>
            </div>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Detalle de Impuestos Nota de Crédito
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
				<div className="col-md-12 p-b-10">
					<div className="float-left">
						TOTAL NOTAS DE CRÉDITO: {notasCredito.length}
                        {['venta', 'compra'].indexOf(queryParams.get("tipo")) > -1 && (
                            <div className="uppercase">
                                TIPO: {queryParams.get("tipo")}
                            </div>
                        )}
					</div>

					<div className="float-right text-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>

			<table className="table m-b-10">
				<thead>
					<tr>
						<th width="290px">Contacto</th>
						<th width="100px">R.N.C</th>
                        {queryParams.get("tipo") === 'compra' && (
                            <th width="85px">NO.</th>
                        )}
						<th width="120px">NCF</th>
						<th width="120px">Transacción Afectada</th>
						<th width="100px">Fecha</th>
						<th width="135px">Importe</th>
						<th width="125px">Impuesto</th>
						<th width="135px">
							Total
						</th>
						<th width="85px">Estado</th>
                        {['venta', 'compra'].indexOf(queryParams.get("tipo")) === -1 && (
                            <th width="85px" className="text-center">Tipo</th>
                        )}
					</tr>
				</thead>
				<tbody>
					{notasCredito.map((notaCredito) => {
						return (
							<tr key={notaCredito.id}>
								<td>
									{notaCredito.cliente}
								</td>
								<td className="text-capitalize">
									{notaCredito.rnc}
								</td>
                                {queryParams.get("tipo") === 'compra' && (
                                    <td width="85px">{notaCredito.numero}</td>
                                )}
								<td>{notaCredito.ncf}</td>
								<td>{notaCredito.afectada}</td>
								<td>
									{notaCredito.fecha3}
								</td>
								<td>
									{formatCurrency(notaCredito.importe)}
								</td>
								<td>
									{formatCurrency(notaCredito.it)}
								</td>
								<td>
									{formatCurrency(notaCredito.total)}
								</td>
								<td className="text-capitalize">
									{notaCredito.estado}
								</td>
                                {['venta', 'compra'].indexOf(queryParams.get("tipo")) === -1 && (
                                    <td className="text-center">{notaCredito.tipo}</td>
                                )}
							</tr>
						);
					})}
                    <tr className="fw-600">
						<td width="290px" style={{fontWeight: "normal"}}>Total de facturas {notasCredito.length}</td>
						<td width="100px"></td>
                        {queryParams.get("tipo") === 'compra' && (
                            <td width="85px"></td>
                        )}
						<td width="120px"></td>
						<td width="120px"></td>
						<td width="100px"></td>
						<td width="135px">
							{formatCurrency(valorImporte)}
						</td>
						<td width="125px">
							{formatCurrency(valorImpuestos)}
						</td>
						<td width="135px">
							{formatCurrency(valorTotal)}
						</td>
						<td width="85px"></td>
                        {['venta', 'compra'].indexOf(queryParams.get("tipo")) === -1 && (
						    <td width="85px"></td>
                        )}
					</tr>
				</tbody>
			</table>

            {/* RESUMEN DE FACTURAS (IMPUESTOS) */}
			<div className="row">
				<div className="fw-600 mb-2 w-100 pl-3">
					RESUMEN DE  NOTAS DE CRÉDITO
				</div>
				{/* DETALLES */}
				<div
					className="p-b-10 pl-3 d-inline-block mr-4"
					style={{ width: "22rem" }}
				>
					<div className="fw-600">DETALLES</div>
					<div>
						<span
							style={{ width: "180px", display: "inline-block" }}
						>
							GRAVADOS CON ITBIS 16%
						</span>
						<span>=</span>{" "}
						<span className="float-right">
							{formatCurrency(
								resumen.detalleGravado?.gravadoITBIS16,
								"$"
							)}
						</span>
					</div>
					<div>
						<span
							style={{ width: "180px", display: "inline-block" }}
						>
							GRAVADOS CON ITBIS 18%
						</span>
						<span>=</span>{" "}
						<span className="float-right">
							{formatCurrency(
								resumen.detalleGravado?.gravadoITBIS18,
								"$"
							)}
						</span>
					</div>
					<div>
						<span
							style={{ width: "180px", display: "inline-block" }}
						>
							TOTAL GRAVADO
						</span>
						<span>=</span>{" "}
						<span className="float-right">
							{formatCurrency(resumen.totalGravado, "$")}
						</span>
					</div>
					<div>
						<span
							style={{ width: "180px", display: "inline-block" }}
						>
							TOTAL EXENTO{" "}
						</span>
						<span>=</span>{" "}
						<span className="float-right">
							{formatCurrency(resumen.totalExento, "$")}
						</span>
					</div>
					<div className="bold m-t-5">
						<span
							style={{ width: "180px", display: "inline-block" }}
						>
							TOTAL GENERAL{" "}
						</span>
						<span>=</span>{" "}
						<span className="float-right">
							{formatCurrency(resumen.totalExento + resumen.totalGravado)}
						</span>
					</div>
				</div>

				{/* IMPUESTOS */}
				<div
					className="p-b-10 d-inline-block"
					style={{ width: "16rem" }}
				>
					<div>
						<div className="fw-600">IMPUESTOS</div>
						<span
							style={{ width: "110px", display: "inline-block" }}
						>
							16% ITBIS
						</span>
						<span>=</span>{" "}
						<span className="float-right">
							{formatCurrency(valorImpuestos16)}
						</span>
					</div>
					<div>
						<span
							style={{ width: "110px", display: "inline-block" }}
						>
							18% ITBIS
						</span>
						<span>=</span>{" "}
						<span className="float-right">
							{formatCurrency(valorImpuestos18)}
						</span>
					</div>
					<div className="fw-600">
						<span
							style={{ width: "110px", display: "inline-block" }}
						>
							TOTAL
						</span>
						<span>=</span>{" "}
						<span className="float-right">
							{formatCurrency(valorImpuestos, "$")}
						</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default ReporteNotasCreditoDetalles;
