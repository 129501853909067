import React from "react";
import { formatNumber } from "../../utils/helpers";

export default function CombustibleDisponible({ contenedores }) {
    return (
        <div className='dashboard-widget-combustible'>
            <h3 className='dashboard-widget-title'>Combustible Disponible</h3>
            <div className='contenedores'>
                {contenedores.map((contenedor, index) => {
                    const porcentajeDisponible =
                        (contenedor.disponible / contenedor.capacidad) * 100;
                    let color = "green";
                    let combustibleDibujar = porcentajeDisponible;
                    if (
                        porcentajeDisponible > contenedor.capacidad 
                    ){
                        color = "orange"
                    }
                    if (
                        porcentajeDisponible < 60 &&
                        porcentajeDisponible > 30
                    ) {
                        color = "yellow";
                    }
                    if (porcentajeDisponible < 30) {
                        color = "red";
                    }
                    if(porcentajeDisponible > contenedor.capacidad){
                        combustibleDibujar = contenedor.capacidad;
                    }
                    return (
                        <div key={index} className='contenedor'>
                            <div className='contenedor-nombre'>
                                {contenedor.almacen}
                            </div>
                            <div className='contenedor-label'>
                                Disponible:{" "}
                                {formatNumber(contenedor.disponible)}{" "}
                                {contenedor.unidad} | Capacidad:{" "}
                                {formatNumber(contenedor.capacidad)}{" "}
                                {contenedor.unidad}
                            </div>
                            
                            <div className='contenedor-bar'>
                                
                                <div
                                    className='contenedor-bar-inner'
                                    style={{
                                        width: combustibleDibujar + "%",
                                        backgroundColor: color,
                                    }}></div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
