import React, { useEffect, useState } from "react";
import queryString from "query-string";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";
import { request } from "../../../utils/request";

dayjs.extend(duration);

const params = queryString.parse(window.location.search);

const PrintCuentasPorCobrar = () => {
	const [clientes, setClientes] = useState([]);
    const [firma, setFirma] = useState(false);

	const getCuentas = () => {
		request(
			"estados-de-cuentas" + window.location.search
		).then((res) => {
			setClientes(res.data?.cuentas);
			setTimeout(() => {
				window.print();
			}, 1500);
		});
	};

	const formatDateTime = (date) => {
		return dayjs(date).format("DD/MM/YYYY, hh:mm a");
	};

	useEffect(() => {
		getCuentas();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotal = 0;

	return (
		<>
            <style type="text/css" media="print">
				@page {"{ size: landscape; }"}
			</style>

			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Estados de Cuenta
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					Desde: {dayjs(params.desde).format('DD/MM/YYYY')} - Hasta: {dayjs(params.hasta).format('DD/MM/YYYY')}
				</div>
				<div className="col-md-12 text-center">
					<small>Generado: {new Date().toLocaleString()} por {user.nombre} {user.apellido[0]}.</small>
				</div>
			</div>
            <div className="row p-b-10" style={{fontSize: 'larger'}}>
                <div className="col-md-2">
                    Clientes: {clientes?.length}
                </div>

                <div className="col text-right">
                    <small>
                        <strong>DC:</strong> Días de Crédito |{" "}
                        <strong>DT:</strong> Días Transcurridos | {" "}
                        <strong>DV:</strong> Días Vencidos | {" "}
                        <strong>VD:</strong> Vendedor
                    </small>
                </div>

                {/* <div className="col-md-2" >
                    <strong>VALOR TOTAL: {formatCurrency(valorTotal)}</strong>
                </div> */}
            </div>

            {clientes?.filter(c => c.cuentas.length > 0).map(cliente => {
                const recibosRegistrados = [];
                const cuentas = cliente.cuentas.reduce((acc, cuenta) => {
                    if (cuenta.estado === 'cancelada' || (cuenta.recurrencia_id && cuenta.validada === 'no')) {
                        return acc;
                    }

                    const pagado = (cuenta.recibos_ingreso.length === 0 && cuenta.notas_credito.length === 0 && cuenta.por_cobrar === 0) ? parseFloat(cuenta.total) : 0;

                    acc.push({
                        tipo: 'factura',
                        numero: cuenta.numero_comprobante,
                        fecha: dayjs(cuenta.fecha),
                        monto: cuenta.total,
                        recibido: pagado,
                        estado: cuenta.estado,
                        por_cobrar: cuenta.por_cobrar,
                        pendiente: parseFloat(cuenta.total) - pagado,
                        dc: cuenta.plazo?.dias,
                        dt: Math.abs(Math.round(dayjs.duration(dayjs(cuenta.fecha).diff(dayjs())).asDays())),
                        dv: (cuenta.estado === 'pagada' || dayjs(cuenta.fecha_vencimiento) > dayjs() ) ? '0' : Math.round(dayjs.duration(dayjs(cuenta.fecha_vencimiento).diff(dayjs())).asDays()),
                        vd: cuenta.vendedor?.codigo,
                        afectada: '',
                    });

                    const alteraciones = [];

                    cuenta.notas_credito.forEach(nota_credito => {
                        if (parseFloat(nota_credito.total) > 0) {
                            alteraciones.push({
                                tipo: 'nota',
                                numero: nota_credito.numeracion,
                                fecha: dayjs(nota_credito.fecha),
                                monto: '',
                                por_cobrar: 0,
                                estado: nota_credito.estado,
                                recibido: nota_credito.estado == 'cancelada' ? 0 : parseFloat(nota_credito.total),
                                pendiente: 0,
                                dc: '',
                                dt: '',
                                dv: '',
                                vd: cuenta.vendedor?.codigo,
                                afectada: cuenta.numero_comprobante,
                            });
                        }
                    });

                    cuenta.pagos.forEach(pago => {
                        if (pago.monto > 0) {
                            const recibo = cuenta.recibos_ingreso?.filter(r => r.recibo_ingreso?.created_at.slice(0,10) === pago.created_at.slice(0,10)).shift();

                            const numero = (recibo?.recibo_ingreso?.numeracion || 'Abono');
                            const totalLabel = recibo?.recibo_ingreso?.abono_total;

                            if (recibo || pago.monto < cuenta.total) {
                                alteraciones.push({
                                    tipo: 'recibo',
                                    numero: numero + (totalLabel && (recibosRegistrados.indexOf(numero) === -1) ? ` (${formatCurrency(totalLabel)})` : ''),
                                    abonoTotal: recibo?.abono_total,
                                    fecha: dayjs(pago.fecha),
                                    monto: '',
                                    por_cobrar: 0,
                                    estado: recibo?.status,
                                    recibido: parseFloat(pago.monto),
                                    pendiente: 0,
                                    dc: '',
                                    dt: '',
                                    dv: '',
                                    vd: cuenta.vendedor?.codigo,
                                    afectada: cuenta.numero_comprobante,
                                });

                                if(!recibosRegistrados.includes(numero)) {
                                    recibosRegistrados.push(numero)
                                }
                            }
                        }
                    });

                    alteraciones.sort((a,b)=>{
                        return a.fecha - b.fecha;
                    });

                    let bal = cuenta.total;
                    alteraciones.forEach(a => {
                        bal -= a.recibido;
                        if (bal>0 && !['cancelada', 'cancelado'].includes(a.estado)) {
                            a.pendiente = bal;
                        }
                        acc.push(a);
                    });

                    return acc;
                }, []);

                cuentas.sort((a,b)=>{
                    return a.fecha - b.fecha;
                });

                let balance = 0;
                let balanceTotal = 0;
                let facturado = 0;
                let recibido = 0;

                cuentas.forEach(cuenta => {
                    if (cuenta.monto === cuenta.recibido) {
                        balanceTotal += 0;
                    } else if (cuenta.recibido > 0) {
                        balanceTotal -= cuenta.recibido;
                    } else {
                        balanceTotal += cuenta.pendiente;
                    }
                });

                return (
                    <>
                        <h4>CLIENTE: {cliente.detalles.nombre}</h4>
                        <table style={{width: '100%'}}>
                            <tbody>
                                <tr>
                                    <td>RNC: {cliente.detalles.identificacion}</td>
                                    <td className="text-right">Límite de Crédito Aprobado: {formatCurrency(cliente.detalles.limite_credito)}</td>
                                </tr>
                                <tr>
                                    <td>Dirección: {cliente.detalles.direccion}</td>
                                    <td className="text-right">Crédito Disponible: {formatCurrency(cliente.detalles.credito_disponible - balanceTotal)}</td>
                                </tr>
                                <tr>
                                    <td>Teléfono: {cliente.detalles.telefono}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table m-b-40 m-t-15" style={{fontSize: 'larger'}}>
                            <thead>
                                <tr>
                                    <th>NCF/Numero</th>
                                    <th>Fecha</th>
                                    <th>DC</th>
                                    <th>DT</th>
                                    <th>DV</th>
                                    <th>VD</th>
                                    <th>Estado</th>
                                    <th>Afectada</th>
                                    <th>Por Cobrar</th>
                                    <th>Facturado</th>
                                    <th>Recibido</th>
                                    <th>Pendiente</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cuentas.map((cuenta) => {
                                    if (cuenta.monto === cuenta.recibido) {
                                        balance += 0;
                                    } else if (cuenta.recibido > 0) {
                                        balance -= cuenta.recibido;
                                    } else {
                                        balance += cuenta.pendiente;
                                    }
                                    facturado += cuenta.monto ? cuenta.monto : 0;
                                    recibido += cuenta.recibido;
                                    return (
                                        <tr key={cuenta.id}>
                                            <td>{cuenta.numero}</td>
                                            <td>{cuenta.fecha.format('DD/MM/YYYY')}</td>
                                            <td>{cuenta.dc}</td>
                                            <td>{cuenta.dt}</td>
                                            <td>{cuenta.dv}</td>
                                            <td>{cuenta.vd}</td>
                                            <td>{cuenta.estado}</td>
                                            <td>{cuenta.afectada}</td>
                                            <td>{cuenta.por_cobrar > 0 && formatCurrency(cuenta.por_cobrar)}</td>
                                            <td>{cuenta.monto > 0 && formatCurrency(cuenta.monto)}</td>
                                            <td>{cuenta.recibido > 0 && formatCurrency(cuenta.recibido)}</td>
                                            <td>{cuenta.pendiente > 0 && formatCurrency(cuenta.pendiente)}</td>
                                            <td>{formatCurrency(balance)}</td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><strong>{formatCurrency(cuentas.reduce((acc, c) => acc + parseFloat(c.por_cobrar), 0))}</strong></td>
                                    <td><strong>{formatCurrency(facturado)}</strong></td>
                                    <td><strong>{formatCurrency(recibido)}</strong></td>
                                    <td></td>
                                    <td><strong>{formatCurrency(balance)}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                );
            })}

            {firma && <div className="footer-facturas">
                <div className="row justify-content-center mt-5">
                    <h3
                        className="col-10 col-md-3 text-center mr-md-2 mr-0 mt-md-4 fw-600 signature-title"
                        style={{ borderTop: "1px solid #000" }}
                    >
                        ELABORADO POR
                    </h3>
                    <div className="col-md-1 d-none d-md-block"></div>
                    <h3
                        className="col-10 col-md-3 text-center mr-md-2 mr-0 mt-md-4 fw-600 signature-title"
                        style={{ borderTop: "1px solid #000" }}
                    >
                        ENTREGADO POR
                    </h3>
                    <div className="col-md-1 d-none d-md-block"></div>
                    <h3
                        className="col-10 col-md-3 text-center ml-md-2 ml-0 fw-600 mt-5 mt-md-4 signature-title"
                        style={{ borderTop: "1px solid #000" }}
                    >
                        RECIBIDO POR
                    </h3>
                </div>
                <br/><br/>
            </div>}

            <div className="ordernar-resultados no-print">
                <div className="form-group">
                    <div className="radio-btn">
                        <label>
                            <input
                                type="radio"
                                onChange={() => setFirma(false)}
                                name="orden"
                                value={false}
                                defaultChecked={true}
                            />{" "}
                            Sin líneas de firma
                        </label>
                        <label>
                            <input
                                type="radio"
                                onChange={() => setFirma(true)}
                                name="orden"
                                value={true}
                            />{" "}
                            Con líneas de firma
                        </label>
                    </div>
                </div>
            </div>
		</>
	);
};

export default PrintCuentasPorCobrar;
