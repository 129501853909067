import React, { Component } from "react";
import { debounce } from "lodash";

import { withRequest } from "../../../utils/request";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../../general/Loader";
import { Link } from "react-router-dom";
import { getLink } from "../../../utils/helpers";
import ReactSelect from "react-select";

class Almacenes extends Component {
    state = {
        almacenes: {},
        almacen: {},
        links: null,
        showCreate: false,
        showEdit: false,
        loading: true,
    };
    componentDidMount() {
        this.getAlmacenes();
    }
    getAlmacenes = async (url = null) => {
        url = url ? url : "almacenes";
        const almacenes = await this.props.request(url);

        if (almacenes.data) {
            this.setState({
                almacenes: almacenes.data,
                loading: false,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            almacen: {},
        });
    };
    toggleEditModal = (almacen) => {
        this.setState({
            showEdit: !this.state.showEdit,
            almacen: almacen,
        });
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getAlmacenes("almacenes?limit=20&" + query.join("&"));
    }, 500);

    render() {
        const { almacenes, loading } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/configuracion/')}>&laquo; Volver a configuración</Link>
                    <h1 className='page-title row'>
                        <span className='col'>Almacenes</span>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Almacen
                        </button>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='search'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='grupo'>Inventario</label>
                                    <ReactSelect
                                        name='inventario'
                                        id='inventario'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "inventario"
                                            )
                                        }
                                        options={[
                                            {value: 'todos', label: 'Todos'},
                                            {value: 'items', label: 'Interno'},
                                            {value: 'productos', label: 'Productos'},
                                        ]}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!almacenes.length ? (
                                <div>
                                    {!loading && (
                                        <div>
                                            Todavía no hay almacenes creados.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th className="text-center">Productos/Items</th>
                                                <th>Inventario</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {almacenes.map((almacen) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={almacen.id}>
                                                    <td>
                                                        {almacen.nombre}{" "}
                                                        {almacen.principal && (
                                                            <span className='badge badge-success'>
                                                                PRINCIPAL
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td className="text-center">{almacen.tipo_producto === 'items' ? almacen.items : almacen.tipo_producto === 'productos' ? almacen.productos : 0}</td>
                                                    <td>{almacen.tipo_producto === 'items' ? 'Interno' : almacen.tipo_producto === 'productos' ? 'Productos' : 'N/A'}</td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <button
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Edit'
                                                                onClick={() => {
                                                                    this.toggleEditModal(
                                                                        almacen
                                                                    );
                                                                }}>
                                                                <i className='zmdi zmdi-edit' />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getAlmacenes}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        almacen={this.state.almacen}
                        getAlmacenes={this.getAlmacenes}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        almacen={this.state.almacen}
                        getAlmacenes={this.getAlmacenes}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Almacenes);
