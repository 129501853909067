import dayjs from 'dayjs';
import React from 'react';
import SelectAsync from 'react-select/async';
import { formatCurrency } from '../../../../utils/helpers';
import { apiRequest } from '../../../../hooks/apiRequest';

const Detalles = ({contacto, setContacto, getClases, balance, pendiente, formData}) => {

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => {
                const nombre = item[label] + (item.estudiante ? ' - Estudiante' : ' - Cliente');
                return {
                    value: item[value],
                    label: nombre,
                    item: item
                };
            });
        }
    };

    return (
        <div className="row">
            <div className="col">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Contacto</label>
                            <SelectAsync
                                name="contacto_id"
                                isClearable
                                cacheOptions
                                placeholder="Seleccionar..."
                                defaultOptions
                                onChange={e => {
                                    if (e?.value) {
                                        setContacto(e.item);
                                        getClases(e.value);
                                    } else {
                                        setContacto({});
                                    }
                                }}
                                loadOptions={(e) => getOptions("contactos?limit=20&concocurriculares=1&buscar="+e, 'nombre')}
                                value={contacto?.nombre ? {
                                    label: contacto.nombre + (contacto.estudiante ? ' - Estudiante' : ' - Cliente'),
                                    value: contacto.id,
                                } : null}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Identificación</label>
                            <input type="text"  className="form-control" name="identificacion" value={contacto.identificacion || ''} disabled />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Teléfono</label>
                            <input type="text" className="form-control" name="telefono" value={contacto.telefono || ''} disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                    <label>Pendiente</label>
                    <input type="text" className="form-control" name="Pendiente" value={formatCurrency(pendiente)} disabled />
                </div>
                <div className="form-group">
                    <label>Balance Total</label>
                    <input type="text"  className="form-control" name="periodo_academico" value={formatCurrency(balance)} disabled />
                </div>
            </div>
            <div className="col-md-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>No.</label>
                            <div className="d-flex">
                                <input type="text"  className="form-control m-r-5" value={formData?.comprobante?.prefijo || ''} readOnly name="prefijo_numero" style={{width: '65px'}} />
                                <input type="text"  className="form-control" readOnly value={formData?.comprobante?.numero_siguiente || ''} name="numero_factura" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Fecha</label>
                            <input type="date"  defaultValue={dayjs().format("YYYY-MM-DD")} className="form-control" name="fecha" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Detalles;
