import React, { useEffect, useState } from "react";

import Form from "./Form";
import Loader from "../../general/Loader";
import { apiRequest } from "../../../hooks/apiRequest";
import { request } from "../../../utils/request";
import { toast } from "../../../utils/helpers";
import Modal from "../../general/Modal";

const Edit = ({ toggleModal, getImpuestosData, impuestoID }) => {
	const [saving, setSaving] = useState(false);
	const [errors, setErrors] = useState({});
	const [impuesto, setImpuesto] = useState({});
	const [showDelete, setShowDelete] = useState(false);

	const getImpuestoData = () => {
		if (!impuestoID) return;

		request(`impuestos/${impuestoID}`).then((res) => {
			setImpuesto(res.data);
		});
	};
	const updateTermino = (event) => {
		event.preventDefault();
		setSaving(true);
		const data = new FormData(event.target);

		apiRequest(data, `impuestos/${impuesto.id}`, "post").then(
			(response) => {
				if (response.success) {
					getImpuestosData();
					toggleModal();
					setSaving(false);
					toast("Impuesto actualizado.");
				}
				if (response.code === 422) {
					setErrors({
						...response.data.message,
					});
					setSaving(false);
				}
			}
		);
	};

	const deleteImpuesto = () => {
		apiRequest(null, `impuestos/${impuesto.id}`, "delete").then(
			(response) => {
				if (response.success) {
					getImpuestosData();
					toggleModal();
					setSaving(false);
					toast("Impuesto Eliminado.");
				}
				if (response.code === 422) {
					setErrors({
						...response.data.message,
					});
					setSaving(false);
				}
			}
		);
	};

	const toggleDeleteModal = () => {
        setShowDelete(state => !state);
    };

	useEffect(() => {
		getImpuestoData();
	}, [impuestoID]);
	return (
		<>
		<form id="terminoForm" method="post" onSubmit={updateTermino}>
			<input type="hidden" name="_method" value="put" />
			<div className="popup__header">Editar Impuesto</div>

			{!impuestoID ? (
				<Loader />
			) : (
				<Form impuesto={impuesto} errors={errors} />
			)}

			<div className="popup__footer">
				{!impuesto.is_default && (
					<button
						type="button"
						className="btn float-left"
						onClick={(e) => toggleDeleteModal()}
					>
						<i className="fas fa-trash-alt"></i>
					</button>
				)}
				<button
					type="button"
					className="btn btn-plain"
					onClick={() => toggleModal()}
				>
					Cancelar
				</button>
				{saving ? (
					<Loader type="small" />
				) : (
					<button type="submit" className="btn btn-primary">
						Editar
					</button>
				)}
			</div>
		</form>
		<Modal
				title="Borrar Impuesto"
				show={showDelete}
				callback={() => deleteImpuesto()}
				acceptText="Borrar"
				toggle={toggleDeleteModal}
			>
				¿Estás seguro de que deseas borrar este Impuesto?
			</Modal>
		</>
	);
};

export default Edit;
