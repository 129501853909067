import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { useEstudianteContext } from '../EstudianteContext';
import Loader from '../../../general/Loader';
import { request } from '../../../../utils/request';

export default function Inscripcion({isEdit}) {
    const {grados, secciones, estudiante, setEstudiante, errors, setTiposDocumentos, onInputChange, getOptions, gruposPagos, evaluacionId, gradosRanking, servicios, saving, isReservaCupo, isInscripcion, setShowProcesarModal} = useEstudianteContext();

    const inscripcion = estudiante.curso || {};
    const [grado, setGrado] = useState(inscripcion?.grado || {});
    const [grupo, setGrupo] = useState(inscripcion?.grupoPago || 'regulares');
    const [seccion, setSeccion] = useState(inscripcion?.seccion || {});
    const [salidaOptativa, setSalidaOptativa] = useState(inscripcion?.salidaOptativa?.id || '');
    const [beca, setBeca] = useState(inscripcion?.beca || '');
    const [periodoAcademico, setPeriodoAcademico] = useState(inscripcion?.periodoAcademico);
    const [periodosAcademicos, setPeriodosAcademicos] = useState([]);

    useEffect(() => {
        if (!isReservaCupo && !isInscripcion) {
            setGrado(inscripcion?.grado);
            setGrupo(inscripcion?.grupoPago);
            setBeca(inscripcion?.beca);
            setPeriodoAcademico(inscripcion?.periodoAcademico);
        }
    }, [grados, gruposPagos, inscripcion.grupoPago, inscripcion.periodoAcademico]);

    useEffect(() => {
        if (isInscripcion) {
            request('academico/periodos-academicos').then((res) => {
                setPeriodosAcademicos(res.data);
            });
        }
    }, []);

    return (<>
        <div className="row">
            <input type="hidden" name="evaluacion_id" value={evaluacionId || ''} />
            <div className='col-md-6 form-group'>
                <label className='label form-control-label'>
                    Año Escolar:
                </label>
                <Select
                    cacheOptions
                    defaultOptions
                    isDisabled={isEdit && (!isReservaCupo && !isInscripcion)}
                    key="periodo_academico"
                    name='inscripcion[periodo_academico_id]'
                    placeholder='Seleccionar...'
                    options={isReservaCupo
                        ? estudiante.inscripcion_disponible?.filter(i => i.disponible).map(i => ({
                            label: i.nombre,
                            value: i.id,
                            item: i,
                        }))
                        : periodosAcademicos.map(p => ({
                            label: p.nombre,
                            value: p.id,
                            item: p
                        }))
                    }
                    onChange={(e) => {
                        if (e?.item) {
                            setPeriodoAcademico(e.item);
                            onInputChange({...estudiante.curso, periodoAcademico: e.item}, 'curso');
                        } else {
                            setPeriodoAcademico({});
                        }
                    }}
                    value={periodoAcademico && {
                        label: periodoAcademico?.label || periodoAcademico?.nombre,
                        value: periodoAcademico?.value || periodoAcademico?.id
                    }}
                />
                {isEdit && !isReservaCupo && <input type="hidden" name="inscripcion[periodo_academico_id]" value={periodoAcademico?.id} />}
                {errors.periodo_academico_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.periodo_academico_id[0]}
                    </small>
                )}
            </div>
            <div className='col-md-3 form-group'>
                <label className='label form-control-label'>
                    Grupo de Pago:
                </label>
                <Select
                    key="grupo_pago"
                    name='inscripcion[grupo_pago]'
                    placeholder='Seleccionar...'
                    options={gruposPagos.map(g => ({
                        label: g.label,
                        value: g.id,
                    }))}
                    onChange={({value}) => {
                        setEstudiante(prev => ({
                            ...prev,
                            curso: {
                                ...prev.curso,
                                grupoPago: value,
                            }
                        }))
                        setGrupo(value);
                    }}
                    value={grupo && gruposPagos.filter(g => g.id === grupo).map(g => ({
                        label: g.label,
                        value: g.id
                    }))}
                />
                {errors.periodo_academico_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.periodo_academico_id[0]}
                    </small>
                )}
            </div>
            <div className='col-md-3 form-group'>
                <label className='label form-control-label'>
                    Fecha Inscripción:
                </label>
                <input
                    type='date'
                    className='text-input form-control'
                    readOnly={isEdit && (!isReservaCupo && !isInscripcion)}
                    defaultValue={(isReservaCupo || isInscripcion) ? dayjs().format('YYYY-MM-DD') : inscripcion.fechaInscripcion && dayjs(inscripcion.fechaInscripcion).format('YYYY-MM-DD')}
                    name='inscripcion[fecha_inscripcion]'
                />
                {errors.fecha_inscripcion && (
                    <small className='help-blockParams form-Text'>
                        {errors.fecha_inscripcion[0]}
                    </small>
                )}
            </div>
            {/* <div className='col-md-3 form-group'>
                <label className='label form-control-label'>
                    Fecha Retiro:
                </label>
                <input
                    type='date'
                    className='text-input form-control'
                    defaultValue={inscripcion.fechaRetiro && dayjs(inscripcion.fechaRetiro).format('YYYY-MM-DD')}
                    name='inscripcion[fecha_retiro]'
                />
                {errors.fecha_retiro && (
                    <small className='help-blockParams form-Text'>
                        {errors.fecha_retiro[0]}
                    </small>
                )}
            </div> */}
        </div>
        <div className='row'>
            <div className='col-md-3 form-group'>
                <label className='label form-control-label'>
                    Grado:
                </label>
                <Select
                    cacheOptions
                    defaultOptions
                    key="grado"
                    isDisabled={isEdit && (!isReservaCupo && !isInscripcion)}
                    name='inscripcion[grado_id]'
                    placeholder='Seleccionar...'
                    onChange={e => {
                        setGrado(e?.grado);
                        setSeccion({});
                        onInputChange({...estudiante.curso, grado: e?.grado}, 'curso');
                        setTiposDocumentos(e?.grado.nivel?.documentos || []);
                    }}
                    options={grados && grados.map(g => ({
                        label: g.nombre,
                        value: g.id,
                        grado: g,
                    }))}
                    value={grado && {
                        label: grado.nombre,
                        value: grado.id,
                        grado: grado,
                    }}
                />
                {isEdit && <input type="hidden" name="inscripcion[grado_id]" value={grado?.id} />}
                {errors.grado_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.grado_id[0]}
                    </small>
                )}
            </div>
            <div className='col-md-3 form-group'>
                <label className='label form-control-label'>
                    Sección:
                </label>
                <Select
                    defaultOptions
                    key="seccion"
                    name='inscripcion[seccion_id]'
                    onChange={e => {
                        setSeccion(e?.seccion);
                        onInputChange({...estudiante.curso, seccion: e?.seccion}, 'curso');
                        if (e?.seccion) {
                            setGrado(e?.seccion?.grado);
                        }
                    }}
                    isClearable
                    placeholder='Seleccionar...'
                    options={secciones && secciones.filter(s => {
                        if (grado) {
                            return s.grado?.id === grado.id;
                        }
                        return true;
                    }).map(s => ({
                        label: s.nombre,
                        value: s.id, seccion: s
                    }))}
                    value={secciones.filter(s => s.id === seccion?.id).map( s => ({
                        label: s.nombre,
                        value: s.id,
                    }))}
                />
                {errors.seccion_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.seccion_id[0]}
                    </small>
                )}
            </div>

            <div className='col-md-3 form-group'>
                <label className='label form-control-label'>
                    Salida Optativa:
                </label>
                <SelectAsync
                    cacheOptions
                    defaultOptions
                    key="salida_optativa"
                    name='inscripcion[salida_optativa_id]'
                    placeholder='Seleccionar...'
                    isClearable
                    onChange={e => {
                        setSalidaOptativa({
                            nombre: e?.label,
                            id: e?.value
                        });
                    }}
                    loadOptions={() => {
                        return getOptions("academico/salidas-optativas", "nombre");
                    }}
                    defaultValue={inscripcion.salidaOptativa && {
                        label: inscripcion.salidaOptativa?.nombre,
                        value: inscripcion.salidaOptativa?.id,
                    }}
                />
                {errors.salida_optativa_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.salida_optativa_id[0]}
                    </small>
                )}
            </div>

            <div className='col-md-3 form-group'>
                <label className='label form-control-label'>
                    Beca:
                </label>
                <SelectAsync
                    cacheOptions
                    defaultOptions
                    isClearable
                    key="beca"
                    name='inscripcion[beca_id]'
                    placeholder='Seleccionar...'
                    onChange={e => {
                        if (e) {
                            setBeca(e?.item);
                        } else {
                            setBeca({});
                        }
                    }}
                    loadOptions={() => {
                        return getOptions("academico/becas", "nombre");
                    }}
                    value={beca && {
                        label: beca?.nombre,
                        value: beca?.id,
                        item: beca,
                    }}
                />
                {errors.beca && (
                    <small className='help-blockParams form-Text'>
                        {errors.beca[0]}
                    </small>
                )}
            </div>
        </div>
        {(isReservaCupo || isInscripcion) && (
            <>
                <div className='row'>
                    <div className="col-md-12 text-right">
                        {saving ? (
                            <Loader type="small" />
                        ) : (
                            <>
                                    <button type='button' onClick={() => setShowProcesarModal(true)} className='btn btn-primary float-right'>
                                        Realizar Pago
                                    </button>
                            </>
                        )}
                    </div>
                </div>
                {servicios.filter(s => s.nombre.includes('ensualidad')).map(servicio => {
                    const rank = gradosRanking[grado?.nombre];
                    const precio = servicio.configuracion[grupo]?.filter(c => {
                        return gradosRanking[c.grado_desde] <= rank && gradosRanking[c.grado_hasta] >= rank;
                    }).shift();

                    return (
                        <>
                            <input type="hidden" name={`servicios[${servicio.id}][nombre]`} value={servicio?.nombre} />
                            <input type="hidden" name={`servicios[${servicio.id}][monto]`} value={precio?.monto} />
                            <input type="hidden" name={`servicios[${servicio.id}][id]`} value={servicio.id} />
                            <input type="hidden" name={`servicios[${servicio.id}][cuotas]`} value={servicio.cuotas} />
                            <input type="hidden" name={`servicios[${servicio.id}][fecha]`} value={servicio.fechaInicio} />
                        </>
                    );
                })}
            </>
        )}
    </>);
}
