/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { request } from "../../../../utils/request";
import { formatCurrency, getSucursal } from "../../../../utils/helpers";
import dayjs from "dayjs";
import { apiRequest } from "../../../../hooks/apiRequest";

const tipos_impuesto = {
	itbis: "Itbis",
	otro: "Otro",
	isc: "ISC",
	propina_legal: "Propina",
	no_facturable: "No facturable",
	exento: "Exento",
};

const tiposComprobante = [
	"B01",
	"B02",
	"B14",
	"B15",
	"B16",
	"B11",
	"B03",
	"B11",
];

const searchParams = new URL(window.location).searchParams;
const isOriginal = searchParams.get("original") === "1";

const getDiasPlazo = (fecha, fecha_vencimiento) => {
	fecha = new Date(fecha);
	fecha_vencimiento = new Date(fecha_vencimiento);

	let difference = fecha.getTime() - fecha_vencimiento.getTime();
	let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
	return Math.abs(TotalDays);
};

const limitVendedorFullName = (name, lastName) => {
	name = name.split(" ");
	lastName = lastName.split(" ");

	return `${name[0]} ${lastName[0]}`;

	// if (name.length <= 2) return name.join(" ");

	// return name.slice(0, 3).join(" ");
};

const tipoITBIS = (impuesto) => {
	if (!impuesto) return "";
	if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0.16) {
		return "I1";
	} else if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0.18) {
		return "I2";
	} else if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0) {
		return "IE";
	}
};

const calcularImpuestos = (productos) => {
	let impuestos = {};

	if (productos) {
		productos.forEach((producto) => {
			const nombreImpuesto = `${tipos_impuesto[producto.impuesto.tipo]} ${
				producto.impuesto.porcentaje * 100
			}%`;

			if (
				!tipos_impuesto[producto.impuesto?.tipo] ||
				producto.impuesto?.porcentaje === 0
			) {
				return;
			}

			if (isNaN(impuestos[nombreImpuesto])) {
				impuestos[nombreImpuesto] = 0;
			}

			impuestos[nombreImpuesto] +=
				producto.cantidad *
				(producto.importe * producto.impuesto?.porcentaje).toFixed(2);
		});
	}

	return impuestos;
};

const calcularExentoGrabado = (productos) => {
	let exentoGrabado = {};

	if (productos) {
		productos.forEach((producto) => {
			const nombreImpuesto =
				producto.impuesto.tipo === "exento" ? "Exento" : "Grabado";

			if (isNaN(exentoGrabado[nombreImpuesto])) {
				exentoGrabado[nombreImpuesto] = 0;
			}

			exentoGrabado[nombreImpuesto] +=
				producto.importe * producto.cantidad;
		});
	}

	return exentoGrabado;
};

const PrintSingleFactura = () => {
	const { id } = useParams();
	const location = useLocation();

	const [factura, setFactura] = useState({
		productos: [],
	});
	const [sucursal, setSucursal] = useState({});
	const [infoBlock2Margin, setInfoBlock2Margin] = useState(10);
	const [isMobileDevice, setIsMobileDevice] = useState(false);

	const [sumatoriaFinal, setSumatoriaFinal] = useState({
		impuestos: {},
	});
	const [status, setStatus] = useState("Original");

	const getFactura = () => {
		request("facturas/" + id).then((res) => {
			setFactura(res.data);
			setTimeout(() => {
				window.print();
			}, 500);
		});
	};

	const isFactura = (factura) => {
		return (
			factura.entidad === "factura" &&
			tiposComprobante.findIndex(
				(tipo) => tipo === factura.numero_comprobante.substring(0, 3)
			) >= 0
		);
	};

	const onChangeStatusFactura = (estado) => {
		setStatus(estado);
	};

	useEffect(() => {
		getFactura();
		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);
		// window.addEventListener("afterprint", () => {
		// 	apiRequest(null, `facturas/${id}/update-printed`, "put");
		// });
		// identify if mobile
		let isMobile = navigator.userAgent.toLowerCase().match(/mobile/i),
			isTablet = navigator.userAgent.toLowerCase().match(/tablet/i),
			isAndroid = navigator.userAgent.toLowerCase().match(/android/i),
			isiPhone = navigator.userAgent.toLowerCase().match(/iphone/i),
			isiPad = navigator.userAgent.toLowerCase().match(/ipad/i);

		if (isMobile || isTablet || isAndroid || isiPhone || isiPad) {
			setIsMobileDevice(true);
		}
	}, []);
	useEffect(() => {
		setSumatoriaFinal((state) => ({
			...state,
			impuestos: calcularImpuestos(factura.productos),
			exentoGrabado: calcularExentoGrabado(factura.productos),
		}));
		// This is for calculate the margin of the second column of client info. that way the last row of each column will be alined\
		if (isMobileDevice) return;

		const infoBlock1 = document.getElementById("info-block-1");
		const infoBlock2 = document.getElementById("info-block-2");
		const count =
			infoBlock2.childElementCount - infoBlock1.childElementCount;

		if (count >= 5 && !factura.logo && !sucursal.logo) {
			setInfoBlock2Margin((count * 25 - 40) * -1);
		} else {
			setInfoBlock2Margin((count * 25 + 10) * -1);
		}

		if (
			!!factura.id &&
			!factura.se_ha_impreso &&
			parseFloat(factura.descuentoExtra) > 0
		) {
			apiRequest(null, `facturas/${factura.id}/update-printed`, "PUT");
		}
	}, [factura]);

	let notas = factura.notas;
	if (!isOriginal) {
		if (factura.descuentoExtra > 0) {
			notas += ` -- Descuento por nota de crédito: ${formatCurrency(
				factura.descuentoExtra
			)}`;
		}
	} else {
		let index = -1;
		if (isOriginal) {
			index = notas?.indexOf("Factura afectada");
			if (index > -1) {
				notas = notas.slice(0, index);
			}
			index = notas?.indexOf("FECHA DE PAGO");
			if (index > -1) {
				notas = notas.slice(0, index);
			}
		}
	}

	return (
		<>
			<FacturaTemplate
				factura={factura}
				sucursal={sucursal}
				infoBlock2Margin={infoBlock2Margin}
				sumatoriaFinal={sumatoriaFinal}
				isFactura={isFactura}
				status={status}
				isMobileDevice={isMobileDevice}
				notas={notas}
			/>
			{/* Segunda factura (Se usa en las facturas dobles) */}
			{location.pathname.includes("doble") && (
				<>
					<div
						style={{ borderTop: "2px dashed #000" }}
						className="my-4"
					></div>
					<FacturaTemplate
						factura={factura}
						sucursal={sucursal}
						infoBlock2Margin={infoBlock2Margin}
						sumatoriaFinal={sumatoriaFinal}
						isFactura={isFactura}
						status={status}
						isMobileDevice={isMobileDevice}
						notas={notas}
					/>
				</>
			)}
			<div className="ordernar-resultados no-print">
				<div className="form-group">
					<div className="radio-btn">
						<label>
							<input
								type="radio"
								onChange={() =>
									onChangeStatusFactura("Original")
								}
								name="orden"
								value="Original"
								defaultChecked={true}
							/>{" "}
							Original
						</label>
						<label>
							<input
								type="radio"
								onChange={() => onChangeStatusFactura("Copia")}
								name="orden"
								value="Copia"
							/>{" "}
							Copia
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export const FacturaTemplate = ({
	factura,
	sucursal,
	infoBlock2Margin,
	sumatoriaFinal,
	isFactura = () => false,
	status = "",
	isMobileDevice = false,
	notas = "",
}) => {

    const totalImpuestos = Object.keys(sumatoriaFinal.impuestos).reduce(
        (a, i) =>
            a +
            parseFloat(sumatoriaFinal.impuestos[i]),
        0
    );

	return (
		<>
			{isMobileDevice ? (
				<MobileLayout
					factura={factura}
					sucursal={sucursal}
					sumatoriaFinal={sumatoriaFinal}
					status={status}
					notas={notas}
                    totalImpuestos={totalImpuestos}
				/>
			) : (
				<>
					<DesktopLayout
						factura={factura}
						sucursal={sucursal}
						sumatoriaFinal={sumatoriaFinal}
						status={status}
						infoBlock2Margin={infoBlock2Margin}
						notas={notas}
                        totalImpuestos={totalImpuestos}
					/>
				</>
			)}
		</>
	);
};

const MobileLayout = ({
	factura,
	sucursal,
	sumatoriaFinal,
	isFactura = () => false,
	status,
	notas = "",
    totalImpuestos,
}) => {
	return (
		<>
			<style>
				{`
					@page {
						size: 90mm 300mm !important;
						margin-top: 30px !important;
						margin-bottom: 0px !important;
						margin-left: 0px !important;
						margin-right: 0px !important;
					}
					.invoice {
						width: 125mm;
						font-size: large !important;
					 }
					 body {
						width: 100% !important;
					}
					.invoice-table {
						display: none;
					}
					.sm-invoice-table {
						display: block;
					}

					.invoice h3 {
						margin-bottom: 0.1rem;
					}
					.invoice h3.signature-title {
						font-size: 0.9rem;
						font-weight: 600 !important;
					}
					.client-info-title {
						font-size: 19px;
						font-weight: 600 !important;
					}
					.info-cliente-responsive .info-client__response {
						font-size: 18px !important;
						line-height: 20px !important;
					}
					.sucursal-info h3 {
						font-size: 23px !important;
						margin-bottom: 8px !important;
						font-weight: 600 !important;
					}
					.invoice-name {
						font-weight: 600 !important;
					}
					.sucursal-info div, .invoice-fecha {
						font-size: 18px !important;
					}
					.sm-invoice-table > div.row {
						font-size: 17.5px !important;
					}
					.invoice-itbis-info p, .invoice-itbis-info div {
						font-size: 19px !important;
						line-height: 22px !important;
					}
					.summary-text {
						font-size: 20px !important;
						line-height: 20px !important;
					}
					.info-cliente-responsive {
						line-height: 20px !important;
						margin-bottom: 1rem !important;
						font-size: 20px !important;
						margin-left: 5px !important;
					}
					.info-cliente-responsive .row{
						margin-bottom: 6px;
					}
					@media(max-width: 575px){

    					.sucursal-name-sm{
    					    display:block;
    					}
    					.factura-logo{
    					    display:none;
    					}
    					.sucursal-name-lg{
    					    display:none;
    					}

						.info-cliente-responsive .row{
							margin-bottom: 0 !important ;
						}
						.summary-text{
							font-size:15.5px !important;
							// max-width:39% !important;
							width:auto !important;
							flex:auto;
							padding-left: 5px !important;
							padding-right: 2px !important;
						}
						.sm-invoice-table > div.row, .invoice h3.signature-title {
							font-size: 15.5px !important;
					   	}
						   .justify-content-between{
							justify-content: flex-end !important;
						}
						.justify-content-between :nth-child(2){
							text-align:left !important;
							max-width: 33% !important;
						}
					}
				`}
			</style>
			<div className="p-2 invoice recibo-print">
				<div className="clear-both p-t-30 p-b-10">
					<div className="sucursal-info sucursal-info__mobile">
						<h3 className="sucursal-name-sm">{sucursal.nombre}</h3>
						{sucursal.logo ? (
							<img
								alt="logo"
								className="mb-2 factura-logo"
								src={sucursal.logo[0].url}
								width="270px"
							/>
						) : factura.logo ? (
							<img
								alt="logo"
								className="mb-2 factura-logo"
								src={factura.logo[0].url}
								width="270px"
							/>
						) : (
							<h3
								className="sucursal-name-lg"
								style={{
									lineHeight: "16px",
									marginBottom: "3px",
								}}
							>
								{sucursal.nombre}
							</h3>
						)}
						<div
							className="mb-0"
							style={{ lineHeight: "16px", fontSize: "23px" }}
						>
							RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
						</div>
						<div
							className="mb-2"
							style={{ lineHeight: "16px", fontSize: "13px" }}
						>
							{sucursal.direccion}
						</div>
					</div>

					<div className="">
						<div
							className="mb-2 invoice-fecha d-block"
							style={{ fontSize: "medium", lineHeight: "16px" }}
						>
							<div className="d-flex fechaSpace">
								<div className="d-block">
									{getDiasPlazo(
										factura.fecha,
										factura.fecha_vencimiento
									) > 0 ? (
										<>
											<span>
												Plazo:{" "}
												{getDiasPlazo(
													factura.fecha,
													factura.fecha_vencimiento
												) || 0}{" "}
												días
											</span>
											<br />
										</>
									) : (
										<>
											<span>Plazo: Contado</span>
											<br />
										</>
									)}

									{factura.estado === "pagada" && (
										<span>
											Método de pago:{" "}
											<span className="text-capitalize">
												{factura.tipo_pago !== ""
													? factura.tipo_pago
													: "Efectivo"}
											</span>
										</span>
									)}
									{factura.tipo === "contado" &&
									factura.estado === "pendiente" ? (
										<span>Estado: Pendiente</span>
									) : (
										factura.tipo === "contado" &&
										factura.estado === "cancelada" && (
											<span>Estado: Cancelada</span>
										)
									)}
									<h3
										style={{
											fontSize: "18px",
											lineHeight: "14px",
											marginRight: "3px",
										}}
										className="d-block invoice-name"
									>
										{factura.entidad === "factura" &&
										tiposComprobante.findIndex(
											(tipo) =>
												tipo ===
												factura.numero_comprobante.substring(
													0,
													3
												)
										) >= 0
											? factura.numeracion?.nombre
											: "Factura"}
										{factura.entidad === "proforma" &&
											"Proforma"}
									</h3>
									<div
										className="d-block"
										style={{
											fontSize: "medium",
											lineHeight: "16px",
										}}
									></div>
									<span className="d-block client-info-title pt-2">
										{status}
									</span>
								</div>

								<div className="d-block">
									Fecha:{" "}
									{dayjs(factura.fecha).format("DD/MM/YYYY")}
									<br />
									<span>
										{factura.entidad === "factura" &&
										tiposComprobante.findIndex(
											(tipo) =>
												tipo ===
												factura.numero_comprobante.substring(
													0,
													3
												)
										) >= 0
											? "NCF: "
											: "COD. "}
										{factura.entidad === "proforma" &&
											"COD. "}
									</span>
									{factura.numero_comprobante}
									<br />
									{factura.numeracion?.fecha_vencimiento && (
										<>
											<span>FV:</span>{" "}
											{dayjs(
												factura.numeracion
													?.fecha_vencimiento
											).format("DD/MM/YYYY")}
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="info-cliente-responsive d-block">
					{factura.cliente && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Cliente:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.nombre}
							</div>
						</div>
					)}
					{factura.cliente?.identificacion && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									RNC/Cédula:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.identificacion}
							</div>
						</div>
					)}
					{factura.cliente?.encargado && (
						<div className="row">
							<div className="col-4 text-right px-1 client-info-title">
								Responsable:
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.encargado}
							</div>
						</div>
					)}
					{factura.cliente?.telefono && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Teléfono:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.telefono}
							</div>
						</div>
					)}
					{factura.cliente?.direccion && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Dirección:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "23px" }}
							>
								{factura.cliente?.direccion}
							</div>
						</div>
					)}
					{factura.vendedor?.nombre && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Vendedor:
								</span>
							</div>
							<div className="col-7 info-client__response">
								{limitVendedorFullName(
									factura.vendedor?.nombre,
									factura.vendedor?.apellido
								)}
							</div>
						</div>
					)}
				</div>

				<h3 className="table-title"></h3>
				{/* PRODUCTOS RESPONSIVE */}
				<div className="sm-invoice-table">
					<div className="row" style={{ fontWeight: "600" }}>
						<div className="col-5">DESCRIPCIÓN</div>
						<div className="col-3 px-0">IMPUESTOS</div>
						<div className="col-4" style={{ whiteSpace: "nowrap" }}>
							TOTAL
						</div>
					</div>

					{factura.productos?.map((producto) => {
						return (
							<div
								className="row"
								style={{
									fontSize: "small",
									marginBottom: "5px",
								}}
								key={producto.id}
							>
								<div
									className="col-5"
									style={{ lineHeight: "16px" }}
								>
									{`${producto.cantidad} x ${formatCurrency(
										producto.precio_facturado,
										""
									)}`}
									<br />
									{producto.producto?.nombre}
									<br />
									REF: {producto.producto?.referencia}
								</div>
								<div className="col-3 px-0">
									{Object.keys(sumatoriaFinal.impuestos)
										.length > 1 && (
										<span className="fw-600">
											{tipoITBIS(producto.impuesto)}{" "}
										</span>
									)}
									{formatCurrency(
                                        producto.precio_facturado - producto.importe
                                    )}
								</div>
								<div className="col-3 pr-0">
									{formatCurrency(producto.total, "$")}{" "}
								</div>
							</div>
						);
					})}
				</div>

				<div className="summary">
					{factura.descuento > 0 && (
						<div className="row justify-content-between">
							<div
								className="col-2 summary-text"
								style={{
									maxWidth: "8rem",
									lineHeight: "16px",
									textAlign: "end",
									fontWeight: "600",
								}}
							>
								Descuento
							</div>
							<div
								className="col-2 text-right summary-text"
								style={{ maxWidth: "9rem", lineHeight: "16px" }}
							>
								-{formatCurrency(factura.descuento)}
							</div>
						</div>
					)}
					<div className="row mt-3 justify-content-between justify-content-md-end">
						<div
							className="col-2 summary-text"
							style={{
								maxWidth: "8rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							Subtotal
						</div>
						<div
							className="col-2 text-right summary-text"
							style={{ maxWidth: "9rem", lineHeight: "16px" }}
						>
							{formatCurrency(factura.subtotal)}
						</div>
					</div>
					<div className="d-block">
						{sumatoriaFinal?.exentoGrabado?.Exento &&
							Object.keys(sumatoriaFinal.exentoGrabado).map(
								(impuesto, index) => {
									return (
										<div
											className="row justify-content-between"
											key={index}
										>
											<div
												className="col summary-text"
												style={{
													maxWidth: "8rem",
													lineHeight: "16px",
													fontWeight: "600",
												}}
											>
												{impuesto}
											</div>
											<div
												className="col-2 text-right summary-text"
												style={{
													maxWidth: "9rem",
													lineHeight: "16px",
												}}
											>
												{formatCurrency(
													sumatoriaFinal
														.exentoGrabado[impuesto]
												)}
											</div>
										</div>
									);
								}
							)}
					</div>
					<div className="d-block">
						{Object.keys(sumatoriaFinal.impuestos).map(
							(impuesto, index) => {
								return (
									<div
										className="row justify-content-between"
										key={index}
									>
										<div
											className="col summary-text"
											style={{
												maxWidth: "8rem",
												lineHeight: "16px",
												fontWeight: "600",
											}}
										>
											{impuesto}
										</div>
										<div
											className="col-2 text-right summary-text"
											style={{
												maxWidth: "9rem",
												lineHeight: "16px",
											}}
										>
											{formatCurrency(
												factura.sumatoria ? factura.sumatoria[impuesto.toUpperCase()] : sumatoriaFinal.impuestos[impuesto]
											)}
										</div>
									</div>
								);
							}
						)}
					</div>
					{!isOriginal &&
						factura.descuentoExtra > 0 &&
						!factura.se_ha_impreso && (
							<div className="row justify-content-between">
								<div
									className="col-3 summary-text"
									style={{
										maxWidth: "13rem",
										lineHeight: "16px",
										textAlign: "end",
										fontWeight: "600",
									}}
								>
									Descuento Nota de Crédito
								</div>
								<div
									className="col-2 text-right summary-text"
									style={{
										maxWidth: "9rem",
										lineHeight: "16px",
									}}
								>
									{formatCurrency(factura.descuentoExtra)}
								</div>
							</div>
						)}

					<div className="row justify-content-between">
						<div
							className="col summary-text"
							style={{
								maxWidth: "11rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							TOTAL A PAGAR
						</div>
						<div
							className="col-2 text-right summary-text"
							style={{
								maxWidth: "9rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							{formatCurrency(factura.total)}
						</div>
					</div>
					{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
						<hr className="mb-0" />
					)}

					<div className="invoice-itbis-info">
						{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
							<p
								style={{
									lineHeight: "16px",
									fontSize: "small",
								}}
							>
								{Object.keys(sumatoriaFinal.impuestos).includes(
									"Itbis 16%"
								) && (
									<>
										<span>
											Items con I1 son gravados con 16%
											ITBIS
										</span>
										<br />
									</>
								)}
								{Object.keys(sumatoriaFinal.impuestos).includes(
									"Itbis 18%"
								) && (
									<span>
										Items con I2 son gravados con 18% ITBIS
									</span>
								)}
							</p>
						)}

						{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
							<div className="row justify-content-between">
								<div
									className="col-6 pr-1"
									style={{
										maxWidth: "11rem",
										fontSize: "small",
										lineHeight: "16px",
										fontWeight: "600",
										whiteSpace: "nowrap",
									}}
								>
									TOTAL IMPUESTOS
								</div>
								<div
									className="col-2"
									style={{
										maxWidth: "9rem",
										fontSize: "small",
										lineHeight: "16px",
										fontWeight: "600",
									}}
								>
									{formatCurrency(
										Object.keys(
											sumatoriaFinal.impuestos
										).reduce(
											(a, i) =>
												a +
												parseFloat(
													sumatoriaFinal.impuestos[i]
												),
											0
										)
									)}
								</div>
							</div>
						)}
					</div>
				</div>

				{!isOriginal && factura.retencion && (
					<div className="m-t-15 m-b-15">
						Retención: {formatCurrency(factura.retencion)}
					</div>
				)}

				<hr className="d-block mt-0" />
				{notas && (
					<>
						<p style={{ fontWeight: "600", fontSize: "1rem" }}>
							Notas:
						</p>
						<p
							className="col-12 pl-0 mb-3"
							style={{
								fontSize: "1rem",
								whiteSpace: "pre-line",
								lineHeight: "normal",
							}}
						>
							{notas}
						</p>
					</>
				)}

				<div className="row justify-content-center mt-5">
					<h3
						className="col-10 text-center mr-0 fw-600 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Elaborado por
					</h3>
					<h3
						className="col-10 text-center mt-5 mr-0 fw-600 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Entregado Por
					</h3>
					<h3
						className="col-10 text-center ml-0 fw-600 mt-5 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Recibido por
					</h3>
				</div>

				{factura.plantilla?.pie_pagina && (
					<div
						className="col-12 pt-5 text-center d-flex justify-content-center"
						style={{
							fontWeight: "600",
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						<p className="text-center pie-pagina">
							{factura.plantilla?.pie_pagina}
						</p>
					</div>
				)}
			</div>
		</>
	);
};

const DesktopLayout = ({
	factura,
	sucursal,
	sumatoriaFinal,
	infoBlock2Margin,
	status,
	isFactura = () => false,
	notas = "",
    totalImpuestos,
}) => {
	let isServicios = false;
	const cantidadProductos = factura?.productos?.reduce((c, a) => {
		if (a.producto.tipo === "servicio") {
			isServicios = true;
		}
		return a.cantidad + c;
	}, 0);

	return (
		<div className="p-2 invoice recibo-print">
			<div className="clear-both p-t-30 p-b-10">
				<div className="float-md-left sucursal-info">
					{sucursal.logo ? (
						<img
							alt="logo"
							className="mb-2 factura-logo"
							src={sucursal.logo[0].url}
							width="270px"
						/>
					) : factura.logo ? (
						<img
							alt="logo"
							className="mb-2 factura-logo"
							src={factura.logo[0].url}
							width="270px"
						/>
					) : (
						<h3 style={{ lineHeight: "12px", marginBottom: "3px" }}>
							{sucursal.nombre}
						</h3>
					)}
					<div
						className="mb-0 mb-md-0"
						style={{ lineHeight: "15px", fontSize: "13px" }}
					>
						RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
					</div>
					<div
						className="mb-2 mb-md-0"
						style={{ lineHeight: "15px", fontSize: "13px" }}
					>
						{sucursal.direccion}
					</div>
				</div>

				<div className="float-md-right text-md-right">
					<div
						className="mb-2 invoice-fecha d-block d-md-none"
						style={{ fontSize: "small", lineHeight: "15px" }}
					>
						Fecha: {dayjs(factura.fecha).format("DD/MM/YYYY")}
						<br />
						{getDiasPlazo(
							factura.fecha,
							factura.fecha_vencimiento
						) > 0 ? (
							<>
								<span>
									Plazo:{" "}
									{getDiasPlazo(
										factura.fecha,
										factura.fecha_vencimiento
									) || 0}{" "}
									días
								</span>
								<br />
							</>
						) : (
							<>
								<span>Plazo: Contado</span>
								<br />
							</>
						)}
						{factura.tipo === "contado" &&
						factura.estado === "pendiente" ? (
							<span>Estado: Pendiente</span>
						) : (
							factura.tipo === "contado" &&
							factura.estado === "cancelada" && (
								<span>Estado: Cancelada</span>
							)
						)}
						{factura.estado === "pagada" && (
							<span>
								Método de pago:{" "}
								<span className="text-capitalize">
									{factura.tipo_pago !== ""
										? factura.tipo_pago
										: "Efectivo"}
								</span>
							</span>
						)}
					</div>
					<h3
						style={{
							fontSize: "15px",
							lineHeight: "12px",
							marginBottom: "3px",
						}}
						className="d-block d-md-none"
					>
						{factura.entidad === "factura" &&
						tiposComprobante.findIndex(
							(tipo) =>
								tipo ===
								factura.numero_comprobante.substring(0, 3)
						) >= 0
							? factura.numeracion?.nombre
							: "Factura"}
						{factura.entidad === "proforma" && "Proforma"}
					</h3>
					<div
						className="d-block d-md-none"
						style={{ fontSize: "small", lineHeight: "12px" }}
					>
						<span>
							{factura.entidad === "factura" &&
							tiposComprobante.findIndex(
								(tipo) =>
									tipo ===
									factura.numero_comprobante.substring(0, 3)
							) >= 0
								? "NCF: "
								: "COD. "}
							{factura.entidad === "proforma" && "COD. "}
						</span>
						{factura.numero_comprobante}
						<br />
						{factura.numeracion?.fecha_vencimiento && (
							<>
								<span>FV:</span>{" "}
								{dayjs(
									factura.numeracion?.fecha_vencimiento
								).format("DD/MM/YYYY")}
							</>
						)}
					</div>
					<span className="d-block d-md-none client-info-title pt-2">
						{/* {status} */}Original
					</span>
				</div>
			</div>

			<div className="info-cliente-responsive d-block d-md-none">
				{factura.cliente && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Cliente:</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.nombre}
						</div>
					</div>
				)}
				{factura.cliente?.identificacion && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								RNC/Cédula:
							</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.identificacion}
						</div>
					</div>
				)}
				{factura.cliente?.encargado && (
					<div className="row">
						<div className="col-4 text-right px-1 client-info-title">
							Responsable:
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.encargado}
						</div>
					</div>
				)}
				{factura.cliente?.telefono && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Teléfono:</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.telefono}
						</div>
					</div>
				)}
				{factura.cliente?.direccion && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								Dirección:
							</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.direccion}
						</div>
					</div>
				)}
				{factura.vendedor?.nombre && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Vendedor:</span>
						</div>
						<div className="col-7">
							{limitVendedorFullName(
								factura.vendedor?.nombre,
								factura.vendedor?.apellido
							)}
						</div>
					</div>
				)}
			</div>

			<div className="clear-both info-row p-t-10 p-b-10 d-none d-md-flex">
				<div className="info-block" style={{ width: "65%" }}>
					<table>
						<tbody id="info-block-1">
							{factura.cliente?.nombre && (
								<tr>
									<td
										width="120px"
										className="client-info-title text-uppercase text-right"
									>
										Cliente:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{factura.cliente?.nombre}
									</td>
								</tr>
							)}
							{factura.cliente?.identificacion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										RNC/Cédula:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.cliente?.identificacion}
									</td>
								</tr>
							)}
							{factura.cliente?.encargado && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Responsable:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.cliente?.encargado}
									</td>
								</tr>
							)}
							{factura.cliente?.telefono && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Teléfono:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.cliente?.telefono}
									</td>
								</tr>
							)}
							{factura.cliente?.direccion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Dirección:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{factura.cliente?.direccion}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<div
					className="info-block"
					style={{ marginTop: infoBlock2Margin }}
				>
					<table>
						<tbody id="info-block-2">
							<tr>
								<td style={{ paddingLeft: "10px" }}>
									<h3
										style={{
											fontSize: "0.9rem",
											textAlign: "end",
										}}
									>
										{status}
									</h3>
								</td>
							</tr>
							<tr>
								<td colSpan={isFactura(factura) ? 2 : 1}>
									<h3
										style={{
											fontSize: "0.9rem",
											marginBottom: "3px",
											paddingBottom: "10px",
											whiteSpace: "nowrap",
											paddingLeft: "25%",
											textAlign: isFactura(factura)
												? "left"
												: "right",
										}}
									>
										{isFactura(factura)
											? factura.numeracion?.nombre
											: "Factura"}
									</h3>
								</td>
							</tr>
							{factura.fecha && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Fecha:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{dayjs(factura.fecha).format(
											"DD/MM/YYYY"
										)}
									</td>
								</tr>
							)}
							{factura.numero_comprobante?.indexOf("P") ===
								-1 && (
								<tr>
									<td colSpan={2}>
										<div className="text-center">
											<small>
												{factura.numeracion.nombre}
											</small>
										</div>
									</td>
								</tr>
							)}
							{factura.numero_comprobante && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										{factura.entidad === "factura" &&
										tiposComprobante.findIndex(
											(tipo) =>
												tipo ===
												factura.numero_comprobante.substring(
													0,
													3
												)
										) >= 0
											? "NCF:"
											: "NO.:"}
										{factura.entidad === "proforma" &&
											"NO.:"}
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.numero_comprobante}
									</td>
								</tr>
							)}

							{getDiasPlazo(
								factura.fecha,
								factura.fecha_vencimiento
							) > 0 ? (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Plazo:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{getDiasPlazo(
											factura.fecha,
											factura.fecha_vencimiento
										) || 0}{" "}
										días
									</td>
								</tr>
							) : (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Plazo:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										Contado
									</td>
								</tr>
							)}
							{factura.tipo === "contado" &&
							factura.estado === "pendiente" ? (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Estado:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										Pendiente
									</td>
								</tr>
							) : (
								factura.tipo === "contado" &&
								factura.estado === "cancelada" && (
									<tr>
										<td className="client-info-title text-uppercase text-right">
											Estado:
										</td>
										<td style={{ paddingLeft: "10px" }}>
											Cancelada
										</td>
									</tr>
								)
							)}

							{factura.estado === "pagada" && (
								<tr>
									<td
										className="client-info-title text-uppercase text-right"
										style={{ whiteSpace: "nowrap" }}
									>
										Método de pago:
									</td>
									<td
										style={{
											paddingLeft: "10px",
											textTransform: "capitalize",
										}}
									>
										{factura.tipo_pago !== ""
											? factura.tipo_pago
											: "Efectivo"}
									</td>
								</tr>
							)}

							{factura.numeracion?.fecha_vencimiento && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										FV:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{dayjs(
											factura.numeracion
												?.fecha_vencimiento
										).format("DD/MM/YYYY")}
									</td>
								</tr>
							)}
							{factura.vendedor?.nombre && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Vendedor:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{limitVendedorFullName(
											factura.vendedor?.nombre,
											factura.vendedor?.apellido
										)}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			<div className="table-wrapper p-b-10">
				{/* eslint-disable-next-line jsx-a11y/heading-has-content */}
				<h3 className="table-title"></h3>
				<table className="table d-none d-md-table">
					<thead>
						<tr className="text-uppercase">
							<th width="12%">Código/Ref.</th>
							<th width="25%">Descripción</th>
							<th width="8%">Cantidad</th>
							<th width="8%">Unidad</th>
							<th>Importe</th>
							<th>Impuesto</th>
							<th>Precio</th>
							{factura.descuento > 0 && <th>Descuento</th>}
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{factura?.productos?.map((producto) => {
							const descuento =
								producto.cantidad *
								(producto.importe +
									producto.importe *
										producto.impuesto?.porcentaje) *
								(producto.descuento / 100);

                            const descuentoReal =
                                (producto.importe *
                                (producto.descuento / 100))
                                * producto.cantidad;
							return (
								<tr key={producto.id}>
									<td>
										<div style={{ lineHeight: 1.2 }}>
											<div>
												{producto.producto?.referencia}
											</div>
										</div>
									</td>
									<td>{producto.producto?.nombre}</td>
									<td>{producto.cantidad}</td>
									<td>{producto.producto?.unidad}</td>
									<td>
										{formatCurrency(producto.importe, "$")}
									</td>
									<td style={{ whiteSpace: "nowrap" }}>
										{Object.keys(sumatoriaFinal.impuestos)
											.length > 1 && (
											<span className="fw-600">
												{tipoITBIS(producto.impuesto)}{" "}
											</span>
										)}
										{formatCurrency(
											producto.precio_facturado - producto.importe
										)}
									</td>
									<td>
										{formatCurrency(
											producto.total / producto.cantidad
										)}
									</td>
									{factura.descuento > 0 && (
										<td>{formatCurrency(descuentoReal)}</td>
									)}
									<td>
										{formatCurrency(producto.total - descuento)}
									</td>
								</tr>
							);
						})}
						{!isServicios && (
							<tr>
								<td></td>
								<td></td>
								<td>{cantidadProductos}</td>
								<td colSpan="42"></td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			<p className="summary">
				{!isOriginal && factura.retencion && (
					<span className="srow">
						<strong>Retención:</strong>
						<span className="svalue">
							{formatCurrency(factura.retencion)}
						</span>
						<br />
					</span>
				)}
				{factura.descuento > 0 && (
					<span className="srow">
						<strong>Descuento</strong>
						<span className="svalue">
							-{formatCurrency(factura.descuento)}
						</span>
						<br />
					</span>
				)}
				<span className="srow">
					<strong>Subtotal</strong>
					<span className="svalue">
						{formatCurrency(factura.subtotal)}
					</span>
					<br />
				</span>
				{sumatoriaFinal?.exentoGrabado?.Exento &&
					Object.keys(sumatoriaFinal.exentoGrabado).map(
						(impuesto, index) => {
							return (
								<span className="srow" key={index}>
									<strong>{impuesto}</strong>
									<span className="svalue">
										{formatCurrency(
											sumatoriaFinal.exentoGrabado[
												impuesto
											],
											"$"
										)}
									</span>
									<br />
								</span>
							);
						}
					)}
				{Object.keys(sumatoriaFinal.impuestos).map(
					(impuesto, index) => {
						return (
							<span className="srow" key={index}>
								<strong>{impuesto}</strong>
								<span className="svalue">
									{formatCurrency(
										factura.sumatoria ? factura.sumatoria[impuesto.toUpperCase()] : sumatoriaFinal.impuestos[impuesto],
										"$"
									)}
								</span>
								<br />
							</span>
						);
					}
				)}
				{!isOriginal &&
					factura.descuentoExtra > 0 &&
					!factura.se_ha_impreso && (
						<span className="srow">
							<strong>Descuento Nota de Crédito</strong>
							<span className="svalue">
								{formatCurrency(factura.descuentoExtra)}
							</span>
							<br />
						</span>
					)}
				<span className="srow">
					<strong>TOTAL FACTURADO</strong>
					<strong className="svalue">
						{formatCurrency(
							factura.total -
								(isOriginal ? 0 : factura.descuentoExtra)
						)}
					</strong>
					<br />
				</span>

				{!isOriginal &&
					factura.porCobrar > 0 &&
					factura.porCobrar <
						factura.total - factura.descuentoExtra && (
						<span className="srow">
							<strong>TOTAL ABONADO</strong>
							<span className="svalue">
								{formatCurrency(
									factura.total -
										factura.descuentoExtra -
										factura.porCobrar,
									"$"
								)}
							</span>
							<br />
							<strong>TOTAL PENDIENTE</strong>
							<span className="svalue">
								{formatCurrency(factura.porCobrar, "$")}
							</span>
							<br />
						</span>
					)}
			</p>
			{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
				<hr className="mb-0" />
			)}
			<div className="text-md-right invoice-itbis-info">
				{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
					<p
						style={{
							lineHeight: "16px",
							fontSize: "small",
						}}
					>
						{Object.keys(sumatoriaFinal.impuestos).includes(
							"Itbis 16%"
						) && (
							<>
								<span>
									Items con I1 son gravados con 16% ITBIS
								</span>
								<br />
							</>
						)}
						{Object.keys(sumatoriaFinal.impuestos).includes(
							"Itbis 18%"
						) && (
							<span>Items con I2 son gravados con 18% ITBIS</span>
						)}
					</p>
				)}
				{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
					<div className="row justify-content-between justify-content-md-end">
						<div
							className="col col-md-6 pr-1"
							style={{
								maxWidth: "11rem",
								fontSize: "small",
								lineHeight: "14px",
								fontWeight: "600",
							}}
						>
							TOTAL IMPUESTOS
						</div>
						<div
							className="col-2"
							style={{
								maxWidth: "9rem",
								fontSize: "small",
								lineHeight: "14px",
								fontWeight: "600",
							}}
						>
							{formatCurrency(
								Object.keys(sumatoriaFinal.impuestos).reduce(
									(a, i) =>
										a +
										parseFloat(sumatoriaFinal.impuestos[i]),
									0
								)
							)}
						</div>
					</div>
				)}
			</div>

			<hr className="d-block d-md-none mt-0" />
			{notas && (
				<>
					<p style={{ fontWeight: "600", fontSize: "1rem" }}>
						Notas:
					</p>
					<p
						className="col-12 col-md-8 pl-0 mb-3"
						style={{
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						{notas}
					</p>
				</>
			)}

			<div className="footer-facturas">
				<div className="row justify-content-center mt-5">
					<h3
						className="col-10 col-md-3 text-center mr-md-2 mr-0 mt-md-4 fw-600 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Elaborado por
					</h3>
					<div className="col-md-1 d-none d-md-block"></div>
					<h3
						className="col-10 col-md-3 text-center mr-md-2 mr-0 mt-md-4 fw-600 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Entregado Por
					</h3>
					<div className="col-md-1 d-none d-md-block"></div>
					<h3
						className="col-10 col-md-3 text-center ml-md-2 ml-0 fw-600 mt-5 mt-md-4 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Recibido por
					</h3>
				</div>

				{factura.plantilla?.pie_pagina && (
					<div
						className="col-12 pt-5 text-center d-flex justify-content-center"
						style={{
							fontWeight: "600",
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						<p className="text-center pie-pagina">
							{factura.plantilla?.pie_pagina}
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default PrintSingleFactura;
