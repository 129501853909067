import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { apiRequest } from "../../../hooks/apiRequest";

const Form = ({ inscripcion, errors, grados, secciones, periodosAcademicos, salidasOptativas, estudiantes, tutores, becas, gruposPagos, editar }) => {

    const [tutorId, setTutorId] = useState('');
    const [estudiante, setEstudiante] = useState('');
    const [grupo, setGrupo] = useState(inscripcion.grupoPago || '');
    const [grado, setGrado] = useState(inscripcion.grado?.nombre || '');
    const [servicios, setServicios] = useState([]);

    const filteredEstudiantes = estudiantes.filter(e => {
        if (tutorId === '') { return true; }
        var resultado = e.tutores.filter(t => t.id === tutorId);
        return resultado.length > 0;
    });

    const getServicios = async () => {
        const url = "academico/pagos-educativos";
        const response = await apiRequest    ({}, url, 'get');
        setServicios(response.data);
    }

    const onChangeEstudiante = (estudiante) => {
        setEstudiante(estudiante);
    }

    useEffect(() => {
        getServicios();
    }, []);

    return (
        <div>
            <div className="row">
                {!editar && (
                    <div className='col-md-6 form-group'>
                        <label className='label form-control-label'>
                            Filtrar por Tutor:
                        </label>
                        <Select
                            key="tutor"
                            placeholder='Seleccionar...'
                            menuPosition='fixed'
                            menuPlacement='auto'
                            isClearable
                            onChange={e => setTutorId(e ? e.value : '')}
                            options={tutores && tutores.map(p => ({
                                label: p.nombreCompleto,
                                value: p.id,
                            }))}
                        />
                    </div>
                )}
                <div className='col form-group'>
                    <label className='label form-control-label'>
                        Estudiante:
                    </label>
                    <Select
                        key="estudiante"
                        name='estudiante_id'
                        isDisabled={editar}
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        onChange={e => {
                            onChangeEstudiante(e.estudiante);
                        }}
                        options={filteredEstudiantes.length > 0 && filteredEstudiantes.map(e => ({
                            label: `${e.matricula || ''} - ${e.nombreCompleto}`,
                            value: e.id,
                            estudiante: e,
                        }))}
                        defaultValue={inscripcion.estudiante && {
                            label: inscripcion.estudiante?.nombreCompleto,
                            value: inscripcion.estudiante?.id,
                            estudiante: inscripcion.estudiante,
                        }}
                    />
                    {editar && (
                        <input type="hidden" name="estudiante_id" value={inscripcion.estudiante?.id} />
                    )}
                    {errors.estudiante_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.estudiante_id[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className="row">
                <div className='col-md-6 form-group'>
                    <label className='label form-control-label'>
                        Grupo de Pago:
                    </label>
                    <Select
                        key="grupo_pago"
                        name='grupo_pago'
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        onChange={e => {
                            setGrupo(e?.value);
                        }}
                        options={gruposPagos && gruposPagos.map(p => ({
                            label: p.label,
                            value: p.id,
                        }))}
                        value={grupo && gruposPagos.filter(g => g.id === grupo).map(g => ({
                            label: g.label,
                            value: g.id,
                        }))}
                    />
                    {errors.producto_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.producto_id[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-3 form-group'>
                    <label className='label form-control-label'>
                        Fecha Inscripción:
                    </label>
                    <input
                        type='date'
                        className='text-input form-control'
                        defaultValue={inscripcion.fechaInscripcion ? dayjs(inscripcion.fechaInscripcion).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')}
                        name='fecha_inscripcion'
                    />
                    {errors.fecha_inscripcion && (
                        <small className='help-blockParams form-Text'>
                            {errors.fecha_inscripcion[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-3 form-group'>
                    <label className='label form-control-label'>
                        Año Escolar:
                    </label>
                    <Select
                        key="periodo_academico"
                        name='periodo_academico_id'
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        options={periodosAcademicos && periodosAcademicos.map(p => ({
                            label: p.nombre,
                            value: p.id,
                        }))}
                        defaultValue={inscripcion.periodoAcademico && {
                            label: inscripcion.periodoAcademico?.nombre,
                            value: inscripcion.periodoAcademico?.id
                        }}
                    />
                    {errors.periodo_academico_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.periodo_academico_id[0]}
                        </small>
                    )}
                </div>
                {/* <div className='col-md-3 form-group'>
                    <label className='label form-control-label'>
                        Fecha Retiro:
                    </label>
                    <input
                        type='date'
                        className='text-input form-control'
                        defaultValue={inscripcion.fechaRetiro && dayjs(inscripcion.fechaRetiro).format('YYYY-MM-DD')}
                        name='fecha_retiro'
                    />
                    {errors.fecha_retiro && (
                        <small className='help-blockParams form-Text'>
                            {errors.fecha_retiro[0]}
                        </small>
                    )}
                </div> */}
            </div>
            <div className='row'>
                <div className='col-md-3 form-group'>
                    <label className='label form-control-label'>
                        Grado:
                    </label>
                    <Select
                        key="grado"
                        name='grado_id'
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        onChange={e => {
                            setGrado(e?.label);
                        }}
                        options={grados && grados.map(p => ({
                            label: p.nombre,
                            value: p.id,
                        }))}
                        defaultValue={inscripcion.grado && {
                            label: inscripcion.grado?.nombre,
                            value: inscripcion.grado?.id
                        }}
                    />
                    {errors.grado_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.grado_id[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-3 form-group'>
                    <label className='label form-control-label'>
                        Sección:
                    </label>
                    <Select
                        key="seccion"
                        name='seccion_id'
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        options={secciones && secciones.map(p => ({
                            label: p.nombre,
                            value: p.id,
                        }))}
                        defaultValue={inscripcion.seccion && {
                            label: inscripcion.seccion?.nombre,
                            value: inscripcion.seccion?.id
                        }}
                    />
                    {errors.seccion_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.seccion_id[0]}
                        </small>
                    )}
                </div>

                <div className='col-md-3 form-group'>
                    <label className='label form-control-label'>
                        Salida Optativa:
                    </label>
                    <Select
                        key="salida_optativa"
                        name='salida_optativa_id'
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        options={salidasOptativas && salidasOptativas.map(p => ({
                            label: p.nombre,
                            value: p.id,
                        }))}
                        defaultValue={inscripcion.salidaOptativa && {
                            label: inscripcion.salidaOptativa?.nombre,
                            value: inscripcion.salidaOptativa?.id
                        }}
                    />
                    {errors.salida_optativa_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.salida_optativa_id[0]}
                        </small>
                    )}
                </div>

                <div className='col-md-3 form-group'>
                    <label className='label form-control-label'>
                        Beca:
                    </label>
                    <Select
                        key="beca"
                        name='beca_id'
                        isClearable
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        options={becas && becas.map(b => ({
                            label: b.nombre,
                            value: b.id,
                        }))}
                        defaultValue={inscripcion.beca && {
                            label: inscripcion.beca?.nombre,
                            value: inscripcion.beca?.id
                        }}
                    />
                    {errors.beca && (
                        <small className='help-blockParams form-Text'>
                            {errors.beca[0]}
                        </small>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Form;
