import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getLink, getOptionsForDropdown, renderEstadoBadge, toast } from "../../../utils/helpers";

// COMPONENTS
import Select from "react-select";
import IndexPageLayout from "../../general/CRUDLayout/IndexPageLayout";
import useFilters from "../../../hooks/useFilters";
import Dropdown from "../../general/Dropdown";
import dayjs from "dayjs";
import { apiRequest } from "../../../hooks/apiRequest";
import Modal from "../../general/Modal";

const NotasCredito = () => {
	const [showCancel, setShowCancel] = useState(false);
	const [saving, setSaving] = useState(false);
	const [notaCredito, setNotaCredito] = useState({});
	const [triggerRequest, setTriggerRequest] = useState(0);
    const [numeraciones, setNumeraciones] = useState([]);

	const { filters, handleFilterChange, filtersQuery } = useFilters({
		buscar: "",
		tipo: "",
		desde: dayjs().format("YYYY-MM-DD"),
		hasta: dayjs().format("YYYY-MM-DD"),
		estado: "",
	});

	const TABLE_COLUMNS = [
		{
			title: "NCF/Número",
			tdContent: (row) => <span>{row.numeracion}</span>,
		},
		{
			title: "Contacto",
			tdContent: (row) => <span>{row.contacto?.nombre}</span>,
		},
		{
			title: "Fecha",
			tdContent: (row) => (
				<span>{dayjs(row.fecha).format("DD/MM/YYYY")}</span>
			),
		},
		{
			title: "Tipo",
			tdContent: (row) => <span style={{textTransform: 'capitalize'}}>{row.tipo}</span>,
		},
		{
			title: "Razón",
			tdContent: (row) => <span>{row.razon}</span>,
		},
		{
			title: "Factura afectada",
			tdContent: (row) => (
				<span>
					{row.tipo === "venta"
						? row.factura?.numero_comprobante
						: row.compra?.ncf || row.compra?.numero}
				</span>
			),
		},
		{
			title: "Total",
			tdContent: (row) => <span>{row.total}</span>,
		},
		{
			title: "Estado",
			tdContent: (row) => <span>{renderEstadoBadge(row.estado)}</span>,
		},
		{
			title: "",
			tdContent: (row) => (
				<Dropdown>
					<Link
						to={getLink(`/imprimir/notas-credito/${row.id}`)}
						target="_blank"
						className="dropdown-item"
						title="Impresión normal"
					>
						Impresión
					</Link>
					{
						(row.estado !== "cancelada" && (
							<>
								<Link
									to={getLink(
										`/consultas/notas-credito/${row.id}/editar`
									)}
									className="dropdown-item"
									title="Editar"
								>
									Editar
								</Link>
								<button
									onClick={() => toggleCancelModal(row)}
									className="dropdown-item"
									title="cancelar"
								>
									Cancelar
								</button>
							</>
						))
					}
					{row.notaFirmada?.length > 0 && <a
                                                                        href={row.notaFirmada[0].url}
                                                                        className="dropdown-item"
                                                                        target="_blank" rel="noreferrer"
                                                                    >
                                                                        Nota Firmada
                                                                    </a>}
				</Dropdown>
			),
		},
	];

	const toggleCancelModal = (notaCredito) => {
		setShowCancel(true);
		setNotaCredito(notaCredito);
	};

	const cancelNotaCredito = async (notaCreditoID) => {
		setShowCancel((state) => !state);
		setSaving(true);

		const response = await apiRequest(
			null,
			`notas-credito/${notaCreditoID}`,
			"DELETE"
		);

		if (response.success) {
			toast("Nota de Crédito Cancelada", "success");
			setTriggerRequest((state) => state + 1);
			return;
		}
		toast("Error cancelando Nota de Crédito", "error");

		setSaving(false);
	};

    useEffect(() => {
        getOptionsForDropdown('numeracion-documentos?tipo_documento=factura_venta').then((data) => {
			setNumeraciones(data);
		});
    }, [])

	return (
		<>
			<IndexPageLayout
				pageHeaderProps={{
					title: "Notas de Crédito de Compras y Ventas",
					hasButton: false,
					children: <HeaderChildren filtersQuery={filtersQuery} />,
				}}
				filters={{
					children: (
						<Filters
							handleFilterChange={handleFilterChange}
                            numeraciones={numeraciones}
							filters={filters}
						/>
					),
				}}
				dataUrl={`notas-credito${filtersQuery}`}
				tableColumns={TABLE_COLUMNS}
				triggerRequest={triggerRequest}
                numeraciones={numeraciones}
			/>
			{/* ---CONFIRMACIÓN CANCELAR FACTURA--- */}
			<Modal
				title="Cancelar Factura"
				show={showCancel}
				callback={() => cancelNotaCredito(notaCredito.id)}
				acceptText="Aceptar"
				toggle={toggleCancelModal}
			>
				¿Estás seguro de que deseas cancelar esta nota de credito? (Esta acción
				no se puede deshacer).
			</Modal>
		</>
	);
};

const HeaderChildren = ({ filtersQuery }) => {
	return (
		<>
			<div className="d-inline-block float-right h-100 d-flex align-items-center">
                <div className="dropdown m-r-10 d-none d-lg-inline-block">
                    <button className="au-btn au-btn-icon au-btn-filter">
						<i className="zmdi zmdi-print"></i> Reportes
					</button>
                    <ul
						style={{ right: "0", left: "auto" }}
						className="dropdown-menu"
					>
                        <li>
                            <Link
                                to={getLink(`/imprimir/notas-credito${filtersQuery}`)}
                                target="_blank"
                                className="dropdown-item"
                            >
                                Reporte Notas Credito
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={getLink(`/imprimir/notas-credito-detalles${filtersQuery}`)}
                                target="_blank"
                                className="dropdown-item"
                            >
                                Detalle de Impuestos
                            </Link>
                        </li>
                    </ul>
                </div>
				<Link
					to={getLink(`/consultas/notas-credito/crear`)}
					className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15"
				>
					<i className="zmdi zmdi-plus" /> Añadir Nota de Crédito
				</Link>
			</div>
		</>
	);
};

const Filters = ({ handleFilterChange, filters, numeraciones }) => {
	return (
		<>
			<div className="col">
				<label>Buscar</label>
				<input
					type="search"
					placeholder="Buscar..."
					className="form-control"
					name="buscar"
					onChange={(e) =>
						handleFilterChange("buscar", e.target.value)
					}
				/>
			</div>

			<div className="col-12 col-md-6 col-lg-2">
			<label>Estado</label>
				<Select
					name="estado"
                    isClearable
					isMulti
					onChange={(e) => {
                        handleFilterChange("estado", e);
                    }}
					options={[
						{ value: "completada", label: "Completada" },
						{ value: "cancelada", label: "Cancelada" },
					]}
				/>
			</div>

			<div className="col-12 col-md-6 col-lg-2">
				<label>Tipo</label>
				<Select
					name="tipo"
                    isClearable
					onChange={(e) => {
                        handleFilterChange("numeracion", "");
                        handleFilterChange("tipo_factura", "");
                        handleFilterChange("tipo", e?.value);
                    }}
					options={[
						{ value: "", label: "Todas" },
						{ value: "venta", label: "Venta" },
						{ value: "compra", label: "Compra" },
					]}
				/>
			</div>
            {filters.tipo !== 'compra' && (
                <div className="col-12 col-md-6 col-lg-2">
                    <label>Numeración</label>
                    <Select
                        name="numeracion"
                        isClearable
                        isMulti
                        isDisabled={filters.tipo !== 'venta'}
                        onChange={(values) => handleFilterChange("numeracion", values)}
                        options={numeraciones}
                    />
                </div>
            )}
            {filters.tipo === 'compra' && (
                <div className="col-12 col-md-6 col-lg-2">
                    <label>Tipo Factura</label>
                    <Select
                        name="tipo_factura"
                        isClearable
                        onChange={(e) => handleFilterChange("tipo_factura", e?.value)}
                        options={[
                            {
                                label: "Todas",
                                value: "todas",
                            },
                            {
                                label: "Proforma",
                                value: "proforma",
                            },
                            {
                                label: "Comprobante Fiscal",
                                value: "comprobante",
                            },
                        ]}
                    />
                </div>
            )}
			<div className="col-12 col-md-6 col-lg-2 pr-lg-0">
				<label
					htmlFor="desde"
					style={{ fontSize: "12px", textTransform: "uppercase" }}
				>
					Desde
				</label>
				<input
					type="date"
					name="desde"
					id="desde"
					className="form-control"
					value={filters.desde}
					onChange={(e) =>
						handleFilterChange("desde", e.target.value)
					}
					// disabled={isVendedor()}
				/>
			</div>
			<div className="col-12 col-md-6 col-lg-2 pr-lg-0">
				<label
					htmlFor="hasta"
					style={{ fontSize: "12px", textTransform: "uppercase" }}
				>
					Hasta
				</label>
				<input
					type="date"
					name="hasta"
					id="hasta"
					defaultValue={filters.hasta}
					className="form-control"
					onChange={(e) =>
						handleFilterChange("hasta", e.target.value)
					}
					// disabled={isVendedor()}
				/>
			</div>
		</>
	);
};

export default NotasCredito;
