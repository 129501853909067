import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { auth } from "../utils/auth";
import { apiUrl } from "../utils/apiUrl";
import Loader from "./general/Loader";
import { getSucursal } from "../utils/helpers";

class Login extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        username: "",
        password: "",
        error: false,
        loading: false,
    };

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    onSubmit = async function (event) {
        event.preventDefault();

        this.setState({ loading: true });

        const data = new FormData(event.target);

        const res = await fetch(apiUrl("acceder", false), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
            },
        });

        const credentials = await res.json();

        if (credentials.access_token) {
            auth.authenticate(
                credentials,
                () => (window.location = "/" + getSucursal())
            );
        } else {
            this.setState({ error: true, loading: false });
        }
    };

    render() {
        const { username, password, error, loading } = this.state;

        return (
            <div className='login page-content--bge5'>
                <div className='container'>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className='login-wrap'>
                            <div className='login-logo'>
                                <img
                                    src='/images/icon/logo.png'
                                    width='160px'
                                    alt='SAE | Software de Administracion Empresarial'
                                />
                            </div>
                            <div className='login-content'>
                                <div className='login-form'>
                                    {error && (
                                        <div
                                            className='alert alert-danger'
                                            role='alert'>
                                            No se pudo acceder. Favor revisar
                                            credenciales.
                                        </div>
                                    )}
                                    <form
                                        onSubmit={this.onSubmit}
                                        method='post'>
                                        <div className='form-group'>
                                            <label>Usuario</label>
                                            <input
                                                className='au-input au-input--full'
                                                type='text'
                                                name='username'
                                                onChange={this.onInputChange}
                                                value={username}
                                                placeholder='Su nombre de usuario'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label>Contraseña</label>
                                            <input
                                                className='au-input au-input--full'
                                                type='password'
                                                name='password'
                                                onChange={this.onInputChange}
                                                value={password}
                                                placeholder='Contraseña'
                                            />
                                        </div>
                                        <div className='login-checkbox'>
                                            <label>
                                                <input
                                                    type='checkbox'
                                                    name='remember'
                                                />{" "}
                                                Recordarme
                                            </label>
                                        </div>
                                        <button
                                            className='au-btn au-btn--block au-btn--green m-b-20 m-t-10'
                                            type='submit'>
                                            Acceder
                                        </button>
                                    </form>
                                </div>
                            </div>
                            {/* <div className="text-center m-t-40">
                                <a href="https://play.google.com/store/apps/details?id=com.companyname.sae" target="_blank">
                                <img
                                    src='/images/google-play.png'
                                    width='160px'
                                    alt='Sae'
                                />
                                </a>
                            </div> */}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
