import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../../utils/request";
import { auth } from "../../../../utils/auth";

class PrintGrupos extends Component {
    state = {
        grupos: [],
    };
    componentDidMount() {
        this.getGrupos();
    }
    getGrupos = async () => {
        const response = await this.props.request(
            "gi-grupos" + window.location.search
        );

        if (response.data) {
            this.setState({
                grupos: response.data,
            });
        }
    };
    render() {
        const { grupos } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Grupos
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL GRUPOS: {grupos.length}
                        </div>

                        <div className='float-right'>
                            <h3>Grupos</h3>
                        </div>
                    </div>
                </div>
                <table className='table m-b-40'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nombre</th>
                            <th>Miembros</th>
                            <th>Estado</th>
                            <th>Cédula</th>
                            <th>Teléfono</th>
                        </tr>
                    </thead>
                    <tbody>
                        {grupos.map((grupo) => {
                            return (
                                <tr key={grupo.id}>
                                    <td>{grupo.foto && (
                                        <div className='col-md-3'>
                                            <span className='page-title-foto'>
                                                <img
                                                    src={grupo.foto}
                                                    alt={grupo.codigo}
                                                />
                                            </span>
                                        </div>
                                    )}</td>
                                    <td>
                                        <strong>{grupo.nombreCompleto}</strong>
                                        <div>({grupo.alias})</div>
                                    </td>
                                    <td>
                                        {grupo.miembros
                                            .filter(m => m.estado === 'activo')
                                            .map(miembro => (
                                            <div key={miembro.id}>{miembro.nombre} - {miembro.servidor}</div>
                                        ))}
                                    </td>
                                    <td>
                                        {grupo.active ?
                                            (<span className='badge badge-success'>
                                                Activo
                                            </span>)
                                            :
                                            (<span className='badge badge-danger'>
                                                Inactivo
                                            </span>)
                                        }
                                    </td>
                                    <td>
                                        {grupo.cedula}
                                    </td>
                                    <td>
                                        {grupo.telefono}
                                        <div>{grupo.flota}</div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='row'>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL GRUPOS: {grupos.length}
                        </div>

                        <div className='float-right'>
                            <h3>GRUPOS</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintGrupos));
