import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";

import Loader from "../../general/Loader";
import Prestamo from "./Prestamo";
import Create from "./Create";
import Modal from "../../general/Modal";
import { getLink, formatCurrency, toast } from "../../../utils/helpers";
import MovimientosForm from "./MovimientosForm";
import dayjs from "dayjs";
import Edit from "./Edit";
import CreateMany from "./CreateMany";

class Prestamos extends Component {
    state = {
        prestamos: [],
        grupos: [],
        semanas: [],
        semana: {
            nombre: 'Semana ' + dayjs().startOf('isoweek').format('DD MMM YYYY') + ' - ' + dayjs().endOf('isoweek').format('DD MMM YYYY'),
            numero: dayjs().isoWeek()+1,
            desde: dayjs().startOf('isoweek').format('YYYY-MM-DD'),
            hasta: dayjs().endOf('isoweek').format('YYYY-MM-DD'),
        },
        loading: false,
        saving: false,
        prestamoEditing: {},
        movimientosPrestamo: {},
        showCreate: false,
        showCreateMany: false,
        showEdit: false,
        showPrestamo: false,
        showDropdown: false,
        showDeletePrestamo: false,
        toRemove: '',
        isSearching: false,
        filteredPrestamos: [],
        search: "",
    };

    constructor(props) {
        super(props);
        this.sidePopupWrap = React.createRef();
        this.sidePopupOverlay = React.createRef();
    }

    componentDidMount() {
        this.getGrupos();
        this.getSemanas();
    }

    getPrestamos = async () => {
        const semana = '?semana=' + this.state.semana.numero;
        const response = await this.props.request("gi-prestamos" + semana);

        if (response.data) {
            this.setState({
                prestamos: response.data,
                filteredPrestamos: response.data,
            });

            if (this.isReady()) {
                this.setState({
                    loading: false,
                });
            }
        }
    };

    defaultSemana = () => {
        return {
            nombre: 'Semana ' + dayjs().startOf('isoweek').format('DD MMM YYYY') + ' - ' + dayjs().endOf('isoweek').format('DD MMM YYYY'),
            numero: dayjs().isoWeek()+1,
            desde: dayjs().startOf('isoweek').format('YYYY-MM-DD'),
            hasta: dayjs().endOf('isoweek').format('YYYY-MM-DD'),
        };
    }

    getSemanas = async () => {
        const response = await this.props.request("gi-prestamos/semanas");

        if (response.data) {
            await this.setState({
                semanas: response.data,
            });

            this.getPrestamos();
        }
    };

    getGrupos = async () => {
        const response = await this.props.request("gi-grupos?estado=activos");

        if (response.data && response.data.length) {
            this.setState({
                grupos: response.data,
            });
        } else {
            this.setState({
                grupos: [],
            });
        }

        if (response.data && this.isReady()) {
            this.setState({
                loading: false,
            });
        }
    };

    clickPrestamo = (activo) => {
        const elem = document.getElementById(activo);
        window.scroll({
            top: elem.offsetTop,
            left: 0,
            behavior: "smooth",
        });
        this.setState({
            activo,
        });
    };

    movimientosPrestamo = (activo) => {
        this.setState({
            activo,
        });
        this.togglePrestamo(true);
    };

    togglePrestamo = (show) => {
        if (!show) {
            this.sidePopupWrap.current.classList.remove("slideInRight");
            this.sidePopupWrap.current.classList.add("slideOutRight");
            this.sidePopupOverlay.current.classList.remove("fadeIn");
            this.sidePopupOverlay.current.classList.add("fadeOut");
            setTimeout(() => {
                this.setState({
                    showPrestamo: show,
                });
            }, 1000);
            return;
        }

        this.setState({
            showPrestamo: true,
        });
    };

    onSearch = (e) => {
        const search = e.target.value.toLowerCase();
        this.setState({
            filteredPrestamos: this.state.prestamos.filter((prestamo) => {
                if (search) {
                    return (
                        JSON.stringify(prestamo).toLowerCase().indexOf(search) > -1
                    );
                } else {
                    return true;
                }
            }),
        });
    };

    onChangeSemana = async (e) => {
        const semana = this.state.semanas.filter(s => s.numero === e.value);
        await this.setState({
            semana: semana.length > 0 ? semana[0] : this.defaultSemana()
        });
        this.getPrestamos();
    }

    isReady = () => {
        const { prestamos, grupos } = this.state;
        return prestamos.length > 0 && grupos;
    };

    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    };

    toggleCreateManyModal = () => {
        this.setState({
            showCreateMany: !this.state.showCreateMany,
        });
    };

    toggleDeleteModal = (id) => {
        this.setState({
            showDeletePrestamo: !this.state.showDeletePrestamo,
            toDelete: id,
        });
    };

    toggleEditModal = (prestamo) => {
        this.setState({
            showEdit: !this.state.showEdit,
            prestamoEditing: prestamo,
        });
    };

    toggleDropdown = (show = 0) => {
        this.setState({
            showDropdown: show === 0 ? !this.state.showDropdown : show,
        });
    };

    updatePrestamos = (prestamo) => {
        this.setState((prevState) => ({
            prestamos: prevState.prestamos.map((tar) =>
                tar.id === prestamo.id ? prestamo : tar
            ),
        }));
    };

    removePrestamo = async () => {
        this.setState({ showDropdown: false });

        const token = auth.getToken();
        const id = this.state.toDelete;

        const url = "gi-prestamos/" + id;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.getPrestamos();
            return;
        } else {
            toast(response.data, "error");
            this.setState({ errors: response.data.message });
        }
    };

    render() {
        const {
            grupos,
            semanas,
            semana,
            prestamos,
            loading,
            activo,
            showDropdown,
            showPrestamo,
            isSearching,
            prestamoEditing,
            filteredPrestamos,
        } = this.state;

        const miembrosIngresados = prestamos.reduce((acc, pres) => {
            acc.push(pres.miembroId)
            return acc;
        }, []);

        const prestamoActivo = prestamos.filter(p => p.id === activo)[0];

        if (loading && grupos !== false && grupos.length === 0) {
            return (
                <div className='text-center m-t-50 m-b-50'>
                    No hay grupos creados. Primero debes crear algunos{" "}
                    <Link to={getLink("/gi-grupos/crear")}>aquí</Link>
                </div>
            );
        }

        return (
            <div>
                {loading ? (
                    <Loader />
                ) : (
                    <div className='row'>
                        <div className='asignaciones-main'>
                            <div className='asignaciones-wrap prestamos-wrap'>
                                <div className='filtros-tarjetas'>
                                    <div
                                        className={
                                            "dropdown " +
                                            (showDropdown ? "show" : "")
                                        }>
                                        <button className='au-btn au-btn-icon au-btn-filter m-r-10'>
                                            <i className='zmdi zmdi-menu'></i>
                                        </button>
                                        <div className='dropdown-menu'>
                                            <button
                                                onClick={this.toggleCreateManyModal}
                                                className='dropdown-item'>
                                                Crear Todos
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            "dropdown " +
                                            (showDropdown ? "show" : "")
                                        }>
                                        <button className='au-btn au-btn-icon au-btn-filter'>
                                            <i className='zmdi zmdi-print'></i>
                                        </button>
                                        <div className='dropdown-menu'>
                                            <Link
                                                to={getLink(
                                                    '/imprimir/gi-prestamos/?ids=' + filteredPrestamos.map(p => p.id).join(',') + '&semana=' + semana.numero
                                                )}
                                                target='_blank'
                                                className='dropdown-item'>
                                                Imprimir Semana
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="prestamos-search-bar">
                                         <Select
                                            name='semana'
                                            defaultValue={{
                                                value: semana.numero,
                                                label: semana.nombre
                                            }}
                                            onChange={e => this.onChangeSemana(e)}
                                            options={semanas
                                                .map((s) => ({
                                                    value: s.numero,
                                                    label: `Semana ${dayjs(s.desde).format('DD MMM YYYY')} - ${dayjs(s.hasta).format('DD MMM YYYY')}`,
                                                }))}
                                        />
                                        <input
                                            onChange={this.onSearch}
                                            type='search'
                                            placeholder='Buscar...'
                                            className='form-control search'
                                        />
                                    </div>
                                    <button
                                        onClick={this.toggleCreateModal}
                                        className='au-btn au-btn-icon au-btn--green au-btn--small'>
                                        <i className='zmdi zmdi-plus'></i>
                                        Añadir
                                    </button>
                                </div>
                                {filteredPrestamos.length > 0 && (
                                    <div className='programacion-resumen'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Deuda Inicial
                                                    </th>
                                                    <th>
                                                        Balance Deuda Inicial
                                                    </th>
                                                    <th>
                                                        Total Abonos
                                                    </th>
                                                    <th>
                                                        Total Préstamos
                                                    </th>
                                                    <th>
                                                        Total Adeudado
                                                    </th>
                                                    <th className='text-right'>
                                                        # Grupos
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{formatCurrency(prestamos.reduce((acc,p) => acc + p.deudaInicial, 0))}</td>
                                                    <td>{formatCurrency(prestamos.reduce((acc,p) => acc + p.deudaInicialP, 0))}</td>
                                                    <td>{formatCurrency(prestamos.reduce((acc,p) => acc + p.totalAbono, 0))}</td>
                                                    <td>{formatCurrency(prestamos.reduce((acc,p) => acc + p.totalPrestamo, 0))}</td>
                                                    <td>{formatCurrency(prestamos.reduce((acc,p) => acc + p.totalAdeudado, 0))}</td>
                                                    <td className='text-right'>
                                                        {prestamos.length}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <div className='asignaciones-main-column'>
                                    {filteredPrestamos.length > 0 ? (
                                        filteredPrestamos.map(
                                            (prestamo, index) => (
                                                <Prestamo
                                                    key={prestamo.id}
                                                    index={1}
                                                    editar={
                                                        this.toggleEditModal
                                                    }
                                                    programacion={
                                                        123456
                                                    }
                                                    prestamo={prestamo}
                                                    grupos={
                                                        grupos
                                                    }
                                                    clickPrestamo={() =>
                                                        this.movimientosPrestamo(
                                                            prestamo.id
                                                        )
                                                    }
                                                    removePrestamo={this.toggleDeleteModal}
                                                    getPrestamos={
                                                        this.getPrestamos
                                                    }
                                                    updatePrestamos={
                                                        this.updatePrestamos
                                                    }
                                                />
                                            )
                                        )
                                    ) : (
                                        <div className='text-center'>
                                            {isSearching ? (
                                                <p className='m-b-20'>
                                                    No hay tareas que coincidan
                                                    con esta búsqueda.
                                                </p>
                                            ) : (
                                                <div>
                                                    <p className='m-b-20'>
                                                        Esta programacion
                                                        todavía no tiene
                                                        prestamos creadas.
                                                        ¿Deseas cargar un grupo
                                                        de asignaciones?
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {showPrestamo && (
                                    <div className='side-popup'>
                                        <div
                                            onClick={() =>
                                                this.togglePrestamo(false)
                                            }
                                            ref={this.sidePopupOverlay}
                                            className='side-popup-background animated fadeIn'></div>
                                        <div
                                            ref={this.sidePopupWrap}
                                            className='side-popup-container animated slideInRight fast'>
                                            <div className='col-md-12'>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h2>
                                                            Prestamos {prestamoActivo.grupo?.alias}{" "}
                                                            <div>
                                                                <small>{prestamoActivo.grupo.nombreCompleto} » {prestamoActivo.grupo.direccion}</small>
                                                            </div>
                                                        </h2>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td><strong>Teléfono:</strong> {prestamoActivo.grupo.telefono}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Flota:</strong> {prestamoActivo.grupo.flota}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Nota:</strong> {prestamoActivo.grupo.notas ?? 'N/A'}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <MovimientosForm
                                                    prestamo={prestamoActivo}
                                                    getPrestamos={this.getPrestamos}
                                                    toggle={this.togglePrestamo}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Modal
                            blank
                            show={this.state.showCreate}
                            toggle={this.toggleCreateModal}>
                                <Create
                                    prestamo={{}}
                                    toggle={this.toggleCreateModal}
                                    getPrestamos={this.getPrestamos}
                                    grupos={grupos}
                                    semana={semana}
                                    miembrosIngresados={miembrosIngresados}
                                />
                        </Modal>
                        <Modal
                            blank
                            show={this.state.showCreateMany}
                            toggle={this.toggleCreateManyModal}>
                                <CreateMany
                                    prestamo={{}}
                                    toggle={this.toggleCreateManyModal}
                                    getPrestamos={this.getPrestamos}
                                    grupos={grupos}
                                    semana={semana}
                                    miembrosIngresados={miembrosIngresados}
                                />
                        </Modal>
                        <Modal
                            blank
                            show={this.state.showEdit}
                            toggle={this.toggleEditModal}>
                                <Edit
                                    prestamo={prestamoEditing}
                                    toggle={this.toggleEditModal}
                                    getPrestamos={this.getPrestamos}
                                    grupos={grupos}
                                    miembrosIngresados={miembrosIngresados}
                                />
                        </Modal>
                        <Modal
                            title='Borrar Préstamo'
                            show={this.state.showDeletePrestamo}
                            callback={this.removePrestamo}
                            toggle={this.toggleDeleteModal}>
                            ¿Seguro que deseas borrar este préstamo?
                        </Modal>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(withRequest(Prestamos));
