import React, { useState, useEffect } from "react";
import Select from "react-select";

import { formatCurrency } from "../../utils/helpers";

 const SuministroCompra = ({index, removeItem, almacen, item, updateTotal, impuestos}) => {


    const [impuestoId, setImpuestoId] = useState(item.impuesto?.id);
    const [impuesto, setImpuesto] = useState(item.impuesto?.porcentaje);
    const [enableTotalInput, setEnableTotalInput] = useState(true);

    const [cantidad, setCantidad] = useState(parseFloat(item.cantidad) || 1);
    const [total, setTotal] = useState(parseFloat(item.total));
    const [costo, setCosto] = useState(parseFloat(item.costo));

    const onChangeTotal = async (value, imp, imp_id) => {
        await setTotal(value);
        const c = value/cantidad;
        const t = parseFloat(c - calcularImpuesto(c, imp, true));
        setCosto(t);
        calcularTotal(value, imp_id, true);
    }

    const onChangeCosto = async (value, imp, imp_id) => {
        setEnableTotalInput(false);
        await setCosto(value);

        const st = value * cantidad;
        const t = parseFloat(st + calcularImpuesto(st, imp, false));
        setTotal(t);
        calcularTotal(t, imp_id);
    }

    const onChangeCantidad = (value, imp, imp_id) => {
        setCantidad(value);

        if (enableTotalInput) {
            const st = parseFloat(total / value);
            setCosto(st - calcularImpuesto(st, imp, true));
        } else {
            const t = (costo * value) + calcularImpuesto(costo * value, imp, false);
            setTotal(t);
            calcularTotal(t, imp_id);
        }
    }

    const onChangeImpuestoId = (value) => {
        const imp = impuestos.filter(i => i.id === value).shift();

        setImpuestoId(value);
        setImpuesto(parseFloat(imp.porcentaje));

        if (enableTotalInput) {
            onChangeTotal(total, imp.porcentaje, value);
        } else {
            onChangeCosto(costo, imp.porcentaje, value);
        }
    }

    const calcularImpuesto = (monto, imp, istotal = true) => {
        const i = parseFloat(imp < 1 ? imp : imp / 100);

        if (istotal) {
            return monto - (monto / (i + 1));
        }

        return monto * i;
    }

    const calcularTotal = (monto, impuesto_id = 0) => {
        if (impuestos.length > 0 && impuesto_id) {
            const imp = impuestos.filter(i => i.id === impuesto_id).shift();
            updateTotal(item.id, parseFloat(monto), {nombre: imp?.nombre, totalImpuesto: calcularImpuesto(monto, imp.porcentaje)})
        } else {
            updateTotal(item.id, parseFloat(monto), {nombre: 'Ninguno', totalImpuesto: 0})
        }
    }

    return (
        <tr>
            <td className='text-center'>
                {index + 1}
            </td>
            <td colSpan={2}>
                <input type="hidden" name={`items_compra[s${index}][almacen_id]`} value={almacen || ''} />
                <input type="hidden" name={`items_compra[s${index}][tipo]`} value={item.tipo ?? 'suministro'} />
                <div>
                    <input type="text" name={`items_compra[s${index}][descripcion]`} id="descripcion" className="form-control" defaultValue={item.descripcion} />
                </div>
            </td>
            <td>
                <input
                    type='number'
                    name={`items_compra[s${index}][cantidad]`}
                    min='0.01'
                    onChange={e => onChangeCantidad(e.target.value, impuesto, impuestoId)}
                    value={cantidad}
                    className='form-control text-right'
                    step='any'
                />
            </td>
            <td className='text-center'
                title="Doble Click para editar"
                onDoubleClick={() => setEnableTotalInput(false)}
            >
                {!enableTotalInput ? (
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">RD$</span>
                    </div>
                    <input
                        type='number'
                        value={costo}
                        onChange={e=>onChangeCosto(e.target.value, impuesto, impuestoId)}
                        className='form-control text-right'
                        step='any'
                    />
                </div>) : (
                    <div className="m-t-5">
                        {formatCurrency(parseFloat(costo).toFixed(2))}<i className="fa fa-pen table-edit-field color-primary"></i>
                    </div>
                )}
                <input
                    type="hidden"
                    name={`items_compra[s${index}][costo]`}
                    value={costo}
                />
            </td>
            <td className='text-center' colSpan={2}>
                <Select
                    key={index + 's-impuestos'}
                    value={impuestoId && impuestos.filter(i => i.id === impuestoId).map(i => ({
                        label: i.nombre,
                        value: i.id,
                    }))}
                    onChange={e => onChangeImpuestoId(e.value)}
                    options={impuestos.map(i => ({
                        label: i.nombre,
                        value: i.id
                    }))}
                />
                <input type="hidden" name={`items_compra[s${index}][impuesto_id]`} value={impuestoId} />
            </td>
            <td
                className="text-right"
                title="Doble Click para editar"
                onDoubleClick={() => setEnableTotalInput(true)}
            >
                {enableTotalInput ? (
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span
                                className="input-group-text"
                                id="basic-addon1"
                            >
                                RD$
                            </span>
                        </div>
                        <input
                            type="number"
                            value={total}
                            onChange={e => onChangeTotal(e.target.value, impuesto, impuestoId)}
                            className="form-control text-right"
                            step="any"
                        />
                    </div>
                ) : (
                    <div className="m-t-5">
                        {formatCurrency(total)}<i className="fa fa-pen table-edit-field color-primary"></i>
                    </div>
                )}
                <input
                    type="hidden"
                    name={`items_compra[s${index}][total]`}
                    value={total}
                />
            </td>
            <td>
                <button
                    type='button'
                    className='btn'
                    onClick={() => removeItem(item.id)}>
                    <i className='fas fa-trash'></i>
                </button>
            </td>
        </tr>
    );
}
export default SuministroCompra;
