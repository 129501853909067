import React from "react";
import PropTypes from "prop-types";
import { formatCurrency, formatNumber } from "../../../utils/helpers";

const TableOfCatalogueProducts = ({ categorias, precioVenta, showCantOfProducts }) => {

    const getPrecioVenta = (producto, precioVenta) => {
        if (producto.precios[precioVenta - 1]?.total && producto.precios[precioVenta - 1]?.total > 0) {
            return producto.precios[precioVenta - 1].total;
        }

        return producto.precios[0].total
    }

    return (
        <>
            {Object.keys(categorias).map(categoria => <div className="col-md-12 seccion-producto">
                    <table className='table print-productos m-b-80'>
                        <thead className="head-table-producto">
                            <tr>
                                <th colSpan="4">{categoria === "undefined" ? "SIN CATEGORIZAR" : categoria}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    Ref/Cód
                                </th>
                                <th>Nombre</th>
                                { showCantOfProducts && <th className='text-right'>Disponible</th>}
                                {precioVenta && <th className='text-right'>Precio</th>}
                            </tr>
                            {categorias[categoria].map((producto) => {
                                return (
                                    <tr key={producto.id}>
                                        <td>{producto.referencia}</td>
                                        <td>{producto.nombre}</td>
                                        {showCantOfProducts && <td className='text-right'>
                                            {formatNumber(producto.cantidad)}{" "}
                                            <small>{producto.unidad}</small>
                                        </td>}
                                        { precioVenta && <td className='text-right'>
                                            {formatCurrency(getPrecioVenta(producto, precioVenta))}
                                        </td>}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>)}
        </>
    );
};

TableOfCatalogueProducts.propTypes = {
    categorias: PropTypes.array,
    precioVenta: PropTypes.number,
    showCantOfProducts: PropTypes.bool
}

export default TableOfCatalogueProducts;
