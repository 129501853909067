import React from "react";
import PropTypes from "prop-types";

// Algo que se me ocurrió es hacer un div que presente el currency formateado y que el input sea transparente

const PrefixedInput = ({
	type = "text",
	prefix,
	placeholder,
	value,
	setValue,
	defaultValue,
	readOnly,
	name,
	height = "38px",
    onBlur = () => {},
}) => {
	return (
		<div className="input-box" style={{ height }}>
			<span className="prefix">{prefix}</span>
				<input
					type={type}
					value={value}
					name={name}
					onChange={(e) => setValue(e.target.value)}
                    onBlur={(e) => onBlur(e.target.value)}
					placeholder={placeholder}
                    defaultValue={defaultValue}
					readOnly={readOnly}
				/>
		</div>
	);
};

PrefixedInput.propTypes = {
	type: PropTypes.string,
	prefix: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.any,
	setValue: PropTypes.func,
	readOnly: PropTypes.bool,
	name: PropTypes.string,
	height: PropTypes.string,
};

export default PrefixedInput;
