import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import Select from "react-select";

import { auth } from "../../utils/auth";
import { getLink } from "../../utils/helpers";

export default function PrintForm({ toggle, getNominas }) {
    const location = useRouteMatch();

    const [nominas, setNominas] = useState([]);
    const onChangeSucursal = async (val) => {
        if (val) {
            setNominas(await getNominas(val.value));
        }
    };

    const [printURL, setPrintURL] = useState("");
    const onChangeFormato = (val) => {
        if (val) {
            setPrintURL(val.value);
        }
    };

    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
        (suc) => suc.id === user.sucursalActual
    )[0];

    return (
        <div>
            <form action={printURL} target='_blank'>
                <div className='popup__header'>Imprimir Nómina Combinada</div>
                <div className='popup__body'>
                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>
                                Formato de Impresión:
                            </label>
                        </div>
                        <div className='col col-md-7'>
                            <Select
                                id='formato'
                                onChange={onChangeFormato}
                                options={[
                                    {
                                        value: getLink(
                                            `/imprimir/nomina/${location.params.id}/resumen`
                                        ),
                                        label: "Resumen Nómina",
                                    },
                                    {
                                        value: getLink(
                                            `/imprimir/nomina/${location.params.id}/comprobantes-estandard`
                                        ),
                                        label: "Volantes Estándard",
                                    },
                                    {
                                        value: getLink(
                                            `/imprimir/nomina/${location.params.id}/comprobantes`
                                        ),
                                        label: "Volantes Producción",
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>
                                Combinar con Sucursal:
                            </label>
                        </div>
                        <div className='col-md-7'>
                            <Select
                                isClearable={true}
                                onChange={onChangeSucursal}
                                name='sucursal'
                                options={sucursal.sucursales.map(
                                    (sucursal) => ({
                                        value: sucursal.id,
                                        label: sucursal.nombre,
                                    })
                                )}
                            />
                        </div>
                    </div>
                    {nominas.length > 0 && (
                        <div className='row form-group'>
                            <div className='col col-md-5'>
                                <label className='label form-control-label'>
                                    Combinar con Nómina:
                                </label>
                            </div>
                            <div className='col col-md-7'>
                                <Select
                                    isClearable={true}
                                    name='nomina'
                                    options={nominas.map((nomina) => ({
                                        value: nomina.id,
                                        label: nomina.nombre,
                                    }))}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Imprimir
                    </button>
                </div>
            </form>
        </div>
    );
}
