import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { apiRequest } from "../../../hooks/apiRequest";
import { getLink, toast, validarPINSeguridad } from "../../../utils/helpers";
import Loader from "../../general/Loader";

import Detalles from "./Components/Detalles";
import Seleccion from "./Components/Seleccion";
import Cocurriculares from "./Components/Cocurriculares";
import { request } from "../../../utils/request";
import Relacionados from "./Components/Relacionados";
import PagoForm from "./Components/PagoForm";
import dayjs from "dayjs";
import Modal from "../../general/Modal";
import EditarPagoForm from "./Components/EditarPagoForm";

const Create = () => {

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [showProcesarModal, setShowProcesarModal] = useState(false);
    const [servicios, setServicios] = useState([]);
    const [cocurriculares, setCocurriculares] = useState([]);
    const [seleccion, setSeleccion] = useState([]);
    const [estudiante, setEstudiante] = useState({});
    const [tutor, setTutor] = useState({});
    const [balance, setBalance] = useState(0);
    const [numeraciones, setNumeraciones] = useState([]);
    const [pagosEducativos, setPagosEducativos] = useState([]);
    const [showPINModal, setShowPINModal] = useState(false);
    const [pinSeguridad, setPinSeguridad] = useState("");
    const [showEditarPagos, setShowEditarPagos] = useState(false);
    const [noTieneCobros, setNoTieneCobros] = useState(false);

    const params = new URLSearchParams(window.location.search);
    const estudianteId = params.get('estudiante_id');

    const [formData, setFormData] = useState({
        estudiantes: [],
        tutores: [],
        comprobante: [],
    });
    const [page, setPage] = useState(1);
    const [pagoInfo, setPagoInfo] = useState({
		pago: 0,
		tipo_pago: "efectivo",
		cuenta: "",
		fecha: dayjs().format("YYYY-MM-DD"),
	});

    const procesarPago = (e) => {
        e.preventDefault();

        setSaving(true);

        const data = new FormData(e.target);

        apiRequest(data, "academico/facturacion/procesar-pago", 'post').then(res => {
            if (res.success) {
                setShowProcesarModal(false);
                //getServicios(estudiante.id);
                limpiar();
                toast('Pago aplicado exitosamente');
                window.open(getLink(`/imprimir/academico/recibos/${res.data?.recibo.id}/mensualidad`));
            }
            if (res.code === 422) {
                toast('Favor revisar todos los campos.', 'error');
            }
            setSaving(false);
        });

    }

    const editarPagos = (e) => {
        e.preventDefault();

        setSaving(true);

        const data = new FormData(e.target);

        apiRequest(data, "academico/facturacion/editar-pagos", 'post').then(res => {
            if (res.success) {
                getServicios(estudiante.id);
                setShowEditarPagos(false);
                toast('Pagos actualizados exitosamente');
            }
            if (res.code === 422) {
                toast('Favor revisar todos los campos.', 'error');
            }
            setSaving(false);
        });

    }

    const getData = async (url, name = null) => {
        const request = await apiRequest({}, url, 'get');
        const table = name ? name : url;

        if (request.data) {
            setFormData(d => ({
                ...d,
                [table]: request.data,
            }));
        }
    };

    const getNumeraciones = async () => {
        request('numeracion-documentos?tipo_documento=factura_venta').then((response) => {
            setNumeraciones(response.data);
        });
    }

    const getPagosEducativos = async () => {
        const url = "academico/pagos-educativos";
        const response = await apiRequest({}, url, 'get');
        setPagosEducativos(response.data);
    }

    const getComprobanteData = (comprobante) => {
		if (!comprobante) return;

		request(`facturas/get-comprobante/${comprobante}`).then((res) => {
			setFormData((form) => ({
				...form,
				comprobante: res.data,
			}));
		});
	};

    const getServicios = (estudianteId) => {
        apiRequest({}, `academico/estudiantes/${estudianteId}/servicios`, 'get').then(res => {
            if (res.data) {
                setServicios(res.data);
                setBalance(res.data?.filter(s => s.estado === 'pendiente').reduce((acc, s) => {
                    return parseFloat(s.monto) + acc
                }, 0));
            }
            if (res.data.length === 0) {
                setNoTieneCobros(true);
            }
        });
    };

    const getCocurriculares = (estudianteId) => {
        apiRequest({}, `academico/estudiantes/${estudianteId}/cocurriculares`, 'get').then(res => {
            if (res.data) {
                setCocurriculares(res.data);
            }
        });
    };

    const openProcesar = () => {
        if (total === 0) {
            toast('Debes seleccionar los servicios a pagar.', 'error')
            return;
        }
        if (!formData.comprobante?.prefijo) {
            toast('Selecciona un tipo de comprobante para proceder.', 'error')
            return;
        }
        setPage(1);
        setPagoInfo({
            pago: 0,
            tipo_pago: "efectivo",
            cuenta: "",
            fecha: dayjs().format("YYYY-MM-DD"),
        });
        setShowProcesarModal(true);
    }

    const updateSeleccion = (id) => {
        setSeleccion(prev => {
            if (prev.length === 1 && prev.includes(id)) {
                return [];
            } else {
                return servicios.filter(s => s.id <= id && s.estado === 'pendiente').map(s => s.id)
            }
        });
    }

    const limpiar = () => {
        setFormData(prev => ({
            ...prev,
            comprobante: [],
        }));
        setEstudiante({});
        setTutor({});
        setServicios([]);
        setBalance(0);
        document.querySelector('#comprobante').selectedIndex = 0;
        document.querySelector('#grupo_pago').value = '';
        document.querySelector('#seccion').value = '';
    }

    const checkPINSeguridad = async () => {
		if (await validarPINSeguridad(pinSeguridad)) {
			setPinSeguridad("");
			setShowPINModal(false);
            setShowEditarPagos(true);
			return;
		}

		toast("PIN incorrecto, intente de nuevo", "error");
	};

    useEffect(() => {
        if (estudianteId) {
            request(`academico/estudiantes/${estudianteId}`).then((res) => {
                if (res.success) {
                    setEstudiante(res.data);
                    getCocurriculares(res.data.contacto_id);
                }
            });
            getServicios(estudianteId);
        }
        getPagosEducativos();
        getData('academico/estudiantes', 'estudiantes');
        getData('academico/padres-tutores', 'tutores');
        getNumeraciones();
    }, []);

    useEffect(() => {
      setNoTieneCobros(false);
    }, [estudiante])


    const serviciosSeleccionados = servicios?.filter(s => {
        return s.estado === 'pendiente' && seleccion.includes(s.id);
    });

    const relacionados = [].concat(estudiante?.relacionados || [], tutor?.estudiantes?.filter(e => e.id !== estudiante.id) || []);

    const total = serviciosSeleccionados.reduce((a,s) => {
        return a + parseFloat(s.restante);
    }, 0);

    return (
        <div>
            <form
                method="post"
                onSubmit={procesarPago}
            >
                <div className="page-header">
                    <Link to={getLink('/academico/recibos')}>&laquo; Volver al listado</Link>
                    <h1 className="page-title row">
                        <div className="col">
                            <span className="m-r-15">Cobros Académicos</span>
                        </div>

                        <div className="col-md-2 offsett-9">
                            <div
                                className="form-group"
                                style={{ marginBottom: "0.3rem" }}
                            >
                                <select
                                    name="numeracion_id"
                                    id="comprobante"
                                    onChange={(e) =>
                                        getComprobanteData(e.target.value)
                                    }
                                >
                                    <option value="">
                                        Seleccione Comprobante
                                    </option>
                                    {numeraciones.map(
                                        (comprobante) => (
                                            <option
                                                value={comprobante.id}
                                                key={comprobante.id}
                                            >
                                                {comprobante.nombre}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>
                    </h1>
                </div>

                <Detalles
                    estudiantes={formData.estudiantes}
                    tutores={formData.tutores}
                    getServicios={getServicios}
                    getCocurriculares={getCocurriculares}
                    estudiante={estudiante}
                    setEstudiante={setEstudiante}
                    tutor={tutor}
                    setTutor={setTutor}
                    balance={balance}
                    formData={formData}
                    limpiar={limpiar}
                />

                {noTieneCobros && (
                    <div className="m-t-25">
                        <hr />
                        <div className="text-center m-t-25">
                            Este estudiante no está inscrito. <Link to={getLink(`/academico/estudiantes/${estudiante.id}/informacion?inscripcion=1`) } className="btn btn-primary">Inscribir</Link>
                        </div>
                    </div>
                )}

                {servicios.length === 0 && (
                    <div className="m-t-25">
                        <Link className="btn btn-outline-primary" to={getLink('/academico/recibos')}>&laquo; Volver atrás</Link>
                    </div>
                )}

                {servicios.length > 0 && (
                    <div className="row m-t-20">
                        <div className="col">
                            <Seleccion
                                total={total}
                                servicios={servicios}
                                seleccion={seleccion}
                                updateSeleccion={updateSeleccion}
                            />
                            <div className="page-footer text-right col-md-12">
                                {seleccion.length > 0 && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary float-left"
                                        onClick={() => {setShowPINModal(true)}}
                                    >
                                        Anular/Exonerar Pagos
                                    </button>
                                )}
                                <Link className="btn btn-outline-primary" to={getLink('/academico/recibos')}>&laquo; Volver atrás</Link>
                                {!saving && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        onClick={() => limpiar()}
                                    >
                                        Limpiar
                                    </button>
                                )}

                                {saving ? (
                                    <Loader type="small" />
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={openProcesar}
                                    >
                                        Procesar Pago
                                    </button>
                                )}
                            </div>
                        </div>
                        {(cocurriculares.length > 0 || relacionados.length > 0) && (
                            <div className="col-md-5">
                                {cocurriculares.length > 0 && (
                                    <Cocurriculares
                                        pagosEducativos={pagosEducativos}
                                        cocurriculares={cocurriculares}
                                        estudiante={estudiante}
                                    />
                                )}
                                {relacionados?.length > 0 && (
                                    <div className="m-t-25">
                                        <Relacionados
                                            relacionados={relacionados}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <Modal
                    title="Procesar Pago"
                    show={showProcesarModal}
                    toggle={() => {
                        setShowProcesarModal((state) => !state);
                    }}
                    footer={
                        <ModalFooter
                            setShowProcesarModal={setShowProcesarModal}
                            pago={total}
                            seleccion={serviciosSeleccionados}
                            abono={pagoInfo.pago}
                            page={page}
                            setPage={setPage}
                            saving={saving}
                            fecha={dayjs().format("YYYY-MM-DD")}
                        />
                    }
                    size="650px"
                >
                    <PagoForm
                        estudiante={estudiante}
                        tutor={tutor}
                        seleccion={serviciosSeleccionados}
                        total={total}
                        abono={pagoInfo.pago >= total ? total : pagoInfo.pago}
                        fecha={dayjs().format("YYYY-MM-DD")}
                        pagoInfo={pagoInfo}
                        setPagoInfo={setPagoInfo}
                        page={page}
                    />
                </Modal>
            </form>

            {/* <---PIN DE SEGURIDAD MODAL--- */}
            <Modal
                title="PIN de seguridad"
                show={showPINModal}
                callback={() => checkPINSeguridad()}
                acceptText="Aceptar"
                toggle={() => setShowPINModal((state) => !state)}
                size="419px"
            >
                <div className="form-group">
                    <label>PIN de seguridad</label>
                    <input
                        type="password"
                        className="form-control"
                        id="PIN"
                        name="pin_seguridad"
                        value={pinSeguridad}
                        onChange={(e) =>
                            setPinSeguridad(e.target.value)
                        }
                    />
                </div>
            </Modal>

            <Modal
                title="Anular/Exonerar Pagos"
                show={showEditarPagos}
                blank
                toggle={() => setShowEditarPagos(false)}
            >
                <form method="POST" onSubmit={editarPagos}>
                    <EditarPagoForm
                        errors={errors}
                        toggle={() => setShowEditarPagos(false)}
                        seleccion={seleccion}
                    />
                </form>
            </Modal>
        </div>
    );
}

export default Create;

const ModalFooter = React.memo(
	({
		setShowProcesarModal,
		pago,
		abono,
		page,
		setPage,
		saving = false,
		fecha,
        seleccion,
	}) => {
		const prevPage = () => setPage((state) => state - 1);
		const nextPage = () => {
			if (parseFloat(pago) <= 0) {
				toast(
					"El valor del abono no puede ser igual o menor a cero",
					"error"
				);
				return;
			}
			if ((seleccion.length > 1 || seleccion[0].nombre.toLowerCase().includes('nscripci')) && pago > abono) {
				toast(
                    seleccion[0].nombre.includes('nscripci')
                        ? "El monto no puede ser menor al total de la inscripción."
                        : "El monto no puede ser menor al total si hay más de una cuota seleccionada.",
					"error"
				);
				return;
			}
			if (!fecha) {
				toast("Elija una fecha para continuar", "error");
				return;
			}
			setPage((state) => state + 1);
		};

		return (
			<>
				<button
					type="button"
					onClick={() => setShowProcesarModal((state) => !state)}
					className="btn btn-plain"
				>
					Cancelar
				</button>
				{page === 2 && (
					<button
						type="button"
						onClick={prevPage}
						className="btn btn-plain float-left"
					>
						&larr; Atrás
					</button>
				)}
				{page === 1 && (
					<button
						type="button"
						onClick={nextPage}
						className="btn btn-primary"
					>
						Continuar
					</button>
				)}
				{page === 2 &&
					(saving ? (
						<Loader type="small" />
					) : (
						<button
							className="btn btn-outline-primary"
							type="submit"
						>
							Aplicar Pago
						</button>
					))}
			</>
		);
	}
);
