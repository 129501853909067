import React, { useState } from "react";
import dayjs from "dayjs";
import Select from "react-select";

export default function Form({empleados, errors, incentivo, tipos}) {

    const [state, setState] = useState({
        fecha: dayjs().format("YYYY-MM-DD"),
        monto: incentivo.monto ?? '',
        descripcion: incentivo.descripcion ?? '',
        frecuencia: incentivo.frecuencia ?? '',
        tipo_incentivo: '',
        tipo_id: '',
    });

    function updateField( field, value ) {
        setState({
            ...state,
            [field]: value,
        })
    }

    function onChangeFrecuencia(e) {
        if (!e.value) return;

        setState({
            ...state,
            frecuencia: e.value,
        });
    }

    function onChangeSelect(field, e) {
        setState({
            ...state,
            [field]: !e.value ? '' : e.value
        });
    }

    const {monto, fecha, descripcion, frecuencia, tipo_incentivo} = state;

    return (
        <div className='popup__body' style={{minHeight: 200}}>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Tipo de Incentivo:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <Select
                        name='tipo_incentivo'
                        required
                        onChange={e => onChangeSelect('tipo_incentivo', e)}
                        options={[
                            {
                                label: 'Incentivos por Tipo de Empleado',
                                value: 'por_tipo'
                            },
                            {
                                label: 'Incentivo Individual',
                                value: 'individual'
                            },
                        ]}
                    />
                    {errors.tipo_incentivo && (
                        <small className='help-blockParams form-Text'>
                            {errors.tipo_incentivo[0]}
                        </small>
                    )}
                </div>
            </div>

            {tipo_incentivo === 'por_tipo' && tipos.length > 0 && (
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Tipo de Empleado:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <Select
                            name='tipo_id'
                            isClearable={true}
                            required
                            options={tipos
                                .filter(tipo => tipo.empleados > 0)
                                .map((tipo) => ({
                                    value: tipo.id,
                                    label: `${tipo.nombre}`,
                                }))}
                        />
                        {errors.tipo_id && (
                            <small className='help-blockParams form-Text'>
                                {errors.tipo_id[0]}
                            </small>
                        )}
                    </div>
                </div>
            )}

            {tipo_incentivo === 'individual' && (
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Empleado:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <Select
                            name='empleado_id'
                            isClearable={true}
                            required
                            options={empleados
                                .map((empleado) => ({
                                    value: empleado.id,
                                    label: `${empleado.nombreCompleto}`,
                                }))}
                        />
                        {errors.empleado_id && (
                            <small className='help-blockParams form-Text'>
                                {errors.empleado_id[0]}
                            </small>
                        )}
                    </div>
                </div>
            )}

            {tipo_incentivo !== '' && (
                <div>
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Frecuencia:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <Select
                                name='frecuencia'
                                onChange={onChangeFrecuencia}
                                required
                                options={[{
                                    value: 'fijo',
                                    label: 'Fijo',
                                },{
                                    value: 'unico',
                                    label: 'Único',
                                },]}
                            />
                            {errors.frecuencia && (
                                <small className='help-blockParams form-Text'>
                                    {errors.frecuencia[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Fecha:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <input
                                type='date'
                                name='fecha'
                                onChange={e => updateField('fecha', e.target.value)}
                                value={fecha}
                                className='form-control'
                            />
                            {errors.fecha_inicio && (
                                <small className='help-blockParams form-Text'>
                                    {errors.fecha_inicio[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Monto:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <input
                                type='number'
                                name='monto'
                                step='0.01'
                                onChange={e => updateField('monto', e.target.value)}
                                value={monto}
                                required
                                className='text-input form-control'
                            />
                            {frecuencia === 'fijo' && (
                                <div><small>Este monto se sumará de manera fija en cada nómina.</small></div>
                            )}
                            {errors.monto && (
                                <small className='help-blockParams form-Text'>
                                    {errors.monto[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Descripción:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <input
                                type='text'
                                name='descripcion'
                                required
                                onChange={e => updateField('descripcion', e.target.value)}
                                value={descripcion}
                                className='form-control'
                            />
                            {errors.descripcion && (
                                <small className='help-blockParams form-Text'>
                                    {errors.descripcion[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
            )}
    </div>
    );
}
