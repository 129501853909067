import React, { Component } from "react";
import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import Create from "./Create";
import Edit from "./Edit";
import Modal from "../../general/Modal";
import { toast } from "../../../utils/helpers";

class PersonaContacto extends Component {
    state = {
        personasContacto: [],
        loading: true,
        showCreate: false,
        showDelete: false,
        showEdit: false,
        personaContactoId: null,
        persona: {},
    };
    componentDidMount() {
        this.getPersonasContacto();
    }
    getPersonasContacto = async (url = null) => {
        const id = this.props.empleado.id;
        url = url ? url : `personas-contacto?empleado_id=${id}`;
        const personasContacto = await this.props.request(url);

        if (personasContacto.success) {
            this.setState({
                personasContacto: personasContacto.data,
                loading: false
            });
        }
    };
    removePersonaContacto = async () => {
        const { personaContactoId } = this.state
        const token = auth.getToken();

        const url = `personas-contacto/${personaContactoId}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.getPersonasContacto();
            toast('Persona de Contacto Borrada', 'success');
            this.toggleDeleteModal();
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate
        });
    };
    toggleEditModal = (persona = {}) => {
        this.setState({
            showEdit: !this.state.showEdit,
            persona
        });
    };
    toggleDeleteModal = (id = null) => {
        this.setState({
            showDelete: !this.state.showDelete,
            personaContactoId: id,
        });
    };
    render() {
        const { empleado } = this.props;
        const { personasContacto, persona, loading } = this.state;

        if (loading === 0) {
            return <Loader />;
        }
        return (
            <div>
                <div className='row m-b-20 descripcion-subpage'>
                    <div className='col'>
                        <h3>Personas de Contacto</h3>
                    </div>
                    <div className='col-md-3 text-right'>
                        <button
                            onClick={this.toggleCreateModal}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'>
                            Nuevo
                        </button>
                    </div>
                </div>
                <table className='table tickets-table'>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Relacion</th>
                            <th>Teléfono</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {personasContacto.map(personaContacto => (
                            <tr key={personaContacto.id}>
                                <td>{personaContacto.nombre}</td>
                                <td>{personaContacto.relacion}</td>
                                <td>{personaContacto.telefono}</td>
                                <td className="text-right">
                                    <button
                                        type="button"
                                        className="m-r-10"
                                        onClick={() => this.toggleEditModal(personaContacto)}>
                                        <i className='fa fa-pen' />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => this.toggleDeleteModal(personaContacto.id)}>
                                        <i className='fa fa-trash-alt' />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        empleadoId={empleado.id}
                        getPersonasContacto={this.getPersonasContacto}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={this.toggleEditModal}>
                    <Edit
                        toggle={this.toggleEditModal}
                        empleadoId={empleado.id}
                        getPersonasContacto={this.getPersonasContacto}
                        persona={persona}
                    />
                </Modal>
                <Modal
                    title='Borrar Persona de Contacto'
                    show={this.state.showDelete}
                    callback={this.removePersonaContacto}
                    acceptText='Borrar'
                    toggle={this.toggleDeleteModal}>
                    ¿Estás seguro de que deseas borrar esta persona de contacto?
                </Modal>
            </div>
        );
    }
}

export default withRequest(PersonaContacto);
