import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import SelectAsync from "react-select/async";
import SelectAsyncCreatable from "react-select/async-creatable";
import { formatCurrency, getCurrentMonthName, getLink, getOptionsForDropdown } from "../../../../utils/helpers";
import { apiRequest } from "../../../../hooks/apiRequest";

const Form = ({ inscripcion, errors, becas, clases, editar }) => {

    const [clase, setClase] = useState(inscripcion.clase || {});

    const cuotas = [];
    for (let index = 0; index < clase.cuotas; index++) {
        const mes = dayjs(clase.fechaInicio).add(index, 'months');
        const nombreMes = getCurrentMonthName(mes.month());
        cuotas.push({
            nombre: 'Mensualidad ' + nombreMes,
            costo: clase.costo,
            fecha: mes.format('YYYY-MM-DD'),
        });
    }

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => {
                const nombre = item[label] + (item.estudiante ? ' - Estudiante' : ' - Cliente');
                return {
                    value: item[value],
                    label: nombre,
                    item: item
                };
            });
        }
    };

    const handleCreate = (e) => {
        window.open(getLink('/contactos?crear_contacto=' + e), '_blank');
    }

    return (
        <div>
            <div className="row">
                <div className='col form-group'>
                    <label className='label form-control-label'>
                        Contacto:
                    </label>
                    <SelectAsyncCreatable
                        key="contacto"
                        name='contacto_id'
                        cacheOptions
                        menuPlacement="auto"
                        menuPosition="fixed"
                        isDisabled={editar}
                        onCreateOption={handleCreate}
                        placeholder="Seleccionar..."
                        defaultOptions
                        isClearable
                        defaultValue={inscripcion.contacto && {
                            label: inscripcion.contacto?.nombre + (inscripcion.contacto.estudiante ? ' - Estudiante' : ' - Cliente'),
                            value: inscripcion.contacto?.id,
                        }}
                        loadOptions={(e) => getOptions("contactos?limit=50&buscar="+e, 'nombre')}
                    />
                    {editar && (
                        <input type="hidden" name="contacto_id" value={inscripcion.contacto?.id} />
                    )}
                    {errors.contacto_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.contacto_id[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className="row">
                <div className='col-md-6 form-group'>
                    <label className='label form-control-label'>
                        Clase:
                    </label>
                    <Select
                        key="cocurricular_id"
                        name='cocurricular_id'
                        isClearable
                        isDisabled={editar}
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        onChange={(e) => setClase(e?.clase || {})}
                        options={clases && clases.map(c => ({
                            label: c.nombre,
                            value: c.id,
                            clase: c,
                        }))}
                        value={clase?.nombre && {
                            label: clase?.nombre,
                            value: clase?.id
                        }}
                    />
                    {errors.cocurricular_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.cocurricular_id[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-6 form-group'>
                    <label className='label form-control-label'>
                        Fecha Inscripción:
                    </label>
                    <input
                        type='date'
                        className='text-input form-control'
                        defaultValue={inscripcion.fechaInscripcion ? dayjs(inscripcion.fechaInscripcion).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')}
                        name='fecha'
                    />
                    {errors.fecha_inscripcion && (
                        <small className='help-blockParams form-Text'>
                            {errors.fecha_inscripcion[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className="row">
                <div className='col-md-6 form-group'>
                    <label className='label form-control-label'>
                        Año:
                    </label>
                    <SelectAsync
                        key="ano"
                        name='ano'
                        isClearable
                        cacheOptions
                        defaultOptions
                        menuPlacement="auto"
                        menuPosition="fixed"
                        isDisabled={editar}
                        placeholder='Seleccionar...'
                        loadOptions={(e) => getOptionsForDropdown("academico/periodos-academicos?buscar="+e, 'nombre', 'nombre')}
                        defaultValue={inscripcion.ano && {
                            label: inscripcion.ano,
                            value: inscripcion.ano
                        }}
                    />
                    {errors.ano && (
                        <small className='help-blockParams form-Text'>
                            {errors.ano[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-6 form-group'>
                    <label className='label form-control-label'>
                        Beca:
                    </label>
                    <Select
                        key="beca"
                        name='beca_id'
                        isClearable
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        options={becas && becas.map(b => ({
                            label: b.nombre,
                            value: b.id,
                        }))}
                        defaultValue={inscripcion.beca && {
                            label: inscripcion.beca?.nombre,
                            value: inscripcion.beca?.id
                        }}
                    />
                    {errors.beca && (
                        <small className='help-blockParams form-Text'>
                            {errors.beca[0]}
                        </small>
                    )}
                </div>
            </div>
            {cuotas.length > 0 && !editar && (
                <div className="row">
                    <div className="col-md-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td><small className="uppercase">Monto</small></td>
                                    <td><small className="uppercase">Fecha</small></td>
                                </tr>
                            </thead>
                            <tbody>
                                {clase.costoInscripcion > 0 && (
                                    <tr>
                                        <td><label><input type="checkbox" defaultChecked name="cuotas[inscripcion][nombre]" value="Inscripción" /> Inscripción</label></td>
                                        <td>
                                            {formatCurrency(clase.costoInscripcion)}
                                            <input type="hidden" name="cuotas[inscripcion][costo]" value={clase.costoInscripcion} />
                                            <input type="hidden" name="cuotas[inscripcion][fecha]" value={clase.fechaInicio} />
                                        </td>
                                        <td>{dayjs(clase.fechaInicio).format('DD/MM/YYYY')}</td>
                                    </tr>
                                )}
                                {cuotas.map((cuota, index) => (
                                    <tr key={cuota.nombre}>
                                        <td><label><input type="checkbox" defaultChecked name={`cuotas[${index}][nombre]`} value={cuota.nombre} /> {cuota.nombre}</label></td>
                                        <td>
                                            {formatCurrency(cuota.costo)}
                                            <input type="hidden" name={`cuotas[${index}][costo]`} value={cuota.costo} />
                                            <input type="hidden" name={`cuotas[${index}][fecha]`} value={cuota.fecha} />
                                        </td>
                                        <td>{dayjs(cuota.fecha).format('DD/MM/YYYY')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Form;
