import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";
import { request } from "../../../utils/request";

const useData = () => {
    const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const [finishedCallCount, setFinishedCallCount] = useState(0);

	const [facturas, setFacturas] = useState([]);
	const [recibos, setRecibos] = useState([]);
    const [notas, setNotas] = useState([]);

	const [compras, setCompras] = useState([]);
	const [notasCompra, setNotasCompra] = useState([]);

	const [cuentasPorPagar, setCuentasPorPagar] = useState([]);

	const [summaries, setSummaries] = useState({
		ventas : {
			efectivo: 0,
			transferencia: 0,
			cheque: 0,
			tarjeta: 0,
			deposito: 0,
			otro: 0,
			credito: 0,
			pagada: 0,
		},
		compras: {
			producto_contado: 0,
			producto_credito: 0,
			producto_nota: 0,
			item_contado: 0,
			item_credito: 0,
			item_nota: 0,
			suministro_contado: 0,
			suministro_credito: 0,
			suministro_nota: 0,
			contado: 0,
			credito: 0,
			nota: 0,
			formaPago: {
				efectivo: 0,
				transferencia: 0,
				tarjeta: 0,
				credito: 0,
				permuta: 0,
				nota: 0,
				mixto: 0,
			},
		},
	});

	const getFacturas = useCallback(() => {
		const searchString =
			location.search === ""
				? "?paginate=100000"
				: location.search + "&paginate=1000000";
		request(`facturas${searchString}`).then((res) => {
			setFacturas(res.data);
			setFinishedCallCount((count) => count + 1);
		});
	}, []);
	const getRecibos = useCallback(() => {
		const params = {
			tipo_pago: queryParams.get("metodo_pago")
				? queryParams.get("metodo_pago")
				: "",
			vendedor: queryParams.get("vendedor")
				? queryParams.get("vendedor")
				: "",
			zona: queryParams.get("zona") ? queryParams.get("zona") : "",
			categoria: queryParams.get("categoria") ? queryParams.get("categoria") : "",
			desde: queryParams.get("desde") ? queryParams.get("desde") : "",
			hasta: queryParams.get("hasta") ? queryParams.get("hasta") : "",
			numeracion: queryParams.get("numeracion") ? queryParams.get("numeracion") : "",
            tipo_recibo: 'ingreso',
            paginate: 1000000,
		};
		const searchString = new URLSearchParams(params);

        request(`recibos-ingreso?${searchString.toString()}`).then((res) => {
			setRecibos(res.data);
			setFinishedCallCount((count) => count + 1);
		});
	}, []);
    const getNotasCredito = useCallback(() => {
		const params = {
			desde: queryParams.get("desde") ? queryParams.get("desde") : "",
			hasta: queryParams.get("hasta") ? queryParams.get("hasta") : "",
		};
		const searchString = `?tipo=venta&desde=${params.desde}&hasta=${params.hasta}&paginate=1000000`;
		request(`notas-credito${searchString}`).then((res) => {
			setNotas(res.data);
			setFinishedCallCount((count) => count + 1);
		});
	}, []);

	const getCompras = useCallback(() => {
		const params = {
			desde: queryParams.get("desde") ? queryParams.get("desde") : "",
			hasta: queryParams.get("hasta") ? queryParams.get("hasta") : "",
		};
		const searchString = `?desde=${params.desde}&hasta=${params.hasta}&paginate=1000000`;
		request(`compras${searchString}`).then((res) => {
			setCompras(res.data);
			setFinishedCallCount((count) => count + 1);
		});
	}, []);
	const getNotasCompra = useCallback(() => {
		const params = {
			desde: queryParams.get("desde") ? queryParams.get("desde") : "",
			hasta: queryParams.get("hasta") ? queryParams.get("hasta") : "",
		};
		const searchString = `?tipo=compra&desde=${params.desde}&hasta=${params.hasta}&paginate=1000000`;
		request(`notas-credito${searchString}`).then((res) => {
			setNotasCompra(res.data);
			setFinishedCallCount((count) => count + 1);
		});
	}, []);

	const getCuentasPorPagar = useCallback(() => {
		const params = {
			desde: queryParams.get("desde") ? queryParams.get("desde") : "",
			hasta: queryParams.get("hasta") ? queryParams.get("hasta") : "",
		};
		const searchString = `?desde=${params.desde}&hasta=${params.hasta}&paginate=1000000`;
		request(`cuentas-por-pagar${searchString}`).then((res) => {
			setCuentasPorPagar(res.data);
			setFinishedCallCount((count) => count + 1);
		});
	}, []);


    const calculateSummaries = useCallback(() => {
		// Summary Ventas
		const credito = facturas.filter((factura) => factura.tipo === "credito").reduce((acc, factura) => acc + factura.total, 0)
			+ facturas.filter((factura) => factura.tipo === "contado").reduce((acc, factura) => acc + (factura.por_cobrar), 0);

		const recibosIndex = [];
		const ventasMetodosPagos = { efectivo: 0, transferencia: 0, cheque: 0, tarjeta: 0, deposito: 0, otro: 0, pagada: 0 };

		const addToVentasMetodosPagos = (metodo, value) => {
			if (Object.hasOwnProperty.call(ventasMetodosPagos, metodo))
				ventasMetodosPagos[metodo] += value;
			else
				ventasMetodosPagos.otro += value;
		};

		// Calculate recibos summaries
		for (const recibo of recibos) {
			recibo.cuentas.forEach(cuenta => recibosIndex.push(cuenta.factura_id));

			if (recibo.status === "cancelado") continue;

			addToVentasMetodosPagos(recibo.tipo_pago, recibo.abono_total);
		}

		// Calculate facturas summaries
		for (const factura of facturas) {
			if (factura.estado === 'pagada') {
				if (recibosIndex.indexOf(factura.id) > -1) continue;

				addToVentasMetodosPagos(factura.tipo_pago, factura.total);

				ventasMetodosPagos.pagada += factura.tipo_pago ? factura.total : 0;
            }
		}

		// Summary Compras
		const summaryCompras = {
			producto_contado: 0,
			producto_credito: 0,
			producto_nota: 0,
			item_contado: 0,
			item_credito: 0,
			item_nota: 0,
			suministro_contado: 0,
			suministro_credito: 0,
			suministro_nota: 0,
		};
		const formaPago = {
			efectivo: 0,
			transferencia: 0,
			tarjeta: 0,
			credito: 0,
			permuta: 0,
			nota: 0,
			mixto: 0,
		};
		for (const compra of compras) {
			const tipoPago = compra.tipoPago === "04 - COMPRA A CREDITO" ? "credito"
				: (compra.tipoPago === "06 - NOTA DE CREDITO" ? "nota" : "contado");

			const key = `${compra.tipo}_${tipoPago}`;

			if (Object.hasOwnProperty.call(summaryCompras, key)) {
				summaryCompras[key] += compra.total - compra.porPagar;
			}

			switch (compra.tipoPago) {
				case "01 - EFECTIVO":
					formaPago.efectivo += compra.total - compra.porPagar;
					break;
				case "02 - CHEQUES/TRANSFERENCIAS":
					formaPago.transferencia += compra.total - compra.porPagar;
					break;
				case "03 - TARJETA DE CREDITO/DEBITO":
					formaPago.tarjeta += compra.total - compra.porPagar;
					break;
				case "04 - COMPRA A CREDITO":
					formaPago.credito += compra.total - compra.porPagar;
					break;
				case "05 - PERMUTA":
					formaPago.permuta += compra.total - compra.porPagar;
					break;
				case "06 - NOTA DE CREDITO":
					formaPago.nota += compra.total - compra.porPagar;
					break;
				case "07 - MIXTO":
					formaPago.mixto += compra.total - compra.porPagar;
					break;
				default:
					break;
			}
		}
		for (const nota of notasCompra) {
			switch (nota.compra.tipo) {
				case "producto":
					summaryCompras.producto_nota += nota.total;
					break
				case "item":
					summaryCompras.item_nota += nota.total;
					break
				case "suministro":
					summaryCompras.suministro_nota += nota.total;
					break
				default:
					break;
			}
		}

		setSummaries({
			ventas: {
				...ventasMetodosPagos,
				credito,
			},
			compras: {
				...summaryCompras,
				contado: summaryCompras.producto_contado + summaryCompras.item_contado + summaryCompras.suministro_contado,
				credito: summaryCompras.producto_credito + summaryCompras.item_credito + summaryCompras.suministro_credito,
				nota: summaryCompras.producto_nota + summaryCompras.item_nota + summaryCompras.suministro_nota,
				formaPago,
			},
		});
		console.log(summaries);
    }, [recibos, facturas, compras, notasCompra]);

    useEffect(() => {
		getFacturas();
		getRecibos();
		getNotasCredito();
		getCompras();
		getNotasCompra();
		getCuentasPorPagar();
	}, []);

    useEffect(() => {
		if (finishedCallCount < 6) return;

        calculateSummaries();

        const timeout = setTimeout(() => {
			window.print();
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [finishedCallCount]);

	return useMemo(
		() => ({
			facturas,
			recibos,
			notas,
			summaries,
			cuentasPorPagar,
		}),
		[facturas, recibos, notas, summaries, cuentasPorPagar]
	);
};

const ReporteCuadreResumido = () => {
    const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];

    const { facturas, recibos, notas, summaries, cuentasPorPagar } = useData();

    const valorTotalFacturas = facturas.reduce((acc, factura) => acc + (factura.estado === 'cancelada' ? 0 : parseFloat(factura.total)), 0);
    const ValorTotalRecibos = recibos.reduce((acc, recibo) => acc + (recibo.status === "cancelado" ? 0 : parseFloat(recibo.abono_total)), 0);
    const ValorTotalNotaCreditos = notas.reduce((acc, nota) => acc + (nota.estado === "cancelada" ? 0 : parseFloat(nota.total)), 0);
	const totalCxP = cuentasPorPagar.reduce((acc, cxp) => acc + (cxp.total - cxp.porPagar), 0);

    return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Cuadre de Caja Resumido
				</h2>
                <div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
            </div>

            <div className="d-flex justify-content-center mt-4">
				<div className="row border" style={{fontSize:"0.9rem", width: "680px"}}>
                    <div className="col col-md-12">
						<h3 className="pt-3 pb-3 text-center border-bottom">Ventas</h3>
					</div>

                    <div className="col col-md-7 pb-3">
						<div className="col col-md-12">
							<b style={{fontSize: "18px"}}>Total Facturado</b> = <span className="float-right">{formatCurrency(valorTotalFacturas, "$")}</span>
						</div>
						<div className="col col-md-12 border-top">
							<h5 className="my-1 text-center">INGRESOS</h5>
							<b>Facturas</b> = <span className="float-right">{formatCurrency(summaries.ventas.pagada, "$")}</span><br/>
							<b>Recibos</b> = <span className="float-right">{formatCurrency(ValorTotalRecibos, "$")}</span><br/>
							<div className="border-top"><b>Total Ingresos</b> = <span className="float-right">{formatCurrency(summaries.ventas.pagada + ValorTotalRecibos, "$")}</span></div><br/>
						</div>
						<div className="col col-md-12" style={{paddingTop: "1.3rem"}}>
							<b>Ventas a crédito</b> = <span className="float-right">{formatCurrency(summaries.ventas.credito, "$")}</span><br/>
							<b>Menos notas crédito</b> = <span className="float-right">{formatCurrency(ValorTotalNotaCreditos, "$")}</span><br/>
							<div className="border-top"><b>Total de ventas</b> = <span className="float-right">{formatCurrency(summaries.ventas.credito - ValorTotalNotaCreditos, "$")}</span></div><br/>
						</div>
					</div>

                    <div className="col col-md-5">
						<h5 className="py-2 text-center">FORMA DE PAGO</h5>
						<b>Efectivo</b> = <span className="float-right">{formatCurrency(summaries.ventas.efectivo, "$")}</span><br/>
						<b>Transferencia</b> = <span className="float-right">{formatCurrency(summaries.ventas.transferencia, "$")}</span><br/>
						<b>Cheque</b> = <span className="float-right">{formatCurrency(summaries.ventas.cheque, "$")}</span><br/>
						<b>Tarjeta</b> = <span className="float-right">{formatCurrency(summaries.ventas.tarjeta, "$")}</span><br/>
						<b>Depósito</b> = <span className="float-right">{formatCurrency(summaries.ventas.deposito, "$")}</span><br/>
						<b>Otro</b> = <span className="float-right">{formatCurrency(summaries.ventas.otro, "$")}</span><br/>
					</div>
                </div>
            </div>

			<div className="d-flex justify-content-center mt-3">
				<div className="row border" style={{fontSize:"0.9rem", width: "680px"}}>
					<div className="col col-md-12">
						<h3 className="pt-3 text-center">Compras</h3>
					</div>

					<table className='table print-productos m-b-10'>
                        <thead className="head-table-producto">
                            <tr>
                                <th></th>
                                <th>Al contado</th>
                                <th>A crédito</th>
                                <th>Nota crédito</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>Inventario de productos</b></td>
                                <td>
									{formatCurrency(summaries.compras.producto_contado, "$")}
								</td>
                                <td>
									{formatCurrency(summaries.compras.producto_credito, "$")}
                                </td>
                                <td>
									{formatCurrency(summaries.compras.producto_nota, "$")}
								</td>
                            </tr>
							<tr>
                                <td><b>Inventario interno</b></td>
                                <td>
									{formatCurrency(summaries.compras.item_contado, "$")}
								</td>
                                <td>
									{formatCurrency(summaries.compras.item_credito, "$")}
                                </td>
                                <td>
									{formatCurrency(summaries.compras.item_nota, "$")}
								</td>
                            </tr>
							<tr>
                                <td><b>Trabajo/Suministro</b></td>
                                <td>
									{formatCurrency(summaries.compras.suministro_contado, "$")}
								</td>
                                <td>
									{formatCurrency(summaries.compras.suministro_credito, "$")}
                                </td>
                                <td>
									{formatCurrency(summaries.compras.suministro_nota, "$")}
								</td>
                            </tr>
							<tr>
                                <td>
									<strong>Total</strong>
								</td>
                                <td>
									<strong>
										{formatCurrency(summaries.compras.contado, "$")}
									</strong>
								</td>
                                <td>
									<strong>
										{formatCurrency(summaries.compras.credito, "$")}
									</strong>
                                </td>
                                <td>
									<strong>
										{formatCurrency(summaries.compras.nota, "$")}
									</strong>
								</td>
                            </tr>
                        </tbody>
                    </table>

					<div className="col col-md-12">
						<h3 className="pt-3 text-center">FORMA DE PAGO</h3>
					</div>
					<div className="col col-md-6 border-top">
						<b>01 - EFECTIVO</b> = <span className="float-right">{formatCurrency(summaries.compras.formaPago.efectivo, "$")}</span><br/>
						<b>02 - CHEQUES/TRANSFERENCIAS</b> = <span className="float-right">{formatCurrency(summaries.compras.formaPago.transferencia, "$")}</span><br/>
						<b>03 - TARJETA DE CREDITO/DEBITO</b> = <span className="float-right">{formatCurrency(summaries.compras.formaPago.tarjeta, "$")}</span><br/>
						<b>04 - COMPRA A CREDITO</b> = <span className="float-right">{formatCurrency(summaries.compras.formaPago.credito, "$")}</span><br/>
					</div>
					<div className="col col-md-6 border-top">
						<b>05 - PERMUTA</b> = <span className="float-right">{formatCurrency(summaries.compras.formaPago.permuta, "$")}</span><br/>
						<b>06 - NOTA DE CREDITO</b> = <span className="float-right">{formatCurrency(summaries.compras.formaPago.nota, "$")}</span><br/>
						<b>07 - MIXTO</b> = <span className="float-right">{formatCurrency(summaries.compras.formaPago.mixto, "$")}</span><br/>
					</div>

					<div className="col col-md-6">
						<h5 className="pt-3 pb-1">
							Total Comprado: {formatCurrency(summaries.compras.contado + summaries.compras.credito + summaries.compras.nota, "$")}
						</h5>
						<h5 className="pt-1 pb-3">
							Total Pagado en CXP: {formatCurrency(totalCxP, "$")}
						</h5>
					</div>

					<div className="col col-md-6">
						<h3 className="pt-3 pb-0 mb-0 text-center">TOTAL DE PAGOS</h3>
						<h3 className="pt-0 pb-3 mb-0 text-center">{formatCurrency(summaries.compras.contado + summaries.compras.credito + summaries.compras.nota + totalCxP, "$")}</h3>
					</div>
				</div>
			</div>
        </>
    );
};

export default ReporteCuadreResumido;
