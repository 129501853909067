import React, { Component } from "react";

import { withRequest } from "../../../utils/request";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../../general/Loader";
import { formatCurrency, getLink } from "../../../utils/helpers";
import { Link } from "react-router-dom";

class Categorias extends Component {
    state = {
        categorias: {},
        categoria: {},
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        loading: true,
    };
    componentDidMount() {
        this.getCategorias();
    }
    getCategorias = async (url = null) => {
        url = url ? url + "&limit=15" : "categorias?limit=15";
        const categorias = await this.props.request(url);

        if (categorias.links) {
            this.setState({
                categorias: categorias.data,
                links: categorias.links,
                meta: categorias.meta,
                loading: false,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            categoria: { operadores: [] },
        });
    };
    toggleEditModal = (categoria) => {
        this.setState({
            showEdit: !this.state.showEdit,
            categoria: categoria,
        });
    };
    render() {
        const { categorias, loading } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/configuracion/')}>&laquo; Volver a configuración</Link>
                    <h1 className='page-title row'>
                        <span className='col'>Categorías de Vehículos</span>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Categoría
                        </button>
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!categorias.length ? (
                                <div>
                                    {!loading && (
                                        <div>
                                            Todavía no hay categorías creadas.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th className='text-center'>
                                                    Vehículos
                                                </th>
                                                <th className='text-center'>
                                                    Operadores
                                                </th>
                                                <th className='text-right'>
                                                    Costo/Tonelada
                                                </th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categorias.map((categoria) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={categoria.id}>
                                                    <td>{categoria.nombre}</td>
                                                    <td className='text-center'>
                                                        {categoria.vehiculos}
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            categoria.operadores
                                                                .length
                                                        }
                                                    </td>
                                                    <td className='text-right'>
                                                        {formatCurrency(
                                                            categoria.costo
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <button
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Edit'
                                                                onClick={() => {
                                                                    this.toggleEditModal(
                                                                        categoria
                                                                    );
                                                                }}>
                                                                <i className='zmdi zmdi-edit' />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getCategorias}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        categoria={this.state.categoria}
                        getCategorias={this.getCategorias}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        categoria={this.state.categoria}
                        getCategorias={this.getCategorias}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Categorias);
