import React, { useEffect, useState } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import { toast } from "../../utils/helpers";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { apiRequest } from "../../hooks/apiRequest";
import Loader from "../general/Loader";

const Edit = ({getCheques, toggle}) => {

    const [state, setState] = useState({
        cheque: {},
        loading: true,
        saving: false,
    });

    const [errors, setErrors] = useState({});
    const [afectarCuentas, setAfectarCuentas] = useState(true);

    const match = useRouteMatch();

    const getCheque = async () => {
        const id = match.params.id;
        const cheque = await apiRequest(null, 'cheques/' + id, 'get');
        if (cheque.data) {
            setState({
                ...state,
                cheque: cheque.data,
                loading: false,
            });
        }
    }

    const editCheque = async (event) => {
        event.preventDefault();

        setState({ ...state, saving: true });

        const data = new FormData(event.target);
        const id = match.params.id;

        const response = await apiRequest(data, `cheques/${id}`);

        if (response.success) {
            toast('Cheque actualizado.');
            toggle();
            getCheques();
        }

        if (response.code === 422) {
            setErrors(response.data.message);
            toast('Error editando cheque.', 'error');
        }

        setState({ ...state, saving: false });
    };

    const deleteCheque = async (event, cheque) => {
        event.preventDefault();

        setState({ ...state, saving: true });

        const token = auth.getToken();

        const url = `cheques/${cheque}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            getCheques();
            toggle();
            toast("Cheque borrado.");
            return;
        } else {
            toast(response.data, "error");
        }

        setState({ ...state, saving: false });
    };

    useEffect(() => {
        getCheque();
    }, []);

    useEffect(() => {
        setAfectarCuentas(state.cheque.movimientos?.length > 0)
    }, [state]);

    const { cheque, loading, saving } = state;

    if (loading) {
        return (<Loader />)
    }
    return (
        <form method='post' onSubmit={editCheque}>
            <input type='hidden' name='_method' value='patch' />
            <div className='popup__header'>
                Editar Cheque
                <div className="float-right">
                    <small className="m-r-15">Afectar cuentas contables</small>
                    <label className='switch switch-3d switch-success mr-3'>
                        <input type='hidden' name='afectar_cuentas' value='no' />
                        <input
                            key={cheque.id}
                            type='checkbox'
                            className='switch-input'
                            id='afectar_cuentas'
                            name='afectar_cuentas'
                            value='yes'
                            checked={afectarCuentas}
                            onChange={() => setAfectarCuentas(!afectarCuentas)}
                        />
                        <span className='switch-label' />
                        <span className='switch-handle' />
                    </label>
                </div>
            </div>
            <div className='popup__body'>
                <Form cheque={cheque} errors={errors} afectarCuentas={afectarCuentas} />
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    className='btn float-left'
                    onClick={(e) => deleteCheque(e, cheque.id)}>
                    <i className='fas fa-trash-alt'></i>
                </button>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                {saving ? (
                    <Loader type='small' />
                ) : (
                    <button type='submit' className='btn btn-primary'>
                        Crear
                    </button>
                )}
            </div>
        </form>
    );
}

export default Edit;
