import React, { useEffect, useState } from 'react';
import Loader from '../../general/Loader';
import Modal from '../../general/Modal';
import FormGrados from './Forms/FormGrados';
import { useConfiguracionAcademica } from './ConfiguracionAcademicaContext';

const Grados = () => {

    const {items, item, onFiltroChange, toggleCreateModal, toggleEditModal, toggleDeleteModal, filtrosQuery, loading, errors, getData, getTable, create, edit, deleteItem, clasesEstadoMapping, saving, showEdit, showDelete, showCreate} = useConfiguracionAcademica();

    const [niveles, setNiveles] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [ciclos, setCiclos] = useState([]);
    const [salidasOptativas, setSalidasOptativas] = useState([]);

    const url = "academico/grados";
    useEffect(() => {
        getData(`${url}${filtrosQuery}`);
    }, [filtrosQuery]);

    useEffect(() => {
        getTable(`academico/niveles`).then(data => setNiveles(data))
        getTable(`academico/modalidades`).then(data => setModalidades(data))
        getTable(`academico/ciclos`).then(data => setCiclos(data))
        getTable(`academico/salidas-optativas`).then(data => setSalidasOptativas(data))
    }, []);

    return (<>
        <div className="row m-b-20 descripcion-subpage">
            <div className="col">
                <input
                    type="text"
                    className='form-control'
                    name="buscar"
                    onChange={(e) =>
                        onFiltroChange(e.target.value, "buscar")
                    }
                    placeholder='Buscar...'
                />
            </div>
            <div className="col-md-3 text-right">
                <button className="au-btn au-btn-icon au-btn--green au-btn--small" onClick={() => toggleCreateModal()}>+ Nuevo Grado</button>
            </div>
        </div>
        <hr />
        {loading && <Loader />}
        {!loading && items.length > 0 && (<div className="table-data">
            <table className='table table-data2'>
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Grado</th>
                        <th>Optativa</th>
                        <th>Modalidad</th>
                        <th>Nivel</th>
                        <th>Ciclos</th>
                        <th className='text-center'>Estado</th>
                        <th className='text-right'></th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item => (
                        <tr key={item.id} className="tr-shadow">
                            <td>{item.codigo}</td>
                            <td style={{lineHeight: 1.2}}>
                                <div>{item.nombre}</div>
                                {item.edadDesde && (
                                    <small>Edad: {item.edadDesde} {item.edadHasta ? `-${item.edadHasta}` : ''} años</small>
                                )}
                            </td>
                            <td>{item.salidaOptativa?.nombre}</td>
                            <td>{item.modalidad?.nombre}</td>
                            <td>{item.nivel?.nombre}</td>
                            <td>{item.ciclos?.map(c => c.nombre).join(', ')}</td>
                            <td className='text-center'>
                                <span className={`badge badge-${clasesEstadoMapping[item.estado]} text-uppercase`}>{item.estado}</span>
                            </td>
                            <td className='text-right'>
                                <div className="table-data-feature">
                                    <button
                                        className="item"
                                        onClick={() => toggleEditModal(item)}
                                    >
                                        <i className="zmdi zmdi-edit" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )))}
                </tbody>
            </table>
        </div>)}

        {!loading && items.length === 0 && (
            <div className='text-center p-t-20 p-b-40'>{ filtrosQuery !== '?' ? "No hay elementos que coincidan con la búsqueda." : "No hay elementos creados." }</div>
        )}
        <Modal
            blank
            show={showCreate}
            toggle={() => toggleCreateModal()}>
            <form method='post' onSubmit={e => create(e, url, "Grado Creado.") }>
                <input type="hidden" name="_method" value="POST" />
                <div className='popup__header'>Crear Nuevo Grado</div>
                <div className='popup__body'>
                    <FormGrados
                        item={{}}
                        errors={errors}
                        niveles={niveles}
                        modalidades={modalidades}
                        salidasOptativas={salidasOptativas}
                        ciclos={ciclos}
                    />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={() => toggleCreateModal()}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving ? (<Loader type="small" />) : (<button type='submit' className='btn btn-primary'>
                        Crear
                    </button>)}
                </div>
            </form>
        </Modal>
        <Modal
            blank
            show={showEdit}
            toggle={toggleEditModal}>
            <form method='post' onSubmit={e => edit(e, url, "Grado Editado.") }>
                <input type="hidden" name="_method" value="PATCH" />
                <div className='popup__header'>Editar Grado</div>
                <div className='popup__body'>
                    <FormGrados
                        item={item}
                        errors={errors}
                        niveles={niveles}
                        modalidades={modalidades}
                        salidasOptativas={salidasOptativas}
                        ciclos={ciclos}
                    />
                </div>
                <div className='popup__footer'>
                    {!saving && (
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={toggleDeleteModal}>
                        <i className='fas fa-trash-alt'></i>
                    </button>)}
                    <button
                        type='button'
                        onClick={() => toggleEditModal()}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {saving ? (<Loader type="small" />) : (<button type='submit' className='btn btn-primary'>
                        Actualizar
                    </button>)}
                </div>
            </form>
        </Modal>
        <Modal
            title='Borrar Grado'
            show={showDelete}
            callback={() => deleteItem(url, "Grado borrado.")}
            acceptText="Borrar"
            toggle={toggleDeleteModal}>
            ¿Seguro que deseas borrar {item.nombre}?
        </Modal>
    </>)
}

export default Grados;
