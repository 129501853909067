import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";

class Despachados extends Component {
    state = {
        programacion: {},
        tarjetas: [],
    };
    componentDidMount() {
        this.getProgramacion();
    }
    getProgramacion = async () => {
        const id = this.props.match.params.id;
        const response = await this.props.request("programaciones/" + id);

        if (response.success) {
            this.setState({
                programacion: {
                    id: response.data.id,
                    nombre: response.data.nombre,
                    fecha: response.data.fecha,
                },
                tarjetas: response.data.tarjetas,
            });
            window.print();
        }
    };
    render() {
        const { programacion, tarjetas } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const totalEfectivo = tarjetas.reduce((a,t) => {
            if (t.giTicket) {
                return a + parseFloat(t.giTicket.total);
            }
            return a;
        }, 0);

        const totalDepositos = tarjetas.reduce((a,t) => {
            if (t.giTicket) {
                const depositos = t.giTicket.depositos.reduce((b, d) => b + parseFloat(d.monto),0);
                return a + depositos;
            }
            return a;
        }, 0);

        const totalDolares = tarjetas.reduce((a,t) => {
            if (t.giTicket) {
                return a + t.giTicket.dolares;
            }
            return a;
        }, 0);

        const totalCuadres = tarjetas.reduce((a,t) => {
            return t.giTicket ? a + 1 : a;
        }, 0);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Programación {programacion.nombre}
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-t-10'>
                        <div className='float-left'>
                            <strong>TOTAL VENTAS: {formatCurrency(totalEfectivo)}</strong>
                        </div>

                        <div className='float-right text-right'>
                            <strong>TOTAL CUADRES: {totalCuadres}</strong>
                        </div>
                    </div>
                    <div className='col-md-12 text-right'>
                        <div className='float-left'>
                            <strong>TOTAL DÓLARES: {formatCurrency(totalDolares, 'USD$')}</strong>
                        </div>

                        <div className='float-right text-right'>
                            <strong>TOTAL VENTAS: {formatCurrency(totalDepositos + totalEfectivo)}</strong>
                        </div>
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            <strong>TOTAL DEPÓSITOS: {formatCurrency(totalDepositos)}</strong>
                        </div>

                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ width: "150px" }}>Ficha</th>
                            <th
                                style={{
                                    width: "550px",
                                }}>
                                Mensajero/Zona
                            </th>
                            <th className='text-right'>Efectivo</th>
                            <th className='text-right'>Depósitos</th>
                            <th className='text-right'>Dólares</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tarjetas.map((tarjeta) => {
                            const chofer = tarjeta.operadores.filter(
                                (op) =>
                                    op.nombre &&
                                    ["chofer", "mensajero"].includes(op.nombre.toLowerCase())
                            )[0];
                            return (
                                <tr key={tarjeta.ficha}>
                                    <td>
                                        {tarjeta.ficha}
                                        <div>
                                            <small class="categoria-camion">
                                                {tarjeta.categoria.toUpperCase()}
                                            </small>
                                        </div>
                                    </td>
                                    <td>
                                        <strong>
                                            {chofer.asignadoNombre}
                                        </strong>
                                        <div>
                                            <small>
                                                CEL: {chofer.asignadoTelefono} - {" "}
                                                <strong>
                                                    {tarjeta.ruta &&
                                                        tarjeta.ruta.nombre}
                                                </strong>{" "}
                                                (
                                                {tarjeta.ruta &&
                                                    tarjeta.ruta.descripcion}
                                                )
                                            </small>
                                        </div>
                                        {tarjeta.nota && (
                                            <small>
                                                <strong>Nota:</strong>{" "}
                                                {tarjeta.nota}
                                            </small>
                                        )}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(tarjeta.giTicket?.total)}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(tarjeta.giTicket?.depositos.reduce((a,c) => a + c.monto, 0))}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(tarjeta.giTicket?.dolares)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(Despachados));
