import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { request } from '../../../../utils/request';

export default function FormBecas({item, errors}) {
    const [state, setState] = useState({
        contactos: [],
    });

    const getTable = (url, nombre) => {
        request(url).then((res) => {
            setState((prevState) => ({
                ...prevState,
                [nombre]: res.data,
            }));
		});
    };

    useEffect(() => {
        getTable('contactos', 'contactos');
    }, []);

    const {contactos} = state;

    return (<>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Nombre:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='text'
                    className='text-input form-control'
                    id='nombre'
                    name='nombre'
                    defaultValue={item.nombre}
                />
                {errors.nombre && (
                    <small className='help-blockParams form-Text'>
                        {errors.nombre[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Descripción:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='text'
                    className='text-input form-control'
                    id='descripcion'
                    name='descripcion'
                    defaultValue={item.descripcion}
                />
                {errors.descripcion && (
                    <small className='help-blockParams form-Text'>
                        {errors.descripcion[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Monto:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='text'
                    className='text-input form-control'
                    id='monto'
                    name='monto'
                    defaultValue={item.monto}
                />
                {errors.monto && (
                    <small className='help-blockParams form-Text'>
                        {errors.monto[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Porcentaje:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='text'
                    className='text-input form-control'
                    id='porcentaje'
                    name='porcentaje'
                    defaultValue={item.porcentaje}
                />
                {errors.porcentaje && (
                    <small className='help-blockParams form-Text'>
                        {errors.porcentaje[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Estado:
                </label>
            </div>
            <div className='col col-md-7'>
                <Select
                    name="estado"
                    placeholder="Seleccionar..."
                    defaultValue={item.estado && {
                        label: item.estado, value: item.estado
                    }}
                    options={[
                        {label: 'Activa', value: 'Activa'},
                        {label: 'Inactiva', value: 'Inactiva'}
                    ]}
                />
                {errors.estado && (
                    <small className='help-blockParams form-Text'>
                        {errors.estado[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Patrocinador:
                </label>
            </div>
            <div className='col col-md-7'>
                <Select
                    name="contacto_id"
                    placeholder="Seleccionar..."
                    defaultValue={item.contacto && {
                        label: item.contacto.nombre,
                        value: item.contacto.id,
                    }}
                    options={contactos && contactos.map(c => ({
                        label: `${c.codigo} - ${c.nombre}`,
                        value: c.id,
                    }))}
                />
                {errors.contacto_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.contacto_id[0]}
                    </small>
                )}
            </div>
        </div>
    </>)
}
