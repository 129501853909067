import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import { apiRequest } from "../../../hooks/apiRequest";
import { getLink, toast, validarPINSeguridad } from "../../../utils/helpers";
import Loader from "../../general/Loader";

import Detalles from "./Components/Detalles";
import Seleccion from "./Components/Seleccion";
import { request } from "../../../utils/request";
import PagoForm from "./Components/PagoForm";
import Modal from "../../general/Modal";
import Cocurriculares from "./Components/Cocurriculares";
import Relacionados from "./Components/Relacionados";
import Balance from "./Components/Balance";
import EditarPagoForm from "./Components/EditarPagoForm";

const Create = () => {
    const params = new URLSearchParams(window.location.search);
    const contactoId = params.get('contacto');
    const cocurricularId = params.get('cocurricular');

    const [errors, setErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [showProcesarModal, setShowProcesarModal] = useState(false);
    const [servicios, setServicios] = useState([]);
    const [seleccion, setSeleccion] = useState([]);
    const [contacto, setContacto] = useState({});
    const [balance, setBalance] = useState(0);
    const [pendiente, setPendiente] = useState(0);
    const [numeraciones, setNumeraciones] = useState([]);
    const [cocurriculares, setCocurriculares] = useState([]);
    const [showPINModal, setShowPINModal] = useState(false);
    const [pinSeguridad, setPinSeguridad] = useState("");
    const [seleccionCocurricular, setSeleccionCocurricular] = useState([]);
    const [showEditarPagos, setShowEditarPagos] = useState(false);

    const [formData, setFormData] = useState({
        comprobante: [],
    });
    const [page, setPage] = useState(1);
    const [pagoInfo, setPagoInfo] = useState({
		pago: 0,
		tipo_pago: "efectivo",
		cuenta: "",
		fecha: dayjs().format("YYYY-MM-DD"),
	});

    const procesarPago = (e) => {
        e.preventDefault();

        setSaving(true);

        const data = new FormData(e.target);

        apiRequest(data, "academico/facturacion/procesar-pago-cocurricular", 'post').then(res => {
            if (res.success) {
                setShowProcesarModal(false);
                limpiar();
                toast('Pago aplicado exitosamente');
                window.open(getLink(`/imprimir/academico/recibos/${res.data?.recibo.id}/cocurricular`));
            }
            if (res.code === 422) {
                toast('Favor revisar todos los campos.', 'error');
            }
        });

        setSaving(false);
    }

    const getNumeraciones = async () => {
        request('numeracion-documentos?tipo_documento=factura_venta').then((response) => {
            setNumeraciones(response.data);
        });
    }

    const getCocurriculares = async () => {
        const url = "academico/cocurriculares";
        const response = await apiRequest({}, url, 'get');
        setCocurriculares(response.data);
    }

    const getComprobanteData = (comprobante) => {
		if (!comprobante) return;

		request(`facturas/get-comprobante/${comprobante}`).then((res) => {
			setFormData((form) => ({
				...form,
				comprobante: res.data,
			}));
		});
	};

    const getClases = (contactoId) => {
        apiRequest({}, `academico/estudiantes/${contactoId}/cocurriculares`, 'get').then(res => {
            if (res.data) {
                setServicios(res.data);
                const balanceTotal = res.data.reduce((acc, servicio) => {
                    acc.pendiente += parseFloat(servicio.pendiente);
                    acc.balance += parseFloat(servicio.balance);
                    return acc;
                }, {pendiente: 0, balance: 0});
                setPendiente(balanceTotal.pendiente);
                setBalance(balanceTotal.balance);
                if (res.data.length === 1) {
                    setSeleccionCocurricular([res.data[0]?.clase?.id]);
                }
            }
        });
    };

    const editarPagos = (e) => {
        e.preventDefault();

        setSaving(true);

        const data = new FormData(e.target);

        apiRequest(data, "academico/facturacion/editar-pagos-cocurriculares", 'post').then(res => {
            if (res.success) {
                getClases(contacto.id);
                setShowEditarPagos(false);
                toast('Pagos actualizados exitosamente');
            }
            if (res.code === 422) {
                toast('Favor revisar todos los campos.', 'error');
            }
            setSaving(false);
        });

    }

    const openProcesar = () => {
        if (total === 0) {
            toast('Debes seleccionar los servicios a pagar.', 'error')
            return;
        }
        if (!formData.comprobante?.prefijo) {
            toast('Selecciona un tipo de comprobante para proceder.', 'error')
            return;
        }
        setPage(1);
        setPagoInfo({
            pago: 0,
            tipo_pago: "efectivo",
            cuenta: "",
            fecha: dayjs().format("YYYY-MM-DD"),
        });
        setShowProcesarModal(true)
    }

    const limpiar = () => {
        setFormData({
            comprobante: [],
        });
        setServicios([]);
        setContacto({});
        setBalance('');
        setPendiente('');
        document.querySelector('#comprobante').selectedIndex = 0;
    }

    const checkPINSeguridad = async () => {
		if (await validarPINSeguridad(pinSeguridad)) {
			setPinSeguridad("");
			setShowPINModal(false);
            setShowEditarPagos(true);
			return;
		}

		toast("PIN incorrecto, intente de nuevo", "error");
	};

    useEffect(() => {
        if (contactoId) {
            request(`contactos/${contactoId}`).then((res) => {
                if (res.success) {
                    setContacto(res.data);
                }
            });
            getClases(contactoId);
        }
        getCocurriculares();
        getNumeraciones();
    }, []);

    useEffect(() => {
        if (cocurricularId) {
            setSeleccionCocurricular([parseInt(cocurricularId)]);
        }
    }, [cocurriculares]);

    const serviciosFiltrados = servicios?.filter(s => seleccionCocurricular.includes(s.clase.id)).reduce((acc, servicio) => {
        servicio.pagos.forEach(s => acc.push(s));
        return acc;
    }, []);

    const updateSeleccion = (id) => {
        setSeleccion(prev => {
            if (prev.length === 1 && prev.includes(id)) {
                return [];
            } else {
                return serviciosFiltrados.filter(s => (s.estado === 'pendiente') && s.id <= id).map(s => s.id)
            }
        });
    }

    const serviciosSeleccionados = serviciosFiltrados?.filter(s => {
        return s.estado === 'pendiente' && seleccion.includes(s.id);
    });

    const total = serviciosFiltrados.reduce((a,s) => {
        if (seleccion.includes(s.id)) {
            return a + parseFloat(s.restante);
        }
        return a;
    }, 0);

    const relacionados = [].concat(contacto?.estudiante?.relacionados || [], contacto?.estudiante?.tutores[0]?.estudiantes?.filter(e => e.id !== contacto.estudiante?.id) || []);

    return (
        <div>
            <form
                method="post"
                onSubmit={procesarPago}
            >
                <div className="page-header">
                    <Link to={getLink('/academico/recibos-cocurriculares')}>&laquo; Volver al listado</Link>
                    <h1 className="page-title row">
                        <div className="col">
                            <span className="m-r-15">Cobros Cocurriculares</span>
                        </div>

                        <div className="col-md-2 offsett-9">
                            <div
                                className="form-group"
                                style={{ marginBottom: "0.3rem" }}
                            >
                                <select
                                    name="numeracion_id"
                                    id="comprobante"
                                    onChange={(e) =>
                                        getComprobanteData(e.target.value)
                                    }
                                >
                                    <option value="">
                                        Seleccione Comprobante
                                    </option>
                                    {numeraciones.map(
                                        (comprobante) => (
                                            <option
                                                value={comprobante.id}
                                                key={comprobante.id}
                                            >
                                                {comprobante.nombre}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>
                    </h1>
                </div>

                <Detalles
                    getClases={getClases}
                    contacto={contacto}
                    setContacto={setContacto}
                    balance={balance}
                    pendiente={pendiente}
                    formData={formData}
                />

                {servicios.length === 0 && (
                    <div className="m-t-25">
                        <Link className="btn btn-outline-primary" to={getLink('/academico/recibos-cocurriculares')}>&laquo; Volver atrás</Link>
                    </div>
                )}

                {servicios.length > 0 && (
                    <div className="row m-t-20">
                        <div className="col">
                            <Seleccion
                                total={total}
                                servicios={servicios}
                                seleccion={seleccion}
                                serviciosFiltrados={serviciosFiltrados}
                                updateSeleccion={updateSeleccion}
                                seleccionCocurricular={seleccionCocurricular}
                            />
                            <div className="page-footer text-right col-md-12">
                                {seleccion.length > 0 && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary float-left"
                                        onClick={() => {setShowPINModal(true)}}
                                    >
                                        Anular/Exonerar Pagos
                                    </button>
                                )}
                                <Link className="btn btn-outline-primary" to={getLink('/academico/recibos-cocurriculares')}>&laquo; Volver atrás</Link>
                                {!saving && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        onClick={() => limpiar()}
                                    >
                                        Limpiar
                                    </button>
                                )}

                                {saving ? (
                                    <Loader type="small" />
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={openProcesar}
                                    >
                                        Procesar Pago
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Cocurriculares
                                servicios={servicios}
                                seleccionCocurricular={seleccionCocurricular}
                                setSeleccionCocurricular={setSeleccionCocurricular}
                            />
                            {contacto?.estudiante && (
                                <div className="m-t-25">
                                    <Balance
                                        estudiante={contacto.estudiante}
                                    />
                                </div>
                            )}
                            {relacionados?.length > 0 && (
                                <div className="m-t-25">
                                    <Relacionados
                                        relacionados={relacionados}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <Modal
                    title="Procesar Pago"
                    show={showProcesarModal}
                    toggle={() => {
                        setShowProcesarModal((state) => !state);
                    }}
                    footer={
                        <ModalFooter
                            setShowProcesarModal={setShowProcesarModal}
                            pago={total}
                            seleccion={serviciosSeleccionados}
                            abono={pagoInfo.pago}
                            page={page}
                            setPage={setPage}
                            saving={saving}
                            fecha={dayjs().format("YYYY-MM-DD")}
                        />
                    }
                    size="650px"
                >
                    <PagoForm
                        contacto={contacto}
                        seleccion={serviciosSeleccionados}
                        total={total}
                        abono={pagoInfo.pago >= total ? total : pagoInfo.pago}
                        fecha={dayjs().format("YYYY-MM-DD")}
                        pagoInfo={pagoInfo}
                        setPagoInfo={setPagoInfo}
                        page={page}
                    />
                </Modal>
            </form>

            {/* <---PIN DE SEGURIDAD MODAL--- */}
            <Modal
                title="PIN de seguridad"
                show={showPINModal}
                callback={() => checkPINSeguridad()}
                acceptText="Aceptar"
                toggle={() => setShowPINModal((state) => !state)}
                size="419px"
            >
                <div className="form-group">
                    <label>PIN de seguridad</label>
                    <input
                        type="password"
                        className="form-control"
                        id="PIN"
                        name="pin_seguridad"
                        value={pinSeguridad}
                        onChange={(e) =>
                            setPinSeguridad(e.target.value)
                        }
                    />
                </div>
            </Modal>

            <Modal
                title="Anular/Exonerar Pagos"
                show={showEditarPagos}
                blank
                toggle={() => setShowEditarPagos(false)}
            >
                <form method="POST" onSubmit={editarPagos}>
                    <EditarPagoForm
                        errors={errors}
                        toggle={() => setShowEditarPagos(false)}
                        seleccion={seleccion}
                    />
                </form>
            </Modal>
        </div>
    );
}

export default Create;

const ModalFooter = React.memo(
	({
		setShowProcesarModal,
		pago,
		abono,
		page,
		setPage,
		saving = false,
		fecha,
        seleccion,
	}) => {
		const prevPage = () => setPage((state) => state - 1);
		const nextPage = () => {
			if (parseFloat(abono) <= 0) {
				toast(
					"El monto no puede ser igual o menor a cero",
					"error"
				);
				return;
			}
			if ((seleccion.length > 1 || seleccion[0].nombre.toLowerCase().includes('nscripci')) && pago > abono) {
				toast(
                    seleccion[0].nombre.includes('nscripci')
                        ? "El monto no puede ser menor al total de la inscripción."
                        : "El monto no puede ser menor al total si hay más de una cuota seleccionada.",
					"error"
				);
				return;
			}
			if (!fecha) {
				toast("Elija una fecha para continuar", "error");
				return;
			}
			setPage((state) => state + 1);
		};

		return (
			<>
				<button
					type="button"
					onClick={() => setShowProcesarModal((state) => !state)}
					className="btn btn-plain"
				>
					Cancelar
				</button>
				{page === 2 && (
					<button
						type="button"
						onClick={prevPage}
						className="btn btn-plain float-left"
					>
						&larr; Atrás
					</button>
				)}
				{page === 1 && (
					<button
						type="button"
						onClick={nextPage}
						className="btn btn-primary"
					>
						Continuar
					</button>
				)}
				{page === 2 &&
					(saving ? (
						<Loader type="small" />
					) : (
						<button
							className="btn btn-outline-primary"
							type="submit"
						>
							Aplicar Pago
						</button>
					))}
			</>
		);
	}
);
