import React from "react";

const Loader = ({ type }) => {
    if (type === "small") {
        return (
            <div
                style={{ display: "inline-block" }}
                className='small-loader lds-dual-ring'
            />
        );
    }
    return (
        <div className='loader loader-wrapper'>
            <div className='lds-ellipsis'>
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export default Loader;
