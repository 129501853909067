/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	useEffect,
	useState,
	useRef,
} from "react";
import Loader from "../../../general/Loader";
import { request } from "../../../../utils/request";
import {
	formatCurrency,
	getLink,
	getVendedoresForDropdown,
	money,
	toast,
	validarPINSeguridad,
} from "../../../../utils/helpers";
import { useHistory } from "react-router-dom";
import { apiRequest } from "../../../../hooks/apiRequest";

import Create from "../../../Contactos/Create";
import Edit from "../../../Contactos/Edit";
import Modal from "../../../general/Modal";
import ProductosFactura from "./ProductosFactura";
import Detalles from "./Detalles";
import dayjs from "dayjs";
import PrefixedInput from "../../../general/PrefixedInput";
import { auth } from "../../../../utils/auth";

import { calcularDescuento, calcularExentoGrabado, calcularImpuestos, calcularSubtotal, calcularTotal, calcularTotalImpuestos, templateProducto } from "../../Components/utils";

const Form = ({ facturaActual, isEdit }) => {
	const history = useHistory();

	const formRef = useRef();
    const [loading, setLoading] = useState(true);

	//Cosas generales como formulario
	const [form, setForm] = useState({
		factura: {},
		comprobante: {},
		fecha: dayjs().format("YYYY-MM-DD"),
		fecha_vencimiento: dayjs().format("YYYY-MM-DD"),
		notas: "",
		categorias: [],
		frecuencia_pago: "mensual",
		termina_en: "meses",
		activo: true,
		anio_generacion: null,
		dia_generacion: null,
		fecha_inicio: null,
		recurrencia_id: null,
		mes_generacion: null,
		ocurrencias: null,
	});

    const [contactoId, setContactoId] = useState();
    const [facturaProductos, setFacturaProductos] = useState([
        templateProducto(Date.now())
    ]);
    const [comprobante, setComprobante] = useState({});
    const [plazo, setPlazo] = useState({});
    const [errors, setErrors] = useState({});

	const [showCreateContactoModal, setShowCreateContactoModal] =
		useState(false);
	const [showEditContactoModal, setShowEditContactoModal] = useState(false);
	const [showPINModal, setShowPINModal] = useState(false);
	const [showAgregarPagoModal, setShowAgregarPagoModal] = useState(false);
	const [showCancel, setShowCancel] = useState(false);

	const [totalitbis, setTotalItbis] = useState(0);

	// Opciones de los Dropdown
	const [dropdownOptions, setDropdownOptions] = useState({
		contactos: [],
		terminos: [],
		productos: [],
		vendedores: [],
		rutas: [],
		grupos: [],
		impuestos: [],
		categorias: [],
	});

	const [saving, setSaving] = useState(false);
	const [createButtonClicked, setCreateButtonClicked] = useState("normal");
	const [pinSeguridad, setPinSeguridad] = useState("");
	const [pagoInfo, setPagoInfo] = useState({
		pago: null,
		tipo_pago: "efectivo",
		terminal_tarjeta: "",
		tipo: "visa",
		banco: "",
		num_aprobacion: "",
		num_cheque: "",
		num_transferencia: "",
		num_deposito: "",
		num_transaccion: "",
	});

	//Calculos Finales
	const [sumatoriaFinal, setSumatoriaFinal] = useState({
		subtotal: 0,
		descuento: 0,
		impuestos: {},
		exentoGrabado: {},
		total: 0,
	});

	//Para llenar inputs de contacto (Cliente)
	const [contactoInfo, setContactoInfo] = useState({});

    const resetFacturaProductos = () => {
        setFacturaProductos([
            templateProducto(Date.now())
        ]);
    }

	const toggleCreateContactoModal = async (e, contacto = null) => {
		if (e) e.preventDefault();
		setShowCreateContactoModal((state) => !state);

		if (!contacto || !Object.keys(contacto).includes("id")) return;

		await getContactosForDropdown();

		setContactoId(contacto.id);
	};

	const toggleEditContactoModal = (e, contacto = null) => {
		if (e) e.preventDefault();
		setShowEditContactoModal((state) => !state);

		if (!contacto || !Object.keys(contacto).includes("id")) return;

		getContactosForDropdown();

		setContactoId(contacto.id);

		changeContactInfo(contacto.id, false);
	};

	const changeContactInfo = (contacto, clear = true) => {
		if (!contacto) return;

		request(`info-contacto/${contacto}`).then(({ data }) => {
            const vendedor =
				auth.is("vendedor") || auth.is("vendedor-factura-orden_compra") || auth.is("cobrador") || auth.is('vendedor-cliente')
					? {
							value: auth.getUser().empleado?.id,
							label: `${auth.getUser().empleado?.codigo} ${
								auth.getUser().empleado?.nombre
							} ${auth.getUser().empleado?.apellido}`,
					  }
					: {
							value: data.vendedor?.id,
							label: `${data.vendedor?.codigo} ${data.vendedor?.nombre} ${data.vendedor?.apellido}`,
					  };

            const contact = {
                ...data,
                vendedor: vendedor.value ? vendedor : null,
                ruta: data.ruta
                    ? { value: data.ruta?.id, label: data.ruta?.nombre }
                    : null,
                credito_disponible_actual: parseFloat(data.credito_disponible),
            };

			setContactoInfo(contact);


            if (clear) {
                changePlazo(dropdownOptions?.terminos[0]);
                resetFacturaProductos();
            } else {
                changePlazo(dropdownOptions?.find(o => o.value == contact.plazo.id));
            }

            aplicarLimiteFecha();
		});
	};

	const handleInputChange = (value, field) => {
		setForm((form) => ({
			...form,
			[field]: value,
		}));
	};

	const handleDetalleChange = (value, field) => {
		setContactoInfo((state) => ({ ...state, [field]: value }));
	};

	const aplicarLimiteFecha = () => {
		if (!contactoInfo.plazo) return;

		return dayjs(form.fecha)
			.add(contactoInfo.plazo?.dias, "day")
			.format("YYYY-MM-DD");
	};

	// Funciones para traer data de los dropdowns
	const getDropdownData = () => {
		request("facturas/create").then((res) => {
			setDropdownOptions((options) => ({
				...options,
				contactos: res.data?.contactos,
				productos: res.data?.productos,
				rutas: res.data?.rutas,
				terminos: res.data?.terminos,
				comprobantes: res.data?.comprobantes,
				impuestos: res.data?.impuestos,
				categorias: res.data?.categorias,
			}));

            if (!isEdit) {
                changePlazo(res.data?.terminos[0]);
            }
		});

		getVendedoresForDropdown().then((data) => {
			setDropdownOptions((options) => ({
				...options,
				vendedores: data.filter((vendedor) => vendedor.value !== ""),
			}));
		});
	};

	const getContactosForDropdown = () => {
		request("contactos").then((res) => {
			setDropdownOptions((options) => ({
				...options,
				contactos: res.data.map((contacto) => ({
					value: contacto.id,
					label: contacto.nombre,
				})),
			}));
		});

        setLoading(false);
	};

	// Informacion de comprobantes
	const getComprobanteData = (comprobante) => {
		if (!comprobante) return;

		request(`facturas/get-comprobante/${comprobante}`).then((res) => {
            setComprobante(res.data);
		});
	};

	const updateTotal = (
		id = null,
		subtotal = null,
		total = 0,
		impuestos = 0,
		descuento = 0,
		impuesto,
        productoInfo = {},
	) => {
		const nproductos = facturaProductos?.map((producto) => {
            if (producto.id === id) {
                producto.producto_id = productoInfo.id;
                producto.importe = subtotal / producto.cantidad
                producto.descuento = descuento;
                producto.subtotal = subtotal;
                producto.impuesto = impuesto;
                producto.impuestos = impuestos;
                producto.total = total;
                if (productoInfo.precio_manual || productoInfo.precio_venta) {
                    producto.precio_facturado = parseFloat(productoInfo.precio_manual ?? productoInfo.precio_venta?.value);
                }
                producto.cantidadAnterior = productoInfo.cantidadAnterior;
            }
            return producto;
        });

        setFacturaProductos(nproductos);

        updateSumatoriaFinal(nproductos, contactoInfo);
	};

	const addProducto = () => {
        setFacturaProductos(prev => [
            ...prev,
            templateProducto(Date.now())
        ])
	};

	const removeProducto = async (index) => {
        const newProds = facturaProductos.filter(
            (i) => i.id !== index
        );
        setFacturaProductos(newProds);
        updateSumatoriaFinal(newProds, contactoInfo);
	};

	const cancel = () => {
		history.push(getLink("/ingresos/facturas-recurrentes"));
	};

	// const guardarYPagar = () => {
	// 	setShowAgregarPagoModal(true);
	// };

	const guardarYNuevaFactura = async () => {
		await setCreateButtonClicked("nueva");

		if (!checkCreditoDisponible()) return;

		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const guardarYSalir = async (btnClick) => {
		await setCreateButtonClicked(btnClick);

		if (!checkCreditoDisponible()) {
			return;
		}
		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const createFactura = async (event) => {
		event.preventDefault();

		setErrors({});

		setSaving(true);
		const data = new FormData(event.target);

		const response = await apiRequest(data, "facturas-recurrentes", "POST");

		if (response.success) {
			toast("Factura creada", "success");

			if (createButtonClicked === "normal") {
				history.push(getLink("/ingresos/facturas-recurrentes"));
			} else if (createButtonClicked === "pagar") {
				history.push(getLink("/ingresos/facturas-recurrentes"));
				window.open(
					getLink(
						`/imprimir/facturas-recurrentes/${response.data.factura}`
					)
				);
			}
			resetFields();
		}
		if (response.code === 422) {
			setErrors(response.data.message);
			toast("Revisar todos los campos", "error");
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}

		setSaving(false);
	};

	const editFactura = async (event) => {
		event.preventDefault();

		setForm((state) => ({ ...state, errors: {} }));

		setSaving(true);
		const data = new FormData(event.target);
		data.append("_method", "put");

		const response = await apiRequest(
			data,
			`facturas-recurrentes/${facturaActual.id}`,
			"POST"
		);

		if (response.success) {
			toast("Factura actualizada", "success");
			if (createButtonClicked === "normal") {
				history.push(getLink("/ingresos/facturas-recurrentes"));
			} else if (createButtonClicked === "pagar") {
				history.push(getLink("/ingresos/facturas-recurrentes"));
				window.open(
					getLink(
						`/imprimir/facturas-recurrentes/${response.data.factura}`
					)
				);
			}
			resetFields();
		}
		if (response.code === 422) {
			setForm((state) => ({ ...state, errors: response.data.message }));
			toast("Revisar todos los campos", "error");
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}

		setSaving(false);
	};

	const checkCreditoDisponible = () => {
		if (
			contactoInfo.credito_disponible_actual < sumatoriaFinal.total &&
			(plazo.dias > 0 || plazo.dias === null)
		) {
			setShowPINModal(true);
			return false;
		}

		return true;
	};

	const resetFields = () => {
		setCreateButtonClicked("normal");
		setContactoInfo({});
		setForm({
            numero_cotizacion: {},
			fecha: dayjs().format("YYYY-MM-DD"),
			fecha_vencimiento: dayjs().format("YYYY-MM-DD"),
			notas: "",
		});

        setErrors({});
        setContactoId(null);
        resetFacturaProductos();
        changePlazo({ value: 0, label: "Seleccione...", dias: 0 });
        setPinSeguridad("");

		window.scrollTo(0, 0);
	};

	const checkPINSeguridad = async () => {
		if (await validarPINSeguridad(pinSeguridad)) {
			setPinSeguridad("");
			setShowPINModal(false);
			formRef.current.dispatchEvent(
				new Event("submit", { bubbles: true, cancelable: true })
			);
			return;
		}

		toast("PIN incorrecto, intente de nuevo", "error");
	};

	const toggleCancelModal = () => {
		setShowCancel((state) => !state);
	};

	const cancelFactura = async (facturaID) => {
		setShowCancel((state) => !state);
		setErrors({});

		setSaving(true);

		const response = await apiRequest(
			null,
			`facturas-recurrentes/${facturaID}`,
			"DELETE"
		);

		if (response.success) {
			toast("Factura Recurrente Eliminada", "success");
			history.push(getLink("/ingresos/facturas-recurrentes"));
			return;
		}
		toast("Error eliminando factura recurrente", "error");

		setSaving(false);
	};

	const setPorPagar = () => {
		setPagoInfo((state) => ({ ...state, pago: sumatoriaFinal.total }));
	};

	const setEditInfo = async () => {
		await setContactoInfo({
			...facturaActual.cliente,
			vendedor: facturaActual.vendedor
				? {
						value: facturaActual.vendedor.id,
						label: `${facturaActual.vendedor.nombre} ${facturaActual.vendedor.apellido}`,
				  }
				: null,
			ruta: facturaActual.ruta
				? {
						value: facturaActual.ruta?.id,
						label: facturaActual.ruta?.nombre,
				  }
				: null,
			credito_disponible_actual: parseFloat(
				facturaActual.cliente?.credito_disponible
			),
		});

        setFacturaProductos(facturaActual.productos);

        setContactoId(facturaActual.cliente?.id);

        changeContactInfo(facturaActual.cliente?.id, false);

        setComprobante({
            prefijo:
                facturaActual.numero_comprobante?.substring(0, 3) ||
                "",
            numero_siguiente:
                facturaActual.numero_comprobante?.substring(
                    3,
                    12
                ) || "",
        });

        changePlazo({
            value: facturaActual.plazo?.id || facturaActual.plazo?.value,
            label: facturaActual.plazo?.nombre || facturaActual.plazo?.label,
            dias: facturaActual.plazo?.dias,
        });

		setForm((form) => ({
			...form,
			numero_cotizacion: {
				value: facturaActual.numeracion_id,
				label: facturaActual.numero_comprobante,
			},
			fecha: dayjs(facturaActual.recurrencia?.fecha_inicio).format(
				"YYYY-MM-DD"
			),
			fecha_vencimiento: dayjs(facturaActual.fecha_vencimiento).format(
				"YYYY-MM-DD"
			),
			notas: facturaActual.notas,
			categorias:
				facturaActual.categorias &&
				facturaActual.categorias?.map((c) => ({
					label: c.nombre,
					value: c.id,
				})),
			// RECURRENCIA
			activo: facturaActual.recurrencia?.status === "activo",
			anio_generacion: facturaActual.recurrencia?.anio_generacion || null,
			dia_generacion: facturaActual.recurrencia?.dia_generacion || null,
			fecha_inicio: facturaActual.recurrencia?.fecha_inicio || null,
			frecuencia_pago: facturaActual.recurrencia?.frecuencia_pago || null,
			recurrencia_id: facturaActual.recurrencia?.id || null,
			mes_generacion: facturaActual.recurrencia?.mes_generacion || null,
			ocurrencias: facturaActual.recurrencia?.ocurrencias || null,
		}));
	};

    const changePlazo = (plazo) => {
        let vencimiento = dayjs(form.fecha);

        setPlazo(plazo);

        if (form.fecha) {
            if (plazo.dias === null) return;

            vencimiento = vencimiento.add(plazo.dias, "day");

            handleInputChange(
                vencimiento.format("YYYY-MM-DD"),
                "fecha_vencimiento"
            );
        }
    }

    const updateSumatoriaFinal = (productos, contacto) => {
        const total = !isNaN(calcularTotal(productos))
			? calcularTotal(productos)
			: 0;

		setSumatoriaFinal((state) => ({
			...state,
			subtotal: calcularSubtotal(productos),
			descuento: calcularDescuento(productos),
			impuestos: calcularImpuestos(productos),
			exentoGrabado: calcularExentoGrabado(productos),
            total: total,
		}));

		setTotalItbis(calcularTotalImpuestos(productos));

        if (Object.keys(contacto).length >= 0) {
			setContactoInfo((state) => ({
				...state,
				credito_disponible: money(
					state.credito_disponible_actual - (total - form.prevTotal)
				),
			}));
		}
    }

	const isComprobanteFiscal = () => {
		return (
			form.comprobante?.comprobante_fiscal?.includes("B01") ||
			form.comprobante?.comprobante_fiscal?.includes("B02") ||
			form.comprobante?.comprobante_fiscal?.includes("B14") ||
			form.comprobante?.comprobante_fiscal?.includes("B15") ||
			form.comprobante?.comprobante_fiscal?.includes("B16")
		);
	};

	useEffect(() => {
		getDropdownData();
		getContactosForDropdown();
	}, []);

	useEffect(() => {
		if (!isEdit) return;
		setEditInfo();
	}, [facturaActual, facturaActual.productos]);

    if (loading) {
		return <Loader />;
	}

	return (
		<>
			<form
				method="post"
				onSubmit={(e) => {
					isEdit ? editFactura(e) : createFactura(e);
				}}
				ref={formRef}
			>
				<input
					type="hidden"
					name="id"
					value={facturaActual.id}
				/>
				<div className="crear-compra">
					<div className="page-controls">
						<Detalles
							form={form}
							contactoInfo={contactoInfo}
                            contactoId={contactoId}
                            setContactoId={setContactoId}
                            plazo={plazo}
                            changePlazo={changePlazo}
                            changeContactInfo={changeContactInfo}
							dropdownOptions={dropdownOptions}
							toggleCreateContactoModal={
								toggleCreateContactoModal
							}
							toggleEditContactoModal={toggleEditContactoModal}
							handleInputChange={handleInputChange}
							getComprobanteData={getComprobanteData}
                            comprobante={comprobante}
							aplicarLimiteFecha={aplicarLimiteFecha}
							handleDetalleChange={handleDetalleChange}
							isComprobanteFiscal={isComprobanteFiscal}
							isEdit={isEdit}
                            errors={errors}
						/>
					</div>
					<div className="col-md-12 p-0">
						<table className="table">
							<thead>
								<tr>
									<th width="170px">REF./CÓDIGO</th>
									<th width="23%">Producto</th>
									<th width="85px">Cantidad</th>
									<th width="9%">Importe</th>
									<th width="160px">Impuesto</th>
									<th width="180px">Precio</th>
									<th width="78px">% Desc.</th>
									<th className="text-right" width="9%">
										Total
									</th>
									<th className="text-center" width="5%">
										Fotos
									</th>
									<th width="85px"></th>
								</tr>
							</thead>
							<tbody>
								{facturaProductos?.map(
									(producto, index) => (
										<ProductosFactura
											key={producto.id}
											producto={producto}
											index={index}
											removeProducto={removeProducto}
											productos={
												dropdownOptions.productos
											}
											updateTotal={updateTotal}
											grupos={dropdownOptions.grupos}
											precioLimite={
												contactoInfo.precio_venta
											}
											banderaContacto={
												contactoInfo.bandera
											}
											impuestosForDropdown={
												dropdownOptions.impuestos
											}
											facturaProductos={
												facturaProductos
											}
											isEdit={isEdit}
											errors={errors}
											contacto={contactoInfo.id}
										/>
									)
								)}
								<tr>
									<td colSpan="10">
										{errors["productos"] && (
											<small className="help-blockParams form-Text">
												{errors["productos"][0]}
											</small>
										)}
									</td>
								</tr>
								<tr>
									<td
										colSpan="10"
										style={{ border: 0, paddingTop: 0 }}
										className="text-right"
									>
										<button
											type="button"
											onClick={addProducto}
											className="btn"
										>
											<i className="fas fa-plus" />{" "}
											Agregar Producto
										</button>
									</td>
								</tr>
                                {sumatoriaFinal.descuento > 0 ? (
                                    <tr>
                                        <td colSpan="8" className="text-right">
                                            <strong className="label form-control-label">
                                                Descuento:
                                            </strong>
                                        </td>
                                        <td colSpan="2" className="text-right">
                                            <input
                                                type="hidden"
                                                name="descuento"
                                                value={
                                                    sumatoriaFinal.descuento || 0
                                                }
                                                readOnly
                                            />
                                            -
                                            {formatCurrency(
                                                money(sumatoriaFinal.descuento)
                                            )}
                                            <input
                                                type="hidden"
                                                name="impuestos"
                                                value={totalitbis}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                ) : (
                                    <tr className="d-none">
                                        <td></td>
                                        <td><input type="hidden" name="descuento" value="0" /></td>
                                    </tr>
                                )}
								<tr>
									<td colSpan="8" className="text-right">
										<strong className="label form-control-label">
											Subtotal:
										</strong>
									</td>
									<td colSpan="2" className="text-right">
										<input
											type="hidden"
											name="subtotal"
											value={sumatoriaFinal.subtotal || 0}
											readOnly
										/>
										{formatCurrency(
											money(sumatoriaFinal.subtotal)
										)}
									</td>
								</tr>
								{Object.keys(sumatoriaFinal.impuestos).map(
									(impuesto, index) => (
										<tr key={index}>
											<td
												colSpan="8"
												className="text-right"
											>
												<strong className="label form-control-label">
													{impuesto}
												</strong>
											</td>
											<td
												colSpan="2"
												className="text-right"
											>
												{formatCurrency(
													money(
														sumatoriaFinal
															.impuestos[impuesto]
													)
												)}
											</td>
										</tr>
									)
								)}
								<tr>
									<td colSpan="8" className="text-right">
										<strong className="label form-control-label">
											Total:
										</strong>
									</td>
									<td colSpan="2" className="text-right">
										<input
											type="hidden"
											name="pagado"
											value="0"
											readOnly
										/>
										<input
											type="hidden"
											name="total"
											value={money(sumatoriaFinal.total)}
											readOnly
										/>
										{formatCurrency(
											money(sumatoriaFinal.total)
										)}
									</td>
								</tr>
                                {errors.total && (
                                    <tr>
                                        <td colSpan="10" className="text-right">
                                                <small className="help-blockParams form-Text">
                                                    {errors.total[0]}
                                                </small>
                                        </td>
                                    </tr>
                                )}
							</tbody>
						</table>
						<hr />
					</div>

                    <div className="col-md-12 d-none">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td><small>ITBIS 16%</small></td>
                                    <td><small>ITBIS 18%</small></td>
                                    <td><small>GRABADO ITBIS 16%</small></td>
                                    <td><small>GRABADO ITBIS 18%</small></td>
                                    <td><small>EXENTO</small></td>
                                    <td><small>GRABADO</small></td>
                                    <td><small>IMPUESTO TOTAL</small></td>
                                    <td><small>DESCUENTO</small></td>
                                    <td><small>SUBTOTAL</small></td>
                                    <td><small>TOTAL</small></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {money(sumatoriaFinal?.impuestos['ITBIS 16%'])}
                                        <input type="hidden" name="sumatoria[ITBIS 16%]" value={money(sumatoriaFinal?.impuestos['ITBIS 16%'])} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal?.impuestos['ITBIS 18%'])}
                                        <input type="hidden" name="sumatoria[ITBIS 18%]" value={money(sumatoriaFinal?.impuestos['ITBIS 18%'])} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal?.exentoGrabado['ITBIS 16%'])}
                                        <input type="hidden" name="sumatoria[Grabado ITBIS 16%]" value={money(sumatoriaFinal?.exentoGrabado['ITBIS 16%'])} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal?.exentoGrabado['ITBIS 18%'])}
                                        <input type="hidden" name="sumatoria[Grabado ITBIS 18%]" value={money(sumatoriaFinal?.exentoGrabado['ITBIS 18%'])} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal?.exentoGrabado['Exento'])}
                                        <input type="hidden" name="sumatoria[Exento]" value={money(sumatoriaFinal?.exentoGrabado['Exento'])} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal?.exentoGrabado['Grabado'])}
                                        <input type="hidden" name="sumatoria[Grabado]" value={money(sumatoriaFinal?.exentoGrabado['Grabado'])} />
                                    </td>
                                    <td>
                                        {money(totalitbis)}
                                        <input type="hidden" name="sumatoria[Total ITBIS]" value={money(totalitbis)} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal.descuento)}
                                        <input type="hidden" name="sumatoria[Descuento]" value={money(sumatoriaFinal.descuento)} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal.subtotal)}
                                        <input type="hidden" name="sumatoria[Subtotal]" value={money(sumatoriaFinal.subtotal)} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal.total)}
                                        <input type="hidden" name="sumatoria[Total]" value={money(sumatoriaFinal.total)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

					<div
						className="form-group col-md-12"
						style={{ marginBottom: "0.3rem" }}
					>
						<label className="label form-control-label">
							notas:
						</label>
						<textarea
                            className="text-input form-control"
                            id="notas"
                            name="notas"
                            rows={4}
                            value={form.notas}
                            onChange={e => handleInputChange(e.target.value, 'notas')}
                        />
						{errors.notas && (
							<small className="help-blockParams form-Text">
								{errors.notas[0]}
							</small>
						)}
					</div>
					<div className="page-footer text-right">
						<button
							type="button"
							onClick={cancel}
							className="btn btn-plain"
						>
							Volver
						</button>
						{isEdit && (
							<>
								{!saving && (
									<button
										type="button"
										onClick={() => toggleCancelModal()}
										className="btn btn-outline-danger mr-2"
									>
										Eliminar
									</button>
								)}
							</>
						)}
						{!isEdit && !saving && (
							<>
								<button
									type="button"
									onClick={() => guardarYNuevaFactura()}
									className="btn btn-outline-primary mr-2"
								>
									Guardar y crear nueva
								</button>
							</>
						)}
						{saving ? (
							<Loader type="small" />
						) : (
							<button
								type="button"
								onClick={() => guardarYSalir("normal")}
								className="btn btn-primary"
							>
								Guardar
							</button>
						)}
					</div>
					{/* <---PIN DE SEGURIDAD MODAL--- */}
					<Modal
						title="PIN de seguridad"
						show={showPINModal}
						callback={() => checkPINSeguridad()}
						acceptText="Aceptar"
						toggle={() => setShowPINModal((state) => !state)}
						size="419px"
					>
						<div className="form-group">
							<label>PIN de seguridad</label>
							<input
								type="password"
								className="form-control"
								id="PIN"
								name="pin_seguridad"
								value={pinSeguridad}
								onChange={(e) =>
									setPinSeguridad(e.target.value)
								}
							/>
							<p
								className="font-weight-bold"
								style={{
									color: "gray",
									fontStyle: "italic",
									lineHeight: "13px",
									fontSize: "0.7rem",
								}}
							>
								El cliente no cuenta con credito suficiente.
								Para continuar ingrese PIN de seguridad
							</p>
						</div>
					</Modal>
				</div>
                <div className="form-group">
                    <label>Retención</label>
                    <PrefixedInput
                        type="number"
                        prefix="RD$"
                        name="retencion"
                        value={pagoInfo.retencion}
                        setValue={(e) =>
                            setPagoInfo((state) => ({ ...state, pago: e }))
                        }
                    />
                </div>
			</form>
			{/* ---CREAR CONTACTO MODAL--- */}
			<Modal
				show={showCreateContactoModal}
				blank={true}
				toggle={toggleCreateContactoModal}
				size="lg"
			>
				<Create toggle={toggleCreateContactoModal} fromFacturacion />
			</Modal>
			{/* ---EDITAR CONTACTO MODAL--- */}
			<Modal
				show={showEditContactoModal}
				blank={true}
				toggle={toggleEditContactoModal}
				size="lg"
			>
				<Edit
					toggle={toggleEditContactoModal}
					contacto={contactoInfo}
					fromFacturacion
				/>
			</Modal>
			{/* ---CONFIRMACIÓN ELIMINAR FACTURA--- */}
			<Modal
				title="Eliminar Factura"
				show={showCancel}
				callback={() => cancelFactura(facturaActual.recurrencia.id)}
				acceptText="Aceptar"
				toggle={toggleCancelModal}
			>
				¿Estás seguro de que deseas eliminar esta Factura recurrente?
				(esta acción no se puede deshacer)
			</Modal>
		</>
	);
};

const TarjetaInfo = ({ pagoInfo, setPagoInfo }) => {
	return (
		<>
			<div className="form-group">
				<label>Terminal de tarjeta</label>
				<input
					type="text"
					name="terminal_tarjeta"
					value={pagoInfo.terminal_tarjeta}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							terminal_tarjeta: e.target.value,
						}));
					}}
					className="form-control"
				/>
			</div>
			<div className="form-group">
				<label>Tipo</label>
				<select
					className="form-control"
					name="tipo"
					value={pagoInfo.tipo}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							tipo: e.target.value,
						}));
					}}
				>
					<option value="visa">Visa</option>
					<option value="mastercard">Mastercard</option>
					<option value="otro">Otro</option>
				</select>
			</div>
			<div className="form-group">
				<label>Banco</label>
				<input
					type="text"
					className="form-control"
					name="banco"
					value={pagoInfo.banco}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							banco: e.target.value,
						}));
					}}
				/>
			</div>
			<div className="form-group">
				<label>Numero de aprobación</label>
				<input
					type="text"
					className="form-control"
					name="num_aprobacion"
					value={pagoInfo.num_aprobacion}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							num_aprobacion: e.target.value,
						}));
					}}
				/>
			</div>
		</>
	);
};

const ChequeInfo = ({ pagoInfo, setPagoInfo }) => {
	return (
		<>
			<div className="form-group">
				<label>Numero de cheque</label>
				<input
					type="text"
					name="num_cheque"
					className="form-control"
					value={pagoInfo.num_cheque}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							num_cheque: e.target.value,
						}));
					}}
				/>
			</div>
			<div className="form-group">
				<label>Banco</label>
				<input
					type="text"
					className="form-control"
					name="banco"
					value={pagoInfo.banco}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							banco: e.target.value,
						}));
					}}
				/>
			</div>
		</>
	);
};

const TransferenciaInfo = ({ pagoInfo, setPagoInfo }) => {
	return (
		<>
			<div className="form-group">
				<label>Numero de Transferencia</label>
				<input
					type="text"
					name="num_transferencia"
					className="form-control"
					value={pagoInfo.num_transferencia}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							num_transferencia: e.target.value,
						}));
					}}
				/>
			</div>
			<div className="form-group">
				<label>Banco</label>
				<input
					type="text"
					className="form-control"
					name="banco"
					value={pagoInfo.banco}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							banco: e.target.value,
						}));
					}}
				/>
			</div>
		</>
	);
};

const DepositoInfo = ({ pagoInfo, setPagoInfo }) => {
	return (
		<>
			<div className="form-group">
				<label>Numero de Deposito</label>
				<input
					type="text"
					name="num_deposito"
					className="form-control"
					value={pagoInfo.num_deposito}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							num_deposito: e.target.value,
						}));
					}}
				/>
			</div>
			<div className="form-group">
				<label>Banco</label>
				<input
					type="text"
					className="form-control"
					name="banco"
					value={pagoInfo.banco}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							banco: e.target.value,
						}));
					}}
				/>
			</div>
		</>
	);
};

const OtroInfo = ({ pagoInfo, setPagoInfo }) => {
	return (
		<>
			<div className="form-group">
				<label>Numero de transaccion</label>
				<input
					type="text"
					name="num_transaccion"
					className="form-control"
					value={pagoInfo.num_transaccion}
					onChange={(e) => {
						e.persist();
						setPagoInfo((state) => ({
							...state,
							num_transaccion: e.target.value,
						}));
					}}
				/>
			</div>
		</>
	);
};

export default Form;
