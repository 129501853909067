import React from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import ReporteCombustible from "./ReporteCombustible";
import ReporteProduccionFichas from "./ReporteProduccionFichas";
import ReporteProduccionEmpleados from "./ReporteProduccionEmpleados";
import ReporteResumenProduccion from "./ReporteResumenProduccion";
import ReporteMovimientoItems from "./ReporteMovimientoItems";
import ReporteNominas from "./ReporteNominas";
import ReporteRelacionGastosProduccion from "./ReporteRelacionGastosProduccion";
import ReporteRelacionGastosFicha from "./ReporteRelacionGastosFicha";
import ReporteProduccionRutas from "./ReporteProduccionRutas";
import ReporteDetalleProduccionRutas from "./ReporteDetalleProduccionRutas";
import ReporteTicketsSoloChofer from "./ReporteTicketsSoloChofer";
import ReporteDescuentosEmpleados from "./ReporteDescuentosEmpleados";
import ReporteLicenciasVencidas from "./ReporteLicenciasVencidas";
import ReporteSegurosVencidos from "./ReporteSegurosVencidos";
import ReporteNominaTSS from "./ReporteNominaTSS";
import ReporteOperacionesInventario from "../Productos/Print/ReporteOperacionesInventario";
import ReporteBalanceInventario from "../Productos/Print/ReporteBalanceInventario";

function Reportes() {
    const query = queryString.parse(window.location.search);

    switch (query.reporte) {
        case "combustible":
            return <ReporteCombustible />;
        case "produccion-mensual":
            return <ReporteNominas />;
        case "produccion-fichas":
            return <ReporteProduccionFichas />;
        case "produccion-empleados":
            return <ReporteProduccionEmpleados />;
        case "resumen-produccion":
            return <ReporteResumenProduccion />;
        case "movimiento-items":
            return <ReporteMovimientoItems />;
        case "relacion-gastos-ficha":
            return <ReporteRelacionGastosFicha />;
        case "relacion-gastos-produccion":
            return <ReporteRelacionGastosProduccion />;
        case "produccion-rutas":
            return <ReporteProduccionRutas />;
        case "detalle-produccion-rutas":
            return <ReporteDetalleProduccionRutas />;
        case "tickets-solo-chofer":
            return <ReporteTicketsSoloChofer />;
        case "descuentos-empleados":
            return <ReporteDescuentosEmpleados />;
        case "licencias-vencidas":
            return <ReporteLicenciasVencidas />;
        case "seguros-vencidos":
            return <ReporteSegurosVencidos />;
        case "comparar-nomina-tss":
            return <ReporteNominaTSS />;
        case "operaciones-inventario":
            return <ReporteOperacionesInventario/>;
        case "balance-inventario":
            return <ReporteBalanceInventario />;
        default:
            return <div>Reportes</div>;
    }
}

export default withRouter(Reportes);
