import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";

const createFilterQueryString = (filters) => {
	let query = [];
	Object.keys(filters).forEach((fil) => {
		if (filters[fil]) {
			query.push(`${fil}=${filters[fil]}`);
		}
	});

	return "?" + query.join("&");
};

const useFilters = (initialFilters) => {
	const [filters, setFilters] = useState(initialFilters);
	const [filtersQuery, setFiltersQuery] = useState(
		createFilterQueryString(initialFilters)
	);

	const handleFilterChange = debounce((key, value) => {
		setFilters((state) => ({ ...state, [key]: value instanceof Array ? value.map((v) => v.value).join(",") : value }));
	}, 500);

	const handleFilterChangeWithoutDebounce = (key, value) => {
		setFilters((state) => ({ ...state, [key]: value instanceof Array ? value.map((v) => v.value).join(",") : value }));
	};

	useEffect(() => {
		setFiltersQuery(createFilterQueryString(filters));
	}, [filters]);

	return { filters, handleFilterChange, handleFilterChangeWithoutDebounce, filtersQuery };
};

useFilters.propTypes = {
	initialFilters: PropTypes.object,
};

export default useFilters;
