/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { request } from "../../../utils/request";
import { useParams } from "react-router-dom";
import Form from "./Components/FacturaForm";
import InvalidScreen from "../../general/InvalidScreen";
import { isVendedor, toast } from "../../../utils/helpers";
import useLocalStorage from "../../../hooks/useLocalStorage";

const isRecovery = !!(localStorage.getItem('facturaGuardada'));

console.log({isRecovery});

const CrearFactura = () => {
	const [fromOrden] = useLocalStorage("fromOrden");
	const [facturaGuardada, setFacturaGuardada] = useLocalStorage('facturaGuardada');
    const { plantilla } = useParams();

	const [factura, setFactura] = useState({});

	const getPlantilla = (id, fromOrden) => {
		request((fromOrden ? "ordenes-compra/" : "cotizaciones/") + id).then((res) => {
			setFactura(res.data);
		});
	};

	useEffect(() => {
        if (plantilla) {
            getPlantilla(plantilla, fromOrden === "si");
        }

        if (isRecovery && !plantilla && facturaGuardada) {
            setFactura(facturaGuardada);
            toast('Reestableciendo Autoguardado...');
        }
	}, []);

	if (isVendedor() && !localStorage.getItem("rutas")) {
		return <InvalidScreen message="No hay zonas seleccionadas" />;
	}

	return (
		<>
			<Form
				facturaActual={factura}
				isEdit={false}
				isRecovery={isRecovery}
				fromPlantilla={plantilla ? true : false}
                setFacturaGuardada={setFacturaGuardada}
			/>
		</>
	);
};

export default CrearFactura;
