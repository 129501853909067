import React, { useState } from "react";
import Form from "./Form";
import Loader from "../../general/Loader";
import { apiRequest } from "../../../hooks/apiRequest";

const Create = ({inscripcion, estudiantes, tutores, productos, secciones, grados, becas, periodosAcademicos, getInscripciones, gruposPagos, toggle}) => {
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});

    const createUser = async (event) => {
        event.preventDefault();

        setSaving(true);

        const data = new FormData(event.target);
        const response = await apiRequest(data, 'academico/inscripciones');

        if (response?.success) {
            getInscripciones();
            toggle();
        }
        if (response?.code === 422) {
            setErrors(response.data.message);
        }

        setSaving(false);
    };

    return (
        <form method='post' onSubmit={createUser}>
            <div className='popup__header'>Reserva de Cupo</div>
            <div className='popup__body'>
                <Form
                    estudiantes={estudiantes}
                    tutores={tutores}
                    productos={productos}
                    secciones={secciones}
                    grados={grados}
                    periodosAcademicos={periodosAcademicos}
                    becas={becas}
                    inscripcion={inscripcion}
                    errors={errors}
                    gruposPagos={gruposPagos}
                />
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                {saving && <Loader type="small"/>}
                {!saving && (
                    <button type='submit' className='btn btn-primary'>
                        Inscribir
                    </button>
                )}
            </div>
        </form>
    );
}

export default Create;
