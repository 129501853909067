import React, { Component } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Select from "react-select";

import { withRequest } from "../../../utils/request";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../../general/Loader";
import { formatNumber, getLink } from "../../../utils/helpers";
import { debounce } from "lodash";

class Inscripciones extends Component {
    state = {
        inscripciones: [],
        inscripcion: {},
        grados: [],
        periodosAcademicos: [],
        estudiantes: [],
        tutores: [],
        productos: [],
        secciones: [],
        becas: [],
        gruposPagos: [],
        salidasOptativas: [],
        totales: {},
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        loading: true,
        filtros: {
        },
        filtrosQuery: "",
    };
    componentDidMount() {
        this.getInscripciones();
        this.getGruposPagos();
        this.getTable('academico/grados', 'grados');
        this.getTable('academico/secciones', 'secciones');
        this.getTable('academico/becas', 'becas');
        this.getTable('academico/estudiantes', 'estudiantes');
        this.getTable('academico/padres-tutores', 'tutores');
        this.getTable('academico/periodos-academicos', 'periodosAcademicos');
        this.getTable('academico/salidas-optativas', 'salidasOptativas');
    }
    getTable = async (url, name = null) => {
        const request = await this.props.request(url);
        const table = name ? name : url;

        if (request.data) {
            this.setState({
                [table]: request.data,
            });
            this.setState({
                loading: false,
            });
        }
    };
    getGruposPagos = async () => {
        const url = "configuraciones?seccion=academica_grupos-pagos";
        const configuraciones = await this.props.request(url);
        const gpagos = JSON.parse(configuraciones.data["grupos-pagos"]);
        this.setState({
            gruposPagos: Object.keys(gpagos).map(g => gpagos[g])
        });
    }
    getInscripciones = async (url = null) => {
        url = url ? url + "&limit=15" : "academico/inscripciones?limit=20";
        const inscripciones = await this.props.request(url);

        console.log({inscripciones});

        if (inscripciones.success) {
            this.setState({
                inscripciones: inscripciones.data.data,
                links: inscripciones.data.links,
                meta: inscripciones.data.meta,
                totales: inscripciones.totales,
                loading: false,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            inscripcion: {},
        });
    };
    toggleEditModal = (inscripcion) => {
        this.setState({
            showEdit: !this.state.showEdit,
            inscripcion: inscripcion,
        });
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: encodeURIComponent(value),
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getInscripciones("academico/inscripciones?" + query.join("&"));
    }, 500);

    render() {
        const { inscripciones, filtrosQuery, loading, grados, periodosAcademicos, tutores, estudiantes, secciones, productos, salidasOptativas, becas, gruposPagos, totales, filtros } = this.state;

        const periodoActivo = periodosAcademicos.find(p => filtros.periodo_academico ? p.id == filtros.periodo_academico : p.estado === 'activo');
        return (
            <div>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Inscripciones {periodoActivo?.nombre}</span>
                        <Link
                            to={getLink(
                                `/imprimir/academico/inscripciones${filtrosQuery}`
                            )}
                            target="_blank"
                            className='au-btn au-btn-icon au-btn-filter m-r-20'>
                            <i className='zmdi zmdi-print'></i> Imprimir
                        </Link>
                        {/* <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Reservar Cupo
                        </button> */}
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='search'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='tipo'>Grado</label>
                                    <Select
                                        name='grados'
                                        id='grados'
                                        placeholder="Seleccionar..."
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "grados"
                                            )
                                        }
                                        options={[
                                            {label: 'Todos', value: 'todos'},
                                            ...grados.map(g => {
                                                return {label: g.nombre, value: g.id}
                                            })
                                        ]}
                                    />
                                </div>
                                <div className='col-md-1'>
                                    <label htmlFor='tipo'>Sección</label>
                                    <Select
                                        name='seccion'
                                        id='seccion'
                                        placeholder="..."
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "seccion"
                                            )
                                        }
                                        options={[
                                            {label: 'Todas', value: 'todos'},
                                            ...secciones.map(g => {
                                                return {label: g.nombre, value: g.id}
                                            })
                                        ]}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='tipo'>Salida Optativa</label>
                                    <Select
                                        name='optativa'
                                        id='optativa'
                                        isClearable
                                        placeholder="..."
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "optativa"
                                            )
                                        }
                                        options={[
                                            {label: 'Todas', value: 'todos'},
                                            ...salidasOptativas.map(g => {
                                                return {label: g.nombre, value: g.id}
                                            })
                                        ]}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Año Escolar</label>
                                    <Select
                                        name='periodo_academico'
                                        id='periodo_academico'
                                        placeholder="Actual"
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "periodo_academico"
                                            )
                                        }
                                        options={[
                                            {label: 'Todos', value: 'todos'},
                                            ...periodosAcademicos.map(p => {
                                                return {label: p.nombre, value: p.id}
                                            })
                                        ]}
                                        value={{
                                            label: periodoActivo?.nombre,
                                            value: periodoActivo?.id
                                        }}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Beca</label>
                                    <Select
                                        name='beca'
                                        id='beca'
                                        placeholder="Seleccionar..."
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "beca"
                                            )
                                        }
                                        options={[
                                            {label: 'Todas', value: 'todos'},
                                            ...becas.map(b => {
                                                return {label: `${b.nombre}`, value: b.id}
                                            })
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-t-25">
                    <div className="col-md-2">
                        <div class="statistic__item statistic__item--orange">
                            <h2 class="number" style={{color: 'white'}}>{totales.totalEstudiantes}</h2>
                            <span class="desc">Total</span>
                            <div class="icon">
                                <i class="zmdi zmdi-account-o"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="statistic__item statistic__item--blue">
                            <h2 class="number" style={{color: 'white'}}>{totales.totalVarones}</h2>
                            <span class="desc">Varones</span>
                            <div class="icon">
                                <i class="zmdi zmdi-male-alt"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="statistic__item statistic__item--green">
                            <h2 class="number" style={{color: 'white'}}>{totales.totalHembras}</h2>
                            <span class="desc">Hembras</span>
                            <div class="icon">
                                <i class="zmdi zmdi-female"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="statistic__item statistic__item--orange">
                            <h2 class="number" style={{color: 'white'}}>{totales.totalExtranjeros}</h2>
                            <span class="desc">Extranjeros</span>
                            <div class="icon">
                                <i class="zmdi zmdi-flag"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="statistic__item statistic__item--red">
                            <h2 class="number" style={{color: 'white'}}>{totales.totalInactivos}</h2>
                            <span class="desc">Inactivos</span>
                            <div class="icon">
                                <i class="zmdi zmdi-close-circle-o"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="statistic__item statistic__item--blue">
                            <h2 class="number" style={{color: 'white'}}>{totales.totalBecados}</h2>
                            <span class="desc">Becados</span>
                            <div class="icon">
                                <i class="zmdi zmdi-star-outline"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!loading && inscripciones.length === 0 && (
                                <div>
                                    {!loading && (
                                        <div>
                                            No hay ninguna inscripción
                                            creada.
                                        </div>
                                    )}
                                </div>
                            )}
                            {!loading && inscripciones.length > 0 && (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Estudiante</th>
                                                <th>Fecha</th>
                                                <th>Grado</th>
                                                <th className="text-center">Sección</th>
                                                <th>Optativa</th>
                                                <th className="text-center">Año Escolar</th>
                                                <th>Beca</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inscripciones.map((inscrip) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={inscrip.id}>
                                                    <td>
                                                        <Link to={getLink(`/academico/estudiantes/${inscrip.estudiante?.id}/editar`)}>
                                                            <strong>{inscrip.estudiante?.nombreCompleto}</strong>
                                                        </Link>
                                                    </td>
                                                    <td>{dayjs(inscrip.fechaInscripcion).format('DD-MM-YYYY')}</td>
                                                    <td>{inscrip.grado?.nombre}</td>
                                                    <td className="text-center">{inscrip.seccion?.nombre}</td>
                                                    <td>{inscrip.salidaOptativa?.nombre}</td>
                                                    <td className="text-center">{inscrip.periodoAcademico?.nombre}</td>
                                                    <td>{inscrip.beca?.nombre}</td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <button
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Edit'
                                                                onClick={() => {
                                                                    this.toggleEditModal(
                                                                        inscrip
                                                                    );
                                                                }}>
                                                                <i className='zmdi zmdi-edit' />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getInscripciones}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    size="1048px"
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        inscripcion={this.state.inscripcion}
                        periodosAcademicos={periodosAcademicos}
                        grados={grados}
                        secciones={secciones}
                        productos={productos}
                        estudiantes={estudiantes}
                        tutores={tutores}
                        becas={becas}
                        salidasOptativas={salidasOptativas}
                        gruposPagos={gruposPagos}
                        getInscripciones={this.getInscripciones}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    size="1048px"
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        inscripcion={this.state.inscripcion}
                        periodosAcademicos={periodosAcademicos}
                        grados={grados}
                        secciones={secciones}
                        productos={productos}
                        estudiantes={estudiantes}
                        tutores={tutores}
                        becas={becas}
                        salidasOptativas={salidasOptativas}
                        gruposPagos={gruposPagos}
                        getInscripciones={this.getInscripciones}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Inscripciones);
