import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatNumber } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";

export default class ReporteProduccionFichas extends Component {
    state = {
        tickets: false
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const token = auth.getToken();

        const res = await fetch(apiUrl(`reportes` + window.location.search), {
            method: "get",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.setState({
                tickets: response.data
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { tickets } = this.state;

        if (!tickets) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            suc => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        const totalTon =
            tickets.reduce(
                (acc, ticket) => acc + parseFloat(ticket.pesoNeto),
                0
            ) / 1000;

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Movimiento de Producción por Fichas
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            Tickets: {tickets.length}
                        </div>

                        <div className='float-right'>
                            <h3>TOTAL VENTAS: {formatNumber(totalTon)}</h3>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Ficha</th>
                            <th>Categoría</th>
                            <th>Ticket</th>
                            <th>Hora</th>
                            <th className='text-right'>VENTAS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tickets.map((ticket, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {dayjs(ticket.fecha).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </td>
                                    <td>{ticket.ficha}</td>
                                    <td>{ticket.categoria}</td>
                                    <td>{ticket.numero}</td>
                                    <td>{ticket.hora}</td>
                                    <td className='text-right'>
                                        {formatNumber(ticket.pesoNeto / 1000)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='m-t-25'>
                    <div className='float-left'>Tickets: {tickets.length}</div>
                    <div className='text-right float-right'>
                        <h3>TOTAL VENTAS: {formatNumber(totalTon)}</h3>
                    </div>
                </div>
            </div>
        );
    }
}
