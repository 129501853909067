import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
	formatCurrency,
	formatDateTime,
	getSucursal,
	money,
	toast,
} from "../../../utils/helpers";
import { request } from "../../../utils/request";
import { filter } from "lodash";
import { useParams } from "react-router-dom";

const ReportInventoryAdjustmentSingle = () => {
	const { data, sucursal, queryParams } = useData();

	return (
		<>
			<div className="row m-b-40">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Ajuste de Inventario de{" "}
					{queryParams.get("productoNombre")}
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{queryParams.get("hasta")
						? dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")
						: 
                        dayjs().format("DD/MM/YYYY")}
				</div>
			</div>

			{/* <h3 className="font-size-lg mb-2"></h3> */}
			<table className="table m-b-15">
				<thead>
					<tr>
						<th>Fecha</th>
						<th>Tipo de ajuste</th>
						<th>Descripción</th>
						<th>Cantidad</th>
						<th>En inventario</th>
					</tr>
				</thead>
				<tbody>
					{data?.map((ajuste) => {
						return (
							<tr key={ajuste.id}>
								<td>{ajuste.fecha}</td>
								<td>
									{ajuste.incrementar
										? "Incrementar"
										: "Reducir"}
								</td>
								<td>{ajuste.descripcion}</td>
								<td>{ajuste.cantidad}</td>
								<td>{ajuste.existencia}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};
export default ReportInventoryAdjustmentSingle;

const useData = () => {
	const location = useLocation();
	const params = useParams();
	const queryParams = new URLSearchParams(location.search);
	const [sucursal, setSucursal] = useState({});
	const [data, setData] = useState([]);

	useEffect(() => {

		(async () => {
			const res = await request(
				`ajustes/${params.ajusteId}`
			);

			setData([res.data]);
			// setTimeout(() => {
			// 	window.print();
			// }, 1000);
		})();

		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);
	}, []);
	return {
		data,
		sucursal,
		queryParams,
	};
};
