import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";

class Edit extends Component {
    state = {
        errors: {},
    };
    editTaxonomia = async (event) => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(
            apiUrl(`taxonomias/${this.props.taxonomia.id}`),
            {
                method: "post",
                body: data,
                headers: {
                    Accept: "application/json",
                    "X-Request-With": "XMLHttpRequest",
                    Authorization: "Bearer " + token,
                },
            }
        );

        const response = await res.json();

        if (response.success) {
            this.props.getTaxonomias();
            this.props.toggle();
            toast("Categoría actualizada.");
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { errors } = this.state;
        const { toggle, taxonomia } = this.props;
        return (
            <form method='post' onSubmit={this.editTaxonomia}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>Editar Categoría</div>
                <div className='popup__body'>
                    <Form taxonomia={taxonomia} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={() => toggle({})}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default Edit;
