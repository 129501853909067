import React, { useEffect, useState } from "react";
import SelectCreate from "react-select/creatable";

import Loader from "../../general/Loader";
import { toast } from "../../../utils/helpers";
import { request } from "../../../utils/request";
import { apiRequest } from "../../../hooks/apiRequest";
import Form from "./Form";

const Create = ({empleadoId, getDescuentos, toggle, columnas}) => {
    const [state, setState] = useState({
        saving: false,
    });

    const createDescuento = async event => {
        event.preventDefault();

        setState({
            ...state,
            saving: true
        });

        const data = new FormData(event.target);
        const response = await apiRequest(data, `empleados/${empleadoId}/descuentos`);

        if (response.success) {
            getDescuentos();
            toggle();
            toast("Descuento creado.");
            document.getElementById("descuentosform").reset();
        }
        if (response.code === 422) {
            setState({ ...state, errors: response.data.message });
        }

        setState({
            ...state,
            saving: false
        });
    };

    return (
        <form
            id='descuentosform'
            method='post'
            onSubmit={createDescuento}>
            <input type='hidden' name='_method' value='post' />
            <div className='popup__header'>Crear Descuento Fijo</div>
            <div className='popup__body'>
                <Form descuento={{}} columnas={columnas} />
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                {state?.saving ? (
                    <Loader type='small' />
                ) : (
                    <button type='submit' className='btn btn-primary'>
                        Crear
                    </button>
                )}
            </div>
        </form>
    );
}

export default Create;
