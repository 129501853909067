/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";

let porcentajeImpuesto = 0;
let currentCost = 0;

function reducer(state, action) {
	switch (action.type) {
		case "input":
			return {
				...state,
				[action.payload.field]:
					action.payload.field !== "impuesto"
						? parseFloat(action.payload.value)
						: action.payload.value,
			};
		case "calcTotal":
			const total =
				parseFloat(state.importe) +
				parseFloat(state.importe) * parseFloat(action.payload.value);

			return {
				...state,
				precio_total: total.toFixed(2),
			};
		case "calcCosto":
			const costoItem =
				(state.importe - action.payload.costo) / action.payload.costo;
			return {
				...state,
				costo: (costoItem * 100).toFixed(2),
			};

		case "calcPrecioVentaOnCostoInput":
			const porcentajeCosto =
				(action.payload.porcentajeCosto / 100) * action.payload.costo;

			return {
				...state,
				importe: parseFloat(
					action.payload.costo + porcentajeCosto
				).toFixed(2),
			};

		case "setImporte":
			return {
				...state,
				importe: action.payload.costo,
			};

		default:
			break;
	}
}

const defaultPrice = {
	id: Date.now(),
	importe: 0,
	impuesto: 0,
	precio_total: 0,
	costo: 0,
};

const Precios = ({
	precio = defaultPrice,
	index,
	removePrecio,
	costo,
	rowClasses,
	impuestos = [],
	accion,
	firstTaxSelected,
}) => {
	const [state, dispatch] = useReducer(reducer, precio);
	const [lastCostoModified, setLastCostoModified] = useState("costo");

	const handleChangeImporte = (value) => {
		setLastCostoModified("importe");
		dispatch({ type: "input", payload: { field: "importe", value } });
		dispatch({ type: "calcTotal", payload: { value: porcentajeImpuesto } });
		dispatch({
			type: "calcCosto",
			payload: { costo: parseFloat(costo?.current?.value) || 0 },
		});
	};

	const handleChangeImpuesto = (value) => {
		porcentajeImpuesto = impuestos.find(
			(impuesto) => impuesto.value === parseInt(value)
		)?.porcentaje;
		dispatch({ type: "input", payload: { field: "impuesto", value } });

		if (lastCostoModified === "importe") {
			dispatch({
				type: "calcTotal",
				payload: { value: porcentajeImpuesto },
			});
		} else {
			dispatch({
				type: "input",
				payload: {
					field: "importe",
					value: (
						parseFloat(state.precio_total) /
						(porcentajeImpuesto + 1)
					).toFixed(2),
				},
			});
			dispatch({
				type: "calcCosto",
				payload: { costo: parseFloat(costo?.current?.value) || 0 },
			});
		}
	};

	const handleChangePrecioTotal = (value) => {
		setLastCostoModified("precio_total");
		dispatch({ type: "input", payload: { field: "precio_total", value } });

		const precioVenta = parseFloat(value) / (porcentajeImpuesto + 1);
		const costoInput =
			((precioVenta - parseFloat(costo?.current?.value)) /
				parseFloat(costo?.current?.value)) *
			100;

		// change importe value
		dispatch({
			type: "input",
			payload: { field: "importe", value: precioVenta.toFixed(2) },
		});
		// change % beneficio value
		dispatch({
			type: "input",
			payload: { field: "costo", value: costoInput.toFixed(2) },
		});
	};

	// this references to "costo" input
	const handleBeneficioChange = (value) => {
		setLastCostoModified("importe");
		dispatch({ type: "input", payload: { field: "costo", value } });
		dispatch({
			type: "calcPrecioVentaOnCostoInput",
			payload: {
				porcentajeCosto: value,
				costo: parseFloat(costo?.current?.value) || 0,
			},
		});
		dispatch({
			type: "calcTotal",
			payload: { value: porcentajeImpuesto },
		});
	};

	useEffect(() => {
		if (accion === "crear") {
			porcentajeImpuesto = impuestos[0]?.porcentaje || 0;
		}
	}, [impuestos]);

	useEffect(() => {
		if (accion === "crear") {
			handleChangeImpuesto(firstTaxSelected);
			return;
		}
	}, [firstTaxSelected]);

	useEffect(() => {
		currentCost = costo?.current?.value;
		if (accion === "editar") {
			porcentajeImpuesto =
				impuestos.find(
					(impuesto) => impuesto.value === parseInt(precio.impuesto)
				)?.porcentaje || 0;

			dispatch({
				type: "setImporte",
				payload: {
					porcentajeCosto: state.costo,
					costo: precio?.precio_venta,
				},
			});
		}
	}, []);

    console.log({precio});

	useEffect(() => {
		if (accion === "editar") {
			porcentajeImpuesto =
				impuestos.find(
					(impuesto) => impuesto.value === parseInt(precio.impuesto)
				)?.porcentaje || 0;

			if (currentCost !== costo?.current?.value) {
				handleBeneficioChange(state.costo);
			}
		}
	}, [costo?.current?.value, impuestos]);

	return (
		<>
			<input
				value={state.id}
				name={`precios[${index}][id]`}
				type="hidden"
			/>
			<table className="precios-table">
				<tbody>
					<tr>
						<td>
							<label className="label form-control-label">
								Importe
							</label>
							<input
								type="number"
								min={0}
								className="text-input form-control"
								id={`precios[${index}][precio_venta]`}
								name={`precios[${index}][precio_venta]`}
								value={state.importe}
								presicion={2}
								onChange={(e) =>
									handleChangeImporte(e.target.value)
								}
								step="0.01"
							/>
						</td>
						<td>
							<span className="h2 color-primary d-flex align-items-end p-t-20">
								+
							</span>
						</td>
						<td width="150px">
							<label className="label form-control-label">
								Impuesto:
							</label>
							<select
								type="text"
								className="form-control"
								id={`precios[${index}][impuesto_id]`}
								name={`precios[${index}][impuesto_id]`}
								value={state.impuesto}
								onChange={(e) =>
									handleChangeImpuesto(e.target.value)
								}
							>
								{impuestos.map((impuesto) => (
									<option
										value={impuesto.value}
										key={impuesto.value}
									>
										{impuesto.label}
									</option>
								))}
							</select>
						</td>
						<td>
							<span className="h2 color-primary d-flex align-items-end p-t-20">
								=
							</span>
						</td>
						<td>
							<label className="label form-control-label">
								Precio venta {index + 1}:
							</label>
							<input
								type="number"
								min={0}
								className="text-input form-control"
								id={`precios[${index}][total]`}
								name={`precios[${index}][total]`}
								value={state.precio_total}
								onChange={(e) =>
									handleChangePrecioTotal(e.target.value)
								}
								step=".01"
							/>
						</td>
						<td>
							<label className="label form-control-label">
								% Beneficio:
							</label>
							<input
								type="number"
								min={0}
								className="text-input form-control"
								id={`precios[${index}][costo]`}
								name={`precios[${index}][costo]`}
								value={state.costo}
								onChange={(e) =>
									handleBeneficioChange(e.target.value)
								}
								step=".01"
							/>
						</td>
						<td className="text-center">
							<button
								type="button"
								className="color-primary d-flex align-items-end p-t-20"
								onClick={() => removePrecio(precio.id)}
							>
								<i className="fas fa-trash-alt text-danger"></i>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};

Precios.propTypes = {
	precio: PropTypes.object,
	index: PropTypes.number,
	removePrecio: PropTypes.func,
	costo: PropTypes.object,
	rowClasses: PropTypes.string,
	impuestos: PropTypes.array,
	firstTaxSelected: PropTypes.number,
	setParentState: PropTypes.func,
};

export default Precios;
