import React, { Component } from "react";

import { withRequest } from "../../../utils/request";
import Loader from "../../general/Loader";
import Modal from "../../general/Modal";
import Asignacion from "./Asignacion";
import Create from "./Create";
import Edit from "./Edit";
import Categorias from "./Categorias";

class Asignaciones extends Component {
    state = {
        asignaciones: [],
        asignacionesOriginal: [],
        empleados: [],
        categorias: [],
        asignacion: {},
        activo: 1,
        showCreate: false,
        showEdit: false,
        showCategorias: false,
        categoriaActiva: 0,
        loading: true,
        asignacionEditing: {},
    };

    componentDidMount() {
        this.getCategorias();
        this.getTable("asignaciones");
        this.getTable("empleados", "estado=activos");
    }

    getTable = async (table, query = null) => {
        const searchQuery = query ? "?" + query : "";
        const response = await this.props.request(table + searchQuery);

        if (response.data && response.data.length) {
            this.setState({
                [table]: response.data,
            });
        }

        if (this.isReady()) {
            this.setState({
                asignacionesOriginal: this.state.asignaciones,
            });
        }

        this.setState({
            loading: false,
        });
    };

    getCategorias = async () => {
        const response = await this.props.request("categorias-asignaciones");

        if (response.data && response.data.length) {
            this.setState({
                categorias: response.data,
                categoriaActiva: response.data.length ? response.data[0].id : 0,
            });
        }
    };

    onSearch = (e) => {
        const keyword = e.target.value.toLowerCase();

        if (keyword !== "") {
            this.setState({
                asignaciones: this.state.asignacionesOriginal.filter((asig) => {
                    return (
                        JSON.stringify(asig).toLowerCase().indexOf(keyword) >= 0
                    );
                }),
            });
        } else {
            this.setState({
                asignaciones: this.state.asignacionesOriginal,
            });
        }
    };

    isReady = () => {
        const { empleados, asignaciones, categorias } = this.state;
        return (
            empleados.length > 0 &&
            asignaciones.length > 0 &&
            categorias.length > 0
        );
    };

    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    };

    toggleEditModal = (asignacion) => {
        this.setState({
            showEdit: !this.state.showEdit,
            asignacionEditing: asignacion,
        });
    };

    toggleCategoriasModal = () => {
        this.setState({
            showCategorias: !this.state.showCategorias,
        });
    };

    clickFicha = (activo) => {
        const elem = document.getElementById(activo);
        window.scroll({
            top: elem.offsetTop,
            left: 0,
            behavior: "smooth",
        });
        this.setState({
            activo,
        });
    };

    changeCategoria = (cat) => {
        this.setState({
            categoriaActiva: cat,
        });

        this.getTable("asignaciones");
    };

    render() {
        const {
            empleados,
            loading,
            activo,
            categorias,
            categoriaActiva,
        } = this.state;

        const filteredAsignaciones = this.state.asignaciones.filter(
            (asignacion) => asignacion.categoriaAsignacion === categoriaActiva
        );

        return (
            <div>
                {loading ? (
                    <Loader />
                ) : (
                    <div className='row'>
                        <div className='asignaciones-main'>
                            <div className='asignaciones-wrap'>
                            <div className='asignaciones-sidebar-left'>
                                    <h3>Fichas</h3>
                                    <ul className='list-unstyled'>
                                        {filteredAsignaciones.map(
                                            (asignacion, index) => (
                                                <li
                                                    className={
                                                        activo === asignacion.id
                                                            ? "active"
                                                            : ""
                                                    }
                                                    key={asignacion.id}>
                                                    <a
                                                        href={
                                                            "#" + asignacion.id
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.clickFicha(
                                                                asignacion.id
                                                            );
                                                        }}>
                                                        {asignacion.ficha}
                                                        {asignacion.completa && (
                                                            <i className='far fa-check-circle completed' />
                                                        )}
                                                    </a>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                                <div className='tabs-tarjetas'>
                                    <ul>
                                        {categorias.length > 0 &&
                                            categorias.map(
                                                (categoria, index) => (
                                                    <li
                                                        key={categoria.id}
                                                        onClick={() =>
                                                            this.changeCategoria(
                                                                categoria.id
                                                            )
                                                        }
                                                        className={
                                                            categoria.id ===
                                                            categoriaActiva
                                                                ? "active"
                                                                : ""
                                                        }>
                                                        {categoria.nombre}
                                                    </li>
                                                )
                                            )}
                                        <li>
                                            <button
                                                onClick={
                                                    this.toggleCategoriasModal
                                                }
                                                className='btn'>
                                                <i className='fas fa-pen'></i>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className='filtros-tarjetas'>
                                    <input
                                        onChange={this.onSearch}
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control search'
                                    />
                                    <button
                                        onClick={this.toggleCreateModal}
                                        className='au-btn au-btn-icon au-btn--green au-btn--small'>
                                        <i className='zmdi zmdi-plus'></i>
                                        Añadir Asignación
                                    </button>
                                </div>
                                
                                <div className='asignaciones-main-column'>
                                    {filteredAsignaciones.length > 0 ? (
                                        <div>
                                            {filteredAsignaciones.map(
                                                (asignacion, index) => (
                                                    <Asignacion
                                                        index={index}
                                                        key={asignacion.id}
                                                        asignacion={asignacion}
                                                        empleados={empleados}
                                                        getTable={this.getTable}
                                                        editar={
                                                            this.toggleEditModal
                                                        }
                                                    />
                                                )
                                            )}
                                        </div>
                                    ) : (
                                        <div>No hay asignaciones creadas.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        getAsignaciones={() => this.getTable("asignaciones")}
                        categoriaActiva={categoriaActiva}
                        toggle={this.toggleCreateModal}
                    />
                </Modal>

                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={this.toggleEditModal}>
                    <Edit
                        getAsignaciones={() => this.getTable("asignaciones")}
                        categoriaActiva={categoriaActiva}
                        asignacion={this.state.asignacionEditing}
                        toggle={this.toggleEditModal}
                    />
                </Modal>

                <Modal
                    show={this.state.showCategorias}
                    blank={true}
                    toggle={this.toggleCategoriasModal}>
                    <Categorias
                        getCategorias={this.getCategorias}
                        categorias={this.state.categorias}
                        toggle={this.toggleCategoriasModal}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Asignaciones);
