import React, { Component } from "react";
import { Route, NavLink, withRouter } from "react-router-dom";
import { withRequest } from "../../../utils/request";
import Loader from "../../general/Loader";
import Edit from "./Edit";
import Miembros from "./Miembros/";
import { getLink } from "../../../utils/helpers";

class Layout extends Component {
    state = {
        grupo: {},
        loading: true,
    };

    componentDidMount() {
        this.getGrupo();
    }

    getGrupo = async (url = null) => {
        const id = this.props.match.params.id;
        url = url ? url : "gi-grupos/" + id;
        const grupo = await this.props.request(url);

        if (grupo.success) {
            this.setState({
                grupo: grupo.data,
                loading: false,
            });
        }
    };

    render() {
        const { loading, grupo } = this.state;

        if (loading) {
            return <Loader />;
        }

        return (
            <div>
                <div className='page-header'>
                    <h1 className='page-title'>
                        {grupo.foto && (
                            <span className='page-title-foto'>
                                <img
                                    src={grupo.foto}
                                    alt={grupo.codigo}
                                />
                            </span>
                        )}
                        {grupo.nombreCompleto}
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-3 page-sidebar'>
                        <ul className='list-unstyled page-sidebar__menu'>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/gi-grupos/${grupo.id}/informacion`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Información General
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/gi-grupos/${grupo.id}/miembros`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Miembros
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-8 offset-1'>
                        <Route
                            path='/:sucursal/gi-grupos/:id/informacion'
                            render={(props) => (
                                <Edit {...props} grupo={grupo} />
                            )}
                        />
                        <Route
                            path='/:sucursal/gi-grupos/:id/miembros'
                            render={(props) => (
                                <Miembros {...props} grupo={grupo} />
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(Layout));
