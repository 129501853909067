import React, { Component } from "react";

class DashboardTabs extends Component {
    state = {
        children: [],
        active: 0,
    };

    componentDidMount() {
        this.setState({
            children: this.props.children,
        });
    }

    onClickTab = (index) => {
        this.setState({
            active: index,
        });
    };
    render() {
        const { children } = this.props;
        const { active } = this.state;

        return (
            <div className='dashboard-tabs'>
                <ul className='dashboard-tabs-tab'>
                    {children.map((child, index) => (
                        <li
                            key={child.props.title}
                            onClick={() => this.onClickTab(index)}
                            className={active === index ? "col active" : "col"}>
                            {child.props.title}
                            {child.props.counter !== false && (
                                <small
                                    className={
                                        child.props.counter > 0 ? "danger" : ""
                                    }>
                                    {child.props.counter}
                                </small>
                            )}
                        </li>
                    ))}
                </ul>
                <div className='dashboard-tabs-content'>
                    {children.map((child, index) =>
                        React.cloneElement(child, { active: active === index })
                    )}
                </div>
            </div>
        );
    }
}

export const Tab = ({ children, active }) => (
    <div className={"dashboard-tabs-body" + (active ? " active" : "")}>
        {children}
    </div>
);

export default DashboardTabs;
