import React, { Component } from "react";
import Select from "react-select";
import { apiUrl } from "../../utils/apiUrl";
import { auth } from "../../utils/auth";
import dayjs from "dayjs";

class FormCompletada extends Component {
    state = {
        errors: {},
    };
    editReclamacion = async (event) => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(
            apiUrl(`reclamaciones/${this.props.reclamacion.id}`),
            {
                method: "post",
                body: data,
                headers: {
                    Accept: "application/json",
                    "X-Request-With": "XMLHttpRequest",
                    Authorization: "Bearer " + token,
                },
            }
        );

        const response = await res.json();

        if (response.success) {
            this.props.getReclamaciones();
            this.props.toggle();
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };

    render() {
        const {
            reclamacion,
            estado,
            empleados,
            vehiculos,
            toggle,
        } = this.props;
        const { errors } = this.state;

        return (
            <form method='post' onSubmit={this.editReclamacion}>
                <input type='hidden' name='_method' value='patch' />
                <div className='popup__header'>
                    {estado === "cancelada" ? (
                        <div>Cancelar Reclamación</div>
                    ) : (
                        <div>¿Cómo se Atendió la Relamación?</div>
                    )}
                </div>
                <div className='popup__body'>
                    <div class="p-b-25">
                        {estado === "cancelada" ? (
                            <p>Favor indicar por qué la reclamación fue cancelada.</p>
                        ) : (
                            <p>Favor indicar la fecha en que se trabajó, el supervisor y la ficha que atendieron la reclamación.</p>
                        )}
                    </div>
                    <div>
                        <input
                            type='hidden'
                            name='reclamacion_id'
                            value={reclamacion.id}
                        />
                        <input type='hidden' name='estado' value={estado} />
                        <div className='row form-group'>
                            <div className='col col-md-3'>
                                <label className='label form-control-label'>
                                    Fecha:
                                </label>
                            </div>
                            <div className='col col-md-9'>
                                <input
                                    required
                                    type='date'
                                    className='text-input form-control'
                                    id='fecha'
                                    min={dayjs(reclamacion.fecha).format(
                                        "YYYY-MM-DD"
                                    )}
                                    defaultValue={dayjs().format(
                                        "YYYY-MM-DD"
                                    )}
                                    name='atendida_en'
                                />
                                {errors.atendida_en && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.atendida_en[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        {estado !== "cancelada" && empleados.length > 0 && (
                            <div className='row form-group'>
                                <div className='col col-md-3'>
                                    <label className='label form-control-label'>
                                        Supervisor:
                                    </label>
                                </div>
                                <div className='col col-md-9'>
                                    <Select
                                        name='atendida_supervisor'
                                        isClearable={true}
                                        options={empleados
                                            .filter(
                                                (emp) =>
                                                    emp.cargo
                                                        .toLowerCase()
                                                        .indexOf("supervisor") >
                                                    -1
                                            )
                                            .map((empleado) => ({
                                                value: empleado.id,
                                                label: `${empleado.nombreCompleto}`,
                                            }))}
                                    />
                                    {errors.atendida_supervisor && (
                                        <small className='help-blockParams form-Text'>
                                            {errors.atendida_supervisor[0]}
                                        </small>
                                    )}
                                </div>
                            </div>
                        )}
                        {estado !== "cancelada" && vehiculos.length > 0 && (
                            <div className='row form-group'>
                                <div className='col col-md-3'>
                                    <label className='label form-control-label'>
                                        Ficha:
                                    </label>
                                </div>
                                <div className='col col-md-9'>
                                    <Select
                                        name='atendida_ficha'
                                        isClearable={true}
                                        options={vehiculos.map((vehiculo) => ({
                                            value: vehiculo.id,
                                            label: `${vehiculo.ficha}`,
                                        }))}
                                    />
                                    {errors.atendida_ficha && (
                                        <small className='help-blockParams form-Text'>
                                            {errors.atendida_ficha[0]}
                                        </small>
                                    )}
                                </div>
                            </div>
                        )}
                        {estado === "cancelada" && (
                            <div className='row form-group'>
                                <div className='col col-md-3'>
                                    <label className='label form-control-label'>
                                        Comentario:
                                    </label>
                                </div>
                                <div className='col col-md-9'>
                                    <textarea
                                        className='form-control'
                                        name='atendida_comentario'
                                        id=''
                                        cols='30'
                                        rows='10'></textarea>
                                    {errors.atendida_comentario && (
                                        <small className='help-blockParams form-Text'>
                                            {errors.atendida_comentario[0]}
                                        </small>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={(e) =>
                            this.deleteReclamacion(e, reclamacion.id)
                        }>
                        <i className='fas fa-trash-alt'></i>
                    </button>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default FormCompletada;
