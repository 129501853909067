import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../utils/request";
import Loader from "../general/Loader";
import { formatDate } from "../../utils/helpers";

class Produccion extends Component {
    state = {
        tarjetas: [],
        loading: true
    };
    componentDidMount() {
        this.getProduccion();
    }
    getProduccion = async (url = null) => {
        const id = this.props.match.params.id;
        url = url ? url : `vehiculos/${id}/produccion`;
        const tarjetas = await this.props.request(url);

        if (tarjetas.success) {
            this.setState({
                tarjetas: tarjetas.data,
                loading: false
            });
        }
    };
    render() {
        const { tarjetas, loading } = this.state;

        if (loading === 0) {
            return <Loader />;
        }
        return (
            <div>
                <h3>Historial de Producción</h3>
                <table className='table tickets-table'>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Toneladas</th>
                            <th>Viajes</th>
                            <th>Zona</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tarjetas.map(tarjeta => (
                            <tr key={tarjeta.id}>
                                <td>{formatDate(tarjeta.fecha)}</td>
                                <td>{tarjeta.toneladas}</td>
                                <td>{tarjeta.viajes}</td>
                                <td>{tarjeta.ruta.nombre}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(Produccion));
