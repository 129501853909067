/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Select, {createFilter} from "react-select";
import {
	formatCurrency,
	isVendedor,
	money,
} from "../../../../utils/helpers";
import PropTypes from "prop-types";

import ItemFotos from "../../../Items/ItemFotos";
import Modal from "../../../general/Modal";
import useProducto from "../../Hooks/useProducto";

const ProductosOrden = ({
	producto,
	updateTotal,
	productos,
	index,
	removeProducto,
	precioLimite,
	banderaContacto,
	impuestosForDropdown,
	facturaProductos,
	isEdit,
	tipoOrden,
	errors,
	contacto
}) => {
    const {
        productoInfo,
        productLabel,
        total,
        totalReal,
        preciosVenta,
        precioManual,
        pinSeguridad,
        productoITBIS,
        checkPINSeguridad,
        onChangeInput,
        onChangeImpuesto,
        onChangeProducto,
        onChangeCostoManual,
        handlePrecioVentaChange,
        setProductoInfo,
        setPrecioManual,
        setPinSeguridad,
        setShowFotos,
        toggleFotosModal,
        toggleDeleteModal,
        togglePINModal,
        showPrecioManual,
        showFotos,
        showDelete,
        showPINModal,
        volverAlSelector
    } = useProducto(producto, productos, facturaProductos, updateTotal, index, impuestosForDropdown, banderaContacto, precioLimite, contacto, isEdit);

	if (!productos) {
		return <></>;
	}

	return (
		<>
			<tr>
				<td colSpan={2}>
					<input
						type="hidden"
						name={`productos[${index}][id]`}
						value={isEdit ? producto.id : "random1"}
					/>
                    {tipoOrden === "interna" ? (
						<input
							type="text"
							name={`productos[${index}][nombre_producto]`}
							className="form-control"
							value={productoInfo.nombre_producto}
							onChange={(e) =>
								onChangeInput("nombre_producto", e.target.value)
							}
						/>
					) : (
                        <Select
                            type="text"
                            key={`productos[${index}]`}
                            name={`productos[${index}][producto_id]`}
                            placeholder={"Seleccione..."}
                            filterOption={createFilter({ignoreAccents: false})}
                            value={
                                productoInfo.id && {
                                    value: productoInfo.id,
                                    label: productoInfo.nombre,
                                }
                            }
                            onChange={(e) => onChangeProducto(e.value)}
                            styles={{
                                singleValue: (provided, state) => ({
                                    ...provided,
                                    width: "100%",
                                    display: "flex",
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                        ? "rgba(152, 204, 245,.3)"
                                        : "white",
                                    color: state.isSelected
                                        ? "#008aab"
                                        : "#666666",
                                    fontWeight: state.isSelected
                                        ? "600"
                                        : "normal",
                                }),
                            }}
                            maxMenuHeight={200}
                            options={
                                productos && contacto &&
                                productos.map((producto) => ({
                                    label: `${producto.referencia} ${producto.nombre}`,
                                    value: producto.id,
                                    producto: producto,
                                }))
                            }
                            formatOptionLabel={(option) =>
                                option.producto
                                    ? productLabel(option.producto)
                                    : option.label
                            }
                        />
                    )}
				</td>
				<td>
					<input
						type="number"
						name={`productos[${index}][cantidad]`}
						placeholder="Cantidad"
						value={productoInfo.cantidad || undefined}
						step="1"
						min="1"
						onChange={(e) => {
							producto.cantidad = parseFloat(e.target.value || 0);
							onChangeInput("cantidad", e.target.value);
						}}
						className="form-control"
					/>
				</td>
				<td>
					<input
						type="text"
						placeholder="Und."
						value={productoInfo.unidad}
						className="form-control"
						style={{ backgroundColor: "#fff" }}
						readOnly
					/>
				</td>
				<td>
					{tipoOrden === "interna" ? (
						<input
							type="number"
							className="form-control"
							style={{ backgroundColor: "#fff" }}
							name={`productos[${index}][importe]`}
							value={productoInfo.costo}
							onChange={(e) =>
								onChangeInput(
									"costo",
									parseFloat(e.target.value)
								)
							}
						/>
					) : (
						<>
							<input
								type="text"
								style={{ backgroundColor: "#fff" }}
								value={formatCurrency(
									money(productoInfo?.costo)
								)}
								className="form-control"
								readOnly={tipoOrden === "cliente"}
							/>
							<input
								type="hidden"
								name={`productos[${index}][importe]`}
								className="form-control"
								value={productoInfo.costo}
								readOnly
							/>
						</>
					)}
				</td>
				<td>
					{/* !isVendedor() */ false  ? (
						<Select
							type="text"
							name={`productos[${index}][impuesto_id]`}
							placeholder="Impuesto"
							onChange={(e) => onChangeImpuesto(e.value)}
							value={productoInfo.impuesto}
							options={impuestosForDropdown}
						/>
					) : (
						<>
							<input
								className="form-control"
								value={productoInfo.impuesto?.label}
							/>
							<input
								type="hidden"
								name={`productos[${index}][impuesto_id]`}
								value={productoInfo.impuesto?.value}
							/>
						</>
					)}
				</td>
				<td>
					{tipoOrden === "interna" ? (
						<>
							<input
								type="text"
								className="form-control"
								style={{ backgroundColor: "#fff" }}
								value={formatCurrency(
									money(
										parseFloat(productoInfo.costo) +
											(parseFloat(productoInfo.costo) *
												productoInfo.impuesto
													?.porcentaje || 0)
									)
								)}
								readOnly
							/>
							<input
								type="hidden"
								className="form-control"
								style={{ backgroundColor: "#fff" }}
								name={`productos[${index}][costo]`}
								value={productoInfo.costo}
								readOnly
							/>
						</>
					) : !showPrecioManual ? (
						<Select
							type="text"
							name={`productos[${index}][costo]`}
							placeholder={"Seleccione..."}
							isLoading={productos[0] === false}
							loadingMessage={() => "Cargando"}
							onChange={(e) => handlePrecioVentaChange(e)}
							maxMenuHeight={200}
							options={
								[
									...preciosVenta(),
									{ value: "manual", label: "Manual" },
								] || []
							}
							value={productoInfo?.precio_venta || {}}
						/>
					) : (
						<input
							type="number"
							className="form-control"
							name={`productos[${index}][costo]`}
							value={precioManual}
                            id={`costo-${index}`}
							onChange={(e) => {
                                const svalue = e.target.value;
                                setPrecioManual(svalue)
                                setProductoInfo(prev => {
                                    return {
                                        ...prev,
                                        precio_manual: svalue
                                    }
                                })
                                onChangeCostoManual(svalue)
                            }}
							onBlur={(e) => {
                                onChangeCostoManual(e.target.value, true)
                            }}
							onDoubleClick={() => {
                                volverAlSelector();
                            }}
						/>
					)}
				</td>
				<td>
                    <input type="hidden" name={`productos[${index}][descuento]`} value="0" />
					<input
						type="number"
						min="0"
						name={`productos[${index}][descuento]`}
						placeholder="Descuento"
						value={productoInfo.descuento}
						step="0.1"
						onChange={(e) =>
							onChangeInput("descuento", e.target.value)
						}
						className="form-control"
					/>
				</td>
				<td className="text-right">
					{!showPrecioManual ? (
						<input
							type="hidden"
							name={`productos[${index}][precio_facturado]`}
							value={productoInfo.costo + productoITBIS}
						/>
					) : (
						<input
							type="hidden"
							name={`productos[${index}][precio_facturado]`}
							value={precioManual}
						/>
					)}

					<input
						type="hidden"
						name={`productos[${index}][total]`}
						value={total}
					/>
					{formatCurrency(productoInfo.descuento > 0 ? totalReal : total)}
				</td>
				<td className="text-center">
					{productoInfo.fotos?.length > 0 && (
						<button
							type="button"
							title="Ver Fotos"
							onClick={() => toggleFotosModal()}
							className="producto"
						>
							<i className="fas fa-eye"></i>
						</button>
					)}
				</td>
				<td className="text-right">
					<button
						type="button"
						className="btn btn-danger"
						onClick={() =>
							isEdit
								? toggleDeleteModal()
								: removeProducto(producto.id)
						}
					>
						<i className="fas fa-trash"></i>
					</button>
				</td>
			</tr>
			{errors[`productos.${index}.total`] && (
				<small
					className="help-blockParams form-Text d-block"
					style={{ whiteSpace: "nowrap" }}
				>
					{errors[`productos.${index}.total`][0]}
				</small>
			)}
			{errors[`productos.${index}.cantidad`] && (
				<small
					className="help-blockParams form-Text d-block"
					style={{ whiteSpace: "nowrap" }}
				>
					{errors[`productos.${index}.cantidad`][0]}
				</small>
			)}
			{productoInfo?.fotos && (
				<Modal
					show={showFotos}
					blank={true}
					toggle={() => setShowFotos((state) => !state)}
				>
					<ItemFotos
						toggle={() => setShowFotos((state) => !state)}
						fotos={productoInfo.fotos}
					/>
				</Modal>
			)}
			<Modal
				title="Borrar Producto"
				show={showDelete}
				callback={() => removeProducto(producto.id)}
				acceptText="Borrar"
				toggle={toggleDeleteModal}
			>
				¿Estás seguro de que deseas borrar este producto?
			</Modal>
			{/* <---PIN DE SEGURIDAD MODAL--- */}
			<Modal
				title="PIN de seguridad"
				show={showPINModal}
				callback={() => checkPINSeguridad()}
				acceptText="Aceptar"
				toggle={() => togglePINModal()}
				size="419px"
			>
				<div className="form-group">
					<label>PIN de seguridad</label>
					<input
						type="password"
						className="form-control"
						name="pin_seguridad"
						value={pinSeguridad}
						onChange={(e) => setPinSeguridad(e.target.value)}
					/>
					<p
						className="font-weight-bold"
						style={{
							color: "gray",
							fontStyle: "italic",
							lineHeight: "13px",
							fontSize: "0.7rem",
						}}
					>
						Para continuar ingrese PIN de seguridad
					</p>
				</div>
			</Modal>
		</>
	);
};

ProductosOrden.propTypes = {
	producto: PropTypes.object,
	updateTotal: PropTypes.func,
	productos: PropTypes.array,
	index: PropTypes.number,
	removeProducto: PropTypes.func,
	precioLimite: PropTypes.number,
	impuestosForDropdown: PropTypes.array,
	ordenProductos: PropTypes.array,
	isEdit: PropTypes.bool,
	tipoOrden: PropTypes.string,
};

export default ProductosOrden;
