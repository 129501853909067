import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency, formatNumber } from "../../../utils/helpers";
import dayjs from "dayjs";

class PrintHistorialCompras extends Component {
    state = {
        compras: [],
        item: {},
    };
    componentDidMount() {
        this.getCompras();
        this.getItem();
    }
    getCompras = async () => {
        const response = await this.props.request(
            "compras" + window.location.search
        );

        if (response.data) {
            this.setState({
                compras: response.data,
            });
        }
    };
    getItem = async () => {
        const response = await this.props.request(
            "productos/" + queryString.parse(window.location.search).producto
        );

        if (response.data) {
            this.setState({
                item: response.data,
            });
        }
    };
    render() {
        const { compras, item } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const params = queryString.parse(window.location.search);

        const valorTotal = compras.reduce((acc, item) => {
            return acc + parseFloat(item.cantidad) * parseFloat(item.costo);
        }, 0);

        return (
            <div>
                <div className='row'>
                    <h3 className='col-md-12 text-center m-b-5'>
                        Historial de Compras de {item.referencia} -{" "}
                        {item.nombre}
                    </h3>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL COMPRAS: {compras.length}
                        </div>

                        <div className='float-left m-l-45'>
                            {params.desde && (
                                <span>
                                    DESDE{" "}
                                    {dayjs(params.desde).format("DD/MM/YYYY")}
                                </span>
                            )}
                            {params.desde && (
                                <span className='m-l-10'>
                                    HASTA{" "}
                                    {dayjs(params.hasta).format("DD/MM/YYYY")}
                                </span>
                            )}
                        </div>

                        <div className='float-right'>
                            <h3>TOTAL: {formatCurrency(valorTotal)}</h3>
                        </div>
                    </div>
                </div>
                <table className='table m-b-40'>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Suplidor</th>
                            <th className='text-right'>Cantidad</th>
                            <th className='text-right'>Costo/UND</th>
                            <th className='text-right'>Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {compras.map((compra) => (
                            <tr key={compra.id}>
                                <td>{compra.fecha}</td>
                                <td>{compra.suplidor}</td>
                                <td className='text-right'>
                                    {formatNumber(compra.cantidad)}
                                </td>
                                <td className='text-right'>
                                    {formatCurrency(compra.costo)}
                                </td>
                                <td className='text-right'>
                                    {formatCurrency(
                                        compra.costo * compra.cantidad
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='row'>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL COMPRAS: {compras.length}
                        </div>

                        <div className='float-right'>
                            <h3>TOTAL: {formatCurrency(valorTotal)}</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintHistorialCompras));
