import React from "react";
import { auth } from "../../../utils/auth";
import InvalidScreen from "../../general/InvalidScreen";
import PageHeader from "../../general/PageHeader";
import Form from "./Components/OrdenForm";

const CrearOrden = () => {
	if ((auth.is("vendedor") || auth.is("vendedor-factura-orden_compra") || auth.is("cobrador") || auth.is('vendedor-cliente')) && !localStorage.getItem("rutas")) {
		return <InvalidScreen message="No hay zonas seleccionadas" />;
	}

	return (
		<>
			<PageHeader title="Nueva Orden de Compra" hasButton={false}/>
			<Form ordenActual={{}} isEdit={false} />
		</>
	);
};

export default CrearOrden;
