import { toast } from "../../../utils/helpers";


export const useChecks = (facturaProductos, productos, productoInfo) => {
    const checkExistenciaProducto = (productoID) => {
        const producto = productos.find((producto) => producto.id === productoID);
        if(producto.cantidad != null && producto.cantidad <= 0) {
            toast("Este producto no tiene existencia", "error");
            return false;
        }
        return true;
    }

    const checkQuantityConstrains = (existencia, cantidad) => {
		if(existencia && cantidad && existencia < cantidad) {
			toast(`Existencia Actual ${existencia}`, "error");
			return false;
		}
		if(existencia && existencia <= 0) {
			toast(`Cantidad Debe ser mayor a 0`, "error");
			// onChangeInput("cantidad", 1);
			return false;
		}

        return true;
	};

    const checkProductoRepetidoFactura = (productoId, index) => {
        const indiceProducto = facturaProductos.findIndex(
            (producto) => producto.producto_id === productoId
        );

        const producto = productos.find((producto) => producto.id === productoId);

        if (!checkQuantityConstrains(producto?.cantidad + facturaProductos[indiceProducto]?.cantidadAnterior, facturaProductos[indiceProducto]?.cantidad+1)) {
            facturaProductos.splice(index, 1);
            return;
        }

        if (indiceProducto >= 0 && indiceProducto !== index) {
            facturaProductos[indiceProducto].cantidad += 1;
            facturaProductos.splice(index, 1);
        }
    };

    const checkProductoRepetido = (productoId, index) => {
        const indiceProducto = facturaProductos.findIndex(
            (producto) => producto.producto_id === productoId
        );

        if (indiceProducto >= 0 && indiceProducto !== index) {
            facturaProductos[indiceProducto].cantidad += 1;
            facturaProductos.splice(index, 1);
        }
    };

    return {checkProductoRepetido, checkProductoRepetidoFactura, checkExistenciaProducto, checkQuantityConstrains}
};
