/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	useEffect,
	useState,
	useRef,
} from "react";
import Loader from "../../../general/Loader";
import { request } from "../../../../utils/request";
import {
	formatCurrency,
	getLink,
	getVendedoresForDropdown,
	money,
	toast,
	validarPINSeguridad,
} from "../../../../utils/helpers";
import { useHistory } from "react-router-dom";
import { apiRequest } from "../../../../hooks/apiRequest";

import Create from "../../../Contactos/Create";
import Edit from "../../../Contactos/Edit";
import Modal from "../../../general/Modal";
import ProductosCotizacion from "./ProductosCotizacion";
import Detalles from "./Detalles";
import dayjs from "dayjs";
import { auth } from "../../../../utils/auth";

import { calcularDescuento, calcularExentoGrabado, calcularImpuestos, calcularSubtotal, calcularTotal, calcularTotalImpuestos, templateProducto } from "../../Components/utils";

const Form = ({ cotizacionActual, isEdit }) => {
	const history = useHistory();

	const formRef = useRef();
    const [loading, setLoading] = useState(true);

	//Cosas generales como formulario
	const [form, setForm] = useState({
		cotizacion: {},
		numero_cotizacion: {},
		fecha: dayjs().format("YYYY-MM-DD"),
		fecha_vencimiento: dayjs().format("YYYY-MM-DD"),
		notas: "",
	});

    const [contactoId, setContactoId] = useState();
    const [facturaProductos, setFacturaProductos] = useState([
        templateProducto(Date.now())
    ]);
    const [plazo, setPlazo] = useState({});
    const [errors, setErrors] = useState({});

	const [showCreateContactoModal, setShowCreateContactoModal] =
		useState(false);
	const [showEditContactoModal, setShowEditContactoModal] = useState(false);
	const [showPINModal, setShowPINModal] = useState(false);
	// const [showAgregarPagoModal, setShowAgregarPagoModal] = useState(false);
	const [showCancel, setShowCancel] = useState(false);

	const [totalitbis, setTotalItbis] = useState(0);

	// Opciones de los Dropdown
	const [dropdownOptions, setDropdownOptions] = useState({
		contactos: [],
		terminos: [],
		productos: [],
		vendedores: [],
		rutas: [],
		grupos: [],
		impuestos: [],
	});

	const [saving, setSaving] = useState(false);
	const [createButtonClicked, setCreateButtonClicked] = useState("normal");
	const [pinSeguridad, setPinSeguridad] = useState("");
	// const [montoRecibido, setMontoRecibido] = useState(null);

	//Calculos Finales
	const [sumatoriaFinal, setSumatoriaFinal] = useState({
		subtotal: 0,
		descuento: 0,
		impuestos: {},
        exentoGrabado: {},
		total: 0,
	});

	//Para llenar inputs de contacto (Cliente)
	const [contactoInfo, setContactoInfo] = useState({});

    const resetFacturaProductos = () => {
        setFacturaProductos([
            templateProducto(Date.now())
        ]);
    }

	const toggleCreateContactoModal = async (e, contacto = null) => {
		if (e) e.preventDefault();
		setShowCreateContactoModal((state) => !state);

		if (!contacto || !Object.keys(contacto).includes("id")) return;

		await getContactosForDropdown();

		setContactoId(contacto.id);
	};

	const toggleEditContactoModal = (e, contacto = null) => {
		if (e) e.preventDefault();
		setShowEditContactoModal((state) => !state);

		if (!contacto || !Object.keys(contacto).includes("id")) return;

		getContactosForDropdown();

		setContactoId(contacto.id);

		changeContactInfo(contacto.id, false);
	};

	const changeContactInfo = (contacto, clear = true) => {
		if (!contacto) return;

		request(`info-contacto/${contacto}`).then(({ data }) => {
            const vendedor =
				auth.is("vendedor") || auth.is("vendedor-factura-orden_compra") || auth.is("cobrador") || auth.is('vendedor-cliente')
					? {
							value: auth.getUser().empleado?.id,
							label: `${auth.getUser().empleado?.codigo} ${
								auth.getUser().empleado?.nombre
							} ${auth.getUser().empleado?.apellido}`,
					  }
					: {
							value: data.vendedor?.id,
							label: `${data.vendedor?.codigo} ${data.vendedor?.nombre} ${data.vendedor?.apellido}`,
					  };

            const contact = {
                ...data,
                vendedor: vendedor.value ? vendedor : null,
                ruta: data.ruta
                    ? { value: data.ruta?.id, label: data.ruta?.nombre }
                    : null,
                credito_disponible_actual: parseFloat(data.credito_disponible),
            };

			setContactoInfo(contact);


            if (clear) {
                changePlazo(dropdownOptions?.terminos[0]);
                resetFacturaProductos();
            }

            aplicarLimiteFecha();
		});
	};

	const handleInputChange = (value, field) => {
		setForm((form) => ({
			...form,
			[field]: value,
		}));
	};

	const handleDetalleChange = (value, field) => {
		setContactoInfo((state) => ({ ...state, [field]: value }));
	};

	const aplicarLimiteFecha = () => {
		if (!contactoInfo.plazo) return;

		return dayjs(form.fecha)
			.add(contactoInfo.plazo?.dias, "day")
			.format("YYYY-MM-DD");
	};

	// Funciones para traer data de los dropdowns
	const getDropdownData = () => {
		setLoading(true);

		request("cotizaciones/create").then((res) => {
			setDropdownOptions((options) => ({
				...options,
				// productos: res.data?.productos,
				rutas: res.data?.rutas,
				terminos: res.data?.terminos,
				impuestos: res.data?.impuestos,
			}));

			if (isEdit) return;

            changePlazo(res.data?.terminos[0]);

			setForm((state) => ({
				...state,
				numero_cotizacion: {
					value: res.data?.numero_cotizacion?.id,
					label: `${res.data?.numero_cotizacion?.tipo}${res.data?.numero_cotizacion?.numero_siguiente}`,
				},
			}));
		});

		getVendedoresForDropdown().then(data => {
			setDropdownOptions((options) => ({
				...options,
				vendedores: data.filter(vendedor => vendedor.value !== ''),
			}));
		});
	};

	const getContactosForDropdown = () => {
		const url = isEdit
			? "contactos?limit=1000"
			: `contactos?limit=1000&tipo=${form.tipo === "interna" ? "proveedor" : "cliente"}`;

		request(url).then((res) => {
			const contactos = [];

			if (
				auth.is("vendedor") ||
				auth.is("vendedor-factura-orden_compra") ||
				auth.is("cobrador") ||
				auth.is('vendedor-cliente')
			) {
				const rutasGuardadas = JSON.parse(localStorage.getItem("rutas"));

				for (let { value } of rutasGuardadas) {
					contactos.push(
						...res.data.filter(
							(contacto) => contacto.ruta?.id === value && (auth.is('vendedor-cliente') ? contacto.vendedor?.id === auth.getUser().empleado?.id : true)
						)
					);
				}
			}

			setDropdownOptions((options) => ({
				...options,
				contactos:
					auth.is("vendedor") ||
					auth.is("vendedor-factura-orden_compra") ||
					auth.is("cobrador") ||
					auth.is('vendedor-cliente')
						? contactos.map((contacto) => ({
								value: contacto.id,
								label: contacto.nombre,
						  }))
						: res.data.map((contacto) => ({
								value: contacto.id,
								label: contacto.nombre,
						  })),
			}));

            setLoading(false);
		});
	};

	const getProductosForDropdown = () => {
		request("productos").then((res) => {
			const almacenesGuardados = JSON.parse(localStorage.getItem("almacenes"));

			setDropdownOptions((options) => ({
				...options,
				productos: res.data.filter((producto) => {
					if (!auth.is('vendedor-cliente')) return true;

					for (let { value } of almacenesGuardados) {
						if (producto.almacen?.id === value) return true;
					}

					return false;
				}),
			}));

            setLoading(false);
		});
	};

	const updateTotal = (
		id = null,
		subtotal = null,
		total = 0,
		impuestos = 0,
		descuento = 0,
		impuesto,
        productoInfo = {},
	) => {
		const nproductos = facturaProductos?.map((producto) => {
            if (producto.id === id) {
                producto.producto_id = productoInfo.id;
                producto.importe = subtotal / producto.cantidad
                producto.descuento = descuento;
                producto.subtotal = subtotal;
                producto.impuesto = impuesto;
                producto.impuestos = impuestos;
                producto.total = total;
                if (productoInfo.precio_manual || productoInfo.precio_venta) {
                    producto.precio_facturado = parseFloat(productoInfo.precio_manual ?? productoInfo.precio_venta?.value);
                }
                producto.cantidadAnterior = productoInfo.cantidadAnterior;
            }
            return producto;
        });

        setFacturaProductos(nproductos);

        updateSumatoriaFinal(nproductos, contactoInfo);
	};

	const addProducto = () => {
        setFacturaProductos(prev => [
            ...prev,
            templateProducto(Date.now())
        ])
	};

	const removeProducto = async (index) => {
        const newProds = facturaProductos.filter(
            (i) => i.id !== index
        );
        setFacturaProductos(newProds);
        updateSumatoriaFinal(newProds, contactoInfo);
	};

	const cancel = () => {
		history.push(getLink("/ingresos/cotizaciones"));
	};

	const guardarYNuevaCotizacion = async () => {
		await setCreateButtonClicked("nueva");

		if (!checkCreditoDisponible()) return;

		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const guardarEImprimir = async () => {
		await setCreateButtonClicked("imprimir");

		if (!checkCreditoDisponible()) return;

		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const guardarYSalir = async () => {
		await setCreateButtonClicked("normal");

		if (!checkCreditoDisponible()) {
			return;
		}
		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const createCotizacion = async (event) => {
		event.preventDefault();

		setErrors({});

		setSaving(true);
		const data = new FormData(event.target);

		const response = await apiRequest(data, "cotizaciones", "POST");

		if (response.success) {
			toast("Cotizacion creada", "success");

			if (createButtonClicked === "normal") {
				history.push(getLink("/ingresos/cotizaciones"));
			} else if (
				createButtonClicked === "imprimir"
			) {
				history.push(getLink("/ingresos/cotizaciones"));

				window.open(getLink(`/imprimir/cotizaciones/${response.data.id}`));
			}
			resetFields();
		}
		if (response.code === 422) {
			setErrors(response.data.message);
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}

		setSaving(false);
	};

	const editCotizacion = async (event) => {
		event.preventDefault();

		setForm((state) => ({ ...state, errors: {} }));

		setSaving(true);
		const data = new FormData(event.target);
		data.append("_method", "put");

		const response = await apiRequest(
			data,
			`cotizaciones/${cotizacionActual.id}`,
			"POST"
		);

		if (response.success) {
			toast("Cotizacion actualizada", "success");
			if (createButtonClicked === "normal") {
				history.push(getLink("/ingresos/cotizaciones"));
			}
			resetFields();
		}
		if (response.code === 422) {
			setForm((state) => ({ ...state, errors: response.data.message }));
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}

		setSaving(false);
	};

	const checkCreditoDisponible = () => {
		if (
			contactoInfo.credito_disponible_actual < sumatoriaFinal.total &&
			(plazo.dias > 0 || plazo.dias === null)
		) {
			setShowPINModal(true);
			return false;
		}

		return true;
	};

	const resetFields = () => {
		setCreateButtonClicked("normal");
		setContactoInfo({});
		setForm({
            numero_cotizacion: {},
			fecha: dayjs().format("YYYY-MM-DD"),
			fecha_vencimiento: dayjs().format("YYYY-MM-DD"),
			notas: "",
		});

        setErrors({});
        setContactoId(null);
        resetFacturaProductos();
        changePlazo({ value: 0, label: "Seleccione...", dias: 0 });
        setPinSeguridad("");

		window.scrollTo(0, 0);
	};

	const checkPINSeguridad = async () => {
		if (await validarPINSeguridad(pinSeguridad)) {
			setPinSeguridad("");
			setShowPINModal(false);
			formRef.current.dispatchEvent(
				new Event("submit", { bubbles: true, cancelable: true })
			);
			return;
		}

		toast("PIN incorrecto, intente de nuevo", "error");
	};

	const toggleCancelModal = () => {
		setShowCancel((state) => !state);
	};

	const cancelCotizacion = async (cotizacionID) => {
		setShowCancel((state) => !state);
		setErrors({});

		setSaving(true);

		const response = await apiRequest(
			null,
			`cotizaciones/${cotizacionID}`,
			"DELETE"
		);

		if (response.success) {
			toast("Cotizacion Cancelada", "success");
			history.push(getLink("/ingresos/cotizaciones"));
			return;
		}
		toast("Error cancelando cotización", "error");

		setSaving(false);
	};

	const setEditInfo = async () => {
		await setContactoInfo({
			...cotizacionActual.cliente,
			vendedor: cotizacionActual.vendedor && {
				value: cotizacionActual.vendedor.id,
				label: `${cotizacionActual.vendedor?.nombre} ${cotizacionActual.vendedor?.apellido}`,
			},
			ruta: cotizacionActual.ruta
				? {
						value: cotizacionActual.ruta?.id,
						label: cotizacionActual.ruta?.nombre,
				  }
				: null,
			credito_disponible_actual: cotizacionActual.cliente?.credito_disponible,
		});

        setFacturaProductos(cotizacionActual.productos);

        setContactoId(cotizacionActual.cliente?.id);
        changeContactInfo(cotizacionActual.cliente?.id, false);

        changePlazo({
            value: cotizacionActual.plazo?.id || cotizacionActual.plazo?.value,
            label: cotizacionActual.plazo?.nombre || cotizacionActual.plazo?.label,
            dias: cotizacionActual.plazo?.dias,
        });

		setForm((form) => ({
			...form,
			numero_cotizacion: {
				value: cotizacionActual.numeracion_id,
				label: cotizacionActual.numero_comprobante,
			},
			fecha: dayjs(cotizacionActual.fecha).format("YYYY-MM-DD"),
			fecha_vencimiento: dayjs(cotizacionActual.fecha_vencimiento).format(
				"YYYY-MM-DD"
			),
			notas: cotizacionActual.notas,
			tipo: cotizacionActual.tipo,
		}));
	};

    const changePlazo = (plazo) => {
        let vencimiento = dayjs(form.fecha);

        setPlazo(plazo);

        if (form.fecha) {
            if (plazo.dias === null) return;

            vencimiento = vencimiento.add(plazo.dias, "day");

            handleInputChange(
                vencimiento.format("YYYY-MM-DD"),
                "fecha_vencimiento"
            );
        }
    }

    const updateSumatoriaFinal = (productos, contacto) => {
        const total = !isNaN(calcularTotal(productos))
			? calcularTotal(productos)
			: 0;

		setSumatoriaFinal((state) => ({
			...state,
			subtotal: calcularSubtotal(productos),
			descuento: calcularDescuento(productos),
			impuestos: calcularImpuestos(productos),
			exentoGrabado: calcularExentoGrabado(productos),
            total: total,
		}));

		setTotalItbis(calcularTotalImpuestos(productos));

        if (Object.keys(contacto).length >= 0) {
			setContactoInfo((state) => ({
				...state,
				credito_disponible: money(
					state.credito_disponible_actual - (total - form.prevTotal)
				),
			}));
		}
    }

	useEffect(() => {
        getDropdownData();
		getContactosForDropdown();
		getProductosForDropdown();
	}, []);

	useEffect(() => {
		if (!isEdit) return;
		setEditInfo();
	}, [cotizacionActual, cotizacionActual.productos]);

    if (loading) {
		return <Loader />;
	}

	return (
		<>
			<form
				method="post"
				onSubmit={(e) => {
					isEdit ? editCotizacion(e) : createCotizacion(e);
				}}
				ref={formRef}
			>
				<input
					type="hidden"
					name="id"
					value={cotizacionActual.id}
					onChange={() => {}}
				/>
				<div className="crear-compra">
					<div className="page-controls">
						<Detalles
							form={form}
							contactoInfo={contactoInfo}
                            contactoId={contactoId}
                            setContactoId={setContactoId}
                            plazo={plazo}
                            changePlazo={changePlazo}
                            changeContactInfo={changeContactInfo}
							dropdownOptions={dropdownOptions}
							toggleCreateContactoModal={
								toggleCreateContactoModal
							}
							toggleEditContactoModal={toggleEditContactoModal}
							handleInputChange={handleInputChange}
							aplicarLimiteFecha={aplicarLimiteFecha}
							handleDetalleChange={handleDetalleChange}
							isEdit={isEdit}
                            errors={errors}
						/>
					</div>
					<div className="col-md-12 p-0">
						<table className="table">
							<thead>
								<tr>
									<th width="170px">REF./CÓDIGO</th>
									<th width="23%">Descripción</th>
									<th width="85px">Cant.</th>
									<th width="85px">Unidad</th>
									<th width="9%">Importe</th>
									<th width="160px">Impuesto</th>
									<th width="180px">Precio</th>
									<th width="78px">% Desc.</th>
									<th className="text-right" width="9%">
										Total
									</th>
									<th className="text-center" width="5%">
										Fotos
									</th>
									<th width="85px"></th>
								</tr>
							</thead>
							<tbody>
								{facturaProductos?.map(
									(producto, index) => (
										<ProductosCotizacion
											key={producto.id}
											producto={producto}
											index={index}
											removeProducto={removeProducto}
											productos={
												dropdownOptions.productos
											}
											updateTotal={updateTotal}
											grupos={dropdownOptions.grupos}
											precioLimite={
												contactoInfo.precio_venta
											}
											banderaContacto={
												contactoInfo.bandera
											}
											impuestosForDropdown={
												dropdownOptions.impuestos
											}
											facturaProductos={
												facturaProductos
											}
											isEdit={isEdit}
											contacto={contactoInfo.id}
                                            errors={errors}
										/>
									)
								)}
								<tr>
									<td colSpan="11">
										{errors["productos"] && (
											<small className="help-blockParams form-Text">
												{errors["productos"][0]}
											</small>
										)}
									</td>
								</tr>
								<tr>
									<td
										colSpan="11"
										style={{ border: 0, paddingTop: 0 }}
										className="text-right"
									>
										<button
											type="button"
											onClick={addProducto}
											className="btn"
										>
											<i className="fas fa-plus" />{" "}
											Agregar Producto
										</button>
									</td>
								</tr>
                                {sumatoriaFinal.descuento > 0 ? (
                                    <tr>
                                        <td colSpan="9" className="text-right">
                                            <strong className="label form-control-label">
                                                Descuento:
                                            </strong>
                                        </td>
                                        <td colSpan="2" className="text-right">
                                            <input
                                                type="hidden"
                                                name="descuento"
                                                value={
                                                    sumatoriaFinal.descuento || 0
                                                }
                                                readOnly
                                            />
                                            -
                                            {formatCurrency(
                                                money(sumatoriaFinal.descuento)
                                            )}
                                            <input
                                                type="hidden"
                                                name="impuestos"
                                                value={totalitbis}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                ) : (
                                    <tr className="d-none">
                                        <td></td>
                                        <td><input type="hidden" name="descuento" value="0" /></td>
                                    </tr>
                                )}
								<tr>
									<td colSpan="9" className="text-right">
										<strong className="label form-control-label">
											Subtotal:
										</strong>
									</td>
									<td colSpan="2" className="text-right">
										<input
											type="hidden"
											name="subtotal"
											value={sumatoriaFinal.subtotal || 0}
											readOnly
										/>
										{formatCurrency(
											money(sumatoriaFinal.subtotal)
										)}
									</td>
								</tr>
								{Object.keys(sumatoriaFinal.impuestos).map(
									(impuesto, index) => (
										<tr key={index}>
											<td
												colSpan="9"
												className="text-right"
											>
												<strong className="label form-control-label">
													{impuesto}
												</strong>
											</td>
											<td
												colSpan="2"
												className="text-right"
											>
												<input
													type="hidden"
													name={`impuesto-${index}`}
													value={
														sumatoriaFinal
															.impuestos[impuesto]
													}
													readOnly
												/>
												{formatCurrency(
													money(sumatoriaFinal.impuestos[
														impuesto
													])
												)}
											</td>
										</tr>
									)
								)}
								<tr>
									<td colSpan="9" className="text-right">
										<strong className="label form-control-label">
											Total:
										</strong>
									</td>
									<td colSpan="2" className="text-right">
										<input
											type="hidden"
											name="pagado"
											value="0"
											readOnly
										/>
										<input
											type="hidden"
											name="total"
											value={money(sumatoriaFinal.total)}
											readOnly
										/>
										{formatCurrency(money(sumatoriaFinal.total))}
										{errors.total && (
											<small className="help-blockParams form-Text">
												{errors.total[0]}
											</small>
										)}
									</td>
								</tr>
							</tbody>
						</table>
						<hr />
					</div>
					<div
						className="form-group col-md-12"
						style={{ marginBottom: "0.3rem" }}
					>
						<label className="label form-control-label">
							notas:
						</label>
						<textarea
							className="text-input form-control"
							id="notas"
							name="notas"
							rows={4}
							value={form.notas}
							onChange={(e) => handleInputChange(e.value, "notas")}
						/>
						{errors.notas && (
							<small className="help-blockParams form-Text">
								{errors.notas[0]}
							</small>
						)}
					</div>
					<div className="page-footer text-right">
						<button
							type="button"
							onClick={cancel}
							className="btn btn-plain"
						>
							Volver
						</button>
						{!isEdit && (
							<>
								{saving ? (
									<Loader type="small" />
								) : (
									<button
										type="button"
										onClick={() =>
											guardarYNuevaCotizacion()
										}
										className="btn btn-outline-primary mr-2"
									>
										Guardar y crear nueva
									</button>
								)}
							</>
						)}
						{!isEdit && (
							<>
								{saving ? (
									<Loader type="small" />
								) : (
									<button
										type="button"
										onClick={() => guardarEImprimir()}
										className="btn btn-outline-primary mr-2"
									>
										Guardar e imprimir
									</button>
								)}
							</>
						)}
						{isEdit && (
							<>
								{saving ? (
									<Loader type="small" />
								) : (
									<button
										type="button"
										onClick={() => toggleCancelModal()}
										className="btn btn-outline-danger mr-2"
									>
										Cancelar Cotizacion
									</button>
								)}
							</>
						)}
						{saving ? (
							<Loader type="small" />
						) : (
							<button
								type="button"
								onClick={() => guardarYSalir()}
								className="btn btn-primary"
							>
								Guardar
							</button>
						)}
					</div>
					<Modal
						title="PIN de seguridad"
						show={showPINModal}
						callback={() => checkPINSeguridad()}
						acceptText="Aceptar"
						toggle={() => setShowPINModal((state) => !state)}
						size="419px"
					>
						<div className="form-group">
							<label>PIN de seguridad</label>
							<input
								type="password"
								className="form-control"
								id="PIN"
								name="pin_seguridad"
								value={pinSeguridad}
								onChange={(e) =>
									setPinSeguridad(e.target.value)
								}
							/>
							<p
								className="font-weight-bold"
								style={{
									color: "gray",
									fontStyle: "italic",
									lineHeight: "13px",
									fontSize: "0.7rem",
								}}
							>
								El cliente no cuenta con credito suficiente.
								Para continuar ingrese PIN de seguridad
							</p>
						</div>
					</Modal>
				</div>
			</form>
			<Modal
				show={showCreateContactoModal}
				blank={true}
				toggle={toggleCreateContactoModal}
				size="lg"
			>
				<Create toggle={toggleCreateContactoModal} fromFacturacion />
			</Modal>
			<Modal
				show={showEditContactoModal}
				blank={true}
				toggle={toggleEditContactoModal}
				size="lg"
			>
				<Edit
					toggle={toggleEditContactoModal}
					contacto={contactoInfo}
					fromFacturacion
				/>
			</Modal>
			<Modal
				title="Cancelar Cotizacion"
				show={showCancel}
				callback={() => cancelCotizacion(cotizacionActual.id)}
				acceptText="Aceptar"
				toggle={toggleCancelModal}
			>
				¿Estás seguro de que deseas cancelar esta cotización? (Esta
				acción no se puede deshacer).
			</Modal>
		</>
	);
};

export default Form;
