import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import FormDatosBancarios from "./FormDatosBancarios";

class Create extends Component {
    state = {
        errors: {},
        sucursal: {},
    };
    createSucursal = async (event) => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl("sucursales"), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.getSucursales();
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { errors, sucursal } = this.state;
        const { toggle, sucursales } = this.props;
        return (
            <form method='post' onSubmit={this.createSucursal}>
                <div className='popup__header'>Nueva Sucursal</div>
                <div className='popup__body'>
                    <Form
                        sucursal={sucursal}
                        sucursales={sucursales}
                        errors={errors}
                    />

                    <FormDatosBancarios sucursal={sucursal} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Crear
                    </button>
                </div>
            </form>
        );
    }
}

export default Create;
