import { useEffect, useState } from "react";

const getSavedValue = (key, initialValue) => {
	let savedValue = null;
	try {
		savedValue = JSON.parse(localStorage.getItem(key))
	} catch(e) {
		savedValue = localStorage.getItem(key);
	}

	if (savedValue) return savedValue;

	if (savedValue instanceof Function) return savedValue();
	return initialValue;
};

const useLocalStorage = (key, initialValue) => {
	const [value, setValue] = useState(() => {
		return getSavedValue(key, initialValue);
	});

	useEffect(() => {
        if (typeof value !== 'undefined') {
            localStorage.setItem(key, JSON.stringify(value));
        }
	}, [key, value]);

	return [value, setValue];
};

export default useLocalStorage;
