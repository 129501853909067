import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const FileLoader = ({
	multiple = false,
	name,
	archivos = [],
	loaderClass = "",
	previewClass = "",
	text="Click para subir archivo (Max: 2MB)",
	className="",
	accept="application/pdf",
	ref = false,
	deleteImageRequest = () => {},
	id = "fileInput"
}) => {
	const [files, setFiles] = useState(archivos || []);
	const [fileURLs, setFileURLs] = useState(archivos.length > 0 ? archivos.map(a => ({
        url: a.url,
        nombre: a.nombre ?? a.name,
        file: a
    })) : []);
	const inputField = useRef(null);
    const [showField, setShowField] = useState(true);

	const onFileChange = (e) => {
		if(ref) {
			setFiles((files) => [...files, ...ref.current.files]);
			return;
		}
        console.log(inputField.current.files);
		setFiles((files) => [...files, ...inputField.current.files]);
	};

	const removeFile = (file) => {
        setShowField(true);
		if (typeof file == "string") {
			setFiles((files) => files.filter((img) => (img.name !== file && img.nombre !== file)));
			return;
		}
		setFiles((files) =>
			files.filter((img) => img.lastModified !== file.lastModified)
		);
	};

	useEffect(() => {
		const newFileUrls = [];
		files.forEach((file) => {
			if (typeof file == "object") {
				newFileUrls.push({
                    url: file.url,
                    nombre: file.nombre ?? file.name,
                    file: file
                });
				return;
			}

			newFileUrls.push({ url: URL.createObjectURL(file), file, nombre: file.name });
		});
		setFileURLs(newFileUrls);
	}, [files]);

	useEffect(() => {
		setFiles(archivos);
        if (archivos.length > 0) {
            setShowField(false);
        }
	}, [archivos]);

	return (
		<div className={className}>
			{fileURLs.map((filesrc, i) => (
				<div className='col-md-12 file-loader-preview' key={filesrc.name || filesrc.nombre}>
                       {filesrc.url && (
                           <input
                               type="hidden"
                               name={name}
                               value={filesrc.url}
                           />
                       )}
                           <a href={filesrc.url} target="_blank">{filesrc.nombre}</a>
						<button
							type="button"
                            className="m-t-5"
							onClick={() => {removeFile(filesrc.name || filesrc.nombre); deleteImageRequest(filesrc)}}
						>
							<i className="fa fa-times"></i>
						</button>
					</div>
			))}
            {showField && (
                <>
                    <label
                        htmlFor={id}
                        className={`custom-file-loader ${loaderClass} ${files.length > 0 && !multiple ? 'd-none' : 'd-flex'}`}
                    >
                        <i
                            className="zmdi zmdi-upload"
                            style={{ fontSize: "2rem" }}
                        ></i>
                        <span>{text}</span>
                        <input
                            id={id}
                            type="file"
                            ref={ref ? ref : inputField}
                            name={name}
                            multiple={multiple}
                            accept={accept}
                            onChange={() => onFileChange()}
                            hidden
                        />
                    </label>
                </>
            )}
			{/* {!multiple && files.length > 0 && (
				<><input type="hidden" name={name} value={inputField?.current?.files[0]} /> </>
			)} */}
		</div>
	);
};

FileLoader.propTypes = {
	multiple: PropTypes.bool,
	name: PropTypes.string,
	archivos: PropTypes.array,
	loaderClass: PropTypes.string,
	previewClass: PropTypes.string,
	deleteImage: PropTypes.func,
	text: PropTypes.string,
	className: PropTypes.string,
	accept: PropTypes.string,
	deleteImageRequest: PropTypes.func
};

export default FileLoader;
