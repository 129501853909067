import React, { useState } from "react";

import Select from "react-select";
import SelectAsync from "react-select/async";

import { bancos } from "../../utils/helpers";
import { apiRequest } from "../../hooks/apiRequest";

export const OPCIONES_VENCIMIENTO = [
	{ value: "todos", label: "Todos" },
	{ value: "no_vencidas", label: "No Vencidas" },
	{ value: "7-", label: "Vencidas 7 días o menos" },
	{ value: "8_15", label: "Vencidas 8 a 15 días" },
	{ value: "16_30", label: "Vencidas 16 días a 30 días" },
	{ value: "31-45", label: "Vencidas 31 días a 45" },
	{ value: "46+", label: "Vencidas mas de 46 días" },
];

export const TarjetaInfo = () => {
	return (
		<>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
                    menuPosition='fixed'
                    menuPlacement='auto'
					name="metodo_pago[banco]"
				/>
			</div>
			<div className="form-group">
				<label>Terminal de tarjeta</label>
				<input
					type="text"
					name="metodo_pago[terminal_tarjeta]"
					// defaultValue={detalleMetodoPago?.terminal_tarjeta}
					className="form-control"
				/>
			</div>
			<div className="form-group">
				<label>Tipo</label>
				<select className="form-control" name="metodo_pago[tipo]">
					<option value="visa">Visa</option>
					<option value="mastercard">Mastercard</option>
					<option value="otro">Otro</option>
				</select>
			</div>
			<div className="form-group">
				<label>Numero de aprobación</label>
				<input
					type="text"
					className="form-control"
					name="metodo_pago[num_aprobacion]"
					// defaultValue={detalleMetodoPago?.num_aprobacion}
				/>
			</div>
		</>
	);
};

export const ChequeInfo = () => {
    const [numero, setNumero] = useState('');
    const [seguirSecuencia, setSeguirSecuencia] = useState(true);

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };

    const getNumeracionSiguiente = async (id) => {
        const response = await apiRequest(null, 'numeracion-documentos/siguiente/' + id, 'get');
        if (response.data) {
            setNumero(response.data.tipo ? response.data.tipo +  response.data.numero_siguiente : '');
        }
    }
	return (
		<>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
                    menuPosition='fixed'
                    menuPlacement='auto'
					name="metodo_pago[banco]"
				/>
			</div>
            <div className='form-group'>
                <label className='label form-control-label'>
                    Número de cheque:
                </label>
                <div className="row">
                    <div className="col-md-5 d-flex">
                        <input type="checkbox" className="m-r-15" onChange={e => setSeguirSecuencia(!seguirSecuencia)} checked={seguirSecuencia} title="Seguir Secuencia" />
                        <div style={{flex: 1}}>
                            <SelectAsync
                                name="metodo_pago[numeracion_id]"
                                cacheOptions
                                defaultOptions
                                isDisabled={!seguirSecuencia}
                                menuPosition='fixed'
                                menuPlacement='auto'
                                style={{width: '100%'}}
                                onChange={ e => {
                                    getNumeracionSiguiente(e.value);
                                }}
                                loadOptions={(e) => getOptions("numeracion-documentos?buscar=cheque", 'prefijo')}
                            />
                        </div>
                    </div>
                    <div className="col-md-7">
                    <input
                        type='text'
                        name="metodo_pago[num_cheque]"
                        required
                        readOnly={seguirSecuencia}
                        className='text-input form-control'
                        defaultValue={numero}
                    />
                    </div>
                </div>
            </div>
			{/* <div className="form-group">
				<label>Numero de cheque</label>
				<input
					type="text"
					name="metodo_pago[num_cheque]"
					className="form-control"
					// defaultValue={detalleMetodoPago?.num_cheque}
				/>
			</div> */}
			{/* <div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
					name="metodo_pago[banco]"
				/>
			</div> */}
		</>
	);
};

export const TransferenciaInfo = () => {
	return (
		<>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
                    menuPosition='fixed'
                    menuPlacement='auto'
					name="metodo_pago[banco]"
				/>
			</div>
			<div className="form-group">
				<label>Numero de Transferencia</label>
				<input
					type="text"
					name="metodo_pago[num_transferencia]"
					className="form-control"
				/>
			</div>
		</>
	);
};

export const DepositoInfo = () => {
	return (
		<>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
                    menuPosition='fixed'
                    menuPlacement='auto'
					name="metodo_pago[banco]"
				/>
			</div>
			<div className="form-group">
				<label>Numero de Deposito</label>
				<input
					type="text"
					name="metodo_pago[num_deposito]"
					className="form-control"
				/>
			</div>
		</>
	);
};

export const OtroInfo = () => {
	return (
		<>
			<div className="form-group">
				<label>Numero de transacción</label>
				<input
					type="text"
					name="metodo_pago[num_transaccion]"
					className="form-control"
				/>
			</div>
		</>
	);
};
