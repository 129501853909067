import React from 'react';
import Select from 'react-select';

export default function FormPeriodos({item, errors, modalidades, niveles, salidasOptativas, ciclos}) {
    return (<>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Nombre:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='text'
                    className='text-input form-control'
                    id='nombre'
                    name='nombre'
                    required
                    defaultValue={item.nombre}
                />
                {errors.nombre && (
                    <small className='help-blockParams form-Text'>
                        {errors.nombre[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Código/Alias:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='text'
                    className='text-input form-control'
                    id='codigo'
                    required
                    name='codigo'
                    defaultValue={item.codigo}
                />
                {errors.codigo && (
                    <small className='help-blockParams form-Text'>
                        {errors.codigo[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Edad Desde:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='number'
                    step='1'
                    className='text-input form-control'
                    id='edad_desde'
                    name='edad_desde'
                    defaultValue={item.edadDesde}
                />
                {errors.edad_desde && (
                    <small className='help-blockParams form-Text'>
                        {errors.edad_desde[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Edad Hasta:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='numer'
                    step='1'
                    className='text-input form-control'
                    id='edad_hasta'
                    name='edad_hasta'
                    defaultValue={item.edadHasta}
                />
                {errors.edad_hasta && (
                    <small className='help-blockParams form-Text'>
                        {errors.edad_hasta[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Modalidad:
                </label>
            </div>
            <div className='col col-md-7'>
                <Select
                    key="modalidad"
                    name='modalidad_id'
                    isClearable
                    required
                    options={modalidades && modalidades.map(p => ({
                        label: p.nombre,
                        value: p.id,
                    }))}
                    defaultValue={item.modalidad && {
                        label: item.modalidad?.nombre,
                        value: item.modalidad?.id
                    }}
                />
                {errors.modalidad_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.modalidad_id[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Salida Optativa:
                </label>
            </div>
            <div className='col col-md-7'>
                <Select
                    key="salida_optativa"
                    name='salida_optativa_id'
                    isClearable
                    options={salidasOptativas && salidasOptativas.map(p => ({
                        label: p.nombre,
                        value: p.id,
                    }))}
                    defaultValue={item.salidaOptativa && {
                        label: item.salidaOptativa?.nombre,
                        value: item.salidaOptativa?.id
                    }}
                />
                {errors.salida_optativa_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.salida_optativa_id[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Nivel:
                </label>
            </div>
            <div className='col col-md-7'>
                <Select
                    key="nivel"
                    name='nivel_id'
                    isClearable
                    required
                    options={niveles && niveles.map(p => ({
                        label: p.nombre,
                        value: p.id,
                    }))}
                    defaultValue={item.nivel && {
                        label: item.nivel?.nombre,
                        value: item.nivel?.id
                    }}
                />
                {errors.nivel_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.nivel_id[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Ciclo:
                </label>
            </div>
            <div className='col col-md-7'>
                <Select
                    isMulti
                    key="ciclos"
                    name='ciclos[]'
                    isClearable
                    options={ciclos && ciclos.map(p => ({
                        label: p.nombre,
                        value: p.id,
                    }))}
                    defaultValue={item.ciclos && item.ciclos.map(ciclo => ({
                        label: ciclo?.nombre,
                        value: ciclo?.id
                    }))}
                />
                {errors.ciclos && (
                    <small className='help-blockParams form-Text'>
                        {errors.ciclos[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Estado:
                </label>
            </div>
            <div className='col col-md-7'>
                <select className='form-control' name="estado" defaultValue={item.estado}>
                    <option value="activo">Activo</option>
                    <option value="pendiente">Pendiente</option>
                    <option value="inactivo">Inactivo</option>
                    <option value="cerrado">Cerrado</option>
                </select>
                {errors.estado && (
                    <small className='help-blockParams form-Text'>
                        {errors.estado[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='row form-group'>
            <div className='col col-md-5'>
                <label className='label form-control-label'>
                    Orden:
                </label>
            </div>
            <div className='col col-md-7'>
                <input
                    type='text'
                    className='text-input form-control'
                    id='orden'
                    required
                    name='orden'
                    defaultValue={item.orden}
                />
                {errors.orden && (
                    <small className='help-blockParams form-Text'>
                        {errors.orden[0]}
                    </small>
                )}
            </div>
        </div>
    </>)
}
