import React, { Component } from "react";

import { withRequest } from "../../../utils/request";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../../general/Loader";
import { auth } from "../../../utils/auth";
import { Link } from "react-router-dom";
import { getLink } from "../../../utils/helpers";
import { debounce } from "lodash";
import ReactSelect from "react-select";

class Usuarios extends Component {
    state = {
        usuarios: [],
        sucursales: [],
        usuario: {},
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        loading: true,
    };
    componentDidMount() {
        this.getUsers();
        this.getSucursales();
    }
    getUsers = async (url = null) => {
        url = url ? url : "usuarios";
        const usuarios = await this.props.request(url);

        this.setState({
            loading: true,
        });

        if (usuarios.data) {
            this.setState({
                usuarios: auth.is("admin") ? usuarios.data?.filter(usuario => usuario.sucursalActual === auth.getUser().sucursalActual) : usuarios.data,
                links: usuarios.links,
                meta: usuarios.meta,
            });
        }

        this.setState({
            loading: false,
        });
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            usuario: {},
        });
    };
    toggleEditModal = (usuario) => {
        this.setState({
            showEdit: !this.state.showEdit,
            usuario: usuario,
        });
    };

    getSucursales = async (url = null) => {
		url = url ? url : "sucursales";
		const sucursales = await this.props.request(url);

		if (sucursales.data) {
			this.setState({
				sucursales: sucursales.data,
				loading: false,
			});
		}
	};

    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getUsers("usuarios?limit=15&" + query.join("&"));
    }, 500);

    render() {
        const { usuarios, loading, sucursales } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/configuracion/')}>&laquo; Volver a configuración</Link>
                    <h1 className='page-title row'>
                        <span className='col'>Usuarios</span>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Usuario
                        </button>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='search'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='grupo'>Estado</label>
                                    <ReactSelect
                                        name='estado'
                                        key='estado'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "estado"
                                            )
                                        }
                                        options={[
                                            {value: '', label: 'Todos'},
                                            {value: 'inactivo', label: 'Inactivos'},
                                            {value: 'activo', label: 'Activos'},
                                        ]}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='grupo'>Rol</label>
                                    <ReactSelect
                                        name='rol'
                                        key='rol'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "rol"
                                            )
                                        }
                                        options={[
                                            {value: '', label: 'Todos'},
                                            {value: 'rrhh', label: 'rrhh'},
                                            {value: 'admin', label: 'admin'},
                                            {value: 'editor', label: 'editor'},
                                            {value: 'digitador', label: 'digitador'},
                                            {value: 'compras', label: 'compras'},
                                            {value: 'gasto', label: 'gasto'},
                                            {value: 'nomina', label: 'nomina'},
                                            {value: 'solicitudes', label: 'solicitudes'},
                                            {value: 'vendedor', label: 'vendedor'},
                                            {value: 'vendedor-factura', label: 'vendedor-factura'},
                                            {value: 'vendedor-admin', label: 'vendedor-admin'},
                                            {value: 'vendedor-factura-orden_compra', label: 'vendedor-factura-orden_compra'},
                                            {value: 'edu.caja', label: 'edu.caja'},
                                            {value: 'edu.recepcion', label: 'edu.recepcion'},
                                            {value: 'super-admin', label: 'super-admin'},
                                        ]}
                                        />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='grupo'>Sucursal</label>
                                    <ReactSelect
                                        name='rol'
                                        key='rol'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "sucursal_id"
                                            )
                                        }
                                        options={sucursales.map((s) => {
                                          return {value: s.id, label: s.nombre};
                                        })}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading ? (
                                <Loader />
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Usuario</th>
                                                <th className='text-center'>
                                                    Activo
                                                </th>
                                                <th>Rol</th>
                                                <th>Sucursal</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usuarios
                                                ?.filter((usr) =>
                                                    auth.is("super-admin")
                                                        ? true
                                                        : usr.rol !==
                                                          "super-admin"
                                                )
                                                .map((usr) => {
                                                    return (
                                                        <tr
                                                            className='tr-shadow'
                                                            key={usr.id}>
                                                            <td>
                                                                {usr.nombre}{" "}
                                                                {usr.apellido}
                                                            </td>
                                                            <td>
                                                                {usr.usuario}
                                                            </td>
                                                            <td className='text-center'>
                                                                <span
                                                                    className={`status ${
                                                                        usr.estado
                                                                            ? "active"
                                                                            : ""
                                                                    }`}
                                                                />
                                                            </td>
                                                            <td>
                                                                <span className='role user'>
                                                                    {usr.rol}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {usr.sucursales.map(u => u.nombre).join(', ')}
                                                            </td>
                                                            <td>
                                                                <div className='table-data-feature'>
                                                                    <button
                                                                        className='item'
                                                                        data-toggle='tooltip'
                                                                        data-placement='top'
                                                                        title=''
                                                                        data-original-title='Edit'
                                                                        onClick={() => {
                                                                            this.toggleEditModal(
                                                                                usr
                                                                            );
                                                                        }}>
                                                                        <i className='zmdi zmdi-edit' />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {this.state.links && (
                                <Pagination
                                    links={this.state.links}
                                    meta={this.state.meta}
                                    getData={this.getUsers}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        usuario={this.state.usuario}
                        getUsers={this.getUsers}
                        sucursales={sucursales}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        usuario={this.state.usuario}
                        getUsers={this.getUsers}
                        sucursales={sucursales}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Usuarios);
