import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import queryString from "query-string";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";

class PrintIncentivos extends Component {
    state = {
        incentivos: [],
    };
    componentDidMount() {
        this.getIncentivos();
    }
    getIncentivos = async () => {
        const response = await this.props.request(
            "incentivos" + window.location.search
        );

        if (response.data) {
            this.setState({
                incentivos: response.data,
            });
            window.print();
        }
    };
    render() {
        const { incentivos } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const query = queryString.parse(window.location.search);
        const {estado, desde, hasta} = query;


        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        {estado === 'todos' && 'Todos los '}
                        Incentivos y Horas Extra
                        {estado === 'pendiente' && ' Pendientes'}
                        {estado === 'completado' && ' Completados'}
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-center'>
                        Desde: {dayjs(desde).format('DD MMM, YYYY')} - Hasta: {dayjs(hasta).format('DD MMM, YYYY')}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className="float-left text-left">
                            <p><strong># Incentivos:</strong> {incentivos.length}</p>
                        </div>
                        <div className='float-right'>
                            <p><strong>Total Incentivos:</strong> {formatCurrency(incentivos.reduce((p, d) => {
                                return p + parseFloat(d.monto);
                            }, 0))}</p>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ width: "25%" }}>Empleado</th>
                            <th style={{ width: "35%" }}>Detalle</th>
                            <th>Tipo</th>
                            <th>Monto</th>
                            <th className="text-right">Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                        {incentivos.map((incentivo) => {
                            return (
                                <tr key={incentivo.id}>
                                    <td>
                                        <strong>
                                            { incentivo.empleado }
                                        </strong>
                                        {incentivo.telefono && <div>
                                            Tel: {incentivo.telefono}
                                        </div>}
                                    </td>
                                    <td>
                                        <div className="incentivo__descripcion">
                                            {
                                                incentivo.descripcion
                                            }
                                        </div>
                                    </td>
                                    <td><span>{incentivo.frecuencia ? (incentivo.frecuencia === 'fijo' ? 'Fijo' : 'Único') : ''}</span></td>
                                    <td>
                                        <div><strong>{formatCurrency(incentivo.monto)}</strong></div>
                                    </td>
                                    <td  className="text-right">
                                        {incentivo.frecuencia === 'fijo' ? '∞' : dayjs(incentivo.fecha).format('DD MMM, YYYY')}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintIncentivos));
