import React, { useState, useEffect } from "react";
import { getTable } from "../../../utils/request";
import Select from "react-select";

export default function ProduccionMensual({ tipo }) {
    const [tipos, setTipos] = useState([]);

    useEffect(() => {
        let ignore = false;

        async function fetchTipos() {
            const data = await getTable("tipos");
            data.unshift({ nombre: "Todos", id: "-1" });
            if (!ignore) {
                setTipos(data);
            }
        }

        fetchTipos();
        return () => {
            ignore = true;
        };
    }, []);

    return (
        <div>
            <div className='form-group'>
                <label>Tipo Contrato:</label>
                <Select
                    name='contrato'
                    defaultValue={{
                        value: "formal",
                        label: "Formales",
                    }}
                    options={[
                        { value: "formal", label: "Formales" },
                        { value: "informal", label: "Informales" },
                    ]}></Select>
            </div>
            <div className='form-group' style={{ marginBottom: "95px" }}>
                <label>Tipo Empleados:</label>
                <Select
                    name='tipo_empleado'
                    defaultValue={{
                        value: "todos",
                        label: "Todos",
                    }}
                    options={tipos.map((tipo) => ({
                        value: tipo.id,
                        label: tipo.nombre,
                    }))}></Select>
            </div>
        </div>
    );
}
