import React, { useState } from "react";
import dayjs from "dayjs";
import Select from "react-select";
import SelectCreate from 'react-select/creatable';
import { auth } from "../../utils/auth";

export default function Form({empleados, errors, descuento, columnas}) {

    const [state, setState] = useState({
        fechaInicio: dayjs().format("YYYY-MM-DD"),
        intervalo: parseInt(descuento.frecuencia) === 15 ? 15 : 1,
        monto: descuento.monto ?? '',
        monto_cuotas: '',
        interes: 0,
        descripcion: descuento.descripcion ?? '',
        cuotas: descuento.cuotas ? descuento.cuotas.length : 1,
    });

    function updateField( field, value ) {
        setState({
            ...state,
            [field]: value,
        })
    }

    function changeMontoCuota( value ) {
        setState((prevState) => ({
            ...prevState,
            cuotas: prevState.cuotas * value > prevState.monto ? Math.ceil((state.monto / value)) : prevState.cuotas,
            monto_cuotas: value,
        }))
    }

    function changeCuotas( value ) {
        setState({
            ...state,
            cuotas: Math.round(value),
            monto_cuotas: (state.monto / value).toFixed(0),
        })
    }

    function changeInteres( value = 0 ) {
        setState({
            ...state,
            interes: parseFloat(value / 100),
        });
    }

    const {cuotas, intervalo, monto, fechaInicio, descripcion, monto_cuotas, interes} = state;

    let proximasCuotas = [];
    if (cuotas > 1 && monto > 0) {
        let time = intervalo === 1 ? 'month' : 'day';
        if (monto_cuotas) {
            for (let index = 0; index < cuotas; index++) {
                let total = parseFloat(monto_cuotas ? monto_cuotas : (monto / cuotas));
                proximasCuotas.push({
                    fecha: dayjs(fechaInicio).add(index * intervalo, time).format("YYYY-MM-DD"),
                    monto: Math.round((total + total * interes)),
                })
            }
            const modulo = (monto / monto_cuotas) % 1;
            if (monto_cuotas < Math.ceil((monto / cuotas))) {
                let total = monto - (monto_cuotas * (cuotas - 1));
                proximasCuotas[proximasCuotas.length - 1].monto = Math.round( total + (total * interes));
            } else if (modulo > 0) {
                let total = modulo * monto_cuotas;
                proximasCuotas[proximasCuotas.length - 1].monto = Math.round( total + (total * interes));
            }
        } else {
            for (let index = 0; index < cuotas; index++) {
                const total = parseFloat(monto / cuotas);
                proximasCuotas.push({
                    fecha: dayjs(fechaInicio).add(index * intervalo, time).format("YYYY-MM-DD"),
                    monto: Math.round((total + total * interes)),
                })
            }
        }
    }

    return (
        <div className='popup__body'>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Empleado:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <Select
                            name='empleado_id'
                            isClearable={true}
                            options={empleados
                                .map((empleado) => ({
                                    value: empleado.id,
                                    label: `${empleado.nombreCompleto}`,
                                }))}
                        />
                        {errors.empleado_id && (
                            <small className='help-blockParams form-Text'>
                                {errors.empleado_id[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Fecha Inicio:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='date'
                            name='fecha_inicio'
                            onChange={e => updateField('fechaInicio', e.target.value)}
                            value={fechaInicio}
                            className='form-control'
                        />
                        {errors.fecha_inicio && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha_inicio[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Monto:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='number'
                            name='monto'
                            step='0.01'
                            onChange={e => updateField('monto', e.target.value)}
                            value={monto}
                            required
                            className='text-input form-control'
                        />
                        {errors.monto && (
                            <small className='help-blockParams form-Text'>
                                {errors.monto[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Columna Nómina:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        {auth.is('super-admin') ? (
                            <SelectCreate
                                defaultValue={descuento.columna ? {label: descuento.columna?.nombre, value: descuento.columna?.id} : null}
                                options={columnas.map(c => ({value: c.id, label: c.nombre}))}
                                name="columna_nomina"
                            />
                        ) : (
                            <Select
                                defaultValue={descuento.columna ? {label: descuento.columna?.nombre, value: descuento.columna?.id} : null}
                                options={columnas.map(c => ({value: c.id, label: c.nombre}))}
                                name="columna_nomina"
                            />
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Descripción:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='text'
                            name='descripcion'
                            required
                            onChange={e => updateField('descripcion', e.target.value)}
                            value={descripcion}
                            className='form-control'
                        />
                        {errors.descripcion && (
                            <small className='help-blockParams form-Text'>
                                {errors.descripcion[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Cuotas:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='number'
                            name="cantidad_cuotas"
                            step='1'
                            onChange={e => changeCuotas(e.target.value)}
                            value={cuotas}
                            className='text-input form-control'
                        />
                        {errors.cantidad_cuotas && (
                            <small className='help-blockParams form-Text'>
                                {errors.cantidad_cuotas[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Monto Cuotas:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='number'
                            step='1'
                            onChange={e => changeMontoCuota(e.target.value)}
                            value={monto_cuotas}
                            className='text-input form-control'
                        />
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            % Interés:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='number'
                            step='any'
                            onChange={e => changeInteres(e.target.value)}
                            className='text-input form-control'
                        />
                    </div>
                </div>
                {cuotas > 1 &&
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Intervalo:
                            </label>
                        </div>
                            <div className='col col-md-8'>
                                <label htmlFor="intervalo_mes" className="inline m-r-15">
                                    <input
                                        type='radio'
                                        name='frecuencia'
                                        id="intervalo_mes"
                                        checked={intervalo === 1}
                                        onChange={e => updateField('intervalo', 1)}
                                        value='1'
                                    />
                                    <span className="m-l-5">Mensual</span>
                                </label>
                                {'    '}
                                <label htmlFor="intervalo_quincenal" className="inline m-r-15">
                                    <input
                                        type='radio'
                                        name='frecuencia'
                                        id="intervalo_quincenal"
                                        checked={intervalo === 15}
                                        onChange={e => updateField('intervalo', 15)}
                                        value='15'
                                        />
                                    <span className="m-l-5">Quincenal</span>
                                </label>
                                {'    '}
                                <label htmlFor="intervalo_semanal" className="inline">
                                    <input
                                        type='radio'
                                        name='frecuencia'
                                        id="intervalo_semanal"
                                        checked={intervalo === 7}
                                        onChange={e => updateField('intervalo', 7)}
                                        value='7'
                                    />
                                    <span className="m-l-5">Semanal</span>
                                </label>
                            </div>
                    </div>
                }
                <div className="row form-group">
                    <div className="col-md-12">
                        <table className="table">
                            <tbody>
                                {proximasCuotas.length < 200 && proximasCuotas.map((cuota, index) => {
                                    return <tr key={index}>
                                        <td>
                                            <label>Monto</label>
                                            <input type="text" name={`cuotas[${index}][monto]`} className="text-input form-control" readOnly value={cuota.monto} />
                                        </td>
                                        <td>
                                            <label>Fecha</label>
                                            <input type="date" name={`cuotas[${index}][fecha]`} className="text-input form-control" readOnly value={cuota.fecha}/>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
        </div>
    );
}
