/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { apiRequest } from "../../../hooks/apiRequest";
import useFilters from "../../../hooks/useFilters";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { apiUrl } from "../../../utils/apiUrl";
import { formatCurrency } from "../../../utils/helpers";
import FiltersContainer from "../../general/FiltersContainer";
import Loader from "../../general/Loader";
import PageHeader from "../../general/PageHeader";
import Pagination from "../../general/Pagination";
import { METODOS_PAGO } from "../../../utils/types";


const HistorialPagosFacturas = () => {
	const { id } = useParams();
	// const formRef = useRef();
	// const formInfoPagoRef = useRef();
	const [factura] = useLocalStorage("factura", "");
	const { handleFilterChange, filtersQuery } = useFilters({
		buscar: "",
		tipo_pago: "",
		vendedor: "",
		categoria: "",
		desde: null,
		hasta: dayjs().format("YYYY-MM-DD"),
		vencimiento_desde: "",
		vencimiento_hasta: "",
	});

	const [pagos, setPagos] = useState({});
	const [loading, setLoading] = useState(false);

	const getTransactions = async (url = null) => {
		setLoading(true);
		if (url && url.includes(apiUrl(""))) {
			url = url.split(apiUrl(""))[1];
		}
		const transactions = await apiRequest(
			null,
			`${url}&factura_id=${id}`,
			"get"
		);

		setPagos(transactions);
		setLoading(false);
	};

	useEffect(() => {
		if (filtersQuery === "") return;
		getTransactions(`historial-pagos-facturas${filtersQuery}`);
	}, [filtersQuery]);

	return (
		<>
			<PageHeader
				title={<Titulo factura={factura} />}
				hasButton={false}
			></PageHeader>
			<FiltersContainer>
				<div className="col">
					<label>Buscar</label>
					<input
						onChange={(e) =>
							handleFilterChange("buscar", e.target.value)
						}
						type="search"
						placeholder="Buscar..."
						className="form-control"
						name="buscar"
					/>
				</div>
				<div className="col-md-2">
					<label>Tipo de pago</label>
					<Select
						id="tipo_pago"
						options={[{value: "", label: "Todos"}, ...METODOS_PAGO]}
						name="tipo_pago"
						placeholder="Todos"
						onChange={(e) =>
							handleFilterChange("tipo_pago", e.value)
						}
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
				<div className="col-md-2">
					<label>Desde</label>
					<input
						className="form-control"
						type="date"
						name="desde"
						onChange={(e) =>
							handleFilterChange("desde", e.target.value)
						}
					/>
				</div>
				<div className="col-md-2">
					<label htmlFor="estado">Hasta</label>
					<input
						className="form-control"
						type="date"
						name="hasta"
						defaultValue={dayjs().format("YYYY-MM-DD")}
						onChange={(e) =>
							handleFilterChange("hasta", e.target.value)
						}
					/>
				</div>
			</FiltersContainer>
			<div className="row">
				<div className="col-md-12">
					<div>
						{loading && <Loader />}
						{!pagos.data?.length ? (
							<div>
								{!loading && (
									<div>Todavía no hay transacciones.</div>
								)}
							</div>
						) : (
							!loading && (
								<div className="table-data">
									<table className="table table-data2">
										<thead>
											<tr>
												<th>No.</th>
												<th>Fecha Procesado</th>
												<th>Procesado por</th>
												<th>Monto</th>
												<th>Tipo de pago</th>
												<th>Detalles</th>
											</tr>
										</thead>
										<tbody>
											{pagos.data.map(
												(transaccion, index) => (
													<tr
														className="tr-shadow"
														key={transaccion.id}
													>
														<td>
															{transaccion.id}
														</td>
														<td>
															{dayjs(
																transaccion.fecha
															).format(
																"DD/MM/YYYY hh:mm a"
															)}
														</td>
														<td>
															{transaccion.usuario
																? `${transaccion.usuario?.nombre} ${transaccion.usuario?.apellido}`
																: "N/A"}
														</td>
														<td>
															{formatCurrency(
																transaccion.monto,
																"$"
															)}
														</td>
														<td className="text-capitalize">
															{
																transaccion.tipo_pago
															}
														</td>
														<td className="text-capitalize">
															{/* Aqui voy a recorrer el detalle de transaccion y poner la determinada info */}
															{transaccion.detalle_transaccion ? (
																<>
																	{Object.keys(
																		transaccion.detalle_transaccion
																	).map(
																		(
																			detalle,
																			i
																		) => (
																			<div
																				key={
																					i
																				}
																			>
																				<span className="fw-600">
																					{
																						detalle.split("_").join(" ")
																					}
																					:{" "}
																				</span>{" "}
																				{
																					transaccion
																						.detalle_transaccion[
																						detalle
																					]
																				}
																			</div>
																		)
																	)}
																</>
															) : (
																<></>
															)}
														</td>
													</tr>
												)
											)}
										</tbody>
									</table>
									{pagos.links && (
										<Pagination
											links={{
												next: pagos.next_page_url,
												prev: pagos.prev_page_url,
											}}
											meta={pagos}
											getData={getTransactions}
										/>
									)}
								</div>
							)
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const Titulo = ({ factura }) => {
	return (
		<span style={{ fontSize: "1.6rem" }}>
			Historial de pagos de factura{" "}
			<span style={{ fontWeight: "600", fontSize: "1.4rem" }}>
				{factura}
			</span>
		</span>
	);
};

export default HistorialPagosFacturas;
