import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { withRequest } from "../../utils/request";
import { apiUrl } from "../../utils/apiUrl";
import { formatDate, getLink } from "../../utils/helpers";
import Modal from "../general/Modal";
import Loader from "../general/Loader";

class Create extends Component {
    state = {
        errors: {},
        empleado: {},
        tipos: [],
        codigo: {},
        showSearch: false,
        search: "",
        searchResults: null,
        searchSuccess: null,
        searching: false,
    };
    componentDidMount() {
        this.getTipos();
        this.getCodigo();
    }
    createEmpleado = async (event) => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl("empleados"), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.history.push(getLink("/empleados"));
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    getTipos = async (url = null) => {
        url = url ? url : "tipos";
        const tipos = await this.props.request(url);

        if (tipos.data) {
            this.setState({
                tipos: tipos.data,
            });
        }
    };
    getCodigo = async (url = null) => {
        url = url ? url : "empleados/codigo";
        const codigo = await this.props.request(url);

        if (codigo.data) {
            this.setState({
                codigo: codigo.data,
            });
        }
    };
    toggleSearchModal = () => {
        this.setState({
            showSearch: !this.state.showSearch,
        });
    };
    buscarEmpleado = async (e) => {
        e.preventDefault();

        this.setState({
            searching: true,
        });

        const { search } = this.state;
        const url = `https://app.migente.com.do/api/getpadronexterno/?cedula=${search}&user=felipe_api&pswd=F3l1pe`;
        const res = await fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        });
        res.json().then((res) => {
            this.setState({
                searchResults: res.Persona,
                searchSuccess: res.Existe,
                searching: false,
            });
        });
    };
    insertarEmpleado = () => {
        const search = this.state.searchResults;

        if (!search) {
            return;
        }

        this.setState({
            empleado: {
                nombre: search.Nombres,
                apellido:
                    search.Apellido1 +
                    " " +
                    (search.Apellido2 ? search.Apellido2 : ""),
                cedula: search.Cedula,
                fechaNacimiento: formatDate(Date.parse(search.FechaNacimiento)),
                direccion: search.Municipio + ", " + search.Provincia,
                foto: search.Imagen,
                fotoCed: search.Imagen,
            },
        });
        this.toggleSearchModal();
    };
    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    render() {
        const {
            errors,
            empleado,
            codigo,
            tipos,
            searchResults,
            searching,
            searchSuccess,
        } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <h1 className='page-title'>
                        Añadir Empleado
                        <button
                            type='button'
                            onClick={this.toggleSearchModal}
                            className='btn btn-primary float-right'>
                            <i className='fas fa-search'></i>
                        </button>
                    </h1>
                </div>
                <div className='col-md-8 offset-2'>
                    <form
                        method='post'
                        onSubmit={this.createEmpleado}
                        encType='multipart/form-data'>
                        <Form
                            empleado={empleado}
                            errors={errors}
                            codigo={codigo}
                            tipos={tipos}
                        />
                        <div className='page-footer text-right'>
                            <Link
                                to={getLink("/empleados")}
                                className='btn btn-plain'>
                                Cancelar
                            </Link>
                            <button type='submit' className='btn btn-primary'>
                                Crear
                            </button>
                        </div>
                    </form>
                </div>
                <Modal
                    show={this.state.showSearch}
                    blank={true}
                    toggle={this.toggleSearchModal}>
                    <div className='popup__header'>Buscar Empleado</div>
                    <div>
                        <form onSubmit={this.buscarEmpleado}>
                            <div className='search-cedula'>
                                <div className='row'>
                                    <div className='col'>
                                        <input
                                            name='search'
                                            onChange={this.onInputChange}
                                            type='text'
                                            className='form-control search'
                                        />
                                    </div>
                                    <div className='col-md-2'>
                                        <button className='btn btn-primary'>
                                            <i className='fas fa-search'></i>
                                        </button>
                                    </div>
                                </div>
                                {searching && <Loader />}
                                {searchResults && searchSuccess && (
                                    <div>
                                        <table className='table'>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <img
                                                        alt={
                                                            searchResults.Nombres
                                                        }
                                                        src={`data:image/jpeg;base64,${searchResults.Imagen}`}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Nombre:</td>
                                                <td>{searchResults.Nombres}</td>
                                            </tr>
                                            <tr>
                                                <td>Apellido:</td>
                                                <td>
                                                    {searchResults.Apellido1}{" "}
                                                    {searchResults.Apellido2}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Fecha Nacimiento:</td>
                                                <td>
                                                    {
                                                        searchResults.FechaNacimiento
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cédula:</td>
                                                <td>{searchResults.Cedula}</td>
                                            </tr>
                                            <tr>
                                                <td>Dirección:</td>
                                                <td>
                                                    {searchResults.Municipio},{" "}
                                                    {searchResults.Provincia}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                )}
                                {searchSuccess === false && (
                                    <div>
                                        No hay resultados para esta cédula.
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                    <div className='popup__footer'>
                        <button
                            type='button'
                            onClick={this.toggleSearchModal}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        <button
                            type='submit'
                            onClick={this.insertarEmpleado}
                            className='btn btn-primary'>
                            Insertar
                        </button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(Create));
