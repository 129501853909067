import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import SelectCreate from "react-select/creatable";
import PageHeader from "../../../general/PageHeader";
import { clientLabel } from "../../Components/utils";

const Detalles = ({
	form,
	contactoInfo,
	dropdownOptions,
	toggleCreateContactoModal,
	toggleEditContactoModal,
	handleInputChange,
	getComprobanteData,
	aplicarLimiteFecha,
	handleDetalleChange,
	isComprobanteFiscal,
	isEdit,
    changeContactInfo,
    contactoId,
    comprobante,
    plazo,
    changePlazo,
    errors,
    setContactoId,
}) => {
	return (
		<>
            {plazo && (
                <input
                    type="hidden"
                    name="tipo"
                    value={
                        plazo?.dias > 0
                            ? "credito"
                            : "contado"
                    }
                />
            )}
			<div className="page-title">
				<div className="d-flex col-2 form-group facturas-active-switch">
					<input
						type="hidden"
						name="recurrencia[id]"
						value={form.recurrencia_id}
					/>
					<div className="col col-md-3 pr-1 pt-2 text-right mr-2">
						<label className="label form-control-label">
							Activo
						</label>
					</div>
					<div className="col-md-9 pl-0">
						<label className="switch switch-3d switch-success mr-3">
							<input
								type="checkbox"
								className="switch-input"
								name="recurrencia[status]"
								value="activo"
								checked={form.activo}
								onChange={() =>
									handleInputChange(!form.activo, "activo")
								}
							/>
							<span className="switch-label" />
							<span className="switch-handle" />
						</label>
					</div>
				</div>
				<div
					className="w-auto px-2 d-inline-block"
					style={{
						borderLeft: "1px solid #e6e6e6",
						borderRight: "1px solid #e6e6e6",
					}}
				>
					<h5>Frecuencia de pago</h5>
					<div className="form-inline mt-3">
						<div className="radio">
							<label className="mr-3">
								<input
									className="mr-1"
									type="radio"
									name="recurrencia[frecuencia_pago]"
									value="mensual"
									checked={form.frecuencia_pago === "mensual"}
									onChange={() =>
										handleInputChange(
											"mensual",
											"frecuencia_pago"
										)
									}
								/>
								Mensual
							</label>
						</div>
						<div className="radio">
							<label>
								<input
									className="mr-1"
									type="radio"
									name="recurrencia[frecuencia_pago]"
									value="anual"
									checked={form.frecuencia_pago === "anual"}
									onChange={() =>
										handleInputChange(
											"anual",
											"frecuencia_pago"
										)
									}
								/>
								Anual
							</label>
						</div>
					</div>
				</div>
				{form.frecuencia_pago === "mensual" ? (
					<>
						<div
							className="col-12 col-md-2 px-3 d-inline-block"
							style={{ borderRight: "1px solid #e6e6e6" }}
						>
							<h5>Opciones de Recurrencia</h5>
							<span style={{ fontSize: "1rem" }}>
								Cada día:{" "}
								<input
									type="number"
									max="31"
									min="1"
									name="recurrencia[dia_generacion]"
									className="form-control d-inline-block"
									style={{
										maxWidth: "60px",
										height: "30px",
									}}
									value={form.dia_generacion}
									onChange={(e) =>
										handleInputChange(
											e.target.value,
											"dia_generacion"
										)
									}
								/>{" "}
								del mes
							</span>
						</div>
						<div className="col-12 col-md-7 d-inline-block">
							<h5>Inicio y Fin</h5>
							<span style={{ fontSize: "1rem" }}>
								Fecha de inicio:{" "}
								<input
									type="date"
									name="recurrencia[fecha_inicio]"
									min={`${new Date()
										.toISOString()
										.slice(0, 10)}`}
									className="form-control d-inline-block"
									onChange={(e) =>
										handleInputChange(
											e.target.value,
											"fecha"
										)
									}
									value={form.fecha}
									style={{
										maxWidth: "150px",
										height: "30px",
									}}
								/>
							</span>
							<div className="d-inline-block ml-4">
								<div className="form-inline mt-3">
									<div className="radio">
										<label className="mr-3">
											<input
												className="mr-1"
												type="radio"
												name="recurrencia[termina_en]"
												value="nunca"
												checked={
													form.termina_en === "nunca"
												}
												onChange={() =>
													handleInputChange(
														"nunca",
														"termina_en"
													)
												}
											/>
											Nunca Termina
										</label>
									</div>
									<div className="radio">
										<label>
											<input
												className="mr-1"
												type="radio"
												name="recurrencia[termina_en]"
												value="meses"
												checked={
													form.termina_en === "meses"
												}
												onChange={() =>
													handleInputChange(
														"meses",
														"termina_en"
													)
												}
											/>
											Termina en{" "}
											<input
												type="number"
												min="0"
												name="recurrencia[ocurrencias]"
												className="form-control d-inline-block mx-1"
												style={{
													maxWidth: "60px",
													height: "30px",
												}}
												value={form.ocurrencias}
												onChange={(e) =>
													handleInputChange(
														e.target.value,
														"ocurrencias"
													)
												}
												readOnly={
													form.termina_en !== "meses"
												}
											/>{" "}
											ocurrencias
										</label>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div
							className="w-auto px-3 d-inline-block"
							style={{ borderRight: "1px solid #e6e6e6" }}
						>
							<h5>Opciones de Recurrencia</h5>
							<span style={{ fontSize: "1rem" }}>
								Cada:{" "}
								<input
									type="number"
									max="31"
									min="0"
									name="recurrencia[anio_generacion]"
									className="form-control d-inline-block"
									style={{
										maxWidth: "60px",
										height: "30px",
									}}
									value={form.anio_generacion}
									onChange={(e) =>
										handleInputChange(
											e.target.value,
											"anio_generacion"
										)
									}
								/>{" "}
								Año(s)
							</span>

							<span className="ml-2" style={{ fontSize: "1rem" }}>
								Cada día:{" "}
								<input
									type="number"
									max="31"
									min="0"
									name="recurrencia[dia_generacion]"
									className="form-control d-inline-block"
									style={{
										maxWidth: "60px",
										height: "30px",
									}}
									value={form.dia_generacion}
									onChange={(e) =>
										handleInputChange(
											e.target.value,
											"dia_generacion"
										)
									}
								/>
							</span>

							<span className="ml-2" style={{ fontSize: "1rem" }}>
								Cada Mes de:{" "}
								<select
									className="form-control d-inline-block"
									name="recurrencia[mes_generacion]"
									style={{
										maxWidth: "100px",
										height: "35px",
									}}
									value={form.mes_generacion}
									onChange={(e) =>
										handleInputChange(
											e.target.value,
											"mes_generacion"
										)
									}
								>
									<option value="01">Enero</option>
									<option value="02">Febrero</option>
									<option value="03">Marzo</option>
									<option value="04">Abril</option>
									<option value="05">Mayo</option>
									<option value="06">Junio</option>
									<option value="07">Julio</option>
									<option value="08">Agosto</option>
									<option value="09">Septiembre</option>
									<option value="10">Octubre</option>
									<option value="11">Noviembre</option>
									<option value="12">Diciembre</option>
								</select>
							</span>
						</div>
						<div className="w-auto pl-4 d-inline-block">
							<h5>Inicio y Fin</h5>
							<span style={{ fontSize: "1rem" }}>
								Fecha de inicio:{" "}
								<input
									type="date"
									name="recurrencia[fecha_inicio]"
									min={`${new Date()
										.toISOString()
										.slice(0, 10)}`}
									onChange={(e) =>
										handleInputChange(
											e.target.value,
											"fecha"
										)
									}
									value={form.fecha}
									className="form-control d-inline-block"
									style={{
										maxWidth: "150px",
										height: "30px",
									}}
								/>
							</span>
							<div className="d-inline-block ml-2">
								<div className="form-inline mt-3">
									<div className="radio">
										<label className="mr-2">
											<input
												className="mr-1"
												type="radio"
												name="recurrencia[termina_en]"
												value="mensual"
												checked={
													form.termina_en === "nunca"
												}
												onChange={() =>
													handleInputChange(
														"nunca",
														"termina_en"
													)
												}
											/>
											Nunca Termina
										</label>
									</div>
									<div className="radio">
										<label>
											<input
												className="mr-1"
												type="radio"
												name="recurrencia[termina_en]"
												value="meses"
												checked={
													form.termina_en === "meses"
												}
												onChange={() =>
													handleInputChange(
														"meses",
														"termina_en"
													)
												}
											/>
											Termina en{" "}
											<input
												type="number"
												min="0"
												name="recurrencia[ocurrencias]"
												className="form-control d-inline-block mx-1"
												style={{
													maxWidth: "60px",
													height: "30px",
												}}
												value={form.ocurrencias}
												onChange={(e) =>
													handleInputChange(
														e.target.value,
														"ocurrencias"
													)
												}
												readOnly={
													form.termina_en !== "meses"
												}
											/>{" "}
											ocurrencias
										</label>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			{!isEdit && (
				<>
					<PageHeader
						title="Nueva Factura Recurrente"
						hasButton={false}
					>
						<div className="col-4 offsett-8">
							<div
								className="form-group"
								style={{ marginBottom: "0.3rem" }}
							>
								<div className="col-lg-9 pl-0">
									<select
										name="numeracion_id"
										id="comprobante"
										onChange={(e) =>
											getComprobanteData(e.target.value)
										}
                                        value={comprobante?.id}
									>
										<option value="">
											Seleccione Comprobante
										</option>
										{dropdownOptions.comprobantes?.map(
											(comprobante) => (
												<option
													value={comprobante.value}
													key={comprobante.value}
												>
													{comprobante.label}
												</option>
											)
										)}
									</select>
									{errors.numeracion_id && (
										<small className="help-blockParams form-Text">
											{errors.numeracion_id[0]}
										</small>
									)}
								</div>
							</div>
						</div>
					</PageHeader>
				</>
			)}
			{isEdit && (
				<PageHeader
					title="Editar Factura Recurrente"
					hasButton={false}
				/>
			)}

			<div className="row">
				{/* PRIMERA COLUMNA */}
				<div className="col-12 col-sm-4 d-inline-block">
					<div
						className="form-group"
						style={{ marginBottom: "0.3rem" }}
					>
						<label htmlFor="inputContacto" className="">
							Cliente
						</label>
						<div className="row">
							<Select
								id="inputContacto"
								key="inputContacto"
								options={dropdownOptions.contactos}
								name="contacto_id"
								onChange={(e) =>{
									setContactoId(e.value);
                                    changeContactInfo(e.value);
								}}
								value={
									contactoId &&
									dropdownOptions.contactos.find(
										(contacto) =>
											contacto.value === contactoId
									)
								}
								className="col-10 pr-0"
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
									singleValue: (provided, state) => ({
										...provided,
										display: "flex",
										width: "100%",
									}),
								}}
								formatOptionLabel={(option) =>
									clientLabel(option)
								}
								isDisabled={isEdit}
							/>
							{!isEdit && (
								<div
									className="tooltip-self color-primary d-inline-block ml-2"
									style={{ alignSelf: "center" }}
								>
									<i
										className="fas fa-plus-circle color-primary"
										style={{
											fontSize: "1.25rem",
											cursor: "pointer",
										}}
										onClick={toggleCreateContactoModal}
									></i>
									<span className="tootip-text tooltip-right">
										Crear Contacto
									</span>
								</div>
							)}
							{contactoId && (
								<div
									className="tooltip-self color-primary d-inline-block ml-2"
									style={{ alignSelf: "center" }}
								>
									<i
										className="fas fa-edit color-primary"
										style={{
											fontSize: "1.25rem",
											cursor: "pointer",
										}}
										onClick={toggleEditContactoModal}
									></i>
									<span className="tootip-text tooltip-right">
										Editar Contacto
									</span>
								</div>
							)}
						</div>
						{errors.contacto_id && (
							<small className="help-blockParams form-Text">
								{errors.contacto_id[0]}
							</small>
						)}
					</div>
					<div className="row">
						<div
							className="form-group col-6 pr-0"
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="inputRnc">RNC o cédula</label>
							<input
								type="text"
								className="form-control"
								id="inputRnc"
								defaultValue={contactoInfo.identificacion}
								readOnly
							/>
						</div>
						<div
							className="form-group col-6"
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="inputTelefono">Teléfono</label>
							<input
								type="text"
								className="form-control"
								id="inputTelefono"
								defaultValue={contactoInfo.telefono}
								readOnly
							/>
						</div>
					</div>
					<div
						className="form-group"
						style={{ marginBottom: "0.3rem" }}
					>
						<label htmlFor="inputDireccion">Dirección</label>
						<input
							type="text"
							className="form-control"
							id="inputDireccion"
							defaultValue={contactoInfo.direccion}
							readOnly
						/>
					</div>
				</div>

				{/* SEGUNDA COLUMNA */}
				<div className="col-12 col-sm-4 d-inline-block">
					<div className="row">
						<div
							className="form-group col-6 pr-0"
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="inputEncargado">Encargado</label>
							<input
								type="text"
								className="text-input form-control"
								id="inputEncargado"
								defaultValue={contactoInfo.encargado}
								readOnly
							/>
						</div>
						<div
							className="form-group col-6"
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="inputCelular">Celular</label>
							<input
								type="text"
								className="form-control"
								id="inputCelular"
								defaultValue={contactoInfo.celular}
								readOnly
							/>
						</div>
					</div>
					<div
						className="form-group"
						style={{ marginBottom: "0.3rem" }}
					>
						<label htmlFor="inputVendedor">Vendedor</label>
						<Select
							id="inputVendedor"
							options={dropdownOptions.vendedores}
							name="empleado_id"
							value={contactoInfo.vendedor}
							styles={{
								control: (styles) => ({
									...styles,
									height: "38px",
								}),
							}}
							onChange={(e) => handleDetalleChange(e, "vendedor")}
						/>
						{errors.empleado_id && (
							<small className="help-blockParams form-Text">
								{errors.empleado_id[0]}
							</small>
						)}
					</div>
					<div className="row" style={{ marginBottom: "0.3rem" }}>
						<div className="form-group col-6 pr-0">
							<label htmlFor="inputRuta">Zona</label>
							<Select
								id="inputRuta"
								options={dropdownOptions.rutas}
								name="ruta_id"
								value={contactoInfo.ruta}
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
								}}
								onChange={(e) => handleDetalleChange(e, "ruta")}
							/>
							{errors.ruta_id && (
								<small className="help-blockParams form-Text">
									{errors.ruta_id[0]}
								</small>
							)}
						</div>
						<div className="form-group col-6">
							<label htmlFor="inputRuta">Categorías</label>
							<SelectCreate
								isMulti
								isClearable
								options={dropdownOptions.categorias}
								value={form.categorias}
								onChange={(values) =>
									handleInputChange(values, "categorias")
								}
							/>
							<input
								type="hidden"
								name="categorias"
								value={form.categorias
									?.map((c) => c.label)
									.join(",")}
							/>
						</div>
					</div>
				</div>

				{/* TERCERA COLUMNA */}
				<div className="col-12 col-sm-4 d-inline-block">
					{!isEdit ? (
						<div
							className="form-group"
							style={{ marginBottom: "0.3rem" }}
						>
							<label>No.</label>
							<div className="row">
								<div className="col-2 pr-2">
									<input
										type="text"
										className="form-control"
										defaultValue={comprobante.prefijo}
										readOnly
									/>
								</div>
								<div className="col-10 pl-0">
									<input
										type="text"
										className="form-control"
										defaultValue={
											comprobante.numero_siguiente
										}
										readOnly
									/>
									{comprobante.prefijo && (
										<input
											type="hidden"
											name="numero_comprobante"
											value={`${comprobante.prefijo}${comprobante.numero_siguiente}`}
										/>
									)}
								</div>
							</div>
							{errors.numero_comprobante && (
								<small className="help-blockParams form-Text">
									{errors.numero_comprobante[0]}
								</small>
							)}
						</div>
					) : (
						<div className="row">
							<div
								className="form-group col-12"
								style={{ marginBottom: "0.3rem" }}
							>
								<label>NO.</label>
								<input
									type="text"
									className="form-control"
									value={`${comprobante.prefijo}${comprobante.numero_siguiente}`}
									name="numero_comprobante"
									onChange={() => {}}
									readOnly={true}
								/>
								{errors.fecha_vencimiento && (
									<small className="help-blockParams form-Text">
										{errors.fecha_vencimiento[0]}
									</small>
								)}
							</div>
						</div>
					)}

					<div className="row">
						<div
							className="form-group col-6 pr-0"
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="fecha">Fecha</label>
							<input
								type="date"
								className="form-control"
								id="fecha"
								name="fecha"
								onChange={(e) =>
									handleInputChange(e.target.value, "fecha")
								}
								value={form.fecha}
							/>
							{errors.fecha && (
								<small className="help-blockParams form-Text">
									{errors.fecha[0]}
								</small>
							)}
						</div>
						{isComprobanteFiscal() && (
							<div
								className={`form-group col-6`}
								style={{ marginBottom: "0.3rem" }}
							>
								<label>Vencimiento comprobante</label>
								<input
									type="date"
									className="form-control"
									value={comprobante?.fecha_vencimiento}
									onChange={(e) =>
										handleInputChange(
											e.target,
											"fecha_vencimiento"
										)
									}
									readOnly
								/>
								{errors.fecha_vencimiento && (
									<small className="help-blockParams form-Text">
										{errors.fecha_vencimiento[0]}
									</small>
								)}
							</div>
						)}
						<div
							className={`form-group col-6 ${
								!isComprobanteFiscal() ? "" : "d-none"
							}`}
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="fecha_vencimiento">
								Vencimiento
							</label>
							<input
								type="date"
								max={`${aplicarLimiteFecha()}`}
								min={`${new Date().toISOString().slice(0, 10)}`}
								className="form-control"
								id="fecha_vencimiento"
								name="fecha_vencimiento"
								value={form.fecha_vencimiento}
								onChange={(e) =>
									handleInputChange(
										e.target,
										"fecha_vencimiento"
									)
								}
								readOnly={plazo.dias !== null}
							/>
							{errors.fecha_vencimiento && (
								<small className="help-blockParams form-Text">
									{errors.fecha_vencimiento[0]}
								</small>
							)}
						</div>
					</div>
					<div className="row">
						<div
							className={`form-group ${
								plazo.dias === 0 ? "col-12" : "col-4 pr-0"
							}`}
							style={{ marginBottom: "0.3rem" }}
						>
							<label htmlFor="inputPlazo" className="">
								Plazo de pago
							</label>
							<Select
								id="inputPlazo"
								options={
									contactoInfo.plazo
										? dropdownOptions.terminos.filter(
												(termino) => {
													if (
														termino.dias <=
														contactoInfo.plazo
															?.dias
													) {
														return termino;
													}
												}
										  )
										: []
								}
								name="plazo_id"
								value={plazo}
								onChange={(e) => {changePlazo(e)}}
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
								}}
							/>
							{errors.plazo_id && (
								<small className="help-blockParams form-Text">
									{errors.plazo_id[0]}
								</small>
							)}
						</div>
						{plazo.dias !== 0 && (
							<>
								<div
									className="form-group col-4 pr-0"
									style={{ marginBottom: "0.3rem" }}
								>
									<label
										htmlFor="credito_aprobado"
										className=""
									>
										Credito aprobado
									</label>
									<input
										type="text"
										className="form-control"
										id="credito_aprobado"
										value={contactoInfo.limite_credito}
										readOnly
									/>
								</div>
								<div
									className="form-group col-4 pr-0"
									style={{ marginBottom: "0.3rem" }}
								>
									<label
										htmlFor="credito_disponible"
										className=""
									>
										Credito disponible
									</label>
									<input
										className="form-control"
										id="credito_disponible"
										name="credito_disponible"
										value={
											contactoInfo.credito_disponible || 0
										}
										readOnly
									/>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

Detalles.propTypes = {
	dropdownOptions: PropTypes.object,
	contactoInfo: PropTypes.object,
	form: PropTypes.object,
	handleInputChange: PropTypes.func,
	getComprobanteData: PropTypes.func,
	aplicarLimiteFecha: PropTypes.func,
	handleDetalleChange: PropTypes.func,
	isEdit: PropTypes.bool,
};

export default React.memo(Detalles);
