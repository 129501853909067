import React, { Component } from "react";
import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import Form from "./Form";
import { toast } from "../../../utils/helpers";

class CreateSolicitud extends Component {
    state = {
        vehiculos: [],
        empleados: [],
        saving: false
    };
    componentDidMount() {
        this.getTable("vehiculos");
        this.getTable("empleados", "?estado=activos");
    }
    updateSolicitud = async (event, id) => {
        event.preventDefault();

        this.setState({
            saving: true
        });

        const token = auth.getToken();
        const data = new FormData(event.target);

        const url = `solicitudes/${id}`;

        const res = await fetch(apiUrl(url), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            if (this.props.getSolicitudes) {
                this.props.getSolicitudes();
            }
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false
        });
    };
    getTable = async (url = null, params = "") => {
        const table = await this.props.request(url + params);

        if (table.data && table.data.length > 0) {
            this.setState({
                [url]: table.data
            });
        }
    };
    deleteSolicitud = async (event, solicitud) => {
        event.preventDefault();

        const token = auth.getToken();

        const url = `solicitudes/${solicitud}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            if (this.props.getSolicitudes) {
                this.props.getSolicitudes();
            }
            this.props.toggle();
            toast("Solicitud borrada.");
            return;
        } else {
            toast(response.data, "error");
        }
    };
    render() {
        const { empleado, solicitud } = this.props;
        const { vehiculos, empleados } = this.state;
        return (
            <form
                id='solicitudesform'
                method='post'
                onSubmit={e => this.updateSolicitud(e, solicitud.id)}>
                <input type='hidden' name='_method' value='PATCH' />
                <div className='popup__header'>Editar Solicitud</div>
                <Form
                    empleado={empleado}
                    vehiculos={vehiculos}
                    empleados={empleados}
                    solicitud={solicitud}
                />
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={e => this.deleteSolicitud(e, solicitud.id)}>
                        <i className='fas fa-trash-alt'></i>
                    </button>
                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {this.state.saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Guardar
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default withRequest(CreateSolicitud);
