import React, { useEffect, useState } from "react";
import Select from "react-select";

import { formatCurrency, toast } from "../../utils/helpers";

const GastoItem = ({index, gasto, gitems, cant, items, updateTotal, removeGasto}) => {
    const [state, setState] = useState({
        costo: gasto.precio || "",
        costoMin: 0.01,
        subtotal: 0,
        disponible: gasto.item?.cantidad || 1,
        impuesto: gasto.impuesto?.porcentaje * 100 || 0,
        impuesto_id: gasto.impuesto?.id || 0,
        valid: true,
        agregarNota: false,
        itemId: gasto.item?.id || null,
        existe: false,
        isItem: gasto.isItem || true,
    });

    const [valid, setValid] = useState(true);
    const [cantidad, setCantidad] = useState(cant || 0);

    useEffect(() => {
        actualizarTotal();
        /* setState({
            ...state,
            cantidad: gasto.cantidad,
        });
        updateTotal(gasto.id, calcularTotal(
            gasto.precio,
            gasto.cantidad,
            gasto.impuesto?.porcentaje * 100 || 0
        )); */
    }, [state, cantidad]);

    useEffect(()=>{
        setCantidad(cant || 1);
    }, [cant]);

    const onChangeCantidad = async (value) => {
        await setCantidad(value);
    };

    const actualizarTotal = () => {
        const { costo, disponible, itemId} = state;

        if (parseFloat(cantidad) > disponible) {
            setValid(false);
            toast("Error - Cantidad disponible: " + disponible, "error");
        } else if (parseFloat(cantidad) < 0.01 || cantidad === "") {
            toast("Cantidad no puede ser 0", "error");
            setValid(false);
        } else {
            setValid(true);
        }

        updateTotal(gasto.id, costo, cantidad, itemId, false);
    }
    const onChangeItem = async (itemId) => {
        let item = items.filter((item) => item.id === itemId)[0];
        item = item ? item : {};

        const existe = gitems.filter(g => g.itemId === itemId).length === 1;

        await setState({
            ...state,
            id: item.id ? item.id : null,
            nombre: item.nombre ? item.nombre : null,
            costo: item.costo ? item.costo : null,
            cantidad: 1,
            impuesto: item.impuesto ? item.impuesto : 0,
            costoMin: item.min ? item.min : item.costo,
            disponible: item.cantidad ? item.cantidad : 0,
            itemId: item.id,
            valid: item.cantidad > 0,
        });

        updateTotal(gasto.id, item.costo, 1, item.id, existe);
    };
    const toggleAgregarNota = () => {
        setState({
            ...state,
            agregarNota: !state.agregarNota,
        });
    };
    const calcularTotal = (costo, cantidad) => {
        return parseFloat(costo) * cantidad;
    }
    const {
        costo,
        disponible,
        impuesto,
        agregarNota,
    } = state;

    let total = calcularTotal(costo, cantidad, impuesto);
    const isItem = true;

    return (
        <tr
            style={{
                backgroundColor: !valid
                    ? "rgba(255,0,0,0.07)"
                    : "transparent",
            }}>
            <td className='text-center'>
                {index + 1}
            </td>
            <td>
                {gasto.id && (
                    <input type="hidden" name={`gastos[${index}][id]`} value={gasto.id} />
                )}
                <div>
                    <Select
                        options={items.map(i => ({value: i.id, label: i.nombre}))}
                        isLoading={items[0] === false}
                        loadingMessage={() => "Cargando items..."}
                        defaultValue={gasto.item && {
                            label: `${gasto.item?.referencia} - ${gasto.item?.nombre}`,
                            value: gasto.item?.id
                        }}
                        required
                        onChange={(e) => {
                            if (!e) e = {};
                            onChangeItem(e.value);
                        }}
                        name={`gastos[${index}][item_id]`}
                        isClearable={true}
                    />
                    <div>
                        <button onClick={toggleAgregarNota} type="button"><small>AGREGAR NOTA +</small></button>
                    </div>
                    {(gasto.descripcion || agregarNota) && (
                        <input
                            type='text'
                            className='form-control'
                            defaultValue={gasto.descripcion}
                            name={`gastos[${index}][descripcion]`}
                            id='descripcion'
                        />
                    )}
                </div>
            </td>
            <td className='text-center'>{isItem || gasto.isItem ? disponible : "N/A"}</td>
            <td>
                <input
                    type='number'
                    name={`gastos[${index}][cantidad]`}
                    value={cantidad || ''}
                    min='0.01'
                    disabled={disponible < 1}
                    max={disponible}
                    onChange={(e) =>
                        onChangeCantidad(e.target.value)
                    }
                    id=''
                    className='form-control text-right'
                    step='0.01'
                />
            </td>
            <td className='text-center'>
                <div className="text-right">
                    {formatCurrency(costo)}
                    <input
                        type='hidden'
                        name={`gastos[${index}][precio]`}
                        value={costo || ''}
                    />
                </div>
            </td>
            <td className='text-right'>
                {formatCurrency(total)}
                <input type="hidden" name={`gastos[${index}][total]`} value={total || 0} />
            </td>
            <td>
                <button
                    type='button'
                    className='btn'
                    onClick={() => removeGasto(gasto.id)}>
                    <i className='fas fa-trash'></i>
                </button>
            </td>
        </tr>
    );
}

export default GastoItem;
