import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import dayjs from "dayjs";
import queryString from "query-string";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

class PrintContratos extends Component {
    state = {
        descuentos: [],
    };
    componentDidMount() {
        this.getDescuentos();
    }
    getDescuentos = async () => {
        const qryString = queryString.parse(window.location.search);
        const query = Object.keys(qryString).map((qry) => {
			return `${qry}=${qryString[qry]}`;
		});
        console.log(query);

        const response = await this.props.request(
            `contratos?limit=1000&${query.join("&")}`
        );
   
        console.log(response.data.length);
        if (response.data) {
            this.setState({
                descuentos: response.data
            });
            window.print();
        }
    };
    render() {
        const { descuentos } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const numero_contratos = descuentos.length;

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Contratos
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 p-b-10'>
                        <div className="float-left">
                            <p><strong># Cantidad de contratos:</strong> {numero_contratos}</p>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th style={{ width: "25%" }}>Contacto</th>
                            <th style={{ width: "35%" }}>Detalle</th>
                            <th>Monto</th>
                            <th>Inicio</th>
                            <th>Fin</th>
                            <th>Perfil</th>
                        </tr>
                    </thead>
                    <tbody>
                        {descuentos.map((contrato, index) => {
                            return (
                                <tr key={contrato.id}>
                                    <td>{index+1}</td>
                                    <td>
                                        <strong>
                                            {contrato.perfil_contrato == 1 ? contrato.contratoable.nombre + " " + contrato.contratoable.apellido : contrato.contratoable.nombre }
                                        </strong>
                                    </td>
                                    <td>                                   
                                        <small>
                                            <b>Estado:</b> {
                                                contrato.activo
                                            }
                                        </small>
                                    {contrato?.frecuencia_pago && (
                                        <>
                                            <br/>
                                            <small>                                  
                                                <b>Frecuencia de pago:</b> {
                                                    contrato.frecuencia_pago
                                                }
                                            </small>
                                        </>)
                                    }
                                    {contrato?.tipo && (
                                        <>
                                            <br/>                                   
                                            <small>
                                                <b>Tipo:</b> {
                                                    contrato.tipo
                                                }
                                            </small>
                                        </>)
                                    }
                                    </td>
                                    <td>
                                        <div><strong>{formatCurrency(contrato.monto)}</strong></div>
                                    </td>
                                    <td>
                                        {contrato?.inicio ? dayjs(contrato.inicio).format("DD-MM-YYYY") : ""}
                                    </td>
                                    <td>
                                        {contrato?.fin ? dayjs(contrato.fin).format("DD-MM-YYYY") : ""}
                                    </td>
                                    <td>
                                        {contrato.perfil_contrato == 1 ? "Empleado" : contrato.perfil_contrato == 2 ? "Cliente" : "Proveedor"}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintContratos));