import React from "react";

import Select from "react-select";

export default function Form({
    vehiculos,
    rutas,
    onSelectFicha,
    asignacion,
    categoria,
    categoriaActiva,
    errors,
}) {
    return (
        <div>
            <input
                type='hidden'
                name='categoria_id'
                defaultValue={categoria ? categoria : asignacion.categoriaId}
            />
            <input
                type='hidden'
                name='categoria_asignacion_id'
                defaultValue={categoriaActiva}
            />
            <div className='form-group'>
                <label className='label form-control-label'>Ficha</label>
                <Select
                    name='vehiculo_id'
                    isClearable={true}
                    isLoading={vehiculos.length === 0}
                    onChange={onSelectFicha}
                    defaultValue={
                        asignacion.vehiculoId && {
                            isFixed: true,
                            value: asignacion.vehiculoId,
                            label: asignacion.ficha,
                        }
                    }
                    options={vehiculos.map((vehiculo) => ({
                        value: vehiculo.id,
                        label: vehiculo.ficha,
                    }))}
                />
                {errors.vehiculo_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.vehiculo_id[0]}
                    </small>
                )}
            </div>
            <div className='form-group'>
                <label className='label form-control-label'>Zona</label>
                <Select
                    name='ruta_id'
                    isClearable={true}
                    isLoading={rutas.length === 0}
                    defaultValue={
                        asignacion.rutaId && {
                            isFixed: true,
                            value: asignacion.rutaId,
                            label: asignacion.ruta.nombre,
                        }
                    }
                    options={rutas.map((ruta) => ({
                        value: ruta.id,
                        label: `${ruta.nombre} ${ruta.descripcion}`,
                    }))}
                />
                {errors.ruta_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.ruta_id[0]}
                    </small>
                )}
            </div>
            <div className='form-group'>
                <label className='label form-control-label'>Meta</label>
                <input
                    className='form-control'
                    defaultValue={asignacion.meta}
                    type='number'
                    name='meta'
                    step='0.01'
                />
                {errors.meta && (
                    <small className='help-blockParams form-Text'>
                        {errors.meta[0]}
                    </small>
                )}
            </div>
            <div className='form-group'>
                <label className='label form-control-label'>Nota</label>
                <textarea
                    className='form-control'
                    defaultValue={asignacion.nota}
                    rows='6'
                    name='nota'
                />
                {errors.nota && (
                    <small className='help-blockParams form-Text'>
                        {errors.nota[0]}
                    </small>
                )}
            </div>
        </div>
    );
}
