import React, {} from "react";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";

import { formatCurrency, getCurrentMonthName } from "../../../utils/helpers";
import useSalarioNavidad from "./Hooks/useSalarioNavidad";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const SalarioNavidadDetalle = () => {
    const {
        pagos,
        empleados,
        orden,
        onChangeOrden,
        sucursal,
        totalRegalia,
        totalRedondeado
    } = useSalarioNavidad();

    return (
        <div>
            <style>{`@page {size: 11in  8.5in;} *{font-size: 14px}`}</style>
            <div className="ordernar-resultados no-print">
                <div className="form-group">
                    <div className="radio-btn">
                        <label>
                            <input type="radio" onChange={onChangeOrden} name="orden" value="nombre" defaultChecked={true} /> Ordenar por Nombre
                        </label>
                        <label>
                            <input type="radio" onChange={onChangeOrden} name="orden" value="codigo" /> Ordenar por Código
                        </label>
                    </div>
                </div>
            </div>
            <div className='row'>
                <h2 className='col-md-12 text-center m-b-5'>
                    Salario Navidad Detalle {dayjs().endOf('year').format('YYYY')}
                </h2>
                <div className='col-md-12 text-center'>
                    {sucursal.nombre} - {sucursal.direccion} •{" "}
                    {new Date().toLocaleString()}
                </div>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'>
                        TOTAL EMPLEADOS: {empleados.length}
                    </div>

                    <div className='float-right'>
                        <h3>Regalía: {formatCurrency(totalRegalia)} | Total: {formatCurrency(totalRedondeado)}</h3>
                    </div>
                </div>
            </div>
            {pagos.sort((a, b) => {
                if ( orden === 'codigo') {
                    if (!a.codigo) {
                        return 1;
                    }
                    return a.codigo.localeCompare(b.codigo);
                }
                return a.nombre.localeCompare(b.nombre);
            }).map(empleado => {
                let acumulado = 0;
                return (
                <>
                    <h3 className="m-b-5">{empleado.codigo} - {empleado.nombre} {empleado.apellido}</h3>
                    <div className="m-b-5"><strong>Tiempo Trabajando:</strong> {empleado.fecha_ingreso && (<>
                                        {empleado.anos > 0 && (<span>{empleado.anos} año(s), </span>)}
                                        {empleado.meses < 12 && empleado.meses > 0 && (<span>{empleado.meses} mes(es)</span>)}
                                        {empleado.dias > 0 && (<span> y {empleado.dias} día(s)</span>)}
                                    </>)} • <strong>Sueldo Actual:</strong> {formatCurrency(empleado.salario_actual)}</div>
                    <table className='table m-b-40'>
                        <thead>
                            <tr>
                                <th>Mes</th>
                                <th>Pago</th>
                                <th className="text-right">Total</th>
                                <th className="text-right">Acumulado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(empleado.salarios).sort((a, b) => {
                                return a - b;
                            }).map((mes) => {
                                const pago = empleado.salarios[mes];
                                acumulado += pago.sueldo_bruto / 12;
                                return (
                                    <tr key={empleado.id}>
                                        <td>{getCurrentMonthName(parseInt(mes) - 1)}</td>
                                        <td>
                                            {formatCurrency(pago.sueldo_bruto)}
                                        </td>
                                        <td className="text-right">
                                            {formatCurrency(pago.sueldo_bruto / 12)}
                                        </td>
                                        <td className="text-right">
                                            {formatCurrency(acumulado)}
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr key={empleado.id}>
                                <td></td>
                                <td></td>
                                <td className="text-right"></td>
                                <td className="text-right">
                                    <strong>TOTAL: {formatCurrency(empleado.prestaciones)}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )})}
            <div className='row'>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'>
                        TOTAL EMPLEADOS: {empleados.length}
                    </div>

                    <div className='float-right'>
                        <h3>Regalía: {formatCurrency(totalRegalia)} | Total: {formatCurrency(totalRedondeado)}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SalarioNavidadDetalle;
