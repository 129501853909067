import React, { useEffect, useState } from "react";
import SelectCreate from "react-select/creatable";

import Loader from "../../general/Loader";
import { toast } from "../../../utils/helpers";
import { request } from "../../../utils/request";
import { apiRequest } from "../../../hooks/apiRequest";
import Form from "./Form";

const Create = ({empleadoId, getPersonasContacto, toggle}) => {
    const [state, setState] = useState({
        saving: false,
        errors: {}
    });

    const createDescuento = async event => {
        event.preventDefault();

        setState({
            ...state,
            saving: true
        });

        const data = new FormData(event.target);
        const response = await apiRequest(data, `personas-contacto?empleado_id=${empleadoId}`);

        if (response.success) {
            getPersonasContacto();
            toggle();
            toast("Persona de contacto creada.");
            document.getElementById("personasform").reset();
            setState({
                ...state,
                saving: false,
            });
        }
        if (response.code === 422) {
            setState({
                ...state,
                errors: response.data.message,
                saving: false,
            });
        }
    };

    return (
        <form
            id='personasform'
            method='post'
            onSubmit={createDescuento}>
            <input type='hidden' name='_method' value='post' />
            <div className='popup__header'>Nueva Persona de Contacto</div>
            <div className='popup__body'>
                <Form persona={{}} errors={state.errors} />
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                {state?.saving ? (
                    <Loader type='small' />
                ) : (
                    <button type='submit' className='btn btn-primary'>
                        Crear
                    </button>
                )}
            </div>
        </form>
    );
}

export default Create;
