import React from 'react';
import { formatCurrency, getLink } from '../../../../utils/helpers';
import { Link } from 'react-router-dom';

const Balance = ({estudiante}) => {
    return (
        <div>
            <style>{`
                ul {
                    list-style: none;
                    margin-bottom: 25px;
                }
                li button {
                    width: 100%;
                    text-align: left;
                    border-bottom: 1px solid #f2f2f2;
                    padding: 10px 0;
                }
                li table {
                    display: none;
                    width: 100%;
                }
                li.active table {
                    display: table;
                }
            `}</style>
            <fieldset className='p-r-20 p-l-20 p-b-20'>
                <legend className="uppercase">Balance del Colegio</legend>
                <table className="w-100">
                    <tbody>
                        <tr key={estudiante.id}>
                            <td style={{lineHeight: 1.2}}>
                                <Link to={getLink(`/academico/facturar?estudiante_id=${estudiante.id}`)} target="_blank">
                                    {estudiante?.nombreCompleto}
                                </Link>
                            </td>
                            <td>{estudiante?.balance > 0 ? 'Pendiente' : 'Al día'}</td>
                            <td className='text-right'>{formatCurrency(estudiante?.balance)}</td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>
        </div>
    );
}

export default Balance;
