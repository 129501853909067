/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Loader from "../../general/Loader";
import PageHeader from "../../general/PageHeader";
import Pagination from "../../general/Pagination";
import { apiRequest } from "../../../hooks/apiRequest";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Link, useParams } from "react-router-dom";
import { formatCurrency, getLink, toast } from "../../../utils/helpers";
import { apiUrl } from "../../../utils/apiUrl";
import FiltersContainer from "../../general/FiltersContainer";
import useFilters from "../../../hooks/useFilters";

const Compras = () => {
	const { id } = useParams();
	const [contacto] = useLocalStorage("contacto", {});

	const [transacciones, setTransacciones] = useState({});
	const [loading, setLoading] = useState(false);
	const { handleFilterChange, filtersQuery } = useFilters({
		buscar: "",
		tipo: "",
		estado: "",
		vendedor: "",
		desde: dayjs("2018-01-01").format("YYYY-MM-DD"),
		consultar: "",
		hasta: dayjs().format("YYYY-MM-DD"),
	});

	const getTransactions = async (url = null) => {
		setLoading(true);
		if (url && url.includes(apiUrl(""))) {
			url = url.split(apiUrl(""))[1];
		}
		const transactions = await apiRequest(null, url, "get");

		if (transactions.data?.length > 0) {
			setTransacciones(transactions.data);
			setLoading(false);
			return;
		}

		setTransacciones([]);
		setLoading(false);
	};

	const renderEstadoBadge = (estado) => {
		if (estado === "pendiente") {
			return <span className="badge badge-secondary p-2">Pendiente</span>;
		} else if (estado === "pagada") {
			return <span className="badge badge-success p-2">Pagada</span>;
		}

		return <span className="badge badge-danger p-2">Cancelada</span>;
	};

	useEffect(() => {
		if (filtersQuery === "") return;
		getTransactions(`compras${filtersQuery}&proveedor=${id}&orderby=fecha`);
	}, [filtersQuery]);
	return (
		<>
			<PageHeader
				title={<Titulo contacto={contacto.nombre} />}
				hasButton={false}
			>
				<div className="col-3">
					<label
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Consultar Productos/Items
					</label>
					<input
						onChange={(e) =>
							handleFilterChange("consultar", e.target.value)
						}
						type="search"
						placeholder="Consultar..."
						className="form-control"
						name="consultar"
					/>
				</div>
				<Link
					to={getLink(
						`/imprimir/contactos/${id}/compras${filtersQuery}`
					)}
					target="_blank"
					className="au-btn-filter m-r-15 ml-4"
				>
					<i className="zmdi zmdi-print" /> Imprimir
				</Link>
				{contacto.tipo?.includes("Cliente") && (
					<Link
						to={getLink(`/contactos/${id}/transacciones`)}
						target="_blank"
						className="au-btn-filter m-r-15"
					>
						Historial Transacciones
					</Link>
				)}
				<Link
					className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15"
					to={`${getLink("/compras/crear")}`}
				>
					<i className="zmdi zmdi-plus" />
					Añadir Compra
				</Link>
			</PageHeader>
			<FiltersContainer>
				<div className="col">
					<label>Buscar</label>
					<input
						onChange={(e) =>
							handleFilterChange("buscar", e.target.value)
						}
						type="search"
						placeholder="Buscar..."
						className="form-control"
						name="buscar"
					/>
				</div>
				<div className="col-md-2">
					<label>Estado</label>
					<select
						className="form-control"
						name="estado"
						id="estado"
						onChange={(e) =>
							handleFilterChange("estado", e.target.value)
						}
					>
						<option value="">Todos</option>
						<option value="pendiente">Pendiente</option>
						<option value="pagada">Pagada</option>
						<option value="cancelada">Cancelada</option>
					</select>
				</div>
				<div className="col-md-2">
					<label>Desde</label>
					<input
						className="form-control"
						type="date"
						name="desde"
						onChange={(e) =>
							handleFilterChange("desde", e.target.value)
						}
					/>
				</div>
				<div className="col-md-2">
					<label htmlFor="estado">Hasta</label>
					<input
						className="form-control"
						type="date"
						name="hasta"
						onChange={(e) =>
							handleFilterChange("hasta", e.target.value)
						}
						defaultValue={dayjs().format("YYYY-MM-DD")}
					/>
				</div>
			</FiltersContainer>
			<div className="row">
				<div className="col-md-12">
					<div>
						{loading && <Loader />}
						{!transacciones?.length ? (
							<div>
								{!loading && (
									<div>
										Todavía no hay transacciones creadas.
									</div>
								)}
							</div>
						) : (
							!loading && (
								<div className="table-data">
									<table className="table table-data2">
										<thead>
											<tr>
												<th>NCF/Número</th>
												<th>Creación</th>
												<th>Vencimiento</th>
												<th>Fecha Pagada</th>
												<th>Total</th>
												<th>Tipo</th>
												<th className="text-center">
													Estado
												</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{transacciones.map(
												(transaccion) => (
													<tr
														className="tr-shadow"
														key={transaccion.id}
													>
														<td>
															{transaccion.ncf}
														</td>
														<td>
															{dayjs(
																transaccion.fecha
															).format(
																"DD/MM/YYYY"
															)}
														</td>
														<td>
															{dayjs(
																transaccion.fecha_vencimiento
															).format(
																"DD/MM/YYYY"
															)}
														</td>
														<td>
															{transaccion.estado ===
															"pagada"
																? dayjs(
																		transaccion.fecha_pago
																  ).format(
																		"DD/MM/YYYY, hh:mm a"
																  )
																: ""}
														</td>
														<td>
															{formatCurrency(
																transaccion.total,
																"$"
															)}
														</td>
														<td className="text-capitalize">
															{transaccion.tipo}
														</td>
														<td className="text-center">
															{renderEstadoBadge(
																transaccion.estado
															)}
														</td>
														<td></td>
														<td>
															<div
																className="table-data-feature"
																style={{
																	justifyContent:
																		"flex-end",
																}}
															>
																<Link
																	to={getLink(
																		`/compras/${transaccion.id}`
																	)}
																	target="_blank"
																	className="item"
																	title="Ver Compra"
																>
																	<i className="fas fa-eye"></i>
																</Link>
																<Link
																	to={getLink(
																		`/imprimir/compras/${transaccion.id}`
																	)}
																	target="_blank"
																	className="item"
																	title="Impresión normal"
																>
																	<i className="fas fa-print"></i>
																</Link>
															</div>
														</td>
													</tr>
												)
											)}
										</tbody>
									</table>
									{transacciones.links && (
										<Pagination
											links={{
												next: transacciones.next_page_url,
												prev: transacciones.prev_page_url,
											}}
											meta={transacciones}
											getData={getTransactions}
										/>
									)}
								</div>
							)
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Compras;

const Titulo = ({ contacto }) => {
	return (
		<span style={{ fontSize: "1.6rem" }}>
			<div style={{ fontSize: "1.2rem" }}>Historial de Compras de </div>
			<span style={{ fontWeight: "600", fontSize: "1.4rem" }}>
				{contacto}
			</span>
		</span>
	);
};
