import dayjs from "dayjs";
import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { formatCurrency, getLink } from "../../../utils/helpers";

const Form = ({ evaluacion, errors, grados, periodosAcademicos, setEstado, setPago, duplicado, showPendientePagoAlert = false }) => {
    const pagoEvaluacion = evaluacion.estudiante?.pagos?.find(p => p.nombre.includes('valuaci'));
    const [verHistorial, setVerHistorial] = useState(false);
    return (
        <div className="evaluacion-form">
            <div className='row'>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Nombre(s):
                    </label>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='nombre'
                        name='nombre'
                        required
                        defaultValue={evaluacion.nombre}
                    />
                    {errors.nombre && (
                        <small className='help-blockParams form-Text'>
                            {errors.nombre[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Primer Apellido:
                    </label>
                    <input
                        type='text'
                        required
                        className='text-input form-control'
                        id='primer_apellido'
                        name='primer_apellido'
                        defaultValue={evaluacion.primerApellido}
                    />
                    {errors.primer_apellido && (
                        <small className='help-blockParams form-Text'>
                            {errors.primer_apellido[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Segundo Apellido:
                    </label>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='segundo_apellido'
                        name='segundo_apellido'
                        defaultValue={evaluacion.segundoApellido}
                    />
                    {errors.segundo_apellido && (
                        <small className='help-blockParams form-Text'>
                            {errors.segundo_apellido[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Fecha de Examen:
                    </label>
                    <input
                        type='date'
                        className='text-input form-control'
                        id='fecha'
                        name='fecha'
                        required
                        defaultValue={dayjs(evaluacion.fecha).format('YYYY-MM-DD')}
                    />
                    {errors.fecha && (
                        <small className='help-blockParams form-Text'>
                            {errors.fecha[0]}
                        </small>
                    )}
                </div>

                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Año Escolar:
                    </label>
                    <Select
                        key="periodo_academico"
                        name='periodo_academico_id'
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        isRequired
                        options={periodosAcademicos && periodosAcademicos.map(p => ({
                            label: p.nombre,
                            value: p.id,
                        }))}
                        defaultValue={evaluacion.periodoAcademico && {
                            label: evaluacion.periodoAcademico?.nombre,
                            value: evaluacion.periodoAcademico?.id
                        }}
                    />
                    {errors.periodo_academico_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.periodo_academico_id[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Grado:
                    </label>
                    <Select
                        key="grado"
                        name='grado_id'
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        options={grados && grados.map(p => ({
                            label: p.nombre,
                            value: p.id,
                        }))}
                        defaultValue={evaluacion.grado && {
                            label: evaluacion.grado?.nombre,
                            value: evaluacion.grado?.id
                        }}
                    />
                    {errors.grado_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.grado_id[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Procedencia:
                    </label>
                    <div className='input-group'>
                        <input
                            type='text'
                            className='text-input form-control'
                            defaultValue={evaluacion.procedencia}
                            name='procedencia'
                        />
                    </div>
                    {errors.procedencia && (
                        <small className='help-blockParams form-Text'>
                            {errors.procedencia[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Acompañante:
                    </label>
                    <div className='input-group'>
                        <input
                            type='text'
                            className='text-input form-control'
                            defaultValue={evaluacion.tutor}
                            name='tutor'
                        />
                    </div>
                    {errors.tutor && (
                        <small className='help-blockParams form-Text'>
                            {errors.tutor[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Teléfono:
                    </label>
                    <input
                        type='text'
                        required
                        className='text-input form-control'
                        id='telefono'
                        name='telefono'
                        defaultValue={evaluacion.telefono}
                    />
                    {errors.telefono && (
                        <small className='help-blockParams form-Text'>
                            {errors.telefono[0]}
                        </small>
                    )}
                </div>
            </div>
            {duplicado && (
                <div className="message">
                    <div className="text-center uppercase">
                        <strong>** Ya existe una evaluación con estos datos: **</strong>
                    </div>
                    <table className="table m-b-10 m-t-15">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Grado</th>
                                <th>Acompañante</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {duplicado.nombre} {duplicado.primerApellido} {duplicado.segundoApellido}
                                    <div>Tel: {duplicado.telefono}</div>
                                </td>
                                <td>
                                    <div>{duplicado.grado?.nombre}</div>
                                    <small>{duplicado.periodoAcademico?.nombre}</small>
                                </td>
                                <td>{duplicado.tutor}</td>
                                <td>
                                    <span className={{
                                        "Aprobado": "uppercase badge badge-success",
                                        "Inscrito": "uppercase badge badge-primary",
                                        "Reprovado": "uppercase badge badge-danger",
                                        "Pendiente": "uppercase badge badge-warning",
                                    }[duplicado.estado]}>{duplicado.estado}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>--------</div>
                    <label>
                        <input type="checkbox" name="ignorar_duplicado" value="si" />{" "}
                        Ignorar alerta y crear
                    </label>
                </div>
            )}
            {pagoEvaluacion && (
                <div className="row">
                    <div className="col">
                        <table className="table m-t-20">
                            <thead>
                                <tr>
                                    <th>Monto</th>
                                    <th>Abonado</th>
                                    <th>Balance</th>
                                    <th>Estado</th>
                                    {pagoEvaluacion?.estado === 'pagado' && (
                                        <th>Fecha Completado</th>
                                    )}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{formatCurrency(pagoEvaluacion.monto)}</td>
                                    <td>{formatCurrency(pagoEvaluacion.abonado)}</td>
                                    <td>{formatCurrency(parseFloat(pagoEvaluacion.monto) - parseFloat(pagoEvaluacion.abonado))}</td>
                                    <td>
                                        <span className={{
                                            "pagado": "uppercase badge badge-success",
                                            "exonerado": "uppercase badge badge-primary",
                                            "devuelto": "uppercase badge badge-danger",
                                            "pendiente": "uppercase badge badge-warning",
                                        }[pagoEvaluacion.estado]}>{pagoEvaluacion.estado}</span>
                                    </td>
                                    {pagoEvaluacion.estado === 'pagado' && (
                                        <td>{dayjs(pagoEvaluacion?.fechaPagado).format('DD/MM/YYYY')}</td>
                                    )}
                                    <td className="text-right">
                                        <button type="button" onClick={() => {setVerHistorial(prev => !prev)}}>{verHistorial ? "Ocultar" : "Ver"} Historial</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {verHistorial && (
                            <div className="m-t-20">
                                <p className="text-uppercase bold">Historial de Pagos</p>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Fecha Pagado</th>
                                            <th>Número</th>
                                            <th>Monto</th>
                                            <th>Procesado Por</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pagoEvaluacion.recibos?.map(recibo => (
                                            <tr>
                                                <td>{dayjs(recibo.fechaPagado).format("DD/MM/YYYY")}</td>
                                                <td>{recibo.recibo_ingreso?.numeracion}</td>
                                                <td>{formatCurrency(recibo.pivot?.abonado)}</td>
                                                <td>{recibo.creador?.username}</td>
                                                <td><Link to={getLink(`/imprimir/academico/recibos/${recibo.id}/mensualidad`)} target="_blank"><i className="fa fa-print"></i></Link></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    {pagoEvaluacion.estado !== 'pagado' && (
                        <div className="col-md-2 p-t-30 text-right">
                            <Link to={getLink(`/academico/facturar?estudiante_id=${evaluacion.estudiante?.id}`)} target="_blank" type="button" className="btn btn-primary">Procesar Pago</Link>
                        </div>
                    )}
                </div>
            )}
            <hr />
            <div className='row'>
                <div className='col form-group'>
                    <label className='label form-control-label'>
                        Notas Resultado:
                    </label>
                    <div className='input-group'>
                        <textarea
                            className='text-input form-control'
                            defaultValue={evaluacion.resultado}
                            name='resultado'
                        ></textarea>
                    </div>
                    {errors.resultado && (
                        <small className='help-blockParams form-Text'>
                            {errors.resultado[0]}
                        </small>
                    )}
                </div>
                {/* <div className='col-md-3 form-group'>
                    <label className='label form-control-label'>
                        Pago:
                    </label>
                    <Select
                        key="pago"
                        name='pago'
                        onChange={e => setPago(e.value)}
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        options={[
                            {label: 'Pendiente', value: 'Pendiente'},
                            {label: 'Exonerado', value: 'Exonerado'},
                            {label: 'Procesado', value: 'Procesado'},
                            {label: 'Devuelto', value: 'Devuelto'},
                        ]}
                        defaultValue={evaluacion.pago ? {label: evaluacion.pago, value: evaluacion.pago} : {
                            label: 'Pendiente',
                            value: 'Pendiente'
                        }}
                    />
                    {errors.pago && (
                        <small className='help-blockParams form-Text'>
                            {errors.pago[0]}
                        </small>
                    )}
                </div> */}
                <div className='col-md-3 form-group'>
                    <label className='label form-control-label'>
                        Estado:
                    </label>
                    <Select
                        key="estado"
                        name='estado'
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        isDisabled={evaluacion.estado === 'Inscrito'}
                        menuPlacement='auto'
                        onChange={e => setEstado(e.value)}
                        options={[
                            {label: 'Pendiente', value: 'Pendiente'},
                            {label: 'Aprobado', value: 'Aprobado'},
                            {label: 'Reprovado', value: 'Reprovado'},
                            {label: 'Reevaluar', value: 'Reevaluar'},
                            /* {label: 'Inscrito', value: 'Inscrito'}, */
                        ]}
                        defaultValue={evaluacion.estado ? {label: evaluacion.estado, value: evaluacion.estado} : {
                            label: 'Pendiente',
                            value: 'Pendiente'
                        }}
                    />
                    {errors.telefono && (
                        <small className='help-blockParams form-Text'>
                            {errors.telefono[0]}
                        </small>
                    )}
                </div>
            </div>

            {showPendientePagoAlert && (
                <div className="message m-t-20">
                    <div className="text-center">
                        <strong>Este estudiante  no ha pagado la evaluación o el dinero fue devuelto ¿Desea continuar con la inscripción?</strong>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Form;
