import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import dayjs from "dayjs";
import queryString from "query-string";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
class PrintDescuentos extends Component {
    state = {
        descuentos: [],
    };
    componentDidMount() {
        this.getDescuentos();
    }
    getDescuentos = async () => {
        const response = await this.props.request(
            "descuentos" + window.location.search
        );

        if (response.data) {
            this.setState({
                descuentos: response.data.map(d => {
                    d.cPendientes = d.cuotas.filter(c => c.estado === 'Pendiente');
                    d.cCompletadas = d.cuotas.filter(c => c.estado === 'Pagada');
                    return d;
                }),
            });
            window.print();
        }
    };
    render() {
        const { descuentos } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const query = queryString.parse(window.location.search);
        const {estado, desde, hasta} = query;

        const numero_descuentos = descuentos.length;

        const cuotas_pendientes = descuentos.filter(c => {
            return c.cuotasPendientes > 0 || (c.frecuencia === 'Unico' && c.pendientes > 0);
        });

        const numero_cuotas_pendientes = descuentos.reduce((p,d) => {
            return p + d.pendientes;
        }, 0);

        const cuotas_pagadas = descuentos.filter(c => {
            return c.cuotasCompletadas > 0 || (c.frecuencia === 'Unico' && c.pendientes < 1);
        }, 0);

        const cuotas_a_pagar = cuotas_pendientes.reduce((p, d) => {
            if (d.cuotasPendientes > 0) {
                return p + d.cPendientes.reduce((a, c) => {
                    if ( dayjs(c.fecha).isSameOrAfter(desde) && dayjs(c.fecha).isSameOrBefore(hasta) ) {
                        return a + 1;
                    }
                    return a;
                }, 0);
            }

            return p + 1;
        }, 0);

        const numero_cuotas_pagadas = cuotas_pagadas.reduce((p, d) => {
            if (d.cuotasCompletadas > 0) {
                return p + d.cCompletadas.reduce((a, c) => {
                    if ( c.pagadaEn !== null ) {
                        return a + 1;
                    }
                    return a;
                }, 0);
            }
            return p + 1;
        }, 0);

        const cantidad_prestado = descuentos.reduce((p, d) => {
            return p + parseFloat(d.montoTotal);
        }, 0);

        const total_pagado = cuotas_pagadas.reduce((p, d) => {
            if (d.cuotasCompletadas > 0) {
                return p + d.cCompletadas.reduce((a, c) => a + parseFloat(c.monto), 0);
            }
            return p + parseFloat(d.monto)
        }, 0);

        const total_pendiente = cuotas_pendientes.reduce((p, d) => {
            if (d.cuotasPendientes >  0) {
                return p + d.cPendientes.reduce((a, c) => a + parseFloat(c.monto), 0);
            }
            return p + parseFloat(d.monto)
        }, 0);

        const total_cuotas_correspondientes = cuotas_pendientes.reduce((p, d) => {
            if (d.cuotasPendientes > 0) {
                return p + d.cPendientes.reduce((a, c) => {
                    if ( dayjs(c.fecha).isSameOrAfter(desde) && dayjs(c.fecha).isSameOrBefore(hasta) ) {
                        return a + parseFloat(c.monto);
                    }
                    return a;
                }, 0);
            }
            if (p.cuotasPendientes) {
                return p + parseFloat(d.monto);
            }
            return p;
        }, 0);

        const numero_cuotas_pagadas_periodo = cuotas_pagadas.reduce((p, d) => {
            if (d.cuotasCompletadas > 0) {
                return p + d.cCompletadas.reduce((a, c) => {
                    if ( dayjs(c.fecha).isSameOrAfter(desde) && dayjs(c.fecha).isSameOrBefore(hasta) ) {
                        return a + 1;
                    }
                    return a;
                }, 0);
            }
            if (d.pendientes === 0 && dayjs(d.fecha).isSameOrAfter(desde) && dayjs(d.fecha).isSameOrBefore(hasta)) {
                return p + 1;
            }
            return p;
        }, 0);

        const total_cuotas_pagadas = cuotas_pagadas.reduce((p, d) => {
            if (d.cuotasCompletadas > 0) {
                return p + d.cCompletadas.reduce((a, c) => {
                    if ( dayjs(c.fecha).isSameOrAfter(desde) && dayjs(c.fecha).isSameOrBefore(hasta) ) {
                        return a + parseFloat(c.monto);
                    }
                    return a;
                }, 0);
            }
            if (d.pendientes === 0 && dayjs(d.fecha).isSameOrAfter(desde) && dayjs(d.fecha).isSameOrBefore(hasta)) {
                return p + parseFloat(d.monto);
            }
            return p;
        }, 0);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        {estado === 'todos' && 'Todos los '}
                        Descuentos Programados
                        {estado === 'pendiente' && ' Pendientes'}
                        {estado === 'completado' && ' Completados'}
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-center'>
                        Desde: {dayjs(desde).format('DD MMM, YYYY')} - Hasta: {dayjs(hasta).format('DD MMM, YYYY')}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 p-b-10'>
                        <div className="float-left">
                            <p><strong># Descuentos:</strong> {numero_descuentos}</p>
                            <p><strong># Total cuotas pendientes:</strong> {numero_cuotas_pendientes}</p>
                            <p><strong># Total cuotas pagadas:</strong> {numero_cuotas_pagadas}</p>
                            <p><strong># Cuotas a pagar en periodo:</strong> {cuotas_a_pagar}</p>
                            <p><strong># Cuotas pagadas en periodo:</strong> {numero_cuotas_pagadas_periodo}</p>
                        </div>
                        <div className='float-right text-right'>
                            <p><strong>Cantidad prestado:</strong> {formatCurrency(cantidad_prestado)}</p>
                            <p><strong>Total pagado:</strong> {formatCurrency(total_pagado)}</p>
                            <p><strong>Total pendiente:</strong> {formatCurrency(total_pendiente)}</p>
                            <p><strong>Total a pagar en periodo:</strong> {formatCurrency(total_cuotas_correspondientes)}</p>
                            <p><strong>Total pagado en periodo:</strong> {formatCurrency(total_cuotas_pagadas)}</p>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th style={{ width: "25%" }}>Empleado</th>
                            <th style={{ width: "35%" }}>Detalle</th>
                            <th>Monto</th>
                            <th>Cuota</th>
                            <th className="text-right">Descuentos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {descuentos.map((descuento, index) => {
                            return (
                                <tr key={descuento.id}>
                                    <td>{index+1}</td>
                                    <td>
                                        <strong>
                                            { descuento.empleado }
                                        </strong>
                                        {descuento.telefono && <div>
                                            Tel: {descuento.telefono}
                                        </div>}
                                    </td>
                                    <td>
                                        <small>
                                            Frecuencia: {
                                                descuento.frecuencia
                                            }
                                        </small>
                                        <div className="descuento__descripcion">
                                            {
                                                descuento.descripcion
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div><strong>{formatCurrency(descuento.montoTotal)}</strong></div>
                                        # Cuotas: {descuento.cuotas?.length > 0 ? descuento.cuotas.length : 1}
                                    </td>
                                    <td>
                                        <div><strong>{formatCurrency(descuento.monto)}</strong></div>
                                        Pendientes: {descuento.cuotasPendientes > 0 ? descuento.cuotasPendientes : (descuento.pendientes > 0 ? 1 : 0)}
                                    </td>
                                    <td  className="text-right d-flex justify-content-end">
                                    {descuento.cuotasCompletadas > 0 && <div className="m-r-15">
                                        <strong>Último:{' '}</strong>
                                        <div>
                                            {descuento.cuotasCompletadas && dayjs(
                                                descuento.cCompletadas[0].fecha
                                            ).format(
                                                "YYYY-MM-DD"
                                            )}
                                        </div>
                                    </div>}
                                    {descuento.cuotasPendientes > 0 && <div>
                                        <strong>Próximo:{' '}</strong>
                                        <div>
                                            {descuento.cuotasPendientes > 0 && dayjs(
                                                descuento.cPendientes[0].fecha
                                            ).format(
                                                "YYYY-MM-DD"
                                            )}
                                        </div>
                                    </div>}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintDescuentos));
