import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { auth } from "../../../../utils/auth";
import { formatCurrency } from "../../../../utils/helpers";
import { request } from "../../../../utils/request";

export const FichaEstudiante = () => {

    const { id } = useParams();
    const [state, setState] = useState({
        estudiante: {}
    });

    const getEstudiante = async () => {
        const response = await request(
            `academico/estudiantes/${id}`
        );

        if (response.data) {
            setState({
                ...state,
                estudiante: response.data,
            });
            //window.print();
        }
    };

    useEffect(() => {
        getEstudiante();
    }, [id])

    const { estudiante } = state;
    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
        (suc) => suc.id === user.sucursalActual
    )[0];

    return (<div className="p-2 invoice recibo-print">
        <div className='clear-both p-t-30 p-b-10'>
            <div className="float-sm-left sucursal-info">
                {sucursal.logo ? (<img alt="logo" className="mb-2 factura-logo" src={sucursal.logo[0].url} width="270px" />) : (
                    <h3 style={{ lineHeight: "12px", marginBottom: "3px" }}>
                        {sucursal.nombre}
                    </h3>
                )}
                <div
                    className="mb-0 mb-sm-0"
                    style={{ lineHeight: "15px", fontSize: "13px" }}
                >
                    RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
                </div>
                <div
                    className="mb-2 mb-sm-0"
                    style={{ lineHeight: "15px", fontSize: "13px" }}
                >
                    {sucursal.direccion}
                </div>
            </div>

            <div className="float-sm-right text-sm-right">
                <h3
                    className="d-block"
                    style={{
                        fontSize: "15px",
                        lineHeight: "12px",
                        marginBottom: "3px",
                    }}
                >
                    Ficha de Estudiante
                </h3>
                <div
                    className="mb-2 invoice-fecha d-block"
                    style={{ fontSize: "small" }}
                >
                    <small>Fecha: {dayjs(estudiante.fecha).format("DD/MM/YYYY")}</small>
                </div>
            </div>
        </div>
        <div className="table-wrapper m-t-100">
            <div className="row">
                <div className="col-md-2">
                    <img src={estudiante.foto} />
                </div>
                <div className="col-md-10">
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>
                                    <small className="uppercase">Nombre(s):</small>
                                    <div>{estudiante.nombre}</div>
                                </td>
                                <td>
                                    <small className="uppercase">Primer Apellido:</small>
                                    <div>{estudiante.primerApellido}</div>
                                </td>
                                <td>
                                    <small className="uppercase">Segundo Apellido:</small>
                                    <div>{estudiante.segundoApellido}</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <small className="uppercase">Cédula/NUI:</small>
                                    <div>{estudiante.identificacion}</div>
                                </td>
                                <td>
                                    <small className="uppercase">Nacionalidad:</small>
                                    <div>{estudiante.nacionalidad}</div>
                                </td>
                                <td>
                                    <small className="uppercase">Lugar Nacimiento:</small>
                                    <div>{estudiante.lugarNacimiento}</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <small className="uppercase">Sexo:</small>
                                    <div>{estudiante.sexo}</div>
                                </td>
                                <td>
                                    <small className="uppercase">Tipo de Sangre:</small>
                                    <div>{estudiante.grupoSanguineo}</div>
                                </td>
                                <td>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <small className="uppercase">Fecha Nacimiento:</small>
                                            <div>{dayjs(estudiante.fechaNacimiento).format("DD/MM/YYYY")}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <small className="uppercase">Edad:</small>
                                            <div>{dayjs().diff(estudiante.fechaNacimiento, 'year')} años</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <small className="uppercase">Dirección:</small>
                                    <div>{estudiante.tutores?.reduce((p,c) => p.residencia ? p : c, {}).direccion}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <section>
                <h3 className="uppercase m-t-20">Datos Circunscripción</h3>
                <table className='table'>
                    <tbody>
                        <tr>
                            <td>
                                <small className="uppercase">ID:</small>
                                <div>{estudiante.idCircunscripcion}</div>
                            </td>
                            <td>
                                <small className="uppercase">Municipio:</small>
                                <div>{estudiante.municipioCircunscripcion}</div>
                            </td>
                            <td>
                                <small className="uppercase">Oficina:</small>
                                <div>{estudiante.oficinaCircunscripcion}</div>
                            </td>
                            <td>
                                <small className="uppercase">Libro:</small>
                                <div>{estudiante.libroCircunscripcion}</div>
                            </td>
                            <td>
                                <small className="uppercase">Folio:</small>
                                <div>{estudiante.folioCircunscripcion}</div>
                            </td>
                            <td>
                                <small className="uppercase">Acta:</small>
                                <div>{estudiante.actaCircunscripcion}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section>
                <h3 className="uppercase m-t-20">Datos Registro</h3>
                <table className='table' style={{tableLayout: "fixed"}}>
                    <tbody>
                        <tr>
                            <td>
                                <small className="uppercase">Referencia Académica:</small>
                                <div>{estudiante.referenciaAcademica || "--"}</div>
                            </td>
                            <td>
                                <small className="uppercase">Matrícula:</small>
                                <div>{estudiante.matricula || "--"}</div>
                            </td>
                            <td>
                                <small className="uppercase">Estado:</small>
                                <div>{estudiante.estado || "--"}</div>
                            </td>
                            <td>
                                <small className="uppercase">Teléfono:</small>
                                <div>{estudiante.telefono || "--"}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <small className="uppercase">RNE:</small>
                                <div>{estudiante.rne || "--"}</div>
                            </td>
                            <td>
                                <small className="uppercase">Usuario Progrentis:</small>
                                <div>{estudiante.usuarioProgrentis || "--"}</div>
                            </td>
                            <td>
                                <small className="uppercase">Correo Electrónico:</small>
                                <div>{estudiante.correo || "--"}</div>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section>
                <h3 className="uppercase m-t-20">Datos Inscripción</h3>
                <table className='table' style={{tableLayout: "fixed"}}>
                    <tbody>
                        <tr>
                            <td>
                                <small className="uppercase">Tipo Inscripción:</small>
                                <div>{estudiante.curso?.producto?.nombre || "--"}</div>
                            </td>
                            <td>
                                <small className="uppercase">Fecha Inscripción:</small>
                                <div>{estudiante.curso?.fechaInscripcion ? dayjs(estudiante.curso.fechaInscripcion).format('DD/MM/YYYY') : "--"}</div>
                            </td>
                            <td>
                                <small className="uppercase">Fecha Retiro:</small>
                                <div>{estudiante.curso?.fechaRetiro ? dayjs(estudiante.curso.fechaRetiro).format('DD/MM/YYYY') : "--"}</div>
                            </td>
                            <td>
                                <small className="uppercase">Año Escolar:</small>
                                <div>{estudiante.curso?.periodoAcademico?.nombre || "--"}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <small className="uppercase">Grado:</small>
                                <div>{estudiante.curso?.grado?.nombre || "--"}</div>
                            </td>
                            <td>
                                <small className="uppercase">Sección:</small>
                                <div>{estudiante.curso?.seccion?.nombre || "--"}</div>
                            </td>
                            <td>
                                <small className="uppercase">Salida Optativa:</small>
                                <div>{estudiante.curso?.salidaOptativa?.nombre || "--"}</div>
                            </td>
                            <td>
                                <small className="uppercase">Beca:</small>
                                <div>{estudiante.curso?.beca?.nombre || "--"}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
            {estudiante.tutores?.length > 0 && (
                <section>
                    <h3 className="uppercase m-t-20">Padres/Tutores</h3>
                    <table className='table'>
                        <tbody>
                            {estudiante.tutores?.map(tutor => (
                                <tr key={tutor.id}>
                                    <td>
                                        <img
                                            src={tutor.foto}
                                            alt={tutor.nombreCompleto}
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                borderRadius: '20px',
                                                marginRight: '15px',
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <small className="uppercase">Nombre:</small>
                                        <div>{tutor.nombreCompleto}</div>
                                    </td>
                                    <td>
                                        <small className="uppercase">Parentesco:</small>
                                        <div>{tutor.parentesco}</div>
                                    </td>
                                    <td>
                                        <small className="uppercase">Residencia:</small>
                                        <div>{tutor.residencia ? 'Si' : 'No'}</div>
                                    </td>
                                    <td>
                                        <small className="uppercase">Notificar:</small>
                                        <div>{tutor.notificar ? 'Si' : 'No'}</div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            )}
            {estudiante.relacionados?.length > 0 && (
                <section>
                    <h3 className="uppercase m-t-20">Estudiantes Relacionados</h3>
                    <table className='table' style={{tableLayout: "fixed"}}>
                        <tbody>
                            {estudiante.relacionados?.map(relacionado => (
                                <tr key={relacionado.id}>
                                    <td>
                                        <img
                                            src={relacionado.foto}
                                            alt={relacionado.nombreCompleto}
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                borderRadius: '20px',
                                                marginRight: '15px',
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <small className="uppercase">Nombre:</small>
                                        <div>{relacionado.nombreCompleto}</div>
                                    </td>
                                    <td>
                                        <small className="uppercase">Parentesco:</small>
                                        <div>{relacionado.parentesco}</div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            )}
            {estudiante.comentarios?.length > 0 && (
                <section>
                    <h3 className="uppercase m-t-20">Observaciones</h3>
                    <table className='table' style={{tableLayout: "fixed"}}>
                        <tbody>
                            {estudiante.comentarios.map(comentario => (
                                <tr key={comentario.id}>
                                    <td>
                                        {comentario.descripcion}
                                        <div><small>Por {comentario.user} - {dayjs(comentario.created_at).format('MM/DD/YYYY')}</small></div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            )}
        </div>

    </div>);
}

export default FichaEstudiante;
