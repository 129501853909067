/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../../../../utils/request";
import { formatCurrency, getSucursal, money } from "../../../../utils/helpers";
import dayjs from "dayjs";

const tipos_impuesto = {
	itbis: "Itbis",
	otro: "Otro",
	isc: "ISC",
	propina_legal: "Propina",
	no_facturable: "No facturable",
	exento: "Exento",
};

const tiposComprobante = [
	"B01",
	"B02",
	"B14",
	"B15",
	"B16",
	"B11",
	"B03",
	"B11",
];

const tipoITBIS = (impuesto) => {
	if (!impuesto) return "";
	if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0.16) {
		return "I1";
	} else if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0.18) {
		return "I2";
	} else if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0) {
		return "IE";
	}
};

const getDiasPlazo = (fecha, fecha_vencimiento) => {
	fecha = new Date(fecha);
	fecha_vencimiento = new Date(fecha_vencimiento);

	let difference = fecha.getTime() - fecha_vencimiento.getTime();
	let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
	return Math.abs(TotalDays);
};

const limitVendedorFullName = (name) => {
	name = name.split(" ");

	if (name.length <= 3) return name.join(" ");

	return name.slice(0, 3).join(" ");
};

const PrintSingleCotizacion = () => {
	const { id } = useParams();

	const [factura, setFactura] = useState({
		productos: [],
	});
	const [sucursal, setSucursal] = useState({});
	const [infoBlock2Margin, setInfoBlock2Margin] = useState(10);

	const [sumatoriaFinal, setSumatoriaFinal] = useState({
		impuestos: {},
	});
	const [impuestosTotal, setImpuestosTotal] = useState(0);
	const [isMobileDevice, setIsMobileDevice] = useState(false);

	const getFactura = () => {
		request("cotizaciones/" + id).then((res) => {
			setFactura(res.data);
			setTimeout(() => {
				window.print();
			}, 1000);
		});
	};

	const calcularImpuestos = (productos) => {
		let impuestos = {};

		if (productos) {
			productos.forEach((producto) => {
				if (
					!tipos_impuesto[producto.impuesto?.tipo] ||
					producto.impuesto?.porcentaje === 0
				)
					return;

                const key = `${tipos_impuesto[producto.impuesto.tipo]} ${
                    producto.impuesto.porcentaje * 100
                }%`;
                const descuento = producto.descuento ? producto.cantidad * producto.importe * (producto.descuento / 100) : 0;

				if (!Object.hasOwnProperty.call(impuestos, key)) {
					impuestos[key] = 0;
				}

				impuestos[key] +=
                    producto.cantidad * (
                        (producto.importe - descuento) * producto.impuesto.porcentaje
                    );
			});
		}

		return impuestos;
	};

	useEffect(() => {
		getFactura();
		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);

		// check if device is not a PC
		let isMobile = navigator.userAgent.toLowerCase().match(/mobile/i),
			isTablet = navigator.userAgent.toLowerCase().match(/tablet/i),
			isAndroid = navigator.userAgent.toLowerCase().match(/android/i),
			isiPhone = navigator.userAgent.toLowerCase().match(/iphone/i),
			isiPad = navigator.userAgent.toLowerCase().match(/ipad/i);

		if (isMobile || isTablet || isAndroid || isiPhone || isiPad) {
			setIsMobileDevice(true);
		}
	}, []);
	useEffect(() => {
		setSumatoriaFinal((state) => ({
			...state,
			impuestos: calcularImpuestos(factura.productos),
		}));

		if (isMobileDevice) return;
		// This is for calculate the margin of the second column of client info. that way the last row of each column will be alined
		const infoBlock1 = document.getElementById("info-block-1");
		const infoBlock2 = document.getElementById("info-block-2");
		const count =
			infoBlock2.childElementCount - infoBlock1.childElementCount;
		if (count >= 5 && !factura.logo && !sucursal.logo) {
			setInfoBlock2Margin((count * 25 - 40) * -1);
		} else {
			setInfoBlock2Margin((count * 25 + 10) * -1);
		}
	}, [factura]);
	useEffect(() => {
		let suma = 0;
		for (const key in sumatoriaFinal) {
			if (sumatoriaFinal.hasOwnProperty(key)) {
				const impuestos = sumatoriaFinal[key];

				for (const key2 in impuestos) {
					if (Object.hasOwnProperty.call(impuestos, key2)) {
						suma += impuestos[key2];
					}
				}
			}
		}

		setImpuestosTotal(suma);
	}, [sumatoriaFinal]);
	return (
		<CotizacionTemplate
			factura={factura}
			sucursal={sucursal}
			infoBlock2Margin={infoBlock2Margin}
			sumatoriaFinal={sumatoriaFinal}
			impuestosTotal={impuestosTotal}
			isMobileDevice={isMobileDevice}
		/>
	);
};

export const CotizacionTemplate = ({
	factura,
	sucursal,
	infoBlock2Margin,
	sumatoriaFinal,
	impuestosTotal,
	isMobileDevice,
}) => {
	return (
		<>
			{isMobileDevice ? (
				<MobileLayout
					factura={factura}
					sucursal={sucursal}
					sumatoriaFinal={sumatoriaFinal}
				/>
			) : (
				<>
					<DesktopLayout
						factura={factura}
						sucursal={sucursal}
						sumatoriaFinal={sumatoriaFinal}
						impuestosTotal={impuestosTotal}
						infoBlock2Margin={infoBlock2Margin}
					/>
				</>
			)}
		</>
	);
};

const MobileLayout = ({
	factura,
	sucursal,
	sumatoriaFinal,
	isFactura = () => false,
	status,
}) => {
	return (
		<>
			<style>
				{`
					@page {
						size: 90mm 300mm !important;
						margin-top: 30px !important;
						margin-bottom: 0px !important;
						margin-left: 0px !important;
						margin-right: 0px !important;
					}
					.invoice {
						width: 125mm;
						 font-size: large !important;
					 }
					 body {
						width: 100% !important;
					}
					.invoice-table {
						display: none;
					}
					.sm-invoice-table {
						display: block;
					}

					.invoice h3 {
						margin-bottom: 0.1rem;
					}
					.invoice h3.signature-title {
						font-size: 0.9rem;
						font-weight: 600 !important;
					}
					.client-info-title {
						font-size: 19px;
						font-weight: 600 !important;
					}
					.info-cliente-responsive .info-client__response {
						font-size: 18px;
						line-height: 18px !important;
					}
					.sucursal-info h3 {
						font-size: 23px !important;
						margin-bottom: 8px !important;
						font-weight: 600 !important;
					}
					.invoice-name {
						font-weight: 600 !important;
					}
					.sucursal-info div, .invoice-fecha {
						font-size: 18px !important;
					}
					.sm-invoice-table > div.row {
					 	font-size: 17.5px !important;
					}

					.summary-text {
						font-size: 20px !important;
						line-height: 20px !important;
					}
					.info-cliente-responsive {
						line-height: 18px !important;
						margin-bottom: 1rem !important;
						font-size: 20px !important;
						margin-left: 5px !important;
					}
					.info-cliente-responsive .row{
						margin-bottom: 6px;
					}
					.sucursal-name{
						display:none;
					}

					@media(max-width: 575px){
						.info-cliente-responsive, .info-client__response{
							font-size: 15.5px !important;
							text-transform: uppercase;
						}
						.sucursal-name{
							display:block;
						}
						.factura-logo{
							display:none;
						}
						.name-sucursal{
							display:none;
						}

						.sm-invoice-table > div.row {
							font-size: 15.5px !important;
					   	}
						// .sm-invoice-table > div.row :nth-child(1){
						// 	flex: 0 0 45% !important;
						// 	max-width: 45% !important;
						// }
						// .sm-invoice-table > div.row :nth-child(2){
						// 	flex: 0 0 27% !important;
						// 	max-width: 27% !important;
						// }
						// .sm-invoice-table > div.row :nth-child(3){
						// 	flex: 0 0 27% !important;
						// 	max-width: 27% !important;
						// }

						.invoice-itbis-info p{
							font-size:small !important;
							line-height: 13px !important;

						}
						.client-info-title, .invoice-name{
							font-size: 15.5px !important;
						}

						.info-cliente-responsive .row{
							margin-bottom: 0 !important;
						}
						.summary >div.row :nth-child(1), .summary >div>div.row :nth-child(1){
							text-align:right !important;
						}

						.justify-content-between :nth-child(2){
							text-align:left !important;
							max-width: 33% !important;
						}
						.info-cliente-responsive > div.row :nth-child(1){
							flex: 0 0 32.666667% !important;
							max-width: 32.666667% !important;
						}
						.info-cliente-responsive > div.row :nth-child(2){
							flex: 0 0 59% !important;
							max-width: 59% !important;
							padding-left:7px !important;
						}
						.invoice h3.signature-title {
							font-size: 15.5px !important;
						}
						.summary-text{
							font-size:15.5px !important;
							// max-width:39% !important;
							width:auto !important;
							flex:auto;
							padding-left: 5px !important;
							padding-right: 2px !important;
						}
						.summary :nth-child(3) div{
							font-size: 16px !important;
						}
						.justify-content-between{
							justify-content: flex-end !important;
						}
					}
					@media(max-width: 350px){

						.justify-content-between{
							justify-content: flex-end !important;
						}
						.info-cliente-responsive > div.row :nth-child(1){
							flex: 0 0 32.666667% !important;
							max-width: 32.666667% !important;
						}
						.info-cliente-responsive > div.row :nth-child(2){
							flex: 0 0 59% !important;
							max-width: 59% !important;
							padding-left: 7px !important;
						}
					}
					@media(max-width: 325px){

						.info-cliente-responsive > div.row :nth-child(1){
							flex: 0 0 48.666667% !important;
							max-width: 48.666667% !important;
						}
						.info-cliente-responsive > div.row :nth-child(2){
							flex: 0 0 49% !important;
							max-width: 49% !important;
							padding-left:7px !important;
						}

						.sucursal-info h3 {
							font-size: 20px !important;
							line-height: 20px !important;
						}
						.info-client__response{
							max-width: max-content !important;
						}
						.text-right{
							width: auto;
							max-width: max-content;
						}
					}
				`}
			</style>
			<div className="p-2 invoice recibo-print">
				<div className="clear-both p-t-30 p-b-10">
					<div className="sucursal-info sucursal-info__mobile">
						<h3 className="sucursal-name-sm">{sucursal.nombre}</h3>
						{sucursal.logo ? (
							<img
								alt="logo"
								className="mb-2 factura-logo"
								src={sucursal.logo[0].url}
								width="270px"
							/>
						) : factura.logo ? (
							<img
								alt="logo"
								className="mb-2 factura-logo"
								src={factura.logo[0].url}
								width="270px"
							/>
						) : (
							<h3
								className="sucursal-name-lg"
								style={{
									lineHeight: "16px",
									marginBottom: "3px",
								}}
							>
								{sucursal.nombre}
							</h3>
						)}
						<div
							className="mb-0"
							style={{ lineHeight: "16px", fontSize: "23px" }}
						>
							RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
						</div>
						<div
							className="mb-2"
							style={{ lineHeight: "16px", fontSize: "13px" }}
						>
							{sucursal.direccion}
						</div>
					</div>

					<div className="">
						<div
							className="mb-2 invoice-fecha d-block"
							style={{ fontSize: "medium", lineHeight: "16px" }}
						>
							<div className="d-flex fecha-invoice">
								<div className="d-block">
									<div className="d-flex">
										Fecha:{" "}
										{dayjs(factura.fecha).format(
											"DD/MM/YYYY"
										)}
									</div>
									{getDiasPlazo(
										factura.fecha,
										factura.fecha_vencimiento
									) > 0 ? (
										<>
											<span>
												Plazo:{" "}
												{getDiasPlazo(
													factura.fecha,
													factura.fecha_vencimiento
												) || 0}{" "}
												días
											</span>
											<br />
										</>
									) : (
										<>
											<span>Plazo: Contado</span>
											<br />
										</>
									)}
								</div>
								<div className="d-block">
									<div className="d-flex">
										<h3
											style={{
												fontSize: "18px",
												lineHeight: "14px",
												marginRight: "3px",
											}}
											className="d-block invoice-name"
										>
											Cotización
										</h3>
										<div
											className="d-block"
											style={{
												fontSize: "medium",
												lineHeight: "16px",
											}}
										>
											<span>
												{factura.entidad ===
													"factura" &&
												tiposComprobante.findIndex(
													(tipo) =>
														tipo ===
														factura.numero_comprobante.substring(
															0,
															3
														)
												) >= 0
													? "NCF: "
													: "COD. "}
												{factura.entidad ===
													"proforma" && "COD. "}
											</span>
											{factura.numero_comprobante}
											<br />
											{factura.numeracion
												?.fecha_vencimiento && (
												<>
													<span>FV:</span>{" "}
													{dayjs(
														factura.numeracion
															?.fecha_vencimiento
													).format("DD/MM/YYYY")}
												</>
											)}
										</div>
									</div>
									<span className="d-block client-info-title pt-2">
										{status}
									</span>
								</div>
							</div>

							{factura.tipo === "contado" &&
							factura.estado === "pendiente" ? (
								<span>Estado: Pendiente</span>
							) : (
								factura.tipo === "contado" &&
								factura.estado === "cancelada" && (
									<span>Estado: Cancelada</span>
								)
							)}
							{factura.estado === "pagada" && (
								<span>
									Método de pago:{" "}
									<span className="text-capitalize">
										{factura.tipo_pago !== ""
											? factura.tipo_pago
											: "Efectivo"}
									</span>
								</span>
							)}
						</div>
					</div>
				</div>

				<div className="info-cliente-responsive d-block">
					{factura.cliente && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Cliente:
								</span>
							</div>
							<div
								className="col-7 info-client__response text-uppercase"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.nombre}
							</div>
						</div>
					)}
					{factura.cliente?.identificacion && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									RNC/Cédula:
								</span>
							</div>
							<div
								className="col-7 info-client__response text-uppercase"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.identificacion}
							</div>
						</div>
					)}
					{factura.cliente?.encargado && (
						<div className="row">
							<div className="col-4 text-right px-1 client-info-title">
								Responsable:
							</div>
							<div
								className="col-7 info-client__response text-uppercase"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.encargado}
							</div>
						</div>
					)}
					{factura.cliente?.telefono && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Teléfono:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.telefono}
							</div>
						</div>
					)}
					{factura.cliente?.direccion && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Dirección:
								</span>
							</div>
							<div
								className="col-7 info-client__response text-uppercase"
								style={{ lineHeight: "23px" }}
							>
								{factura.cliente?.direccion}
							</div>
						</div>
					)}
					{factura.vendedor?.nombre && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Vendedor:
								</span>
							</div>
							<div className="col-7 info-client__response text-uppercase">
								{limitVendedorFullName(
									factura.vendedor?.nombre,
									factura.vendedor?.apellido
								)}
							</div>
						</div>
					)}
				</div>

				<h3 className="table-title"></h3>
				{/* PRODUCTOS RESPONSIVE */}
				<div className="sm-invoice-table">
					<div className="row" style={{ fontWeight: "600" }}>
						<div className="col-5">DESCRIPCIÓN</div>
						<div className="col-3 px-0">IMPUESTOS</div>
						<div className="col-4" style={{ whiteSpace: "nowrap" }}>
							TOTAL
						</div>
					</div>

					{factura.productos?.map((producto) => {
						return (
							<div
								className="row"
								style={{
									fontSize: "small",
									marginBottom: "5px",
								}}
								key={producto.id}
							>
								<div
									className="col-5"
									style={{ lineHeight: "16px" }}
								>
									{`${producto.cantidad} x ${formatCurrency(
										producto.precio_facturado,
										""
									)}`}
									<br />
									{producto.producto?.nombre}
									<br />
									REF: {producto.producto?.referencia}
								</div>
								<div className="col-3 px-0">
									<span className="fw-600">
										{tipoITBIS(producto.impuesto)}
									</span>
									{formatCurrency(
										producto.precio_facturado -
											producto.importe
									)}
								</div>
								<div className="col-3 pr-0">
									{formatCurrency(producto.total, "$")}{" "}
								</div>
							</div>
						);
					})}
				</div>

				<div className="summary pt-3">
					{factura.descuento > 0 && (
						<div className="row justify-content-between">
							<div
								className="col-2 summary-text"
								style={{
									maxWidth: "8rem",
									lineHeight: "16px",
									textAlign: "end",
									fontWeight: "600",
								}}
							>
								Descuento
							</div>
							<div
								className="col-2 text-right summary-text"
								style={{ maxWidth: "9rem", lineHeight: "16px" }}
							>
								-{formatCurrency(factura.descuento)}
							</div>
						</div>
					)}
					<div className="row justify-content-between justify-content-md-end">
						<div
							className="col-2 summary-text"
							style={{
								maxWidth: "8rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							Subtotal:
						</div>
						<div
							className="col-2 text-right summary-text"
							style={{ maxWidth: "9rem", lineHeight: "16px" }}
						>
							{formatCurrency(
								factura.subtotal
							)}
						</div>
					</div>
					<div className="d-block">
						{Object.keys(sumatoriaFinal.impuestos).map(
							(impuesto, index) => {
								return (
									<div
										className="row justify-content-between"
										key={index}
									>
										<div
											className="col summary-text"
											style={{
												maxWidth: "8rem",
												lineHeight: "16px",
												fontWeight: "600",
											}}
										>
											{impuesto}
											{":"}
										</div>
										<div
											className="col-2 text-right summary-text"
											style={{
												maxWidth: "9rem",
												lineHeight: "16px",
											}}
										>
											{formatCurrency(
												sumatoriaFinal.impuestos[
													impuesto
												],
												"$"
											)}
										</div>
									</div>
								);
							}
						)}
					</div>

					<div className="row justify-content-between">
						<div
							className="col summary-text"
							style={{
								maxWidth: "12rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							TOTAL A PAGAR:
						</div>
						<div
							className="col-2 text-right summary-text"
							style={{
								maxWidth: "9rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							{formatCurrency(factura.total, "$")}
						</div>
					</div>
					{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
						<hr className="mb-0" />
					)}

					<div className="invoice-itbis-info">
						{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
							<p
								style={{
									lineHeight: "16px",
									fontSize: "small",
								}}
							>
								{Object.keys(sumatoriaFinal.impuestos).includes(
									"Itbis 16%"
								) && (
									<>
										<span>
											Items con I1 son gravados con 16%
											ITBIS
										</span>
										<br />
									</>
								)}
								{Object.keys(sumatoriaFinal.impuestos).includes(
									"Itbis 18%"
								) && (
									<span>
										Items con I2 son gravados con 18% ITBIS
									</span>
								)}
							</p>
						)}

						{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
							<div className="row justify-content-between">
								<div
									className="col-6 pr-1"
									style={{
										maxWidth: "11rem",
										fontSize: "small",
										lineHeight: "16px",
										fontWeight: "600",
										whiteSpace: "nowrap",
									}}
								>
									TOTAL IMPUESTOS
								</div>
								<div
									className="col-2"
									style={{
										maxWidth: "9rem",
										fontSize: "small",
										lineHeight: "16px",
										fontWeight: "600",
									}}
								>
									{formatCurrency(factura.impuestos, "$")}
								</div>
							</div>
						)}
					</div>
				</div>

				<hr className="d-block mt-0" />
				{factura.notas && (
					<>
						<p style={{ fontWeight: "600", fontSize: "1rem" }}>
							Notas:
						</p>
						<p
							className="col-12 pl-0 mb-3"
							style={{
								fontSize: "1rem",
								whiteSpace: "pre-line",
								lineHeight: "normal",
							}}
						>
							{factura.notas}
						</p>
					</>
				)}

				{factura.plantilla?.pie_pagina && (
					<div
						className="col-12 pt-5 text-center d-flex justify-content-center"
						style={{
							fontWeight: "600",
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						<p className="text-center pie-pagina">
							{factura.plantilla?.pie_pagina}
						</p>
					</div>
				)}
			</div>
		</>
	);
};

const DesktopLayout = ({
	factura,
	sucursal,
	infoBlock2Margin,
	sumatoriaFinal,
	impuestosTotal,
}) => {
	return (
		<div className="p-2 invoice recibo-print">
			<div className="clear-both p-t-30 p-b-10">
				<div className="float-sm-left sucursal-info">
					{sucursal.logo ? (
						<img
							alt="logo"
							className="mb-2 factura-logo"
							src={sucursal.logo[0].url}
							width="270px"
						/>
					) : factura.logo ? (
						<img
							alt="logo"
							className="mb-2 factura-logo"
							src={factura.logo[0].url}
							width="270px"
						/>
					) : (
						<h3 style={{ lineHeight: "12px", marginBottom: "3px" }}>
							{sucursal.nombre}
						</h3>
					)}
					<div
						className="mb-0 mb-sm-0"
						style={{ lineHeight: "15px", fontSize: "13px" }}
					>
						RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
					</div>
					<div
						className="mb-2 mb-sm-0"
						style={{ lineHeight: "15px", fontSize: "13px" }}
					>
						{sucursal.direccion}
					</div>
				</div>

				<div className="float-sm-right text-sm-right">
					<div
						className="mb-2 invoice-fecha d-block d-sm-none"
						style={{ fontSize: "small", lineHeight: "15px" }}
					>
						Fecha: {dayjs(factura.fecha).format("DD/MM/YYYY")}
						<br />
						{getDiasPlazo(
							factura.fecha,
							factura.fecha_vencimiento
						) > 0 ? (
							<>
								<span>
									Plazo:{" "}
									{getDiasPlazo(
										factura.fecha,
										factura.fecha_vencimiento
									) || 0}{" "}
									días
								</span>
								<br />
							</>
						) : (
							<>
								<span>Plazo: Contado</span>
								<br />
							</>
						)}
					</div>
					<h3
						style={{
							fontSize: "15px",
							lineHeight: "12px",
							marginBottom: "3px",
						}}
						className="d-block d-sm-none"
					>
						Cotización
					</h3>
					<div
						className="d-block d-sm-none"
						style={{ fontSize: "small", lineHeight: "12px" }}
					>
						<span>
							{factura.entidad === "factura" &&
							tiposComprobante.findIndex(
								(tipo) =>
									tipo ===
									factura.numero_comprobante.substring(0, 3)
							) >= 0
								? "NCF: "
								: "COD. "}
							{factura.entidad === "proforma" && "COD. "}
						</span>
						{factura.numero_comprobante}
						<br />
						{factura.numeracion?.fecha_vencimiento && (
							<>
								<span>FV:</span>{" "}
								{dayjs(
									factura.numeracion?.fecha_vencimiento
								).format("DD/MM/YYYY")}
							</>
						)}
					</div>
				</div>
			</div>

			<div className="info-cliente-responsive d-block d-sm-none">
				{factura.cliente && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Cliente:</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.nombre}
						</div>
					</div>
				)}
				{factura.cliente?.identificacion && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								RNC/Cédula:
							</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.identificacion}
						</div>
					</div>
				)}
				{factura.cliente?.encargado && (
					<div className="row">
						<div className="col-4 text-right px-1 client-info-title">
							Responsable:
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.encargado}
						</div>
					</div>
				)}
				{factura.cliente?.telefono && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Teléfono:</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.telefono}
						</div>
					</div>
				)}
				{factura.cliente?.direccion && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								Dirección:
							</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.direccion}
						</div>
					</div>
				)}
				{factura.vendedor?.nombre && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Vendedor:</span>
						</div>
						<div className="col-7">
							{limitVendedorFullName(
								`${factura.vendedor?.nombre} ${factura.vendedor?.apellido}`
							)}
						</div>
					</div>
				)}
			</div>

			<div className="clear-both info-row p-t-10 p-b-10 d-none d-sm-flex">
				<div className="info-block" style={{ width: "65%" }}>
					<table>
						<tbody id="info-block-1">
							{factura.cliente?.nombre && (
								<tr>
									<td
										width="120px"
										className="client-info-title text-uppercase text-right"
									>
										Cliente:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{factura.cliente?.nombre}
									</td>
								</tr>
							)}
							{factura.cliente?.identificacion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										RNC/Cédula:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.cliente?.identificacion}
									</td>
								</tr>
							)}
							{factura.cliente?.encargado && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Responsable:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.cliente?.encargado}
									</td>
								</tr>
							)}
							{factura.cliente?.telefono && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Teléfono:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.cliente?.telefono}
									</td>
								</tr>
							)}
							{factura.cliente?.direccion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Dirección:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{factura.cliente?.direccion}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<div
					className="info-block"
					style={{ marginTop: infoBlock2Margin }}
				>
					<table>
						<tbody id="info-block-2">
							<tr>
								<td colSpan={2} style={{ paddingLeft: "18%" }}>
									<h3
										style={{
											fontSize: "0.9rem",
											marginBottom: "3px",
											paddingBottom: "10px",
										}}
									>
										COTIZACIÓN
									</h3>
								</td>
							</tr>
							{factura.fecha && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Fecha:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{dayjs(factura.fecha).format(
											"DD/MM/YYYY"
										)}
									</td>
								</tr>
							)}
							{factura.numero_comprobante && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										No.:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.numero_comprobante}
									</td>
								</tr>
							)}

							{getDiasPlazo(
								factura.fecha,
								factura.fecha_vencimiento
							) > 0 ? (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Plazo:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{getDiasPlazo(
											factura.fecha,
											factura.fecha_vencimiento
										) || 0}{" "}
										días
									</td>
								</tr>
							) : (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Plazo:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										Contado
									</td>
								</tr>
							)}

							{factura.numeracion?.fecha_vencimiento && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										FV:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{dayjs(
											factura.numeracion
												?.fecha_vencimiento
										).format("DD/MM/YYYY")}
									</td>
								</tr>
							)}
							{factura.vendedor?.nombre && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Vendedor:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{limitVendedorFullName(
											`${factura.vendedor?.nombre} ${factura.vendedor?.apellido}`
										)}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			<div className="table-wrapper">
				<h3 className="table-title"></h3>
				<table className="table d-none d-sm-table">
					<thead>
						<tr className="text-uppercase">
							<th width="12%">Código/Ref.</th>
							<th width="25%">Descripción</th>
							<th width="8%">Cantidad</th>
							<th width="8%">Unidad</th>
							<th>Importe</th>
							<th>Impuesto</th>
							<th>Precio</th>
							{factura.descuento > 0 && <th>Descuento</th>}
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{factura?.productos?.map((producto) => {
                            const descuento =
                                producto.cantidad *
                                (producto.importe +
                                    producto.importe *
                                        producto.impuesto?.porcentaje) *
                                (producto.descuento / 100);

                            const descuentoReal =
                                (producto.importe *
                                (producto.descuento / 100))
                                * producto.cantidad;
							return (
								<tr key={producto.id}>
									<td>
										<div style={{ lineHeight: 1.2 }}>
											<div>
												{producto.producto.referencia}
											</div>
										</div>
									</td>
									<td>{producto.producto?.nombre}</td>
									<td>{producto.cantidad}</td>
									<td>{producto.producto?.unidad}</td>
									<td>{formatCurrency(producto.importe)}</td>
									<td style={{ whiteSpace: "nowrap" }}>
										<span className="fw-600">
											{tipoITBIS(producto.impuesto)}
										</span>{" "}
										{formatCurrency(
											producto.precio_facturado - producto.importe
										)}
									</td>
									<td>
										{formatCurrency(producto.precio_facturado)}
									</td>
									{factura.descuento > 0 && (
										<td>
											{formatCurrency(Math.round(descuentoReal))}
										</td>
									)}
									<td>{formatCurrency(producto.total - descuento)}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			{/* PRODUCTOS RESPONSIVE */}
			<div className="sm-invoice-table">
				<div className="row" style={{ fontWeight: "600" }}>
					<div className="col-5">DESCRIPCIÓN</div>
					<div className="col-3 px-0">IMPUESTOS</div>
					<div className="col-4 ">TOTAL</div>
				</div>

				{factura.productos?.map((producto) => {
					return (
						<>
							<div
								className="row"
								style={{
									fontSize: "smaller",
									marginBottom: "5px",
								}}
							>
								<div
									className="col-5"
									style={{ lineHeight: "13px" }}
								>
									{`${producto.cantidad} x ${formatCurrency(
										producto.precio_facturado,
										""
									)}`}
									<br />
									{producto.producto?.nombre}
									<br />
									REF: {producto.producto?.referencia}
								</div>
								<div className="col-3 px-0">
									<span className="fw-600">
										{tipoITBIS(producto.impuesto)}
									</span>
									{formatCurrency(
										producto.precio_facturado *
											producto.impuesto?.porcentaje,
										"$"
									)}
								</div>
								<div className="col-3 pr-0">
									{formatCurrency(producto.total, "$")}{" "}
								</div>
							</div>
						</>
					);
				})}
			</div>

			<div className="summary pt-3">
				{factura.descuento > 0 && (
					<div className="row justify-content-between justify-content-md-end">
						<div
							className="col-2 text-sm-right"
							style={{
								maxWidth: "8rem",
								textAlign: "end",
								fontWeight: "600",
							}}
						>
							Descuento
						</div>
						<div
							className="col-2 text-right"
							style={{ maxWidth: "9rem" }}
						>
							-{formatCurrency(factura.descuento)}
						</div>
					</div>
				)}
				<div className="row justify-content-between justify-content-md-end">
					<div
						className="col-2 text-sm-right"
						style={{
							maxWidth: "8rem",
							fontWeight: "600",
						}}
					>
						Subtotal
					</div>
					<div
						className="col-2 text-right"
						style={{ maxWidth: "9rem" }}
					>
						{formatCurrency(factura.subtotal)}
					</div>
				</div>
				<div className="d-block">
					{Object.keys(sumatoriaFinal.impuestos).map(
						(impuesto, index) => {
							return (
								<div
									className="row justify-content-between justify-content-md-end"
									key={index}
								>
									<div
										className="col col-md-2 text-sm-right"
										style={{
											maxWidth: "8rem",
											fontWeight: "600",
										}}
									>
										{impuesto}
									</div>
									<div
										className="col-2 text-right"
										style={{
											maxWidth: "9rem",
										}}
									>
										{formatCurrency(
											sumatoriaFinal.impuestos[impuesto],
											"$"
										)}
									</div>
								</div>
							);
						}
					)}
				</div>

				<div className="row justify-content-between justify-content-md-end">
					<div
						className="col col-md-2 text-sm-right"
						style={{
							maxWidth: "8rem",
							fontWeight: "600",
						}}
					>
						TOTAL
					</div>
					<div
						className="col-2 text-right"
						style={{
							maxWidth: "9rem",
							fontWeight: "600",
						}}
					>
						{formatCurrency(factura.total, "$")}
					</div>
				</div>
				{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
					<hr className="mb-0" />
				)}

				<div className="text-sm-right invoice-itbis-info">
					{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
						<p
							style={{
								fontSize: "small",
							}}
						>
							{Object.keys(sumatoriaFinal.impuestos).includes(
								"Itbis 16%"
							) && (
								<>
									<span>
										Items con I1 son gravados con 16% ITBIS
									</span>
									<br />
								</>
							)}
							{Object.keys(sumatoriaFinal.impuestos).includes(
								"Itbis 18%"
							) && (
								<span>
									Items con I2 son gravados con 18% ITBIS
								</span>
							)}
						</p>
					)}
					{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
						<div className="row justify-content-between justify-content-md-end">
							<div
								className="col col-md-6 pr-1"
								style={{
									maxWidth: "11rem",
									fontSize: "small",
									fontWeight: "600",
								}}
							>
								TOTAL IMPUESTOS
							</div>
							<div
								className="col-2"
								style={{
									maxWidth: "9rem",
									fontSize: "small",
									fontWeight: "600",
								}}
							>
								{/* {formatCurrency(factura.impuestos, "$")} */}
								{formatCurrency(impuestosTotal, "$")}
							</div>
						</div>
					)}
				</div>
			</div>

			<hr className="d-block d-sm-none mt-0" />
			{factura.notas && (
				<>
					<p className="font-weight-bold">Notas:</p>
					<p
						className="col-12 col-md-6 pl-0"
						style={{
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						{factura.notas}
					</p>
				</>
			)}
			{factura.plantilla?.pie_pagina && (
				<div
					className="col-12 pt-5 text-center d-flex justify-content-center"
					style={{
						fontWeight: "600",
						fontSize: "1rem",
						whiteSpace: "pre-line",
						lineHeight: "normal",
					}}
				>
					<p className="text-center pie-pagina">
						{factura.plantilla?.pie_pagina}
					</p>
				</div>
			)}
		</div>
	);
};

export default PrintSingleCotizacion;
