import React from "react";
import Select from "react-select";
import dayjs from "dayjs";

const Form = ({ vehiculos, servicio, errors }) => {
    if (!servicio) {
        servicio = {};
    }
    return (
        <div className='popup__body'>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Vehiculo:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <Select
                        name='vehiculo_id'
                        isClearable={true}
                        defaultValue={
                            servicio.vehiculoId && {
                                value: servicio.vehiculoId,
                                label: servicio.vehiculo,
                            }
                        }
                        options={vehiculos.map((vehiculo) => ({
                            value: vehiculo.id,
                            label: vehiculo.ficha,
                        }))}
                    />
                    {errors.vehiculo_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.vehiculo_id[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>Nombre:</label>
                </div>
                <div className='col col-md-8'>
                    <input
                        type='text'
                        name='nombre'
                        className='form-control'
                        defaultValue={servicio.nombre}
                    />
                    {errors.nombre && (
                        <small className='help-blockParams form-Text'>
                            {errors.nombre[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>Unidad:</label>
                </div>
                <div className='col col-md-8'>
                    <select
                        name='unidad'
                        className='form-control'
                        defaultValue={servicio.unidad}>
                        <option value='TON'>Toneladas</option>
                        <option value='DIAS'>Días</option>
                    </select>
                    {errors.unidad && (
                        <small className='help-blockParams form-Text'>
                            {errors.unidad[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Intervalo:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <input
                        type='number'
                        name='intervalo'
                        step='0.01'
                        className='form-control'
                        defaultValue={servicio.intervalo}
                    />
                    {errors.intervalo && (
                        <small className='help-blockParams form-Text'>
                            {errors.intervalo[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Generar Alerta en:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <input
                        type='number'
                        name='alerta'
                        step='0.01'
                        className='form-control'
                        defaultValue={servicio.alerta}
                    />
                    {errors.alerta && (
                        <small className='help-blockParams form-Text'>
                            {errors.alerta[0]}
                        </small>
                    )}
                </div>
            </div>
            {servicio.contador === undefined && (
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Último Mantenimiento:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='date'
                            name='fecha_mantenimiento'
                            className='form-control'
                            defaultValue={dayjs().format("YYYY-MM-DD")}
                        />
                        {errors.contador && (
                            <small className='help-blockParams form-Text'>
                                {errors.contador[0]}
                            </small>
                        )}
                    </div>
                </div>
            )}
            {/* <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>
                        Auto Renovar:
                    </label>
                </div>
                <div className='col col-md-8'>
                    <label className='switch switch-3d switch-success mr-3'>
                        <input type='hidden' name='renovar' value='no' />
                        <input
                            key={servicio.id}
                            type='checkbox'
                            className='switch-input'
                            id='renovar'
                            name='renovar'
                            value='si'
                            defaultChecked={
                                servicio.id ? servicio.renovar : true
                            }
                        />
                        <span className='switch-label' />
                        <span className='switch-handle' />
                    </label>
                    {errors.renovar && (
                        <small className='help-blockParams form-Text'>
                            {errors.renovar[0]}
                        </small>
                    )}
                </div>
            </div> */}
            {/* <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>Email:</label>
                </div>
                <div className='col col-md-8'>
                    <input
                        type='text'
                        name='email'
                        step="0.01"
                        className='form-control'
                        defaultValue={servicio.email}
                    />
                </div>
            </div> */}
            <div className='row form-group'>
                <div className='col col-md-4'>
                    <label className='label form-control-label'>Notas:</label>
                </div>
                <div className='col col-md-8'>
                    <textarea
                        name='descripcion'
                        rows='5'
                        className='form-control'
                        defaultValue={servicio.descripcion}
                    />
                    {errors.descripcion && (
                        <small className='help-blockParams form-Text'>
                            {errors.descripcion[0]}
                        </small>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Form;
