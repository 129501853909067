/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../../../utils/request";
import NotaCreditoForm from "./components/NotaCreditoForm";

export const NotaCreditoContext = createContext(null);

const EditNotaCredito = () => {
    const { id } = useParams();

    const [notaCredito, setNotaCredito] = useState({
		productos: [],
	});

    const getNotaCredito = () => {
		request("notas-credito/" + id).then((res) => {
			setNotaCredito(res.data);
		});
	};


    useEffect(() => {
        getNotaCredito();
    }, []);

    return (
        <NotaCreditoContext.Provider value={notaCredito}>
            <NotaCreditoForm isEdit={true} />
        </NotaCreditoContext.Provider>
    );
};

export default EditNotaCredito;