import React, { useState } from 'react';
import Select from 'react-select';
import Loader from '../../../general/Loader';

const EditarPagoForm = ({errors, toggle, seleccion}) => {
    const [saving, setSaving] = useState(false);
    return (<>
        <div className='popup__header'>Editar Pagos</div>
        <div className='popup__body'>
            <div className='row form-group'>
                {seleccion.map(id => {
                    return (
                        <input type="hidden" name="seleccion[]" value={id} />
                    )
                })}
                <div className='col col-md-5'>
                    <label className='label form-control-label'>
                        Estado:
                    </label>
                </div>
                <div className='col col-md-7'>
                    <Select
                        key="estado"
                        name='estado'
                        isClearable
                        required
                        menuPosition="fixed"
                        options={[
                            {
                                label: 'Anulado',
                                value: 'anulado'
                            },
                            {
                                label: 'Pagado',
                                value: 'pagado'
                            },
                            {
                                label: 'Pendiente',
                                value: 'pendiente'
                            },
                            {
                                label: 'Exonerado',
                                value: 'exonerado'
                            },
                            {
                                label: 'Retirado',
                                value: 'retirado'
                            },
                            {
                                label: 'Devuelto',
                                value: 'devuelto'
                            },
                        ]}
                    />
                    {errors.estado && (
                        <small className='help-blockParams form-Text'>
                            {errors.estado[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>
                        Notas:
                    </label>
                </div>
                <div className='col col-md-7'>
                    <textarea className='form-control' name="notas">
                    </textarea>
                    {errors.notas && (
                        <small className='help-blockParams form-Text'>
                            {errors.notas[0]}
                        </small>
                    )}
                </div>
            </div>
        </div>
        <div className='popup__footer'>
            <button
                type='button'
                onClick={toggle}
                className='btn btn-plain'>
                Cancelar
            </button>
            {saving && <Loader type="small"/>}
            {!saving && (
                <button type='submit' className='btn btn-primary'>
                    Guardar
                </button>
            )}
        </div>
    </>)
}

export default EditarPagoForm;
