import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import queryString from "query-string";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { getEstadoServicio } from "../../../utils/helpers";

class PrintServicios extends Component {
    state = {
        servicios: [],
    };
    componentDidMount() {
        this.getServicios();
    }
    getServicios = async () => {
        const response = await this.props.request(
            "servicios" + window.location.search
        );

        if (response.data) {
            this.setState({
                servicios: response.data,
            });
            window.print();
        }
    };
    render() {
        const { servicios } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const query = queryString.parse(window.location.search);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>Servicios</h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL SERVICIOS: {servicios.length}
                        </div>

                        <div className='float-right'>
                            <h3>
                                SERVICIOS{" "}
                                {getEstadoServicio(
                                    query.estado ? query.estado : "todos"
                                )}
                            </h3>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Ficha</th>
                            <th>Nombre</th>
                            <th>Intervalo</th>
                            <th>Contador</th>
                            <th>Alerta</th>
                            <th>Creador</th>
                        </tr>
                    </thead>
                    <tbody>
                        {servicios.map((servicio) => {
                            return (
                                <tr key={servicio.id}>
                                    <td>
                                        {dayjs(servicio.fecha).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </td>
                                    <td>{servicio.vehiculo}</td>
                                    <td>{servicio.nombre}</td>
                                    <td>
                                        {servicio.intervalo} {servicio.unidad}
                                    </td>
                                    <td>
                                        <strong>
                                            {servicio.contador}{" "}
                                            {servicio.unidad}
                                        </strong>
                                    </td>
                                    <td>
                                        {servicio.alerta} {servicio.unidad}
                                    </td>
                                    <td>{servicio.creador}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintServicios));
