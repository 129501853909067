import React, { Component } from "react";
import { withRequest } from "../../../utils/request";
import ReporteVehiculos from "./ReporteVehiculos";
import ProduccionMensual from "./ProduccionMensual";
import ReporteFechas from "./ReporteFechas";
import Select from "react-select";
import ReporteItems from "./ReporteItems";
import ReporteEmpleados from "./ReporteEmpleados";
import { getLink, toast } from "../../../utils/helpers";
import ReporteRutas from "./ReporteRutas";

class Reports extends Component {
    state = {
        errors: {},
        vehiculos: [],
        ficha: "",
        tipo: "combustible",
    };

    componentDidMount() {
        this.getTable("vehiculos", "produccion=1");
    }

    getTable = async (table, query = false) => {
        const response = await this.props.request(
            table + (query ? `?${query}` : "")
        );

        if (response.data && response.data.length) {
            this.setState({
                [table]: response.data,
            });
        }
    };

    updateList = (id) => {
        this.setState({
            vehiculos: this.state.vehiculos.map((vehiculo) => {
                if (vehiculo.id === id) {
                    vehiculo.checked = !vehiculo.checked;
                }
                return vehiculo;
            }),
        });
    };

    checkAll = (categoria) => {
        if (categoria === "todos") {
            this.setState({
                vehiculos: this.state.vehiculos.map((vehiculo) => {
                    return {
                        ...vehiculo,
                        checked: true,
                    };
                }),
            });
            return;
        }
        if (categoria === "quitar") {
            this.setState({
                vehiculos: this.state.vehiculos.map((vehiculo) => {
                    return {
                        ...vehiculo,
                        checked: false,
                    };
                }),
            });
            return;
        }
        this.setState({
            vehiculos: this.state.vehiculos.map((vehiculo) => {
                if (vehiculo.categoria === categoria) {
                    return {
                        ...vehiculo,
                        checked: true,
                    };
                }
                return vehiculo;
            }),
        });
    };

    buscarFicha = (ficha) => {
        this.setState({ ficha });
    };

    onChangeTipo = (input) => {
        this.setState({
            tipo: input.value,
        });
    };

    validate = (event) => {
        const { tipo } = this.state;

        const form = new FormData(event.target);

        if (
            tipo === "combustible" ||
            tipo === "produccion-fichas" ||
            tipo === "relacion-gastos-ficha" ||
            tipo === "relacion-gastos-produccion" ||
            tipo === "resumen-produccion"
        ) {
            if (!form.get("vehiculos")) {
                event.preventDefault();
                toast("Debes seleccionar al menos un vehículo.", "error");
            }
        }

        if (tipo === "produccion-empleados") {
            if (!form.get("empleados")) {
                event.preventDefault();
                toast("Debes seleccionar al menos un empleado.", "error");
            }
        }

        if (tipo === "movimiento-items") {
            if (!form.get("items")) {
                event.preventDefault();
                toast("Debes seleccionar al menos un item.", "error");
            }
        }

        if (tipo === "relacion-gastos-ficha") {
            if (!form.get("vehiculos")) {
                event.preventDefault();
                toast("Debes seleccionar al menos una ficha.", "error");
            }
        }

        if (
            tipo === "produccion-rutas" ||
            tipo === "detalle-produccion-rutas"
        ) {
            if (!form.get("rutas")) {
                event.preventDefault();
                toast("Debes seleccionar al menos una zona.", "error");
            }
        }
    };

    render() {
        const { vehiculos, ficha, tipo } = this.state;

        const vechiculosFiltrados = vehiculos.filter(
            (vehiculo) => vehiculo.ficha.indexOf(ficha) > -1
        );

        return (
            <form
                method='get'
                target='_blank'
                action={getLink("/imprimir/reportes")}
                onSubmit={this.validate}>
                <div className='popup__header'>Generar Reporte</div>
                <div className='popup__body'>
                    <div className='form-group'>
                        <label>Tipo:</label>
                        <Select
                            name='reporte'
                            onChange={this.onChangeTipo}
                            defaultValue={{
                                label: "Combustible",
                                value: "combustible",
                            }}
                            options={[
                                {
                                    label: "Combustible",
                                    value: "combustible",
                                },
                                {
                                    label: "Resumen Producción Empleados",
                                    value: "produccion-mensual",
                                },
                                {
                                    label: "Detalle Producción Empleados",
                                    value: "produccion-empleados",
                                },
                                {
                                    label: "Descuentos Empleados",
                                    value: "descuentos-empleados",
                                },
                                {
                                    label:
                                        "Movimiento de Producción por Fichas",
                                    value: "produccion-fichas",
                                },
                                {
                                    label: "Relación de Gastos por Ficha",
                                    value: "relacion-gastos-ficha",
                                },
                                {
                                    label: "Resumen de Producción",
                                    value: "resumen-produccion",
                                },
                                {
                                    label: "Relación de Gastos vs Producción",
                                    value: "relacion-gastos-produccion",
                                },
                                {
                                    label: "Movimiento Items",
                                    value: "movimiento-items",
                                },
                                {
                                    label: "Producción por Zonas",
                                    value: "produccion-rutas",
                                },
                                {
                                    label: "Detalle Producción por Zonas",
                                    value: "detalle-produccion-rutas",
                                },
                                {
                                    label: "Licencias Vencidas",
                                    value: "licencias-vencidas",
                                },
                                {
                                    label: "Seguros Vencidos",
                                    value: "seguros-vencidos",
                                },
                            ]}
                        />
                    </div>
                    {(tipo === "combustible" ||
                        tipo === "produccion-fichas" ||
                        tipo === "relacion-gastos-ficha" ||
                        tipo === "relacion-gastos-produccion" ||
                        tipo === "resumen-produccion") && (
                        <div>
                            <input
                                type='hidden'
                                name='tipo'
                                value='vehiculos'
                            />
                            <ReporteFechas />
                            <ReporteVehiculos
                                vehiculos={vechiculosFiltrados}
                                ficha={ficha}
                                checkAll={this.checkAll}
                                updateList={this.updateList}
                                buscarFicha={this.buscarFicha}
                            />
                        </div>
                    )}
                    {tipo === "produccion-mensual" && (
                        <div>
                            <input
                                type='hidden'
                                name='tipo'
                                value='empleados'
                            />
                            <ReporteFechas />
                            <ProduccionMensual tipo={tipo} />
                        </div>
                    )}
                    {(tipo === "produccion-rutas" ||
                        tipo === "detalle-produccion-rutas") && (
                        <div>
                            <input type='hidden' name='tipo' value='rutas' />
                            <ReporteFechas />
                            <ReporteRutas />
                        </div>
                    )}
                    {tipo === "movimiento-items" && (
                        <div>
                            <input type='hidden' name='tipo' value='items' />
                            <ReporteFechas />
                            <ReporteItems />
                        </div>
                    )}
                    {tipo === "produccion-empleados" && (
                        <div>
                            <input
                                type='hidden'
                                name='tipo'
                                value='empleados'
                            />
                            <ReporteFechas />
                            <ReporteEmpleados />
                        </div>
                    )}
                    {tipo === "descuentos-empleados" && (
                        <div>
                            <ReporteFechas />
                            <div className="form-group">
                                <label>Tipo de Descuento:</label>
                                <Select
                                    name="tipo"
                                    options={[{
                                        value: 'descuentos-programados',
                                        label: 'Descuentos Programados/Amonestaciones'
                                    }]}></Select>
                            </div>
                            <ReporteEmpleados />
                        </div>
                    )}
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Generar
                    </button>
                </div>
            </form>
        );
    }
}

export default withRequest(Reports);
