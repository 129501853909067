import React from "react";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";

import { formatCurrency } from "../../../utils/helpers";
import useSalarioNavidad from "./Hooks/useSalarioNavidad";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const SalarioNavidad = () => {
    const {
        pagos,
        empleados,
        orden,
        onChangeOrden,
        sucursal,
        redondear,
        totalRegalia,
        totalRedondeado
    } = useSalarioNavidad();

    return (
        <div>
            <style>{`@page {size: 11in  8.5in;} *{font-size: 14px}`}</style>
            <div className="ordernar-resultados no-print">
                <div className="form-group">
                    <div className="radio-btn">
                        <label>
                            <input type="radio" onChange={onChangeOrden} name="orden" value="nombre" defaultChecked={true} /> Ordenar por Nombre
                        </label>
                        <label>
                            <input type="radio" onChange={onChangeOrden} name="orden" value="codigo" /> Ordenar por Código
                        </label>
                    </div>
                </div>
            </div>
            <div className='row'>
                <h2 className='col-md-12 text-center m-b-5'>
                    Salario Navidad {dayjs().endOf('year').format('YYYY')}
                </h2>
                <div className='col-md-12 text-center'>
                    {sucursal.nombre} - {sucursal.direccion} •{" "}
                    {new Date().toLocaleString()}
                </div>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'>
                        TOTAL EMPLEADOS: {empleados.length}
                    </div>

                    <div className='float-right'>
                        <h3>Regalía: {formatCurrency(totalRegalia)} | Total: {formatCurrency(totalRedondeado)}</h3>
                    </div>
                </div>
            </div>
            <table className='table m-b-40'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Código</th>
                        <th>Fecha Inc.</th>
                        <th>Fecha Fin</th>
                        <th className="text-right">Salario Act.</th>
                        <th className="text-right">Salario Prom.</th>
                        <th>Tiempo Trabajado</th>
                        <th className='text-right'>Regalía</th>
                        <th className='text-right'>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {pagos.sort((a, b) => {
                        if ( orden === 'codigo') {
                            if (!a.codigo) {
                                return 1;
                            }
                            return a.codigo.localeCompare(b.codigo);
                        }

                        return a.nombre.localeCompare(b.nombre);
                    }).map((empleado, index) => {
                        return (
                            <tr key={empleado.id}>
                                <td>{index+1}</td>
                                <td>
                                    {empleado.nombre} {empleado.apellido}
                                </td>
                                <td>{empleado.codigo}</td>
                                <td>{empleado.fechai.format('DD/MM/YYYY')}</td>
                                <td>{empleado.fechaf.format('DD/MM/YYYY')}</td>
                                <td className="text-right">{formatCurrency(empleado.salario_actual)}</td>
                                <td className="text-right">{formatCurrency(empleado.salario_bruto)}</td>
                                {/* <td className="text-right">{formatCurrency((empleado.salariom/23.83).toFixed(2))}</td> */}
                                <td>
                                    {empleado.fecha_ingreso && (<>
                                        {empleado.anos > 0 && (<span>{empleado.anos} año(s), </span>)}
                                        {empleado.meses < 12 && empleado.meses > 0 && (<span>{empleado.meses} mes(es)</span>)}
                                        {empleado.dias > 0 && (<span> y {empleado.dias} día(s)</span>)}
                                    </>)}
                                </td>
                                <td className="text-right">
                                    {empleado.fecha_ingreso && formatCurrency(empleado.prestaciones)}
                                </td>
                                <td className="text-right">
                                    {empleado.fecha_ingreso && formatCurrency(redondear(empleado.prestaciones))}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className='row'>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'>
                        TOTAL EMPLEADOS: {empleados.length}
                    </div>

                    <div className='float-right'>
                        <h3>Regalía: {formatCurrency(totalRegalia)} | Total: {formatCurrency(totalRedondeado)}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SalarioNavidad;
