import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { getDiaProgramacion } from "../../../utils/helpers";
import dayjs from "dayjs";

class Tarjetas extends Component {
    state = {
        programacion: {},
        tarjetas: [],
    };
    componentDidMount() {
        this.getProgramacion();
    }
    getProgramacion = async () => {
        const id = this.props.match.params.id;
        const response = await this.props.request(`programaciones/${id}`);

        if (response.success) {
            this.setState({
                programacion: {
                    id: response.data.id,
                    nombre: response.data.nombre,
                    fecha: response.data.fecha,
                },
                tarjetas: response.data.tarjetas,
            });
            //window.print();
        }
    };
    render() {
        const { programacion, tarjetas } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        return (
            <div className='tarjetas-print-wrap'>
                <style>{`@page {size: 11in 8.5in;}`}</style>
                {tarjetas.map((tarjeta) => {
                    const chofer = tarjeta.operadores.filter(
                        (op) =>
                            op.nombre && op.nombre.toLowerCase() === "chofer"
                    )[0];
                    return (
                        <div className='tarjeta-print' key={tarjeta.ficha}>
                            <div className='tarjeta-print__header'>
                                <div className='dia-asignacion'>
                                    {getDiaProgramacion(
                                        dayjs(programacion.fecha).day() - 1
                                    )}
                                </div>
                                <h1>{sucursal.nombre}</h1>
                                <div className='tarjeta-print__slogan'>
                                    RNC: {sucursal.rnc}
                                </div>
                                <div className='tarjeta-print__descripcion'>
                                    {sucursal.direccion}
                                    <br />
                                    {sucursal.notas}
                                </div>
                            </div>
                            <div className='tarjeta-print__top'>
                                <h2 className='text-center'>
                                    Formulario de Despacho
                                </h2>
                                <div className='row'>
                                    <div className='col color-red'>
                                        Fecha: {programacion.nombre}
                                    </div>
                                    <div className='col text-right color-red'>
                                        Ficha: {tarjeta.ficha}
                                    </div>
                                </div>
                            </div>
                            <table className='table'>
                                <thead>
                                    <th>#Ticket</th>
                                    <th>Hora</th>
                                    <th>Peso Neto</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th className='text-left'>Total:</th>
                                    </tr>
                                </tfoot>
                            </table>
                            <div className='tarjeta-print__operadores'>
                                <div className='tarjeta-print__operador'>
                                    <span className='label'>Chofer:</span>{" "}
                                    <span className='value'>
                                        {chofer.asignadoNombre}
                                    </span>
                                </div>
                                <div className='tarjeta-print__operador'>
                                    <span className='label'>Activador:</span>
                                    <span className='value'></span>
                                </div>
                                <div className='tarjeta-print__operador'>
                                    <span className='label'>Ayudante:</span>
                                    <span className='value'></span>
                                </div>
                                <div className='tarjeta-print__operador'>
                                    <span className='label'>Ayudante:</span>
                                    <span className='value'></span>
                                </div>
                                {/* ayudantes.map((ayudante) => (
                                    <div className='tarjeta-print__operador'>
                                        <span className='label'>
                                            {ayudante.nombre}:
                                        </span>
                                        <span className='value'></span>
                                    </div>
                                )) */}
                            </div>
                            <div className='tarjeta-print__ruta'>
                                <strong class="color-red">
                                    {tarjeta.ruta && tarjeta.ruta.nombre} - {tarjeta.ruta && tarjeta.ruta.descripcion}
                                </strong>
                            </div>
                            <div className='tarjeta-print__meta'>
                                La meta de este dia es de{" "}
                                {tarjeta.meta > 1000
                                    ? tarjeta.meta / 100
                                    : tarjeta.meta}{" "}
                                toneladas mínimas, si no la cumple se le pondrá
                                una amonestación.
                            </div>
                            <div className='tarjeta-print__footer'>
                                <span className='firma'>
                                    FIRMA DEL SUPERVISOR:
                                </span>
                                <span className='line'></span>
                                <span className='firma'>HORA:</span>
                                <span className='line hora'></span>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default withRouter(withRequest(Tarjetas));
