/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
	formatCurrency,
	formatNumber,
	getSucursal,
	money,
	toast,
} from "../../../utils/helpers";
import { request } from "../../../utils/request";

const BalanceInventario = () => {
	const { data, sucursal, queryParams } = useData();

    const utilidadBruta = parseFloat(data.inventarioPrecio) - parseFloat(data.inventarioCosto);

	// TODO en el lado izquierdo hacer un resumen de las ventas de los vendedores.
	return (
		<>
			{/* SUCURSAL INFO */}
			<div className="row mb-3">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte de Balance Inventario
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs().format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs().format("DD/MM/YYYY")}
				</div>
			</div>
			{/* CUENTAS TOTALS */}
			<div
				className="clear-both info-row p-t-10 p-b-10 d-flex justify-content-center"
				style={{ fontSize: "1.3rem" }}
			>
				<div className="info-block" style={{ width: "75%" }}>
					<table className="w-100">
						<tbody id="info-block-1">
							<tr>
								<td className="text-uppercase">
								   INVENTARIO AL COSTO:
								</td>
								<td width="120px" className="text-center"> =</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(data.inventarioCosto)}
								</td>
							</tr>
							<tr>
								<td className="text-uppercase">
								   INVENTARIO A PRECIO DE VENTA:
								</td>
								<td width="120px"className="text-center" > =</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(money(data.inventarioPrecio))}
								</td>
							</tr>
							<tr>
								<td className="text-uppercase">
								   MARGEN BRUTO DE GANANCIA:
								</td>
								<td width="120px"className="text-center" > =</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(utilidadBruta)}
								</td>
							</tr>
							<tr>
								<td className="text-uppercase">
								   GANANCIA BRUTA/COSTO:
								</td>
								<td width="120px"className="text-center" > =</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatNumber((utilidadBruta/parseFloat(data.inventarioCosto)*100))}%
								</td>
							</tr>
                            <tr>
								<td className="text-uppercase">
								   GANANCIA BRUTA/PRECIO:
								</td>
								<td width="120px"className="text-center" > =</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatNumber((utilidadBruta/parseFloat(data.inventarioPrecio)*100))}%
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

const useData = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [sucursal, setSucursal] = useState({});
	const [data, setData] = useState({
		totalCxC: 0,
		totalProductos: 0,
		totalCxP: 0,
		totalPagosCompras: 0,
		totalComprasPeriodo: 0,
		totalComprasPeriodoCanceladas: 0,
		totalVentasCanceladas: 0,
		totalVentasPeriodo: 0,
		totalIngresosPeriodo: 0,
		totalDescuentosFacturacion: 0,
		totalMenosCostoDeVenta: 0,
		totalITBIS18: 0,
		totalITBIS16: 0,
		totalFacturas: 0,
		totalProformas: 0,
	});

	useEffect(() => {
		let filterParams = "";
		if (queryParams.get("desde")) {
			filterParams += `?desde=${queryParams.get("desde")}`;
		}
		if (queryParams.get("hasta")) {
			filterParams += `&hasta=${queryParams.get("hasta")}`;
		}

		(async () => {
			const res = await request(`reportes${location.search}`);

			if (!res.success) {
				toast("error obteniendo data", "error");
				return;
			}

			setData(res.data);
			setTimeout(() => {
				window.print();
			}, 1000);
		})();

		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);
	}, []);

	return React.useMemo(
		() => ({ data, sucursal, queryParams }),
		[data, sucursal]
	);
};

export default BalanceInventario;
