import React, { useState, useEffect } from "react";
import { request } from "../../../utils/request";
import dayjs from "dayjs";
import Select from "react-select";
import SelectAsync from "react-select/async";
import { getCategoriasForDropdown, getLink, toast } from "../../../utils/helpers";

const EstadosCuentaForm = ({toggle, numeraciones}) => {

    const [productos, setProductos] = useState([]);
    const [selectedProductos, setSelectedProductos] = useState([]);

    useEffect(() => {
        let ignore = false;

        async function fetchProducto() {
            if (!ignore) {
                const response = await request("contactos?tipo=cliente");
                setProductos(response.data);
            }
        }

        fetchProducto();

        return () => {
            ignore = true;
        };
    }, []);

    function onChangeProducto(values) {
        if (values) {
            setSelectedProductos(values.map((i) => i.value));
        } else {
            setSelectedProductos([]);
        }

    }

    const validate = (event) => {
        const form = new FormData(event.target);

        if (!form.get("contactos")) {
            event.preventDefault();
            toast("Debes seleccionar al menos un cliente.", "error");
        }
    };

    return (
        <form
            method='get'
            target='_blank'
            action={getLink(`/imprimir/estados-cuenta`)}
            onSubmit={validate}>
            <div className='popup__header'>Generar Estados de Cuentas</div>
            <div className='popup__body'>
                <div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Desde:</label>
                                <input
                                    type='date'
                                    name='desde'
                                    id='desde'
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Hasta:</label>
                                <input
                                    type='date'
                                    name='hasta'
                                    defaultValue={dayjs().endOf('month')
                                        .format("YYYY-MM-DD")}
                                    id='hasta'
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label
                                    style={{ fontSize: "12px", textTransform: "uppercase" }}
                                >
                                    Numeración
                                </label>
                                <Select
                                    name="numeracion[]"
                                    isClearable
                                    menuPosition="fixed"
                                    isMulti
                                    options={[{label: 'Todos', value: ''}].concat(numeraciones.map(numeracion => {
                                        return {
                                            label: numeracion.nombre,
                                            value: numeracion.id,
                                        }
                                    }))}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label
                                    style={{ fontSize: "12px", textTransform: "uppercase" }}
                                >
                                    Categoría
                                </label>
                                <SelectAsync
                                    name="categoria[]"
                                    isMulti
                                    menuPosition="fixed"
                                    isClearable
                                    placeholder="Todas"
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={() => getCategoriasForDropdown()}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            height: "38px",
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div>
                                <div className='form-group' style={{zIndex: 9999}}>
                                    <label>Clientes | <small>Puede seleccionar más de uno</small></label>
                                    <input
                                        type='hidden'
                                        name='contactos'
                                        value={selectedProductos.join(",")}
                                    />
                                        <Select
                                            menuPosition={'fixed'}
                                            maxMenuHeight={250}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            isLoading={productos.length === 0}
                                            isMulti
                                            loadingMessage={() => "Cargando Productos..."}
                                            onChange={onChangeProducto}
                                            options={[{value: "todos", label:"Todos"},...productos.map((producto) => ({
                                                value: producto.id,
                                                label: `${producto.identificacion || ''} - ${producto.nombre}`,
                                            }))]}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Atrás
                </button>
                <button type='submit' className='btn btn-primary'>
                    Generar
                </button>
            </div>
        </form>
    );
}

export default EstadosCuentaForm;
