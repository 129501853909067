import cookie from "js-cookie";
import { apiUrl } from "./apiUrl";

export const auth = {
    getUser: () => {
        let user = window.localStorage.getItem("user");
        user = JSON.parse(user);
        user.sucursalActual = parseInt(
            window.localStorage.getItem("sucursalActual")
        );
        return user;
    },
    getToken: () => {
        return cookie.get("token");
    },
    is: (rol) => {
        return auth.getUser().rol === rol;
    },
    can: (ability) => {
        return auth.permissions[auth.getUser().rol].indexOf(ability) > -1;
    },
    getConfig: () => {
        const predeterminado = {
            unidad_produccion_p: 'Toneladas',
            unidad_produccion_s: 'Tonelada',
            unidad_produccion_abr: 'TON.',
        }

        const config = JSON.parse(window.localStorage.getItem("config"));

        return config.unidad_produccion_p ? config : predeterminado;
    },
    permissions: {
        "super-admin": [
            "editar-usuarios",
            "ver-dashboard",
            "abrir-tarjetas",
            "ver-configuracion",
            "ver-programacion",
            "ver-empleados",
            "ver-nomina",
            "ver-solicitudes",
            "ver-reclamaciones",
            "ver-descuentos",
            "ver-incentivos",
            "ver-vehiculos",
            "ver-rutas",
            "ver-inventario",
            "ver-contratos",
            "editar-inventario",
            "ver-gastos-generales",
            "ver-gastos",
            "ver-compras",
            "ver-servicios",
            "ver-reportes",
            "ver-contactos",
            "editar-sucursales",
            "ver-tipos",
            "ver-cuentas",
            "ver-gi-grupos",
            "ver-gi-prestamos",
            "ver-gi-servidores",
            "ver-grupo-tipos-empleados",
            "ver-ingresos",
            "ver-facturas",
            "ver-facturas-recurrentes",
            "ver-ordenes-compra",
            "ver-cotizaciones",
            "ver-cotizaciones",
            "ver-comprobantes",
            "ver-productos",
            "editar-productos",
            "ver-gastos-internos",
            "ver-plantillas-impresion",
            "ver-consultas",
            "ver-cuentas-por-cobrar",
            "ver-recibos-ingreso",
            "ver-academico",
            "ver-cuentas-por-pagar",
            "ver-evaluaciones",
            "ver-estudiantes",
            "ver-inscripcion",
            "ver-facturacion-academica",
            "ver-padres-tutores",
            "ver-contabilidad",
            "ver-notas-credito",
        ],
        admin: [
            "ver-dashboard",
            "abrir-tarjetas",
            "ver-configuracion",
            "ver-programacion",
            "ver-empleados",
            "ver-nomina",
            "ver-solicitudes",
            "ver-reclamaciones",
            "ver-descuentos",
            "ver-incentivos",
            "ver-vehiculos",
            "ver-rutas",
            "ver-inventario",
            "editar-inventario",
            "ver-gastos",
            "ver-gastos-generales",
            "ver-gastos-internos",
            "ver-compras",
            "ver-contratos",
            "ver-servicios",
            "ver-reportes",
            "ver-contactos",
            "ver-tipos",
            "ver-cuentas",
            "ver-gi-grupos",
            "ver-gi-prestamos",
            "ver-gi-servidores",
            "ver-grupo-tipos-empleados",
            "ver-productos",
            "editar-productos",
            "ver-comprobantes",
            "ver-ingresos",
            "ver-facturas",
            "ver-facturas-recurrentes",
            "ver-cotizaciones",
            "ver-ordenes-compra",
            "ver-plantillas-impresion",
            "ver-consultas",
            "ver-cuentas-por-cobrar",
            "ver-cuentas-por-pagar",
            "ver-recibos-ingreso",
            "ver-academico",
            "ver-evaluaciones",
            "ver-estudiantes",
            "ver-inscripcion",
            "ver-facturacion-academica",
            "ver-padres-tutores",
            "ver-contabilidad",
            "ver-notas-credito",
        ],
        rrhh: [
            "ver-dashboard",
            "ver-configuracion",
            "ver-programacion",
            "ver-empleados",
            "ver-nomina",
            "ver-solicitudes",
            "ver-reclamaciones",
            "ver-vehiculos",
            "ver-rutas",
            "ver-inventario",
            "editar-inventario",
            "ver-gastos",
            "ver-gastos-generales",
            "ver-gastos-internos",
            "ver-compras",
            "ver-servicios",
            "ver-reportes",
            "ver-contactos",
        ],
        nomina: [
            "ver-empleados",
            "ver-tipos",
            "ver-nomina",
            "ver-descuentos",
            "ver-incentivos",
            "ver-gi-grupos",
            "ver-gi-prestamos",
            "ver-gi-servidores",
        ],
        editor: [
            "ver-programacion",
            "ver-ingresos",
            "ver-facturas",
            "ver-vehiculos",
            "ver-cotizaciones",
            "ver-ordenes-compra",
            "ver-descuentos",
            "ver-servicios",
            "ver-inventario",
            "editar-inventario",
            "ver-compras",
            "ver-gastos",
            "ver-gastos-generales",
            "ver-gastos-internos",
            "ver-incentivos",
        ],
        compras: [
            "ver-compras",
            "ver-solicitudes",
            "ver-reclamaciones",
            "ver-gastos",
            "ver-gastos-generales",
            "ver-gastos-internos",
            "ver-inventario",
            "ver-productos",
            "ver-servicios",
            "ver-notas-credito",
        ],
        gasto: [
            "ver-solicitudes",
            "ver-reclamaciones",
            "ver-gastos",
            "ver-gastos-generales",
            "ver-inventario",
            "ver-servicios",
        ],
        digitador: [
            "ver-solicitudes",
            "ver-reclamaciones",
            "ver-empleados",
            "ver-programacion"
        ],
        solicitudes: [
            "ver-solicitudes",
        ],
        vendedor: [
            "ver-ingresos",
            "ver-ordenes-compra",
            "ver-cotizaciones",
            "ver-consultas",
            "ver-cuentas-por-cobrar",
            "ver-recibos-ingreso"
        ],
        "vendedor-cliente": [
            "ver-ingresos",
            "ver-ordenes-compra",
            "ver-cotizaciones",
            "ver-consultas",
            "ver-cuentas-por-cobrar",
            "ver-recibos-ingreso"
        ],
        cobrador: [
            "ver-ordenes-compra",
            "ver-cotizaciones",
            "ver-consultas",
            "ver-cuentas-por-cobrar",
            "ver-recibos-ingreso",
        ],
        "vendedor-admin": [
            "ver-ingresos",
            "ver-ordenes-compra",
            "ver-facturas",
            "ver-cotizaciones",
            "ver-consultas",
            "ver-cuentas-por-cobrar",
            "ver-recibos-ingreso"
        ],
        "vendedor-factura": [
            "ver-ingresos",
            "ver-facturas",
            "ver-consultas",
            "ver-cuentas-por-cobrar",
            "ver-recibos-ingreso"
        ],
        "vendedor-factura-orden_compra": [
            "ver-ingresos",
            "ver-facturas",
            "ver-ordenes-compra",
            "ver-consultas",
            "ver-cuentas-por-cobrar",
            "ver-recibos-ingreso"
        ],
        'edu.caja': [
            "ver-ingresos",
            "ver-facturas",
            "ver-facturas-recurrentes",
            "ver-consultas",
            "ver-ordenes-compra",
            "ver-cuentas-por-cobrar",
            "ver-recibos-ingreso",
            "ver-contactos",
            "ver-academico",
            "ver-evaluaciones",
            "ver-estudiantes",
            "ver-inscripcion",
            "ver-padres-tutores",
        ],
        'edu.recepcion': [
            "ver-academico",
            "ver-evaluaciones",
            "ver-estudiantes",
            "ver-inscripcion",
            "ver-padres-tutores",
        ],
        'contratos': [
            "ver-contratos",
            "ver-contactos",
        ],
    },
    async authenticate(credentials, cb) {
        const { access_token, user, expires_at } = credentials;

        cookie.set("token", access_token, { expires: new Date(expires_at) });
        window.localStorage.setItem("user", JSON.stringify(user));
        window.localStorage.setItem("sucursalActual", user.sucursalActual);
        window.localStorage.setItem("config", JSON.stringify(user.config));
        cb();
    },
    async logout() {
        const token = cookie.get("token");
        cookie.remove("token");

        await fetch(apiUrl("salir"), {
            method: "post",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        // to support logging out from all windows
        window.localStorage.setItem("logout", Date.now());
        window.localStorage.removeItem("sucursalAcual");
        window.localStorage.removeItem("config");
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("rutas");
        window.localStorage.removeItem("almacenes");

        window.location = "/";
    },
    isLoggedIn() {
        const token = cookie.get("token");
        if (!token) {
            return false;
        }
        return token;
    },
    inactivityTime() {
        var time;
        window.onload = resetTimer;

        // DOM Events
        document.onmousemove = resetTimer;
        document.onkeypress = resetTimer;
        document.addEventListener("scroll", resetTimer, true);

        function logout() {
            clearTimeout(time);
            auth.logout();
        }

        function resetTimer() {
            clearTimeout(time);

            if (auth.isLoggedIn()) {
                time = setTimeout(logout, 60000 * 60);
                return;
            }
        }
    },
};
