import React, { Component } from "react";
import { auth } from "../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";

class Create extends Component {
    state = {
        errors: {},
        categoria: {
            nombre: "",
            vehiculos: false,
            operadores: [{
                nombre: 'Chofer',
                sueldo: 10,
            }],
        }
    };
    createCategoria = async event => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl("categorias"), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.props.getCategorias();
            this.props.toggle();
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { errors, categoria } = this.state;
        const { toggle } = this.props;
        return (
            <form method='post' onSubmit={this.createCategoria}>
                <div className='popup__header'>Nueva Categoría</div>
                <div className='popup__body'>
                    <Form categoria={categoria} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    <button type='submit' className='btn btn-primary'>
                        Crear
                    </button>
                </div>
            </form>
        );
    }
}

export default Create;
