import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { apiRequest } from "../../../hooks/apiRequest";
import useFilters from "../../../hooks/useFilters";
import { auth } from "../../../utils/auth";
import {
	formatCurrency,
	getLink,
	isVendedor,
	toast,
} from "../../../utils/helpers";
import { request } from "../../../utils/request";
import Dropdown from "../../general/Dropdown";
import FiltersContainer from "../../general/FiltersContainer";
import Loader from "../../general/Loader";
import Modal from "../../general/Modal";
import PageHeader from "../../general/PageHeader";
import Pagination from "../../general/Pagination";
import { METODOS_PAGO } from "../../../utils/types";
import HistorialPagos from "../Estudiantes/HistorialPagos";

const estados = [
	{ value: "", label: "Todos" },
	{ value: "completado", label: "Completado" },
	{ value: "cancelado", label: "Cancelado" },
];

const renderEstadoBadge = (estado) => {
	if (estado === "completado") {
		return <span className="badge badge-success p-2">Completado</span>;
	}

	return <span className="badge badge-danger p-2">Cancelado</span>;
};

const Recibos = () => {
	const role = auth.getUser().rol;
	const { filters, handleFilterChange, filtersQuery } = useFilters({
		buscar: "",
		tipo_pago: "",
		tipo_recibo: role === "edu.caja" ? "ingreso" : "",
		estado: "",
		vendedor: "",
		zona: "",
		desde: dayjs().format("YYYY-MM-DD"),
		hasta: dayjs().format("YYYY-MM-DD"),
	});
	const [recibos, setRecibos] = useState({ data: [] });
	const [isLoading, setIsLoading] = useState(true);
	const [cuentasSeleccionadas, setCuentasSeleccionadas] = useState([]);
	const [showCuentasModal, setShowCuentasModal] = useState(false);
	const [showCancel, setShowCancel] = useState(false);
	const [reciboSeleccionado, setReciboSeleccionado] = useState({});
	const [numeraciones, setNumeraciones] = useState([]);

	const getRecibosIngreso = async (url) => {
		url = url ? url + "&limit=15" : "academico/recibos?limit=15";
		request(url).then((data) => {
			setRecibos(data);
			setIsLoading(false);
		});
	};

    const getNumeraciones = async () => {
        request('numeracion-documentos?tipo_documento=factura_venta').then((response) => {
            setNumeraciones(response.data);
        });
    }

	const toggleCancelModal = (recibo = {}) => {
		setReciboSeleccionado(recibo);
		setShowCancel((state) => !state);
	};

	const cancelRecibo = async (recibo) => {
		const response = await apiRequest(
			null,
			`academico/recibos/${recibo}/cancel`,
			"POST"
		);

		if (response.success) {
			toast("Recibo de ingreso cancelado");
			setShowCancel(false);
			getRecibosIngreso(`academico/recibos${filtersQuery}`);
		}
	};

	useEffect(() => {
		if (filtersQuery === "") return;
		getRecibosIngreso(`academico/recibos${filtersQuery}`);
	}, [filtersQuery]);

    useEffect(() => {
        getNumeraciones();
    }, []);

	return (
		<>
			<PageHeader title="Cobros Académicos" hasButton={false}>
                <div className="col-12 col-md-7 col-lg-2" style={{ fontSize: "14px" }}>
					<label
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Numeración
					</label>
                    <Select
                        key="numeracion"
                        name="numeracion"
                        isMulti
                        isClearable
                        defaultValue={{ value: "", label: "Todas" }}
                        onChange={(values) => {
							handleFilterChange("numeracion", values)
						}}
                        options={numeraciones && [
                            { value: "", label: "Todas" },
                            ...numeraciones?.map(numeracion => {
                            return {
                                label: numeracion.nombre,
                                value: numeracion.id,
                            }
                        })]}
                    />
				</div>
				<div className="col-12 col-md-7 col-lg-2"
                style={{ fontSize: "14px" }}>
					<label
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Estado
					</label>
					<Select
						name="estado"
                        defaultValue={{ value: "", label: "Todos" }}
						onChange={(e) =>
							handleFilterChange("estado", e?.value)
						}
                        options={estados.map((estado) => ({
                            label: estado.label,
                            value: estado.value,
                        }))}
					/>
				</div>
				<div className="d-inline-block float-right h-100 d-flex align-items-center">
					<Link
						to={getLink(`/imprimir/academico/recibos${filtersQuery}`)}
						target="_blank"
						className="au-btn-filter m-r-15 d-none d-lg-inline-block"
					>
						<i className="zmdi zmdi-print" /> Imprimir
					</Link>
                    <Link
                        to={getLink("/academico/facturar")}
                        className='au-btn au-btn-icon au-btn--green au-btn--small'
                    >
                        <i className='zmdi zmdi-plus' />
                        Nuevo Cobro Académico
                    </Link>
				</div>
			</PageHeader>
			<FiltersContainer>
				<div className="col">
					<label>Buscar</label>
					<input
						type="search"
						placeholder="Buscar..."
						className="form-control"
						name="buscar"
						onChange={(e) =>
							handleFilterChange("buscar", e.target.value)
						}
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2">
					<label>Tipo de pago</label>
					<Select
						id="tipo_pago"
						options={[
							{ value: "", label: "Todos" },
							...METODOS_PAGO,
						]}
						name="tipo_pago"
						onChange={(e) =>
							handleFilterChange("tipo_pago", e.value)
						}
						placeholder="Todos"
						defaultValue={{ value: "", label: "Todos" }}
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2 pr-lg-0">
					<label
						htmlFor="desde"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Desde
					</label>
					<input
						type="date"
						name="desde"
						id="desde"
						className="form-control"
						value={filters.desde}
						onChange={(e) =>
							handleFilterChange("desde", e.target.value)
						}
						disabled={isVendedor()}
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2 pr-lg-0">
					<label
						htmlFor="hasta"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Hasta
					</label>
					<input
						type="date"
						name="hasta"
						id="hasta"
						defaultValue={filters.hasta}
						className="form-control"
						onChange={(e) =>
							handleFilterChange("hasta", e.target.value)
						}
						disabled={isVendedor()}
					/>
				</div>
			</FiltersContainer>
			<div
				className="row px-3"
				style={{ overflowX: "auto", paddingBottom: "5rem" }}
			>
				{isLoading && <Loader />}
				{!isLoading && recibos.data?.length ? (
					<div
                        className="table-data table-responsive table-row__desktop"
                        style={{ overflowX: "visible" }}
                    >
                        <table className="table table-data2">
                            <thead>
                                <tr>
                                    <th>Numero</th>
                                    <th>Estudiante</th>
                                    <th>Tipo</th>
                                    <th>Tutor</th>
                                    <th>Creación</th>
                                    <th>Total</th>
                                    <th className="text-center">Cuotas</th>
                                    <th>Estado</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {recibos.data.map((recibo) => (
                                    <tr className="tr-shadow" key={recibo.id}>
                                        <td>{recibo.numeracion}</td>
                                        <td>
                                            <Link to={getLink(`/academico/estudiantes/${recibo.estudiante?.id}/informacion`)}>
                                                {recibo.estudiante?.matricula} {recibo.estudiante?.nombreCompleto}
                                            </Link>
                                        </td>
                                        <td className="text-capitalize">
                                            {recibo.servicios.find(r => r.nombre === 'Evaluación') ? "EVALUACIÓN" : (recibo.concepto.includes('Cupo') ? "RESERVA CUPO" : recibo.tipo === 'INSCRIPCION' ? "INSCRIPCIÓN" : recibo.tipo)}
                                        </td>
                                        <td>{recibo.estudiante?.tutores.length > 0 && `${recibo.estudiante?.tutores[0].nombre} ${recibo.estudiante?.tutores[0].apellido}`}</td>
                                        <td>
                                            {dayjs(recibo.created_at).format(
                                                "DD/MM/YYYY hh:mm"
                                            )}
                                        </td>
                                        <td>{formatCurrency(recibo.total)}</td>
                                        <td className="text-center">{recibo.servicios?.length}</td>
                                        <td className="text-capitalize">
                                            {renderEstadoBadge(recibo.estado)}
                                        </td>
                                        <td className="text-right">
                                            <Dropdown>
                                                <Link
                                                    to={getLink(
                                                        `/imprimir/academico/recibos/${recibo.id}/${recibo.tipo.toLowerCase()}`
                                                    )}
                                                    target="_blank"
                                                    className="dropdown-item"
                                                    title="Impresión normal"
                                                >
                                                    Impresión
                                                </Link>

                                                <button
                                                    className="dropdown-item"
                                                    onClick={() => {
                                                        setReciboSeleccionado(recibo);
                                                        setCuentasSeleccionadas(
                                                            recibo.cuentas
                                                        );
                                                        setShowCuentasModal(
                                                            (state) => !state
                                                        );
                                                    }}
                                                >
                                                    Historial de pagos
                                                </button>
                                                {!isVendedor() && recibo.estado !== 'cancelado' && (
                                                    <button
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                            toggleCancelModal(recibo)
                                                        }
                                                    >
                                                        Cancelar
                                                    </button>
                                                )}
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
				) : (
					<div>
						{!isLoading && (
							<div>No hay ningún Recibo de ingreso creado.</div>
						)}
					</div>
				)}
				{recibos.links && (
					<Pagination
						links={recibos.links}
						meta={recibos.meta}
						getData={getRecibosIngreso}
					/>
				)}
			</div>
			<Modal
				title="Transacciones procesadas"
				show={showCuentasModal}
				toggle={() => {
					setShowCuentasModal((state) => !state);
				}}
                showCancelBtn={false}
                acceptText={"Cerrar"}
				size="1024px"
			>
				<HistorialPagos estudiante={reciboSeleccionado.estudiante} />
			</Modal>
			<Modal
				title="Cancelar Recibo de ingreso"
				show={showCancel}
				callback={() => cancelRecibo(reciboSeleccionado.id)}
				acceptText="Aceptar"
				toggle={toggleCancelModal}
			>
				¿Estás seguro de que deseas cancelar este recibo de ingreso?
				(Esta acción no se puede deshacer).
			</Modal>
		</>
	);
}

export default Recibos;
