import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import SelectAsync from "react-select/async";
import { apiRequest } from "../../hooks/apiRequest";
import { formatCurrency } from "../../utils/helpers";

const Form = ({ cheque, errors, afectarCuentas }) => {

    const [movimientos, setMovimientos] = useState([{
        id: Date.now(),
    }]);

    const [cuentasContables, setCuentasContables] = useState([]);
    const [numero, setNumero] = useState('');
    const [seguirSecuencia, setSeguirSecuencia] = useState(cheque.numeracion_id ? true : false);

    const getCuentasContables = async () => {
        const cuentas = await apiRequest(null, 'cuentas-contables', 'get');
        if (cuentas.data) {
            setCuentasContables(cuentas.data);
        }
    }

    const getNumeracionSiguiente = async (id) => {
        const response = await apiRequest(null, 'numeracion-documentos/siguiente/' + id, 'get');
        if (response.data) {
            setNumero(response.data.tipo ? response.data.tipo +  response.data.numero_siguiente : '');
        }
    }

    const addMovimiento = () => {
        setMovimientos([
            ...movimientos,
            {id: Date.now(), monto: 0}
        ]);
    }

    const removeMovimiento = (id) => {
        setMovimientos([
            ...movimientos.filter(m => m.id !== id)
        ]);
    }

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };

    const actualizarMonto = (id, monto) => {
        setMovimientos(movimientos.map(m => {
            if (m.id === id) {
                m.monto = monto;
            }
            return m;
        }));
    }

    useEffect(() => {
        getCuentasContables();
    }, []);

    useEffect(() => {
        if (cheque.movimientos) {
            setMovimientos(cheque.movimientos);
            setNumero(cheque.numero);
        }
    }, [cheque]);

    const totalCreditos = movimientos.reduce((a, p) => {
        return a + parseFloat(p.monto);
    }, 0);

    return (
        <div>
            <div className='row'>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Cuenta Bancaria:
                    </label>
                    <SelectAsync
                        key="cuenta"
                        name='cuenta_id'
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        cacheOptions
                        defaultOptions
                        value={cheque.cuenta && ({
                            label: cheque.cuenta.nombre,
                            value: cheque.cuenta.id,
                        })}
                        loadOptions={(e) => getOptions("cuentas?buscar="+e)}
                    />
                    {errors.cuenta_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.cuenta_id[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Proveedor:
                    </label>
                    <SelectAsync
                        key="proveedor"
                        name='contacto_id'
                        placeholder='Seleccionar...'
                        menuPosition='fixed'
                        menuPlacement='auto'
                        cacheOptions
                        defaultOptions
                        value={cheque.contacto && ({
                            label: cheque.contacto.nombre,
                            value: cheque.contacto.id,
                        })}
                        loadOptions={(e) => getOptions("contactos?buscar="+e)}
                    />
                    {errors.contacto_id && (
                        <small className='help-blockParams form-Text'>
                            {errors.contacto_id[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Fecha:
                    </label>
                    <input
                        type='date'
                        className='text-input form-control'
                        id='fecha'
                        name='fecha'
                        defaultValue={dayjs().format('YYYY-MM-DD')}
                    />
                    {errors.fecha && (
                        <small className='help-blockParams form-Text'>
                            {errors.fecha[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-5 form-group'>
                    <label className='label form-control-label'>
                        Número de cheque:
                    </label>
                    <div className="d-flex">
                        <input type="checkbox" className="m-r-15" onChange={e => setSeguirSecuencia(!seguirSecuencia)} checked={seguirSecuencia} title="Seguir Secuencia" />
                        <div className="m-r-15" style={{flex: 0.75}}>
                            <SelectAsync
                                name="numeracion_id"
                                cacheOptions
                                defaultOptions
                                isDisabled={!seguirSecuencia}
                                menuPosition='fixed'
                                menuPlacement='auto'
                                onChange={ e => {
                                    getNumeracionSiguiente(e.value);
                                }}
                                defaultValue={cheque.numeracion && {
                                    label: cheque.numeracion.tipo,
                                    value: cheque.numeracion.id,
                                }}
                                loadOptions={(e) => getOptions("numeracion-documentos?buscar=cheque", 'prefijo')}
                            />
                        </div>
                        <div style={{flex: 1}}>
                            <input
                                type='text'
                                name="numero"
                                required
                                readOnly={seguirSecuencia}
                                className='text-input form-control'
                                defaultValue={numero}
                            />
                            {errors.numero && (
                                <small className='help-blockParams form-Text'>
                                    {errors.numero[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='col-md-4 form-group'>
                    <label className='label form-control-label'>
                        Concepto:
                    </label>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='concepto'
                        name='concepto'
                        required
                        defaultValue={cheque.concepto}
                    />
                    {errors.concepto && (
                        <small className='help-blockParams form-Text'>
                            {errors.concepto[0]}
                        </small>
                    )}
                </div>
                <div className='col-md-3 form-group'>
                    <label className='label form-control-label'>
                        Monto:
                    </label>
                    <div className='input-box'>
                        <span className="prefix">RD$</span>
                        <input
                            type='text'
                            readOnly={cheque.monto ? true : false}
                            defaultValue={cheque.monto}
                            name='monto'
                        />
                    </div>
                    {errors.monto && (
                        <small className='help-blockParams form-Text'>
                            {errors.monto[0]}
                        </small>
                    )}
                </div>
            </div>
            {afectarCuentas && (
                <div>
                    <table className="table table-compact m-t-20">
                        <thead>
                            <tr>
                                <th>Acreditar Cuenta Contable</th>
                                <th>Descripción</th>
                                <th width="200">Monto</th>
                                <th width="60"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {movimientos.map((movimiento, index) => (
                                <MovimientoCuenta
                                    key={movimiento.id}
                                    movimiento={movimiento}
                                    index={index}
                                    getOptions={getOptions}
                                    removeMovimiento={removeMovimiento}
                                    errors={errors}
                                    cuentasContables={cuentasContables}
                                    actualizarMonto={actualizarMonto}
                                />
                            ))}
                            <tr>
                                <td>
                                {errors.total_creditos && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.total_creditos[0]}
                                    </small>
                                )}
                                </td>
                                <td className="text-right"><strong>Total</strong></td>
                                <td>
                                    <input type="hidden" name="total_creditos" value={totalCreditos} />
                                    {formatCurrency(totalCreditos)}
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="text-right">
                        <button onClick={addMovimiento} type="button" className="btn">
                            <i className="fa fa-plus m-r-10"></i> Agregar Cuenta Contable
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Form;

const MovimientoCuenta = ({movimiento, index, errors, removeMovimiento, cuentasContables, actualizarMonto}) => {
    return (
        <tr>
            <td>
                <input type="hidden" name={`movimientos_cuentas_contables[${index}][id]`} value={movimiento.id} />
                <Select
                    key={"cuenta" + index}
                    name={`movimientos_cuentas_contables[${index}][cuenta_contable_id]`}
                    placeholder='Seleccionar...'
                    menuPosition='fixed'
                    menuPlacement='auto'
                    defaultValue={movimiento.cuenta?.nombre && ({
                        label: movimiento.cuenta.nombre,
                        value: movimiento.cuenta.id,
                    })}
                    options={cuentasContables.map(c => ({
                        label: c.nombre,
                        value: c.id
                    }))}
                />
                {errors[`movimientos_cuentas_contables.${index}.cuenta_contable_id`] && (
                    <small className='help-blockParams form-Text'>
                        {errors[`movimientos_cuentas_contables.${index}.cuenta_contable_id`][0]}
                    </small>
                )}
            </td>
            <td>
                <div className='input-group'>
                    <input
                        type='text'
                        name={`movimientos_cuentas_contables[${index}][descripcion]`}
                        className='text-input form-control'
                        defaultValue={movimiento.descripcion}
                    />
                </div>
                {errors[`movimientos_cuentas_contables.${index}.descripcion`] && (
                    <small className='help-blockParams form-Text'>
                        {errors[`movimientos_cuentas_contables.${index}.descripcion`][0]}
                    </small>
                )}
            </td>
            <td>
                <div className='input-box'>
                    <span className="prefix">RD$</span>
                    <input
                        type='number'
                        name={`movimientos_cuentas_contables[${index}][monto]`}
                        onChange={(e) => {
                            actualizarMonto(movimiento.id, e.target.value);
                        }}
                        defaultValue={movimiento.monto}
                    />
                </div>
                {errors[`movimientos_cuentas_contables.${index}.monto`] && (
                    <small className='help-blockParams form-Text'>
                        {errors[`movimientos_cuentas_contables.${index}.monto`][0]}
                    </small>
                )}
            </td>
            <td className="text-center">
                <button type="button" className="btn" onClick={() => removeMovimiento(movimiento.id)}>
                    <i className="fa fa-trash"></i>
                </button>
            </td>
        </tr>
    );
}
