import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency, formatNumber } from "../../../utils/helpers";

class PrintItems extends Component {
	state = {
		items: [],
	};
	componentDidMount() {
		this.getItems();
	}
	getItems = async () => {
		const response = await this.props.request(
			"items" + window.location.search
		);

		if (response.data) {
			this.setState({
				items: response.data,
			});
			window.print();
		}
	};
	render() {
		const { items } = this.state;
		const user = auth.getUser();
		const sucursal = user.sucursales.filter(
			(suc) => suc.id === user.sucursalActual
		)[0];

		const valorTotal = items.reduce((acc, item) => {
			return (
				acc +
				(isNaN(parseFloat(item.cantidad))
					? 0
					: parseFloat(item.cantidad) * parseFloat(item.costo))
			);
		}, 0);

		return (
			<div>
				<div className="row">
					<h2 className="col-md-12 text-center m-b-5">
						Reporte Inventario
					</h2>
					<div className="col-md-12 text-center">
						{sucursal.nombre} - {sucursal.direccion} •{" "}
						{new Date().toLocaleString()}
					</div>
					<div className="col-md-12 text-right p-b-10">
						<div className="float-left">
							TOTAL ITEMS: {items.length}
						</div>

                        <div className='float-right'>
                            <h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
                        </div>
                    </div>
                </div>
                <table className='table m-b-40'>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Ref.</th>
                            <th>Almacén</th>
                            <th>Categoría</th>
                            <th className='text-right'>Disponible</th>
                            <th className='text-right'>Costo</th>
                            <th className='text-right'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.referencia}</td>
                                    <td>{item.nombre}</td>
                                    <td>
                                        {item.almacen && item.almacen.nombre}
                                    </td>
                                    <td>{item.grupo && item.grupo.nombre}</td>
                                    <td className='text-right'>
                                        {formatNumber(item.cantidad)}{" "}
                                        {item.unidad}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(item.precios[0]?.precio_venta)}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(
                                            parseFloat(item.precios[0]?.precio_venta) *
                                                parseFloat(item.cantidad)
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='row'>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL ITEMS: {items.length}
                        </div>

						<div className="float-right">
							<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withRequest(PrintItems));
