import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withRequest } from "../../utils/request";
import { formatCurrency, getLink, getMoneda } from "../../utils/helpers";
import Loader from "../general/Loader";
import Transacciones from "./Transacciones";
import Modal from "../general/Modal";
import Edit from "./Edit";
import Transaccion from "./Transaccion";

class Layout extends Component {
    state = {
        cuenta: {},
        loading: true,
        cuentas: [],
        showEdit: false,
        showTransaccion: false,
    };

    componentDidMount() {
        this.getCuentas();
    }

    getCuentas = async (url = null) => {
        const id = parseInt(this.props.match.params.id);
        url = url ? url : "cuentas";
        const cuentas = await this.props.request(url);

        if (cuentas.data) {
            this.setState({
                cuenta: cuentas.data.filter(s => s.id === id)[0],
                cuentas: cuentas.data,
                loading: false,
            });
        }
    };

    toggleEditModal = () => {
        this.setState({
            showEdit: !this.state.showEdit,
        });
    };

    toggleTransaccionModal = () => {
        this.setState({
            showTransaccion: !this.state.showTransaccion,
        });
    };

    render() {
        const { loading, cuenta } = this.state;

        if (loading) {
            return <Loader />;
        }

        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/cuentas/')}>&laquo; Volver a cuentas</Link>
                    <div className="page-title with-subtitle clearfix">
                        <div className="float-left">
                            <div>{cuenta.nombre}</div>
                        </div>
                        <div className="float-right"><small>Balance:</small> <strong>{formatCurrency(cuenta.balance, cuenta.moneda)}</strong></div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2">
                        <strong>Tipo:</strong> <div>{cuenta.tipo}</div>
                    </div>
                    {cuenta.ubicacion && (<div className="col-md-2">
                        <strong>Ubicación:</strong> <div>{cuenta.ubicacion}</div>
                    </div>)}
                    <div className="col-md-2">
                        <strong>Moneda:</strong> <div>{getMoneda(cuenta.moneda)}</div>
                    </div>
                    {cuenta.banco && (<div className="col-md-3">
                        <strong>Banco:</strong> <div>{cuenta.banco}</div>
                    </div>)}
                    {cuenta.numeroCuenta && (<div className="col-md-2">
                        <strong>Número de Cuenta:</strong> <div>{cuenta.numeroCuenta}</div>
                    </div>)}
                    {cuenta.notas && (<div className="col-md-12">
                        <strong>Notas:</strong> <div>{cuenta.notas}</div>
                    </div>)}
                </div>

                <Transacciones
                    cuenta={cuenta}
                    toggleTransaccionModal={this.toggleTransaccionModal}
                    toggleEditModal={() => this.toggleEditModal({})}
                />

                <Modal
                    show={this.state.showTransaccion}
                    blank={true}
                    toggle={this.toggleTransaccionModal}>
                    <Transaccion
                        toggle={this.toggleTransaccionModal}
                        cuenta={cuenta}
                        cuentas={this.state.cuentas}
                        getCuentas={this.getCuentas}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        cuenta={cuenta}
                        getCuentas={this.getCuentas}
                        borrarCuenta={this.toggleBorrarCuenta}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(Layout));
