import React, { Component } from "react";
import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import Select from "react-select";

class Form extends Component {
	state = {
		sucursales: [],
		rol: this.props.usuario.rol || null,
		empleados: [],
		sucursalesUsuario: [],
	};

	componentDidMount() {
		this.getVendedoresForDropdown();
		this.setState({
			sucursalesUsuario: this.props.usuario?.sucursales?.map(
				(sucursal) => ({
					value: sucursal.id,
					label: sucursal.nombre,
				})
			),
		});
	}

	getVendedoresForDropdown = () => {
		this.props.request("empleados?limit=100").then((res) => {
			this.setState((state) => ({
				...state,
				empleados: res.data.map((empleado) => ({
					value: empleado.id,
					label: `${empleado.codigo} ${empleado.nombre} ${empleado.apellido}`,
				})),
			}));
		});
	};

	changeSelectedSucursales = (values) => {
		this.setState({ sucursalesUsuario: values });
	};

	render() {
		const { usuario, sucursales, errors } = this.props;
		const { empleados, rol, sucursalesUsuario } = this.state;

        const user = auth.getUser();
        const sucursalActual = user.sucursales.filter(s => s.id === user.sucursalActual)[0];

		return (
			<div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Nombre de Usuario:
						</label>
					</div>
					<div className="col-12 col-md-7">
						<input
							type="text"
							className="text-input form-control"
							id="username"
							name="username"
							defaultValue={usuario.usuario}
						/>
						{errors.username && (
							<small className="help-blockParams form-Text">
								{errors.username[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Nombre:
						</label>
					</div>
					<div className="col-md-7">
						<input
							type="text"
							className="text-input form-control"
							id="nombre"
							name="nombre"
							defaultValue={usuario.nombre}
						/>
						{errors.nombre && (
							<small className="help-blockParams form-Text">
								{errors.nombre[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Apellido:
						</label>
					</div>
					<div className="col-md-7">
						<input
							type="text"
							className="text-input form-control"
							id="apellido"
							name="apellido"
							defaultValue={usuario.apellido}
						/>
						{errors.apellido && (
							<small className="help-blockParams form-Text">
								{errors.apellido[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Email
						</label>
					</div>
					<div className="col-md-7">
						<input
							type="email"
							className="text-input form-control"
							id="email"
							name="email"
							defaultValue={usuario.email}
						/>
						{errors.email && (
							<small className="help-blockParams form-Text">
								{errors.email[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">Rol</label>
					</div>
					<div className="col-md-7">
						<select
							className="text-input form-control"
							id="role"
							defaultValue={usuario.rol}
							name="role"
							onChange={(e) =>
								this.setState({ rol: e.target.value })
							}
						>
							<option value="rrhh">RRHH</option>
							<option value="admin">Admin</option>
							<option value="editor">Editor</option>
							<option value="digitador">Digitador</option>
							<option value="cobrador">Cobrador</option>
							<option value="compras">Compras</option>
							<option value="gasto">Gasto</option>
							<option value="nomina">Nómina</option>
							<option value="solicitudes">Solicitudes</option>
							<option value="vendedor">Vendedor Órdenes de compra</option>
							<option value="vendedor-cliente">Vendedor Ordenes de Compra Clientes</option>
							<option value="vendedor-factura">Vendedor Facturación</option>
							<option value="vendedor-factura-orden_compra">Vendedor Facturas + Órdenes de Compra</option>
							<option value="vendedor-admin">Vendedor Administrador</option>
							<option value="contratos">Contratos</option>
                            {sucursalActual.tipo === 'educativa' && (
                                <option value="edu.caja">EDU Caja</option>
                            )}
                            {sucursalActual.tipo === 'educativa' && (
                                <option value="edu.recepcion">EDU Recepción</option>
                            )}
							{auth.is("super-admin") && (
								<option value="super-admin">Super Admin</option>
							)}
						</select>
						{errors.role && (
							<small className="help-blockParams form-Text">
								{errors.role[0]}
							</small>
						)}
					</div>
				</div>

				{(rol === "vendedor" || rol === "vendedor-factura" || "vendedor-factura-orden_compra") && (
					<div className="row form-group">
						<div className="col col-md-5">
							<label className="label form-control-label">
								Empleado
							</label>
						</div>
						<div className="col-md-7">
							<Select
								name="empleado_id"
								options={empleados}
								defaultValue={
									usuario.empleado && {
										value: usuario.empleado?.id,
										label: `${usuario.empleado?.codigo} ${usuario.empleado?.nombre} ${usuario.empleado?.apellido}`,
									}
								}
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
								}}
							/>
							{errors.empleado_id && (
								<small className="help-blockParams form-Text">
									{errors.empleado_id[0]}
								</small>
							)}
						</div>
					</div>
				)}

				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Activo
						</label>
					</div>
					<div className="col-md-7">
						<label className="switch switch-3d switch-success mr-3">
							<input
								key={usuario.id}
								type="checkbox"
								className="switch-input"
								id="active"
								name="active"
								value="yes"
								defaultChecked={usuario.estado ? true : false}
							/>
							<span className="switch-label" />
							<span className="switch-handle" />
						</label>
					</div>
				</div>
				{!auth.is("admin") && (
					<div className="row form-group">
						<div className="col col-md-5">
							<label className="label form-control-label">
								Sucursales
							</label>
						</div>
						<div className="col-md-7">
							<Select
								options={sucursales.map((sucursal) => ({
									value: sucursal.id,
									label: sucursal.nombre,
								}))}
								isMulti
								name="sucursales[]"
								onChange={(e) =>
									this.changeSelectedSucursales(e)
								}
								value={sucursalesUsuario}
								menuPortalTarget={document.body}
								styles={{
									menuPortal: (base) => ({
										...base,
										zIndex: 9999,
									}),
								}}
							/>
						</div>
					</div>
				)}

				{auth.is("admin") && (
					<input
						type="hidden"
						name="sucursales[]"
						value={auth.getUser().sucursalActual}
					/>
				)}

				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Contraseña
						</label>
					</div>
					<div className="col-md-7">
						<input
							type="password"
							className="text-input form-control"
							id="password"
							name="password"
						/>
						{errors.password && (
							<small className="help-blockParams form-Text">
								{errors.password[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label className="label form-control-label">
							Confirmar Contraseña
						</label>
					</div>
					<div className="col-md-7">
						<input
							type="password"
							className="text-input form-control"
							id="password_confirmation"
							name="password_confirmation"
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default withRequest(Form);
