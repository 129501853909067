import React, { Component } from "react";
import Select from "react-select";
import dayjs from "dayjs";

class Form extends Component {
    render() {
        const { reclamacion, rutas, empleados, vehiculos, errors } = this.props;
        return (
            <div>
                {reclamacion.id && (
                    <input
                        type='hidden'
                        name='reclamacion_id'
                        value={reclamacion.id}
                    />
                )}
                <input
                    type='hidden'
                    name='estado'
                    value={reclamacion.estado ?? "pendiente"}
                />
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Fecha:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <input
                            type='date'
                            className='text-input form-control'
                            id='fecha'
                            name='fecha'
                            defaultValue={
                                reclamacion.fecha &&
                                dayjs(reclamacion.fecha).format("YYYY-MM-DD")
                            }
                        />
                        {errors.fecha && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Nombre:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre_reclamador'
                            name='nombre_reclamador'
                            defaultValue={reclamacion.nombreReclamador}
                        />
                        {errors.nombre_reclamador && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre_reclamador[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Teléfono:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='telefono_reclamador'
                            name='telefono_reclamador'
                            defaultValue={reclamacion.telefonoReclamador}
                        />
                        {errors.telefono_reclamador && (
                            <small className='help-blockParams form-Text'>
                                {errors.telefono_reclamador[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Dirección:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='direccion'
                            name='direccion'
                            defaultValue={reclamacion.direccion}
                        />
                        {errors.direccion && (
                            <small className='help-blockParams form-Text'>
                                {errors.direccion[0]}
                            </small>
                        )}
                    </div>
                </div>
                {rutas.length > 0 && (
                    <div className='row form-group'>
                        <div className='col col-md-3'>
                            <label className='label form-control-label'>
                                Zona:
                            </label>
                        </div>
                        <div className='col col-md-9'>
                            <Select
                                name='ruta_id'
                                isClearable={true}
                                defaultValue={
                                    reclamacion.ruta && {
                                        isFixed: true,
                                        value: reclamacion.ruta.id,
                                        label: reclamacion.ruta.nombre,
                                    }
                                }
                                options={rutas.map((ruta) => ({
                                    value: ruta.id,
                                    label: `${ruta.nombre} ${ruta.descripcion}`,
                                }))}
                            />
                            {errors.fecha && (
                                <small className='help-blockParams form-Text'>
                                    {errors.fecha[0]}
                                </small>
                            )}
                        </div>
                    </div>
                )}
                {empleados.length > 0 && (
                    <div className='row form-group'>
                        <div className='col col-md-3'>
                            <label className='label form-control-label'>
                                Supervisor:
                            </label>
                        </div>
                        <div className='col col-md-9'>
                            <Select
                                name='supervisor_id'
                                isClearable={true}
                                defaultValue={
                                    reclamacion.supervisor && {
                                        isFixed: true,
                                        value: reclamacion.supervisor.id,
                                        label: reclamacion.supervisor.nombre,
                                    }
                                }
                                options={empleados
                                    .filter(
                                        (emp) =>
                                            emp.cargo
                                                .toLowerCase()
                                                .indexOf("supervisor") > -1
                                    )
                                    .map((empleado) => ({
                                        value: empleado.id,
                                        label: `${empleado.nombreCompleto}`,
                                    }))}
                            />
                            {errors.supervisor_id && (
                                <small className='help-blockParams form-Text'>
                                    {errors.supervisor_id[0]}
                                </small>
                            )}
                        </div>
                    </div>
                )}
                {vehiculos.length > 0 && (
                    <div className='row form-group'>
                        <div className='col col-md-3'>
                            <label className='label form-control-label'>
                                Ficha:
                            </label>
                        </div>
                        <div className='col col-md-9'>
                            <Select
                                name='vehiculo_id'
                                isClearable={true}
                                defaultValue={
                                    reclamacion.ficha && {
                                        isFixed: true,
                                        value: reclamacion.ficha.id,
                                        label: reclamacion.ficha.ficha,
                                    }
                                }
                                options={vehiculos.map((vehiculo) => ({
                                    value: vehiculo.id,
                                    label: `${vehiculo.ficha}`,
                                }))}
                            />
                            {errors.vehiculo_id && (
                                <small className='help-blockParams form-Text'>
                                    {errors.vehiculo_id[0]}
                                </small>
                            )}
                        </div>
                    </div>
                )}
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Detalles:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <textarea
                            type='text'
                            className='text-input form-control'
                            id='comentario'
                            name='comentario'
                            rows='8'
                            defaultValue={reclamacion.comentario}
                        />
                        {errors.comentario && (
                            <small className='help-blockParams form-Text'>
                                {errors.comentario[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
