import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { auth } from "../../../../utils/auth";
import { isVendedor } from "../../../../utils/helpers";
import { clientLabel } from "../../Components/utils";

const Detalles = ({
	form,
	contactoInfo,
	dropdownOptions,
	toggleCreateContactoModal,
	toggleEditContactoModal,
	handleInputChange,
	aplicarLimiteFecha,
	handleDetalleChange,
	tipoOrden,
	isEdit,
    changeContactInfo,
    contactoId,
    plazo,
    changePlazo,
    errors,
    setContactoId,
}) => {
	return (
		<>
            {plazo && (
                <input
                    type="hidden"
                    name="tipo"
                    value={
                        plazo?.dias > 0
                            ? "credito"
                            : "contado"
                    }
                />
            )}
			<div className="row">
				{/* PRIMERA COLUMNA */}
				<div className="col-12 col-lg-4 d-inline-block">
					<div
						className="form-group"
						style={{ marginBottom: "0.3rem" }}
					>
						<label
							htmlFor="inputContacto"
							className="orden-filter-label"
						>
							{tipoOrden === "interna" ? "Suplidor" : "Cliente"}
						</label>
						<div className="row">
							<Select
								id="inputContacto"
								options={dropdownOptions.contactos}
								name="contacto_id"
								onChange={(e) => {
									setContactoId(e.value);
                                    changeContactInfo(e.value);
								}}
								value={
									contactoId &&
									dropdownOptions.contactos.find(
										(contacto) =>
											contacto.value === contactoId
									)
								}
								className="col-10 pr-0"
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
									singleValue: (provided, state) => ({
										...provided,
										display: "flex",
										width: "100%",
									}),
								}}
								formatOptionLabel={(option) =>
									clientLabel(option)
								}
								isDisabled={isEdit}
							/>
							{!isEdit &&
								!isVendedor() && (
									<div
										className="tooltip-self color-primary d-inline-block ml-2"
										style={{ alignSelf: "center" }}
									>
										<i
											className="fas fa-plus-circle color-primary"
											style={{
												fontSize: "1.25rem",
												cursor: "pointer",
											}}
											onClick={toggleCreateContactoModal}
										></i>
										<span className="tootip-text tooltip-right">
											Crear Contacto
										</span>
									</div>
								)}
							{contactoId &&
								!isVendedor() && (
									<div
										className="tooltip-self color-primary d-inline-block ml-2"
										style={{ alignSelf: "center" }}
									>
										<i
											className="fas fa-edit color-primary"
											style={{
												fontSize: "1.25rem",
												cursor: "pointer",
											}}
											onClick={toggleEditContactoModal}
										></i>
										<span className="tootip-text tooltip-right">
											Editar Contacto
										</span>
									</div>
								)}
						</div>
						{errors.contacto_id && (
							<small className="help-blockParams form-Text">
								{errors.contacto_id[0]}
							</small>
						)}
					</div>
					<div
						className={`row ${
							isVendedor() &&
							"d-none d-lg-flex"
						}`}
					>
						<div
							className="form-group col-6 pr-0"
							style={{ marginBottom: "0.3rem" }}
						>
							<label
								htmlFor="inputRnc"
								className="orden-filter-label"
							>
								RNC o cédula
							</label>
							<input
								type="text"
								className="form-control"
								id="inputRnc"
								defaultValue={contactoInfo.identificacion}
								readOnly
							/>
						</div>
						<div
							className="form-group col-6"
							style={{ marginBottom: "0.3rem" }}
						>
							<label
								htmlFor="inputTelefono"
								className="orden-filter-label"
							>
								Teléfono
							</label>
							<input
								type="text"
								className="form-control"
								id="inputTelefono"
								defaultValue={contactoInfo.telefono}
								readOnly
							/>
						</div>
					</div>
					<div
						className={`form-group ${
							isVendedor() &&
							"d-none d-lg-block"
						}`}
						style={{ marginBottom: "0.3rem" }}
					>
						<label
							htmlFor="inputDireccion"
							className="orden-filter-label"
						>
							Dirección
						</label>
						<input
							type="text"
							className="form-control"
							id="inputDireccion"
							defaultValue={contactoInfo.direccion}
							readOnly
						/>
					</div>
				</div>

				{/* SEGUNDA COLUMNA */}
				<div className="col-12 col-lg-4 d-inline-block">
					<div className="row">
						<div
							className="form-group col-6 pr-0"
							style={{ marginBottom: "0.3rem" }}
						>
							<label
								htmlFor="inputEncargado"
								className="orden-filter-label"
							>
								Encargado
							</label>
							<input
								type="text"
								className="text-input form-control"
								id="inputEncargado"
								defaultValue={contactoInfo.encargado}
								readOnly
							/>
						</div>
						<div
							className="form-group col-6"
							style={{ marginBottom: "0.3rem" }}
						>
							<label
								htmlFor="inputCelular"
								className="orden-filter-label"
							>
								Celular
							</label>
							<input
								type="text"
								className="form-control"
								id="inputCelular"
								defaultValue={contactoInfo.celular}
								readOnly
							/>
						</div>
					</div>
					<div
						className={`form-group ${
							isVendedor() &&
							"d-none d-lg-block"
						}`}
						style={{ marginBottom: "0.3rem" }}
					>
						<label
							htmlFor="inputVendedor"
							className="orden-filter-label"
						>
							Vendedor
						</label>
						<Select
							id="inputVendedor"
							options={dropdownOptions.vendedores}
							name="empleado_id"
							value={contactoInfo.vendedor}
							styles={{
								control: (styles) => ({
									...styles,
									height: "38px",
								}),
							}}
							onChange={(e) => handleDetalleChange(e, "vendedor")}
							isDisabled={
								isVendedor()
							}
						/>
						{isVendedor() && (
							<input
								type="hidden"
								name="empleado_id"
								value={auth.getUser().empleado?.id}
							/>
						)}
						{errors.empleado_id && (
							<small className="help-blockParams form-Text">
								{errors.empleado_id[0]}
							</small>
						)}
					</div>
					<div
						className={`form-group ${
							isVendedor() &&
							"d-none d-lg-block"
						}`}
						style={{ marginBottom: "0.3rem" }}
					>
						<label
							htmlFor="inputRuta"
							className="orden-filter-label"
						>
							Zona
						</label>
						<Select
							id="inputRuta"
							options={
								isVendedor()
									? JSON.parse(localStorage.getItem("rutas"))
									: dropdownOptions.rutas
							}
							name="ruta_id"
							value={contactoInfo.ruta}
							styles={{
								control: (styles) => ({
									...styles,
									height: "38px",
								}),
							}}
							onChange={(e) => handleDetalleChange(e, "ruta")}
						/>
						{errors.ruta_id && (
							<small className="help-blockParams form-Text">
								{errors.ruta_id[0]}
							</small>
						)}
					</div>
					{isVendedor() && (
						<>
							<input
								type="hidden"
								name="empleado_id"
								value={auth.getUser().empleado?.id}
							/>{" "}
							<input
								type="hidden"
								name="ruta_id"
								value={contactoInfo.ruta?.value}
							/>
						</>
					)}
				</div>

				{/* TERCERA COLUMNA */}
				<div className="col-12 col-lg-4 d-inline-block">
					<div className="row">
						<div
							className={`form-group col-12 col-sm-6 pr-0 ${
								isVendedor() &&
								"d-none d-lg-block"
							}`}
							style={{ marginBottom: "0.3rem" }}
						>
							<label
								htmlFor="tipo"
								className="orden-filter-label"
							>
								Tipo de orden
							</label>
							<select
								type="text"
								name="tipo"
								className="form-control"
								id="tipo"
								onChange={(e) =>
									handleInputChange(e.target.value, "tipo")
								}
								value={form.tipo}
							>
								<option value="cliente">
									Orden Para Cliente
								</option>
								{!auth.is("vendedor") && !auth.is("vendedor-factura-orden_compra") && !auth.is("cobrador") && !auth.is('vendedor-cliente')
									&& (
										<option value="interna">
											Orden Interna
										</option>
									)}
							</select>
						</div>
						<div
							className={`form-group col-12 col-sm-6 ${
								isVendedor() &&
								"d-none d-lg-block"
							}`}
							style={{ marginBottom: "0.3rem" }}
						>
							<label
								htmlFor="inputEncargado"
								className="orden-filter-label"
							>
								Número de orden
							</label>
							<input
								type="text"
								className="text-input form-control"
								id="inputEncargado"
								name="numero_comprobante"
								value={form.numero_orden?.label || ''}
								readOnly
							/>
							<input
								type="hidden"
								name="numeracion_id"
								value={form.numero_orden?.value}
							/>
						</div>
						{isVendedor() && (
							<>
								<input
									type="hidden"
									className="text-input form-control"
									id="inputEncargado"
									name="numero_comprobante"
									defaultValue={form.numero_orden?.label}
									readOnly
								/>
								<input
									type="hidden"
									name="numeracion_id"
									value={form.numero_orden?.value}
								/>
								<input
									type="hidden"
									name="tipo"
									value="cliente"
								/>
							</>
						)}
					</div>
					<div
						className={`row ${
							isVendedor() &&
							"d-none d-lg-flex"
						}`}
					>
						<div
							className="form-group col-6 pr-0"
							style={{ marginBottom: "0.3rem" }}
						>
							<label
								htmlFor="fecha"
								className="orden-filter-label"
							>
								Fecha
							</label>
							<input
								type="date"
								className="form-control"
								id="fecha"
								name="fecha"
								onChange={(e) =>
									handleInputChange(e.target.value, "fecha")
								}
								value={form.fecha}
								readOnly={
									auth.is("vendedor") ||
									auth.is("vendedor-factura-orden_compra")
								}
							/>
							{errors.fecha && (
								<small className="help-blockParams form-Text">
									{errors.fecha[0]}
								</small>
							)}
						</div>
						<div
							className="form-group col-6"
							style={{ marginBottom: "0.3rem" }}
						>
							<label
								htmlFor="fecha_vencimiento"
								className="orden-filter-label"
							>
								Vencimiento
							</label>
							<input
								type="date"
								max={`${aplicarLimiteFecha()}`}
								className="form-control"
								id="fecha_vencimiento"
								name="fecha_vencimiento"
								value={form.fecha_vencimiento}
								onChange={(e) =>
									handleInputChange(
										e.target.value,
										"fecha_vencimiento"
									)
								}
								readOnly={plazo.dias !== null}
							/>
							{errors.fecha_vencimiento && (
								<small className="help-blockParams form-Text">
									{errors.fecha_vencimiento[0]}
								</small>
							)}
						</div>
					</div>
					{isVendedor() && (
						<>
							<input
								type="hidden"
								name="fecha"
								value={form.fecha}
							/>
							<input
								type="hidden"
								name="fecha_vencimiento"
								value={form.fecha_vencimiento}
							/>
						</>
					)}

					<div className="row">
						<div
							className={`form-group ${
								plazo.dias === 0 ? "col-12" : "col-4 pr-0"
							}`}
							style={{ marginBottom: "0.3rem" }}
						>
							<label
								htmlFor="inputPlazo"
								className="orden-filter-label"
							>
								Plazo de pago
							</label>
							<Select
								id="inputPlazo"
								options={
									contactoInfo.plazo
										? dropdownOptions.terminos.filter(
												(termino) => {
													if (
														termino.dias <=
														contactoInfo.plazo
															?.dias
													) {
														return termino;
													}
												}
										  )
										: []
								}
								name="plazo_id"
								value={plazo}
								onChange={(e) => {changePlazo(e)}}
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
								}}
							/>
							{errors.plazo_id && (
								<small className="help-blockParams form-Text">
									{errors.plazo_id[0]}
								</small>
							)}
						</div>
						{plazo.dias !== 0 && (
							<>
								<div
									className="form-group col-4 pr-0"
									style={{ marginBottom: "0.3rem" }}
								>
									<label
										htmlFor="credito_aprobado"
										className=""
									>
										Credito aprobado
									</label>
									<input
										type="text"
										className="form-control"
										id="credito_aprobado"
										value={contactoInfo.limite_credito}
										readOnly
									/>
								</div>
								<div
									className="form-group col-4 pr-0"
									style={{ marginBottom: "0.3rem" }}
								>
									<label
										htmlFor="credito_disponible"
										className=""
									>
										Credito disponible
									</label>
									<input
										className="form-control"
										id="credito_disponible"
										name="credito_disponible"
										value={
											contactoInfo.credito_disponible || 0
										}
										readOnly
									/>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

Detalles.propTypes = {
	dropdownOptions: PropTypes.object,
	contactoInfo: PropTypes.object,
	form: PropTypes.object,
	handleInputChange: PropTypes.func,
	tipoOrden: PropTypes.string,
	aplicarLimiteFecha: PropTypes.func,
	handleDetalleChange: PropTypes.func,
	isEdit: PropTypes.bool,
};

export default React.memo(Detalles);
