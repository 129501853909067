import React, { Component } from "react";

import { withRequest } from "../../../utils/request";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../../general/Loader";
import { Link } from "react-router-dom";
import { getLink } from "../../../utils/helpers";

class Grupos extends Component {
    state = {
        tipos: {},
        tipo: {},
        links: null,
        showCreate: false,
        showEdit: false,
        loading: true,
    };
    componentDidMount() {
        this.getTipos();
    }
    getTipos = async (url = null) => {
        url = url ? url : "categorias-contrato";
        const grupos = await this.props.request(url);

        if (grupos.data) {

            this.setState({
                tipos: grupos.data,
                loading: false,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            grupo: {},
        });
    };
    toggleEditModal = (tipo) => {
        this.setState({
            showEdit: !this.state.showEdit,
            tipo: tipo,
        });
    };
    render() {
        const { tipos, loading } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/configuracion/')}>&laquo; Volver a configuración</Link>
                    <h1 className='page-title row'>
                        <span className='col'>Categorías de Contratos</span>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Categoria
                        </button>
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!tipos.length ? (
                                <div>
                                    {!loading && (
                                        <div>
                                            Todavía no hay tipos de contratos creados.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tipos.map((tipo) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={tipo.id}>
                                                    <td>{tipo.nombre}</td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <button
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Edit'
                                                                onClick={() => {
                                                                    this.toggleEditModal(
                                                                        tipo
                                                                    );
                                                                }}>
                                                                <i className='zmdi zmdi-edit' />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getTipos}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        tipo={this.state.tipo}
                        getTipos={this.getTipos}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        tipo={this.state.tipo}
                        getTipos={this.getTipos}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Grupos);
