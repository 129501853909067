import React, { Component } from "react";
import { getCurrentMonthName, getMiddleDate } from "../../utils/helpers";
import dayjs from "dayjs";
import Select from "react-select";
import SelectAsync from "react-select/async";
import { withRequest } from "../../utils/request";
class Form extends Component {
    state = {
        nombre: '',
        tipo: 'FIJOS',
        grupos: [],
        nombreGrupos: '',
        fecha_inicio: '',
        fecha_cierre: '',
        periodo: '',
        tipoEmpleados: '',
        tipoEmpleadosId: '',
    };
    onChangeTipo = async (e) => {
        const tipo = e.target.value;

        await this.setState({
            tipo: tipo,
            periodo: tipo === 'FIJOS' ? 'quincenal' : 'mensual',
        });

        const fechas = this.getFechas();

        await this.setState({
            fecha_inicio: fechas.inicio,
            fecha_cierre: fechas.cierre,
        });

        this.setState({
            nombre: this.getNombre(tipo, dayjs()),
        });

    };

    onChangeEmpleados = async (values) => {
        if (!values) {
            await this.setState({
                tipoEmpleados: '',
                tipoEmpleadosId: '',
            });
        } else {
            const label  = values.map(v => v.label).join(', ');
            const value  = values.map(v => v.value).join(',');

            await this.setState({
                tipoEmpleados: label,
                tipoEmpleadosId: value,
            });
        }

        this.setState({
            nombre: this.getNombre(this.state.tipo, dayjs()),
        });
    };

    getNombre = (tipo, fecha) => {
        const { periodo, tipoEmpleados, nombreGrupos } = this.state;
        const month = getCurrentMonthName(fecha.month());
        const year = fecha.year();
        const inicio = dayjs(this.state.fecha_inicio);
        const cierre = dayjs(this.state.fecha_cierre);
        const inicioMonth = getCurrentMonthName(inicio.month());
        const cierreMonth = getCurrentMonthName(cierre.month());
        let nombre = '';

        if (nombreGrupos) {
            nombre += nombreGrupos;
        }

        if (tipoEmpleados) {
            if (nombreGrupos) {
                nombre += ' & ' + tipoEmpleados;
            } else {
                nombre += tipoEmpleados;
            }
        }

        if (periodo === 'mensual') {
            return (
                month + " " + fecha.year() + " - " + tipo + " " + tipoEmpleados
            );
        }
        if (periodo === 'quincenal') {
            if (fecha.date() > 15) {
                return `${month} ${year} - ${nombre} - 2da Quincena`;
            } else {
                return `${month} ${year} - ${nombre} - 1ra Quincena`;
            }
        }
        if (periodo === 'semanal') {
            if (inicioMonth !== cierreMonth) {
                return (
                    `Del ${inicio.date()} ${inicioMonth} al ${cierre.date()} ${cierreMonth} ${inicio.year()}`
                );
            }

            return (
                `Del ${inicio.date()} al ${cierre.date()} ${cierreMonth} ${inicio.year()} - ${nombre}`
            );
        }
    };
    getFechas = () => {
        const {periodo} = this.state;
        let inicio, cierre;
        let format = "YYYY-MM-DD";

        if (periodo === 'mensual') {
            inicio = dayjs().startOf("month").format(format);
            cierre = dayjs().endOf("month").format(format);
        }
        if (periodo === 'semanal') {
            inicio = dayjs().startOf("week").format(format);
            cierre = dayjs().endOf("week").format(format);
        }
        if (periodo === 'quincenal') {
            if (dayjs().date() > 15) {
                inicio = getMiddleDate();
                cierre = dayjs().endOf("month").format(format);
            } else if (dayjs().date() > 15) {
                inicio = getMiddleDate();
                cierre = dayjs().endOf("month").format(format);
            } else {
                inicio = dayjs().startOf("month").format(format);
                cierre = getMiddleDate();
            }
        }

        return { inicio, cierre };
    };
    onChangeNombre = (e) => {
        this.setState({
            nombre: e.target.value,
        });
    };
    onChangeGrupos = async (e) => {
        if (e === null) {
            await this.setState({
                grupos: [],
                nombreGrupos: '',
            });
        } else {
            await this.setState({
                grupos: e.map(v => v.value),
                nombreGrupos: e.map(v => v.label).join(', '),
            });
        }

        this.setState({
            nombre: this.getNombre(this.state.tipo, dayjs()),
        });
    };
    onChangePeriodo = async (e) => {
        await this.setState({
            periodo: e.target.value,
        });

        const fechas = this.getFechas();

        await this.setState({
            fecha_inicio: fechas.inicio,
            fecha_cierre: fechas.cierre,
        });

        this.setState({
            nombre: this.getNombre(this.state.tipo, dayjs()),
        })
    };
    onChangeDate = async (e) => {
        const target = e.target;
        await this.setState({
            [target.name]: target.value,
        });

        this.setState({
            nombre: this.getNombre(this.state.tipo, dayjs(target.value)),
        });
    };

    getOptions = async (url = null, label = "nombre", value = "id") => {
		const table = await this.props.request(url);
		if (table.data) {
			return table.data.map((item) => ({
				value: item[value],
				label: item[label],
			}));
		}
	};

    render() {
        const { nombre, tipo, grupos, fecha_inicio, fecha_cierre, periodo, tipoEmpleadosId } = this.state;
        const { errors, tiposEmpleados, gruposEmpleados } = this.props;
        const tiposSeleccionados = tiposEmpleados
            .map((t) => {
                return {
                    label: t.nombre,
                    value: t.id,
                };
            });

        const gruposDisponibles = gruposEmpleados.filter(g => {
            return g.periodo === periodo;
        });

        return (
            <div>
                <input type="hidden" name="tipo" value='FIJOS' />
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Periodo:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <select
                            name='periodo'
                            className="form-control"
                            required
                            onChange={this.onChangePeriodo}
                            value={periodo}
                        >
                            <option value="">Seleccionar...</option>
                            <option value="semanal">Semanal</option>
                            <option value="quincenal">Quincenal</option>
                            <option value="mensual">Mensual</option>
                        </select>
                        {errors.periodo && (
                            <small className='help-blockParams form-Text'>
                                {errors.periodo[0]}
                            </small>
                        )}
                    </div>
                </div>

                {gruposDisponibles.length > 0 && (
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Grupos de Empleados:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <Select
                                isMulti
                                onChange={this.onChangeGrupos}
                                options={[
                                    {
                                        label: gruposEmpleados.length > 0 ? "Todos" : 'No hay empleados',
                                        value: gruposEmpleados.length > 0 ? "todos" : '',
                                    },
                                    ...gruposDisponibles.map(g => ({label: g.nombre, value: g.id})),
                                ]}
                            />
                            <input type="hidden" name="grupo_empleados" value={grupos} />
                            {errors.grupo_empleados && (
                                <small className='help-blockParams form-Text'>
                                    {errors.grupo_empleados[0]}
                                </small>
                            )}
                        </div>
                    </div>
                )}

                {periodo && tipo && tiposSeleccionados.length > 0 && (
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Tipo Empleados:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <Select
                                isMulti
                                onChange={this.onChangeEmpleados}
                                options={[
                                    {
                                        label: tiposSeleccionados.length > 0 ? "Todos" : 'No hay empleados',
                                        value: tiposSeleccionados.length > 0 ? "todos" : '',
                                    },
                                    ...tiposSeleccionados,
                                ]}
                            />
                            <input type="hidden" name="tipo_empleados" value={tipoEmpleadosId}/>
                            {errors.tipo && (
                                <small className='help-blockParams form-Text'>
                                    {errors.tipo[0]}
                                </small>
                            )}
                        </div>
                    </div>
                )}
                {periodo && tiposSeleccionados.length === 0 && (
                    <div className="nota-formulario">No hay empleados disponibles en el periodo seleccionado.</div>
                )}

                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Categoría Empleados:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <SelectAsync
                            name="categorias[]"
                            isMulti
                            cacheOptions
                            defaultOptions
                            loadOptions={(e) => {
                                return this.getOptions(
                                    "taxonomias?relacion=empleados&buscar=" + e
                                );
                            }}
                        />
                    </div>
                </div>

                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Fecha de Inicio:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='date'
                            className='text-input form-control'
                            id='fecha_inicio'
                            name='fecha_inicio'
                            value={fecha_inicio}
                            onChange={this.onChangeDate}
                        />
                        {errors.fecha_inicio && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha_inicio[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Fecha de Cierre:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='date'
                            className='text-input form-control'
                            id='fecha_cierre'
                            name='fecha_cierre'
                            onChange={this.onChangeDate}
                            value={fecha_cierre}
                        />
                        {errors.fecha_cierre && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha_cierre[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Nombre:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre'
                            name='nombre'
                            onChange={this.onChangeNombre}
                            value={nombre}
                        />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                {/* <div className='row form-group'>
                    <div className="col col-md-4">
                        <label className='label form-control-label'>
                            Ordenar por:
                        </label>
                    </div>
                    <div className="col col-md-8">
                        <div className="form-group row">
                            <div className="col radio-btn">
                                <label>
                                    <input type="radio" name="orden" value="alfabetico" />
                                    Nombre
                                </label>
                                <label>
                                    <input type="radio" name="orden" value="codigo"/>
                                    Código
                                </label>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default withRequest(Form);
