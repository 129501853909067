import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

import { withRequest } from "../../utils/request";
import Modal from "../general/Modal";
import TicketTable from "./tickets/Edit";
import Loader from "../general/Loader";
import { getLink } from "../../utils/helpers";

class Programaciones extends Component {
    state = {
        loading: true,
        meta: null,
        links: null,
        search: "",
        ticket: {},
        error: "",
        showResults: false,
    };

    getCalendarData = async (desde, hasta) => {
        if (this.state.search) {
            return;
        }
        const programaciones = await this.props.request(
            `programaciones?desde=${desde}&hasta=${hasta}`
        );

        if (programaciones.data) {
            return programaciones.data;
        }

        return [];
    };

    clickProgramacion = (fecha) => {
        this.props.history.push(getLink(`/programaciones/${fecha.dateStr}`));
    };

    toggleModal = () => {
        this.setState({
            showResults: !this.state.showResults,
            ticket: {},
            error: "",
            search: "",
        });
    };

    searchTicket = async (e) => {
        e.preventDefault();

        this.toggleModal();

        const res = await this.props.request("tickets/" + this.state.search);

        if (res.success) {
            this.setState({
                ticket: res.data,
                error: "",
            });
        } else {
            this.setState({
                error: res.data,
                ticket: {},
            });
        }
    };

    render() {
        const { search, showResults, ticket, error } = this.state;
        return (
            <div className='container'>
                <div className='search-tickets'>
                    <form
                        onSubmit={this.searchTicket}
                        className='container-fluid'>
                        <input
                            type='text'
                            name='search'
                            placeholder='Buscar Ticket...'
                            value={search}
                            required
                            onChange={(e) =>
                                this.setState({ search: e.target.value })
                            }
                            className='form-control'
                        />
                        <button
                            type='submit'
                            className='au-btn au-btn--green au-btn--small'>
                            <i className='fas fa-search'></i>
                        </button>
                    </form>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <FullCalendar
                            defaultView='dayGridMonth'
                            events={(params) => {
                                return this.getCalendarData(
                                    params.startStr.substring(0, 10),
                                    params.endStr.substring(0, 10)
                                );
                            }}
                            /* validRange={{
                                end: dayjs().endOf("day").toDate(),
                            }} */
                            locale={esLocale}
                            dateClick={this.clickProgramacion}
                            plugins={[dayGridPlugin, interactionPlugin]}
                        />
                    </div>
                </div>
                <Modal
                    blank={ticket.id}
                    show={showResults}
                    title={error ? "Ticket No Encontrado" : "Buscando..."}
                    toggle={this.toggleModal}>
                    {!ticket.id && !error && <Loader />}
                    {error && "No hay resultados para este ticket."}
                    {ticket.id && (
                        <TicketTable
                            ticket={ticket}
                            toggle={this.toggleModal}
                        />
                    )}
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(Programaciones));
