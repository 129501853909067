/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isVendedor } from "../../../utils/helpers";
import { request } from "../../../utils/request";
import InvalidScreen from "../../general/InvalidScreen";
import Form from "./Components/FacturaForm";
import useLocalStorage from "../../../hooks/useLocalStorage";

const EditFactura = () => {
	const { id } = useParams();

    const [facturaGuardada, setFacturaGuardada] = useLocalStorage('facturaGuardada');
    const isRecovery = typeof facturaGuardada !== 'undefined';

	const [factura, setFactura] = useState({
		errors: {},
		factura: {},
		contacto: null,
		facturaProductos: [
			{
				id: Date.now(),
				subtotal: 0,
				impuestos: 0,
				total: 0,
				cantidad: 1,
				impuesto: {},
			},
		],
		comprobante: {},
		fecha: new Date(),
		fecha_vencimiento: new Date(),
		plazo: { value: 0, label: "Seleccione...", dias: 0 },
		notas: "",
		categorias: [],
	});

	const getFactura = () => {
		request("facturas/" + id).then((res) => {
			setFactura(res.data);
		});
	};

	useEffect(() => {
		getFactura();
	}, []);

	if (isVendedor() && !localStorage.getItem("rutas")) {
		return <InvalidScreen message="No hay zonas seleccionadas" />;
	}

	return (
		<>
			<Form
				facturaActual={factura}
				isEdit={true}
				fromPlantilla={false}
                isRecovery={isRecovery}
                setFacturaGuardada={setFacturaGuardada}
			/>
		</>
	);
};

export default EditFactura;
