/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { request } from "../../utils/request";
import { formatCurrency, getSucursal, money } from "../../utils/helpers";

const tipos_impuesto = {
	itbis: "Itbis",
	otro: "Otro",
	isc: "ISC",
	propina_legal: "Propina",
	no_facturable: "No facturable",
	exento: "Exento",
};

const getDiasPlazo = (fecha, fechaVencimiento) => {
	fecha = new Date(fecha);
	fechaVencimiento = new Date(fechaVencimiento);

	let difference = fecha.getTime() - fechaVencimiento.getTime();
	let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
	return Math.abs(TotalDays);
};

const limitVendedorFullName = (name) => {
	name = name.split(" ");

	if (name.length <= 3) return name.join(" ");

	return name.slice(0, 3).join(" ");
};

const tipoITBIS = (impuesto) => {
	if(!impuesto) return '';
	if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0.16) {
		return "I1";
	} else if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0.18) {
		return "I2";
	} else if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0) {
		return "IE";
	}
};

const PrintSingleCompra = () => {
	const { id } = useParams();
	const [compra, setCompra] = useState({
		productos: [],
		suplidor: {},
		vendedor: {},
	});
	const [sucursal, setSucursal] = useState({});
	const [infoBlock2Margin, setInfoBlock2Margin] = useState(10);

	const [sumatoriaFinal, setSumatoriaFinal] = useState({
		impuestos: {},
		totalITBIS: 0,
		subTotalCompra: 0,
	});
	const [isMobileDevice, setIsMobileDevice] = useState(false);
	const detalleTotal = compra.detalleTotal ?? {};

	const getCompra = () => {
		request("compras/" + id + "/print").then((res) => {
			setCompra({
				...res.data.compra,
				logo: res.data.logo,
				plantilla: res.data.plantilla,
				tipo_pago: res.data.tipo_pago,
			});
			setTimeout(() => {
				window.print();
			}, 1000);
		});
	};

	const calcularImpuestos = (productos) => {
		let impuestos = {};

		if (productos) {
			productos.forEach((producto) => {
				if (
					!tipos_impuesto[producto.impuesto?.tipo] ||
					producto.impuesto?.porcentaje === 0
				)
					return;
				if (
					isNaN(
						impuestos[
							`${tipos_impuesto[producto.impuesto.tipo]} ${
								producto.impuesto.porcentaje * 100
							}%`
						]
					)
				) {
					impuestos[
						`${tipos_impuesto[producto.impuesto.tipo]} ${
							producto.impuesto.porcentaje * 100
						}%`
					] = 0;
				}
				impuestos[
					`${tipos_impuesto[producto.impuesto.tipo]} ${
						producto.impuesto.porcentaje * 100
					}%`
				] +=
					producto.cantidad *
					(parseFloat(producto.costo) *
					producto.impuesto.porcentaje);
			});
		}

		return impuestos;
	};

	// TODO make sure that this works when theres no impuestos on a product
	const calcularTotalITBIS = (impuestos) => Object.values(impuestos).reduce((prevVal, currVal) => parseFloat(prevVal) + currVal, 0);

	const calcularSubTotalCompra = (productos) => {
		if (!productos) return;
		return productos.reduce(
			(prevVal, currVal) => prevVal + (parseFloat(currVal.costo) * parseFloat(currVal.cantidad)),
			0
		);
	};

	useEffect(() => {
		getCompra();
		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);

		// identify if mobile
		let isMobile = navigator.userAgent.toLowerCase().match(/mobile/i),
			isTablet = navigator.userAgent.toLowerCase().match(/tablet/i),
			isAndroid = navigator.userAgent.toLowerCase().match(/android/i),
			isiPhone = navigator.userAgent.toLowerCase().match(/iphone/i),
			isiPad = navigator.userAgent.toLowerCase().match(/ipad/i);

		if (isMobile || isTablet || isAndroid || isiPhone || isiPad) {
			setIsMobileDevice(true);
		}
	}, []);
	useEffect(() => {

		(async () => {
			await setSumatoriaFinal((state) => ({
				...state,
				impuestos: calcularImpuestos(compra.items),
				subTotalCompra: calcularSubTotalCompra(compra.items)
			}));
            console.log({impuestos: sumatoriaFinal.impuestos})
			setSumatoriaFinal(state => ({...state, totalITBIS: calcularTotalITBIS(state.impuestos)}));
		})();

		// This is for calculate the margin of the second column of client info. that way the last row of each column will be alined\
		if (isMobileDevice) return;

		const infoBlock1 = document.getElementById("info-block-1");
		const infoBlock2 = document.getElementById("info-block-2");
		const count =
			infoBlock2.childElementCount - infoBlock1.childElementCount;

		if (count >= 5 && !compra.logo && !sucursal.logo) {
			setInfoBlock2Margin((count * 25 - 40) * -1);
		} else {
			setInfoBlock2Margin((count * 25 + 10) * -1);
		}
	}, [compra]);
	return (
		<CompraTemplate
			compra={compra}
			sucursal={sucursal}
			infoBlock2Margin={infoBlock2Margin}
			sumatoriaFinal={sumatoriaFinal}
			isMobileDevice={isMobileDevice}
            detalleTotal={detalleTotal}
		/>
	);
};

export const CompraTemplate = ({
	compra,
	sucursal,
	infoBlock2Margin,
	sumatoriaFinal,
	isMobileDevice = false,
    detalleTotal
}) => {
	return (
		<>
			{isMobileDevice ? (
				<MobileLayout
					compra={compra}
					sucursal={sucursal}
					sumatoriaFinal={sumatoriaFinal}
                    detalleTotal={detalleTotal}
				/>
			) : (
				<>
					<DesktopLayout
						compra={compra}
						sucursal={sucursal}
						sumatoriaFinal={sumatoriaFinal}
                        detalleTotal={detalleTotal}
						infoBlock2Margin={infoBlock2Margin}
					/>
				</>
			)}
		</>
	);
};

const MobileLayout = ({
	compra,
	sucursal,
	sumatoriaFinal,
	isFactura = () => false,
	status,
    detalleTotal
}) => {
	return (
		<>
			<style>
				{`
					@page {
						size: 90mm 300mm !important;
						margin-top: 30px !important;
						margin-bottom: 0px !important;
						margin-left: 0px !important;
						margin-right: 0px !important;
					}
					.invoice {
						width: 125mm;
						font-size: large !important;
					 }
					 body {
						width: 100% !important;
					}
					.invoice-table {
						display: none;
					}
					.sm-invoice-table {
						display: block;
					}

					.invoice h3 {
						margin-bottom: 0.1rem;
					}
					.invoice h3.signature-title {
						font-size: 0.9rem;
						font-weight: 600 !important;
					}
					.client-info-title {
						font-size: 19px;
						font-weight: 600 !important;
					}
					.info-cliente-responsive .info-client__response {
						font-size: 18px !important;
						line-height: 20px !important;
					}
					.sucursal-info h3 {
						font-size: 23px !important;
						margin-bottom: 8px !important;
						font-weight: 600 !important;
					}
					.invoice-name {
						font-weight: 600 !important;
					}
					.sucursal-info div, .invoice-fecha {
						font-size: 18px !important;
					}
					.sm-invoice-table > div.row {
						font-size: 17.5px !important;
					}
					.invoice-itbis-info p, .invoice-itbis-info div {
						font-size: 19px !important;
						line-height: 22px !important;
					}
					.summary-text {
						font-size: 20px !important;
						line-height: 20px !important;
					}
					.info-cliente-responsive {
						line-height: 20px !important;
						margin-bottom: 1rem !important;
						font-size: 20px !important;
						margin-left: 5px !important;
					}
					.info-cliente-responsive .row{
						margin-bottom: 6px;
					}
				`}
			</style>
			<div className="p-2 invoice recibo-print">
				<div className="clear-both p-t-30 p-b-10">
					<div className="sucursal-info sucursal-info__mobile">
						{sucursal.logo ? (
							<img
								alt="logo"
								className="mb-2 factura-logo"
								src={sucursal.logo[0].url}
								width="270px"
							/>
						) : compra.logo ? (
							<img
								alt="logo"
								className="mb-2 factura-logo"
								src={compra.logo[0].url}
								width="270px"
							/>
						) : (
							<h3
								style={{
									lineHeight: "16px",
									marginBottom: "3px",
								}}
							>
								{sucursal.nombre}
							</h3>
						)}
						<div
							className="mb-0"
							style={{ lineHeight: "16px", fontSize: "23px" }}
						>
							RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
						</div>
						<div
							className="mb-2"
							style={{ lineHeight: "16px", fontSize: "13px" }}
						>
							{sucursal.direccion}
						</div>
					</div>

					<div className="">
						<div
							className="mb-2 invoice-fecha d-block"
							style={{ fontSize: "medium", lineHeight: "16px" }}
						>
							Fecha: {dayjs(compra.fecha).format("DD/MM/YYYY")}
							<br />
							{getDiasPlazo(
								compra.fecha,
								compra.fechaVencimiento
							) > 0 ? (
								<>
									<span>
										Plazo:{" "}
										{getDiasPlazo(
											compra.fecha,
											compra.fechaVencimiento
										) || 0}{" "}
										días
									</span>
									<br />
								</>
							) : (
								<>
									<span>Plazo: Contado</span>
									<br />
								</>
							)}
							{compra.estado === "pendiente" ? (
								<span>Estado: Pendiente</span>
							) : (
								compra.estado === "cancelada" && (
									<span>Estado: Cancelada</span>
								)
							)}
							{compra.estado === "pagada" && (
								<span>
									Método de pago:{" "}
									<span className="text-capitalize">
										{compra.tipo_pago !== ""
											? compra.tipo_pago
											: "Efectivo"}
									</span>
								</span>
							)}
						</div>
						<h3
							style={{
								fontSize: "18px",
								lineHeight: "14px",
								marginBottom: "3px",
							}}
							className="d-block invoice-name"
						>
							Compra
						</h3>
						{compra.numero && (<div
							className="d-block"
							style={{ fontSize: "medium", lineHeight: "16px" }}
						>
							<span>NO.</span>
							{compra.numero}
						</div>)}
						{compra.ncf && (<div
							className="d-block"
							style={{ fontSize: "medium", lineHeight: "16px" }}
						>
							<span>NCF.</span>
							{compra.ncf}
							<br />
							{compra.numeracion?.fechaVencimiento && (
								<>
									<span>FV:</span>{" "}
									{dayjs(
										compra.numeracion?.fechaVencimiento
									).format("DD/MM/YYYY")}
								</>
							)}
						</div>)}
						<span className="d-block client-info-title pt-2">
							{status}
						</span>
					</div>
				</div>

				<div className="info-cliente-responsive d-block">
					{compra.suplidor && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Cliente:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{compra.suplidor?.nombre}
							</div>
						</div>
					)}
					{compra.suplidor?.identificacion && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									RNC/Cédula:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{compra.suplidor?.identificacion}
							</div>
						</div>
					)}
					{compra.suplidor?.encargado && (
						<div className="row">
							<div className="col-4 text-right px-1 client-info-title">
								Responsable:
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{compra.suplidor?.encargado}
							</div>
						</div>
					)}
					{compra.suplidor?.telefono && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Teléfono:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{compra.suplidor?.telefono}
							</div>
						</div>
					)}
					{compra.suplidor?.direccion && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Dirección:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "23px" }}
							>
								{compra.suplidor?.direccion}
							</div>
						</div>
					)}
					{compra.vendedor?.nombre && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Vendedor:
								</span>
							</div>
							<div className="col-7 info-client__response">
								{limitVendedorFullName(
									compra.vendedor?.nombre,
									compra.vendedor?.apellido
								)}
							</div>
						</div>
					)}
				</div>

				<h3 className="table-title"></h3>
				{/* PRODUCTOS RESPONSIVE */}
				<div className="sm-invoice-table">
					<div className="row" style={{ fontWeight: "600" }}>
						<div className="col-5">DESCRIPCIÓN</div>
						<div className="col-3 px-0">IMPUESTOS</div>
						<div className="col-4" style={{ whiteSpace: "nowrap" }}>
							TOTAL A PAGAR
						</div>
					</div>

					{compra.items?.map((item) => {
						return (
							<div
								className="row"
								style={{
									fontSize: "small",
									marginBottom: "5px",
								}}
								key={item.id}
							>
								<div
									className="col-5"
									style={{ lineHeight: "16px" }}
								>
									{`${item.cantidad} x ${formatCurrency(
										item.costo,
										""
									)}`}
									<br />
									{item.producto?.nombre}
									<br />
									REF: {item.producto?.referencia}
								</div>
								<div className="col-3 px-0">
									<span className="fw-600">
										{tipoITBIS(item.impuesto)}
									</span>{" "}
									{formatCurrency(
										item.costo *
											item.impuesto?.porcentaje,
										"$"
									)}
								</div>
								<div className="col-3 pr-0">
									{formatCurrency(item.total, "$")}{" "}
								</div>
							</div>
						);
					})}
				</div>

				<div className="summary">
					<div className="d-block">
                        {Object.keys(detalleTotal).sort((a,b) => {
                            if (b.includes('Subtotal')) {
                                return 1;
                            }
                            if (b.includes('ITBIS')) {
                                return -1;
                            }
                            return b - a;
                        }).map((impuesto, index) => {
                            return (
                                <div
                                    className="row justify-content-between"
                                    key={index}
                                >
                                    <div
                                        className="col summary-text capitalize"
                                        style={{
                                            maxWidth: "8rem",
                                            lineHeight: "16px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {impuesto}
                                    </div>
                                    <div
                                        className="col-2 text-right summary-text"
                                        style={{
                                            maxWidth: "9rem",
                                            lineHeight: "16px",
                                        }}
                                    >
                                        {formatCurrency(
                                            detalleTotal[
                                                impuesto
                                            ],
                                            "$"
                                        )}
                                    </div>
                                </div>
                            );
                        })}
					</div>
					<div className="row justify-content-between">
						<div
							className="col-2 summary-text"
							style={{
								maxWidth: "8rem",
								lineHeight: "16px",
								textAlign: "end",
								fontWeight: "600",
							}}
						>
							Descuento
						</div>
						<div
							className="col-2 text-right summary-text"
							style={{ maxWidth: "9rem", lineHeight: "16px" }}
						>
							{formatCurrency(compra.items?.reduce((a,d) => a + d.descuento * d.cantidad, 0), "$")}
						</div>
					</div>

					<div className="row justify-content-between">
						<div
							className="col summary-text"
							style={{
								maxWidth: "11rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							TOTAL A PAGAR
						</div>
						<div
							className="col-2 text-right summary-text"
							style={{
								maxWidth: "9rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							{formatCurrency(compra.total, "$")}
						</div>
					</div>
					{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
						<hr className="mb-0" />
					)}

					<div className="invoice-itbis-info">
						{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
							<p
								style={{
									lineHeight: "16px",
									fontSize: "small",
								}}
							>
								{Object.keys(sumatoriaFinal.impuestos).includes(
									"Itbis 16%"
								) && (
									<>
										<span>
											Items con I1 son gravados con 16%
											ITBIS
										</span>
										<br />
									</>
								)}
								{Object.keys(sumatoriaFinal.impuestos).includes(
									"Itbis 18%"
								) && (
									<span>
										Items con I2 son gravados con 18% ITBIS
									</span>
								)}
							</p>
						)}

						{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
							<div className="row justify-content-between">
								<div
									className="col-6 pr-1"
									style={{
										maxWidth: "11rem",
										fontSize: "small",
										lineHeight: "16px",
										fontWeight: "600",
										whiteSpace: "nowrap",
									}}
								>
									TOTAL IMPUESTOS
								</div>
								<div
									className="col-2"
									style={{
										maxWidth: "9rem",
										fontSize: "small",
										lineHeight: "16px",
										fontWeight: "600",
									}}
								>
									{formatCurrency(sumatoriaFinal.totalITBIS)}
								</div>
							</div>
						)}
					</div>
				</div>

				<hr className="d-block mt-0" />
				{compra.notas && (
					<>
						<p style={{ fontWeight: "600", fontSize: "1rem" }}>
							Notas:
						</p>
						<p
							className="col-12 pl-0 mb-3"
							style={{
								fontSize: "1rem",
								whiteSpace: "pre-line",
								lineHeight: "normal",
							}}
						>
							{compra.notas}
						</p>
					</>
				)}

				<div className="row justify-content-center mt-5">
					<h3
						className="col-10 text-center mr-0 fw-600 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Elaborado por
					</h3>
					<h3
						className="col-10 text-center ml-0 fw-600 mt-5 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Recibido por
					</h3>
				</div>

				{compra.plantilla?.pie_pagina && (
					<div
						className="col-12 pt-5 text-center d-flex justify-content-center"
						style={{
							fontWeight: "600",
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						<p className="text-center pie-pagina">
							{compra.plantilla?.pie_pagina}
						</p>
					</div>
				)}
			</div>
		</>
	);
};

const DesktopLayout = ({
	compra,
	sucursal,
	infoBlock2Margin,
	sumatoriaFinal,
    detalleTotal
}) => {

    const totalDescuentosItems = compra.items?.reduce((a,d) => a + d.descuento * d.cantidad, 0);

    let msg = compra.descripcion;
    if (compra.descuentoExtra > 0) {
        msg += ` -- Descuento por nota de crédito: ${formatCurrency(compra.descuentoExtra)}`;
    }

	return (
		<div className="p-2 invoice recibo-print">
			<div className="clear-both p-t-30 p-b-10">
				<div className="float-sm-left sucursal-info">
					{sucursal?.logo ? (
						<img
							alt="logo"
							className="mb-2 factura-logo"
							src={sucursal?.logo[0]?.url}
							width="270px"
						/>
					) : compra.logo ? (
						<img
							alt="logo"
							className="mb-2 factura-logo"
							src={compra?.logo[0]?.url}
							width="270px"
						/>
					) : (
						<h3 style={{ lineHeight: "12px", marginBottom: "3px" }}>
							{sucursal.nombre}
						</h3>
					)}
					<div
						className="mb-0 mb-sm-0"
						style={{ lineHeight: "15px", fontSize: "13px" }}
					>
						RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
					</div>
					<div
						className="mb-2 mb-sm-0"
						style={{ lineHeight: "15px", fontSize: "13px" }}
					>
						{sucursal.direccion}
					</div>
				</div>

				<div className="float-sm-right text-sm-right">
					<div
						className="mb-2 invoice-fecha d-block d-sm-none"
						style={{ fontSize: "small", lineHeight: "15px" }}
					>
						Fecha: {dayjs(compra.fecha).format("DD/MM/YYYY")}
						{getDiasPlazo(compra.fecha, compra.fechaVencimiento) >
						0 ? (
							<>
								<br />
								<span>
									Plazo:{" "}
									{getDiasPlazo(
										compra.fecha,
										compra.fechaVencimiento
									) || 0}{" "}
									días
								</span>
							</>
						) : (
							<>
								<br />
								<span>Plazo: Contado</span>
							</>
						)}
					</div>
					<h3
						className="d-block d-sm-none"
						style={{
							fontSize: "15px",
							lineHeight: "12px",
							marginBottom: "3px",
						}}
					>
						Compra
					</h3>
					<div
						className="d-block d-sm-none"
						style={{ fontSize: "15px", lineHeight: "12px" }}
					>
						<span>No.: </span>
						{compra.ncf}
						<br />
					</div>
				</div>
			</div>

			<div className="info-cliente-responsive d-block d-sm-none">
				{compra.suplidor && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Cliente:</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{compra.suplidor?.nombre}
						</div>
					</div>
				)}
				{compra.suplidor?.identificacion && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								RNC/Cédula:
							</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{compra.suplidor?.identificacion}
						</div>
					</div>
				)}
				{compra.suplidor?.encargado && (
					<div className="row">
						<div className="col-4 text-right px-1 client-info-title">
							Responsable:
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{compra.suplidor?.encargado}
						</div>
					</div>
				)}
				{compra.suplidor?.telefono && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Teléfono:</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{compra.suplidor?.telefono}
						</div>
					</div>
				)}
				{compra.suplidor?.direccion && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								Dirección:
							</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{compra.suplidor?.direccion}
						</div>
					</div>
				)}
				{compra.vendedor && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Vendedor:</span>
						</div>
						<div className="col-7">
							{`${compra.vendedor?.nombre} ${compra.vendedor?.apellido}`}
						</div>
					</div>
				)}
			</div>

			<div className="clear-both info-row p-t-10 p-b-10 d-none d-sm-flex">
				<div className="info-block" style={{ width: "65%" }}>
					<table>
						<tbody id="info-block-1">
							{compra.suplidor?.nombre && (
								<tr>
									<td
										width="120px"
										className="client-info-title text-uppercase text-right"
									>
										Cliente:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{compra.suplidor?.nombre}
									</td>
								</tr>
							)}
							{compra.suplidor?.identificacion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										RNC/Cédula:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{compra.suplidor?.identificacion}
									</td>
								</tr>
							)}
							{compra.suplidor?.encargado && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Responsable:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{compra.suplidor?.encargado}
									</td>
								</tr>
							)}
							{compra.suplidor?.telefono && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Teléfono:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{compra.suplidor?.telefono}
									</td>
								</tr>
							)}
							{compra.suplidor?.direccion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Dirección:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{compra.suplidor?.direccion}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<div
					className="info-block"
					style={{ marginTop: infoBlock2Margin }}
				>
					<table>
						<tbody id="info-block-2">
							<tr>
								<td colSpan={2} style={{ paddingLeft: "18%" }}>
									<h3
										style={{
											fontSize: "0.9rem",
											marginBottom: "3px",
											paddingBottom: "10px",
										}}
									>
										Compra
									</h3>
								</td>
							</tr>
							{compra.fecha && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Fecha:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{dayjs(compra.fecha).format(
											"DD/MM/YYYY"
										)}
									</td>
								</tr>
							)}
							{compra.numero && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										No.:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{compra.numero}
									</td>
								</tr>
							)}
							{compra.ncf && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										NCF:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{compra.ncf}
									</td>
								</tr>
							)}
							{getDiasPlazo(
								compra.fecha,
								compra.fechaVencimiento
							) > 0 ? (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Plazo:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{getDiasPlazo(
											compra.fecha,
											compra.fechaVencimiento
										) || 0}{" "}
										días
									</td>
								</tr>
							) : (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Plazo:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										Contado
									</td>
								</tr>
							)}
							{compra.estado === "pendiente" ? (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Estado:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										Pendiente
									</td>
								</tr>
							) : (
								compra.estado === "cancelada" && (
									<tr>
										<td className="client-info-title text-uppercase text-right">
											Estado:
										</td>
										<td style={{ paddingLeft: "10px" }}>
											Cancelada
										</td>
									</tr>
								)
							)}
							{compra.estado === "pagada" && (
								<tr>
									<td
										className="client-info-title text-uppercase text-right"
										style={{ whiteSpace: "nowrap" }}
									>
										Método de pago:
									</td>
									<td
										style={{
											paddingLeft: "10px",
											textTransform: "capitalize",
										}}
									>
										{compra.tipo_pago !== ""
											? compra.tipo_pago
											: "Efectivo"}
									</td>
								</tr>
							)}

							{compra.vendedor?.nombre && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Vendedor:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{limitVendedorFullName(
											`${compra.vendedor?.nombre} ${compra.vendedor?.apellido}`
										)}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			<div className="table-wrapper table-responsive">
				<h3 className="table-title"></h3>
				<table className="table d-none d-sm-table m-b-20">
					<thead>
						<tr className="text-uppercase">
							<th width="12%">Código/Ref.</th>
							<th width="25%">Descripción</th>
							<th width="8%" className="text-center">Cantidad</th>
							<th width="8%" className="text-center">Unidad</th>
							<th>Importe</th>
							<th>Impuesto</th>
							<th>Precio</th>
							<th>Desc</th>
							<th className="text-right">Total</th>
						</tr>
					</thead>
					<tbody>
						{compra?.items?.map((item) => {
							return (
								<tr key={item.id}>
									<td>
										<div style={{ lineHeight: 1.2 }}>
											<div>{item.referencia}</div>
										</div>
									</td>
									<td>{item.nombre}</td>
									<td className="text-center">{item.cantidad}</td>
									<td className="text-center">{item.unidad}</td>
									<td>{formatCurrency(item.costo)}</td>
									<td style={{ whiteSpace: "nowrap" }}>
										<span className="fw-600">
											{tipoITBIS(item.impuesto)}
										</span>
										{formatCurrency(
											money(item.costo *
												item.impuesto?.porcentaje)
										)}
									</td>
									<td>
										{formatCurrency(item.total/item.cantidad,
											"$"
										)}
									</td>
                                    <td>
                                        {item.descuento > 0 && formatCurrency(item.descuento )}
                                    </td>
									<td className="text-right">
										{formatCurrency(item.total)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{/* PRODUCTOS RESPONSIVE */}
			<div className="sm-invoice-table">
				<div className="row" style={{ fontWeight: "600" }}>
					<div className="col-5">DESCRIPCIÓN</div>
					<div className="col-3 px-0">IMPUESTOS</div>
					<div className="col-4 ">TOTAL</div>
				</div>

				{compra.productos?.map((producto) => {
					return (
						<>
							<div
								className="row"
								style={{
									fontSize: "smaller",
									marginBottom: "5px",
								}}
							>
								<div
									className="col-5"
								>
									{`${producto.cantidad} x ${formatCurrency(
										producto.precio_facturado,
										""
									)}`}
									<br />
									{producto.producto?.nombre}
									<br />
									REF: {producto.producto?.referencia}
								</div>
								<div className="col-3 px-0">
									<span className="fw-600">
										{tipoITBIS(producto.impuesto)}
									</span>
									{formatCurrency(
										producto.precio_facturado *
											producto.impuesto?.porcentaje,
										"$"
									)}
								</div>
								<div className="col-3 pr-0">
									{formatCurrency(producto.total, "$")}{" "}
								</div>
							</div>
						</>
					);
				})}
			</div>

			<div className="summary">
				<div className="d-block">
                    {detalleTotal && Object.keys(detalleTotal).sort((a,b) => {
                            if (b.includes('Subtotal')) {
                                return 1;
                            }
                            if (b.includes('ITBIS')) {
                                return -1;
                            }
                            return b - a;
                        }).map((impuesto, index) => {
                        return (
                            <div
                                className="row justify-content-between justify-content-md-end"
                                key={index}
                            >
                                <div
                                    className="col col-md-2 text-sm-right capitalize"
                                    style={{
                                        maxWidth: "8rem",
                                        fontWeight: "600",
                                    }}
                                >
                                    {impuesto}
                                </div>
                                <div
                                    className="col-2 text-right"
                                    style={{
                                        maxWidth: "9rem",
                                    }}
                                >
                                    {formatCurrency(
                                        detalleTotal[impuesto],
                                        "$"
                                    )}
                                </div>
                            </div>
                        );
                    })}
				</div>

                {totalDescuentosItems > 0 && (
                    <div className="row justify-content-between justify-content-md-end">
                        <div
                            className="col-2 text-sm-right"
                            style={{
                                maxWidth: "8rem",
                                textAlign: "end",
                                fontWeight: "600",
                            }}
                        >
                            Total Descuentos
                        </div>
                        <div
                            className="col-2 text-right"
                            style={{ maxWidth: "9rem" }}
                        >
                            {formatCurrency(totalDescuentosItems, "$")}
                        </div>
                    </div>
                )}

                {compra.descuento > 0 && (
                  <div className="row justify-content-between justify-content-md-end">
                        <div
                            className="col-4 text-sm-right"
                            style={{
                                textAlign: "end",
                                fontWeight: "600",
                            }}
                        >
                            Descuento General
                        </div>
                        <div
                            className="col-2 text-right"
                            style={{ maxWidth: "9rem", lineHeight: "14px" }}
                        >
                            {formatCurrency(compra.descuento, "$")}
                        </div>
                    </div>
                )}

				<div className="row justify-content-between justify-content-md-end">
					<div
						className="col col-md-2 text-sm-right"
						style={{
							maxWidth: "8rem",
							fontWeight: "600",
						}}
					>
						TOTAL
					</div>
					<div
						className="col-2 text-right"
						style={{
							maxWidth: "9rem",
							fontWeight: "600",
						}}
					>
						{formatCurrency(compra.total, "$")}
					</div>
				</div>

				{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
					<hr className="mb-0" />
				)}

				<div className="text-sm-right invoice-itbis-info m-t-10">
					{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
						<p
							style={{
								fontSize: "small",
							}}
						>
							{Object.keys(sumatoriaFinal.impuestos).includes(
								"Itbis 16%"
							) && (
								<>
									<span>
										Items con I1 son gravados con 16% ITBIS
									</span>
									<br />
								</>
							)}
							{Object.keys(sumatoriaFinal.impuestos).includes(
								"Itbis 18%"
							) && (
								<span>
									Items con I2 son gravados con 18% ITBIS
								</span>
							)}
						</p>
					)}

					{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
						<div className="row justify-content-between justify-content-md-end">
							<div
								className="col col-md-6 pr-1"
								style={{
									maxWidth: "11rem",
									fontSize: "small",
									fontWeight: "600",
								}}
							>
								TOTAL IMPUESTOS
							</div>
							<div
								className="col-2"
								style={{
									maxWidth: "9rem",
									fontSize: "small",
									fontWeight: "600",
								}}
							>
								{formatCurrency(sumatoriaFinal.totalITBIS)}
							</div>
						</div>
					)}
				</div>
			</div>

			<hr className="d-block d-sm-none mt-0 mb-4" />
			{msg && (
				<>
					<p style={{ fontWeight: "600", fontSize: "1rem" }}>
						Notas:
					</p>
					<p
						className="col-12 col-md-8 pl-0 mb-3"
						style={{
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						{msg}
					</p>
				</>
			)}

			<div className="row justify-content-center mt-5">
				<h3
					className="col-10 col-sm-3 text-center mr-sm-2 mr-0 mt-sm-4 fw-600 signature-title"
					style={{ borderTop: "1px solid #000" }}
				>
					Elaborado por
				</h3>
				<div className="col-sm-2 d-none d-sm-block"></div>
				<h3
					className="col-10 col-sm-3 text-center ml-sm-2 ml-0 fw-600 mt-5 mt-sm-4 signature-title"
					style={{ borderTop: "1px solid #000" }}
				>
					Recibido por
				</h3>
			</div>

			{compra.plantilla?.pie_pagina && (
				<div
					className="col-12 pt-5 text-center d-flex justify-content-center"
					style={{
						fontWeight: "600",
						fontSize: "1rem",
						whiteSpace: "pre-line",
						lineHeight: "normal",
					}}
				>
					<p className="text-center pie-pagina">
						{compra.plantilla?.pie_pagina}
					</p>
				</div>
			)}
		</div>
	);
};

export default PrintSingleCompra;
