import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Select from "react-select";
import SelectAsync from "react-select/async-creatable";

import FotoField from "../../general/FotoField";
import Inscripcion from "./Form/Inscripcion";
import { getLink, toast } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import { auth } from "../../../utils/auth";
import DocumentoItem from "./Form/Documentos";
import { estadosEstudiantes, useEstudianteContext } from "./EstudianteContext";
import Loader from "../../general/Loader";
import PagoForm from "./Form/Pago";
import Modal from "../../general/Modal";
import Tutores from "./Form/Tutores";
import Relacionados from "./Form/Relacionados";

const Form = ({isEdit, setShowHistorialPagos}) => {
    const {tiposDocumentos, estudiante, setEstudiante, errors, getOptions, saving, setShowProcesarModal, showProcesarModal, pagoInfo, montoPagar, page, setPage, procesarTransaccion, isReservaCupo, loading, isInscripcion, pagoEvaluacion} = useEstudianteContext();

    const sidebarRef = useRef(null);
    const [isSticked, setIsSticked] = useState(false);
    const [showClave, setShowClave] = useState(false);
    const [correo, setCorreo] = useState(estudiante.correo);
    const [titular, setTitular] = useState({});

    const onInputChange = (value, nombre) => {
        setEstudiante(prevState => ({
            ...prevState,
            [nombre]: value,
        }));
    };

    const generarCorreo = () => {
        const n = estudiante.nombre.split(' ');
        const nombre = n[0];
        const nombre2 = n.length > 1 ? n[1][0] : '';
        const apellido1 = estudiante.primerApellido[0] || '';
        const apellido2 = estudiante.segundoApellido[0];
        const fecha = estudiante.fechaNacimiento ? dayjs(estudiante.fechaNacimiento).format('YY') : "";

        setCorreo( (`${nombre}${nombre2}${apellido1}${apellido2}${fecha}@colegiopadreemilianotardif.edu.do`).toLowerCase() )
    }

    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
        (suc) => suc.id === user.sucursalActual
    )[0];

    if (loading && (isReservaCupo || isInscripcion || isEdit)) {
        return <Loader />
    }

    return (
        <div className="row">
            <style>
                {`.sticked {
                    position: fixed;
                    top: 120px;
                }`}
            </style>
            <div id="estudiante-sidebar" className="col-md-3">
                <div className={"sticky-wrap " + (isSticked ? 'sticked' : '')} style={{maxWidth: '300px'}}>
                    <div className='account2' style={estudiante.foto && {marginBottom: '-75px', marginTop: '-25px', zIndex: 9999, position: 'relative'}}>
                        <div className={estudiante.foto ? "image img-cir img-120" : ""} style={{maxWidth: '300px'}}>
                            <FotoField
                                foto={estudiante.foto}
                                name='foto'
                            />
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body p-t-40">
                            <div>
                                {estudiante.matricula && (
                                    <div className="m-b-15">
                                        <small className="text-uppercase">Matrícula:</small>
                                        <div>{estudiante.matricula}</div>
                                    </div>
                                )}
                                {estudiante.fechaNacimiento && (
                                    <div className="m-b-15">
                                        <small className="text-uppercase">Edad:</small>
                                        <div>{dayjs().diff(estudiante.fechaNacimiento, 'year')} años</div>
                                    </div>
                                )}
                                {estudiante.nacionalidad && (
                                    <div className="m-b-15">
                                        <small className="text-uppercase">Nacionalidad:</small>
                                        <div>{estudiante.nacionalidad}</div>
                                    </div>
                                )}
                                {estudiante.curso && (
                                    <div className="m-b-15">
                                        <small className="text-uppercase">Curso:</small>
                                        <div>{estudiante.curso?.grado?.nombre} {estudiante.curso?.seccion?.nombre}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <ul className="list-group">
                        {estudiante.curso && (
                            <li className="list-group-item">
                                <button type="button" onClick={() => setShowHistorialPagos(true)} className="btn">
                                    » Historial de Pagos
                                </button>
                            </li>
                        )}
                        {estudiante.tieneCocurriculares && (
                            <li className="list-group-item">
                                <button type="button" className="btn">
                                    » Historial Cocurriculares
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            <div className="col-md-9">
                {(estudiante.curso || isReservaCupo || isInscripcion) && (
                    <>
                        <section>
                            <h3>{isReservaCupo ? 'Datos Reserva' : 'Datos Inscripción'}</h3>
                            <Inscripcion isEdit={isEdit} />
                        </section>
                        <div className='form-separator' />
                    </>
                )}
                <section>
                    <h3>Datos Generales</h3>
                    <div className='row'>
                        <div className='col-md-4 form-group'>
                            <label className='label form-control-label'>
                                Nombre(s):
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='nombre'
                                name='nombre'
                                onChange={e => onInputChange(e.target.value, 'nombre')}
                                defaultValue={estudiante.nombre}
                            />
                            {errors.nombre && (
                                <small className='help-blockParams form-Text'>
                                    {errors.nombre[0]}
                                </small>
                            )}
                        </div>
                        <div className='col-md-4 form-group'>
                            <label className='label form-control-label'>
                                Primer Apellido:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='primer_apellido'
                                name='primer_apellido'
                                onChange={e => onInputChange(e.target.value, 'primerApellido')}
                                defaultValue={estudiante.primerApellido}
                            />
                            {errors.primer_apellido && (
                                <small className='help-blockParams form-Text'>
                                    {errors.primer_apellido[0]}
                                </small>
                            )}
                        </div>
                        <div className='col-md-4 form-group'>
                            <label className='label form-control-label'>
                                Segundo Apellido:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='segundo_apellido'
                                name='segundo_apellido'
                                onChange={e => onInputChange(e.target.value, 'segundoApellido')}
                                defaultValue={estudiante.segundoApellido}
                            />
                            {errors.segundo_apellido && (
                                <small className='help-blockParams form-Text'>
                                    {errors.segundo_apellido[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 form-group'>
                            <label className='label form-control-label'>
                                Cédula/NUI:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='identificacion'
                                name='identificacion'
                                onChange={e => onInputChange(e.target.value, 'identificacion')}
                                defaultValue={estudiante.identificacion}
                            />
                            {errors.identificacion && (
                                <small className='help-blockParams form-Text'>
                                    {errors.identificacion[0]}
                                </small>
                            )}
                        </div>
                        <div className='col-md-4 form-group'>
                            <label className='label form-control-label'>
                                Nacionalidad:
                            </label>
                            <SelectAsync
                                isClearable
                                key="nacionalidad"
                                name="nacionalidad"
                                cacheOptions
                                defaultOptions
                                onChange={e => onInputChange(e?.value || '', 'nacionalidad')}
                                defaultValue={estudiante.nacionalidad && {label: estudiante.nacionalidad, value: estudiante.nacionalidad}}
                                loadOptions={e => getOptions("taxonomias?relacion=Estudiantes&tipo=Nacionalidad&buscar="+e, 'nombre', 'nombre')}
                            />
                            {errors.nacionalidad && (
                                <small className='help-blockParams form-Text'>
                                    {errors.nacionalidad[0]}
                                </small>
                            )}
                        </div>
                        <div className='col-md-4 form-group'>
                            <label className='label form-control-label'>
                                Lugar de Nacimiento:
                            </label>
                            <div className='input-group'>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    defaultValue={estudiante.lugarNacimiento}
                                    onChange={e => onInputChange(e.target.value, 'lugarNacimiento')}
                                    name='lugar_nacimiento'
                                />
                            </div>
                            {errors.lugar_nacimiento && (
                                <small className='help-blockParams form-Text'>
                                    {errors.lugar_nacimiento[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 form-group'>
                            <label className='label form-control-label'>
                                Fecha de Nacimiento:
                            </label>
                            <div className='input-group'>
                                <div className='input-group-addon'>
                                    <i className='far fa-calendar' />
                                </div>
                                <input
                                    type='date'
                                    className='text-input form-control'
                                    defaultValue={estudiante.fechaNacimiento && dayjs(estudiante.fechaNacimiento).format('YYYY-MM-DD')}
                                    onChange={e => onInputChange(e.target.value, 'fechaNacimiento')}
                                    name='fecha_nacimiento'
                                />
                            </div>
                            {errors.fecha_nacimiento && (
                                <small className='help-blockParams form-Text'>
                                    {errors.fecha_nacimiento[0]}
                                </small>
                            )}
                        </div>
                        <div className="col-md-1">
                            <label>Edad:</label>
                            <div className="m-t-5">
                                {estudiante.fechaNacimiento && (
                                    <strong>{dayjs().diff(estudiante.fechaNacimiento, 'year')} años</strong>
                                )}
                            </div>
                        </div>
                        <div className='col-md-3 form-group'>
                            <label className='label form-control-label'>
                                Sexo:
                            </label>
                            <Select
                                key='sexo'
                                name='sexo'
                                placeholder='Seleccionar...'
                                onChange={e => onInputChange(e.value, 'sexo')}
                                defaultValue={estudiante.sexo && {
                                    value: estudiante.sexo,
                                    label: estudiante.sexo === 'M' ? 'Masculino' : 'Femenino',
                                }}
                                options={[
                                    {label: 'Masculino', value: 'M'},
                                    {label: 'Femenino', value: 'F'}
                                ]}
                            />
                            {errors.sexo && (
                                <small className='help-blockParams form-Text'>
                                    {errors.sexo[0]}
                                </small>
                            )}
                        </div>
                        <div className='col-md-4 form-group'>
                            <label className='label form-control-label'>
                                Tipo Sanguineo:
                            </label>
                            <Select
                                key='grupo_sanguineo'
                                placeholder='Seleccionar...'
                                name='grupo_sanguineo'
                                onChange={e => onInputChange(e.value, 'grupoSanguineo')}
                                defaultValue={estudiante.grupoSanguineo && {
                                    label: estudiante.grupoSanguineo,
                                    value: estudiante.grupoSanguineo,
                                }}
                                options={[
                                    {label: 'A+', value: 'A+'},
                                    {label: 'A-', value: 'A-'},
                                    {label: 'B+', value: 'B+'},
                                    {label: 'B-', value: 'B-'},
                                    {label: 'AB+', value: 'AB+'},
                                    {label: 'AB-', value: 'AB-'},
                                    {label: 'O+', value: 'O+'},
                                    {label: 'O-', value: 'O-'},
                                ]}
                            />
                            {errors.grupo_sanguineo && (
                                <small className='help-blockParams form-Text'>
                                    {errors.grupo_sanguineo[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 form-group'>
                            <label className='label form-control-label'>
                                Dirección:
                            </label>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='direccion'
                                name='direccion'
                                readOnly
                                value={titular?.direccion || ''}
                            />
                            {errors.direccion && (
                                <small className='help-blockParams form-Text'>
                                    {errors.direccion[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </section>
                <div className='form-separator' />
                <section>
                    <h3 className="m-t-20">Datos Circunscripción</h3>
                    <div className='row'>
                        <div className='col-md-2 form-group'>
                            <label className='label form-control-label'>
                                ID:
                            </label>
                            <div className='input-group'>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    defaultValue={estudiante.idCircunscripcion}
                                    name='id_circunscripcion'
                                />
                            </div>
                            {errors.id_circunscripcion && (
                                <small className='help-blockParams form-Text'>
                                    {errors.id_circunscripcion[0]}
                                </small>
                            )}
                        </div>
                        <div className='col-md-2 form-group'>
                            <label className='label form-control-label'>
                                Municipio:
                            </label>
                            <div className='input-group'>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    defaultValue={estudiante.municipioCircunscripcion}
                                    name='municipio_circunscripcion'
                                />
                            </div>
                            {errors.municipio_circunscripcion && (
                                <small className='help-blockParams form-Text'>
                                    {errors.municipioCircunscripcion[0]}
                                </small>
                            )}
                        </div>
                        <div className='col-md-2 form-group'>
                            <label className='label form-control-label'>
                                Oficina:
                            </label>
                            <div className='input-group'>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    defaultValue={estudiante.oficinaCircunscripcion}
                                    name='oficina_circunscripcion'
                                />
                            </div>
                            {errors.oficina_circunscripcion && (
                                <small className='help-blockParams form-Text'>
                                    {errors.oficina_circunscripcion[0]}
                                </small>
                            )}
                        </div>
                        <div className='col-md-2 form-group'>
                            <label className='label form-control-label'>
                                Libro:
                            </label>
                            <div className='input-group'>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    defaultValue={estudiante.libroCircunscripcion}
                                    name='libro_circunscripcion'
                                />
                            </div>
                            {errors.libro_circunscripcion && (
                                <small className='help-blockParams form-Text'>
                                    {errors.libro_circunscripcion[0]}
                                </small>
                            )}
                        </div>
                        <div className='col-md-2 form-group'>
                            <label className='label form-control-label'>
                                Folio:
                            </label>
                            <div className='input-group'>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    defaultValue={estudiante.folioCircunscripcion}
                                    name='folio_circunscripcion'
                                />
                            </div>
                            {errors.folio_circunscripcion && (
                                <small className='help-blockParams form-Text'>
                                    {errors.folio_circunscripcion[0]}
                                </small>
                            )}
                        </div>
                        <div className='col-md-2 form-group'>
                            <label className='label form-control-label'>
                                Acta:
                            </label>
                            <div className='input-group'>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    defaultValue={estudiante.actaCircunscripcion}
                                    name='acta_circunscripcion'
                                />
                            </div>
                            {errors.acta_circunscripcion && (
                                <small className='help-blockParams form-Text'>
                                    {errors.acta_circunscripcion[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </section>
                <div className='form-separator' />
                <section>
                    <h3 className="m-t-20">Datos Registro</h3>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label className='label form-control-label'>
                                    Referencia Académica:
                                </label>
                                <SelectAsync
                                    isClearable
                                    key="referencia_academica"
                                    name="referencia_academica"
                                    cacheOptions
                                    defaultOptions
                                    onChange={e => onInputChange(e?.value || '', 'referencia_academica')}
                                    defaultValue={estudiante.referenciaAcademica && {label: estudiante.referenciaAcademica, value: estudiante.referenciaAcademica}}
                                    loadOptions={e => getOptions("taxonomias?relacion=Estudiantes&tipo=Referencia+Académica&buscar="+e)}
                                />
                                {errors.referenciaAcademica && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.referenciaAcademica[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label className='label form-control-label'>
                                    Matrícula:
                                </label>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    id='matricula'
                                    name='matricula'
                                    onChange={e => onInputChange(e.target.value, 'matricula')}
                                    defaultValue={estudiante.matricula}
                                />
                                {errors.matricula && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.matricula[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='col-md-4 form-group'>
                            <label className='label form-control-label'>
                                Estado:
                            </label>
                            <Select
                                isClearable
                                key="estado"
                                name="estado"
                                cacheOptions
                                defaultOptions
                                onChange={e => onInputChange(e?.value || '', 'estado')}
                                defaultValue={{
                                    label: 'Inactivo',
                                    value: 'Inactivo',
                                }}
                                value={estudiante.estado && {label: estudiante.estado, value: estudiante.estado}}
                                options={Object.keys(estadosEstudiantes).map(estado => estadosEstudiantes[estado])}
                            />
                            {errors.estado && (
                                <small className='help-blockParams form-Text'>
                                    {errors.estado[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label className='label form-control-label'>
                                    Teléfono:
                                </label>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    id='telefono'
                                    name='telefono'
                                    defaultValue={estudiante.telefono}
                                />
                                {errors.telefono && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.telefono[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label className='label form-control-label'>
                                    RNE:
                                </label>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    id='rne'
                                    name='rne'
                                    defaultValue={estudiante.rne}
                                />
                                {errors.rne && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.rne[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label className='label form-control-label'>
                                    Usuario Progrentis:
                                </label>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    autoComplete="off"
                                    name='usuario_progrentis'
                                    defaultValue={estudiante.usuarioProgrentis}
                                />
                                {errors.usuario_progrentis && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.usuario_progrentis[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row clave-field-wrap p-t-10 p-b-10 m-b-30">
                        <div className='col-md-5'>
                            <div className='form-group'>
                                <label className='label form-control-label'>
                                    Correo Electrónico:
                                </label>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    id='correo'
                                    name='correo'
                                    value={correo}
                                    onChange={e => setCorreo(e.target.value)}
                                />
                                {errors.correo && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.correo[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='form-group'>
                                <label className='label form-control-label'>
                                    Clave:
                                </label>
                                <div>
                                    <input
                                        type={showClave ? 'text' : 'password'}
                                        className='text-input form-control p-r-70'
                                        name='clave'
                                        autoComplete="new-password"
                                        defaultValue={estudiante.clave}
                                    />
                                </div>
                                {errors.clave && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.clave[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="col-md-2 p-t-25">
                            <button type="button" className='btn btn-secondary m-r-5' onClick={() => generarCorreo()}>
                                <i className={`zmdi zmdi-refresh-sync`}></i>
                            </button>
                            <button type="button" className={`btn btn-${showClave ? 'primary' : 'secondary'} m-r-5`} onClick={() => setShowClave(!showClave)}>
                                <i className={`fa ${!showClave ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                            </button>
                            {estudiante.clave && (
                                <Link to={getLink(`/imprimir/academico/estudiantes/${estudiante.id}/clave`)} target="_blank" className="btn btn-secondary m-r-5"><i className="fa fa-print"></i></Link>
                            )}
                            {estudiante.telefono && (
                                <a href={`https://api.whatsapp.com/send?phone=${estudiante.telefono}&text=*${sucursal.nombre}*%20a%20compartido%20con%20usted%20el%20*usuario:*%20${estudiante.correo}%20*clave:*%20${estudiante.clave}%20para%20iniciar%20sesion%20visite%20nombreempresa.com`} className="btn btn-secondary"><i className="fab fa-whatsapp"></i></a>
                            )}
                        </div>
                    </div>
                </section>

                <Tutores
                    setTitular={setTitular}
                />

                <Relacionados />

                <div className='form-separator' />

                {(estudiante.curso || isReservaCupo || isInscripcion) && (
                    <>
                        <section>
                            <h3>Documentos Requeridos para Nivel {estudiante?.curso?.grado?.nivel?.nombre}</h3>
                            <table className="table tickets-table">
                                <thead>
                                    <tr className="text-uppercase">
                                        <td width="45%"><small><strong>Nombre</strong></small></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tiposDocumentos.map(documento => (
                                        <DocumentoItem key={documento.id} documento={documento} />
                                    ))}
                                </tbody>
                            </table>
                        </section>
                        <div className='form-separator' />
                    </>
                )}
                {/* <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <h3>Notas Médicas</h3>
                            <textarea
                                key={estudiante.id}
                                className='text-input form-control'
                                name='notas_medicas'
                                rows='4'
                                defaultValue={estudiante.notas}
                            />
                            {errors.notas_medicas && (
                                <small className='help-blockParams form-Text'>
                                    {errors.notas_medicas[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='form-separator' /> */}
                <div className="row notas">
                    <h3>Observaciones</h3>
                    {estudiante.comentarios && estudiante.comentarios.map(comentario => (
                        <div className="col-md-12 nota" key={comentario.id}>
                            <i className="zmdi zmdi-comment-text"></i>
                            {comentario.descripcion}
                            <div className="nota-meta">Por {comentario.user} - {dayjs(comentario.created_at).format('MM/DD/YYYY')}</div>
                        </div>
                    ))}
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <label>Agregar Observación:</label>
                        <div className='form-group'>
                            <textarea
                                key={estudiante.id}
                                className='text-input form-control'
                                id='comentarios'
                                name='comentarios'
                                rows='4'
                            />
                            {errors.comentarios && (
                                <small className='help-blockParams form-Text'>
                                    {errors.comentarios[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className='page-footer text-right'>
                    <Link
                        to={getLink("/academico/estudiantes")}
                        className='btn btn-plain'>
                        Cancelar
                    </Link>
                    {saving ? (
                        <Loader type="small" />
                    ) : (
                        <>
                            <button type="submit" className='btn btn-secondary'>
                                Guardar
                            </button>
                            {((isReservaCupo || isInscripcion) && !estudiante.curso?.estado) && (
                                <button type='button' onClick={() => setShowProcesarModal(true)} className='btn btn-primary'>
                                    Realizar Pago
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>


            <Modal
                title={isReservaCupo ? 'Pago Reserva de Cupo' : 'Pago Inscripción'}
                show={showProcesarModal}
                toggle={() => {
                    setShowProcesarModal((state) => !state);
                }}
                footer={
                    <ModalFooter
                        setShowProcesarModal={setShowProcesarModal}
                        pago={pagoInfo.pago}
                        abono={montoPagar}
                        page={page}
                        setPage={setPage}
                        saving={saving}
                        isInscripcion={isInscripcion}
                        descuento={pagoEvaluacion}
                        processTransaction={(e) => {
                            procesarTransaccion(e);
                            //createEstudiante();
                        }}
                        fecha={pagoInfo.fecha}
                    />
                }
                size="650px"
            >
                <PagoForm />
            </Modal>
        </div>
    );
}

export default Form;

const ModalFooter = React.memo(
	({
		setShowProcesarModal,
		pago,
		abono,
		page,
		setPage,
        descuento,
		saving = false,
		processTransaction,
		fecha,
	}) => {
		const prevPage = () => setPage((state) => state - 1);
		const nextPage = () => {
            const pagoTotal = abono - parseFloat(descuento);
			if (parseFloat(pago) <= 0) {
				toast(
					"El valor del abono no puede ser igual o menor a cero",
					"error"
				);
				return;
			}
			if (pagoTotal > pago) {
				toast(
					"El valor del pago no puede ser menor al abono seleccionado",
					"error"
				);
				return;
			}
			if (!fecha) {
				toast("Elija una fecha para continuar", "error");
				return;
			}
			setPage((state) => state + 1);
		};

		return (
			<>
				<button
					type="button"
					onClick={() => setShowProcesarModal((state) => !state)}
					className="btn btn-plain"
				>
					Cancelar
				</button>
				{page === 2 && (
					<button
						type="button"
						onClick={prevPage}
						className="btn btn-plain float-left"
					>
						&larr; Atrás
					</button>
				)}
				{page === 1 && (
					<button
						type="button"
						onClick={nextPage}
						className="btn btn-primary"
					>
						Continuar
					</button>
				)}
				{page === 2 &&
					(saving ? (
						<Loader type="small" />
					) : (
						<button
							className="btn btn-outline-primary"
							type="submit"
						>
							Guardar y Aplicar Pago
						</button>
					))}
			</>
		);
	}
);
