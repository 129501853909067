import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getLink, formatHumanReadableDate } from "../../../utils/helpers";
import Pagination from "../../general/Pagination";
import Loader from "../../general/Loader";
import { request } from "../../../utils/request";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";

const tipos_documento = [
	{
		value: "proforma",
		label: "Proforma",
	},
	{
		value: "factura_proveedor",
		label: "Factura de proveedor",
	},
	{
		value: "factura_venta",
		label: "Factura de venta",
	},
	{
		value: "nota_credito",
		label: "Nota de crédito",
	},
	{
		value: "nota_debito",
		label: "Nota de débito",
	},
	{
		value: "recibo_caja",
		label: "Recibo de caja",
	},
	{
		value: "gasto",
		label: "Gasto",
	},
	{
		value: "cotizacion",
		label: "Cotización",
	},
	{
		value: "orden_compra",
		label: "Orden de compra",
	},
	{
		value: "conduce",
		label: "Conduce",
	},
	{
		value: "ajuste_inventario",
		label: "Ajuste de inventario",
	},
	{
		value: "contacto",
		label: "Contacto",
	},
	{
		value: "recibo_ingreso",
		label: "Recibo de Ingreso",
	},
	{
		value: "recibo_egreso",
		label: "Recibo de egreso",
	},
	{
		value: "cheque",
		label: "Cheque",
	},
];

const Numeraciones = () => {
	const [showCreateModal, setCreateModal] = useState(false);
	const [showEditModal, setEditModal] = useState(false);
	const [numeracionToEdit, setNumeracionToEdit] = useState({});
	const [numeraciones, setNumeracionesData] = useState({
		data: [],
		links: null,
		meta: null,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [filtrosQuery, setFiltrosQuery] = useState("");
	const [filtros, setFiltros] = useState({
		buscar: "",
		tipo_documento: "",
		status: "todos",
	});

	const toggleCreateModal = () => {
		setCreateModal((state) => !state);
	};
	const toggleEditModal = (numeracion) => {
		setEditModal((state) => !state);
		if (!numeracion) return;

		setNumeracionToEdit(numeracion.id);
	};

	const getNumeracionesData = (url = null) => {
		url = url ? url + "&limit=15" : "numeracion-documentos?limit=15";

		request(url).then((res) => {
			setNumeracionesData(res);
			setIsLoading(false);
		});
	};

	const onFiltroChange = async (value, filtro) => {
		setFiltros((filtros) => ({
			...filtros,
			[filtro]: value,
		}));
	};

	useEffect(() => {
		getNumeracionesData(`numeracion-documentos${filtrosQuery}`);
	}, [filtrosQuery]);

	useEffect(() => {
		let query = Object.keys(filtros).map((fil) => {
			return `${fil}=${filtros[fil]}`;
		});

		setFiltrosQuery("?" + query.join("&"));
	}, [filtros]);
	return (
		<>
			<div className="page-header">
				<Link to={getLink("/configuracion/")}>
					&laquo; Volver a configuración
				</Link>
				<h1 className="page-title row">
					<span className="col">Numeración de documentos</span>
					<button
						className="au-btn au-btn-icon au-btn--green au-btn--small"
						onClick={toggleCreateModal}
					>
						<i className="zmdi zmdi-plus" />
						Añadir Numeración
					</button>
				</h1>
				<div className="page-controls">
					<div className="table-data__tool">
						<div className="table-data__tool-left row">
							<div className="col">
								<label htmlFor="search">Buscar</label>
								<input
									onChange={(e) =>
										onFiltroChange(e.target.value, "buscar")
									}
									type="search"
									placeholder="Buscar..."
									className="form-control"
									name="buscar"
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="tipo_documento">Tipo de documento</label>
								<select
									className="form-control"
									name="tipo_documento"
									id="tipo_documento"
									onChange={(e) =>
										onFiltroChange(e.target.value, "tipo_documento")
									}
								>
									<option value="todos">Todos</option>
									<option value="factura_venta">Factura de venta</option>
									<option value="nota_credito">Nota de crédito</option>
									<option value="nota_debito">Nota de débito</option>
									<option value="recibo_caja">Recibo de caja</option>
									<option value="gasto">Gasto</option>
									<option value="cotizacion">Cotización</option>
									<option value="orden_compra">Orden de compra</option>
									<option value="conduce">Conduce</option>
									<option value="ajuste_inventario">Ajuste de inventario</option>
									<option value="cheque">Cheque</option>
								</select>
							</div>
							<div className="col-md-2">
								<label htmlFor="status">Estado</label>
								<select
									className="form-control"
									name="status"
									id="status"
									onChange={(e) =>
										onFiltroChange(e.target.value, "status")
									}
								>
									<option value="todos">Todos</option>
									<option value="activo">Activos</option>
									<option value="inactivo">Inactivos</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<div>
						{isLoading && <Loader />}
						{!numeraciones.data?.length ? (
							<div>
								{!isLoading && (
									<div>No hay ninguna Numeración creada.</div>
								)}
							</div>
						) : (
							<div className="table-data">
								<table className="table table-data2">
									<thead>
										<tr>
											<th>Tipo de documento</th>
											<th>Nombre</th>
											<th>Preferida</th>
											<th>Vigencia hasta</th>
											<th>Prefijo</th>
											<th>Siguiente numero</th>
											<th>Número final</th>
											<th className="text-center">Estado</th>
											<th className="text-end">
												Acciones
											</th>
										</tr>
									</thead>
									<tbody>
										{numeraciones.data.map((numeracion) => {
											return (
												<tr
													className="tr-shadow"
													key={numeracion.id}
												>
													<td>
														{
															tipos_documento?.find(
																(tipo) =>
																	tipo.value ===
																	numeracion.tipo_documento
															)?.label
														}
													</td>
													<td>{numeracion.nombre}</td>
													<td>
														{numeracion.preferida}
													</td>
													<td>
														{formatHumanReadableDate(
															numeracion.fecha_vencimiento
														)}
													</td>
													<td>
														{numeracion.prefijo}
													</td>
													<td>
														{
															numeracion.siguiente_numero
														}
													</td>
													<td>
														{
															numeracion.numero_final
														}
													</td>
													<td className="d-flex justify-content-center">
														{numeracion.status ===
														"activo" ? (
															<span className="badge badge-success p-2">
																Activo
															</span>
														) : (
															<span className="badge badge-danger p-2">
																Inactivo
															</span>
														)}
													</td>
													<td>
														<div
															className="table-data-feature"
															style={{
																justifyContent:
																	"flex-start",
															}}
														>
															<button
																className="item"
																data-toggle="tooltip"
																data-placement="top"
																title=""
																data-original-title="Edit"
																onClick={() => {
																	toggleEditModal(
																		numeracion
																	);
																}}
															>
																<i className="zmdi zmdi-edit" />
															</button>
														</div>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
						{numeraciones.links && (
							<Pagination
								links={numeraciones.links}
								meta={numeraciones.meta}
								getData={getNumeracionesData}
							/>
						)}
					</div>
				</div>
			</div>
			<Modal
				show={showCreateModal}
				blank={true}
				toggle={toggleCreateModal}
			>
				<Create
					toggleModal={toggleCreateModal}
					getNumeracionesData={getNumeracionesData}
					filtrosQuery={filtrosQuery}
				/>
			</Modal>
			<Modal
				show={showEditModal}
				blank={true}
				toggle={() => toggleEditModal()}
			>
				<Edit
					toggleModal={() => toggleEditModal()}
					getNumeracionesData={getNumeracionesData}
					numeracionID={numeracionToEdit}
					filtrosQuery={filtrosQuery}
				/>
			</Modal>
		</>
	);
};

export default Numeraciones;
