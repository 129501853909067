import React, { useCallback, useEffect, useMemo, useState } from "react";
import { request } from "../../../../utils/request";
import { useParams } from "react-router-dom";
import { formatCurrency } from "../../../../utils/helpers";

const PrintNominasEmpleado = () => {
    const { empleado, pagos, summaries } = useData();

    return (
        <>
            <div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte de Historial de Pagos
				</h2>
                <div className="col-md-12 text-center">
					{empleado?.nombreCompleto}
				</div>
			</div>

            <table className='table tickets-table'>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Sueldo Fijo</th>
                            <th>Descuentos</th>
                            <th>Incentivos Fijos</th>
                            <th>Incentivos Únicos</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pagos?.map((pago) => (
                            <tr key={pago.id}>
                                <td>{pago.fecha}</td>
                                <td>{formatCurrency(pago.sueldoBruto)}</td>
                                <td>{formatCurrency(pago.descuentosTotal)}</td>
                                <td>{formatCurrency(pago.incentivosFijos)}</td>
                                <td>{formatCurrency(pago.incentivosUnicos)}</td>
                                <td>{formatCurrency(pago.total)}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="text-right"><strong>Total</strong></td>
                            <td><strong>{formatCurrency(summaries.sueldoFijo)}</strong></td>
                            <td><strong>{formatCurrency(summaries.descuentosTotal)}</strong></td>
                            <td><strong>{formatCurrency(summaries.incentivosFijos)}</strong></td>
                            <td><strong>{formatCurrency(summaries.incentivosUnicos)}</strong></td>
                            <td><strong>{formatCurrency(summaries.total)}</strong></td>
                        </tr>
                    </tfoot>
                </table>
        </>
    );
};

const useData = () => {
    const { id } = useParams();
    const [empleado, setEmpleado] = useState();
	const [pagos, setPagos] = useState([]);
	const [summaries, setSummaries] = useState({
		sueldoFijo: 0,
		descuentosTotal: 0,
		incentivosFijos: 0,
		incentivosUnicos: 0,
		total: 0,
	});

    const getEmpleado = async () => {
        const { data } = await request(`empleados/${id}`);

        setEmpleado(data);
	};

    const getPagos = async () => {
        const { data } = await request(`empleados/${id}/pagos`);

        setPagos(data);
    };

    const calculateSummaries = useCallback(() => {
        console.log(pagos);
        for (const pago of pagos) {
            setSummaries((preview) => ({
                sueldoFijo: preview.sueldoFijo + pago.sueldoFijo,
                descuentosTotal: preview.descuentosTotal + pago.descuentosTotal,
                incentivosFijos: preview.incentivosFijos + pago.incentivosFijos,
                incentivosUnicos: preview.incentivosUnicos + pago.incentivosUnicos,
                total: preview.total + parseFloat(pago.total),
            }));
        }
    }, [pagos]);

    useEffect(() => {
		getEmpleado();
        getPagos();
	}, []);

    useEffect(() => {
		if (pagos.length <= 0) return;

		calculateSummaries();

		const timeout = setTimeout(() => window.print(), 700);

		return () => clearTimeout(timeout);
	}, [pagos]);

    return useMemo(
		() => ({
			empleado,
			pagos,
			summaries,
		}),
		[empleado, pagos, summaries]
	);
};

export default PrintNominasEmpleado;
