import React, { Component } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import Select from "react-select";
import SelectAsync from "react-select/async";
import { getLink } from "../../../utils/helpers";
import Loader from "../../general/Loader";
import Pagination from "../../general/Pagination";
import { withRequest } from "../../../utils/request";


class Tutores extends Component {
    state = {
        tutores: [],
        links: [],
        meta: [],
        filtros: {
            buscar: "",
            tipo: "",
            estado: "todos",
        },
        loading: true,
        showCompararTss: false,
        filtrosQuery: "",
    };

    componentDidMount() {
        this.getTutores();
    }

    getTutores = async (url = null) => {
        url = url ? url + "&limit=12" : "academico/padres-tutores?limit=12";
        const tutores = await this.props.request(url);

        if (tutores.links) {
            this.setState({
                tutores: tutores.data,
                links: tutores.links,
                meta: tutores.meta,
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
            });
        }
    };

    getTable = async (url, name = null) => {
        const request = await this.props.request(url);
        const table = name ? name : url;

        if (request.data) {
            this.setState({
                [table]: request.data,
            });
            this.setState({
                loading: false,
            });
        }
    };

    getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await this.props.request(url);
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };

    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: encodeURIComponent(value),
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getTutores("academico/padres-tutores?" + query.join("&"));
    }, 500);

    displayFilters = () => {
        document.getElementById('mas-filtros').classList.toggle('d-none');
    }
    render() {
        const { tutores, loading, filtrosQuery } = this.state;
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Listado de Padres/Tutores</span>

                        <div className='dropdown m-r-10'>
                            <button className='au-btn au-btn-icon au-btn-filter'>
                                <i className='zmdi zmdi-print'></i> Imprimir
                            </button>
                            <ul
                                style={{ right: "0", left: "auto" }}
                                className='dropdown-menu'>
                                <li>
                                    <Link
                                        to={getLink(
                                            `/imprimir/tutores${filtrosQuery}`
                                            )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        {" "}
                                        Listado Tutores
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={getLink(
                                            `/imprimir/tutores/cumpleanios${filtrosQuery}`
                                            )}
                                        target='_blank'
                                        className='dropdown-item'>
                                        {" "}
                                        Cumpleaños
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <Link
                            to={getLink("/academico/padres-tutores/crear")}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Padre/Tutor
                        </Link>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='search'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='periodo'>Año Escolar</label>
                                    <SelectAsync
                                        name='periodo_academico'
                                        key='periodo_academico'
                                        placeholder="Seleccionar..."
                                        cacheOptions
                                        defaultOptions
                                        isClearable
                                        onChange={(e) => this.onFiltroChange(e?.value || '', "periodo_academico")}
                                        loadOptions={(e) => this.getOptions("academico/periodos-academicos?buscar="+e)}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='grado'>Grado</label>
                                    <SelectAsync
                                        name='grado'
                                        key='grado'
                                        placeholder="Seleccionar..."
                                        cacheOptions
                                        defaultOptions
                                        isClearable
                                        onChange={(e) => this.onFiltroChange(e?.value || '', "grado")}
                                        loadOptions={(e) => this.getOptions("academico/grados?buscar="+e)}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='seccion'>Sección</label>
                                    <SelectAsync
                                        name='seccion'
                                        key='seccion'
                                        placeholder="Seleccionar..."
                                        cacheOptions
                                        defaultOptions
                                        isClearable
                                        onChange={(e) => this.onFiltroChange(e?.value || '', "seccion")}
                                        loadOptions={(e) => this.getOptions("academico/secciones?buscar="+e)}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label>Nacionalidad</label>
                                    <SelectAsync
                                        name='nacionalidad'
                                        key='nacionalidad'
                                        placeholder="Seleccionar..."
                                        cacheOptions
                                        defaultOptions
                                        isClearable
                                        onChange={(e) => this.onFiltroChange(e?.value || '', "nacionalidad")}
                                        loadOptions={(e) => this.getOptions("taxonomias?relacion=Tutores&tipo=Nacionalidad&buscar="+e, 'nombre', 'nombre')}
                                    />
                                </div>
                                <div style={{width: '50px'}} className="filtros-avanzados" onClick={this.displayFilters}>
                                    <label>&nbsp;</label>
                                    <button className="btn" title="Filtros Avanzados"><i className="zmdi zmdi-filter-list"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mas-filtros" className="row mas-filtros d-none m-b-25">
                    </div>
                </div>
                <div className='tutores-list'>
                    {loading && <Loader />}
                    {!tutores.length ? (
                        <div>
                            {!loading && <div>No hay Tutores creados.</div>}
                        </div>
                    ) : (
                        <div className='table-data'>
                            <table className="table table-data2">
                                <thead>
                                    <tr>
                                        <th width="50px"></th>
                                        <th>Nombre</th>
                                        <th>Estudiantes</th>
                                        <th>Estado</th>
                                        <th>Nacionalidad</th>
                                        <th>Contacto</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {tutores.map((tutor) => (
                                        <TutorCard
                                            key={tutor.id}
                                            tutor={tutor}
                                        />
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                links={this.state.links}
                                meta={this.state.meta}
                                getData={this.getTutores}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const TutorCard = ({tutor}) => {
    return (
        <tr className="tr-shadow">
            <td>{tutor.foto && (
                <div className='col-md-3'>
                    <span className='page-title-foto'>
                        <img
                            src={tutor.foto}
                            alt={tutor.codigo}
                        />
                    </span>
                </div>
            )}</td>
            <td>
                <Link to={getLink(`/academico/padres-tutores/${tutor.id}/informacion`)}>
                    <strong>{tutor.nombreCompleto}</strong>
                </Link>
                {tutor.comite && (
                    <div><span className="badge badge-primary">{tutor.comite}</span></div>
                )}
            </td>
            <td>
                {tutor.estudiantes.map(e => (
                    <div key={e.id}>{e.parentesco} de <Link to={getLink(`/academico/estudiantes/${e.id}/editar`)}>{e.nombreCompleto}</Link></div>
                ))}
            </td>
            <td>
                {tutor.activo ?
                    (<span className='badge badge-success'>
                        Activo
                    </span>)
                    :
                    (<span className='badge badge-danger'>
                        Inactivo
                    </span>)
                }
            </td>
            <td>{tutor.nacionalidad}</td>
            <td>
                {tutor.telefonoCasa && (
                    <div>Casa: <strong>{tutor.telefonoCasa}</strong></div>
                )}
                {tutor.celular && (
                    <div>Cel: <strong>{tutor.celular}</strong></div>
                )}
                {tutor.telefonoTrabajo && (
                    <div>Trabajo: <strong>{tutor.telefonoTrabajo}</strong></div>
                )}
                {tutor.correo && (
                    <div>{tutor.correo}</div>
                )}
            </td>
            <td>
                <div className="table-data-feature">
                    <Link to={getLink(`/academico/padres-tutores/${tutor.id}/informacion`)}
                        className='item'
                        >
                        <i className='zmdi zmdi-edit' />
                    </Link>
                </div>
            </td>
        </tr>
    );
};

export default withRequest(Tutores);
