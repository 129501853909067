import React, { useState } from "react";
import { toast } from "../../../utils/helpers";
import Loader from "../../general/Loader";
import { apiRequest } from "../../../hooks/apiRequest";
import FormDatosBancarios from "./FormDatosBancarios";

const DatosBancarios = ({sucursal}) => {
    const [errors, setErrors] = useState({});
    const [saving, setSaving] = useState(false);

    const submit = async (event) => {
        event.preventDefault();

        setSaving(true);

        const data = new FormData(event.target);
        const url = `sucursales/${sucursal.id}`;

        const response = await apiRequest(data, url, "POST");

        if (response.success) {
            toast("Sucursal Editada con éxito", 'success');
        }

        if (response.code === 422) {
            setErrors(response.data.message);
        }

        setSaving(false);
    };

    return <form onSubmit={submit}>
        <input type='hidden' name='_method' value='patch' />
        <input type='hidden' className='text-input form-control' name='nombre' value={sucursal.nombre} />
        <input type='hidden' className='text-input form-control' name='tipo' value={sucursal.tipo} />
        
        <FormDatosBancarios sucursal={sucursal} errors={errors} />

        <div className='page-footer text-right'>
            {
                saving 
                    ? (<Loader type='small' />) 
                    : (<button type='submit' className='btn btn-primary'>Guardar</button>)
            }
        </div>
    </form>;
};

export default DatosBancarios;