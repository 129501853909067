/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../utils/auth";
import { arrayToCsv, composeCSV, downloadBlob, formatCurrency, money } from "../../../utils/helpers";
import { request } from "../../../utils/request";
import { procesarNotasCreditoData } from "../../Consultas/NotasCredito/print/ReporteNotasCreditoDetalles";

const ReporteFacturasDetalles = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [facturas, setFacturas] = useState([]);
	const [notasCredito, setNotasCredito] = useState([]);
	const [resumen, setResumen] = useState({
		totalGravado: 0,
		totalExento: 0,
		detalleGravado: {
			totalITBIS16: 0,
			totalITBIS18: 0,
			gravadoITBIS16: 0,
			gravadoITBIS18: 0
		},
		detallePagos: {
			efectivo: 0,
			cheque: 0,
			tarjeta: 0,
			ventaCredito: 0,
			bonusRegalo: 0,
			permuta: 0,
			otro: 0,
		},
	});

	const getFacturas = () => {
		request(
			"facturas" +
				window.location.search +
				"&paginate=1000000"
		).then((res) => {
            const dataProcesada = procesarData(res.data);
			setFacturas(dataProcesada.facturas);
			setResumen(dataProcesada.resumenes);
			setTimeout(() => {
                window.print();
            }, 1000);
		});
	};

    const getNotasCredito = () => {
		request(
			"notas-credito" +
				window.location.search +
				"&paginate=1000000&order=ncf&tipo=venta"
		).then((res) => {
            var data = procesarNotasCreditoData(res.data)
			setNotasCredito(data.notasCredito);
		});
	};

    const procesarData = (data) => {
        const resumenes = {
            totalExento: 0,
            totalGravado: 0,
            detalleGravado: {
                totalITBIS16: 0,
                gravadoITBIS16:0,
                totalITBIS18: 0,
                gravadoITBIS18:0,
            },
            detallePagos: {
                efectivo: 0,
                cheque: 0,
                tarjeta: 0,
                ventaCredito: 0,
                bonusRegalo: 0,
                permuta: 0,
                otro: 0,
            },
        };

        const facturas = [];
        let notasCredito = [];

        data.forEach(factura => {
            const imp = factura.productos?.reduce((a,i) => {
                return a + (i.importe * i.impuesto?.porcentaje * i.cantidad)
            }, 0);

            const row = {
                cliente: factura.cliente?.nombre || '',
                rnc: factura.cliente?.identificacion?.replace(/-/g, '') || '',
                tipoIdentificacion: factura.cliente?.tipo_identificacion === 'rnc' ? 1 : 2,
                ncf: factura.numero_comprobante,
                fecha: dayjs(factura.fecha).format("YYYYMMDD"),
                importe: 0,
                afectada: '',
                importeC: factura.estado === "cancelada" ? 0 : money(factura.total - imp),
                impuesto: factura.estado === "cancelada" ? 0 : money(imp),
                total: 0,
                descuento: 0,
                totalC: factura.estado === "cancelada" ? 0 : money(factura.total),
                estado: factura.estado,
                i16: 0,
                i18: 0,
                ie: 0,
                ig16: 0,
                ig18: 0,
                it: 0,
                tipo: factura.tipo_pago,
                tipoIngreso: '01 - Ingresos por Operaciones (No Financieros)',
                retencion: factura.retencion || '',
                fechaRetencion: (factura.retencion && factura.pagos.length > 0) ? dayjs(factura.pagos.shift().fecha).format('YYYYMMDD') : '',
                efectivo: factura.tipo_pago === 'efectivo' ? factura.total : '',
                cheque: ['cheque', 'deposito', 'transferencia'].includes(factura.tipo_pago) ? factura.total : '',
                tarjeta: factura.tipo_pago === 'tarjeta' ? factura.total : '',
                credito: factura.plazo?.nombre.includes('Contado') ? '' : factura.total,
            }

            if (factura.estado !== 'cancelada') {
                factura.productos.forEach(producto => {
                    const precioProducto = producto.precio_facturado;
                    const porcentaje = producto.impuesto?.porcentaje || 0;
                    let totalImporte = (precioProducto / (porcentaje + 1)) * producto.cantidad;
                    let totalImpuesto = totalImporte * porcentaje;
                    let total = precioProducto * producto.cantidad;

                    if (producto.impuesto.porcentaje === 0.16) {
                        row.i16 += totalImpuesto;
                        row.ig16 += totalImporte;

                        resumenes.detalleGravado.totalITBIS16 += totalImpuesto;
                        resumenes.detalleGravado.gravadoITBIS16 += totalImporte;
                        resumenes.totalGravado += totalImporte;
                    } else if (producto.impuesto.porcentaje === 0.18) {
                        row.i18 += totalImpuesto;
                        row.ig18 += totalImporte;

                        resumenes.detalleGravado.totalITBIS18 += totalImpuesto;
                        resumenes.detalleGravado.gravadoITBIS18 += totalImporte;
                        resumenes.totalGravado += totalImporte;
                    } else {
                        row.ie += totalImporte;
                        resumenes.totalExento += totalImporte;
                    }

                    row.it += totalImpuesto;
                    row.importe += totalImporte;
                    const descuento = total * producto.descuento / 100;
                    row.descuento += descuento;
                    row.total += total - descuento;
                });

                factura.pagos.forEach(pago => {
                    if (pago.tipo_pago === 'efectivo') {
                        resumenes.detallePagos.efectivo += parseFloat(pago.monto);
                    } else if (
                        pago.tipo_pago === 'cheque'
                        || pago.tipo_pago === 'deposito'
                        || pago.tipo_pago === 'transferencia'
                    ) {
                        resumenes.detallePagos.cheque += parseFloat(pago.monto);
                    } else if (pago.tipo_pago === 'tarjeta') {
                        resumenes.detallePagos.tarjeta += parseFloat(pago.monto);
                    } else {
                        resumenes.detallePagos.otro += parseFloat(pago.monto);
                    }
                });

                if (factura.tipo === 'credito') {
                    resumenes.detallePagos.ventaCredito += factura.subtotal;
                }
            }
            facturas.push(row);
        });

        return {
            resumenes,
            facturas,
        };
    }

	useEffect(() => {
		getFacturas();
        getNotasCredito();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];

	const valorTotal = facturas.reduce((acc, factura) => {
		return acc + factura.total;
	}, 0);

	const valorImpuestos = facturas.reduce((acc, factura) => {
        return acc + factura.it;
    }, 0);

	const valorDescuentos = facturas.reduce((acc, factura) => {
        return acc + factura.descuento;
    }, 0);

	const valorImporte = facturas.reduce((acc, factura) => {
		return acc + factura.importe;
	}, 0);

    const reporte = composeCSV(
        facturas.map(factura => ({
            "Cliente"   : factura.cliente,
            "R.N.C"     : factura.rnc,
            "NCF/Numero": factura.ncf,
            "Fecha"     : factura.fecha,
            "Importe"   : money(factura.importe),
            "Impuesto"  : money(factura.it),
            "descuento" : money(factura.descuento),
            "Total"     : money(factura.total),
            "Estado"    : factura.estado,
            "Tipo pago" : factura.tipo
        }))
    );

    const reporteDetalle = composeCSV(
        facturas.map(factura => ({
            "Cliente"    : factura.cliente,
            "R.N.C"      : factura.rnc,
            "NCF/Numero" : factura.ncf,
            "Fecha"      : factura.fecha,
            "Estado"     : factura.estado,
            "Tipo pago"  : factura.tipo,
            "Importe"    : money(factura.importe),
            "ITBIS 16%"  : money(factura.i16),
            "ITBIS 18%"  : money(factura.i18),
            "Impuesto"   : money(factura.it),
            "Descuento"  : money(factura.descuento),
            "Total"      : money(factura.total),
            "Exento"     : money(factura.ie),
            "Grabado 16%": money(factura.ig16),
            "Grabado 18%": money(factura.ig18),
        }))
    );

    const reporte607 = composeCSV(
        [...facturas.filter(f => f.estado !== 'cancelada'), ...notasCredito.filter(f => f.estado !== 'cancelada')].map(factura => ({
            "RNC/Cédula o Pasaporte"              : factura.rnc,
            "Tipo Identificación"                 : factura.tipoIdentificacion,
            "Número Comprobante Fiscal"           : factura.ncf,
            "Número Comprobante Fiscal Modificado": factura.afectada,
            "Tipo de Ingreso"                     : factura.tipoIngreso,
            "Fecha Comprobante"                   : factura.fecha,
            "Fecha de Retención"                  : factura.fechaRetencion,
            "Monto Facturado"                     : money(factura.importe),
            "ITBIS Facturado"                     : money(factura.it),
            "ITBIS Retenido por Terceros"         : factura.retencion ? money(factura.retencion): '',
            "ITBIS Percibido"                     : '',
            "Retención Renta por Terceros"        : '',
            "ISR Percibido"                       : '',
            "Impuesto Selectivo al Consumo"       : '',
            "Otros Impuestos/Tasas"               : '',
            "Efectivo"                            : factura.efectivo ? money(factura.efectivo)  : '',
            "Cheque/Transferencia/Depósito"       : factura.cheque ? money(factura.cheque)      : '',
            "Tarjeta Débito/Crédito"              : factura.tarjeta ? money(factura.tarjeta)    : '',
            "Venta a Crédito"                     : factura.credito ? money(factura.credito)    : '',
            "Bonos o Certificados de Regalo"      : '',
            "Permuta"                             : '',
            "Otras Formas de Ventas"              : '',
        }))
    );

	return (
		<>
			<style>{`@page {size: A4 landscape}`}</style>
            <div className="ordernar-resultados no-print">
                <div className="form-group">
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        const content = arrayToCsv(reporte);
                        downloadBlob(content, `impuestos-facturas-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}).csv`)
                    }}>Exportar</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        const content = arrayToCsv(reporteDetalle);
                        downloadBlob(content, `detalle-de-impuestos-facturas-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}).csv`)
                    }}>Exportar Detalle</button>
                    <button className="btn btn-secondary" onClick={() => {
                        const content = arrayToCsv(reporte607);
                        downloadBlob(content, `impuestos-facturas-607(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}).csv`)
                    }}>Exportar 607</button>
                </div>
            </div>

			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Detalle de Impuestos Facturas
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {facturas.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>

			<table className="table m-b-10">
				<thead>
					<tr>
						<th width="290px">Cliente</th>
						<th width="100px">R.N.C</th>
						<th width="120px">NCF/Numero</th>
						<th width="100px">Fecha</th>
						<th width="135px">Importe</th>
						<th width="125px">Impuestos</th>
						<th>Desc.</th>
						<th width="135px">Total</th>
						<th width="85px">Estado</th>
						<th width="110px">Forma de Pago</th>
					</tr>
				</thead>
				<tbody>
					{facturas.map((factura) => {
						return (
							<tr key={factura.ncf}>
								<td>
									{factura.cliente}
								</td>
								<td className="text-capitalize">
									{factura.rnc}
								</td>
								<td>{factura.ncf}</td>
								<td>
									{factura.fecha}
								</td>
								<td>
									{formatCurrency(factura.importe)}
								</td>
								<td>
									{formatCurrency(factura.it)}
								</td>
								<td>
									{formatCurrency(factura.descuento)}
								</td>
								<td>
									{formatCurrency(factura.total)}
								</td>
								<td className="text-capitalize">
									{factura.estado}
								</td>
								<td className="text-capitalize">
									{factura.tipo}
								</td>
							</tr>
						);
					})}
                    <tr className="fw-600">
						<td width="290px" style={{fontWeight: "normal"}}>Total de facturas {facturas.length}</td>
						<td width="100px"></td>
						<td width="120px"></td>
						<td width="100px"></td>
						<td width="135px">
							{formatCurrency(valorImporte)}
						</td>
						<td
							width="125px"
						>
							{formatCurrency(valorImpuestos)}
						</td>
						<td>
                            {formatCurrency(valorDescuentos)}
                        </td>
						<td width="135px">
							{formatCurrency(valorTotal)}
						</td>
						<td width="85px"></td>
						<td width="110px"></td>
					</tr>
				</tbody>
			</table>
			{/* RESUMEN DE FACTURAS (IMPUESTOS) */}
			<div className="row">
				<div className="fw-600 mb-2 w-100 pl-3 col-md-12">
					RESUMEN DE FACTURAS
				</div>
				<div className="col-md-6">
                    {/* DETALLES */}
                    <div
                        className="p-b-10 d-inline-block mr-4"
                        style={{ width: "22rem" }}
                    >
                        <div className="fw-600">TOTALES NETOS</div>
                                        <hr style={{borderBottom: "3px double #999"}}/>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                GRAVADOS CON ITBIS 16%
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(
                                    resumen.detalleGravado?.gravadoITBIS16
                                )}
                            </span>
                        </div>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                GRAVADOS CON ITBIS 18%
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(
                                    resumen.detalleGravado?.gravadoITBIS18
                                )}
                            </span>
                        </div>
                                        <hr style={{borderBottom: "3px double #999"}}/>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL GRAVADO
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(resumen.totalGravado)}
                            </span>
                        </div>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL EXENTO{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(resumen.totalExento)}
                            </span>
                        </div>
                                        <hr style={{borderBottom: "3px double #999"}}/>
                                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL OPERACIONES{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(resumen.totalGravado + resumen.totalExento)}
                            </span>
                        </div>
                                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL ITBIS{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(resumen.detalleGravado?.totalITBIS16 + resumen.detalleGravado?.totalITBIS18)}
                            </span>
                        </div>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL DESCUENTOS{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(valorDescuentos)}
                            </span>
                        </div>
                                        <hr style={{borderBottom: "3px double #999"}}/>
                                        <div className="bold">
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL GENERAL{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(valorTotal)}
                            </span>
                        </div>
                    </div>
                </div>

				<div className="col-md-6">
                    {/* IMPUESTOS */}
                    <div
                        className="p-b-10"
                        style={{ width: "26rem" }}
                    >
                        {/* IMPUESTOS */}
                        <div className="p-b-10" style={{ width: "26rem" }}>
                            <div className="fw-600">TOTALES POR TASAS DE ITBIS:</div>
                            <hr style={{borderBottom: "3px double #999"}}/>
                            <table className="table-clean">
                                <thead style={{borderBottom: "1px dashed #999"}}>
                                    <tr>
                                        <td>TASA</td>
                                        <td className="text-right">VENTA</td>
                                        <td className="text-right">ITBIS</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>16% ITBIS</td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.gravadoITBIS16
                                            )}
                                        </td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.totalITBIS16
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>18% ITBIS</td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.gravadoITBIS18
                                            )}
                                        </td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.totalITBIS18
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot style={{borderTop: "1px dashed #999"}}>
                                    <tr>
                                        <td></td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.gravadoITBIS16 +
                                                resumen.detalleGravado?.gravadoITBIS18
                                            )}
                                        </td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.totalITBIS16 +
                                                resumen.detalleGravado?.totalITBIS18
                                            )}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        </div>
                        {/* TIPOS DE COBRO */}
                        <div
                            className="p-b-10 d-inline-block mr-4"
                            style={{ width: "26rem" }}
                        >
                            <div className="fw-600">FORMAS DE PAGO</div>
                            <div>
                                <span
                                    style={{ width: "250px", display: "inline-block" }}
                                >
                                    EFECTIVO
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(
                                        resumen.detallePagos?.efectivo
                                    )}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "250PX", display: "inline-block" }}
                                >
                                    CHEQUE/TRANSFERENCIA/DEPOSITO
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(
                                        resumen.detallePagos?.cheque
                                    )}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "250PX", display: "inline-block" }}
                                >
                                    TARJETA DÉBITO/CRÉDITO
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(resumen.detallePagos?.tarjeta, "$")}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "250PX", display: "inline-block" }}
                                >
                                    VENTA A CRÉDITO
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(resumen.detallePagos?.ventaCredito, "$")}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "180px", display: "inline-block" }}
                                >
                                    BONOS O CER. DE REGALO
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(
                                        resumen.detallePagos?.bonusRegalo
                                    )}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "180px", display: "inline-block" }}
                                >
                                    PERMUTA
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(
                                        resumen.detallePagos?.permuta
                                    )}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "180px", display: "inline-block" }}
                                >
                                    OTRAS FORMAS
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(resumen.detallePagos?.otro, "$")}
                                </span>
                            </div>
                        </div>
                </div>
			</div>

			{/* RESUMEN DE FACTURAS (TIPOS DE COBRO) */}
			<div className="row">


				{/* SEGUNDA SECCIÓN TIPOS COBRO */}

			</div>
		</>
	);
};

export default ReporteFacturasDetalles;
