import React, { Component } from "react";
import Select from "react-select";
import SelectCreate from "react-select/creatable";

import { formatCurrency, money, toast } from "../../utils/helpers";

export default class ItemCompra extends Component {
	state = {
		cantidad: 1,
		costo: 0,
		costoMin: 0.01,
		subtotal: 0,
		disponible: 0,
		impuesto: 0,
		referencia: null,
		impuesto_id: null,
		precios: [],
		grupo: null,
		valid: true,
		enableTotalInput: true,
		total: 0,
	};
	componentDidMount() {
		this.loadData();
	}
    loadData = async () => {
        const { item } = this.props;
		if (item) {
			await this.setState({
				costo: item.costo,
                total: item.total || 0,
				cantidad: item.cantidad,
				referencia: item.referencia,
				disponible: item.cantidad || 1,
				impuesto: item.impuesto?.porcentaje * 100 || 0,
				impuesto_id: item.impuesto?.id || 0,
				precios: item.precios || [],
				grupo: item.grupo?.id,
			});
            if (this.state.enableTotalInput) {
                this.calcularCosto(true);
            } else {
                this.props.updateTotal(
                    item.id,
                    this.calcularTotal(
                        item.costo,
                        item.cantidad,
                        item.impuesto?.porcentaje * 100 || 0
                    ),
                    {
                        nombre: item?.impuesto?.nombre,
                        totalImpuesto: this.calcularImpuesto(
                            item.costo,
                            item.cantidad,
                            item.impuesto?.porcentaje * 100 || 0
                        ),
                    }, 0, true
                );
            }
		}
    }
	onChangeInput = async (name, value) => {
		await this.setState({
			[name]: value,
		});

		if (this.state.enableTotalInput && name !== "costo") {
            this.calcularCosto();
            return;
        } else if (this.state.enableTotalInput && name === "costo"){
            this.setState({enableTotalInput: false});
        }
		this.actualizarTotal();
	};
	onChangeImpuesto = async (value) => {
		const impuesto = this.props.impuestos.filter((i) => i.id === value)[0];

		await this.setState({
			impuesto_id: impuesto.id,
			impuesto: impuesto.porcentaje || 0,
		});

        if (this.state.enableTotalInput) {
            this.calcularCosto();
            return;
        };

		this.actualizarTotal();
	};
	onChangePrecio = (id, value) => {
		this.setState((prevState) => ({
			precios: prevState.precios.map((precio) => {
				if (precio.id === id) {
					precio.total = Math.round(value);
				}
				return precio;
			}),
		}));
	};
	actualizarTotal = (ignoreTotals = false) => {
		const { cantidad, costo, impuesto, impuesto_id } = this.state;
		const { item } = this.props;
		let imp = this.props.impuestos.filter((i) => i.id === impuesto_id)[0];

        if (!imp && item.impuesto) {
            imp = item.impuesto;
        }

		if (parseFloat(cantidad) < 0.01 || cantidad === "") {
			toast("Cantidad no puede ser 0", "error");
			this.setState({ valid: false });
		} else {
			this.setState({ valid: true });
		}

		this.props.updateTotal(
			item.id,
			this.calcularTotal(costo, cantidad, impuesto),
			{
				nombre: imp?.nombre,
				totalImpuesto: this.calcularImpuesto(costo, cantidad, impuesto),
			}, 0, ignoreTotals
		);

        if(this.state.enableTotalInput) return;
        this.setState({total: this.calcularTotal(costo, cantidad, impuesto)});
	};
	onChangeItem = async (itemId) => {
		let itemCompra = this.props.items.filter(
			(item) => item.id === itemId
		)[0];
		itemCompra = itemCompra ? itemCompra : {};

		await this.setState({
			id: itemCompra.id ? itemCompra.id : null,
			nombre: itemCompra.nombre ? itemCompra.nombre : null,
            viejoCosto: itemCompra.costo ?? 0,
			costo: 0,
			total: itemCompra.total ?? 0,
			cantidad: 1,
			referencia: itemCompra.referencia || "",
			impuesto: itemCompra.impuesto?.porcentaje || 0,
			impuesto_id: itemCompra.impuesto ? itemCompra.impuesto.id : 0,
			costoMin: itemCompra.min ? itemCompra.min : itemCompra.costo,
			disponible: itemCompra.cantidad ? itemCompra.cantidad : 0,
			grupo: itemCompra.grupo?.nombre,
			valid: itemCompra.cantidad > 0,
            total: itemCompra.total,
            enableTotalInput: true,
		});

		const { item } = this.props;
		this.props.updateTotal(
			item.id,
			this.calcularTotal(
				itemCompra.costo,
				itemCompra.cantidad,
				itemCompra.impuesto
			)
		);
		this.actualizarTotal();
	};
	addPrecio = () => {
		this.setState((prevState) => ({
			precios: [...prevState.precios, { id: Date.now() }],
		}));
	};
	removePrecio = (id) => {
		this.setState((prevState) => ({
			precios: prevState.precios.filter((p) => p.id !== id),
		}));
	};
	toggleItem = () => {
		this.setState({
			isItem: !this.state.isItem,
		});
	};
	calcularTotal = (costo, cantidad, impuesto) => {
        const p = impuesto < 1 ? impuesto : impuesto / 100;
        return (costo * parseFloat(cantidad)) + (costo * p * parseFloat(cantidad));
    }
	calcularImpuesto = (costo, cantidad, impuesto) => {
        const p = impuesto < 1 ? impuesto : impuesto / 100;
        return costo * p * cantidad;
    }
	toggleEnableTotalInput = () => {
		this.setState((state) => ({
			...state,
			enableTotalInput: !state.enableTotalInput,
		}));
	};

    calcularCosto = async (ignoreTotals=false) => {
        const { total, cantidad, impuesto } = this.state;

        const porcentajeImpuesto = impuesto < 1 ? parseFloat(impuesto) : parseFloat(impuesto) / 100;

        await this.setState({costo: ((parseFloat(total) / (porcentajeImpuesto + 1))/cantidad)});

        this.actualizarTotal(ignoreTotals);
    }

	render() {
		const { index, items, item, impuestos, removeItem, grupos, almacen } =
			this.props;
		const {
			cantidad,
			grupo,
			costo,
			impuesto_id,
			referencia,
			total,
            viejoCosto,
            disponible,
			enableTotalInput,
		} = this.state;

		const imp = impuesto_id ? impuestos.filter(i => i.id === impuesto_id)[0] : impuestos[0];

		return (
			<tr>
				<td className="text-center">{index + 1}</td>
				<td>
					<input
						type="hidden"
						name={`items_compra[i${index}][almacen_id]`}
						value={almacen || ""}
					/>
                    <input type="hidden" name={`items_compra[i${index}][tipo]`} value={item.tipo ?? 'item'} />
					<div>
						<SelectCreate
                            key={`i${index}-item_id`}
							options={items?.map((i) => ({
								value: i.id,
								label: `${
									i.referencia?.padEnd(8, " ") || "N/A"
								} - ${i.nombre}`,
							}))}
							isLoading={items[0] === false}
							loadingMessage={() => "Cargando items..."}
							defaultValue={
								item?.nombre && {
									label: item.nombre,
									value: item.item_id,
								}
							}
							required
							onChange={(e) => {
								if (!e) e = {};
								this.onChangeItem(e.value);
							}}
							name={`items_compra[i${index}][item_id]`}
							isClearable={true}
						/>
                        {viejoCosto > 0 && (
                            <div style={{with: '100%'}}>
                                <small>
                                    Últ. Precio: {formatCurrency(parseFloat(viejoCosto).toFixed(2))} | Displonible: {disponible}
                                </small>
                            </div>
                        )}
					</div>
				</td>
				<td>
					<input
						type="text"
						key="referencia"
						name={`items_compra[i${index}][referencia]`}
						value={referencia || ""}
						onChange={(e) => {
							this.onChangeInput("referencia", e.target.value);
						}}
						/* disabled={compra.item?.referencia} */
						className="form-control"
					/>
				</td>
				<td>
					<input
						type="number"
						name={`items_compra[i${index}][cantidad]`}
						value={cantidad}
						min="0.01"
						onChange={(e) =>
							this.onChangeInput("cantidad", e.target.value)
						}
						className="form-control text-right"
						step="any"
					/>
				</td>
				<td className="text-center"
                    title="Doble Click para editar"
                    onDoubleClick={() => this.toggleEnableTotalInput()}
                    >
                    {!enableTotalInput ? (
					<div className="input-group">
						<div className="input-group-prepend">
							<span
								className="input-group-text"
								id="basic-addon1"
							>
								RD$
							</span>
						</div>
						<input
							type="number"
							value={enableTotalInput ? parseFloat(costo).toFixed(2) : costo}
							onChange={(e) =>
								this.onChangeInput("costo", e.target.value)
							}
							className="form-control text-right"
							step="any"
						/>
					</div>) : (
                        <div className="m-t-5">
                            {formatCurrency(parseFloat(costo).toFixed(2))} <i className="fa fa-pen table-edit-field color-primary"></i>
                        </div>
                    )}
                    <input
						type="hidden"
						name={`items_compra[i${index}][costo]`}
						value={costo || 0}
					/>
				</td>
				<td className="text-center">
					<Select
                        key={`i${index}-impuesto_id`}
						options={impuestos.map((i) => ({
							label: i.nombre,
							value: i.id,
						}))}
						value={imp && {
                            label: imp?.nombre,
                            value: imp?.id,
                        }}
						onChange={(e) => this.onChangeImpuesto(e.value)}
					/>
					<input
						type="hidden"
						name={`items_compra[i${index}][impuesto_id]`}
						value={impuesto_id || ""}
					/>
				</td>
				<td>
					<div>
						<SelectCreate
							options={grupos?.map((i) => ({
								value: i.nombre,
								label: i.nombre,
							}))}
							loadingMessage={() => "Cargando..."}
							value={
								item?.grupo
									? {
											label: item.grupo,
											value: item.grupo,
									  }
									: { label: grupo, value: grupo }
							}
							required
							onChange={(e) => {
								if (!e) e = {};
								this.onChangeInput("grupo", e.value);
							}}
							name={`items_compra[i${index}][grupo_id]`}
							isClearable={true}
						/>
					</div>
				</td>
				<td
					className="text-right"
					onDoubleClick={() => this.toggleEnableTotalInput()}
                    title="Doble Click para editar"
				>
					{enableTotalInput ? (
						<div className="input-group">
							<div className="input-group-prepend">
								<span
									className="input-group-text"
									id="basic-addon1"
								>
									RD$
								</span>
							</div>
							<input
								type="number"
								value={total || 0}
								onChange={(e) =>
									this.onChangeInput("total", e.target.value)
								}
								className="form-control text-right"
								step="any"
							/>
						</div>
					) : (
						<div className="m-t-5">
                            {formatCurrency(total)} <i className="fa fa-pen table-edit-field color-primary"></i>
                        </div>
					)}
					<input
						type="hidden"
						name={`items_compra[i${index}][total]`}
						value={total || 0}
					/>
				</td>
				<td>
					<button
						type="button"
						className="btn"
						onClick={() => removeItem(item.id)}
					>
						<i className="fas fa-trash"></i>
					</button>
				</td>
			</tr>
		);
	}
}
