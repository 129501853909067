import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { request } from "../../../../../utils/request";
import { formatCurrency, getSucursal } from "../../../../../utils/helpers";
import { auth } from "../../../../../utils/auth";

const SingleReciboCocurricular = () => {
	const { id } = useParams();
	// const location = useLocation();

	const [recibo, setRecibo] = useState({});
	const [sucursal, setSucursal] = useState({});
	const [infoBlock2Margin, setInfoBlock2Margin] = useState(10);
	const [status, setStatus] = useState("Original");

	const getRecibo = () => {
		request("academico/recibos/" + id).then((res) => {
			setRecibo(res.data);
			setTimeout(() => {
				window.print();
			}, 500);
		});
	};

	const onChangeStatusRecibo = (estado) => {
		setStatus(estado);
	};

	useEffect(() => {
		getRecibo();
		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);
	}, []);

	useEffect(() => {
		// This is for calculate the margin of the second column of client info. that way the last row of each column will be alined
		const infoBlock1 = document.getElementById("info-block-1");
		const infoBlock2 = document.getElementById("info-block-2");
		const count =
			infoBlock2.childElementCount - infoBlock1.childElementCount;
		setInfoBlock2Margin((count * 25 + 10) * -1);
	}, [recibo]);

    const contacto = recibo.contacto || {};
    const reciboIngreso = recibo.reciboIngreso;

    const usuario = auth.getUser();

    const detallePago = recibo.reciboIngreso?.detalle_metodo_pago || {};

    const isAbono = recibo.servicios?.length > 0 && recibo.servicios[0].monto > parseFloat(recibo.servicios[0].pivot.abonado);

	return (
		<>
			<ReciboTemplate
                sucursal={sucursal}
                recibo={recibo}
                infoBlock2Margin={infoBlock2Margin}
                status={status}
                reciboIngreso={reciboIngreso}
                contacto={contacto}
                detallePago={detallePago}
                usuario={usuario}
                isAbono={isAbono}
            />

            <hr style={{border: "1px dashed #ccc"}} />

			<ReciboTemplate
                sucursal={sucursal}
                recibo={recibo}
                infoBlock2Margin={infoBlock2Margin}
                status={status}
                reciboIngreso={reciboIngreso}
                contacto={contacto}
                detallePago={detallePago}
                usuario={usuario}
                isAbono={isAbono}
            />

			<div className="ordernar-resultados no-print">
				<div className="form-group">
					<div className="radio-btn">
						<label>
							<input
								type="radio"
								onChange={() =>
									onChangeStatusRecibo("Original")
								}
								name="orden"
								value="Original"
								defaultChecked={true}
							/>{" "}
							Original
						</label>
						<label>
							<input
								type="radio"
								onChange={() => onChangeStatusRecibo("Copia")}
								name="orden"
								value="Copia"
							/>{" "}
							Copia
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default SingleReciboCocurricular;

const ReciboTemplate = ({
    sucursal,
    recibo,
    contacto,
    infoBlock2Margin,
    status,
    reciboIngreso,
    detallePago,
    usuario,
    isAbono
}) => {
    return <div className="p-2 invoice recibo-print" style={{ fontSize: "1rem" }}>
        <div className="clear-both p-t-30 p-b-10">
            <div className="float-sm-left sucursal-info">
                {sucursal.logo ? (
                    <img
                        alt="logo"
                        className="mb-2 recibo-logo"
                        src={sucursal.logo[0].url}
                        width="270px" />
                ) : recibo.logo ? (
                    <img
                        alt="logo"
                        className="mb-2 recibo-logo"
                        src={recibo.logo[0].url}
                        width="270px" />
                ) : (
                    <h3 style={{ lineHeight: "12px", marginBottom: "3px" }}>
                        {sucursal.nombre}
                    </h3>
                )}
                <div
                    className="mb-0 mb-sm-0"
                    style={{ lineHeight: "15px", fontSize: "13px" }}
                >
                    RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
                </div>
                <div
                    className="mb-2 mb-sm-0"
                    style={{ lineHeight: "15px", fontSize: "13px" }}
                >
                    {sucursal.direccion}
                </div>
            </div>
        </div>

        <div className="clear-both info-row p-t-10 d-sm-flex">
            <div className="info-block" style={{ width: "65%" }}>
                <table>
                    <tbody id="info-block-1">
                        <tr>
                            <td
                                width="120px"
                                className="client-info-title text-uppercase text-right"
                            >
                                Contacto:
                            </td>
                            <td
                                style={{
                                    lineHeight: "13px",
                                    paddingLeft: "10px",
                                }}
                            >
                                {contacto.nombreCompleto}
                            </td>
                        </tr>
                        <tr>
                            <td className="client-info-title text-uppercase text-right">
                                Cédula/RNC:
                            </td>
                            <td style={{ paddingLeft: "10px" }}>
                                {contacto.identificacion}
                            </td>
                        </tr>
                        <tr>
                            <td className="client-info-title text-uppercase text-right">
                                Teléfono:
                            </td>
                            <td style={{ paddingLeft: "10px" }}>
                                {contacto.telefono}
                            </td>
                        </tr>
                        {contacto.direccion && (
                            <tr>
                                <td className="client-info-title text-uppercase text-right">
                                    Dirección:
                                </td>
                                <td
                                    style={{
                                        lineHeight: "13px",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    {contacto.direccion}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div
                className="info-block"
                style={{ marginTop: infoBlock2Margin }}
            >
                <table>
                    <tbody id="info-block-2">
                        <tr>
                            <td colSpan={2} style={{ paddingLeft: "10px" }}>
                                <h3
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    {status} {recibo.estado === 'cancelado' ? ' - CANCELADO' : ''}
                                </h3>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <h3
                                    style={{
                                        marginBottom: "3px",
                                        paddingBottom: "10px",
                                        whiteSpace: "nowrap",
                                        paddingLeft: "50%",
                                        textAlign: "right",
                                    }}
                                >
                                    Recibo de Ingreso
                                </h3>
                            </td>
                        </tr>
                        {recibo.fecha && (
                            <tr>
                                <td className="client-info-title text-uppercase text-right">
                                    Fecha:
                                </td>
                                <td style={{ paddingLeft: "10px" }}>
                                    {dayjs(recibo.fecha).format(
                                        "DD/MM/YYYY"
                                    )}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td className="client-info-title text-uppercase text-right">
                                No.:
                            </td>
                            <td style={{ paddingLeft: "10px" }}>
                                {recibo.numeracion}
                            </td>
                        </tr>
                        {reciboIngreso?.tipo_pago && (
                            <tr>
                                <td className="client-info-title text-uppercase text-right">
                                    MÉTODO DE PAGO:
                                </td>
                                <td style={{ paddingLeft: "10px", textTransform: "capitalize" }}>
                                    {reciboIngreso.tipo_pago}
                                </td>
                            </tr>
                        )}
                        {reciboIngreso?.tipo_pago === "cheque" && reciboIngreso?.detalle_metodo_pago?.num_cheque && (
                            <tr>
                                <td className="client-info-title text-uppercase text-right">
                                    NÚMERO CHEQUE:
                                </td>
                                <td style={{ paddingLeft: "10px", textTransform: "uppercase" }}>
                                    {reciboIngreso.detalle_metodo_pago.num_cheque}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>

        <div className="table-wrapper m-t-25">
            <h3 className="table-title"></h3>
            <table className="table d-none d-sm-table">
                <thead>
                    <tr className="text-uppercase">
                        <th>Detalle</th>
                        <th className="text-right">Cuota</th>
                        {/* <th className="text-right">Monto Auspiciado</th> */}
                        {isAbono && (
                            <th className="text-right">Pendiente</th>
                        )}
                        <th className="text-right">{isAbono ? 'Abono' : 'Total'}</th>
                        {isAbono && (
                            <th className="text-right">Balance</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {recibo?.servicios?.map((servicio) => {
                        return (
                            <tr key={servicio.id}>
                                <td>
                                    <div className="text-uppercase">{servicio.nombre}</div>
                                </td>
                                <td className="text-right">
                                    {formatCurrency(servicio.monto)}
                                </td>
                                {isAbono && (
                                    <td className="text-right">{formatCurrency(servicio.pivot?.pendiente)}</td>
                                )}
                                {/* <td className="text-right"></td> */}
                                <td className="text-right">
                                    {formatCurrency(parseFloat(servicio.pivot?.abonado || servicio.monto))}
                                </td>
                                {isAbono && (
                                    <td className="text-right">{formatCurrency(parseFloat(servicio.monto - servicio.abonado))}</td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
        <div className="summary mt-sm-3">
            <div className="row justify-content-between justify-content-md-end">
                <div
                    className="col col-md-3 text-sm-right"
                    style={{
                        maxWidth: "10rem",
                        lineHeight: "14px",
                        fontWeight: "600",
                    }}
                >
                    TOTAL PAGADO
                </div>
                <div
                    className="col-2 text-right"
                    style={{
                        maxWidth: "8rem",
                        lineHeight: "14px",
                        fontWeight: "600",
                    }}
                >
                    {formatCurrency(recibo.total)}
                </div>
            </div>
        </div>

        <hr className="d-block d-sm-none mt-0 mb-4" />

        {recibo.notas && (
            <>
                <p style={{ fontWeight: "600" }}>
                    Notas:
                </p>
                <p
                    className="col-12 col-md-8 pl-0 mb-3"
                    style={{
                        whiteSpace: "pre-line",
                        lineHeight: "normal",
                    }}
                >
                    {recibo.notas}
                </p>
            </>
        )}

        {recibo.plantilla?.pie_pagina && recibo.tipo_recibo === "ingreso" && (
            <div
                className="col-12 pt-5 text-center d-flex justify-content-center"
                style={{
                    fontWeight: "600",
                    whiteSpace: "pre-line",
                    lineHeight: "normal",
                }}
            >
                <p className="text-center pie-pagina">
                    {recibo.plantilla?.pie_pagina}
                </p>
            </div>
        )}
        <div className="m-t-50">
            <table className="table">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Banco</th>
                        <th>Tarjeta</th>
                        <th>Terminal</th>
                        <th>Aprob.</th>
                        <th className="text-right">Monto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-uppercase">{reciboIngreso?.tipo_pago}</td>
                        <td>{detallePago.banco || '--'}</td>
                        <td className="text-uppercase">{detallePago.tipo || '--'}</td>
                        <td>{detallePago.terminal_tarjeta || '--'}</td>
                        <td>{detallePago.num_aprobacion || '--'}</td>
                        <td className="text-right">{formatCurrency(recibo.total)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="m-t-50">
            <h3
                className="col-sm-3 text-center fw-600"
                style={{ borderTop: "1px solid #000", margin: '55px auto 25px' }}
            >
                <div>Sello y Firma</div>
                <small>({usuario.nombre} {usuario.apellido})</small>
            </h3>
            <div className="col-sm-2 d-none d-sm-block"></div>
        </div>
    </div>;
}
