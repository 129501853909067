import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { auth } from "../../../utils/auth";
import { request } from "../../../utils/request";
import { contador, diferenciaEnSegundos } from "../../../utils/helpers";

const Solicitud = () => {
    const [solicitud, setSolicitud] = useState({});
    const { id } = useParams();

    const getSolicitud = async () => {
        request("solicitudes/" + id).then((res) => {
            setSolicitud(res.data)
            window.print();
		});
    };

    useEffect(() => {
        getSolicitud();
    }, []);

    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
        (suc) => suc.id === user.sucursalActual
    )[0];

    return (
        <div>
            <div className='row'>
                <h2 className='col-md-12 text-center m-b-5'>
                    Solicitud {solicitud.numero}
                </h2>
                <div className='col-md-12 text-center'>
                    {sucursal.nombre} - {sucursal.direccion} •{" "}
                    {new Date().toLocaleString()}
                </div>
                <div className='col-md-12 text-center p-b-10'></div>
            </div>
            <table className='table'>
                <tbody>
                    <tr>
                        <td>Número:</td>
                        <td>{solicitud.numero}</td>
                    </tr>
                    <tr>
                        <td style={{ width: "150px" }}>Estado</td>
                        <td>
                            {solicitud.estado &&
                                solicitud.estado.toUpperCase()}
                        </td>
                    </tr>
                    <tr>
                        <td>Fecha:</td>
                        <td>
                            {dayjs(solicitud.fecha).format("YYYY-MM-DD")}
                        </td>
                    </tr>
                    {solicitud.ruta && (
                        <tr>
                            <td>Zona:</td>
                            <td>{solicitud.ruta.nombre}</td>
                        </tr>
                    )}
                    {solicitud.contacto && (
                        <tr>
                            <td>Contacto:</td>
                            <td>{solicitud.contacto.nombre}</td>
                        </tr>
                    )}
                    <tr>
                        <td>Detalles:</td>
                        <td>{solicitud.descripcion}</td>
                    </tr>
                    {solicitud.empleado && (
                        <tr>
                            <td>Empleado:</td>
                            <td>
                                {solicitud.empleado}
                            </td>
                        </tr>
                    )}

                    {solicitud.telefono && (
                        <tr>
                            <td>Teléfono:</td>
                            <td>
                                {solicitud.telefono}
                            </td>
                        </tr>
                    )}

                    {solicitud.flota && (
                        <tr>
                            <td>Flota:</td>
                            <td>
                                {solicitud.flota}
                            </td>
                        </tr>
                    )}

                    {solicitud.vehiculo && (
                        <tr>
                            <td>Vehículo:</td>
                            <td>
                                <div>{solicitud.vehiculo}</div>
                            </td>
                        </tr>
                    )}
                    {solicitud.averia && (
                        <tr>
                            <td>Avería:</td>
                            <td>
                                <div>{solicitud.averia}</div>
                            </td>
                        </tr>
                    )}
                    {solicitud.establecimiento && (
                        <tr>
                            <td>Establecimiento:</td>
                            <td>
                                <div>{solicitud.establecimiento}</div>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>Tiempo Pendiente:</td>
                        <td>
                            <div>{contador(
                                solicitud.t_pendiente ||
                                diferenciaEnSegundos(solicitud.fechaCreacion)
                            )}</div>
                        </td>
                    </tr>
                    {solicitud.t_progreso && (
                        <tr>
                            <td>Tiempo En Progreso:</td>
                            <td>
                                <div>{contador(solicitud.t_progreso)}</div>
                            </td>
                        </tr>
                    )}
                    {solicitud.estado === 'completada' && (
                        <tr>
                            <td>Tiempo para Completar:</td>
                            <td>
                                <div>{contador(solicitud.t_pendiente + solicitud.t_progreso)}</div>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>Creador:</td>
                        <td>
                            <div>{solicitud.creador}</div>
                        </td>
                    </tr>
                    {solicitud.ultimaActividad && (
                        <tr>
                            <td>Última Actividad:</td>
                            <td>
                                <div>{solicitud.ultimaActividad}</div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default Solicitud;