import React from "react";
import Select from "react-select";
import { bancos } from "../../../utils/helpers";

const FormDatosBancarios = ({sucursal, errors}) => {
    return <div className='row'>
        <div className='col-md-12'>
            <div className='form-group'>
                <label className='label form-control-label'>
                    Institución Financiera:
                </label>
                <Select
                    isClearable
                    options={bancos}
                    name="institucion_financiera"
                    defaultValue={sucursal.institucion_financiera ? {label: sucursal.institucion_financiera, value: sucursal.institucion_financiera} : null}
                />
                {errors.institucion_financiera && (
                    <small className='help-blockParams form-Text'>
                        {errors.institucion_financiera[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='col-md-12'>
            <div className='form-group'>
                <label className='control-label'>Tipo de Cuenta:</label>
                <Select
                    isClearable
                    options={[
                        { label: 'Ahorros', value: 'Ahorros' },
                        { label: 'Corriente', value: 'Corriente' },
                        { label: 'Ahorros-Corriente', value: 'Ahorros-Corriente' },
                        {label: 'Otro', value: 'Otro' },
                    ]}
                    name="tipo_cuenta"
                    defaultValue={sucursal.tipo_cuenta ? {label: sucursal.tipo_cuenta, value: sucursal.tipo_cuenta} : null}
                />
                {errors.tipo_cuenta && (
                    <small className='help-blockParams form-Text'>
                        {errors.tipo_cuenta[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='col-md-12'>
            <div className='form-group'>
                <label className='control-label'>Cuenta Bancaria:</label>
                <input
                    type='text'
                    className='text-input form-control'
                    id='cuenta_bancaria'
                    name='cuenta_bancaria'
                    defaultValue={sucursal.cuenta_bancaria}
                />
                {errors.cuenta_bancaria && (
                    <small className='help-blockParams form-Text'>
                        {errors.cuenta_bancaria[0]}
                    </small>
                )}
            </div>
        </div>
    </div>;
};

export default FormDatosBancarios;