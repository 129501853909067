import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { apiRequest } from "../../../hooks/apiRequest";
import useFilters from "../../../hooks/useFilters";
import { getLink, toast } from "../../../utils/helpers";
import { request } from "../../../utils/request";
import DesktopTable from "../../general/CRUDLayout/components/DesktopTable";
// import IndexPageLayout from "../../general/CRUDLayout/IndexPageLayout";
import Dropdown from "../../general/Dropdown";
import FiltersContainer from "../../general/FiltersContainer";
import Loader from "../../general/Loader";
import Modal from "../../general/Modal";
import PageHeader from "../../general/PageHeader";
import Pagination from "../../general/Pagination";

const RazonesNotasCredito = () => {
	const TABLE_COLUMNS = [
		{
			title: "Detalle",
			tdContent: (row) => row.detalle,
		},
		{
			title: "Creación",
			tdContent: (row) => dayjs(row.created_at).format("DD/MM/YYYY"),
		},
		{
			title: "",
			tdContent: (row) => (
				<Dropdown>
					<button
						type="button"
						className="dropdown-item"
						title="Impresión normal"
						onClick={() => setEditRazon(row)}
					>
						Editar
					</button>
					<button
						type="button"
						className="dropdown-item"
						title="Impresión normal"
						onClick={() => openDeleteModal(row)}
					>
						Eliminar
					</button>
				</Dropdown>
			),
			tdProps: { width: "165px" },
		},
	];

	const formRef = useRef();
	const { handleFilterChange, filtersQuery } = useFilters({
		buscar: "",
		desde: null,
		hasta: dayjs().format("YYYY-MM-DD"),
	});

	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);

	// const handleFormInputChange = (input) => {};
	const [form, setForm] = useState({ detalle: "", tiene_devolucion: "Si", id: null });
	const [formErrors, setFormErrors] = useState({});

	const openCreateModal = () => {
		setShowCreateModal(true);
	};

	const submitForm = () => {
		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const getData = async (url) => {
		setIsLoading(true);
		await request(url).then((data) => {
			setData(data);
			setIsLoading(false);
		});
	};

	const createRazon = async (e) => {
		e.preventDefault();

		const data = new FormData(e.target);

		const response = await apiRequest(data, `razones`, "POST");

		if (response.success) {
			toast("Razón creada con éxito");
			setShowCreateModal(false);
			setForm({ detalle: "", tiene_devolucion: "Si", id: null });
			getData("razones");
			return;
		} else if (response.code === 422) {
			setFormErrors(response.data.message);
			return;
		}

		toast("Error creando razón", "error");
	};

	const editRazon = async (e) => {
		e.preventDefault();

		const data = new FormData(e.target);

		const response = await apiRequest(data, `razones/${form.id}`, "POST");

		if (response.success) {
			toast("Razón actualizada con éxito");
			setShowCreateModal(false);
			setForm({ detalle: "", tiene_devolucion: "Si", id: null });
			getData("razones");
			return;
		} else if (response.code === 422) {
			setFormErrors(response.data.message);
			return;
		}

		toast("Error actualizando razón", "error");
	};

	const deleteRazon = async () => {
		const response = await apiRequest(null, `razones/${form.id}`, "DELETE");

		if (response.success) {
			toast("Razón eliminada con éxito");
			setShowDeleteModal(false);
			getData("razones");
			return;
		} else if (response.code === 422) {
			setFormErrors(response.data.message);
			return;
		}

		toast("Error actualizando razón", "error");
	};

	const openDeleteModal = (razon) => {
		setForm({ id: razon.id, detalle: razon.detalle });
		setShowDeleteModal(true);
	};

	const setEditRazon = (razon) => {
		setForm({
			detalle: razon.detalle,
			tiene_devolucion: razon.tiene_devolucion,
			id: razon.id,
		});
		openCreateModal();
	};

	const onFilterChange = () => {};
	const filters = {};

	useEffect(() => {
		getData("razones" + filtersQuery);
	}, [filtersQuery]);

	return (
		<>
			<PageHeader
				title="Razones de Notas de Crédito"
				hasButton={false}
				goBackText="Volver a configuración"
				goBackLink={getLink("/configuracion/")}
			>
				<HeaderChildren openCreateModal={openCreateModal} />
			</PageHeader>
			<FiltersContainer>
				<div className="col">
					<label className="orden-filter-label">Buscar</label>
					<input
						onChange={(e) =>
							handleFilterChange("buscar", e.target.value)
						}
						type="search"
						placeholder="Buscar..."
						className="form-control"
						name="buscar"
					/>
				</div>

				<div className="col-12 col-md-6 col-lg-2 pr-md-0">
					<label
						htmlFor="desde"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Desde
					</label>
					<input
						type="date"
						name="desde"
						id="desde"
						defaultValue={null}
						className="form-control"
						onChange={(e) =>
							handleFilterChange("desde", e.target.value)
						}
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2">
					<label
						htmlFor="hasta"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Hasta
					</label>
					<input
						type="date"
						name="hasta"
						id="hasta"
						defaultValue={dayjs().format("YYYY-MM-DD")}
						className="form-control"
						onChange={(e) =>
							handleFilterChange("hasta", e.target.value)
						}
					/>
				</div>
			</FiltersContainer>
			<div
				className="row px-3"
				style={{ overflowX: "auto", paddingBottom: "5rem" }}
			>
				{isLoading && <Loader />}
				{!isLoading && data.data?.length ? (
					<div
						className="table-data table-responsive table-row__desktop"
						style={{ overflowX: "visible" }}
					>
						<DesktopTable
							data={data.data}
							columns={TABLE_COLUMNS}
						/>
					</div>
				) : (
					<div>
						{!isLoading && <div>No hay ningún Record creado.</div>}
					</div>
				)}
				{data.links && (
					<Pagination
						links={data.links}
						meta={data}
						getData={getData}
					/>
				)}
			</div>

			{/* CREATE FORM */}
			<Modal
				title="Crear nueva razón de notas de crédito"
				show={showCreateModal}
				callback={() => submitForm()}
				acceptText={!form.id ? "Crear" : "Actualizar"}
				toggle={() => {
					setForm({ detalle: "", tiene_devolucion: "Si", id: null });
					setShowCreateModal((state) => !state)
				}}
			>
				<form
					method={"post"}
					onSubmit={!form.id ? createRazon : editRazon}
					ref={formRef}
				>
					<input type="hidden" name="id" defaultValue={form.id} />
					<input
						type="hidden"
						name="_method"
						value={!form.id ? "post" : "put"}
					/>
					<div className="row form-group">
						<div className="col col-md-4">
							<label className="label form-control-label">
								Detalle:
							</label>
						</div>
						<div className="col col-md-8">
							<textarea
								type="text"
								name="detalle"
								defaultValue={form.detalle}
								className="form-control"
								readOnly={form.is_default}
							/>
							{formErrors.detalle && (
								<small className="help-blockParams form-Text">
									{formErrors.detalle[0]}
								</small>
							)}
						</div>
					</div>
					<div className="row form-group">
						<div className="col col-md-4 pr-0">
							<label className="label form-control-label">
								Tiene devolución:
							</label>
						</div>
						<div className="col col-md-8">
							<div className="form-inline">
								<div className="radio">
									<label className="mr-3">
										<input
											className="mr-1"
											type="radio"
											name="tiene_devolucion"
											id="optionsRadiosActivo"
											value="Si"
											checked={form.tiene_devolucion == "Si"}
											onChange={() => {
												setForm(state => ({...state, tiene_devolucion: "Si"}))
											}}
										/>
										Si
									</label>
								</div>
								<div className="radio">
									<label>
										<input
											className="mr-1"
											type="radio"
											name="tiene_devolucion"
											id="optionsRadiosActivo"
											value="No"
											checked={form.tiene_devolucion == "No"}
											onChange={() => {
												setForm(state => ({...state, tiene_devolucion: "No"}))
											}}
										/>
										No
									</label>
								</div>
							</div>
						</div>
					</div>
				</form>
			</Modal>
			<Modal
				title="Eliminar razón"
				show={showDeleteModal}
				callback={() => deleteRazon()}
				acceptText="Crear"
				toggle={() => setShowDeleteModal((state) => !state)}
				size="419px"
			>
				¿Seguro que desea eliminar esta razón? <br /> (Esta acción no se
				puede deshacer)
			</Modal>
		</>
	);
};

const HeaderChildren = ({ openCreateModal }) => {
	return (
		<button
			type="button"
			onClick={openCreateModal}
			className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15"
		>
			<i className="zmdi zmdi-plus" /> Añadir Razón
		</button>
	);
};

export default RazonesNotasCredito;
