import React, { useState } from "react";
import Form from "./Form";
import { apiRequest } from "../../hooks/apiRequest";

const Create = ({getCuentas, toggle, padre}) => {
    const [state, setState] = useState({
        errors: {},
        cuenta: {}
    });

    const createCuenta = async event => {
        event.preventDefault();

        const data = new FormData(event.target);

        const response = await apiRequest(data, 'cuentas-contables');

        if (response.success) {
            getCuentas();
            toggle();
        }
        if (response.code === 422) {
            setState({...state, errors: response.data.message });
        }
    };

    const { errors, cuenta } = state;

    return (
        <form method='post' onSubmit={createCuenta}>
            <div className='popup__header'>Nueva Cuenta Contable</div>
            <div className='popup__body'>
                {padre && (<>
                    <p className="p-b-20">Se creará como una subcuenta de <strong>{padre.nombre}</strong></p>
                    <input type="hidden" name="padre_id" value={padre.id} />
                </>)}
                <Form cuenta={cuenta} errors={errors} />
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                <button type='submit' className='btn btn-primary'>
                    Crear
                </button>
            </div>
        </form>
    );
}

export default Create;
