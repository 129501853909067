/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../../utils/auth";
import { formatCurrency } from "../../../../utils/helpers";
import { request } from "../../../../utils/request";

const ListadoRecibos = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [recibos, setRecibos] = useState([]);
	const [summaries, setSummaries] = useState({
		efectivo: 0,
		tarjeta: 0,
		transferencia: 0,
		cheque: 0,
		otro: 0,
		canceladas: 0,
		pendientesDePago: 0,
		pagadas: 0,
		// totalVendido: 0,
	});

	const getRecibos = () => {
		request(
			"academico/recibos" +
				window.location.search +
				"&paginate=100000"
		).then((res) => {
			setRecibos(res.data);
            setTimeout(() => {
				window.print();
			}, 500);
		});
	};

	const calculateSummaries = useCallback(() => {
		recibos.forEach((recibo) => {
			if (recibo.estado === "cancelado") {
				setSummaries((prevState) => ({
					...prevState,
					canceladas: prevState.canceladas + recibo.total,
				}));
			} else {
				switch (recibo.reciboIngreso.tipo_pago) {
					case "efectivo":
						setSummaries((prevState) => ({
							...prevState,
							efectivo: prevState.efectivo + recibo.total,
						}));
						break;
					case "tarjeta":
						setSummaries((prevState) => ({
							...prevState,
							tarjeta: prevState.tarjeta + recibo.total,
						}));
						break;
					case "transferencia":
						setSummaries((prevState) => ({
							...prevState,
							transferencia:
								prevState.transferencia + recibo.total,
						}));
						break;
					case "cheque":
						setSummaries((prevState) => ({
							...prevState,
							cheque: prevState.cheque + recibo.total,
						}));
						break;
					case "otro":
						setSummaries((prevState) => ({
							...prevState,
							otro: prevState.otro + recibo.total,
						}));
						break;
					case "deposito":
						setSummaries((prevState) => ({
							...prevState,
							otro: prevState.otro + recibo.total,
						}));
						break;
					default:
						break;
				}
				setSummaries((prevState) => ({
					...prevState,
					pagadas: prevState.pagadas + recibo.total,
				}));
				setSummaries((prevState) => ({
					...prevState,
					pendientesDePago:
						prevState.pendientesDePago + recibo.balance_total,
				}));
			}
		});
	}, [recibos]);

	useEffect(() => {
		getRecibos();
	}, []);

	useEffect(() => {
		if (recibos.length <= 0) return;
		calculateSummaries();
		const timeout = setTimeout(() => {
			window.print();
		}, 700);

		return () => {
			clearTimeout(timeout);
		};
	}, [recibos]);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotal = recibos.reduce((acc, recibo) => {
		return (
			acc +
			(recibo.estado === "cancelado" ? 0 : parseFloat(recibo.total))
		);
	}, 0);
	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Recibos de Ingreso Académicos
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL RECIBOS DE INGRESO: {recibos.length}
					</div>

					<div className="float-right">
						<h3>ABONO TOTAL: {formatCurrency(valorTotal, "$")}</h3>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-6">
					<h5 className="fw-600 w-100 text-center mb-2">
						FORMAS DE PAGO
					</h5>
					<table className="table m-b-40">
						<thead>
							<tr>
								<th>Efectivo</th>
								<th>Tarjeta</th>
								<th>Transferencia</th>
								<th>Cheque</th>
								<th width="90px">Otro</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{formatCurrency(summaries.efectivo, "$")}
								</td>
								<td>
									{formatCurrency(summaries.tarjeta, "$")}
								</td>
								<td>
									{formatCurrency(
										summaries.transferencia,
										"$"
									)}
								</td>
								<td>{formatCurrency(summaries.cheque, "$")}</td>
								<td>{formatCurrency(summaries.otro, "$")}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="col-6">
					<h5 className="fw-600 w-100 text-center mb-2">
						DETALLES DE RECIBOS DE INGRESO
					</h5>
					<table className="table m-b-40">
						<thead>
							<tr>
								<th>Canceladas</th>
								<th>Total Facturado</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{formatCurrency(summaries.canceladas)}
								</td>
								<td>
									{formatCurrency(
                                        valorTotal
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<table className="table m-b-40">
				<thead>
					<tr>
						<th>Número</th>
						<th width="300px">Concepto</th>
						<th>Estudiante</th>
						<th>Creación</th>
						<th>Monto</th>
						<th>Tipo de pago</th>
						<th>Estado</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{recibos.map((recibo) => {
						return (
							<tr key={recibo.id}>
								<td>{recibo.numeracion}</td>
								<td>
									{recibo.concepto}
								</td>
								<td>{recibo.estudiante?.nombreCompleto}</td>
								<td>
									{dayjs(recibo.created_at).format(
										"DD/MM/YYYY hh:mm"
									)}
								</td>
								<td>
									{formatCurrency(recibo.total)}
								</td>
								<td className="text-capitalize">
									{recibo.reciboIngreso.tipo_pago}
								</td>
								<td className="text-capitalize">
									{recibo.estado}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL RECIBOS DE INGRESO: {recibos.length}
					</div>

					<div className="float-right">
						<h3>ABONO TOTAL: {formatCurrency(valorTotal, "$")}</h3>
					</div>
				</div>
			</div>
		</>
	);
};

export default ListadoRecibos;
