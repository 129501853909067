import React, { useState } from "react";

const Form = ({ tipo, errors }) => {
    const [state, setState] = useState({
        sueldo: tipo.sueldoFijo || 0,
        descuento: tipo.descuento || 0,
    });

    function onChangeSueldo(value) {
        setState({
            ...state,
            sueldo: value,
            descuento: value * 0.0591
        });
    }

    function onChangeInput(nombre, value) {
        setState({
            ...state,
            [nombre]: value
        });
    }

    const {sueldo, descuento} = state;

    return (
        <div>
            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>Nombre:</label>
                </div>
                <div className='col col-md-7'>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='nombre'
                        name='nombre'
                        defaultValue={tipo.nombre}
                    />
                    {errors.nombre && (
                        <small className='help-blockParams form-Text'>
                            {errors.nombre[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>Tipo:</label>
                </div>
                <div className='col-md-7'>
                    <select
                        className='text-input form-control'
                        id='tipo_nomina'
                        name='tipo_nomina'
                        defaultValue={tipo.tipoNomina}>
                        <option>Seleccionar</option>
                        <option value='fijo'>Sueldo Fijo</option>
                        <option value='produccion'>Producción</option>
                        <option value='fijo-produccion'>Sueldo Fijo + Producción</option>
                    </select>
                    {errors.tipo_nomina && (
                        <small className='help-blockParams form-Text'>
                            {errors.tipo_nomina[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>Período Nómina:</label>
                </div>
                <div className='col-md-7'>
                    <select
                        className='text-input form-control'
                        id='intervalo_nomina'
                        name='intervalo_nomina'
                        defaultValue={tipo.intervaloNomina}>
                        <option>Seleccionar</option>
                        <option value='semanal'>Semanal</option>
                        <option value='quincenal'>Quincenal</option>
                        <option value='mensual'>Mensual</option>
                    </select>
                    {errors.intervalo_nomina && (
                        <small className='help-blockParams form-Text'>
                            {errors.intervalo_nomina[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>
                        Sueldo Fijo Mensual:
                    </label>
                </div>
                <div className='col-md-7'>
                    <input
                        type='number'
                        step='0.01'
                        className='text-input form-control'
                        id='sueldo_fijo'
                        name='sueldo_fijo'
                        onChange={e => onChangeSueldo(e.target.value)}
                        value={sueldo}
                    />
                    {errors.sueldoFijo && (
                        <small className='help-blockParams form-Text'>
                            {errors.sueldoFijo[0]}
                        </small>
                    )}
                </div>
            </div>
            <row className="row form-group">
                <div className="col-md-6 d-flex align-center">
                    <div style={{width: '140px'}}>AFP 2.87%</div> <input type="number" className="form-control" disabled value={sueldo * 0.0287}/>
                </div>
                <div className="col-md-6 d-flex align-center">
                    <div style={{width: '140px'}}>TSS 3.04%</div> <input type="number" className="form-control" disabled value={sueldo * 0.0304}/>
                </div>
            </row>
            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>
                        TSS + AFP Mensual:
                    </label>
                </div>
                <div className='col-md-7'>
                    <input
                        type='number'
                        className='text-input form-control'
                        id='descuento'
                        name='descuento'
                        step='0.01'
                        onChange={e => onChangeInput('descuento', e.target.value)}
                        value={descuento}
                    />
                    {errors.descuento && (
                        <small className='help-blockParams form-Text'>
                            {errors.descuento[0]}
                        </small>
                    )}
                </div>
            </div>
            <div className='row form-group'>
                <div className='col col-md-5'>
                    <label className='label form-control-label'>
                        Opera Vehículos:
                    </label>
                </div>
                    <div className='col col-md-7'>
                        <input
                            type='checkbox'
                            name='opera_vehiculo'
                            id="opera_vehiculo"
                            defaultChecked={tipo.operaVehiculo === 1}
                            value='1'
                        />
                    </div>
            </div>
        </div>
    );
};

export default Form;
