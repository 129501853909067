import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import dayjs from 'dayjs';

import { formatCurrency } from "../../utils/helpers";
import EntradaItem from './EntradaItem';
import { apiRequest } from '../../hooks/apiRequest';

const Form = ({ entrada, date, items, isCreate, errors }) => {

    const [state, setState] = useState({
        contacto_id: false,
        entradaItems: items,
        fecha: date,
        estado: entrada.estado,
        credito: entrada.credito,
        debito: entrada.debito,
        referencia: entrada.referencia,
    });

    const [cuentasContables, setCuentasContables] = useState([]);
    const [contactos, setContactos] = useState([]);

    const onChangeDate = (e) => {
        setState({
            ...state,
            fecha: e.target.value,
        });
    };

    const removeMovimiento = (item) => {
        const entradaItems = state.entradaItems.filter((i) => i.id !== item);
        setState({
            ...state,
            entradaItems,
            credito: entradaItems.reduce((accum, item) => {
                return accum + item.credito;
            }, 0),
            debito: entradaItems.reduce((accum, item) => {
                return accum + item.debito;
            }, 0),
        });
    };

    const addItem = () => {
        setState({
            ...state,
            entradaItems: [...state.entradaItems, {id: Date.now(), credito: 0, debito: 0}],
        });
    };

    const updateCredito = async (id, value) => {
        await setState({
            ...state,
            entradaItems: entradaItems.map((item) => {
                if (item.id === id) {
                    item.credito = parseFloat(value || 0);
                }
                return item;
            }),
        });

        setState({
            ...state,
            credito: entradaItems.reduce((accum, item) => {
                return accum + item.credito;
            }, 0),
        });
    };

    const updateDebito = async (id, value) => {
        await setState({
            ...state,
            entradaItems: entradaItems.map((item) => {
                if (item.id === id) {
                    item.debito = parseFloat(value || 0);
                }
                return item;
            }),
        });

        setState({
            ...state,
            debito: entradaItems.reduce((accum, item) => {
                return accum + item.debito;
            }, 0),
        });
    };

    const getCuentasContables = async () => {
        const table = await apiRequest(null, 'cuentas-contables', 'get');
        setCuentasContables(table.data);
    };

    const getContactos = async () => {
        const table = await apiRequest(null, 'contactos', 'get');
        setContactos(table.data);
    };

    useEffect(() => {
        getCuentasContables();
        getContactos();
    }, []);

    const {entradaItems, fecha, referencia, credito, debito, estado} = state;

    return <>
        <div className="col-md-5">
            <div className="row">
                <div className="col-md-6">
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Fecha:
                        </label>
                        <input
                            type='date'
                            className='text-input form-control'
                            id='fecha'
                            name='fecha'
                            value={fecha}
                            onChange={onChangeDate}
                        />
                        {errors.fecha && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label form-control-label">Estado:</label>
                        <Select
                            name="estado"
                            defaultValue={{label: estado, value: estado}}
                            options={[
                                {label: 'Activa', value: 'Activa'},
                                {label: 'Verificada', value: 'Verificada'},
                                {label: 'Cancelada', value: 'Cancelada'},
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className='form-group'>
                <label className='label form-control-label'>
                    Referencia:
                </label>

                <input
                    type='text'
                    className='text-input form-control'
                    id='referencia'
                    name='referencia'
                    defaultValue={referencia}
                />
                {errors.referencia && (
                    <small className='help-blockParams form-Text'>
                        {errors.referencia[0]}
                    </small>
                )}
            </div>
        </div>
        <div className="col">
            <div className='form-group'>
                <label className='label form-control-label'>
                    Observaciones:
                </label>
                <textarea name="observaciones" className='form-control' rows="4">
                    {entrada.observaciones}
                </textarea>

                {errors.observaciones && (
                    <small className='help-blockParams form-Text'>
                        {errors.observaciones[0]}
                    </small>
                )}
            </div>
        </div>
        <div className='col-md-12'>
            <table className='table'>
                <thead>
                    <tr>
                        <th width='25%'>Contacto</th>
                        <th width='25%'>Cuenta Contable</th>
                        <th width='20%'>Descripción</th>
                        <th>Débito</th>
                        <th>Crédito</th>
                        <th width='25px'></th>
                    </tr>
                </thead>
                <tbody>
                    {entradaItems.map((item, index) => (
                        <EntradaItem
                            key={item.id}
                            item={item}
                            index={index}
                            cuentasContables={cuentasContables}
                            contactos={contactos}
                            removeMovimiento={removeMovimiento}
                            updateCredito={updateCredito}
                            updateDebito={updateDebito}
                            errors={errors}
                            isCreate={isCreate}
                        />
                    ))}
                    <tr>
                        <td colSpan='6' className='text-right'>
                            <button
                                type='button'
                                onClick={addItem}
                                className='btn'>
                                <i className='fas fa-plus' />{" "}
                                Agregar Linea
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan='3'></td>
                        <td>
                            {formatCurrency(debito)}
                            <input
                                type='hidden'
                                name='debito'
                                value={debito || 0}
                            />
                        </td>
                        <td>
                            <input
                                type='hidden'
                                name='credito'
                                value={credito || 0}
                            />
                            {formatCurrency(credito)}
                        </td>
                        <td></td>
                    </tr>
                    {errors.debito && (
                        <tr>
                            <td className="text-right" colSpan={6}>
                                <small className='help-blockParams form-Text'>
                                    {errors.debito[0]}
                                </small>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <hr />
        </div>
    </>
}

export default Form;
