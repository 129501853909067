import React, { Component } from "react";
import { withRequest } from "../../../../utils/request";
import { auth } from "../../../../utils/auth";
import { apiUrl } from "../../../../utils/apiUrl";
import Loader from "../../../general/Loader";
import { toast } from "../../../../utils/helpers";
import Form from "./Form";

class Edit extends Component {
    state = {
        grupos: [],
        miembro: {},
        servidores: [],
        gruposIds: [],
        errors: {},
        saving: false
    };

    componentDidMount() {
        this.getTable("gi-grupos", "?estado=activos", 'grupos');
        this.getTable("gi-servidores", "", "servidores");

        if (this.props.grupo) {
            this.setState({ grupoId: this.props.grupo.id });
        }
    }

    editMiembro = async event => {
        event.preventDefault();

        this.setState({
            saving: true
        });

        const token = auth.getToken();
        const data = new FormData(event.target);

        const url = `gi-grupos/${this.props.grupo.id}/miembros/${this.props.miembro.id}`;

        const res = await fetch(apiUrl(url), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            if (this.props.getAusencias) {
                this.props.getAusencias();
            }
            document.getElementById("ausenciasform").reset();
            this.props.toggle();
            this.props.getMiembros();
            toast("Miembro creado exitosamente.");
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }

        this.setState({
            saving: false
        });
    };

    getTable = async (url = null, params = "", name = null) => {
        name = name ? name : url;
        const table = await this.props.request(url + params);

        if (table.data && table.data.length > 0) {
            this.setState({
                [name]: table.data
            });
        }
    };

    onChangeGrupo = values => {
        if (values) {
            this.setState({
                gruposIds: values.map((i) => i.value)
            });
        } else {
            this.setState({
                gruposIds: []
            });
        }
    };

    deleteMiembro = async (event, miembro) => {
        event.preventDefault();

        const token = auth.getToken();
        const url = `gi-grupos/${this.props.grupo.id}/miembros/${miembro}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.getMiembros();
            this.props.toggle();
            toast("Miembro borrado.");
        } else {
            toast(response.data, "error");
        }
    };

    render() {
        const { grupo, miembro } = this.props;
        const { servidores, errors } = this.state;
        return (
            <form
                id='ausenciasform'
                method='post'
                onSubmit={this.editMiembro}>
                <input type='hidden' name='_method' value='patch' />
                <input type='hidden' name='grupo_id' value={grupo.id} />
                <div className='popup__header'>Agregar Miembro</div>
                <div className='popup__body'>
                    <Form miembro={miembro} servidores={servidores} errors={errors} />
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        className='btn float-left'
                        onClick={(e) => this.deleteMiembro(e, miembro.id)}>
                        <i className='fas fa-trash-alt'></i>
                    </button>

                    <button
                        type='button'
                        onClick={this.props.toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {this.state.saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Actualizar
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

export default withRequest(Edit);
