import React, { Component } from "react";
import queryString from "query-string";

import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { formatCurrency } from "../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../general/Loader";

export default class ReporteDescuentosEmpleados extends Component {
    state = {
        descuentos: false,
    };
    componentDidMount() {
        this.getReporte();
    }
    getReporte = async () => {
        const token = auth.getToken();

        const res = await fetch(apiUrl(`reportes` + window.location.search), {
            method: "get",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.setState({
                descuentos: response.data,
            });
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    render() {
        const { descuentos } = this.state;

        if (!descuentos) {
            return <Loader />;
        }

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        const totalDescuentos = descuentos.reduce((acc, descuento)=> {
            return acc + descuento.monto
        }, 0);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Descuentos Empleados
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(query.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(query.hasta).format("DD/MM/YYYY")}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>Descuentos: {descuentos.length}</div>

                        <div className='float-right text-right'>
                            Total: {formatCurrency(totalDescuentos)}
                        </div>
                    </div>
                </div>

                <div>
                    <table className='table'>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Empleado</th>
                            <th>Cargo</th>
                            <th>Descripción</th>
                            <th className='text-right'>Monto</th>
                        </tr>
                    </thead>
                        <tbody>
                        {descuentos.map((descuento, index) => {
                            return <tr>
                                <td>{descuento.fecha}</td>
                                <td>{descuento.empleado}</td>
                                <td>{descuento.cargo}</td>
                                <td>{descuento.descripcion}</td>
                                <td className='text-right'>{descuento.monto}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'></div>

                    <div className='float-right text-right'>
                        Total: {formatCurrency(totalDescuentos)}
                    </div>
                </div>
            </div>
        );
    }
}
