/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../utils/auth";
import { formatCurrency, formatNumber } from "../../../utils/helpers";
import { request } from "../../../utils/request";

const PrintFacturas = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [facturas, setFacturas] = useState([]);

	const [summaries, setSummaries] = useState({
		efectivo: 0,
		tarjeta: 0,
		transferencia: 0,
		cheque: 0,
		otro: 0,
		canceladas: 0,
		pendientesDePago: 0,
		pagadas: 0,
		// totalVendido: 0,
	});

	const getFacturas = () => {
		request("facturas" + window.location.search + "&estado=pendiente,pagada&paginate=1000000").then(
			(res) => {
				setFacturas(res.data);
			}
		);
	};

	const formatDateTime = (date) => {
		return dayjs(date).format("DD/MM/YYYY, hh:mm a");
	};

	const calculateSummaries = useCallback(() => {
		facturas.forEach((factura) => {
            setSummaries((prevState) => ({
                ...prevState,
                [factura.tipo_pago]: prevState[factura.tipo_pago] +
                    (factura.total - factura.por_cobrar),
            }));

			if (factura.estado === "pendiente") {
				setSummaries((prevState) => ({
					...prevState,
					pendientesDePago:
						prevState.pendientesDePago + factura.por_cobrar,
				}));
			} else if (factura.estado === "cancelada") {
				setSummaries((prevState) => ({
					...prevState,
					canceladas: prevState.canceladas + factura.total,
				}));
			}
			if (factura.estado === "pagada") {
				setSummaries((prevState) => ({
					...prevState,
					pagadas:
						prevState.pagadas + factura.total,
				}));
			}
		});
	}, [facturas]);

	useEffect(() => {
		getFacturas();
	}, []);

	useEffect(() => {
		if (facturas.length <= 0) return;
		calculateSummaries();
		const timeout = setTimeout(() => {
			window.print();
		}, 700);

		return () => {
			clearTimeout(timeout);
		};
	}, [facturas]);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];

    let valorTotal = 0;
    let totalCosto = 0;
    let totalCostoReal = 0;
    let totalUtilidad = 0;
    let totalNotas = 0;

    const entries = facturas.filter(f => f.estado !== 'cancelada').map(factura => {
        factura.costo = 0;
        factura.costoReal = 0;

        factura.productos.forEach(prod => {
            let cantidad = prod.cantidad;
            factura.notasCredito.forEach(nota => {
                if (nota.estado !== 'cancelada') {
                    nota.productos.forEach(p => {
                        if (p.producto_id === prod.producto_id) {
                            cantidad = prod.cantidad - p.cantidad;
                        }
                    });
                }
            });

            factura.costo += (prod.costo_total * prod.cantidad);

            if (cantidad > 0) {
                factura.costoReal += (prod.costo_total * cantidad);
            }
        });

        factura.notasCreditoTotal = factura.notasCredito.reduce((acc, nota) => {
            if (nota.estado !== 'cancelada') {
                return acc + nota.total;
            }

            return acc;
        }, 0);

        factura.monto = factura.total - factura.notasCreditoTotal;
        factura.beneficio = factura.monto > 0 ? factura.monto - factura.costoReal : 0;
        factura.porcentaje = (factura.beneficio / factura.monto) * 100;

        totalCosto += factura.costo;
        totalCostoReal += factura.costoReal;
        totalUtilidad += factura.beneficio;
        totalNotas += factura.notasCreditoTotal;
        valorTotal += factura.monto;

        return factura;
    });

	return (
		<>
			<style>{`@page {size: A4 landscape}`}</style>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Balance Facturas en Detalle
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {facturas.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
			<table className="table m-b-40">
				<thead>
					<tr>
						<th>NCF/Numero</th>
						<th>Cliente</th>
						<th>Fecha</th>
						<th className="text-right">Estado</th>
						<th className="text-right">Costo</th>
						{/* <th className="text-right">Costo Real</th> */}
						<th className="text-right">Monto</th>
						<th className="text-right">NC</th>
						<th className="text-right">Utilidad Bruta</th>
						<th className="text-right">% Utilidad</th>
					</tr>
				</thead>
				<tbody>
					{entries.map((factura) => {
						return (
							<tr key={factura.id}>
								<td>{factura.numero_comprobante}</td>
								<td>
									{factura.cliente
										? factura.cliente.nombre
										: ""}
								</td>
								<td>{factura.fecha}</td>
								<td className="text-capitalize text-right">
									{factura.estado}
								</td>
                                {/* <td className="text-right">{formatCurrency(costo)}</td> */}
                                <td className="text-right">{formatCurrency(factura.costoReal)}</td>
                                <td className="text-right">{formatCurrency(factura.monto)}</td>
                                <td className="text-right">{formatCurrency(factura.notasCreditoTotal)}</td>
								<td className="text-capitalize text-right">
									{formatCurrency(factura.beneficio)}
								</td>
								<td className="text-right">
									{formatNumber(factura.porcentaje >= 0 ? factura.porcentaje : 0)}%
								</td>
							</tr>
						);
					})}
                    <tr>
						<th></th>
						<th></th>
						<th></th>
						<th className="text-right"></th>
						{/* <th className="text-right">{formatCurrency(totalCosto)}</th> */}
						<th className="text-right">{formatCurrency(totalCostoReal)}</th>
						<th className="text-right">{formatCurrency(valorTotal)}</th>
						<th className="text-right">{formatCurrency(totalNotas)}</th>
						<th className="text-right">{formatCurrency(totalUtilidad)}</th>
						<th className="text-right">{formatNumber(valorTotal > 0 ? (totalUtilidad / valorTotal) * 100 : 0)}%</th>
					</tr>
				</tbody>
			</table>
			<div className="row">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {facturas.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrintFacturas;
