import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { apiRequest } from '../../../../hooks/apiRequest';
import { useEstudianteContext } from '../EstudianteContext';
import { toast } from '../../../../utils/helpers';

export default function TutorItem({tutor: tutorOriginal, tutores, setTutores, removeTutor, setResidencia, index: key}) {
    const {toggleModal, setEstudiante} = useEstudianteContext();

    const [tutor, setTutor] = useState(tutorOriginal || {});
    const [ncf, setNcf] = useState(tutor.ncf || false);
    const [id, setId] = useState(tutor.hasOwnProperty('nombreCompleto') ? tutor.id : false);

    const [seleccionados, setSeleccionados] = useState(tutores.map(t => t.idOriginal || t.id) || []);

    useEffect(() => {
        setSeleccionados(tutores.reduce((acc, t) => {
            acc.push(t.id);
            return acc;
        }, []));
    }, [tutores]);

    useEffect(() => {
        setTutor(tutorOriginal);
        setId(tutorOriginal.id);
    }, [tutorOriginal]);

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data
                .filter(t => !seleccionados.includes(t.id))
                .map(item => ({value: item[value], label: item[label], item}));
        }
    };

    return (
        <tr>
            <td>
                {tutor.foto && (
                    <span className='page-title-foto'>
                        <img
                            src={tutor.foto}
                            alt={tutor.nombreCompleto}
                        />
                    </span>
                )}
            </td>
            <td>
                <div className="row">
                    <SelectAsync
                        key={`${tutor.id}-tutor`}
                        className="col"
                        name={`tutores[${tutor.id}][tutor_id]`}
                        cacheOptions
                        defaultOptions
                        onChange={e => {
                            if (e) {
                                if (seleccionados.includes(e.value)) {
                                    toast('Este tutor ya fue seleccionado', 'error');
                                    return;
                                }
                                const newTutor = e.item;
                                newTutor.key = tutor.key;
                                newTutor.residencia = tutor.residencia;
                                setId(e.value);
                                setTutor(newTutor);
                                const newTutores = tutores.map(t => {
                                    if (t.key == tutor.key) {
                                        return newTutor;
                                    }
                                    return t;
                                });
                                setTutores(newTutores);
                                setEstudiante(prevState => {
                                    return {...prevState, tutores: newTutores}
                                });
                            } else {
                                setTutor({});
                            }
                        }}
                        value={tutor.nombreCompleto && {
                            label: tutor.nombreCompleto, value: tutor.id
                        }}
                        loadOptions={(e) => {
                            return getOptions("academico/padres-tutores?limit=15&buscar=" + e, "nombreCompleto");
                        }}
                    />
                    {!id && (
                    <div className="tooltip-self color-primary d-inline-block mt-2 m-r-20">
                        <i className="fas fa-plus-circle color-primary"
                            style={{
                                fontSize: "1.25rem",
                                cursor: "pointer",
                            }}
                            onClick={() => toggleModal('CreateTutor')}
                        ></i>
                        <span className="tootip-text tooltip-right">
                            Crear Padre/Tutor
                        </span>
                    </div>
                    )}
                    {id && (
                        <div
                            className="tooltip-self color-primary d-inline-block m-r-20"
                            style={{ alignSelf: "center" }}
                        >
                            <i
                                className="fas fa-edit color-primary"
                                style={{
                                    fontSize: "1.25rem",
                                    cursor: "pointer",
                                }}
                                onClick={() => toggleModal('EditTutor', false, id)}
                            ></i>
                            <span className="tootip-text tooltip-right">
                                Editar Padre/Tutor
                            </span>
                        </div>
                    )}
                    {ncf && (
                        <SelectAsync
                            key={`${tutor.id}-contacto`}
                            className="col-md-12 m-t-10"
                            name={`tutores[${tutor.id}][contacto_id]`}
                            placeholder="Seleccionar Empresa..."
                            cacheOptions
                            defaultOptions
                            defaultValue={tutor.contacto && {
                                label: tutor.contacto?.nombre, value: tutor.contacto?.id
                            }}
                            loadOptions={(e) => getOptions("contactos?limit=15&buscar="+e)}
                        />
                    )}
                </div>
            </td>
            <td>
                <Select
                    key={`${tutor.id}-parentesco`}
                    name={`tutores[${tutor.id}][parentesco]`}
                    defaultValue={tutor.parentesco && {
                        label: tutor.parentesco, value: tutor.parentesco
                    }}
                    options={[
                        {label: 'Padre', value: 'Padre'},
                        {label: 'Madre', value: 'Madre'},
                        {label: 'Padrastro', value: 'Padrastro'},
                        {label: 'Madrastra', value: 'Madrastra'},
                        {label: 'Abuelo(a)', value: 'Abuelo(a)'},
                        {label: 'Tio(a)', value: 'Tio(a)'},
                        {label: 'Familiar', value: 'Familiar'},
                        {label: 'Responsable', value: 'Responsable'},
                    ]}
                />
            </td>
            <td>
                <div className="text-center">
                    <label className='switch switch-3d switch-success mr-3'>
                        <input type='hidden' name={`tutores[${tutor.id}][residencia]`} value='no' />
                        <input
                            key={`${tutor.id}-residencia`}
                            type='checkbox'
                            className='switch-input'
                            name={`tutores[${tutor.id}][residencia]`}
                            onChange={(e) => {
                                setResidencia(tutor.key, e.target.checked, tutor)
                            }}
                            value='si'
                            checked={tutor.residencia || false}
                        />
                        <span className='switch-label' />
                        <span className='switch-handle' />
                    </label>
                </div>
            </td>
            <td>
                <div className="text-center">
                    <label className='switch switch-3d switch-success mr-3'>
                        <input type='hidden' name={`tutores[${tutor.id}][notificar]`} value='no' />
                        <input
                            key={`${tutor.id}-notificar`}
                            type='checkbox'
                            className='switch-input'
                            name={`tutores[${tutor.id}][notificar]`}
                            value='si'
                            defaultChecked={
                                tutor.id ? tutor.notificar : false
                            }
                        />
                        <span className='switch-label' />
                        <span className='switch-handle' />
                    </label>
                </div>
            </td>
            <td>
                <div className="text-center">
                    <label className='switch switch-3d switch-success mr-3'>
                        <input type='hidden' name={`tutores[${tutor.id}][ncf]`} value='no' />
                        <input
                            key={`${tutor.id}-ncf`}
                            type='checkbox'
                            className='switch-input'
                            name={`tutores[${tutor.id}][ncf]`}
                            value='si'
                            onChange={(e) => setNcf(e.target.checked)}
                            defaultChecked={
                                tutor.id ? tutor.ncf : false
                            }
                        />
                        <span className='switch-label' />
                        <span className='switch-handle' />
                    </label>
                </div>
            </td>
            <td className="text-right">
                <button
                    type="button"
                    className="btn"
                    onClick={() => removeTutor(key)}>
                    <i className='fa fa-trash-alt' />
                </button>
            </td>
        </tr>
    );
}
