import React, { Component } from "react";

import { withRequest } from "../../../utils/request";
import { getCurrentMonthName, getLink } from "../../../utils/helpers";
import Modal from "../../general/Modal";
import CreateRegistro from "../../Vehiculos/Servicios/Create";
import ServiciosPorVencer from "../ServiciosPorVencer";
import SolicitudesPendiente from "../SolicitudesPendiente";
import LicenciasPorVencer from "../LicenciasPorVencer";
import SegurosPorVencer from "../SegurosPorVencer";
import DashboardTabs, { Tab } from "../../general/DashboardTabs";
import CombustibleDisponible from "../CombustibleDisponible";
import AppChart from "../AppChart";
import AppLine from "../AppLine";
import AppCard from "../AppCard";
import GraficoBarras from "../GraficoBarras";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

class HomeDashboard extends Component {
	state = {
		ventas: 0,
		viajes: 0,
		dolares: 0,
		depositos: 0,
		topChoferes: [],
		topRutas: [],
		produccionCat: [],
		produccionDelMes: [],
		viajesDelMes: [],
		licenciasPorVencer: [],
		segurosPorVencer: [],
		serviciosPorVencer: [],
		solicitudesPendiente: [],
		gastosPorCategoria: [],
		diasDelMes: [],
		combustibleDisponible: [],
		showRegistro: false,
		vehiculoActivo: {},
		registroActivo: {},
		gastosGenerales: 0,
		gastosCombustible: 0,
		showGastoConfirmation: false,
		activeTab: "servicios",
		dataCompra: [],
	};

	componentDidMount() {
		this.getDashboard();
	}
	getDashboard = async (url = "dashboard/gi") => {
		const dashboard = await this.props.request(url);

		if (dashboard.success) {
			this.setState({
				...dashboard.data,
				anio: parseInt(dashboard.data.anio),
				mes: parseInt(dashboard.data.mes),
				loading: false,
			});
		}
	};

	activateTab = (tab) => {
		this.setState({
			activeTab: tab,
		});
	};

	isActiveClass = (tab) => {
		return tab === this.state.activeTab ? "active" : "";
	};

	nextMonth = async () => {
		const { mes, anio } = this.state;

		if (mes === 12) {
			await this.setState({
				mes: 1,
				anio: anio + 1,
			});
		} else {
			await this.setState({
				mes: mes + 1,
			});
		}

		this.filtroQuery();
	};

	prevMonth = async () => {
		const { mes, anio } = this.state;

		if (mes === 1) {
			await this.setState({
				mes: 12,
				anio: anio - 1,
			});
		} else {
			await this.setState({
				mes: mes - 1,
			});
		}

		this.filtroQuery();
	};

	expandRanking = (rank) => {
		this.setState({
			[rank]: !this.state[rank],
		});
	};

	onFiltroChange = async (e, filtro) => {
		await this.setState({
			[filtro]: e.target ? e.target.value : e,
		});
		this.filtroQuery();
	};

	filtroQuery = () => {
		const query = [`mes=${this.state.mes}`, `anio=${this.state.anio}`];
		this.getDashboard("dashboard/gi?" + query.join("&"));
	};

	toggleRegistroModal = (vehiculo, registro = {}, showGasto = false) => {
		if (vehiculo) {
			this.setState({
				showRegistro: true,
				vehiculoActivo: vehiculo,
				registroActivo: registro,
			});
		} else {
			this.setState({
				showRegistro: false,
				registroActivo: registro,
				showGastoConfirmation: showGasto,
			});
		}
	};
	toggleGastoConfrimation = () => {
		this.setState({
			showGastoConfirmation: !this.state.showGastoConfirmation,
		});
	};

	render() {
		const {
			licenciasPorVencer,
			segurosPorVencer,
			serviciosPorVencer,
			solicitudesPendiente,
			mes,
			anio,
			registroActivo,
			diasDelMes,
			combustibleDisponible,
		} = this.state;
		let months = [];
		let years = [];
		for (let month = 0; month < 12; month++) {
			months.push({
				numero: month + 1,
				nombre: getCurrentMonthName(month),
			});
		}
		for (let year = 2012; year <= anio; year++) {
			years.push(year);
		}

		const currentMonth =
			mes - 1 + "" + anio ===
			new Date().getMonth() + "" + new Date().getFullYear();

		return (
			<div className="dashboard">
				<section className="dashboard-header">
					<h1>
						Estadísticas {getCurrentMonthName(mes - 1)} {anio}
					</h1>
					<div className="dashboard-dates">
						<button
							className="btn btn-primary"
							onClick={this.prevMonth}
						>
							<i className="zmdi zmdi-chevron-left"></i>
						</button>
						<select
							name="mes"
							value={mes}
							onChange={(e) => this.onFiltroChange(e, "mes")}
							className="form-control"
						>
							{months.map((month) => (
								<option key={month.numero} value={month.numero}>
									{month.nombre}
								</option>
							))}
						</select>
						<select
							name="anio"
							value={anio}
							onChange={(e) => this.onFiltroChange(e, "anio")}
							className="form-control"
						>
							{years.map((year) => (
								<option key={year} value={year}>
									{year}
								</option>
							))}
						</select>
						<button
							className="btn btn-primary"
							onClick={this.nextMonth}
							disabled={currentMonth}
						>
							<i className="zmdi zmdi-chevron-right"></i>
						</button>
					</div>
				</section>
				<AppCard
					facturaData={this.state.cardDashboard}
					ingresos={this.state.ingresos}
					dataCuenta={this.state.cardCuentaDashboard}
					egresos={this.state.egresos}
					compraData={this.state.cardCompraDashboard}
				/>

				<div className="dashboard-top-section">
					<div className="col-xl-5 spacing dashboard-tabs-widget">
						{solicitudesPendiente &&
							serviciosPorVencer &&
							licenciasPorVencer &&
							segurosPorVencer && (
								<DashboardTabs>
									<Tab
										key="1"
										title="Solicitudes"
										counter={solicitudesPendiente.length}
									>
										<div className="contenedor-tabs">
										<SolicitudesPendiente
											solicitudes={solicitudesPendiente}
										/>
										</div>
										
										<div className="ver-mas d-flex justify-content-end align-items-end">
											<NavLink to={getLink("/solicitudes")}>
												<p className="text-secondary">Ver más</p>
											</NavLink>
										</div>
									</Tab>
									<Tab
										key="2"
										title="Servicios"
										counter={serviciosPorVencer.length}
									>
										<div className="contenedor-tabs">

											<ServiciosPorVencer
												servicios={serviciosPorVencer}
												toggleModal={
												this.toggleRegistroModal
												}
											/>
										</div>
										<div className="ver-mas d-flex justify-content-end align-items-end">
											<NavLink to={getLink("/servicios")}>
												<p className="text-secondary">Ver más</p>
											</NavLink>
										</div>
									</Tab>
									<Tab
										title="Licencias"
										key="3"
										counter={licenciasPorVencer.length}
									>
										<div className="contenedor-tabs">

											<LicenciasPorVencer
												licencias={licenciasPorVencer}
											/>
										</div>
										<div className="ver-mas d-flex justify-content-end align-items-end">
											<NavLink target='_blank' to={getLink("/imprimir/reportes?reporte=licencias-vencidas")}>
												<p className="text-secondary">Ver más</p>
											</NavLink>
										</div>
									</Tab>
									<Tab
										key="4"
										title="Seguros"
										counter={segurosPorVencer.length}
									>
										<div className="contenedor-tabs">

											<SegurosPorVencer
												seguros={segurosPorVencer}
											/>
										</div>
										<div className="ver-mas d-flex justify-content-end align-items-end">
											<NavLink target='_blank' to={getLink("/imprimir/reportes?reporte=seguros-vencidos")}>
												<p className="text-secondary">Ver más</p>
											</NavLink>
										</div>
									</Tab>
								</DashboardTabs>
							)}
					</div>
					<div className="col spacing app-dashboard dashboard-tabs-widget">					
						<AppChart
							diasDelMes={this.state.diasDelMes}
							chartDashboardData={this.state.chartDashboard}
					/>
					</div>
				</div>
				<div className="dashboard-row">
				
					<GraficoBarras
						data={{
							
							totalCompraItems: {
								label: "Inventario Interno",
								value: this.state.graficoBarras
									?.totalCompraItems,
							},
							totalCompraProductos: {
								label: "Inventario de Producto",
								value: this.state.graficoBarras
									?.totalCompraProductos,
							},
							totalCompraSuministros: {
								label: "Trabajo/Suministro",
								value: this.state.graficoBarras
									?.totalCompraSuministros,
							},
							gastosInternos: {
								label: "Gastos Internos",
								value: this.state.graficoBarras?.gastosInternos,
							},
						}}
					/>
					<AppLine
						diasDelMes={diasDelMes}
						LastThreeMonthsChartDashboard={
							this.state.lastThreeMonthsChartDashboard
						}
					/>
				</div>

				{combustibleDisponible.length > 0 && (
					<div className="combustible">
						<CombustibleDisponible
							contenedores={combustibleDisponible}
						/>
					</div>
				)}

				<Modal
					show={this.state.showRegistro}
					blank={true}
					toggle={this.toggleRegistroModal}
				>
					<CreateRegistro
						toggle={this.toggleRegistroModal}
						vehiculo={this.state.vehiculoActivo}
						getRegistros={this.getDashboard}
						registro={this.state.registroActivo}
					/>
				</Modal>
				<Modal
					title="Registrar gastos..."
					show={this.state.showGastoConfirmation}
					toggle={this.toggleGastoConfrimation}
					acceptText="Ir a gastos"
					callback={() =>
						this.props.history.push(
							getLink(
								`/gastos/crear?registro=${registroActivo.id}&vehiculo=${registroActivo.vehiculoId}&mecanico=${registroActivo.mecanicoId}`
							)
						)
					}
				>
					¿Deseas registrar los gastos relacionados a este servicio
					ahora?
				</Modal>
			</div>
		);
	}
}

export default withRequest(HomeDashboard);
