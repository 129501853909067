/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../../utils/auth";
import { formatCurrency, money } from "../../../../utils/helpers";
import { request } from "../../../../utils/request";

const PrintReporteNotasCredito = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [notasCredito, setNotasCredito] = useState([]);

	const getNotasCredito = () => {
		request(
			"notas-credito" + window.location.search + "&order=ncf&paginate=1000000"
		).then((res) => {
			setNotasCredito(res.data);
		});
	};

	const formatDateTime = (date) => {
		return dayjs(date).format("DD/MM/YYYY, hh:mm a");
	};

	useEffect(() => {
		getNotasCredito();
	}, []);
	useEffect(() => {
		if (notasCredito.length <= 0) return;
		const timeout = setTimeout(() => {
			window.print();
		}, 700);

		return () => {
			clearTimeout(timeout);
		};
	}, [notasCredito]);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotal = notasCredito.reduce((acc, notaCredito) => {
		return acc + parseFloat(notaCredito.total);
	}, 0);

	return (
		<>
			<style>{`@page {size: A4 landscape}`}</style>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Nota de crédito
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL NOTAS DE CRÉDITO: {notasCredito.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
			<table className="table m-b-40">
				<thead>
					<tr>
						<th>NCF/Numero</th>
						<th>Tipo</th>
						<th>Contacto</th>
						<th>Fecha</th>
						<th className="text-right">Razón</th>
						<th className="text-right">Transacción Afectada</th>
						<th className="text-right">Total</th>
					</tr>
				</thead>
				<tbody>
					{notasCredito.map((notaCredito) => {
						return (
							<tr key={notaCredito.id}>
								<td>{notaCredito.numeracion}</td>
								<td className="text-capitalize">
									{notaCredito.tipo}
								</td>
								<td>
									{notaCredito.contacto
										? notaCredito.contacto.nombre
										: ""}
								</td>
								<td>
									{formatDateTime(notaCredito.fecha)}
								</td>
								<td className="text-capitalize text-right">
									{notaCredito.razon}
								</td>
								<td className="text-capitalize text-right">
									{notaCredito.tipo === "venta"
										? notaCredito.factura
												?.numero_comprobante
										: (notaCredito.compra?.ncf ||
										  notaCredito.compra?.numero)}
								</td>
								<td className="text-right">
									{formatCurrency(money(notaCredito.total))}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL NOTAS DE CRÉDITO: {notasCredito.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrintReporteNotasCredito;
