import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiRequest } from "../../../hooks/apiRequest";
import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";

const SingleTransaccion = () => {
	const { transaccion, id } = useParams();

	const [transaction, setTransaction] = useState({});
	const [cuenta, setCuenta] = useState({});
	const [sucursal, setSucursal] = useState({});
	const [infoBlock2Margin, setInfoBlock2Margin] = useState(10);
	const getTransaccion = async () => {
		const response = await apiRequest(
			null,
			`transacciones/${transaccion}`,
			"GET"
		);

		if (response.success) {
			setTransaction(response.data);
		}
	};

	const getCuenta = async () => {
		const response = await apiRequest(null, `cuentas/${id}`, "GET");

		if (response.success) {
			setCuenta(response.data);
		}
	};

	useEffect(() => {
		getCuenta();
		getTransaccion();
		const user = auth.getUser();
		setSucursal(
			user.sucursales.filter((suc) => suc.id === user.sucursalActual)[0]
		);
		const infoBlock1 = document.getElementById("info-block-1");
		const infoBlock2 = document.getElementById("info-block-2");
		const count =
			infoBlock2.childElementCount - infoBlock1.childElementCount;
		setInfoBlock2Margin((count * 25 + 10) * -1);
	}, []);
	return (
		<div className="p-2 invoice recibo-print">
			{/* -----MOBILE---- */}
			<div className="d-block d-sm-none">
				<div className="col-12 text-center">
					{sucursal.logo ? (
						<img
							alt="logo"
							className="mb-2 factura-logo"
							src={sucursal.logo[0].url}
							width="270px"
						/>
					) : (
						<h3
							style={{
								lineHeight: "12px",
								marginBottom: "3px",
							}}
						>
							{sucursal.nombre}
						</h3>
					)}
					<div className="col-md-10">
						{sucursal.direccion} <br />{" "}
						{new Date().toLocaleString()}
					</div>
				</div>
                <h3 className="col-md-12 text-center m-b-5 mt-3">
					Recibo de Transacción
				</h3>

				<div className="d-flex flex-column align-items-center">
					<div className="row print-transaction__container col-9 py-2 mt-2">
						<div className="col-12 col-sm-6 print-transaction__title">
							Fecha:
						</div>
						<div className="col-12 col-sm-6 print-transaction__body">
							{dayjs(transaction.created_at).format(
								"DD/M/YYYY hh:mm a"
							)}
						</div>
						<div className="col-12 col-sm-6 print-transaction__body">
							NO.: {transaction.id}
						</div>
					</div>
					<div className="row print-transaction__container col-9 py-2">
						<div className="col-12 col-sm-6 print-transaction__title">
							REALIZADA POR:
						</div>
						<div className="col-12 col-sm-6 print-transaction__body">
							{transaction.autor?.nombre}{" "}
							{transaction.autor?.apellido}
						</div>
					</div>
					<div className="row print-transaction__container col-9 py-2">
						<div className="col-12 col-sm-6 print-transaction__title">
							Monto:
						</div>
						<div
							className="col-12 col-sm-6 print-transaction__body"
							style={{ fontSize: "1.2rem" }}
						>
							{formatCurrency(parseFloat(transaction.monto))}
						</div>
					</div>
					<div className="row print-transaction__container col-9 py-2">
						<div className="col-12 col-sm-6 print-transaction__title">
							Tipo:
						</div>
						<div className="col-12 col-sm-6 print-transaction__body">
							{transaction.tipo}
						</div>
					</div>
					<div className="row print-transaction__container col-9 py-2">
						<div className="col-12 col-sm-6 print-transaction__title">
							Desde cuenta:
						</div>
						<div className="col-12 col-sm-6 print-transaction__body">
							{cuenta.nombre}
						</div>
					</div>
					<div className="row print-transaction__container col-9 py-2">
						<div className="col-12 col-sm-6 print-transaction__title">
							A Cuenta:
						</div>
						<div className="col-12 col-sm-6 print-transaction__body">
							{transaction.cuenta?.nombre}
						</div>
					</div>
					<div className="row print-transaction__container col-9 py-2">
						<div className="col-12 col-sm-6 print-transaction__title">
							Concepto:
						</div>
						<div className="col-12 col-sm-6 print-transaction__body">
							{transaction.descripcion}
						</div>
					</div>
					{/* <div className="clear-both info-row p-t-10 p-b-10 d-none d-sm-flex col-10">
                        <div className="info-block" style={{ width: "65%" }}>
                            <table>
                                <tbody id="info-block-1">
                                        <tr>
                                            <td
                                                width="320px"
                                                className="client-info-title text-uppercase text-right"
                                            >
                                                Tipo:
                                            </td>
                                            <td
                                                style={{
                                                    lineHeight: "13px",
                                                    paddingLeft: "10px",
                                                }}
                                            >
                                                {transaction.tipo}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="client-info-title text-uppercase text-right">
                                                Desde Cuenta:
                                            </td>
                                            <td style={{ paddingLeft: "10px" }}>
                                                {cuenta.nombre}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="client-info-title text-uppercase text-right">
                                                Hasta Cuenta:
                                            </td>
                                            <td style={{ paddingLeft: "10px" }}>
                                                {transaction.cuenta?.nombre}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="client-info-title text-uppercase text-right">
                                                Concepto:
                                            </td>
                                            <td style={{ paddingLeft: "10px" }}>
                                                {transaction.descripcion}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="client-info-title text-uppercase text-right">
                                                Dirección:
                                            </td>
                                            <td
                                                style={{
                                                    lineHeight: "13px",
                                                    paddingLeft: "10px",
                                                }}
                                            >
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> */}
				</div>
			</div>

			{/* ---DESKTOP--- */}
			<div className="d-none d-sm-block">
				<div className="row pt-4">
					<div className="col-7">
						{sucursal.logo ? (
							<img
								alt="logo"
								className="mb-2 factura-logo"
								src={sucursal.logo[0].url}
								width="270px"
							/>
						) : (
							<h3
								style={{
									lineHeight: "12px",
									marginBottom: "3px",
								}}
							>
								{sucursal.nombre}
							</h3>
						)}
						<div className="col-md-10">
							{sucursal.direccion} <br />{" "}
							{new Date().toLocaleString()}
						</div>
					</div>
					<div className="col-4 d-flex align-items-center">
						<h3
							style={{
								fontSize: "1.3rem",
								fontWeight: "600",
								paddingLeft: "15px",
							}}
						>
							monto: {formatCurrency(transaction.monto)}
						</h3>
					</div>
				</div>

				<div className="clear-both info-row p-t-10 p-b-10 d-none d-sm-flex col-10">
					<div className="info-block" style={{ width: "65%" }}>
						<table>
							<tbody id="info-block-1">
								<tr>
									<td
										width="145px"
										className="client-info-title text-uppercase text-right"
									>
										Tipo:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{transaction.tipo}
									</td>
								</tr>
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Desde Cuenta:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{cuenta.nombre}
									</td>
								</tr>
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Hasta Cuenta:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{transaction.cuenta?.nombre}
									</td>
								</tr>
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Concepto:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{transaction.descripcion}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div
						className="info-block"
						style={{ marginTop: infoBlock2Margin }}
					>
						<table>
							<tbody id="info-block-2">
								<tr>
									<td colSpan={2}>
										<h3
											style={{
												fontSize: "0.9rem",
												marginBottom: "3px",
												paddingBottom: "10px",
												whiteSpace: "nowrap",
												paddingLeft: "40%",
											}}
										>
											Recibo de transacción
										</h3>
									</td>
								</tr>
								<tr>
									<td
										width="145px"
										className="client-info-title text-uppercase text-right"
										colSpan={2}
									>
										FECHA:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
											whiteSpace: "nowrap",
										}}
									>
										{dayjs(transaction.created_at).format(
											"DD/M/YYYY hh:mm a"
										)}
									</td>
								</tr>
								<tr>
									<td
										width="145px"
										className="client-info-title text-uppercase text-right"
										colSpan={2}
									>
										NO.:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{transaction.id}
									</td>
								</tr>
								<tr>
									<td
										width="145px"
										className="client-info-title text-uppercase text-right"
										colSpan={2}
										style={{ whiteSpace: "nowrap" }}
									>
										REALIZADA POR:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{transaction.autor?.nombre}{" "}
										{transaction.autor?.apellido}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SingleTransaccion;
