import React, { useState, useRef, useEffect } from "react";
import { withRequest } from "../../utils/request";
import { capitalize } from "lodash";
import PropTypes from "prop-types";
import AsyncCreatableSelect from 'react-select/async-creatable';
import Select from "react-select";
import { toast, provinciasMunicipiosForDropdown } from "../../utils/helpers";
import { apiRequest } from "../../hooks/apiRequest";
import { auth } from "../../utils/auth";
import DocumentoItem from "./Form/Documentos";
import SelectAsync from 'react-select/async';

const Form = ({contrato, errors}) => {
	
    const [state, setState] = useState({
        loading: true,
    });

	let perfil_contrato = contrato?.perfil_contrato || 0;
	const [perfil, setPerfil] = useState(perfil_contrato);
    const [documentos, setDocumentos] = useState([{id: 0}]);
    const sidebarRef = useRef(null);
    const [isSticked, setIsSticked] = useState(false);
	
    useEffect(() => {
		if(contrato){
			setDocumentos(contrato.documentos)
		}
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, []);

    const isSticky = (e) => {
        const sidebarEl = sidebarRef.current.getBoundingClientRect();

        const scrollTop = window.scrollY;
        if (scrollTop >= sidebarEl.top - 10) {
          setIsSticked(true);
        } else {
          setIsSticked(false);
        }
    }

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
		
        const table = await apiRequest({}, url, 'get');

        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };

    const addDocumento = () => {
        setDocumentos(prev => ([
            ...prev,
            {id: documentos.length}
        ]))
    }

   /* const removeDocumento = (id) => {
        setDocumentos(prev => ([
            ...prev.filter(t => t.id !== id),
        ]))
    }*/

    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
        (suc) => suc.id === user.sucursalActual
    )[0];

    return (
		<div className="row">
			<div className="col">
				<div className="row form-group">
					<div className="col col-md-5">
						<label
							htmlFor="tipo"
							className="label form-control-label"
						>
							Tipo:
						</label>
					</div>
					<div className="col col-md-7">
						<Select name="tipo" id="tipo" options={[{value:"interno",label:"Interno"},{value:"externo",label:"Externo"}]} defaultValue={contrato?.tipo && ({value: contrato.tipo, label: capitalize(contrato.tipo)})}/>
						{errors.tipo && (
							<small className="help-blockParams form-Text">
								{errors.tipo[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label
							htmlFor="frecuencia_pago"
							className="label form-control-label"
						>
							Categoría:
						</label>
					</div>
					<div className="col col-md-7">
						<AsyncCreatableSelect
							key="categoria_contrato_id"
							name='categoria_contrato_id'
							placeholder='Seleccionar...'
							menuPosition='fixed'
							menuPlacement='auto'
							cacheOptions
							defaultOptions
							defaultValue={{label: contrato?.categoria?.nombre,value: contrato?.categoria?.id}}
							loadOptions={(e) => getOptions("categorias-contrato?nombre="+e)}
						/>
						{errors.categoria_contrato_id && (
							<small className="help-blockParams form-Text">
								{errors.categoria_contrato_id[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label
							htmlFor="frecuencia_pago"
							className="label form-control-label"
						>
							Frecuencia de Pago:
						</label>
					</div>
					<div className="col col-md-7">
						<Select name="frecuencia_pago" 
								id="frecuencia_pago" 
								defaultValue={contrato?.frecuencia_pago && (
									{value:contrato.frecuencia_pago, label:capitalize(contrato.frecuencia_pago)}
								)} 
								options={[{value:"mensual",label:"Mensual"},{value:"trimestral",label:"Trimestral"},{value:"semestral",label:"Semestral"},{value:"anual",label:"Anual"}]}/>
						{errors.frecuencia_pago && (
							<small className="help-blockParams form-Text">
								{errors.frecuencia_pago[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-5">
						<label
							htmlFor="monto"
							className="label form-control-label"
						>
							Monto:
						</label>
					</div>
					<div className="col col-md-7">
						<input
							type="number"
							className="text-input form-control"
							id="monto"
							name="monto"
							step={0.01}
							defaultValue={contrato?.monto}
						/>
						{errors.monto && (
							<small className="help-blockParams form-Text">
								{errors.monto[0]}
							</small>
						)}
					</div>
				</div>
				<div className="row">
				<div className="col col-md-9 pl-0">				
					<div className="form-group">
						<div className="col col-md-12">
							<h3>Seleccione el perfil del contrato:</h3>
						</div>
					</div>
					<div className="form-group">
						<div className="col col-md-12" style={{marginTop:"-0.1rem"}}>
							<div className="form-check form-check-inline">
								{(perfil == 1) ? <input type="radio" className="form-check-input" name="perfil_contrato" value="1" defaultChecked onChange={() => setPerfil(1)}/> : <input type="radio" className="form-check-input" name="perfil_contrato" value="1" onChange={() => setPerfil(1)}/>}
								<label className="form-check-label">Empleado</label>
							</div>
							<div className="form-check form-check-inline">
								{(perfil == 2) ? <input type="radio" className="form-check-input" name="perfil_contrato" value="2" defaultChecked onChange={() => setPerfil(2)}/> : <input type="radio" className="form-check-input" name="perfil_contrato" value="2" onChange={() => setPerfil(2)}/>}
								<label className="form-check-label">Cliente</label>
							</div>
							<div className="form-check form-check-inline">
								{(perfil == 3) ? <input type="radio" className="form-check-input" name="perfil_contrato" value="3" defaultChecked onChange={() => setPerfil(3)}/> : <input type="radio" className="form-check-input" name="perfil_contrato" value="3" onChange={() => setPerfil(3)}/>}
								<label className="form-check-label">Proveedor</label>
							</div>
						</div>
						{errors.perfil_contrato && (
							<div className="col col-md-12">
								<small className="help-blockParams form-Text">
									{errors.perfil_contrato[0]}
								</small>
							</div>
						)}
					</div>
					</div>
					<div className="col col-md-3 pt-1 pr-0 float-right">
                            <span style={{marginRight:"10px",fontWeight:"normal",textTransform:"none"}}>Activo:</span>
                            <label className='switch switch-3d switch-success'>
                                <input type='hidden' name='activo' value='no' />
                                <input
                                    key={contrato?.id}
                                    type='checkbox'
                                    className='switch-input'
                                    id='activo'
                                    name='activo'
                                    value='si'                                    
									defaultChecked={
                                        contrato?.activo ? contrato.activo == "activo" ? true : false
														 : true
                                    }
                                />
                                <span className='switch-label' />
                                <span className='switch-handle' />
                            </label>
                            {errors.active && (
                                <small className='help-blockParams form-Text'>
                                    {errors.active[0]}
                                </small>
                            )}
                        </div>
				</div>
				<div className="row form-group">

				{(perfil != 0) && (		
					<div className="col col-md-5">
						<label
							htmlFor="frecuencia_pago"
							className="label form-control-label"
						>
							Nombre:
						</label>
					</div>)
					}
					{
						(perfil == 2) && (
						<div className="col col-md-7">
							<SelectAsync
								key="contacto_id"
								name='contacto_id'
								placeholder='Seleccionar...'
								menuPosition='fixed'
								menuPlacement='auto'
								cacheOptions
								defaultOptions
								defaultValue={contrato?.perfil_contrato == perfil ? {label: contrato?.contratoable?.nombre, value: contrato?.contratoable?.id} : " "}
								loadOptions={(e) => getOptions("contactos?buscar="+e)}
							/>
						</div>
						)	
					}
					{
						(perfil == 1) && (
							<div className="col col-md-7">
								<SelectAsync
									key="empleado_id"
									name='empleado_id'
									placeholder='Seleccionar...'
									menuPosition='fixed'
									menuPlacement='auto'
									cacheOptions
									defaultOptions
									defaultValue={{label: contrato?.contratoable?.nombre,value: contrato?.contratoable?.id}}
									loadOptions={(e) => getOptions("empleados?buscar="+e)}
								/>
							</div>
						)
					}
					{
						(perfil == 3) && (
							<div className="col col-md-7">
								<SelectAsync
									key="contacto_id"
									name='contacto_id'
									placeholder='Seleccionar...'
									menuPosition='fixed'
									menuPlacement='auto'
									cacheOptions
									defaultOptions
									defaultValue={{label: contrato?.contratoable?.nombre,value: contrato?.contratoable?.id}}
									loadOptions={(e) => getOptions("suplidores","label","value")}
								/>
							</div>
						)
					}
					{errors.contacto_id && (
						<small className="help-blockParams form-Text">
							{errors.contacto_id[0]}
						</small>
					)}
				</div>
				<div className="row form-group">
					<div className='col col-md-5'>
						<label htmlFor='desde' className="label form-control-label">Fecha Inicio:</label>
					</div>
					<div className='col col-md-7'>
						<input
							type='date'
							name='fecha_inicio'
							id='fecha_inicio'
							className='form-control'
							defaultValue={contrato?.inicio}
						/>
					</div>
				</div>
				<div className="row form-group">
					<div className='col col-md-5'>
						<label htmlFor='desde' className="label form-control-label">Fecha Fin:</label>
					</div>
					<div className='col col-md-7'>
						<input
							type='date'
							name='fecha_fin'
							id='fecha_fin'
							className='form-control'
							defaultValue={contrato?.fin}
						/>
					</div>
				</div>
				<div className="row form-group">
					<div className="col col-md-6">
						<h3>Documentos Adjuntos</h3>
					</div>
				</div>
				<div>
					{documentos.map((documento, index) => (
						<DocumentoItem key={documento.id}
							documento={documento}
							existing={contrato?.documentos?.length}
							id={index}
						/>
					))}

					<div className="row form-group">
						<div className="col">
							<button type="button" className="btn float-right" onClick={addDocumento}>
								+ Agregar Documento
							</button> 
						</div>
					</div>	
				</div>

				<div className='form-separator' />
				<div className="row form-group">
					<div className="col col-md-5">
						<label
							htmlFor="notas"
							className="label form-control-label"
						>
							Notas:
						</label>
					</div>
					<div className="col col-md-7">
						<textarea
							type="text"
							className="text-input form-control"
							id="notas"
							name="notas"
							aria-label="notasInput"
							defaultValue={contrato?.notas}
						></textarea>
						{errors.notas && (
							<small className="help-blockParams form-Text">
								{errors.notas[0]}
							</small>
						)}
					</div>
				</div>
		</div>
    </div>  
    );
};


export default Form;
