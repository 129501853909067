import React from "react";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";

import { withRequest } from "../../../utils/request";
import { formatCurrency } from "../../../utils/helpers";
import useSalarioNavidad from "./Hooks/useSalarioNavidad";

const SalarioNavidadDesglose = () => {
    const {
        empleados,
        orden,
        onChangeOrden,
        sucursal,
        redondear,
        redondeo,
        denominaciones,
        resumenDesglose,
        onChangeRedondear,
        totalPagos,
        totalEmpleados,
        salarioNavidadDesglose,
    } = useSalarioNavidad();

    return (
        <div>
            <style>{`@page {size: 11in  8.5in;} *{font-size: 14px}`}</style>
            <div className="ordernar-resultados no-print">
                <div className="form-group">
                    <div className="radio-btn">
                        <label>
                            <input type="radio" onChange={onChangeOrden} name="orden" value="nombre" defaultChecked={true} /> Ordenar por Nombre
                        </label>
                        <label>
                            <input type="radio" onChange={onChangeOrden} name="orden" value="codigo" /> Ordenar por Código
                        </label>
                        <label>
                            <input type="checkbox" onChange={onChangeRedondear} name="redondear" value="redondear" /> Redondear
                        </label>
                    </div>
                </div>
            </div>
            <div className='row'>
                <h2 className='col-md-12 text-center m-b-5'>
                    Desglose Efectivo Salario Navidad
                </h2>
                <div className='col-md-12 text-center'>
                    {sucursal.nombre} - {sucursal.direccion} •{" "}
                    {dayjs().format("DD/MM/YYYY")}
                </div>
                <div className='col-md-12 p-b-10'></div>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'>
                        EMPLEADOS: {totalEmpleados}
                    </div>

                    <div className='float-right'>
                        <h3>TOTAL: {formatCurrency(totalPagos)}</h3>
                    </div>
                </div>
            </div>
            <table className="table m-b-50">
                <thead>
                    <tr>
                        <th className="text-center">RD$2,000</th>
                        <th className="text-center">RD$1,000</th>
                        <th className="text-center">RD$500</th>
                        <th className="text-center">RD$200</th>
                        <th className="text-center">RD$100</th>
                        <th className="text-center">RD$50</th>
                        <th className="text-center">RD$25</th>
                        <th className="text-center">RD$10</th>
                        <th className="text-center">RD$5</th>
                        <th className="text-center">RD$1</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {denominaciones.map(denominacion => (
                            <td className="text-center" key={denominacion}>{Math.floor(resumenDesglose[denominacion])}</td>
                        ))}
                    </tr>
                </tbody>
            </table>
            <table className='table'>
                <thead>
                    <tr>
                        <th style={{width: 100}}>Código</th>
                        <th>Nombre</th>
                        <th>Cargo</th>
                        <th className='text-center'>
                            2,000
                        </th>
                        <th className='text-center' style={{minWidth: 50}}>
                            1,000
                        </th>
                        <th className='text-center' style={{minWidth: 50}}>
                            500
                        </th>
                        <th className='text-center' style={{minWidth: 50}}>
                            200
                        </th>
                        <th className='text-center' style={{minWidth: 50}}>
                            100
                        </th>
                        <th className='text-center' style={{minWidth: 50}}>
                            50
                        </th>
                        <th className='text-center' style={{minWidth: 50}}>
                            25
                        </th>
                        <th className='text-center' style={{minWidth: 50}}>
                            10
                        </th>
                        <th className='text-center' style={{minWidth: 50}}>
                            5
                        </th>
                        <th className='text-center' style={{minWidth: 50}}>
                            1
                        </th>
                        <th className='text-right'>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {empleados.sort((a, b) => {
                        if ( orden === 'codigo') {
                            if (!a.codigo) {
                                return 1;
                            }
                            return a.codigo.localeCompare(b.codigo);
                        }

                        return a.nombre.localeCompare(b.nombre);
                    }).map((empleado, index) => {
                        const snDesglose = salarioNavidadDesglose(empleado);
                        return (
                            <tr key={index}>
                                <td>{empleado.codigo}</td>
                                <td>{empleado.nombre}</td>
                                <td>{empleado.tipo?.nombre}</td>
                                {denominaciones.map(desglose => (
                                    <td className="text-center" key={desglose}>{Math.floor(snDesglose[desglose])}</td>
                                ))}
                                <td className="text-right">
                                    {redondeo ? (
                                        <span>
                                            {formatCurrency(
                                                redondear(empleado.prestaciones)
                                            )}
                                        </span>
                                    ) : (
                                        <span>
                                            {formatCurrency(
                                                empleado.prestaciones
                                            )}
                                        </span>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className='row'>
                <div className='col-md-12 text-right p-t-10 p-b-10'>
                    <div className='float-left'>
                        EMPLEADOS: {totalEmpleados}
                    </div>

                    <div className='float-right'>
                        <h3>TOTAL: {formatCurrency(totalPagos)}</h3>
                    </div>
                </div>
            </div>
            <div className='firma-reporte'>
                <div className='firma'>Supervisor</div>
            </div>
        </div>
    );
}

export default withRouter(withRequest(SalarioNavidadDesglose));
