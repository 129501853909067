import React, { Component } from "react";

import { withRequest } from "../../../utils/request";
import { getLink } from "../../../utils/helpers";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../../general/Loader";
import { Link } from "react-router-dom";

class Servidores extends Component {
    state = {
        servidores: {},
        servidor: {},
        links: null,
        meta: null,
        showCreate: false,
        showEdit: false,
        loading: true,
    };
    componentDidMount() {
        this.getServidores();
    }
    getServidores = async (url = null) => {
        url = url ? url + "&limit=15" : "gi-servidores?limit=15";
        const servidores = await this.props.request(url);

        if (servidores.links) {
            this.setState({
                servidores: servidores.data,
                links: servidores.links,
                meta: servidores.meta,
                loading: false,
            });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate,
            servidor: {},
        });
    };
    toggleEditModal = (servidor) => {
        this.setState({
            showEdit: !this.state.showEdit,
            servidor: servidor,
        });
    };
    render() {
        const { servidores, loading } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/configuracion/')}>&laquo; Volver a configuración</Link>
                    <h1 className='page-title row'>
                        <span className='col'>Servidores</span>
                        <button
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            onClick={this.toggleCreateModal}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Servidor
                        </button>
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!servidores.length ? (
                                <div>
                                    {!loading && (
                                        <div>
                                            No hay ningún servidor
                                            creado.
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th className="text-center">Cantidad Activos</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {servidores.map((servidor) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={servidor.id}>
                                                    <td>{servidor.nombre}</td>
                                                    <td className="text-center">{servidor.miembros}</td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <button
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Edit'
                                                                onClick={() => {
                                                                    this.toggleEditModal(
                                                                        servidor
                                                                    );
                                                                }}>
                                                                <i className='zmdi zmdi-edit' />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {this.state.links && (
                                        <Pagination
                                            links={this.state.links}
                                            meta={this.state.meta}
                                            getData={this.getServidores}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        servidor={this.state.servidor}
                        getServidores={this.getServidores}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={() => this.toggleEditModal({})}>
                    <Edit
                        toggle={() => this.toggleEditModal({})}
                        servidor={this.state.servidor}
                        getServidores={this.getServidores}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Servidores);
