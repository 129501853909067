import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { auth } from "../utils/auth";

import MobileHeader from "./general/MobileHeader";
import Sidebar from "./general/Sidebar";
import DesktopHeader from "./general/DesktopHeader";

import Login from "./Login";
import GIDashboard from "./HomeDashboard/GI/Dashboard";
import Empleados from "./Empleados";
import Nominas from "./Nominas";
import ViewNomina from "./Nominas/View";
import CrearEmpleado from "./Empleados/Create";
import LayoutEmpleado from "./Empleados/Layout";
import Vehiculos from "./Vehiculos";
import Reclamaciones from "./Reclamaciones";
import CrearVehiculo from "./Vehiculos/Create";
import LayoutVehiculo from "./Vehiculos/Layout";
import Programaciones from "./Programaciones";
import Edit from "./Programaciones/Edit";
import Rutas from "./Rutas";
import Reportes from "./Reportes/Form";
import Usuarios from "./Configuracion/Usuarios";
import Tipos from "./Configuracion/Tipos";
import Ausencias from "./Ausencias";
import TipoAusencias from "./Configuracion/TipoAusencias";
import Categorias from "./Configuracion/Categorias";
import Asignaciones from "./Configuracion/Asignaciones";
import Solicitudes from "./Solicitudes";
import Sucursales from "./Configuracion/Sucursales";
import LayoutSucursales from "./Configuracion/Sucursales/Layout";
import Configuracion from "./Configuracion";
import Almacenes from "./Configuracion/Almacenes";
import Grupos from "./Configuracion/Grupos";
import Cuentas from "./Cuentas";
import LayoutCuentas from "./Cuentas/Layout";
import Productos from "./Productos";
import CreateProducto from "./Productos/Create";
import LayoutProducto from "./Productos/Layout";
import Items from "./Items";
import CreateItem from "./Items/Create";
import LayoutItem from "./Items/Layout";
import Servicios from "./Servicios";
import Gastos from "./Gastos";
import CrearGastos from "./Gastos/CreateMultiple";
import GastosGenerales from "./GastosGenerales";
import CrearGastosGenerales from "./GastosGenerales/Create";
import Contactos from "./Contactos";
import Compras from "./Compras";
import EditCompras from "./Compras/Edit";
import CrearCompras from "./Compras/Create";
import Modal from "./general/Modal";
import DescuentosProgramados from "./DescuentosProgramados";
import Incentivos from "./Incentivos";
import GIPrestamos from "./GI/Prestamos";
import GIGrupos from "./GI/Grupos";
import GIServidores from "./GI/Servidores";
import GIGruposCrear from "./GI/Grupos/Create";
import GIGruposLayout from "./GI/Grupos/Layout";
import GrupoTipoEmpleados from "./GrupoTipoEmpleados";
import Facturas from "./Ingresos/Facturacion";
import CrearFactura from "./Ingresos/Facturacion/Create";
import EditFactura from "./Ingresos/Facturacion/Edit";
import OrdenesCompra from "./Ingresos/OrdenCompra";
import CrearOrden from "./Ingresos/OrdenCompra/Create";
import EditOrden from "./Ingresos/OrdenCompra/Edit";
import Cotizaciones from "./Ingresos/Cotizacion";
import CrearCotizacion from "./Ingresos/Cotizacion/Create";
import EditCotizacion from "./Ingresos/Cotizacion/Edit";
import Numeraciones from "./Configuracion/Numeraciones";
import TerminosPago from "./Configuracion/TerminosPago";
import Impuestos from "./Configuracion/Impuestos";
import Taxonomias from "./Configuracion/Taxonomias";
import Academica from "./Configuracion/Academica";
import GastosInternos from "./GastosInternos";
import GastosInternosCrear from "./GastosInternos/Create";
import GastosInternosEditar from "./GastosInternos/Edit";
import PlantillaImpresion from "./Configuracion/PlantillaImpresion";
import ContactosTransaccionesHistory from "./Contactos/Transacciones/History";
import ContactosTransaccionesCompras from "./Contactos/Transacciones/Compras";
import CuentasPorCobrar from "./Consultas/CuentasPorCobrar";
import RecibosIngreso from "./Consultas/RecibosIngresos";
import FacturasRecurrentes from "./Ingresos/FacturacionRecurrente";
import CrearFacturaRecurrente from "./Ingresos/FacturacionRecurrente/Create";
import EditFacturaRecurrente from "./Ingresos/FacturacionRecurrente/Edit";
import HistorialPagosFacturas from "./Consultas/HistorialPagosFacturas";
import Estudiantes from "./Academico/Estudiantes";
import CrearEstudiante from "./Academico/Estudiantes/Create";
import EditarEstudiante from "./Academico/Estudiantes/Edit";
import Evaluaciones from "./Academico/Evaluaciones";
import Inscripciones from "./Academico/Inscripciones";
import Tutores from "./Academico/Tutores";
import CrearTutor from "./Academico/Tutores/Create";
import FacturacionAcademica from "./Academico/FacturacionAcademica/Create";
import RecibosAcademicos from "./Academico/Recibos";
import RecibosCocurriculares from "./Academico/Cocurriculares/Recibos";
import Cocurriculares from "./Academico/Cocurriculares/Create";
import InscripcionCocurriculares from "./Academico/Cocurriculares/Inscripcion";
import LayoutTutor from "./Academico/Tutores/Layout";
import CuentasPorPagar from "./Consultas/CuentasPorPagar";
import CuentasContables from "./CuentasContables";
import EntradasDiario from "./EntradasDiario";
import CrearEntradasDiario from "./EntradasDiario/Create";
import EditarEntradasDiario from "./EntradasDiario/Edit";
import NotasCredito from "./Consultas/NotasCredito";
import CreateNotaCredito from "./Consultas/NotasCredito/CreateNotaCredito";
import RazonesNotasCredito from "./Configuracion/RazonesNotasCredito";
import EditNotaCredito from "./Consultas/NotasCredito/EditNotaCredito";
import Cheques from "./Cheques";
import Contratos from "./Contratos";
import TipoContratos from "./Configuracion/Contratos"

class Dashboard extends Component {
    state = {
        isLoggedIn: auth.isLoggedIn(),
        showReportes: false,
    };
    componentDidMount() {
        window.addEventListener("storage", this.syncLogout);

        // auth.inactivityTime();
    }
    componentWillUnmount() {
        window.removeEventListener("storage", this.syncLogout);
        window.localStorage.removeItem("logout");
    }
    syncLogout = (event) => {
        if (event.key === "logout") {
            this.setState({ isLoggedIn: auth.isLoggedIn() });
        }
    };
    toggleModal = (modal, show = null) => {
        this.setState({
            [modal]: show === null ? !this.state[modal] : show,
        });
    };
    hideSidebar = () => {
        if(window.screen.width > 990) return;
        const sidebar = document.getElementById("sidebar");

        if(!sidebar.classList.contains('d-none')) {
            sidebar.classList.add('d-none');
        }
    };
    render() {
        if (!this.state.isLoggedIn) return <Login />;

        let MainView = GIDashboard; //NewDashboard;

        if (auth.is("digitador")) {
            MainView = Programaciones;
        } else if (auth.is("gasto")) {
            MainView = Gastos;
        } else if (auth.is("solicitudes")) {
            MainView = Solicitudes;
        } else if (auth.is("vendedor") || auth.is('vendedor-cliente')) {
            MainView = OrdenesCompra;
        } else if (auth.is('cobrador')){
            MainView = CuentasPorCobrar;
        } else if (auth.is("vendedor-factura") || auth.is("vendedor-factura-orden_compra")) {
            MainView = Facturas;
        }

        const sucursal = auth.getUser().sucursalActual;

        return (
            <div>
                <MobileHeader />
                <Sidebar toggleModal={this.toggleModal} />
                <div className='page-container' onClick={() => this.hideSidebar()}>
                    <DesktopHeader toggleModal={this.toggleModal} />
                    <div className='main-content'>
                        <div className='section__content section__content--p30'>
                            <div className='container-fluid'>
                                <Switch>
                                    <Route
                                        path="/"
                                        exact
                                        render={({ location }) => (
                                            <Redirect
                                                to={{
                                                    pathname: `/${sucursal}`,
                                                    state: { from: location },
                                                }}
                                            />
                                        )}
                                    />
                                    <Route
                                        path='/:sucursal/'
                                        exact
                                        component={MainView}
                                    />

                                    {/* <---SUCURSALES---> */}
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("editar-sucursales")}
                                        path='/:sucursal/sucursales'>
                                        <Sucursales />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                        can={auth.can("editar-sucursales")}
                                        path='/:sucursal/sucursales/:id/*'>
                                        <LayoutSucursales />
                                    </ProtectedRoute>

                                    {/* <---Académico---> */}
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/estudiantes'>
                                        <Estudiantes />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/estudiantes/crear'>
                                        <CrearEstudiante />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/estudiantes/:id/*'>
                                        <EditarEstudiante />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/evaluaciones'>
                                        <Evaluaciones />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/inscripcion'>
                                        <Inscripciones />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/padres-tutores'>
                                        <Tutores />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/padres-tutores/crear'>
                                        <CrearTutor />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/facturar'>
                                        <FacturacionAcademica />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/recibos'>
                                        <RecibosAcademicos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/inscripcion-cocurriculares'>
                                        <InscripcionCocurriculares />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/cocurriculares'>
                                        <Cocurriculares />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/recibos-cocurriculares'>
                                        <RecibosCocurriculares />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                        can={auth.can("ver-academico")}
                                        path='/:sucursal/academico/padres-tutores/:id/*'>
                                        <LayoutTutor />
                                    </ProtectedRoute>

                                    {/* <---EMPLEADOS---> */}
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-empleados")}
                                        path='/:sucursal/empleados'>
                                        <Empleados />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-empleados")}
                                        path='/:sucursal/ausencias'>
                                        <Ausencias />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-empleados")}
                                        path='/:sucursal/empleados/crear'>
                                        <CrearEmpleado />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-empleados")}
                                        path='/:sucursal/empleados/:id/*'>
                                        <LayoutEmpleado />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-grupo-tipos-empleados")}
                                        path='/:sucursal/grupo-tipos-empleados/'>
                                        <GrupoTipoEmpleados />
                                    </ProtectedRoute>

                                    {/* <---GI GRUPOS---> */}
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-gi-grupos")}
                                        path='/:sucursal/gi-grupos'>
                                        <GIGrupos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-gi-grupos")}
                                        path='/:sucursal/gi-grupos/:id/*'>
                                        <GIGruposLayout />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-gi-prestamos")}
                                        path='/:sucursal/gi-prestamos'>
                                        <GIPrestamos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-gi-grupos")}
                                        path='/:sucursal/gi-grupos/crear'>
                                        <GIGruposCrear />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        can={auth.can("ver-gi-servidores")}
                                        path='/:sucursal/gi-servidores'>
                                        <GIServidores />
                                    </ProtectedRoute>

                                    {/* <---NOMINA---> */}
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/nomina'
                                        can={auth.can("ver-nomina")}>
                                        <Nominas />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/nomina/:id'
                                        can={auth.can("ver-nomina")}>
                                        <ViewNomina />
                                    </ProtectedRoute>

                                    {/* <---SOLICITUDES---> */}
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/solicitudes'
                                        can={auth.can("ver-solicitudes")}>
                                        <Solicitudes />
                                    </ProtectedRoute>

                                    {/* <---RECLAMACIONES---> */}
                                    <ProtectedRoute
                                        path='/:sucursal/reclamaciones'
                                        can={auth.can("ver-reclamaciones")}>
                                        <Reclamaciones />
                                    </ProtectedRoute>

                                    {/* <---DESCUENTOS---> */}
                                    <ProtectedRoute
                                        path='/:sucursal/descuentos'
                                        can={auth.can("ver-descuentos")}>
                                        <DescuentosProgramados />
                                    </ProtectedRoute>

                                    {/* <---INCENTIVOS---> */}
                                    <ProtectedRoute
                                        path='/:sucursal/incentivos'
                                        can={auth.can("ver-incentivos")}>
                                        <Incentivos />
                                    </ProtectedRoute>

                                    {/* <---SERVICIOS---> */}
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/servicios'
                                        can={auth.can("ver-servicios")}>
                                        <Servicios />
                                    </ProtectedRoute>

                                    {/* <---VEHICULOS---> */}
                                    <ProtectedRoute
                                        path='/:sucursal/vehiculos/crear'
                                        can={auth.can("ver-vehiculos")}>
                                        <CrearVehiculo />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/vehiculos/:id/*'
                                        can={auth.can("ver-vehiculos")}>
                                        <LayoutVehiculo />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/vehiculos'
                                        can={auth.can("ver-vehiculos")}>
                                        <Vehiculos />
                                    </ProtectedRoute>

                                    {/* <---RUTAS---> */}
                                    <ProtectedRoute
                                        path='/:sucursal/rutas'
                                        can={auth.can("ver-rutas")}>
                                        <Rutas />
                                    </ProtectedRoute>

                                    {/* <---REPORTES---> */}
                                    <ProtectedRoute
                                        path='/:sucursal/reportes'
                                        can={auth.can("ver-reportes")}>
                                        <Reportes />
                                    </ProtectedRoute>

                                    {/* <---PROGRAMACION---> */}
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/programaciones'
                                        can={auth.can("ver-programacion")}>
                                        <Programaciones />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/programaciones/:id'
                                        can={auth.can("ver-programacion")}>
                                        <Edit />
                                    </ProtectedRoute>

                                    {/* <---INVENTARIO---> */}
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/inventario'
                                        can={auth.can("ver-inventario")}>
                                        <Items />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/inventario/:id/*'
                                        can={auth.can("ver-inventario")}>
                                        <LayoutItem />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/inventario/crear'
                                        can={auth.can("ver-inventario")}>
                                        <CreateItem />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/productos'
                                        can={auth.can("ver-productos")}>
                                        <Productos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/productos/:id/*'
                                        can={auth.can("ver-productos")}>
                                        <LayoutProducto />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/productos/crear'
                                        can={auth.can("ver-productos")}>
                                        <CreateProducto />
                                    </ProtectedRoute>

                                    {/* <---GASTOS---> */}
                                    <ProtectedRoute
                                        path='/:sucursal/gastos'
                                        exact
                                        can={auth.can("ver-gastos")}>
                                        <Gastos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/gastos-internos'
                                        exact
                                        can={auth.can("ver-gastos")}>
                                        <GastosInternos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/gastos-internos/crear'
                                        exact
                                        can={auth.can("ver-gastos")}>
                                        <GastosInternosCrear />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/gastos-internos/:id/editar'
                                        exact
                                        can={auth.can("ver-gastos")}>
                                        <GastosInternosEditar />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/gastos/crear'
                                        can={auth.can("ver-gastos")}>
                                        <CrearGastos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/gastos-generales'
                                        exact
                                        can={auth.can("ver-gastos-generales")}>
                                        <GastosGenerales />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/gastos-generales/crear'
                                        can={auth.can("ver-gastos-generales")}>
                                        <CrearGastosGenerales />
                                    </ProtectedRoute>

                                    {/* <---COMPRAS---> */}
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/compras'
                                        can={auth.can("ver-compras")}>
                                        <Compras />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/compras/crear'
                                        can={auth.can("ver-compras")}>
                                        <CrearCompras />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/compras/:id'
                                        can={auth.can("ver-compras")}>
                                        <EditCompras />
                                    </ProtectedRoute>

                                    {/* <---CONTACTOS---> */}
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/contactos'
                                        can={auth.can("ver-contactos")}>
                                        <Contactos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/contactos/:id/transacciones'
                                        can={auth.can("ver-contactos")}>
                                        <ContactosTransaccionesHistory />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/contactos/:id/compras'
                                        can={auth.can("ver-contactos")}>
                                        <ContactosTransaccionesCompras />
                                    </ProtectedRoute>

                                    {/* <---CONFIGURACIÓN---> */}
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/usuarios'
                                        can={auth.can("ver-configuracion")}>
                                        <Usuarios />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        exact
                                        path='/:sucursal/configuracion'
                                        can={auth.can("ver-configuracion")}>
                                        <Configuracion />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/academica'
                                        can={auth.can("ver-configuracion")}>
                                        <Academica />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/usuarios'
                                        can={auth.can("ver-configuracion")}>
                                        <Usuarios />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/tipos'
                                        can={auth.can("ver-tipos")}>
                                        <Tipos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/tipo-ausencias'
                                        can={auth.can("ver-configuracion")}>
                                        <TipoAusencias />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/categorias'
                                        can={auth.can("ver-configuracion")}>
                                        <Categorias />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/asignaciones'
                                        can={auth.can("ver-configuracion")}>
                                        <Asignaciones />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/almacenes'
                                        can={auth.can("ver-configuracion")}>
                                        <Almacenes />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/grupos'
                                        can={auth.can("ver-configuracion")}>
                                        <Grupos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/numeraciones'
                                        can={auth.can("ver-configuracion")}>
                                        <Numeraciones />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/terminos-pago'
                                        can={auth.can("ver-configuracion")}>
                                        <TerminosPago />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/impuestos'
                                        can={auth.can("ver-configuracion")}>
                                        <Impuestos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/taxonomias'
                                        can={auth.can("ver-configuracion")}>
                                        <Taxonomias />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-plantillas-impresion")}
                                        exact
                                        path='/:sucursal/configuracion/plantillas-impresion'>
                                        <PlantillaImpresion />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-configuracion")}
                                        exact
                                        path='/:sucursal/configuracion/razones-notas-credito'>
                                        <RazonesNotasCredito />
                                    </ProtectedRoute>

                                    {/* <---CUENTAS---> */}
                                    <ProtectedRoute
                                        path='/:sucursal/cuentas'
                                        exact
                                        can={auth.can("ver-cuentas")}>
                                        <Cuentas />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-cuentas")}
                                        path='/:sucursal/cuentas/:id/*'>
                                        <LayoutCuentas />
                                    </ProtectedRoute>

                                    {/* <---INGRESOS---> */}
                                    <ProtectedRoute
                                        can={auth.can("ver-facturas")}
                                        exact
                                        path='/:sucursal/ingresos/facturas'>
                                        <Facturas />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-facturas")}
                                        exact
                                        path='/:sucursal/ingresos/facturas/crear'>
                                        <CrearFactura />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-facturas")}
                                        exact
                                        path='/:sucursal/ingresos/facturas/crear/:plantilla'>
                                        <CrearFactura />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-facturas")}
                                        exact
                                        path='/:sucursal/ingresos/facturas/:id/edit'>
                                        <EditFactura />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                        can={auth.can("ver-facturas-recurrentes")}
                                        exact
                                        path='/:sucursal/ingresos/facturas-recurrentes'>
                                        <FacturasRecurrentes />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-facturas-recurrentes")}
                                        exact
                                        path='/:sucursal/ingresos/facturas-recurrentes/crear'>
                                        <CrearFacturaRecurrente />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-facturas-recurrentes")}
                                        exact
                                        path='/:sucursal/ingresos/facturas-recurrentes/:id/edit'>
                                        <EditFacturaRecurrente />
                                    </ProtectedRoute>

                                    <ProtectedRoute
                                        can={auth.can("ver-ordenes-compra")}
                                        exact
                                        path='/:sucursal/ingresos/ordenes-compra'>
                                        <OrdenesCompra />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-ordenes-compra")}
                                        exact
                                        path='/:sucursal/ingresos/ordenes-compra/crear'>
                                        <CrearOrden />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-ordenes-compra")}
                                        exact
                                        path='/:sucursal/ingresos/ordenes-compra/:id/edit'>
                                        <EditOrden />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-cotizaciones")}
                                        exact
                                        path='/:sucursal/ingresos/cotizaciones'>
                                        <Cotizaciones />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-cotizaciones")}
                                        exact
                                        path='/:sucursal/ingresos/cotizaciones/crear'>
                                        <CrearCotizacion />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-cotizaciones")}
                                        exact
                                        path='/:sucursal/ingresos/cotizaciones/:id/edit'>
                                        <EditCotizacion />
                                    </ProtectedRoute>

                                    {/* <---CONTRATOS---> */}
                                    <ProtectedRoute
                                        can={auth.can("ver-contratos")}
                                        exact
                                        path='/:sucursal/contratos'>
                                        <Contratos />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        path='/:sucursal/configuracion/contratos'
                                        can={auth.can("ver-tipos")}>
                                        <TipoContratos />
                                    </ProtectedRoute>

                                    {/* <---CONSULTAS---> */}
                                    <ProtectedRoute
                                        can={auth.can("ver-cuentas-por-cobrar")}
                                        exact
                                        path='/:sucursal/consultas/cuentas-por-cobrar'>
                                        <CuentasPorCobrar />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-cuentas-por-pagar")}
                                        exact
                                        path='/:sucursal/consultas/cuentas-por-pagar'>
                                        <CuentasPorPagar />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-recibos-ingreso")}
                                        exact
                                        path='/:sucursal/consultas/recibos'>
                                        <RecibosIngreso />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-notas-credito")}
                                        exact
                                        path='/:sucursal/consultas/notas-credito'>
                                        <NotasCredito />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-notas-credito")}
                                        exact
                                        path='/:sucursal/consultas/notas-credito/crear'>
                                        <CreateNotaCredito />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-notas-credito")}
                                        exact
                                        path='/:sucursal/consultas/notas-credito/:id/editar'>
                                        <EditNotaCredito />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-facturas")}
                                        exact
                                        path='/:sucursal/consultas/historial-pagos-facturas/:id'>
                                        <HistorialPagosFacturas />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-contabilidad")}
                                        exact
                                        path='/:sucursal/cuentas-contables'>
                                        <CuentasContables />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-contabilidad")}
                                        exact
                                        path='/:sucursal/entradas-de-diario'>
                                        <EntradasDiario />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-contabilidad")}
                                        exact
                                        path='/:sucursal/entradas-de-diario/crear'>
                                        <CrearEntradasDiario />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-contabilidad")}
                                        exact
                                        path='/:sucursal/entradas-de-diario/:id'>
                                        <EditarEntradasDiario />
                                    </ProtectedRoute>
                                    <ProtectedRoute
                                        can={auth.can("ver-contabilidad")}
                                        path='/:sucursal/cheques'>
                                        <Cheques />
                                    </ProtectedRoute>

                                    {/* REDIRECT WHEN PAGE DOESN'T EXISTS */}
                                    {auth.is("vendedor") ? (
                                        <Route path="*">
                                            <Redirect to={`/${sucursal}/ingresos/ordenes-compra`} />
                                        </Route>
                                    ) : auth.is("vendedor-factura") || auth.is("vendedor-factura-orden_compra") ? (
                                        <Route path="*">
                                            <Redirect to={`/${sucursal}/ingresos/facturas`} />
                                        </Route>
                                    ) : (
                                        <Route path="*">
                                            <Redirect to={`/${sucursal}`} />
                                        </Route>
                                    )}
                                </Switch>

                                <Modal
                                    show={this.state.showReportes}
                                    blank={true}
                                    toggle={() =>
                                        this.toggleModal("showReportes")
                                    }>
                                    <Reportes
                                        toggle={() =>
                                            this.toggleModal("showReportes")
                                        }
                                    />
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function ProtectedRoute({ children, can, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                can ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: auth.is("vendedor") ? "/ingresos/ordenes-compra" : auth.is("vendedor-factura") || auth.is("vendedor-factura-orden_compra") ? "/ingresos/facturas" : "/",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export default Dashboard;
