import { apiUrl } from "../utils/apiUrl";
import { auth } from "../utils/auth";
import { toast } from "../utils/helpers";

export const apiRequest = async (data, url, method = "post") => {
	try {
		let options = {};

		if (
			method.toLocaleLowerCase() === "post" ||
			method.toLocaleLowerCase() === "delete" ||
			method.toLocaleLowerCase() === "put" ||
			method.toLocaleLowerCase() === "patch"
		) {
			options = {
				method,
				body: data,
				headers: {
					Accept: "application/json",
					"X-Request-With": "XMLHttpRequest",
					Authorization: "Bearer " + auth.getToken(),
				},
			};
		}

		if (method.toLocaleLowerCase() === "get") {
			options = {
				headers: {
					Accept: "application/json",
					"X-Request-With": "XMLHttpRequest",
					Authorization: "Bearer " + auth.getToken(),
				},
			};
		}

		const res = await fetch(apiUrl(url), options);

		return await res.json();
	} catch (error) {
		toast("Error Procesando consulta", "error");
	}
};
