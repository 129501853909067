/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../../utils/auth";
import { formatCurrency, money } from "../../../../utils/helpers";
import { request } from "../../../../utils/request";

const PrintFacturasResumen = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [facturas, setFacturas] = useState([]);
	const vendedoresMap = new Map([]);
	const [vendedoresMap1, setVendedoresMap1] = useState({});

	const [summaries, setSummaries] = useState({
		efectivo: 0,
		tarjeta: 0,
		transferencia: 0,
		cheque: 0,
		otro: 0,
		canceladas: 0,
		pendientesDePago: 0,
		pagadas: 0,
		// totalVendido: 0,
	});

	const getFacturas = () => {
		request("facturas" + window.location.search + "&paginate=1000000").then(
			(res) => {
				setFacturas(res.data);
			}
		);
	};

	const formatDateTime = (date) => {
		return dayjs(date).format("DD/MM/YYYY, hh:mm a");
	};

	const calculateSummaries = useCallback(() => {
		facturas.forEach((factura) => {
			switch (factura.tipo_pago) {
				case "efectivo":
					setSummaries((prevState) => ({
						...prevState,
						efectivo:
							prevState.efectivo +
							(factura.total - factura.por_cobrar),
					}));
					break;
				case "tarjeta":
					setSummaries((prevState) => ({
						...prevState,
						tarjeta:
							prevState.tarjeta +
							(factura.total - factura.por_cobrar),
					}));
					break;
				case "transferencia":
					setSummaries((prevState) => ({
						...prevState,
						transferencia:
							prevState.transferencia +
							(factura.total - factura.por_cobrar),
					}));
					break;
				case "cheque":
					setSummaries((prevState) => ({
						...prevState,
						cheque:
							prevState.cheque +
							(factura.total - factura.por_cobrar),
					}));
					break;
				case "otro":
					setSummaries((prevState) => ({
						...prevState,
						otro:
							prevState.otro +
							(factura.total - factura.por_cobrar),
					}));
					break;

				default:
					break;
			}

			if (factura.estado === "pendiente") {
				setSummaries((prevState) => ({
					...prevState,
					pendientesDePago:
						prevState.pendientesDePago + (factura.por_cobrar || 0),
				}));
			} else if (factura.estado === "cancelada") {
				setSummaries((prevState) => ({
					...prevState,
					canceladas: prevState.canceladas + (factura.total || 0),
				}));
			}
			if (factura.estado !== "cancelada") {
				setSummaries((prevState) => ({
					...prevState,
					pagadas:
						prevState.pagadas +
						(factura.total - factura.por_cobrar),
				}));
			}
		});
	}, [facturas]);

	const fillVendedores = () => {
		facturas.forEach((factura) => {
			if (factura.vendedor) {
				setVendedoresMap1((prevState) => ({
					...prevState,
					[factura.vendedor?.id]: {
						cantidad:
							(prevState[factura.vendedor?.id]?.cantidad || 0) +
							1,
						nombre: `${factura.vendedor?.nombre} ${factura.vendedor?.apellido}`,
						total:
							(prevState[factura.vendedor?.id]?.total || 0) +
							parseFloat(factura.total),
						codigo: factura.vendedor.codigo,
					},
				}));
			}
		});
	};

	useEffect(() => {
		getFacturas();
	}, []);
	useEffect(() => {
		if (facturas.length <= 0) return;
		calculateSummaries();
		fillVendedores();
		const timeout = setTimeout(() => {
			window.print();
		}, 700);

		return () => {
			clearTimeout(timeout);
		};
	}, [facturas]);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotal = facturas.reduce((acc, factura) => {
		return acc + parseFloat(factura.total);
	}, 0);

    const facturasPorNumeracion = facturas.reduce((acc, fact) => {
        if (typeof acc[fact.numeracion.nombre] === 'undefined') {
            acc[fact.numeracion.nombre] = [];
        }
        acc[fact.numeracion.nombre].push(fact);

        return acc;
    }, {});

	return (
		<>
			<style>{`@page {size: A4 landscape}`}</style>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Resumen Facturas
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {facturas.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-6">
					<h5 className="fw-600 w-100 text-center mb-2">
						FORMAS DE PAGO
					</h5>
					<table className="table m-b-40">
						<thead>
							<tr>
								<th>Efectivo</th>
								<th>Tarjeta</th>
								<th>Transferencia</th>
								<th>Cheque</th>
								<th width="90px">Otro</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{formatCurrency(summaries.efectivo, "$")}
								</td>
								<td>
									{formatCurrency(summaries.tarjeta, "$")}
								</td>
								<td>
									{formatCurrency(
										summaries.transferencia,
										"$"
									)}
								</td>
								<td>{formatCurrency(summaries.cheque, "$")}</td>
								<td>{formatCurrency(summaries.otro, "$")}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="col-6">
					<h5 className="fw-600 w-100 text-center mb-2">
						DETALLES DE FACTURAS
					</h5>
					<table className="table m-b-40">
						<thead>
							<tr>
								<th>Canceladas</th>
								<th>Pendiente de pago</th>
								<th>Pagadas</th>
								<th>Total vendido</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{formatCurrency(summaries.canceladas, "$")}
								</td>
								<td>
									{formatCurrency(
										summaries.pendientesDePago,
										"$"
									)}
								</td>
								<td>
									{formatCurrency(summaries.pagadas, "$")}
								</td>
								<td>
									{formatCurrency(
										summaries.pendientesDePago +
											summaries.pagadas,
										"$"
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

            {Object.keys(facturasPorNumeracion).map(numeracion => {
                const facts = facturasPorNumeracion[numeracion];
                return (
                <>
                    <h3>{numeracion}</h3>
                    <table className="table m-b-40">
                        <thead>
                            <tr>
                                <th>NCF/Numero</th>
                                <th>Cliente</th>
                                <th>Cel/Tel</th>
                                <th>Creación</th>
                                <th className="text-right">Vencimiento</th>
                                <th className="text-right">Estado</th>
                                <th className="text-right">Balance</th>
                                <th className="text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {facts.map((factura) => {
                                return (
                                    <tr key={factura.id}>
                                        <td>{factura.numero_comprobante}</td>
                                        <td>
                                            {factura.cliente?.nombre || ''}
                                        </td>
                                        <td>
                                            {factura.cliente?.celular || '--'}/{factura.cliente?.telefono || '--'}
                                        </td>
                                        <td>{formatDateTime(factura.created_at)}</td>
                                        <td className="text-right">
                                            {new Intl.DateTimeFormat("es-ES").format(
                                                new Date(factura.fecha_vencimiento)
                                            )}
                                        </td>
                                        <td className="text-capitalize text-right">
                                            {factura.estado}
                                        </td>
                                        <td className="text-capitalize text-right">
                                            {formatCurrency(money(factura.por_cobrar), "$")}
                                        </td>
                                        <td className="text-right">
                                            {formatCurrency(money(factura.total))}
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td colSpan={6}></td>
                                <td className="text-right">{formatCurrency(facts.reduce((a, f) => { return a + f.por_cobrar}, 0))}</td>
                                <td className="text-right">{formatCurrency(facts.reduce((a, f) => { return a + f.total}, 0))}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )})}

			<div className="row">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {facturas.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrintFacturasResumen;
