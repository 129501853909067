/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
// import PageHeader from "../../general/PageHeader";
import Form from "./Components/FacturaForm";

const CrearFacturaRecurrente = () => {
	return (
        <Form
            facturaActual={{}}
            isEdit={false}
        />
	);
};

export default CrearFacturaRecurrente;
