import React, { Component } from "react";

import { auth } from "./auth";
import { apiUrl } from "./apiUrl";
import { toast } from "./helpers";

export const requestFile = async (url, signal = null) => {
    window.NProgress.start();
    const token = auth.getToken();

    url = url.indexOf("http") === 0 ? url : apiUrl(url);

    try {
        const response = await fetch(url, {
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
            signal,
        });

        window.NProgress.done();

        return await response.blob();
    } catch (error) {
        // Implementation or Network error
        window.NProgress.done();

        return error;
    }
}

export const request = async (url, signal = null) => {
    window.NProgress.start();
    const token = auth.getToken();

    url = url.indexOf("http") === 0 ? url : apiUrl(url);

    try {
        const response = await fetch(url, {
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
            signal,
        });

        window.NProgress.done();
        const res = await response.json();

        if (res.code === 401) {
            auth.logout();
            toast("Su sesión expiró.", "error");
        }

        return await res;
    } catch (error) {
        // Implementation or Network error
        window.NProgress.done();

        return error;
    }
};

export async function getTable(table, query) {
    const response = await request(table + (query ? `?${query}` : ""));

    if (response.data && response.data.length) {
        return response.data;
    }

    return [];
}

export function withRequest(WrappedComponent) {
    return class withRequest extends Component {
        constructor(props) {
            super(props);

            this.controller = new AbortController();
            this.signal = this.controller.signal;
        }

        request = (url) => {
            return request(url, this.signal);
        };

        getTable = async (table, query = false) => {
            const response = await this.request(
                table + (query ? `?${query}` : "")
            );

            if (response.data && response.data.length) {
                return response.data;
            }

            return [];
        };

        componentWillUnmount() {
            this.controller.abort();
        }

        render() {
            return (
                <WrappedComponent
                    request={this.request}
                    getTable={this.getTable}
                    {...this.props}
                    {...this.state}
                />
            );
        }
    };
}

export const downloadFile = async (url, name, signal = null) => {
    const documento = await requestFile(url, signal);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.download = name;
    a.href = window.URL.createObjectURL(documento);
    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
};
