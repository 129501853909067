import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";

class PrintReclamaciones extends Component {
    state = {
        reclamaciones: [],
    };
    componentDidMount() {
        this.getReclamaciones();
    }
    getReclamaciones = async () => {
        const response = await this.props.request(
            "reclamaciones" + window.location.search
        );

        if (response.data) {
            this.setState({
                reclamaciones: response.data,
            });
            window.print();
        }
    };
    render() {
        const { reclamaciones } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Reclamaciones
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 p-b-10'></div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-right'>
                            <h3># Reclamaciones: {reclamaciones.length}</h3>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ width: "100px" }}>Fecha</th>
                            <th>Detalle</th>
                            <th style={{ width: "30%" }}>Dirección</th>
                            <th>Reclamado Por</th>
                            <th style={{ width: "150px" }}>Estado</th>
                            <th style={{ width: "200px" }}>Responsables</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reclamaciones.map((reclamacion) => {
                            return (
                                <tr key={reclamacion.id}>
                                    <td>
                                        {dayjs(reclamacion.fecha).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </td>
                                    <td>{reclamacion.comentario}</td>
                                    <td>
                                        <strong>
                                            {reclamacion.ruta &&
                                                reclamacion.ruta.nombre}
                                        </strong>
                                        <br />
                                        {reclamacion.direccion}
                                    </td>
                                    <td>
                                        {reclamacion.nombreReclamador}
                                        <br />
                                        <strong>Teléfono:</strong>
                                        <br />
                                        {reclamacion.telefonoReclamador}
                                    </td>
                                    <td style={{ textTransform: "uppercase" }}>
                                        {reclamacion.estado}
                                    </td>
                                    <td>
                                        <strong>Supervisor</strong>
                                        <br />
                                        {reclamacion.supervisor &&
                                            reclamacion.supervisor
                                                .nombreCompleto}
                                        <br />
                                        <strong>
                                            {reclamacion.ficha &&
                                                reclamacion.ficha.ficha}
                                        </strong>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintReclamaciones));
