import React from "react";
import dayjs from "dayjs";

export default function ReporteFechas() {
    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className='form-group'>
                    <label>Desde:</label>
                    <input
                        type='date'
                        name='desde'
                        id='desde'
                        defaultValue={dayjs()
                            .startOf("month")
                            .format("YYYY-MM-DD")}
                        className='form-control'
                    />
                </div>
            </div>
            <div className='col-md-6'>
                <div className='form-group'>
                    <label>Hasta:</label>
                    <input
                        type='date'
                        name='hasta'
                        defaultValue={dayjs()
                            .endOf("month")
                            .format("YYYY-MM-DD")}
                        id='hasta'
                        className='form-control'
                    />
                </div>
            </div>
        </div>
    );
}
