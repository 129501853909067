import dayjs from "dayjs";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency, getLink, toast } from "../../../../utils/helpers";

const FacturasGroup = ({
	factura,
	openPagarFacturaModal,
	toggleDeleteModal,
	openValidarFacturaModal,
}) => {
	const [showFacturas, setShowFacturas] = useState(false);

	const toggleFacturas = () => {
		setShowFacturas((state) => !state);
	};

	const validate = (facturaRecurrente) => {
		const noValidateFacturas = factura?.facturas?.filter((facturaRecurrente) => facturaRecurrente.recurrencia_id &&
			facturaRecurrente.validada === "no" && facturaRecurrente.estado === "pendiente") ?? [];
		const nextFacturaTovalidate = noValidateFacturas.length ? noValidateFacturas[0].id : -1;

		if (facturaRecurrente.id === nextFacturaTovalidate) {
			openValidarFacturaModal(facturaRecurrente);
		} else {
			toast("Las Facturas se deben validar en orden de emisión.", "error");
		}
	};

	const noValidateFacturas = factura?.facturas?.filter((facturaRecurrente) => facturaRecurrente.recurrencia_id &&
		facturaRecurrente.validada === "no" && facturaRecurrente.estado === "pendiente") ?? [];
	const nextFacturaToValidate = noValidateFacturas.length ? noValidateFacturas[0].id : factura.factura?.id;

	return (
		<div
			className="descuento-item"
			id={`di-${factura.id}`}
			key={factura.id}
		>
			<div
				className="descuento-row descuento-info"
				onClick={() => toggleFacturas()}
			>
				<div>
					<strong>
						<Link
							to={getLink(
								`/contactos/${factura.factura?.cliente?.id}/transacciones`
							)}
							onClick={() => {
								localStorage.setItem(
									"contacto",
									JSON.stringify(factura.factura?.cliente)
								);
							}}
						>
							{factura.factura?.cliente?.nombre}
						</Link>
					</strong>
				</div>
				<div>
					<small>
						<strong>Estado:</strong> {factura.status}
					</small>
					<small>
						<strong>Plazo de pago:</strong> {factura.frecuencia}
					</small>
					<small>
						<strong>Numeración:</strong>{" "}
						{factura.factura?.numeracion?.nombre}
					</small>
				</div>
				<div>
					<div>
						<strong>
							{formatCurrency(factura.factura?.total)}
						</strong>
					</div>
					# Ocurrencias: {factura.ocurrencias}
				</div>
				<div>{dayjs(factura.fecha_inicio).format("DD/MM/YYYY")}</div>
				<div>
					{/* {completadas.length > 0 && <div>
                        <strong>Último:{' '}</strong>
                        <div>
                            {ultimaCuota}
                        </div>
                    </div>} */}
					{factura.facturas?.length > 0 && (
						<div>
							<div>
								<strong>Próximo: </strong>
								{factura.siguiente ? dayjs(factura.siguiente).format("DD/MM/YYYY") : ''}
							</div>
							<strong>Final:</strong>{" "}
							{dayjs(factura.final).format("DD/MM/YYYY")}
						</div>
					)}
					{factura.frecuencia === "Unico" && (
						<div>
							<strong>Fecha: </strong>
							<div>
								{dayjs(factura.created_at).format("DD/MM/YYYY")}
							</div>
						</div>
					)}
				</div>
				<div>
					{factura.factura?.estado !== "pagada" && (
						<div className="table-data-feature">
							<div className="dropdown">
								<button className="au-btn au-btn-icon au-btn-filter">
									Acciones
								</button>
								<div
									style={{
										left: "-30%",
									}}
									className="dropdown-menu"
								>
									<Link
										to={getLink(
											`/ingresos/facturas-recurrentes/${nextFacturaToValidate}/edit`
										)}
										className="dropdown-item"
										title="Editar"
									>
										Editar
									</Link>
									<button
										className="dropdown-item"
										onClick={() =>
											toggleDeleteModal(factura)
										}
									>
										Eliminar
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			{factura.facturas?.length > 0 && (
				<div
					className={`descuento-cuotas ${showFacturas ? "show" : ""}`}
				>
					<table className="table">
						<thead>
							<tr>
								<th style={{ width: 40 }}></th>
								<th>Monto</th>
								<th>Fecha</th>
								<th>Estado</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{factura.facturas.map(
								(facturaRecurrente, index) => (
									<tr
										key={facturaRecurrente.id}
										className={`cuota ${facturaRecurrente.estado}`}
									>
										<td></td>
										<td>{formatCurrency(facturaRecurrente.total)}</td>
										<td>
											<div>
												<strong>Emisión: </strong>
												{dayjs(
													facturaRecurrente.fecha_emision ?? facturaRecurrente.fecha
												).format("DD/MM/YYYY")}
											</div>
											<div>
												{facturaRecurrente.estado ===
													"pagada" && (
													<>
														<strong>
															Pagada:{" "}
														</strong>
														{dayjs(
															facturaRecurrente.fecha
														).format("DD/MM/YYYY")}
													</>
												)}
											</div>
										</td>
										<td className="cuota__estado">
											{facturaRecurrente.estado}
										</td>
										<td>
											{!facturaRecurrente.deleted_at && (
												<div className="table-data-feature">
													{facturaRecurrente.recurrencia_id &&
													facturaRecurrente.validada === "no" &&
													facturaRecurrente.estado === "pendiente" ?
													<span className="badge badge-danger mx-2 p-2">No Validada</span> :
													<span className="badge badge-success mx-2 p-2">Validada</span>
													}
													<div className="dropdown">
														<button className="au-btn au-btn-icon au-btn-filter">
															Acciones
														</button>
														<div
															style={{
																left: "-30%",
															}}
															className="dropdown-menu"
														>
															{facturaRecurrente.recurrencia_id &&
															facturaRecurrente.validada ===
																"no" && facturaRecurrente.estado === "pendiente" ? (
																<button
																	className="dropdown-item"
																	onClick={() =>
																		validate(
																			facturaRecurrente
																		)
																	}
																>
																	Validar
																</button>
															) : (
																<>
																	{/* {facturaRecurrente.estado ===
																		"pendiente" && (
																		<button
																			className="dropdown-item"
																			onClick={() =>
																				checkOpenPagarModal(
																					facturaRecurrente,
																					index
																				)
																			}
																		>
																			Pagar
																		</button>
																	)} */}
																	<Link
																		to={getLink(
																			`/imprimir/facturas/${facturaRecurrente.id}`
																		)}
																		target="_blank"
																		className="dropdown-item"
																		title="Impresión normal"
																	>
																		Impresión
																	</Link>
																</>
															)}
														</div>
													</div>
												</div>
											)}
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default FacturasGroup;
