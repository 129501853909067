import dayjs from "dayjs";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { auth } from "../../../../utils/auth";
import { withRequest } from "../../../../utils/request";


class PrintEvaluaciones extends Component {
    state = {
        evaluaciones: [],
    };
    componentDidMount() {
        this.getEmpleados();
    }
    getEmpleados = async () => {
        const response = await this.props.request(
            "academico/evaluaciones" + window.location.search
        );

        if (response.data) {
            this.setState({
                evaluaciones: response.data,
            });
            window.print();
        }
    };
    render() {
        const { evaluaciones } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Evaluaciones
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL EVALUACIONES: {evaluaciones.length}
                        </div>

                        <div className='float-right'>
                            <h3>EVALUACIONES {evaluaciones.length > 0 ? evaluaciones[0].periodoAcademico?.nombre : ''}</h3>
                        </div>
                    </div>
                </div>
                <table className='table m-b-40'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nombre</th>
                            {/* <th>Fecha</th> */}
                            <th>Curso</th>
                            {/* <th>Año</th> */}
                            <th>Teléfono</th>
                            <th>Procedencia</th>
                            <th>Resultado</th>
                            <th>Pago</th>
                            <th>Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {evaluaciones.map((evaluacion, index) => {
                            return (
                                <tr key={evaluacion.id}>
                                    <td>{index+1}</td>
                                    <td>{evaluacion.nombreCompleto}</td>
                                    {/* <td>{dayjs(evaluacion.fecha).format('DD-MM-YYYY')}</td> */}
                                    <td>{evaluacion.grado?.nombre}</td>
                                    {/* <td>{evaluacion.periodoAcademico?.nombre}</td> */}
                                    <td>{evaluacion.telefono}</td>
                                    <td>{evaluacion.procedencia}</td>
                                    <td>{evaluacion.resultado}</td>
                                    <td>{evaluacion.pago}</td>
                                    <td>{evaluacion.estado}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='row'>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL EVALUACIONES: {evaluaciones.length}
                        </div>

                        <div className='float-right'>
                            <h3>EVALUACIONES {evaluaciones.length > 0 ? evaluaciones[0].periodoAcademico?.nombre : ''}</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintEvaluaciones));
