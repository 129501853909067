import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { formatCurrency, getLink } from "../../utils/helpers";

export default function IncentivoItem({incentivo}) {
    return (
        <div className="incentivo-item" key={incentivo.id}>
            <div className="incentivo-row incentivo-info">
                <div>
                    <strong>
                        <Link to={getLink(`/empleados/${incentivo.empleadoId}/incentivos`)}>{ incentivo.empleado }</Link>
                    </strong>
                    {incentivo.telefono && <div>
                        Tel: {incentivo.telefono}
                    </div>}
                </div>
                <div>
                    <div className="incentivo__descripcion">
                        {
                            incentivo.descripcion
                        }
                    </div>
                </div>
                <div>
                    <div className="incentivo__descripcion text-center">
                        {
                            incentivo.frecuencia ? (incentivo.frecuencia === 'fijo' ? 'FIJO' : 'ÚNICO') : ''
                        }
                    </div>
                </div>
                <div className="text-center">
                    {incentivo.frecuencia === 'fijo' ? '∞' : dayjs(incentivo.fecha).format('DD MMM, YYYY')}
                </div>
                <div>
                    <div><strong>{formatCurrency(incentivo.monto)}</strong></div>
                </div>
                <div>
                    <div className='table-data-feature'>
                        <Link
                            to={getLink(
                                `/incentivos/${incentivo.id}`
                            )}
                            className='item'
                            data-toggle='tooltip'
                            data-placement='top'
                            title=''
                            data-original-title='Edit'>
                            <i className='zmdi zmdi-edit' />
                        </Link>
                        <Link
                            to={getLink(
                                `/imprimir/incentivos/${incentivo.id}`
                            )}
                            className='item'
                            target='_blank'
                            data-original-title='Imprimir'>
                            <i className='zmdi zmdi-print' />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
