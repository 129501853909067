import React, { Component } from "react";

class Form extends Component {
    render() {
        const { ruta, errors } = this.props;
        return (
            <div>
                {ruta.id && (
                    <input type='hidden' name='ruta_id' value={ruta.id} />
                )}
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Nombre:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre'
                            name='nombre'
                            defaultValue={ruta.nombre}
                        />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-3'>
                        <label className='label form-control-label'>
                            Descripción:
                        </label>
                    </div>
                    <div className='col col-md-9'>
                        <textarea
                            type='text'
                            className='text-input form-control'
                            id='descripcion'
                            name='descripcion'
                            rows='8'
                            defaultValue={ruta.descripcion}
                        />
                        {errors.descripcion && (
                            <small className='help-blockParams form-Text'>
                                {errors.descripcion[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
