import React, { useEffect, useState } from "react";
import { auth } from "../../../../utils/auth";
import {
	formatCurrency,
} from "../../../../utils/helpers";
import { request } from "../../../../utils/request";

const PrintCotizaciones = () => {
	const [cotizaciones, setCotizaciones] = useState([]);

	const getCotizaciones = () => {
		request("cotizaciones" + window.location.search + "&limit=1000").then((res) => {
			setCotizaciones(res.data);
            window.print();
		});
	};

	const formatDateTime = (date) => {
		let options = {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			hour12: true,
		};
		date = new Date(date);
		return new Intl.DateTimeFormat("es-ES", options).format(date);
	};

	useEffect(() => {
		getCotizaciones();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotal = cotizaciones.reduce((acc, cotizacion) => {
		return acc + parseFloat(cotizacion.total);
	}, 0);

	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Cotizaciones
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{new Date().toLocaleString()}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL COTIZACIONES: {cotizaciones.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
			<table className="table m-b-40">
				<thead>
					<tr>
						<th>NCF/Numero</th>
						<th>Tipo</th>
						<th>Cliente</th>
						<th>Vendedor</th>
						<th>Creación</th>
						<th className="text-right">Vencimiento</th>
						<th className="text-right">Estado</th>
						<th className="text-right">Total</th>
					</tr>
				</thead>
				<tbody>
					{cotizaciones.map((cotizacion) => {
						return (
							<tr key={cotizacion.id}>
								<td>{cotizacion.numero_comprobante}</td>
								<td className="text-capitalize">
									{cotizacion.tipo}
								</td>
								<td>{cotizacion.cliente?.nombre}</td>
								{cotizacion.vendedor ? <td>{`${cotizacion.vendedor?.nombre} ${cotizacion.vendedor?.apellido}`}</td> : <td></td>}
								<td>{formatDateTime(cotizacion.created_at)}</td>
								<td className="text-right">
									{new Intl.DateTimeFormat("es-ES").format(
										new Date(cotizacion.fecha_vencimiento)
									)}
								</td>
								<td className="text-capitalize text-right">
									{cotizacion.estado}
								</td>
								<td className="text-right">
									{formatCurrency(cotizacion.total)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL COTIZACIONES: {cotizaciones.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrintCotizaciones;
