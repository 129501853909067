import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { auth } from "../../../../utils/auth";
import { formatCurrency } from "../../../../utils/helpers";
import { request } from "../../../../utils/request";
import queryString from "query-string";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
const PrintFacturasRecurrentes = () => {
	const [facturas, setFacturas] = useState([]);
	const query = queryString.parse(window.location.search);
	const { status, desde, hasta } = query;

	const getFacturas = () => {
		request(
			"facturas-recurrentes" + window.location.search + "&paginate=100000"
		).then((res) => {
			setFacturas(res.data);
			setTimeout(() => {
				window.print();
			}, 500);
		});
	};

	useEffect(() => {
		getFacturas();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotal = facturas.reduce((acc, factura) => {
		return (
			acc +
			parseFloat(factura.factura?.total)
		);
	}, 0);

	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					{status === "todos" && "Todos los "}
					Reporte Facturas Recurrentes
					{status === "pendiente" && " Pendientes"}
					{status === "completado" && " Completados"}
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{new Date().toLocaleString()}
				</div>
				<div className="col-md-12 text-center">
					Desde: {dayjs(desde).format("DD MMM, YYYY")} - Hasta:{" "}
					{dayjs(hasta).format("DD MMM, YYYY")}
				</div>
				<div className="col-md-12 p-b-10"></div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {facturas.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
				{/* <div className='col-md-12 p-b-10'>
                        <div className="float-left">
                            <p><strong># Descuentos:</strong> {facturas.length}</p>
                            <p><strong># Total cuotas pendientes:</strong> {numeroCuotasPendientes}</p>
                            <p><strong># Total cuotas pagadas:</strong> {numero_cuotas_pagadas}</p>
                            <p><strong># Cuotas a pagar en periodo:</strong> {cuotas_a_pagar}</p>
                            <p><strong># Cuotas pagadas en periodo:</strong> {numero_cuotas_pagadas_periodo}</p>
                        </div>
                        <div className='float-right text-right'>
                            <p><strong>Cantidad prestado:</strong> {formatCurrency(cantidad_prestado)}</p>
                            <p><strong>Total pagado:</strong> {formatCurrency(total_pagado)}</p>
                            <p><strong>Total pendiente:</strong> {formatCurrency(total_pendiente)}</p>
                            <p><strong>Total a pagar en periodo:</strong> {formatCurrency(total_cuotas_correspondientes)}</p>
                            <p><strong>Total pagado en periodo:</strong> {formatCurrency(total_cuotas_pagadas)}</p>
                        </div>
                    </div> */}
			</div>
			<table className="table">
				<thead>
					<tr>
						<th>NO.</th>
						<th>Cliente</th>
						<th>Detalle</th>
						<th>Monto</th>
						<th>Fecha Inicio</th>
						<th className="text-right">Siguiente</th>
					</tr>
				</thead>
				<tbody>
					{facturas.map((factura, index) => {
						return (
							<tr key={factura.id}>
								<td>{index + 1}</td>
								<td>
									<strong>
										{factura.factura?.cliente?.nombre}
									</strong>
									{factura.factura?.cliente?.telefono && (
										<div>
											Tel:{" "}
											{factura.factura?.cliente?.telefono}
										</div>
									)}
								</td>
								<td>
									<div><strong>Estado:</strong> {factura.status}</div>
									<div>
										<strong>Plazo de pago:</strong> {factura.frecuencia}
									</div>
									<div>
										<strong>Numeración:</strong> {factura.factura?.numeracion?.nombre}
									</div>
									<div className="descuento__descripcion">
										{factura.factura?.notas}
									</div>
								</td>
								<td>
									<div>
										<strong>
											{formatCurrency(
												factura.factura?.total
											)}
										</strong>
									</div>
									# Ocurrencias: {factura.ocurrencias}
								</td>
								<td>
									{dayjs(factura.fecha_inicio).format(
										"DD/MM/YYYY"
									)}
								</td>
								<td className="text-right">
									{factura.facturas?.length > 0 && (
										<div>
											<div>
												<strong>Próximo: </strong>
												{dayjs(
													factura.siguiente
												).format("DD/MM/YYYY")}
											</div>
											<div>
												<strong>Final: </strong>
												{dayjs(factura.final).format(
													"DD/MM/YYYY"
												)}
											</div>
										</div>
									)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row">
				<div className="col-md-12 text-right p-t-15">
					<div className="float-left">
						TOTAL FACTURAS: {facturas.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrintFacturasRecurrentes;
