import React, { Component } from "react";
import Select from "react-select";
import { withRequest } from "../../../utils/request";
import dayjs from "dayjs";

class Form extends Component {
    state = {
        vehiculoId: 0,
        saving: false,
    };

    render() {
        const { vehiculo, servicios, mecanicos, errors, registro } = this.props;
        return (
            <div>
                <input type='hidden' name='vehiculo_id' value={vehiculo.id} />
                {servicios.length > 0 && (
                    <div className='row form-group'>
                        <div className='col col-md-4'>
                            <label className='label form-control-label'>
                                Servicio:
                            </label>
                        </div>
                        <div className='col col-md-8'>
                            <input type='hidden' name='servicio_id' required />
                            <Select
                                name='servicio_id'
                                isClearable={true}
                                defaultValue={{
                                    value: registro.servicioId,
                                    label: registro.servicio,
                                }}
                                options={servicios.map((servicio) => ({
                                    value: servicio.id,
                                    label: servicio.nombre,
                                }))}
                            />
                            {errors.servicio_id && (
                                <small className='help-blockParams form-Text'>
                                    {errors.servicio_id[0]}
                                </small>
                            )}
                        </div>
                    </div>
                )}
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Fecha:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='date'
                            name='fecha'
                            defaultValue={
                                registro.fecha
                                    ? dayjs(registro.fecha).format("YYYY-MM-DD")
                                    : dayjs().format("YYYY-MM-DD")
                            }
                            className='form-control'
                        />
                        {errors.fecha && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Notas:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <textarea
                            type='text'
                            name='notas'
                            rows='3'
                            defaultValue={registro.notas}
                            className='form-control'
                        />
                        {errors.notas && (
                            <small className='help-blockParams form-Text'>
                                {errors.notas[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Mecánico:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <Select
                            name='mecanico_id'
                            isClearable={true}
                            defaultValue={{
                                value: registro.mecanicoId,
                                label: registro.mecanico,
                            }}
                            options={mecanicos.map((mecanico) => ({
                                value: mecanico.id,
                                label: mecanico.nombreCompleto,
                            }))}
                        />
                        {errors.mecanico_id && (
                            <small className='help-blockParams form-Text'>
                                {errors.mecanico_id[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRequest(Form);
