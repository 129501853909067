import React, { Component, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { formatNumber } from "../../utils/helpers";

const AppChart = (props) => {
	const [state, setState] = useState({
		options: {
			chart: {
				id: "basic-bar",
				stacked: true,
			
			},
			//Es ncesario este array fijo para que valide los dias que llegan con los que estan (con diasDelMes no funciona)
			xaxis: {
				categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
			},
			legend: {
				position: "top",
				offsetY:10,
				fontSize:'15px',
    			fontFamily: 'Helvetica, Arial, sans-serif',
				
			},
			title: {
				text: "Tipo de Factura:",
				align: "center",
				margin: 2,
				offsetX: 0,
				offsetY: 10,
				floating: false,
				style: {
					color: "rgb(55, 61, 63)",
						fontSize: "15px",
						fontWeight: 600,
						fontFamily: "Helvetica, Arial, sans-serif",
				},
			},
			dataLabels: {
				enabled: false,
			},
			
			yaxis: {
				labels: {
				  formatter: function (value) {
					return formatNumber(value);
				  }
				},
			  },
			  responsive: [
				{
				  breakpoint: 575,
				  options: {
					chart: {
					 width: "100%",
					 padding:0,
					 margin:0,
					
					},
					legend: {
						fontSize: "12px",
					},
					xaxis: {
						labels: {
						  style: {
							fontSize: "7px", // Adjust font size for responsive mode
						  },
						},
					  },
					  yaxis: {
						labels: {
							formatter: function (value) {
								return formatNumber(value);
							  },
						  style: {
							fontSize: "12px", // Adjust font size for responsive mode
						  },
						},
					  },
					
				  },
				},
			  ],
		},
		series: [
		],
	});

	useEffect(() => {
		if (!props.chartDashboardData || !props.diasDelMes) return;

		setState((prevState) => ({
			...prevState, series: props.chartDashboardData, xaxis: {
				categories: props.diasDelMes,
			}
			
		}))
	}, [props]);

	return (
		<div className="app app-dashboard">
			<div className="row">

				<div className="mixed-chart app-dashboard-inside" style={{paddingLeft: '21.5px'}}>
					{/* <p className="title">Tipo de Factura:</p> */}
					<Chart
						options={state.options}
						series={state.series}
						type="bar"
						height="280"
												
					/>
				</div>
			</div>
		</div>
	);
};

export default AppChart;