import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";

class PrintEmpleados extends Component {
    state = {
        empleados: [],
    };
    componentDidMount() {
        this.getEmpleados();
    }
    getEmpleados = async () => {
        const response = await this.props.request(
            "empleados" + window.location.search
        );

        if (response.data) {
            this.setState({
                empleados: response.data,
            });
            window.print();
        }
    };
    render() {
        const { empleados } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Reporte Empleados
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL EMPLEADOS: {empleados.length}
                        </div>

                        <div className='float-right'>
                            <h3>EMPLEADOS</h3>
                        </div>
                    </div>
                </div>
                <table className='table m-b-40'>
                    <thead>
                        <tr>
                            <th>Codigo</th>
                            <th>Nombre</th>
                            <th>Cédula</th>
                            <th>Cargo</th>
                            <th>Teléfono</th>
                            <th>Contrato</th>
                            <th>Estado</th>
                            <th className='text-right'>Sueldo Fijo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {empleados.map((empleado) => {
                            return (
                                <tr key={empleado.id}>
                                    <td>{empleado.codigo}</td>
                                    <td>
                                        {empleado.nombre} {empleado.apellido}
                                    </td>
                                    <td>{empleado.cedula}</td>
                                    <td>{empleado.cargo}</td>
                                    <td>{empleado.telefono}</td>
                                    <td>
                                        {empleado.informal
                                            ? "Informal"
                                            : "Formal"}
                                    </td>
                                    <td>
                                        {empleado.active
                                            ? "Activo"
                                            : "Inactivo"}
                                    </td>
                                    <td className='text-right'>
                                        {formatCurrency(empleado.sueldoFijo)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='row'>
                    <div className='col-md-12 text-right p-b-10'>
                        <div className='float-left'>
                            TOTAL EMPLEADOS: {empleados.length}
                        </div>

                        <div className='float-right'>
                            <h3>EMPLEADOS</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintEmpleados));
