import React from "react";

import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { getLink } from "../../utils/helpers";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export default function SolicitudesPendiente({ solicitudes }) {
	if (solicitudes.length === 0) {
		return <div>¡Muy bien! No hay solicitudes pendiente.</div>;
	}

	return (
		<>
			<table className="table table-top-campaign">
				<tbody>
					<tr>
						<td style={{ width: "25px" }}></td>
						<td style={{ minWidth: "85px" }}>Fecha</td>
						<td>Empleado</td>
						<td>Tipo</td>
						<td>Descripción</td>
					</tr>
					{solicitudes.map((solicitud, index) => (
						<tr
							className={solicitud.pendiente ? "pendiente" : ""}
							key={solicitud.numero}
						>
							<td style={{ fontWeight: "bold" }}>{index + 1}.</td>
							<td>
								{dayjs(solicitud.fecha).format("DD-MM-YYYY")}
							</td>
							<td>{solicitud.empleado}</td>
							<td>{solicitud.averia}</td>
							<td>{solicitud.descripcion}</td>
						</tr>
					))}
				</tbody>
			</table>
			
		</>
	);
}
