import React, { Component } from "react";
import { auth } from "../../../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../../../utils/apiUrl";
import { toast } from "../../../../utils/helpers";
import Loader from "../../../general/Loader";
import Modal from "../../../general/Modal";

class EditInscripcion extends Component {
    state = {
        errors: {},
        showDelete: false,
    };
    editUser = async event => {
        event.preventDefault();

        this.setState({saving: true});

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(apiUrl(`academico/inscripciones-cocurriculares/${this.props.inscripcion.id}`), {
            method: "post",
            body: data,
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.props.getInscripciones();
            this.props.toggle();
            toast("Inscripción actualizada.");
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message, saving: false });
        }
    };
    deleteInscripcion = async (inscripcion) => {
        this.setState({saving: true});

        const token = auth.getToken();

        const url = `academico/inscripciones-cocurriculares/${inscripcion}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
            }
        });

        const response = await res.json();

        if (response.success) {
            this.props.getInscripciones();
            this.props.toggle();
            this.setState({saving: false});
            toast("Inscripción borrada.");
            return;
        } else {
            this.setState({errors: response.data.message, saving: false})
            toast(response.data, "error");
        }
    };
    render() {
        const { errors, saving, showDelete } = this.state;
        const { toggle, inscripcion } = this.props;
        return (
            <>
                <form method='post' onSubmit={this.editUser}>
                    <input type='hidden' name='_method' value='patch' />
                    <div className='popup__header'>Editar Inscripción</div>
                    <div className='popup__body'>
                        <Form
                            editar
                            estudiantes={this.props.estudiantes}
                            becas={this.props.becas}
                            clases={this.props.clases}
                            inscripcion={inscripcion}
                            errors={errors}
                        />
                    </div>
                    <div className='popup__footer'>
                        {!saving && (
                            <button
                                type='button'
                                className='btn float-left'
                                onClick={e => this.setState({showDelete: true})}>
                                <i className='fas fa-trash-alt'></i>
                            </button>
                        )}
                        <button
                            type='button'
                            onClick={toggle}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        {saving && <Loader type="small"/>}
                        {!saving && (
                            <button type='submit' className='btn btn-primary'>
                                Guardar
                            </button>
                        )}
                    </div>
                </form>
                <Modal
                    title='Borrar Inscripción'
                    show={showDelete}
                    callback={() => this.deleteInscripcion(inscripcion.id)}
                    acceptText="Borrar"
                    toggle={() => this.setState(prev => ({showDelete: !prev.showDelete}))}>
                    ¿Seguro que deseas borrar la inscripcion de {inscripcion.contacto?.nombre}?
                </Modal>
            </>
        );
    }
}

export default EditInscripcion;
