import React, { Component } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import SelectAsync from "react-select/async";
import dayjs from "dayjs";
import Select from "react-select";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Loader from "../general/Loader";
import {
    formasPagoCompras,
	formatCurrency,
	getCuentasForDropdown,
	getLink,
	isPastDate,
	money,
	renderEstadoBadge,
	toast,
} from "../../utils/helpers";
import Dropdown from "../general/Dropdown";
import PageHeader from "../general/PageHeader";
import FiltersContainer from "../general/FiltersContainer";
import { apiRequest } from "../../hooks/apiRequest";
import Modal from "../general/Modal";
import {
	ChequeInfo,
	DepositoInfo,
	OtroInfo,
	TarjetaInfo,
	TransferenciaInfo,
} from "../Consultas/utils";
import PrefixedInput from "../general/PrefixedInput";
import { METODOS_PAGO } from "../../utils/types";

class Compras extends Component {
	state = {
		compras: [],
		compra: {},
		links: null,
		loading: true,
		showCreate: false,
		showEdit: false,
		showSidePopup: false,
		filtersQuery: `?desde=${dayjs().format("YYYY-MM-DD")}&hasta=${dayjs().format("YYYY-MM-DD")}`,
		filtros: {
			desde: dayjs().format("YYYY-MM-DD"),
			hasta: dayjs().format("YYYY-MM-DD"),
		},
		showEliminarModal: false,
		showPagoModal: false,
		pagoInfo: {
			pago: 0,
			tipo_pago: "efectivo",
			cuenta: "",
		},
	};
	constructor(props) {
		super(props);
		this.sidePopupWrap = React.createRef();
		this.sidePopupOverlay = React.createRef();
	}
	componentDidMount() {
		this.getCompras();
		this.pagoRef = React.createRef();
	}
	getOptions = async (url = null, label = "nombre", value = "id") => {
		const table = await this.props.request(url);
		if (table.data) {
			return table.data.map((item) => ({
				value: item[value],
				label: item[label],
			}));
		}
	};
	getCompras = async (url = null) => {
		url = url ? url : "compras?limit=20";
		const compras = await this.props.request(url);

		if (compras.data) {
			this.setState({
				compras: compras.data,
				meta: compras.meta,
				links: compras.links,
				loading: false,
			});
		}
	};
	onFiltroChange = debounce(async (value, filtro) => {
		if (value && value.value) {
			value = value.value;
		}
		if (value instanceof Array) {
			value = value.map((v) => v.value).join(",");
		}
		if (!value) {
			value = "";
		}

		await this.setState({
			filtros: {
				...this.state.filtros,
				[filtro]: value,
			},
		});

		let query = Object.keys(this.state.filtros).map((fil) => {
			return `${fil}=${this.state.filtros[fil]}`;
		});

		this.setState({ filtersQuery: `?${query.join("&")}` });

		this.getCompras("compras?limit=20&" + query.join("&"));
	}, 500);
	toggleCreateModal = () => {
		this.setState({
			showCreate: !this.state.showCreate,
			compra: {},
		});
	};
	toggleEditModal = (compra) => {
		this.setState({
			showEdit: !this.state.showEdit,
			compra: compra,
		});
	};
	changeItem = (activo) => {
		this.setState({
			activo,
		});
		this.toggleSidePopup(true);
	};
	toggleCancelModal = (compra) => {
		if (this.state.showEliminarModal) {
			this.setState({ showEliminarModal: false, compra: {} });
			return;
		}
		this.setState({ showEliminarModal: true, compra });
	};
	togglePagoModal = (compra) => {
		this.setState((state) => ({
			...state,
			showPagoModal: false,
			pagoInfo: { pago: 0, tipo_pago: "efectivo", cuenta: "" },
		}));
	};
	cancelCompra = async (e, id) => {
		e.preventDefault();

		const data = new FormData(e.target);

		const res = await apiRequest(data, `compras/${id}`, "DELETE");

		if (res.success) {
			toast("Compra Cancelada con exito");
			this.toggleCancelModal();
			this.getCompras("compras" + this.state.filtersQuery + "&limit=20");
			return;
		}

		toast("Error cancelando compra");
	};
	guardarPago = async (e) => {
		e.preventDefault();

		const data = new FormData(e.target);

		const res = await apiRequest(
			data,
			`compras/${this.state.compra?.id}/pagar`,
			"POST"
		);

		if (res.success) {
			toast("Pago agregado exitosamente");
			this.togglePagoModal();
			this.getCompras(this.state.filtersQuery);
			return;
		}

		toast("Error realizando pago");
	};

    displayFilters = () => {
        document.getElementById('mas-filtros').classList.toggle('d-none');
    }

	render() {
		const {
			compras,
			showSidePopup,
			activo,
			loading,
			filtersQuery,
			showEliminarModal,
			showPagoModal,
			pagoInfo,
		} = this.state;

		const itemActivo =
			compras.length > 0
				? compras.filter((compra) => compra.id === activo)[0]
				: null;

		const tipoMap = {
			item: "ITEMS",
			producto: "PRODUCTOS",
			suministro: "SUMINISTRO",
		};

		return (
			<>
				<div>
					<PageHeader title="Compras">
						<div className="col-12 col-md-6 col-lg-3">
							<label
								htmlFor="desde"
								style={{ fontSize: "12px", textTransform: "uppercase" }}
							>
								Consulta
							</label>
							<input
								onChange={(e) =>
									this.onFiltroChange(e.target.value, "super_filter")
								}
								type="search"
								placeholder="Consultar..."
								className="form-control"
								name="super_filter"
							/>
						</div>

						<div className="dropdown m-r-10 d-none d-lg-inline-block">
							<button className="au-btn au-btn-icon au-btn-filter">
								<i className="zmdi zmdi-print"></i> Reportes
							</button>
							<ul
								style={{ right: "0", left: "auto" }}
								className="dropdown-menu"
							>
								<li>
									<Link
										to={getLink(
											`/imprimir/compras${filtersQuery}`
										)}
										target="_blank"
										className="dropdown-item"
									>
										Reporte Compras
									</Link>
								</li>
								<li>
									<Link
										to={getLink(
											`/imprimir/cuadre-compras${filtersQuery}`
										)}
										target="_blank"
										className="dropdown-item"
									>
										Cuadre de Compra
									</Link>
								</li>
								<li>
									<Link
										to={getLink(
											`/imprimir/detalle-compras${filtersQuery}`
										)}
										target="_blank"
										className="dropdown-item"
									>
										Detalle Impuestos
									</Link>
								</li>
							</ul>
						</div>
						<Link
							to={getLink("/compras/crear")}
							className="au-btn au-btn-icon au-btn--green au-btn--small"
						>
							<i className="zmdi zmdi-plus" />
							Nueva Compra
						</Link>
					</PageHeader>
					<FiltersContainer>
						<div className="col">
							<label>Buscar</label>
							<input
								onChange={(e) =>
									this.onFiltroChange(
										e.target.value,
										"buscar"
									)
								}
								type="search"
								placeholder="Buscar..."
								className="form-control"
								name="buscar"
							/>
						</div>
						<div className="col-md-2">
							<label htmlFor="desde">Categorías</label>
							<SelectAsync
								name="categorias"
								isMulti
								cacheOptions
								defaultOptions
                                isClearable
								onChange={(e) =>
									this.onFiltroChange(e, "categorias")
								}
								loadOptions={(e) => {
									return this.getOptions(
										"taxonomias?relacion=compras&buscar=" + e
									);
								}}
							/>
						</div>
						<div className="col-md-2">
							<label htmlFor="desde">Tipo</label>
							<Select
								onChange={(e) =>
									this.onFiltroChange(e?.value, "tipo")
								}
								isClearable
								options={[
									{
										label: "Inventario de Productos",
										value: "producto",
									},
									{
										label: "Inventario Interno",
										value: "item",
									},
									{
										label: "Trabajo/Suministro",
										value: "suministro",
									},
								]}
							/>
						</div>
						<div className="col-md-2">
							<label htmlFor="desde">Desde</label>
							<input
								type="date"
								name="desde"
								id="desde"
								defaultValue={dayjs().format("YYYY-MM-DD")}
								className="form-control"
								onChange={(e) =>
									this.onFiltroChange(e.target.value, "desde")
								}
							/>
						</div>
						<div className="col-md-2">
							<label htmlFor="hasta">Hasta</label>
							<input
								type="date"
								name="hasta"
								id="hasta"
								defaultValue={dayjs().format("YYYY-MM-DD")}
								className="form-control"
								onChange={(e) =>
									this.onFiltroChange(e.target.value, "hasta")
								}
							/>
						</div>
                        <div style={{width: '50px'}} className="filtros-avanzados" onClick={this.displayFilters}>
                            <label>&nbsp;</label>
                            <button className="btn" title="Filtros Avanzados"><i className="zmdi zmdi-filter-list"></i></button>
                        </div>
                        <div id="mas-filtros" className="row col-md-12 mas-filtros d-none m-b-25 m-t-5">
                            <div className="col-md-3">
                                <label htmlFor="desde">Tipo Factura</label>
                                <Select
                                    onChange={(e) =>
                                        this.onFiltroChange(e?.value, "tipo_factura")
                                    }
                                    isClearable
                                    options={[
                                        {
                                            label: "Todas",
                                            value: "todas",
                                        },
                                        {
                                            label: "Proforma",
                                            value: "proforma",
                                        },
                                        {
                                            label: "Comprobante Fiscal",
                                            value: "comprobante",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="col-12 col-md-6 col-lg-3">
                                <label className="orden-filter-label">Forma de Pago</label>
                                <Select
                                    id="forma_pago"
                                    options={formasPagoCompras.map(f => ({
                                        label: f, value: f
                                    }))}
                                    name="forma_pago"
                                    isMulti
                                    isClearable
                                    placeholder="Todas"
                                    onChange={(values) =>
                                        this.onFiltroChange(values, "forma_pago")
                                    }
                                />
                            </div>
                            <div className="col-12 col-md-6 col-lg-3">
                                <label className="orden-filter-label">Estado</label>
                                <Select
                                    id="estado"
                                    options={[
                                        {label: "Todos", value: ''},
                                        {label: "Pendiente", value: 'pendiente'},
                                        {label: "Pagada", value: 'pagada'},
                                    ]}
                                    name="estado"
                                    isMulti
                                    isClearable
                                    placeholder="Todos"
                                    onChange={(values) =>
                                        this.onFiltroChange(values, "estado")
                                    }
                                />
                            </div>
                        </div>
					</FiltersContainer>
					<div className="row">
						<div className="col-md-12">
							<div>
								{loading && <Loader />}
								{!compras.length ? (
									<div>
										{!loading && (
											<div>
												Todavía no hay compras creados.
											</div>
										)}
									</div>
								) : (
									<div className="table-data">
										<table
											className="table table-data2"
											style={{ tableLayout: "fixed" }}
										>
											<thead>
												<tr>
													<th width="130px">Fecha</th>
													<th>No. Factura</th>
													<th>NCF</th>
													<th width="200px">
														Suplidor
													</th>
													<th>Total</th>
													<th>Balance</th>
													<th>Tipo</th>
													<th>Items</th>
													<th width="130px">Vence</th>
													<th width="130px">
														Estado
													</th>
												</tr>
											</thead>
											<tbody>
												{compras.map((compra) => (
													<tr
														className="tr-shadow"
														key={compra.id}
													>
														<td>
															{dayjs(
																compra.fecha
															).format(
																"DD/MM/YYYY"
															)}
														</td>
														<td>{compra.numero}</td>
														<td>{compra.ncf}</td>
														<td>
															<Link
																to={getLink(
																	`/contactos?id=${compra.contactoId}`
																)}
															>
																{
																	compra.contacto
																}
															</Link>
														</td>

														<td>
															{formatCurrency(
																money(compra.total)
															)}
														</td>
														<td>
															{formatCurrency(
																money(compra.porPagar)
															)}
														</td>
														<td>
															{
																tipoMap[
																	compra.tipo
																]
															}
														</td>
														<td>{compra.items}</td>
														<td>
															{compra.fechaVencimiento
																? dayjs(
																		compra.fechaVencimiento
																  ).format(
																		"DD-MM-YYYY"
																  )
																: "N/A"}
														</td>
														<td>
															{renderEstadoBadge(
																compra.estado
															)}
														</td>
														<td>
															<Dropdown classes={{dropdownMenu: "dropdown-menu-right"}}>
																{compra.estado ===
																	"pendiente" &&
																	!isPastDate(
																		compra.fecha
																	) && (compra.porPagar === compra.total) && (
																		<button
																			onClick={() => {
																				this.setState(
																					(
																						prevState
																					) => ({
																						...prevState,
																						compra,
																						showPagoModal: true,
																					})
																				);
																			}}
																			className="dropdown-item"
																			type="button"
																		>
																			Pagar
																		</button>
																	)}
																{compra.estado ===
																	"pendiente" && (
																	<Link
																		to={getLink(
																			`/compras/${compra.id}`
																		)}
																		className="dropdown-item"
																		type="button"
																	>
																		Editar
																	</Link>
																)}

																<Link
																	to={getLink(
																		`/imprimir/compras/${compra.id}`
																	)}
																	className="dropdown-item"
																	type="button"
																	target="_blank"
																>
																	Impresión
																</Link>

                                                                {compra.facturaCompra?.length > 0 && (
                                                                    <a
                                                                        href={compra.facturaCompra[0].url}
                                                                        className="dropdown-item"
                                                                        target="_blank"
                                                                    >
                                                                        Imprimir Factura Original
                                                                    </a>
                                                                )}

																<button
																	onClick={() =>
																		this.toggleCancelModal(
																			compra
																		)
																	}
																	className="dropdown-item"
																>
																	Eliminar
																</button>
															</Dropdown>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										{this.state.links && (
											<Pagination
												links={this.state.links}
												meta={this.state.meta}
												getData={this.getCompras}
											/>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
					{showSidePopup && (
						<div className="side-popup">
							<div
								onClick={() => this.toggleSidePopup(false)}
								ref={this.sidePopupOverlay}
								className="side-popup-background animated fadeIn"
							></div>
							<div
								ref={this.sidePopupWrap}
								className="side-popup-container animated slideInRight fast"
							>
								<h2>
									{itemActivo.nombre}{" "}
									<button
										className="btn btn-primary float-right compra"
										data-toggle="tooltip"
										data-placement="top"
										title=""
										data-original-title="Edit"
										onClick={() => {
											this.toggleEditModal(itemActivo);
										}}
									>
										<i className="zmdi zmdi-edit" />
									</button>
								</h2>
								<br />
								<table className="table">
									<tbody>
										<tr>
											<td>Encargado</td>
											<td>{itemActivo.encargado}</td>
										</tr>
										<tr>
											<td>Tipo</td>
											<td>{itemActivo.tipo}</td>
										</tr>
										<tr>
											<td>Teléfono</td>
											<td>{itemActivo.telefono}</td>
										</tr>
										<tr>
											<td>Celular</td>
											<td>{itemActivo.celular}</td>
										</tr>
										<tr>
											<td>Cédula/RNC</td>
											<td>{itemActivo.rnc}</td>
										</tr>
										<tr>
											<td>Dirección</td>
											<td>{itemActivo.direccion}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					)}
				</div>
				{/* DELETE CONFIRMATION MODAL */}
				<Modal
					show={showEliminarModal}
					toggle={() => this.toggleCancelModal()}
					title="Cancelar compra"
					blank={true}
				>
					<form
						onSubmit={(e) =>
							this.cancelCompra(e, this.state?.compra?.id)
						}
					>
						<div className="popup__header">Eliminar Compra</div>
						<div className="popup__body">
							<p className="m-b-20">
								Esta acción no puede ser revertida. Si deseas
								continuar por favor indica la razón por la cual
								deseas eliminarla:
							</p>
							<textarea
								className="form-control"
								name="descripcion"
								required
								rows="5"
							></textarea>
						</div>
						<div className="popup__footer">
							<button
								onClick={() => this.toggleCancelModal()}
								type="button"
								className="btn btn-plain"
							>
								Cancelar
							</button>
							<button type="submit" className="btn btn-primary">
								Eliminar
							</button>
						</div>
					</form>
				</Modal>
				{/* ADD PAYMENT MODAL */}
				<Modal
					title="Agregar Pago"
					show={showPagoModal}
					callback={() => {
						if (!pagoInfo.cuenta) {
							toast("Seleccione cuenta para continuar", "error");
							return;
						} else if (!pagoInfo.pago || pagoInfo.pago <= 0) {
							toast("Agregue el monto a pagar", "error");
							return;
						}
						this.togglePagoModal();
						this.pagoRef.current.dispatchEvent(
							new Event("submit", {
								bubbles: true,
								cancelable: true,
							})
						);

						// this.guardarPago("pagar");
					}}
					acceptText="Aceptar"
					toggle={() => this.togglePagoModal()}
					size="419px"
				>
					<form ref={this.pagoRef} onSubmit={this.guardarPago}>
						<div className="form-group">
							<label>Tipo de pago</label>
							<select
								className="form-control"
								name="metodo_pago[tipo_pago]"
								value={pagoInfo.tipo_pago}
								onChange={(e) => {
									e.persist();
									this.setState((prevState) => ({
										...prevState,
										pagoInfo: {
											...prevState.pagoInfo,
											terminal_tarjeta: "",
											tipo: "visa",
											banco: "",
											num_aprobacion: "",
											num_cheque: "",
											num_transferencia: "",
											num_transaccion: "",
											num_deposito: "",
											tipo_pago: e.target.value,
										},
									}));
								}}
							>
								{METODOS_PAGO.map((metodo) => (
									<option key={metodo.value} value={metodo.value}>
										{metodo.label}
									</option>
								))}
							</select>
						</div>

						{pagoInfo.tipo_pago === "cheque" && <ChequeInfo />}
						{pagoInfo.tipo_pago === "tarjeta" && <TarjetaInfo />}
						{pagoInfo.tipo_pago === "transferencia" && (
							<TransferenciaInfo />
						)}
						{pagoInfo.tipo_pago === "deposito" && <DepositoInfo />}
						{pagoInfo.tipo_pago === "otro" && <OtroInfo />}

						<div className="form-group">
							<label>Cuenta</label>
							<SelectAsync
								name="metodo_pago[cuenta]"
								cacheOptions
								defaultOptions
								loadOptions={() => getCuentasForDropdown()}
								onChange={(e) => {
									this.setState((prevState) => ({
										...prevState,
										pagoInfo: {
											...prevState.pagoInfo,
											cuenta: e,
										},
									}));
								}}
								value={pagoInfo.cuenta}
							/>
						</div>

						<div className="form-group">
							<label>Monto Recibido</label>
							<PrefixedInput
								type="number"
								prefix="RD$"
								name="metodo_pago[pago]"
								value={pagoInfo.pago}
								setValue={(e) =>
									this.setState((prevState) => ({
										...prevState,
										pagoInfo: {
											...prevState.pagoInfo,
											pago: e,
										},
									}))
								}
                                onBlur={e => {
                                    if (e < this.state.compra?.porPagar) {
                                        this.setState((prevState) => ({
                                            ...prevState,
                                            pagoInfo: {
                                                ...prevState.pagoInfo,
                                                pago: '',
                                            },
                                        }));
                                        toast('El monto debe ser mayor o igual al total de la compra.', 'error');
                                    }
                                }}
							/>
						</div>
						<div
							className="row"
							style={{ justifyContent: "space-around" }}
						>
							<div
								className="col-5 text-center"
								style={{
									border: "1px solid #cdd2d7",
									borderRadius: "7px",
									paddingTop: "8px",
									paddingBottom: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									this.setState((prevState) => ({
										...prevState,
										pagoInfo: {
											...prevState.pagoInfo,
											pago: money(
												this.state.compra?.porPagar
											),
										},
									}));
								}}
							>
								<h5 className="mb-2">Total Factura:</h5>
								<p>
									{formatCurrency(
										money(this.state.compra?.porPagar)
									)}
								</p>
							</div>
							<div
								className="col-5 text-center"
								style={{
									border: "1px solid #cdd2d7",
									borderRadius: "7px",
									paddingTop: "8px",
									paddingBottom: "8px",
								}}
							>
								<h5 className="mb-2">Devuelta:</h5>
								<p>
									{pagoInfo.pago > this.state.compra?.porPagar
										? formatCurrency(
												pagoInfo.pago -
													this.state.compra?.porPagar
										  )
										: 0}
								</p>
							</div>
						</div>
					</form>
				</Modal>
			</>
		);
	}
}

export default withRequest(Compras);
