import React, { Component } from "react";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { apiUrl } from "../../utils/apiUrl";
import { toast } from "../../utils/helpers";
import Loader from "../general/Loader";

class Edit extends Component {
	state = {
		errors: {},
        isLoading: false,
	};
	editUser = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		const token = auth.getToken();
		const data = new FormData(event.target);

		const res = await fetch(apiUrl(`contactos/${this.props.contacto.id}`), {
			method: "post",
			body: data,
			headers: {
				Accept: "application/json",
				"X-Request-With": "XMLHttpRequest",
				Authorization: "Bearer " + token,
			},
		});

		const response = await res.json();

		if (response.success) {
			if (this.props.fromFacturacion) {
				this.props.toggle(null, response.data);
				return;
			}
			this.props.getContactos();
			this.props.toggle();
			toast("Contacto actualizado exitosamente.");
		}

		if (response.code === 422) {
			this.setState({ errors: response.data.message });
			toast("No se pudo actualizar el contacto.", "error");
		}
		this.setState({ isLoading: false });
	};
	render() {
		const { errors, isLoading } = this.state;
		const { toggle, contacto } = this.props;
		return (
			<form method="post" onSubmit={this.editUser}>
				<input type="hidden" name="_method" value="patch" />
				<div className="popup__header">Editar Contacto</div>
				<div className="popup__body">
					<Form contacto={contacto} errors={errors} />
				</div>
				<div className="popup__footer">
					<button
						type="button"
						onClick={toggle}
						className="btn btn-plain"
					>
						Cancelar
					</button>
					{isLoading ? (
						<Loader type="small" />
					) : (
						<button type="submit" className="btn btn-primary">
							Guardar
						</button>
					)}
				</div>
			</form>
		);
	}
}

export default Edit;
