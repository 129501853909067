import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import ReactSelect from "react-select";

import { auth } from "../../utils/auth";
import { getLink, getSucursal } from "../../utils/helpers";
import { withRequest } from "../../utils/request";

class DesktopHeader extends Component {
    setSucursal = async (sid) => {
        const res = await this.props.request("sset?sid=" + sid);

        if (res.success) {
            for (var i = 0; i < localStorage.length; i++){
                console.log({key: localStorage.key(i)})
                if (localStorage.key(i) !== 'user') {
                    localStorage.removeItem(localStorage.key(i));
                }
            }
            window.localStorage.setItem("sucursalActual", res.data.id);
            window.localStorage.setItem("config", JSON.stringify(res.data.config));
            window.location = "/" + res.data.id;
        }
    };
    render() {
        const usuario = auth.getUser();
        const sucursalActual = usuario.sucursales.filter(s => s.id === parseInt(getSucursal()))[0];
        return (
            <header className='header-desktop' style={{zIndex: 300}}>
                <div className='section__content section__content--p30'>
                    <div className='container-fluid'>
                        <div className='header-wrap'>
                            <div className='logo'>
                                <img src='/images/icon/logo-recolector.png' alt='SAE Logo' />
                            </div>
                            <div className="nombre-sucursal">
                                {sucursalActual.nombre}
                            </div>
                            {auth.can("ver-reportes") && (
                                <div className='reportes text-right'>
                                    <button
                                        type='button'
                                        className='au-btn au-btn-icon au-btn-filter m-r-10'
                                        onClick={() =>
                                            this.props.toggleModal(
                                                "showReportes"
                                            )
                                        }>
                                        <i className='fas fa-file-alt' />
                                        Reportes
                                    </button>
                                </div>
                            )}
                            <div
                                className='header-button'
                                style={{flex: !auth.can("ver-reportes") && 1}}
                                onClick={() =>
                                    this.props.toggleModal(
                                        "showReportes",
                                        false
                                    )
                                }>
                                <div
                                    className='account-wrap'
                                    style={{marginLeft: !auth.can("ver-reportes") && 'auto'}}
                                    onBlur={this.onClickUserMenu}>
                                    <UserDropDown
                                        setSucursal={this.setSucursal}
                                        history={this.props.history}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

class UserDropDown extends Component {
    state = {
        show: false,
    };
    UNSAFE_componentWillMount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick, false);
    }
    handleClick = (e) => {
        if (this.node.contains(e.target)) {
            this.setState({ show: true });
            return;
        }
        this.setState({ show: false });
    };
    render() {
        const {setSucursal} = this.props;
        const dropdownClass = this.state.show ? "show-dropdown" : "";
        const usuario = auth.getUser();
        return (
            <div
                className={`account-item clearfix js-item-menu ${dropdownClass}`}
                ref={(node) => (this.node = node)}>
                <div className='content'>
                    <span className='js-acc-btn'>
                        Hola, {usuario.nombre}{" "}
                        <i className='fas fa-angle-down'></i>
                    </span>
                </div>
                <div className='account-dropdown js-dropdown'>
                    <div className='info clearfix'>
                        <div className='content'>
                            <h5 className='name'>
                                {`${usuario.nombre} ${usuario.apellido}`}
                            </h5>
                            <span className='email'>{usuario.email}</span>
                        </div>
                    </div>

                    <div className='account-dropdown__body'>
                        {usuario.sucursales.length > 1 && (
                            <div className="account-dropdown__item p-l-20 p-r-20">
                                <ReactSelect
                                    onChange={e => setSucursal(e.value)}
                                    placeholder="Cambiar Sucursal"
                                    options={usuario.sucursales.map(s => ({
                                        label: s.nombre,
                                        value: s.id
                                    }))}
                                />
                            </div>
                        )}
                    </div>
                    <div className='account-dropdown__footer'>
                        {auth.can("ver-configuracion") && (
                            <div className='account-dropdown__item'>
                                <Link to={getLink("/configuracion")}>
                                    <i className='zmdi zmdi-settings'></i>
                                    Configuración
                                </Link>
                            </div>
                        )}
                    </div>

                    <div className='account-dropdown__footer'>
                        <button onClick={auth.logout}>
                            <i className='zmdi zmdi-power'></i>Logout
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(DesktopHeader));
