import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../../utils/request";
import { auth } from "../../utils/auth";
import { formatCurrency, getModalidadAusencia } from "../../utils/helpers";
import dayjs from "dayjs";

const useData = () => {
    const { id } = useParams();
    const [ausencia, setAusencia] = useState();

    const getAusencia = useCallback(() => {
		request(`ausencias/${id}`).then((res) => {
			setAusencia(res.data);
		});
	}, [id]);

    useEffect(() => {
        getAusencia();
    }, []);

    useEffect(() => {
		if (!ausencia?.id) return;

		const timeout = setTimeout(() => {
			window.print();
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [ausencia]);

    return useMemo(
		() => ({
			ausencia,
		}),
		[ausencia]
	);
};

const PrintSingleAusencia = () => {
    const { ausencia } = useData();

    const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];

    return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Ausencia
				</h2>
				
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{dayjs(ausencia?.fechaCreacion).format("DD/MM/YYYY hh:mm a")}
				</div>
			</div>

			<div className="row mt-5">
				<h3 className="col-md-12 text-center m-b-5">
					{ausencia?.empleado?.nombreCompleto}
				</h3>
			</div>

			<div className="row mt-5 ml-2">
				<table className="table m-b-15">
					<tr>
						<td><strong>Tipo</strong></td>
						<td>{ausencia?.tipo?.nombre}</td>
						<td><strong>Cantidad Días</strong></td>
						<td>{ausencia?.cantidad_dia}</td>
					</tr>

					<tr>
						<td><strong>Desde</strong></td>
						<td>{dayjs(ausencia?.desde).format("DD/MM/YYYY")}</td>
						<td><strong>Hasta</strong></td>
						<td>{dayjs(ausencia?.hasta).format("DD/MM/YYYY")}</td>
					</tr>

					{ ausencia?.tipo_ausencia_id === 2 && <tr>
						<td><strong>Modalidad</strong></td>
						<td>{getModalidadAusencia(ausencia?.modalidad)?.label}</td>
						<td><strong>Monto a Pagar</strong></td>
						<td>{formatCurrency(ausencia?.monto_pagar)}</td>
					</tr> }

					<tr>
						<td><strong>Descontar Vacaciones</strong></td>
						<td>{ausencia?.descontar_vacaciones ? "Si" : "No"}</td>
						<td><strong>Descripción</strong></td>
						<td style={{maxWidth: '256px', padding: '5px', overflowWrap: 'break-word'}}>{ausencia?.descripcion}</td>
					</tr>
					<tr><td colSpan={4}></td></tr>
				</table>
			</div>

			{ausencia?.notas && <div className="row mt-2 ml-2">
				<p style={{whiteSpace: "pre-wrap"}}>
					<strong>Notas: </strong> {ausencia?.notas}
				</p>
			</div>}

			<div className="row mt-5 ml-2">
				<table className="table m-b-15">
						<tr>
							<td className="text-center">{ausencia?.empleado?.nombre} {ausencia?.empleado?.apellido}</td>
						</tr>
				</table>
			</div>
        </>
    );
}

export default PrintSingleAusencia;