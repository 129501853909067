import React, { Component } from "react";
import Select from "react-select";

class Form extends Component {
    state = {
        selectedTipos: [],
        periodo: '',
    }

    componentDidMount() {
        this.setState({
            periodo: this.props.grupo?.periodo || '',
            selectedTipos: this.props.grupo.tipos?.map(t => t.id) || [],
        })
    }

    onChangeTipos = (values) => {
        if (values) {
            this.setState({selectedTipos: values.map(i => i.value)});
        } else {
            this.setState({selectedTipos: []});
        }
    }

    onChangePeriodo = (data) => {
        if (data) {
            this.setState({periodo: data.value});
        } else {
            this.setState({periodo: ''});
        }
    }

    render() {
        const { selectedTipos, periodo } = this.state;
        const { grupo, tipos, errors, edit } = this.props;
        return (
            <div>
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Nombre:
                        </label>
                    </div>
                    <div className='col col-md-7'>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='nombre'
                            name='nombre'
                            defaultValue={grupo.nombre}
                        />
                        {errors.nombre && (
                            <small className='help-blockParams form-Text'>
                                {errors.nombre[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Periodo:
                        </label>
                    </div>
                    <div className="col col-md-7">
                        <Select
                            onChange={this.onChangePeriodo}
                            name="periodo"
                            defaultValue={{
                                value: grupo.periodo,
                                label: grupo.periodo
                            }}
                            options={[
                                {value: 'semanal', label: 'Semanal'},
                                {value: 'quincenal', label: 'Quincenal'},
                                {value: 'mensual', label: 'Mensual'},
                            ]}></Select>
                    </div>
                </div>
                {edit && (
                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>
                                Estado:
                            </label>
                        </div>
                        <div className="col col-md-7">
                            <Select
                                name="estado"
                                defaultValue={{
                                    value: grupo.estado,
                                    label: grupo.estado
                                }}
                                options={[
                                    {value: 'activo', label: 'Activo'},
                                    {value: 'inactivo', label: 'Inactivo'},
                                ]}></Select>
                        </div>
                    </div>
                )}
                <div className='row form-group' style={{marginBottom: 120}}>
                    <div className='col col-md-5'>
                        <label className='label form-control-label'>
                            Tipos:
                        </label>
                    </div>
                    <div className="col col-md-7">
                        <Select
                            isLoading={tipos.length === 0}
                            isMulti
                            loadingMessage={() => "Cargando Tipos.."}
                            onChange={this.onChangeTipos}
                            defaultValue={grupo.tipos && grupo.tipos.map(t => ({value: t.id, label: t.nombre}))}
                            options={tipos.filter(p => p.intervaloNomina === periodo)
                                .map(tipo => ({
                                    value: tipo.id,
                                    label: `${tipo.nombre}`
                            }))}></Select>
                            <input
                                type='hidden'
                                name='tipos'
                                required
                                value={selectedTipos.join(",")}
                            />
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
