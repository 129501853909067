import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { auth } from "../../../../utils/auth";
import { request } from "../../../../utils/request";
import { formatCurrency } from "../../../../utils/helpers";


const PrintInscripciones = () => {
    const [inscripciones, setInscripciones] = useState([]);

    const getInscripciones = async () => {
        request("academico/inscripciones-cocurriculares" + window.location.search).then(response => {
            if (response.data) {
                setInscripciones(response.data);
                window.print();
            }
        });
    };

    useEffect(() => {
        getInscripciones();
    }, []);

    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
        (suc) => suc.id === user.sucursalActual
    )[0];

    const clases = inscripciones.reduce((acc, inscripcion) => {
        if (acc[inscripcion.clase.nombre]) {
            acc[inscripcion.clase.nombre].push(inscripcion);
        } else {
            acc[inscripcion.clase.nombre] = [];
            acc[inscripcion.clase.nombre].push(inscripcion);
        }
        return acc;
    }, {});

    return (
        <div>
            <div className='row'>
                <h2 className='col-md-12 text-center m-b-5'>
                    Inscripciones Cocurriculares
                </h2>
                <div className='col-md-12 text-center'>
                    {sucursal.nombre} - {sucursal.direccion} •{" "}
                    {new Date().toLocaleString()}
                </div>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'>
                        TOTAL INSCRIPCIONES: {inscripciones.length}
                    </div>

                    <div className='float-right'>
                        <h3>INSCRIPCIONES</h3>
                    </div>
                </div>
            </div>

            {Object.keys(clases).map(clase => {
                const estudiantes = clases[clase];
                return (
                    <>
                        <h3>{clase} <span className="float-right">TOTAL: {estudiantes.length}</span></h3>
                        <table className='table m-b-40'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nombre</th>
                                    <th>Tipo</th>
                                    <th>Fecha</th>
                                    <th>Clase</th>
                                    <th>Año</th>
                                    <th>Teléfono</th>
                                    <th>Estado</th>
                                    <th>Pendiente</th>
                                </tr>
                            </thead>
                            <tbody>
                                {estudiantes.map((inscripcion, index) => {
                                    return (
                                        <tr key={inscripcion.id}>
                                            <td>{index+1}</td>
                                            <td>{inscripcion.contacto?.nombre}</td>
                                            <td>{inscripcion.contacto?.estudiante ? 'Estudiante' : 'Cliente'}</td>
                                            <td>{dayjs(inscripcion.fecha).format('DD-MM-YYYY')}</td>
                                            <td>{inscripcion.clase?.nombre}</td>
                                            <td>{inscripcion.ano}</td>
                                            <td>{inscripcion.contacto?.telefono}</td>
                                            <td>{inscripcion.estado}</td>
                                            <td>{formatCurrency(inscripcion.pendiente)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                );
            })}
            <div className='row'>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'>
                        TOTAL INSCRIPCIONES: {inscripciones.length}
                    </div>

                    <div className='float-right'>
                        <h3>INSCRIPCIONES</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrintInscripciones;
