import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withRequest } from "../../../utils/request";
import { formatCurrency } from "../../../utils/helpers";
import queryString from "query-string";
import { auth } from "../../../utils/auth";
import TableOfProducts from './TableOfCatalogueProducts';

class CatalogueOfProducts extends Component {
    state = {
        productos: [],
        catalogueType: '',
        groups: [],
        nameOrCOde: false,
        productsExist: false,
        showCantOfProducts: false,
        query: {}
    };

    componentDidMount() {
        this.getProductos();
        this.getQuery();
    }

    getProductos = async () => {
        const response = await this.props.request(
            "productos" + window.location.search
        );

        if (response.data) {
            this.setState({
                productos: response.data,
            });

            setTimeout(() => {
                window.print()
            }, 200)
        }
    };

    getQuery = () => {
        this.setState({
            ...this.state,
            query: queryString.parse(window.location.search)
        });
    }

    render() {
        const divStyle={pageBreakInside: 'avoid'};
        const { productos, query } = this.state;
        const categorias = query.catalogueType !== 'listado' 
        ? productos
        .filter(product => product.fotos.length > 0 && product.fotos[0].url)
        .reduce((acc, current) => {
            if (!acc[current.grupo?.nombre]) {
                acc[current.grupo?.nombre] = [];
            }
            acc[current.grupo?.nombre].push(current);
            return acc;
        }, {})
        : productos.reduce((acc, current) => {
            if (!acc[current.grupo?.nombre]) {
                acc[current.grupo?.nombre] = [];
            }
            acc[current.grupo?.nombre].push(current);
            return acc;
        }, {});
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        return (
            <div>
                <style type="text/css" media="print">{query.catalogueType === 'listado' ? `@page {size: portrait;}` : `@page {size: A4 landscape;}`}</style>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-20'>
                        Catálogo de Productos
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {sucursal.telefono}
                    </div>
                    {query.catalogueType === 'listado' && 
                    <TableOfProducts
                        categorias={categorias}
                        precioVenta={query?.precio_venta}
                        showCantOfProducts={query?.showCantOfProducts ? true :false}
                     />}
                    {query.catalogueType !== 'listado' && Object.keys(categorias).map(categoria => (
                        <div className="col-md-12" >
                            <h3>Categoria: {categoria}</h3>
                            <div className="row">
                                {categorias[categoria].map((product) => (
                                    <div className='col-md-3' style={divStyle} key={product.id}>
                                        <div className="card" style={{border: '1px solid #d3d7db',borderRadius: '7%', padding: '10px'}}>
                                            {
                                                <img 
                                                    src={product.fotos[0].url}
                                                    className='card-img-top'
                                                    style={{width: "50%", height: "140px", marginLeft: "auto", marginRight: "auto"}}
                                                    alt="imagen"
                                                />
                                            }
                                            <div className="card-body" style={{paddingBottom: "0px", padding: "2px"}}>
                                                {query?.nameOrCode && <p className="font-weight-bold" style={{margin:"-3px"}}>{product.nombre}</p>}
                                                {query?.nameOrCode && <p style={{margin:"-3px"}}>{product.referencia}</p>}
                                                {query?.showCantOfProducts &&
                                                 <p style={{margin:"-3px"}}>Cantidad: {product.cantidad}</p>
                                                }
                                                {query?.precio_venta && <h5 className="card-title" style={{marginBottom:"2px"}}>{formatCurrency(product.precios[(query?.precio_venta || 1) -  1]?.total || product.precio)}</h5>}
                                                <p>{product.descripcion}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(CatalogueOfProducts));