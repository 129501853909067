import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import dayjs from "dayjs";
import { formatCurrency, getLink } from "../../../utils/helpers";

class PrintReciboDescuento extends Component {
    state = {
        descuento: {},
        cuota: {},
    };
    componentDidMount() {
        this.getTicket();
    }
    getTicket = async () => {
        const id = this.props.match.params.id;
        const cuota = this.props.match.params.cuota;
        const response = await this.props.request("descuentos/" + id);

        if (response.success) {
            this.setState({
                descuento: response.data,
                cuota: response.data.cuotas.filter(c => c.id === parseInt(cuota))[0]
            });
            window.print();
        }
    };
    render() {
        const { descuento, cuota } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const comentario = cuota.comentarios ? cuota.comentarios[0] : {};
        const autor = comentario?.user || '';

        const cuotasPendientes = descuento.cuotas?.filter(c => c.estado === 'Pendiente');
        const cuotasCompletadas = descuento.cuotas?.filter(c => c.estado === 'Pagada');
        const proxCuota = cuotasPendientes?.length && dayjs(
            cuotasPendientes[0].fecha
        ).format("DD MMM, YYYY");

        let completadas = [];
        if (proxCuota) {
            completadas = cuotasCompletadas.filter(c => {
                return cuotasPendientes?.length && dayjs(c.fecha).isBefore(proxCuota);
            });
        } else {
            completadas = cuotasCompletadas;
        }

        const ultimaCuota = completadas?.length && dayjs(
            completadas[0].fecha
        ).format("DD MMM, YYYY");

        return (
            <div className="p-2 invoice recibo-print">
                {/* -----MOBILE---- */}
                <div className="d-block d-sm-none">
                    <div className="col-12 text-center">
                        {sucursal.logo ? (
                            <img
                                alt="logo"
                                className="mb-2 factura-logo"
                                src={sucursal.logo[0].url}
                                width="270px"
                            />
                        ) : (
                            <h3
                                style={{
                                    lineHeight: "12px",
                                    marginBottom: "3px",
                                }}
                            >
                                {sucursal.nombre}
                            </h3>
                        )}
                        <div className="col-md-10">
                            {sucursal.direccion} <br />{" "}
                            {new Date().toLocaleString()}
                        </div>
                    </div>
                    <h3 className="col-md-12 text-center m-b-5 mt-3">
                        Recibo de Ingreso
                    </h3>

                    <div className="d-flex flex-column align-items-center">
                        <div className="row print-transaction__container col-9 py-2 mt-2">
                            <div className="col-12 col-sm-6 print-transaction__title">
                                Fecha:
                            </div>
                            <div className="col-12 col-sm-6 print-transaction__body">
                                {dayjs(cuota.fecha).format(
                                    "DD/M/YYYY hh:mm a"
                                )}
                            </div>
                            <div className="col-12 col-sm-6 print-transaction__body">
                                NO.: {cuota.id}
                            </div>
                        </div>
                        <div className="row print-transaction__container col-9 py-2">
                            <div className="col-12 col-sm-6 print-transaction__title">
                                REALIZADO POR:
                            </div>
                            <div className="col-12 col-sm-6 print-transaction__body">
                                {autor}
                            </div>
                        </div>
                        {/* <div className="row print-transaction__container col-9 py-2">
                            <div className="col-12 col-sm-6 print-transaction__title">
                                Monto:
                            </div>
                            <div
                                className="col-12 col-sm-6 print-transaction__body"
                                style={{ fontSize: "1.2rem" }}
                            >
                                {formatCurrency(parseFloat(cuota.monto))}
                            </div>
                        </div> */}
                        <div className="row print-transaction__container col-9 py-2">
                            <div className="col-12 col-sm-6 print-transaction__title">
                                Concepto:
                            </div>
                            <div className="col-12 col-sm-6 print-transaction__body">
                                {comentario ? comentario.descripcion : descuento.descripcion}
                            </div>
                        </div>
                        {/* <div className="clear-both info-row p-t-10 p-b-10 d-none d-sm-flex col-10">
                            <div className="info-block" style={{ width: "65%" }}>
                                <table>
                                    <tbody id="info-block-1">
                                            <tr>
                                                <td
                                                    width="320px"
                                                    className="client-info-title text-uppercase text-right"
                                                >
                                                    Tipo:
                                                </td>
                                                <td
                                                    style={{
                                                        lineHeight: "13px",
                                                        paddingLeft: "10px",
                                                    }}
                                                >
                                                    {cuota.tipo}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="client-info-title text-uppercase text-right">
                                                    Desde Cuenta:
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    {cuenta.nombre}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="client-info-title text-uppercase text-right">
                                                    Hasta Cuenta:
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    {cuota.cuenta?.nombre}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="client-info-title text-uppercase text-right">
                                                    Concepto:
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    {cuota.descripcion}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="client-info-title text-uppercase text-right">
                                                    Dirección:
                                                </td>
                                                <td
                                                    style={{
                                                        lineHeight: "13px",
                                                        paddingLeft: "10px",
                                                    }}
                                                >
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* ---DESKTOP--- */}
                <div className="d-none d-sm-block">
                    <div className="row pt-4">
                        <div className="col-7">
                            {sucursal.logo ? (
                                <img
                                    alt="logo"
                                    className="mb-2 factura-logo"
                                    src={sucursal.logo[0].url}
                                    width="270px"
                                />
                            ) : (
                                <h3
                                    style={{
                                        lineHeight: "12px",
                                        marginBottom: "3px",
                                    }}
                                >
                                    {sucursal.nombre}
                                </h3>
                            )}
                            <div className="col-md-10">
                                {sucursal.direccion} <br />{" "}
                                {new Date().toLocaleString()}
                            </div>
                        </div>
                        <div className="col">
                            <h3
                                style={{
                                    fontSize: "1.2rem",
                                    marginBottom: "3px",
                                    paddingBottom: "10px",
                                    whiteSpace: "nowrap",
                                    paddingLeft: "40%",
                                }}
                                className="text-right"
                            >
                                Recibo de Pago
                            </h3>
                        </div>
                        {/* <div className="col-4 d-flex align-items-center">
                            <h3
                                style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "600",
                                    paddingLeft: "15px",
                                }}
                            >
                                monto: {formatCurrency(cuota.monto)}
                            </h3>
                        </div> */}
                    </div>

                    <div className="clear-both row">
                        <div className="col">
                            <h3 className="client-info-title text-uppercase">
                                <strong>Pago de Cuota</strong>
                            </h3>
                        </div>
                        <div
                            className="col info-block"
                        >
                            <table>
                                <tbody id="info-block-2">
                                    <tr>
                                        <td
                                            width="145px"
                                            className="client-info-title text-uppercase text-right"
                                            colSpan={2}
                                        >
                                            FECHA:
                                        </td>
                                        <td
                                            style={{
                                                lineHeight: "13px",
                                                paddingLeft: "10px",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {dayjs(cuota.fecha).format(
                                                "DD/M/YYYY hh:mm a"
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            width="145px"
                                            className="client-info-title text-uppercase text-right"
                                            colSpan={2}
                                        >
                                            NO.:
                                        </td>
                                        <td
                                            style={{
                                                lineHeight: "13px",
                                                paddingLeft: "10px",
                                            }}
                                        >
                                            {cuota.id}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            width="145px"
                                            className="client-info-title text-uppercase text-right"
                                            colSpan={2}
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            REALIZADA POR:
                                        </td>
                                        <td
                                            style={{
                                                lineHeight: "13px",
                                                paddingLeft: "10px",
                                            }}
                                        >
                                            {autor}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row m-t-20 m-b-20">
                    <div className="col-4">
                        <small>Empleado</small>
                        <div>
                            <strong>
                                { descuento.empleado }
                            </strong>
                        </div>
                        {descuento.telefono && <div>
                            Tel: {descuento.telefono}
                        </div>}
                    </div>
                    <div className="col">
                        <small>
                            Frecuencia: {
                                descuento.frecuencia
                            }
                        </small>
                        <div className="descuento__descripcion">
                            {
                                descuento.descripcion
                            }
                        </div>
                    </div>
                    <div className="col">
                        <small>Total Préstamo</small>
                        <div><strong>{formatCurrency(descuento.montoTotal)}</strong></div>
                        # Cuotas: {descuento.cuotas ? descuento.cuotas?.length : 1}
                    </div>
                    <div className="col">
                        <small>Monto Couta</small>
                        <div><strong>{formatCurrency(descuento.monto)}</strong></div>
                        Pendientes: {descuento.pendientes}
                    </div>
                    <div className="col">
                        <div className="d-flex justify-content-end">
                            {completadas?.length > 0 && <div style={{minWidth: "120px"}}>
                                <small>Último:{' '}</small>
                                <div>
                                    <strong>{ultimaCuota}</strong>
                                </div>
                            </div>}
                            {cuotasPendientes?.length > 0 && <div style={{minWidth: "120px"}}>
                                <small>Próximo:{' '}</small>
                                <div>
                                    <strong>{proxCuota}</strong>
                                </div>
                            </div>}
                            {descuento.frecuencia === 'Unico' && <div style={{minWidth: "120px"}}>
                                <small>Fecha:{' '}</small>
                                <div>
                                    <strong>{dayjs(descuento.fecha).format("DD MMM, YYYY")}</strong>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="info-block" style={{ width: "65%" }}>
                    <div><strong>Detalle:</strong></div>
                    <div>{comentario ? comentario.descripcion : descuento.descripcion}</div>
                </div>
                <table className="m-t-40">
                    <tbody>
                        <tr>
                            <td>
                                <hr />
                                <div className="recibo-firma text-center">Entregado por</div>
                            </td>
                            <td></td>
                            <td>
                                <hr />
                                <div className="recibo-firma text-center">Recibido por</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintReciboDescuento));
