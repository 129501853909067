import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import { getLink } from "../../../utils/helpers";
import Pagination from "../../general/Pagination";
import Loader from "../../general/Loader";
import { request } from "../../../utils/request";
import Modal from "../../general/Modal";
import Create from "./Create";
import Edit from "./Edit";

const TerminoPago = () => {
	const [terminos, setTerminos] = useState({
		data: [],
		links: null,
		meta: null,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [showCreateModal, setCreateModal] = useState(false);
	const [showEditModal, setEditModal] = useState(false);
	const [terminoToEdit, setTerminoToEdit] = useState();
	const [filtrosQuery, setFiltrosQuery] = useState('');
	const [filtros, setFiltros] = useState({
		buscar: "",
		status: "todos",
	});

	const toggleCreateModal = () => {
		setCreateModal((state) => !state);
	};

	const toggleEditModal = (termino) => {
		setEditModal((state) => !state);
		if (!termino) return;

		setTerminoToEdit(termino.id);
	};

	const getTerminosData = (url = null) => {

		url = url ? url + "&limit=15" : "terminos-pago?limit=15";

		request(url).then((res) => {
			setTerminos(res);
			setIsLoading(false);
		});
	};

	const onFiltroChange = async (value, filtro) => {
		setFiltros(filtros => ({
			...filtros,
			[filtro]: value,
		}));
	};

	useEffect(() => {
		getTerminosData(`terminos-pago${filtrosQuery}`);
	}, [filtrosQuery]);

	useEffect(() => {
			let query = Object.keys(filtros).map((fil) => {
				return `${fil}=${filtros[fil]}`;
			});
	
			setFiltrosQuery("?" + query.join("&"));
	}, [filtros]);
	return (
		<>
			<div className="page-header">
				<Link to={getLink("/configuracion/")}>
					&laquo; Volver a configuración
				</Link>
				<h1 className="page-title row">
					<span className="col">Términos de pago</span>
					<button
						className="au-btn au-btn-icon au-btn--green au-btn--small"
						onClick={toggleCreateModal}
					>
						<i className="zmdi zmdi-plus" />
						Añadir Término
					</button>
				</h1>
				<div className="page-controls">
					<div className="table-data__tool">
						<div className="table-data__tool-left row">
							<div className="col">
								<label htmlFor="search">Buscar</label>
								<input
									onChange={(e) =>
										onFiltroChange(e.target.value, "buscar")
									}
									type="search"
									placeholder="Buscar..."
									className="form-control"
									name="buscar"
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="status">Estado</label>
								<select
									className="form-control"
									name="status"
									id="status"
									onChange={(e) =>
										onFiltroChange(
											e.target.value,
											"status"
										)
									}
								>
									<option value="todos">Todos</option>
									<option value="activos">Activos</option>
									<option value="inactivos">Inactivos</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<div>
						{isLoading && <Loader />}
						{!terminos.data?.length ? (
							<div>
								{!isLoading && (
									<div>No hay ningún Término creado.</div>
								)}
							</div>
						) : (
							<div className="table-data">
								<table className="table table-data2">
									<thead>
										<tr>
											<th>Nombre</th>
											<th>Días</th>
											<th className="text-center">
												Estado
											</th>
											<th className="text-center">
												Acciones
											</th>
										</tr>
									</thead>
									<tbody>
										{terminos.data.map((termino) => {
											return (
												<tr
													className="tr-shadow"
													key={termino.id}
												>
													<td>{termino.nombre}</td>
													<td>{termino.dias}</td>
													<td className="d-flex justify-content-center">
														{termino.status ==
														"activo" ? (
															<span className="badge badge-success p-2">
																Activo
															</span>
														) : (
															<span className="badge badge-danger p-2">
																Inactivo
															</span>
														)}
													</td>
													<td>
														{!termino.is_default && (
															<div
																className="table-data-feature"
																style={{
																	justifyContent:
																		"center",
																}}
															>
																<button
																	className="item"
																	data-toggle="tooltip"
																	data-placement="top"
																	title=""
																	data-original-title="Edit"
																	onClick={() => {
																		toggleEditModal(
																			termino
																		);
																	}}
																>
																	<i className="zmdi zmdi-edit" />
																</button>
															</div>
														)}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
						{terminos.links && (
							<Pagination
								links={terminos.links}
								meta={terminos.meta}
								getData={getTerminosData}
							/>
						)}
					</div>
				</div>
			</div>
			<Modal
				show={showCreateModal}
				blank={true}
				toggle={toggleCreateModal}
			>
				<Create
					toggleModal={toggleCreateModal}
					getTerminosData={getTerminosData}
				/>
			</Modal>
			<Modal
				show={showEditModal}
				blank={true}
				toggle={() => toggleEditModal()}
			>
				<Edit
					toggleModal={() => toggleEditModal()}
					getTerminosData={getTerminosData}
					terminoID={terminoToEdit}
				/>
			</Modal>
		</>
	);
};

export default TerminoPago;
