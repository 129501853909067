import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../../hooks/apiRequest';
import dayjs from 'dayjs';
import { formatCurrency, getLink } from '../../../utils/helpers';
import { Link } from 'react-router-dom';

const HistorialPagos = ({estudiante}) => {
    const [servicios, setServicios] = useState([]);

    const getServicios = (estudianteId) => {
        apiRequest({}, `academico/estudiantes/${estudianteId}/servicios`, 'get').then(res => {
            if (res.data) {
                setServicios(res.data);
            }
        });
    };

    useEffect(() => {
        getServicios(estudiante.id);
    }, []);

    return (
        <>
            <h5 className="row text-uppercase text-secondary fw-600">
                <div className="col-md-4">
                    <small>Contacto:</small>
                    <h3>{estudiante?.nombreCompleto}</h3>
                </div>
                <div className="col-md-4">
                    <small>Grado:</small>
                    <h3>{estudiante?.curso?.grado?.nombre} {estudiante?.curso?.seccion?.nombre}</h3>
                </div>
                <div className="col-md-4">
                    <small>Periodo Académico:</small>
                    <h3>{estudiante?.curso?.periodoAcademico?.nombre}</h3>
                </div>
            </h5>
            <hr />
            {servicios[0]?.recibos.length > 0 ? (
                <table className="table table-fixed">
                    <tbody>
                        <tr>
                            <td style={{ fontWeight: "600" }}>
                                Fecha de pago:
                            </td>
                            <td style={{ fontWeight: "600" }}>Pago:</td>
                            <td style={{ fontWeight: "600" }}>Cuota:</td>
                            {/* <td style={{ fontWeight: "600" }}>Pendiente:</td> */}
                            <td style={{ fontWeight: "600" }}>Abono:</td>
                            <td style={{ fontWeight: "600" }}>Balance:</td>
                        </tr>
                        {servicios?.map((pago, i) => {
                            return(<>
                                {pago.recibos?.map( recibo => (
                                    <tr key={recibo.id}>
                                        <td>
                                            {dayjs(recibo.fecha).format("DD-MM-YYYY")}
                                        </td>
                                        <td>
                                            {pago.nombre} <Link to={getLink(`/imprimir/academico/recibos/${recibo.id}/${recibo.tipo}`)} target="_blank"><i className="fa fa-print"></i></Link>
                                            {recibo.estado === 'cancelado' && (
                                                <small className="badge badge-danger text-uppercase m-l-10">Cancelado</small>
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(pago.monto)}
                                        </td>
                                        {/* <td>
                                            {formatCurrency(recibo.servicios[0]?.pivot?.pendiente)}
                                        </td> */}
                                        <td>
                                            {recibo.estado === 'cancelado' ? (
                                                <del>{formatCurrency(recibo.pivot?.abonado)}</del>
                                            ) : (
                                                formatCurrency(recibo.pivot?.abonado)
                                            )}
                                        </td>
                                        <td>
                                            {recibo.estado === 'cancelado' ? (
                                                formatCurrency(parseFloat(recibo.pivot?.pendiente))
                                            ) : (
                                                formatCurrency(parseFloat(recibo.pivot?.pendiente) - parseFloat(recibo.pivot?.abonado))
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </>)
                        })}
                    </tbody>
                </table>
            ) : (
                <div className='text-center p-b-10'>
                    No hay pagos registrados todavía. <Link to={getLink(`/academico/facturar?estudiante_id=${estudiante.id}`)} target="_blank">Ir a cobros »</Link>
                </div>
            )}
        </>
    )
}

export default HistorialPagos;
